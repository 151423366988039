import * as types from "./actionTypes";
import {dispatchDashboardData, fetchPerfData} from "./index";

export function fetchUserProfileDetails() {
    return async function (dispatch, getState) {
        try {
            await dispatch({
                type: types.FETCH_PROFILE_DETAILS_REQUESTED
            });
            const url = "/fetch-user-profile-details.json";
            let response = await fetch(url, {
                headers: {
                    'Accept': 'application/json',
                },
                'credentials': 'include',
            });
            let data = await response.json();
            await dispatch({
                type: types.FETCH_PROFILE_DETAILS_RECEIVED,
                data
            });
            console.log("Fetching User Profile Details successfully", data);
            return data;

        } catch (e) {
            console.error("exception", e);
            console.error("Error in account Details updating", e);
            await dispatch({
                type: types.FETCH_PROFILE_DETAILS_FAILED,
            });
        }

    }
}

export function deleteCardDetails(isPriority, paymentDetailId) {
    console.log("delecting card=", isPriority, paymentDetailId);
    return async function (dispatch) {
        const url = "/payment-delete.json";
        dispatch({
            type: types.DELETE_CARD_DETAILS_REQUESTED
        });
        try {
            let response = await fetch(url, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                },
                'credentials': 'include',
                body: new URLSearchParams({
                    isPriority: isPriority,
                    selectedPaymentDetailId: paymentDetailId,
                })
            });
            let data = await response.json();
            console.log("data = ", data);
            dispatch({
                type: types.DELETE_CARD_DETAILS_RECEIVED,
                data
            });
            return data;
        } catch (e) {
            dispatch({
                type: types.DELETE_CARD_DETAILS_FAILED,
            })
        }
    }

};

export function updateCardDetailsForm(formObj) {
    return async function (dispatch) {
        const url = "/add-card-details.json";
        dispatch({
            type: types.ADD_CARD_DETAILS_REQUESTED,
        });
        try {
            let response = await fetch(url, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                },
                'credentials': 'include',
                body: new URLSearchParams({
                    paymentDetailsFormDetails: JSON.stringify(formObj)
                })
            });
            let data = await response.json();
            console.log("data = ", data);
            dispatch({
                type: types.ADD_CARD_DETAILS_RECEIVED,
                data
            });
            return data;
        } catch (e) {
            dispatch({
                type: types.ADD_CARD_DETAILS_FAILED,
            })
        }
    }
}

export function updateSubscriptionByType(subscriptionType) {
    return async function (dispatch) {
        const url = "/resub-details.json";
        dispatch({
            type: types.SUBSCRIBE_DETAILS_REQUESTED,
        });
        try {
            let response = await fetch(url, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                },
                'credentials': 'include',
                body: new URLSearchParams({
                    subscribe: subscriptionType
                })
            });
            let data = await response.json();
            console.log("data = ", data);
            dispatch({
                type: types.SUBSCRIBE_DETAILS_RECEIVED,
                data
            });
            return data;
        } catch (e) {
            dispatch({
                type: types.SUBSCRIBE_DETAILS_FAILED,
            })
        }
    }
}

export function resumeAccount() {
    return async function (dispatch, getState) {
        const {userInfo} = getState().userInformation;
        const {userId} = userInfo;
        const url = "/resume-account.json";
        dispatch({
            type: types.RESUME_ACCOUNT_REQUESTED,
        });
        try {
            let response = await fetch(url, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                },
                'credentials': 'include',
                body: new URLSearchParams({
                    userId: userId
                })
            });
            let data = await response.json();
            console.log("data = ", data);
            dispatch({
                type: types.RESUME_ACCOUNT_RECEIVED,
                data
            });
            return data;
        } catch (e) {
            dispatch({
                type: types.RESUME_ACCOUNT_FAILED,
            })
        }
    }
}
export function unSubscribeAccount(comment) {
    return async function (dispatch) {
        const url = "/get-Unsub-details.json";
        dispatch({
            type: types.UNSUBSCRIBE_ACCOUNT_REQUESTED,
        });
        try {
            let response = await fetch(url, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                },
                'credentials': 'include',
                body: new URLSearchParams({
                    unsubscriptionReason: comment
                })
            });
            let data = await response.json();
            console.log("data = ", data);
            dispatch({
                type: types.UNSUBSCRIBE_ACCOUNT_RECEIVED,
                data
            });
            return data;
        } catch (e) {
            dispatch({
                type: types.UNSUBSCRIBE_ACCOUNT_FAILED,
            })
        }
    }
}

export function updatePassword(oldPassword, newPassword, reEnterPassword) {
    return async function (dispatch) {
        const url = "/getPasswordDetails.json";
        // dispatch({
        //     type: types.ADD_CARD_DETAILS_REQUESTED,
        // });
        try {
            let response = await fetch(url, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                },
                'credentials': 'include',
                body: new URLSearchParams({
                    oldPassword: oldPassword,
                    newPassword: newPassword,
                    ReEnterPassword: reEnterPassword,
                })
            });
            let data = await response.json();
            console.log("data = ", data);
            // dispatch({
            //     type: types.ADD_CARD_DETAILS_RECEIVED,
            //     data
            // });
            return data;
        } catch (e) {
            dispatch({
                type: types.ADD_CARD_DETAILS_FAILED,
            })
        }
    }
}
