import React, {Fragment} from 'react';
import {
    SortingState,
    GroupingState,
    IntegratedSorting,
    PagingState,
    IntegratedPaging,
} from '@devexpress/dx-react-grid';
import {
    Grid,
} from '@devexpress/dx-react-grid-material-ui';
import {CustomPaging} from '@devexpress/dx-react-grid'
import { Card, CircularProgress, Paper, Typography } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import {nullCheck} from './templates';
import moment from 'moment';

const CustomDateTypography = withStyles(theme => ({
    root: {
        display: "flex",
        justifyContent: "flex-end",
        fontSize: 10,
    }
}))(Typography);

function getNotesPapers(key, adminName, note, creationDate) {
    return <Fragment key={key}>
        <Paper style={{margin: 5, padding: 3}}>
            <Typography style={{padding: 5}}><b>{nullCheck(adminName)}</b></Typography>
            <Typography style={{padding: 5}}>{note}</Typography>
            <CustomDateTypography>{(creationDate && creationDate != null) ? moment(creationDate).format("MM/DD/YYYY") : null}</CustomDateTypography>
        </Paper>
    </Fragment>;
}

function userNoteTable(props) {
    const {rows, noteTableColumns, isLoading, tableColumnExtensions, userInfoNote, userInfoRegDate} = props;
    console.log("rows", rows);
    console.log("col's", noteTableColumns);
    const Root = props => <Grid.Root {...props} style={{height: '100%'}}/>;

    return (
        <div>
            {isLoading ? <CircularProgress/> :
                <Paper style={{maxHeight: 145, overflowY: 'scroll', marginBottom: 20}}>
                    {getNotesPapers(1, "Registration Note", userInfoNote, userInfoRegDate)}
                    {rows != null && rows.length > 0 && rows.map((userNote, key) =>
                        getNotesPapers(key, userNote.adminName, userNote.note, userNote.creationDate)
                    )}
                </Paper>
            }
        </div>
    )
}

export default (userNoteTable);