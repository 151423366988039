import * as types from "./actionTypes";

export function getPaymentDetails() {
    return async function (dispatch) {
        try {
            const useDummyData = window.lxrOptions && window.lxrOptions.useDummyData;
            await dispatch({
                type: types.PAYMENT_DETAILS_REQUESTED
            });
            const url = useDummyData ? "/payment-history-local.JSON" : "/payment-history.json";
            let response = await fetch(url, {
                headers: {
                    'Accept': 'application/json',
                },
                'credentials': 'include',
            });
            let data = await response.json();
            console.log("Payment Details Received", data);
            await dispatch({
                type: types.PAYMENT_DETAILS_RECEIVED,
                data
            });
        } catch (e) {
            console.error("Payment Details Not Received", e);
            await dispatch({
                type: types.PAYMENT_DETAILS_FAILED,
            });
        }
    }
}