import React, { Fragment } from "react";
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import classNames from "classnames";
import NumberFormat from "react-number-format";
import * as PropTypes from "prop-types";
import { Link } from "react-router-dom";
import withStyles from '@mui/styles/withStyles';
import {
  NewOutLinedRectBlackBtn,
  NewOutLinedRectBlackTextBtn,
} from "../../components/buttons";
import moment from "moment/moment";

function PaidSearchTraffic(props) {
  const { siteGrader, classes, type } = props;
  const monthlyAdwordsBudget =
    siteGrader != null && siteGrader.monthlyAdwordsBudget
      ? siteGrader.monthlyAdwordsBudget
      : 0;
  return (
    <Grid item xs={12} md={12} sm={12}>
      <Card className={classes.smallCard}>
        {/*<SubSectionCardHeader title={"Website Traffic"}
                                      className={classNames(classes.cardTitle, classes.paper)}/>*/}

        <CardContent
          className={classes.cardContent}
          style={{ paddingTop: 5, height: "100%" }}
        >
          <Typography className={classes.text} color={"textPrimary"}>
            <b>Paid Search</b>
          </Typography>
          <Grid container style={{ height: "100%" }} alignItems={"center"}>
            <Grid item xs={12} sm={12} md={12}>
              {monthlyAdwordsBudget > 0 ? (
                <Typography
                  variant={"body1"}
                  gutterBottom
                  className={classes.websiteErrorContent}
                >
                  In {moment().subtract(1, "month").format("MMMM")} an estimated{" "}
                  <span className={classes.lightGreen}>
                    <b>
                      <NumberFormat
                        value={monthlyAdwordsBudget}
                        thousandSeparator={true}
                        prefix={"$"}
                        readOnly={true}
                        displayType={"text"}
                      />
                    </b>
                  </span>{" "}
                  was spent on Google Ads.
                </Typography>
              ) : (
                <Typography
                  variant={"body1"}
                  align={"left"}
                  color={"textSecondary"}
                  gutterBottom
                >
                  <Typography
                    align={"left"}
                    variant={"body1"}
                    className={classNames(classes.websiteErrorContent)}
                    color={"textSecondary"}
                  >
                    <b>
                      Increase search traffic with Google Ads, a leader in paid
                      search advertising.
                      {/*<br/>
                                        <span>Find out how LXRGuide can help with your Google Ads performance.</span>*/}
                    </b>
                  </Typography>
                  <br />
                  {monthlyAdwordsBudget > 0 ? (
                    <Typography
                      variant={"body1"}
                      color={"textSecondary"}
                      className={classNames(classes.paidSearchErrorContent)}
                    >
                      Find out if you are spending your budget efficiently.
                    </Typography>
                  ) : (
                    <Typography
                      variant={"body1"}
                      color={"textSecondary"}
                      className={classNames(classes.paidSearchErrorContent)}
                    >
                      Find out how LXRGuide can help with your Google Ads
                      performance.
                    </Typography>
                  )}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Typography
                align={"center"}
                style={{ marginBottom: "1rem" }}
                gutterBottom
              >
                <Link
                  to={`/google-ads-grader`}
                  style={{ textDecoration: "none" }}
                  target={"_blank"}
                >
                  <NewOutLinedRectBlackTextBtn
                    variant={"outline"}
                    style={{ textTransform: "none" }}
                  >
                    <b>Get a Free Google Ads Scorecard</b>
                  </NewOutLinedRectBlackTextBtn>
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className={"justifyCenter"}></CardActions>
      </Card>
    </Grid>
  );
}

PaidSearchTraffic.propTypes = {
  classes: PropTypes.any,
  robotsText: PropTypes.bool,
  validSSLCertificate: PropTypes.bool,
  brokenURLs: PropTypes.any,
  wwwResolved: PropTypes.bool,
  structuredDataScore: PropTypes.number,
  seoTrafficEstimate: PropTypes.number,
  monthlyAdwordsBudget: PropTypes.number,
};
const websiteErrorStyles = (theme) => ({
  smallCard: {
    height: 221,
    boxShadow: "4px 4px 18px rgba(0, 0, 0, 0.06)",
    border: "1px solid #E3E6F4",
    borderRadius: "6px",
  },
  text: { fontSize: "14px" },
  montserrat: {
    fontFamily: "Montserrat",
    opacity: "0.5",
    color: theme.palette.text.primary,
    fontSize: "16px",
  },
});
export default withStyles(websiteErrorStyles)(PaidSearchTraffic);
