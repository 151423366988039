import React, { Component, Fragment } from "react";
import { Grid, Paper, List, ListItem, ListItemIcon, ListItemText, CardContent, Card } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import { packagesList } from "./templates";
import Typography from "@mui/material/Typography";
import * as _ from "lodash";
import icons from "../icons";
import { OutLinedGreenBtn } from "../buttons";
import classNames from "classnames";
import { updateSelectedSEOPackage } from "../../actions";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

const styles = (theme) => ({
  paper: {
    position: "relative",
  },
  recommendation: {
    position: "absolute",
    textAlign: "right",
    width: "100%",
    top: "-50px",
    right: "-23px",
  },
  mainTitle: {
    fontWeight: "800",
    fontFamily: "Montserrat",
  },
  gridP: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  caption: {
    fontFamily: "Lato",
    fontSize: 16,
  },
  titleAlignLeft: {
    [theme.breakpoints.down('xl')]: {
      textAlign: "center",
    },
    [theme.breakpoints.up("sm")]: {
      textAlign: "left",
    },
  },
  title: {
    color: theme.palette.lightB,
    fontFamily: "Montserrat",
  },
  cPaper: {
    boxShadow: "unset",
    padding: 6,
  },
  icon: {
    color: theme.palette.lightB,
  },
  contactUsEmail: {
    fontFamily: "Montserrat",
  },
  contactUs: {
    fontFamily: "Montserrat",
    fontSize: 20,
  },
  mainContactUs: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  packageName: {
    color: theme.palette.dialogText,
    fontFamily: "Lato",
  },
  price: {
    color: theme.palette.text.secondary,
    fontFamily: "Montserrat",
    fontWeight: "bold",
  },
});

const CustomListItem = withStyles((theme) => ({
  root: {
    paddingTop: 1,
    paddingBottom: 1,
    alignItems: "baseline",
  },
}))(ListItem);
const CustomListItemText = withStyles((theme) => ({
  root: {
    color: theme.palette.summaryText,
    paddingLeft: 2,
    "& > span": {
      color: theme.palette.summaryText,
      paddingLeft: 2,
    },
  },
}))(ListItemText);
const CustomListItemIcon = withStyles((theme) => ({
  root: {
    marginLeft: 3,
  },
}))(ListItemIcon);

class PackagesSection extends Component {
  handleClick = async (selectedPackageObject) => {
    console.log("selectedPackageObject ", selectedPackageObject);
    await this.props.updateSelectedPackage(
      selectedPackageObject.packagesDataId
    );
  };

  render() {
    console.log("this.props.domainUrl)", this.props.domainUrl);
    const { classes } = this.props;
    return (
      <div className={"fullWidth"}>
        <Grid
          container
          spacing={5}
          justifyContent={"space-around"}
          className={classes.gridP}
        >
          {packagesList.map((data, i) => (
            <Grid item md={4} key={i}>
              <Paper className={classes.paper}>
                {data.recommendation ? (
                  <div className={classes.recommendation}>
                    <img src={icons.recommendation} alt={"recommendations"} />
                  </div>
                ) : null}
                <br />
                <br />
                <br />
                <Typography align={"center"}>{data.packageName}</Typography>
                <br />
                <br />
                <Typography
                  align={"center"}
                  className={classes.price}
                  variant={"h4"}
                  component={"h4"}
                >
                  <b>${data.price}</b>
                </Typography>
                <br />
                <br />
                <List component="nav" style={{ minHeight: "34vh" }}>
                  {_.map(data.description, (description, i) => (
                    <CustomListItem key={i}>
                      <CustomListItemIcon>{icons.dotGrey}</CustomListItemIcon>
                      <CustomListItemText primary={description} />
                    </CustomListItem>
                  ))}
                </List>
                <br />
                <br />
                <br />
                <Typography align={"center"} style={{ height: "4.3em" }}>
                  <OutLinedGreenBtn
                    onClick={() => this.handleClick(data)}
                    size={"large"}
                  >
                    Select
                  </OutLinedGreenBtn>
                </Typography>
                <br />
              </Paper>
            </Grid>
          ))}
        </Grid>
        <br />
        <br />
        <Grid
          container
          justifyContent={"flex-start"}
          className={classes.mainContactUs}
        >
          <Grid item md={12} sm={12}>
            <br />
            <Typography
              align={"left"}
              className={classes.contactUs}
              color={"textSecondary"}
              gutterBottom
            >
              <b>Interested in enterprise SEO services, please email us at</b>
            </Typography>
          </Grid>
          <Grid item>
            <Paper className={classes.cPaper}>
              <Grid container alignItems={"center"} spacing={2}>
                <Grid item className={classes.icon}>
                  {icons.envelopeMail}
                </Grid>
                <Grid item>
                  <Typography className={classes.title}>
                    <b>info@netelixir.com</b>
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
            <br />
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateSelectedPackage: (packageId) => {
    return dispatch(updateSelectedSEOPackage(packageId));
  },
});
const mapStateToProps = (state) => ({
  siteGraderServiceDetails: state.siteGraderServiceDetails,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PackagesSection));
