import React, { PureComponent, Fragment } from "react";
import { Paper } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import CommonStyles from "../../assets/jss/reports/commonStyles";
import DataMap from "./DataMap";
import * as _ from "lodash";
import WorlMap from "./WorlMap";
import { CircularProgress } from "@mui/material";
import d3 from "d3";
import { NewOutLinedRectBlackTextBtn } from "../../components/buttons";
import { Grid, Typography } from "@mui/material/index";
import icons from "../../components/icons";
import { IsLoading } from "./performanceGraph";

export function numbersFormat(num, digits) {
  var si = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}

export const DefaultRegionData = [
  { regionName: "Alabama", code: "AL", value: 89 },
  { regionName: "Alaska", code: "AK", value: 112 },
  { regionName: "Arizona", code: "AZ", value: 101 },
  { regionName: "Arkansas", code: "AR", value: 123 },
  { regionName: "California", code: "CA", value: 145 },
  { regionName: "Colorado", code: "CO", value: 98 },
  { regionName: "Connecticut", code: "CT", value: 101 },
  { regionName: "Delaware", code: "DE", value: 109 },
  // { 'regionName':'District of Columbia','code':'DC', 'value': 115 },
  { regionName: "Florida", code: "FL", value: 122 },
  { regionName: "Georgia", code: "GA", value: 91 },
  { regionName: "Hawaii", code: "HI", value: 131 },
  { regionName: "Idaho", code: "ID", value: 110 },
  { regionName: "Illinois", code: "IL", value: 134 },
  { regionName: "Indiana", code: "IN", value: 94 },
  { regionName: "Iowa", code: "IA", value: 106 },
  { regionName: "Kansa", code: "KS", value: 116 },
  { regionName: "Kentucky", code: "KY", value: 122 },
  { regionName: "Lousiana", code: "LA", value: 99 },
  { regionName: "Maine", code: "ME", value: 100 },
  { regionName: "Maryland", code: "MD", value: 101 },
  { regionName: "Massachusetts", code: "MA", value: 102 },
  { regionName: "Michigan", code: "MI", value: 104 },
  { regionName: "Minnesota", code: "MN", value: 112 },
  { regionName: "Mississippi", code: "MS", value: 105 },
  { regionName: "Missouri", code: "MO", value: 116 },
  { regionName: "Montana", code: "MT", value: 107 },
  { regionName: "Nebraska", code: "NE", value: 97 },
  { regionName: "Nevada", code: "NV", value: 108 },
  { regionName: "New Hampshire", code: "NH", value: 118 },
  { regionName: "New Jersey", code: "NJ", value: 98 },
  { regionName: "New Mexico", code: "NM", value: 109 },
  { regionName: "New York", code: "NY", value: 119 },
  { regionName: "North Carolina", code: "NC", value: 99 },
  { regionName: "North Dakota", code: "ND", value: 100 },
  { regionName: "Ohio", code: "OH", value: 126 },
  { regionName: "Oklahoma", code: "OK", value: 125 },
  { regionName: "Oregon", code: "OR", value: 124 },
  { regionName: "Pennsylvania", code: "PA", value: 122 },
  { regionName: "Rhode Island", code: "RI", value: 122 },
  { regionName: "South Carolina", code: "SC", value: 141 },
  { regionName: "South Dakota", code: "SD", value: 131 },
  { regionName: "Tennessee", code: "TN", value: 132 },
  { regionName: "Texas", code: "TX", value: 133 },
  { regionName: "Utah", code: "UT", value: 134 },
  { regionName: "Vermont", code: "VT", value: 121 },
  { regionName: "Virginia", code: "VA", value: 122 },
  { regionName: "Washington", code: "WA", value: 91 },
  { regionName: "West Virginia", code: "WV", value: 92 },
  { regionName: "Wisconsin", code: "WI", value: 93 },
  { regionName: "Wyoming", code: "WY", value: 94 },
];

const codeMap = _.invert({
  AL: "Alabama",
  AK: "Alaska",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  DC: "District of Columbia",
  FL: "Florida",
  GA: "Georgia",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  PR: "Puerto Rico",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
});
export const countryCode = {
  AF: "AFG",
  AL: "ALB",
  DZ: "DZA",
  AS: "ASM",
  AD: "AND",
  AO: "AGO",
  AI: "AIA",
  AQ: "ATA",
  AG: "ATG",
  AR: "ARG",
  AM: "ARM",
  AW: "ABW",
  AU: "AUS",
  AT: "AUT",
  AZ: "AZE",
  BS: "BHS",
  BH: "BHR",
  BD: "BGD",
  BB: "BRB",
  BY: "BLR",
  BE: "BEL",
  BZ: "BLZ",
  BJ: "BEN",
  BM: "BMU",
  BT: "BTN",
  BO: "BOL",
  BQ: "BES",
  BA: "BIH",
  BW: "BWA",
  BV: "BVT",
  BR: "BRA",
  IO: "IOT",
  BN: "BRN",
  BG: "BGR",
  BF: "BFA",
  BI: "BDI",
  CV: "CPV",
  KH: "KHM",
  CM: "CMR",
  CA: "CAN",
  KY: "CYM",
  CF: "CAF",
  TD: "TCD",
  CL: "CHL",
  CN: "CHN",
  CX: "CXR",
  CC: "CCK",
  CO: "COL",
  KM: "COM",
  CD: "COD",
  CG: "COG",
  CK: "COK",
  CR: "CRI",
  HR: "HRV",
  CU: "CUB",
  CW: "CUW",
  CY: "CYP",
  CZ: "CZE",
  CI: "CIV",
  DK: "DNK",
  DJ: "DJI",
  DM: "DMA",
  DO: "DOM",
  EC: "ECU",
  EG: "EGY",
  SV: "SLV",
  GQ: "GNQ",
  ER: "ERI",
  EE: "EST",
  SZ: "SWZ",
  ET: "ETH",
  FK: "FLK",
  FO: "FRO",
  FJ: "FJI",
  FI: "FIN",
  FR: "FRA",
  GF: "GUF",
  PF: "PYF",
  TF: "ATF",
  GA: "GAB",
  GM: "GMB",
  GE: "GEO",
  DE: "DEU",
  GH: "GHA",
  GI: "GIB",
  GR: "GRC",
  GL: "GRL",
  GD: "GRD",
  GP: "GLP",
  GU: "GUM",
  GT: "GTM",
  GG: "GGY",
  GN: "GIN",
  GW: "GNB",
  GY: "GUY",
  HT: "HTI",
  HM: "HMD",
  VA: "VAT",
  HN: "HND",
  HK: "HKG",
  HU: "HUN",
  IS: "ISL",
  IN: "IND",
  ID: "IDN",
  IR: "IRN",
  IQ: "IRQ",
  IE: "IRL",
  IM: "IMN",
  IL: "ISR",
  IT: "ITA",
  JM: "JAM",
  JP: "JPN",
  JE: "JEY",
  JO: "JOR",
  KZ: "KAZ",
  KE: "KEN",
  KI: "KIR",
  KP: "PRK",
  KR: "KOR",
  KW: "KWT",
  KG: "KGZ",
  LA: "LAO",
  LV: "LVA",
  LB: "LBN",
  LS: "LSO",
  LR: "LBR",
  LY: "LBY",
  LI: "LIE",
  LT: "LTU",
  LU: "LUX",
  MO: "MAC",
  MG: "MDG",
  MW: "MWI",
  MY: "MYS",
  MV: "MDV",
  ML: "MLI",
  MT: "MLT",
  MH: "MHL",
  MQ: "MTQ",
  MR: "MRT",
  MU: "MUS",
  YT: "MYT",
  MX: "MEX",
  FM: "FSM",
  MD: "MDA",
  MC: "MCO",
  MN: "MNG",
  ME: "MNE",
  MS: "MSR",
  MA: "MAR",
  MZ: "MOZ",
  MM: "MMR",
  NA: "NAM",
  NR: "NRU",
  NP: "NPL",
  NL: "NLD",
  NC: "NCL",
  NZ: "NZL",
  NI: "NIC",
  NE: "NER",
  NG: "NGA",
  NU: "NIU",
  NF: "NFK",
  MP: "MNP",
  NO: "NOR",
  OM: "OMN",
  PK: "PAK",
  PW: "PLW",
  PS: "PSE",
  PA: "PAN",
  PG: "PNG",
  PY: "PRY",
  PE: "PER",
  PH: "PHL",
  PN: "PCN",
  PL: "POL",
  PT: "PRT",
  PR: "PRI",
  QA: "QAT",
  MK: "MKD",
  RO: "ROU",
  RU: "RUS",
  RW: "RWA",
  RE: "REU",
  BL: "BLM",
  SH: "SHN",
  KN: "KNA",
  LC: "LCA",
  MF: "MAF",
  PM: "SPM",
  VC: "VCT",
  WS: "WSM",
  SM: "SMR",
  ST: "STP",
  SA: "SAU",
  SN: "SEN",
  RS: "SRB",
  SC: "SYC",
  SL: "SLE",
  SG: "SGP",
  SX: "SXM",
  SK: "SVK",
  SI: "SVN",
  SB: "SLB",
  SO: "SOM",
  ZA: "ZAF",
  GS: "SGS",
  SS: "SSD",
  ES: "ESP",
  LK: "LKA",
  SD: "SDN",
  SR: "SUR",
  SJ: "SJM",
  SE: "SWE",
  CH: "CHE",
  SY: "SYR",
  TW: "TWN",
  TJ: "TJK",
  TZ: "TZA",
  TH: "THA",
  TL: "TLS",
  TG: "TGO",
  TK: "TKL",
  TO: "TON",
  TT: "TTO",
  TN: "TUN",
  TR: "TUR",
  TM: "TKM",
  TC: "TCA",
  TV: "TUV",
  UG: "UGA",
  UA: "UKR",
  AE: "ARE",
  GB: "GBR",
  UM: "UMI",
  US: "USA",
  UY: "URY",
  UZ: "UZB",
  VU: "VUT",
  VE: "VEN",
  VN: "VNM",
  VG: "VGB",
  VI: "VIR",
  WF: "WLF",
  EH: "ESH",
  YE: "YEM",
  ZM: "ZMB",
  ZW: "ZWE",
  AX: "ALA",
};
var sanitizeStateName = function (name) {
  if (!_.isString(name)) {
    return null;
  }

  // bad whitespace remains bad whitespace e.g. "O  hi o" is not valid
  name = name
    .trim()
    .toLowerCase()
    .replace(/[^a-z\s]/g, "")
    .replace(/\s+/g, " ");

  var tokens = name.split(/\s+/);
  tokens = _.map(tokens, function (token) {
    return token.charAt(0).toUpperCase() + token.slice(1);
  });

  // account for District of Columbia
  if (tokens.length > 2) {
    tokens[1] = tokens[1].toLowerCase();
  }

  name = tokens.join(" ");
  return codeMap[name] ? name : null;
};

function calculateGraphData(selectedRegion, data) {
  let withCountryData = {};
  if (!_.isEmpty(data)) {
    withCountryData =
      selectedRegion == "world"
        ? _.keyBy(
            _.map(data.data, (object) => ({
              ...object,
              cost: object.cost,
              countryCode: countryCode[object.country_code],
              // "fillKey": "USA",
            })),
            "countryCode"
          )
        : _.keyBy(
            _.map(data.data, (object) => ({
              ...object,
              // "regionCode": codeMap[object.name],
              regionName: object.name,
              code: codeMap[sanitizeStateName(object.name)],
              state: object.name,
            })),
            "code"
          );
  }
  return withCountryData;
}

function GeoGraph(props) {
  const {
    width,
    classes,
    isLoading,
    data,
    kpiType,
    handleCallBack,
    selectedRegion,
    currency,
    handleReportSent,
    handleDownloadReport,
  } = props;
  const withCountryData = calculateGraphData(selectedRegion, data);
  console.log("selectedRegion=", selectedRegion);
  console.log("currency=", currency);
  return (
    <Paper className={classes.paper}>
      <Grid container justifyContent={"space-between"}>
        <Grid item sm={6} xs={6} md={6}>
          <Typography color={"textPrimary"} variant={"h3"}>
            <b>Geographical Distribution</b>
          </Typography>
        </Grid>
        {isLoading ? (
          <IsLoading classes={classes} />
        ) : (
          <Fragment>
            {_.isEmpty(data) || (data && data.data.length == 0) ? (
              <Grid item sm={12} xs={12} md={12}>
                <Typography
                  align={"center"}
                  color={"textPrimary"}
                  variant={"body1"}
                >
                  No data to display
                </Typography>
                {selectedRegion != "world" ? (
                  <NewOutLinedRectBlackTextBtn
                    className={classes.countryLevelBtn}
                    variant="outlined"
                    onClick={() => handleCallBack("world")}
                  >
                    Country Level
                  </NewOutLinedRectBlackTextBtn>
                ) : null}
              </Grid>
            ) : (
              <Fragment>
                <Grid
                  item
                  sm={6}
                  xs={6}
                  md={6}
                  className={classes.countryLevel}
                >
                  {!_.isEmpty(withCountryData) && selectedRegion != "world" ? (
                    <NewOutLinedRectBlackTextBtn
                      className={classes.countryLevelBtn}
                      variant="outlined"
                      onClick={() => handleCallBack("world")}
                    >
                      Country Level
                    </NewOutLinedRectBlackTextBtn>
                  ) : null}
                  <Typography align={"right"}>
                    <span
                      className={classes.icons}
                      onClick={() => handleReportSent("geo")}
                    >
                      {icons.plane}
                    </span>
                    &nbsp; &nbsp; &nbsp;
                    <span
                      className={classes.icons}
                      onClick={(event) => handleDownloadReport("geo", event)}
                    >
                      {icons.download}
                    </span>
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={12}
                  style={{
                    position:
                      width == "sm" || width == "xs" ? "unset" : "relative",
                  }}
                >
                  {width == "sm" || width == "xs" ? (
                    <Fragment>
                      <br />
                      <br />
                      <br />
                    </Fragment>
                  ) : null}
                  {isLoading ? (
                    <CircularProgress color={"secondary"} />
                  ) : (
                    <Fragment>
                      {!_.isEmpty(withCountryData) &&
                      selectedRegion == "world" ? (
                        <WorlMap
                          regionData={withCountryData}
                          handleCallBack={handleCallBack}
                          kpiType={kpiType}
                          currency={currency}
                          width={width}
                        />
                      ) : null}
                      {!_.isEmpty(withCountryData) &&
                      selectedRegion != "world" ? (
                        <Fragment>
                          <DataMap
                            regionData={withCountryData}
                            handleCallBack={handleCallBack}
                            kpiType={kpiType}
                            currency={currency}
                            selectedRegion={selectedRegion}
                            width={width}
                          />
                        </Fragment>
                      ) : null}
                    </Fragment>
                  )}
                </Grid>
              </Fragment>
            )}
          </Fragment>
        )}
      </Grid>
    </Paper>
  );
}

export default withStyles(CommonStyles)(GeoGraph);
