import React, { Fragment } from "react";
import * as _ from "lodash";
import { Checkbox, Grid, Paper, Typography, Button } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import icons from "../icons";
import { getRenderer } from "./task-data";
import PropTypes from "prop-types";
import Warning from "@mui/icons-material/Warning";
import { connect } from "react-redux";
import { DivTypography } from "../typography";
import { shoppingFeedBack } from "../../actions/index";
import {
  deleteDatatInServer,
  executeTasks,
  onDemandPayment,
  onDemandPaymentStatus,
  resetInputForms,
  resetItemCount,
  toggleSelection,
} from "../../actions";
import ReplayIcon from "@mui/icons-material/Replay.js";
import { NewTasks } from "../../NewInterfacePages/commonFunctions/commonFunctions";
// const BoldTypography = withStyles(theme =>({
//     root:{
//         fontWeight:"bold"
//     }
//
// }))(DivTypography);

export function IconAndName({ icon, name, caption }) {
  // if(bold)
  // const DivTypography = bold ? BoldTypography: DivTypography;
  return (
    <Grid container spacing={2}>
      <Grid item>{icon}</Grid>
      <Grid item>
        <DivTypography color="inherit" style={{ maxWidth: "auto" }}>
          {name}
        </DivTypography>
        <Typography color="inherit" variant={"caption"}>
          {caption}
        </Typography>
      </Grid>
    </Grid>
  );
}

const ActualCheckbox = withStyles((theme) => ({
  root: {
    color: theme.palette.grey[400],
  },
}))(Checkbox);

const DisableGrid = withStyles((theme) => ({
  item: {
    color: theme.palette.grey[400],
  },
}))(Grid);

const CustomCheckbox = withStyles((theme) => ({
  root: {
    color: theme.palette.checkedStatus,
    "&$checked": {
      color: theme.palette.checkedStatus,
    },
  },
  checked: {},
}))(Checkbox);

function WithCheckbox({
  taskDataId,
  taskData,
  theme,
  toggleSelection,
  currency,
  isAdmin,
  classes,
}) {
  const td = _.isArray(taskData) ? taskData[0] : taskData;
  let checkbox = (
    <CustomCheckbox
      disabled={isAdmin}
      color={"primary"}
      onChange={(_, selected) => toggleSelection([taskDataId], selected)}
      checked={td.selected}
    />
  );
  if (td.status == 1)
    checkbox = (
      <Checkbox
        checkedIcon={
          <icons.CheckCircle style={{ color: theme.palette.success }} />
        }
        checked
        disableRipple
      />
    );
  if (td.status == -1)
    checkbox = <Checkbox checkedIcon={<Warning />} checked disableRipple />;
  if (td.status == 2)
    checkbox = <Checkbox checkedIcon={<ReplayIcon />} checked disableRipple />;
  let successCheckTaskType51 = (status) => {
    let check = "";
    if (status == 1)
      check = (
        <Checkbox
          checkedIcon={
            <icons.CheckCircle style={{ color: theme.palette.success }} />
          }
          checked
          disableRipple
        />
      );
    return check;
  };

  return (
    <Fragment>
      {taskData.taskTypeId != 51 ? (
        <Grid item md={6}>
          <Grid
            container
            style={{ padding: 2 }}
            alignItems={"flex-start"}
            wrap={"nowrap"}
          >
            <Grid item>{checkbox}</Grid>
            <Grid item className={"growable"}>
              {getRenderer(td.taskTypeId)(taskData, classes)}
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid item md={12}>
          <Grid
            container
            style={{ padding: 2 }}
            alignItems={"center"}
            wrap={"nowrap"}
          >
            <Grid item>{successCheckTaskType51(taskData.status)}</Grid>
            <Grid item className={"growable"}>
              {getRenderer(td.taskTypeId)(taskData)}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
}

WithCheckbox = withStyles({}, { withTheme: true })(WithCheckbox);

const mapDispatchToProps = (dispatch) => ({
  toggleSelection: (taskIds, selected) =>
    dispatch(toggleSelection(taskIds, selected)),
});

WithCheckbox = connect(
  (state, ownProps) => ({
    taskData: state.taskDataById[ownProps.taskDataId],
    currency: state.perf.currency,
    isAdmin: state.taskAnalysis.isAdmin,
  }),
  mapDispatchToProps
)(WithCheckbox);

WithCheckbox.propTypes = {
  taskDataId: PropTypes.number.isRequired,
};

const styles = (theme) => ({
  root: {
    // ...theme.mixins.gutters(),
    width: "100%",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    // backgroundColor: theme.palette.common.black
    backgroundColor: "#F5F6FA",
  },
});

const MyPaper = withStyles(styles)(Paper);

export class Hierarchy extends React.PureComponent {
  render() {
    let { data, level = 0, headerRenderers = [], groupId } = this.props;
    // console.log(level, "Rendering Hierarchy: ", this.props.title);
    level == 0 && console.log("Rendering Hierarchy: ", this.props.title);

    if (_.isArray(data)) {
      return (
        <MyPaper elevation={0}>
          <Grid container>
            {data.map((item) => (
              <Fragment key={item}>
                <WithCheckbox taskDataId={item} />
              </Fragment>
            ))}
          </Grid>
        </MyPaper>
      );
    }

    let headerRenderer =
      _.first(headerRenderers) || ((name) => <IconAndName name={name} />);

    return (
      <MyPaper elevation={0}>
        {_.map(data, (list, caption) => (
          <div key={caption}>
            {!NewTasks.includes(groupId) && headerRenderer(caption)}
            <Hierarchy
              data={list}
              level={level + 1}
              headerRenderers={headerRenderers.slice(1)}
            />
          </div>
        ))}
      </MyPaper>
    );
  }
}

Hierarchy.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  level: PropTypes.number,
  headerRenderers: PropTypes.array,
};
