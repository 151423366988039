import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import { notificationIcon } from "./notification";
import { Tab } from "@mui/material/index";
import { withStyles } from "@mui/styles";
import { connect } from "react-redux";
import { saveRequestDemo } from "../../actions";
import { sortTasks } from "../../reducers/common";
import { Link } from "react-router-dom";
import { displayTextTrim } from "./notification";

const styles = (theme) => ({
  paper: {
    width: 452,
    background: "#FFFFFF",
    boxShadow: "14px 14px 60px rgba(30, 51, 78, 0.14)",
    borderRadius: 3,
  },
  p: {
    padding: theme.spacing(1),
  },
  iconp: {
    paddingTop: 2,
  },
  readUnread: {
    fontSize: "0.5rem",
  },
  readBg: {
    // padding: theme.spacing(1),
    padding: "0px 16px 16px 16px",
    backgroundColor: theme.palette.common.white,
    border: "1px solid " + theme.palette.common.border,
    borderRadius: 3,
    minHeight: 90,
  },
  unreadBg: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.common.skyBlue,
    border: "1px solid " + theme.palette.common.border,
    minHeight: 84,
  },
  timeText: {
    opacity: 0.5,
  },
  card: {
    boxShadow: "unset",
  },
});

// function calculateNotificationData()

function setUrl(dbUrl) {
  let url = "/";
  if (dbUrl.includes("accountSettings.html")) {
    url = "/account-settings";
  } else if (dbUrl.includes("dashboard.html")) {
    url = "/dashboard";
  }
  return url;
}

class NotificationsPage extends React.PureComponent {
  render() {
    const { notificationList, classes } = this.props;
    const selectedPpcId = this.props.match.params.ppcId
      ? this.props.match.params.ppcId
      : 0;
    console.log("this.props.match=", selectedPpcId);
    const filteredNotificationList = notificationList.filter(
      (notification) => notification.ppcId == selectedPpcId
    );

    return (
      <Card className={classes.card}>
        <CardContent>
          <br />
          <br />
          <br />
          <br />
          {filteredNotificationList != null &&
          filteredNotificationList.length > 0 ? (
            filteredNotificationList.map((notification) => (
              <Grid
                container
                alignItems={"flex-start"}
                spacing={2}
                className={classes.readBg}
              >
                <Grid item>
                  <Typography className={classes.iconp}>
                    {notificationIcon(
                      notification.priority,
                      notification.displayText
                    )}
                  </Typography>
                </Grid>
                <Grid item>
                  {notification.displayText != null &&
                  notification.displayText != "" ? (
                    <Typography
                      variant={"body1"}
                      style={{ fontWeight: 500 }}
                      color={"textPrimary"}
                    >
                      {displayTextTrim(notification.displayText)}
                    </Typography>
                  ) : (
                    <Typography>No message</Typography>
                  )}
                  {notification.anchorText != null ? (
                    <Typography>
                      <Button component={Link} to={setUrl(notification.url)}>
                        {notification.anchorText}&nbsp;&nbsp;{" "}
                        <i className="fa fa-external-link"></i>
                      </Button>
                    </Typography>
                  ) : null}
                </Grid>
                <Grid item md={12}>
                  <Typography
                    className={classes.timeText}
                    color={"textPrimary"}
                    variant={"caption"}
                    align={"right"}
                  >
                    {notification.displayTime}
                  </Typography>
                </Grid>
              </Grid>
            ))
          ) : (
            <Typography variant={"body1"} color={"textPrimary"}>
              No Notifications
            </Typography>
          )}
        </CardContent>
      </Card>
    );
  }
}

NotificationsPage.propTypes = {};
const mapStateToProps = (state, ownProps) => ({
  /*notificationList: (ownProps.match.params.ppcId && ownProps.match.params.ppcId != 0) ?
        state.userInformation.notificationList.filter(notification => notification.ppcd == state.match.params.ppcId) :
        state.userInformation.notificationList.filter(notification => notification.ppcd == 0),*/
  notificationList: state.userInformation.notificationList,
});
const mapDispatchToProps = (dispatch) => ({
  saveInputsToServer: (demoObj) => {
    return dispatch(saveRequestDemo(demoObj));
  },
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(NotificationsPage));
