import * as types from "./actionTypes";

export function saveLeadGeneration(emailId, website, isGoogleActive, eCommercePlatform) {
    return async function (dispatch) {
        console.log("saving Lead request...");
        console.log(emailId,website,isGoogleActive,eCommercePlatform);
           await dispatch({
                    type: types.UPDATE_LEAD_GENERATION,
                    data:{ emailId:emailId, website: website }
                });
        try {
            const url = "/save-lead-generation.json";
            let response = await fetch(url, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                },
                'credentials': 'include',
                body: new URLSearchParams({
                emailId: emailId,
                website: website,
                isGoogleActive: isGoogleActive,
                eCommercePlatform: eCommercePlatform
                })
            });
//             let data = await response.json();
//             console.log("Demo request saved = ", data);
//             return data;
        } catch (e) {
            console.log("exception", e);
        }
    }
}