import imageBg from "../../img/home_bg.svg";

const helpPageStyles = (theme) => ({
  card: {
    boxShadow: "unset",
  },
  gridBg: {
    background: `url(${imageBg})`,

    [theme.breakpoints.up("md")]: {
      backgroundSize: "contain",
      backgroundPosition: "top center",
      backgroundColor: theme.palette.common.darkGreybg,
    },
    [theme.breakpoints.up("xl")]: {
      backgroundRepeat: "no-repeat",
    },
    [theme.breakpoints.down('xl')]: {
      background: `unset`,
    },
  },
  cardContent: {
    padding: 0,
    // paddingTop: theme.spacing(3),
    paddingBottom: 0,
  },
  sectionBg: {
    backgroundColor: theme.palette.common.darkGreybg,
    height: 500,
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('xl')]: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
  },
  section2: {
    backgroundColor: theme.palette.common.white,
    // height: 400,
    paddingTop: theme.spacing(3.5),
    paddingBottom: theme.spacing(2.5),
    [theme.breakpoints.down('xl')]: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
  },
  section3: {
    backgroundColor: theme.palette.common.darkGreybg,
    minHeight: 400,
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    paddingRight: theme.spacing(8),
    paddingLeft: theme.spacing(8),
    [theme.breakpoints.down('xl')]: {
      padding: theme.spacing(4),
    },
  },
  secCard: {
    border: "1px solid " + theme.palette.common.darkGrey,
    boxSizing: "border-box",
    borderRadius: 10,
    height: 583,
    boxShadow: "unset",
    background: "unset",
    [theme.breakpoints.down('xl')]: {
      height: 330,
    },
  },
  section4: {
    backgroundColor: theme.palette.common.white,
    minHeight: 400,
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    paddingRight: theme.spacing(8),
    paddingLeft: theme.spacing(8),
    [theme.breakpoints.down('xl')]: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
  },
  menuItemText: {},
  confusedbar: {
    [theme.breakpoints.up("xs")]: {
      background: "linear-gradient(90.25deg, #F58120 0.05%, #FF7400 100%)",
      textAlign: "center",
      color: "white",
      fontStyle: "bold",
      // height:100,
    },
    [theme.breakpoints.down('xl')]: {
      height: 200,
      padding: 20,
      // height:100,
    },
    [theme.breakpoints.up("md")]: {
      // background: 'linear-gradient(to right, #F58120 0.05%,#FF7400 100%,#fa461e 100%)',
      background: "linear-gradient(90.25deg, #F58120 0.05%, #FF7400 100%)",
      // background: 'linear-gradient(0deg, #F58120, #F58120)',

      textAlign: "center",
      height: 100,
      lineHeight: 4,
      color: "white",
      fontStyle: "bold",
      fontSize: 18,
    },
  },
  confusionTitle: {
    color: theme.palette.common.white,
    fontWeight: "700",
    fontSize: 24,
    [theme.breakpoints.down('xl')]: {
      fontSize: 20,
    },
  },
  buttonstyle: {
    height: 45,
    fontWeight: "700",
    fontSize: 16,
    fontFamily: "Lato",
    padding: "10px 10px",
    textTransform: "none",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "white",
    },
  },
});

export default helpPageStyles;
