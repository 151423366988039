import {
  Collapse,
  Divider,
  FormControl,
  InputBase,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuItem,
  MenuList,
  NativeSelect,
  Paper,
  Tooltip,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import ExpandMore from "@mui/icons-material/ExpandMore";
import classNames from "classnames";
import * as _ from "lodash";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import icons from "../../components/icons";

export const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    // backgroundColor: theme.palette.background.paper,
    backgroundColor: theme.palette.dropdownBg,
    color: "#ffffff",
    // border: '1px solid #ced4da',
    border: "1px solid #182C45",
    fontSize: 16,
    width: "80%",
    minWidth: 120,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);
const CustomListItemText = withStyles((theme) => ({
  primary: {
    color: theme.palette.common.white,
  },
}))(ListItemText);
const CustomMenuItem = withStyles((theme) => ({
  root: {
    marginLeft: 0,
    // paddingLeft:4,
    opacity: 0.5,
    borderRadius: 2,

    
  },
  selected: {
    color: theme.palette.common.white,
    opacity: 1,
    borderLeft: "2px solid" + theme.palette.selected,
    marginLeft: 0,
    backgroundColor: "rgba(255, 255, 255, 0.05) !important",
  },
}))(MenuItem);
const CustomNestedMenuItem = withStyles((theme) => ({
  root: {
    opacity: 0.5,
    borderRadius: 0,
    marginLeft: 16,
    padding: 7,
  },
  selected: {
    color: theme.palette.common.white,
    opacity: 1,
    borderRight: "2px solid" + theme.palette.selected,
    marginLeft: 16,
    backgroundColor: "rgba(255, 255, 255, 0.05) !important",
  },
}))(MenuItem);
const CustomNativeSelect = withStyles((theme) => ({
  icon: {
    color: theme.palette.common.white,
  },
}))(NativeSelect);

const CustomListSubheader = withStyles((theme) => ({
  root: {
    textAlign: "left",
    // color: theme.palette.common.white,
    opacity: 0.5,
    fontWeight: "bold",
    fontSize: "14px",
    textTransform: "uppercase",
    backgroundColor: "#1E334E",
    color: "rgba(255, 255, 255, 0.3)",
  },
}))(ListSubheader);

function CustomMenuLists(props) {
  const {
    pricingPlanId,
    userDetails,
    updateSelectedAccount,
    updateSelectedClient,
    classes,
    handleNestedMenuClick,
    selectedEngineId,
    isDrawer,
    width,
    isPermission,
    isPerfLoading,
    history,
    welcomeToggle,
    productTourInProgress,
    statsHistory,
    productThankYouDialog,
    productTourCloseAlert,
    isMarTech,
    openMartech,
    isSubscriptions,
    accountId,
    isAnalyticsAccountDeleted,
  } = props;
  const handleEngine = (event, engineId) => {
    event.stopPropagation();
    handleNestedMenuClick(engineId);
  };
  const { userInfo } = userDetails;
  console.log("userDetails.type", userInfo);
  const selectedPpcId = userDetails.selectedAccountInfo.ppcId
    ? userDetails.selectedAccountInfo.ppcId
    : 0;
  const { accounts } = userDetails.selectedClientInfo;
  const isGoogleAccounts =
    !_.isEmpty(userDetails.selectedClientInfo) &&
    userDetails.selectedClientInfo.accounts
      ? userDetails.selectedClientInfo.accounts.filter(
          (account) => account.searchEngineId == 1 && account.enabled == 1
        ).length
      : 0;
  const isBingAccounts =
    !_.isEmpty(userDetails.selectedClientInfo) &&
    userDetails.selectedClientInfo.accounts
      ? userDetails.selectedClientInfo.accounts.filter(
          (account) => account.searchEngineId == 2 && account.enabled == 1
        ).length
      : 0;
  //if the analyticsAccountInfo is not empty then returns false, if empty returns true.
  const isAnalyticsAccounts = !_.isEmpty(userDetails.analyticsAccountInfo) && !isAnalyticsAccountDeleted
    ? true
    : false;
  const selectedClientId = !_.isEmpty(userDetails.selectedClientInfo)
    ? userDetails.selectedClientInfo.clientId
    : 0;
  const totalActiveAccount =
    userDetails.selectedClientInfo && userDetails.selectedClientInfo.accounts
      ? userDetails.selectedClientInfo.accounts.filter(
          (account) =>
            account.enabled == 1 &&
            (account.searchEngineId == 1 || account.searchEngineId == 2)
        ).length
      : 0;
  const handleNativeSelect = (event) => {
    if (width == "sm" || width == "xs") {
      event.stopPropagation();
    }
  };

  const getAutoPostList = () => {
    console.log("Click successfull on getAutoPostList");
    // console.log("User Details:::-"+userDetails);
    console.log(
      "User Details only ppcId:::-",
      userDetails.selectedAccountInfo.ppcId
    );
  };

  return (
    <Fragment >
      <div className={props.classes.menuSelectSpace} id="accounts-drop-Down">
        <FormControl
          className={classNames(props.classes.margin, {
            [props.classes.hide]: isDrawer == false,
          })}
        >
          <CustomNativeSelect
            value={selectedClientId}
            onChange={props.onChange}
            input={<BootstrapInput name="age" />}
            IconComponent={ExpandMore}
            onClick={handleNativeSelect}
          >
            {!_.isEmpty(userDetails.userInfo) &&
              userDetails.userInfo.clientInfo &&
              userDetails.userInfo.clientInfo
                .filter((client) => client.accountImported == true)
                .map((client, i) => (
                  <option
                    style={{ color: "#1E334E" }}
                    key={i}
                    value={client.clientId}
                  >
                    {client.clientName}
                  </option>
                ))}
          </CustomNativeSelect>
        </FormControl>
      </div>
      {/*{isDrawer == true ?
            <Typography className={props.classes.linkText}>Accounts Linked</Typography>
            : null}*/}
      <MenuList
        style={{ marginLeft: 10}}
        className={props.classes.menuPaper}
        id="g-b-icons"
      >
        {isPermission != null &&
        isPermission &&
        pricingPlanId != null &&
        pricingPlanId != 7 ? (
          <Fragment>
            {isDrawer == true ? (
              <CustomListSubheader component={"p"}>
                ACCOUNTS LINKED
              </CustomListSubheader>
            ) : null}
            {isGoogleAccounts ? (
              <CustomMenuItem
                button
                id={"google-accounts-list"}
                onClick={(event) =>
                  handleEngine(event, selectedEngineId == 1 && isDrawer ? 0 : 1)
                }
                selected={
                  selectedEngineId == 1 &&
                  !props.pathname.includes("/auto-posting") &&
                  !props.pathname.includes(
                    "/google-analytics-dashboard-menu"
                  ) &&
                  !props.pathname.includes("/site-grader-report") &&
                  !props.pathname.includes("/website-health-scorecard") &&
                  !props.pathname.includes("/on-demand-service") &&
                  !props.pathname.includes("/account-settings") &&
                  !props.pathname.includes("/help-center") &&
                  !props.pathname.includes("/subscriptions") &&
                  !props.pathname.includes("/connections-page")
                }
              >
                <ListItemIcon>{icons.googleSocial}</ListItemIcon>
                <CustomListItemText primary={"Google"} />
                {/*{selectedEngineId == 1 ? <ExpandLess/> : <ExpandMore/>}*/}
              </CustomMenuItem>
            ) : null}
            <Collapse
              in={selectedEngineId == 1 && isDrawer}
              timeout="auto"
              unmountOnExit
            >
              <MenuList>
                {userDetails.selectedClientInfo &&
                  userDetails.selectedClientInfo.accounts &&
                  userDetails.selectedClientInfo.accounts
                    .filter(
                      (account) =>
                        account.enabled == 1 && account.searchEngineId == 1
                    )
                    .map((account, i) => (
                      <CustomNestedMenuItem
                        key={i}
                        onClick={() => updateSelectedAccount(account.ppcId)}
                        // selected={(i == 0 ? "/" === props.pathname : undefined) ||
                        selected={
                          account.ppcId == selectedPpcId &&
                          !props.pathname.includes("/auto-posting") &&
                          !props.pathname.includes(
                            "/google-analytics-dashboard-menu"
                          ) &&
                          !props.pathname.includes("/site-grader-report") &&
                          !props.pathname.includes("/website-health-scorecard") &&
                          !props.pathname.includes("/on-demand-service") &&
                          !props.pathname.includes("/account-settings") &&
                          !props.pathname.includes("/help-center") &&
                          !props.pathname.includes("/subscriptions") &&
                          !props.pathname.includes("/connections-page")
                        }
                      >
                        <ListItemIcon>{icons.account}</ListItemIcon>
                        <Tooltip
                          placement={"right-end"}
                          title={account.clientName}
                        >
                          <CustomListItemText primary={account.clientName} />
                        </Tooltip>
                      </CustomNestedMenuItem>
                    ))}
              </MenuList>
            </Collapse>
            {isBingAccounts ? (
              <CustomMenuItem
                button
                id={"bing-accounts-list"}
                onClick={(event) =>
                  handleEngine(event, selectedEngineId == 2 && isDrawer ? 0 : 2)
                }
                selected={
                  selectedEngineId == 2 &&
                  !props.pathname.includes("/site-grader-report") &&
                  !props.pathname.includes("/on-demand-service") &&
                  !props.pathname.includes("/account-settings") &&
                  !props.pathname.includes("/help-center") &&
                  !props.pathname.includes("/subscriptions")
                }
              >
                <ListItemIcon>{icons.microSoftNewIcon}</ListItemIcon>
                <CustomListItemText primary={"Microsoft"} />
                {/*{selectedEngineId == 2 ? <ExpandLess/> : <ExpandMore/>}*/}
              </CustomMenuItem>
            ) : null}
            <Collapse
              in={selectedEngineId == 2 && isDrawer}
              timeout="auto"
              unmountOnExit
            >
              <MenuItem>
                {userDetails.selectedClientInfo &&
                  userDetails.selectedClientInfo.accounts &&
                  userDetails.selectedClientInfo.accounts
                    .filter(
                      (account) =>
                        account.enabled == 1 && account.searchEngineId == 2
                    )
                    .map((account, i) => (
                      <CustomNestedMenuItem
                        key={i}
                        onClick={() => updateSelectedAccount(account.ppcId)}
                        // selected={(i == 0 ? "/" === props.pathname : undefined) ||
                        selected={
                          account.ppcId == selectedPpcId &&
                          !props.pathname.includes("/site-grader-report") &&
                          !props.pathname.includes("/on-demand-service") &&
                          !props.pathname.includes("/account-settings") &&
                          !props.pathname.includes("/help-center") &&
                          !props.pathname.includes("/subscriptions")
                        }
                      >
                        <ListItemIcon>{icons.account}</ListItemIcon>
                        <Tooltip
                          placement={"right-end"}
                          title={account.clientName}
                        >
                          <CustomListItemText primary={account.clientName} />
                        </Tooltip>
                      </CustomNestedMenuItem>
                    ))}
              </MenuItem>
            </Collapse>
          </Fragment>
        ) : null}
        <Tooltip placement={"right-end"} title={"SEO Scorecard"}>
          <CustomMenuItem
            component={Link}
            to={"/site-grader-report/1"}
            disabled={pricingPlanId != null && pricingPlanId == 7}
            selected={"/site-grader-report/1" === props.pathname}
          >
            <ListItemIcon>{icons.SEOScoreCard}</ListItemIcon>
            <CustomListItemText primary={"SEO Scorecard"} id={"seo-link"} />
          </CustomMenuItem>
        </Tooltip>
        <Tooltip placement={"right-end"} title={"Website Health Scorecard"}>
          <CustomMenuItem
            component={Link}
            to={"/website-health-scorecard"}
            disabled={pricingPlanId != null && pricingPlanId == 7}
            selected={"/website-health-scorecard" === props.pathname}
          >
            <ListItemIcon>{icons.whs_logo_2}</ListItemIcon>
            <CustomListItemText primary={"Website Health Scorecard"} id={"whs-link"} />
          </CustomMenuItem>
        </Tooltip>
        
        {/* {isAnalyticsAccounts ? (
          <Tooltip placement={"right-end"} title={"Google Analytics 4"}>
            <CustomMenuItem
              component={Link}
              to={"/google-analytics-dashboard-menu"}
              selected={"/google-analytics-dashboard-menu" === props.pathname}
            >
              <ListItemIcon>{icons.GoogleAnalyticsLight}</ListItemIcon>
              <CustomListItemText
                primary={"Google Analytics 4"}
                id={"google-analytics-link"}
              />
            </CustomMenuItem>
          </Tooltip>
        ) : null} */}
      </MenuList>
      {width == "sm" || width == "xs" ? (
        <div className={classes.mobileMenuSpace}></div>
      ) : (
        <div className={props.classes.grow}></div>
      )}

      <Paper className={props.classes.menuSubPaper}>
        <MenuList
        //  style={{ marginLeft: 2 }}
         >
          {/* below is for connections module */}
          <Tooltip placement={"right-end"} title={"Connections"}>
            <CustomMenuItem
              component={Link}
              to={"/connections-page"}
              selected={"/connections-page" === props.pathname}
            >
              <ListItemIcon>{icons.connectionVector}</ListItemIcon>

              <CustomListItemText primary={"Connections"} />
            </CustomMenuItem>
          </Tooltip>
          {/* end of connections module */}
          <Divider className={classes.menuDivider} variant={"middle"} />
          {/* // opening autoapply link in */}
          {selectedEngineId != 2 ? (
            <Tooltip placement={"right-end"} title={"Auto Apply"}>
              <CustomMenuItem
                component={Link}
                to={"/auto-posting"}
                selected={"/auto-posting" === props.pathname}
              >
                <ListItemIcon>{icons.AutoApplyUpdated}</ListItemIcon>
                {/* <ListItemIcon>{icons.AutoApply}</ListItemIcon> */}
                <CustomListItemText primary={"Auto Apply"} />
              </CustomMenuItem>
            </Tooltip>
          ) : null}
          {selectedEngineId != 2 ? (
            <Divider className={classes.menuDivider} variant={"middle"} />
          ) : null}
          {/* closing of the autoapply */}
          {/* uncomment below code for martech services */}
          {/*<Tooltip
            placement={"right-end"}
            title={"Schedule Expert Consultation"}
          >
            <CustomMenuItem onClick={() => openMartech()} selected={isMarTech}>
              <ListItemIcon>{icons.webDev}</ListItemIcon>
              <CustomListItemText primary={"Web Development"} />
            </CustomMenuItem>
          </Tooltip>*/}
          {/* end of uncomment below code for martech services */}
          {/* below code is for the holiday banner */}
          {/* <Tooltip
            placement={"right-end"}
            title={"Holiday Expert Consultation"}
          >
            <CustomMenuItem onClick={() => openMartech()} selected={isMarTech} >
              <ListItemIcon>{icons.HolidayBoost}</ListItemIcon>
              <CustomListItemText primary={"Boost Holiday Sales"} />
            </CustomMenuItem>
          </Tooltip> */}
          {/* end of holiday banner */}
          <Divider className={classes.menuDivider} variant={"middle"} />
          {userInfo.type != 11 ? (
            <Tooltip placement={"right-end"} title={"On-Demand Service"}>
              <CustomMenuItem
                component={Link}
                to={"/on-demand-service"}
                disabled={pricingPlanId != null && pricingPlanId == 7}
                selected={"/on-demand-service" === props.pathname}
              >
                <ListItemIcon id={"on-demand-opt"}>
                  {icons.OnDemand}
                </ListItemIcon>
                <CustomListItemText primary={"On-Demand Service"} />
              </CustomMenuItem>
            </Tooltip>
          ) : null}
          {userInfo.type != 11 ? (
            <Divider className={classes.menuDivider} variant={"middle"} />
          ) : null}
          {/* below commented code is for the holidayPackage */}
           {/* {(userInfo.type!=11)?<Tooltip placement={"right-end"} title={"Holiday Package"}>
             <CustomMenuItem component={Link} to={"/holiday-package"}
                              disabled={pricingPlanId != null && pricingPlanId == 7}
                              selected={"/holiday-package" === props.pathname}>
                  <ListItemIcon>{icons.holidayPackageIcon}</ListItemIcon>
                  <CustomListItemText primary={"Holiday Package"}/>
              </CustomMenuItem>
          </Tooltip>:null}   */}
          <Divider className={classes.menuDivider} variant={"middle"} />

          <Tooltip placement={"right-end"} title={"Account Settings"}>
            <CustomMenuItem
              component={Link}
              to={"/account-settings"}
              selected={"/account-settings" === props.pathname}
            >
              <ListItemIcon>{icons.accSettings}</ListItemIcon>
              <CustomListItemText
                primary={"Account Settings"}
                id={"account-sett"}
              />
            </CustomMenuItem>
          </Tooltip>

          {!isPerfLoading &&
          totalActiveAccount > 0 &&
          statsHistory.length > 0 ? (
            <Fragment>
              <Divider className={classes.menuDivider} variant={"middle"} />
              <Tooltip
                placement={"right-end"}
                title={"Product Tour"}
                style={{ zIndex: "99999" }}
              >
                <CustomMenuItem
                  disabled={
                    productTourInProgress ||
                    productThankYouDialog ||
                    productTourCloseAlert ||
                    (pricingPlanId != null && pricingPlanId == 7)
                  }
                  onClick={async () => {
                    welcomeToggle();
                    await window.handleNested(0);
                  }}
                  selected={productTourInProgress}
                >
                  <ListItemIcon>{icons.tour}</ListItemIcon>
                  <CustomListItemText primary={"Product Tour"} />
                </CustomMenuItem>
              </Tooltip>
            </Fragment>
          ) : null}

          {isSubscriptions ? (
            <Fragment>
              <Divider className={classes.menuDivider} variant={"middle"} />
              <Tooltip placement={"right-end"} title={"Subscriptions"}>
                <CustomMenuItem
                  component={Link}
                  to={"/subscriptions"}
                  selected={"/subscriptions" === props.pathname}
                >
                  <ListItemIcon>{icons.subscription}</ListItemIcon>
                  {/*<ListItemIcon>{<i className="fa fa-bell" aria-hidden="true"></i>
                            }
                        </ListItemIcon>*/}
                  <CustomListItemText primary={"Subscriptions"} />
                </CustomMenuItem>
              </Tooltip>
            </Fragment>
          ) : null}
          {/* <Divider className={classes.menuDivider} variant={"middle"}/>
                <Tooltip placement={"right-end"} title={"Help Center"}>
                    <CustomMenuItem component={Link} to={"/help-center"}
                                    selected={"/help-center" === props.pathname}>
                        <ListItemIcon>{icons.helpCenter}</ListItemIcon>
                        <CustomListItemText primary={"Help Center"}/>
                    </CustomMenuItem>
                </Tooltip>*/}
        </MenuList>
      </Paper>
    </Fragment>
  );
}

CustomMenuLists.propTypes = {
  classes: PropTypes.any,
  open: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  pathname: PropTypes.any,
};
export default CustomMenuLists;
