import React, { Component } from "react";
import PropTypes from "prop-types";
import { Grid, Typography, Button, Card, TableCell } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import icons from "../components/icons";
import { CompactCardContent, MainSectionCardHeader } from "../components/cards";
import SalesAdminFrom from "../components/admin/sales-admin-form";
import UserForm from "../components/admin/user-form";
import AdminUserTable from "../components/admin/admin-user-table";
import { adminUserTable, handleDeleteUserAlert } from "../actions";
import { connect } from "react-redux";
import SnackBar from "../components/snackBar";

const adminStyles = (theme) => ({
  button: {
    margin: theme.spacing(3),
    boxShadow:
      "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)",
    backgroundColor: "#E0E0E0",
    width: "175px",
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
});
const CustomTypography = withStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
}))(Typography);

class AdminPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      salesAdminDialog: false,
      userDialog: false,
    };
  }

  handleUserDialog = (toggleType) => {
    console.log("test", this.state.userDialog);
    this.setState({
      [toggleType]: !this.state.userDialog,
      provUser: undefined,
    });
  };

  handleProvUserDialog = (toggleType, id) => {
    this.setState({ [toggleType]: !this.state.userDialog, provUser: id });
  };

  handleUserDialogClose = () => {
    this.setState({ provUser: undefined });
  };
  /* handleAlertClose = () => {
        this.props.handleDeleteUserAlert();
    };*/

  render() {
    const { salesAdminDialog, userDialog } = this.state;
    const { classes } = this.props;
    console.log("state=", this.state);
    console.log("adminUserTable=", this.props.adminUserTable);
    return (
      <Card>
        <CompactCardContent>
          <Grid container justifyContent={"flex-end"} alignItems={"center"}>
            <Grid item className={"flex"}>
              <CustomTypography>
                *Please do not open two obf simultaneously in the same browser
              </CustomTypography>
              <Button
                // variant="contained"
                size="large"
                onClick={() => this.handleUserDialog("userDialog")}
                className={classes.button}
              >
                Create User
                <icons.create className={classes.rightIcon} />
              </Button>

              {/*<SalesAdminFrom salesAdminDialog={salesAdminDialog}
                                            toggle={() => this.handleUserDialog('handleUserDialog')}/>*/}

              <UserForm
                userDialog={userDialog}
                provUser={this.state.provUser}
                handleUserDialogClose={this.handleUserDialogClose}
                toggle={() => this.handleUserDialog("userDialog")}
              />
            </Grid>
            <Grid item md={12}>
              <AdminUserTable
                handleProvUserDialog={this.handleProvUserDialog}
              />
            </Grid>
            {/*<Grid item md={12}>
                            <SnackBar handleAlertClose={() => this.handleAlertClose}
                                      colorType={'success'}
                                      message={"User Delected Successfully"}
                                      alert={deleteAlert}
                            />
                        </Grid>*/}
          </Grid>
        </CompactCardContent>
      </Card>
    );
  }
}

AdminPage.propTypes = {};

const mapStateToProps = (state, ownProps) => ({
  adminUserTable: state.adminUserTable,
});
const mapDispatchToProps = (dispatch) => ({
  getAdminUserTableData: () => {
    return dispatch(adminUserTable());
  },
  // handleDeleteUserAlert: (dispatch) => dispatch(handleDeleteUserAlert())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(adminStyles)(AdminPage));
