import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { alpha, styled } from "@mui/material/styles";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem, treeItemClasses } from "@mui/x-tree-view/TreeItem";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Slide,
  Typography,
} from "@mui/material";
import {
  JsAddUserLabelTypography,
  JsSubSectionTypography,
} from "../../components/typography";
import icons from "../../components/icons";
import { JsTextAsButton } from "../../components/buttons";
import Checkbox from "@mui/material/Checkbox";
import { JsOrangeContainedBtn } from "../../components/buttons";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { useParams, useHistory } from "react-router-dom";
import {
  storeAdgroupData,
  deleteAdgroupData,
  submitCampaignFramework,
  fetchAdGroupDetailsByJsAccountIdTEST,
} from "../../actions/jumpstartRevamped";
import {
  storeAdgroupDataReducer,
  fetchAdGroupDetailsByJsAccountIdReducer,
  deleteAdgroupDataReducer,
} from "../../reducers/jumpstartRevampedReducer.js";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { Authority } from "./commonComponents/jumpstartEnum.js";
import { websiteValidation } from "../commonFunctions/commonFunctions";
import { ErrorSmallTypography } from "../../components/typography";
import Cookies from "js-cookie";

const StyledTreeItem = styled(TreeItem)(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    "& .close": {
      opacity: 0.3,
    },
    "&.Mui-selected": {
      background: "none",
    },
    "&.MuiTreeItem-iconContainer": {
      margin: 0,
      width: 0,
    },
  },
  [`&.${treeItemClasses.root}`]: {
    "&.Mui-selected": {
      backgroundColor: "transparent",
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 25,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function JsAddAdgroups(props) {
  const {
    selCampaignId,
    selCampaignJsId,
    selCampaignName,
    storeAdgroupData,
    storeAdgroupDataReducer,
    deleteAdgroupData,
    deleteAdgroupDataReducer,
    fetchAdGroupDetailsByJsAccountIdReducer,
    authority,
    submitCampaignFramework,
    disableFramework,
    fetchAdGroupDetailsByJsAccountIdTEST,
    fetchAdGroupDetailsByJsAccountIdTESTReducer,
  } = props;

  const { id } = useParams();
  const [snackbarMsg, setSnackbarMsg] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [error, setError] = useState();
  const [errorAdgroupURL, setErrorAdgroupURL] = useState();
  const [deleteAdgroupArrayVar, setDeleteAdgroupArrayVar] = useState([]);
  const history = useHistory();

  //Delete dialog
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    if (checkFieldsValidity(Object.values(newArray))) {
      setError(true);
      setSnackbarMsg("Some fields are empty, or URL format is invalid.");
      setSnackbarOpen(true);
    } else {
      setOpenDialog(true);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const [adGroups, setAdGroups] = useState([
    {
      name: "Ad group 1",
      jsAccountId: selCampaignJsId,
      jsCampaignId: selCampaignId,
      children: [
        {
          id: "1",
          label: "Type Ad Group Name here",
        },
        {
          id: "2",
          label: "Type Focus Product or Theme here",
        },
        {
          id: "3",
          label: "Type URL here",
        },
      ],
    },
  ]);

  let adGroupsArray = "";
  let data = "";
  useEffect(async () => {
    if (deleteAdgroupDataReducer.status == 200) {
      delete fetchAdGroupDetailsByJsAccountIdReducer.message;
      data = await fetchAdGroupDetailsByJsAccountIdTEST(jwtToken, id);

      delete data.message;
      adGroupsArray = Object.values(data)
        .filter((group) => group.jsCampaignId === selCampaignId)
        .map((group) => {
          const id = group.id ? group.id.toString() : ""; // Check if id exists before calling toString

          return {
            id,
            name: group.name || "",
            productFocus: group.productFocus || "",
            url: group.url || "",
            children: [
              { id: `${id}-1`, label: group.name },
              { id: `${id}-2`, label: group.productFocus },
              { id: `${id}-3`, label: group.url },
            ],
          };
        });

      let deletedGroupId = deleteAdgroupDataReducer[0].id;
      let uniqueIds = new Set();

      let updatedAdGroupsArray = adGroupsArray.filter((group) => {
        let groupIdString = String(group.id);
        if (!uniqueIds.has(groupIdString)) {
          uniqueIds.add(groupIdString);
          return true;
        }
        return false;
      });

      if (updatedAdGroupsArray.length > 0) {
        setAdGroups(updatedAdGroupsArray);
      }
    } else {
      data = await fetchAdGroupDetailsByJsAccountIdTEST(jwtToken, id);

      delete data.message;
      adGroupsArray = Object.values(data)
        .filter((group) => group.jsCampaignId === selCampaignId)
        .map((group) => {
          const id = group.id ? group.id.toString() : ""; // Check if id exists before calling toString

          return {
            id,
            name: group.name || "",
            productFocus: group.productFocus || "",
            url: group.url || "",
            children: [
              { id: `${id}-1`, label: group.name },
              { id: `${id}-2`, label: group.productFocus },
              { id: `${id}-3`, label: group.url },
            ],
          };
        });
      if (adGroupsArray.length > 0) {
        setAdGroups(adGroupsArray);
      }
    }
  }, [
    fetchAdGroupDetailsByJsAccountIdReducer,
    storeAdgroupDataReducer,
    selCampaignId,
  ]);

  useEffect(() => {
    adGroupsArray = Object.values(data)
      .filter((group) => group.jsCampaignId === selCampaignId)
      .map((group) => {
        const id = group.id ? group.id.toString() : ""; // Check if id exists before calling toString
        return {
          id,
          name: group.name || "",
          productFocus: group.productFocus || "",
          url: group.url || "",
          children: [
            { id: `${id}-1`, label: group.name },
            { id: `${id}-2`, label: group.productFocus },
            { id: `${id}-3`, label: group.url },
          ],
        };
      });
    if (adGroupsArray.length > 0) {
      setAdGroups(adGroupsArray);
    }
  }, [fetchAdGroupDetailsByJsAccountIdTESTReducer]);
  const handleInputChange = (groupId, inputId, value) => {
    const updatedAdGroups = adGroups.map((group) => {
      if (group.id === groupId) {
        return {
          ...group,
          children: group.children.map((child) => {
            if (child.id === inputId) {
              return {
                ...child,
                label: value,
              };
            }
            return child;
          }),
        };
      }
      return group;
    });
    setAdGroups(updatedAdGroups);
  };

  const createLabel = (labelText, inputId, groupId) => (
    <div className="treeViewCss">
      {icons.HorizontalDashedLine}
      <input
        className="addAdGroupCss"
        value={labelText.includes("Type") ? "" : labelText}
        id={inputId}
        placeholder={labelText}
        onChange={(e) => handleInputChange(groupId, inputId, e.target.value)}
        required
        disabled={disableFramework}
      />
    </div>
  );
  const handleAddAdGroup = (index) => {
    const indexString = String(index);
    const newAdGroup = {
      jsAccountId: selCampaignJsId,
      jsCampaignId: selCampaignId,
      name: `Ad group ${adGroups.length + 1}`,
      children: [
        {
          id: String(indexString + adGroups.length + 2),
          label: "Type Ad Group Name here",
        },
        {
          id: String(indexString + adGroups.length + 3),
          label: "Type Focus Product or Theme here",
        },
        {
          id: String(indexString + adGroups.length + 4),
          label: "Type URL here",
        },
      ],
    };

    setAdGroups([...adGroups, newAdGroup]);
  };

  const jwtToken = sessionStorage.getItem("jwtToken");
  // const jwtToken = Cookies.get("jwtToken");

  const newArray = adGroups.map((obj) => {
    const newObj = {
      // id: obj.id,
      jsAccountId: selCampaignJsId,
      jsCampaignId: selCampaignId,
      // nameid: obj.name,
    };
    if (
      obj.children &&
      Array.isArray(obj.children) &&
      obj.children.length >= 3
    ) {
      newObj.name = obj.children[0].label;
      newObj.productFocus = obj.children[1].label;
      newObj.url = obj.children[2].label;
    }
    if (!obj.id || obj.id.startsWith("new-")) {
      return newObj; // Exclude 'id' field for new groups
    }

    newObj.id = obj.id;
    return newObj;
  });

  const checkFieldsValidity = (dataArray) => {
    // const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/; // Regular expression for URL validation

    for (const item of dataArray) {
      if (
        item.name === "" ||
        item.name === "Type Ad Group Name here" ||
        item.productFocus === "" ||
        item.productFocus === "Type Focus Product or Theme here" ||
        item.url === "" ||
        item.url === "Type URL here" ||
        !websiteValidation(item.url)
      ) {
        return true; // Return false if any field is empty or URL is invalid
      }
    }
    return false; // Return true if all fields are filled and URLs are valid
  };

  const handleNextButton = async () => {
    if (checkFieldsValidity(Object.values(newArray))) {
      // setEmptyAdgroupData("")
      setError(true);
      setSnackbarMsg("Some fields are empty, or URL format is invalid.");
    } else {
      const storedAddgroupdata = await storeAdgroupData(
        jwtToken,
        Object.values(newArray)
      );

      if (storedAddgroupdata.status == 200) {
        setSnackbarMsg("Successfully updated adgroup details");
        setError(false);
      } else {
        setError(true);
        setSnackbarMsg("Error in updating adgroup details");
      }
    }
    setSnackbarOpen(true);
  };

  const handleSubmitButton = async () => {
    if (checkFieldsValidity(Object.values(newArray))) {
      // setEmptyAdgroupData("")
      setError(true);
      setSnackbarMsg("Some fields are empty, or URL format is invalid.");
      setSnackbarOpen(true);
    } else {
      const data = await storeAdgroupData(jwtToken, Object.values(newArray));
      // const data = await updateUserDetails(token, updatedUser);

      if (data && data.status == 200) {
        const submitData = await submitCampaignFramework(
          jwtToken,
          selCampaignJsId
        );
        if (submitData && submitData.status == 200) {
          history.push(`/campaign-framework-completed/${selCampaignJsId}`);
        } else {
          setError(true);
          setSnackbarMsg("Error in submitting campaign details");
          setSnackbarOpen(true);
        }
      } else {
        setError(true);
        setSnackbarMsg("Error in updating adgroup details");
        setSnackbarOpen(true);
      }
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  // ------------Delete Adgroup-----------
  let deleteArrayAdgroup = [];

  const handleDeleteAdGroup = async (adgroup, desiredIndex) => {
    if (adgroup.id != undefined) {
      deleteArrayAdgroup = [
        {
          id: adgroup.id,
          jsCampaignId: selCampaignId,
          jsAccountId: selCampaignJsId,
          name: adgroup.name,
          productFocus: adgroup.productFocus,
          url: adgroup.url,
        },
      ];

      const data = await deleteAdgroupData(jwtToken, deleteArrayAdgroup);
      if (data && data.status == 200) {
        setError(false);
        setSnackbarMsg("Successfully deleted adgroup details");
        const updatedAdGroups = adGroups.filter(
          (group) => group.id != adgroup.id
        );
        setAdGroups(updatedAdGroups);
      } else {
        setError(true);
        setSnackbarMsg("Error in deleting adgroup details");
      }
    } else {
      const updatedAdGroups = adGroups.filter(
        (group, index) => index != desiredIndex
      );
      if (updatedAdGroups.length != adGroups.length) {
        setError(false);
        setSnackbarMsg("Successfully deleted adgroup details");
        setAdGroups(updatedAdGroups);
      } else {
        setError(true);
        setSnackbarMsg(" 1 Error in deleting adgroup details");
      }
    }
    setSnackbarOpen(true);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <JsAddUserLabelTypography>
          <span className="numberContainer">2</span>Add the Ad Groups Name,
          Product Focus, URL details
        </JsAddUserLabelTypography>
      </Grid>
      <Grid item xs={12}>
        {adGroups.map((group, index) => (
          <TreeView
            key={group.id ? group.id : group.name}
            aria-label="customized"
            defaultExpanded={[group.id, group.name]}
            sx={{ overflowX: "hidden", pb: 5 }}
          >
            <StyledTreeItem
              nodeId={group.id ? group.id : group.name}
              label={
                <div className="flexSpaceBtwn">
                  <JsAddUserLabelTypography>
                    Ad Group {index + 1}
                  </JsAddUserLabelTypography>
                  {adGroups.length > 1 && (
                    <JsTextAsButton
                      disabled={disableFramework}
                      style={{ color: "#ED4A4A" }}
                      onClick={() => handleDeleteAdGroup(group, index)}
                    >
                      Delete
                    </JsTextAsButton>
                  )}
                </div>
              }
            >
              {group.children.map((child) => (
                <StyledTreeItem
                  key={child.id}
                  nodeId={child.id}
                  // label={createLabel(child.label, child.id)}
                  label={createLabel(child.label, child.id, group.id)}
                />
              ))}
            </StyledTreeItem>
            {errorAdgroupURL == !true ? (
              <ErrorSmallTypography>
                Please enter valid URL
              </ErrorSmallTypography>
            ) : (
              ""
            )}
            {index == adGroups.length - 1 ? (
              <JsTextAsButton
                style={{ color: "F58120", float: "right" }}
                onClick={() => handleAddAdGroup(index + 1)}
                disabled={disableFramework}
              >
                + Add New Ad Group
              </JsTextAsButton>
            ) : (
              ""
            )}
          </TreeView>
        ))}
      </Grid>
      <Grid item container xs={12} direction="row" alignItems="center">
        <Grid item xs={4}>
          {/* {authority == Authority.ANALYST || authority == Authority.RWF ? (
            ""
          ) : (
            <>
              <FormControlLabel
                control={<Checkbox size="small" id="final" color="secondary" />}
                label="Mark this as final"
                sx={{
                  ".MuiFormControlLabel-label": {
                    fontSize: "0.875rem",
                    color: "#838383",
                  },
                }}
              />
            </>
          )} */}
        </Grid>
        <Grid
          item
          xs={8}
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          columnSpacing={2}
        >
          <Grid item>
            {/* <Typography variant="subtitle2" style={{cursor:"pointer"}}> */}
            <Button
              variant="text"
              onClick={handleNextButton}
              disabled={disableFramework}
              style={{ fontSize: "0.875rem", textTransform: "none" }}
            >
              <SaveOutlinedIcon sx={{ color: "#F58120" }} />
              Save
            </Button>

            {/* </Typography> */}
          </Grid>
          <Grid item>
            <JsOrangeContainedBtn
              disabled={disableFramework}
              style={{ padding: "3% 5%", fontSize: "0.875rem" }}
              onClick={() => handleOpenDialog()}
            >
              Submit
            </JsOrangeContainedBtn>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={error ? "error" : "success"}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarMsg}
        </Alert>
      </Snackbar>
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleCloseDialog()}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          {"Are you sure you want to submit the Campaign Framework?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => handleCloseDialog()}>Disagree</Button>
          <Button onClick={() => handleSubmitButton()}>Agree</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  storeAdgroupDataReducer: state.storeAdgroupDataReducer,
  deleteAdgroupDataReducer: state.deleteAdgroupDataReducer,
  submitCampaignFrameworkReducer: state.submitCampaignFramework,
  fetchAdGroupDetailsByJsAccountIdReducer:
    state.fetchAdGroupDetailsByJsAccountIdReducer,
  fetchAdGroupDetailsByJsAccountIdTESTReducer:
    state.fetchAdGroupDetailsByJsAccountIdTESTReducer,
});
const mapDispatchToProps = (dispatch) => ({
  storeAdgroupData: (jwtToken, newArray) =>
    dispatch(storeAdgroupData(jwtToken, newArray)),
  deleteAdgroupData: (jwtToken, deleteArrayAdgroup) =>
    dispatch(deleteAdgroupData(jwtToken, deleteArrayAdgroup)),
  submitCampaignFramework: (jwtToken, id) =>
    dispatch(submitCampaignFramework(jwtToken, id)),
  fetchAdGroupDetailsByJsAccountIdTEST: (jwtToken, id) =>
    dispatch(fetchAdGroupDetailsByJsAccountIdTEST(jwtToken, id)),
});
export default connect(mapStateToProps, mapDispatchToProps)(JsAddAdgroups);
