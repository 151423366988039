const taskActivityStyles = (theme) => ({
  paper: {
    border: "1px solid" + theme.palette.common.border,
    borderRadius: 6,
    boxShadow: "4px 4px 18px " + theme.palette.boxShadowC,
    height: 401,
    padding: theme.spacing(1.5),
  },
  current: {
    fontWeight: "800",
  },
  target: {
    opacity: "0.5",
  },
  pgTop: {
    marginTop: theme.spacing(3.5),
  },
  mgTop1: {
    marginTop: theme.spacing(1.5),
  },
  loadingDiv: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    justifyContent: "center",
  },
});

export default taskActivityStyles;
