import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Chip, Divider, Grid, Typography } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import icons from "../icons";
import {
  trackingList,
  IconText,
  trackingContent,
  trackingHead,
} from "./templates";
import * as types from "../../actions/actionTypes";

const trackingHierarchyStyles = (theme) => ({
  notSet: {
    color: theme.palette.graderGraphColors.red,
    fontFamily: "Montserrat",
    fontSize: 14,
  },
  set: {
    color: theme.palette.graderGraphColors.hyperLink,
    fontFamily: "Montserrat",
    fontSize: 14,
  },
  setYes: {
    color: theme.palette.graderGraphColors.green,
    fontFamily: "Montserrat",
    fontSize: 14,
  },
  eComTypo: {
    color: theme.palette.hyperLink,
    fontFamily: "Montserrat",
    fontSize: 14,
  },
  title: {
    fontFamily: "Montserrat",
    fontWeight: "500",
    color: theme.palette.graderGraphColors.text,
  },
  headTitle: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: 18,
  },
  contentp: {
    "& > p": {
      fontSize: 14,
      fontWeight: "300",
      color: theme.palette.graderGraphColors.text,
    },
  },
});

function getTrackingTypography(style, value) {
  return (
    <Typography className={style}>
      <b>{value}</b>
    </Typography>
  );
}

function getTrackingValues(title, data, classes) {
  console.log("data in getTrackingValues=", data);
  switch (title) {
    case "E Commerce Platform":
      return data.eCommercePlatform == ""
        ? getTrackingTypography(classes.notSet, "-")
        : getTrackingTypography(classes.eComTypo, data.eCommercePlatform);
    case "Tracking  Implementation":
      return data.conversionTrackingPresent == false
        ? getTrackingTypography(classes.notSet, "Not Set")
        : getTrackingTypography(classes.setYes, "Yes");
    case "Revenue Tracking":
      return data.revTrackingPresent == false
        ? getTrackingTypography(classes.notSet, "Not Set")
        : getTrackingTypography(classes.setYes, "Yes");
    case "Multiple Tracking":
      return data.multipleTrackingPresent == false
        ? getTrackingTypography(classes.notSet, "Not Set")
        : getTrackingTypography(classes.setYes, "Yes");
    default:
      return null;
  }
}

class TrackingHierarchy extends React.PureComponent {
  render() {
    const { classes, data } = this.props;
    const CustomHeadlineTypography = withStyles((theme) => ({
      root: {
        color:
          theme.palette.graderGraphColors[
            trackingHead(data.googleAdsReportData) == "Tracking implemented"
              ? "green"
              : "red"
          ],
      },
    }))(Typography);
    return (
      <Fragment>
        {data &&
          data.googleAdsReportData &&
          trackingList.map((title, key) => (
            <div key={key}>
              <Grid
                container
                spacing={2}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Grid item>
                  <IconText
                    padding
                    icon={icons.checkedCircle}
                    text={title}
                    classes={classes}
                  />
                </Grid>
                <Grid item>
                  {getTrackingValues(title, data.googleAdsReportData, classes)}
                </Grid>
              </Grid>
              <Divider />
            </div>
          ))}
        <Grid container>
          <Grid item>
            <br />
            <CustomHeadlineTypography
              component={"h5"}
              variant={"h5"}
              className={classes.headTitle}
            >
              {" "}
              {data && data.googleAdsReportData
                ? trackingHead(data.googleAdsReportData)
                : null}
            </CustomHeadlineTypography>
            <Typography component={"p"} className={classes.contentp}>
              {data && data.googleAdsReportData
                ? trackingContent(data.googleAdsReportData)
                : null}
            </Typography>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

TrackingHierarchy.propTypes = {};

export default withStyles(trackingHierarchyStyles)(TrackingHierarchy);
