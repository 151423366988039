import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import {
  Alert,
  Button,
  Chip,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  Pagination,
  Skeleton,
  Snackbar,
  TextField,
} from "@mui/material";
import "../jumpstart.css";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import SaveOutlined from "@mui/icons-material/SaveOutlined";
import OptionalSettings from "./optionalSettings";
import { JsOrangeContainedBtn } from "../../../components/buttons";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import { connect } from "react-redux";
import {
  updateKeywords,
  fetchKeywords,
} from "../../../actions/jumpstartRevamped";
import EditOutlined from "@mui/icons-material/EditOutlined";
import { CenterFocusStrong } from "@mui/icons-material";
import { MatchTypeHeadCells, getComparator, stableSort } from "../commonComponents/sortingFunctions";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

function EnhancedTableHead(props) {
  const {
    order,
    orderBy,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead sx={{ backgroundColor: "#F2F6F9" }}>
      <TableRow>
        {MatchTypeHeadCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label === "Keyword" ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                sx={{
                  "& .MuiTableSortLabel-icon": {
                    opacity: 1,
                    color: "#CCCFD1",
                  },
                }}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
             ) : (
              headCell.label
            )} 
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

function MatchType(props) {
  const {
    id,
    handleGenerateAdCopies,
    updateKeywords,
    UpdateKeywordsReducer,
    fetchKeywordsReducer,
    token,
    handleButton,
    selectedKeywordsForMatchType,
    disableStructure,
    setSelectedKeywordsForMatchType,
  } = props;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("id");
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [editModes, setEditModes] = React.useState({});
  const [updatedData, setUpdatedData] = React.useState(
    selectedKeywordsForMatchType || []
  );
  const [isOpen, setIsOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  //Snackbar
  const [snackbarMsg, setSnackbarMsg] = React.useState();
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [error, setError] = React.useState(false);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(false);
    setSnackbarOpen(false);
  };

  const handleEditURL = (index) => {
    setEditModes((prevEditModes) => ({ ...prevEditModes, [index]: true }));
  };

  const handleSave = async (updatedData) => {
    const data = await updateKeywords(token, updatedData);
    return data;
  };

  const handleChange = (id, property, value) => {
    setUpdatedData((prevData) => {
      return prevData.map((obj) => {
        if (obj.id === id) {
          const isValuePresent = (obj.matchTypes || []).includes(value);

          const updatedMatchTypes = isValuePresent
            ? (obj.matchTypes || []).filter((type) => type !== value)
            : [...(obj.matchTypes || []), value];

          return { ...obj, matchTypes: updatedMatchTypes };
        }
        return obj;
      });
    });
  };

  const handleChangeUrl = (id, property, value) => {
    setUpdatedData((prevData) => {
      return prevData.map((obj) => {
        if (obj.id === id) {
          return { ...obj, [property]: value };
        }
        return obj;
      });
    });
  };

  const handleSaveButton = async () => {
    setLoading(true);
    const data = await handleSave(updatedData);
    if (data && data.status == 200) {
      setSnackbarMsg("Match type saved");
      setError(false);
    } else {
      setSnackbarMsg("Error in saving Match type");
      setError(true);
    }
    
    setLoading(false);
    setSnackbarOpen(true);
  };
  

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    newPage = newPage - 1;
    setPage(newPage);
  };

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const handleNextButton = async () => {
    await handleSave(updatedData);
    await setSelectedKeywordsForMatchType(updatedData);
    handleButton("AdCopy", id);
  };

  const [visibleRows, setVisibleRows] = useState([]);

  useEffect(() => {
    if (updatedData.length > 0) {
      const startIndex = page * rowsPerPage;
      const endIndex = Math.min(startIndex + rowsPerPage, updatedData.length);
      setVisibleRows(stableSort(updatedData, getComparator(order, orderBy)).slice(startIndex, endIndex));
    } else {
      setVisibleRows([]);
    }
  }, [page, updatedData, order, orderBy]);

  return (
    <Box sx={{ width: "100%", pl: 2, pr: 2 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "space-between",
          paddingBottom: "2%",
        }}
      >
        <Typography variant="subtitle2">
          Define Keyword match type and URL for selected keywords:
        </Typography>
        <Typography variant="subtitle2">
          No. of Selected Keywords:
          <span className="keywordCount">{updatedData.length}</span>
        </Typography>
      </Box>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={dense ? "small" : "medium"}
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={updatedData.length}
            // visibleRows={vis}
          />
          <TableBody>
            {visibleRows.map((row, index) => {
              return (
                <TableRow hover tabIndex={-1} key={index}>
                  <TableCell>{row.text}</TableCell>
                  <TableCell align="left">
                    <FormControl component="fieldset" variant="standard">
                      <FormGroup aria-label="position" row>
                        <FormControlLabel
                          value="BROAD"
                          control={
                            <Checkbox
                              checked={
                                row.matchTypes != null
                                  ? row.matchTypes.filter(
                                      (row) => row == "BROAD"
                                    ).length > 0
                                  : false
                              }
                              onChange={(e) =>
                                handleChange(row.id, "matchTypes", "BROAD")
                              }
                              color="secondary"
                              size="small"
                              disabled={disableStructure}
                            />
                          }
                          label={
                            <Typography variant="subtitle2">Broad</Typography>
                          }
                        />
                        <FormControlLabel
                          value="PHRASE"
                          control={
                            <Checkbox
                              checked={
                                row.matchTypes != null
                                  ? row.matchTypes.filter(
                                      (row) => row == "PHRASE"
                                    ).length > 0
                                  : false
                              }
                              onChange={(e) =>
                                handleChange(
                                  row.id,
                                  "matchTypes",
                                  // e.target.checked ? "PHRASE" : ""
                                  "PHRASE"
                                )
                              }
                              color="secondary"
                              size="small"
                              disabled={disableStructure}
                            />
                          }
                          label={
                            <Typography variant="subtitle2">Phrase</Typography>
                          }
                        />
                        <FormControlLabel
                          value="EXACT"
                          control={
                            <Checkbox
                              checked={
                                row.matchTypes != null
                                  ? row.matchTypes.filter(
                                      (row) => row == "EXACT"
                                    ).length > 0
                                  : false
                              }
                              onChange={(e) =>
                                handleChange(
                                  row.id,
                                  "matchTypes",
                                  "EXACT"
                                )
                              }
                              color="secondary"
                              size="small"
                              disabled={disableStructure}
                            />
                          }
                          label={
                            <Typography variant="subtitle2">Exact</Typography>
                          }
                        />
                      </FormGroup>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <Grid container direction="row" alignItems="center" justifyContent="center">
                      <Grid item xs={11}>
                        <input
                          type="text"
                          value={row.url}
                          onChange={(e) =>
                            handleChangeUrl(row.id, "url", e.target.value)
                          }
                          style={{
                            width: "100%",
                            height: "38px",
                            border: "none",
                            padding: "0 2%",
                            backgroundColor: "inherit",
                            cursor: "pointer",
                          }}
                          disabled={disableStructure}
                        />
                      </Grid>
                      <Grid
                      
                      >
                        <EditOutlinedIcon
                          titleAccess="Click on Text to Edit"
                          style={{ cursor: "pointer" }}
                          fontSize="0.875rem"
                        />
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Grid
        container
        direction="column"
        rowSpacing={1}
        justifyContent="center"
        alignItems="center"
        style={{ paddingTop: "2%" }}
      >
        <Grid item xs={12}>
          <Pagination
            count={Math.ceil(updatedData.length / rowsPerPage)}
            defaultPage={1}
            onChange={handleChangePage}
            showFirstButton
            showLastButton
          />
        </Grid>

        <Grid
          item
          container
          xs={12}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={8}>
            <Button
              variant="text"
              style={{ textTransform: "none" }}
              onClick={() => handleButton("Keywords", id)}
              disabled={loading}
            >
              <ChevronLeftIcon/>Back
            </Button>
          </Grid>

          <Grid
            item
            xs={4}
            container
            direction="row"
            columnSpacing={2}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid item>
              <Button
                variant="text"
                sx={{ textTransform: "none" }}
                onClick={() => handleSaveButton()}
                disabled={
                  disableStructure ||
                  (updatedData.some((obj) => obj.matchTypes != null)
                    ? false
                    : true)
                }

                // disabled={updatedData.filter((row)=> row.matchType != "").length > 0}
              >
                <SaveOutlined sx={{ color: "#F58120", paddingRight: "2px" }} />
                Save
              </Button>
            </Grid>
            <Grid item>
              <JsOrangeContainedBtn
                sx={{ fontSize: "0.875rem", padding: "0" }}
                disabled={
                  updatedData.every(
                    (obj) => obj.matchTypes != null && obj.matchTypes.length > 0
                  )
                    ? false
                    : true
                }
                onClick={() => handleNextButton()}
              >
                Next
              </JsOrangeContainedBtn>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}
      >
        <Box sx={{ width: 550 }}>
          <OptionalSettings handleCloseIcon={toggleDrawer} />
        </Box>
      </Drawer>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={error ? "error" : "success"}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarMsg}
        </Alert>
      </Snackbar>
    </Box>
  );
}
const mapStateToProps = (state) => ({
  UpdateKeywordsReducer: state.UpdateKeywordsReducer,
  fetchKeywordsReducer: state.fetchKeywordsReducer,
});

const mapDispatchToProps = (dispatch) => ({
  updateKeywords: (token, keywords) =>
    dispatch(updateKeywords(token, keywords)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MatchType);
