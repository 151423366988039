/* This file consists of all the methods 
which are repeatedly used in the code across the project 
Note: Please name the method names properly and easy to understand
*/
export const emailValidationMtd = (email) => {
  return /\S+@\S+\.\S+/.test(email);
};

/*Convert the date received in number format to Date format
    Example: formatDate(1700711825)
    Result : 11/23/2023, 09:27:05
*/
export function formatDate(originalDateTimestamp) {
  const originalDate = new Date(originalDateTimestamp * 1000);

  return new Date(originalDate).toLocaleString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    // hour: "2-digit",
    // minute: "2-digit",
    // second: "2-digit",
    // hour12: false, // Set to false to use 24-hour format
  });
}

export function addCommas(number) {
  const [integerPart, fractionalPart] = number.toFixed(2).split('.');
  const formattedInteger = parseFloat(integerPart).toLocaleString('en-US');
  if (fractionalPart === '00' || fractionalPart === '0') {
    return formattedInteger;
  } else {
    return `${formattedInteger}.${fractionalPart}`;
  }
}

export const websiteValidation = (website) => {
  let urlPattern = "";
  if (website.includes("www")) {
    urlPattern = /^(https?:\/\/)(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}\.[a-zA-Z]{2,}(\/[a-zA-Z0-9-._~:\/?#[\]@!$&'()*+,;=%]*)?(\?[a-zA-Z0-9-._~:\/?#[\]@!$&'()*+,;=%]*)?$/;
  } else {
    urlPattern = /^(https?:\/\/)(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(.[a-zA-Z]{2,})?(\/[a-zA-Z0-9-._~:\/?#[\]@!$&'()*+,;=%]*)?(\?[a-zA-Z0-9-._~:\/?#[\]@!$&'()*+,;=%]*)?$/;
  }

  return urlPattern.test(website);
};

export const TaskTypeEnum = {
  INCREASE_BID_GEO_LOCATIONS: {
    taskTypeId: 1,
    taskName: "Increase campaign Bid Modifiers for performing Geos",
  },
  DECREASE_BID_GEO_LOCATIONS: {
    taskTypeId: 21,
    taskName: "Decrease Bid Modifiers for non-performing Geo locations",
  },
  SQR_EXACT_NEGATIVE_KEYWORDS: {
    taskTypeId: 2,
    taskName: "Add Exact match type Negative Keywords",
  },
  SQR_CONVERTING_SEARCH_QUERIES: {
    taskTypeId: 3,
    taskName: "Add Converting Search Queries as Keywords",
  },
  SQR_EXACT_KEYWORDS: {
    taskTypeId: 4,
    taskName: "Add Exact match type Keywords",
  },
  COST_TAKING_SQ_AS_NEGATIVE: {
    taskTypeId: 5,
    taskName: "Add only cost taking Search Queries as Negative Keywords",
  },

  CONVERTING_KEY_MATCH_TYPE: {
    taskTypeId: 6,
    taskName: "Add match type variations for converting Keywords",
  },

  PAUSE_NON_PERFORM_KEY: {
    taskTypeId: 7,
    taskName: "Pause non-performing Keywords",
  },

  KEY_BID_INCREASE_LOW_IMPRESSION: {
    taskTypeId: 8,
    taskName: "Increase Bids for low impression Keywords",
  },
  DECREASE_BID_NON_PERFORM_KEY: {
    taskTypeId: 9,
    taskName: "Decrease Bids for non-performing Keywords",
  },
  INCREASE_BID_AOV: {
    taskTypeId: 16,
    taskName: "Increase Bids based on AOV",
  },
  DECREASE_BID_AOV: {
    taskTypeId: 19,
    taskName: "Decrease Bids based on AOV",
  },
  PAUSE_ADGRPS_NO_ACTIVE_KEY: {
    taskTypeId: 10,
    taskName: "Pause active Adgroups with no active Keywords",
  },
  PAUSE_ADGRPS_NO_ACTIVE_ADCOPY: {
    taskTypeId: 11,
    taskName: "Pause active Adgroups with no active Adcopies",
  },
  REMOVE_CONFLICT_NEG_KEY: {
    taskTypeId: 14,
    taskName: "Remove conflicting Negative Keywords",
  },
  REMOVE_CONFLICT_CAMPAIGN_NEG_KEY: {
    taskTypeId: 22,
    taskName: "Remove conflicting campaign Negative Keywords",
  },
  REMOVE_ADGROUP_NEG_KEYWORDS: {
    taskTypeId: 41,
    taskName: "Remove Plussed Adgroup Negative Keywords",
  },
  REMOVE_CAMPAIGN_NEG_KEYWORDS: {
    taskTypeId: 42,
    taskName: "Remove Plussed Campaign Negative Keywords",
  },
  CAMPAIGN_SITE_LINKS: {
    taskTypeId: 39,
    taskName: "Active Campaigns with no active sitelinks",
  },
  ADGROUP_SITE_LINKS: {
    taskTypeId: 40,
    taskName: "Active AdGroups with no active sitelinks",
  },
  PAUSE_ADCOPY_NO_PERFORMANCE: {
    taskTypeId: 47,
    taskName: "Pause Adcopies with no performance",
  },
  OPTIMIZE_SPEND_MOBILE_DEVICES: {
    taskTypeId: 15,
    taskName: "Optimize spend by decreasing Bids for Mobile devices",
  },
  INCREASE_BID_MOBILE_DEVICES: {
    taskTypeId: 17,
    taskName: "Increase Bid Modifiers for Mobile devices",
  },

  PAUSE_KEY_BROKEN_URLS: {
    taskTypeId: 12,
    taskName: "Pause Keywords with Broken URLs",
  },
  PAUSE_ADCOPY_BROKEN_URLS: {
    taskTypeId: 13,
    taskName: "Pause Adcopies with Broken URLs",
  },
  INCREASE_BID_GEO_LOCATIONS_PLA: {
    taskTypeId: 23,
    taskName: "Increase Bid Modifiers for performing Geo locations",
  },
  DECREASE_BID_GEO_LOCATIONS_PLA: {
    taskTypeId: 24,
    taskName: "Decrease Bid Modifiers for non-performing Geo locations",
  },
  INCREASE_BID_MOBILE_DEVICES_PLA: {
    taskTypeId: 25,
    taskName: "Increase Bid Modifiers for Mobile devices",
  },
  OPTIMIZE_SPEND_MOBILE_DEVICES_PLA: {
    taskTypeId: 26,
    taskName: "Optimize spend by decreasing Bids for Mobile devices"
  },
  PAUSE_ADGRPS_CONFLICT_PRODUCT_GRPS:{
    taskTypeId: 27,
    taskName: "Pause active Adgroups with conflicting Product Groups",
  },
  RESTRUCTURE_PRODUCT_GROUP:{
    taskTypeId: 28,
    taskName: "Restructure Product Group model"
  },
  INCREASE_BID_PRODUCT_GRPS:{
    taskTypeId:29,
    taskName: "Increase Bids for Product Groups"
  },
  DECREASE_BID_PRODUCT_GRPS:{
    taskTypeId: 30,
    taskName:"Decrease Bids for Product Groups"
  },
  RESTRUCTURE_PRODUCT_GRP_CROSS_SERVING:{
    taskTypeId: 31,
    taskName: "Restructure Product Groups to remove cross serving"
  },
  NEW_PRODUCT_GRP_OPPORTUNITIES: {
    taskTypeId: 32,
    taskName: "New Product Group Opportunities"
  },
  INCREASE_BUDGET_PERFORMING_CAMPAIGN: {
    taskTypeId:33,
    taskName: "Increase Budget for Performing campaigns"
  },
  DECREASE_BUDGET_NON_PERFORMING_CAMPAIGN: {
    taskTypeId:33,
    taskName: "Decrease budget for non-performing campaigns"
  },
  HOURLY_BID_MODIFIER: {
    taskTypeId:35,
    taskName: "Change Hourly Bid Modifiers for Performing Hours"
  },
  ADD_NEGATIVE_KEYWORDS: {
    taskTypeId:38,
    taskName: "Add Negative Keywords"
  },
  ADD_KEYWORD_CUSTOM: {
    taskTypeId:37,
    taskName: "Add New Keywords",
  },
  PAUSE_NON_PERFORMING_KEY_CUSTOM:{
    taskTypeId: 7,
    taskName: "Pause non-performing Keywords",
  },
  PAUSE_ADCOPIES_LOW_PERFORM_CUSTOM:{
    taskTypeId: 47,
    taskName: "Pause Adcopies with Low Performance",
  },
  CHANGE_KEYWORD_BIDS_CUSTOM:{
    taskTypeId: 53,
    taskName: "Change Keyword Bids",
  },
  MAXIMUM_CLICKS_POSSIBLE_:{
    taskTypeId: 56,
    taskName: "Get Maximum Clicks Possible Within Daily Budget"
  },
  INCREASE_BUDGET_CUSTOM : {
    taskTypeId: 33,
    taskName: "Increase budget for performing campaigns"
  },
  DECREASE_BUDGET_CUSTOM: {
    taskTypeId: 34,
    taskName: "Decrease budget for non-performing campaigns"
  },
  NEW_SHOPPING_CAMPAIGN: {
    taskTypeId: 43,
    taskName: "Decrease budget for non-performing campaigns"
  },
  SETUP_SHOPPING_FEED: {
    taskTypeId: 49,
    taskName: "Setup Shopping Feed"
  },
  DISABLE_SEARCH_PARTNER: {
    taskTypeId: 44,
    taskName: "Disable Search Partner Network"
  },
  PAUSE_BROAD_KEYWORD: {
    taskTypeId: 48,
    taskName: "Pause cost taking Broad Keywords"
  },
  ADD_CAMPAIGN_NEGATIVE: {
    taskTypeId: 46,
    taskName: "Add Campaign Negative Keyword",
  },
  ADD_KEYWORD_RECOMMENDATION:{
    taskTypeId: 37,
    taskName: "Add New Keywords",
  },
  ADD_ADCOPY_RECOMMENDATION: {
    taskTypeId: 52,
    taskName: "Add New Adcopies",
  },
  PAUSE_ADCOPY: {
    taskTypeId: 47,
    taskName: "Pause Low Performing Adcopies",
  },
  NEW_SMART_SHOPPING_CAMPAIGN:{
    taskTypeId: 57,
    taskName: "Start Smart Shopping Campaign",
  },
  ADD_ADCOPY: {
    taskTypeId: 51,
    taskName: "Create Responsive Search Ads (using Generative AI)",
  },
  ADD_NEW_KEYWORD_OPPORTUNITY_CONNECT_TASK:{
    taskTypeId: 54,
    taskName: "Link Your Google Search Console and LXRGuide Account"
  },
  ADD_NEW_KEYWORD_OPPORTUNITY_ANALYSIS_TASK:{
    taskTypeId: 37,
    taskName: "Add Fresh Keywords",
  },
  ADD_NEW_KEYWORD_OPPORTUNITY_ANALYSIS_FRESH_KEYWORD_TASK:{
    taskTypeId: 55,
    taskName: "Add Fresh Keywords in fresh AdGroup",
  },
  INCREASE_TARGET_ROAS:{
    taskTypeId: 58,
    taskName: "Increase Target ROAS for cost-taking campaigns",
  },
  DECREASE_TARGET_ROAS:{
    taskTypeId: 59,
    taskName: "Decrease Target ROAS to improve performance",
  },
  INCREASE_TARGET_CPA: {
    taskTypeId: 60,
    taskName: "Increase Target CPA to improve performance",
  },
  DECREASE_TARGET_CPA:{
    taskTypeId: 61,
    taskName: "Decrease Target CPA for cost-taking campaigns"
  },
  UPGRADE_MANUAL_BID_TO_TARGET_ROAS:{
    taskTypeId: 62,
    taskName: "Upgrade Bidding Strategy to Target ROAS",
  },
  UPGRADE_MANUAL_BID_TO_TARGET_CPA:{
    taskTypeId: 63,
    taskName: "Upgrade Bidding Strategy to Target CPA",
  }
};

export const NewTasks = ["51", "58", "59", "60", "61", "62", "63"];
