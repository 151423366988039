import * as types from "./actionTypes";

export function updateContractSigned() {
    return async function (dispatch) {
        console.log("contract signing")

        let url = "/contract-sign.json";
        dispatch({
            type: types.CONTRACT_SIGNED_REQUESTED
        });
        try {
            let response = await fetch(url, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                },
                'credentials': 'include',
            });
            let data = await response.json();
            console.log("data = ", data);
            dispatch({
                type: types.CONTRACT_SIGNED_RECEIVED,
                data
            });
            return data;
        } catch (e) {
            dispatch({
                type: types.CONTRACT_SIGNED_FAILED,
            });
            console.log("Error in getting account access Status=", e);
        }
    }
}