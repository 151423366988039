import {
  Grid,
  Paper,
  Card,
  CardContent,
  TextField,
  Typography,
} from "@mui/material";
import * as PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { GreenButton } from "../../components/buttons";
import CircularProgress from "@mui/material/CircularProgress";
import { saveSiteGraderReportDetails } from "../../actions";
import { connect } from "react-redux";
import Snackbar from "../../components/snackbars";
import classNames from "classnames";
import {
  NewOutLinedRectBlackBtn,
  NewOutLinedRectBlackTextBtn,
} from "../../components/buttons";
import * as types from "../../actions/actionTypes";

class DetailedReportForm extends PureComponent {
  constructor(props) {
    super();
    this.state = {
      isLoading: false,
      name: "",
      nameError: "",
      emailId: "",
      emailIdError: "",
      phoneNumber: "",
      alert: false,
      errorMessage: "",
    };
  }

  validate = () => {
    let isError = false;
    const errors = {};
    if (this.state.name.length == 0) {
      isError = true;
      errors.nameError = "This field is required";
    } else {
      errors.nameError = "";
    }
    if (this.state.emailId.length == 0) {
      isError = true;
      errors.emailIdError = "This field is required";
    } else if (this.state.emailId.length > 0) {
      let result = this.state.emailId.match(
        /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
      );
      if (!result) {
        isError = true;
        errors.emailIdError = "Please enter valid email";
      } else {
        errors.emailIdError = "";
      }
    } else {
      errors.emailIdError = "";
    }

    // if (isError) {
    this.setState({
      ...this.state,
      ...errors,
    });
    // }
    return isError;
  };
  handleClose = () => {
    // this.setState({alert: false, errorMessage: ""});
    this.setState({ alert: false });
  };

  handleButtonClick = async (event) => {
    event.preventDefault();
    let error;
    error = this.validate();
    this.props.seoSiteGraderFormSubmit();
    if (!error) {
      const { emailId, name, phoneNumber } = this.state;
      this.setState({ isLoading: true });
      const response = await this.props.saveSiteGraderReportDetails(
        emailId,
        name,
        phoneNumber,
        this.props.domainId
      );
      if (response && response.errorMessage == null) {
        this.setState({
          alert: true,
          isLoading: false,
          errorMessage: null,
        });
      } else {
        this.setState({
          alert: true,
          isLoading: false,
          errorMessage: response.errorMessage,
        });
      }
      if (!this.state.isLoading) {
        this.setState(
          {
            success: false,
            isLoading: true,
          },
          () => {
            this.timer = setTimeout(() => {
              this.setState({
                isLoading: false,
                success: true,
              });
            }, 2000);
          }
        );
      }
    }
  };

  handleInputs = async (event) => {
    event.preventDefault();
    await this.setState({
      [event.target.name]: event.target.value,
    });
  };

  render() {
    const { classes } = this.props;
    const { isLoading } = this.state;
    /*return <Paper className={classNames(classes.padding3X, classes.paper)}>*/
    return (
      <Card
        className={classNames(classes.cardClass, classes.detailedReportCard)}
      >
        <CardContent className={classes.cardContent}>
          <Grid item xs={12} sm={12} md={12}>
            <Typography
              color={"textPrimary"}
              variant={"body1"}
              className={classes.text}
            >
              Want to make <b>example.com</b> easy to find on Google?
            </Typography>
            <Typography
              color={"textPrimary"}
              variant={"body1"}
              className={classes.text}
              gutterBottom
            >
              <b>
                Download our detailed SEO audit report with 62+ SEO parameters
                for <span className={classes.lightGreen}>Free</span>.
              </b>
            </Typography>
          </Grid>
          {/*<br/>*/}
          <form onSubmit={this.handleButtonClick}>
            <Grid container justifyContent={"space-between"} spacing={6}>
              <Grid item md={11} style={{ marginLeft: "10px" }}>
                <Grid container spacing={2} className={classes.formSection}>
                  <Grid item xs={11} sm={11} md={6}>
                    <TextField
                      size="medium"
                      variant="standard"
                      placeholder={"Please Enter your name..."}
                      className={classes.inputField}
                      margin="normal"
                      fullWidth
                      name="name"
                      value={this.state.name}
                      onChange={this.handleInputs}
                      error={this.state.nameError !== ""}
                      helperText={this.state.nameError}
                    />
                  </Grid>
                  <Grid item xs={11} sm={11} md={6}>
                    <TextField
                      size="medium"
                      variant="standard"
                      placeholder={"Please Enter your email address..."}
                      className={classes.inputField}
                      margin="normal"
                      fullWidth
                      name="emailId"
                      value={this.state.emailId}
                      onChange={this.handleInputs}
                      error={this.state.emailIdError !== ""}
                      helperText={this.state.emailIdError}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} className={classes.formSection}>
                  <Grid item xs={11} sm={11} md={6}>
                    <TextField
                      size="medium"
                      variant="standard"
                      placeholder={"Your Phone Number...(Optional)"}
                      className={classes.inputField}
                      margin="normal"
                      fullWidth
                      name="phoneNumber"
                      value={this.state.phoneNumber}
                      onChange={this.handleInputs}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <div
                      className={classes.wrapper}
                      style={{ marginTop: "12px" }}
                    >
                      <GreenButton
                        type={"submit"}
                        variant="contained"
                        color="primary"
                        disabled={isLoading}
                        fullWidth
                      >
                        Send my free report
                      </GreenButton>
                      {isLoading && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
          <Snackbar
            verticalAlign={"bottom"}
            horizontalAlign={"left"}
            open={this.state.alert}
            // duration={3000}
            handleClose={this.handleClose}
            onClose={this.handleClose}
            variant={this.state.errorMessage != null ? "error" : "success"}
            message={
              this.state.errorMessage != null
                ? "Something Went Wrong, Please Try again."
                : "Mail sent Successfully."
            }
          />
        </CardContent>
      </Card>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  saveSiteGraderReportDetails: (emailId, name, phoneNumber, domainId) => {
    return dispatch(
      saveSiteGraderReportDetails(emailId, name, phoneNumber, domainId)
    );
  },
  seoSiteGraderFormSubmit: () =>
    dispatch({ type: types.SEO_SITE_GRADER_FORM_SUBMIT_TRACK }),
});

DetailedReportForm.propTypes = { classes: PropTypes.any };
export default connect(null, mapDispatchToProps)(DetailedReportForm);
