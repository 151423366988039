import { styled } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import { tableCellClasses } from "@mui/material/TableCell";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F8F9F9",
    color: "#4E4E4E",
    borderBottom: "1px solid #E6E6E6",
    fontSize: "0.875rem",
    fontWeight: "500",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "0.875rem",
    color: "#747474",
    fontWeight: "400",
    padding: "14px",
  },
}));

export default StyledTableCell;
