import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import "../jumpstart.css";
import {
  Box,
  Button,
  DialogContent,
  DialogContentText,
  Divider,
  Drawer,
  Grid,
  Pagination,
  TableSortLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import JsSearchBar from "../jsSearchBar";
import StyledTableCell from "../commonComponents/styledTableCell";
import JsAddUser from "./jsAddUser";
import { formatDate } from "../../commonFunctions/commonFunctions";
import { deleteJSUser } from "../../../actions/jumpstartRevamped";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Authority } from "../commonComponents/jumpstartEnum";
import {
  JsUsersHeadCells,
  getComparator,
  stableSort,
} from "../commonComponents/sortingFunctions";
import PropTypes from "prop-types";
import { visuallyHidden } from "@mui/utils";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead sx={{ backgroundColor: "#F2F6F9", position: "relative", zIndex: 10 }}>
      <TableRow>
        {JsUsersHeadCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label !== "Assigned Accounts" && headCell.label !== "#"? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                sx={{
                  "& .MuiTableSortLabel-icon": {
                    opacity: 1,
                    color: "#CCCFD1",
                  },
                }}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};


function JsUsers(props) {
  const {
    userList,
    jsAccounts,
    fetchJsAccountsReducer,
    jwtToken,
    deleteJSUser,
    handleMainUserList,
  } = props;

  const [userData, setUserData] = useState([]);
  const [selectedAcc, setSelectedAcc] = useState(null);
  const [searchFlag, setSearchFlag] = useState(false);
  const [updateAcc, setUpdateAcc] = useState();
  const [open, setIsOpen] = useState(false);

  //Snackbar
  const [error, setError] = useState();
  const [snackbarMsg, setSnackbarMsg] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  //Delete dialog
  const [openDialog, setOpenDialog] = useState(false);

  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);
  const rowsPerPage = 10;

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("id");

  useEffect(() => {
    if (userData && userData.length > 0) {
      const indexOfLastItem = page * rowsPerPage;
      const indexOfFirstItem = indexOfLastItem - rowsPerPage;
      const currentItems = userData.slice(indexOfFirstItem, indexOfLastItem);
      setTotalPages(Math.ceil(userData.length / rowsPerPage));
    }
  }, [userData]);

  const handleOpenDialog = () => {
    setIsOpen(false);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleRowClick = (account) => {
    setUpdateAcc(account);
    setIsOpen(true);
  };

  const handleCloseIcon = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (userList !== undefined || userList !== null) {
      setUserData(userList);
    }
  }, [userList]);

  const handleSelectedAcc = (account) => {
    setSelectedAcc(account);
    setSearchFlag(true);
  };
  const handleClearSearch = () => {
    setSelectedAcc(null);
    setSearchFlag(false);
  };

  const setRoleColor = (role) => {
    if (
      role == Authority.ADMIN ||
      role == Authority.MANAGER ||
      role == Authority.LXRGUIDE_SERVICE
    ) {
      return "blueStatusColor";
    } else if (role == Authority.ANALYST) {
      return "yellowStatusColor";
    } else if (role == Authority.REVIEWER) {
      return "purpleStatusColor";
    } else if (role == Authority.RWF) {
      return "orangeStatusColor";
    }
  };

  const formatAuthority = (role) => {
    if (role == Authority.ADMIN) {
      return "Admin";
    } else if (role == Authority.ANALYST) {
      return "Analyst";
    } else if (role == Authority.MANAGER) {
      return "Manager";
    } else if (role == Authority.REVIEWER) {
      return "Reviewer";
    } else if (role == Authority.LXRGUIDE_SERVICE) {
      return "Lxrguide Service";
    } else if (role == Authority.RWF) {
      return "Vendor";
    }
  };

  const handleAccountUpdate = (updatedUser) => {
    const updatedUsersArray = userData.map((user) =>
      user.id === updatedUser.id ? updatedUser : user
    );
    setUserData(updatedUsersArray);
  };

  const handleDelete = async () => {
    const data = await deleteJSUser(jwtToken, updateAcc.email);
    let updatedUsersArray;
    if (data && data.status == 200) {
      updatedUsersArray = userData.filter((user) => user.id !== updateAcc.id);
      setError(false);
      setSnackbarMsg("Successfully deleted user");
    } else {
      setError(true);
      setSnackbarMsg("Error in deleting user");
    }
    handleCloseDialog();
    setSnackbarOpen(true);
    await setUserData(updatedUsersArray);
    handleMainUserList(updatedUsersArray);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    newPage = newPage - 1;
    setPage(newPage);
  };

  const [visibleRows, setVisibleRows] = useState([]);

  useEffect(() => {
    if (userData && userData.length > 0) {
      const startIndex = page * rowsPerPage;
      const endIndex = Math.min(startIndex + rowsPerPage, userData.length);
      setVisibleRows(
        stableSort(userData, getComparator(order, orderBy)).slice(
          startIndex,
          endIndex
        )
      );
    } else {
      setVisibleRows([]);
    }
  }, [page, userData, order, orderBy]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <div style={{padding: "200px 2% 3%"  }}>
      <Grid
        container
        direction="row"
        style={{ background: "#fff" }}
        justifyContent="space-between"
        alignItems="center"
        className="searchBar"
      >
        <Grid item xs={9} sm={7} md={4} lg={3}>
          {userList !== null ? (
            <JsSearchBar
              data={userData}
              handleSelectedAcc={handleSelectedAcc}
              handleClearSearch={handleClearSearch}
              component={"JsUsers"}
            />
          ) : (
            ""
          )}
        </Grid>
        <Grid item xs={2}>
          {/* <Typography variant="subtitle2" style={{float:"right", display:"flex", alignItems:"center"}}>
            Show :
            <FormControl variant="standard" sx={{ ml: 1, minWidth: 120 }}>
              <Select
                // value={age}
                // onChange={handleChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                autoWidth
              >
                <MenuItem value={"All"} sx={{fontSize:"0.875rem"}}>All</MenuItem>
                {accountStage == AccountStage.CAMPAIGN_FRAMEWORK &&
                  <MenuItem value={"Created"} sx={{fontSize:"0.875rem"}}>Created</MenuItem>
                }
                <MenuItem value={"In Progress"} sx={{fontSize:"0.875rem"}}>In Progress</MenuItem>
                <MenuItem value={"In Review"} sx={{fontSize:"0.875rem"}}>In Review</MenuItem>
                <MenuItem value={"Reviewer Approved"} sx={{fontSize:"0.875rem"}}>
                  Reviewer Approved
                </MenuItem>
              </Select>
            </FormControl>
          </Typography> */}
        </Grid>
      </Grid>
      <TableContainer
        component={Paper}
        style={{ boxShadow: "none", border: 0 }}
      >
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {userData !== undefined && searchFlag !== true ? (
              visibleRows.map((row, index) => (
                <TableRow
                  key={row.id}
                  onClick={() => handleRowClick(row)}
                  style={{ cursor: "pointer" }}
                >
                  <StyledTableCell component="th" scope="row">
                    {index + page * rowsPerPage + 1}
                  </StyledTableCell>
                  <StyledTableCell>{row.login}</StyledTableCell>
                  <StyledTableCell>{row.email}</StyledTableCell>
                  <StyledTableCell>
                    {formatDate(row.createdDate)}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      color:
                        row.jsAccounts && row.jsAccounts.length > 0
                          ? "#747474"
                          : "#ED4A4A",
                    }}
                  >
                    {row.jsAccounts && row.jsAccounts.length > 0 ? (
                      <>
                        <span>{row.jsAccounts[0].companyName}</span>
                        {row.jsAccounts.length > 1 ? (
                          <span
                            style={{ color: "#F58120", paddingLeft: "4px" }}
                          >
                            <Tooltip
                              title={
                                <ul
                                  style={{
                                    listStyle: "none",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  {row.jsAccounts.slice(1).map((account) => (
                                    <li
                                      style={{ fontSize: "0.75rem" }}
                                      key={account.companyName}
                                    >
                                      {account.companyName}
                                    </li>
                                  ))}
                                </ul>
                              }
                            >
                              + {row.jsAccounts.length - 1} more
                            </Tooltip>
                          </span>
                        ) : null}
                      </>
                    ) : (
                      "Not Assigned"
                    )}
                  </StyledTableCell>
                  <StyledTableCell>
                    <span className={setRoleColor(row.authority)}>
                      {formatAuthority(row.authority)}
                    </span>
                  </StyledTableCell>
                </TableRow>
              ))
            ) : userData !== undefined && searchFlag === true ? (
              <TableRow
                key={selectedAcc.id}
                onClick={() => handleRowClick(selectedAcc)}
                style={{ cursor: "pointer" }}
              >
                <StyledTableCell component="th" scope="row">
                  {1}
                </StyledTableCell>
                <StyledTableCell>{selectedAcc.login}</StyledTableCell>
                <StyledTableCell>{selectedAcc.email}</StyledTableCell>
                <StyledTableCell>
                  {formatDate(selectedAcc.createdDate)}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    color:
                      selectedAcc.jsAccounts.length > 0 ? "#747474" : "#ED4A4A",
                  }}
                >
                  {selectedAcc.jsAccounts &&
                  selectedAcc.jsAccounts.length > 0 ? (
                    <>
                      <span>{selectedAcc.jsAccounts[0].companyName}</span>
                      {selectedAcc.jsAccounts.length > 1 ? (
                        <span style={{ color: "#F58120", paddingLeft: "4px" }}>
                          <Tooltip
                            title={
                              <ul
                                style={{
                                  listStyle: "none",
                                  padding: 0,
                                  margin: 0,
                                  fontSize: "0.875rem",
                                }}
                              >
                                {selectedAcc.jsAccounts
                                  .slice(1)
                                  .map((account) => (
                                    <li
                                      style={{ fontSize: "0.75rem" }}
                                      key={account.companyName}
                                    >
                                      {account.companyName}
                                    </li>
                                  ))}
                              </ul>
                            }
                          >
                            + {selectedAcc.jsAccounts.length - 1} more
                          </Tooltip>
                        </span>
                      ) : null}
                    </>
                  ) : (
                    "Not Assigned"
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  <span className={setRoleColor(selectedAcc.authority)}>
                    {formatAuthority(selectedAcc.authority)}
                  </span>
                </StyledTableCell>
              </TableRow>
            ) : (
              ""
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Drawer
        anchor="right"
        open={open}
        onClose={handleCloseIcon}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}
      >
        <Box sx={{ width: 550 }}>
          <JsAddUser
            handleCloseIcon={handleCloseIcon}
            updateAcc={updateAcc}
            handleAccountUpdate={handleAccountUpdate}
            jsAccounts={jsAccounts}
            token={jwtToken}
            handleSnackbarOpen={setSnackbarOpen}
            handleError={setError}
            handleSnackbarMsg={setSnackbarMsg}
            handleOpenDialog={handleOpenDialog}
          />
        </Box>
      </Drawer>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={error ? "error" : "success"}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarMsg}
        </Alert>
      </Snackbar>
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleCloseDialog()}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Are you sure you want to delete the user?"}</DialogTitle>
        <DialogActions>
          <Button onClick={() => handleCloseDialog()}>Disagree</Button>
          <Button onClick={() => handleDelete()}>Agree</Button>
        </DialogActions>
      </Dialog>

      {selectedAcc == null ? (
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ background: "#fff", padding: "1% 0" }}
        >
          <Pagination
            count={totalPages}
            defaultPage={1}
            onChange={handleChangePage}
            showFirstButton
            showLastButton
          />
        </Grid>
      ) : (
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ background: "#fff", padding: "1% 0" }}
        >
          <Pagination
            count={1}
            page={1}
            showFirstButton
            showLastButton
            disabled
          />
        </Grid>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  fetchJsAccountsReducer: state.fetchJsAccountsReducer,
});
const mapDispatchToProps = (dispatch) => ({
  deleteJSUser: (jwtToken, email) => dispatch(deleteJSUser(jwtToken, email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(JsUsers);
