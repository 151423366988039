import React, { Component, Fragment, useEffect, useState } from "react";
import { Grid, Paper, Typography } from "@mui/material";
import { store } from "../../reducers/common";
import { Card, CardContent, TableBody } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import accountSettingsStyles from "../../assets/jss/accountSettings/accountSettingStyle";
import {
  CircularProgress,
  Table,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material/index";
import { connect } from "react-redux";
import {
  updateSelectedClient,
  updateClientDetails,
} from "../../actions/fetchingUserData";
import ClientSettingsForm from "./clientSettingsForm";
import MySnackbarWrapper from "../snackbars";
import { getAuthUrls } from "../../actions/fetchAccountSettingsData";
import * as _ from "lodash";
export const CustomTableCellHead = withStyles((theme) => ({
  head: {
    background: "rgba(242, 243, 249, 0.6)",
    border: "1px solid #E3E6F4",
    boxSizing: "border-box",
    // borderRadius: "0px 0px 0px 0px",
    color: theme.palette.text.primary,
    fontWeight: "500",
    fontSize: theme.typography.pxToRem(14),
    fontFamily: "Lato",

  },
  // label
}))(TableCell);
export const CustomTableCell = withStyles((theme) => ({
  body: {
    background: "rgba(255, 255, 255, 0.6)",
    border: "1px solid #E3E6F4",
    boxSizing: "border-box",
    color: theme.palette.text.primary,
    fontWeight: "bold",
    fontSize: theme.typography.pxToRem(16),
    fontFamily: "Lato",
    maxWidth: 100,
    // width: 330,
    height: 33,
  },
  // label
}))(TableCell);
export const SelectedTableCell = withStyles((theme) => ({
  body: {
    background: "rgba(255, 255, 255, 0.6)",
    border: "1px solid #E3E6F4",
    boxSizing: "border-box",
    color: theme.palette.secondary.main,
    fontWeight: "bold",
    fontSize: theme.typography.pxToRem(16),
    fontFamily: "Lato",
  },
  // label
}))(TableCell);

function ClientSettings(props) {
  const { classes, userDetails, theme } = props;
  const { userInfo, selectedClientInfo, isClientDetailsLoading } = userDetails;

  const selectedClientId =
    selectedClientInfo && selectedClientInfo.clientId
      ? selectedClientInfo.clientId
      : 0;

  console.log("selectedClientInfo=", selectedClientInfo);

  const [state, setState] = useState({
    name: "",
    alert: false,
    message: "",
    variantType: "",
  });

  useEffect(() => {
    document.title = "LXRGuide - Client Settings";
    props.getAuthUrls();
  }, []);

  const updateSelectedClient = (clientId) => {
    const { userDetails } = props;
    const selectedClientId = userDetails.selectedClientInfo.clientId
      ? userDetails.selectedClientInfo.clientId
      : 0;
    if (clientId == selectedClientId) {
      return;
    }
    props.updateSelectedClient(clientId);
  };

  const handleUpdate = async (clientId, clientObject) => {
    let response = await props.updateClientDetails({
      clientId,
      ...clientObject,
    });
    if (response !== null && response.error && response.error != "") {
      setState({
        alert: true,
        message: response.error,
        variantType: "error",
      });
    } else {
      setState({
        alert: true,
        message: "Client Details Updated Successfully",
        variantType: "success",
      });
    }
  };

  // const handleCloseAlert = async () => {
  //   setState((prevState) => ({
  //     ...prevState,
  //     alert: false,
  //     message: "",
  //   }));
  //   props.updateRefreshAccessSuccess();
  // };
  const handleCloseAlert = () => {
    setState((prevState) => ({
      ...prevState,
      alert: false,
    }));
  };

  return (
    <Fragment>
      {userInfo.isLoading ? (
        <div
          className={"flex alignItemsCenter justifyCenter"}
          style={{ height: "70%" }}
        >
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <Card className={classes.card} style={{ zoom: "90%" }}>
          <CardContent>
            <Grid container spacing={4} >
              <Grid item md={12} lg={12} xl={12}>
                <Typography color={"textPrimary"} variant={"h4"} gutterBottom>
                  <b>Settings</b>
                </Typography>
                <Paper p={1} style={{ width: "100%" }}>
                  <Typography pl={2} pt={2}  color={"textPrimary"} variant={"h4"} gutterBottom>
                    <b>Clients</b>
                  </Typography>
                  <Table style={{
                    borderCollapse: "separate",
                    paddingLeft:"18px",
                    paddingRight:"18px"
                  }}>
                    <TableHead>
                      <TableRow>
                        <CustomTableCellHead p={1}>Client Name</CustomTableCellHead>
                        <CustomTableCellHead p={1}>Client Email</CustomTableCellHead>
                        <CustomTableCellHead p={1}>
                          Client Website
                        </CustomTableCellHead>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {userInfo.clientInfo &&
                        userInfo.clientInfo.map((client, i) => (
                          <Fragment>
                            {client.clientId == selectedClientId ? (
                              <TableRow
                                onClick={() =>
                                  updateSelectedClient(client.clientId)
                                }
                              >
                                <SelectedTableCell p={1}>
                                  {client.clientName}
                                </SelectedTableCell>
                                <SelectedTableCell p={1}>
                                  {client.clientEmail}
                                </SelectedTableCell>
                                <SelectedTableCell p={1}>
                                  {client.clientWebsite}
                                </SelectedTableCell>
                              </TableRow>
                            ) : (
                              <TableRow
                                onClick={() =>
                                  updateSelectedClient(client.clientId)
                                }
                              >
                                <CustomTableCell>
                                  {client.clientName}
                                </CustomTableCell>
                                <CustomTableCell>
                                  {client.clientEmail}
                                </CustomTableCell>
                                <CustomTableCell>
                                  {client.clientWebsite}
                                </CustomTableCell>
                              </TableRow>
                            )}
                          </Fragment>
                        ))}
                    </TableBody>
                  </Table>
                  <br />
                  <br />
                </Paper>
              </Grid>
              <Grid item md={12}>
                <br />
                {selectedClientInfo.clientId ? (
                  // <Grid item md={12} lg={12} xl={12} >
                  <ClientSettingsForm p={1}
                    classes={classes}
                    selectedClientInfo={selectedClientInfo}
                    updateSelectedClient={() => updateSelectedClient()}
                    UpdateClientDetails={(clientObject) =>
                      handleUpdate(selectedClientInfo.clientId, clientObject)
                    }
                    isClientDetailsLoading={isClientDetailsLoading}
                  />
                  // </Grid>
                ) : (
                  <Typography variant={"caption"} gutterBottom color={"error"}>
                    Something Went wrong in loading selected client details
                  </Typography>
                )}
              </Grid>
            </Grid>
            <MySnackbarWrapper
              open={state.alert}
              onClose={handleCloseAlert}
              message={state.message}
              verticalAlign={"bottom"}
              horizontalAlign={"right"}
              duration={6000}
              variant={state.variantType}
            />
          </CardContent>
        </Card>
      )}
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  userDetails: state.userInformation,
});
const mapDispatchToProps = (dispatch) => ({
  updateSelectedClient: (clientId) => dispatch(updateSelectedClient(clientId)),
  updateClientDetails: (ClientDetailsObject) =>
    dispatch(updateClientDetails(ClientDetailsObject)),
  getAuthUrls: () => dispatch(getAuthUrls()),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(accountSettingsStyles, { withTheme: true })(ClientSettings));
