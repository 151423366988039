import { Grid, Typography, Button } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
// import { extend } from 'lodash';
import React, { Component, Fragment } from "react";
import lxrgrowthmodel from "../../assets/img/lxrgrowthmodel.png";
import {
  OrangeTextSize12,
  GrayText,
  BlueTitleTypography,
  HeadLineTypography,
  CatalinaBlue,
} from "../../components/typography";
import icons from "../../components/icons";
import { Link } from "react-router-dom";

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="xl" />;

const styles = (theme) => ({
  rootstyle: {
    [theme.breakpoints.up("xs")]: {
      // height: '530px',
      backgroundColor: "#FFFFFF",
      alignItems: "center",
      padding: 10,
      textAlign: "center",
    },
    [theme.breakpoints.up("md")]: {
      height: "612px",
      backgroundColor: "#FFFFFF",
      alignItems: "center",
      textAlign: "center",
    },
  },

  imagestylemobile: {
    [theme.breakpoints.down("xl")]: {
      width: "100%",
      textAlign: "center",
    },
    [theme.breakpoints.up("md")]: {
      width: "100%",
      textAlign: "center",
    },
  },
  para: {
    fontSize: "24px",
  },
  Stylecenter: {
    textAlign: "center",
  },
  orangeText: {
    color: "#E97F2B",
    fontWeight: "700",
  },
  buttonstyle: {
    height: 45,
    border: "1px solid #A2B6C4",
    boxSizing: "border-box",
    borderRadius: "3px",

    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "17px",
  },
  gridgrowthmodel: {
    [theme.breakpoints.up("xs")]: {
      textAlign: "center",
    },
    [theme.breakpoints.up("md")]: {
      textAlign: "initial",
    },
  },
  heading: {
    color: "textPrimary",
    fontSize: "1.25rem",
    fontWeight: "700",
    [theme.breakpoints.up("md")]: {
      fontSize: "2.75rem",
    },
  },
});

function LxrguideGrowthModel(props) {
  const classes = props.classes;
  const { width, growthModel } = props;
  return (
    <div>
      <Grid
        container
        className={classes.rootstyle}
        justifyContent={width == "sm" || width == "xs" ? "center" : null}
      >
        <Grid item xs={9} sm={9} md={6} className={classes.imagestylemobile}>
          {width == "sm" || width == "xs" ? (
            <Fragment>
              <br />
              <br />
            </Fragment>
          ) : null}
          {width == "sm" || width == "xs"
            ? icons.lxrguidegrowthmodelhp("80%")
            : icons.lxrguidegrowthmodelhp("unset")}
          {width == "sm" || width == "xs" ? (
            <Fragment>
              <br />
              <br />
            </Fragment>
          ) : null}
        </Grid>
        <Grid item md={6} xs={12} className={classes.gridgrowthmodel}>
          <OrangeTextSize12 className={classes.Stylecenter} gutterBottom>
            HOW IT WORKS
          </OrangeTextSize12>
          <Typography className={classes.heading}>
            LXRGUIDE GROWTH MODEL{" "}
          </Typography>
          <br />
          {growthModel ? (
            <Fragment>
              <CatalinaBlue>
                LXRGuide’s Growth Model is powered by our first-hand experience
                and consistently delivers proven results for e-commerce
                marketers. Our extensive experience has helped us understand the
                unique aspects of a high-performing paid search campaign for
                e-commerce businesses. We know how a successful campaign should
                be structured, what ad copies perform better than the rest,
                types of analysis that need to be done during various stages of
                the campaign, and bidding strategies.
              </CatalinaBlue>
              <br />

              <CatalinaBlue>
                Priester’s Pecans is Alabama’s largest gourmet handmade candy
                company. They signed on for LXRGuide in late 2016 with the goal
                of growing their ecommerce sales through paid search
                advertising. Our growth model helped drive 11% Y/Y growth in the
                first year, 51% growth in the third year, and 158% growth in the
                fourth year.
              </CatalinaBlue>
            </Fragment>
          ) : (
            <Fragment>
              {" "}
              <CatalinaBlue>
                LXRGuide’s Growth Model is built on expertise acquired from over{" "}
                <span className={classes.orangeText}>7,000,000 hours</span> of
                driving consistent double digit paid search revenue growth for
                e-commerce businesses around the world.
              </CatalinaBlue>
              <br />
              <br />
              <Button
                component={Link}
                to={"/growth-model"}
                className={classes.buttonstyle}
              >
                LEARN MORE
              </Button>
            </Fragment>
          )}

          {/*<CatalinaBlue>
                        LXRGuide’s Growth Model is powered by our first-hand experience and consistently delivers proven results for ecommerce marketers.
                    </CatalinaBlue>
                    <br/>
                    <CatalinaBlue>
                        Our extensive experience has helped us understand the unique aspects of a high performing paid search campaign for ecommerce businesses – how a successful campaign should be structured, what ad copies perform better than the rest, types of analysis that need to be done during various stages of the campaign and bidding strategies that need to be applied for best results.
                    </CatalinaBlue>
                    <br/>
                <CatalinaBlue>
                        Priester’s Pecans is Alabama’s largest gourmet handmade candy company. They signed up for LXRGuide in 2017 with the goal of growing their ecommerce sales through paid search advertising. Our growth model helped drive a xx% Y/Y growth in year 1, yy% growth in year 2 and zz% growth in year 3.
                </CatalinaBlue>*/}

          {width == "sm" || width == "xs" ? (
            <Fragment>
              <br /> <br />
            </Fragment>
          ) : null}
        </Grid>
      </Grid>
    </div>
  );
}
export default withWidth()(withStyles(styles)(LxrguideGrowthModel));
