import React, { useCallback, useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridColumnMenu } from "@mui/x-data-grid";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Alert,
  Badge,
  Button,
  Chip,
  Divider,
  Drawer,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Pagination,
  Select,
  Snackbar,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  fetchKeywords,
  updateKeywords,
} from "../../../actions/jumpstartRevamped";
import SaveOutlined from "@mui/icons-material/SaveOutlined";
import { JsOrangeContainedBtn } from "../../../components/buttons";
import Checkbox from "@mui/material/Checkbox";
import FilterListIcon from "@mui/icons-material/FilterList";
import "../jumpstart.css";
import KeywordFilterDrawer from "./keywordFilterDrawer";
import KeywordFilterSearchBar from "./keywordFilterSearchBar";
import { columns } from "./keywordColumns";

function createData(
  selected,
  id,
  text,
  minSearchVolume,
  maxSearchVolume,
  ninetyDaysSearchVolumeTrend,
  yearOverYearSearchVolumeTrend,
  lowTopOfPageBid,
  highTopOfPageBid,
  competition
) {
  return {
    selected,
    id,
    text,
    minSearchVolume,
    maxSearchVolume,
    ninetyDaysSearchVolumeTrend,
    yearOverYearSearchVolumeTrend,
    lowTopOfPageBid,
    highTopOfPageBid,
    competition,
  };
}

function EnhancedTableToolbar(props) {
  const { numSelected, classes, selectedRows, handleChipDelete, rows , disableStructure} = props;
  return (
    <Toolbar
      sx={{
        mb: 1,
        p: { xs: 1, sm: 2 },
        maxHeight: "107px",
        overflowY: "auto",
        display: "grid",
        flexDirection: "column",
        justifyContent: "flex-start",
        ...(numSelected > 0 && {
          bgcolor: "#F8F9F9",
        }),
      }}
    >
      {numSelected > 0 ? (
        <div className="chips">
          {selectedRows.map(
            (row, index) =>
              row.selected && (
                <Chip
                  key={`${row.id}-${index}`}
                  label={row.text}
                  className="chip"
                  onDelete={() => handleChipDelete(row)}
                  disabled = {disableStructure}
                />
              )
          )}
        </div>
      ) : (
        ""
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function KeywordsWithFilter(props) {
  const {
    handleKeywordsNextButton,
    fetchKeywords,
    updateKeywords,
    fetchKeywordsReducer,
    updateKeywordsReducer,
    token,
    id,
    newSelected,
    handleNewSelected,
    setSelectedKeywordsForMatchType,
    disableStructure,
    currencySymbol,
  } = props;

  const [keywords, setKeywords] = useState([]);

  const [snackbarMsg, setSnackbarMsg] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [allSelectCheck, setAllSelectCheck] = useState(false);
  const [selected, setSelected] = useState([]);
  const [show, setShow] = useState("All");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [page, setPage] = useState(1);
  const [pageNo, setPageNo] = useState(0);
  const [totalSize, setTotalSize] = useState();
  const [sortModel, setSortModel] = useState();
  const [filterCriterion, setFilterCriterion] = useState([]);
  const [maxRanges, setMaxRanges] = useState();
  const [searchTerm, setSearchTerm] = useState({
    searchText: "",
    contains: true,
  });
  const [searchTermList, setSearchTermList] = useState([]);

  useEffect(()=>{
    setSearchTermList([searchTerm]);
    // setSearchTermList(prevList => [...prevList, searchTerm]);
  },[searchTerm])

  const fetchKeywordsData = async (
    token,
    id,
    pageNo,
    sortBy,
    sortDir,
    show,
    filterCriterion,
    searchTermList
  ) => {
    if (sortBy == undefined) {
      sortBy = "id";
    }
    if (sortDir == undefined) {
      sortDir = "asc";
    }
    setLoading(true);
    debugger;
    const data = await fetchKeywords(
      token,
      id,
      pageNo,
      sortBy,
      sortDir,
      show,
      filterCriterion,
      searchTermList
    );
    if (data && data.status == 200) {
      if (data.content.length > 0) {
        const finalData = Object.values(data.content);
        setKeywords([...finalData]);
        setSelected([...Object.values(data.selectedKeywords)]);
      } else {
        setKeywords([]);
        setSelected(
          data.selectedKeywords.length > 0
            ? [...Object.values(data.selectedKeywords)]
            : []
        );
      }
    } else {
      setError("No data found");
    }
    setLoading(false);
  };

  useEffect(() => {
    const handleUseEffect = async () => {
      setShow("All");
      await handleNewSelected([]);
      setTotalPages(0);
      setPage(1);
      setSelected([]);
      setPageNo(0);
      setOrder("asc");
      setOrderBy("text");
      setSortModel([{ field: 'text', sort: 'asc' }]);
      setFilterCriterion([]);
      setSearchTerm({ searchText: "",contains: true,});
      setSearchTermList([]);
      await fetchKeywordsData(
        token,
        id,
        0,
         "text",
        "asc",
        "All",
         [],
         [{searchTerm: "", contains: true}]
      );
    };

    handleUseEffect();
  }, [id]);

  useEffect(() => {
    if (fetchKeywordsReducer.message === "FETCH_KEYWORDS_RECEIVED") {
      setTotalSize(fetchKeywordsReducer.totalElements);
      setTotalPages(fetchKeywordsReducer.totalPages);
      setPage(fetchKeywordsReducer.pageNumber + 1);
      setMaxRanges(fetchKeywordsReducer.maxRanges);
    }
  }, [fetchKeywordsReducer]);

  const rows = keywords.map((item) =>
    createData(
      item.selected,
      item.id,
      item.text,
      item.minSearchVolume,
      item.maxSearchVolume,
      item.ninetyDaysSearchVolumeTrend,
      item.yearOverYearSearchVolumeTrend,
      item.lowTopOfPageBid,
      item.highTopOfPageBid,
      item.competition
    )
  );

  const handleRowCheckbox = (event, clickedRow) => {
    const isChecked = event.target.checked;
    const updatedRow = keywords.find((row) => row.id === clickedRow.id);

    if (updatedRow) {
      updatedRow.selected = isChecked;

      setSelected((prevSelected) => {
        const index = prevSelected.findIndex(
          (selectedRow) => selectedRow.id === updatedRow.id
        );
        if (index !== -1) {
          prevSelected[index] = updatedRow;
          return [...prevSelected];
        } else {
          return [...prevSelected, updatedRow];
        }
      });

      handleNewSelected([...newSelected, selected[selected.length - 1]]);
    }
  };

  const handleSelectAllClick = (event) => {
    setAllSelectCheck(true);
    const updatedKeywords = keywords.map((item) => ({
      ...item,
      selected: event.target.checked,
    }));

    const mergedArray = Array.from(
      new Set([...updatedKeywords, ...selected].map((item) => item.id))
    ).map((id) => {
      const matchingItem = [...updatedKeywords, ...selected].find(
        (item) => item.id === id
      );
      return matchingItem;
    });

    setSelected(mergedArray);
  };

  const handleShowSort = (e) => {
    setSortModel([{ field: 'text', sort: 'asc' }]);
    setShow(e.target.value);
    fetchKeywordsData(
      token,
      id,
      pageNo,
      orderBy,
      order,
      e.target.value,
      filterCriterion,
      searchTermList
    );
  };

  const handleChipDelete = (row) => {
    row.selected = false;
    setSelected((prevSelected) => {
      const index = prevSelected.findIndex(
        (selectedRow) => selectedRow.id === row.id
      );
      prevSelected[index] = row;
      return [...prevSelected];
    });
  };

  const handleSaveAPICall = async () => {
    return await updateKeywords(token, selected);
  };

  const handleChangePage = async (event, newPage) => {
    debugger;
    console.log(order, orderBy);
    await handleSaveAPICall();
    const nextPage = newPage - 1;
    await fetchKeywordsData(
      token,
      id,
      nextPage,
      orderBy,
      order,
      show,
      filterCriterion,
      searchTermList
    );
    setPage(newPage);
  };


  const handleSaveKeywords = async () => {
    setLoading(true);
    const data = await handleSaveAPICall();
    if (data && data.status == 200) {
      setError(false);
      setSnackbarMsg("Keywords saved");
      setSelectedKeywordsForMatchType(
        selected.filter((keyword) => keyword.selected)
      );
      if (show == "Selected" || (show == "Unselected" && allSelectCheck)) {
        fetchKeywordsData(
          token,
          id,
          page - 1,
          orderBy,
          order,
          show,
          filterCriterion,
          searchTermList,
        );
        setAllSelectCheck(false);
      }
    } else {
      setError(true);
      setSnackbarMsg("Error in saving keywords");
    }
    setLoading(false);
    setSnackbarOpen(true);
  };

  const isRowSelected = (row) => {
    return selected.some(
      (selectedRow) => selectedRow.id == row.id && selectedRow.selected
    );
  };

  const selectedRowCount = () => {
    return (
      rows.length > 0 &&
      rows.filter((row) =>
        selected.some(
          (selectedRow) => selectedRow.id === row.id && selectedRow.selected
        )
      ).length
    );
  };

  const handleRequestSort = async (event) => {
    if (event.length > 0) {
      setSortModel(event);
      setOrder(event[0].sort);
      setOrderBy(event[0].field);
      fetchKeywordsData(
        token,
        id,
        page - 1,
        event[0].field,
        event[0].sort,
        show,
        filterCriterion,
        searchTermList,
      );
    } else {
      setSortModel([{ field: orderBy, sort: "asc" }]);
      setOrder("asc");
      fetchKeywordsData(
        token,
        id,
        page - 1,
        orderBy,
        "asc",
        show,
        filterCriterion,
        searchTermList
      );
    }
  };

  const handleNextButton = async () => {
    setLoading(true);
    await handleSaveKeywords(selected);
    setLoading(false);
    handleKeywordsNextButton("MatchType", id);
  };

  //Filter logic
  const [openDrawer, setOpenDrawer] = useState(false);
  const handleOpenDrawer = () => setOpenDrawer(true);
  const handleCloseDrawer = () => setOpenDrawer(false);

  const handleApplyFilters = (filterList) => {
    setFilterCriterion(filterList);
    fetchKeywordsData(token, id, 0, orderBy, order, show, filterList,searchTermList);
    handleCloseDrawer();
  };

  const handleResetAll = async () =>{
    setFilterCriterion([]);
    await handleApplyFilters([]);
    handleCloseDrawer();
    setSnackbarMsg("Filters are successfully reset");
    setError(false);
    setSnackbarOpen(true);
  }

  const handleSearchByKeyword = (searchKeyword) => {
    setSearchTerm(searchKeyword);
    fetchKeywordsData(token, id, 0, orderBy, order, show, filterCriterion, [searchKeyword]);
  }

  return (
    <Box
      sx={{
        width: "100%",
        pl: 2,
        pr: 2,
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography style={{ paddingBottom: "2%" }} variant="subtitle2">
            Selected Keywords:
            {selected.length > 0 &&
            selected.filter((row) => row != undefined || row.selected).length >
              0 ? (
              <span className="keywordCount">
                {selected.filter((row) => row.selected).length}
              </span>
            ) : (
              ""
            )}
          </Typography>
        </Grid>
        <Grid
          item
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Typography variant="subtitle2">Show: </Typography>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <Select
              autoWidth
              value={show}
              onChange={handleShowSort}
              inputProps={{ "aria-label": "Without label" }}
              sx={{
                fontSize: "0.875rem",
                color: "#F58120",
                boxShadow: "none",
                ".MuiOutlinedInput-notchedOutline": { borderStyle: "none" },
              }}
            >
              <MenuItem value={"All"}>All</MenuItem>
              <MenuItem value={"Selected"}>Selected</MenuItem>
              <MenuItem value={"Unselected"}>Unselected</MenuItem>
            </Select>
          </FormControl>
          <Divider
            orientation="vertical"
            flexItem
            variant="middle"
            sx={{ height: "35px" }}
          />
          <Tooltip
            title={
              filterCriterion.length > 0
                ? `${filterCriterion.length} active filter(s)`
                : "Filters"
            }
          >
            <IconButton
              aria-label="filter"
              size="small"
              onClick={handleOpenDrawer}
            >
              <Badge
                badgeContent={filterCriterion.length}
                color="secondary"
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <FilterListIcon />
              </Badge>
            </IconButton>
          </Tooltip>
          <KeywordFilterSearchBar handleSearchByKeyword={handleSearchByKeyword} searchTerm={searchTerm} handleSearchTerm = {setSearchTerm}/>
        </Grid>
       
      </Grid>
      {selected.length > 0 &&
      selected.filter((row) => row != undefined && row.selected).length > 0 ? (
        <EnhancedTableToolbar
          numSelected={selected.filter((row) => row.selected).length}
          selectedRows={selected}
          handleChipDelete={handleChipDelete}
          rows={keywords}
          disableStructure={disableStructure}
        />
      ) : (
        ""
      )}
      <Box sx={{ width: "100%", mb: 2 }}>
        <DataGrid
          disableColumnMenu={true}
          keepNonExistentRowsSelected
          autoHeight={true}
          rows={rows}
          columns={columns(
            handleRowCheckbox,
            handleSelectAllClick,
            isRowSelected,
            selectedRowCount,
            disableStructure
          )}
          disableColumnFilter
          paginationMode="server"
          disableRowSelectionOnClick
          autosizeOnMount={true}
          columnHeaderHeight={60}
          hideFooter
          loading={loading}
          sortingMode="server"
          onSortModelChange={handleRequestSort}
          initialState={{
            sorting: {
              sortModel: [{ field: 'text', sort: 'asc' }],
            },
          }}
          
          sortModel={sortModel}
          filterMode="server"
          sx={{
            ".MuiDataGrid-columnHeadersInner": {
              backgroundColor: "#F2F6F9",
              color: "#4E4E4E",
              fontWeight: 500,
            },
            color: "#747474",
          }}
          localeText={{ noRowsLabel: "No Data Found" }}
        />

        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mt: 2 }}
        >
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {rows.length == 0 && show == "All" ? (
              ""
            ) : (
              <Pagination
                count={totalPages}
                page={page}
                onChange={handleChangePage}
                showFirstButton
                showLastButton
              />
            )}
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            columnSpacing={2}
          >
            <Grid item>
              <Button
                variant="text"
                sx={{ textTransform: "none" }}
                onClick={() => handleSaveKeywords(selected)}
                disabled={loading || disableStructure || keywords.length == 0}
              >
                <SaveOutlined sx={{ color: "#F58120", paddingRight: "2px" }} />
                Save
              </Button>
            </Grid>
            <Grid item>
              <JsOrangeContainedBtn
                style={{ padding: "3% 5%", fontSize: "0.875rem" }}
                onClick={() => handleNextButton()}
                disabled={
                  loading == true ||
                  selected.filter((row) => row.selected).length == 0
                }
              >
                Next
              </JsOrangeContainedBtn>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={() => handleCloseDrawer()}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}
      >
        <Box sx={{ width: 550 }}>
          <KeywordFilterDrawer
            handleCloseIcon={handleCloseDrawer}
            handleApplyFilters={handleApplyFilters}
            filterCriterion={filterCriterion}
            handleFilterCriterion={setFilterCriterion}
            maxRanges={maxRanges}
            currencySymbol={currencySymbol}
            handleResetAll = {handleResetAll}
          />
        </Box>
      </Drawer>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={error ? "error" : "success"}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarMsg}
        </Alert>
      </Snackbar>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  fetchKeywordsReducer: state.fetchKeywordsReducer,
  UpdateKeywordsReducer: state.UpdateKeywordsReducer,
});

const mapDispatchToProps = (dispatch) => ({
  fetchKeywords: (token, id, pageNo, orderBy, order, show, filterCriterion,searchTerm) =>
    dispatch(fetchKeywords(token, id, pageNo, orderBy, order, show, filterCriterion,searchTerm)),
  updateKeywords: (token, keywords) =>
    dispatch(updateKeywords(token, keywords)),
});

export default connect(mapStateToProps, mapDispatchToProps)(KeywordsWithFilter);
