import React, { Fragment, useState } from "react";
import * as _ from "lodash";
import {
  CartesianGrid,
  LineChart,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import {
  Grid,
  Typography,
  Box,
  Button,
  Paper,
  Tooltip as tooltip,
} from "@mui/material";

import withStyles from "@mui/styles/withStyles";

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="xl" />;

const styles = (theme) => ({
  paper: {
    [theme.breakpoints.up("md")]: {
      width: "auto",
      height: "150px",
    },
    [theme.breakpoints.down("xl")]: {
      width: "auto",
      height: "150px",
    },
  },
  number: {
    font: "lato",
    fontWeight: "800",
    fontSize: "24px",
    color: "#FF8735",
    marginLeft: "10px",
  },
  prevNumber: {
    font: "lato",
    fontWeight: "400",
    fontSize: "24px",
    color: "#949EAB",
  },
  section: {
    font: "lato",
    fontWeight: "400",
    fontSize: "16px",
    color: "#FF8735",
    marginLeft: "10px",
  },
  lineGraphTooltipDiv: {
    width: "200px",
    height: "120px",
    paddingLeft: "10px",
    border: "1px solid #C4C4C4",
    borderRadius: "5px",
    padding: "0px",
    background: "white",
  },
  lineGraphTooltipDivPara1: {
    color: "#74839C",
    // fontWeight:"bold",
    marginTop: "0px",
    padding: "0px",
    marginBotton: "0px",
  },
  lineGraphTooltipDivPara2: {
    padding: "0px",
    // fontWeight:"bold",
    color: "#F58120",
    marginTop: "0px",
    marginBotton: "0px",
  },
  lineGraphToolTip: {
    color: "#949EAB",
  },
});

function LineGraph(props) {
  const classes = props.classes;
  const {
    Gdata,
    currValue,
    prevValue,
    kpiNumber,
    prevKpiNumber,
    kpiName,
    prevKpiName,
    totalValue,
    prevTotalValue,
    compareSwitch,
  } = props;

  // For Hover => On hovering we are getting the value number values of Transactionsa and Sessions.
  const [show, setShow] = useState(false);
  const handleClick = () => {
    if (show) {
      setShow(false);
    } else {
      setShow(true);
    }
  };

  // for showing the first letter as Capital in Line Graph tooltip
  let capital1 = _.startCase(currValue);
  let capital2 = _.startCase(prevValue);

  const lineGraphToolTip = ({ active, payload, label }) => {
    console.log("lineGraphToolTip props--->", props.currValue);

    if (
      active &&
      label &&
      payload &&
      payload.length &&
      props.currValue === "currSession"
    ) {
      return (
        <div style={{ margin: "0px" }} className={classes.lineGraphTooltipDiv}>
          <span className={classes.lineGraphTooltipDivPara2}>
            Curr. Date: {payload[0].payload.currDay.toLocaleString("en-US")}
          </span>
          <br />
          <span className={classes.lineGraphTooltipDivPara2}>
            Curr. Sessions:{" "}
            {payload[0].payload.currSession.toLocaleString("en-US")} &nbsp;
          </span>
          {compareSwitch ? (
            <div style={{ margin: "0px" }}>
              <br />
              <span className={classes.lineGraphTooltipDivPara1}>
                Prev. Date: {payload[0].payload.prevDay.toLocaleString("en-US")}
              </span>
              <br />
              <span className={classes.lineGraphTooltipDivPara1}>
                Prev. Sessions:{" "}
                {payload[0].payload.prevSession.toLocaleString("en-US")}
              </span>
            </div>
          ) : null}
        </div>
      );
    } else if (
      active &&
      label &&
      payload &&
      payload.length &&
      props.currValue === "currTransaction"
    ) {
      return (
        <div style={{ margin: "0px" }} className={classes.lineGraphTooltipDiv}>
          <span className={classes.lineGraphTooltipDivPara2}>
            Curr. Date: {payload[0].payload.currDay.toLocaleString("en-US")}
          </span>
          <br />
          <span className={classes.lineGraphTooltipDivPara2}>
            Curr. Transactions:{" "}
            {payload[0].payload.currTransaction.toLocaleString("en-US")} &nbsp;
          </span>
          {compareSwitch ? (
            <div style={{ margin: "0px" }}>
              <br />
              <span className={classes.lineGraphTooltipDivPara1}>
                Prev. Date: {payload[0].payload.prevDay.toLocaleString("en-US")}
              </span>
              <br />
              <span className={classes.lineGraphTooltipDivPara1}>
                Prev. Transactions:{" "}
                {payload[0].payload.prevTransaction.toLocaleString("en-US")}
              </span>
            </div>
          ) : null}
        </div>
      );
    }
    return null;
  };

  return (
    <div>
      <div>
        <Paper className={classes.paper}>
          <ResponsiveContainer width="100%" height={94}>
            <LineChart
              data={Gdata}
              margin={{ top: 30, right: 20, left: 20, bottom: 0 }}
            >
              <Tooltip
                wrapperStyle={{ top: -100, left: 50 }}
                cursor={false}
                content={(props) => lineGraphToolTip(props)}
              />
              <Line
                type="monotone"
                strokeWidth={2}
                dataKey={currValue}
                stroke="#FF8735"
                dot={false}
              />
              {compareSwitch ? (
                <Line
                  type="monotone"
                  strokeWidth={2}
                  dataKey={prevValue}
                  stroke="#949EAB"
                  dot={false}
                />
              ) : null}
            </LineChart>
          </ResponsiveContainer>
          <Grid container>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <tooltip
                title={totalValue}
                placement="right"
                onClick={handleClick}
              >
                <span
                  className={classes.number}
                  onMouseEnter={() => setShow(true)}
                  onMouseLeave={() => setShow(false)}
                >
                  {kpiNumber}
                </span>
              </tooltip>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              {compareSwitch ? (
                <tooltip
                  title={prevTotalValue}
                  placement="right"
                  onClick={handleClick}
                >
                  {prevKpiName == "Prev. Sessions" ? (
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "right",
                        marginRight: "7px",
                      }}
                      className={classes.prevNumber}
                      onMouseEnter={() => setShow(true)}
                      onMouseLeave={() => setShow(false)}
                    >
                      {prevKpiNumber}
                    </span>
                  ) : (
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "right",
                        marginRight: "7px",
                      }}
                      className={classes.prevNumber}
                      onMouseEnter={() => setShow(true)}
                      onMouseLeave={() => setShow(false)}
                    >
                      {prevKpiNumber}
                    </span>
                  )}
                </tooltip>
              ) : null}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Typography className={classes.section}> {kpiName}</Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              {compareSwitch ? (
                <Typography
                  style={{
                    color: "#949EAB",
                    display: "flex",
                    justifyContent: "right",
                    marginRight: "7px",
                  }}
                >
                  {prevKpiName == "Prev. Sessions" ? (
                    <span>{prevKpiName}</span>
                  ) : (
                    <span>{prevKpiName}</span>
                  )}
                </Typography>
              ) : null}
            </Grid>
          </Grid>
        </Paper>
      </div>
    </div>
  );
}
export default withWidth()(withStyles(styles, { withTheme: true })(LineGraph));
