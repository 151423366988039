import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  SnackbarContent,
  Grid,
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Dialog,
  FormGroup,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import icons from "../../components/icons";
import { connect } from "react-redux";
import * as types from "../../actions/actionTypes";
import { updateContractSigned } from "../../actions/contractSignedActions";
import MySnackbarWrapper from "../snackbars";
import Typography from "@mui/material/Typography";
import { CircularProgress } from "@mui/material/index";
import { PopUpCommonBtn } from "../../components/admin/templates";
import { PopUpOrangeBtn } from "../task";

const CustomSnackbarContent = withStyles((theme) => ({
  root: {
    maxWidth: "100%",
    margin: theme.spacing(1),
    justifyContent: "center",
    background: "#060C14",
    color: theme.palette.conversionAlertText,
    fontWeight: "bold",
  },
}))(SnackbarContent);
const styles = (theme) => ({
  icon: {
    marginRight: theme.spacing(1),
    display: "flex",
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
  buttonProgress: {
    // color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  pb1: {
    paddingBottom: 5,
  },
  /* listType: {
         "&::before": {
             content: "counter(item) '.'",
             display: "inline-block",
             fontWeight: "bold",
             paddingRight: ".5rem",
             textAlign: "right",
             width: "1.5rem",
         }
     }*/
});

class ContractSign extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      scroll: "paper",
      statusAlert: false,
      variantType: "info",
      alertMessage: "",
      showBtn: false,
      checkedA: false,
    };
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  handleCloseAlert = () => {
    this.setState({ statusAlert: false, executedLength: 0, isSaving: false });
  };
  handleAccept = async () => {
    this.setState({ open: true, isLoading: true });
    let response = await this.props.updateContractSigned();
    if (response && response.isContractSigned == false) {
      this.setState({
        open: false,
        statusAlert: true,
        variantType: "success",
        alertMessage: "Thanks for Accepting terms of service",
        isLoading: false,
      });
    } else {
      this.setState({
        open: false,
        statusAlert: true,
        variantType: "error",
        alertMessage: "Error in updating Contract Signing",
        isLoading: false,
      });
    }
  };

  handleChange = (name) => (event) => {
    console.log(":event.target.checked", event.target.checked);
    this.setState({ [name]: event.target.checked });
  };

  render() {
    const { classes, icon, message, theme } = this.props;
    const { statusAlert, variantType, alertMessage, isLoading, showBtn } =
      this.state;
    const handleScroll = (e) => {
      const bottom =
        e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
      if (bottom) this.setState({ showBtn: true });
    };
    console.log("showBtn", showBtn);
    console.log("showBtn1", !showBtn && !this.state.checkedA);

    return (
      <Grid container>
        <Grid item md={12}>
          {/* removing the terms and services banner which is 2021 */}
          {/* <CustomSnackbarContent
            message={
              <Grid container>
                <Grid item md={12} sm={12} xs={12}>
                  <Typography style={{ color: "#fff" }}>
                    <b>Terms of Service Update:</b> A change in our Terms of
                    Service takes effect on May 1, 2021. Please{" "}
                    <span
                      onClick={() => this.handleClickOpen()}
                      style={{ cursor: "pointer", textDecoration: "underline" }}
                    >
                      review here
                    </span>
                  </Typography>
                </Grid>
              </Grid>
            }
          /> */}
        </Grid>
        <Grid item>
          <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            scroll={"paper"}
            aria-labelledby="scroll-dialog-title"
            maxWidth={"md"}
          >
            <DialogTitle>
              <Typography color={"textPrimary"} variant={"h5"}>
                <b>LXRGuide Services & License Agreement</b>
              </Typography>
              <Typography color={"textPrimary"} variant={"body1"}>
                We have updated LXRGuide terms and conditions to improve
                readability and transparency. Please scroll down to read and
                agree the updated terms and conditions.
              </Typography>
            </DialogTitle>
            <DialogContent onScroll={handleScroll}>
              <DialogContentText id={"terms"}>
                <Typography gutterBottom color={"textPrimary"} variant={"body"}>
                  <b>IMPORTANT: READ CAREFULLY</b>
                </Typography>
                <Typography gutterBottom color={"textPrimary"} variant={"body"}>
                  This Services and License Agreement (hereinafter “Agreement”)
                  is a legal contract between You and NetElixir, Inc.
                  (“NetElixir”). This Agreement contains the terms of Your use
                  of NetElixir’s LXRGuide to manage your Google Ads and/or
                  Microsoft Ads account, and the Services NetElixir will provide
                  in connection with Your use.
                </Typography>
                <Typography gutterBottom color={"textPrimary"} variant={"body"}>
                  <b>
                    BY CLICKING THE “ACCEPT” BUTTON BELOW, YOU AGREE TO BE BOUND
                    BY EACH TERM OF THIS AGREEMENT, INCLUDING ITS PAYMENT TERMS.
                  </b>
                </Typography>
                <ol type="1" style={{ color: theme.palette.text.primary }}>
                  <li className="p-3">
                    <b className={classes.pb1}>DEFINITIONS.</b>
                    <ol type="a" className="pt-3">
                      <li>
                        <Typography
                          gutterBottom
                          color={"textPrimary"}
                          variant={"body"}
                        >
                          “LXRGuide” means NetElixir’s proprietary paid search
                          management solutions tool to help small and mid-sized
                          businesses to manage and grow their Google Ads and/or
                          Microsoft Ads campaigns. As used herein, “LXRGuide”
                          also includes any associated printed, online, or
                          electronic material or document, including manuals,
                          dashboards, pages, interfaces, algorithms,
                          corrections, bug fixes, enhancements, updates,
                          upgrades, modifications, or amendments, that are
                          generated, made, or supplied by NetElixir.
                        </Typography>
                      </li>
                      <li>
                        <Typography
                          gutterBottom
                          color={"textPrimary"}
                          variant={"body"}
                        >
                          “Notice” means the communication that NetElixir sends
                          to You about Your LXRGuide account through email
                          and/or by posting it under the “Notifications” on the
                          dashboard of Your LXRGuide account.
                        </Typography>
                      </li>
                      <li>
                        <Typography
                          gutterBottom
                          color={"textPrimary"}
                          variant={"body"}
                        >
                          “Service” or “Services” means the personalized service
                          that NetElixir will provide with Your LXRGuide
                          account.
                        </Typography>
                      </li>
                      <li>
                        <Typography
                          gutterBottom
                          color={"textPrimary"}
                          variant={"body"}
                        >
                          “You” or “Your” means the individual or business that
                          has subscribed to LXRGuide by entering this Agreement.
                        </Typography>
                      </li>
                    </ol>
                  </li>
                  <li className="p-3">
                    <b className={classes.pb1}>
                      LICENSE TERMS AND PROPRIETARY RIGHTS.
                    </b>
                    <ol type="a" className="pt-3">
                      <li>
                        <Typography
                          gutterBottom
                          color={"textPrimary"}
                          variant={"body"}
                        >
                          To use the LXRGuide, You must complete an online
                          registration form and thereafter, update its
                          information to keep it current. Your registration
                          information will be used to provide You with access to
                          LXRGuide and its Services, and will not be shared with
                          third parties. You may obtain the complete NetElixir
                          Privacy Policy by clicking here [add button to access
                          Privacy Policy].
                        </Typography>
                      </li>
                      <li>
                        <Typography
                          gutterBottom
                          color={"textPrimary"}
                          variant={"body"}
                        >
                          Subject to Your compliance with this Agreement,
                          NetElixir hereby grants You a non-exclusive, non-
                          transferable license (hereinafter “License”) to use
                          LXRGuide for Your own business purposes. LXRGuide is
                          provided to You on a subscription basis only.
                        </Typography>
                      </li>
                      <li>
                        <Typography
                          gutterBottom
                          color={"textPrimary"}
                          variant={"body"}
                        >
                          NetElixir owns all proprietary rights in LXRGuide,
                          including patent, copyright, trade secret, trademark,
                          and other proprietary rights.
                        </Typography>
                      </li>
                      <li>
                        <Typography
                          gutterBottom
                          color={"textPrimary"}
                          variant={"body"}
                        >
                          You have no title to LXRGuide, and under no
                          circumstance may You sell, license, assign, publish,
                          display, distribute, or otherwise transfer to anyone,
                          LXRGuide, or any copy thereof, in whole or in part,
                          without NetElixir’s prior written consent. You may
                          also not assign this Agreement without NetElixir’s
                          prior written consent.
                        </Typography>
                      </li>
                      <li>
                        <Typography
                          gutterBottom
                          color={"textPrimary"}
                          variant={"body"}
                        >
                          Only NetElixir has the right to alter, maintain,
                          enhance or otherwise modify LXRGuide, and NetElixir is
                          under no obligation to notify You of any such change.
                          You shall not disassemble, decompile or reverse
                          engineer LXRGuide, nor shall You permit or assist any
                          third party to do so.
                        </Typography>
                      </li>
                      <li>
                        <Typography
                          gutterBottom
                          color={"textPrimary"}
                          variant={"body"}
                        >
                          NetElixir reserves any and all rights not expressly
                          and explicitly granted in this Agreement, including,
                          but not limited to, the right to license LXRGuide or
                          assign this Agreement, to any third party.
                        </Typography>
                      </li>
                    </ol>
                  </li>
                  <li className="p-3">
                    <b className={classes.pb1}>NETELIXIR SUPPORT.</b>
                    <ol type="a" className="pt-3">
                      <li>
                        <Typography
                          gutterBottom
                          color={"textPrimary"}
                          variant={"body"}
                        >
                          To help You use LXRGUIDE, NetElixir will provide the
                          following support: (i) initial product training and
                          on-boarding support; (ii) ongoing email support; and
                          (iii) up to one growth strategy meeting per month with
                          Your Customer Success Manager.
                        </Typography>
                      </li>
                      <li>
                        <Typography
                          gutterBottom
                          color={"textPrimary"}
                          variant={"body"}
                        >
                          NetElixir may also, in its sole discretion, utilize
                          its expertise to make occasional adjustments to Your
                          advertising campaign that it deems will best increase
                          traffic to Your website. Such adjustments may involve
                          using paid searches through third-party advertising
                          platforms such as Google Ads or Microsoft Ads. You
                          acknowledge and agree that You will be solely
                          responsible for all additional fees and costs
                          associated with the use of such third-party platforms.
                        </Typography>
                      </li>
                      <li>
                        <Typography
                          gutterBottom
                          color={"textPrimary"}
                          variant={"body"}
                        >
                          NetElixir will provide You additional, personalized
                          support services based on the type of Service You
                          select from the “On-Demand Service.”
                        </Typography>
                      </li>
                    </ol>
                  </li>
                  <li className="p-3">
                    <b className={classes.pb1}>YOUR RESPONSIBILITIES.</b>
                    <ol type="a" className="pt-3">
                      <li>
                        <Typography
                          gutterBottom
                          color={"textPrimary"}
                          variant={"body"}
                        >
                          By entering this Agreement and completing the
                          registration for LXRGuide, You represent and warrant
                          that You are over 18 years of age, and are duly
                          authorized to enter into this Agreement for and on
                          behalf of the person, company, or other entity that
                          You, as the subscriber, identified in the registration
                          process for LXRGuide. You further represent and
                          warrant that all information contained in Your
                          registration is accurate. Should any representation
                          prove incorrect, at any time, in addition to any other
                          remedy it may seek, NetElixir may, in its sole
                          discretion, immediately disable Your use of LXRGuide
                          and cease its Services without notice, and without
                          recourse by You.
                        </Typography>
                      </li>
                      <li>
                        <Typography
                          gutterBottom
                          color={"textPrimary"}
                          variant={"body"}
                        >
                          You must maintain an active Google Ads and/or
                          Microsoft Ads account. All terms of use of Google Ads
                          and Microsoft Ads is strictly between You and those
                          entities. NetElixir is not responsible for any aspect
                          of Your Google Ads and/or Microsoft Ads account,
                          including any fee or cost.
                        </Typography>
                      </li>
                      <li>
                        <Typography
                          gutterBottom
                          color={"textPrimary"}
                          variant={"body"}
                        >
                          You agree that NetElixir has complete and unfettered
                          access to the data and analytics gathered and/or
                          generated by Your Google Ads and/or Microsoft Ads
                          accounts. You further agree that NetElixir may use or
                          transfer anonymized versions of the data and analytics
                          for its business and/or for research purposes.
                        </Typography>
                      </li>
                      <li>
                        <Typography
                          gutterBottom
                          color={"textPrimary"}
                          variant={"body"}
                        >
                          You are solely responsible for all aspects of Your
                          website, including its development, operation, and
                          maintenance. You must conform with all applicable laws
                          governing the website and its contents. You agree that
                          NetElixir has no liability or responsibility for any
                          aspect of Your Website or its contents.
                        </Typography>
                      </li>
                      <li>
                        <Typography
                          gutterBottom
                          color={"textPrimary"}
                          variant={"body"}
                        >
                          You agree to promptly respond to NetElixir’s request
                          for comment and approval for its suggested ad
                          campaigns. You agree that Your failure to do so will
                          not alter any term of this Agreement, nor will it
                          extend or modify the scope of any Service. For
                          example, if You have enrolled in a 30-day JumpStart
                          Service, but fail to timely communicate with NetElixir
                          about Your ad campaign, or fail to respond to
                          NetElixir’s proposed changes to Your ad campaign,
                          NetElixir will, at the end of the 30-day JumpStart
                          period, upload its proposed ad campaign and deem
                          JumpStart concluded. Thereafter, Your fee-based
                          subscription of LXRGuide will commence and You will be
                          charged monthly in accordance with Section 5 of the
                          LXRGuide Services and License Agreement.
                        </Typography>
                      </li>
                    </ol>
                  </li>
                  <li className="p-3">
                    <b className={classes.pb1}>
                      PAYMENT TERMS FOR PAID SUBSCRIPTIONS &amp; FREE TRIALS{" "}
                    </b>
                    (if available).
                    <ol type="a" className="pt-3">
                      <li>
                        <Typography
                          gutterBottom
                          color={"textPrimary"}
                          variant={"body"}
                        >
                          Use of LXRGuide during a free trial period does not
                          require the payment of a subscription fee. NetElixir
                          reserves the right, in its sole and absolute
                          discretion, to restrict or limit the features of free
                          or trial versions of LXRGuide, and of the number of
                          free or trial versions that may be used by any
                          individual, entity, or group of entities, at any time.
                        </Typography>
                      </li>
                      <li>
                        <Typography
                          gutterBottom
                          color={"textPrimary"}
                          variant={"body"}
                        >
                          If You elect to obtain or convert to, a paid
                          subscription of LXRGuide by choosing any fee-based
                          Service, You must provide NetElixir with Your current
                          and valid subscriber and credit card information. In
                          that event, You agree to pay to NetElixir the
                          subscription fee for LXRGuide, including any
                          associated On-Demand Service package that You select.
                          That fee is due and payable monthly in advance,
                          commencing from the date of Your subscription, and,
                          thereafter, on the monthly anniversary of such date.
                          You may pay the owed fees either by an on-line
                          payment, or by pre- authorizing NetElixir to charge
                          your credit card. If the computation of the fee for
                          Your selected subscription package is stated in terms
                          of the greater of a stated monthly amount or a
                          percentage of your advertising spend, any fee owed in
                          excess of the monthly base fee will be charged to You
                          in the next (succeeding) month.
                        </Typography>
                      </li>
                      <li>
                        <Typography
                          gutterBottom
                          color={"textPrimary"}
                          variant={"body"}
                        >
                          Payment of all fees is payable to NetElixir when due,
                          without demand, invoicing, or Notice.
                        </Typography>
                      </li>
                      <li>
                        <Typography
                          gutterBottom
                          color={"textPrimary"}
                          variant={"body"}
                        >
                          Subscription fees are subject to change by NetElixir
                          in its sole discretion, at any time, upon notice to
                          You.
                        </Typography>
                      </li>
                      <li>
                        <Typography
                          gutterBottom
                          color={"textPrimary"}
                          variant={"body"}
                        >
                          Sales promotions and other special discounted pricing
                          offers are temporary and may expire upon renewal of
                          Your subscription. NetElixir reserves the right to
                          discontinue or modify any promotional offer in its
                          sole discretion at any time.
                        </Typography>
                      </li>
                      <li>
                        <Typography
                          gutterBottom
                          color={"textPrimary"}
                          variant={"body"}
                        >
                          Your fee-based subscription will renew automatically
                          unless terminated in accordance with Section 6. Free,
                          trial, or discounted subscriptions will terminate
                          automatically in accordance with their terms or as
                          terminated earlier in accordance with Section 6.
                        </Typography>
                      </li>
                    </ol>
                  </li>
                  <li className="p-3">
                    <b className={classes.pb1}>TERMINATION.</b>
                    <ol type="a" className="pt-3">
                      <li>
                        <Typography
                          gutterBottom
                          color={"textPrimary"}
                          variant={"body"}
                        >
                          Either party may cancel this Agreement upon written
                          notice to the other at any time. You may provide
                          notice of cancellation by clicking the “Unsubscribe”
                          button on Your LXRGuide Profile. NetElixir may advise
                          You of its cancellation upon Notice as provided in
                          Section 1(d).
                        </Typography>
                      </li>
                      <li>
                        <Typography
                          gutterBottom
                          color={"textPrimary"}
                          variant={"body"}
                        >
                          All cancellations will be deemed effective either on
                          the date of the notice of cancellation, or on the last
                          day of the month in which the cancellation notice was
                          issued.
                        </Typography>
                      </li>
                      <li>
                        <Typography
                          gutterBottom
                          color={"textPrimary"}
                          variant={"body"}
                        >
                          Without prejudice to any of its other rights,
                          NetElixir may terminate this Agreement immediately if
                          You fail to comply with any term or condition of this
                          Agreement. In such event, NetElixir may deny access to
                          LXRGuide and cease any accompanying Service.
                        </Typography>
                      </li>
                      <li>
                        <Typography
                          gutterBottom
                          color={"textPrimary"}
                          variant={"body"}
                        >
                          In no event shall any termination, irrespective of
                          reason, relieve You of Your obligation to pay any fee
                          or cost that was due for any period prior to the
                          effective date of termination.
                        </Typography>
                      </li>
                    </ol>
                  </li>
                  <li className="p-3">
                    <b className={classes.pb1}>
                      DISCLAIMER OF WARRANTIES &amp; LIMITATION OF LIABILITY.
                    </b>
                    <ol type="a" className="pt-3">
                      <li>
                        <Typography
                          gutterBottom
                          color={"textPrimary"}
                          variant={"body"}
                        >
                          YOU ACKNOWLEDGE AND AGREE THAT NETELIXIR’S SERVICES
                          AND LXRGUIDE ARE PROVIDED ON AN &quot;AS IS&quot;
                          BASIS. YOU HAVE ENTERED INTO THIS AGREEMENT WITH DUE
                          REGARD FOR THE BUSINESS RISK ASSOCIATED WITH USE OF
                          THE SERVICES AND LXRGUIDE. NETELIXIR DOES NOT WARRANT
                          THAT THE SERVICES, LXRGUIDE, OR ANY AD CAMPAIGN WILL
                          ACHIEVE ANY PARTICULAR RESULT. SUBJECT TO APPLICABLE
                          LAW, NETELIXIR DISCLAIMS ALL WARRANTIES, EXPRESS AND
                          IMPLIED, INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED
                          WARRANTIES OF MERCHANTABILITY, SUITABILITY OR FITNESS
                          FOR A PARTICULAR PURPOSE. NO ORAL OR WRITTEN
                          INFORMATION OR ADVICE GIVEN BY NETELIXIR, ITS AGENTS
                          OR EMPLOYEES SHALL IN ANY WAY REDUCE THE SCOPE OF THIS
                          WARRANTY.
                        </Typography>
                      </li>
                      <li>
                        <Typography
                          gutterBottom
                          color={"textPrimary"}
                          variant={"body"}
                        >
                          SUBJECT TO APPLICABLE LAW, IN NO EVENT WILL NETELIXIR
                          BE LIABLE TO YOU OR ANY THIRD PARTY FOR INDIRECT,
                          SPECIAL, INCIDENTAL, CONSEQUENTIAL, PUNITIVE,
                          EXEMPLARY OR OTHER DAMAGES, INCLUDING LOST PROFITS,
                          ARISING FROM: ITS SERVICES; FROM YOUR USE OF, OR
                          INABILITY TO USE, LXRGUIDE; FOR ANY DATA BREACH; OR
                          FROM ANY BREACH OF WARRANTY, EVEN IF NETELIXIR HAS
                          BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. YOU
                          EXPRESSLY AGREE THAT YOUR USE OF LXRGUIDE AND
                          NETELIXIR’S SERVICES IS AT YOUR OWN RISK, AND THAT
                          NETELIXIR’S MAXIMUM LIABILITY FOR ALL DAMAGES, LOSSES,
                          FEES (INCLUDING ATTORNEYS’ FEES), OR COSTS OF ANY KIND
                          OR NATURE (WHETHER IN CONTRACT, TORT OR OTHERWISE), IS
                          LIMITED TO THE AMOUNT YOU PAID TO NETELIXIR DURING THE
                          30 DAYS IMMEDIATELY BEFORE THE CLAIM AROSE.
                        </Typography>
                      </li>
                    </ol>
                  </li>
                  <li className="p-3">
                    <b className={classes.pb1}>GENERAL PROVISIONS.</b>
                    <ol type="a" className="pt-3">
                      <li>
                        <Typography
                          gutterBottom
                          color={"textPrimary"}
                          variant={"body"}
                        >
                          NetElixir may amend, alter, or revise the terms of
                          this Agreement at any time by: (i) posting a revised
                          Agreement onto the LXRGuide Terms of Service page; and
                          (ii) sending information regarding the Agreement’s
                          amendment through Notice as provide in Section 1(d).
                          Said amendments, alterations, or revisions, shall
                          become effective immediately upon said posting and/or
                          sending. You are responsible for (i) regularly review
                          Your Notifications that are visible on the LXRGuide
                          dashboard and (ii) regularly review Your email, to
                          obtain timely notice of such amendments. If You do not
                          agree to accept the changes, Your sole remedy is to
                          terminate this Agreement in accordance with the
                          Termination provisions of Section 6.
                        </Typography>
                      </li>
                      <li>
                        <Typography
                          gutterBottom
                          color={"textPrimary"}
                          variant={"body"}
                        >
                          Governing Law/Venue/Jurisdiction/Service of Process.
                          This Agreement shall be governed by and construed
                          under the laws of the State of New Jersey, without
                          regard to its choice of law provisions. The
                          application of the United Nations Convention on
                          Contracts for the International Sale of Goods is
                          expressly excluded. Venue and jurisdiction shall be
                          proper only in the state and federal courts serving
                          Mercer County, New Jersey, and each party hereby
                          consents to such exclusive jurisdiction and venue. You
                          further agree to accept all service of process by
                          email, or by certified mail or express delivery to the
                          address You provided with Your registration.
                        </Typography>
                      </li>
                      <li>
                        <Typography
                          gutterBottom
                          color={"textPrimary"}
                          variant={"body"}
                        >
                          Severability. The provisions of this Agreement are
                          severable, and if any one or more such provisions are
                          determined to be invalid, illegal or unenforceable, in
                          whole or in part, the validity, legality and
                          enforceability of the remaining provisions, shall not
                          in any way be affected, and shall remain binding
                          between the parties. Any such invalid, illegal or
                          unenforceable provision or portion thereof, shall be
                          changed and interpreted so as to best accomplish the
                          objectives of such provision, within the limits of
                          applicable law.
                        </Typography>
                      </li>
                      <li>
                        <Typography
                          gutterBottom
                          color={"textPrimary"}
                          variant={"body"}
                        >
                          Complete Agreement. This Agreement is the complete and
                          exclusive statement of the agreement between the
                          parties. It supersedes and merges all prior proposals,
                          understandings and all other agreements, oral or
                          written, between the parties relating to the subject
                          matter. This Agreement may not be modified except by a
                          written addendum issued by a duly authorized
                          representative of NetElixir in accordance with Section
                          8(a).
                        </Typography>
                      </li>
                      <li>
                        <Typography
                          gutterBottom
                          color={"textPrimary"}
                          variant={"body"}
                        >
                          Any waiver, either expressed or implied, by either
                          party of any default by the other in the observance
                          and performance of any of the conditions and/or
                          covenants or duties set forth herein shall not
                          constitute or be construed as a waiver of any
                          subsequent or other default.
                        </Typography>
                      </li>
                      <li>
                        <Typography
                          gutterBottom
                          color={"textPrimary"}
                          variant={"body"}
                        >
                          Read and Understood. By clicking “Accept”, You hereby
                          acknowledge that You have read this Agreement, and
                          understand each of its terms, and agree to be bound by
                          its terms.
                        </Typography>
                      </li>
                    </ol>
                  </li>
                </ol>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Grid container>
                <Grid item md={12}>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.checkedA}
                          onChange={this.handleChange("checkedA")}
                          value="checkedA"
                        />
                      }
                      label={
                        <Typography style={{ paddingBottom: 2 }}>
                          I agree to the terms of the license agreement
                        </Typography>
                      }
                    />
                  </FormGroup>
                </Grid>
                <Grid item md={12} style={{ padding: "0px 10px" }}>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <div style={{ position: "relative" }}>
                      <PopUpOrangeBtn
                        onClick={this.handleAccept}
                        disabled={!showBtn || !this.state.checkedA}
                        color="primary"
                      >
                        Accept
                      </PopUpOrangeBtn>
                      {isLoading && (
                        <CircularProgress
                          className={classes.buttonProgress}
                          color="secondary"
                          size={24}
                        />
                      )}
                    </div>
                  </div>
                </Grid>
              </Grid>
              {/*  <PopUpCommonBtn onClick={this.handleClose} color="primary">
                                Cancel
                            </PopUpCommonBtn>*/}
            </DialogActions>
          </Dialog>
          <MySnackbarWrapper
            open={statusAlert}
            onClose={this.handleCloseAlert}
            message={
              <Grid container>
                <Grid item md={12} sm={12} xs={12}>
                  <Typography variant={"h3"} style={{ color: "#ffffff" }}>
                    <b>{variantType == "success" ? "Success" : "Failed"}</b>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    // variant={"h3"}
                    style={{ color: "#ffffff" }}
                    variant={"body1"}
                  >
                    {alertMessage}
                  </Typography>
                </Grid>
              </Grid>
            }
            verticalAlign={"bottom"}
            horizontalAlign={"right"}
            duration={6000}
            variant={this.state.variantType}
          />
        </Grid>
      </Grid>
    );
  }
}

ContractSign.propTypes = {};

const mapStateToProps = (state, ownProps) => ({
  isContractSigned: state.perf.isContractSigned,
});
const mapDispatchToProps = (dispatch) => ({
  updateContractSigned: () => {
    return dispatch(updateContractSigned());
  },
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(ContractSign));
