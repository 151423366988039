import React from "react";
import {
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import DateRangeSelector from "../components/dashboard/date-range-selector";
import Chart from "./Chart";
import * as PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  mapDispatchToProps,
  mapStateToProps,
} from "../selectors/dashboard-selectors";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ActivityKPIChart from "./activityReport/activityKPIChart";
import icons from "../components/icons";

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="xl" />;

function KpiMetric(props) {
  return (
    <FormControl className={props.classes.formControl}>
      <Select
        sx={{
          boxShadow: "none",
          ".MuiOutlinedInput-notchedOutline": { border: 0 },
          "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            border: 0,
          },
          "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              border: 0,
            },
        }}
        disableUnderline
        value={props.value}
        onChange={props.onChange}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        IconComponent={ExpandMore}
        MenuProps={{
          anchorOrigin: { vertical: "bottom", horizontal: "bottom" },
          transformOrigin: { vertical: "top", horizontal: "top" },
        }}
      >
        <MenuItem value={"RC"}>
          <b>Revenue / Cost</b>
        </MenuItem>
        <MenuItem value={"CR"}>
          <b>Cost / Revenue</b>
        </MenuItem>
        <MenuItem value={"CPO"}>
          <b>Cost Per Order</b>
        </MenuItem>
        <MenuItem value={"CPL"}>
          <b>Cost Per Lead</b>
        </MenuItem>
      </Select>
    </FormControl>
  );
}

KpiMetric.propTypes = {
  classes: PropTypes.any,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

function IconAndText({ icon, text }) {
  return (
    <Grid container spacing={1}>
      <Grid item>{icon}</Grid>
      <Grid item style={{ textAlign: "left" }}>
        <Typography
          align={"left"}
          variant={"h3"}
          color={"textPrimary"}
          gutterBottom
        >
          <b>{text}</b>
        </Typography>
      </Grid>
    </Grid>
  );
}

function KPIGraph(props) {
  console.log("props kpi type=", props.kpiHistory);
  const {
    width,
    dateRanges,
    kpiTypeUi,
    kpiType,
    acitivityReport,
    navigateToThatWeek,
  } = props;
  return (
    <Paper className={props.className}>
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item>
          <Typography
            align={"left"}
            variant={"h3"}
            color={"textPrimary"}
            gutterBottom
          >
            <b>
              {acitivityReport ? (
                <IconAndText icon={icons.performance} text={"Performance"} />
              ) : (
                "KPI"
              )}
            </b>
          </Typography>
        </Grid>

        {(width === "md" || width === "lg" || width === "xl") &&
        !acitivityReport ? (
          <Grid item md={2}>
            <KpiMetric
              classes={props.classes}
              value={props.kpiTypeUi}
              onChange={(event) =>
                props.onChaneKPIGraphValue(event.target.value)
              }
            />
          </Grid>
        ) : null}
        <Grid className={"growable"} item></Grid>
        {dateRanges ? (
          <Grid item md={8} style={{ textAlign: "right" }}>
            <DateRangeSelector
              value={props.value1}
              onChange={props.onChange1}
              range={props.range}
              customDateRanges={props.customGraphDateRanges}
            />
            {width != "sm" || width != "xs" ? null : (
              <FormHelperText>&nbsp;</FormHelperText>
            )}
          </Grid>
        ) : null}

        {(width === "sm" || width === "xs") && !acitivityReport && (
          <Grid item sm={12} xs={12}>
            <KpiMetric
              classes={props.classes}
              value={props.kpiTypeUi}
              onChange={(event) =>
                props.onChaneKPIGraphValue(event.target.value)
              }
            />
          </Grid>
        )}
      </Grid>
      {acitivityReport ? null : ("")}  
      {acitivityReport ? (
        <ActivityKPIChart
          kpiTypeUi={props.kpiTypeUi}
          isKpiGraphLoading={props.isKpiGraphLoading}
          kpiHistory={props.kpiHistory}
          kpiType={props.kpiType}
          navigateToThatWeek={navigateToThatWeek}
          isLoading={props.loading}
        />
      ) : (
        <Chart
          kpiTypeUi={props.kpiTypeUi}
          kpiHistory={props.kpiHistory}
          kpiType={props.kpiType}
          isLoading={props.loading}
          selectedDateRangeKPIValue={props.selectedDateRangeKPIValue}
        />
      )}
    </Paper>
  );
}

/*KPIGraph.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.any,
    value: PropTypes.number,
    onChange: PropTypes.func,
    value1: PropTypes.any,
    onChange1: PropTypes.any,
    range: PropTypes.any,
    customGraphDateRanges: PropTypes.any,
    kpiHistory: PropTypes.any,
    kpiType: PropTypes.any,
    loading: PropTypes.any,
    selectedDateRange: PropTypes.any
}*/
export default withWidth()(KPIGraph);
