import * as types from "../actions/actionTypes";

export function paymentHistory(state = {
    isLoading: false,
    errorMessage: "",
    transactionHistoryList: [],
}, action) {
    switch (action.type) {
        case types.PAYMENT_DETAILS_RECEIVED:
            let {data} = action;
            console.log("payment reducer", data);
            return Object.assign({}, state, {
                isLoading: false,
                ...action.data,
            });
        case types.PAYMENT_DETAILS_REQUESTED:
            return Object.assign({}, state, {
                isLoading: true,
            });
        case types.PAYMENT_DETAILS_FAILED:
            return Object.assign({}, state, {
                isLoading: false,
                errorMessage: "Something went wrong"
            });
        default:
            return state;
    }
}