import {dateRangeInitialState} from "../components/admin/templates";
import * as types from "../actions/actionTypes";

export function subscriptionsData(state = {
    subscriptions: null,
    isLoading: false,
    error: "",
    isUnsubscribing: false,
}, action) {
    switch (action.type) {
        case types.SUBSCRIPTIONS_REQUESTED:
            return Object.assign({}, state, {
                isLoading: true,
            });
        case types.UN_SUBSCRIPTIONS_REQUESTED:
            return Object.assign({}, state, {
                isUnsubscribing: true,
            });

        case types.SUBSCRIPTIONS_FAILED:
        case types.UN_SUBSCRIPTIONS_FAILED:
            return Object.assign({}, state, {
                isLoading: false,
                isUnsubscribing: false,
                error: "Something wnt wrong in fetching subscriptions"
            });
        case types.SUBSCRIPTIONS_RECEIVED:
            return Object.assign({}, state, {
                ...action.data,
                isLoading: false
            });
        case types.UN_SUBSCRIPTIONS_RECEIVED:
            let subscriptions = state.subscriptions;
            if (action.data.status == false) return
            subscriptions = (subscriptions != null) ? subscriptions.filter(sub => sub.subscriptionId != action.id) : subscriptions;
            console.log("subscriptions======", subscriptions);
            return Object.assign({}, state, {
                isUnsubscribing: false,
                subscriptions
            });
        case types.ON_DEMAND_PAYMENT_RECEIVED:
        case types.PERF_DATA_RECEIVED:
            console.log("action sub===", action)
            return Object.assign({}, state, {
                subscriptions: action.data.subscriptionsData,
            });
        default:
            return state
    }
}
