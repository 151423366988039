import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import withStyles from '@mui/styles/withStyles';
import {
  Button,
  Card,
  Chip,
  CircularProgress,
  Grid,
  LinearProgress,
  RadioGroup,
  Typography,
} from "@mui/material";
import { CompactCardContent, MainSectionCardHeader } from "../components/cards";
import { connect } from "react-redux";
import * as _ from "lodash";
import { sortTasks } from "../reducers/common";
import GroupExpansionPanel from "../components/task/group-expansion-panel";
import { CHANGE_ORGANIZED_BY, TASK_PAGE_TRACK } from "../actions/actionTypes";
import { getTasksByAccount } from "../actions";
import icons from "../components/icons";
import { createHashHistory } from "history";

const WhiteButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    borderRadius: 4,
    // padding: "7px 55px",
    fontWeight: "normal",
    marginTop: theme.spacing(1),
    "&:hover": {
      backgroundColor: theme.palette.common.white,
    },
  },
}))(Button);

const OrangeBtn = withStyles((theme) => ({
  root: {
    backgroundColor: "#F8CAA1",
    fontWeight: "500",
  },
  outlined: {
    border: "0.2px solid #E0E0E0",
  },
}))(Button);
const WhiteBtn = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    fontWeight: "normal",
  },
  outlined: {
    border: "1px solid #E0E0E0",
  },
}))(Button);

function MyRadioButton({ checked, onChange, value, children, style }) {
  // const variant = checked ? 'outlined' : 'contained';
  const Button = checked ? OrangeBtn : WhiteBtn;
  return (
    <Button
      disableRipple
      variant={"outlined"}
      size={"small"}
      style={style}
      onClick={() => onChange({ target: { value } }, true)}
    >
      {children}
    </Button>
  );
}

function OrganizeBy({ value, setValue, theme }) {
  return (
    <Grid
      container
      justifyContent={"flex-end"}
      spacing={2}
      alignItems={"center"}
      style={{ margin: "4px 0px" }}
      id="organizeBy"
    >
      <Grid item>
        <Typography>Organize By:</Typography>
      </Grid>
      <Grid item>
        <RadioGroup
          row={true}
          value={value}
          onChange={(evt) => setValue(evt.target.value)}
        >
          <MyRadioButton value={"TaskType"} style={{ marginRight: 5 }}>
            Task
          </MyRadioButton>
          <MyRadioButton value={"Campaign"} className={"firstLetter"}>
            Campaign
          </MyRadioButton>
        </RadioGroup>
      </Grid>
    </Grid>
  );
}

class TaskPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      organizeBy: "TaskType",
    };
    this.scrollToTaskId = this.props.match.params.id;
    this.selectedPpcId = this.props.match.params.ppcId
      ? this.props.match.params.ppcId
      : null;
    this.selectedAccountId = this.props.match.params.accountId
      ? this.props.match.params.accountId
      : null;
    console.log(":selectedPpcId=", this.selectedPpcId);
    console.log(":selectedAccountId=", this.selectedAccountId);
    this.myrefs = {};
    console.log(this.props);
  }

  componentDidMount() {
    console.log("TaskPage mounted");
    this.initialScroll();
    if (window.hopscotch) {
      const currentStep = window.hopscotch.getCurrStepNum();
      if (currentStep) {
        const currentTour = window.hopscotch.getCurrTour();
        window.hopscotch.startTour(currentTour, currentStep);
      }
    }
    this.props.taskPageTrack();
    this.loadTasksByAccount();
  }

  componentDidUpdate(prevProps) {
    console.log("componentDidUpdate: prevProps = ", prevProps);
    this.initialScroll();
  }

  loadTasksByAccount() {
    const { getTasksByAccount } = this.props;
    console.log(
      "condition check in task page",
      this.selectedAccountId != null && this.selectedPpcId != null
    );
    if (this.selectedAccountId != null && this.selectedPpcId != null)
      getTasksByAccount(this.selectedPpcId, this.selectedAccountId);
  }

  initialScroll = () => {
    this.scrollToTaskId &&
      this.scrollTo(this.scrollToTaskId) &&
      (this.scrollToTaskId = null);
  };

  scrollTo = (taskId) => {
    let ref = this.myrefs[taskId];
    if (ref) {
      ReactDOM.findDOMNode(ref).scrollIntoView({
        block: "start",
        behavior: "instant",
        inline: "start",
      });
      return true;
    }
  };

  render() {
    console.log("Rendering task-page");
    const { groupedTaskData, isAdmin } = this.props;
    let groups = sortTasks(groupedTaskData[this.state.organizeBy]);
    const history = createHashHistory();

    return (
      <Fragment>
        {isAdmin ? (
          <Typography align={"right"}>
            <Button
              style={{ margin: 4 }}
              variant={"outlined"}
              onClick={() => history.goBack()}
            >
              {icons.ChevronArrowLeft}Back
            </Button>
          </Typography>
        ) : null}

        <Card style={{ overflow: "unset" }}>
          <MainSectionCardHeader
            style={{ paddingBottom: 0 }}
            title={"TASKS"}
            subheader={"Optimize your account by performing the tasks below"}
            action={
              <OrganizeBy
                value={this.state.organizeBy}
                setValue={(organizeBy) => {
                  this.props.changeOrganizedBy(organizeBy);
                  this.setState({ organizeBy });
                }}
              />
            }
          ></MainSectionCardHeader>
          <CompactCardContent>
            {_.map(groups, (group) => (
              <GroupExpansionPanel
                organizeBy={this.state.organizeBy}
                data={group}
                organizedBy={this.state.organizeBy}
                key={group.summary.groupId}
                ref={(e) => (this.myrefs[group.summary.groupId] = e)}
                defaultExpanded={
                  this.props.match.params.id === group.summary.groupId
                }
              />
            ))}
          </CompactCardContent>
        </Card>
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  changeOrganizedBy: (organizeBy) =>
    dispatch({ type: CHANGE_ORGANIZED_BY, organizeBy }),
  taskPageTrack: () => dispatch({ type: TASK_PAGE_TRACK }),
  getTasksByAccount: (ppcId, accountId) =>
    dispatch(getTasksByAccount(ppcId, accountId)),
});
const mapStateToProps = (state) => ({
  groupedTaskData: state.groupedTaskData,
  isAdmin: state.taskAnalysis.isAdmin,
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskPage);
