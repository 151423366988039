import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import {
  Alert,
  Button,
  Chip,
  FormControl,
  Grid,
  MenuItem,
  Pagination,
  Select,
  Skeleton,
  Snackbar,
} from "@mui/material";
import "../jumpstart.css";
import { JsOrangeContainedBtn } from "../../../components/buttons";
import SaveOutlined from "@mui/icons-material/SaveOutlined";
import { connect } from "react-redux";
import {
  fetchKeywords,
  updateKeywords,
} from "../../../actions/jumpstartRevamped";

function formatSearchVolumeRange(searchVolume) {
  if (!searchVolume || !Array.isArray(searchVolume) || searchVolume.length === 0) {
    return "NA";
  }

  const minMonthlySearches = Math.min(
    ...searchVolume.map((entry) => entry.monthlySearches)
  );
  const maxMonthlySearches = Math.max(
    ...searchVolume.map((entry) => entry.monthlySearches)
  );

  const formattedMin =
    minMonthlySearches >= 1000
      ? (minMonthlySearches / 1000).toFixed(1) + "K"
      : minMonthlySearches;
  const formattedMax =
    maxMonthlySearches >= 1000
      ? (maxMonthlySearches / 1000).toFixed(1) + "K"
      : maxMonthlySearches;

  return `${formattedMin} - ${formattedMax}`;
}

function createData(
  id,
  keyword,
  searchVolume,
  trend,
  yearOverYeartrend,
  bidLow,
  bidHigh,
  competition
) {
  const searchVolumeRange = formatSearchVolumeRange(searchVolume);

  return {
    id,
    keyword,
    monthlySearchVolume: searchVolumeRange,
    trend,
    yearOverYeartrend,
    bidLow,
    bidHigh,
    competition,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "text",
    numeric: false,
    disablePadding: false,
    label: "Keyword",
  },
  {
    id: "searchVolume",
    numeric: false,
    disablePadding: false,
    label: "Avg. Monthly Search Volume",
  },
  {
    id: "ninetyDaysSearchVolumeTrend",
    numeric: false,
    disablePadding: false,
    label: "90 Days Trend",
  },
  {
    id: "yearOverYearSearchVolumeTrend",
    numeric: false,
    disablePadding: false,
    label: "Y-o-Y Trend",
  },
  {
    id: "lowTopOfPageBid",
    numeric: true,
    disablePadding: false,
    label: "Bid Low",
  },
  {
    id: "highTopOfPageBid",
    numeric: true,
    disablePadding: false,
    label: "Bid High",
  },
  {
    id: "competition",
    numeric: false,
    disablePadding: false,
    label: "Competition",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    disableStructure,
    visibleRowsSelCount,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead sx={{ backgroundColor: "#F2F6F9" }}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="secondary"
            checked={rowCount > 0 && visibleRowsSelCount == rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all keywords",
            }}
            size="small"
            disabled={disableStructure}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <>
            {headCell.label == "90 Days Trend" ||
            headCell.label == "Y-O-Y Trend" ? (
              <TableCell>{headCell.label}</TableCell>
            ) : (
              <TableCell
                key={headCell.id}
                align={"left"}
                padding={headCell.disablePadding ? "none" : "normal"}
                sortDirection={orderBy == headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                  disabled={disableStructure}
                  sx={{
                    "& .MuiTableSortLabel-icon": {
                      opacity: 1,
                      color: "#CCCFD1",
                    },
                  }}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            )}
          </>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected, classes, selectedRows, handleChipDelete, rows } = props;
  return (
    <Toolbar
      sx={{
        p: { xs: 1, sm: 2 },
        maxHeight: "107px", // Set your desired fixed height here
        overflowY: "auto", // Add a vertical scrollbar when content overflows
        display: "grid",
        flexDirection: "column",
        justifyContent: "flex-start",
        ...(numSelected > 0 && {
          bgcolor: "#F8F9F9",
        }),
      }}
    >
      {numSelected > 0 ? (
        <div className="chips">
          {selectedRows.map(
            (row, index) =>
              row.selected && (
                <Chip
                  key={`${row.id}-${index}`}
                  label={row.text}
                  className="chip"
                  onDelete={() => handleChipDelete(row)}
                />
              )
          )}
        </div>
      ) : (
        ""
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function Keywords(props) {
  const {
    handleKeywordsNextButton,
    fetchKeywords,
    updateKeywords,
    fetchKeywordsReducer,
    updateKeywordsReducer,
    token,
    id,
    newSelected,
    handleNewSelected,
    setSelectedKeywordsForMatchType,
    // fetchUserInfoReducer,
    disableStructure,
  } = props;

  const [keywords, setKeywords] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(1);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [totalSize, setTotalSize] = useState(0);
  const [lastPage, setLastPage] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [show, setShow] = useState("All");

  //Snackbar
  const [snackbarMsg, setSnackbarMsg] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [allSelectCheck, setAllSelectCheck] = useState(false);

  const fetchKeywordsData = async (
    token,
    id,
    pageNo,
    sortBy,
    sortDir,
    show
  ) => {
    if (sortBy == undefined) {
      sortBy = "id";
    }
    if (sortDir == undefined) {
      sortDir = "asc";
    }
    setLoading(true);
    const data = await fetchKeywords(token, id, pageNo, sortBy, sortDir, show);
    if (data && data.status == 200) {
      if (data.content.length > 0) {
        const finalData = Object.values(data.content);
        setKeywords([...finalData]);
        setSelected([...Object.values(data.selectedKeywords)]);
      } else {
        setKeywords([]);
        setSelected(
          data.selectedKeywords.length > 0
            ? [...Object.values(data.selectedKeywords)]
            : []
        );
      }
    } else {
      setError("No data found");
    }
    setLoading(false);
  };

  useEffect(() => {
    const handleUseEffect = async () => {
      setShow("All");
      await handleNewSelected([]);
      await handleEmptyArray();
      await setTotalPages(0);
      await setPage(1);
      await setSelected([]);
      await setPageNo(0);
      await setOrder("asc");
      await setOrderBy("id");
      await fetchKeywordsData(token, id, 0, "id", "asc", "All");
    };

    handleUseEffect();
  }, [id]);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(false);
    setSnackbarOpen(false);
  };

  function handleEmptyArray() {
    keywords.splice(0, keywords.length); // Array becomes empty
    setKeywords(keywords); // Update the state
  }

  useEffect(() => {
    if (fetchKeywordsReducer.message === "FETCH_KEYWORDS_RECEIVED") {
      setTotalSize(fetchKeywordsReducer.totalElements);
      setLastPage(fetchKeywordsReducer.lastPage);
      setTotalPages(fetchKeywordsReducer.totalPages);
    }
  }, [fetchKeywordsReducer]);

  const rows = keywords.map((item) =>
    createData(
      item.id,
      item.text,
      item.searchVolume,
      item.ninetyDaysSearchVolumeTrend,
      item.yearOverYearSearchVolumeTrend,
      item.lowTopOfPageBid,
      item.highTopOfPageBid,
      item.competition
    )
  );

  const handleChipDelete = (row) => {
    row.selected = false;
    setSelected((prevSelected) => {
      const index = prevSelected.findIndex(
        (selectedRow) => selectedRow.id === row.id
      );
      prevSelected[index] = row;
      return [...prevSelected];
    });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    fetchKeywordsData(token, id, pageNo, property, newOrder, show);
  };

  const handleSelectAllClick = (event) => {
    setAllSelectCheck(true);
    const updatedKeywords = keywords.map((item) => ({
      ...item,
      selected: event.target.checked,
    }));

    const mergedArray = Array.from(
      new Set([...updatedKeywords, ...selected].map((item) => item.id))
    ).map((id) => {
      const matchingItem = [...updatedKeywords, ...selected].find(
        (item) => item.id === id
      );
      return matchingItem;
    });

    setSelected(mergedArray);
  };

  const handleRowCheckbox = (event, clickedRow) => {
    const isChecked = event.target.checked;
    const updatedRow = keywords.find((row) => row.id === clickedRow.id);

    if (updatedRow) {
      updatedRow.selected = isChecked;

      setSelected((prevSelected) => {
        const index = prevSelected.findIndex(
          (selectedRow) => selectedRow.id === updatedRow.id
        );
        if (index !== -1) {
          prevSelected[index] = updatedRow;
          return [...prevSelected];
        } else {
          return [...prevSelected, updatedRow];
        }
      });

      handleNewSelected([...newSelected, selected[selected.length - 1]]);
    }
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(() => {
    const sortedRows = stableSort(rows, getComparator(order, orderBy));
    const slicedRows = sortedRows.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );

    return slicedRows;
  }, [order, orderBy, page, rowsPerPage]);

  const handleSaveAPICall = async () => {
    return await updateKeywords(token, selected);
  };

  const handleChangePage = async (event, newPage) => {
    await handleSaveAPICall();
    const nextPage = newPage - 1;
    await fetchKeywordsData(token, id, nextPage, orderBy, order, show);
    setPage(newPage);
  };

  const handleSaveKeywords = async () => {
    setLoading(true);
    const data = await handleSaveAPICall();
    if (data && data.status == 200) {
      setError(false);
      setSnackbarMsg("Keywords saved");
      setSelectedKeywordsForMatchType(
        selected.filter((keyword) => keyword.selected)
      );
      if (show == "Selected" || (show == "Unselected" && allSelectCheck)) {
        fetchKeywordsData(token, id, page - 1, orderBy, order, show);
        setAllSelectCheck(false);
      }
    } else {
      setError(true);
      setSnackbarMsg("Error in saving keywords");
    }
    setLoading(false);
    setSnackbarOpen(true);
  };

  const handleNextButton = async () => {
    setLoading(true);
    await handleSaveKeywords(selected);
    setLoading(false);
    handleKeywordsNextButton("MatchType", id);
  };
  const roundedValue = (value) => {
    return Number(value.toFixed(2));
  };
  const handleShowSort = (e) => {
    setPage(1);
    setShow(e.target.value);
    fetchKeywordsData(token, id, 0, orderBy, order, e.target.value);
  };

  return (
    <Box sx={{ width: "100%", pl: 2, pr: 2 }}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography style={{ paddingBottom: "2%" }} variant="subtitle2">
            Selected Keywords:
            {selected.length > 0 &&
            selected.filter((row) => row != undefined || row.selected).length >
              0 ? (
              <span className="keywordCount">
                {selected.filter((row) => row.selected).length}
              </span>
            ) : (
              ""
            )}
          </Typography>
        </Grid>
        <Grid
          item
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Typography variant="subtitle2">Show: </Typography>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <Select
              autoWidth
              value={show}
              onChange={handleShowSort}
              inputProps={{ "aria-label": "Without label" }}
              sx={{
                fontSize: "0.875rem" /* Set font size to 0.875 rem */,
                color: "#F58120",
                boxShadow: "none",
                ".MuiOutlinedInput-notchedOutline": { borderStyle: "none" },
              }}
            >
              <MenuItem value={"All"}>All</MenuItem>
              <MenuItem value={"Selected"}>Selected</MenuItem>
              <MenuItem value={"Unselected"}>Unselected</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Paper sx={{ width: "100%", mb: 2 }}>
        {selected.length > 0 &&
        selected.filter((row) => row != undefined && row.selected).length >
          0 ? (
          <EnhancedTableToolbar
            numSelected={selected.filter((row) => row.selected).length}
            selectedRows={selected}
            handleChipDelete={handleChipDelete}
            rows={keywords}
          />
        ) : (
          ""
        )}
        {rows.length > 0 && rows != null ? (
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={
                  selected.length > 0 &&
                  selected.filter((row) => row != undefined && row.selected)
                    .length
                }
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={keywords.length}
                disableStructure={disableStructure}
                visibleRowsSelCount={
                  rows.length > 0 &&
                  rows.filter((row) =>
                    selected.some(
                      (selectedRow) =>
                        selectedRow.id === row.id && selectedRow.selected
                    )
                  ).length
                }
              />
              {rows.length > 0 && rows != null ? (
                <TableBody
                  sx={{
                    ".MuiTableRow-root.Mui-selected": {
                      background: "#fff",
                    },
                  }}
                >
                  {rows.map((row, index) => {
                    const isItemSelected = selected.some(
                      (selectedRow) =>
                        selectedRow.id == row.id && selectedRow.selected
                    );
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleRowCheckbox(event, row)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        // selected={isItemSelected}
                        selected={row.selected == true ? false : true}
                        sx={{
                          cursor: "pointer",
                          background: "#fff",
                        }}
                      >
                        <TableCell padding="checkbox">
                          {loading ? (
                            <Skeleton />
                          ) : (
                            <Checkbox
                              color="secondary"
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                              size="small"
                              disabled={disableStructure}
                            />
                          )}
                        </TableCell>
                        <TableCell id={labelId} sx={{ color: "#747474" }}>
                          {loading ? <Skeleton /> : row.keyword}
                        </TableCell>
                        <TableCell sx={{ color: "#747474" }}>
                          {loading ? <Skeleton /> : row.monthlySearchVolume}
                        </TableCell>
                        <TableCell sx={{ color: "#747474" }}>
                          {loading ? (
                            <Skeleton />
                          ) : (
                            <Typography
                              variant="subtitle2"
                              className={
                                row.trend < 0
                                  ? "redColor"
                                  : row.trend > 0
                                  ? "greenColor"
                                  : ""
                              }
                            >
                              {row.trend == null
                                ? "NA"
                                : `${roundedValue(row.trend)} %`}
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell sx={{ color: "#747474" }}>
                          {loading ? (
                            <Skeleton />
                          ) : (
                            <Typography
                              variant="subtitle2"
                              className={
                                row.yearOverYeartrend < 0
                                  ? "redColor"
                                  : row.yearOverYeartrend > 0
                                  ? "greenColor"
                                  : "inherit"
                              }
                            >
                              {row.yearOverYeartrend == null
                                ? "NA"
                                : `${roundedValue(row.yearOverYeartrend)} %`}
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell sx={{ color: "#747474" }}>
                          {loading ? (
                            <Skeleton />
                          ) : (
                            "$" + row.bidLow.toLocaleString()
                          )}
                        </TableCell>
                        <TableCell sx={{ color: "#747474" }}>
                          {loading ? (
                            <Skeleton />
                          ) : (
                            "$" + row.bidHigh.toLocaleString()
                          )}
                        </TableCell>
                        <TableCell>
                          {loading ? (
                            <Skeleton />
                          ) : (
                            <Typography
                              variant="subtitle2"
                              className={
                                row.competition === "HIGH"
                                  ? "redColor"
                                  : row.competition === "MEDIUM"
                                  ? "blueColor"
                                  : row.competition === "LOW"
                                  ? "greenColor"
                                  : "greenColor"
                              }
                            >
                              {row.competition == "HIGH"
                                ? "High"
                                : row.competition == "MEDIUM"
                                ? "Medium"
                                : row.competition === "LOW"
                                ? "Low"
                                : "Low"}
                            </Typography>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              ) : (
                <span className="structureContent"></span>
              )}
            </Table>
          </TableContainer>
        ) : (
          <span className="structureContent"><Typography variant="subtitle2" align="center">No data to show</Typography></span>
        )}
      </Paper>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        {/* {loading ? <Skeleton/> :    */}
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {rows.length == 0 && show == "All" ? (
            ""
          ) : (
            <Pagination
              count={totalPages}
              // defaultPage={1}
              page={page}
              onChange={handleChangePage}
              showFirstButton
              showLastButton
            />
          )}
        </Grid>

        <Grid
          item
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          columnSpacing={2}
          xs={12}
        >
          {/* <Grid item container direction="row" alignItems="center" xs={3}>
            {authority == Authority.ANALYST || authority == Authority.RWF ? (
              ""
            ) : (
              <>
                <Checkbox size="small" />
                <Typography variant="subtitle2">Mark this as final</Typography>
              </>
            )}
          </Grid> */}
          {/* <Grid
            item
            container
            xs={9}
            justifyContent="flex-end"
            columnSpacing={2}
          > */}
          <Grid item>
            <Button
              variant="text"
              sx={{ textTransform: "none" }}
              onClick={() => handleSaveKeywords(selected)}
              disabled={loading || disableStructure || keywords.length == 0}
            >
              <SaveOutlined sx={{ color: "#F58120", paddingRight: "2px" }} />
              Save
            </Button>
          </Grid>
          <Grid item>
            <JsOrangeContainedBtn
              style={{ padding: "3% 5%", fontSize: "0.875rem" }}
              onClick={() => handleNextButton()}
              disabled={
                loading == true ||
                selected.filter((row) => row.selected).length == 0
              }
            >
              Next
            </JsOrangeContainedBtn>
          </Grid>
          {/* </Grid> */}
        </Grid>
      </Grid>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={error ? "error" : "success"}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarMsg}
        </Alert>
      </Snackbar>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  fetchKeywordsReducer: state.fetchKeywordsReducer,
  UpdateKeywordsReducer: state.UpdateKeywordsReducer,
});

const mapDispatchToProps = (dispatch) => ({
  fetchKeywords: (token, id, pageNo, orderBy, order, show) =>
    dispatch(fetchKeywords(token, id, pageNo, orderBy, order, show)),
  updateKeywords: (token, keywords) =>
    dispatch(updateKeywords(token, keywords)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Keywords);
