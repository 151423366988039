import React, { Fragment } from "react";
import { Card, CardContent } from "@mui/material";
import { Helmet } from "react-helmet";
import { Grid, Typography } from "@mui/material/index";
import { withStyles } from "@mui/styles";
import leadGenerationStyles from "../../assets/jss/leadGeneration/leadGenerationPageStyles";
import useMediaQuery from "@mui/material/useMediaQuery";
import icons from "../../components/icons";
import {
  CatalinaBlue,
  OrangeTextSize12,
  OrangeTypography,
  TaglineForHeadline,
} from "../../components/typography";
import LeadGenerationForm from "./leadGenerationForm";
import LeadGenContent from "./leadGenContent";
import { withRouter } from "react-router";
import * as PropTypes from "prop-types";

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="xl" />;

function IconMain(props) {
  const isTabletResponsive = useMediaQuery(
    props.theme.breakpoints.between("sm", "xl")
  );
  return (
    <Typography gutterBottom>
      {props.width == "sm" || props.width == "xs"
        ? isTabletResponsive
          ? props.icon("auto")
          : props.icon("100%")
        : props.icon("auto")}
    </Typography>
  );
}

export const eCommerceType = {
  shopify: "Shopify",
  bigcommerce: "Bigcommerce",
  woocommerce: "Woocommerce",
};

class LeadGenerationPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      eCommercePlatform: window.location.search
        ? new URLSearchParams(window.location.search).get("channel")
        : "",
    };
  }

  render() {
    const { classes, width, theme } = this.props;
    const { eCommercePlatform } = this.state;
    const type = eCommerceType[eCommercePlatform];
    console.log("type=", type);
    const isMobile = width == "sm" || width == "xs";
    const icon = (value) =>
      eCommercePlatform == "shopify"
        ? icons.shopifyEcommerce(value)
        : eCommercePlatform == "bigcommerce"
        ? icons.bigEcommerce(value)
        : icons.wooEcommerce(value);
    return (
      <Card className={classes.card}>
        <CardContent className={classes.cardContent} style={{ padding: 0 }}>
          {/*<Helmet>*/}
          {/*    <title> Google Ads Grader Tool | Google Adwords Tool | LXRGuide</title>*/}
          {/*    <meta name="description"*/}
          {/*          content="Use our Google Ad Graders Tool to optimize your web presence with scores on your target impression share and more. We show you how to increase conversions with enhanced cpc."/>*/}
          {/*</Helmet>*/}
          {type == undefined ? (
            <Fragment>
              <br />
              <Typography align={"center"} variant={"subtitle1"}>
                Invalid URL Given
              </Typography>
            </Fragment>
          ) : (
            <Grid container>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                className={[
                  classes.sectionBg,
                  classes.gridBg,
                  isMobile && classes.sectionMobile,
                ]}
              >
                <Grid
                  container
                  justifyContent={"space-around"}
                  alignItems={"flex-start"}
                  style={{ height: "100%" }}
                >
                  {/*<Grid item md={12}>{icon}</Grid>*/}
                  <Grid item md={7}>
                    <IconMain
                      theme={theme}
                      width={width}
                      icon={(value) => icon(value)}
                    />
                    <OrangeTextSize12
                      align={"left"}
                      style={{ textAlign: "left", padding: 0 }}
                    >
                      GOOGLE ADS, MICROSOFT ADS, SEO TOOLS & EXPERT HELP
                    </OrangeTextSize12>
                    <Typography
                      color={"textPrimary"}
                      variant={
                        width == "sm" || width == "xs" ? "h3" : "subtitle1"
                      }
                      style={{ fontWeight: "700" }}
                    >
                      Paid Search Management Solution for Small and Mid-size
                      Businesses on {type}
                    </Typography>
                    <CatalinaBlue align={"left"} color={"textPrimary"}>
                      {type} powers hundreds of thousands of businesses sell
                      online? Many of these businesses use Google and Microsoft
                      Ads to drive new customers to their websites and grow
                      their online sales. Having helped Shopify businesses run
                      successful Paid Search Campaigns for 10+ years, we know
                      what works and what does not. The LXRGuide Growth Solution
                      for Shopify Businesses has been built on a foundation of
                      our learnings and has a proven track record of delivering
                      predictable and profitable paid search results.
                    </CatalinaBlue>
                  </Grid>
                  <Grid item md={4}>
                    <br />
                    <br />
                    <br />
                    <LeadGenerationForm eCommercePlatform={eCommercePlatform} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={12} xs={12} md={12}>
                <LeadGenContent type={type} />
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                className={classes.lastSection}
              >
                <LeadGenerationForm
                  bottomUi
                  type={type}
                  eCommercePlatform={eCommercePlatform}
                />
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>
    );
  }
}

LeadGenerationPage.propTypes = {};

export default withRouter(
  withWidth()(
    withStyles(leadGenerationStyles, { withTheme: true })(LeadGenerationPage)
  )
);
