import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import icons from "../../components/icons";
import { connect } from "react-redux";
import "./WebsiteHealthScoreCard.css";
import { BlueConsultationTextWHS,OverallSectionTypography } from "../../components/typography";
import {
  sendScorecardMail,
  fetchWHSExpertData,
  onDemandPayment,
} from "../../actions";
import { withStyles } from "@mui/styles";
import { WHSOrangeButton } from "../../components/buttons";
import { CircularProgress } from "@mui/material";
import ReactSpeedometer from "react-d3-speedometer";
import CropSquare from '@mui/icons-material/CropSquare';
const styles = (theme) => ({
  speedometer: {
    [theme.breakpoints.up("md")]: {},
    [theme.breakpoints.down("md")]: {
      padding: "15px",
      backgroundColor: "#F9FAFB",
    },
  },
});

function overallScore_WHC(props) {
  var websiteHealthScoreDataReducers = props.websiteHealthScoreDataReducers;
  var sectionWithLowestScore = props.sectionWithLowestScore;
  const {
    sendScorecardMail,
    websiteHealthEmailDataReducers,
    fetchWHSExpertData,
    fetchWHSExpertDataReducer,
    externalUserId,
    userInformation,
  } = props;
  useEffect(() => {
    if(userInformation && userInformation.clientId){
         setUserId(userInformation.clientId);
        } else if(externalUserId) {
          setUserId(externalUserId);
        } 
      }, [userInformation]);

  const [userId, setUserId] = useState(userInformation.clientId ? userInformation.clientId : externalUserId);
  const finalUserId = userId;  
  const userType = externalUserId ? 1 : 0;

  useEffect(() => {
    if(userInformation && userInformation.clientId){
         setUserId(userInformation.clientId);
        } else if(externalUserId) {
          setUserId(externalUserId);
        } 
      }, [userInformation]);

  const [overallScore, setOverallScore] = useState(
    websiteHealthScoreDataReducers.overallScore
      ? websiteHealthScoreDataReducers.overallScore
      : null
  );

  const [isMailSupportSent, setIsMailSupportSent] = useState(
    websiteHealthEmailDataReducers.isMailSupportSent
      ? websiteHealthEmailDataReducers.isMailSupportSent
      : null
  );

  const [topErrorParameters, setTopErrorParameters] = useState(
    websiteHealthScoreDataReducers.topErrorParameters
      ? websiteHealthScoreDataReducers.topErrorParameters
      : null
  );

  const [consultationRequestStatus, setConsultationRequestStatus] = useState(
    fetchWHSExpertDataReducer && fetchWHSExpertDataReducer[0] ? fetchWHSExpertDataReducer[0].requestStatus : undefined
  );
  const [consultsLeft, setConsultsLeft] = useState(
    websiteHealthScoreDataReducers
      ? websiteHealthScoreDataReducers.consultsLeft
      : null
  );
  const [paymentStatus, setPaymentStatus] = useState(
    props.onDemandPayment ? props.onDemandPayment.payment_status : null
  );
  console.log("whs payment status",paymentStatus);
  console.log("whs payment status",paymentStatus);
  const [consultationText, setConsultationText] = useState(
    "Claim Your Free Consultation!"
  );
  const [disableConsultation, setDisableConsultation] = useState(false);
  const [successfulContact, setSuccessfulContact] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (websiteHealthScoreDataReducers) {
      setOverallScore(
        websiteHealthScoreDataReducers.overallScore
          ? websiteHealthScoreDataReducers.overallScore
          : null
      );
      setTopErrorParameters(
        websiteHealthScoreDataReducers.topErrorParameters
          ? websiteHealthScoreDataReducers.topErrorParameters
          : null
      );
      setConsultsLeft(
        websiteHealthScoreDataReducers
          ? websiteHealthScoreDataReducers.consultsLeft
          : null
      );
    }
  }, [websiteHealthScoreDataReducers]);

  useEffect(() => {
    if (websiteHealthEmailDataReducers.isMailSupportSent) {
      setIsMailSupportSent(websiteHealthEmailDataReducers.isMailSupportSent);
    }
  }, [websiteHealthEmailDataReducers]);

  useEffect(() => {
    if (fetchWHSExpertDataReducer && fetchWHSExpertDataReducer[0]) {
   
        setConsultationRequestStatus(
          fetchWHSExpertDataReducer[0].requestStatus ? fetchWHSExpertDataReducer[0].requestStatus : null
       );
    }
  }, [fetchWHSExpertDataReducer]);

  const [screenResolution, setScreenResolution] = useState({
    width: 0,
    height: 0,
  });
  useEffect(() => {
    const updateScreenResolution = () => {
      setScreenResolution({
        width: window.screen.width,
        height: window.screen.height,
      });
    };
    window.addEventListener("resize", updateScreenResolution);
    updateScreenResolution();
    return () => {
      window.removeEventListener("resize", updateScreenResolution);
    };
  }, []);

  const claimYourConsultation = async () => {
    setIsLoading(true);
    setConsultationText("Requesting...");
    setDisableConsultation(true);
    const data = await sendScorecardMail(userId, userType); // Wait for sendScorecardMail to complete
  if (data != null && data.isMailSupportSent) {
      setSuccessfulContact(true);
      setIsLoading(false);
    } else {
      setSuccessfulContact(false);
      setIsLoading(false);
      if (consultsLeft > 0) {
        setConsultationText("Claim Your Free Consultation!");
      } else {
        setConsultationText("Claim Your Consultation!");
      }
    }
  };

  useEffect(() => {
    const processPaymentAndReload = async () => {
      if (paymentStatus && !isMailSupportSent && userId !== undefined) {
        console.log("user id for paid payment", userId);
    };
    processPaymentAndReload();
  }}, [paymentStatus]);

  const textColor = "#C4C4C4";

  console.log("consultation issue", isLoading, successfulContact, consultationRequestStatus, fetchWHSExpertDataReducer[0],consultsLeft);
  return (
    <Fragment style={{ padding: "0px" }}>
      <div
        className={
          overallScore <= 35
            ? "gradient-border-poor"
            : overallScore > 35 && overallScore <= 70
            ? "gradient-border-avg"
            : "gradient-border-good"
        }
        >
        
        <Grid
          container
          direction="row"
          justify="space-evenly"
          alignItems="center"
          style={{ padding: "10px 10px", backgroundColor: "#fff" }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={2}
            lg={2}
            xl={2}
            style={{ textAlign: "center" }}
          >
            <div className="flexRowSpaceBtwn">
              <div>
                <Typography
                  variant="h3"
                  style={{ color: "#6D6D6D", fontWeight: "500" }}
                >
                  Overall Score
                </Typography>
                {overallScore ? (
                  <Typography
                    align="center"
                    style={{
                      fontWeight: "900",
                      fontSize: "4.25rem",
                      fontSize: "4.25rem",
                      color:
                        overallScore <= 35
                          ? "#F13B0C"
                          : overallScore > 35 && overallScore <= 70
                          ? "#FFCF15"
                          : "#00BF11",
                    }}
                  >
                    {overallScore}
                    <span style={{ fontSize: "1.25rem", fontWeight: "400" }}>
                      /100
                    </span>
                  </Typography>
                ) : (
                  <Typography></Typography>
                )}
              </div>
              <div className="verticalLine1">{icons.WHSVerticalLine}</div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
            <div className="grid-item-css" style={{ paddingRight: "4%" }}>
              <ReactSpeedometer
                minValue={0}
                maxValue={100}
                value={overallScore}
                needleHeightRatio={0.3}
                needleColor="#1E334E"
                needleTransitionDuration={4000}
                needleTransition="easeElastic"
                // customSegmentStops={[0, 20, 40, 60, 80, 100]}
                customSegmentStops={[0, 30, 70, 100]}
                segmentColors={["#F13B0C", "#FFCF15", "#00BF11"]}
                textColor={textColor}
                height={110}
                width={200}
                currentValueText=" "
                ringWidth={40}
                // paddingHorizontal={10}
                segmentValueFormatter
                customSegmentLabels={[
                  {
                    text: "Poor",
                    position: "OUTSIDE",
                    color: "#F13B0C",
                  },
                  {
                    text: "Average",
                    position: "OUTSIDE",
                    color: "#FFCF15",
                  },
                  {
                    text: "Good",
                    position: "OUTSIDE",
                    color: "#00BF11",
                  },
                ]}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "7px",
              }}
            >
              <Typography
                variant="subtitle2"
                style={{ color: "#747474", paddingRight: "15px" }}
              >
                <CropSquare
                  style={{
                    fill: "#F13B0C",
                    backgroundColor: "#F13B0C",
                    width: "14px",
                    height: "14px",
                    marginRight: "4px",
                  }}
                />
                <span style={{ paddingRight: "12px" }}>0-35</span>
                <CropSquare
                  style={{
                    fill: "#FFCF15",
                    backgroundColor: "#FFCF15",
                    width: "14px",
                    height: "14px",
                    marginRight: "4px",
                  }}
                />
                <span style={{ paddingRight: "12px" }}>36-70</span>
                <CropSquare
                  style={{
                    fill: "#00BF11",
                    backgroundColor: "#00BF11",
                    width: "14px",
                    height: "14px",
                    marginRight: "4px",
                  }}
                />
                <span>71-100</span>
              </Typography>
            </div>
          </Grid>
          <Grid
            item
            xs={3}
            sm={2}
            md={1}
            lg={1}
            xl={1}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="verticalLine">{icons.WHSVerticalLine}</div>
            {overallScore > 35 ? icons.WHSBulb : icons.WHSErrorBulb}
          </Grid>
          {overallScore > 70 ? (
            <Grid
              item
              xs={9}
              sm={7}
              md={3}
              lg={3}
              xl={3}
              style={{ paddingLeft: "1%",paddingTop:"1.25rem" }}
             
            >
              <div>
                
                <OverallSectionTypography style={{ color:"#1E334E", fontWeight: "600" }}>
                  <b>Congratulations! Your website ranks among the top 25%.</b>
                </OverallSectionTypography>
                
                <OverallSectionTypography style={{ color: "#747474" }}>
                  Moreover, your website excels in all the four scoring
                  criteria.
                  <br /> Great job in ensuring an outstanding user experience!
                  <br />
                  Keep up the great work!
                </OverallSectionTypography>
              </div>
            </Grid>
          ) : overallScore > 35 && overallScore <= 70 ? (
            <Grid
              item
              xs={9}
              sm={7}
              md={3}
              lg={3}
              xl={3}
              style={{ paddingLeft: "1%" ,paddingTop:"1.25rem" }}
            >
              <div>
                <OverallSectionTypography style={{ color:"#1E334E",fontWeight: "600" }}>
                  <b>Not Bad!</b>
                </OverallSectionTypography>
                <OverallSectionTypography style={{ color: "#747474", lineHeight: "28px" }}>
                  Your website has an average score. <br />
                  There is an opportunity for the website to do better in{" "}
                  <span className="orange-text">
                    {sectionWithLowestScore}
                  </span>. <br />
                  Our website specialists will be happy to help.
                </OverallSectionTypography>
              </div>
            </Grid>
          ) : (
            <Grid
              item
              xs={9}
              sm={7}
              md={4}
              lg={4}
              xl={4}
              style={{ paddingLeft: "1%",paddingTop:"1.25rem" }}
            >
              <div>
                <OverallSectionTypography style={{ color: "#747474", fontSize:"0.875rem" }}>
                  <span style={{ color: "#F13B0C" }}>
                    <b>
                      Heads up! Your website currently ranks in the bottom 25%.
                    </b>
                  </span>{" "}
                  <br /> There's room for improvement in some of the four
                  criteria. Take this as an opportunity to enhance the user
                  experience!
                </OverallSectionTypography>

                {topErrorParameters &&
                  topErrorParameters.map((str, index) => (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        padding: "5px 0px",
                      }}
                    >
                      {index == 0
                        ? icons.WHSPoint1
                        : index == 1
                        ? icons.WHSPoint2
                        : index == 2
                        ? icons.WHSPoint3
                        : ""}

                      <OverallSectionTypography
                        style={{ color: "#747474", paddingLeft: "5px" ,fontSize:"0.875rem"}}
                      >
                        {str ? str : ""}
                      </OverallSectionTypography>
                    </div>
                  ))}
                <OverallSectionTypography style={{ color: "#747474", fontSize:"0.875rem" }}>
                  Our website specialists will be happy to help.
                </OverallSectionTypography>
              </div>
            </Grid>
          )}
          <Grid item xs={12} sm={3} md={2} lg={2} xl={2} style={{textAlign:"center" }}>
            {screenResolution.width < 600 || screenResolution.width > 1400 
              ? overallScore > 50
                ? icons.WHSGoodOverallScore
                : icons.WHSPoorOverallScore
              : overallScore > 50
              ? icons.WHSGoodOverallScore1
              : icons.WHSPoorOverallScore1}
          </Grid>
        </Grid>
      </div>
      <div className="consultation-css">
        <Grid container direction="row" alignItems="center">
          <Grid
            item
            xs={2}
            sm={1}
            md={1}
            lg={1}
            xl={1}
            style={{ display: "flex", justifyContent: "center" }}
          >
            {icons.WHSConsultation}
          </Grid>
          {(!isLoading && successfulContact) ||
          (fetchWHSExpertDataReducer &&
            Object.keys(fetchWHSExpertDataReducer).length > 1 &&
            consultationRequestStatus !== "Closed") ? (
            <Grid item xs={10} sm={11} md={11} lg={11} xl={11}>
              <BlueConsultationTextWHS>
                Your 30-minute consultation session has been successfully
                scheduled – looking forward to our discussion!
                <span className="orange-text"> Need support? &nbsp;</span>
                Email us:{" "}
                <span className="orange-text">
                  {" "}
                  <a
                    href="mailto:support@lxrguide.com"
                    style={{ color: "#F58120", textDecoration: "none" }}
                  >
                    support@lxrguide.com
                  </a>
                </span>
              </BlueConsultationTextWHS>
            </Grid>
          ) : (
            <Grid item xs={10} sm={11} md={11} lg={11} xl={11}>
              <Grid container direction="row" alignItems="center">
                <Grid item xs={12} sm={9} md={9} lg={9} xl={9}>
                  {fetchWHSExpertDataReducer &&
                  Object.keys(fetchWHSExpertDataReducer).length >= 0 &&
                  (consultationRequestStatus == "Closed" ||
                    consultationRequestStatus == undefined) &&
                  consultsLeft > 0 ? (
                    <BlueConsultationTextWHS>
                      Unlock your website's potential with a{" "}
                      <span className="orange-text">free consultation</span> by
                      our website optimization specialist.
                    </BlueConsultationTextWHS>
                  ) : (
                    <BlueConsultationTextWHS>
                      Get the insights you need to take your website to the next
                      level with a paid consultation session with our website
                      optimization experts.
                    </BlueConsultationTextWHS>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  md={3}
                  lg={3}
                  xl={3}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {isLoading && !successfulContact ? (
                    <WHSOrangeButton
                      id="consultation"
                      disabled={disableConsultation}
                    >
                      <span
                        className="grid-item-css"
                        style={{ color: "white" }}
                      >
                        {consultationText} &nbsp;
                        <CircularProgress color="inherit" size={"19px"} />
                      </span>
                    </WHSOrangeButton>
                  ) : !isLoading &&
                    !successfulContact &&
                    fetchWHSExpertDataReducer &&
                    Object.keys(fetchWHSExpertDataReducer).length >= 0 &&
                    (consultationRequestStatus == "Closed" ||
                      consultationRequestStatus == undefined) &&
                    consultsLeft != 0 ? (
                    <WHSOrangeButton
                      id="consultation"
                      onClick={claimYourConsultation}
                      disabled={disableConsultation}
                      style={{ color: "white" }}
                    >
                      {consultationText}
                    </WHSOrangeButton>
                  ) : (
                    <Link to="/whs-payment">
                      <WHSOrangeButton
                        id="consultation"
                        disabled={disableConsultation}
                      >
                        Claim Your Consultation!
                      </WHSOrangeButton>
                    </Link>
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </div>
    </Fragment>
  );
}
const mapStateToProps = (state) => ({
  websiteHealthEmailDataReducers: state.websiteHealthEmailDataReducers,
  fetchWHSExpertDataReducer: state.fetchWHSExpertDataReducer,
  onDemandPayment: state.onDemandPayment,
  userInformation: state.userInformation.selectedClientInfo,
});

const mapDispatchToProps = (dispatch) => ({
  sendScorecardMail: (userId,userType) => dispatch(sendScorecardMail(userId, userType)),
  fetchWHSExpertData: (userId, userType) => dispatch(fetchWHSExpertData(userId, userType)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(overallScore_WHC));
