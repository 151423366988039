import React, { Fragment } from "react";
import { ExpertHelpInputField } from "../input-forms";
import {
  Grid,
  Typography,
  Button,
  Checkbox,
  MenuItem,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import {
  saveRequestExpertHelpData,
  getClientDetailsForExpertHelp,
} from "../../actions";
import { connect } from "react-redux";
import { CurvedBlueBtn } from "../buttons";
import { getLastDates, requestDemoTime } from "./templates";
import icons from "../icons";
import NumberFormat from "react-number-format";

let moment = require("moment");

const demoFormStyles = (theme) => ({
  hyperLinks: {
    textDecoration: "unset",
    color: theme.palette.hyperLink,
    fontWeight: "bold",
    fontSize: "0.8rem",
    lineHeight: 0,
    padding: 0,
  },
  customGrid: {
    // paddingLeft: theme.spacing(15),
    [theme.breakpoints.down("lg")]: {
      padding: "1rem 0rem 0rem 0rem",
    },
    paddingBottom: theme.spacing(2),
  },
  scheduleTitle: {
    fontSize: "1rem",
  },
  orangeTxt: {
    color: theme.palette.orangeColor,
  },
  progress: {
    margin: theme.spacing(2),
  },
});

export const FormTitleTypography = withStyles((theme) => ({
  root: {
    fontFamily: "Lato",
    color: theme.palette.text.secondary,
    fontSize: 14,
  },
}))(Typography);
export const CustomCheckbox = withStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
  },
}))(Checkbox);
const PopUpCommonBtn = withStyles((theme) => ({
  root: {
    fontWeight: "400",
    color: theme.palette.text.primary,
  },
}))(Button);

class requestExpertHelpForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.dates = getLastDates();
    this.state = {
      firstName: "",
      emailId: "",
      companyName: "",
      phoneNumber: "",
      website: "",
      country: "",
      advertiserType: "false",
      firstNameError: "",
      emailIdError: "",
      companyNameError: "",
      phoneNumberError: "",
      countryError: "",
      termsAndConditionsError: "",
      saving: false,
      termsAndConditions: false,
      SuccessNotification: "",
      websiteError: "",
      meetingDate: "",
      meetingDateError: "",
      meetingTime: "",
      meetingTimeError: "",
      channel: this.props.channel,
      clientDetails: null,
      isLoading: false,
      alert: false,
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({ isLoading: true });
    const response = await this.props.getClientDetails();
    if (response.clientDetails != null) {
      this.setState({
        isLoading: false,
        clientDetails: response.clientDetails,
        emailId: response.clientDetails.clientEmail,
        firstName: response.clientDetails.clientName,
        website: response.clientDetails.clientWebsite,
      });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  handleCheckBox = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  validate = () => {
    let isError = false;
    const errors = {};
    if (this.state.firstName.length === 0) {
      isError = true;
      errors.firstNameError = "This field is required";
    } else {
      errors.firstNameError = "";
    }
    if (this.state.emailId.length === 0) {
      isError = true;
      errors.emailIdError = "This field is required";
    } else if (this.state.emailId.length > 0) {
      let result = this.state.emailId.match(
        /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
      );
      if (!result) {
        isError = true;
        errors.emailIdError = "Please enter valid email";
      } else {
        errors.emailIdError = "";
      }
    } else {
      errors.emailIdError = "";
    }
    if (this.state.phoneNumber.length === 0) {
      isError = true;
      errors.phoneNumberError = "This field is required";
    } else if (this.state.phoneNumber.length < 10) {
      isError = true;
      errors.phoneNumberError = "Phone Number should be minimum 10 digits";
    } else if (this.state.phoneNumber.length > 10) {
      isError = true;
      errors.phoneNumberError = "Phone Number should not exceed 10 digits";
    } else {
      errors.phoneNumberError = "";
    }
    const UrlRegex = /^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}$/;
    if (this.state.website.length === 0 || this.state.website.trim() === "") {
      isError = true;
      errors.websiteError = "This field is required";
    } else if (
      this.state.website.length !== 0 &&
      !UrlRegex.test(this.state.website)
    ) {
      isError = true;
      errors.websiteError = "URL Format Wrong";
    } else {
      errors.websiteError = "";
    }

    if (this.state.meetingDate.length === 0) {
      isError = true;
      errors.meetingDateError = "This field is required";
    } else {
      errors.meetingDateError = "";
    }
    if (this.state.meetingTime.length === 0) {
      isError = true;
      errors.meetingTimeError = "This field is required";
    } else {
      errors.meetingTimeError = "";
    }

    this.setState({
      ...this.state,
      ...errors,
    });
    return isError;
  };

  handleInputs = async (event) => {
    event.preventDefault();
    await this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = async () => {
    console.log(
      "meetingDate=",
      moment(this.state.meetingDate).format("MMMM-DD-YYYY")
    );
    let meetingDate = moment(
      this.state.meetingDate + " " + this.state.meetingTime
    );
    console.log("meetingDate=", meetingDate.format("MMMM-DD-YYYY HH:mm"));
    let error;
    error = this.validate();
    if (!error) {
      this.setState({ saving: true });
      this.state.response = await this.props.saveInputsToServer({
        firstName: this.state.firstName,
        emailId: this.state.emailId,
        companyName: this.state.companyName,
        phoneNumber: this.state.phoneNumber,
        website: this.state.website,
        meetingDate: meetingDate,
        channel: this.state.channel,
        meetingTime: this.state.meetingTime,
      });
      this.setState({
        saving: false,
        SuccessNotification:
          "LXRGuide team will contact you as soon as possible",
        firstName: "",
        emailId: "",
        companyName: "",
        phoneNumber: "",
        website: "",
        country: "",
        emailIdError: "",
        companyNameError: "",
        phoneNumberError: "",
        meetingDate: "",
        meetingTime: "",
        websiteError: "",
        alert: true,
      });
    }

    setTimeout(() => {
      this.clearNotification();
    }, 3000);
  };
  clearNotification = () => {
    this.setState({ alert: false });
  };

  render() {
    const { classes } = this.props;
    const { fullScreen } = this.props;
    const { isLoading } = this.state;
    return (
      <Fragment>
        <Grid
          container
          justifyContent={"center"}
          alignContent={"center"}
          className={classes.customGrid}
        >
          {isLoading === true ? (
            <Grid item md={12} style={{ height: "55vh" }}>
              <Typography align={"center"}>
                <CircularProgress />
              </Typography>
            </Grid>
          ) : (
            <Fragment>
              <Grid item md={4} xs={12} sm={4}>
                <FormTitleTypography align={"left"}>
                  Name*&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </FormTitleTypography>
              </Grid>
              <Grid item md={8} xs={12} sm={8}>
                <ExpertHelpInputField
                  size="small"
                  fullWidth
                  margin="normal"
                  name="firstName"
                  value={this.state.firstName}
                  onChange={this.handleInputs}
                  error={this.state.firstNameError !== ""}
                  helperText={this.state.firstNameError}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                />
              </Grid>
              <Grid item md={4} xs={12} sm={4}>
                <FormTitleTypography align={"left"}>
                  Email Address*&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </FormTitleTypography>
              </Grid>
              <Grid item md={8} xs={12} sm={8}>
                <ExpertHelpInputField
                  size="small"
                  type={"email"}
                  fullWidth
                  margin="normal"
                  name="emailId"
                  value={this.state.emailId}
                  onChange={this.handleInputs}
                  error={this.state.emailIdError !== ""}
                  helperText={this.state.emailIdError}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                />
              </Grid>
              <Grid item md={4} xs={12} sm={4}>
                <FormTitleTypography align={"left"}>
                  Phone Number*&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </FormTitleTypography>
              </Grid>
              <Grid item md={8} xs={12} sm={8}>
                <NumberFormat
                  size="small"
                  fullWidth
                  margin="normal"
                  name="phoneNumber"
                  value={this.state.phoneNumber}
                  onValueChange={async (values) => {
                    const { formattedValue, value } = values;
                    await this.setState({ phoneNumber: value });
                  }}
                  error={this.state.phoneNumberError !== ""}
                  helperText={this.state.phoneNumberError}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  customInput={ExpertHelpInputField}
                  format="###-###-####"
                  mask="_"
                />
              </Grid>
              <Grid item md={4} xs={12} sm={4}>
                <FormTitleTypography align={"left"}>
                  Website* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </FormTitleTypography>
              </Grid>
              <Grid item md={8} xs={12} sm={8}>
                <ExpertHelpInputField
                  size="small"
                  type={"url"}
                  fullWidth
                  margin="normal"
                  name="website"
                  value={this.state.website}
                  onChange={this.handleInputs}
                  error={this.state.websiteError !== ""}
                  helperText={this.state.websiteError}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item md={12} xs={12} sm={12}>
                <Typography alig={"center"} className={classes.scheduleTitle}>
                  <b>Schedule Consultation</b>
                </Typography>
              </Grid>
              <Grid item md={4} xs={12} sm={4}>
                <FormTitleTypography align={"left"}>
                  Date* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </FormTitleTypography>
              </Grid>
              <Grid item md={8} xs={12} sm={8}>
                <ExpertHelpInputField
                  size="small"
                  select
                  fullWidth
                  margin="normal"
                  value={this.state.meetingDate}
                  onChange={this.handleInputs}
                  name="meetingDate"
                  error={this.state.meetingDateError !== ""}
                  helperText={this.state.meetingDateError}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                >
                  <MenuItem key={1} value="">
                    <em>Day</em>
                  </MenuItem>
                  {this.dates.map((date, i) => {
                    return (
                      <MenuItem key={i} value={date.date}>
                        {date.date}
                      </MenuItem>
                    );
                  })}
                  ;
                </ExpertHelpInputField>
              </Grid>
              <Grid item md={4} xs={12} sm={4}>
                <FormTitleTypography align={"left"}>
                  Time (US Eastern Time)* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </FormTitleTypography>
              </Grid>
              <Grid item md={8} xs={12} sm={8}>
                <ExpertHelpInputField
                  size="small"
                  select
                  fullWidth
                  margin="normal"
                  value={this.state.meetingTime}
                  onChange={this.handleInputs}
                  name="meetingTime"
                  error={this.state.meetingTimeError !== ""}
                  helperText={this.state.meetingTimeError}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                >
                  <MenuItem key={1} value="">
                    <em>Time</em>
                  </MenuItem>
                  {requestDemoTime.map((date, i) => {
                    return (
                      <MenuItem key={i} value={date.time}>
                        {date.time}
                      </MenuItem>
                    );
                  })}
                  ;
                </ExpertHelpInputField>
              </Grid>
              <Grid item md={12} xs={12} sm={12}>
                {this.state.saving ? (
                  <Typography align={"center"}>
                    <CircularProgress className={classes.progress} />
                  </Typography>
                ) : (
                  <Fragment>
                    <CurvedBlueBtn
                      fullWidth
                      size={"large"}
                      variant="contained"
                      className={"fullWidth"}
                      onClick={this.handleSubmit}
                    >
                      <Typography className={classes.orangeTxt}>
                        {icons.light}
                      </Typography>
                      &nbsp; Submit
                    </CurvedBlueBtn>
                  </Fragment>
                )}
              </Grid>
            </Fragment>
          )}

          <Grid>
            <Dialog
              fullScreen={fullScreen}
              open={this.state.alert === true}
              onClose={this.clearNotification}
              aria-labelledby="responsive-dialog-title"
            >
              <DialogTitle id="responsive-dialog-title">
                {"Thanks for Your Interest"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {this.state.SuccessNotification}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <PopUpCommonBtn
                  onClick={this.clearNotification}
                  color="primary"
                  autoFocus
                >
                  Ok
                </PopUpCommonBtn>
              </DialogActions>
            </Dialog>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

requestExpertHelpForm.propTypes = {};

const mapDispatchToProps = (dispatch) => ({
  saveInputsToServer: (expertHelpData) => {
    return dispatch(saveRequestExpertHelpData(expertHelpData));
  },
  getClientDetails: () => dispatch(getClientDetailsForExpertHelp()),
});

export default connect(
  null,
  mapDispatchToProps
)(withStyles(demoFormStyles)(requestExpertHelpForm));
