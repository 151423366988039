import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import * as types from "../../actions/actionTypes";
import { Typography } from "@mui/material/index";
import { withStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import {
  NewOrangeContainedBtn,
  NewOutLinedRectCancelBlueBtn,
  WHSBannerButton,
} from "../../components/buttons";
import icons from "../../components/icons";
import googleAnalyticsBanner from "../../assets/img/googleAnalyticsBanner.png";
import WHSBanner from "../../assets/img/website_health_scorecard/WHSBanner.png";
import WHSBannerClose from "../../assets/img/website_health_scorecard/WHSBannerClose.svg";

const styles = (theme) => ({
  bannerbgstyle: {
    backgroundImage: "url(" + WHSBanner + ")",
    // backgroundImage: "url(" + holidayBannerBg + ")",

    backgroundSize: "cover",
    // /static/media/martech_bg_2.e3d6a88a.png);
    backgroundPosition: "bottom",
    [theme.breakpoints.up("sm")]: {
      minHeight: "10vh",
    },
    [theme.breakpoints.up("md")]: {
      minHeight: "20vh",
    },
    [theme.breakpoints.up("lg")]: {
      minHeight: "30vh",
    },
    [theme.breakpoints.up("xl")]: {
      minHeight: "5vh",
    },
  },
  bannerdialogcontent: {
    textAlign: "center",
    color: "#FFFFFF",
  },
  bannerdialogcontenttext: {
    color: "#FFFFFF",
  },
  actionbutton: {
    color: "white",
    marginTop: "1vh",
  },
  // root: {

  // },
});

function GoogleAnalyticsBanner(props) {
  const {
    googleAnalyticsBannerServiceReducer,
    closeGoogleAnalyticsBanner,
    contenttext1,
    buttontext1,
    classes,
  } = props;
  const [bannerOpen, setBannerOpen] = useState(true);
  const onClose = () => {
    setBannerOpen(false);
  };
  const handleDialogToOpen = () => {
    setBannerOpen(true);
  };

  const handleDialogToClose = () => {
    setBannerOpen(false);
    // if (googleAnalyticsBannerServiceReducer.isGoogleAnalyticsBanner) {
    //   closeGoogleAnalyticsBanner();
    // }
  };
  return (
    <div>
      <Dialog open={bannerOpen} onClose={handleDialogToClose}>
        {/* <div style={{ maxWidth: "486px" }} className={classes.bannerbgstyle}>
          <DialogContent className={classes.bannerdialogcontent}>
            <DialogContentText className={classes.bannerdialogcontenttext}>
              <h3>{contenttext1}</h3>
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <NewOutLinedRectCancelBlueBtn
              component={Link}
              // to={"/connections-page"}
              to={"/website-health-scorecard"}
              className={classes.actionbutton}
              // selected={"/connections-page" === props.pathname}
            >
              {buttontext1}
            </NewOutLinedRectCancelBlueBtn>
          </DialogActions>
        </div>  */}
         <div>
          <img
            src={WHSBanner}
            alt="Your Image"
            style={{ width: "100%" }}
          />
          <button
              onClick={handleDialogToClose}
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                background: 'transparent',
                border: 'none',
                fontSize: '20px',
                cursor: 'pointer'
              }}
            >
              <img src={WHSBannerClose} alt="Close Button"/>
            </button>
          <Link to="/website-health-scorecard">
            <WHSBannerButton>
              Check Now
            </WHSBannerButton>
          </Link>
        </div>
      </Dialog>
    </div>
  );
}
const mapStateToProps = (state) => ({
  isGoogleAnalyticsBanner:
    state.googleAnalyticsBannerServiceReducer.isGoogleAnalyticsBanner,
  googleAnalyticsBannerServiceReducer:
    state.googleAnalyticsBannerServiceReducer,
});
const mapDispatchToProps = (dispatch) => ({
  openGoogleAnalyticsBanner: () =>
    dispatch({
      type: types.OPEN_ANALYTICS_BANNER,
    }),
  closeGoogleAnalyticsBanner: () =>
    dispatch({
      type: types.CLOSE_ANALYTICS_BANNER,
    }),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(GoogleAnalyticsBanner));
