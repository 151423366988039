import React, {Component,Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {executeTasks, taskSeenDate, toggleSelection} from "../../actions";
import {idToTaskDataList} from "../../reducers/common";
import {EXPANSION_STATUS, POST_TRIGGER} from "../../actions/actionTypes";
import { Typography } from "@mui/material";


import withStyles from '@mui/styles/withStyles';


/*const CustomDefaultBadgeTypography = withStyles(theme => ({
    root: {
        width: 4,
        // height: "100%",
        background: theme.palette.orangeColor,
        marginTop: -12,
        marginBottom: -12,
    }
}))(Typography);
const CustomDefaultBadgeTypography = withStyles(theme => ({
    root: {
        width: 4,
        // height: "100%",
        background: theme.palette.orangeColor,
        marginTop: -12,
        marginBottom: -12,
    }
}))(Typography);*/

const demoFormStyles = (theme) => ({
    completedStatus: {
        width: 4,
        // height: "100%",
        background: theme.palette.success,
        marginTop: -12,
        marginBottom: -12,
    },
    defaultStatus: {
        width: 4,
        // height: "100%",
        background: theme.palette.orangeColor,
        marginTop: -12,
        marginBottom: -12,
    }
});

class TaskStatus extends React.PureComponent {
    render() {
        const {actionStatus, classes, isAdmin, displayStatus} = this.props;

        return (
            <Fragment>
                {isAdmin === true ?
                    <Typography className={displayStatus == 2 ? classes.completedStatus : classes.defaultStatus}/> :
                    <Typography className={actionStatus == 2 ? classes.completedStatus : classes.defaultStatus}/>
                }
            </Fragment>
        );
    }
}

TaskStatus.propTypes = {};

const mapStateToProps = (state, ownProps) => ({
    actionStatus: ownProps.dataIdList.filter(id => state.taskDataById[id].status === 0).length ? 0 : 2,
    isAdmin: state.taskAnalysis.isAdmin,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, null)(withStyles(demoFormStyles)(TaskStatus));
