import {
  Grid,
  Paper,
  Typography,
  CircularProgress,
  Divider,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import * as PropTypes from "prop-types";
import React, { PureComponent, Fragment } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import classNames from "classnames";
import {
  GetMeters,
  GetMetersWithOutSeconds,
} from "../SEOSiteGraderInternal/templates";
import icons from "../../components/icons";
import NumericLabel from "react-pretty-numbers";
import { buildStyles } from "react-circular-progressbar/dist/index";
import {
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Cell,
  Text,
  ResponsiveContainer,
} from "recharts";

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="xl" />;

let moment = require("moment");

function getSpeedValueBySeconds(seconds, theme) {
  console.log("seconds=", seconds);
  let color = theme.palette.speedMeter.green;
  if (seconds <= 3) {
    color = theme.palette.speedMeter.green;
  } else if (seconds > 3 && seconds <= 5) {
    color = theme.palette.speedMeter.lightGreen;
  } else if (seconds > 5 && seconds <= 8) {
    color = theme.palette.speedMeter.lightCream;
  } else if (seconds > 8 && seconds <= 30) {
    color = theme.palette.speedMeter.red;
  } else {
    color = theme.palette.speedMeter.lightRed;
  }
  return color;
}

function IconAndText({ icon, text, caption }) {
  return (
    <Grid container spacing={2} style={{ padding: "2%" }}>
      <Grid item>{icon}</Grid>
      <Grid item style={{ textAlign: "left" }}>
        <Typography
          align={"left"}
          variant={"body1"}
          color={"textPrimary"}
          gutterBottom
        >
          <b>{text}</b>
        </Typography>
        {caption ? (
          <div>
            <Typography
              variant={"caption"}
              style={{
                fontWeight: "500",
                color: "#1E334E",
                opacity: "0.5",
                fontSize: "14px",
              }}
              align={"left"}
            >
              As of {moment().subtract(1, "days").format("Do MMMM")}
            </Typography>
          </div>
        ) : null}
      </Grid>
    </Grid>
  );
}

class SpeedMeters extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      desktopSpeedWidth: 0,
      mobileSpeedWidth: 0,
    };
  }

  handleMobileValue = (mobileSpeedWidth) => {
    this.setState({ mobileSpeedWidth: mobileSpeedWidth });
  };
  handleDesktopValue = (desktopSpeedWidth) => {
    this.setState({ desktopSpeedWidth: desktopSpeedWidth });
  };

  render() {
    const { classes, theme, width } = this.props;
    const data = [
      {
        name: "Mobile",
        uv: 1,
        Speed: this.props.mobilePageLoadTime,
        amt: 1400,
      },
      {
        name: "Desktop",
        uv: 2,
        Speed: this.props.desktopPageLoadTime,
        amt: 1506,
      },
    ];
    return (
      <Grid>
        <Grid container alignItems={"center"}>
          <Paper className={classes.paper}>
            <IconAndText icon={icons.speedIcon} text={"Speed"} />
            <ResponsiveContainer
              width={width == "sm" || width == "xs" ? 400 : 500}
              height={180}
            >
              <ComposedChart
                layout="vertical"
                // width={500}
                // height={180}
                data={data}
                margin={{
                  top: 0,
                  right: 20,
                  bottom: 20,
                  left: 20,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" horizontal={false} />

                <XAxis
                  type="number"
                  orientation={"top"}
                  // label={props => <Text>{props.text == 10 ? ">10" : "hello"}</Text>}
                  ticks={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                  unit="s"
                />
                <YAxis dataKey="name" type="category" />
                <Tooltip />
                {/*<Legend/>*/}
                <Bar
                  dataKey="Speed"
                  isAnimationActive={true}
                  animationDuration={2000}
                  barSize={50}
                  // background={{ fill: '#eee' }}
                  fill="#413ea0"
                >
                  {data.map((entry, index) => {
                    const color = getSpeedValueBySeconds(entry.Speed, theme);
                    return <Cell fill={color} />;
                  })}
                </Bar>
              </ComposedChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

SpeedMeters.propTypes = {
  classes: PropTypes.any,
  speedScore: PropTypes.number,
  speedScore1: PropTypes.number,
};
const style = (theme) => ({
  dashedLine: {
    border: "1px dashed" + theme.palette.common.darkHash,
    backgroundColor: "unset",
    boxSizing: "border-box",
    borderRadius: 10,
    opacity: 0.8,
    height: 0,
  },
  pieChart: {
    width: "50%",
    height: "50%",
    margin: "0 auto",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "1.2rem",
      paddingRight: "1.2rem",
    },
    [theme.breakpoints.down("xl")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    paddingBottom: "1.2rem",
  },
  meter1: {
    background: theme.palette.speedMeter.green,
  },
  meterText1: {
    color: theme.palette.speedMeter.green,
  },
  meter2: {
    background: theme.palette.speedMeter.lightGreen,
  },
  meterText2: {
    color: theme.palette.speedMeter.lightGreen,
  },
  meter3: {
    background: theme.palette.speedMeter.lightCream,
  },
  meterText3: {
    color: theme.palette.speedMeter.lightCream,
  },
  meter4: {
    background: theme.palette.speedMeter.red,
  },
  meterText4: {
    color: theme.palette.speedMeter.red,
  },
  meter5: {
    background: theme.palette.speedMeter.lightRed,
  },
  meterText5: {
    color: theme.palette.speedMeter.lightRed,
  },
});
export default withWidth()(withStyles(style, { withTheme: true })(SpeedMeters));
