import green from "@mui/material/colors/green";

const drawerWidth = 240;

const taskDataAppBarStyles = (theme) => ({
  newClass: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "90px",
    },
  },
  appBar: {
    background: theme.palette.common.white,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: "0px 2px 14px rgba(0, 0, 0, 0.05)",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolbar: {
    ...theme.mixins.toolbar,
    minHeight: 80,
  },
  menuButton: {
    // marginLeft: theme.spacing(8),
    // marginRight: 20,
  },
  btn: {
    padding: 0,
  },
  space: {
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  appBarGrid: {
    marginLeft: -6,
  },
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    // color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
});
export default taskDataAppBarStyles;
