import React, { Component } from "react";
import PropTypes from "prop-types";
import { CardContent, Card, Typography, Grid } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import Paper from "@mui/material/Paper";
import Footer from "../components/footer";

const styles = (theme) => ({});

class ThankYouPage extends React.PureComponent {
  render() {
    const { activeUser, packageName } = this.props.match.params;
    const showHeader = window.lxrOptions && window.lxrOptions.showHeader;

    return (
      <div>
        <Card className={"fullWidth"}>
          <CardContent>
            <Grid
              container
              justifyContent={"center"}
              alignItems={"center"}
              style={{ minHeight: "65vh" }}
            >
              <Grid item md={12}>
                <Typography component={"h5"} variant={"h5"}>
                  Welcome onboard!
                </Typography>
                <br />
                <Typography component={"p"}>
                  Thank you for subscribing to LXRGuide’s SEO {packageName}{" "}
                  Service pack. Your order confirmation invoice is on its way.
                </Typography>
                {activeUser != true ? (
                  <Typography component={"p"}>
                    Please check your email inbox for a link to create your
                    password for LXRGuide dashboard.
                  </Typography>
                ) : null}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {showHeader && (
          <Card>
            <CardContent style={{ paddingBottom: 0, padding: 0 }}>
              <Paper style={{ boxShadow: "unset" }}>
                <Footer />
              </Paper>
            </CardContent>
          </Card>
        )}
      </div>
    );
  }
}

ThankYouPage.propTypes = {};

export default withStyles(styles)(ThankYouPage);
