import React, { useEffect, useState } from "react";
import JsSideMenuBar from "./jsSideMenu.jsx";
import "./jumpstart.css";
import { useHistory } from "react-router-dom";
import Footer from "../footer/footer";
import { Typography } from "@mui/material";

const events = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress",
];

const JumpstartMain = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(true);
  const history = useHistory();

  const handleLogout = () => {
    sessionStorage.removeItem("jwtToken");
    localStorage.removeItem("expireTime");
    // localStorage.setItem("loggedOut", true);
    sessionStorage.setItem("loggedOut", true);
    history.push("/jumpstart-login");
  };

  const checkForInactivity = () => {
    const expireTime = localStorage.getItem("expireTime");
    if (expireTime < Date.now()) {
      handleLogout();
    }
  };

  const updateExpireTime = () => {
    const expireTime = Date.now() + 20 * 60 * 1000;
    localStorage.setItem("expireTime", expireTime);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      checkForInactivity();
    }, 180000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    updateExpireTime();

    window.addEventListener("click", updateExpireTime);
    window.addEventListener("keypress", updateExpireTime);
    window.addEventListener("scroll", updateExpireTime);
    window.addEventListener("mousemove", updateExpireTime);

    return () => {
      window.removeEventListener("click", updateExpireTime);
      window.removeEventListener("keypress", updateExpireTime);
      window.removeEventListener("scroll", updateExpireTime);
      window.removeEventListener("mousemove", updateExpireTime);
    };
  }, []);

  useEffect(() => {
    document.getElementById("external-footer").style.display = "none";
     document.getElementById("homepage-header").style.display = "none";
  }, []);

  return (
    <div className="mainDivBg">
      <JsSideMenuBar children={children} />
      {/* <span>
        <Footer />
      </span> */}
      {/* <Typography align="center" fontSize="1rem" color={"#7F7F7F"} paddingLeft="100px" paddingBottom="10px">
        <b>
          &copy; {new Date().getFullYear()}
          &nbsp;NetElixir Inc, All Rights Reserved.
        </b>
      </Typography> */}
    </div>
    
  );
};

export default JumpstartMain;
