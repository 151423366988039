import React from "react";
import withStyles from "@mui/styles/withStyles";
import * as _ from "lodash";
import AdGroupFrom from "./adgroup-from";
import CommonForm from "./common-form";
import CommonFrom1 from "./common-form-1";
import {
  Grid,
  Button,
  Card,
  CardContent,
  CardHeader,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import SvgIcon from "@mui/material/SvgIcon";
import icons from "../icons";
import { calculateSteps } from "./templates";

export const BackButton = withStyles((theme) => ({
  root: {
    boxShadow: "unset",
    backgroundColor: "#ffffff",
    color: "#333333",
  },
}))(Button);
export const CustomGrid = withStyles((theme) => ({
  item: {
    border: "1px solid #8F99A7",
    borderRadius: 3,
    // [theme.breakpoints.down('md')]: {
    //     overflowX: "scroll",
    //     width: "90%"
    // },
  },
}))(Grid);
export const CustomHeader = withStyles((theme) => ({
  root: {
    borderBottom: "1px solid #8F99A7",
    // padding: 5
  },
  avatar: {
    marginRight: 2,
  },
}))(CardHeader);

export const CustomCardContent = withStyles((theme) => ({
  root: {
    padding: 15,
  },
}))(CardContent);

/*const CustomStepper = withStyles(theme => ({
    root: {
        boxShadow: " 0px 0px 10px 0px rgba(0,0,0,0.33)",
        padding: 10
    },
}))(Stepper);
;
const CustomStep =withStyles(theme =>({
    root:{
        padding:15
    }
}))(Step);*/
const CustomLabel = withStyles((theme) => ({
  root: {
    padding: 20,
  },
}))(StepLabel);

const style = (theme) => ({
  paper: {
    border: "1px solid #8F99A7",
    borderRadius: 3,
    [theme.breakpoints.down("lg")]: {
      overflowX: "scroll",
      width: "80%",
    },
  },
});

const Campaigns = [
  {
    value: "Campaign 01",
    label: "Campaign 01",
  },
  {
    value: "Campaign 02",
    label: "Campaign 02",
  },
  {
    value: "Campaign 03",
    label: "Campaign 03",
  },
  {
    value: "Campaign 04",
    label: "Campaign 04",
  },
  {
    value: "Campaign 01",
    label: "Campaign 01",
  },
  {
    value: "Campaign 02",
    label: "Campaign 02",
  },
  {
    value: "Campaign 03",
    label: "Campaign 03",
  },
  {
    value: "Campaign 04",
    label: "Campaign 04",
  },
];

function ServiceDetails(props) {
  const getStepContent = (step) => {
    console.log("getStepContent, step=", step);
    const { type, label } = step;
    const { parentToggle, allInputs, activeStep, handleMultiPageAndSteppers } =
      props;
    // console.log("steps", steps);
    let { steps } = props;
    steps = calculateSteps(steps);

    switch (type) {
      case "Ad Group":
        return (
          <AdGroupFrom
            key={label}
            label={label}
            type={type}
            handleMultiPageAndSteppers={(preNext, parentToggle) =>
              handleMultiPageAndSteppers(label, preNext, parentToggle)
            }
            activeStep={activeStep}
            steps={steps}
            handleNext={parentToggle}
          />
        );
        break;
      case "Ad Copy":
      case "Sitelink":
      case "Callout":
        return (
          <CommonForm
            key={label}
            label={label}
            type={type}
            handleMultiPageAndSteppers={(preNext, parentToggle) =>
              handleMultiPageAndSteppers(label, preNext, parentToggle)
            }
            activeStep={activeStep}
            steps={steps}
            handleNext={parentToggle}
          />
        );
        break;
      case "Structure Snippet":
      case "Price":
        return (
          <CommonFrom1
            key={label}
            label={label}
            type={type}
            handleMultiPageAndSteppers={(preNext, parentToggle) =>
              handleMultiPageAndSteppers(label, preNext, parentToggle)
            }
            activeStep={activeStep}
            steps={steps}
            handleNext={parentToggle}
          />
        );
        break;
    }
  };

  const { classes, handlePreviuos, activeStep } = props;
  console.log("The main props are", props);

  console.log("activeStep=====>", activeStep);
  let { steps } = props;

  console.log("steps coming in props", steps);

  steps = calculateSteps(steps);

  console.log("steps coming from calculateSteps", steps);
  // debugger;
  return (
    <Grid item sm={12} xs={12} md={10}>
      <Card className={classes.paper}>
        <CustomHeader
          avatar={
            <BackButton
              variant="fab"
              mini
              aria-label="Back"
              onClick={handlePreviuos}
            >
              {icons.ChevronArrowLeft}
            </BackButton>
          }
          title={"Provide below information to proceed."}
        />
        <CustomCardContent>
          <Grid container>
            <Grid item className={"fullWidth"} style={{ overflowX: "auto" }}>
              <Stepper activeStep={activeStep}>
                {steps &&
                  steps.map((step, index) => {
                    return (
                      <Step key={step.label}>
                        <StepLabel>{step.label}</StepLabel>
                      </Step>
                    );
                  })}
              </Stepper>
            </Grid>
            <Grid item className={"fullWidth"}>
              <Grid container>
                <Grid item md={12} style={{ minHeight: "65vh" }}>
                  {getStepContent(steps[activeStep])}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CustomCardContent>
      </Card>
    </Grid>
  );
}

export default withStyles(style)(ServiceDetails);
