import * as types from "./actionTypes";
import {fetchPerfData} from "./index";
import {KpiTypeNumbers} from "../selectors/dashboard-selectors";

export function fetchActivityReport(historyId, isPpIdChanged, kpiTypeUi) {
    return async function (dispatch, getState) {
        console.log("fetching Activity Report Data");
        console.log("kpiTypeUi=,",kpiTypeUi);
        setTimeout(async () => {
            try {
                dispatch({
                    type: types.ACTIVITY_REPORT_REQUESTED,
                    isPpIdChanged: isPpIdChanged
                });
                let url = "/activity-report.json?historyId=" + historyId + "&selectedKpiMetric=" + KpiTypeNumbers[kpiTypeUi];
                let response = await fetch(url, {
                    headers: {
                        'Accept': 'application/json',
                    },
                    'credentials': 'include'
                });
                let data = await response.json();
                data = {
                    ...data,
                    selectedDateRange: historyId
                }
                console.log("ACTIVITY REPORT", data);
                await dispatch({
                    type: types.ACTIVITY_REPORT_RECEIVED,
                    data
                });
                return data;
                /* if (data.error && data.error == "") {
                     await this.props.fetchPerfData();
                 }*/
            } catch (e) {
                dispatch({type: types.ACTIVITY_REPORT_FAILED});
            }
        }, 0);
    }
}

export function toggleDateRange(historyId) {
    console.log("historyId=", historyId);
    return async (dispatch) => {
        await dispatch({
                type: types.TOGGLE_WEEKLY_DATE_RANGE,
                historyId
            }
        );
        // dispatch(fetchActivityReport(historyId, false));
    };
}

export function fetchTaskDataByTaskId(taskId, taskTypeId, analysisTypeId) {
    return async function (dispatch) {
        console.log("fetching User info data");
            try {
                dispatch({
                    type: types.ACTIVITY_REPORT_TASK_DATA_REQUESTED,
                });
                let url = "/fetch-task-data.json?taskId=" + taskId + "&taskTypeId=" + taskTypeId + "&analysisTypeId=" +analysisTypeId;
                let response = await fetch(url, {
                    headers: {
                        'Accept': 'application/json',
                    },
                    'credentials': 'include'
                });
                let data = await response.json();
                console.log("ACTIVITY REPORT-123", data);
                await dispatch({
                    type: types.ACTIVITY_REPORT_TASK_DATA_RECEIVED,
                    data,
                    taskId: taskId
                });
                return data;
            } catch (e) {
                dispatch({type: types.ACTIVITY_REPORT_TASK_DATA_FAILED});
            }
    }
}