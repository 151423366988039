import {
  CardContent,
  Grid,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import * as _ from "lodash";
import React, { useEffect, useState } from "react";
//connect gives us state by default.
import { connect } from "react-redux";
import { fetchAutoTaskData, saveAutoTaskData } from "../../actions";
import { fetchUserProfileDetails } from "../../actions/fetchUserProfile";
import {
  NewOrangeContainedBtn,
  NewOutLinedRectCancelBlueBtn,
} from "../../components/buttons";
import Savings from "../../components/task/savings";
import {
  ErrorTypography
} from "../../components/typography";
import MySnackbarWrapperAutoTask from "../snackbars";

function AutoPostingListTask(props) {
  const {
    userDetails,
    fetchAutoTaskData,
    autoPostingDataReducers,
    classes,
    fetchUserProfileDetails,
    saveAutoTaskData,
    saveAutoPostingDataReducers,
  } = props;


  const AutoTaskPostkeysArray = Object.keys(autoPostingDataReducers);

  const [selectedTaskIds, setSelectedTaskIds] = useState(
    AutoTaskPostkeysArray.includes("selTaskTypeIds")
      ? [...autoPostingDataReducers.selTaskTypeIds]
      : []
  );

  const saveAutoPostingDataReducersResponse =
    saveAutoPostingDataReducers.response;
  

  const allTaskTypeIdsObject = autoPostingDataReducers.allTaskTypeIds;

  //
  let allTaskTypeIdsArray = [];
  //all tasks
  _.forEach(allTaskTypeIdsObject, (d, i) =>
    allTaskTypeIdsArray.push({ key: i, value: d })
  );

  //=============================================================
  let allTaskTimeEstimateArray = [];
  const allTaskTimeEstimateObject = autoPostingDataReducers.allTaskTimeEstimate;

  //all tasks time estimate
  _.forEach(allTaskTimeEstimateObject, (t, i) =>
    allTaskTimeEstimateArray.push({ key: i, value: t })
  );
  //now combine two arrays allTaskTimeEstimateArray and allTaskTypeIdsArray
  var array2idx = allTaskTimeEstimateArray.reduce(function (map, value) {
    map[value.id] = value;
    return map;
  }, {});
  var new_array = [];
  for (var i = 0; i < allTaskTypeIdsArray.length; i++) {
    var v2 = allTaskTimeEstimateArray[i].value;
    // console.log("v2===>",v2)
    if (v2) {
      new_array.push({
        id: allTaskTypeIdsArray[i].key,
        value: allTaskTypeIdsArray[i].value,
        timeestimate: v2,
      });
    }
  }
  //==============================================================

  //selected tasks
  if (selectedTaskIds.length > 0)
    _.forEach(
      selectedTaskIds,
      (d, i) => (selectedTaskIds[i] = selectedTaskIds[i].toString())
    );

  const FormLableStyle = {
    display: "block",
    fontFamily: "Lato",

    fontWeight: 700,
  };
  const TaskListStyle = {
    fontWeight: 700,
    fontSize: "20px",
    paddingBottom: "1rem",
  };
  const ButtonStyle = {
    textAlign: "Right",
  };
  const TextCenter = {
    textAlign: "Center",
    fontSize: "16px",
  };
  const FontSizeAutoPostSave = {
    fontSize: "16px",
  };

  const SavingStyle = {
    paddingLeft: "7px",
    display: "inline-block",
    verticalAlign: "3px",
  };
  const CustomKeywordsTableCellHead = withStyles((theme) => ({
    head: {
      width: "50%",
      // background: "rgba(242, 243, 249, 0.6)",
      border: "1px solid #E3E6F4",
      boxSizing: "border-box",
      borderRadius: "10px 10px 0px 0px",
      color: theme.palette.text.primary,
      fontWeight: "500",
      fontSize: theme.typography.pxToRem(14),
      fontFamily: "Lato",
      [theme.breakpoints.down("xl")]: {
        padding: 5,
      },
    },
    // label
  }))(TableCell);
  const CustomFormControlLabelHead = withStyles((theme) => ({
    label: {
      fontSize: "0.875rem",
    },
  }))(FormControlLabel);

  useEffect(() => {
    if (userDetails.selectedAccountInfo) {
      const ppcId = userDetails.selectedAccountInfo.ppcId;
      console.log("UserDetails ppc ID in autopostinglist::", ppcId);
      fetchAutoTaskData(ppcId);
    } else {
      fetchUserProfileDetails();
    }
    // loadActivity();
  }, []);

  useEffect(() => {
    if (userDetails.selectedAccountInfo) {
      const ppcId = userDetails.selectedAccountInfo.ppcId;
      console.log("UserDetails ppc ID in autopostinglist::", ppcId);
      fetchAutoTaskData(ppcId);
    }
  }, [userDetails]);

  useEffect(() => {
    if (AutoTaskPostkeysArray.includes("selTaskTypeIds")) {
      setSelectedTaskIds([...autoPostingDataReducers.selTaskTypeIds]);
    }
  }, [autoPostingDataReducers, userDetails]);

  const handleCheckBoxSelectedChange = (event, taskId) => {
    let localSelectedTaskIds = [...selectedTaskIds];
    console.log("before localSelectedTaskIds----->", localSelectedTaskIds);
    if (localSelectedTaskIds.includes(taskId.toString())) {
      localSelectedTaskIds = _.remove(localSelectedTaskIds, function (n) {
        return n.toString() !== taskId.toString();
      });
      setSelectedTaskIds([...localSelectedTaskIds]);
      console.log("if after localSelectedTaskIds----->", localSelectedTaskIds);
    } else {
      localSelectedTaskIds.push(taskId.toString());
      setSelectedTaskIds([...localSelectedTaskIds]);
      // console.log(
      //   "else after localSelectedTaskIds----->",
      //   localSelectedTaskIds
      // );
    }
  };

  let stringSelTaskTypeIds = [];
  if (AutoTaskPostkeysArray.includes("selTaskTypeIds")) {
    if (autoPostingDataReducers.selTaskTypeIds.length > 0) {
      _.forEach(
        autoPostingDataReducers.selTaskTypeIds,
        (d, i) =>
          (stringSelTaskTypeIds[i] =
            autoPostingDataReducers.selTaskTypeIds[i].toString())
      );
    }
  }


  // console.log("check stringSelTaskTypeIds======>", stringSelTaskTypeIds);
  // console.log(
  //   "Check----->",
  //   _.xor(selectedTaskIds, stringSelTaskTypeIds).length === 0
  // );

  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");

  // const openAlert=()=>{setOpen(true)}

  const onClose = () => {
    setOpen(false);
  };

  const handleThankyouMsg = async (response) => {
    console.log("handleThankyouMsg response-->", response);
    // if(response === true){
    setMessage("Thank you! Your selection has been successfully updated.");
    setOpen(true);
    // }
    // else{
    //   setMessage("Task failed to update.")
    // setOpen(true)
    // }
  };
  return (
    <div className="checkList">
      {/* <Card> */}
      <CardContent>
        <Grid container>
          {/* <Grid item md={1}/> */}
          <Grid item md={12}>
            <div>
              {/* <Paper className={classes.tdPaper}> */}
              <Grid container spacing={0}>
                <Grid item md={1} />
                <Grid item md={10}>
                  <Typography
                    variant={"h4"}
                    color={"textPrimary"}
                    // className={classes.campaignTitle}
                    // style={{ maxWidth: "auto" }}
                    style={TaskListStyle}
                  >
                    Tasks List
                  </Typography>
                  <Table>
                    {new_array.length > 1 ? (
                      <TableHead>
                        {new_array.map((d, i) => {
                          return (
                            <TableRow index={d["id"]}>
                              <CustomKeywordsTableCellHead>
                                <FormGroup row style={FormLableStyle}>
                                  <Checkbox
                                    color="secondary"
                                    checked={selectedTaskIds.includes(
                                      // d["key"].toString()
                                      d["id"].toString()
                                    )}
                                    onChange={(e) => {
                                      handleCheckBoxSelectedChange(e, d["id"]);
                                    }}
                                    id={d["id"]}
                                  />
                                  {d["value"]} |
                                  <span style={SavingStyle}>
                                    &nbsp;
                                    <Savings
                                      timeSavedEstimate={d["timeestimate"]}
                                    ></Savings>{" "}
                                  </span>
                                </FormGroup>
                              </CustomKeywordsTableCellHead>
                            </TableRow>
                          );
                        })}
                      </TableHead>
                    ) : null}
                  </Table>
                </Grid>
                <Grid item md={1} />
              </Grid>
              {/* </Paper> */}
            </div>
          </Grid>
          {/* <Grid item md={1}/> */}
        </Grid>
      </CardContent>
      <Grid container>
        <Grid item md={10} style={ButtonStyle}>
          <NewOutLinedRectCancelBlueBtn
            style={FontSizeAutoPostSave}
            disabled={_.xor(selectedTaskIds, stringSelTaskTypeIds).length === 0}
            onClick={(e) => {
              console.log("cancel");

              setSelectedTaskIds([...autoPostingDataReducers.selTaskTypeIds]);
            }}
          >
            Reset
          </NewOutLinedRectCancelBlueBtn>
        </Grid>
        <Grid item md={2}>
          <NewOrangeContainedBtn
            variant="contained"
            disabled={_.xor(selectedTaskIds, stringSelTaskTypeIds).length === 0}
            onClick={async (e) => {
              await saveAutoTaskData(
                userDetails.selectedAccountInfo.ppcId,
                selectedTaskIds
              );

              await fetchAutoTaskData(userDetails.selectedAccountInfo.ppcId);
              handleThankyouMsg(saveAutoPostingDataReducersResponse);
            }}
          >
            Save
          </NewOrangeContainedBtn>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={12}>
          {saveAutoPostingDataReducers.response === true ? (
            <React.Fragment>
              <MySnackbarWrapperAutoTask
                open={open}
                onClose={onClose}
                message={message}
                verticalAlign={"bottom"}
                horizontalAlign={"right"}
                duration={6000}
                variant={"success"}
              />
            </React.Fragment>
          ) : saveAutoPostingDataReducers.response === false ? (
            <ErrorTypography style={TextCenter}>
              Task failed to update.
            </ErrorTypography>
          ) : null}
        </Grid>
      </Grid>
    </div>
  );
}
const mapStateToProps = (state) => ({
  userDetails: state.userInformation,
  autoPostingDataReducers: state.autoPostingDataReducers,
  saveAutoPostingDataReducers: state.saveAutoPostingDataReducers,
});
const mapDispatchToProps = (dispatch) => ({
  fetchAutoTaskData: (ppcId) => dispatch(fetchAutoTaskData(ppcId)),
  fetchUserProfileDetails: () => dispatch(fetchUserProfileDetails()),
  saveAutoTaskData: (ppcId, selectedTaskIds) =>
    dispatch(saveAutoTaskData(ppcId, selectedTaskIds)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AutoPostingListTask);

// export default (connect(mapStateToProps, mapDispatchToProps)(withStyles(accountSettingsStyles, {withTheme: true})(ClientSettings)));
// In this connect method we are giving requests to the action(index.js) and states(reducers)

// connect()
