import React, { Fragment, useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import icons from "../../components/icons";
import {
  SectionInfoWHS,SubSectionHeading,GoodSubSectionComment,ImproveSubSectionComment, ErrorSubSectionComment, NoDataSubSectionComment
} from "../../components/typography";
import "./WebsiteHealthScoreCard.css";

function Security_WHC({ securityData }) {

  console.log("inside securityData==>", securityData);
  const [validSSLCertificate, setValidSSLCertificate] = useState(
    securityData ? securityData.validSSLCertificate : null
  );
  const [toxicBacklinks, setToxicBacklinks] = useState(
    securityData ? securityData.toxicBacklinks : null
  );
  const [numberOfhttpLinks, setNumberOfhttpLinks] = useState(
    securityData ? securityData.numberOfhttpLinks : null
  );
  const [privacyPolicy, setPrivacyPolicy] = useState(
    securityData ? securityData.privacyPolicy : null
  );
  const [securityHeaderAvailability, setsecurityHeaderAvailability] = useState(
    securityData ? securityData.securityHeaderAvailability : null
  );
  const [strictSecurityPolicy, setStrictSecurityPolicy] = useState(
    securityData ? securityData.strictTransportSecurity : null
  );
  const [securityScore, setSecurityScore] = useState(
    securityData ? securityData.securityScore : null
  );

  //Description
  const [validSSLCertificateDescription, setValidSSLCertificateDescription] = useState(
    securityData ? securityData.validSSLCertificateDescription : null
  );

  const [toxicBacklinksDescription, setToxicBacklinksDescription] = useState(
    securityData ? securityData.toxicBacklinksDescription : null
  );
  
  const [numberOfhttpLinksDescription, setnumberOfhttpLinksDescription] = useState(
    securityData ? securityData.numberOfhttpLinksDescription : null
  );

  const [privacyPolicyDescription, setPrivacyPolicyDescription] = useState(
    securityData ? securityData.privacyPolicyDescription : null
  );

  const [securityHeaderAvailabilityDescription, setSecurityHeaderAvailabilityDescription]= useState(
    securityData ? securityData.securityHeaderAvailabilityDescription : null
  );

  const [strictTransportSecurityDescription, setStrictTransportSecurityDescription]= useState(
    securityData ? securityData.strictTransportSecurityDescription : null
  );

  const GOOD = "Looks good";
  const IMPROVED = "Can be improved";
  const ERROR = "Critical error found!";
  const NO_DATA = "Not available";

  useEffect(() => {
    if (securityData) {
      setValidSSLCertificate(
        securityData.validSSLCertificate !== undefined
          ? securityData.validSSLCertificate
          : null
      );
      setToxicBacklinks(
        securityData.toxicBacklinks !== undefined
          ? securityData.toxicBacklinks
          : null
      );
      setNumberOfhttpLinks(
        securityData.numberOfhttpLinks !== undefined
          ? securityData.numberOfhttpLinks
          : null
      );
      setPrivacyPolicy(
        securityData.privacyPolicy !== undefined
          ? securityData.privacyPolicy
          : null
      );
      setsecurityHeaderAvailability(
        securityData.securityHeaderAvailability !== undefined
          ? securityData.securityHeaderAvailability
          : null
      );
      setStrictSecurityPolicy(
        securityData.strictTransportSecurity !== undefined
          ? securityData.strictTransportSecurity
          : null
      );
      setSecurityScore(
        securityData.securityScore ? securityData.securityScore : null
      );
      setValidSSLCertificateDescription(
        securityData.validSSLCertificateDescription !== undefined
          ? securityData.validSSLCertificateDescription
          : null 
      )
      setSecurityHeaderAvailabilityDescription(
        securityData.securityHeaderAvailabilityDescription !== undefined
          ? securityData.securityHeaderAvailabilityDescription
          : null   
      )
      setStrictTransportSecurityDescription(
        securityData.strictTransportSecurityDescription !== undefined
        ? securityData.strictTransportSecurityDescription
        : null   
      )
      setToxicBacklinksDescription(
        securityData.toxicBacklinksDescription !== undefined
        ? securityData.toxicBacklinksDescription
        : null        
      )
      setPrivacyPolicyDescription(
        securityData.privacyPolicyDescription !== undefined
        ? securityData.privacyPolicyDescription
        : null           
      )
      setnumberOfhttpLinksDescription(
        securityData.numberOfhttpLinksDescription !== undefined
        ? securityData.numberOfhttpLinksDescription
        : null      
      )
    }
  }, [securityData]);
  console.log(
    "The all values are in security page",
    validSSLCertificate,
    toxicBacklinks,
    numberOfhttpLinks,
    privacyPolicy,
    securityHeaderAvailability,
    strictSecurityPolicy
  );

  const securityInfo = "Security refers to protecting the website and its users from unauthorized access, data breaches, and malicious activities. It involves implementing measures such as encryption, secure authentication, and regular security updates to safeguard user information and maintain the integrity of the website. Security should be a top priority as a matter of protecting users, the business, and the brand reputation.";

  return (
    <Fragment>
      <Grid container direction="row" className="sectionInfo" alignItems="center">
     <Grid item xs={2} sm={2} md={1} lg={1} xl={1} className="grid-item-css">
          {icons.WHSSecurityInfo}
        </Grid>
        <Grid item xs={10} sm={10} md={11} lg={11} xl={11}>
          <SectionInfoWHS>{securityInfo}</SectionInfoWHS>
        </Grid>
      </Grid>

      <Grid container direction="row" justify="space-between" alignItems="flex-start" columnSpacing={3} style={{marginTop:"2%"}}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          {/* Valid SSL/TSL Certificate*/}
              <div className="subsection-css">
                <SubSectionHeading>Valid SSL/TSL Certificate</SubSectionHeading>
                <div className={validSSLCertificate ? "looksGoodBgColor" : !validSSLCertificate ? "errorBgColor" : "noDataBgColor"}>
                 { validSSLCertificate ? (
                  <GoodSubSectionComment>{GOOD}</GoodSubSectionComment>
                  ): !validSSLCertificate ? (
                  <ErrorSubSectionComment>{ERROR}</ErrorSubSectionComment>
                  ) : (
                    <NoDataSubSectionComment>{NO_DATA}</NoDataSubSectionComment>
                  )
                }
               </div>
              </div>
              <div className={validSSLCertificate ? "subsection-info-good" : !validSSLCertificate ? "subsection-info-error" : "subsection-info-noData"}>
                <Typography variant="subtitle2" className="wordBreakTypography">{validSSLCertificateDescription}</Typography>
              </div>                          
            {/* Privacy Policy Page */}
            <div className="subsection-css">
               <SubSectionHeading>Privacy Policy Page</SubSectionHeading>
               <div className={privacyPolicy ? "looksGoodBgColor" : !privacyPolicy ? "errorBgColor" : "noDataBgColor"}>
                 { privacyPolicy ? (
                  <GoodSubSectionComment>{GOOD}</GoodSubSectionComment>
                  ): !privacyPolicy ? (
                    <ErrorSubSectionComment>{ERROR}</ErrorSubSectionComment>
                  ): (
                    <NoDataSubSectionComment>{NO_DATA}</NoDataSubSectionComment>
                  )
                }
               </div>
              </div>
              <div className={privacyPolicy ? "subsection-info-good" : !privacyPolicy ? "subsection-info-error" : "subsection-info-noData"}>
              <Typography variant="subtitle2" className="wordBreakTypography">{privacyPolicyDescription}</Typography>
              </div>     
         </Grid>
         <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            {/* HTTP links on HTTPS Pages*/}
            <div className="subsection-css">
               <SubSectionHeading>HTTP links on HTTPS Pages</SubSectionHeading>
               <div className={numberOfhttpLinks == 0 ? "looksGoodBgColor" : numberOfhttpLinks > 0 ? "errorBgColor" : "noDataBgColor"}>
                 { numberOfhttpLinks == 0 ? (
                  <GoodSubSectionComment>{GOOD}</GoodSubSectionComment>
                  ):  numberOfhttpLinks > 0 ? (
                    <ErrorSubSectionComment>{ERROR}</ErrorSubSectionComment>
                  ): (
                    <NoDataSubSectionComment>{NO_DATA}</NoDataSubSectionComment>
                  )}
               </div>
              </div>
              <div className={numberOfhttpLinks == 0 ? "subsection-info-good" : numberOfhttpLinks > 0 ? "subsection-info-error" : "subsection-info-noData"}>
                  <Typography variant="subtitle2" className="wordBreakTypography">{numberOfhttpLinksDescription}</Typography>
              </div>

            {/* Security Header Availability*/}
            <div className="subsection-css">
               <SubSectionHeading>Security Header Availability</SubSectionHeading>
               <div className={securityHeaderAvailability ? "looksGoodBgColor" : !securityHeaderAvailability ? "errorBgColor" : "noDataBgColor"}>
                 {securityHeaderAvailability ? (
                  <GoodSubSectionComment>{GOOD}</GoodSubSectionComment>
                  ): !securityHeaderAvailability ? (
                    <ErrorSubSectionComment>{ERROR}</ErrorSubSectionComment>
                  ):(
                    <NoDataSubSectionComment>{NO_DATA}</NoDataSubSectionComment>
                  )
                }
               </div>
              </div>
              <div className={securityHeaderAvailability ? "subsection-info-good" : !securityHeaderAvailability ? "subsection-info-error" : "subsection-info-noData"}>
                  <Typography variant="subtitle2" className="wordBreakTypography">{securityHeaderAvailabilityDescription}</Typography>
              </div>
         </Grid>
         <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          {/* Strict Security Policy */}
          <div className="subsection-css">
            <SubSectionHeading>Strict Security Policy</SubSectionHeading>
            <div className={strictSecurityPolicy == true ? "looksGoodBgColor" : strictSecurityPolicy == false ? "errorBgColor" : "noDataBgColor"}>
              { strictSecurityPolicy == true ? (
                <GoodSubSectionComment>{GOOD}</GoodSubSectionComment>
                ): strictSecurityPolicy == false ? (
                  <ErrorSubSectionComment>{ERROR}</ErrorSubSectionComment>
                ): (
                  <NoDataSubSectionComment>{NO_DATA}</NoDataSubSectionComment>
                )
              }
            </div>
          </div>
          <div className={strictSecurityPolicy == true ? "subsection-info-good" : strictSecurityPolicy == false ? "subsection-info-error" : "subsection-info-noData" }>
            <Typography variant="subtitle2" className="wordBreakTypography">{strictTransportSecurityDescription}</Typography>
          </div> 
          {/* Toxic Backlinks */} 
          <div className="subsection-css">
            <SubSectionHeading>Toxic Backlinks</SubSectionHeading>
            <div className={toxicBacklinks == 0 ? "looksGoodBgColor" : toxicBacklinks > 0 && toxicBacklinks <= 40 ? "improvedBgColor" : toxicBacklinks > 40 ? "errorBgColor" : "noDataBgColor"}>
            { toxicBacklinks == 0  ? (
              <GoodSubSectionComment>{GOOD}</GoodSubSectionComment>
              ): toxicBacklinks > 0 && toxicBacklinks <= 40 ? (
              <ImproveSubSectionComment>{IMPROVED}</ImproveSubSectionComment>
              ): toxicBacklinks > 40 ? (
                <ErrorSubSectionComment>{ERROR}</ErrorSubSectionComment>
                ) : (
                  <NoDataSubSectionComment>{NO_DATA}</NoDataSubSectionComment>
                )
             }
            </div>
          </div>
          <div className={toxicBacklinks == 0 ? "subsection-info-good" : toxicBacklinks > 0 && toxicBacklinks <= 40 ? "subsection-info-improve" : toxicBacklinks > 40 ? "subsection-info-error" : "subsection-info-noData"}>
            <Typography variant="subtitle2" className="wordBreakTypography">{toxicBacklinksDescription}</Typography>
          </div>   
         </Grid>
        </Grid>
    </Fragment>
  );
}
export default Security_WHC;
