import React from "react";
import icons from "../components/icons";
import * as PropTypes from "prop-types";
import { Tooltip } from "@mui/material";

import withStyles from '@mui/styles/withStyles';

class CustomToolTip extends React.PureComponent {
  state = {
    arrowRef: null,
  };

  handleArrowRef = (node) => {
    this.setState({
      arrowRef: node,
    });
  };

  render() {
    const { classes, children, verticalAlign } = this.props;
    return (
      <Tooltip
        placement={"right"}
        title={
          <div className={classes.spacing}>
            {children}
            <span className={classes.arrow} ref={this.handleArrowRef} />
          </div>
        }
        style={{}}
        classes={{
          tooltip: classes.bootstrapTooltip,
          popper: classes.bootstrapPopper,
          tooltipPlacementLeft: classes.bootstrapPlacementLeft,
          tooltipPlacementRight: classes.bootstrapPlacementRight,
          tooltipPlacementTop: classes.bootstrapPlacementTop,
          tooltipPlacementBottom: classes.bootstrapPlacementBottom,
        }}
        PopperProps={{
          popperOptions: {
            modifiers: {
              arrow: {
                enabled: Boolean(this.state.arrowRef),
                element: this.state.arrowRef,
              },
            },
          },
        }}
      >
        {verticalAlign ? (
          <span style={{ verticalAlign: "middle" }}>{icons.infoIcon}</span>
        ) : (
          icons.infoIcon
        )}
      </Tooltip>
    );
  }
}

function arrowGenerator(color) {
  return {
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: "-0.95em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "0 1em 1em 1em",
        borderColor: `transparent transparent ${color} transparent`,
        boxShadow: "4px 4px 18px rgba(0, 0, 0, 0.06)",
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: "-0.95em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "1em 1em 0 1em",
        borderColor: `${color} transparent transparent transparent`,
        boxShadow: "4px 4px 18px rgba(0, 0, 0, 0.06)",
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: "-0.95em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 1em 1em 0",
        borderColor: `transparent ${color} transparent transparent`,
        boxShadow: "4px 4px 18px rgba(0, 0, 0, 0.06)",
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: "-0.95em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 0 1em 1em",
        boxShadow: "4px 4px 18px rgba(0, 0, 0, 0.06)",
        borderColor: `transparent transparent transparent ${color}`,
      },
    },
  };
}

const styles = (theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  lightTooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.white,
    // boxShadow: theme.shadows[1],
    fontSize: 12,
    border: "1px solid " + theme.palette.common.border,
    boxShadow: "4px 4px 18px rgba(0, 0, 0, 0.06)",
    opacity: "1",
  },
  arrowPopper: arrowGenerator(theme.palette.grey[700]),
  arrow: {
    position: "absolute",
    fontSize: 6,
    width: "3em",
    height: "3em",
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid",
    },
  },
  bootstrapPopper: arrowGenerator(theme.palette.common.white),
  bootstrapTooltip: {
    backgroundColor: theme.palette.common.white,
    border: "1px solid " + theme.palette.common.border,
    boxShadow: "4px 4px 18px rgba(0, 0, 0, 0.06)",
    maxWidth: 500,
    zIndex: "9999999",
    // textOverflow:
  },
  bootstrapPlacementLeft: {
    margin: "0 8px",
  },
  bootstrapPlacementRight: {
    margin: "0 8px",
  },
  bootstrapPlacementTop: {
    margin: "8px 0",
  },
  bootstrapPlacementBottom: {
    margin: "8px 0",
  },
  htmlPopper: arrowGenerator(theme.palette.common.white),
  htmlTooltip: {
    backgroundColor: theme.palette.common.white,
    // color: theme.palette.common.white,
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid " + theme.palette.common.border,
    boxShadow: "4px 4px 18px rgba(0, 0, 0, 0.06)",
    "& b": {
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  spacing: {
    padding: theme.spacing(1),
    // width:"100%"
  },
});
CustomToolTip.propTypes = {
  className: PropTypes.any,
  ref: PropTypes.any,
  popper: PropTypes.any,
  value: PropTypes.any,
};

export default withStyles(styles)(CustomToolTip);
