import React, { Component } from "react";
import PropTypes from "prop-types";
import orange from "@mui/material/colors/orange";
import deepOrange from "@mui/material/colors/deepOrange";
import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { adminUserTable } from "../../actions";
import { connect } from "react-redux";

const userTypeStyles = (theme) => ({
  colorSwitchBase: {
    color: deepOrange[400],
    "&$colorChecked": {
      color: deepOrange[400],
      "& + $colorBar": {
        backgroundColor: orange[500],
      },
    },
  },
  colorBar: {},
  colorChecked: {},
});

class UserTypeToggle extends React.PureComponent {
  constructor(Props) {
    super();
  }

  handleInputs = (event) => {
    const {
      currentPage,
      sorting,
      searchTerm,
      channelFilter,
      statusFilter,
      userTypeToggle,
    } = this.props.adminUserTable;
    console.log("toggle=", userTypeToggle);
    this.props.getAdminUserTableData(
      currentPage,
      sorting,
      true,
      statusFilter,
      searchTerm,
      channelFilter,
      !userTypeToggle
    );
  };

  render() {
    const { userTypeToggle } = this.props.adminUserTable;
    const { classes } = this.props;
    return (
      <div>
        <FormGroup row>
          <FormControlLabel
            control={
              <Switch
                color="secondary"
                checked={userTypeToggle}
                onChange={this.handleInputs}
                value={"userTypeToggle"}
                classes={{
                  switchBase: classes.colorSwitchBase,
                  checked: classes.colorChecked,
                  bar: classes.colorBar,
                }}
              />
            }
            label="Agency Users"
          />
        </FormGroup>
      </div>
    );
  }
}

UserTypeToggle.propTypes = {};

const mapStateToProps = (state) => ({
  adminUserTable: state.adminUserTable,
});
const mapDispatchToProps = (dispatch) => ({
  getAdminUserTableData: (
    currentPage,
    sorting,
    sortingPresent,
    statusFilter,
    searchTerm,
    channelFilter,
    userTypeToggle
  ) => {
    return dispatch(
      adminUserTable(
        currentPage,
        sorting,
        sortingPresent,
        statusFilter,
        searchTerm,
        channelFilter,
        userTypeToggle
      )
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(userTypeStyles)(UserTypeToggle));
