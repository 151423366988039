import React, { Fragment } from "react";
import { Typography, Grid, CircularProgress } from "@mui/material";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Legend,
} from "recharts";
import { withStyles } from "@mui/styles";
import icons from "../../components/icons.js";
import * as _ from "lodash";

const styles = () => ({
  toolTipDiv: {
    backgroundColor: "white",
    border: "1px solid #C0C0C0",
  },
  labelTypo: {
    margin: "8px",
    color: "#1E334E",
  },
  kpiTypo: {
    margin: "8px",
    color: "#1E334E",
  },
  KPITargetTypo: {
    margin: "8px",
    color: "#F58120",
  },
});

var moment = require("moment");
function formatTooltipValue(value, name, props) {
  return value;
}

function ActivityKPIChart(props) {
  const {
    classes,
    isLoading,
    kpiType,
    kpiTypeUi,
    kpiHistory,
    selectedDateRangeKPIValue,
    theme,
    isKpiGraphLoading,
    navigateToThatWeek,
  } = props;
  const targetKpiValue =
    kpiHistory && kpiHistory.length > 0 ? _.first(kpiHistory).KPITarget : 0;
  const KpiValue =
    kpiHistory && kpiHistory.length > 0 ? _.sumBy(kpiHistory, "KPI") : 0;
  console.log("kpiHistory=", kpiHistory);
  const requestTagsWithPattern = (object) => {
    // navigateToThatWeek(object.value);
    navigateToThatWeek(object.payload.week);
  };

  const customTooltip = ({ active, payload, label }) => {
    console.log("props are", active, payload, label);

    if (active && label && payload && payload.length) {
      //For KPI
      const kpiValue = payload[0].payload.KPI;
      const KPI = kpiValue.toFixed(2);
      const formattedKPI = KPI.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      // For KPI Target
      const kpiTargetValue = payload[0].payload.KPITarget;
      const KPITarget = kpiTargetValue.toFixed(2);
      const formattedKPITarget = KPITarget.toString().replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      );
      return (
        <div className={classes.toolTipDiv}>
          <Typography className={classes.labelTypo}>
            {formatTooltipValue(label)}
          </Typography>
          <Typography className={classes.kpiTypo}>
            KPI : {formattedKPI}
          </Typography>
          <Typography className={classes.KPITargetTypo}>
            KPI Target :{formattedKPITarget}
          </Typography>
        </div>
      );
    }
    return null;
  };

  return (
    <div className={"fullHeight"} id={"KpiGraph"}>
      {isLoading ? (
        <div
          className={"flex alignItemsCenter justifyCenter"}
          style={{ height: "70%" }}
        >
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <Fragment>
          {kpiHistory.length > 0 ? (
            <Fragment>
              <Grid container alignItems={"center"} spacing={1}>
                {kpiType !== "CPO" && kpiType !== "CPL" ? (
                  <Grid item style={{ paddingBottom: 10 }}>
                    {icons.square(theme.palette.secondary.main)}
                  </Grid>
                ) : null}
                {kpiType !== "CPO" && kpiType !== "CPL" ? (
                  <Grid item>
                    <Typography
                      variant={"body2"}
                      color={"textPrimary"}
                      style={{ fontWeight: "500", paddingBottom: "8px" }}
                    >
                      KPI Target - {targetKpiValue}
                    </Typography>
                  </Grid>
                ) : null}
                <Grid item style={{ paddingBottom: 10 }}>
                  {icons.square(theme.palette.primary.main)}
                </Grid>
                <Grid item>
                  <Typography
                    variant={"body2"}
                    color={"textPrimary"}
                    style={{ fontWeight: "500", paddingBottom: "8px" }}
                  >
                    {" "}
                    KPI - {KpiValue && KpiValue > 0 ? KpiValue.toFixed(2) : 0.0}
                  </Typography>
                </Grid>
              </Grid>
              <br />
              <br />
              <ResponsiveContainer width="100%" height={220}>
                <LineChart data={kpiHistory}>
                  <XAxis
                    dataKey="week"
                    interval={"preserveStartEnd"}
                    type={"category"}
                    // domain={['dataMin', 'dataMax']}
                    tick={{ style: { fontSize: 12 } }}
                    tickFormatter={formatTooltipValue}
                  />
                  <YAxis axisLine={false} width={40} />
                  <CartesianGrid strokeDasharray="3" vertical={false} />
                  <Tooltip content={(props) => customTooltip(props)} />
                  {/* <Tooltip
                    formatter={formatTooltipValue}
                    // labelFormatter={formatLabel}
                  /> */}
                  <Line
                    type="monotone"
                    dataKey="KPI"
                    stroke={theme.palette.primary.main}
                    strokeWidth={2}
                    dot={{ r: 5 }}
                    activeDot={{
                      onClick: (object) => requestTagsWithPattern(object),
                      r: 8,
                    }}
                    isAnimationActive={true}
                    animationBegin={500}
                    animationDuration={6000}
                  />
                  {kpiType !== "CPO" && kpiType !== "CPL" ? (
                    <Line
                      type="monotone"
                      dataKey="KPITarget"
                      stroke={theme.palette.secondary.main}
                      strokeWidth={2}
                      activeDot={{
                        onClick: (object) => requestTagsWithPattern(object),
                      }}
                      isAnimationActive={true}
                      animationBegin={500}
                      animationDuration={6000}
                    />
                  ) : null}
                </LineChart>
              </ResponsiveContainer>
            </Fragment>
          ) : (
            "no data"
          )}
        </Fragment>
      )}
    </div>
  );
}
export default withStyles(styles, { withTheme: true })(ActivityKPIChart);
