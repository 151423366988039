import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Grid, TextField, Typography } from "@mui/material";
import {
  NewOrangeContainedBtn,
  NewOrangeSaveContainedBtn,
  NewOutLinedRectBlackBtn,
  NewTextRectOrangeTextBtn,
  NewOrangeSaveContainedCardBtn,
  NewOutLinedRectBlueBtn,
  NewOutLinedRectCancelBlueBtn,
} from "../../components/buttons";
import {
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  NativeSelect,
} from "@mui/material/index";
import { withStyles } from "@mui/styles";
import icons from "../../components/icons";
import { ErrorTypography } from "../../components/typography";
import NumberFormat from "react-number-format";
import { InputFields } from "../../components/input-forms";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { BootstrapInput } from "../accountSettings/accountSettingsForm";
import { deleteCardDetails } from "../../actions/fetchUserProfile";
import { connect } from "react-redux";


// const TransitionGroup = React.addons.TransitionGroup;

let moment = require("moment");

//export const ListYears = ["2020", "2021", "2022", "2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030", "2031"];

export const ListYears = [
  "2023",
  "2024",
  "2025",
  "2026",
  "2027",
  "2028",
  "2029",
  "2030",
  "2031",
  "2032",
  "2033",
  "2034",
  "2035",
  "2036",
  "2037",
  "2038",
  "2039",
  "2040",
];

export function getCardType(cardType) {
  let type = "CardType";
  if (cardType == "MasterCard") {
    type = icons.cardTypeMasterCard;
  } else if (cardType == "Visa") {
    type = icons.cardTypeVisa;
  } else if (cardType == "American Express") {
    type = icons.CardTypeAmex;
  } else if (cardType == "JCB") {
    type = icons.cardTypeJcb;
  } else if (cardType == "Discover") {
    type = icons.cardTypeDiscover;
  } else if (cardType == "Maestro") {
    type = icons.cardTypeMaestro;
  }
  return type;
}

export const NativeSelectFields = withStyles((theme) => ({
  select: {
    border: "1px solid",
    borderColor: theme.palette.text.primary,
    borderRadius: 3,
    padding: "8.5px 8px",
  },
  icon: {
    color: theme.palette.text.primary,
  },
}))(NativeSelect);

const CustomFormControlLabelHead = withStyles((theme) => ({
  label: {
    fontSize: "0.875rem",
  },
}))(FormControlLabel);

function TextIcon({ text, icon }) {
  return (
    <Grid container spacing={2} alignItems={"center"}>
      <Grid item>
        <Typography
          color={text == "Delete" ? "secondary" : "textPrimary"}
          variant={"body1"}
        >
          {icon}
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          variant={"body1"}
          color={text == "Delete" ? "secondary" : "textPrimary"}
          style={{ paddingBottom: text != "Delete" ? 5 : 0 }}
        >
          {text}
        </Typography>
      </Grid>
    </Grid>
  );
}

function CardDetailsForm(props) {
  const {
    classes,
    cardDetails,
    handleDeleteCard,
    endDate,
    isCardLoading,
    isCardUpdating,
    userType,
  } = props;
  console.log("cardDetails=", cardDetails);
  console.log("isCardUpdating=", isCardUpdating);
  console.log("userInfo=", props);

  const [state, setState] = useState({
    cardNo: "",
    fullName: "",
    expireMonth: "",
    expireYear: "",
    cvv: "",
    cardNumberError: "",
    cardNameError: "",
    expireMonthError: "",
    expireYearError: "",
    cvvError: "",
    commonError: "",
    // priority: false,
    // isEdit: false,
    // deletingPriority: null,
  });
  const [cardNo, setCardNo] = useState("");
  const [fullName, setFullName] = useState("");
  const [expireMonth, setExpireMonth] = useState("");
  const [expireYear, setExpireYear] = useState("");
  const [cvv, setCvv] = useState("");
  const [cardNumberError, setCardNumberError] = useState("");
  const [cardNameError, setCardNameError] = useState("");
  const [expireMonthError, setExpireMonthError] = useState("");
  const [expireYearError, setExpireYearError] = useState("");
  const [cvvError, setCvvError] = useState("");
  const [commonError, setCommonError] = useState("");

  const [isEdit, setIsEdit] = useState(false);
  const [deletingPriority, setDeletingPriority] = useState(null);
  const [priority, setPriority] = useState(false);
  // const { isEdit, deletingPriority } = state;

  const validate = () => {
   
    let isError = false;
    const errors = {};
    if (
      (cardNo && cardNo.trim().length == 0) || cardNo.trim() == "") {
      isError = true;
      errors.cardNumberError = "This Field is required";
      setCardNumberError("This Field is required");
    } else {
      errors.cardNumberError = "";
      setCardNumberError("");
    }

    if ((fullName && fullName.trim().length == 0) || fullName.trim() == "") {
      isError = true;
      errors.cardNameError = "This Field is required";
      setCardNameError("This Field is required");
    } else {
      errors.cardNameError = "";
      setCardNameError("");
    }

    if (expireMonth.length == 0) {
      isError = true;
      errors.expireMonthError = "This Field is required";
      setExpireMonthError("This Field is required");
    } else {
      errors.expireMonthError = "";
      setExpireMonthError("");
    }

    if (expireYear.length == 0) {
      isError = true;
      errors.expireYearError = "This Field is required";
      setExpireYearError("This Field is required");
    } else {
      errors.expireYearError = "";
      setExpireYearError("");
    }
    let today = new Date();
    let someday = new Date();
    someday.setFullYear(expireYear, expireMonth, 1);
    if (
      expireYear.length > 0 &&
      expireMonth.length > 0 &&
      someday < today
    ) {
      isError = true;
      errors.commonError = "Please select a valid expiry date";
      setCommonError("Please select a valid expiry date")
    } else {
      errors.commonError = "";
      setCommonError("");
    }
    if ((cvv && cvv.length == 0) || cvv.trim() == "") {
      isError = true;
      errors.cvvError = "This Field is required";
      setCvvError("This Field is required")
    } else {
      errors.cvvError = "";
      setCvvError("");
    }

    if (isError) {
      setState({
        ...state,
        ...errors,
      });
    }
    return isError;
  };

  const onSubmit = async (event) => {
  
    console.log("state-->" + state)
    event.preventDefault();
    let error;
    // let {cardNo, fullName, expireMonth, expireYear, cvv, priority} = state;
    setCardNo(cardNo.trim());
    setFullName(fullName.trim());
    error = validate();
    if (!error) {
      // this.setState({isLoading: true});
      let response = await props.addCard({
        cardNo, fullName,
        expireMonth, expireYear, cvv, priority
      });
      if (response !== null && response && response.errorMsg && response.errorMsg != "") {
        setState({ commonError: response.errorMsg })
        setCommonError(response.errorMsg);
      } else if (response !== null && response && response.errorMsg && response.errorMsg == "") {
        // setState({
        //     cardNo: "",
        //     fullName: "",
        //     expireMonth: "",
        //     expireYear: "",
        //     cvv: "",
        //     priority: false,
        //     isEdit: false
        // })
        setCardNo("");
        setFullName("");
        setExpireMonth("");
        setExpireYear("");
        setCvv("");
        setPriority(false);
        setIsEdit(false);

      } else {
        // setState({
        //     cardNo: "",
        //     fullName: "",
        //     expireMonth: "",
        //     expireYear: "",
        //     cvv: "",
        //     priority: false,
        //     isEdit: false,
        //     commonError: ""
        // })
        setCardNo("");
        setFullName("");
        setExpireMonth("");
        setExpireYear("");
        setCvv("");
        setPriority(false);
        setIsEdit(false);
        setCommonError("");

      }
    }

  };

  const handleDeleting = (isPriority, paymentDetailId) => {
    setDeletingPriority(isPriority);
    props.handleDeleteCard(isPriority, paymentDetailId);
  };

  const handleChange = (name) => (event) => {
    // debugger;
    // setState((prevState) => ({
    //   ...prevState,
    //   [name]: event.target.value,
    // }));
    if ("fullName" == name) {
      setFullName(event.target.value)
    }
    else if ("expireMonth" == name) {
      setExpireMonth(event.target.value)
    }
    else if ("expireYear" == name) {
      setExpireYear(event.target.value)
    }
    else if ("cvv" == name) {
      setCvv(event.target.value)
    }
    else {
      console.log("some thing went wrong in handleChange function")
    }


  };

  const handleCancel = () => {

    // setState({
    //   // cvv: "",

    //   // fullName: "",
    //   // expireMonth: "",
    //   expireYear: "",
    //   cardNo: "",

    // });
    setCvv("");
    setExpireYear("");
    setFullName("");
    setExpireMonth("");
    setCvvError("");
    setCardNo("");
    setCardNameError("");
    setCardNumberError("");
    setCommonError("");
    // setState({cardNumberError:""});
    // setState({cardNameError:""});
    // setState({cvvError:""});
    setIsEdit(false);

  };
  return (
    <div>
      <form onSubmit={onSubmit}>
        <Grid
          container
          justifyContent={"space-between"}
          alignItems={"center"}
          className={classes.sectionHead}
        >
          <Grid item>
            <Typography color={"textPrimary"} variant={"h4"}>
              <b>Payment Details</b>
            </Typography>
          </Grid>
          <Grid item>
            {cardDetails && cardDetails.length < 2 ? (
              <Fragment>
                {isEdit == false ? (
                  <NewTextRectOrangeTextBtn
                    style={{ margin: 0 }}
                    type="submit"
                    align={"right"}
                    // onClick={() => setState({ isEdit: true })}
                    onClick={() => setIsEdit(true)}
                  >
                    Add Backup card
                  </NewTextRectOrangeTextBtn>
                ) : (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <NewOutLinedRectCancelBlueBtn
                      variant={"outlined"}
                      onClick={handleCancel}
                    >
                      Cancel
                    </NewOutLinedRectCancelBlueBtn>
                    <div className={classes.wrapper}>
                      <NewOrangeSaveContainedCardBtn
                        type="submit"
                        variant={"contained"}
                      >
                        Save
                      </NewOrangeSaveContainedCardBtn>
                      {isCardUpdating ? (
                        <CircularProgress
                          className={classes.buttonProgress}
                          color={"primary"}
                          size={24}
                        />
                      ) : null}
                    </div>
                  </div>
                )}
              </Fragment>
            ) : null}
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {isEdit ? (
            <Grid item md={8}>
              <Grid container>
                <Grid item md={12}>
                  <Grid container style={{ paddingRight: 10 }} spacing={2}>
                    {commonError != "" ? (
                      <ErrorTypography style={{paddingLeft:14}}>{commonError}</ErrorTypography>
                    ) : null}
                    <Grid item md={12}>
                      <Typography variant={"body1"} gutterBottom>
                        <b> Card Type:</b> Credit Card
                      </Typography>
                    </Grid>
                    <Grid item sm={12} xs={12} md={12}>
                      <Typography variant={"body1"} gutterBottom>
                        <b>Credit Card Number</b>
                      </Typography>
                      <FormControl className={classes.formControl}>
                        <NumberFormat
                          fullWidth
                          margin="normal"
                          name="cardNo"
                          value={cardNo}
                          onValueChange={async (values) => {
                            const { formattedValue, value } = values;
                            // await setState({ cardNo: value });
                            await setCardNo(value);
                          }}
                          error={cardNumberError != ""}
                          helperText={cardNumberError}
                          // placeholder="Credit Card Number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          customInput={InputFields}
                          format="####-####-####-####"
                          mask="_"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={12} xs={12} md={12}>
                      <Typography variant={"body1"} gutterBottom>
                        <b>Card Holder's Name/ Name of the Card Holder</b>
                      </Typography>
                      <FormControl className={classes.formControl}>
                        <InputFields
                          fullWidth
                          // onKeyPress={event => (event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122)}
                          margin="normal"
                          name="fullName"
                          value={fullName}
                          onChange={handleChange("fullName")}
                          // onChange={(event) =>
                          //   setState({ fullName: event.target.value })
                          // }
                          error={cardNameError != ""}
                          helperText={cardNameError}
                          // placeholder="Card Holder's Name/ Name of the Holder"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={6} xs={6} md={6}>
                      <Typography variant={"body1"} gutterBottom>
                        <b>Expiry Month</b>
                      </Typography>
                      <FormControl className={classes.formControl}>
                        <NativeSelectFields
                          // label="Month"
                          disableUnderline
                          value={expireMonth}
                          onChange={handleChange("expireMonth")}
                          // onChange={(event) =>
                          //   setState({ expireMonth: event.target.value })
                          // }
                          displayEmpty
                          input={<BootstrapInput />}
                          inputProps={{ "aria-label": "Without label" }}
                          IconComponent={ExpandMore}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "bottom",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "top",
                            },
                          }}
                        // error={state.expireMonthError != ''}
                        // helperText={state.expireMonthError}
                        >
                          <option value="">MM</option>
                          <option value={1}>01</option>
                          <option value={2}>02</option>
                          <option value={3}>03</option>
                          <option value={4}>04</option>
                          <option value={5}>05</option>
                          <option value={6}>06</option>
                          <option value={7}>07</option>
                          <option value={8}>08</option>
                          <option value={9}>09</option>
                          <option value={10}>10</option>
                          <option value={11}>11</option>
                          <option value={12}>12</option>
                        </NativeSelectFields>
                      </FormControl>
                    </Grid>
                    <Grid item sm={6} xs={6} md={6}>
                      <Typography variant={"body1"} gutterBottom>
                        <b>Expiry Year</b>
                      </Typography>
                      <FormControl className={classes.formControl}>
                        <NativeSelectFields
                          disableUnderline
                          value={expireYear}
                          onChange={handleChange("expireYear")}
                          // onChange={(event) =>
                          //   setState({ expireYear: event.target.value })
                          // }
                          displayEmpty
                          input={<BootstrapInput />}
                          inputProps={{ "aria-label": "Without label" }}
                          IconComponent={ExpandMore}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "bottom",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "top",
                            },
                          }}
                          error={expireYearError != ""}
                          helperText={expireYearError}
                        >
                          <option key={1} value="">
                            YYYY
                          </option>
                          {ListYears.map((value, i) => {
                            return (
                              <option key={i} value={value}>
                                {value}
                              </option>
                            );
                          })}
                          ;
                        </NativeSelectFields>
                      </FormControl>
                    </Grid>
                    <Grid item sm={12} xs={12} md={12}>
                      <Typography variant={"body1"} gutterBottom>
                        <b>CVV</b>
                      </Typography>
                      <FormControl className={classes.formControl}>
                        <InputFields
                          type={"password"}
                          fullWidth
                          margin="normal"
                          name="cvv"
                          value={cvv}
                          onChange={handleChange("cvv")}
                          // onChange={(event) =>
                          //   setState({ cvv: event.target.value })
                          // }
                          error={cvvError != ""}
                          helperText={cvvError}
                          // placeholder="CVV"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={12}>
                      <CustomFormControlLabelHead
                        control={
                          <Checkbox
                            color="secondary"
                            checked={priority}
                            onChange={() => {
                              setPriority(!priority);
                            }}
                          />
                        }
                        label={"Set As Default Card"}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : null}

          {cardDetails &&
            cardDetails.length > 0 &&
            cardDetails.map((card) => (
              <Grid item md={12}>
                <Card className={classes.cardStyle}>
                  <CardContent>
                    <Grid container>
                      <Grid item md={12}>
                        <Grid container spacing={2}>
                          {card.isPriority == 1 ? (
                            <Grid item md={12}>
                              <Typography
                                variant={"h4"}
                                color={"textPrimary"}
                                gutterBottom
                              >
                                <b>Your saved payment option</b>
                              </Typography>
                            </Grid>
                          ) : null}
                          <Grid item md={4}>
                            {getCardType(card.cardType)}
                          </Grid>
                          <Grid item>
                            <Typography variant={"body1"} color={"textPrimary"}>
                              <b>"{card.cardNo}"</b>
                            </Typography>
                          </Grid>
                          <Grid item className={classes.grow}></Grid>
                          {cardDetails.length > 1 ? (
                            <Grid
                              item
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                handleDeleting(
                                  card.isPriority,
                                  card.paymentDetailId
                                )
                              }
                            >
                              <div className={classes.wrapper}>
                                <TextIcon
                                  text={"Delete"}
                                  icon={icons.deleteIcon}
                                />
                                {deletingPriority == card.isPriority &&
                                  isCardLoading && (
                                    <CircularProgress
                                      className={classes.buttonProgress}
                                      color="secondary"
                                      size={24}
                                    />
                                  )}
                              </div>
                            </Grid>
                          ) : null}
                          <Grid item md={12}>
                            <br />
                            <Typography variant={"body1"} color={"textPrimary"}>
                              {card.fullName}
                            </Typography>
                          </Grid>
                          {card.isPriority == 1 ? (
                            <Grid item md={12}>
                              {userType != 11 ? (
                                <Grid
                                  container
                                  spacing={2}
                                  alignItems={"flex-end"}
                                >
                                  <Grid item>
                                    <Grid item md={24}>
                                      <br />
                                      <Typography
                                        variant={"body1"}
                                        color={"textPrimary"}
                                        gutterBottom
                                      >
                                        Default Payment Option
                                      </Typography>
                                      <Typography
                                        variant={"body1"}
                                        color={"textPrimary"}
                                      >
                                        Next Payment Date:
                                      </Typography>
                                      <Typography
                                        variant={"body1"}
                                        color={"textPrimary"}
                                      >
                                        {moment(endDate).format("ll")}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                  <Grid item className={classes.grow}></Grid>
                                  <Grid item>
                                    <TextIcon
                                      text={"Default Card"}
                                      icon={icons.greenDot}
                                    />
                                  </Grid>
                                </Grid>
                              ) : (
                                <Grid
                                  container
                                  spacing={2}
                                  alignItems={"flex-end"}
                                >
                                  <Grid item>
                                    <Grid item md={24}>
                                      <br />
                                      <Typography
                                        variant={"body1"}
                                        color={"textPrimary"}
                                        gutterBottom
                                      >
                                        Default Payment Option
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                  <Grid item className={classes.grow}></Grid>
                                  <Grid item>
                                    <TextIcon
                                      text={"Default Card"}
                                      icon={icons.greenDot}
                                    />
                                  </Grid>
                                </Grid>
                              )}
                            </Grid>
                          ) : (
                            <Grid item>
                              <Grid
                                container
                                spacing={6}
                                alignItems={"flex-end"}
                              >
                                <Grid item md={12}>
                                  <Typography
                                    variant={"body1"}
                                    color={"textPrimary"}
                                    gutterBottom
                                  >
                                    Backup Payment Option
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            ))}
        </Grid>
      </form>
    </div>
  );
}
CardDetailsForm.propTypes = {};

export default CardDetailsForm;
