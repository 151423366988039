import { Grid, Paper } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import Typography from "@mui/material/Typography";
import classNames from "classnames";
import React from "react";
import { connect } from "react-redux";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { getSteps, getStepsForPackagesView } from "./template";
import icons from "../icons";

const styles = (theme) => ({
  mainTitle: {
    fontWeight: "800",
    fontFamily: "Montserrat",
  },
  gridP: {
    padding: theme.spacing(2),
    paddingTop: 0,
  },
  gridM: {
    padding: theme.spacing(2),
    paddingTop: 0,
    marginBottom: theme.spacing(6),
  },
  caption: {
    fontFamily: "Lato",
    fontSize: 16,
  },
  stepTitle: {
    fontFamily: "Montserrat",
  },
  stepper: {
    boxShadow: "unset",
    background: theme.palette.errorBg,
  },
  Paper: {
    boxShadow: "unset",
  },
});

/*function getIcon(activeStep) {
    let icon=
    if(activeStep==2){

    }
    switch (activeStep) {
        case 0:
            return icons.circleCheckCustom;
    }
}*/

function SeoStepper(props) {
  const steps = getStepsForPackagesView();
  const { activeStep, classes, margin } = props;
  return (
    <Grid container justifyContent={"center"}>
      <Grid
        item
        md={5}
        sm={12}
        className={margin ? classes.gridM : classes.gridP}
      >
        <Stepper
          className={classes.stepper}
          activeStep={activeStep}
          orientation="horizontal"
          alternativeLabel
        >
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel className={classes.stepTitle}>
                <b>{label}</b>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <br />
        <Typography
          component={"h5"}
          variant={"h5"}
          color={"textSecondary"}
          className={props.classes.mainTitle}
          align={"center"}
        >
          <b>SEO ON DEMAND</b>
        </Typography>
        {activeStep ? null : (
          <Typography
            color="inherit"
            variant={"caption"}
            align={"center"}
            className={classNames(
              props.classes.caption,
              props.classes.titleAlignLeft
            )}
          >
            Please Select a Package
          </Typography>
        )}
        <br />
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  activeStep: state.siteGraderServiceDetails.activeStep,
});

export default connect(null, null)(withStyles(styles)(SeoStepper));
