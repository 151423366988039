import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "../jumpstart.css";
import { JsSectionTypography } from "../../../components/typography";
import { Grid, TextField, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { JsAddUserLabelTypography } from "../../../components/typography";
import {
  JsBlackContainedBtn,
  JsOrangeContainedBtn,
} from "../../../components/buttons";

function RenameCallout(props) {
  const { handleCloseIcon, callout, handleUpdateCallout } = props;
  const [updatedCallout, setUpdatedCallout] = useState(callout);

  const handleCalloutUpdate = (e) => {
    setUpdatedCallout((prevCallout) => ({
      ...prevCallout,
      text: e.target.value,
    }));
  };

  const handleButtonClick = () => {
    handleUpdateCallout(updatedCallout);
    handleCloseIcon();
  };

  return (
    <div>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        style={{ padding: "2%", borderBottom: "1px solid #E6E6E6" }}
      >
        <Grid item xs={11}>
          <JsSectionTypography style={{ fontSize: "1rem" }}>
            + Edit Callout
          </JsSectionTypography>
        </Grid>
        <Grid item xs={1}>
          <IconButton aria-label="close" onClick={handleCloseIcon}>
            <CloseIcon color="secondary" />
          </IconButton>
        </Grid>
      </Grid>
      <div>
        <Grid
          container
          direction="row"
          columnSpacing={2}
          rowSpacing={1}
          style={{ padding: "2%" }}
        >
          <Grid
            item
            container
            xs={12}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <label htmlFor="callout">
                <JsAddUserLabelTypography>
                  <span className="numberContainer">1</span>Type Callout Text
                </JsAddUserLabelTypography>
              </label>
            </Grid>
            <Grid item>
              <JsAddUserLabelTypography>
                Char Count: &nbsp;
                <span
                  style={{
                    color:
                      updatedCallout.text.trim().length > 25
                        ? "red"
                        : "inherit",
                  }}
                >
                  {updatedCallout.text.trim().length}
                </span>
                /25
              </JsAddUserLabelTypography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={updatedCallout.text.trim().length > 25}
              helperText={
                updatedCallout.text.trim().length > 25
                  ? "Callout is more than 25 characters"
                  : ""
              }
              fullWidth
              placeholder="Type your text here"
              size="small"
              id="callout"
              value={updatedCallout.text}
              onChange={handleCalloutUpdate}
              sx={{
                ".MuiOutlinedInput-root": {
                  fontSize: "0.8125rem",
                },
              }}
            />
            {/* <input
              type="text"
              placeholder="Type your text here"
              id="callout"
              className="addUser-input-css"
              onChange={handleCalloutUpdate}
              value={updatedCallout.text}
            /> */}
          </Grid>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={6} className="bottomPositionBtn">
            <JsOrangeContainedBtn
              onClick={handleButtonClick}
              style={{ fontSize: "0.875rem" }}
              disabled={updatedCallout.text.trim().length > 25}
            >
              Update
            </JsOrangeContainedBtn>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(RenameCallout);
