import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
  
} from "@mui/material";
import {withStyles} from "@mui/styles"
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  deleteAnalyticsConnection,
  fetchGAModelData,
  fetchGA_URL,
  fetchGaDataViewsPropertiesAction,
  storeSearchEngineId,
} from "../../actions";
import {
  NewOrangeContainedBtn,
  NewOutLinedRectCancelBlueBtn,
} from "../../components/buttons";

import { ErrorTypography } from "../../components/typography";
import SnackbarWrapperConnections from "../snackbars";
import MySnackbarWrapper from "../snackbars";

const styles = (theme) => ({
  GaGrid: {
    [theme.breakpoints.up("md")]: {
      width: "100%",
      height: "88px",
      border: "solid 2px #BBBBBB",
      borderRadius: "6px",
      padding: "0px 10px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "15px",
      width: "100%",
      height: "300px",
      border: "solid 1px #BBBBBB",
      borderRadius: "3px",
      textAlign: "center",
    },
  },
  TextCenter: {
    textAlign: "Center",
    fontSize: "16px",
  },

  gridItems: {
    margin: "Auto 0",
  },
  buttons: {
    backgroundColor: "#F58120",
    border: "solid 1px #1E334E",
  },
});

let engineId = 0;

function GoogleAnalyticsConnection(props) {
  window.scrollTo(0, 0);
  const classes = props.classes;

  const {
    fetchGA_URL,
    storeSearchEngineId,
    googleAnalyticsURLDataReducers,
    fetchGAModelDataReducers,
    deleteAnalyticsConnection,
    deleteConnectionsDataReducer,
    fetchGaDataViewsPropertiesAction,
    googleAnalyticsData,
  } = props;

  useEffect(() => {
    fetchGA_URL();
  }, []);

  var authUrl = googleAnalyticsURLDataReducers["authUrl"];

  const [analyticsAccountNameGA4, setAnalyticsAccountNameGA4] = useState("");
  const [analyticsIdGA4, setAnalyticsIdGA4] = useState("");
  const [propertyIdGA4, setPropertyIdGA4] = useState("");
  const [alert, setAlert] = useState(false);

  useEffect(() => {
    if (googleAnalyticsData) {
      setAnalyticsAccountNameGA4(
        googleAnalyticsData.accountName ? googleAnalyticsData.accountName : "-"
      );
      setAnalyticsIdGA4(
        googleAnalyticsData.analyticsId ? googleAnalyticsData.analyticsId : "-"
      );
      setPropertyIdGA4(
        googleAnalyticsData.propertyId ? googleAnalyticsData.propertyId : "-"
      );
    }
  }, [googleAnalyticsData]);

  console.log("selectedAnalyticsId===>", analyticsIdGA4);

  const handleAddAccountUA = () => {
    engineId = 1;
    storeSearchEngineId(engineId);
    window.open(authUrl, "_top");
  };

  const handleAddAccountGA4 = () => {
    engineId = 2;
    storeSearchEngineId(engineId);
    window.open(authUrl, "_top");
  };

  const getGA4RefreshToken = () => {
    engineId = 2;
    storeSearchEngineId(engineId);
    window.open(authUrl, "_top");
  };
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const onClose = () => {
    setOpen(false);
  };
  const handleDialogToOpen = () => {
    setOpen(true);
  };

  const handleDialogToClose = () => {
    setOpen(false);
  };

  const [deleteObjectAccountName, setDeleteObjectAccountName] = useState("");

  const handleDeleteConnection = async (analyId, analyAccName, analyPropertyId) => {
    setDeleteObjectAccountName(analyAccName);
    await deleteAnalyticsConnection(analyId);
    setOpen(false);
    setAlert(true);
  };

  const refreshTokenStatus = false;
  const getUARefreshToken = (refreshTokenStatus, analyId) => {
    fetchGaDataViewsPropertiesAction(refreshTokenStatus, analyId);
  };
  useEffect(() => {
    if ( deleteConnectionsDataReducer.message === "DELETE_ANALY_CONNECTION_RECEIVED") {
      if (deleteObjectAccountName === analyticsAccountNameGA4) {
        setAnalyticsAccountNameGA4("");
        setPropertyIdGA4("");
      }
    }
  }, [deleteConnectionsDataReducer]);

  const handleCloseAlert = async () => {
    await setAlert(false);
  };

  console.log("snackbar alert",alert);

  console.log("fetchGAModelDataReducers", fetchGAModelDataReducers);
  const [showGoogleAnalyComponentStatus, setShowGoogleAnalyComponentStatus] =
    useState(false);
  const showGoogleAnalyComponent = () => {
    console.log("inside showGoogleAnalyComponent");
    setShowGoogleAnalyComponentStatus(true);
  };

  return (
    <div style={{ marginTop: "20px" }}>
      <Grid container className={classes.GaGrid}>
        <Grid item xs={12} sm={12} md={2} lg={2} className={classes.gridItems}>
          <Typography style={{ color: "#F58120" }}>
            Google Analytics 4
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={3} lg={3} className={classes.gridItems}>
          {analyticsAccountNameGA4 === "" || analyticsAccountNameGA4 === "-" ? (
            <Typography></Typography>
          ) : (
            <Typography>Account Name: {analyticsAccountNameGA4}</Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5} className={classes.gridItems}>
          {propertyIdGA4 === "" || propertyIdGA4 === "-" ? (
            <Typography></Typography>
          ) : (
            <Typography> Property Id: {propertyIdGA4}</Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2} className={classes.gridItems}>
          {googleAnalyticsData != undefined &&
          (deleteConnectionsDataReducer["message"] === "" ||
            deleteConnectionsDataReducer["message"] ===
              "DELETE_ANALY_CONNECTION_FAILED") ? (
            <Typography>
              <NewOutLinedRectCancelBlueBtn onClick={handleDialogToOpen}>
                Delete Account
              </NewOutLinedRectCancelBlueBtn>
            </Typography>
          ) : (
            <Typography>
              <NewOrangeContainedBtn onClick={handleAddAccountGA4}>
                +Add Account
              </NewOrangeContainedBtn>
            </Typography>
          )}
        </Grid>
      </Grid>

      {/* Snackbar for error in deleting google analytics account */}
      <Grid container>
        <Grid item md={12}>
          {deleteConnectionsDataReducer.response === true ? (
            <React.Fragment>
              <SnackbarWrapperConnections
                open={open}
                onClose={onClose}
                message={message}
                verticalAlign={"bottom"}
                horizontalAlign={"right"}
                duration={6000}
                variant={"error"}
              />
            </React.Fragment>
          ) : deleteConnectionsDataReducer.response === false ? (
            <ErrorTypography className={classes.TextCenter}>
              Error deleting the Account
            </ErrorTypography>
          ) : null}
        </Grid>
      </Grid>

      <Dialog open={open} onClose={handleDialogToClose}>
        <DialogTitle>{"Delete Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you really want to delete the Google Analytics 4 integration in
            LXRGuide?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() =>
              handleDeleteConnection(
                analyticsIdGA4,
                analyticsAccountNameGA4,
                propertyIdGA4
              )
            }
            color="primary"
            autoFocus
          >
            Delete
          </Button>
          <Button onClick={handleDialogToClose} color="primary" autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <MySnackbarWrapper
        open={alert}
        onClose={handleCloseAlert}
        message={
          "GA 4 account deleted successfully"
        }
        verticalAlign={"bottom"}
        horizontalAlign={"right"}
        duration={6000}
        variant={"success"}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  googleAnalyticsURLDataReducers: state.googleAnalyticsURLDataReducers,
  connectionsDataReducer: state.connectionsDataReducer,
  fetchDataAndStoreEngIdDataReducers: state.fetchDataAndStoreEngIdDataReducers,
  fetchGAModelDataReducers: state.fetchGAModelDataReducers,
  deleteConnectionsDataReducer: state.deleteConnectionsDataReducer,
  fetchPropertiesAndViewsDataReducer: state.fetchPropertiesAndViewsDataReducer,
});
const mapDispatchToProps = (dispatch) => ({
  fetchGA_URL: () => dispatch(fetchGA_URL()),
  storeSearchEngineId: (engineId) => dispatch(storeSearchEngineId(engineId)),
  fetchGAModelData: (accountId, propertyId, profileId) =>
    dispatch(fetchGAModelData(accountId, propertyId, profileId)),
  deleteAnalyticsConnection: (analyId) =>
    dispatch(deleteAnalyticsConnection(analyId)),
  fetchGaDataViewsPropertiesAction: (refreshTokenStatus, analyId) =>
    dispatch(fetchGaDataViewsPropertiesAction(refreshTokenStatus, analyId)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(GoogleAnalyticsConnection));
