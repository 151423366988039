import React from 'react';

const LinearProgressBar = ({ progress, color }) => {
  
  return (
    <div style={{ width: '200px', height: '10px', border: '1px solid #F2F6F9',backgroundColor:"beige" }}>
      <div
        style={{
          width: `${progress}%`,
          height: '100%',
          background: color,
        }}
      ></div>
    </div>
  );
};

export default LinearProgressBar;
