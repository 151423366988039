const activityReportStyles = (theme) => ({
  card: {
    boxShadow: "unset",
    background: "unset",
  },
  fixedGraphHeightPaper: {
    height: 401,
  },
  paper: {
    padding: theme.spacing(1.5),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    border: "1px solid" + theme.palette.common.border,
    borderRadius: 6,
    boxShadow: "4px 4px 18px " + theme.palette.boxShadowC,
  },
  loader: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    justifyContent: "center",
  },
  pb: {
    paddingBottom: 1,
  },
  CardContent: {
    padding: 0,
  },
  weekFocus: {
    "&:focus": {
      color: theme.palette.common.white,
      outline: " none !important",
    },
  },
});

export default activityReportStyles;
