import React from "react";
import Attractleadsimg from "../../assets/img/speakerbg.png";
import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid";
import {
  OrangeTextSize12,
  GrayText,
  BlueTitleTypography,
  HeadLineTypography,
  CatalinaBlue,
} from "../../components/typography";
import { Typography } from "@mui/material/index";

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="xl" />;

const styles = (theme) => ({
  root: {
    [theme.breakpoints.down("xl")]: {
      backgroundImage: "unset",
    },
    backgroundImage: `url(${Attractleadsimg})`,
    textAlign: "center",
    backgroundRepeat: "no-repeat",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    backgroundPositionX: "center",
  },
  contentAlignCenter: {
    textAlign: "center",
  },
  HeadLineTypographyStyle: {
    paddingTop: 34,
    paddingBottom: 17,
  },
  CatalinaBlueStyle: {
    [theme.breakpoints.up("xs")]: {
      paddingLeft: 15,
      paddingRight: 15,
      paddingBottom: 15,
      textAlign: "initial",
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: 180,
      paddingRight: 198,
      paddingBottom: 20,
      textAlign: "initial",
    },
  },
});

function AttractLeads(props) {
  const classes = props.classes;
  const width = props.width;

  return (
    <div>
      <Grid container className={classes.root}>
        <Grid item md={12} sm={12} xs={12}>
          <Typography
            align={"center"}
            variant={width == "sm" || width == "xs" ? "h3" : "subtitle1"}
            color={"textPrimary"}
            style={{ fontWeight: "700" }}
            gutterBottom
          >
            Attract Leads
          </Typography>
        </Grid>
        <Grid item md={12} xs={12} sm={12}>
          <CatalinaBlue className={classes.CatalinaBlueStyle}>
            As an organisation grows, so too does the number of mouths to feed.
            This extra strain on resources means that sales and marketing teams
            are put under pressure to generate more leads. That is why the very
            first pillar of the Growth Model is to attract website traffic that
            converts into qualified leads your sales team can go on to close.
            <br />
            <br />
            In order to attract visitors to your website and to generate leads
            you will need to have a SaaS Marketing Strategy. We recommend a
            GamePlan Strategy Workshop with your sales and marketing teams to
            help you build a go-to market strategy that uses specific buyer
            personas to focus on your potential customers needs, and the
            questions they want answered.
            <br />
            <br />
            <b>
              In order to do this we provide content strategies that include:
            </b>
            <br />
            <br />
            Content offers
            <br />
            Lead magnets
            <br />
            Blog/articles
            <br />
            Paid ads
            <br />
            SEO
          </CatalinaBlue>
        </Grid>
      </Grid>
    </div>
  );
}

export default withWidth()(withStyles(styles)(AttractLeads));
