import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import "./jumpstart.css";
import {
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  Skeleton,
  Typography,
} from "@mui/material";
import {
  JsAddUserLabelTypography,
  JsSubSectionTypography,
} from "../../components/typography";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { JsOrangeContainedBtn } from "../../components/buttons";
import Checkbox from "@mui/material/Checkbox";
import {
  fetchJsAccountById,
  updateBusinessDetails,
  fetchBusinessDetailsByJsAccountId,
  fetchJsAccounts,
} from "../../actions/jumpstartRevamped";
import { useParams, useHistory } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { AccountStatus, Authority } from "./commonComponents/jumpstartEnum";
import { websiteValidation } from "../commonFunctions/commonFunctions";
import {ErrorSmallTypography} from "../../components/typography"

function JsBusinessDetails(props) {
  const {
    authority,
    updateBusinessDetails,
    updateBusinessDetailsReducer,
    fetchJsAccountByIdReducer,
    fetchBusinessDetailsByJsAccountId,
    fetchBusinessDetailsByJsAccountIdReducer,
    handleCampaignDetailsDisable,
    fetchJsAccountById,
    handleTabChange,
    fetchJsAccounts,
  } = props;
  const [businessId, setBusinessId] = useState(null);
  const [businessName, setBusinessName] = useState("");
  const [businessURL, setBusinessURL] = useState("");
  const [brandName, setBrandName] = useState("");
  const [industry, setIndustry] = useState("");
  const [usp, setAllUsp] = useState([]);
  const jwtToken = sessionStorage.getItem("jwtToken");
  // const jwtToken = Cookies.get("jwtToken");
  const { id } = useParams();
  const [snackbarMsg, setSnackbarMsg] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [accountStatus, setAccountStatus] = useState("");
  const [disableFramework, setDisableFramework] = useState(false);
  const [errorBusinessURLShow, setErrorBusinessURLShow] = useState(false);

  if (
    businessURL == "" ||
    businessName == "" ||
    industry == "" ||
    brandName == "" ||
    usp.filter((row) => row != "").length < 4
  ) {
    handleCampaignDetailsDisable(true);
  } else {
    handleCampaignDetailsDisable(false);
  }

  useEffect(() => {
    if (fetchJsAccountByIdReducer.message == "JS_ACCOUNT_BY_ID_RECEIVED" && fetchJsAccountByIdReducer.id == id) {
      setAccountStatus(fetchJsAccountByIdReducer.status);
      if (
        fetchJsAccountByIdReducer.status ==
          AccountStatus.CAMPAIGN_FRAMEWORK_IN_REVIEW &&
        (authority == Authority.ANALYST || authority == Authority.RWF)
      ) {
        setDisableFramework(true);
      }
    }
  }, [fetchJsAccountByIdReducer]);

  useEffect(() => {
    const fetchBusinessData = async () => {
      setLoading(true);
      const data = await fetchBusinessDetailsByJsAccountId(jwtToken, id);
      if (data && data.status == 200) {
        setBusinessName(data.businessName);
        setBusinessURL(data.businessUrl);
        setBrandName(data.brandName);
        setIndustry(data.industry);
        setAllUsp(data.usp);
        setBusinessId(data.id ? data.id : null)
      }
      setLoading(false);
    };
    fetchBusinessData();
  }, []);
  const handleUspChange = (index, value) => {
     const newArray = [...usp];
    newArray[index] = value;
    setAllUsp(newArray);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  let businessDetailsObj = "";
  const handleSaveAPICall = async () => {
    if(businessId == null){
      businessDetailsObj = {
        jsAccountId: id,
        businessName: businessName,
        businessUrl: businessURL,
        brandName: brandName,
        industry: industry,
        usp: usp,
      }
    }else{
      businessDetailsObj = {
        id: businessId,
        jsAccountId: id,
        businessName: businessName,
        businessUrl: businessURL,
        brandName: brandName,
        industry: industry,
        usp: usp,
      }
    }
    const data = await updateBusinessDetails(jwtToken, businessDetailsObj);
    return data;
  }


  const handleSaveButton = async () => {
    setLoading(true);
    const data = await handleSaveAPICall();
    if(data && data.status == 200){
      if(accountStatus == AccountStatus.ACCOUNT_CREATED){
         fetchJsAccountById(jwtToken, id);
         fetchJsAccounts(jwtToken);
      }
      setError(false);
      setSnackbarMsg("Business details saved");
    }else{
      setError(true);
      setSnackbarMsg("Error in saving business details");
    }
    setLoading(false);
    setSnackbarOpen(true);
  }

  const handleNextButton = async (e) => {
    setLoading(true);
    const data = await handleSaveAPICall();
    setLoading(false);
    handleTabChange(e,1);
  }
  
  const handleBusinessURL = (event) =>{
    setBusinessURL(event.target.value)
    if(websiteValidation(event.target.value)){
      setErrorBusinessURLShow(false)
    }
    else{
      setErrorBusinessURLShow(true)
    }
  }

  return (
    <>
      <Grid container direction="row">
        <Grid item xs={12} sm={12} md={6}>
          <Grid
            container
            direction="column"
            rowSpacing={3}
            sx={{ borderRight: "1px solid #E6E6E6", paddingRight: "4%" }}
          >
            <Grid item xs={12}>
              {loading ? (
                <Skeleton />
              ) : (
                <label htmlFor="businessName">
                  <JsAddUserLabelTypography>
                    <span className="numberContainer">1</span>Business / Account
                    Name
                  </JsAddUserLabelTypography>
                </label>
              )}
            </Grid>
            <div>
              {loading ? (
                <Skeleton />
              ) : (
                <input
                  type="text"
                  placeholder="Type your text here"
                  id="businessName"
                  className="addUser-input-css"
                  onChange={(event) => {
                    setBusinessName(event.target.value);
                  }}
                  value={businessName}
                  disabled={disableFramework}
                />
              )}
            </div>
            <Grid item xs={12}>
              {loading ? (
                <Skeleton />
              ) : (
                <label htmlFor="businessUrl">
                  <JsAddUserLabelTypography>
                    <span className="numberContainer">2</span>Business URL
                  </JsAddUserLabelTypography>
                </label>
              )}
            </Grid>
            <div>
              {loading ? (
                <Skeleton />
              ) : (
                <input
                  type="text"
                  placeholder="Type your text here"
                  id="businessUrl"
                  className="addUser-input-css"
                  // onChange={(event) => {
                  //   event.target.value != null
                  //     ? setBusinessURL(event.target.value)
                  //     : "";
                  // }}
                  onChange={handleBusinessURL}
                  value={businessURL}
                  disabled={disableFramework}
                />
               
              )}
               {errorBusinessURLShow?(<ErrorSmallTypography>Please Enter Valid URL</ErrorSmallTypography>):("")}
            </div>
            <Grid item xs={12}>
              {loading ? (
                <Skeleton />
              ) : (
                <label htmlFor="brandName">
                  <JsAddUserLabelTypography>
                    <span className="numberContainer">3</span>Brand Name
                  </JsAddUserLabelTypography>
                </label>
              )}
            </Grid>
            <div>
              {loading ? (
                <Skeleton />
              ) : (
                <input
                  type="text"
                  placeholder="Type your text here"
                  id="brandName"
                  className="addUser-input-css"
                  onChange={(event) => {
                    setBrandName(event.target.value);
                  }}
                  value={brandName}
                  disabled={disableFramework}
                />
              )}
            </div>
            <Grid item xs={12}>
              {loading ? (
                <Skeleton />
              ) : (
                <label htmlFor="industry">
                  <JsAddUserLabelTypography>
                    <span className="numberContainer">4</span>Industry
                  </JsAddUserLabelTypography>
                </label>
              )}
            </Grid>
            <div>
              {loading ? (
                <Skeleton />
              ) : (
                <input
                  type="text"
                  placeholder="Type your text here"
                  id="industry"
                  className="addUser-input-css"
                  onChange={(event) => {
                    setIndustry(event.target.value);
                  }}
                  value={industry}
                  disabled={disableFramework}
                />
              )}
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} style={{ position: "relative" }}>
          <Grid
            container
            direction="column"
            rowSpacing={3}
            sx={{ paddingLeft: "4%" }}
          >
            <Grid item xs={12}>
              {loading ? (
                <Skeleton />
              ) : (
                <label htmlFor="usp">
                  <JsAddUserLabelTypography>
                    <span className="numberContainer">5</span>Add 5 USPs of your
                    business
                  </JsAddUserLabelTypography>
                </label>
              )}
            </Grid>
            {[0, 1, 2, 3, 4].map((index) => (
              <div style={{ paddingBottom: "6px" }}>
                {loading ? (
                  <Skeleton />
                ) : (
                  <input
                    type="text"
                    placeholder="Type your text here"
                    id={index + 1}
                    className="addUser-input-css"
                    onChange={(e) => handleUspChange(index, e.target.value)}
                    value={usp != undefined ? usp[index] : ""}
                    name={index}
                    key={index}
                    disabled={disableFramework}
                  />
                )}
              </div>
            ))}
          </Grid>
          <Grid
            item
            xs={12}
            container
            direction="row"
            alignItems="center"
            style={{ position: "absolute", bottom: "1px" }}
          >
            <Grid item xs={4}>
              {/* {authority == Authority.ANALYST || authority == Authority.RWF ? (
                ""
              ) : (
                <FormControlLabel
                  control={
                    <Checkbox size="small" id="final" color="secondary" />
                  }
                  label="Mark this as final"
                  sx={{
                    ".MuiFormControlLabel-label": {
                      fontSize: "0.875rem",
                      color: "#838383",
                    },
                    paddingLeft: "11%",
                  }}
                />
              )} */}
            </Grid>
            <Grid
              item
              xs={8}
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              columnSpacing={2}
            >
              <Grid item>
                <Button
                  variant="text"
                  onClick={()=>handleSaveButton()}
                  style={{
                    fontSize: "0.875rem",
                    textTransform: "none",
                    color: "#747474",
                  }}
                  disabled={
                    disableFramework ||
                    (businessURL == "" &&
                      businessName == "" &&
                      industry == "" &&
                      brandName == "" &&
                      usp.length == 0) || errorBusinessURLShow===true
                  }
                >
                  <SaveOutlinedIcon
                    sx={{
                      color:
                        businessURL == "" &&
                        businessName == "" &&
                        industry == "" &&
                        brandName == "" &&
                        usp.length == 0
                          ? ""
                          : "#F58120",
                    }}
                  />
                  &nbsp;Save
                </Button>
              </Grid>
              <Grid item>
                <JsOrangeContainedBtn
                  style={{ padding: "3% 5%", fontSize: "0.875rem" }}
                  onClick={()=>handleNextButton()}
                  name="next"
                  disabled={
                    businessURL == "" ||
                    businessName == "" ||
                    industry == "" ||
                    brandName == "" ||
                    usp.filter((row) => row != "").length < 4 || errorBusinessURLShow===true
                  }
                >
                  Next
                </JsOrangeContainedBtn>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={error ? "error" : "success"}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarMsg}
        </Alert>
      </Snackbar>
    </>
  );
}

const mapStateToProps = (state) => ({
  fetchJsAccountsReducer: state.fetchJsAccountsReducer,
  updateBusinessDetailsReducer: state.updateBusinessDetailsReducer,
  fetchJsAccountByIdReducer: state.fetchJsAccountByIdReducer,
  fetchBusinessDetailsByJsAccountIdReducer:
    state.fetchBusinessDetailsByJsAccountIdReducer,  
});
const mapDispatchToProps = (dispatch) => ({
  fetchJsAccounts: (jwtToken) => dispatch(fetchJsAccountById(jwtToken)),
  fetchJsAccountById: (jwtToken, id) =>
    dispatch(fetchJsAccountById(jwtToken, id)),
  updateBusinessDetails: (jwtToken, businessDetailsData) =>
    dispatch(updateBusinessDetails(jwtToken, businessDetailsData)),
  fetchBusinessDetailsByJsAccountId: (jwtToken, id) =>
    dispatch(fetchBusinessDetailsByJsAccountId(jwtToken, id)),
    fetchJsAccounts: (jwtToken) =>
    dispatch(fetchJsAccounts(jwtToken)),
});
export default connect(mapStateToProps, mapDispatchToProps)(JsBusinessDetails);