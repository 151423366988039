import React, { Fragment } from "react";
import { Grid, Paper, Typography } from "@mui/material";
import { CircularProgress } from "@mui/material/index";
import { withStyles } from "@mui/styles";
import taskSectionStyles from "../../assets/jss/activityReport/taskSectionStyle";
import clsx from "classnames";
import className from "classnames";
import History from "./history";
import { Link } from "react-router-dom";
import classNames from "classnames";

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="xl" />;

function PlainLink({ classes, ...rest }) {
  return <Link {...rest} className={classes.a} />;
}

PlainLink = withStyles({
  a: {
    width: "100%",
    color: "unset",
    textDecoration: "none",
    cursor: "pointer",
  },
})(PlainLink);

function TaskHistory(props) {
  const {
    classes,
    isLoading,
    width,
    tasks,
    dateRange,
    selectedDateRange,
    selectedNodeKey,
  } = props;
  const fixedGraphHeightPaper = clsx(
    classes.paper,
    classes.fixedGraphHeightPaper
  );
  console.log("taskstasks=", tasks);
  console.log("dateRange=", dateRange);
  return (
    <Grid container>
      <Grid item md={12}>
        <Paper className={fixedGraphHeightPaper}>
          {isLoading ? (
            <div className={classes.loader}>
              <CircularProgress color="secondary" />
            </div>
          ) : (
            <Grid container>
              <Grid item xs={12} sm={12} md={12}>
                <Grid container justifyContent={"space-between"}>
                  <Grid item>
                    <Typography
                      variant={"h4"}
                      color={"textPrimary"}
                      className={classNames(
                        "underline2",
                        selectedNodeKey != null && selectedNodeKey == dateRange
                          ? "underline3"
                          : null
                      )}
                    >
                      <b>{dateRange}</b>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <div className={className("flex justifyCenter")}>
                      <Typography
                        className={classes.current}
                        variant={width == "sm" || width == "xs" ? "h4" : "h2"}
                        component={width == "sm" || width == "xs" ? "h4" : "h2"}
                      >
                        {tasks
                          ? tasks.filter(
                              (task) =>
                                task.actionStatus == 2 || task.actionStatus == 5
                            ).length
                          : 0}
                      </Typography>

                      <Typography
                        className={classes.target}
                        variant={
                          width == "sm" || width == "xs" ? "body2" : "body1"
                        }
                      >
                        &nbsp;/&nbsp;
                        <b>{tasks ? tasks.length : 0}</b>
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid md={12}>
                <br />
                {tasks &&
                  tasks.map((task, i) => (
                    <Fragment>
                      {task.taskTypeId == 54 ||
                      task.taskTypeId == 52 ||
                      task.taskTypeId == 49 ||
                      task.taskTypeId == 47 ? (
                        <History
                          key={i}
                          index={i}
                          task={task}
                          totalTasks={tasks.length}
                          noNavigate
                        />
                      ) : (
                        <PlainLink
                          to={`/task-data/${task.taskId}/${task.taskTypeId}/${task.analysisTypeId}/${selectedDateRange}`}
                        >
                          <History
                            key={i}
                            index={i}
                            task={task}
                            totalTasks={tasks.length}
                          />
                        </PlainLink>
                      )}
                    </Fragment>
                  ))}
              </Grid>
            </Grid>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}

export default withWidth()(withStyles(taskSectionStyles)(TaskHistory));
