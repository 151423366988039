import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { Grid, Hidden, Typography } from "@mui/material/index";
import withStyles from "@mui/styles/withStyles";
import {
  ErrorSmallTypography,
  SessionErrorTypography,
} from "../../components/typography";
import CircularProgress from "@mui/material/CircularProgress";
import {
  ExternalOrangeBtnNew,
  WhiteAndOrangeText,
} from "../../components/buttons";
import { WHSExternalUserDetails} from "../../actions";
import { connect } from "react-redux";
import Home_bg from "../../assets/img/home_1.png";
import siteGraderHomeFormStyles from "../../assets/jss/siteGraderExternal/siteGraderHomeFormStyles";
import Input from "@mui/material/Input";
import NumberFormat from "react-number-format";

const PlainInput = withStyles((theme) => ({
  root: {
    // fontWeight: "bold"
  },
  underline: {
    "&::before": {
      borderBottom: "1px solid " + "#A0A0A0",
    },
    "&:hover": {
      borderBottom: "1px solid #fff",
    },
    "&:after": {
      borderBottom: "2px solid " + theme.palette.orangeColor,
    },
  },
}))(Input);

function WhsUserDetailsForm(props) {
  const { classes,WHSExternalUserDetails,WHSExternalUserDetailsReducer} = props;
  const [state, setState] = useState({
    domainName: "",
    domainNameError: "",
    errorMsg:
      window.lxrOptions && window.lxrOptions.errorMsg
        ? window.lxrOptions.errorMsg
        : null,
    emailId: "",
    emailIdError: "",
    phoneNumber: "",
    phoneNumberError: "",
  });

  const [loading, setLoading] = useState(false);
 console.log("loading in whs external", loading);
  const validate = () => {
    let isError = false;
    const errors = {};

    const UrlRegex =
      /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
    if (
      state.domainName.trim().length === 0 ||
      state.domainName.trim() === ""
    ) {
      isError = true;
      errors.domainNameError = "This field is required";
    } else if (
      state.domainName.trim().length !== 0 &&
      !UrlRegex.test(state.domainName.trim())
    ) {
      isError = true;
      errors.domainNameError = "URL Format Wrong";
    } else {
      errors.domainNameError = "";
    }

    if (state.emailId.trim().length === 0) {
      isError = true;
      errors.emailIdError = "This field is required";
    } else if (state.emailId.trim().length > 0) {
      let result = state.emailId
        .trim()
        .match(
          /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
        );
      if (!result) {
        isError = true;
        errors.emailIdError = "Please enter a valid email";
      } else {
        errors.emailIdError = "";
      }
    } else {
      errors.emailIdError = "";
    }

    if (state.phoneNumber.trim().length === 0) {
      isError = true;
      errors.phoneNumberError = "This field is required";
    } else if (state.phoneNumber.length > 0 && state.phoneNumber.length < 10) {
      isError = true;
      errors.phoneNumberError = "Phone Number should be a minimum of 10 digits";
    } else if (
      state.phoneNumber.length !== 0 &&
      state.phoneNumber.length > 10
    ) {
      isError = true;
      errors.phoneNumberError = "Phone Number should not exceed 10 digits";
    } else {
      errors.phoneNumberError = "";
    }

    setState({ ...state, ...errors });
    return isError;
  };

  const handleSubmit = async (event) => {
    console.log("inside submit function");
    setLoading(true);
    event.preventDefault();
    let error;
    error = validate();
    if (!error) {
    console.log("calling external user details api", error,state.domainName.trim(),
    state.emailId.trim(),
    state.phoneNumber.trim());

      await WHSExternalUserDetails(
        state.domainName.trim(),
        state.emailId.trim(),
        state.phoneNumber.trim()
      );
    }
    setLoading(false);
  };

  const handleChange = (name) => (event) => {
    setState({ ...state, [name]: event.target.value });
  };

  return (
    <Fragment>
      {state.errorMsg !== "" ? (
        <SessionErrorTypography align={"center"}>
          <b>{state.errorMsg}</b>
        </SessionErrorTypography>
      ) : (
        ""
      )}
      <form onSubmit={handleSubmit}>
        <Grid
          container
          justify={"center"}
          alignItems={"center"}
          className={classes.dialogP}
        >
          <Grid item md={12} sm={12} className={classes.formHeight}>
            <PlainInput
              placeholder="Your Website..."
              onChange={handleChange("domainName")}
              inputProps={{
                "aria-label": "Description",
              }}
              fullWidth={true}
              error={state.domainNameError !== ""}
              defaultValue={state.domainName}
              helpertext={state.domainNameError}
              style={{ color: "#333333", height: "2em" }}
            />
            {state.domainNameError !== "" ? (
              <ErrorSmallTypography
                className={classes.errorTypo}
                align={"left"}
              >
                <b>{state.domainNameError}</b>
              </ErrorSmallTypography>
            ) : (
              ""
            )}
          </Grid>
          <Grid item md={12} xs={12} sm={12} className={classes.formHeight}>
            <PlainInput
              placeholder="Your Email..."
              onChange={handleChange("emailId")}
              inputProps={{
                "aria-label": "Description",
              }}
              fullWidth={true}
              error={state.emailIdError !== ""}
              defaultValue={state.emailId}
              helperText={state.emailIdError}
              style={{ color: "#333333", height: "2em" }}
            />
            <Hidden mdDown>
              {" "}
              {state.emailIdError !== "" ? (
                <ErrorSmallTypography
                align={"left"}
                >
                  <b>{state.emailIdError}</b>
                </ErrorSmallTypography>
              ) : (
                ""
              )}
            </Hidden>
          </Grid>
          <Grid item md={12} xs={12} sm={12} className={classes.formHeight}>
            <NumberFormat
              placeholder="Your Phone Number..."
              fullWidth
              margin="normal"
              name="phoneNumber"
              value={state.phoneNumber}
              onValueChange={async (values) => {
                const { formattedValue, value } = values;
                await setState({ ...state, phoneNumber: value });
              }}
              error={state.phoneNumberError !== ""}
              helperText={state.phoneNumberError}
              InputLabelProps={{
                shrink: true,
              }}
              customInput={PlainInput}
              format="###-###-####"
              mask="_"
              style={{ color: "#333333", height: "2em" }}
            />
            {state.phoneNumberError !== "" ? (
              <ErrorSmallTypography
                className={classes.errorTypo}
                align={"left"}
              >
                <b>{state.phoneNumberError}</b>
              </ErrorSmallTypography>
            ) : (
              ""
            )}
          </Grid>
          <Grid item md={12} xs={12} sm={12}>
            {loading ? (
              <Typography align={"center"}>
                <CircularProgress color={"secondary"} />
              </Typography>
            ) : (
              <Typography align={"center"} style={{ minHeight: "14vh" }}>
                <ExternalOrangeBtnNew
                  type="submit"
                  size="large"
                  fullWidth
                  variant="contained"
                  className={classes.button}
                >
                  Get your Free Website Scorecard
                </ExternalOrangeBtnNew>
              </Typography>
            )}
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
}

WhsUserDetailsForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  defaultWebsite: state.leadGeneration.website,
  WHSExternalUserDetailsReducer: state.WHSExternalUserDetailsReducer,
});

const mapDispatchToProps = (dispatch) => ({
  WHSExternalUserDetails: (domainName, emailId, phoneNumber) => {
    return dispatch(WHSExternalUserDetails(domainName, emailId, phoneNumber));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(siteGraderHomeFormStyles)(WhsUserDetailsForm));
