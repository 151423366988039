import React from "react";
import { useTheme } from "@mui/material/styles";
import { withStyles } from "@mui/styles";
import { Typography, Grid, CircularProgress, Box } from "@mui/material";
// import {LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer} from 'recharts';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Legend,
} from "recharts";
import icons from "../components/icons.js";
import * as _ from "lodash";
import {
  mapDispatchToProps,
  mapStateToProps,
} from "../selectors/dashboard-selectors";
import { connect } from "react-redux";

const styles = () => ({
  toolTipDiv: {
    backgroundColor: "white",
    border: "1px solid #C0C0C0",
  },
  labelTypo: {
    margin: "8px",
    color: "#1E334E",
  },
  kpiTypo: {
    margin: "8px",
    color: "#1E334E",
  },
  KPITargetTypo: {
    margin: "8px",
    color: "#F58120",
  },
});

var moment = require("moment");
const formatLabel = (label) => moment.unix(label).format("MMM D");

function Chart(props) {
  const {
    classes,
    isLoading,
    kpiType,
    kpiTypeUi,
    kpiHistory,
    selectedDateRangeKPIValue,
    theme,
    isKpiGraphLoading,
  } = props;

  const customTooltip = ({ active, payload, label }) => {
    console.log("props are", active, payload, label);

    if (active && label && payload && payload.length) {
      return (
        <div className={classes.toolTipDiv}>
          <Typography className={classes.labelTypo}>
            {formatLabel(label)}
          </Typography>
          <Typography className={classes.kpiTypo}>
            KPI : {payload[0].payload.KPI.toFixed(2).toLocaleString()}
          </Typography>
          <Typography className={classes.KPITargetTypo}>
            KPI Target :{" "}
            {payload[0].payload.KPITarget.toFixed(2).toLocaleString()}
          </Typography>
        </div>
      );
    }
    return null;
  };

  const targetKpiValue =
    kpiHistory && kpiHistory.length > 0 ? _.first(kpiHistory).KPITarget : 0;
  console.log("kpiType==", kpiType);
  console.log("kpiTypeUi==", kpiTypeUi);

  return (
    <div className={"fullHeight"} id={"KpiGraph"}>
      <Grid container alignItems={"center"} spacing={2}>
        {kpiTypeUi !== "CPO" && kpiTypeUi !== "CPL" && kpiTypeUi == kpiType ? (
          <Grid item>{icons.square(theme.palette.secondary.main)}</Grid>
        ) : null}
        {kpiTypeUi !== "CPO" && kpiTypeUi !== "CPL" && kpiTypeUi == kpiType ? (
          <Grid item>
            <Typography
              variant={"body2"}
              color={"textPrimary"}
              style={{ fontWeight: "500" }}
            >
              KPI Target - {targetKpiValue}
            </Typography>
          </Grid>
        ) : null}
        <Grid item>{icons.square(theme.palette.primary.main)}</Grid>
        <Grid item>
          <Typography
            variant={"body2"}
            color={"textPrimary"}
            style={{ fontWeight: "500" }}
          >
            {" "}
            KPI -{" "}
            {selectedDateRangeKPIValue &&
            selectedDateRangeKPIValue.selectedDateRangeKPIValue > 0
              ? selectedDateRangeKPIValue.selectedDateRangeKPIValue.toFixed(2)
              : 0.0}
          </Typography>
        </Grid>
      </Grid>
      <br />
      <br />
      {isLoading || isKpiGraphLoading ? (
        <div
          className={"flex alignItemsCenter justifyCenter"}
          style={{ height: "70%" }}
        >
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <ResponsiveContainer width="100%" height={220}>
          <LineChart data={kpiHistory}>
            <XAxis
              dataKey="day"
              interval={"preserveStartEnd"}
              type={"number"}
              domain={["dataMin", "dataMax"]}
              tick={{ style: { fontSize: 12 } }}
              // tick={{angle: -45}} textAnchor={"end"}
              tickFormatter={formatLabel}
            />
            <YAxis axisLine={false} width={45} />
            <CartesianGrid strokeDasharray="3" vertical={false} />
            <Tooltip content={(props) => customTooltip(props)} />
            <Line
              type="monotone"
              dataKey="KPI"
              stroke={theme.palette.primary.main}
              strokeWidth={2}
              dot={{ r: 2 }}
              isAnimationActive={true}
              animationDuration={500}
            />
            {kpiTypeUi !== "CPO" &&
            kpiTypeUi !== "CPL" &&
            kpiTypeUi == kpiType ? (
              <Line
                type="monotone"
                dataKey="KPITarget"
                stroke={theme.palette.secondary.main}
                strokeWidth={2}
                dot={false}
                isAnimationActive={false}
                animationDuration={500}
              />
            ) : null}
          </LineChart>
        </ResponsiveContainer>
      )}
    </div>
  );
}
export default withStyles(styles, { withTheme: true })(Chart);
// export default withStyles({}, { withTheme: true })(Chart);
