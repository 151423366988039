import React, { Component, Fragment } from "react";
import * as types from "../../actions/actionTypes";
import PropTypes from "prop-types";
import { Button, Checkbox, Grid, TextField, Typography } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import { adminUserTable, updateSearchTerm } from "../../actions";
import { connect } from "react-redux";
import { DemoInputField } from "../input-forms";

export const CustomInputField = withStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
  },
}))(TextField);
export const CustomButton = withStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
  },
}))(Button);

class Search extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchTermError: "",
    };
  }

  handleInputs = (event) => {
    const { currentPage, sorting } = this.props.adminUserTable;
    console.log("Status Filter", event.target.value);
    this.props.updateSearch(event.target.value);
  };
  validate = () => {
    let isError = false;
    const errors = {};
    const { searchTerm } = this.props.adminUserTable;
    if (searchTerm.length == 0) {
      isError = true;
      errors.searchTermError = "This field is required";
    } else {
      errors.searchTermError = "";
    }
    if (isError) {
      this.setState({
        ...this.state,
        ...errors,
      });
    }
    return isError;
  };
  handleSubmit = (event) => {
    event.preventDefault();
    const {
      sorting,
      statusFilter,
      searchTerm,
      currentPage,
      channelFilter,
      userTypeToggle,
    } = this.props.adminUserTable;
    this.props.getAdminUserTableData(
      currentPage,
      sorting,
      true,
      statusFilter,
      searchTerm.trim(),
      channelFilter,
      userTypeToggle
    );
  };

  render() {
    const { searchTerm } = this.props.adminUserTable;
    console.log("searchTerm=", searchTerm);
    return (
      <form onSubmit={this.handleSubmit}>
        <Grid container justifyContent={"flex-end"} alignItems={"flex-end"}>
          <Grid item>
            <Typography align={"right"} className={"alignItemsCenter"}>
              <CustomInputField
                error={this.state.searchTermError != ""}
                helperText={this.state.searchTermError}
                placeholder="Name/Email/Company"
                margin="normal"
                value={searchTerm}
                onChange={this.handleInputs}
              />
            </Typography>
          </Grid>
          <Grid item>
            <CustomButton type="submit" variant={"outlined"}>
              Search
            </CustomButton>
          </Grid>
        </Grid>
      </form>
    );
  }
}

Search.propTypes = {};
const mapStateToProps = (state, ownProps) => ({
  adminUserTable: state.adminUserTable,
});
const mapDispatchToProps = (dispatch) => ({
  getAdminUserTableData: (
    currentPage,
    sorting,
    sortingPresent,
    statusFilter,
    searchTerm,
    channelFilter,
    userTypeToggle
  ) => {
    return dispatch(
      adminUserTable(
        currentPage,
        sorting,
        sortingPresent,
        statusFilter,
        searchTerm,
        channelFilter,
        userTypeToggle
      )
    );
  },
  updateSearch: (searchTerm) => {
    return dispatch(updateSearchTerm(searchTerm));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Search);
