import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { JsSectionTypography } from "../../../components/typography";
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Select,
  Typography,
  TextField,
  Autocomplete,
  Chip,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { JsAddUserLabelTypography } from "../../../components/typography";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { createTheme, ThemeProvider } from "@mui/material";

import "../jumpstart.css";
import {
  JsBlackContainedBtn,
  JsOrangeContainedBtn,
} from "../../../components/buttons";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import { updateUserDetails } from "../../../actions/jumpstartRevamped";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Authority } from "../commonComponents/jumpstartEnum";
import {
  emailValidationMtd,
  formatDate,
} from "../../commonFunctions/commonFunctions";
import dayjs, { Dayjs } from "dayjs";

const theme = createTheme({
  components: {
    MuiPickersYear: {
      styleOverrides: {
        yearButton: {
          fontSize: "0.875rem",
        },
      },
    },
  },
});

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function JsAddUser(props) {
  const {
    handleCloseIcon,
    jsAccounts,
    handleJsUser,
    fetchUserInfoReducer,
    updateAcc,
    handleAccountUpdate,
    updateUserDetails,
    token,
    handleOpenDialog,
    handleSnackbarOpen,
    handleError,
    handleSnackbarMsg,
  } = props;

  const [login, setLogin] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [expiry, setExpiry] = useState(null);
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [authority, setAuthority] = useState("ANALYST");
  const [isDatePickerDisabled, setIsDatePickerDisabled] = useState(true);
  const [createdBy, setCreatedBy] = useState(
    fetchUserInfoReducer.login || null
  );
  const [createdDate, setCreatedDate] = useState(null);
  const [sectionHeading, setSectionHeading] = useState("+ Add New User");

  const [currentUserAuthority, setCurrentUserAuthority] = useState(
    fetchUserInfoReducer.authority || null
  );

  const checkForSpaces = (name) => {
    const spaceRegex = /\s+/;
    return spaceRegex.test(name);
  };

  const checkForSpacesAndSpecialCharacters = (name) => {
    const regex = /[^\w\s]/;
    return regex.test(name);
  };

  useEffect(() => {
    if (updateAcc !== undefined && Object.keys(updateAcc).length > 1) {
      setLogin(updateAcc.login);
      setSectionHeading("Update User");
      setFirstName(updateAcc.firstName);
      setLastName(updateAcc.lastName);
      setEmail(updateAcc.email);
      setExpiry(
        updateAcc.expiryDate != null ? formatDate(updateAcc.expiryDate) : null
      );
      setAuthority(updateAcc.authority);
      setSelectedAccounts(updateAcc.jsAccounts);
    }
  }, [updateAcc]);

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleLoginChange = (event) => {
    setLogin(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleRoleChange = (event) => {
    setAuthority(event.target.value);
  };

  const handleDatePickerChange = (newDate) => {
    setExpiry(formatDate(newDate));
  };

  const [expiryCheckedDefault, setExpiryCheckedDefault] = useState(true);

  const handleExpiryChecked = (event) => {
    if (event.target.checked) {
      setExpiry(null);
      setIsDatePickerDisabled(true);
      setExpiryCheckedDefault(true);
    } else {
      setIsDatePickerDisabled(false);
      setExpiry(undefined);
      setExpiryCheckedDefault(false);
    }
  };

  useEffect(() => {
    if (expiry != null && expiry != undefined) {
      setExpiryCheckedDefault(false);
      setIsDatePickerDisabled(false);
    }
  }, [expiry]);

  const handleCheckboxChange = (event, option) => {
    const selectedIndex = selectedAccounts.findIndex(
      (account) => account.id === option.id
    );
    let newSelectedAccounts = [];
    if (selectedIndex === -1) {
      newSelectedAccounts = newSelectedAccounts.concat(
        selectedAccounts,
        option
      );
    } else {
      newSelectedAccounts = newSelectedAccounts.concat(
        selectedAccounts.slice(0, selectedIndex),
        selectedAccounts.slice(selectedIndex + 1)
      );
    }

    setSelectedAccounts(newSelectedAccounts);
  };

  const handleClearButton = (option) => {
    setSelectedAccounts((prevSelectedAccounts) =>
      prevSelectedAccounts.filter((account) => account !== option)
    );
  };

  const handleAddUser = () => {
    const userData = {
      login: login,
      firstName: firstName,
      lastName: lastName,
      email: email,
      imageUrl: null,
      authority: authority,
      createdBy: createdBy,
      createdDate: createdDate,
      jsAccounts: selectedAccounts,
      expiryDate: expiry,
    };
    handleJsUser(userData);
  };

  const handleUpdate = async () => {
    const updatedUser = {
      ...updateAcc,
      login: login,
      firstName: firstName,
      lastName: lastName,
      email: email,
      expiryDate: expiry,
      authority: authority,
      jsAccounts: selectedAccounts,
    };
    const data = await updateUserDetails(token, updatedUser);
    if (data.status == 200) {
      handleAccountUpdate(updatedUser);
      handleError(false);
      handleSnackbarMsg("Successfully updated user details");
      handleSnackbarOpen(true);
      await handleCloseIcon();
    } else {
      handleError(true);
      handleSnackbarMsg("Error in updating user details");
      handleSnackbarOpen(true);
    }
  };

  const handleClearAllButton = (event) => {
    setSelectedAccounts([]);
  };
  useEffect(() => {
    const close = document.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
    if (close !== undefined) {
      close.addEventListener("click", handleClearAllButton);
    }
  }, [selectedAccounts]);

  const isDisableCheck = () => {
    if (
      login == undefined ||
      login == "" ||
      checkForSpaces(login) ||
      firstName == undefined ||
      firstName == "" ||
      checkForSpaces(firstName) ||
      lastName == undefined ||
      lastName == "" ||
      checkForSpaces(lastName) ||
      email == undefined ||
      email == "" ||
      !emailValidationMtd(email) ||
      authority == "" ||
      (expiryCheckedDefault == false && expiry == undefined)
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <div
        style={{
          zIndex: 2000,
          backgroundColor: "#fff",
          position: "sticky",
          top: 0,
        }}
      >
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          style={{ padding: "2%", borderBottom: "1px solid #E6E6E6" }}
        >
          <Grid item xs={11}>
            <JsSectionTypography style={{ fontSize: "1rem" }}>
              {sectionHeading}
            </JsSectionTypography>
          </Grid>
          <Grid item xs={1}>
            <IconButton aria-label="close" onClick={handleCloseIcon}>
              <CloseIcon color="secondary" />
            </IconButton>
          </Grid>
        </Grid>
      </div>
      {/* Page Content */}
      <div className="content-container">
        <Grid
          container
          direction="row"
          columnSpacing={2}
          rowSpacing={2}
          style={{ padding: "2%" }}
        >
          <Grid item xs={12} md={6}>
            <label htmlFor="firstName">
              <JsAddUserLabelTypography>
                <span className="numberContainer">1</span>First Name
              </JsAddUserLabelTypography>
            </label>
            <TextField
              id="firstName"
              value={firstName}
              error={checkForSpaces(firstName)}
              helperText={
                checkForSpaces(firstName)
                  ? "First name should not contain any spaces"
                  : ""
              }
              onChange={handleFirstNameChange}
              size="small"
              placeholder="Type your text here"
              fullWidth
              sx={{
                ".MuiOutlinedInput-root": {
                  fontSize: "0.875rem",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <label htmlFor="lastName">
              <JsAddUserLabelTypography>
                <span className="numberContainer">2</span>Last Name
              </JsAddUserLabelTypography>
            </label>
            <TextField
              id="lastName"
              error={checkForSpaces(lastName)}
              helperText={
                checkForSpaces(lastName)
                  ? "Last name should not contain any spaces"
                  : ""
              }
              value={lastName}
              onChange={handleLastNameChange}
              size="small"
              placeholder="Type your text here"
              fullWidth
              sx={{
                ".MuiOutlinedInput-root": {
                  fontSize: "0.875rem",
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <label htmlFor="login">
              <JsAddUserLabelTypography>
                <span className="numberContainer">3</span>Username
                <Tooltip
                  title="Username should not contain any spaces and special characters"
                  placement="right-end"
                >
                  <IconButton size="small">
                    <InfoOutlinedIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </JsAddUserLabelTypography>
            </label>
            <TextField
              id="login"
              error={checkForSpacesAndSpecialCharacters(login)}
              helperText={
                checkForSpacesAndSpecialCharacters(login)
                  ? "Username should not contain any spaces or special characters"
                  : ""
              }
              value={login}
              onChange={handleLoginChange}
              size="small"
              placeholder="Type your text here"
              fullWidth
              sx={{
                ".MuiOutlinedInput-root": {
                  fontSize: "0.875rem",
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <label htmlFor="email">
              <JsAddUserLabelTypography>
                <span className="numberContainer">4</span>Type a Valid Email
                Address
              </JsAddUserLabelTypography>
            </label>
            <TextField
              id="email"
              error={email != undefined && !emailValidationMtd(email)}
              helperText={
                email != undefined && !emailValidationMtd(email)
                  ? "Please enter valid email format"
                  : ""
              }
              value={email}
              onChange={handleEmailChange}
              size="small"
              placeholder="Type your text here"
              fullWidth
              sx={{
                ".MuiOutlinedInput-root": {
                  fontSize: "0.875rem",
                },
              }}
            />
          </Grid>
          {authority == Authority.ADMIN ? (
            ""
          ) : (
            <>
              <Grid
                item
                xs={12}
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
              >
                <Grid item>
                  <label htmlFor="authority">
                    <JsAddUserLabelTypography>
                      <span className="numberContainer">5</span>Assign a Role:
                    </JsAddUserLabelTypography>
                  </label>
                </Grid>
                <Grid item>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-form-control-label-placement"
                      name="position"
                      onChange={handleRoleChange}
                      value={authority}
                    >
                      <FormControlLabel
                        value="MANAGER"
                        control={<Radio size="small" />}
                        label={
                          <Typography fontSize="0.875rem" color="#A4A4A4">
                            Manager
                          </Typography>
                        }
                        labelPlacement="End"
                      />
                      <FormControlLabel
                        value="REVIEWER"
                        control={<Radio size="small" />}
                        label={
                          <Typography fontSize="0.875rem" color="#A4A4A4">
                            Reviewer
                          </Typography>
                        }
                        labelPlacement="End"
                      />
                      <FormControlLabel
                        value="ANALYST"
                        control={<Radio size="small" />}
                        label={
                          <Typography fontSize="0.875rem" color="#A4A4A4">
                            Analyst
                          </Typography>
                        }
                        labelPlacement="End"
                      />
                      <FormControlLabel
                        value="RWF"
                        control={<Radio size="small" />}
                        label={
                          <Typography fontSize="0.875rem" color="#A4A4A4">
                            Vendor
                          </Typography>
                        }
                        labelPlacement="End"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <JsAddUserLabelTypography>
                    <span className="numberContainer">6</span>Set Expiry:
                  </JsAddUserLabelTypography>
                </Grid>
                <Grid item>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <ThemeProvider theme={theme}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          disablePast
                          value={dayjs(expiry)}
                          disabled={isDatePickerDisabled}
                          onChange={handleDatePickerChange}
                          sx={{
                            ".MuiOutlinedInput-root": {
                              color: "#9A9A9A",
                              fontSize: "0.875rem",
                            },
                            ".MuiOutlinedInput-input": {
                              padding: "11px 14px",
                            },
                            width: "100px",
                          }}
                          slotProps={{ textField: "medium" }}
                        />
                      </DemoContainer>
                    </ThemeProvider>
                  </LocalizationProvider>
                </Grid>
                <Grid item>
                  <Typography fontSize="0.875rem" color="#A4A4A4">
                    <input
                      type="checkbox"
                      checked={expiryCheckedDefault}
                      onChange={handleExpiryChecked}
                    />
                    &nbsp; Doesn't Expire
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <JsAddUserLabelTypography>
                  <span className="numberContainer">7</span>Select Account(s) to
                  Give Access:
                </JsAddUserLabelTypography>
                <Autocomplete
                  multiple
                  id="js-accounts-list"
                  options={jsAccounts || []}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.companyName}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  limitTags={2}
                  value={selectedAccounts.length > 0 ? selectedAccounts : []}
                  renderOption={(props, option, { selected }) => (
                    <li
                      key={option.id}
                      {...props}
                      onClick={(event) => handleCheckboxChange(event, option)}
                    >
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.companyName}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      placeholder="Select Account(s) to Give Access"
                      sx={{
                        ".MuiOutlinedInput-input": {
                          fontSize: "0.875rem",
                        },
                      }}
                    />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={index}
                        label={option.companyName}
                        {...getTagProps({ index })}
                        onDelete={() => handleClearButton(option)}
                      />
                    ))
                  }
                />
              </Grid>
            </>
          )}
        </Grid>
      </div>
      <div className="footer-pin">
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          style={{
            padding: "2%",
            backgroundColor: "#F8F9F9",
          }}
        >
          {updateAcc !== undefined ? (
            <Grid
              item
              container
              xs={12}
              direction="row"
              justifyContent="space-between"
            >
              <Grid item xs={3}>
                <Button
                  type="text"
                  color="error"
                  style={{ float: "left", textTransform: "none" }}
                  onClick={() => handleOpenDialog()}
                >
                  <DeleteOutlineIcon fontSize="small" color="error" />
                  Delete User
                </Button>
              </Grid>
              <Grid item xs={3}>
                <JsOrangeContainedBtn
                  style={{ float: "right", fontSize: "0.875rem" }}
                  onClick={() => handleUpdate()}
                  disabled={isDisableCheck()}
                >
                  Update User
                </JsOrangeContainedBtn>
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={3}>
              <JsOrangeContainedBtn
                style={{ float: "right", fontSize: "0.875rem" }}
                onClick={handleAddUser}
                disabled={isDisableCheck()}
              >
                Add User
              </JsOrangeContainedBtn>
            </Grid>
          )}
        </Grid>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  fetchUserInfoReducer: state.fetchUserInfoReducer,
});
const mapDispatchToProps = (dispatch) => ({
  updateUserDetails: (jwtToken, userData) =>
    dispatch(updateUserDetails(jwtToken, userData)),
});
export default connect(mapStateToProps, mapDispatchToProps)(JsAddUser);
