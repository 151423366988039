import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import "../jumpstart.css";
import {
  Box,
  Drawer,
  Grid,
  Pagination,
  Skeleton,
  TableSortLabel,
  Tooltip,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import JsSearchBar from "../jsSearchBar";
import { useHistory } from "react-router-dom";
import StyledTableCell from "../commonComponents/styledTableCell";
import { formatDate } from "../../commonFunctions/commonFunctions";
import {
  AccountStage,
  Authority,
} from "../commonComponents/jumpstartEnum";
import { FormatAccountStatus } from "../commonComponents/formatStatus";
import AssignAccount from "./assignAccount";
import {
  JsAccountsHeadCells,
  getComparator,
  stableSort,
} from "../commonComponents/sortingFunctions";
import PropTypes from "prop-types";
import { visuallyHidden } from "@mui/utils";

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead sx={{ backgroundColor: "#F2F6F9" }}>
      <TableRow>
        {JsAccountsHeadCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label !== "Analyst" && headCell.label !== "Reviewer" ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                sx={{
                  "& .MuiTableSortLabel-icon": {
                    opacity: 1,
                    color: "#CCCFD1",
                  },
                }}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

function JsAccounts(props) {
  const {
    jsAccounts,
    accountStage,
    fetchUserInfoReducer,
    fetchAllUserDataReducer,
    loading,
  } = props;

  const [selectedAcc, setSelectedAcc] = useState();
  const [searchFlag, setSearchFlag] = useState(false);
  const history = useHistory();

  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);
  const [userAuthority, setUserAuthority] = useState();
  const rowsPerPage = 10;
  const [open, setIsOpen] = useState(false);
  const [sort, setSort] = useState("All");
  const [analysts, setAnalysts] = useState();
  const [reviewers, setReviewers] = useState();

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("id");

  const handleCloseIcon = () => {
    setIsOpen(false);
  };

  const handleSortType = (e) => {
    setSort(e.target.value);
  };

  useEffect(async () => {
    setPage(0);
    setOrder("asc");
    setOrderBy("id");
  }, [accountStage]);

  useEffect(() => {
    if (
      fetchAllUserDataReducer &&
      fetchAllUserDataReducer.message == "JS_ALL_USER_DETAILS_RECEIVED"
    ) {
      const data = Object.values(fetchAllUserDataReducer);
      setAnalysts(
        data.filter(
          (row) =>
            row.authority == Authority.ANALYST || row.Authority == Authority.RWF
        )
      );
      setReviewers(data.filter((row) => row.authority == Authority.REVIEWER));
    }
  }, [fetchAllUserDataReducer]);

  useEffect(() => {
    if (fetchUserInfoReducer.message == "JS_USER_INFO_RECEIVED") {
      setUserAuthority(fetchUserInfoReducer.authority);
    }
  }, [fetchUserInfoReducer]);

  const handleSelectedAcc = (account) => {
    setSelectedAcc(account);
    setSearchFlag(true);
  };
  const handleClearSearch = () => {
    setSelectedAcc();
    setSearchFlag(false);
  };

  const handleRowClick = (users, accountId, status) => {
    const isReviewerApproved = status.endsWith("_REVIEWER_APPROVED");
    const isReviewInProgress = status.endsWith("_IN_REVIEW");
    const isAnalystOrRWF =
      userAuthority === Authority.ANALYST || userAuthority === Authority.RWF;

    const pathPrefix =
      accountStage === AccountStage.CAMPAIGN_FRAMEWORK
        ? "campaign-framework"
        : "campaign-structure";

    const pathSuffix = isReviewerApproved ? "-completed" : "";

    history.push(`/${pathPrefix}${pathSuffix}/${accountId}`);
  };

  const setStatusColor = (status) => {
    if (status.includes("CREATED")) {
      return "yellowStatusColor";
    } else if (status.includes("IN_PROGRESS")) {
      return "orangeStatusColor";
    } else if (status.includes("IN_REVIEW")) {
      return "blueStatusColor";
    } else if (status.includes("REVIEWER_APPROVED")) {
      return "purpleStatusColor";
    } else if (status == "COMPLETED") {
    }
  };

  const handleChangePage = (event, newPage) => {
    newPage = newPage - 1;
    setPage(newPage);
  };

  const [visibleRows, setVisibleRows] = useState([]);

  useEffect(() => {
    if (jsAccounts.length > 0) {
      const startIndex = page * rowsPerPage;
      const endIndex = Math.min(startIndex + rowsPerPage, jsAccounts.length);
      setVisibleRows(
        stableSort(jsAccounts, getComparator(order, orderBy)).slice(
          startIndex,
          endIndex
        )
      );
    } else {
      setVisibleRows([]);
    }
  }, [page, jsAccounts, order, orderBy, accountStage]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  return (
    <div className="content-container-padding ">
      <Grid
        container
        direction="row"
        style={{ background: "#fff" }}
        justifyContent="space-between"
        alignItems="center"
        className="searchBar"
      >
        <Grid item xs={9} sm={7} md={6} lg={3}>
          {jsAccounts !== undefined && loading == false ? (
            <JsSearchBar
              data={jsAccounts}
              handleSelectedAcc={handleSelectedAcc}
              handleClearSearch={handleClearSearch}
              component={"JsAccounts"}
            />
          ) : (
            ""
          )}
        </Grid>
        <Grid item xs={2}>
          {/* <Typography variant="subtitle2" style={{float:"right", display:"flex", alignItems:"center"}}>
            Show :
            <FormControl variant="standard" sx={{ ml: 1, minWidth: 120 }}>
              <Select
                // value={age}
                // onChange={handleChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                autoWidth
              >
                <MenuItem value={"All"} sx={{fontSize:"0.875rem"}}>All</MenuItem>
                {accountStage == AccountStage.CAMPAIGN_FRAMEWORK &&
                  <MenuItem value={"Created"} sx={{fontSize:"0.875rem"}}>Created</MenuItem>
                }
                <MenuItem value={"In Progress"} sx={{fontSize:"0.875rem"}}>In Progress</MenuItem>
                <MenuItem value={"In Review"} sx={{fontSize:"0.875rem"}}>In Review</MenuItem>
                <MenuItem value={"Reviewer Approved"} sx={{fontSize:"0.875rem"}}>
                  Reviewer Approved
                </MenuItem>
              </Select>
            </FormControl>
          </Typography> */}
        </Grid>
      </Grid>

      <TableContainer
        component={Paper}
        style={{ boxShadow: "none", border: 0 }}
      >
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {jsAccounts !== undefined && searchFlag !== true ? (
              visibleRows.map((row, index) => (
                <TableRow
                  onClick={() =>
                    handleRowClick(row.workPersons.length, row.id, row.status)
                  }
                  key={row.id}
                  style={{ cursor: "pointer" }}
                >
                  <>
                    <StyledTableCell>
                      {loading ? (
                        <Skeleton />
                      ) : row.seAccountId != null ? (
                        row.seAccountId
                      ) : (
                        "-"
                      )}
                    </StyledTableCell>
                    <StyledTableCell>
                      {loading ? <Skeleton /> : row.companyName}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        color:
                          row.workPersons.length > 0 ? "#747474" : "#ED4A4A",
                      }}
                    >
                      {loading ? (
                        <Skeleton />
                      ) : row.workPersons.length > 0 ? (
                        <>
                          <span>{row.workPersons[0].login}</span>
                          {row.workPersons.length > 1 ? (
                            <span
                              style={{ color: "#F58120", paddingLeft: "4px" }}
                            >
                              <Tooltip
                                title={
                                  <>
                                    <ul
                                      style={{
                                        listStyle: "none",
                                        padding: 0,
                                        margin: 0,
                                      }}
                                    >
                                      {row.workPersons
                                        .slice(1)
                                        .map((workPerson) => (
                                          <li
                                            style={{ fontSize: "0.75rem" }}
                                            key={workPerson.login}
                                          >
                                            {workPerson.login}
                                          </li>
                                        ))}
                                    </ul>
                                  </>
                                }
                              >
                                + {row.workPersons.length - 1} more
                              </Tooltip>
                            </span>
                          ) : null}
                        </>
                      ) : (
                        "Not Assigned"
                      )}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        color: row.reviewers.length > 0 ? "#747474" : "#ED4A4A",
                      }}
                    >
                      {loading ? (
                        <Skeleton />
                      ) : row.reviewers.length > 0 ? (
                        <>
                          <span>{row.reviewers[0].login}</span>
                          {row.reviewers.length > 1 ? (
                            <span
                              style={{ color: "#F58120", paddingLeft: "4px" }}
                            >
                              <Tooltip
                                title={
                                  <>
                                    <ul
                                      style={{
                                        listStyle: "none",
                                        padding: 0,
                                        margin: 0,
                                      }}
                                    >
                                      {row.reviewers
                                        .slice(1)
                                        .map((reviewer) => (
                                          <li
                                            style={{ fontSize: "0.75rem" }}
                                            key={reviewer.login}
                                          >
                                            {reviewer.login}
                                          </li>
                                        ))}
                                    </ul>
                                  </>
                                }
                              >
                                + {row.reviewers.length - 1} more
                              </Tooltip>
                            </span>
                          ) : null}
                        </>
                      ) : (
                        "Not Assigned"
                      )}
                    </StyledTableCell>
                    <StyledTableCell>
                      {loading ? <Skeleton /> : formatDate(row.createdDate)}
                    </StyledTableCell>
                    <StyledTableCell>
                      {row.lastModifiedDate == null
                        ? "NA"
                        : formatDate(row.lastModifiedDate)}
                    </StyledTableCell>
                    <StyledTableCell>
                      {loading ? (
                        <Skeleton />
                      ) : (
                        <span className={setStatusColor(row.status)}>
                          {FormatAccountStatus(row.status)}
                        </span>
                      )}
                    </StyledTableCell>
                  </>
                  {/* )} */}
                </TableRow>
              ))
            ) : jsAccounts !== undefined &&
              searchFlag == true &&
              selectedAcc !== undefined ? (
              <TableRow
                onClick={() =>
                  handleRowClick(
                    selectedAcc.workPersons.length,
                    selectedAcc.id,
                    selectedAcc.status
                  )
                }
                key={selectedAcc.id}
                style={{ cursor: "pointer" }}
              >
                <StyledTableCell component="th" scope="row">
                  {selectedAcc.seAccountId}
                </StyledTableCell>
                <StyledTableCell>{selectedAcc.companyName}</StyledTableCell>
                <StyledTableCell
                  style={{
                    color:
                      selectedAcc.workPersons.length > 0
                        ? "#747474"
                        : "#ED4A4A",
                  }}
                >
                  {selectedAcc.workPersons.length > 0 ? (
                    <>
                      <span>{selectedAcc.workPersons[0].login}</span>
                      {selectedAcc.workPersons.length > 1 ? (
                        <span style={{ color: "#F58120", paddingLeft: "4px" }}>
                          <Tooltip
                            title={
                              <>
                                <ul
                                  style={{
                                    listStyle: "none",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  {selectedAcc.workPersons
                                    .slice(1)
                                    .map((workPerson) => (
                                      <li
                                        style={{ fontSize: "0.75rem" }}
                                        key={workPerson.login}
                                      >
                                        {workPerson.login}
                                      </li>
                                    ))}
                                </ul>
                              </>
                            }
                          >
                            + {selectedAcc.workPersons.length - 1} more
                          </Tooltip>
                        </span>
                      ) : null}
                    </>
                  ) : (
                    "Not Assigned"
                  )}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    color:
                      selectedAcc.reviewers.length > 0 ? "#747474" : "#ED4A4A",
                  }}
                >
                  {selectedAcc.reviewers.length > 0 ? (
                    <>
                      <span>{selectedAcc.reviewers[0].login}</span>
                      {selectedAcc.reviewers.length > 1 ? (
                        <span style={{ color: "#F58120", paddingLeft: "4px" }}>
                          <Tooltip
                            title={
                              <>
                                <ul
                                  style={{
                                    listStyle: "none",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  {selectedAcc.reviewers
                                    .slice(1)
                                    .map((reviewer) => (
                                      <li
                                        style={{ fontSize: "0.75rem" }}
                                        key={reviewer.login}
                                      >
                                        {reviewer.login}
                                      </li>
                                    ))}
                                </ul>
                              </>
                            }
                          >
                            + {selectedAcc.reviewers.length - 1} more
                          </Tooltip>
                        </span>
                      ) : null}
                    </>
                  ) : (
                    "Not Assigned"
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  {formatDate(selectedAcc.createdDate)}
                </StyledTableCell>
                <StyledTableCell>
                  {selectedAcc.lastModifiedDate == null
                    ? "NA"
                    : formatDate(selectedAcc.lastModifiedDate)}
                </StyledTableCell>
                <StyledTableCell>
                  <span className={setStatusColor(selectedAcc.status)}>
                    {FormatAccountStatus(selectedAcc.status)}
                  </span>
                </StyledTableCell>
              </TableRow>
            ) : (
              ""
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {selectedAcc == null ? (
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ background: "#fff", padding: "1% 0" }}
        >
          <Pagination
            count={Math.ceil(jsAccounts.length / rowsPerPage)}
            page={page + 1}
            onChange={handleChangePage}
            showFirstButton
            showLastButton
          />
        </Grid>
      ) : (
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ background: "#fff", padding: "1% 0" }}
        >
          <Pagination
            count={1}
            page={1}
            showFirstButton
            showLastButton
            disabled
          />
        </Grid>
      )}
      <Drawer
        anchor="right"
        open={open}
        onClose={handleCloseIcon}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}
      >
        <Box sx={{ width: 550 }}>
          <AssignAccount
            handleCloseDrawer={handleCloseIcon}
            analysts={analysts}
            reviewers={reviewers}
          />
        </Box>
      </Drawer>
    </div>
  );
}

const mapStateToProps = (state) => ({
  fetchUserInfoReducer: state.fetchUserInfoReducer,
  fetchAllUserDataReducer: state.fetchAllUserDataReducer,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(JsAccounts);
