import { Card, CardContent, Grid, Typography } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import icons from "../icons";
import classNames from "classnames";
import * as PropTypes from "prop-types";
import React from "react";
import siteGraderBg from "../../assets/img/site-grader-bg.jpg";

function ClientTestimonial(props) {
  return (
    <Card className={props.classes.iconsContainer}>
      <CardContent>
        <br />
        <Grid
          container
          justifyContent={"center"}
          alignItems={"center"}
          style={{ textAlign: "center" }}
        >
          <Grid item md={3} sm={12}>
            {icons.client_2}
          </Grid>
          <Grid item md={3} sm={12}>
            {icons.client_3}
          </Grid>
          <Grid item md={3} sm={12}>
            {icons.client_4}
          </Grid>
          <Grid item md={3} sm={12}>
            {icons.client_5}
          </Grid>
        </Grid>
        <br />
        <br />
        <Grid container className={props.classes.testimonialSection}>
          <Grid item md={4} style={{ borderRadius: 5 }}>
            <iframe
              id="video"
              className="embed-responsive-item iframe-client-review border-white embed-responsive-16by9"
              style={{
                width: "100%",
                height: 233,
                overflow: "auto",
                borderRadius: 5,
              }}
              src="https://www.youtube.com/embed/DNqTdH_60Fg?rel=0&amp;showinfo=0"
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
            ></iframe>
          </Grid>
          <Grid item sm={12} xs={12} md={8}>
            <div className={classNames(props.classes.details, "fullWidth")}>
              <CardContent className={props.classes.content}>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  className={props.classes.para}
                >
                  <sup>{icons.qoute_left}</sup>&nbsp;
                  {/*<sup style={{fontSize:40}}>"</sup>*/}
                  LXRGuide the thing that really has made the biggest difference
                  is how simple it's been to go in and make decisions every
                  day... I feel like we saw immediate results in that, to begin
                  with.
                </Typography>
                <br />
                <Grid container spacing={2}>
                  <Grid item>
                    <div>{icons.client_1_icon}</div>
                    <Typography
                      component={"p"}
                      variant={"h6"}
                      color="textSecondary"
                    >
                      <b>Thomas Ellis</b>
                      <Typography
                        component={"span"}
                        color="textSecondary"
                        className={props.classes.client1}
                      >
                        Owner
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item></Grid>
                </Grid>
              </CardContent>
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

ClientTestimonial.propTypes = { classes: PropTypes.any };

const styles = (theme) => ({
  /* Testimonial styles*/
  card: {
    display: "flex",
    boxShadow: "none",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  para: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
  },
  client1: {
    fontFamily: "Montserrat",
    fontWeight: "lighter",
    fontSize: 20,
  },
  testimonialSection: {
    padding: "60px 5px 0px 5px",
  },
  iconsContainer: {
    boxShadow: "unset",
    // paddingTop: 50
    // paddingTop: 20
  },
});

export default withStyles(styles)(ClientTestimonial);
