import {store} from '../reducers/common';
import {
    ACTIVITY_REPORT_ID,
    DASHBOARD_NOTIFICATION_ID,
    DASHBOARD_VIEW_VALUE_UPDATED, ON_PRODUCT_TOUR_CLOSE, REPORT_NOTIFICATION_ID, RESET_MENU,
    SCROLL_HANDLER_ENDED,
    SCROLL_HANDLER_START, TASK_NOTIFICATION_ID, TOGGLE_THANK_YOU_BANNER, UPDATE_MENU, UPDATE_PRODUCT_TOUR_TRACK
} from "../actions/actionTypes";
import {history} from "../index"
import * as types from "../actions/actionTypes";
import {trackTour, updateDrawer} from "../actions";
import * as _ from "lodash";

window.taskTypeId = 0;

export async function getTaskTypeId() {
    const data = await store.getState().groupedTaskData["TaskType"];
    if (!_.isEmpty(data)) {
        let taskTypeIds = _.keys(data).filter(eachKey => eachKey != 51 && eachKey != 54 && eachKey != 49);
        if (_.isArray(taskTypeIds) && taskTypeIds.length > 0) {
            window.taskTypeId = taskTypeIds[0];
        }
    }
}

const anchorEl = "<button class=\"MuiButtonBase-root-166 MuiIconButton-root-324 Connect-MiniDrawer--accountIcon-278\" tabindex=\"0\" type=\"button\" aria-haspopup=\"true\" aria-owns=\"material-appbar\"><span class=\"MuiIconButton-label-329\"><img src=\"/static/media/profile.5132c4e5.svg\" alt=\"User Dialog\" width=\"30\" height=\"30\">&nbsp;<svg class=\"MuiSvgIcon-root-330\" focusable=\"false\" viewBox=\"0 0 24 24\" aria-hidden=\"true\" role=\"presentation\" style=\"font-size: 18px;\"><g><path d=\"M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z\"></path></g></svg></span><span class=\"MuiTouchRipple-root-362\"></span></button>"

export const tour = (TypeId) => ({
    id: "hello-hopscotch",
    // showPrevButton: !0,
    // bubbleWidth:0,
    onEnd: async function () {
        // alert("onENd");
        await store.dispatch({type: SCROLL_HANDLER_ENDED});
        await store.dispatch({type: TOGGLE_THANK_YOU_BANNER});
        // window.location.hash = `/task-new/${taskTypeId}`;
    },
    onStart: async function () {
        await store.dispatch({type: SCROLL_HANDLER_START});
        document.getElementsByClassName("hopscotch-bubble-number").innerHTML = "";
        // await window.handleNested(0);
        // window.taskTypeId = getTaskTypeId();
        // console.log("window.taskTypeId-", window.taskTypeId);
        // window.location.hash = "/dashboard/"

    },
    onClose: async function () {
        // alert("onclose");
        await store.dispatch({type: SCROLL_HANDLER_ENDED});
        await store.dispatch({type: ON_PRODUCT_TOUR_CLOSE});
    },

    steps: [
        {
            target: "accounts-drop-Down",
            title: "Multiple Accounts",
            content: "If you have multiple accounts, use this dropdown to select which account to focus on.",
            placement: "bottom",
            delay: 100,
            arrowOffset: "center",
            onShow: async () => {
                await store.dispatch(updateDrawer(true));
                window.handleNested(0);
                document.getElementsByClassName("hopscotch-bubble-number").innerHTML = "";
                // document.getElementById("google-accounts-list").click();
            }
        },
        {
            target: "g-b-icons",
            title: "Google and Microsoft Ads",
            content: "Toggle between your Google Ads and Microsoft Ads account from here. Be sure to check your task recommendations for both accounts to optimize your paid search campaigns to align with each network's best practices.",
            placement: "right",
            arrowOffset: "center",
            onNext: async () => {
                await store.dispatch(updateDrawer(false));
            }
        },
        {
            target: "dashboard-tab",
            title: "Dashboard",
            content: "Get an overview of your account performance and pending tasks.",
            placement: "right",
            arrowOffset: "top",
            delay: 500,
            onNext: async () => {
                if (store.getState().productTour.dashboardTour) {
                    store.dispatch(trackTour(DASHBOARD_NOTIFICATION_ID))
                }
            }
        }, {
            target: "snapshot",
            title: "Campaign Snapshot",
            content: "Get an overview of your campaign performance for the selected date range. Data is compared with previous time period so you can quickly judge your current campaign's success.",
            placement: "right",
            arrowOffset: "top",
            // delay: 500,
            yOffset: -15,
            // xOffset: -100
        }, {
            target: "performance",
            title: "Campaign Performance",
            content: "See what your spend and revenue is up to the current date. Revenue target is calculated based on the profitability target you set up when you first logged in (can be adjusted in account settings).",
            placement: "left",
            arrowOffset: "center",
        },
        {
            target: "user-KPI",
            title: "Your KPIs",
            content: "Understand your Key Performance Indicators based on profitability (revenue/cost or cost/revenue) and cost per lead or order for your selected date range.",
            placement: "top",
            arrowOffset: "center",
        }, {
            target: "pending-tasks",
            title: "Pending Tasks",
            content: "LXRGuide recommends weekly tasks to further optimize your campaigns. Complete these actions to boost your performance.",
            placement: "top",
            arrowOffset: "center",
            onNext: async () => {
                await store.dispatch({
                    type: DASHBOARD_VIEW_VALUE_UPDATED,
                    viewValue: 1
                });
                window.location.hash = "/tasks/"
            }
        },
        {
            target: "task-tab",
            title: "Your Tasks",
            content: window.taskTypeId == 0 ? "Find all the recommended tasks provided by LXRGuide's intelligence system. Please come back later once you have some task generated." : "Find all the recommended tasks provided by LXRGuide's intelligence system. See all your pending and completed tasks.",
            placement: "right",
            arrowOffset: "top",
            onNext: () => {
                if(window.taskTypeId==0){
                    window.taskTypeId=getTaskTypeId();
                }
                if (store.getState().productTour.taskListTour) {
                    store.dispatch(trackTour(TASK_NOTIFICATION_ID));
                }
            },
            onShow: async () => {
                if(window.taskTypeId==0){
                    window.taskTypeId=getTaskTypeId();
                }
                await store.dispatch({
                    type: DASHBOARD_VIEW_VALUE_UPDATED,
                    viewValue: 1
                });
                window.location.hash = "/tasks/"
            }
        },
        /*  {
              target: "no-tasks",
              title: "Your Tasks",
              content: "Find all the recommended tasks provided by LXRGuide's intelligence system. Please come back later once you have some task generated.",
              placement: "right",
              arrowOffset: "top",
              // delay: 500,
              // yOffset: -15,
              // xOffset: -100
          },*/
        {
            target: "tasks-filter",
            title: "All Tasks, Pending Tasks, Completed Tasks",
            content: "Quickly toggle between completed and pending tasks.",
            placement: "right",
            arrowOffset: "top",
        },
        {
            target: "task-0",
            title: "Task Progress",
            content: "Some tasks have multiple components to them. Track your progress for each task here.",
            placement: "left",
            arrowOffset: "top",
            multipage: !0,
            onNext: async function () {
                window.location.hash = "/task-new/" + window.taskTypeId;
            },
            yOffset: -30,
        },
        {
            target: `publish-btn-${window.taskTypeId}`,
            title: "Publish",
            content: "Click 'Publish' to submit the selected task data to the Google/Microsoft Ads interface to go live.",
            delay: 300,
            placement: "left",
            arrowOffset: "top",
            multipage: true,
            onNext: async () => {
                await store.dispatch({
                    type: DASHBOARD_VIEW_VALUE_UPDATED,
                    viewValue: 2
                });
                window.location.hash = "/reports/"
            }
        },
        {
            target: `reports-tab`,
            title: "Reports",
            content: "Generate a high-level overview of your account's health across 6 metrics: performance, geography, device, hourly, quality, and impressions.",
            // delay: 500,
            placement: "left",
            arrowOffset: "top",
            onNext: () => {
                if (store.getState().productTour.reportsTour) {
                    store.dispatch(trackTour(REPORT_NOTIFICATION_ID));
                }
            },
            onShow: async () => {
                /* if (store.getState().productTour.reportsTour) {
                     await store.dispatch(trackTour(REPORT_NOTIFICATION_ID));
                 }*/

                if (window.location.hash != "#/reports/") {
                    await store.dispatch({
                        type: DASHBOARD_VIEW_VALUE_UPDATED,
                        viewValue: 2
                    });
                    window.location.hash = "/reports/"
                }
            }


            // yOffset: -30,
            // xOffset: -100
        },
        {
            target: `download-plane`,
            title: "Share Your Reports",
            content: "Send your report directly to another person in your organization via email.",
            // delay: 500,
            placement: "left",
            arrowOffset: "top",
            yOffset: -25,
            // xOffset: -100
        },
        {
            target: `download-report`,
            title: "Download Your Reports",
            content: "Download your report in an excel spreadsheet and pdf format for further analysis. Visualizations can be download as an image.",
            // delay: 500,
            placement: "left",
            arrowOffset: "top",
            yOffset: -25,
            // xOffset: -100
        },
        {
            target: `activity-reports-tab`,
            title: "Activity Report",
            content: "This tab measures your progress in optimizing your accounts based on your number of completed tasks.",
            // delay: 500,
            placement: "left",
            arrowOffset: "top",
            onNext:()=>{
                if (store.getState().productTour.activityReportTour) {
                    store.dispatch(trackTour(ACTIVITY_REPORT_ID));
                }
            },
            onShow: async () => {
                await store.dispatch({
                    type: DASHBOARD_VIEW_VALUE_UPDATED,
                    viewValue: 3
                });
                window.location.hash = "/activity-report/"
            }
            // yOffset: -25,
            // xOffset: -100
        },
        {
            target: `task-activity`,
            title: "Task Activity",
            content: "Learn how many tasks you completed for your account within the selected date range.",
            // delay: 500,
            placement: "top",
            arrowOffset: "center",
            // yOffset: -25,
            // xOffset: -100
        },
        {
            target: `performance-section`,
            title: "Performance",
            content: "Understand the account's KPI performance over the selected timeframe (a minimum of four weeks). " +
                "Click on any date to see what tasks were completed that particular week.",
            // delay: 500,
            placement: "left",
            arrowOffset: "center",
            // yOffset: -25,
            // xOffset: -100
        },
        {
            target: `task-his`,
            title: "Task History",
            content: "Review historical task data at a later point.",
            placement: "top",
            arrowOffset: "center",
        },
        {
            target: `on-demand-opt`,
            title: "On-Demand Services",
            content: "Occassionally, your accounts may need some extra help. You can choose a one-time asset creation for a set fee or a monthly full agency service for a set fee at a fraction of agency costs, based on your specific needs.",
            placement: "top",
            arrowOffset: "center",
            onShow: async () => {
                await store.dispatch(updateDrawer(true));
                window.handleNested(0)
            }
        },
        {
            target: `account-sett`,
            title: "Account Settings",
            content: "Here you can fine-tune your account requirements and settings related to LXRGuide communications.",
            placement: "top",
            arrowOffset: "center",
            multipage: !0,
            onShow: async () => {
                await store.dispatch(updateDrawer(true));
                window.handleNested(0);
            },
            onNext: () => {
                document.getElementById("menu-sel").click();
            }
        },
        /*{
            target: `account-edit`,
            title: "Edit Your Account",
            content: "Modify you account KPI and monthly spend target. You can also opt in or out of email communications for task reminders and weekly reports. Add more email recipients by separating each email with a comma.",
            placement: "top",
            arrowOffset: "center",
            multipage: !0,
            onShow: () => {
                window.location.hash = "/account-settings/";

            },
            onNext: () => {
                document.getElementById("menu-sel").click();
            }
        },*/
        {
            target: `menu-sel`,
            // delay: 300,
            title: "User Profile",
            content: "Add communication and billing details to update your payment information.",
            placement: "left",
            arrowOffset: "center",
            yOffset: 32,
            xOffset: -145
        },
        {
            target: `client-link`,
            // delay: 300,
            title: "Client Settings",
            content: "Update your profile information in the LXRGuide interface.",
            placement: "left",
            arrowOffset: "top",
            yOffset: -10,
        }, {
            target: `payment-link`,
            // delay: 300,
            title: "Payment History",
            content: "See your archived payment details and upcoming invoices.",
            placement: "left",
            arrowOffset: "top",
            yOffset: -10,
        }, {
            target: `notification-icon`,
            // delay: 300,
            title: "Notifications",
            content: "We'll notify you on important changes to your account, performance achievements, platform updates, and more.",
            placement: "left",
            arrowOffset: "top",
            onShow: () => {
                store.dispatch({type: RESET_MENU})
            },
            yOffset: -10,
        },
        {
            target: `seo-link`,
            // delay: 300,
            title: "SEO Scorecard",
            content: "Get a complementary monthly SEO audit on your account to identify any gaps in your website that could be impacting your rankings. ",
            placement: "right",
            arrowOffset: "top",
            onShow: async () => {
                await store.dispatch(updateDrawer(true));
                window.handleNested(0);
            },
            yOffset: -20,
        },
        /*  {
          target: "tab1",
          title: "Campaigns Snapshot",
          content: "Get a quick snapshot of how your campaigns are performing.",
          placement: "top",
          arrowOffset: "center"
      }, {
          target: "perTab",
          title: "Get a Bird’s-Eye View",
          content: "Always keep an eye on how you’re doing in comparison to your target.",
          placement: "left"
      }, {
          target: "kpiTab",
          title: "Get a Bird’s-Eye View",
          content: "Always keep an eye on how you’re doing in comparison to your target.",
          placement: "right"
      }, {
          target: "pendingTab",
          title: "Optimize your Account",
          content: "Execute your weekly tasks to get the best results out of LXRGuide.",
          placement: "top",
          arrowOffset: "center",
          xOffset: "center",
          multipage: !0,
          onNext: async function () {
              await store.dispatch({
                  type: DASHBOARD_VIEW_VALUE_UPDATED,
                  viewValue: 1
              });
              window.location.hash = "/tasks/"
          }
      }, {
          target: "tasksTab",
          title: "Task Section",
          content: "Just hit POST and the recommendations you selected will automatically be applied to your account!",
          placement: "top",
          onPrev: function () {
              window.location.hash = "/"
          }
      }, {
          target: "filters",
          title: "Filters Section",
          content: "Just hit POST and the recommendations you selected will automatically be applied to your account!",
          placement: "bottom",
          onPrev: function () {
              window.location.hash = "/"
          }
      }, {
          target: "postBtn",
          title: "Post the Task",
          content: "Just hit POST and the recommendations you selected will automatically be applied to your account!",
          placement: "left",
          onPrev: function () {
              window.location.hash = "/"
          }
      }, {
          target: "organizeBy",
          title: "View your tasks",
          content: "Easily view your tasks by type or by which campaign they're related to",
          placement: "left",
          arrowOffset: -.2
      }*/
    ]

})