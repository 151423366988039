export const AccountStatus = {
  ACCOUNT_CREATED: "ACCOUNT_CREATED",
  CAMPAIGN_FRAMEWORK_IN_PROGRESS: "CAMPAIGN_FRAMEWORK_IN_PROGRESS",
  CAMPAIGN_FRAMEWORK_IN_REVIEW: "CAMPAIGN_FRAMEWORK_IN_REVIEW",
  CAMPAIGN_FRAMEWORK_REVIEWER_APPROVED: "CAMPAIGN_FRAMEWORK_REVIEWER_APPROVED",
  CAMPAIGN_STRUCTURE_IN_PROGRESS: "CAMPAIGN_STRUCTURE_IN_PROGRESS",
  CAMPAIGN_STRUCTURE_IN_REVIEW: "CAMPAIGN_STRUCTURE_IN_REVIEW",
  CAMPAIGN_STRUCTURE_REVIEWER_APPROVED: "CAMPAIGN_STRUCTURE_REVIEWER_APPROVED",
  COMPLETED: "COMPLETED",
};

export const AccountStage = {
  CAMPAIGN_FRAMEWORK: "CAMPAIGN_FRAMEWORK",
  CAMPAIGN_STRUCTURE: "CAMPAIGN_STRUCTURE",
};

export const Authority = {
  ADMIN: "ADMIN",
  MANAGER: "MANAGER",
  REVIEWER: "REVIEWER",
  ANALYST: "ANALYST",
  RWF: "RWF",
  LXRGUIDE_SERVICE: "LXRGUIDE_SERVICE",
};

export const KeywordFilterOperators = {
  CONTAINS: "CONTAINS",
  EQUALS: "EQUALS",
  NOT_EQUALS: "NOT_EQUALS",
  STARTS_WITH: "STARTS_WITH",
  ENDS_WITH: "ENDS_WITH",
  GREATER_THAN: "GREATER_THAN",
  GREATER_THAN_EQUAL: "GREATER_THAN_EQUAL",
  LESS_THAN: "LESS_THAN",
  LESS_THAN_EQUAL: "LESS_THAN_EQUAL",
  IN_BETWEEN: "IN_BETWEEN",
};

export const KeywordFields = {};
