import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import {
  CompactCardContent,
  MainSectionCardHeader,
  SubSectionCardHeader,
} from "../cards";
import { Button, Card, CardContent, Divider, Grid, Typography } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import {
  CustomCard,
  getLastDates,
  trackingContent,
  getAccountStatusHead,
  getAccountStatusContent,
} from "./templates";
import ImpressionGraph from "./impressionsGraph";
import * as _ from "lodash";
import TrackingHierarchy from "./trackingHierarchy";
import { GreenButton } from "../buttons";

const CustomCardContent = withStyles((theme) => ({
  root: {
    marginTop: 4,
    backgroundColor: theme.palette.common.white,
  },
}))(CardContent);
const CustomCard1 = withStyles((theme) => ({
  root: {
    boxShadow: "unset",
  },
}))(Card);
const InactiveTypography = withStyles((theme) => ({
  root: {
    color: theme.palette.graderGraphColors.red,
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: 18,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
}))(Typography);
const ActiveTypography = withStyles((theme) => ({
  root: {
    color: theme.palette.graderGraphColors.green,
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: 18,
  },
}))(Typography);
const ContentPTypography = withStyles((theme) => ({
  root: {
    fontSize: 14,
    fontWeight: "300",
    color: theme.palette.graderGraphColors.text,
  },
}))(Typography);

class AccountTracking extends React.PureComponent {
  render() {
    const impressionGraphData = this.props.impressionsGraphData
      ? this.props.impressionsGraphData
      : [];
    const { isInFreeTrial, isTaskGen } = this.props.data;

    return (
      <CustomCard>
        <CompactCardContent>
          {/*<CustomCard1 className={"fullHeight"}>*/}
          <CustomCard1>
            {/*<SubSectionCardHeader title={"KPI Graph"} subheader={`${KpiString[kpiType]} for last ${selectedDateRange} days`}/>*/}
            <SubSectionCardHeader title={"Account Health"} />

            <CustomCardContent className={"compactVertical"}>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  {_.map(impressionGraphData).length == 0 ? (
                    <InactiveTypography>Account is inactive</InactiveTypography>
                  ) : (
                    <Fragment>
                      <ImpressionGraph
                        impressionsGraphData={this.props.impressionsGraphData}
                      />
                      <br />
                      {getAccountStatusHead(_.map(impressionGraphData).length)}
                      {getAccountStatusContent(
                        _.map(impressionGraphData).length
                      )}
                    </Fragment>
                  )}
                </Grid>
                <Grid item md={6}>
                  <TrackingHierarchy data={this.props.data} />
                </Grid>
                <Grid item md={12} sm={12}>
                  <Divider />
                </Grid>
                {!isInFreeTrial && isTaskGen ? (
                  <Grid item md={12} style={{ padding: "18px 0px 15px 0px" }}>
                    <Grid container spacing={2} alignItems={"center"}>
                      <Grid item md={9} sm={12}>
                        <ContentPTypography component={"p"}>
                          Feel free to try the LXRGuide! A simple tool that
                          combines 6.5 million hours of digital marketing
                          experience with machine learning technology, to help
                          you evaluate complicated business opportunities.
                        </ContentPTypography>
                      </Grid>
                      <Grid item md={3} sm={12} xs={12}>
                        <a
                          href={"/trail-user.html"}
                          style={{ textDecoration: "unset" }}
                        >
                          <GreenButton
                            variant="contained"
                            color="primary"
                            className={"fullWidth"}
                          >
                            Try LXRGuide For Free
                          </GreenButton>
                        </a>
                      </Grid>
                    </Grid>
                    <br />
                  </Grid>
                ) : null}
              </Grid>
            </CustomCardContent>
          </CustomCard1>
        </CompactCardContent>
      </CustomCard>
    );
  }
}

AccountTracking.propTypes = {};

export default AccountTracking;
