import React, { Fragment } from "react";
import { JumpstartInputField } from "../input-forms";
import {
  Grid,
  Typography,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import { saveRequestJumpstartData } from "../../actions";
import { connect } from "react-redux";
import { CurvedBlueBtn } from "../buttons";
import icons from "../icons";
import NumberFormat from "react-number-format";

const demoFormStyles = (theme) => ({
  hyperLinks: {
    textDecoration: "unset",
    color: theme.palette.hyperLink,
    fontWeight: "bold",
    fontSize: "0.8rem",
    lineHeight: 0,
    padding: 0,
  },
  customGrid: {
    paddingLeft: theme.spacing(15),
    [theme.breakpoints.down('xl')]: {
      padding: "3rem 0rem 0rem 0rem",
    },
  },
  orangeTxt: {
    color: theme.palette.orangeColor,
  },
  progress: {
    margin: theme.spacing(2),
  },
});

export const FormTitleTypography = withStyles((theme) => ({
  root: {
    fontFamily: "Lato",
    color: theme.palette.text.secondary,
    fontSize: 14,
  },
}))(Typography);
export const CustomCheckbox = withStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
  },
}))(Checkbox);
const PopUpCommonBtn = withStyles((theme) => ({
  root: {
    fontWeight: "400",
    color: theme.palette.text.primary,
  },
}))(Button);

class requestJumpstartForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      emailId: "",
      companyName: "",
      phoneNumber: "",
      website: "",
      country: "",
      advertiserType: "false",
      firstNameError: "",
      emailIdError: "",
      companyNameError: "",
      phoneNumberError: "",
      countryError: "",
      termsAndConditionsError: "",
      saving: false,
      termsAndConditions: false,
      SuccessNotification: "",
      websiteError: "",
    };
  }

  handleCheckBox = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  validate = () => {
    let isError = false;
    const errors = {};
    if (this.state.firstName.length == 0) {
      isError = true;
      errors.firstNameError = "This field is required";
    } else {
      errors.firstNameError = "";
    }
    if (this.state.emailId.length == 0) {
      isError = true;
      errors.emailIdError = "This field is required";
    } else if (this.state.emailId.length > 0) {
      let result = this.state.emailId.match(
        /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
      );
      if (!result) {
        isError = true;
        errors.emailIdError = "Please enter valid email";
      } else {
        errors.emailIdError = "";
      }
    } else {
      errors.emailIdError = "";
    }
    if (this.state.companyName.length == 0) {
      isError = true;
      errors.companyNameError = "This field is required";
    } else {
      errors.companyNameError = "";
    }
    /*if (this.state.country.length == 0) {
            isError = true;
            errors.countryError = 'This field is required';
        } else {
            errors.countryError = "";
        }*/
    if (this.state.phoneNumber.length == 0) {
      isError = true;
      errors.phoneNumberError = "This field is required";
    } else if (this.state.phoneNumber.length < 10) {
      isError = true;
      errors.phoneNumberError = "Phone Number should be minimum 10 digits";
    } else if (this.state.phoneNumber.length > 10) {
      isError = true;
      errors.phoneNumberError = "Phone Number should not exceed 10 digits";
    } else {
      errors.phoneNumberError = "";
    }
    /*if (this.state.termsAndConditions == false) {
            isError = true;
            errors.termsAndConditionsError = 'You must accept our Terms of Service and Privacy Policy';
        } else {
            errors.termsAndConditionsError = "";
        }*/

    const UrlRegex = /^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}$/;
    if (this.state.website.length == 0 || this.state.website.trim() == "") {
      isError = true;
      errors.websiteError = "This field is required";
    } else if (
      this.state.website.length != 0 &&
      !UrlRegex.test(this.state.website)
    ) {
      isError = true;
      errors.websiteError = "URL Format Wrong";
    } else {
      errors.websiteError = "";
    }
    this.setState({
      ...this.state,
      ...errors,
    });
    return isError;
  };

  handleInputs = async (event) => {
    event.preventDefault();
    await this.setState({
      [event.target.name]: event.target.value,
    });
    console.log("Request Jumpstart State Updated=", this.state);
  };

  handleSubmit = async () => {
    let error;
    error = this.validate();
    if (!error) {
      this.setState({ saving: true });
      this.state.response = await this.props.saveInputsToServer({
        firstName: this.state.firstName,
        emailId: this.state.emailId,
        companyName: this.state.companyName,
        phoneNumber: this.state.phoneNumber,
        website: this.state.website,
        /*country: this.state.country,
                    advertiserType: this.state.advertiserType*/
      });
      this.setState({
        saving: false,
        SuccessNotification:
          "LXRGuide team will contact you as soon as possible",
        firstName: "",
        emailId: "",
        companyName: "",
        phoneNumber: "",
        website: "",
        country: "",
        emailIdError: "",
        companyNameError: "",
        phoneNumberError: "",
        // countryError: "",
        // termsAndConditionsError: "",
        // advertiserType: "false",
        websiteError: "",
      });
    }

    /*setTimeout(() => {
            this.clearNotification();
        }, 3000);*/
  };
  clearNotification = () => {
    this.setState({ SuccessNotification: "" });
  };

  render() {
    const { classes } = this.props;
    const { fullScreen } = this.props;
    return (
      <Fragment>
        <Grid container alignItems={"center"} className={classes.customGrid}>
          <Grid item md={4} xs={12} sm={4}>
            <FormTitleTypography align={"left"}>
              Name*&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </FormTitleTypography>
          </Grid>
          <Grid item md={8} xs={12} sm={8}>
            <JumpstartInputField
              fullWidth
              margin="normal"
              name="firstName"
              value={this.state.firstName}
              onChange={this.handleInputs}
              error={this.state.firstNameError !== ""}
              helperText={this.state.firstNameError}
              InputLabelProps={{
                shrink: true,
              }}
              required
            />
          </Grid>
          <Grid item md={4} xs={12} sm={4}>
            <FormTitleTypography align={"left"}>
              Email Address*&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </FormTitleTypography>
          </Grid>
          <Grid item md={8} xs={12} sm={8}>
            <JumpstartInputField
              fullWidth
              margin="normal"
              name="emailId"
              value={this.state.emailId}
              onChange={this.handleInputs}
              error={this.state.emailIdError !== ""}
              helperText={this.state.emailIdError}
              InputLabelProps={{
                shrink: true,
              }}
              required
            />
          </Grid>
          <Grid item md={4} xs={12} sm={4}>
            <FormTitleTypography align={"left"}>
              Company Name*&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </FormTitleTypography>
          </Grid>
          <Grid item md={8} xs={12} sm={8}>
            <JumpstartInputField
              fullWidth
              margin="normal"
              name="companyName"
              value={this.state.companyName}
              onChange={this.handleInputs}
              error={this.state.companyNameError !== ""}
              helperText={this.state.companyNameError}
              InputLabelProps={{
                shrink: true,
              }}
              required
            />
          </Grid>
          <Grid item md={4} xs={12} sm={4}>
            <FormTitleTypography align={"left"}>
              Phone Number*&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </FormTitleTypography>
          </Grid>
          <Grid item md={8} xs={12} sm={8}>
            <NumberFormat
              fullWidth
              margin="normal"
              name="phoneNumber"
              value={this.state.phoneNumber}
              onValueChange={async (values) => {
                const { formattedValue, value } = values;
                await this.setState({ phoneNumber: value });
              }}
              error={this.state.phoneNumberError !== ""}
              helperText={this.state.phoneNumberError}
              InputLabelProps={{
                shrink: true,
              }}
              customInput={JumpstartInputField}
              format="###-###-####"
              mask="_"
            />
          </Grid>
          <Grid item md={4} xs={12} sm={4}>
            <FormTitleTypography align={"left"}>
              Website* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </FormTitleTypography>
          </Grid>
          <Grid item md={8} xs={12} sm={8}>
            <JumpstartInputField
              fullWidth
              margin="normal"
              name="website"
              value={this.state.website}
              onChange={this.handleInputs}
              error={this.state.websiteError !== ""}
              helperText={this.state.websiteError}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item md={12} xs={12} sm={12}>
            {this.state.saving ? (
              <CircularProgress className={classes.progress} />
            ) : (
              <Fragment>
                <CurvedBlueBtn
                  fullWidth
                  size={"large"}
                  variant="contained"
                  className={"fullWidth"}
                  onClick={this.handleSubmit}
                >
                  <Typography className={classes.orangeTxt}>
                    {icons.light}
                  </Typography>
                  &nbsp; Submit
                </CurvedBlueBtn>
              </Fragment>
            )}
          </Grid>
          <Grid>
            <Dialog
              fullScreen={fullScreen}
              open={this.state.SuccessNotification !== ""}
              onClose={this.clearNotification}
              aria-labelledby="responsive-dialog-title"
            >
              <DialogTitle id="responsive-dialog-title">
                {"Thanks for Your Interest"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {this.state.SuccessNotification}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <PopUpCommonBtn
                  onClick={this.clearNotification}
                  color="primary"
                  autoFocus
                >
                  Ok
                </PopUpCommonBtn>
              </DialogActions>
            </Dialog>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

requestJumpstartForm.propTypes = {};

const mapDispatchToProps = (dispatch) => ({
  saveInputsToServer: (jumpstartObj) => {
    return dispatch(saveRequestJumpstartData(jumpstartObj));
  },
});

export default connect(
  null,
  mapDispatchToProps
)(withStyles(demoFormStyles)(requestJumpstartForm));
