import * as types from "./actionTypes";

export function updateJsAccountStatus() {
    return async function (dispatch, getState) {
        console.log("fetching User info data");
        setTimeout(async () => {
            try {
                dispatch({
                    type: types.UPDATE_JS_ACCOUNT_REQUESTED
                });
                let url = "/update-js-account.json";
                let response = await fetch(url, {
                    headers: {
                        'Accept': 'application/json',
                    },
                    'credentials': 'include'
                });
                let data = await response.json();
                console.log("UserInfo Received", data);
                await dispatch({
                    type: types.UPDATE_JS_ACCOUNT_RECEIVED,
                    data
                });
                return data;
                /* if (data.error && data.error == "") {
                     await this.props.fetchPerfData();
                 }*/
            } catch (e) {
                dispatch({type: types.UPDATE_JS_ACCOUNT_FAILED});
            }
        }, 0);
    }
}