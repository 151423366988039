import React, { Fragment } from "react";
import { Grid, Paper, Typography } from "@mui/material";
import { CircularProgress } from "@mui/material/index";
import { withStyles } from "@mui/styles";
import statsStyles from "../../assets/jss/activityReport/statsStyles";
import NumericLabel from "react-pretty-numbers";
import { KpiString, kpiValue } from "../../selectors/dashboard-selectors";

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="xl" />;

const metricsWithCTR = [
  "clicks",
  "impressions",
  "CTR",
  "orders",
  "spend",
  "kpiValue",
];
const metricsWithRevenue = [
  "clicks",
  "impressions",
  "revenue",
  "orders",
  "spend",
  "kpiValue",
];
export const metricTitles = {
  clicks: "Clicks",
  impressions: "Impressions",
  CTR: "CTR",
  orders: "Orders",
  revenue: "Revenue",
  spend: "Spend",
  kpiValue: "Conversion Rate",
};
export const kpiLabel = {
  RC: "Orders",
  CR: "Orders",
  CPO: "Orders",
  CPL: "Leads",
};
export const numberFormat = (value) =>
  new Intl.NumberFormat("en-US", {}).format(value);

// const K_Formatter = (num) => {

//   if ((num / 100) % 10 == 0) return Math.floor(num / 1000) + "K";
//   else return Math.floor(num / 100) / 10.0 + "K";

// };

function Metric(props) {
  const { currencySymbol, metric } = props;
  console.log("props.metric=", props.metric);
  console.log("props.number=", props.number);
  console.log("currencySymbol", props.currencySymbol);
  const K_Formatter = (num,metric) => {
    console.log("metric ---->"+metric)
    if(metric == ("revenue") || metric == ("spend")){
      if ((num / 100) % 10 == 0) return Math.floor(num / 1000) + "K";
      else return (Math.floor(num / 100) / 10.0).toLocaleString("en-US", { style: "currency", currency:  props.currencySymbol }) + "K";
  
    }
    else{
      if ((num / 100) % 10 == 0) return Math.floor(num / 1000) + "Kt";
      else return (Math.floor(num / 100) / 10.0) + "K";
  
    }
  //   if ((num / 100) % 10 == 0) return Math.floor(num / 1000) + "K";
  //   else return (Math.floor(num / 100) / 10.0).toLocaleString("en-US", { style: "currency", currency: currencySymbol }) + "K";
  // 
  };
  const numOptions = {
    commafy: true,
    shortFormatMinValue: 999,
    shortFormatPrecision: 1,
    precision: 0,
    shortFormat: true,
    justification: "L",
    locales: "en-US",
    currency: metric == "spend" || metric == "revenue" ? true : false,
    currencyIndicator: currencySymbol,
  };
  return (
    <Grid container alignItems={"flex-end"} className={"fullHeight"}>
      <Grid item>
        <Typography
          component={"p"}
          variant={props.width == "sm" || props.width == "xs" ? "h2" : "h1"}
          align={"left"}
          gutterBottom
          style={{ fontWeight: "bold" }}
        >
          <b>
            <span
              title={
                !isNaN(props.number)
                  ? props.metric == "spend" ||
                    props.metric == "revenue" ||
                    props.metric == "kpiValue" ||
                    props.metric == "orders"
                    ? numberFormat(props.number.toFixed(2))
                    : numberFormat(props.number)
                  : 0
              }
            >
              {props.number === 0 && props.metric === "revenue" ? (
                <span>
                  {!isNaN(props.number) ? "$" + props.number.toFixed(2) : "0"}
                </span>
              ) : props.number === 0 && props.metric === "spend" ? (
                <span>
                  {!isNaN(props.number) ? "$" + props.number.toFixed(2) : "0"}
                </span>
              ) : props.number === 0 && props.metric === "orders" ? (
                <span>
                  {!isNaN(props.number) ? props.number.toFixed(2) : "0"}
                </span>
              ) : (
                <b>
                  {1000 <= props.number.toFixed(2) &&
                  props.number.toFixed(2) < 999999 ? (
                    <span>{K_Formatter(props.number.toFixed(2),props.metric)}</span>
                  ) : (
                    <NumericLabel params={numOptions}>
                      {!isNaN(props.number) ? props.number.toFixed(2) : "0"}
                    </NumericLabel>
                  )}
                </b>
              )}
            </span>
          </b>
        </Typography>
      </Grid>
    </Grid>
  );
}


class Stats extends React.PureComponent {
  render() {
    const {
      isLoading,
      classes,
      width,
      currencySymbol,
      statsPerformanceMap,
      kpiType,
    } = this.props;
    // const metricTotal = 20;
    const metrics =
      kpiType == "RC" || kpiType == "CR" ? metricsWithRevenue : metricsWithCTR;
    console.log("statsPerformanceMap", statsPerformanceMap);
    // const {Current, Previous} = statsPerformanceMap;
    // console.log("statsPerformanceMap.Current && statsPerformanceMap.Current.IMPRESSIONS",statsPerformanceMap && statsPerformanceMap.Current.IMPRESSIONS)
    const zeroInDouble = 0.0;
    const impressions =
      statsPerformanceMap.Current &&
      statsPerformanceMap.Current[0] &&
      statsPerformanceMap.Current[0].IMPRESSIONS
        ? statsPerformanceMap.Current[0].IMPRESSIONS
        : 0;
    const clicks =
      statsPerformanceMap.Current &&
      statsPerformanceMap.Current[0] &&
      statsPerformanceMap.Current[0].CLICKS
        ? statsPerformanceMap.Current[0].CLICKS
        : 0;
    const orders =
      statsPerformanceMap.Current &&
      statsPerformanceMap.Current[0] &&
      statsPerformanceMap.Current[0].CONVERSIONS
        ? statsPerformanceMap.Current[0].CONVERSIONS
        : 0;
    const spend =
      statsPerformanceMap.Current &&
      statsPerformanceMap.Current[0] &&
      statsPerformanceMap.Current[0].COST
        ? statsPerformanceMap.Current[0].COST
        : 0;
    const revenue =
      statsPerformanceMap.Current &&
      statsPerformanceMap.Current[0] &&
      statsPerformanceMap.Current[0].CONVERSIONVALUE
        ? statsPerformanceMap.Current[0].CONVERSIONVALUE
        : 0;
    console.log("spend", spend);
    console.log("revenue", revenue);
    console.log("orders", orders);
    const kpiValue1 = kpiValue[kpiType](spend, revenue, orders);
    const metricValues = {
      impressions: impressions,
      clicks: clicks,
      orders: orders,
      spend: spend,
      revenue: revenue,
      CTR: Number(impressions) == 0 ? 0 : (clicks / impressions) * 100,
      kpiValue: kpiValue1,
    };
    console.log("metricValues=", metricValues);

    return (
      <Grid container spacing={2}>
        <Fragment>
          {metrics.map((metric, i) => (
            <Grid item sm={4} xs={4} md={2}>
              <Paper className={classes.paper}>
                <Typography align={"left"} variant={"body2"}>
                  <b>
                    {metric == "kpiValue"
                      ? KpiString[kpiType]
                      : metric !== "orders"
                      ? metricTitles[metric]
                      : kpiLabel[kpiType]}
                  </b>
                </Typography>
                {isLoading ? (
                  <div className={classes.loadingDiv}>
                    <CircularProgress color="secondary" />
                  </div>
                ) : (
                  <Metric
                    width={width}
                    number={metricValues[metric]}
                    metric={metric}
                    // value={metricValues[metric]}
                    currencySymbol={currencySymbol}
                  />
                )}
              </Paper>
            </Grid>
          ))}
        </Fragment>
      </Grid>
    );
  }
}

Stats.propTypes = {};

export default withWidth()(withStyles(statsStyles)(Stats));
