import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Grid, Typography, Button } from "@mui/material";
import "./jumpstart.css";
import icons from "../../components/icons";
import { Link } from "react-router-dom";

function PasswordChanged(props) {

  return (
    <>
      <Grid
        item
        container
        xs={12}
        direction="row"
        rowSpacing={2}
        style={{ textAlign: "center" }}
      >
        <Grid item xs={12}>
          <Typography fontFamily="500">Password Changed</Typography>
        </Grid>
        <Grid item xs={12}>
          {icons.PasswordChanged}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2" color="#747474">
            Your password has been changed successfully.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Link to="/jumpstart-login">
            <Button style={{ textTransform: "none", color: "#F58120" }}>
              Go Back to Sign In
            </Button>
          </Link>
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordChanged);
