import { AccountStatus } from "./jumpstartEnum";

export function FormatAccountStatus(status) {
  if (status == AccountStatus.ACCOUNT_CREATED) {
    return "Account Created";
  } else if (
    status == AccountStatus.CAMPAIGN_FRAMEWORK_IN_PROGRESS ||
    status == AccountStatus.CAMPAIGN_STRUCTURE_IN_PROGRESS
  ) {
    return "In Progress";
  } else if (
    status == AccountStatus.CAMPAIGN_FRAMEWORK_IN_REVIEW ||
    status == AccountStatus.CAMPAIGN_STRUCTURE_IN_REVIEW
  ) {
    return "In Review";
  } else if (
    status == AccountStatus.CAMPAIGN_FRAMEWORK_REVIEWER_APPROVED ||
    status == AccountStatus.CAMPAIGN_STRUCTURE_REVIEWER_APPROVED
  ) {
    return "Reviewer Approved";
  } else if( status == AccountStatus.COMPLETED){
    return "Completed"
  }
}
