import React, { Component } from "react";
import { CardContent, Grid, LinearProgress, Typography } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import { CustomCard } from "../grader/templates";
import { CompactCardContent } from "../cards";
import { connect } from "react-redux";
import icons from "../icons";
import { calculateIntegrationStatus } from "../siteGrader/templates";
import {
  getsSiteGraderResult,
  siteGraderServerError,
} from "../../actions/index";

class Integration extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      counter: 0,
      status: 0,
    };
  }

  componentDidMount() {
    const { urlInfo } = this.props.siteGrader;
    if (urlInfo != null) {
      this.threadStatus = setInterval(this.progress, 12000);
    }
    const useDummyData = window.lxrOptions && window.lxrOptions.useDummyData;
    if (useDummyData) {
      this.props.getSiteGraderResult();
    }
  }

  progress = async () => {
    const { siteGrader, type } = this.props;
    const { siteGraderScore, urlInfo } = this.props.siteGrader;
    let { domainId, url } = urlInfo;

    const { counter } = this.state;
    let response = null;
    if (siteGraderScore === 0) {
      response = await this.props.getSiteGraderResult(domainId, url, type);
      // await this.setState((prevState) => ({counter: prevState.counter + 1, status: prevState.status + 20}));
      this.setState({ status: calculateIntegrationStatus(siteGrader) });
      if (
        response != null &&
        response.serverError != null &&
        response.serverError == "" &&
        response.webStats &&
        response.webStats.siteGraderScore > 0
      ) {
        await clearInterval(this.threadStatus);
      } else if (
        response != null &&
        response.serverError != null &&
        response.serverError != ""
      ) {
        await clearInterval(this.threadStatus);
        await this.props.setError();
      }
    }
  };

  render() {
    const CompactCardContent = withStyles({
      root: {
        paddingTop: 0,
        marginTop: 20,
        boxShadow: "unset",
      },
    })(CardContent);
    const CustomLinearProgress = withStyles((theme) => ({
      root: {
        height: 15,
      },
      barColorPrimary: {
        backgroundColor: theme.palette.graderGraphColors.green,
      },
      determinate: {
        backgroundColor: "#E6E6E6",
      },
    }))(LinearProgress);
    const CustomTypography = withStyles((theme) => ({
      root: {
        color: theme.palette.graderGraphColors.text,
      },
    }))(Typography);
    const IntegrationStatusTypography = withStyles((theme) => ({
      root: {
        color: theme.palette.graderGraphColors.green,
      },
    }))(Typography);
    return (
      <CustomCard>
        <CompactCardContent>
          <Grid
            container
            style={{ minHeight: "85vh" }}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Grid item md={10}>
              <CustomTypography align={"center"}>
                {icons.loading_6_Resizable(100, 100)}
              </CustomTypography>
              <br />
              <CustomLinearProgress
                variant="determinate"
                value={this.state.status == 0 ? 2 : this.state.status}
              />
              <IntegrationStatusTypography
                align={"center"}
                variant={"h6"}
                component={"h6"}
              >
                {/*<b>{this.state.status ? this.state.status : 0}%</b>*/}
                <b>{this.state.status == 0 ? 2 : this.state.status}%</b>
              </IntegrationStatusTypography>
            </Grid>
          </Grid>
        </CompactCardContent>
      </CustomCard>
    );
  }
}

Integration.propTypes = {};
const mapStateToProps = (state) => ({
  siteGrader: state.siteGrader,
});
const mapDispatchToProps = (dispatch) => ({
  getSiteGraderResult: (domainId, domainUrl, type) =>
    dispatch(getsSiteGraderResult(domainId, domainUrl, type)),
  setError: () => dispatch(siteGraderServerError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Integration);
