import React from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import { Button } from "@mui/material/index";
import withStyles from "@mui/styles/withStyles";
import helpPageStyles from "../../assets/jss/help/helpPageStyles";
import icons from "../../components/icons";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {
  SubHeadingTypo,
  TaglineForHeadline,
} from "../../components/typography";
import { Link } from "react-router-dom";
import MetaTags from "react-meta-tags";

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="xl" />;

export function IconAndName({ name, icon, width }) {
  return (
    <Grid container spacing={2} alignItems={"center"}>
      <Grid item>{icon}</Grid>
      <Grid item>
        <Typography
          variant={width == "sm" || width == "xs" ? "body1" : "h4"}
          color="textPrimary"
          style={{ maxWidth: "auto" }}
        >
          <b>{name}</b>
        </Typography>
      </Grid>
    </Grid>
  );
}

export const cardsDetails = [
  {
    icon: "userCEO",
    title: "SMALL BUSINESS OWNERS",
    para: "Owners, CEOs and managing directors love how the LXRGuide Growth Model delivers a marketing strategy for business growth with transparency and accountability.",
  },
  {
    icon: "userSpeaker",
    title: "MARKETING MANAGERS",
    para: "Marketing teams are delighted by the LXRGuide Growth Model as it delivers results that can be traced back to their marketing tactics, automates tough processes, and drives traffic directly to their converting content offers.",
  },
  {
    icon: "userDoller",
    title: "AGENCY MANAGER",
    para: "Sales directors and managers enjoy how the LXRGuide Growth Model helps to focus their team’s time into impactful engagements with the right people, at the right time.",
  },
];

export const content = [
  "Open the property selector dropdown menu in any Search Console page.",
  "Select “+ Add property” in the dropdown menu.",
  "Choose the type of property to add: URL-prefix property, domain property, or Google-hosted property.",
  "Choose one of the verification methods provided and follow the prompted steps. You can verify immediately or pause and resume the process later.",
  "Data should begin appearing in your property within a few days.",
];
export const CustomListItem = withStyles((theme) => ({
  root: {
    paddingTop: 1,
    paddingBottom: 1,
    alignItems: "baseline",
    paddingLeft: 0,
  },
}))(ListItem);
export const CustomListItemText = withStyles((theme) => ({
  primary: {
    color: theme.palette.text.primary,
    fontSize: 24,
    [theme.breakpoints.down("xl")]: {
      fontSize: 12,
    },
  },
}))(ListItemText);
export const CatalinaHeadBlue = withStyles((theme) => ({
  root: {
    fontSize: 24,
    color: theme.palette.text.primary,
    fontFamily: "Lato",
    [theme.breakpoints.down("xl")]: {
      fontSize: 12,
    },
  },
}))(Typography);
export const CatalinaHeadOrangeBlue = withStyles((theme) => ({
  root: {
    fontSize: 24,
    color: theme.palette.secondary.main,
    fontFamily: "Lato",
    [theme.breakpoints.down("xl")]: {
      fontSize: 12,
    },
  },
}))(Typography);

function HelpPage(props) {
  const { width, classes } = props;
  return (
    <Card className={classes.card}>
      <MetaTags>
        <title>Who We Help | Small & Medium Business Owners | LXRGuide</title>
        <meta
          name="description"
          content="LXRGuide powered by NetElixir helps small business owners, marketing managers, agency managers, and more looking to improve their marketing and ecommerce sales."
        />
      </MetaTags>
      <CardContent className={classes.cardContent} style={{ paddingBottom: 0 }}>
        <Grid container>
          <Grid item sm={12} xs={12} md={12}>
            <Grid container>
              <Grid
                sm={12}
                xs={12}
                md={12}
                className={[classes.sectionBg, classes.gridBg]}
              >
                <Typography align={"center"} gutterBottom>
                  {icons.helpBg}
                </Typography>
                <Typography
                  align={"center"}
                  color={"textPrimary"}
                  variant={width == "sm" || width == "xs" ? "h3" : "subtitle1"}
                  style={{ fontWeight: "700" }}
                  gutterBottom
                >
                  <b>WHO WE HELP</b>
                </Typography>
                <TaglineForHeadline align={"center"}>
                  small & medium business seeking great results
                </TaglineForHeadline>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12} className={classes.section2}>
            <Grid container justifyContent={"center"}>
              <Grid item md={7}>
                <Typography
                  align={"center"}
                  color={"textPrimary"}
                  variant={width == "sm" || width == "xs" ? "h3" : "subtitle1"}
                  style={{ fontWeight: "700" }}
                  gutterBottom
                >
                  We Create Sales Pipelines Using Digital and Inbound Marketing
                </Typography>
                <TaglineForHeadline align={"center"}>
                  Here at LXRGUIDE, we know there are two types of businesses
                  that we deliver amazing results for.
                </TaglineForHeadline>
              </Grid>
              <Grid item md={12}>
                <br />
                <br />
                <Grid container justifyContent={"center"} spacing={2}>
                  <Grid item>
                    <IconAndName
                      name={"SMALL & MEDIUM BUSINESS"}
                      icon={icons.orangeChecked}
                      width={width}
                    />
                  </Grid>
                  <Grid item>
                    <IconAndName
                      name={"DIGITAL MARKETING AGENCIES"}
                      icon={icons.orangeChecked}
                      width={width}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12} className={classes.section3}>
            <Grid container justifyContent={"space-around"} spacing={2}>
              {cardsDetails.map((data, index) => (
                <Grid item md={4} key={`${data.title}${index}`}>
                  <Card className={classes.secCard}>
                    <CardContent>
                      <Typography align={"center"}>
                        {icons[data.icon]}
                      </Typography>
                      <br />
                      <CatalinaHeadBlue>
                        <b>{data.title}</b>
                      </CatalinaHeadBlue>
                      <CatalinaHeadBlue variant={"body1"} gutterBottom>
                        {data.para}
                      </CatalinaHeadBlue>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item sm={12} xs={12} md={12} className={classes.section4}>
            <Grid container justifyContent={"center"}>
              <Grid sm={12} xs={12} md={8} item>
                <Typography
                  align={"center"}
                  color={"textPrimary"}
                  variant={width == "sm" || width == "xs" ? "h3" : "subtitle1"}
                  style={{ fontWeight: "700" }}
                  gutterBottom
                >
                  SMALL & MEDIUM BUSINESS SEEKING GREAT RESULTS
                </Typography>
                <br />
                <CatalinaHeadBlue
                  align={"left"}
                  variant={"body1"}
                  color={"textPrimary"}
                  gutterBottom
                >
                  The E-commercce industry is a fast-moving and highly
                  competitive market place. Sed ut perspiciatis unde omnis iste
                  natus error sit voluptatem accusantium doloremque laudantium,
                  totam rem aperiam, eaque ipsa quae ab illo inventore veritatis
                  et quasi architecto beatae vitae dicta sunt explicabo.
                </CatalinaHeadBlue>
                <br />
                <CatalinaHeadBlue
                  align={"left"}
                  variant={"body1"}
                  color={"textPrimary"}
                  gutterBottom
                >
                  Perfect for CEOs, senior sales professionals, and marketing
                  leaders.
                </CatalinaHeadBlue>
                <br />
                <CatalinaHeadBlue
                  align={"left"}
                  variant={"body1"}
                  color={"textPrimary"}
                  gutterBottom
                >
                  Companies that follow the GamePlan process experience real
                  results. From growing website traffic to increasing monthly
                  retained revenue opportunities.
                </CatalinaHeadBlue>
                <br />
                <CatalinaHeadOrangeBlue
                  align={"left"}
                  color={"secondary"}
                  style={{ fontWeight: "700" }}
                  gutterBottom
                >
                  Is your E-commerce business seeking:
                </CatalinaHeadOrangeBlue>
                <br />
                {content.map((value, index) => {
                  return (
                    <CustomListItem>
                      <ListItemIcon style={{ margin: 0 }}>
                        {icons.dot}
                      </ListItemIcon>
                      <CustomListItemText primary={value} />
                    </CustomListItem>
                  );
                })}
                <br />
                <CatalinaHeadOrangeBlue
                  align={"left"}
                  color={"secondary"}
                  style={{ fontWeight: "700" }}
                  gutterBottom
                >
                  Do you:
                </CatalinaHeadOrangeBlue>
                <br />
                {content.map((value, index) => {
                  return (
                    <CustomListItem>
                      <ListItemIcon style={{ margin: 0 }}>
                        {icons.dot}
                      </ListItemIcon>
                      <CustomListItemText primary={value} />
                    </CustomListItem>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            md={12}
            xs={12}
            sm={12}
            className={[classes.confusedbar, "flex"]}
          >
            <Grid
              container
              spacing={width == "sm" || width == "xs" ? null : 8}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Grid item>
                <Typography
                  variant={"body1"}
                  className={classes.confusionTitle}
                >
                  Begin Planning Your Growth With Our Digital Marketing Expert.
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  varient="primary"
                  component={Link}
                  to={"/request-demo"}
                  className={classes.buttonstyle}
                >
                  Book your free growth call
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default withWidth()(withStyles(helpPageStyles)(HelpPage));
