import React, { Fragment } from "react";
import { Grid, Typography, Button, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import icons from "../components/icons";
// import { unstable_Box as Box } from '@mui/material/Box';

const footerStyles = (theme) => ({
  footerBg: {
    backgroundColor: theme.palette.footerBg,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(4),
    },
  },
  socialIcons: {
    display: "flex",
    justifyContent: "flex-end",
    paddingLeft: theme.spacing(1),
  },
  padding: {
    padding: theme.spacing(2),
  },
  footerlinks: {
    cursor: "pointer",
    textDecoration: "unset",
  },
  socialSection: {
    paddingLeft: 3,
    paddingRight: 5,
    paddingTop: 0,
    textAlign: "right",
  },
  socIcons: {
    paddingBottom: 5,
  },
  socDes: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  socP: {
    paddingRight: 5,
  },
});
const CustomListItemText = withStyles((theme) => ({
  primary: {
    color: theme.palette.common.white,
    fontSize: "0.8rem",
  },
}))(ListItemText);
const LinksBtns = withStyles((theme) => ({
  root: {
    padding: 0,
    color: theme.palette.footerTxt,
    fontSize: "0.8rem",
  },
}))(Button);
const FooterTxt = withStyles((theme) => ({
  root: {
    fontSize: "0.8rem",
    color: theme.palette.common.white,
  },
}))(Typography);
const SocialTxt = withStyles((theme) => ({
  root: {
    fontSize: 22,
    color: theme.palette.common.white,
  },
}))(Typography);

const OrangeTypography = withStyles((theme) => ({
  root: {
    color: theme.palette.orangeColor,
  },
}))(Typography);
const FooterBg = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.footerBg2,
    padding: theme.spacing(2),
  },
}))(Typography);
const CustomListItem = withStyles((theme) => ({
  root: {
    padding: 0,
  },
}))(ListItem);

let id = 1;

function createFooterData(icon, content, type, hyperLink) {
  id += 1;
  return { id, icon, content, type, hyperLink };
}

export const footerData = [
  createFooterData("envelope", "support@lxrguide.com", "email", ""),
  createFooterData("phone", "609 356 5112", "number", ""),
  createFooterData(
    "location",
    "3 Independence Way, Suite #203 Princeton,NJ 08540 USA",
    "address",
    ""
  ),
];
export const footerList1 = [
  createFooterData(null, "Features", "link", "/features.html"),
  createFooterData(null, "Pricing", "link", "/pricing.html"),
  createFooterData(null, "Blog", "link", "/blog/"),
];
export const footerList2 = [
  createFooterData(null, "Terms of Service", "link", "/terms-of-service.html"),
  createFooterData(null, "News", "link", "/press-release.html"),
  createFooterData(null, "Privacy Policy", "link", "/privacy-policy.html"),
];

export function FooterList1(props) {
  const { dataObj } = props;
  return (
    <Fragment>
      {dataObj && dataObj.type != "link" ? (
        <List>
          <CustomListItem>
            <ListItemIcon>
              <OrangeTypography>{icons[dataObj.icon]}</OrangeTypography>
            </ListItemIcon>
            <CustomListItemText primary={dataObj.content} />
          </CustomListItem>
        </List>
      ) : null}
    </Fragment>
  );
}

export function FooterList2(props) {
  const { dataObj, classes } = props;
  console.log("dataObj=", dataObj);
  return (
    <Fragment>
      {dataObj && dataObj.type == "link" ? (
        <List>
          <CustomListItem>
            <a
              href={dataObj.hyperLink}
              target={"_blank"}
              className={classes.footerlinks}
            >
              <CustomListItemText primary={dataObj.content} />
            </a>
          </CustomListItem>
        </List>
      ) : null}
    </Fragment>
  );
}

function footer(props) {
  const { classes } = props;
  return (
    <Fragment>
      <Grid
        container
        className={classes.footerBg}
        justifyContent={"flex-start"}
        spacing={2}
      >
        <Grid item md={3}>
          {icons.WhiteLogo1}
          <br />
        </Grid>
        <Grid
          item
          md={9}
          className={classes.socDes}
          style={{ paddingBottom: 20 }}
        >
          <div>
            <OrangeTypography>Stay Connected. Find us on.</OrangeTypography>
          </div>
          <div className={"flex"}>
            <a
              style={{ padding: "0px 10px 0px 10px" }}
              href={"https://www.facebook.com/LXRGuide-1736697119883388/"}
              target={"_blank"}
              className={classes.footerlinks}
            >
              <SocialTxt> {icons.facebook}</SocialTxt>
            </a>

            <a
              style={{ padding: "0px 10px 0px 10px" }}
              href={"https://twitter.com/LXRGuide"}
              target={"_blank"}
              className={classes.footerlinks}
            >
              <SocialTxt>{icons.twitter1}</SocialTxt>
            </a>

            <a
              style={{ padding: "0px 10px 0px 10px" }}
              href={"https://www.linkedin.com/company/11116448/"}
              target={"_blank"}
              className={classes.footerlinks}
            >
              <SocialTxt>{icons.linkedIn}</SocialTxt>
            </a>
          </div>
        </Grid>
        <Grid item md={4} sm={12} xs={12}>
          {footerData.map((value, i) => {
            return <FooterList1 key={i} dataObj={value} classes={classes} />;
          })}
        </Grid>
        <Grid item md={2} sm={12} xs={12}>
          {footerList1.map((value, i) => {
            return <FooterList2 key={i} dataObj={value} classes={classes} />;
          })}
        </Grid>
        <Grid item md={2} sm={12} xs={12}>
          {footerList2.map((value, i) => {
            return <FooterList2 key={i} dataObj={value} classes={classes} />;
          })}
        </Grid>
        <Grid
          item
          md={2}
          className={classes.socialSection}
          style={{ paddingTop: 0, paddingRight: 3 }}
        >
          <a
            href={"https://www.google.com/partners/agency?id=1430663498"}
            target={"_blank"}
            className={classes.footerlinks}
          >
            <div className={classes.socIcons}>{icons.googleSig}</div>
          </a>
          <a
            href={
              "https://learninglab.about.ads.microsoft.com/certification/membership-directory/"
            }
            target={"_blank"}
            className={classes.footerlinks}
          >
            <div className={classes.socIcons}>{icons.bingSig}</div>
          </a>
          {/* <div className={classes.socIcons}>
                        {icons.fbSig}
                    </div>*/}
          <div className={classes.socIcons}>{icons.iSOSig}</div>
        </Grid>
        <Grid item style={{ padding: 0 }}>
          <a
            href={"https://www.netelixir.com/ups/"}
            target={"_blank"}
            className={classes.footerlinks}
          >
            <div>{icons.upsSig}</div>
          </a>
        </Grid>
      </Grid>
      <FooterBg className={"fullWidth"}>
        <FooterTxt align={"center"}>
          © {new Date().getFullYear()} NetElixir Inc. All Rights Reserved.
        </FooterTxt>
      </FooterBg>
    </Fragment>
  );
}

export default withStyles(footerStyles)(footer);
