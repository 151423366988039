import React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import { Grid, Typography, Box, Button, Paper } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import icons from "../../components/icons";

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="xl" />;

const CustomLinearProgress = withStyles((theme) => ({
  bar1Determinate: {
    backgroundColor: "#6AB04C",
  },
}))(LinearProgress);

const styles = (theme) => ({
  bar: {
    marginTop: "5px",
    height: "15px",
    borderRadius: "5px",
  },
  containers: {
    marginTop: "10px",
  },
  compareContainer: {
    marginTop: "10px",
    borderBottom: "solid 1px #E3E6F4",
  },
  mainDiv: {
    marginRight: "10px",
    marginTop: "44px",
  },

  state: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: "10px",
    },
    [theme.breakpoints.down("xl")]: {
      paddingLeft: "10px",
    },
    color: "white",
  },
  totalSessions: {
    [theme.breakpoints.up("md")]: {
      textAlign: "center",
      color: "white",
    },
    [theme.breakpoints.down("xl")]: {
      paddingLeft: "10px",
      color: "white",
    },
  },
  session: {
    [theme.breakpoints.up("md")]: {
      textAlign: "right",
      paddingRight: "10px",
    },
    [theme.breakpoints.down("xl")]: {
      textAlign: "right",
      paddingRight: "10px",
    },
    color: "white",
  },
  headContainer: {
    backgroundColor: "#1E334E",
    border: "solid 2px #1E334E",
    borderRadius: "3px",
    [theme.breakpoints.up("md")]: {},
    [theme.breakpoints.down("xl")]: {},
  },
});

function ProgressBar(props) {
  const classes = props.classes;
  const { progressBarData, compareSwitch } = props;
  console.log("The progressBar data is", progressBarData);
  return (
    <div className={classes.mainDiv}>
      {compareSwitch ? (
        <div>
          <Grid container className={classes.headContainer}>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography className={classes.state}>Region</Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography className={classes.totalSessions}>
                Total Sessions
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography className={classes.session}>Change (%)</Typography>
            </Grid>
          </Grid>
          {progressBarData &&
            progressBarData.map((items, key) => {
              return (
                <Grid container className={classes.compareContainer}>
                  <Grid item xs={3} sm={3} md={3} lg={3}>
                    <Typography style={{ paddingLeft: "10px" }}>
                      {items.region}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} sm={3} md={3} lg={3}>
                    <Typography
                      style={{
                        textAlign: "center",
                        color: "#958B8B",
                      }}
                    >
                      {items.prevPeriodSessions.toLocaleString("en-US")}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} sm={3} md={3} lg={3}>
                    <Typography
                      style={{
                        borderLeft: "1px solid #E3E6F4",
                        textAlign: "center",

                        color: "#1E334E",
                      }}
                    >
                      {items.sessions.toLocaleString("en-US")}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} sm={3} md={3} lg={3}>
                    {items.changePercentage >= 0 &&
                    !isNaN(items.changePercentage) ? (
                      <Typography
                        style={{
                          textAlign: "center",
                          paddingLeft: "23px",
                          color: "#27AE60",
                        }}
                      >
                        {parseInt(items.changePercentage)
                          .toLocaleString("en-US")
                          .replace("-", "")}
                        % &nbsp; {icons.upwardArrow}
                      </Typography>
                    ) : isNaN(items.changePercentage) ? (
                      <Typography
                        style={{
                          textAlign: "center",
                          paddingLeft: "23px",
                          color: "#1E334E",
                        }}
                      >
                        {items.changePercentage
                          .toLocaleString()
                          .replace("-", "")}
                      </Typography>
                    ) : (
                      <Typography
                        style={{
                          textAlign: "center",
                          paddingLeft: "23px",
                          color: "#EB5757",
                        }}
                      >
                        {parseInt(items.changePercentage)
                          .toLocaleString()
                          .replace("-", "")}
                        % &nbsp; {icons.downwardArrow}
                      </Typography>
                    )}
                  </Grid>
                  <br />
                  <br />
                </Grid>
              );
            })}
        </div>
      ) : (
        <div>
          <Grid container className={classes.headContainer}>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Typography className={classes.state}>Region</Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Typography className={classes.session}>Sessions (%)</Typography>
            </Grid>
          </Grid>
          {progressBarData &&
            progressBarData.map((items, key) => {
              return (
                <Grid container className={classes.containers}>
                  <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <Typography style={{ paddingLeft: "12px" }}>
                      {items.region}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <CustomLinearProgress
                      className={classes.bar}
                      variant="determinate"
                      value={items.sessionsPercentage.toLocaleString("en-US")}
                    />
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <Typography
                      style={{
                        textAlign: "center",
                        paddingLeft: "20px",
                      }}
                    >
                      {items.sessionsPercentage.toLocaleString("en-US")}%
                    </Typography>
                  </Grid>
                  <br />
                  <br />
                </Grid>
              );
            })}
        </div>
      )}
    </div>
  );
}

export default withWidth()(
  withStyles(styles, { withTheme: true })(ProgressBar)
);
