import withStyles from '@mui/styles/withStyles';
import {Typography, DialogActions, Button, Select, Dialog, DialogTitle} from '@mui/material';
import icons from "./icons";
import {LightCaption} from "./typography";


export const CustomTypographyText = withStyles(theme => ({
    root: {
        fontWeight: "normal",
        color: theme.palette.dialogText,
        fontSize: "1.1rem",
    }

}))(Typography);

export const SubHeading =withStyles(theme=>({
    subheading:{
        fontWeight:"lighter",
        color: theme.palette.dialogText,
    }
}))(Typography);
SubHeading.defaultProps = {
    variant: "subheading"
};

export const CustomDialogBox = withStyles(theme => ({
    paperWidthSm: {
        maxWidth: 400,
    }

}))(Dialog);

export const CustomDialogActions = withStyles(theme => ({
    root: {
        justifyContent: "center",
    }
}))(DialogActions);

export const CustomDialogTitle = withStyles(theme => ({
    root: {
        "& > span": {
            color: theme.palette.text.secondary,
            fontSize:"1rem",
            display:"flex",
            alignItems:"center"
        }
    }

}))(DialogTitle);

export const BlueBtn = withStyles(theme => ({
    root: {
        backgroundColor: theme.palette.text.primary,
        color: theme.palette.common.white,
        textTransform: "uppercase",
        fontWeight: "bold",
        boxShadow:"0px 6px 12px -3px rgba(189,189,189,1)",
        margin:10,
        "&:hover": {
            backgroundColor: theme.palette.text.primary,
        }
    }
}))(Button);
export const GreyBtn = withStyles(theme => ({
    root: {
        backgroundColor: theme.palette.grey[400],
        color: theme.palette.common.white,
        textTransform: "uppercase",
        fontWeight: "bold",
        boxShadow:"0px 6px 12px -3px rgba(224,224,224,1)",
        margin:10,
        "&:hover": {
            backgroundColor: theme.palette.grey[400],
        }
    }
}))(Button);

export const GreenCheck = withStyles(theme => ({
    root: {
        color: theme.palette.success
    }
}))(icons.CheckCircle);
export const IconTypography = withStyles(theme => ({
    root: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color:theme.palette.text.primary,
    }
}))(Typography);
