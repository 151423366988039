import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { CustomCard } from "../components/cards";
import RequestExpertHelpForm from "../components/requestExpertHelp/request-expert-help-form";
import {
  HeadLineTypography,
  ParagraphTypography,
} from "../components/typography";
import Footer from "../components/footer";
import {
  REQUEST_EXPERT_HELP_TRACK,
  REQUEST_JUMPSTART_TRACK,
} from "../actions/actionTypes";
import { connect } from "react-redux";
import { getsSiteGraderInternalResult } from "../actions";

const requestDemoStyles = (theme) => ({
  formsSectionBg: {
    backgroundColor: theme.palette.formFieldsBg,
    minHeight: "70vh",
  },
  customGridForms: {
    /* paddingLeft: "4.5rem",
         paddingRight: "4.5rem",
         paddingBottom: "2rem",
         [theme.breakpoints.down('md')]: {
             padding: "1rem"
         }*/
  },
  padding: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  lowPadding: {
    padding: theme.spacing(1),
  },
  paddingTopBottom: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  paddingBottom: {
    paddingBottom: theme.spacing(4),
  },
  typoSubHead: {
    color: theme.palette.dialogText,
    fontSize: "0.975rem",
  },
  orangeText: {
    color: theme.palette.orangeColor,
  },
  inline: {
    display: "flex",
    justifyContent: "center",
  },
  containerPadding: {
    padding: theme.spacing(4),
  },
  ParagraphTypography: {
    color: theme.palette.dialogText,
    fontSize: 16,
    fontWeight: "600",
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down("xl")]: {
      fontSize: 12,
      padding: theme.spacing(2),
    },
  },
  card: {
    width: "100%",
  },
});
const content = [
  "Setting up a Google Ads account can be an arduous task. Our Google Ads certified paid search experts can help you setup an account structure that will achieve the maximum results.",
  "We start building a strategy, discuss the business goals you want to achieve by advertising on Google, give recommendations on budget and identify keywords that maximize budget and results. Our paid search experts take care of all the intricate details, including target demographic, device preferences, geography,and language.",
  "We keep you informed and ask for your feedback throughout the process. Please fill out your contact information and we will be in touch as soon as possible.",
];

function MultiColorHeadLine(props) {
  const { blueTxt, orangeTxt, classes } = props;

  return (
    <Typography className={classes.inline}>
      <HeadLineTypography align={"center"}>
        {blueTxt}&nbsp;<span className={classes.orangeText}>{orangeTxt} </span>
      </HeadLineTypography>
    </Typography>
  );
}

function requestJumpstart(props) {
  const { classes } = props;
  const showHeader = window.lxrOptions && window.lxrOptions.showHeader;

  const [channelType, setChannelType] = useState(() => {
    const channelTypeForExternal = window.location.search
      ? new URLSearchParams(window.location.search).get("channel")
      : "0";
    return window.location.pathname === "/expert-help.html"
      ? channelTypeForExternal
      : props.match.params.channel;
  });

  useEffect(() => {
    props.requestExpertHelpPage(channelType);
  }, [channelType, props]);

  return (
    <CustomCard className={classes.card}>
      <Grid container justifyContent={"center"}>
        <Grid item md={12} component={"div"} className={classes.formsSectionBg}>
          <Grid
            container
            direction="row"
            justifyContent={"space-around"}
            alignItems={"flex-start"}
            className={classes.customGridForms}
          >
            <Grid item md={12} className={classes.padding}>
              <MultiColorHeadLine
                blueTxt={"REQUEST EXPERT"}
                orangeTxt={"HELP"}
                classes={classes}
              />
            </Grid>
            <Grid item md={6} xs={11} sm={11}>
              <RequestExpertHelpForm channel={channelType} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CustomCard>
  );
}
const mapDispatchToProps = (dispatch) => ({
  requestExpertHelpPage: (channelType) =>
    dispatch({ type: REQUEST_EXPERT_HELP_TRACK, channelType }),
});
export default connect(
  null,
  mapDispatchToProps
)(withStyles(requestDemoStyles)(requestJumpstart));
