import Datamap from "datamaps/dist/datamaps.usa.min";
import React, { useEffect } from "react";
import d3 from "d3";
import { DefaultRegionData } from "./Map";
import objectAssign from "object-assign";
import * as _ from "lodash";

export default class DataMap extends React.Component {
  constructor(props) {
    super(props);
    this.datamap = null;
  }

  renderMap() {
    const handleCallBack = this.props.handleCallBack;
    const kpiType = this.props.kpiType;
    let regionData = this.props.regionData;
    const currency = this.props.currency;
    const width = this.props.width;
    console.log("The data in props of worldMap is", regionData);
    return new Datamap({
      element: document.getElementById("datamap-container"),
      position: "relative",
      scope: "usa",
      projection: "mercator",
      data: regionData,
      fills: {
        USA: "#2ca02c",
        defaultFill: "#1E334E",
      },
      done: function (datamap) {
        datamap.svg
          .selectAll(".datamaps-subunit")
          .on("click", function (geography) {});
      },
      geographyConfig: {
        popupOnHover: true,
        highlightOnHover: true,
        highlightBorderColor: "#949EAB",
        borderWidth: 0.5,
        highlightFillColor: "#FF8735",
        popupTemplate: function (geography, data) {
         
          return (
            '<div class="hoverinfo"><strong> Region: ' +
            geography.properties.name +
            "</strong><br><strong>Sessions: " +
            data.sessions.toLocaleString("en-US") +
            "</strong></div>"
          );
        },
      },
    });
  }

  currentScreenWidth() {
    return (
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    );
  }

  componentDidMount() {
    d3.select(".datamap").remove();
    const mapContainer = d3.select("#datamap-container");
    const initialScreenWidth = this.currentScreenWidth();
    const containerWidth =
      initialScreenWidth < 500
        ? {
            width: initialScreenWidth + "px",
            height: initialScreenWidth * 0.5625 + "px",
          }
        : { width: "500px", height: "300px", margin: "0 auto" };
    mapContainer.style(containerWidth);
    let regionData1 = this.props;
    console.log("The regionData1 is", regionData1);
    this.datamap = this.renderMap();
  }

  render() {
    return <div id="datamap-container" />;
  }
}
