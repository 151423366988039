import React, { Fragment } from "react";
import Insights from "../grader/insights";
import icons from "../icons";
import { Paper } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import Typography from "@mui/material/Typography";
import { GreenText } from "../typography";
import CardContent from "@mui/material/CardContent";

const CustomPaper = withStyles((theme) => ({
  root: {
    [theme.breakpoints.down('xl')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      boxShadow: "unset",
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
      boxShadow: "unset",
    },
  },
}))(Paper);
const styles = (theme) => ({
  icon_1: {
    width: "40%",
    height: 220,
    [theme.breakpoints.down('xl')]: {
      width: "65%",
      paddingTop: theme.spacing(3),
    },
  },
  icon_2: {
    width: "40%",
    height: 220,
    [theme.breakpoints.down('xl')]: {
      width: "65%",
    },
  },
  icon_3: {
    width: "40%",
    height: 200,
    [theme.breakpoints.down('xl')]: {
      width: "65%",
      paddingTop: theme.spacing(3),
    },
  },
  icon_4: {
    width: "40%",
    height: 230,
    [theme.breakpoints.down('xl')]: {
      width: "70%",
    },
  },
  icon_5: {
    width: "37%",
    height: 200,
    [theme.breakpoints.down('xl')]: {
      width: "60%",
      paddingTop: theme.spacing(3),
    },
  },
  icon_6: {
    width: "50%",
    height: 200,
    [theme.breakpoints.down('xl')]: {
      width: "75%",
    },
  },
});

function SixMetrics(props) {
  const { classes } = props;
  return (
    <CustomPaper>
      {/* <Hidden lgDown>
                <Paper className={classes.paper}>mdDown</Paper>
            </Hidden>*/}
      {/*<Hidden mdUp>*/}
      <Insights
        icon={
          <img
            src={icons.onPageScore_Resizable}
            className={classes.icon_1}
          ></img>
        }
        title={"On Page Score"}
        para={
          "On page score is an important metric that tells you about the overall " +
          "optimization of your entire website. It consists of an aggregate of the scores of " +
          "all the individual URLs that make up your site. While scoring the website as a whole," +
          " our grader also notes parts of your site that need attention and could hinder ranking" +
          " and a higher grade."
        }
        /*subtitle={"Holiday Season Tip:"}
                subPara={
                    <span>
                        With fewer holidays, many marketing experts
                    feel that there will be more opportunities to generate sales
                        in 2019. A higher Quality Score can help your ads achieve
                        a higher position in the search results keeping you visible
                        during the holiday rush. <b>Make sure your quality score falls between
                        7 and 10 this season.</b> Concentrate on clickthrough rate, ad relevance
                        and landing page performance to keep the Quality Score high.
                    </span>
                }*/
        subtitle2={"How we grade your website:"}
        subPara2={
          "We will give your site an on page score from 0 to 10 based on the average" +
          " performance of the individual pages measured for a set of metrics."
        }
      />
      <Insights
        leftIcon
        icon={
          <img src={icons.keywordScore_Resizable} className={classes.icon_2} />
        }
        title={"Keyword Score"}
        para={
          "Keyword score covers the overall ranking of your site based on keywords." +
          " It tells you how good your site keywords are and if there are any individual" +
          " keywords that need attention or an update."
        }
        /* subtitle={"Holiday Season Tip:"}
                 subPara={
                     <span>
                         It is predicted that decline in sales will be very gradual this time and
                         marketers will do well to ride the sales rush till it lasts. There will be
                         a jump in search queries which will persist throughout the holiday season.
                         This calls for a slightly bigger PPC budget than usual to <b>make sure you don’t
                         lose impressions due to budget problems during this season.</b> Make the most of
                         the impressions you garner, while also ensuring that your ad rank is consistently
                         on the higher side in search results.
                     </span>
                 }*/
        subtitle2={"How we grade your website:"}
        subPara2={
          "We will give your website a keyword score from 0 to 10 based on an average" +
          " of the individual keyword rankings for the website."
        }
      />
      <Insights
        icon={
          <img
            src={icons.mobileCompatibility_Resizable}
            className={classes.icon_3}
          />
        }
        title={"Mobile Compatibility Score"}
        para={
          "Mobile compatibility score indicates how well your web design responds on" +
          " various devices, especially on customers’ mobile devices." +
          " We consider how your site adjusts to screen sizes and displays the same" +
          " content in different formats to suit the device in use.\n"
        }
        /*subtitle={"Holiday Season Tip:"}
                subPara={
                    <span>
                       Starting thanksgiving and throughout December there is a good chance
                        that your landing page will have a stream of visitors. Search is
                        expected to peak around this time and what is more stay that way
                        throughout. So, if you <b>improve your page speed, strengthen your call
                        to action on the page</b>, and tweak your keywords to match your pages,
                        you can’t go wrong. Your landing page status and performance will improve.
                        Page Status maybe average, below average or above average depending on user
                        behavior on site.  Higher the status, greater the chances of conversions.
                    </span>
                }*/
        subtitle2={"How we grade your website:"}
        subPara2={
          "We will give your website a score from 0 to 10 based on the average speed at which" +
          " individual pages optimize for mobile and how accessible your mobile optimizations" +
          " are to search engine crawlers."
        }
      />
      <Insights
        leftIcon
        icon={
          <img
            src={icons.pageLoadSpeedScore_Resizable}
            className={classes.icon_4}
          />
        }
        title={"Page Load Speed Score"}
        para={
          "Page load speed is the time it takes for all the content on your page to load," +
          " aggregating all the individual page load times from your site across all devices."
        }
        /*subtitle={"Holiday Season Tip:"}
                subPara={<span>Thanksgiving marks the start of a wonderful buying spree.
                    Is your account ready to avoid wasteful traffic? While it is important
                    to avoid wrong exposure for your ad, it doesn’t make sense Use negative
                    keyword analysis report to find and <b>eliminate searches that are using up
                        your budget without giving you the necessary conversions.</b></span>}*/
        subtitle2={"How we grade your website:"}
        subPara2={
          "We will give your website a score from 0 to 10 based on the average speed" +
          " of the individual pages of your site calculated across all devices."
        }
      />
      <Insights
        icon={
          <img
            src={icons.linkValidityScore_Resizable}
            className={classes.icon_5}
          />
        }
        title={"Link Validity Score"}
        para={
          "Link validity score evaluates the quality, pattern, and distribution of the" +
          " inbound and outbound links to your website and the internal" +
          " links across your site."
        }
        /*subtitle={"Holiday Season Tip:"}
                subPara={<span>If you suspect wasted spend, use our Obligation
                    free Google Ad health check ASAP to determine whether this
                    is true. <b>It is important to correct this quickly and divert
                        the spend where it will yield results.</b> If you have trouble,
                    book a free demo to find out how LXRGuide can help you out this season.
                    Alternately, you may also choose to explore the tool yourself.</span>}*/
        subtitle2={"How we grade your website:"}
        subPara2={
          "We will give your website a score from 0 to 10 based on the average quality," +
          " pattern, and distribution of links across individual pages and their distance" +
          " from the home page."
        }
      />

      <Insights
        leftIcon
        icon={
          <img
            src={icons.technicalVisibility_Resizable}
            className={classes.icon_6}
          />
        }
        title={"Technical Viability Score"}
        para={
          "Technical viability is about technical errors onsite and offsite," +
          " as well as crawl errors found on your individual web pages." +
          " These errors can make your website inaccessible to bots or visitors." +
          " Reliable accessibility, especially for bots crawling your site, is crucial to SEO."
        }
        /* subtitle={"Holiday Season Tip:"}
                 subPara={
                     <span>
                 Account Score gives the contours of your account structure and indicates soundness. It is
                         probably the best indicator of how well you will do this season. Doing well when the
                         market booms depends on your preparedness. <b>Make sure you check how well you score on this
                         index and rope in LXRGuide to help you along,</b> if you are not sure.
                 </span>
                 }*/
        subtitle2={"How we grade your website:"}
        subPara2={
          "We will give your website a score from 0 to 10 based on the seriousness" +
          " and number of errors encountered on average per page across all devices."
        }
      />
    </CustomPaper>
  );
}

export default withStyles(styles)(SixMetrics);
