import React, { PureComponent, Fragment } from "react";
import { Paper } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import CommonStyles from "../../assets/jss/reports/commonStyles";
import * as _ from "lodash";
import WorldMap from "./worldMap";
import DataMap from "./dataMap";
import d3 from "d3";

import { Grid, Typography } from "@mui/material/index";
import icons from "../../components/icons";
import { useEffect } from "react";
import MapChart from "./MapChart";

export const DefaultRegionData = [
  { regionName: "Alabama", code: "AL", value: 89 },
  { regionName: "Alaska", code: "AK", value: 112 },
  { regionName: "Arizona", code: "AZ", value: 101 },
  { regionName: "Arkansas", code: "AR", value: 123 },
  { regionName: "California", code: "CA", value: 145 },
  { regionName: "Colorado", code: "CO", value: 98 },
  { regionName: "Connecticut", code: "CT", value: 101 },
  { regionName: "Delaware", code: "DE", value: 109 },
  { regionName: "District of Columbia", code: "DC", value: 115 },
  { regionName: "Florida", code: "FL", value: 122 },
  { regionName: "Georgia", code: "GA", value: 91 },
  { regionName: "Hawaii", code: "HI", value: 131 },
  { regionName: "Idaho", code: "ID", value: 110 },
  { regionName: "Illinois", code: "IL", value: 134 },
  { regionName: "Indiana", code: "IN", value: 94 },
  { regionName: "Iowa", code: "IA", value: 106 },
  { regionName: "Kansa", code: "KS", value: 116 },
  { regionName: "Kentucky", code: "KY", value: 122 },
  { regionName: "Lousiana", code: "LA", value: 99 },
  { regionName: "Maine", code: "ME", value: 100 },
  { regionName: "Maryland", code: "MD", value: 101 },
  { regionName: "Massachusetts", code: "MA", value: 102 },
  { regionName: "Michigan", code: "MI", value: 104 },
  { regionName: "Minnesota", code: "MN", value: 112 },
  { regionName: "Mississippi", code: "MS", value: 105 },
  { regionName: "Missouri", code: "MO", value: 116 },
  { regionName: "Montana", code: "MT", value: 107 },
  { regionName: "Nebraska", code: "NE", value: 97 },
  { regionName: "Nevada", code: "NV", value: 108 },
  { regionName: "New Hampshire", code: "NH", value: 118 },
  { regionName: "New Jersey", code: "NJ", value: 98 },
  { regionName: "New Mexico", code: "NM", value: 109 },
  { regionName: "New York", code: "NY", value: 119 },
  { regionName: "North Carolina", code: "NC", value: 99 },
  { regionName: "North Dakota", code: "ND", value: 100 },
  { regionName: "Ohio", code: "OH", value: 126 },
  { regionName: "Oklahoma", code: "OK", value: 125 },
  { regionName: "Oregon", code: "OR", value: 124 },
  { regionName: "Pennsylvania", code: "PA", value: 122 },
  { regionName: "Rhode Island", code: "RI", value: 122 },
  { regionName: "South Carolina", code: "SC", value: 141 },
  { regionName: "South Dakota", code: "SD", value: 131 },
  { regionName: "Tennessee", code: "TN", value: 132 },
  { regionName: "Texas", code: "TX", value: 133 },
  { regionName: "Utah", code: "UT", value: 134 },
  { regionName: "Vermont", code: "VT", value: 121 },
  { regionName: "Virginia", code: "VA", value: 122 },
  { regionName: "Washington", code: "WA", value: 91 },
  { regionName: "West Virginia", code: "WV", value: 92 },
  { regionName: "Wisconsin", code: "WI", value: 93 },
  { regionName: "Wyoming", code: "WY", value: 94 },
];
const dummydata = {
  "North Carolina": {
    state: "North Carolina",
    sessions: 125,
    sessionPercentage: 3,
    fillColor: "#a0abad",
  },
  Indiana: {
    state: "Indiana",
    sessions: 105,
    sessionPercentage: 3,
    fillColor: "#a0abad",
  },
  Wyoming: {
    state: "Wyoming",
    sessions: 12,
    sessionPercentage: 1,
    fillColor: "#a0abad",
  },
  Utah: {
    state: "Utah",
    sessions: 28,
    sessionPercentage: 1,
    fillColor: "#a0abad",
  },
  Arizona: {
    state: "Arizona",
    sessions: 85,
    sessionPercentage: 2,
    fillColor: "#a0abad",
  },
  Montana: {
    state: "Montana",
    sessions: 18,
    sessionPercentage: 1,
    fillColor: "#a0abad",
  },
  Kentucky: {
    state: "Kentucky",
    sessions: 49,
    sessionPercentage: 1,
    fillColor: "#a0abad",
  },
  Kansas: {
    state: "Kansas",
    sessions: 31,
    sessionPercentage: 1,
    fillColor: "#a0abad",
  },
  California: {
    state: "California",
    sessions: 496,
    sessionPercentage: 10,
    fillColor: "#a0abad",
  },
  Delaware: {
    state: "Delaware",
    sessions: 13,
    sessionPercentage: 1,
    fillColor: "#a0abad",
  },
  Florida: {
    state: "Florida",
    sessions: 341,
    sessionPercentage: 7,
    fillColor: "#a0abad",
  },
  Pennsylvania: {
    state: "Pennsylvania",
    sessions: 161,
    sessionPercentage: 4,
    fillColor: "#a0abad",
  },
  Mississippi: {
    state: "Mississippi",
    sessions: 25,
    sessionPercentage: 1,
    fillColor: "#a0abad",
  },
  Iowa: {
    state: "Iowa",
    sessions: 55,
    sessionPercentage: 2,
    fillColor: "#a0abad",
  },
  Illinois: {
    state: "Illinois",
    sessions: 193,
    sessionPercentage: 4,
    fillColor: "#a0abad",
  },
  Texas: {
    state: "Texas",
    sessions: 479,
    sessionPercentage: 10,
    fillColor: "#a0abad",
  },
  Connecticut: {
    state: "Connecticut",
    sessions: 53,
    sessionPercentage: 2,
    fillColor: "#a0abad",
  },
  Georgia: {
    state: "Georgia",
    sessions: 188,
    sessionPercentage: 4,
    fillColor: "#a0abad",
  },
  Maryland: {
    state: "Maryland",
    sessions: 103,
    sessionPercentage: 3,
    fillColor: "#a0abad",
  },
  Virginia: {
    state: "Virginia",
    sessions: 161,
    sessionPercentage: 4,
    fillColor: "#a0abad",
  },
  Idaho: {
    state: "Idaho",
    sessions: 26,
    sessionPercentage: 1,
    fillColor: "#a0abad",
  },
  Vermont: {
    state: "Vermont",
    sessions: 12,
    sessionPercentage: 1,
    fillColor: "#a0abad",
  },
  Oregon: {
    state: "Oregon",
    sessions: 53,
    sessionPercentage: 2,
    fillColor: "#a0abad",
  },
  Maine: {
    state: "Maine",
    sessions: 22,
    sessionPercentage: 1,
    fillColor: "#a0abad",
  },
  Oklahoma: {
    state: "Oklahoma",
    sessions: 43,
    sessionPercentage: 1,
    fillColor: "#a0abad",
  },
  Tennessee: {
    state: "Tennessee",
    sessions: 89,
    sessionPercentage: 2,
    fillColor: "#a0abad",
  },
  Alabama: {
    state: "Alabama",
    sessions: 45,
    sessionPercentage: 1,
    fillColor: "#a0abad",
  },
  Arkansas: {
    state: "Arkansas",
    sessions: 28,
    sessionPercentage: 1,
    fillColor: "#a0abad",
  },
  "South Carolina": {
    state: "South Carolina",
    sessions: 76,
    sessionPercentage: 2,
    fillColor: "#a0abad",
  },
  Washington: {
    state: "Washington",
    sessions: 259,
    sessionPercentage: 6,
    fillColor: "#a0abad",
  },
  Nebraska: {
    state: "Nebraska",
    sessions: 25,
    sessionPercentage: 1,
    fillColor: "#a0abad",
  },
  "West Virginia": {
    state: "West Virginia",
    sessions: 43,
    sessionPercentage: 1,
    fillColor: "#a0abad",
  },
  Colorado: {
    state: "Colorado",
    sessions: 85,
    sessionPercentage: 2,
    fillColor: "#a0abad",
  },
  Massachusetts: {
    state: "Massachusetts",
    sessions: 104,
    sessionPercentage: 3,
    fillColor: "#a0abad",
  },
  Missouri: {
    state: "Missouri",
    sessions: 60,
    sessionPercentage: 2,
    fillColor: "#a0abad",
  },
  // "Alaska": {
  //     "state": "Alaska",
  //     "sessions": 6,
  //     "sessionPercentage": 1,
  //     "fillColor": "#a0abad"
  // },
  "North Dakota": {
    state: "North Dakota",
    sessions: 18,
    sessionPercentage: 1,
    fillColor: "#a0abad",
  },
  Wisconsin: {
    state: "Wisconsin",
    sessions: 112,
    sessionPercentage: 3,
    fillColor: "#a0abad",
  },
  Nevada: {
    state: "Nevada",
    sessions: 31,
    sessionPercentage: 1,
    fillColor: "#a0abad",
  },
  "District of Columbia": {
    state: "District of Columbia",
    sessions: 17,
    sessionPercentage: 1,
    fillColor: "#a0abad",
  },
  "Rhode Island": {
    state: "Rhode Island",
    sessions: 19,
    sessionPercentage: 1,
    fillColor: "#a0abad",
  },
  "New York": {
    state: "New York",
    sessions: 312,
    sessionPercentage: 7,
    fillColor: "#a0abad",
  },
  "South Dakota": {
    state: "South Dakota",
    sessions: 6,
    sessionPercentage: 1,
    fillColor: "#a0abad",
  },
  Hawaii: {
    state: "Hawaii",
    sessions: 16,
    sessionPercentage: 1,
    fillColor: "#a0abad",
  },
  Minnesota: {
    state: "Minnesota",
    sessions: 79,
    sessionPercentage: 2,
    fillColor: "#a0abad",
  },
  "New Jersey": {
    state: "New Jersey",
    sessions: 72,
    sessionPercentage: 2,
    fillColor: "#a0abad",
  },
  Michigan: {
    state: "Michigan",
    sessions: 120,
    sessionPercentage: 3,
    fillColor: "#a0abad",
  },
  "New Mexico": {
    state: "New Mexico",
    sessions: 24,
    sessionPercentage: 1,
    fillColor: "#a0abad",
  },
  "New Hampshire": {
    state: "New Hampshire",
    sessions: 9,
    sessionPercentage: 1,
    fillColor: "#a0abad",
  },
  Louisiana: {
    state: "Louisiana",
    sessions: 51,
    sessionPercentage: 2,
    fillColor: "#a0abad",
  },
  Ohio: {
    state: "Ohio",
    sessions: 161,
    sessionPercentage: 4,
    fillColor: "#a0abad",
  },
};
const codeMap = _.invert({
  AL: "Alabama",
  AK: "Alaska",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  DC: "District of Columbia",
  FL: "Florida",
  GA: "Georgia",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  PR: "Puerto Rico",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
});
const array_usa_states = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "District of Columbia",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Puerto Rico",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

const styles = (theme) => ({
  hrStyle: {
    [theme.breakpoints.up("md")]: {
      display: "block",
      width: "1px", 
      height:"115%",
      
    },
    [theme.breakpoints.down('xl')]: {
      display: "none",
    },
  },
});

function Map(props) {
  const { width, classes, data, selectedRegion, handleCallBack } = props;
  console.log("The map data is=====>", data);
  //Here we are getting APIdata and selectedRgion
  var temp3;
  var temp4;

  console.log("The mapData and selectedRegions is", data, selectedRegion);
  let finaldata = [];
  if (data) {
    finaldata = Object.values(data);
  }

  const linearPalleteScale = (value) => {
    var dataValues = Object.values(data).map(function (data) {
      return data.sessions;
    });
    var minVal = Math.min(...dataValues);
    var maxVal = Math.max(...dataValues);
    /!* main dark code is used here*!/;
    // return d3.scale.linear().domain([minVal, maxVal]).range(["#EFEFFF", "#02386F"])(value);
    return d3.scale
      .linear()
      .domain([minVal, maxVal])
      .range(["#C0C6CD", "#1E334E"])(value);
  };



  if (data) {
    console.log("map data in mapjxs:", finaldata);

    temp3 = _.map(finaldata, function (t) {
      return {
        state: t.state,
        sessions: t.sessions,
        code: codeMap[t.state],
        fillColor: t.fillColor,
      };
    });
    array_usa_states.map((o) => {
      //below condition is for checking if the received data contains every state of usa
      if (!data[o]) {
        temp3.push({ state: o, code: codeMap[o] });
        finaldata.push({ state: o ,sessions:0});
      }
    });
    console.log("finaldata----->",finaldata)

    temp4 = temp3.reduce((obj, data) => {
      obj[data.code] = {
        state: data.state,
        sessions: data.sessions,
        fillColor: linearPalleteScale(data.sessions?data.sessions:0),
      };
      return obj;
    }, {});
  }

  console.log("temp3 --->", temp3);
  console.log("temp4-->", temp4);
  // const worldMap = () => {
  //   <WorldMap regionData={temp4} selectedRegion={selectedRegion} />;
  // };
  // useEffect(() => {
  //   if (data) {
  //     worldMap(<WorldMap regionData={temp4} selectedRegion={selectedRegion} />);
  //   }
  // }, [data]);
  return (
    <div>
      <Grid container style={{ justifyContent: "center" }}>
        <Fragment>
          {_.isEmpty(data) ? (
            <Grid item>
              <Typography
                align={"center"}
                color={"textPrimary"}
                variant={"body1"}
              >
                {/* No data to display */}
              </Typography>
            </Grid>
          ) : (
            <Fragment>
              <Grid item>
                <Fragment>
                  {!_.isEmpty(data) && selectedRegion == "usa" ? (
                    // here we r sending the data
                    // <WorldMap
                    //   handleCallBack={handleCallBack}
                    //   regionData={temp4}
                    //   selectedRegion={selectedRegion}
                    // />
                    <MapChart
                      style={{ position: "relative" }}
                      regionData={temp4}
                    ></MapChart>
                  ) : // worldMap()
                  null}
                </Fragment>
              </Grid>
              <div>
                <hr className={classes.hrStyle} />
              </div>
            </Fragment>
          )}
        </Fragment>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(Map);
