import { CardContent, CardHeader, Card } from "@mui/material";


import withStyles from '@mui/styles/withStyles';


export const MainSectionCardHeader = withStyles(theme =>({
    title: {
        fontSize: "24px",
        fontWeight: "900",
    },
    subheader: {
        fontSize: "16px",
        color:theme.palette.text.primary,
    },
    action: {
        marginRight: "unset"
    }
}))(CardHeader);

export const SubSectionCardHeader = withStyles(theme =>({
    root:{
        border:"1px solid rgba(30, 51, 78, 0.5)",
        borderRadius:"3px"
    },
    title: {
        fontSize: "16px",
        fontWeight: "bold"
    },
    subheader: {
        fontSize: "12px",
        fontWeight: "300",
        color:theme.palette.text.primary
    },
    action:{
        marginTop:0,
        alignSelf:"auto",
        color:theme.palette.info
    }
}))(CardHeader);

export const CompactCardContent = withStyles({
    root: {
        paddingTop: 0,
        marginTop:20,
    },
})(CardContent);
export const CustomCard = withStyles(theme => ({
    root:{
        boxShadow:"none",
    }
}))(Card);

