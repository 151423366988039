import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import Warning from "@mui/icons-material/Warning";
import icons from "../components/icons";

class NotFound extends React.PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { classes } = this.props;
    return (
      <Card className={classes.card}>
        <CardContent>
          <Grid
            container
            className={classes.container}
            spacing={2}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Grid item>
              {/*<Warning className={classes.warningIconStyle}/>*/}
              {icons.error}
            </Grid>
            <Grid item>
              <Typography align={"center"} color={"textPrimary"}>
                <b>404 Error</b>
              </Typography>
              <Typography align={"center"} color={"textPrimary"}>
                <b>Not Found</b>
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

NotFound.propTypes = {};

export default withStyles((theme) => ({
  card: {
    boxShadow: "unset",
    marginTop: 10,
  },
  container: {
    minHeight: 300,
  },
  warningIconStyle: {
    color: theme.palette.error.light,
  },
}))(NotFound);
