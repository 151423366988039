import React,{useEffect} from "react";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  Chip,
  Checkbox,
  Button,
} from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import PropTypes from "prop-types";
import polygon from "../../assets/img/no-aspect-ratio.svg";
import icons from "../icons";
import { Link } from "react-router-dom";
import Savings from "../task/savings.jsx";
import ArrowForward from "@mui/icons-material/ArrowForward.js";
import { connect } from "react-redux";
import { idToTaskDataList } from "../../reducers/common";
import { executeTasks, taskSeenDate } from "../../actions";
import { TASK_PAGE_TRACK } from "../../actions/actionTypes";

export function PlainLink({ classes, ...rest }) {
  return <Link {...rest} />;
}

PlainLink = withStyles({
  a: {
    width: "100%",
    color: "unset",
    textDecoration: "none",
    cursor: "pointer",
  },
})(PlainLink);
const GoChip = withStyles((theme) => ({
  root: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.text.primary,
    cursor: "pointer",
  },
}))(Chip);

const CircleCheckGreen = withStyles((theme) => ({
  root: {
    color: theme.palette.common.white,
  },
}))(icons.Done);

function Task(props) {
  const { classes } = props;
 useEffect(() => {
  window.scrollTo(0, 0);
}, []);
  // const {taskId, taskTypeId, taskName, taskDescription, costForecast, revenueForecast, orderForecast,KPIType} = props;
  const { task, actionStatus, KPIType, currency, taskAnalysis } = props;
  const {
    groupId: taskTypeId,
    title: taskName,
    description: taskDescription,
    impact,
  } = task.summary;
  return (
    <PlainLink
      to={
        taskAnalysis.isAdmin
          ? `/admin-task/${taskTypeId}/${taskAnalysis.selectedAccountId}/${taskAnalysis.selectedPpcId}`
          : `/task/${taskTypeId}`
      }
    >
      <Card
        className={[
          classes.card,
          taskAnalysis.isAdmin
            ? pushStatus(props.task.summary.displayStatus, classes)
            : "",
        ].join(" ")}
      >
        <CardHeader
          title={taskName}
          classes={{
            root: classes.cardHeader,
            title: classes.cardHeaderTitle,
            content: classes.cardHeaderContent,
          }}
        />
        <CardContent className={classes.cardContent}>
          <Grid container justifyContent={"center"}>
            <Grid item>
              <Typography
                className={classes.description}
                variant={"caption"}
                align={"center"}
                color={"inherit"}
              >
                {taskDescription}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                className={classes.impact}
                component={"div"}
                align={"center"}
              >
                <Savings {...impact} KPIType={KPIType} currency={currency} />
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className={classes.cardFooter}>
          <Grid container justifyContent={"center"}>
            <Grid item>
              {/*{actionStatus==2 ?  <CircleCheckGreen/>:<GoChip  label={<ArrowForward/>}></GoChip>}*/}
              {actionStatus == 2 ? (
                <Button
                  variant="fab"
                  mini
                  aria-label="completed"
                  className={classes.completedButton}
                >
                  <CircleCheckGreen />
                </Button>
              ) : (
                <Button
                  aria-label="task"
                  size="small"
                  className={classes.taskButton}
                >
                  <ArrowForward />
                </Button>
              )}
              {/*{actionStatus==2 ?  <CircleCheckGreen/>:<Typography>{icons.CircleForwordIcon}</Typography>}*/}
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </PlainLink>
  );
}

const mapDispatchToProps = (dispatch) => ({
  taskSeenDate: (taskId) => dispatch(taskSeenDate(taskId)),
});

Task = connect(
  (state, ownProps) => ({
    actionStatus: ownProps.task.dataIdList.filter(
      (id) => state.taskDataById[id].status === 0
    ).length
      ? 0
      : 2,
    taskAnalysis: state.taskAnalysis,
  }),
  mapDispatchToProps
)(Task);

Task.propTypes = {
  classes: PropTypes.object.isRequired,
};
const pushStatus = (displayStatus, classes) => {
  console.log("pushStatus=", displayStatus);
  if (displayStatus != undefined && displayStatus == 2) {
    return classes.borderGreen;
  } else {
    return classes.borderOrange;
  }
};
// noinspection JSUnusedLocalSymbols
let taskStyles = (theme) => ({
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    // "&:hover $cardHeader": {background:"#333C4E"},
    transition:
      "box-shadow .3s ease-out, transform .3s ease-out, opacity .2s ease-out",
    transitionDelay: ".1s",
    "&:hover": {
      "& $cardHeader": {
        background: "rgba(30, 51, 78, 0.7)",
        backgroundImage: "url(" + polygon + ")",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "40% 40%",
      },
      "& $cardHeaderTitle": {
        color: theme.palette.common.white,
        fontWeight: "900",
      },
      // boxShadow:" 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
      boxShadow:
        "rgba(45,45,45,0.05) 0px 2px 2px, rgba(49,49,49,0.05) 0px 4px 4px, rgba(42,42,42,0.05) 0px 8px 8px, rgba(32,32,32,0.05) 0px 16px 16px, rgba(49,49,49,0.05) 0px 32px 32px, rgba(35,35,35,0.05) 0px 64px 64px",
      // transition:"all .20s ease-in-out",
      // transform: "scale(1.1)",
      transform: "translate(0, -4px)",
      // webkitBackfaceVisibility:"hidden",
      // webkitTransitionProperty:"scale(1.1)",
    },
  },
  cardHeader: {
    background: "rgba(30, 51, 78, 0.1)",
    backgroundImage: "url(" + polygon + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "40% 40%",
  },
  cardHeaderTitle: {
    fontSize: "16px",
    fontWeight: "bold",
    color: theme.palette.text.primary,
  },
  cardHeaderContent: {
    minHeight: 42,
  },
  cardContent: {
    fontSize: "14px",
    fontWeight: 300,
    flexGrow: 1,
    color: theme.palette.text.primary,
  },
  impact: {
    paddingTop: 10,
  },
  taskButton: {
    backgroundColor: theme.palette.taskBtn,
    color: theme.palette.common.white,
    borderRadius: 24,
    "&:hover": {
      backgroundColor: theme.palette.taskBtn,
      color: theme.palette.common.white,
    },
  },
  completedButton: {
    backgroundColor: theme.palette.success,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.success,
      color: theme.palette.common.white,
    },
  },
  description: {
    fontSize: 16,
    fontWeight: "normal",
  },
  borderOrange: {
    borderTop: "5px solid #F58120",
  },
  borderGreen: {
    borderTop: "5px solid #6FCF97",
  },
});
export default withStyles(taskStyles)(Task);
