import { Grid, Typography } from "@mui/material";
import React from "react";
import classNames from "classnames";
import icons from "../icons";

let id = 0;

function socialData(icon, title, valueName) {
  id += 1;
  return { id, icon, title, valueName };
}

export const socialContentList = [
  socialData("facebook", "Likes", "likes"),
  socialData("linkIcon", "Internal Links", "internalLinks"),
  socialData("settingIcon", "Low Code Text Ratio", "lowCodeTextRatio"),
  socialData("leafIcon", "Titles Not Optimised", "titlesNotOptimised"),
];

function keywordData(title, color) {
  id += 1;
  return { id, title, color };
}

export const topKeywordList = [
  keywordData("keyword1", "orange"),
  keywordData("keyword2", "orange"),
  keywordData("keyword3", "orange"),
  keywordData("keyword4", "orange"),
  keywordData("keyword5", "orange"),
];

let packagesDataId = 0;

function packagesData(packageName, description, price, recommendation) {
  packagesDataId += 1;
  return { packagesDataId, packageName, description, price, recommendation };
}

export const packagesList = [
  packagesData(
    "Starter",
    ["Site Health Overview Report", "1 Hour Consulting"],
    199,
    false
  ),

  packagesData(
    "Professional",
    [
      "Site Health Overview Report",
      "Indexability and Site Crawability Audit",
      "Mobile First Audit",
      "Meta Tags Audit",
      "1 Hour Consultation",
    ],
    499,
    true
  ),
  packagesData(
    "Premier",
    [
      "Site Health Overview Report",
      "Indexability and Site Crawability Audit",
      "Mobile First Audit",
      "Meta Tags Audit",
      "Keywords Report",
      "Site Architecture Audit",
      "Backlink Report",
      "2 Hour Consultation",
    ],
    999,
    false
  ),
];

export function IconText({ text, padding, classes, mobile }) {
  return (
    <Grid
      container
      alignItems={"center"}
      spacing={2}
      style={padding ? { padding: 15 } : null}
    >
      <Grid item className={mobile == true ? classes.iconG : classes.iconR}>
        {mobile == true ? icons.checkedSquare : icons.closeSquare}
      </Grid>
      <Grid item>
        <Typography className={classes.title}>{text}</Typography>
      </Grid>
    </Grid>
  );
}

export function IconMainText({ text, classes }) {
  return (
    <Grid container alignItems={"center"} spacing={2}>
      <Grid item>{icons.mobile}</Grid>
      <Grid item>
        <Typography style={{ color: "#1E334E" }}>{text}</Typography>
      </Grid>
    </Grid>
  );
}

export function IconPara({ icon, title, text, padding, classes, des, mobile }) {
  return (
    <Grid
      container
      alignItems={"center"}
      spacing={2}
      style={padding ? { padding: 15 } : null}
    >
      <Grid item md={2} className={classes.icon}>
        {icon}
      </Grid>
      <Grid item md={10}>
        <Typography
          className={
            mobile == true
              ? classNames(classes.titlePara, classes.titleGreen)
              : classNames(classes.titlePara, classes.titleRed)
          }
        >
          {title}
        </Typography>
        <Typography className={classes.para}>{text}</Typography>
      </Grid>
      <Grid item md={12}>
        <Typography className={classes.des}>{des}</Typography>
      </Grid>
    </Grid>
  );
}
