import React, { Fragment } from "react";
import { ErrorTypography } from "../typography";
import { CardContent, Typography, Grid, Card, Paper } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import { GreenButton } from "../buttons";
import icons from "../icons";

const styles = (theme) => ({
  card: {
    boxShadow: "unset",
    backgroundColor: theme.palette.errorBg,
    minHeight: "100vh",
  },
  grid: { minHeight: "40vh" },
  paper: { boxShadow: "unset" },
  greenText: { color: theme.palette.graderGraphColors.green },
  textDec: { textDecoration: "unset" },
  fontFamily: { fontFamily: "Lato" },
  mainTitle: { fontFamily: "Montserrat" },
});

function ErrorRedirect({ serverError, classes }) {
  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography
          component={"h5"}
          variant={"h5"}
          color={"textSecondary"}
          align={"left"}
          className={classes.mainTitle}
        >
          <b>YOUR WEBSITE’S SEO SCORECARD</b>
        </Typography>
        <br />
        <br />
        <Paper className={classes.paper}>
          <Grid
            container
            className={classes.grid}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Grid item md={12}>
              <Typography align={"center"}>{icons.warningCircle}</Typography>
            </Grid>
            <Grid item md={12}>
              {/*<ErrorTypography align={"center"}>{serverError}</ErrorTypography>*/}
              <Typography
                className={classes.fontFamily}
                component={"h3"}
                variant={"h3"}
                align={"center"}
                color={"textSecondary"}
              >
                Please do grade your site{" "}
                <a
                  href={"https://lxrseo.com/seo-site-grader"}
                  target={"_blank"}
                >
                  here
                </a>
              </Typography>
              <Typography
                className={classes.fontFamily}
                variant={"subheading"}
                color={"textSecondary"}
                align={"center"}
                gutterBottom
              >
                Download our detailed SEO audit report with 62+ SEO parameters
                for <span className={classes.greenText}>Free</span>.
              </Typography>
            </Grid>
            <Grid item md={12}>
              <Typography align={"center"}>
                <a
                  href={"https://lxrseo.com/seo-site-grader"}
                  target={"_blank"}
                  className={classes.textDec}
                >
                  <GreenButton>Go to LXRSEO</GreenButton>
                </a>
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </CardContent>
    </Card>
  );
}

export default withStyles(styles)(ErrorRedirect);
