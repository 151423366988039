import React, { Fragment } from "react";
import { Card, CardContent, Grid } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import activityReportStyles from "../../assets/jss/activityReport/activityReportStyles";
import TasksActivity from "./tasksActivity";
import KPIGraph from "../kpi-graph";
import { connect } from "react-redux";
import clsx from "classnames";
import { onChangeReportKPIGraphType } from "../../actions";
import Stats from "./stats";
import {
  fetchActivityReport,
  toggleDateRange,
} from "../../actions/activityReport";
import Typography from "@mui/material/Typography";
import WeeklyDateRange from "../weekly-date-range";
import * as _ from "lodash";
import Task from "../../components/dashboard/task";
import TaskHistory from "./taskHistory";
import { CircularProgress, Paper } from "@mui/material/index";
import ReactDOM from "react-dom";
import { UPDATE_SELECTED_NODE_KEY } from "../../actions/actionTypes";

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="xl" />;

function getKpiGraphHistory(activityReport, kpiTarget) {
  console.log("activityReport==,", activityReport);
  const kpiHistory = activityReport.kpiHistory;
  const selectedDateRange = activityReport.selectedDateRange;
  const reportKPITypeUi = activityReport.reportKPITypeUi;
  let data = [];
  if (_.has(kpiHistory, selectedDateRange)) {
    let rawData = kpiHistory[selectedDateRange];
    // _.map(temp1,(data,key)=>({week:key, KPI:data[0],missed:data[1],completed:data[3]}))
    /*0:KPI value, 1:completed, 2:skipped, 3:Missed*/
    data = _.map(rawData, (data, key) => ({
      week: key,
      KPI: Number(data[0]),
      missed: Number(data[3]),
      completed: Number(data[1]),
      KPITarget: kpiTarget,
    }));
    console.log("dataMap=", data);
  }
  return data;
}

/*function getKpiGraphHistory(activityReport, kpiTarget) {
    console.log("activityReport==,", activityReport);
    const kpiHistory = activityReport.kpiHistory;
    const selectedDateRange = activityReport.selectedDateRange;
    const reportKPITypeUi = activityReport.reportKPITypeUi;
    let data = [];
    if (_.has(kpiHistory, selectedDateRange) && _.has(kpiHistory[selectedDateRange], reportKPITypeUi)) {
        let rawData = kpiHistory[selectedDateRange][reportKPITypeUi];
        // _.map(temp1,(data,key)=>({week:key, KPI:data[0],missed:data[1],completed:data[3]}))
        /!*0:KPI value, 1:completed, 2:skipped, 3:Missed*!/
        data = _.map(rawData, (data, key) => ({
            week: key,
            KPI: Number(data[0]),
            missed: Number(data[3]),
            completed: Number(data[1]),
            KPITarget: kpiTarget
        }))
        console.log("dataMap=", data);
    }
    return data;
}*/

class ActivityReport extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedNodeKey: null,
    };
    this.myrefs = {};
  }

  componentDidMount() {
    document.title = "LXRGuide - Activity Report";
    const { activityReport, selectedAccountInfo, kpiTypeUi } = this.props;
    const { selectedDateRange } = activityReport;
    if (
      selectedAccountInfo.ppcId &&
      (activityReport.ppcId != selectedAccountInfo.ppcId ||
        activityReport.targetKpi != selectedAccountInfo.targetKpi ||
        activityReport.targetValue != selectedAccountInfo.targetValue ||
        activityReport.budget != selectedAccountInfo.budget)
    ) {
      this.props.fetchActivityReport(selectedDateRange, true, kpiTypeUi);
    }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const { userDetails } = this.props;
    const { userInfo } = userDetails;
    const pricingPlanId =
      userInfo && userInfo.pricingPlan
        ? userInfo.pricingPlan.pricingPlanId
        : null;
    const changeInAccountSettings =
      prevProps.userDetails.selectedAccountInfo &&
      (prevProps.userDetails.selectedAccountInfo.ppcId !=
        userDetails.selectedAccountInfo.ppcId ||
        prevProps.userDetails.selectedAccountInfo.targetKpi !=
          userDetails.selectedAccountInfo.targetKpi ||
        prevProps.userDetails.selectedAccountInfo.targetValue !=
          userDetails.selectedAccountInfo.targetValue ||
        prevProps.userDetails.selectedAccountInfo.budget !=
          userDetails.selectedAccountInfo.budget);
    if (
      changeInAccountSettings &&
      pricingPlanId != null &&
      pricingPlanId != 7
    ) {
      const { activityReport, selectedAccountInfo, kpiTypeUi } = this.props;
      const { selectedDateRange } = activityReport;
      this.props.fetchActivityReport(selectedDateRange, true, kpiTypeUi);
    }
  }

  handleDateRangeChange = (event) => {
    const { tasksStatusCount } = this.props.activityReport;
    console.log("tasksStatusCount value is: ", tasksStatusCount);
    this.props.toggleDateRange(event, this.props.kpiTypeUi);
    if (
      !_.isEmpty(tasksStatusCount) &&
      _.has(tasksStatusCount, event.target.value)
    )
      return;
    this.props.fetchActivityReport(event.target.value, false);
  };
  navigateToThatWeek = (value) => {
    console.log("clicked value=", value);
    this.scrollTo(value);
  };
  scrollTo = async (value) => {
    console.log("scrollValue=", value);
    // console.log("-----------", value.substr(0, value.indexOf("-")));
    let substringDay = value.substr(0, value.indexOf("-"));
    let selectedNode = null;
    // let ref = this.myrefs[value];
    // console.log("this.myrefs123=", this.myrefs);
    // console.log("this.myrefs[value]=", this.myrefs[value]);
    let selectedNodeKey = null;
    let counter = 0;
    await _.forEach(this.myrefs, async (value, key) => {
      console.log("key.contains(substringDay)=", key.includes(substringDay));
      if (key.includes(substringDay) && counter == 0) {
        selectedNodeKey = key;
        console.log("selectedNodeKey", selectedNodeKey);
        console.log("substringDay", substringDay);
        // await this.setState({selectedNodeKey: key})
        selectedNode = value;
        counter++;
      }
    });
    if (selectedNode != null || selectedNode != undefined) {
      const { handleScrollStart, handleScrollEnd } = this.props;
      var headerOffset = 100;
      // await handleScrollStart();
      var elementRect =
        ReactDOM.findDOMNode(selectedNode).getBoundingClientRect().top;
      console.log("elementRect=", elementRect);
      const bodyRect = document.body.getBoundingClientRect().top;
      console.log("bodyRect= before", bodyRect);
      const elementPosition = elementRect - bodyRect;
      console.log("elementPosition=", elementPosition);
      var offsetPosition = elementPosition - headerOffset;
      console.log("offsetPosition=", offsetPosition);
      // console.lo
      console.log("selectedNode==", selectedNode);
      console.log(
        "ReactDOM.findDOMNode(selectedNode).focus()",
        ReactDOM.findDOMNode(selectedNode).focus()
      );
      // ReactDOM.findDOMNode(selectedNode).scrollTo({top: offsetPosition, behavior: 'smooth',});
      // this[selectedNode].focus();
      ReactDOM.findDOMNode(selectedNode).focus({ preventScroll: true });
      // await window.scrollTo({top: offsetPosition, behavior: 'smooth'});
      console.log(
        "bodyRect after scroll",
        document.body.getBoundingClientRect().top
      );
      await this.setState({ selectedNodeKey: selectedNodeKey });
      this.props.updateSelectedNode(selectedNodeKey);
      console.log("before 5 seconds");

      // await this.delay(5000);
      // console.log("after 5 seconds")
      // handleScrollEnd();
      // var intervalId = setInterval(() => this.clearTheScroll(selectedNodeKey), 1000);
      // await this.setState({intervalId})
    }
    /*else {
            this.props.updateSelectedNode(selectedNodeKey);
        }*/
  };

  /*    clearTheScroll = (selectedNodeKey) => {
            const {intervalId} = this.state;
            const {handleScrollEnd} = this.props;
            handleScrollEnd();
            this.setState({selectedNodeKey: selectedNodeKey});
            clearInterval(intervalId);
        }
        onScroll =async (selectedNodeKey) => {
            await window.scrollTo({top: selectedNodeKey, behavior: 'smooth'});
           return new Promise();
        }*/
  delay = (ms) => new Promise((res) => setTimeout(res, ms));

  render() {
    const {
      classes,
      onChangeKPIGraphValue,
      kpiHistory,
      kpiType,
      isLoading,
      kpiTypeUi,
      isReportKpiGraphLoading,
      activityReport,
      toggleDateRange,
      width,
      taskPerformanceMap,
      currency,
      tasksStatusCount,
      weeklyMap,
      kpiGraphHistory,
    } = this.props;
    // const {selectedNodeKey} = this.state;
    const { selectedNodeKey } = this.props;
    const { selectedDateRange } = activityReport;
    
    // console.log("weeklyMap=", weeklyMap);
    // console.log("myrefs=", this.myrefs);

    const fixedGraphHeightPaper = clsx(
      classes.paper,
      classes.fixedGraphHeightPaper
    );
    return (
      <Card className={classes.card}>
        <CardContent className={classes.CardContent}>
          <Grid
            container
            alignItems={"center"}
            className={classes.pb}
            justifyContent={
              width == "sm" || width == "xs" ? "space-between" : "flex-start"
            }
          >
            <Grid item md={2}>
              <Typography
                align={"left"}
                variant={"h3"}
                component={"h3"}
                color={"textPrimary"}
                gutterBottom
              >
                <b>Task Activity</b>
              </Typography>
            </Grid>
            <Grid item>
              {/*<WeeklyDateRange toggleDateRange={(event) => toggleDateRange(event, kpiTypeUi)}
                                             selectedDateRange={selectedDateRange}/>*/}
              <WeeklyDateRange
                toggleDateRange={(event) => this.handleDateRangeChange(event)}
                selectedDateRange={selectedDateRange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} id={"task-activity"}>
              <TasksActivity
                isLoading={isReportKpiGraphLoading}
                tasksStatusCount={tasksStatusCount}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} id={"performance-section"}>
              <KPIGraph
                kpiTypeUi={kpiTypeUi}
                onChaneKPIGraphValue={onChangeKPIGraphValue}
                className={fixedGraphHeightPaper}
                classes={classes}
                kpiHistory={kpiGraphHistory}
                kpiType={kpiType}
                loading={isReportKpiGraphLoading}
                navigateToThatWeek={this.navigateToThatWeek}
                acitivityReport
              />
            </Grid>
            <Grid item md={12}>
              <Stats
                isLoading={isReportKpiGraphLoading}
                statsPerformanceMap={taskPerformanceMap}
                currencySymbol={currency}
                kpiType={kpiType}
              />
            </Grid>
            <Fragment>
              {isReportKpiGraphLoading ? (
                <Grid item md={12} className={classes.loader}>
                  <CircularProgress color="secondary" />
                </Grid>
              ) : (
                <Fragment>
                  {!_.isEmpty(weeklyMap) &&
                    _.map(weeklyMap, (tasks, dateRange) => (
                      <Grid
                        item
                        tabindex="-1"
                        id={"task-his"}
                        className={classes.weekFocus}
                        md={6}
                        ref={(e) => (this.myrefs[dateRange] = e)}
                      >
                        <TaskHistory
                          key={dateRange}
                          isLoading={isReportKpiGraphLoading}
                          selectedNodeKey={selectedNodeKey}
                          tasks={tasks}
                          dateRange={dateRange}
                          selectedDateRange={selectedDateRange}
                        />
                      </Grid>
                    ))}
                  <Grid item md={12} id={"task-his"}>
                    {_.isEmpty(weeklyMap) && (
                      <Typography color={"textPrimary"} align={"center"}>
                        No History For Selected Date Range.
                      </Typography>
                    )}
                  </Grid>
                </Fragment>
              )}
            </Fragment>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

const calculateTaskMap = (activityReport) => {
  let taskPerformanceData = {};
  if (!_.isEmpty(activityReport.history))
    taskPerformanceData = _.groupBy(
      activityReport.history[activityReport.selectedDateRange],
      "time_period"
    );
  return taskPerformanceData;
};
const calculateWeekTasks = (activityReport) => {
  let weeklyData = {};
  if (!_.isEmpty(activityReport.history))
    weeklyData = activityReport.weekTasks[activityReport.selectedDateRange];
  return weeklyData;
};
// _.groupBy(state.activityReport.history[state.activityReport.selectedDateRange], "time_period")
const mapStateToProps = (state) => ({
  isReportKpiGraphLoading: state.activityReport.isLoading,
  kpiHistory: state.activityReport.kpiHistory,
  kpiType: state.perf.kpiType,
  currency: state.perf.currency,
  kpiTypeUi: state.activityReport.reportKPITypeUi,
  activityReport: state.activityReport,
  selectedAccountInfo: state.userInformation.selectedAccountInfo,
  taskPerformanceMap: calculateTaskMap(state.activityReport),
  weeklyMap: calculateWeekTasks(state.activityReport),
  tasksStatusCount:
    state.activityReport.tasksStatusCount[
      state.activityReport.selectedDateRange
    ],
  kpiGraphHistory: getKpiGraphHistory(
    state.activityReport,
    state.perf.kpiTarget
  ),
  userDetails: state.userInformation,
  selectedNodeKey: state.kpiGraphNavigation.selectedNodeKey,
  // isScrollHandleProgress: state.scrollHandler.isProgress,
});
const mapDispatchToProps = (dispatch) => ({
  updateSelectedNode: (selectedNodeKey) =>
    dispatch({ type: UPDATE_SELECTED_NODE_KEY, selectedNodeKey }),
  onChangeKPIGraphValue: (KPiType) =>
    dispatch(onChangeReportKPIGraphType(KPiType)),
  fetchActivityReport: (historyId, isPpIdChanged, kpiTypeUi) =>
    dispatch(fetchActivityReport(historyId, isPpIdChanged, kpiTypeUi)),
  toggleDateRange: (event, kpiTypeUi) =>
    dispatch(toggleDateRange(event.target.value, kpiTypeUi)),
  // updateSelectedNode: (selectedNodeKey) => dispatch({type: UPDATE_SELECTED_NODE_KEY, selectedNodeKey}),
  // handleScrollStart: () => dispatch({type: SCROLL_HANDLER_START}),
  // handleScrollEnd: () => dispatch({type: SCROLL_HANDLER_ENDED}),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withWidth()(withStyles(activityReportStyles)(ActivityReport)));
