import React, { Fragment, PureComponent } from "react";
import { Grid, Paper } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import taskActivityStyles from "../../assets/jss/activityReport/taskActivityStyle";
import { IconAndText } from "../dashboard";
import icons from "../../components/icons";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import * as PropTypes from "prop-types";
import { CircularProgress, Typography } from "@mui/material/index";
import className from "classnames";
import * as _ from "lodash";
import { PieChart, Pie, Sector, Cell } from "recharts";

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="xl" />;

function ProgressBar(props) {
  const { classes, width, title, value, totalTasks } = props;
  return (
    <div>
      <CircularProgressbar
        value={!isNaN(props.percentage) ? props.percentage : 0}
        text={`${!isNaN(props.percentage) ? props.percentage : 0}%`}
        styles={buildStyles({
          textSize: "1rem",
          // How long animation takes to go from one percentage to another, in seconds
          pathTransitionDuration: 0.5,
          // Can specify path transition in more detail, or remove it entirely
          // pathTransition: 'none',
          // Colors
          pathColor: `${props.fillColor}`,
          textColor: props.theme.palette.text.primary,
          trailColor: props.theme.palette.common.liteGrey,
        })}
      />
      <div className={className("flex justifyCenter", classes.pgTop)}>
        <Typography
          className={classes.current}
          variant={width == "sm" || width == "xs" ? "h4" : "h2"}
          component={width == "sm" || width == "xs" ? "h4" : "h2"}
        >
          {value}
        </Typography>
        &nbsp;/&nbsp;
        <Typography
          className={classes.target}
          variant={width == "sm" || width == "xs" ? "body2" : "body1"}
        >
          <b>{totalTasks}</b>
        </Typography>
      </div>
      <Typography className={classes.mgTop1} variant={"body1"} align={"center"}>
        {" "}
        <b>{title}</b>
      </Typography>
    </div>
  );
}

ProgressBar.propTypes = {
  number: PropTypes.any,
  fillColor: PropTypes.any,
  theme: PropTypes.any,
};

class TasksActivity extends PureComponent {
  state = {
    activeIndex: 0,
  };

  onPieEnter = (data, index) => {
    this.setState({
      activeIndex: index,
    });
  };

  render() {
    const { classes, theme, width, isLoading, tasksStatusCount } = this.props;
    // console.log("tasksStatusCount=", tasksStatusCount);
    const percentage = 0;
    const completedTasks =
      tasksStatusCount != undefined ? tasksStatusCount.completedTasks : 0;
    const incompleteTasks =
      tasksStatusCount != undefined ? tasksStatusCount.incompleteTasks : 0;
    const totalTasks =
      tasksStatusCount != undefined
        ? _.sumBy(_.values(tasksStatusCount).map(Number))
        : 0;
    // const completedPercentage = Math.round(Math.min(completedTasks / totalTasks * 100, 100));
    // const missedPercentage = Math.round(Math.min(incompleteTasks / totalTasks * 100, 100));

    const data = [
      { name: "Completed", value: Number(completedTasks) },
      { name: "Missed", value: Number(incompleteTasks) },
    ];
    const renderActiveShape = (props) => {
      const RADIAN = Math.PI / 180;
      const {
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        startAngle,
        endAngle,
        fill,
        payload,
        percent,
        value,
        name,
      } = props;
      // console.log("graph props=", props)
      const sin = Math.sin(-RADIAN * midAngle);
      const cos = Math.cos(-RADIAN * midAngle);
      const sx = cx + (outerRadius + 10) * cos;
      const sy = cy + (outerRadius + 10) * sin;
      const mx = cx + (outerRadius + 30) * cos;
      const my = cy + (outerRadius + 30) * sin;
      const ex = mx + (cos >= 0 ? 1 : -1) * 22;
      const ey = my;
      const textAnchor = cos >= 0 ? "start" : "end";
      const customFill =
        name == "Completed"
          ? theme.palette.secondary.main
          : theme.palette.common.darkGrey;
      const textFill = theme.palette.text.primary;
      return (
        <g>
          <text
            x={cx}
            y={cy}
            dy={8}
            textAnchor="middle"
            fill={theme.palette.text.primary}
          >
            {payload.name}
          </text>
          <Sector
            cx={cx}
            cy={cy}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            startAngle={startAngle}
            endAngle={endAngle}
            fill={customFill}
          />
          <Sector
            cx={cx}
            cy={cy}
            startAngle={startAngle}
            endAngle={endAngle}
            innerRadius={outerRadius + 6}
            outerRadius={outerRadius + 10}
            fill={customFill}
          />
          <path
            d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
            stroke={customFill}
            fill="none"
          />
          <circle cx={ex} cy={ey} r={2} fill={customFill} stroke="none" />
          <text
            x={ex + (cos >= 0 ? 1 : -1) * 12}
            y={ey}
            textAnchor={textAnchor}
            fill={textFill}
          >{`${name}: ${value}`}</text>
          <text
            x={ex + (cos >= 0 ? 1 : -1) * 12}
            y={ey}
            dy={18}
            textAnchor={textAnchor}
            fill={textFill}
          >
            {`(${(percent * 100).toFixed(2)}%)`}
          </text>
        </g>
      );
    };
    const COLORS = [
      theme.palette.secondary.main,
      theme.palette.common.darkGrey,
    ];
    console.log("tasksStatusCount", tasksStatusCount);
    const isEmpty =
      tasksStatusCount != undefined &&
      tasksStatusCount.completedTasks == "0" &&
      tasksStatusCount.incompleteTasks == "0" &&
      tasksStatusCount.offlineCompletedTasks == "0" &&
      tasksStatusCount.removedTasks == "0" &&
      tasksStatusCount.skippedTasks == "0";
    return (
      <Paper className={classes.paper}>
        <IconAndText icon={icons.pendingTask} text={"Task Performance"} />
        {isLoading ? (
          <div className={classes.loadingDiv}>
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <Fragment>
            {tasksStatusCount != undefined && !isEmpty ? (
              <Fragment>
                <Grid container spacing={2} style={{}}>
                  <Grid item>{icons.square(theme.palette.secondary.main)}</Grid>
                  <Grid item>Completed</Grid>
                  <Grid item>
                    {icons.square(theme.palette.common.darkGrey)}
                  </Grid>
                  <Grid item>Missed</Grid>
                </Grid>
                <Grid
                  container
                  justifyContent={"space-around"}
                  alignItems={"center"}
                  spacing={2}
                  style={{ height: "70%" }}
                >
                  <Grid item md={12} >
                    <PieChart
                      width={580}
                      height={390}
                      margin={{ top: 10, right: 5, bottom: 5, left: 10 }}
                    >
                      <Pie
                        activeIndex={this.state.activeIndex}
                        activeShape={renderActiveShape}
                        data={data}
                        // cx={200}
                        // cy={200}
                        innerRadius={60}
                        isAnimationActive={true}
                        animationBegin={500}
                        animationDuration={6000}
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="value"
                        onMouseEnter={this.onPieEnter}
                      >
                        {data.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Pie>
                    </PieChart>
                  </Grid>
                </Grid>
              </Fragment>
            ) : (
              <Typography align={"center"}>
                There is no data for the selected date range
              </Typography>
            )}
          </Fragment>
        )}
      </Paper>
    );
  }
}

TasksActivity.propTypes = {};

export default withWidth()(
  withStyles(taskActivityStyles, { withTheme: true })(TasksActivity)
);
