import React, { useState, useEffect } from "react";
import { Grid, Typography, withStyles, Button } from "@mui/material";
import {
  GreyHeadingTextWHS,
  ScoreTypography,
} from "../../components/typography";
import Discoverability_WHC from "./discoverability_WHC";
import Performance_WHC from "./performance_WHC";
import Accessibility_WHC from "./accessibility_WHC";
import Security_WHC from "./security_WHC";
import { Lens } from "@mui/icons-material";
import "./WebsiteHealthScoreCard.css";

function ParameterSection_WHC(props) {
  const {
    discoverabilityData,
    performanceData,
    accessibilityData,
    securityData,
  } = props;

  const getRoundedScore = (score) => Math.round(score);

  const getScoreColor = (score) => {
    if (score <= 35) return "#F13B0C";
    if (score >= 36 && score <= 70) return "#FFCF15";
    if (score >= 71 && score <= 100) return "#00BF11";
    return "";
  };

  const getScoreLabel = (score) => {
    if (score <= 35) return "Poor";
    if (score >= 36 && score <= 70) return "Average";
    if (score >= 71 && score <= 100) return "Good";
    return "";
  };

  const [discoverabilityScore, setDiscoverabilityScore] = useState(
    discoverabilityData ? discoverabilityData.discoverabilityScore : null
  );
  const [performanceScore, setPerformanceScore] = useState(
    performanceData ? performanceData.performanceScore : null
  );
  const [accessibilityScore, setAccessibilityScore] = useState(
    accessibilityData ? accessibilityData.accessibilityScore : null
  );
  const [securityScore, setSecurityScore] = useState(
    securityData ? securityData.securityScore : null
  );

  //colors
  const [discoverabilityColor, setDiscoverabilityColor] = useState("");
  const [performanceColor, setPerformanceColor] = useState("");
  const [accessibilityColor, setAccessibilityColor] = useState("");
  const [securityColor, setSecurityColor] = useState("");

  //Label
  const [discoverabilityLabel, setDiscoverabilityLabel] = useState("");
  const [performanceLabel, setPerformanceLabel] = useState("");
  const [accessibilityLabel, setAccessibilityLabel] = useState("");
  const [securityLabel, setSecurityLabel] = useState("");

  useEffect(() => {
    if (discoverabilityData) {
      setDiscoverabilityScore(
        discoverabilityData.discoverabilityScore
          ? discoverabilityData.discoverabilityScore
          : null
      );
      setDiscoverabilityColor(
        getScoreColor(
          discoverabilityData.discoverabilityScore
            ? discoverabilityData.discoverabilityScore
            : null
        )
      );
      setDiscoverabilityLabel(
        getScoreLabel(
          discoverabilityData.discoverabilityScore
            ? discoverabilityData.discoverabilityScore
            : null
        )
      );
    }
    if (performanceData) {
      setPerformanceScore(
        performanceData.performanceScore
          ? performanceData.performanceScore
          : null
      );
      setPerformanceColor(
        getScoreColor(
          performanceData.performanceScore
            ? performanceData.performanceScore
            : null
        )
      );
      setPerformanceLabel(
        getScoreLabel(
          performanceData.performanceScore
            ? performanceData.performanceScore
            : null
        )
      );
    }
    if (accessibilityData) {
      setAccessibilityScore(
        accessibilityData.accessibilityScore
          ? accessibilityData.accessibilityScore
          : null
      );
      setAccessibilityColor(
        getScoreColor(
          accessibilityData.accessibilityScore
            ? accessibilityData.accessibilityScore
            : null
        )
      );
      setAccessibilityLabel(
        getScoreLabel(
          accessibilityData.accessibilityScore
            ? accessibilityData.accessibilityScore
            : null
        )
      );
    }
    if (securityData) {
      setSecurityScore(
        securityData.securityScore ? securityData.securityScore : null
      );
      setSecurityColor(
        getScoreColor(
          securityData.securityScore ? securityData.securityScore : null
        )
      );
      setSecurityLabel(
        getScoreLabel(
          securityData.securityScore ? securityData.securityScore : null
        )
      );
    }
  }, [discoverabilityData, performanceData, securityData, accessibilityData]);

  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  console.log(
    "color of sections",
    discoverabilityLabel,
    performanceLabel,
    accessibilityLabel,
    securityLabel
  );

  return (
    // <div>
    <div className="container">
      <div className="block-tabs">
        {[1, 2, 3, 4].map((index) => (
          <button
            key={index}
            className={
              index == 1 &&
              toggleState == index &&
              discoverabilityLabel == "Poor"
                ? "tabs active-tabs-poor"
                : index == 1 &&
                  toggleState == index &&
                  discoverabilityLabel == "Average"
                ? "tabs active-tabs-average"
                : index == 1 &&
                  toggleState == index &&
                  discoverabilityLabel == "Good"
                ? "tabs active-tabs-good"
                : index == 2 &&
                  toggleState == index &&
                  performanceLabel == "Poor"
                ? "tabs active-tabs-poor"
                : index == 2 &&
                  toggleState == index &&
                  performanceLabel == "Average"
                ? "tabs active-tabs-average"
                : index == 2 &&
                  toggleState == index &&
                  performanceLabel == "Good"
                ? "tabs active-tabs-good"
                : index == 3 &&
                  toggleState == index &&
                  accessibilityLabel == "Poor"
                ? "tabs active-tabs-poor"
                : index == 3 &&
                  toggleState == index &&
                  accessibilityLabel == "Average"
                ? "tabs active-tabs-average"
                : index == 3 &&
                  toggleState == index &&
                  accessibilityLabel == "Good"
                ? "tabs active-tabs-good"
                : index == 4 && toggleState == index && securityLabel == "Poor"
                ? "tabs active-tabs-poor"
                : index == 4 &&
                  toggleState == index &&
                  securityLabel == "Average"
                ? "tabs active-tabs-average"
                : index == 4 && toggleState == index && securityLabel == "Good"
                ? "tabs active-tabs-good"
                : "tabs"
            }
            onClick={() => toggleTab(index)}
          >
            {index == 1 && toggleState == 1 ? (
              <GreyHeadingTextWHS
                style={{ color: discoverabilityColor, fontWeight: "700" }}
              >
                Discoverability
              </GreyHeadingTextWHS>
            ) : index == 2 && toggleState == 2 ? (
              <GreyHeadingTextWHS
                style={{ color: performanceColor, fontWeight: "700" }}
              >
                Performance
              </GreyHeadingTextWHS>
            ) : index == 3 && toggleState == 3 ? (
              <GreyHeadingTextWHS
                style={{ color: accessibilityColor, fontWeight: "700" }}
              >
                Accessibility
              </GreyHeadingTextWHS>
            ) : index == 4 && toggleState == 4 ? (
              <GreyHeadingTextWHS
                style={{ color: securityColor, fontWeight: "700" }}
              >
                Security
              </GreyHeadingTextWHS>
            ) : (
              <GreyHeadingTextWHS>
                {index === 1
                  ? "Discoverability"
                  : index === 2
                  ? "Performance"
                  : index === 3
                  ? "Accessibility"
                  : "Security"}
              </GreyHeadingTextWHS>
            )}
            <div className="tabs-block-content">
              {index == 1 && toggleState == 1 ? (
                <ScoreTypography style={{ color: discoverabilityColor }}>
                  {getRoundedScore(discoverabilityScore)}
                  <span className="totalScoreText">/100</span>
                </ScoreTypography>
              ) : index == 2 && toggleState == 2 ? (
                <ScoreTypography style={{ color: performanceColor }}>
                  {getRoundedScore(performanceScore)}
                  <span className="totalScoreText">/100</span>
                </ScoreTypography>
              ) : index == 3 && toggleState == 3 ? (
                <ScoreTypography style={{ color: accessibilityColor }}>
                  {getRoundedScore(accessibilityScore)}
                  <span className="totalScoreText">/100</span>
                </ScoreTypography>
              ) : index == 4 && toggleState == 4 ? (
                <ScoreTypography style={{ color: securityColor }}>
                  {getRoundedScore(securityScore)}
                  <span className="totalScoreText">/100</span>{" "}
                </ScoreTypography>
              ) : (
                <ScoreTypography>
                  {getRoundedScore(
                    index === 1
                      ? discoverabilityScore
                      : index === 2
                      ? performanceScore
                      : index === 3
                      ? accessibilityScore
                      : securityScore
                  )}
                  <span className="totalScoreText">/100</span>
                </ScoreTypography>
              )}
              {index >= 1 && index <= 4 ? (
                <div className="grid-item-css">
                  <Lens
                    style={{
                      color:
                        index === 1
                          ? discoverabilityColor
                          : index === 2
                          ? performanceColor
                          : index === 3
                          ? accessibilityColor
                          : index === 4
                          ? securityColor
                          : "RGBA(252, 252, 252, 0)",
                      width: "12px",
                    }}
                  />
                  <Typography
                    style={{
                      color:
                        index === 1
                          ? discoverabilityColor
                          : index === 2
                          ? performanceColor
                          : index === 3
                          ? accessibilityColor
                          : index === 4
                          ? securityColor
                          : "RGBA(252, 252, 252, 0)",
                    }}
                  >
                    &nbsp;
                    {index === 1
                      ? discoverabilityLabel
                      : index === 2
                      ? performanceLabel
                      : index === 3
                      ? accessibilityLabel
                      : index === 4
                      ? securityLabel
                      : ""}
                  </Typography>
                </div>
              ) : (
                <div>
                  <p>----</p>
                </div>
              )}
            </div>
          </button>
        ))}
      </div>
      <div className="content-tabs">
        {[1, 2, 3, 4].map((index) => (
          <div
            key={index}
            className={
              toggleState === index ? "content  active-content" : "content"
            }
          >
            {index === 1 ? (
              <Discoverability_WHC discoverabilityData={discoverabilityData} />
            ) : index === 2 ? (
              <Performance_WHC performanceData={performanceData} />
            ) : index === 3 ? (
              <Accessibility_WHC accessibilityData={accessibilityData} />
            ) : (
              <Security_WHC securityData={securityData} />
            )}
          </div>
        ))}
      </div>
    </div>
    // </div>
  );
}

export default ParameterSection_WHC;
