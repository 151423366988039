import React from 'react';
import SiteGraderHome from "../../pages/site-grader-home";
import {connect} from "react-redux";
import Integration from "./integration";
import ErrorRedirect from "./errorRedirect";
import { Card, CardContent, CircularProgress, Paper } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import {Redirect} from "react-router-dom";
import Footer from "../footer";
import {getDomainInfo, updateDomainInfoFromSession} from "../../actions";
import {EXTERNAL_SEO} from "../../actions/actionTypes";
import SiteGraderNewHomePage from "../../NewInterfacePages/SEOSiteGraderHomePage/site-grader-home-page"

function defaultRouteSwitch(siteGrader, props) {
    const {urlInfo} = siteGrader;
    if (urlInfo !== null && urlInfo.urlStatus == 200 && siteGrader.siteGraderScore == 0) {
        return <Integration type={EXTERNAL_SEO}/>
    } else if (siteGrader && siteGrader.siteGraderScore > 0 && urlInfo !== null) {
        const {domainId} = siteGrader.urlInfo;
        const {url} = siteGrader.urlInfo;
        // return <Redirect push to={`/site-grader/${domainId}/${url}/0`}/>
        console.log("result page condition");
        return window.location.href=`/seo-site-grader-report.html?domainId=${domainId}&url=${url}&type=0`;
        // return <Redirect push to={`/seo-site-grader-report/${domainId}/${url}/0`}/>
        // return <SiteGrader/>
    } else if (urlInfo === null) {
        // return <SiteGraderHome/>
        return <SiteGraderNewHomePage/>
    }

}


class SiteGraderComponents extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isCompLoading: false,
        }
    }

    async componentDidMount() {
        const homepageUrl = (window.lxrOptions && window.lxrOptions.homepageUrl) ? window.lxrOptions.homepageUrl : null;
        if (homepageUrl && homepageUrl !== "" && homepageUrl !== null && homepageUrl.length > 0) {
            this.setState({isCompLoading: true});
            await this.props.saveSiteGraderDetails(homepageUrl, EXTERNAL_SEO);
            this.setState({isCompLoading: false});
        }
    }

    render() {
        const {serverError} = this.props.siteGrader;
        const {siteGrader, classes} = this.props;
        const {isCompLoading} = this.state;
        return (
            <div>
                {(serverError !== null && serverError !== "") ?
                    <ErrorRedirect serverError={serverError}/> :

                    isCompLoading ? <div className={classes.mainDiv}>
                            <CircularProgress/>
                        </div> :
                        defaultRouteSwitch(siteGrader, this.props)}
                {/*<Card>
                    <CardContent style={{paddingBottom: 0, padding: 0}}>
                        <Paper style={{boxShadow: "unset"}}>
                            <Footer/>
                        </Paper>
                    </CardContent>
                </Card>*/}

            </div>
        );
    }
}

const styles = (theme) => ({
    mainDiv: {
        display: "flex", justifyContent: "center", alignItems: "center", minHeight: "40em",
        width: "100%",
    }
});


const mapStateToProps = state => ({
    siteGrader: state.siteGrader,
});
const mapDispatchToProps = (dispatch) => ({
    updateDomainInfoFromSession: () => {
        return dispatch(updateDomainInfoFromSession())
    },
    saveSiteGraderDetails: (domainName, type) => {
        return dispatch(getDomainInfo(domainName, type))
    },
});
export default connect(mapStateToProps, mapDispatchToProps)((withStyles(styles))(SiteGraderComponents));
