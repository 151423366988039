import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import Overview from "./overview";
import OpportunityScore from "./opportunityScore";
import AccountTracking from "./accountTracking";
import LxrGuideInAction from "./lxrGuideInAction";
import { getGoogleAccountPerformance } from "../../actions";
import { Button, Grid, Typography } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import CircularProgress from "@mui/material/CircularProgress";
import { connect } from "react-redux";
import Integration from "./integration";
import icons from "../icons";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MetaTags from "react-meta-tags";

// let moment = require('moment');
import moment from "moment";

const CustomAppBar = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.graderWarning,
    // padding: theme.spacing(2),
    color: theme.palette.common.white,
    flexGrow: 1,
    borderRadius: 0,
    justifyContent: "center",
  },
}))(AppBar);
const CustomWarningIcon = withStyles((theme) => ({
  root: {
    color: theme.palette.common.white,
  },
}))(icons.Warning);

class GraderComponents extends React.PureComponent {
  componentDidMount() {
    if (window.lxrOptions.graderReportId == undefined)
      window.lxrOptions = {
        ...window.lxrOptions,
        graderReportId: null,
      };
    this.props.getGoogleAccountPerformance(window.lxrOptions.graderReportId);
  }

  render() {
    const { integrationProgressBar, impressionsGraphData, graderData } =
      this.props;
    return (
      <div>
        <MetaTags>
          <title>
            {" "}
            Google Ads Grader Tool | Google Adwords Tool | LXRGuide
          </title>
          <meta
            name="description"
            content="Use our Google Ad Graders Tool to optimize your web presence with scores on your target impression share and more. We show you how to increase conversions with enhanced cpc."
          />
        </MetaTags>
        {graderData.isLoading ? (
          <Integration />
        ) : (
          <Fragment>
            {graderData &&
            moment(graderData.registrationDate).isBefore(
              moment().format("YYYY-MM-DD")
            ) ? (
              <CustomAppBar position="static">
                <Toolbar style={{ justifyContent: "center" }}>
                  {icons.Warning}&nbsp;
                  <Typography variant="p" color="inherit" align={"center"}>
                    Your Google Ads Grader report is outdated.
                    <a
                      style={{ color: "#fff" }}
                      target="_blank"
                      href={"/adwords-grader.html"}
                    >
                      Please rerun the report for the most current results.
                    </a>
                  </Typography>
                </Toolbar>
              </CustomAppBar>
            ) : null}
            <Overview />
            <OpportunityScore />
            <AccountTracking
              data={this.props.graderData}
              impressionsGraphData={impressionsGraphData}
            />
            <LxrGuideInAction />
          </Fragment>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getGoogleAccountPerformance: (graderReportId) =>
    dispatch(getGoogleAccountPerformance(graderReportId)),
});
const mapStateToProps = (state) => ({
  graderData: state.googleAdsPerformance,
  integrationProgressBar: state.googleAdsPerformance.integrationProgressBar,
  impressionsGraphData: state.googleAdsPerformance.googleAdsReportData
    ? state.googleAdsPerformance.googleAdsReportData.accImpDataMap
    : null,
});

GraderComponents.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps)(GraderComponents);
