import React from "react";
import { CompactCardContent } from "../cards";
import {
  CustomCard,
  IconText,
  getLastDates,
  MainSectionCardHeader,
} from "./templates";
import { ZOOM_LINK } from "../../actions/actionTypes";
import { updateSelectedDate, bookGraderMeeting } from "../../actions/index";
import CustomDialog from "../dialog-generic";
import * as _ from "lodash";
import {
  CardHeader,
  Grid,
  CardContent,
  Card,
  CardActionArea,
  Typography,
  CardActions,
} from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import icons from "../icons";
import { OutLinedOrangeBtn } from "../buttons";
import { connect } from "react-redux";

export const CustomCardHeader = withStyles((theme) => ({
  root: {
    background: theme.palette.common.white,
    padding: 7,
  },
  title: {
    fontSize: 12,
    fontWeight: "normal",
  },
  subheader: {
    fontSize: "16px",
    color: theme.palette.text.primary,
  },
  action: {
    marginRight: "unset",
  },
}))(CardHeader);
export const CustomCardActions = withStyles((theme) => ({
  root: {
    justifyContent: "center",
    background: theme.palette.graderBg,
  },
}))(CardActions);
export const TimeDateTypography = withStyles((theme) => ({
  root: {
    color: theme.palette.graderBg.text,
    fontFamily: "Montserrat",
  },
  h2: {
    color: theme.palette.graderBg.text,
    fontFamily: "Montserrat",
  },
  h5: {
    color: theme.palette.graderBg.text,
    fontFamily: "Montserrat",
  },
}))(Typography);
const LxrGuideInActionStyles = (theme) => ({
  selectedDate: {
    border: "2px solid #F7931E",
  },
  unSelectedDates: {
    border: "2px solid #FFFFFF",
  },
  card: {
    borderRadius: 4,
    boxShadow: "unset",
  },
  CardContent: {
    padding: 3,
    background: theme.palette.graderBg,
  },
  my: {
    color: theme.palette.graderGraphColors.text,
    fontFamily: "Montserrat",
  },
  timeDate: {
    color: theme.palette.graderGraphColors.text,
    fontFamily: "Montserrat",
    "& > p": {
      color: theme.palette.graderGraphColors.text,
      fontFamily: "Montserrat",
    },
  },
  title: {
    color: theme.palette.graderGraphColors.text,
    fontFamily: "Montserrat",
  },
  selectedCalIcon: {
    color: theme.palette.orangeColor,
  },
});

class LxrGuideInAction extends React.PureComponent {
  constructor(props) {
    super(props);
    this.lastDates = getLastDates();
    this.state = {
      dialog: false,
      isAccountLevelDetailsUpdated: false,
    };
  }

  componentDidMount() {
    this.props.updateSelectedDate(
      _.head(_.values(_.pickBy(this.lastDates, _.identity))).date
    );
  }

  handleSelectedDate = (selectedDate) => {
    this.props.updateSelectedDate(selectedDate);
  };
  handleBookMeeting = async () => {
    const { selectedDate, accountLevelGraderUserId } = this.props;
    const response = await this.props.bookGraderMeeting(
      selectedDate,
      accountLevelGraderUserId
    );
    this.setState({
      dialog: true,
    });
    if (response && response.isAccountLevelDetailsUpdated == true) {
      this.setState({
        isAccountLevelDetailsUpdated: true,
      });
    } else {
      this.setState({
        isAccountLevelDetailsUpdated: false,
      });
    }
  };
  handleClose = () => {
    this.setState({
      dialog: false,
    });
  };

  render() {
    const { classes, selectedDate } = this.props;
    console.log("lastDates=", this.lastDates);
    console.log("selectedDate=", selectedDate);
    return (
      <div>
        <CustomCard>
          <MainSectionCardHeader
            style={{ paddingBottom: 0 }}
            title={"See LXRGuide in action"}
            subheader={
              "Get answers to your questions, and find out why LXRGUIDE is right choice for your business."
            }
          />
          <CompactCardContent>
            <Grid
              container
              spacing={2}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Grid item md={9}>
                <Grid container spacing={2}>
                  {this.lastDates &&
                    this.lastDates.map((date, i) => (
                      <Grid item md={2} key={i}>
                        <Card
                          key={i}
                          className={[
                            classes.card,
                            date.date == selectedDate
                              ? classes.selectedDate
                              : classes.unSelectedDates,
                          ]}
                        >
                          <CardActionArea
                            onClick={() => this.handleSelectedDate(date.date)}
                            disabled={
                              this.state.isAccountLevelDetailsUpdated == true
                            }
                          >
                            <CustomCardHeader
                              title={
                                <Grid
                                  container
                                  justifyContent={"space-between"}
                                >
                                  <Grid item className={classes.my}>
                                    {date.month}
                                  </Grid>
                                  <Grid item className={classes.my}>
                                    {date.year}
                                  </Grid>
                                </Grid>
                              }
                            />
                            <CardContent className={classes.CardContent}>
                              <Typography
                                variant="h5"
                                align={"center"}
                                component="h2"
                              >
                                <b className={classes.timeDate}>{date.day}</b>
                              </Typography>
                              <Typography
                                component={"p"}
                                align={"center"}
                                className={classes.timeDate}
                              >
                                {date.dayText}
                              </Typography>
                            </CardContent>
                            <CustomCardActions style={{ padding: "2px 4px" }}>
                              <Typography>
                                <IconText
                                  icon={
                                    date.date == selectedDate ? (
                                      <span className={classes.selectedCalIcon}>
                                        {icons.calenderChecked}
                                      </span>
                                    ) : null
                                  }
                                  text={<b>11:30 AM ET </b>}
                                  classes={classes}
                                />
                              </Typography>
                            </CustomCardActions>
                          </CardActionArea>
                        </Card>
                      </Grid>
                    ))}
                </Grid>
              </Grid>
              <Grid item md={3}>
                {this.state.isAccountLevelDetailsUpdated == true ? (
                  <Typography component={"p"}>
                    Thanks for taking the time. Looks like you are free on{" "}
                    {selectedDate}, 11:30 AM ET to meet us. Please save this
                    link <a href={ZOOM_LINK}>{ZOOM_LINK}</a> and let’s catch up
                    soon.
                  </Typography>
                ) : null}
                <OutLinedOrangeBtn
                  disabled={
                    this.state.isAccountLevelDetailsUpdated == true ||
                    selectedDate == null
                  }
                  variant="outlined"
                  onClick={this.handleBookMeeting}
                  className={"fullWidth"}
                  color="primary"
                >
                  Book Your Demo
                </OutLinedOrangeBtn>
              </Grid>
            </Grid>
          </CompactCardContent>
        </CustomCard>
        <CustomDialog
          open={this.state.dialog}
          content={
            this.state.isAccountLevelDetailsUpdated == true ? (
              <Typography component={"p"}>
                Our Team will contact you shortly.
              </Typography>
            ) : (
              <Typography component={"p"}>
                Something Went Wrong, Please Try again.
              </Typography>
            )
          }
          okBtn
          handleAction={this.handleClose}
          titleColor={"Notification"}
        />
      </div>
    );
  }
}

LxrGuideInAction.propTypes = {};
const mapStateToProps = (state) => ({
  selectedDate: state.googleAdsPerformance.selectedDate,
  accountLevelGraderUserId: state.googleAdsPerformance.graderUserId,
  lastDates: state.googleAdsPerformance.lastDates,
});
const mapDispatchToProps = (dispatch) => ({
  updateSelectedDate: (selectedDate) =>
    dispatch(updateSelectedDate(selectedDate)),
  bookGraderMeeting: (selctedDate, accountLevelGraderUserId) =>
    dispatch(bookGraderMeeting(selctedDate, accountLevelGraderUserId)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(LxrGuideInActionStyles)(LxrGuideInAction));
