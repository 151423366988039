import React from 'react';
import { Card, CardContent, Grid, Typography } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import {getDomainInfo} from "../actions";
import {connect} from "react-redux";
import siteGraderBg from "../assets/img/site-grader-bg.jpg";
import SixMetrics from "../components/siteGrader/six-metrics";
import {GreenText} from "../components/typography";
import SeoSiteGraderHomePage from "../pages/seo-site-grader-home-page.jsx";
import ClientTestimonial from "../components/siteGrader/ClientTestimonial";
import {updateDomainInfoFromSession} from "../actions/index";


const styles = theme => ({
    cards: {
        maxWidth: 250, textAlign: "center", boxShadow: "unset"
    },
    cardContent: {
        padding: 0,
        paddingBottom: 0
    },
    form: {
        minHeight: "11vh"
    },
    title: {
        fontFamily: "Montserrat",
        color: theme.palette.orangeColor
    },
    graderBg: {
        backgroundImage: "url(" + siteGraderBg + ")",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        [theme.breakpoints.down('xl')]: {
            backgroundSize: "auto",
        },
        [theme.breakpoints.up('sm')]: {
            backgroundSize: "cover",
        },

    },
    paddingZero: {
        padding: 0,
    },
    errorTypo: {
        paddingTop: 10
    },
    testimonialTitle: {
        fontFamily: "Montserrat", fontWeight: 500, fontSize: "2.8rem",
        color: theme.palette.orangeColor,
        [theme.breakpoints.down('xl')]: {
            fontSize: "1.8rem",
        },
    },
    centerAlign: {
        width: "50%",
        margin: "0 auto",
    },
    head: {
        fontSize: "3rem",
        [theme.breakpoints.down('xl')]: {
            fontSize: "2.15rem",
        },
    }

});


class SiteGraderHome extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            domainName: "",
            domainNameError: "",
            errorMsg: (window.lxrOptions && window.lxrOptions.errorMsg) ? window.lxrOptions.errorMsg : null,
        }
    }


    render() {
        const {classes} = this.props;
        const {urlInfoLoading} = this.props.siteGrader;
        return (
            <div>
                <SeoSiteGraderHomePage/>
                <Card>
                    <CardContent style={{paddingBottom: 0}} className={classes.paddingZero}>
                        <Typography className={classes.testimonialTitle} variant={"h3"} component={"h3"}
                                    color="textSecondary"
                                    align={"center"} gutterBottom>
                            <b>YOU ARE IN A GOOD COMPANY.</b>
                        </Typography>
                        {/*<Typography component={"p"} align={"center"} className={classes.centerAlign} gutterBottom>
                            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
                            laudantium, totam rem aperiam.
                        </Typography>*/}
                        <ClientTestimonial classes={classes}/>
                        <br/>
                        <Grid item md={12}>
                            <br/>
                            <br/>
                            <Typography className={classes.head}
                                // variant={"h3"} component={"h3"}
                                        align={"center"}
                                        gutterBottom style={{color: "#F58120"}}>
                                {/*<GreenText>The six factors</GreenText> that our SEO Grader examines to calculate your
                                score*/}
                                <b>The Six Factors That Our SEO Grader Examines To Calculate Your
                                    Score</b>
                            </Typography>
                            <SixMetrics/>
                        </Grid>
                    </CardContent>
                </Card>
            </div>

        );
    }
}

SiteGraderHome.propTypes = {};
const mapStateToProps = state => ({
    siteGrader: state.siteGrader,
});


export default connect(mapStateToProps, null)(withStyles(styles)(SiteGraderHome));
