import React, { Fragment } from "react";
import {
  Card,
  Chip,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import { CompactCardContent, SubSectionCardHeader } from "../cards";
import NumericLabel from "react-pretty-numbers";
import { LightCaption } from "../../components/typography.jsx";
import { KpiString, kpiLabel } from "../../selectors/dashboard-selectors";
import CurrencySymbol from "../../components/currencySymbol.jsx";

import icons from "../icons";

const RectChip = withStyles((theme) => ({
  root: {
    borderRadius: 0,
    height: 24,
    color: theme.palette.text.primary,
    background: "rgba(30, 51, 78, 0.1)",
    padding: 2,
  },
  label: {
    paddingLeft: 6,
    paddingRight: 6,
  },
}))(Chip);

const CustomLinearProgress = withStyles((theme) => ({
  root: {
    height: 8,
  },
  bar: {
    backgroundColor: theme.palette.orangeColor,
  },
}))(LinearProgress);

const CustomGrid = withStyles((theme) => ({
  item: {
    color: theme.palette.text.primary,
  },
}))(Grid);

function RunRateGraph({
  icon,
  label,
  current,
  target,
  currency,
  currencySymbol,
}) {
  const formatOptions = {
    commafy: true,
    shortFormatMinValue: 9999,
    shortFormatPrecision: 0,
    precision: 0,
    shortFormat: true,
    locales: "en-US",
    currency: true,
    wholenumber: "ceil",
    currencyIndicator: "USD",
    cssClass: ["inline"],
  };

  const numericValue = (value, isCurrency) =>
    isCurrency ? (
      <CurrencySymbol formatOptions={formatOptions}>{value}</CurrencySymbol>
    ) : (
      value
    );
  let Icon = icons[icon];
  return (
    <Grid item>
      <Grid container justifyContent={"space-between"} alignItems={"flex-end"}>
        <CustomGrid item>
          <Typography variant={"caption"} color={"inherit"}>
          {Icon}&nbsp;&nbsp;&nbsp;{label}
          </Typography>
        </CustomGrid>
        <CustomGrid item></CustomGrid>
        <CustomGrid item>
          <Typography variant={"caption"} color={"inherit"}>
            <span style={{ fontWeight: "900" }}>
              {numericValue(current, currency)} /
            </span>{" "}
            {numericValue(target, currency)}
          </Typography>
        </CustomGrid>
      </Grid>
      <CustomLinearProgress
        variant="determinate"
        value={Math.min((current / target) * 100, 100)}
      />
    </Grid>
  );
}

function CurrentMonthPerformance({
  currentMonthRunRate: month,
  budget,
  revenueTarget,
  orderTarget,
  kpiType,
  kpiTarget,
  currencySymbol,
}) {
  return (
    <Grid container className={"fullHeight"} direction={"column"}>
      <Grid item>
        <Grid container spacing={2} justifyContent={"flex-end"}>
          <Grid item id="KPIPerformance">
            <RectChip
              label={
                <Fragment>
                  <LightCaption>Current {KpiString[kpiType]}</LightCaption>
                  &nbsp;&nbsp;
                  <Typography>
                    {!isNaN(month.kpiValue.toFixed(2))
                      ? month.kpiValue.toFixed(2)
                      : (0).toFixed(2)}
                  </Typography>
                </Fragment>
              }
            />
          </Grid>
          <Grid item>
            <RectChip
              label={
                <Fragment>
                  <LightCaption>Target {KpiString[kpiType]}</LightCaption>
                  &nbsp;&nbsp;
                  <Typography>{kpiTarget.toFixed(2)}</Typography>
                </Fragment>
              }
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={"growable"}>
        <Card className={"fitParent"}>
          <SubSectionCardHeader
            title={`${month.currentMonth} Performance`}
            action={
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                title={
                  "Here’s a look at your performance for the month. On the left you’ll see your actual spend and revenue compared to your goals on the right."
                }
              ></i>
            }
          />
          <CompactCardContent>
            <Grid container direction={"column"} spacing={2}>
              {kpiType === "CPO" || kpiType === "CPL" ? (
                <RunRateGraph
                  icon={"Orders"}
                  label={kpiLabel[kpiType]}
                  current={month.orders}
                  target={orderTarget}
                  currencySymbol={currencySymbol}
                  currency={true}
                />
              ) : (
                <RunRateGraph
                  icon={"Revenue"}
                  label={"Revenue"}
                  current={month.revenue}
                  target={revenueTarget}
                  currencySymbol={currencySymbol}
                  currency={true}
                />
              )}
              <RunRateGraph
                icon={"Spend"}
                label={"Spend"}
                current={month.spend}
                target={budget}
                currencySymbol={currencySymbol}
                currency={true}
              />
              <RunRateGraph
                icon={"Calendar"}
                label={"Days"}
                current={month.days}
                target={month.daysInMonth}
                currencySymbol={currencySymbol}
                currency={false}
              />
            </Grid>
          </CompactCardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default CurrentMonthPerformance;
