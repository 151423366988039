import * as React from "react";

function SvgSquare(props) {
    return (
        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="10" height="10" rx="2" fill={props.fill}/>
        </svg>
    );
}

export default SvgSquare;
