import imageBg from "../../img/home_bg.svg";

const leadGenerationStyles = (theme) => ({
  card: {
    boxShadow: "unset",
  },
  gridBg: {
    background: `url(${imageBg})`,
    [theme.breakpoints.up("md")]: {
      backgroundSize: "contain",
      backgroundPosition: "top center",
      backgroundColor: theme.palette.common.darkGreybg,
    },
    [theme.breakpoints.up("xl")]: {
      backgroundRepeat: "no-repeat",
    },
    [theme.breakpoints.down('xl')]: {
      background: `unset`,
      backgroundColor: theme.palette.common.darkGreybg,
    },
  },
  sectionBg: {
    backgroundColor: theme.palette.common.darkGreybg,
    height: 610,
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('xl')]: {
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(4),
      paddingLeft: theme.spacing(2),
      height: 550,
    },
    [theme.breakpoints.between("sm", 'xl')]: {
      height: 525,
    },
  },
  sectionMobile: {
    [theme.breakpoints.down('xl')]: {
      height: 860,
    },
  },
  cardContent: {
    padding: 0,
    paddingBottom: 0,
  },
  lastSection: {
    height: "40vh",
    // backgroundColor: theme.palette.common.liteGrey
  },
});

export default leadGenerationStyles;
