import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Grid, Hidden, Input, Typography } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import {
  ErrorSmallTypography,
  ErrorTypography,
} from "../../components/typography";
import { ExternalOrangeBtnNew } from "../../components/buttons";
import Snackbar from "../../components/snackbars";
import BrandDialog from "../../components/dashboard/brand-dialog";
import icons from "../../components/icons";
import SafeAndSecure from "../../components/safe-and-secure";
import { connect } from "react-redux";
import { saveLeadGeneration } from "../../actions/leadGenerationAction.js";
import * as types from "../../actions/actionTypes";
import Checkbox from "@mui/material/Checkbox";
import Warning from "@mui/icons-material/Warning";
import FormControlLabel from "@mui/material/FormControlLabel";
import leadGenerationFormStyles from "../../assets/jss/leadGeneration/leadGenerationFormStyles";
import { FormGroup } from "@mui/material";
import { withRouter } from "react-router";
import routes from "../../NewInterfacePages/routes";

const PlainInput = withStyles((theme) => ({
  root: {
    // marginBottom: 20
  },
  underline: {
    "&::before": {
      borderBottom: "1px solid " + theme.palette.text.darkGrey,
    },
    "&:hover": {
      borderBottom: "1px solid " + theme.palette.orangeColor,
    },
    "&:after": {
      borderBottom: "2px solid " + theme.palette.orangeColor,
    },
  },
}))(Input);

const CustomFormControlLabel = withStyles((theme) => ({
  label: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: "bold",
    fontFamily: "Lato",
  },
}))(FormControlLabel);

function Condition(props) {
  return (
    <Fragment>
      <FormGroup row>
        <CustomFormControlLabel
          control={
            <Checkbox
              checked={props.checked}
              onChange={props.onChange}
              value="isGoogleActive"
              // disabled={}
            />
          }
          label={"I have active Google Ads (Adwords) account"}
        />
      </FormGroup>
      <br />
      <br />
    </Fragment>
  );
}

Condition.propTypes = {
  checked: PropTypes.any,
  onChange: PropTypes.func,
};

function FormButton(props) {
  return (
    <div className={props.classes.buttonH}>
      <ExternalOrangeBtnNew
        className={props.classes.btn}
        type="submit"
        size="medium"
        variant="contained"
        color="primary"
        fullWidth={true}
      >
        {/*{icons.signup}&nbsp;*/}Get Your Free Scorecard
      </ExternalOrangeBtnNew>
    </div>
  );
}

FormButton.propTypes = { classes: PropTypes.any };

function Qoute(props) {
  return (
    <Fragment>
      {props.googleActive ? (
        <div className={props.classes.conditionBg}>
          <Typography variant={"body1"}>
            <b>Test Drive LXRGuide for Free for 14-days</b>
          </Typography>
          <Typography variant={"body2"}>
            Start with a Free Health Check of your Google Ads campaign. Get the
            Free 14-day Test-Drive link on the Grader Results Page.{" "}
          </Typography>
        </div>
      ) : null}
    </Fragment>
  );
}

Qoute.propTypes = {
  googleActive: PropTypes.any,
  classes: PropTypes.any,
};

class leadGenerationForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      emailId: "",
      emailIdError: "",
      website: "",
      websiteError: "",
      eCommercePlatform: props.eCommercePlatform,
      isGoogleActive: false,
    };
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };
  handleChangeCheckBox = () => {
    this.setState({ isGoogleActive: !this.state.isGoogleActive });
  };
  validate = () => {
    let isError = false;
    const errors = {};
    errors.errorMsg = null;
    if (window.lxrOptions && window.lxrOptions.errorMsg) {
      window.lxrOptions.errorMsg = "";
    }

    if (this.state.emailId.trim().length == 0) {
      isError = true;
      errors.emailIdError = "This field is required";
    } else if (this.state.emailId.trim().length > 0) {
      let result = this.state.emailId
        .trim()
        .match(
          /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
        );
      if (!result) {
        isError = true;
        errors.emailIdError = "Please enter valid email";
      } else {
        errors.emailIdError = "";
      }
    } else {
      errors.emailIdError = "";
    }
    const UrlRegex =
      /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
    if (
      this.state.website.trim().length == 0 ||
      this.state.website.trim() == ""
    ) {
      isError = true;
      errors.websiteError = "This field is required";
    } else if (
      this.state.website.trim().length != 0 &&
      !UrlRegex.test(this.state.website.trim())
    ) {
      isError = true;
      errors.websiteError = "URL Format Wrong";
    } else {
      errors.websiteError = "";
    }

    this.setState({
      ...this.state,
      ...errors,
    });
    return isError;
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const { history } = this.props;
    const { emailId, website, isGoogleActive, eCommercePlatform } = this.state;
    let error;
    error = this.validate();
    if (!error) {
      await this.props.leadGenFormSubmit(
        emailId,
        website,
        isGoogleActive,
        eCommercePlatform
      );
      if (this.state.isGoogleActive) {
        history.push(routes.googleAdsGrader);
      } else {
        history.push(routes.seoSiteGrader);
      }
    }
  };

  render() {
    const { classes, bottomUi } = this.props;
    const { isGoogleActive } = this.state;
    console.log("this.state=", this.state);
    return (
      <div className={bottomUi ? classes.bottomP : null}>
        <form onSubmit={this.handleSubmit}>
          <Grid
            container
            spacing={bottomUi ? 40 : 0}
            justifyContent={bottomUi ? "center" : "unset"}
          >
            <Grid
              item
              sm={12}
              xs={12}
              md={bottomUi ? 3 : 12}
              className={classes.gridHeight}
            >
              <PlainInput
                placeholder="Your Email..."
                className={classes.input}
                onChange={this.handleChange("emailId")}
                inputProps={{
                  "aria-label": "Description",
                }}
                fullWidth={true}
                error={this.state.emailIdError != ""}
                helperText={this.state.emailIdError}
                style={{ color: "#333333" }}
              />
              {this.state.emailIdError !== "" ? (
                <ErrorSmallTypography>
                  <b>{this.state.emailIdError}</b>
                </ErrorSmallTypography>
              ) : (
                ""
              )}
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={bottomUi ? 3 : 12}
              className={classes.gridHeight}
            >
              <PlainInput
                placeholder="Website..."
                className={classes.input}
                onChange={this.handleChange("website")}
                inputProps={{
                  "aria-label": "Description",
                }}
                fullWidth={true}
                error={this.state.websiteError != ""}
                helperText={this.state.websiteError}
                style={{ color: "#333333" }}
              />
              {this.state.websiteError !== "" ? (
                <ErrorSmallTypography>
                  <b>{this.state.websiteError}</b>
                </ErrorSmallTypography>
              ) : (
                ""
              )}
            </Grid>

            {bottomUi ? (
              <Grid item sm={12} xs={12} md={5}>
                <Grid Container>
                  <Grid item>
                    <Condition
                      checked={this.state.isGoogleActive}
                      onChange={this.handleChangeCheckBox}
                    />
                    <FormButton classes={classes} />
                    <Qoute googleActive={isGoogleActive} classes={classes} />
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Fragment>
                <Grid item sm={12} xs={12} md={bottomUi ? 6 : 12}>
                  <Condition
                    checked={this.state.isGoogleActive}
                    onChange={this.handleChangeCheckBox}
                  />
                </Grid>
                <Grid item sm={12} xs={12} md={bottomUi ? 4 : 12}>
                  <FormButton classes={classes} />
                </Grid>
                <Grid item md={12}>
                  <Qoute googleActive={isGoogleActive} classes={classes} />
                </Grid>
              </Fragment>
            )}
          </Grid>
        </form>
      </div>
    );
  }
}

leadGenerationForm.propTypes = {};
const mapDispatchToProps = (dispatch) => ({
  leadGenFormSubmit: (emailId, website, isGoogleActive, eCommercePlatform) => {
    return dispatch(
      saveLeadGeneration(emailId, website, isGoogleActive, eCommercePlatform)
    );
  },
  leadGenFormSubmitTrack: (eCommercePlatform) =>
    dispatch({ type: types.LEAD_GEN_TRACK, eCommercePlatform }),
});

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(withStyles(leadGenerationFormStyles)(leadGenerationForm))
);
