import React, { useState, useEffect, createContext, useContext } from "react";
import { connect } from "react-redux";
import "./jumpstart.css";
import { JsSectionTypography } from "../../components/typography";
import {
  Button,
  Grid,
  Input,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { JsAddUserLabelTypography } from "../../components/typography";
import "./jumpstart.css";
import {
  JsBlackContainedBtn,
  JsOrangeContainedBtn,
} from "../../components/buttons";

function JsAddCampaign(props) {
  const {
    campaignName,
    campaignType,
    dailyBudget,
    currencyCode,
    currencySymbol,
    handleCloseIcon,
    handleAddNewCampaignData,
    handleCampaignTypeChange,
    handleDailyBudgetChange,
    handleDoneButton,
    handleCampaignNameChange,
    storeUpdatedCampaignDataReducer,
    isEditMode,
    heading,
    campaignId,
  } = props;

  const [newCampaignName, setNewCampaignName] = useState();
  const [newCampaignType, setNewCampaignType] = useState("SEARCH");
  const [newDailyBudget, setNewDailyBudget] = useState();
  const handleNewCampaignNameChange = (e) => {
    setNewCampaignName(e.target.value);
  };
  const handleNewCampaignTypeChange = (e) => {
    setNewCampaignType(e.target.value);
  };
  const handleNewDailyBudgetChange = (e) => {
    if (e.target.value.match(/[^0-9]/)) {
      e.preventDefault();
    }
    setNewDailyBudget(e.target.value);
  };

  useEffect(() => {
    if (isEditMode == true) {
      setNewCampaignName(campaignName);
      setNewCampaignType(campaignType);
      setNewDailyBudget(dailyBudget);
    }
  }, [isEditMode]);

  const handleButtonClick = async () => {
    handleDoneButton(
      campaignId,
      newCampaignName,
      newCampaignType,
      newDailyBudget,
      0
    );
  };

  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        style={{ padding: "2%", borderBottom: "1px solid #E6E6E6" }}
      >
        <Grid item xs={11}>
          <JsSectionTypography style={{ fontSize: "1rem" }}>
            {heading}
          </JsSectionTypography>
        </Grid>
        <Grid item xs={1}>
          <IconButton aria-label="close" onClick={handleCloseIcon}>
          <CloseIcon color="secondary" />
          </IconButton>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        columnSpacing={2}
        rowSpacing={2}
        style={{ padding: "2%" }}
      >
        <Grid item xs={12}>
          <label htmlFor="campaignName">
            <JsAddUserLabelTypography>
              <span className="numberContainer">1</span>Type a Campaign Name
            </JsAddUserLabelTypography>
          </label>
        </Grid>
        <div style={{ width: "100%", paddingLeft: "7%" }}>
          <TextField
            id="campaignName"
            placeholder="Type your text here"
            variant="outlined"
            hiddenLabel
            size="small"
            value={newCampaignName}
            onChange={handleNewCampaignNameChange}
            fullWidth
            sx={{   [`& .MuiOutlinedInput-root`]: {
              fontSize: "0.875rem",
            },}}
          />
        </div>
        <Grid item xs={6}>
          <label htmlFor="campaignType">
            <JsAddUserLabelTypography>
              <span className="numberContainer">2</span>Select Campaign Type
            </JsAddUserLabelTypography>
          </label>
        </Grid>
        <Grid item xs={6}>
          <JsAddUserLabelTypography>
            <span className="numberContainer">3</span>Daily Campaign Budget
          </JsAddUserLabelTypography>
        </Grid>
        <div style={{ width: "50%", paddingLeft: "7%" }}>
          <Select
            value={newCampaignType}
            className="campaignType"
            onChange={handleNewCampaignTypeChange}
          >
            <MenuItem
              value="DISPLAY"
              disabled
              style={{ color: "#A4A4A4", fontSize: "0.875rem" }}
            >
              Display
            </MenuItem>
            <MenuItem
              value="DISCOVERY"
              disabled
              style={{ color: "#A4A4A4", fontSize: "0.875rem" }}
            >
              Discovery
            </MenuItem>
            <MenuItem
              value="PERFORMANCE_MAX"
              disabled
              style={{ color: "#A4A4A4", fontSize: "0.875rem" }}
            >
              Performance Max
            </MenuItem>
            <MenuItem value="SEARCH" style={{ fontSize: "0.875rem" }}>
              Search
            </MenuItem>
          </Select>
        </div>

        <div style={{ width: "50%", paddingLeft: "7%" }}>
          <TextField
            type="number"
            size="small"
            onChange={handleNewDailyBudgetChange}
            id="budget"
            placeholder="0.00"
            sx={{
              [`& .MuiOutlinedInput-root`]: {
                borderRadius: "none",
                height: "0",
                fontSize: "0.875rem",
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  sx={{
                    [`& .MuiTypography-root`]: {
                      color: "#A4A4A4",
                    },
                  }}
                >
                  {currencyCode}({currencySymbol})
                </InputAdornment>
              ),
            }}
            value={newDailyBudget}
          />
        </div>
        <Grid
          item
          xs={12}
          container
          direction="row"
          justifyContent="flex-end"
          style={{
            padding: "2% 1%",
            position: "absolute",
            bottom: "2px",
            background: "#F8F9F9",
          }}
        >
          <Grid item>
            <JsOrangeContainedBtn
              onClick={() => handleButtonClick()}
              disabled={
                newCampaignName == undefined ||
                newCampaignName == "" ||
                newDailyBudget == undefined ||
                newDailyBudget == "" ||
                newDailyBudget <= 0
              }
              style={{ float: "right", fontSize: "0.875rem" }}
            >
              {isEditMode ? "Update" : "Done"}
            </JsOrangeContainedBtn>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = (state) => ({
  storeUpdatedCampaignDataReducer: state.storeUpdatedCampaignDataReducer,
});
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(JsAddCampaign);
