import React from "react";
import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid";
import {
  OrangeTextSize12,
  GrayText,
  BlueTitleTypography,
  HeadLineTypography,
  CatalinaBlue,
} from "../../components/typography";
import icons from "../../components/icons";
import priesterspecan from "../../assets/img/priesterspecan.png";
import Carousel from "react-material-ui-carousel";
import { Paper } from "@mui/material";
import { Typography } from "@mui/material/index";

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="xl" />;

const style = (theme) => ({
  root: {
    [theme.breakpoints.up("md")]: {
      height: "539",
    },
    [theme.breakpoints.up("xs")]: {
      textAlign: "center",
    },
  },
  Headline: {
    [theme.breakpoints.up("xs")]: {
      textAlign: "initial",
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 10,
    },
    [theme.breakpoints.up("md")]: {
      textAlign: "initial",
      paddingTop: 233,
      paddingBottom: 304,
      paddingLeft: 115,
    },
  },

  CatalinaBlueStyling: {
    textAlign: "center",
    fontSize: 17,
  },
  borderSpacing: {
    [theme.breakpoints.up("xs")]: {
      paddingTop: 10,
      paddingBottom: 10,
      paddingRight: 10,
      paddingLeft: 10,
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: 76,
      paddingBottom: 61,
      paddingRight: 84,
    },
  },
  InsideBorderStyling: {
    borderStyle: "solid",
    borderColor: "#A2B6C3",
    borderRadius: 10,
    borderWidth: 1,
    paddingTop: 58,
    paddingLeft: 20,
    paddingRight: 10,
    height: 539,
    textAlign: "center",
  },
  priesterspecanstyle: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  ownerstyle: {
    fontSize: 14,
  },
  activeIndicator: {
    color: theme.palette.orangeColor,
  },
  heading: {
    color: "textPrimary",
    fontWeight: "700",
    textTransform: "uppercase",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      fontSize: "1.25rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2.75rem",
      textAlign: "left",
    },
  },
});

function Item(props) {
  const { classes, item } = props;
  return (
    <div className={classes.InsideBorderStyling}>
      <div>{item.ownerimage}</div>
      <CatalinaBlue className={classes.CatalinaBlueStyling}>
        <b>{item.name}</b> <br />
        <div className={classes.ownerstyle}>Owner</div>
      </CatalinaBlue>
      <br />

      <CatalinaBlue className={classes.CatalinaBlueStyling}>
        {item.description}
        <br />
        <div className={classes.priesterspecanstyle}>
          {item.clientbrandicon}
        </div>
      </CatalinaBlue>
    </div>
  );
}

function Testimonial(props) {
  const { width, classes } = props;
  var items = [
    {
      ownerimage: icons.thomesEllis,
      name: "Thomas Ellis",
      description:
        "I feel like LXRGuide brought to Priester Pecans– a tool that we would not have available to us, otherwise – a powerful tool to manage our AdWords campaign. It is a big advantage to us as a small-medium business. We are able to see the beginning results and looking forward to seeing more to come.",
      clientbrandicon: icons.priesterspecan,
    },
    {
      ownerimage: icons.coffeecowowner,
      name: "Gary Giberson",
      description:
        "I am glad to see such growth in my results. Moreover, I believe once my account gets all the tender love and care it needs, I will see even greater results. I am very optimistic about LXRGuide and the impact it will have on my revenue in the future.",
      clientbrandicon: icons.coffeecowlogo,
    },
  ];
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item className={classes.Headline} md={6} xs={12}>
          <OrangeTextSize12 gutterBottom>TESTIMONIAL</OrangeTextSize12>
          <Typography
            className={classes.heading}
            // color={"textPrimary"}
            // align={width == "sm" || width == "xs" ? "center" : "left"}
            // variant={width == "sm" || width == "xs" ? "h3" : "subtitle1"}
            // style={{ fontWeight: "700", textTransform: "uppercase" }}
          >
            What They Say About
            <br />
            Our Company?
          </Typography>
        </Grid>
        <Grid item md={6} xs={12} className={classes.borderSpacing}>
          <Carousel activeIndicatorProps={{ style: { color: "F58120" } }}>
            {items.map((item, i) => (
              <Item key={i} classes={classes} item={item} />
            ))}
          </Carousel>
        </Grid>
      </Grid>
    </div>
  );
}

export default withWidth()(withStyles(style)(Testimonial));
