import React, { Fragment,useEffect } from "react";
import { Button, Card, Grid, Typography } from "@mui/material";
import Task from "./task.jsx";
import { CompactCardContent, MainSectionCardHeader } from "../cards";
import withStyles from "@mui/styles/withStyles";
import { connect } from "react-redux";
import { sortTasks } from "../../reducers/common";
import CircularProgress from "@mui/material/CircularProgress";
import icons from "../icons";

const TaskHeader = withStyles((theme) => ({
  root: {
    paddingLeft: 25,
    paddingRight: 25,
    paddingBottom: 5,
  },
}))(Typography);

const TaskHeaderInside = withStyles((theme) => ({
  root: {
    backgroundColor: "rgba(30, 51, 78, 0.05)",
    padding: 4,
  },
}))(MainSectionCardHeader);
const NoTasks = withStyles((theme) => ({
  root: {
    fontWeight: "lighter",
  },
}))(Typography);

const CustomCard = withStyles((theme) => ({
  root: {
    boxShadow: "none",
  },
}))(Card);
const TaskGridContainer = withStyles((theme) => ({
  container: {
    minHeight: "20vw",
  },
}))(Grid);
// const TaskCards = (tasks) =>{
//     return(
//         <div>
//             {tasks.map((task, i) =>
//                 <Grid key={i} item md={3}>
//                     <Task task={task} KPIType={KPIType}/>
//                 </Grid>)
//             }
//         </div>
//     )
// }
// const NoTaskDetails = () =>{
//     return(
//             <Grid item style={{margin: "auto"}}>
//                 <NoTasks className={"textGradient"} variant="display1" align={"center"}><b>HI !</b></NoTasks>
//                 <NoTasks className={"textGradient"} variant={"h5"} align={"center"}>There are no tasks for this account.</NoTasks>
//             </Grid>
//     )
// }

function Tasks(props) {
  const { tasks, KPIType, isLoading, theme } = props;
  useEffect(() => {
  window.scrollTo(0, 0);
}, []);
  console.log("tasksprops-", props);
  const { taskAnalysis } = props;
  console.log("taskanalysis123=", taskAnalysis);
  console.log("taskAnalysisIsLoading=", taskAnalysis.taskAnalysisIsLoading);
  return (
    <CustomCard id="taskSection">
      {taskAnalysis.taskAnalysisIsLoading == true ? (
        <CircularProgress />
      ) : (
        <div>
          <TaskHeader component={"div"}>
            <TaskHeaderInside
              title={"TASKS"}
              subheader={"Optimize your account by performing tasks below"}
            />
          </TaskHeader>
          <CompactCardContent>
            <TaskGridContainer
              container
              spacing={2}
              justifyContent={"flex-start"}
            >
              {isLoading ? (
                <CircularProgress />
              ) : tasks.length != 0 ? (
                tasks.map((task, i) => (
                  <Grid key={i} item md={3} style={{ marginBottom: 8 }}>
                    <Task task={task} KPIType={KPIType} />
                  </Grid>
                ))
              ) : (
                <Grid item style={{ margin: "auto" }}>
                  <NoTasks
                    className={"textGradient"}
                    variant="display1"
                    align={"center"}
                  >
                    <b>HI !</b>
                  </NoTasks>
                  <NoTasks
                    className={"textGradient"}
                    variant={"h5"}
                    align={"center"}
                  >
                    There are no tasks for this account.
                  </NoTasks>
                </Grid>
              )}
            </TaskGridContainer>
          </CompactCardContent>
        </div>
      )}
    </CustomCard>
  );
}

export const mapStateToProps = (state) => {
  return {
    tasks: sortTasks(state.groupedTaskData.TaskType, state.taskDataById),
    KPIType: state.perf.kpiType,
    currency: state.perf.currency,
    isLoading: state.perf.isLoading,
    taskAnalysis: state.taskAnalysis,
  };
};

// noinspection JSUnusedLocalSymbols
export const mapDispatchToProps = (dispatch, ownProps) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Tasks);
