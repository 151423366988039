import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';
import withStyles from '@mui/styles/withStyles';
import * as _ from 'lodash';
let moment = require('moment');

class ImpressionGraph extends React.PureComponent {
    render() {
        console.log("impressionsGraphData=", this.props.impressionsGraphData);
        /*const data = [
            {
                day: moment("2019-06-22").unix(), impressions: 3888, fill: "#4CAF50"
            },
            {
                day: moment("2019-06-23").unix(), impressions: 2222, fill: "#4CAF50"
            },
            {
                day: moment("2019-06-24").unix(), impressions: 4666, fill: "#4CAF50"
            },
            {
                day: moment("2019-06-25").unix(), impressions: 5555, fill: "#4CAF50"
            },
            {
                day: moment("2019-06-26").unix(), impressions: 2345, fill: "#4CAF50"
            },
            {
                day: moment("2019-06-27").unix(), impressions: 3124, fill: "#4CAF50"
            },
            {
                day: moment("2019-06-28").unix(), impressions: 3245, fill: "#4CAF50"
            },
            {
                day: moment("2019-06-29").unix(), impressions: 3245, fill: "#4CAF50"
            },
            {
                day: moment("2019-06-30").unix(), impressions: 2312, fill: "#4CAF50"
            },
            {
                day: moment("2019-07-01").unix(), impressions: 3450, fill: "#4CAF50"
            },
            {
                day: moment("2019-07-02").unix(), impressions: 3425, fill: "#4CAF50"
            },
            {
                day: moment("2019-07-03").unix(), impressions: 3256, fill: "#4CAF50"
            },
            {
                day: moment("2019-07-04").unix(), impressions: 3456, fill: "#4CAF50"
            },
            {
                day: moment("2019-07-05").unix(), impressions: 3243, fill: "#4CAF50"
            },
            {
                day: moment("2019-07-06").unix(), impressions: 4000, fill: "#4CAF50"
            },
            {
                day: moment("2019-07-07").unix(), impressions: 4000, fill: "#4CAF50"
            },
            {
                day: moment("2019-07-08").unix(), impressions: 4000, fill: "#4CAF50"
            },
            {
                day: moment("2019-07-09").unix(), impressions: 4000, fill: "#4CAF50"
            },
            {
                day: moment("2019-07-10").unix(), impressions: 3000, fill: "#4CAF50"
            },
            {
                day: moment("2019-07-11").unix(), impressions: 2000, fill: "#4CAF50"
            },
            {
                day: moment("2019-07-12").unix(), impressions: 2780, fill: "#4CAF50"
            },
            {
                day: moment("2019-07-13").unix(), impressions: 1890, fill: "#4CAF50"
            },
            {
                day: moment("2019-07-10").unix(), impressions: 2390, fill: "#4CAF50"
            },
            {
                day: moment("2019-07-14").unix(), impressions: 3490, fill: "#4CAF50"
            },
            {
                day: moment("2019-07-15").unix(), impressions: 4000, fill: "#4CAF50"
            },
            {
                day: moment("2019-07-16").unix(), impressions: 3000, fill: "#4CAF50"
            },
            {
                day: moment("2019-07-17").unix(), impressions: 2000, fill: "#4CAF50"
            },
            {
                day: moment("2019-07-18").unix(), impressions: 2780, fill: "#4CAF50"
            },
            {
                day: moment("2019-07-19").unix(), impressions: 1890, fill: "#4CAF50"
            },
            {
                day: moment("2019-07-20").unix(), impressions: 1890, fill: "#4CAF50"
            },
            {
                day: moment("2019-07-21").unix(), impressions: 4500, fill: "#4CAF50"
            },
            {
                day: moment("2019-07-22").unix(), impressions: 4000, fill: "#4CAF50"
            },
        ];*/
        const formatLabel = (label) => moment.unix(label).format("MMM-D");
        const graphData = (impressionsGraphData) => {
            return _.map(impressionsGraphData, (value, key) => ({
                "Impressions": value,
                "day": moment(key).unix()
            }));
        };
        return (
            <div style={{paddingTop:25}}>
                <BarChart
                    width={500}
                    height={180}
                    data={this.props.impressionsGraphData ? graphData(this.props.impressionsGraphData) : []}
                    /*margin={{
                        top: 5, right: 30, left: 20, bottom: 5,
                    }}*/
                    fill={"#4CAF50"}
                    barSize={2}
                    barCategoryGap={10}
                >
                    <XAxis dataKey="day" interval={"preserveStartEnd"}
                           type={'number'}
                           domain={['dataMin', 'dataMax']}
                           tick={{style: {fontSize: 12}}}
                           tickFormatter={formatLabel}
                           height={1}
                    />

                    <Tooltip labelFormatter={formatLabel}/>
                    <Bar dataKey="Impressions"/>
                </BarChart>
            </div>
        );
    }
}

ImpressionGraph.propTypes = {};

export default ImpressionGraph;
