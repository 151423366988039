export const adCopyHierarchyStyles = (theme) => ({
  headerLink: {
    color: theme.palette.blueLink,
    fontWeight: "normal",
    fontSize: 15,
    // fontSize: 18
  },
  urlPath: {
    fontWeight: "normal",
    fontFamily: "Lato",
    color: "#1C1C1C",
    fontSize: 14,
  },
  description: {
    fontWeight: "normal",
    color: "#4D5156",
    fontSize: 12,
  },
  displayContainer: {
    padding: theme.spacing(2),
  },
  paper: {
    border: "1px solid " + theme.palette.common.border,
    boxShadow: "4px 4px 18px rgba(0, 0, 0, 0.06)",
    borderRadius: 5,
  },
});

export function getDomainUrl(refAdCopy) {
  let domainUrl = "Domain Url";
  try {
    if (
      refAdCopy &&
      refAdCopy.creativeFinalUrls &&
      refAdCopy.creativeFinalUrls != "" &&
      refAdCopy.creativeFinalUrls != DB_EMPTY_CHECK
    ) {
      domainUrl = new URL(refAdCopy.creativeFinalUrls).origin;
    } else if (
      refAdCopy &&
      refAdCopy.finalUrl != "" &&
      refAdCopy.finalUrl != DB_EMPTY_CHECK
    ) {
      domainUrl = new URL(refAdCopy.finalUrl).origin;
    }
  } catch (e) {
    domainUrl =
      refAdCopy &&
      refAdCopy.creativeFinalUrls != "" &&
      refAdCopy.creativeFinalUrls != " --"
        ? refAdCopy.creativeFinalUrls
        : "Domain Url";
    console.log("error in getting domain url error=", e);
  }
  return domainUrl;
  // return (refAdCopy && refAdCopy.creativeFinalUrls != "" && refAdCopy.creativeFinalUrls != " --") ? new URL(refAdCopy.creativeFinalUrls).origin : "Domain Url";
}

export const DB_EMPTY_CHECK = " --";
