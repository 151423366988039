import React, { Fragment } from "react";
import { FormGroup, FormControlLabel, Checkbox, Grid, Button, Typography } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import AdCopyHierarchyFrom from "./adCopy-hierarchy-from";
import AdCopyPreview from "./adCopy-hierarchy-preview";
import { connect } from "react-redux";
import { idToTaskDataList } from "../../../reducers/common";
import {
  addAdCopyMiniService,
  toggleSelection,
  toggleSelectionForServicePack,
} from "../../../actions";
import { getDomainUrl } from "../adcopyHierarchyTask/templates";
import { BlueBtn, NewBlueBtn } from "../../buttons";
import { withRouter } from "react-router";

const refAdCopyAPIDataFilter = (refAdCopy) => {
  console.log("refAdCopy helo=", refAdCopy);
  if (refAdCopy == null) {
    return;
  }
  if (refAdCopy.headline && refAdCopy.headline != "") {
    refAdCopy.headlinePart1 = refAdCopy.headline;
  }
  if (refAdCopy.line1 && refAdCopy.line1 != "") {
    refAdCopy.description = refAdCopy.line1;
  }
  if (refAdCopy.displayUrl && refAdCopy.displayUrl != "") {
    console.log("refAdCopy.displayUr", refAdCopy.displayUrl);
    let pathName = "";
    try {
      pathName = new URL(refAdCopy.displayUrl).pathname;
    } catch (e) {
      console.log("error in getting display url", e);
    }
    console.log("pathName", pathName);
    if (pathName != "/") {
      const removedFirstSlashUrl = pathName.startsWith("/")
        ? pathName.substring(1, pathName.length)
        : pathName;
      refAdCopy.path1 =
        removedFirstSlashUrl.indexOf("/") >= 0
          ? removedFirstSlashUrl.substring(0, removedFirstSlashUrl.indexOf("/"))
          : removedFirstSlashUrl;
      refAdCopy.path2 =
        removedFirstSlashUrl.indexOf("/") >= 0
          ? removedFirstSlashUrl.substring(
              removedFirstSlashUrl.indexOf("/") + 1
            )
          : removedFirstSlashUrl;
    } else {
      refAdCopy.path1 = "";
      refAdCopy.path2 = "";
    }
  }
  console.log("refAdCopy=", refAdCopy);
  return refAdCopy;
};

const CustomCheckbox = withStyles((theme) => ({
  root: {
    color: theme.palette.checkedStatus,
    "&$checked": {
      color: theme.palette.checkedStatus,
    },
  },
  checked: {},
}))(Checkbox);

class AdCopyHierarchy extends React.PureComponent {
  state = {
    servicePack: false,
    DIY: false,
  };

  componentDidMount() {
    if (
      this.props.selectedServicePacks[this.props.taskData.taskDataId] &&
      this.props.selectedServicePacks[this.props.taskData.taskDataId]
        .selected == true
    ) {
      this.setState({ servicePack: true, DIY: false });
    }
  }

  handleChange = (name) => async (event) => {
    const { taskDataId, campaignName, adGroupName } = this.props.taskData;
    this.setState({ [name]: event.target.checked });
    if (name == "servicePack") {
      await this.setState({ servicePack: !this.state.servicePack, DIY: false });
    } else {
      await this.setState({ servicePack: false, DIY: !this.state.DIY });
    }
    this.props.toggleSelection([taskDataId], {
      taskDataId: taskDataId,
      campaignName: campaignName,
      adGroupName: adGroupName,
      selected: this.state.servicePack,
    });
  };
  handleFromPreview = (data) => {
    this.setState({ fromPreview: data });
  };
  handleSelected = async () => {
    const { taskDataId, campaignName, adGroupName } = this.props.taskData;
    const {
      toggleSelection,
      addAdCopyMiniServices,
      history,
      miniServiceItemCount,
    } = this.props;
    await toggleSelection([taskDataId], {
      taskDataId: taskDataId,
      campaignName: campaignName,
      adGroupName: adGroupName,
      // selected: this.state.servicePack
      selected: true,
    });
    console.log("miniServiceItemCount=", miniServiceItemCount);
    await addAdCopyMiniServices();
    history.push("/add-adcopy-service/3");
    /*await addAdCopyMiniServices();
        history.push("/add-adcopy-service/3");*/
  };

  render() {
    var refAdCopy = this.props.taskData.refAdCopy
      ? this.props.taskData.refAdCopy
      : null;
    refAdCopy = refAdCopyAPIDataFilter(refAdCopy);
    console.log("this.props.taskData=", this.props.taskData);
    console.log(
      "this.props.selectedServicePacks=",
      this.props.selectedServicePacks
    );
    const { fromPreview } = this.state;
    const { taskData, miniServiceItemCount, toggleSelectionForServicePack } =
      this.props;
    console.log("taskData=", taskData);
    const headline1 =
      fromPreview && fromPreview.headLine1
        ? fromPreview.headLine1
        : "Headline1";
    const headline2 =
      fromPreview && fromPreview.headLine2
        ? fromPreview.headLine2
        : "Headline2";
    const headline3 =
      fromPreview && fromPreview.headLine3 ? fromPreview.headLine3 : "";
    const path1 =
      fromPreview && fromPreview.path1 ? fromPreview.path1 : "Path1";
    const path2 =
      fromPreview && fromPreview.path2 ? fromPreview.path2 : "Path2";
    const domainUrl = getDomainUrl(refAdCopy);
    const description1 =
      fromPreview && fromPreview.description1
        ? fromPreview.description1
        : "Description";
    const description2 =
      fromPreview && fromPreview.description2 ? fromPreview.description2 : "";
    console.log("miniServiceItemCoun1t=", miniServiceItemCount);
    console.log(
      "toggleSelectionForServicePack=",
      toggleSelectionForServicePack
    );

    return (
      <div className={"fullWidth"}>
        <Fragment>
          <Grid container spacing={5}>
            <Grid item md={6}>
              <br />
              <AdCopyHierarchyFrom
                formObj={(data) => this.handleFromPreview(data)}
                taskData={this.props.taskData}
                domainUrl={domainUrl}
              />
            </Grid>
            <Grid item md={6}>
              <AdCopyPreview
                headLine1={headline1}
                headLine2={headline2}
                headLine3={headline3}
                domainUrl={domainUrl}
                path1={path1}
                path2={path2}
                description1={description1}
                description2={description2}
                headLine={"Your Ad"}
              />
              {refAdCopy != null ? (
                <AdCopyPreview
                  headLine1={refAdCopy.headlinePart1}
                  headLine2={refAdCopy.headlinePart2}
                  domainUrl={domainUrl}
                  path1={refAdCopy.path1}
                  path2={refAdCopy.path2}
                  description1={refAdCopy.description}
                  headLine={"Reference Ad"}
                />
              ) : null}
              <Typography
                color={"textPrimary"}
                variant={"body2"}
                style={{ fontWeight: "500", padding: 16 }}
              >
                Need help in writing a new Ad copy? Our content team can help
                you write a fresh and unique ad.
              </Typography>
              <NewBlueBtn
                type={"button"}
                variant={"contained"}
                size={"large"}
                onClick={this.handleSelected}
              >
                Request Service
              </NewBlueBtn>
            </Grid>
          </Grid>
        </Fragment>
      </div>
    );
  }
}

AdCopyHierarchy.propTypes = {};
/*const mapStateToProps = (state, ownProps) => ({

});*/
const mapDispatchToProps = (dispatch) => ({
  toggleSelection: (taskDataId, selected) =>
    dispatch(toggleSelectionForServicePack(taskDataId, selected)),
  addAdCopyMiniServices: () => dispatch(addAdCopyMiniService()),
});
const mapStateToProps = (state, ownProps) => ({
  selectedServicePacks: state.selectedServicePackBytTaskDataIds,
  miniServiceItemCount: state.miniServiceItemCount,
});

const _AdCopyHierarchy = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdCopyHierarchy);
export default withRouter(_AdCopyHierarchy);
