const headerBarStyles = theme => ({
    root: {
        flexGrow: 1,
    },
    flex: {
        flex: 1,
    },
    menuButton: {
        marginLeft: -12,
        // marginRight: 20,
    },

    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
    sideList: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    app_bar: {
        backgroundColor: "#FFF",
        // padding:"0 4rem"
        boxShadow: "unset",
        [theme.breakpoints.down('xl')]: {
            padding: 0
        },
        [theme.breakpoints.up('sm')]: {
            padding: "0px 50px 0px 50px"
        }
    },
    Button: {
        "&,&:focus,&:hover": {
            background: "#FFFFFF",
            borderRadius: "3",
            border: "0",
            color: "#333333",
            height: "48",
            padding: "0 30px",
            margin: "0rem .75rem 0rem .75rem",
            boxShadow: "0 3px 5px 2px rgba(0, 0, 0, 0.1)",
        }
    },
    BrandLogo: {
        width: 340,
        height: 42,
        paddingBottom: 7,
        [theme.breakpoints.down('xl')]: {
            width: "100%",
            height:38,
        }
    },
    menuButton: {
        color: "#333333"
    },
    hyperlink: {
        "&:focus": {
            outlineStyle: "none"
        }
    },
    a: {
        textDecoration: "none",
        // fontFamily: "Lato",
        fontFamily: "Montserrat",
    },
    banner: {
        backgroundColor: "#F58120",
        height: 40,
        color: "#FFF",
        textAlign:"center",
        position:"fixed",
        width:'100%',
        zIndex:"999999",
        top:0,
        display:"flex",
        justifyContent:"center",
        alignItems: "center",
        fontFamily: "Montserrat",
        fontWeight:"bold",
        left: 0,
    },
    lxrguideBold: {
        /*cursor: "pointer",*/
        color:"#fff",
        fontSize:"0.9rem",

}, covidBannerHead: {
        display: "flex",
        height: "7.5rem",
        alignItems: "flex-end",
    }

});
export default headerBarStyles;
