import React, {Fragment} from "react";
import {Grid, TextField, Typography} from "@mui/material";
import InputMask from "react-input-mask";
import MenuItem from "@mui/material/MenuItem";

export function getSteps() {
    return ['Account Details', 'Payment', 'Review'];
}

export function getStepsForPackagesView() {
    return ['Packages', 'Account Details', 'Payment', 'Review'];
}

export function getCardType(cardNo) {
    // let cardNo = creditCardNo.replace(/-|\s/g, "");
    let type = "unknown";
    if (/^5[1-5][0-9]{14}$/.test(cardNo)) {
        type = "mastercard";
    } else if (/^4[0-9]{12}(?:[0-9]{3})?$/.test(cardNo)) {
        type = "visa";
    } else if (/^3[47][0-9]{13}$/.test(cardNo)) {
        type = "amex";
    } else if (/^(?:2131|1800|35\d{3})\d{11}$/.test(cardNo)) {
        type = "jcb";
    } else if (/^3(?:0[0-5]|[68][0-9])[0-9]{11}$/.test(cardNo)) {
        type = "dinnersclub";
    } else if (/^6(?:011|5[0-9]{2})[0-9]{12}$/.test(cardNo)) {
        type = "discover";
    } else if (/^(5018|5020|5038|6304|6759|6761|6763)[0-9]{8,15}$/.test(cardNo)) {
        type = "maestro";
    }
    console.log("type=", type);
    return type;
}

export function getStepsForSEODashboardView() {
    return ['PURCHASED', 'PROGRESS', 'DELIVERED', 'ACCEPTED'];
}


