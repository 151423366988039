import React, { Component } from "react";
import PropTypes from "prop-types";
import { Grid, Typography, Paper } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { IconPara, IconText, IconMainText } from "./templates";
import icons from "../../components/icons";

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="xl" />;

class Mobile extends React.PureComponent {
  render() {
    const { classes, siteGrader, width } = this.props;
    const mobileFriendly =
      siteGrader &&
      siteGrader.mobile != null &&
      siteGrader.mobile.mobileFriendly
        ? siteGrader.mobile.mobileFriendly
        : false;
    const mobileViewPort =
      siteGrader &&
      siteGrader.mobile != null &&
      siteGrader.mobile.mobileViewPort
        ? siteGrader.mobile.mobileViewPort
        : false;

    console.log("mobileFriendly=", mobileFriendly);
    console.log("mobileViewPort=", mobileViewPort);

    return (
      <Paper
        className={classes.paper}
        style={{ maxHeight: width == "sm" || width == "xs" ? "100vh" : "70vh" }}
      >
        <Typography className={classes.lato} color={"textPrimary"}>
          <b>
            <IconMainText text={"Mobile"} classes={classes} />
          </b>
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} alignItems={"left"}>
            <div className={classes.gridItem}>
              <br />
              <IconText
                text={"Mobile Friendly"}
                classes={classes}
                mobile={mobileFriendly}
                padding={true}
              />
              <div className={classes.sec1}>
                {mobileFriendly == true ? (
                  <IconPara
                    icon={icons.mobileFriendly}
                    title={"Responsive Design"}
                    text={
                      "Google recognizes your site\n" +
                      "as having a mobile friendly\n" +
                      "responsive design."
                    }
                    classes={classes}
                    padding={true}
                    des={
                      "Google recommends a responsive website design pattern. A responsive website design automatically changes to fit the device you're reading it on and provides a seamless user experience."
                    }
                    mobile={mobileFriendly}
                  />
                ) : (
                  <IconPara
                    icon={icons.mobileFriendlyNot}
                    title={"Not optimized"}
                    text={
                      "Your site does not have a responsive design as per Google's API."
                    }
                    classes={classes}
                    padding={true}
                    des={
                      "Google recommends a responsive website design pattern. A responsive website design automatically changes to fit the device you're reading it on and provides a seamless user experience."
                    }
                    mobile={mobileFriendly}
                  />
                )}
              </div>
            </div>
            <br />
            <div className={classes.gridItem}>
              <IconText
                className={classes.sec1}
                text={"Mobile Viewport"}
                padding={true}
                classes={classes}
                mobile={mobileViewPort}
              />

              <div className={classes.sec1}>
                {mobileViewPort == true ? (
                  <IconPara
                    icon={icons.mobileViewPort}
                    title={"Your site has a meta viewport tag set"}
                    text={
                      "Google recognizes your site\n" +
                      "as having a mobile friendly\n" +
                      "responsive design."
                    }
                    classes={classes}
                    padding={true}
                    des={
                      "The meta viewpoint tag lets you control your page width and scale on different devices types."
                    }
                    mobile={mobileViewPort}
                  />
                ) : (
                  <IconPara
                    icon={icons.mobileViewPortNot}
                    title={"Not optimized"}
                    text={"Your site does not have a meta viewport tag set"}
                    classes={classes}
                    padding={true}
                    des={
                      "The meta viewport tag lets you control your page width and scale on different devices types."
                    }
                    mobile={mobileViewPort}
                  />
                )}
              </div>
            </div>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

const styles = (theme) => ({
  lato: {
    fontFamily: "Lato",
    fontSize: "14px",
  },
  title: {
    color: theme.palette.text.primary,
    fontFamily: "Lato",
    fontWeight: "bold",
    fontSize: 16,
  },
  iconG: {
    color: theme.palette.greenCheck,
    fontSize: 24,
  },
  iconR: {
    color: theme.palette.redText,
    fontSize: 24,
  },
  titleGreen: {
    color: theme.palette.textGreen,
  },
  titleRed: {
    color: theme.palette.redText,
  },
  titlePara: {
    // color: theme.palette.textGreen,
    fontFamily: "Lato",
    fontWeight: "bold",
  },
  para: {
    fontFamily: "Lato",
    fontWeight: "400",
    color: theme.palette.text.primary,
    opacity: 0.5,
    fontSize: 14,
  },
  des: {
    fontFamily: "Lato",
    fontWeight: "400",
    color: theme.palette.text.primary,
    opacity: 0.5,
    fontSize: 14,
  },
  paper: {
    boxShadow: "unset",
    padding: theme.spacing(1),
    height: "34em",
  },
  gridItem: {
    // paddingLeft: theme.spacing(2)
    // paddingTop:20
  },
  sec1: {
    paddingLeft: theme.spacing(2),
  },
});
Mobile.propTypes = {};

export default withWidth()(withStyles(styles)(Mobile));
