import React, { Fragment } from "react";
import {
  CircularProgress,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import PerformanceGraphStyles from "../../assets/jss/reports/reportsStyles";
import CommonStyles from "../../assets/jss/reports/commonStyles";
import icons from "../../components/icons";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Card } from "@mui/material/index";
import * as _ from "lodash";
import getSymbolFromCurrency from "currency-symbol-map";
import * as PropTypes from "prop-types";

export const yAxisFormatter = (eachScaleNumber) => {
  return new Intl.NumberFormat("en-US", {}).format(eachScaleNumber);
};

export const renderTooltipContent = (o, currency) => {
  const { payload, label } = o;
  const total = payload.reduce((result, entry) => result + entry.value, 0);
  return (
    <div
      className="customized-tooltip-content"
      style={{
        backgroundColor: "#ffffff",
        padding: 5,
        border: "1px solid #c6adad",
      }}
    >
      <p style={{ margin: 5 }}>{`${label}`}</p>
      {payload.map((entry, index) => (
        <p
          key={`item-${index}`}
          style={{
            color: entry.color,
            margin: 5,
          }}
        >{`${entry.name} : ${getSymbolFromCurrency(
          currency
        )}${entry.value.toLocaleString()}`}</p>
      ))}
    </div>
  );
};

export function IsLoading(props) {
  return (
    <Grid item sm={12} xs={12} md={12} className={props.classes.loadingDiv}>
      <CircularProgress color={"secondary"} />
    </Grid>
  );
}

IsLoading.propTypes = { classes: PropTypes.any };

export const yAxisFontSize = 12;
export const graphSpacing = { top: 5, right: 20, left: 10, bottom: 5 };

function PerformanceGraph(props) {
  const {
    width,
    classes,
    data,
    kpiType,
    isLoading,
    theme,
    tabValue,
    handleChange,
    handleReportSent,
    handleDownloadReport,
    handleRefs,
    currency,
  } = props;
  
  let metric = "revenue";
  if (kpiType != null && kpiType != undefined) {
    if (kpiType == "RC" || kpiType == "CR") {
      metric = "revenue";
    } else if (kpiType == "CPL") {
      metric = "leads";
    } else {
      metric = "orders";
    }
  }

  // const formatLabel = (value, name, prop) => {
  //     console.log("label=",value)
  //     console.log("payload=",name)
  //     console.log("payload1=",prop)
  // }
  // value, name, props) => ( return "formatted value" )
  return (
    <Paper className={classes.paper}>
      <Grid
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        style={{ background: "#ffffff" }}
      >
        <Grid item sm={6} xs={6}>
          <Typography color={"textPrimary"} variant={"h3"}>
            <b>Performance Graph</b>
          </Typography>
        </Grid>
        {isLoading ? (
          <IsLoading classes={classes} />
        ) : (
          <Fragment>
            {_.isEmpty(data) || (data && data.data.length == 0) ? (
              <Grid item md={12}>
                <Typography
                  align={"center"}
                  color={"textPrimary"}
                  variant={"body1"}
                >
                  No data to display
                </Typography>
              </Grid>
            ) : (
              <Fragment>
                <Grid item>
                  <Typography align={"right"}>
                    <span
                      className={classes.icons}
                      onClick={() => handleReportSent("account")}
                      id={"download-plane"}
                    >
                      {icons.plane}
                    </span>
                    &nbsp; &nbsp; &nbsp;
                    <span
                      className={classes.icons}
                      id={"download-report"}
                      onClick={(event) =>
                        handleDownloadReport("account", event)
                      }
                    >
                      {icons.download}
                    </span>
                  </Typography>
                </Grid>
                <Grid item sm={12} xs={12} md={12}>
                  <Grid container alignItems={"center"}>
                    <Grid item sm={6} xs={6} md={6}>
                      <Grid container alignItems={"center"} spacing={2}>
                        <Grid item className={classes.legM}>
                          {icons.square(theme.palette.common.lightBlue)}
                        </Grid>
                        <Grid item>
                          <Typography
                            variant={"body2"}
                            color={"textPrimary"}
                            style={{ fontWeight: "500" }}
                          >
                            PPC Spend &nbsp;&nbsp;&nbsp;&nbsp;
                          </Typography>
                        </Grid>
                        <Grid item className={classes.legM}>
                          {icons.square(theme.palette.secondary.main)}
                        </Grid>
                        <Grid item>
                          <Typography
                            variant={"body2"}
                            color={"textPrimary"}
                            style={{
                              fontWeight: "500",
                              textTransform: "capitalize",
                            }}
                          >
                            {metric}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      sm={6}
                      xs={6}
                      md={6}
                      className={"flex justifyEnd"}
                    >
                      <Tabs
                        value={tabValue}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons={false}
                        classes={{ indicator: classes.indicator }}
                      >
                        <Tab
                          className={classes.tab}
                          classes={{
                            label: tabValue == 0 ? classes.selectedTab : null,
                            labelContainer: classes.labelContainer,
                          }}
                          label={`D`}
                          disableRipple
                        />
                        <Tab
                          className={classes.tab}
                          classes={{
                            label: tabValue == 1 ? classes.selectedTab : null,
                            labelContainer: classes.labelContainer,
                          }}
                          label={`w`}
                          disableRipple
                        />
                        <Tab
                          className={classes.tab}
                          classes={{
                            label: tabValue == 2 ? classes.selectedTab : null,
                            labelContainer: classes.labelContainer,
                          }}
                          label={`M`}
                          disableRipple
                        />
                      </Tabs>
                    </Grid>
                  </Grid>
                  <br />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Card className={classes.card}>
                    <ResponsiveContainer width="100%" height={360}>
                      <ComposedChart
                        data={data.data}
                        ref={(ref) => ref}
                        margin={graphSpacing}
                      >
                        <CartesianGrid strokeDasharray="0 0" vertical={false} />
                        <XAxis
                          dataKey="date"
                          tick={{ fontSize: yAxisFontSize }}
                        />
                        <YAxis
                          yAxisId="left"
                          axisLine={false}
                          tickFormatter={yAxisFormatter}
                          // width={100}
                          label={{
                            value: "PPC Spend",
                            angle: -90,
                            position: "insideLeft",
                          }}
                          tick={{ fontSize: yAxisFontSize }}
                        />
                        <YAxis
                          yAxisId="right"
                          orientation="right"
                          axisLine={false}
                          dataKey={metric == "revenue" ? "revenue" : metric}
                          name={metric == "revenue" ? "revenue" : metric}
                          tickFormatter={yAxisFormatter}
                          label={{
                            value: metric == "revenue" ? "Revenue" : metric,
                            angle: -90,
                            position: "right",
                          }}
                          // tickLine={{ stroke: 'red' }}
                          // width={70}
                          tick={{ fontSize: yAxisFontSize }}
                        />
                        {!(
                          _.isEmpty(data) ||
                          (data && data.data.length == 0)
                        ) ? (
                          <Tooltip
                            content={(props) =>
                              renderTooltipContent(props, currency)
                            }
                          />
                        ) : null}
                        <Bar
                          yAxisId="left"
                          name="PPC Spend"
                          dataKey="cost"
                          fill="#1098F7"
                        />
                        <Line
                          yAxisId="right"
                          type="monotone"
                          strokeWidth={2}
                          name={metric == "revenue" ? "Revenue" : metric}
                          dataKey={metric == "revenue" ? "revenue" : metric}
                          stroke="#F58120"
                        />
                      </ComposedChart>
                    </ResponsiveContainer>
                  </Card>
                </Grid>
              </Fragment>
            )}
          </Fragment>
        )}
      </Grid>
    </Paper>
  );
}

export default withStyles(CommonStyles, { withTheme: true })(PerformanceGraph);
