const leadGenerationFormStyles = (theme) => ({
  cards: {
    maxWidth: 250,
    textAlign: "center",
    boxShadow: "unset",
  },
  cardContent: {
    padding: 0,
    paddingBottom: 0,
  },
  hyperText: {
    color: theme.palette.orangeColor,
  },
  buttonH: {
    height: "10vh",
    // [theme.breakpoints.down('md')]: {
    //     height: "12vh",
    // },
    [theme.breakpoints.down('xl')]: {
      height: "9.5vh",
    },
    [theme.breakpoints.between("sm", 'xl')]: {
      height: "6.5vh",
    },
  },
  btn: {
    [theme.breakpoints.down('xl')]: {
      fontSize: 12,
      padding: "18px 45px",
    },
  },
  fromH: {
    minHeight: "11vh",
    [theme.breakpoints.down('xl')]: {
      minHeight: "6vh",
    },
  },
  gridHeight: {
    height: 50,
  },
  conditionBg: {
    backgroundColor: theme.palette.common.whiteGrey,
    padding: 5,
  },
  bottomP: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
});
export default leadGenerationFormStyles;
