import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { JsSectionTypography } from "../../components/typography";
import { styled } from "@mui/system";
import CustomTabs from "./customTabs";
import "./jumpstart.css";

function JsAnalyticsHeader(props) {
  const {handleTabChange,currentTabIndex,campaignFrameworkAccountsLength,campaignStructureAccountsLength} = props;
  
  const createLabel = (name, length,index, selectedIndex) => (
    <Grid container direction="row" alignItems="center" justifyContent="space-between">
      <Grid item>
        {name}
      </Grid>
      <Grid item style={{background: index == selectedIndex ? "#FFF4EB" : "#EFEFEF" , padding: "5px 11px" , borderRadius: "45%"}}>
        {length}
      </Grid>
    </Grid>
  );

  const tabs = [
    { label: createLabel("Campaign Framework",campaignFrameworkAccountsLength,0,currentTabIndex) },
    { label: createLabel("Campaign Structure",campaignStructureAccountsLength,1, currentTabIndex) },
  ];


  return (
    <div className="stickyHeader">
      <Grid container direction="row" rowSpacing={2} alignItems="center">
        <Grid item xs={12}>
          <JsSectionTypography>Account Management</JsSectionTypography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle2" style={{ color: "#474747", paddingRight:"5px" }}>
            Account Stage:
          </Typography>
        </Grid>
        <Grid item xs={12} sm={5} md={5} lg={5} xl={4} >
          <CustomTabs
            variant="fullWidth"
            tabs={tabs}
            currentTabIndex={currentTabIndex}
            handleTabChange={handleTabChange}
            tabcolor="#838383"
            disabled={null}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default JsAnalyticsHeader;
