import React from "react";
import { Card, CardContent, Grid, Hidden, Input, Typography } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import { connect } from "react-redux";
import { saveGraderDetails } from "../../actions";
import { ErrorTypography, ErrorSmallTypography } from "../typography";
import Snackbar from "../snackbars";
import { OrangeBtnNew } from "../buttons";
import icons from "../icons";
import BrandDialog from "../dashboard/brand-dialog";
import SafeAndSecure from "../safe-and-secure";
/*
const PlainTextField = withStyles(theme => ({
    marginNormal: {
        marginTop: 8,
        marginBottom: 8,

    },
}))(TextField);*/
const PlainInput = withStyles((theme) => ({
  underline: {
    "&::before": {
      borderBottom: "1px solid " + theme.palette.text.secondary,
    },
    "&:hover": {
      borderBottom: "1px solid #fff",
    },
    "&:after": {
      borderBottom: "2px solid " + theme.palette.orangeColor,
    },
  },
}))(Input);
const CustomTypography = withStyles((theme) => ({
  root: {
    color: theme.palette.dialogText,
  },
}))(Typography);
window.refreshAfterSuccessFromAdwordsGrader = () => {
  window.location = "/import-status-grader.html";
};

/*function refreshAfterSuccessFromAdwordsGrader() {
    window.location = "/import-status-grader.html";
}*/
function InformationCards({ icon, text, classes }) {
  return (
    <Grid item>
      <Card className={classes.cards}>
        <CardContent className={classes.cardContent}>
          {icon}
          <CustomTypography style={{ fontSize: 14 }}>{text}</CustomTypography>
        </CardContent>
      </Card>
    </Grid>
  );
}

async function saveUserDetails() {
  const {
    emailId,
    userName,
    phoneNumber,
    url,
    dateRange,
    customerId,
    monthlySpend,
  } = this.state;

  const response = await this.props.saveGoogleAdDetails(
    emailId,
    userName,
    phoneNumber,
    url,
    dateRange,
    customerId,
    monthlySpend
  );
  if (response && response.error && response.error != null) {
    this.setState({ error: true });
  } else {
    window.open(
      this.state.authorizationUrl,
      "authorizationUrl",
      "menubar=no,status=no,location=no,toolbar=no,scrollbars=yes,resizable=no"
    );
  }
}

const styles = (theme) => ({
  cards: {
    maxWidth: 250,
    textAlign: "center",
    boxShadow: "unset",
  },
  cardContent: {
    padding: 0,
    paddingBottom: 0,
  },
  hyperText: {
    color: theme.palette.orangeColor,
  },
});

class GraderHomePageForm extends React.PureComponent {
  constructor(props) {
    super(props);
    if (window.lxrOptions.authorizationUrl == undefined)
      window.lxrOptions = {
        ...window.lxrOptions,
        authorizationUrl: null,
      };
    this.state = {
      authorizationUrl: window.lxrOptions.authorizationUrl,
      emailId: "",
      userName: "",
      phoneNumber: "",
      url: "",
      dateRange: "1",
      customerId: "",
      monthlySpend: "",
      emailIdError: "",
      error: false,
      safeAndSecureDialog: false,
      errorMsg:
        window.lxrOptions && window.lxrOptions.errorMsg
          ? window.lxrOptions.errorMsg
          : null,
      // counter: 10
    };
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };
  validate = () => {
    let isError = false;
    const errors = {};

    if (this.state.emailId.length == 0) {
      isError = true;
      errors.emailIdError = "This field is required";
    } else if (this.state.emailId.length > 0) {
      let result = this.state.emailId.match(
        /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
      );
      if (!result) {
        isError = true;
        errors.emailIdError = "Please enter valid email";
      } else {
        errors.emailIdError = "";
      }
    } else {
      errors.emailIdError = "";
    }

    if (this.state.termsAndConditions == false) {
      isError = true;
      errors.termsAndConditionsError =
        "You must accept our Terms of Service and Privacy Policy";
    } else {
      errors.termsAndConditionsError = "";
    }

    if (isError) {
      this.setState({
        ...this.state,
        ...errors,
      });
    }
    return isError;
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    let error;
    error = this.validate();
    if (!error) {
      this.setState({ safeAndSecureDialog: true });
      // this.timer = setInterval(this.progress, 1000);
    }
  };
  /*progress = () => {
        const {counter} = this.state;
        console.log("counter=", counter);
        if (counter == 0) {
            this.clearIntervelAndCounter();
            this.handleAction();
        } else {
            this.setState(preState => ({
                counter: preState.counter - 1
            }))
        }
    };*/

  /*clearIntervelAndCounter() {
        clearInterval(this.timer);
        this.setState({counter: 10});
    }*/

  handleClose = () => {
    this.setState({
      error: false,
    });
  };
  handleDialogClose = async () => {
    // this.clearIntervelAndCounter();
    await this.setState({ safeAndSecureDialog: false });
  };
  handleAction = async () => {
    this.handleDialogClose();
    await saveUserDetails.call(this);
  };

  render() {
    // const {authorizationUrl, safeAndSecureDialog, counter} = this.state;
    const { authorizationUrl, safeAndSecureDialog } = this.state;
    const { classes } = this.props;
    console.log("authorizationUrl=", authorizationUrl);
    console.log("emailId=", this.state.emailId);

    const dialogTitle = (
      <div>
        <CustomTypography>
          Redirecting to Google authorization page{" "}
        </CustomTypography>
        <CustomTypography>
          Please follow these steps to get your free Google Ads scorecard
        </CustomTypography>
      </div>
    );

    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <div style={{ minHeight: "11vh" }}>
            <PlainInput
              placeholder="Your Email..."
              className={classes.input}
              onChange={this.handleChange("emailId")}
              inputProps={{
                "aria-label": "Description",
              }}
              fullWidth={true}
              error={this.state.emailIdError !== ""}
              helperText={this.state.emailIdError}
              style={{ color: "#333333" }}
            />
            <Hidden lgDown>
              {" "}
              {this.state.emailIdError !== "" ? (
                <ErrorSmallTypography>
                  <b>{this.state.emailIdError}</b>
                </ErrorSmallTypography>
              ) : (
                ""
              )}
              {this.state.errorMsg != null && this.state.errorMsg !== "" ? (
                <Typography
                  align={"center"}
                  varient={"h6"}
                  component={"h6"}
                  className={classes.errorText}
                >
                  <b>Error: {this.state.errorMsg}</b>
                </Typography>
              ) : null}
            </Hidden>
          </div>
          <Hidden smUp>
            <div>
              {this.state.emailIdError !== "" ? (
                <ErrorTypography>
                  <b>{this.state.emailIdError}</b>
                </ErrorTypography>
              ) : (
                ""
              )}
              {this.state.errorMsg != null && this.state.errorMsg !== "" ? (
                <ErrorTypography
                  align={"center"}
                  varient={"h6"}
                  component={"h6"}
                  className={classes.errorText}
                >
                  <b>Error: {this.state.errorMsg}</b>
                </ErrorTypography>
              ) : null}
            </div>
          </Hidden>
          <div style={{ height: "8vh" }}>
            <OrangeBtnNew
              type="submit"
              size="medium"
              variant="contained"
              color="primary"
              fullWidth={true}
            >
              {icons.signup}&nbsp;Get Your Free Google Ads Scorecard
            </OrangeBtnNew>
          </div>
          <Typography style={{ boxShadow: "black" }} component={"p"}>
            <b>
              Do not have a Google Ads account?{" "}
              <a
                className={classes.hyperText}
                target={"_blank"}
                // href={"/adwords-grader.html#/request-jumpstart"}>Click
                href={"/site-grader.html#/PPC/expert-help"}
              >
                Click Here
              </a>
            </b>
          </Typography>
        </form>
        <Snackbar
          verticalAlign={"bottom"}
          horizontalAlign={"left"}
          open={this.state.snackbarOpen}
          duration={3000}
          handleClose={this.handleClose}
          onClose={this.handleClose}
          variant={"error"}
          message={"Something Went Wrong, Please Try again."}
        />
        <BrandDialog
          title={dialogTitle}
          // open={true}
          open={safeAndSecureDialog}
          handleClose={this.handleDialogClose}
          fullWidth
          safeAndSecure
          handleAction={this.handleAction}
          // counter={counter}
        >
          <Grid container justifyContent={"space-around"}>
            <InformationCards
              icon={icons.block1}
              text={
                <span>
                  Choose the email associated with your{" "}
                  <b>Google Ads account</b>
                </span>
              }
              classes={classes}
            />
            <InformationCards
              icon={icons.block2}
              text={
                "Hit “Allow” (LXRGuide will only use your login info to grade your account)"
              }
              classes={classes}
            />
            <InformationCards
              icon={icons.block3}
              text={"See your results!"}
              classes={classes}
            />
            <Grid item className={"fullWidth"}>
              <SafeAndSecure dialog />
            </Grid>
          </Grid>
        </BrandDialog>
      </div>
    );
  }
}

GraderHomePageForm.propTypes = {};

const mapDispatchToProps = (dispatch) => ({
  saveGoogleAdDetails: (
    emailId,
    userName,
    phoneNumber,
    url,
    dateRange,
    customerId,
    monthlySpend
  ) => {
    return dispatch(
      saveGraderDetails(
        emailId,
        userName,
        phoneNumber,
        url,
        dateRange,
        customerId,
        monthlySpend
      )
    );
  },
});

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(GraderHomePageForm));
