import React from "react";
import PropTypes from "prop-types";
import { Button, Card, CardContent, CardHeader, CircularProgress, Grid, Typography } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import icons from "../components/icons";
import classNames from "classnames";
import * as types from "../actions/actionTypes";
import Hidden from "@mui/material/Hidden";
import { sortTasks } from "../reducers/common";
import { store } from "../reducers/common";
import { connect } from "react-redux";
import * as _ from "lodash";
import { getFreeTrial } from "../actions";
import { ErrorTypography } from "../components/typography";
import CurrencySymbol from "../components/currencySymbol";
import {
  IconAndText,
  PricingPlan,
  TaskDescription,
  MainSectionCardHeader,
} from "../components/freeTrialDashboard/template";

const styles = (theme) => ({
  container: {
    paddingLeft: theme.spacing(3),
  },
  pricingPlanCard: {
    border: "1px solid #4F4F4F",
    boxShadow: "unset",

    [theme.breakpoints.up("sm")]: {
      minHeight: theme.spacing(50),
      maxHeight: theme.spacing(50),
    },
    [theme.breakpoints.down('xl')]: {
      minHeight: theme.spacing(43),
      maxHeight: theme.spacing(43),
    },
  },
  montserratRegular: {
    fontFamily: "Montserrat",
    fontWeight: "500",
  },
  LatoRegular: {
    fontFamily: "Lato",
  },
  expireColor: {
    color: theme.palette.trailText,
  },
  trialText: {
    color: theme.palette.trailText,
    fontFamily: "Montserrat",
    fontWeight: "500",
  },
  txtColor: {
    color: theme.palette.text.secondary,
  },
  whiteColor: {
    color: theme.palette.common.white,
  },
  btnRoot: {
    backgroundColor: theme.palette.text.primary,
    color: theme.palette.common.white,
    textTransform: "uppercase",
    fontWeight: "bold",
    boxShadow: "0px 6px 12px -3px rgba(189,189,189,1)",
    // margin: 10,
    "&:hover": {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.text.primary,
      border: "1px solid" + theme.palette.text.secondary,
    },
  },
  btnHeight: {
    maxHeight: 35,
  },
  taskSection: {
    background: theme.palette.lightgrey,
    padding: 30,
  },
  title: {
    [theme.breakpoints.up("sm")]: {
      fontSize: 20,
    },
    [theme.breakpoints.down('xl')]: {
      fontSize: 18,
    },
  },
  container2: {
    height: "95%",
  },
  flex: {
    display: "flex",
  },
});

class FreeTrialDashboard extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedPlan: 0,
      totalRevenue: null,
      totalOrders: null,
      tasksCount: null,
      isLoading: false,
      error: null,
      currency: "GBP",
    };
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    const response = await this.props.getFreeTrialContent();
    console.log("response=", response);

    if (response) {
      this.setState({ isLoading: false, ...response });
    }
  }

  handlePlan = async (value) => {
    await this.setState({ selectedPlan: value });
    console.log("handlePlan=", this.state.selectedPlan);
  };

  render() {
    const { classes } = this.props;
    const {
      selectedPlan,
      isLoading,
      error,
      totalRevenue,
      totalOrders,
      tasksCount,
      currency,
    } = this.state;
    console.log("store", store.getState());
    const numOptions = {
      commafy: true,
      shortFormatMinValue: 9999,
      shortFormatPrecision: 0,
      precision: 0,
      shortFormat: true,
      justification: "R",
      locales: "en-US",
      currency: true,
      wholenumber: "ceil",
      currencyIndicator: currency,
    };
    const currencyOptions = {
      currency: true,
      wholenumber: "ceil",
      currencyIndicator: "USD",
    };
    return (
      <div>
        <Grid container className={classes.container}>
          <Grid item md={5}>
            <Card style={{ boxShadow: "unset" }}>
              <MainSectionCardHeader
                style={{ paddingBottom: 0 }}
                title={
                  <div>
                    <Typography
                      color={"textSecondary"}
                      className={classNames(
                        classes.title,
                        classes.montserratRegular
                      )}
                    >
                      <b>Thanks for signing up for LXRGuide test drive.</b>
                    </Typography>
                    <Typography
                      color={"textSecondary"}
                      className={classNames(
                        classes.title,
                        classes.montserratRegular
                      )}
                    >
                      <b>Your 14-day free trial of LXRGuide has expired!</b>
                    </Typography>
                  </div>
                }
              />
              <CardContent>
                {isLoading ? (
                  <CircularProgress />
                ) : (
                  <div>
                    <Typography
                      component={"p"}
                      className={classNames(
                        classes.LatoRegular,
                        classes.expireColor
                      )}
                      color={"textSecondary"}
                    >
                      Here’s a summary of activities in the last 14 days
                    </Typography>
                    <br />
                    {tasksCount != null ? (
                      <IconAndText
                        icon={icons.time}
                        text={"Tasks were recommended by LXRGuide."}
                        count={tasksCount}
                      />
                    ) : null}
                    {totalOrders != null ? (
                      <IconAndText
                        icon={icons.handCircle}
                        text={"Orders were placed."}
                        count={totalOrders}
                      />
                    ) : null}
                    {totalRevenue != null ? (
                      <IconAndText
                        icon={icons.time}
                        text={"Revenue was generated."}
                        classes={classes}
                        count={
                          <CurrencySymbol
                            formatOptions={{
                              ...numOptions,
                              ...currencyOptions,
                            }}
                          >
                            {totalRevenue}
                          </CurrencySymbol>
                        }
                      />
                    ) : null}
                  </div>
                )}
                {error != null || error != "" ? (
                  <ErrorTypography>{error}</ErrorTypography>
                ) : null}

                <br />
                <Typography
                  color={"textSecondary"}
                  component={"P"}
                  gutterBottom
                >
                  To continue driving exceptional results from your Google Ads
                  campaigns while spending less than 15 minutes per week, you
                  can choose from either of the paid plans below.
                </Typography>
                <br />
                <Typography
                  className={classNames(
                    classes.title,
                    classes.montserratRegular
                  )}
                  color={"textSecondary"}
                >
                  Plans designed for your business
                </Typography>
                <br />
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <PricingPlan
                      plan={"Standard"}
                      price={"$500/mo"}
                      para={
                        "If you would like to manage PPC campaigns on your own, this is a good option. Go ahead! DIY!"
                      }
                      icon={icons.signup}
                      classes={classes}
                      planId={types.STANDARD}
                      selectedPlan={selectedPlan}
                      handlePlan={this.handlePlan}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <PricingPlan
                      plan={"Jumpstart"}
                      price={"$2,500/mo"}
                      para={
                        "" +
                        "Our PPC experts will manage your account for the first 30 days, to give you an edge in the set up, " +
                        "management and optimization of PPC campaigns. You will be billed $500 after the first month."
                      }
                      icon={icons.signup}
                      classes={classes}
                      planId={types.JUMP_START}
                      selectedPlan={selectedPlan}
                      handlePlan={this.handlePlan}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={7} className={classes.taskSection}>
            <Typography
              component={"h5"}
              variant={"h5"}
              className={classNames(
                classes.montserratRegular,
                classes.whiteColor
              )}
              gutterBottom
            >
              <b>More recommendations once you subscribe:</b>
            </Typography>
            <br />
            <br />
            <Grid container alignItems={"center"}>
              <Grid item>
                <Grid container>
                  <Grid item md={6}>
                    <TaskDescription
                      title={"Optimize Geo Bids"}
                      description={
                        "Identifies high/low performing regions and recommends bid adjustments based on campaign performance and account targets."
                      }
                      icon={icons.locCircle}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TaskDescription
                      title={"Optimize Hourly Performance"}
                      description={
                        "Set bid multipliers to reduce ad spend at poor-performing hours of the day and increase campaign exposure at " +
                        "the best performing times of the day."
                      }
                      icon={icons.meter}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TaskDescription
                      title={"Keyword Ideas"}
                      description={
                        "Recommendations for new keyword ideas from users search query of Google Ads campaigns, and also from regular organic Google searches."
                      }
                      icon={icons.keyCircle}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TaskDescription
                      title={"Optimize Ads"}
                      description={
                        "Identifies underperforming ad copies and suggests replacement/improvement. " +
                        "LXRGuide content experts are available to help you write new ads."
                      }
                      icon={icons.bookCircle}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TaskDescription
                      title={"Create Product Feed"}
                      description={
                        "Create product feed and regularly upload to your Google Merchant Center." +
                        " Helps you run shopping campaign without the fear of feed expiry"
                      }
                      icon={icons.blogCircle}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TaskDescription
                      title={"Optimize Shopping Campaign"}
                      description={
                        "Boost (or suppress) individual products based on performance, optimize for daypart, device and geotarget."
                      }
                      icon={icons.shoppingCartCricle}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className={"fullWidth"}>
                <br />
                <br />
                <Typography
                  className={classNames(
                    classes.whiteColor,
                    classes.montserratRegular
                  )}
                  component={"h5"}
                  variant={"h5"}
                  align={"right"}
                >
                  <b>+30 more</b>
                </Typography>
              </Grid>
              <Grid item className={"fullWidth"}>
                <Hidden lgDown>
                  <br />
                  <br />
                </Hidden>
                <Typography
                  className={classNames(
                    classes.whiteColor,
                    classes.title,
                    classes.montserratRegular
                  )}
                  component={"h5"}
                  variant={"h5"}
                  gutterBottom
                >
                  <b>Advertisement Partner:</b>
                </Typography>
                <div>
                  <span style={{ padding: 5 }}>{icons.googlePartner}</span>
                  <span style={{ padding: 5 }}>{icons.microsoftIcon}</span>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  firstTaskObj: _.head(sortTasks(state.groupedTaskData.TaskType)),
});

const mapDispatchToProps = (dispatch) => ({
  getFreeTrialContent: () => dispatch(getFreeTrial()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(FreeTrialDashboard));
