import React from 'react';
import Overview from "../components/dashboard/overview";
import Tasks from "../components/dashboard/tasks.jsx";
import {sortTasks, store} from "../reducers/common";
import {connect} from "react-redux";
import * as _ from 'lodash';
import Feedback from "../components/feedBack";
import {dispatchDashboardData, fetchPerfData} from "../actions";
import {DASHBOARD_PAGE_TRACK} from "../actions/actionTypes";
import NotificationAlert from '../components/dashboard/notification-alert';
import {Card, CardContent, CardHeader, CardMedia, Grid, Typography} from "@mui/material";
import icons from "../components/icons";
import SafeAndSecure from "../components/safe-and-secure";
// import SiteGrader from "../components/siteGrader/siteGrader";


class Dashboard extends React.PureComponent {

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.dashboardPageTrack();
        if (window.hopscotch && window.hopscotch != null) {
            const currentStep = window.hopscotch.getCurrStepNum();
            if (currentStep) {
                const currentTour = window.hopscotch.getCurrTour();
                window.hopscotch.startTour(currentTour, currentStep);
            }
        }
    }

    render() {
        const {firstTaskObj, isConversionAlert, isLoading} = this.props;
        /*Used for Product Tour*/
        window.taskTypeId = firstTaskObj ? firstTaskObj.summary.groupId : 0;
        return (
            <React.Fragment>
                {isConversionAlert ? <NotificationAlert isConversionAlert={isConversionAlert} icon={icons.WarningAlert}
                                                        message={"Conversion Tracking for your account is not enabled."}/> : null}
                {/*<NotificationAlert isConversionAlert={isConversionAlert} icon={icons.WarningAlert}
                                   message={"Conversion Tracking for your account is not enabled."}/>*/}

                <Feedback/>
                <Overview/>
                <Tasks {...this.props} />
                {/*<SiteGrader/>*/}
            </React.Fragment>

        );
    }
}

const mapStateToProps = state => ({
    firstTaskObj: _.head(sortTasks(state.groupedTaskData.TaskType)),
    endDate: state.perf.endDate,
    graderUser: state.perf.graderUser,
    isLoading: state.perf.isLoading,
    isConversionAlert: state.perf.isConversionAlert,
});
const mapDispatchToProps = (dispatch) => ({
    dashboardPageTrack: () => dispatch({
        type: DASHBOARD_PAGE_TRACK,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
