import React, { useEffect, useRef, useState } from "react";
import * as Datamap from "datamaps";
import * as _ from "lodash";
import d3 from "d3";
import withStyles from '@mui/styles/withStyles';

const MapChart = (props) => {
  const { regionData } = props;
  const [mapData, setMapData] = useState(null);
  const [dataMap, setDataMap] = useState(null);

  useEffect(() => {
    console.log("regionData==>", regionData);
    if (!_.isEmpty(regionData)) {
      setMapData(regionData);
    }
  }, [regionData]);

  function currentScreenWidth() {
    return (
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    );
  }
  useEffect(() => {
    d3.select(".datamap").remove();
    const mapContainer = d3.select("#mapContainer");
    const initialScreenWidth = currentScreenWidth();
    const containerWidth =
      initialScreenWidth < 580
        ? { height: initialScreenWidth * 0.5625 + "px" }
        : { height: "400px", width: currentScreenWidth() * 0.4 + "px" };
    mapContainer.style(containerWidth);
    // Load the datamap whenever the data changes
    if (mapData) {
      console.log("mapData---->", mapData);
      const map = new Datamap({
        element: document.getElementById("mapContainer"),
        scope: "usa",
        geographyConfig: {
          popupOnHover: true,
          highlightOnHover: true,
          highlightBorderColor: "#949EAB",
          borderWidth: 0.5,
          highlightFillColor: "#FF8735",
          popupTemplate: function (geography, mapData) {
            return mapData.sessions
              ? ('<div style="color: #1E334E" class="hoverinfo"><strong> Region: ' +
                  mapData.state +
                  "</strong><br><strong>Sessions: " +
                  mapData.sessions.toLocaleString("en-US") +
                  " </strong></div>")
              :  ('<div style="color: #1E334E" class="hoverinfo"><strong> Region: ' +
              mapData.state +
              "</strong><br><strong>Sessions: " +
             "NA" +
              " </strong></div>")
          },
        },
        fills: {
          USA: "#2ca02c",
          defaultFill: "#C0C6CD",
        },
        data: mapData,
      });

      setDataMap(map);
    }
  }, [mapData]);

  return <div style={{ position: "relative" }} id="mapContainer"></div>;
};

export default MapChart;
