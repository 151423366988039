export function descendingComparator(a, b, orderBy) {
  if (orderBy === "createdDate" || orderBy == "lastModifiedDate") {
    return new Date(b[orderBy]) - new Date(a[orderBy]);
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

//Match type column header
export const MatchTypeHeadCells = [
  {
    id: "text",
    numeric: false,
    disablePadding: false,
    label: "Keyword",
  },
  {
    id: "matchType",
    numeric: false,
    disablePadding: false,
    label: "Match type",
  },
  {
    id: "url",
    numeric: false,
    disablePadding: false,
    label: "Final URL",
  },
];

//JS Accounts Column Header
export const JsAccountsHeadCells = [
  {
    id: "seAccountId",
    numeric: true,
    disablePadding: false,
    label: "Account ID",
  },
  {
    id: "companyName",
    numeric: false,
    disablePadding: false,
    label: "Company Name",
  },
  {
    id: "analyst",
    numeric: false,
    disablePadding: false,
    label: "Analyst",
  },
  {
    id: "reviewer",
    numeric: false,
    disablePadding: false,
    label: "Reviewer",
  },
  {
    id: "createdDate",
    numeric: false,
    disablePadding: false,
    label: "Created On",
  },
  {
    id: "lastModifiedDate",
    numeric: false,
    disablePadding: false,
    label: "Last Updated",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
];

//JS Users Column Header
export const JsUsersHeadCells = [
  {
    id: "serialNo",
    numeric: true,
    disablePadding: false,
    label: "#",
  },
  {
    id: "login",
    numeric: false,
    disablePadding: false,
    label: "Username",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email",
  },
  {
    id: "createdDate",
    numeric: false,
    disablePadding: false,
    label: "Added On",
  },
  {
    id: "jsAccounts",
    numeric: false,
    disablePadding: false,
    label: "Assigned Accounts",
  },
  {
    id: "authority",
    numeric: false,
    disablePadding: false,
    label: "Role",
  },
];
