import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Button from "@mui/material/Button";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import green from "@mui/material/colors/green";
import amber from "@mui/material/colors/amber";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import WarningIcon from "@mui/icons-material/Warning";
import { withStyles } from "@mui/styles";
const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const styles1 = (theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: "flex",
    alignItems: "flex-start",
  },
});

const CustomSnackBar = withStyles((theme) => ({
  root: {
    zIndex: "9999999999",
  },
  // label
}))(Snackbar);

function MySnackbar(props) {
  const {
    classes,
    className,
    message,
    onClose,
    variant,
    verticalAlign,
    horizontalAlign,
    handleClose,
    ...other
  } = props;
  const Icon = variantIcon[variant];

  return (
    <CustomSnackBar
      anchorOrigin={{
        vertical: verticalAlign,
        horizontal: horizontalAlign,
      }}
      open={other.open}
      autoHideDuration={other.duration}
      onClose={() => onClose()}
      ContentProps={{
        "aria-describedby": "message-id",
      }}
    >
      <SnackbarContent
        className={classNames(classes[variant], className)}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={classNames(classes.icon, classes.iconVariant)} />
            {message}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={onClose}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
      />
    </CustomSnackBar>
  );
}

MySnackbar.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(["success", "warning", "error", "info"]).isRequired,
};

const MySnackbarWrapper = withStyles(styles1)(MySnackbar);

export default MySnackbarWrapper;
