import React from "react";
import { Card, Grid, Typography, Button } from "@mui/material";
import KpiGraph from "./kpi-graph";
import CampaignSnapshot from "./campaign-snapshot";
import CurrentMonthPerformance from "./current-month-perf.js";
import DateRangeSelector from "./date-range-selector";
import withStyles from '@mui/styles/withStyles';
import { connect } from "react-redux";

import { CompactCardContent, MainSectionCardHeader } from "../cards";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../selectors/dashboard-selectors";

const CustomCard = withStyles((theme) => ({
  root: {
    boxShadow: "none",
  },
}))(Card);
const CustomButtonLink = withStyles((theme) => ({
  root: {
    backgroundColor: "rgba(30, 51, 78, 0.3)",
  },
  label: {
    fontWeight: "bold",
    color: theme.palette.common.white,
    "& :hover": {
      color: theme.palette.white,
    },
    textTransform: "capitalize",
  },
}))(Button);

function Overview(props) {
  const {
    selectedDateRange,
    onSelectDateRange,
    displayDateRange,
    customDateRanges,
  } = props;
  const { kpiType, kpiHistory } = props;
  const { isLoading } = props;

  return (
    <CustomCard>
      <MainSectionCardHeader
        style={{ paddingBottom: 0 }}
        title={"OVERVIEW"}
        subheader={"Overall performance and status"}
      />
      <CompactCardContent>
        <Grid container spacing={1}>
          <Grid item md={9} xs={12} sm={12}>
            <Grid container direction={"column"}>
              <Grid item style={{ textAlign: "right" }}>
                <DateRangeSelector
                  value={selectedDateRange}
                  onChange={onSelectDateRange}
                  range={displayDateRange}
                  customDateRanges={customDateRanges}
                />
              </Grid>
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item md={4}>
                    <KpiGraph
                      kpiHistory={kpiHistory}
                      kpiType={kpiType}
                      isLoading={isLoading}
                      selectedDateRange={selectedDateRange}
                    />
                  </Grid>
                  <Grid item md={8}>
                    <CampaignSnapshot {...props} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={3}>
            <CurrentMonthPerformance {...props} />
          </Grid>
        </Grid>
      </CompactCardContent>
    </CustomCard>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
