const statsStyles = (theme) => ({
  paper: {
    padding: theme.spacing(1),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    border: "1px solid" + theme.palette.common.border,
    borderRadius: 6,
    boxShadow: "4px 4px 18px " + theme.palette.boxShadowC,
    height: 100,
  },
  loadingDiv: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    justifyContent: "center",
  },
});

export default statsStyles;
