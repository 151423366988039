const historyStyles = theme => ({
    avatar: {
        // padding: theme.spacing(0),
        width: 30,
        height: 24,
        fontSize: "1rem",
        backgroundColor: theme.palette.common.whiteGrey,
        borderRadius: 0
    },
    caption: {
        opacity: 0.5,
        fontWeight: "800"
    },
    completedAvatar:{
       backgroundColor: theme.palette.success1.light,
        color: theme.palette.common.white,
    },
    unCompletedAvatar:{
        backgroundColor: theme.palette.lightRedColor,
        color: theme.palette.text.primary,
    },

});

export default historyStyles;