import React from "react";
import { Tab, Tabs } from "@mui/material";
import { styled } from "@mui/system";

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme, tabcolor }) => ({
  textTransform: "none",
  minWidth: 0,
  [theme.breakpoints.up("sm")]: {
    minWidth: 0,
  },
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  color: tabcolor || "#838383",
  fontFamily: "lato",
  "&.Mui-selected": {
    color: "#F58120",
    fontWeight: "700",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "#d1eaff",
  },
}));

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{
      children: <span className="MuiTabs-indicatorSpan" />,
    }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    width: "100%",
    backgroundColor: "#F58120",
  },
});

function CustomTabs({ tabs, currentTabIndex, handleTabChange, variant, tabcolor, disabled }) {
  return (
    <StyledTabs variant={variant} value={currentTabIndex}   onChange={handleTabChange}>
      {tabs.map((tab, index) => (
        <AntTab key={index} label={tab.label} tabcolor={tabcolor} disabled={ (disabled == true && index == 1) ? true : false} />
      ))}
    </StyledTabs>
  );
}

export default CustomTabs;