import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import logo from '../../assets/img/lxr-guide-logo-powered-by-netelixir.png';
import {Menu} from "@mui/icons-material";
import {
    AppBar,
    Toolbar,
    Typography,
    Button,
    MenuList,
    Hidden,
} from '@mui/material';
import headerBarStyles from "../../assets/jss/headerBar/headerBarStyles.jsx";
import {LinkBtns, LinkBtnsNew, BorderBtn, BurgerButton} from '../buttons'
import MenuItem from '@mui/material/MenuItem';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import classNames from "classnames";
import brand_1 from "../../assets/img/brand_1.png";
import brand_2 from "../../assets/img/brand_2.png";

class MenuAppBar extends React.Component {
    state = {
        auth: true,
        anchorEl: null,
        right: false,
        drawer: false,
        open: false
    };
    toggleDrawer = (side, open) => () => {
        this.setState({
            [side]: open,
        });
    };

    handleChange = (event, checked) => {
        this.setState({auth: checked});
    };

    handleDropToggle = () => {
        this.setState(state => ({open: !state.open}));
    };

    handleDropClose = event => {
        if (this.anchorEl.contains(event.target)) {
            return;
        }

        this.setState({open: false});
    };
    handleMenu = event => {
        this.setState({anchorEl: event.currentTarget});
    };

    handleClose = () => {
        this.setState({anchorEl: null});
    };
    handleDrawerToggle = () => {
        this.setState({drawer: !this.state.drawer});
    };

    render() {
        const {classes} = this.props;
        // const {anchorEl, anchorEle} = this.state;
        // const open = Boolean(anchorEl);
        const {open} = this.state;

        return (
            <div className={classes.root}>
                <AppBar position="static" className={classes.app_bar} id={"main-head"}>
                    <div className={classNames(classes.banner, classes.lxrguideBold)}>
                        <p>Supporting Small Businesses During COVID-19 <a
                            href="https://www.netelixir.com/move-your-business-online/" target="_blank" rel="noopener noreferrer"
                            className={classes.lxrguideBold}><span style={{paddingLeft:20}}><u>LEARN
                            MORE</u></span></a></p>
                    </div>
                    <Toolbar className={classes.covidBannerHead}>
                         <Typography variant="title" color="inherit" className={classes.flex}>

                                {/*<img src={logo} className={classes.BrandLogo} alt={"Brand Logo"}/>*/}
                                {/*<img src={logo} className={classes.BrandLogo} alt={"Brand Logo"}/>*/}
                                <div className={"flex alignItemsCenter"}>
                                    <Button href="/index.html" style={{textDecoration: "none"}}>
                                        <img  src={brand_1} width="240px" height={"auto"}/>
                                    </Button>
                                    <Button href="https://www.netelixir.com/" target={"_blank"} style={{textDecoration: "none",paddingLeft:5}}>
                                        <img src={brand_2} width={"95px"} height={"auto"} style={{paddingLeft:2}}/>
                                    </Button>
                                </div>

                        </Typography>
                        <Hidden xlDown>
                            <MenuList>
                                {/*  <LinkBtns href="/adwords-grader.html" color="inherit">
                                    GOOGLE ADS GRADER
                                </LinkBtns>*/}
                                {/*<LinkBtns href="/features.html" color="inherit">
                                    FEATURES
                                </LinkBtns>*/}
                                <LinkBtnsNew
                                    buttonRef={node => {
                                        this.anchorEl = node;
                                    }}
                                    aria-owns={open ? 'menu-list-grow' : undefined}
                                    aria-haspopup="true"
                                    onClick={this.handleDropToggle}

                                >
                                    TOOLS&nbsp;<i className="fa fa-sort-desc" aria-hidden="true"
                                                      style={{paddingBottom: 10}}></i>
                                </LinkBtnsNew>
                                <Popper open={open} anchorEl={this.anchorEl} transition disablePortal placement={"top-start"}
                                        style={{zIndex: 9999}}>
                                    {({TransitionProps, placement}) => (
                                        <Grow
                                            {...TransitionProps}
                                            id="menu-list-grow"
                                            style={{transformOrigin: placement == 'bottom' ? 'center top' : 'center bottom'}}
                                        >
                                            <Paper >
                                                <ClickAwayListener onClickAway={this.handleDropClose}>
                                                    <MenuList>
                                                        <MenuItem>
                                                            <LinkBtns href="/adwords-grader.html" color="inherit">
                                                                GOOGLE ADS GRADER
                                                            </LinkBtns>
                                                        </MenuItem>
                                                        <MenuItem>
                                                            <LinkBtns href="/site-grader.html" color="inherit">
                                                                    SEO SITE GRADER
                                                            </LinkBtns>
                                                        </MenuItem>

                                                        {/*<MenuItem>
                                                            <LinkBtns href="/blog/" color="inherit">
                                                                BLOG
                                                            </LinkBtns>
                                                        </MenuItem>*/}
                                                    </MenuList>
                                                </ClickAwayListener>
                                            </Paper>
                                        </Grow>
                                    )}
                                </Popper>
                                <LinkBtns href="/features.html" color="inherit">
                                    FEATURES
                                </LinkBtns>
                                <LinkBtns href="/pricing.html" color="inherit">
                                    PRICING
                                </LinkBtns>
                                 <LinkBtns href="/blog/" color="inherit">
                                    BLOG
                                </LinkBtns>
                                <LinkBtns href="/login.html" color="inherit">
                                    LOGIN
                                </LinkBtns>
                                <BorderBtn href="/signup.html">
                                    SIGN UP
                                </BorderBtn>
                            </MenuList>
                        </Hidden>
                        <Hidden lgUp>
                            <BurgerButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={this.handleDrawerToggle}
                            >
                                <Menu/>
                            </BurgerButton>
                        </Hidden>

                    </Toolbar>
                </AppBar>
                {/*<SideBar drawer={this.state.drawer} handleDrawerToggle={() => this.handleDrawerToggle}/>*/}
            </div>
        );
    }
}

MenuAppBar.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(headerBarStyles)(MenuAppBar);
