import React from "react";
import { Grid, Paper, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import icons from "../../components/icons";
import {
  CatalinaBlue,
  GreenText,
  OrangeText,
  SubHeadingInsights,
  SubHeadingTypo,
} from "../../components/typography";

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="xl" />;

function IconText({ icon, text, classes }) {
  return (
    <Grid
      container
      spacing={24}
      alignItems={"flex-end"}
      className={classes.marginBtm}
    >
      <Grid item md={12} xs={12} sm={12}>
        {icon}
      </Grid>
      <Grid item md={12} xs={12} sm={12}>
        <Typography component={"p"} variant="subtitle1">
          <GreenText>
            <b style={{ fontFamily: "Lato" }}>{text}</b>
          </GreenText>
        </Typography>
      </Grid>
    </Grid>
  );
}

const InsightsStyles = (theme) => ({
  title: {
    fontWeight: "700",
  },
  subtitle: {
    fontFamily: "Lato",
  },
  subPara: {
    fontFamily: "Lato",
  },
  subTitle: {
    fontFamily: "Lato",
  },
  marginBtm: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  insightsPaper: {
    // padding: 10,
    boxShadow: "unset",
    // paddingLeft:theme.spacing(2),
    // paddingRight:theme.spacing(2)
  },
  insightsPaperBg: {
    // padding: 10,
    boxShadow: "unset",
    backgroundColor: theme.palette.common.darkGreybg,
    // paddingLeft:theme.spacing(2),
    // paddingRight:theme.spacing(2)
  },
  sectionHeight1: {
    minHeight: 350,
  },
  sectionHeight2: {
    minHeight: 300,
  },
  headTitle: {
    color: "textPrimary",
    fontWeight: "700",
    textAlign: "center",
    // lineHeight: "20px",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.25rem",
      margin: "20px 0px 0px 31px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2.75rem",
    },
    // paddingLeft: theme.spacing(4),
    // paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    textTransform: "uppercase",
  },
});
const CustomPaper = withStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xl")]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingTop: theme.spacing(4),
      // paddingBottom: theme.spacing(4),
      boxShadow: "unset",
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
      paddingTop: theme.spacing(5),
      // paddingBottom: theme.spacing(5),
      boxShadow: "unset",
    },
  },
}))(Paper);

function Insights(props) {
  const {
    icon,
    title,
    subtitle,
    para,
    subPara,
    subtitle2,
    subPara2,
    leftIcon,
    classes,
    bg,
    head,
    width,
    features,
  } = props;
 

  const getInsightsContent = () => {
    return (
      <Grid item md={6}>
        <SubHeadingInsights
          color="secondary"
          className={classes.title}
          gutterBottom
        >
          {title}
        </SubHeadingInsights>
        {/*<br/>*/}
        <CatalinaBlue
          color="textPrimary"
          className={classes.subPara}
          gutterBottom
          dangerouslySetInnerHTML={{
            __html: para.replace(
              "growth@lxrguide.com",
              "<a href='https://mail.google.com/mail/?view=cm&amp;fs=1&amp;to=support@lxrguide.com&amp;su=Reg: LXRGuide Product Help' target='_blank'>growth@lxrguide.com</a>"
            ),
          }}
        />
        {/*<IconText icon={icons.leafOrange} text={subtitle} classes={classes}/>*/}
        <CatalinaBlue
          color="textPrimary"
          className={classes.subPara}
          gutterBottom
        >
          {subPara}
        </CatalinaBlue>
        <br />
        <br />
        <CatalinaBlue
          className={classes.subPara}
          color="textPrimary"
          gutterBottom
        >
          {subPara2}
        </CatalinaBlue>
      </Grid>
    );
  };

  return (
    <CustomPaper
      className={bg ? classes.insightsPaperBg : classes.insightsPaper}
    >
      <Grid
        container
        justifyContent={"center"}
        spacing={6}
        alignItems={"center"}
        className={bg ? classes.sectionHeight2 : classes.sectionHeight1}
      >
        {head ? (
          <Grid md={12}>
            <Typography
              // color={"textPrimary"}
              // variant={width == "sm" || width == "xs" ? "h3" : "subtitle1"}
              // style={{ fontWeight: "700" }}
              // align={"center"}
              className={classes.headTitle}
              gutterBottom
            >
              {head}
            </Typography>
          </Grid>
        ) : null}
        {leftIcon ? (
          <Grid item md={features ? 6 : 5}>
            <Typography align={"center"}>{icon}</Typography>
          </Grid>
        ) : (
          getInsightsContent()
        )}

        {leftIcon ? (
          getInsightsContent()
        ) : (
          <Grid item md={features ? 6 : 6}>
            <Typography align={"center"}>{icon}</Typography>
          </Grid>
        )}
      </Grid>
      <br />
      <br />
    </CustomPaper>
  );
}

export default withWidth()(withStyles(InsightsStyles)(Insights));
