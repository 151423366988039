import React from "react";
import { Button, Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";

import withStyles from '@mui/styles/withStyles';

export function IconAndText({ icon, text, count, classes }) {
  return (
    <Grid
      container
      alignItems={"center"}
      justifyContent={"flex-start"}
      spacing={2}
    >
      <Grid item>{icon}</Grid>
      <Grid item>
        {classes ? (
          <Typography
            className={classes.flex}
            component={"p"}
            color={"textSecondary"}
          >
            <b>{count}</b> &nbsp;{text}
          </Typography>
        ) : (
          <Typography component={"p"} color={"textSecondary"}>
            <b>{count}</b> {text}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}

export function PricingPlan({ plan, price, para, icon, classes, planId }) {
  return (
    <Card className={classes.pricingPlanCard}>
      <CardContent>
        <Typography
          align={"center"}
          gutterBottom
          color={"textSecondary"}
          component={"h6"}
          className={classes.montserratRegular}
          variant={"h6"}
        >
          <b>{plan}</b>
        </Typography>
        <Typography
          align={"center"}
          variant={"h6"}
          component={"h6"}
          className={classes.montserratRegular}
          color={"textSecondary"}
        >
          {price}
        </Typography>
        <br />
        <Typography align={"center"} gutterBottom className={classes.btnHeight}>
          <a
            href={"/payment-details.html?selectedPlan=" + planId}
            style={{ color: "inherit", textDecoration: "unset" }}
          >
            <Button
              variant={"contained"}
              classes={{
                root: classes.btnRoot,
              }}
            >
              {icon}&nbsp;Payment Options
            </Button>
          </a>
        </Typography>
        <br />
        <Typography
          component={"p"}
          align={"center"}
          className={classes.montserratRegular}
          color={"textSecondary"}
          gutterBottom
        >
          {para}
        </Typography>
      </CardContent>
    </Card>
  );
}

export function TaskDescription({ title, description, icon }) {
  return (
    <Grid container spacing={2}>
      <Grid item sm={1} md={2}>
        {icon}
      </Grid>
      <Grid item sm={11} md={10}>
        <Typography
          component={"h6"}
          variant={"h6"}
          style={{ color: "#ffffff" }}
        >
          {title}
        </Typography>
        <Typography
          component={"p"}
          style={{ color: "#ffffff", maxWidth: "auto" }}
        >
          {description}
        </Typography>
      </Grid>
    </Grid>
  );
}

export const MainSectionCardHeader = withStyles((theme) => ({
  title: {
    fontSize: "24px",
    fontWeight: "900",
    color: theme.palette.text.secondary,
  },
}))(CardHeader);
