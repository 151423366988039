import React, { Fragment, useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import icons from "../../components/icons";
import "./WebsiteHealthScoreCard.css";
import {
  SectionInfoWHS,
  SubSectionHeading,
  GoodSubSectionComment,
  ImproveSubSectionComment,
  ErrorSubSectionComment,
  NoDataSubSectionComment,
} from "../../components/typography";
import Tooltip from "@mui/material/Tooltip";

function Discoverability_WHC({ discoverabilityData }) {
  let discoverability_WHC = discoverabilityData;
  console.log(
    "inside discoverability_WHC discoverability_WHC===>",
    discoverability_WHC
  );

  const [socialMediaAccounts, setSocialMediaAccounts] = useState(
    discoverability_WHC ? discoverability_WHC.socialMediaAccounts : []
  );
  const [isFacebook, setIsFacebook] = useState(
    discoverability_WHC &&
      discoverability_WHC.socialMediaAccounts &&
      discoverability_WHC.socialMediaAccounts.facebook
      ? discoverability_WHC.socialMediaAccounts.facebook
      : null
  );
  const [isInstagram, setIsInstagram] = useState(
    discoverability_WHC &&
      discoverability_WHC.socialMediaAccounts &&
      discoverability_WHC.socialMediaAccounts.instagram
      ? discoverability_WHC.socialMediaAccounts.instagram
      : null
  );
  const [isTwitter, setIsTwitter] = useState(
    discoverability_WHC &&
      discoverability_WHC.socialMediaAccounts &&
      discoverability_WHC.socialMediaAccounts.twitter
      ? discoverability_WHC.socialMediaAccounts.twitter
      : null
  );
  const [isLinkedIn, setIsLinkedIn] = useState(
    discoverability_WHC &&
      discoverability_WHC.socialMediaAccounts &&
      discoverability_WHC.socialMediaAccounts.linkedin
      ? discoverability_WHC.socialMediaAccounts.linkedin
      : null
  );
  const [isYoutube, setIsYoutube] = useState(
    discoverability_WHC &&
      discoverability_WHC.socialMediaAccounts &&
      discoverability_WHC.socialMediaAccounts.youtube
      ? discoverability_WHC.socialMediaAccounts.youtube
      : null
  );
  const [topKeywords, setTopKeywords] = useState(
    discoverability_WHC ? discoverability_WHC.topKeywords : []
  );
  const [robotsTxt, setrobotsTxt] = useState(
    discoverability_WHC ? discoverability_WHC.robotsTxt : null
  );
  const [siteMapXML, setSiteMapXML] = useState(
    discoverability_WHC ? discoverability_WHC.siteMapXML : null
  );
  const [structuredData, setStructuredData] = useState(
    discoverability_WHC ? discoverability_WHC.structuredData : null
  );
  const [robotsTxtDescription, setrobotsTxtDescription] = useState(
    discoverability_WHC ? discoverability_WHC.robotsTxtDescription : null
  );
  const [siteMapXMLDescription, setSiteMapXMLDescription] = useState(
    discoverability_WHC ? discoverability_WHC.siteMapXMLDescription : null
  );
  const [structuredDataDescription, setStructuredDataDescription] = useState(
    discoverability_WHC ? discoverability_WHC.structuredDataDescription : null
  );
  const [socialMediaAccountsDescription, setSocialMediaAccountsDescription] =
    useState(
      discoverability_WHC
        ? discoverability_WHC.socialMediaAccountsDescription
        : null
    );
  const [topKeywordsDescription, setTopKeywordsDescription] = useState(
    discoverability_WHC ? discoverability_WHC.topKeywordsDescription : null
  );
  const GOOD = "Looks good";
  const IMPROVED = "Can be improved";
  const ERROR = "Critical error found!";
  const NO_DATA = "Not available";
  const [socialMediaCount, setSocialMediaCount] = useState();
  useEffect(() => {
    if (discoverability_WHC) {
      setrobotsTxt(
        discoverability_WHC.robotsTxt !== undefined
          ? discoverability_WHC.robotsTxt
          : null
      );
      setSiteMapXML(
        discoverability_WHC.siteMapXML !== undefined
          ? discoverability_WHC.siteMapXML
          : null
      );
      setStructuredData(
        discoverability_WHC.structuredData !== undefined
          ? discoverability_WHC.structuredData
          : null
      );
      setTopKeywords(
        discoverability_WHC.topKeywords ? discoverability_WHC.topKeywords : null
      );
      setSocialMediaAccounts(
        discoverability_WHC.socialMediaAccounts
          ? discoverability_WHC.socialMediaAccounts
          : null
      );
      setIsFacebook(
        discoverability_WHC &&
          discoverability_WHC.socialMediaAccounts &&
          discoverability_WHC.socialMediaAccounts.facebook
          ? discoverability_WHC.socialMediaAccounts.facebook
          : null
      );
      setIsInstagram(
        discoverability_WHC &&
          discoverability_WHC.socialMediaAccounts &&
          discoverability_WHC.socialMediaAccounts.instagram
          ? discoverability_WHC.socialMediaAccounts.instagram
          : null
      );
      setIsTwitter(
        discoverability_WHC &&
          discoverability_WHC.socialMediaAccounts &&
          discoverability_WHC.socialMediaAccounts.twitter
          ? discoverability_WHC.socialMediaAccounts.twitter
          : null
      );
      setIsLinkedIn(
        discoverability_WHC &&
          discoverability_WHC.socialMediaAccounts &&
          discoverability_WHC.socialMediaAccounts.linkedin
          ? discoverability_WHC.socialMediaAccounts.linkedin
          : null
      );
      setIsYoutube(
        discoverability_WHC &&
          discoverability_WHC.socialMediaAccounts &&
          discoverability_WHC.socialMediaAccounts.youtube
          ? discoverability_WHC.socialMediaAccounts.youtube
          : null
      );
      setrobotsTxtDescription(
        discoverability_WHC.robotsTxtDescription !== undefined
          ? discoverability_WHC.robotsTxtDescription
          : null
      );
      setSiteMapXMLDescription(
        discoverability_WHC.siteMapXMLDescription !== undefined
          ? discoverability_WHC.siteMapXMLDescription
          : null
      );
      setStructuredDataDescription(
        discoverability_WHC.structuredDataDescription !== undefined
          ? discoverability_WHC.structuredDataDescription
          : null
      );
      setSocialMediaAccountsDescription(
        discoverability_WHC.socialMediaAccountsDescription !== undefined
          ? discoverability_WHC.socialMediaAccountsDescription
          : null
      );
      setTopKeywordsDescription(
        discoverability_WHC.topKeywordsDescription !== undefined
          ? discoverability_WHC.topKeywordsDescription
          : null
      );
    }
  }, [discoverability_WHC]);

  useEffect(() => {
    if (socialMediaAccounts) {
      let count = 0;
      for (const value of Object.values(socialMediaAccounts)) {
        if (value) {
          count++;
        }
      }
      setSocialMediaCount(count);
    }
  }, [socialMediaAccounts]);
  console.log("social media count", socialMediaCount);

  const discoverabilityInfo =
    "Discoverability means how easily users can find what they're looking for on a website. It's about making sure information and features are easy to find and navigate, so people can quickly get the information they need.";
  return (
    <Fragment>
      <Grid
        container
        direction="row"
        className="sectionInfo"
        alignItems="center"
      >
        <Grid item xs={3} sm={2} md={1} lg={1} xl={1} className="grid-item-css">
          {icons.WHSDiscoverabilityInfo}
        </Grid>
        <Grid item xs={9} sm={10} md={11} lg={11} xl={11}>
          <SectionInfoWHS>{discoverabilityInfo}</SectionInfoWHS>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-start"
        columnSpacing={3}
        style={{ marginTop: "2%" }}
      >
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <div className="subsection-css">
            <SubSectionHeading>Robots.txt</SubSectionHeading>
            <div
              className={
                robotsTxt
                  ? "looksGoodBgColor"
                  : !robotsTxt
                  ? "errorBgColor"
                  : "noDataBgColor"
              }
            >
              {robotsTxt ? (
                <GoodSubSectionComment>{GOOD}</GoodSubSectionComment>
              ) : !robotsTxt ? (
                <ErrorSubSectionComment>{ERROR}</ErrorSubSectionComment>
              ) : (
                <NoDataSubSectionComment>{NO_DATA}</NoDataSubSectionComment>
              )}
            </div>
          </div>
          <div
            className={
              robotsTxt
                ? "subsection-info-good"
                : !robotsTxt
                ? "subsection-info-error"
                : "subsection-info-noData"
            }
            // style={{ height: "auto" }}
          >
            <Typography variant="subtitle2" className="wordBreakTypography">
              {robotsTxtDescription}
            </Typography>
          </div>
          {/* Structured Data */}
          <div className="subsection-css">
            <SubSectionHeading>Structured Data (JSON-LD)</SubSectionHeading>
            <div
              className={
                structuredData
                  ? "looksGoodBgColor"
                  : !structuredData
                  ? "errorBgColor"
                  : "noDataBgColor"
              }
            >
              {structuredData ? (
                <GoodSubSectionComment>{GOOD}</GoodSubSectionComment>
              ) : !structuredData ? (
                <ErrorSubSectionComment>{ERROR}</ErrorSubSectionComment>
              ) : (
                <NoDataSubSectionComment>{NO_DATA}</NoDataSubSectionComment>
              )}
            </div>
          </div>
          <div
            className={
              structuredData
                ? "subsection-info-good"
                : !structuredData
                ? "subsection-info-error"
                : "subsection-info-noData"
            }
            // style={{ height: "auto" }}
          >
            <Typography variant="subtitle2" className="wordBreakTypography">
              {structuredDataDescription}
            </Typography>
          </div>

          {/* sitemap.xml */}
          <div className="subsection-css">
            <SubSectionHeading>Sitemap</SubSectionHeading>
            <div
              className={
                siteMapXML
                  ? "looksGoodBgColor"
                  : !siteMapXML
                  ? "errorBgColor"
                  : "noDataBgColor"
              }
            >
              {siteMapXML ? (
                <GoodSubSectionComment>{GOOD}</GoodSubSectionComment>
              ) : !siteMapXML ? (
                <ErrorSubSectionComment>{ERROR}</ErrorSubSectionComment>
              ) : (
                <NoDataSubSectionComment>{NO_DATA}</NoDataSubSectionComment>
              )}
            </div>
          </div>
          <div
            className={
              siteMapXML
                ? "subsection-info-good"
                : !siteMapXML
                ? "subsection-info-error"
                : "subsection-info-noData"
            }
            // style={{ height: "auto" }}
          >
            <Typography variant="subtitle2" className="wordBreakTypography">
              {siteMapXMLDescription}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <div className="subsection-css">
            <div className="grid-item-css">
              <SubSectionHeading>
                Social Media Channels &nbsp;
              </SubSectionHeading>
              <Tooltip
                title={
                  <React.Fragment>
                    <Typography style={{ fontSize: "12px" }} color="inherit">
                      {socialMediaAccountsDescription}
                    </Typography>
                  </React.Fragment>
                }
                placement="right-end"
              >
                {icons.WHSInfo}
              </Tooltip>
            </div>
            <div
              className={
                socialMediaCount >= 3
                  ? "looksGoodBgColor"
                  : socialMediaCount >= 1 && socialMediaCount < 3
                  ? "improvedBgColor"
                  : socialMediaCount == 0
                  ? "errorBgColor"
                  : "noDataBgColor"
              }
            >
              {socialMediaCount >= 3 ? (
                <GoodSubSectionComment>{GOOD}</GoodSubSectionComment>
              ) : socialMediaCount >= 1 && socialMediaCount < 3 ? (
                <ImproveSubSectionComment>{IMPROVED}</ImproveSubSectionComment>
              ) : socialMediaCount == 0 ? (
                <ErrorSubSectionComment>{ERROR}</ErrorSubSectionComment>
              ) : (
                <NoDataSubSectionComment>{NO_DATA}</NoDataSubSectionComment>
              )}
            </div>
          </div>

          <div
            style={{
              border: "1px solid",
              borderColor: "#F2F6F9",
              marginTop: "2%",
            }}
          >
            <div className="subsection-css header-css">
              <Typography variant="subtitle2" align="left">
                Channel Name{" "}
              </Typography>
              <Typography variant="subtitle2" align="right">
                Status
              </Typography>
            </div>
            {socialMediaAccounts ? (
              <div style={{ padding: "3% 4%" }}>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  style={{ padding: "10px 0px" }}
                >
                  <Grid item xs={1} className="grid-item-css">
                    {icons.WHSFacebook}{" "}
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="subtitle2" align="left">
                      &nbsp;Facebook
                    </Typography>
                  </Grid>
                  <Grid item xs={6} style={{ overflow: "hidden" }}>
                    {icons.WHSHorizontalLine}
                  </Grid>
                  <Grid item xs={1} className="grid-item-css">
                    {isFacebook ? icons.WHSChecked : icons.WHSUnchecked}
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justify="space-around"
                  style={{ padding: "10px 0px" }}
                >
                  <Grid item xs={1} className="grid-item-css">
                    {icons.WHSInstagram}{" "}
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="subtitle2" align="left">
                      &nbsp;Instagram
                    </Typography>
                  </Grid>
                  <Grid item xs={6} style={{ overflow: "hidden" }}>
                    {icons.WHSHorizontalLine}
                  </Grid>
                  <Grid item xs={1} className="grid-item-css">
                    {isInstagram ? icons.WHSChecked : icons.WHSUnchecked}
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justify="space-around"
                  style={{ padding: "10px 0px" }}
                >
                  <Grid item xs={1} className="grid-item-css">
                    {icons.WHSTwitter}{" "}
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="subtitle2" align="left">
                      &nbsp;Twitter
                    </Typography>
                  </Grid>
                  <Grid item xs={6} style={{ overflow: "hidden" }}>
                    {icons.WHSHorizontalLine}
                  </Grid>
                  <Grid item xs={1} className="grid-item-css">
                    {isTwitter ? icons.WHSChecked : icons.WHSUnchecked}
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justify="space-around"
                  style={{ padding: "10px 0px" }}
                >
                  <Grid item xs={1} className="grid-item-css">
                    {icons.WHSLinkedIn}{" "}
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="subtitle2" align="left">
                      &nbsp;LinkedIn
                    </Typography>
                  </Grid>
                  <Grid item xs={6} style={{ overflow: "hidden" }}>
                    {icons.WHSHorizontalLine}
                  </Grid>
                  <Grid item xs={1} className="grid-item-css">
                    {isLinkedIn ? icons.WHSChecked : icons.WHSUnchecked}
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justify="space-around"
                  style={{ padding: "10px 0px" }}
                >
                  <Grid item xs={1} className="grid-item-css">
                    {icons.WHSYoutube}{" "}
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="subtitle2" align="left">
                      &nbsp;YouTube
                    </Typography>
                  </Grid>
                  <Grid item xs={6} style={{ overflow: "hidden" }}>
                    {icons.WHSHorizontalLine}
                  </Grid>
                  <Grid item xs={1} className="grid-item-css">
                    {isYoutube ? icons.WHSChecked : icons.WHSUnchecked}
                  </Grid>
                </Grid>
              </div>
            ) : (
              <div style={{ padding: "20px 0px" }}>
                <Typography variant="subtitle2" align="center">
                  Data not available!
                </Typography>
              </div>
            )}
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <div className="subsection-css">
            <div className="grid-item-css">
              <SubSectionHeading>Top Keywords &nbsp;</SubSectionHeading>
              <Tooltip
                title={
                  <React.Fragment>
                    <Typography style={{ fontSize: "12px" }} color="inherit">
                      {topKeywordsDescription}
                    </Typography>
                  </React.Fragment>
                }
                placement="top"
              >
                {icons.WHSInfo}
              </Tooltip>
            </div>
            <div
              className={topKeywords ? "looksGoodBgColor" : "improvedBgColor"}
            >
              {topKeywords ? (
                <GoodSubSectionComment>{GOOD}</GoodSubSectionComment>
              ) : (
                <ErrorSubSectionComment>{ERROR}</ErrorSubSectionComment>
              )}
            </div>
          </div>
          <div
            style={{
              border: "1px solid",
              borderColor: "#F2F6F9",
              marginTop: "2%",
            }}
          >
            <div className="subsection-css header-css">
              <Typography variant="subtitle2" align="left">
                Keyword Name{" "}
              </Typography>
              <Typography variant="subtitle2" align="right">
                Count
              </Typography>
            </div>
            {topKeywords ? (
              <div style={{ padding: "3% 3%" }}>
                {Object.entries(topKeywords).map(([key, value]) => (
                  <div key={key}>
                    <Grid
                      container
                      direction="row"
                      justify="space-around"
                      style={{ padding: "10px 0px" }}
                    >
                      <Grid item xs={4}>
                        <Typography
                          variant="subtitle2"
                          className="wordBreakTypography"
                          align="left"
                        >
                          {key}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} style={{ overflow: "hidden" }}>
                        {icons.WHSHorizontalLine}
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="subtitle2" align="right">
                          {value}
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                ))}
              </div>
            ) : (
              <div style={{ padding: "20px 0px" }}>
                <Typography variant="subtitle2" align="center">
                  Data not available!
                </Typography>
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </Fragment>
  );
}
export default Discoverability_WHC;
