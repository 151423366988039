import React, { Component } from "react";
import { Card, Grid, Paper, Input, Typography, CardContent } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import { CompactCardContent } from "../components/cards";
import GraderHomePageForm from "../components/grader/grader-home-page-form";
import AccountsAudit from "../components/grader/accounts-audit";
import CustomerTestimonial from "../components/grader/customer-testimonial";
import SafeAndSecure from "../components/safe-and-secure";
import SixMetrics from "../components/grader/six-metrics";
import Footer from "../components/footer";
import { GreenText } from "../components/typography";
import GraderBg from "../assets/img/google-ads-grader-bg.jpg";

const GraderHomePageStyles = (theme) => ({
  paper: {
    boxShadow: "unset",
  },
  calSection: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  holidaySectionHeight: {
    minHeight: "65vh",
    textAlign: "center",
  },
  input: {
    margin: "8px 0 8px 0",
    color: theme.palette.common.white,
    fontWeight: "bold",
  },
  title: {
    color: theme.palette.orangeColor,
  },
  graderHolidayBg: {
    backgroundImage: "url(" + GraderBg + ")",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",

    [theme.breakpoints.down('xl')]: {
      backgroundSize: "auto",
    },
    [theme.breakpoints.up("sm")]: {
      backgroundSize: "cover",
    },
  },
  sectionOne: {
    [theme.breakpoints.up("md")]: {
      minHeight: "70vh",
    },
    [theme.breakpoints.down('xl')]: {
      minHeight: "90vh",
    },
  },
  head: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "2.8rem",
  },
  errorText: {
    color: theme.palette.unSuccess,
  },
  paddingZero: {
    padding: "0px 0px 0px 0px",
  },
});

class GraderHomePage extends React.PureComponent {
  render() {
    const { classes } = this.props;
    return (
      <div>
        <Card className={classes.graderHolidayBg}>
          <CompactCardContent>
            <Grid container justifyContent={"center"} alignItems={"center"}>
              <Grid item md={12} className={classes.sectionOne}>
                <Grid
                  container
                  className={classes.holidaySectionHeight}
                  justifyContent={"center"}
                >
                  <Grid item md={9}>
                    <Typography
                      variant={"h3"}
                      component={"h3"}
                      className={classes.title}
                    >
                      <br />
                      <b>Is Your Google Ads Campaign Ready?</b>
                    </Typography>
                    <br />
                    <Typography
                      variant={"subhead"}
                      align={"center"}
                      color={"textSecondary"}
                    >
                      How many more orders you can get for the same Google Ads
                      Investment?
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <GraderHomePageForm classes={classes} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CompactCardContent>
        </Card>
        <Card>
          <CardContent style={{ padding: 0 }} className={classes.paddingZero}>
            <Grid container>
              <Grid item md={12}>
                <Grid container>
                  <Grid item md={12}>
                    <AccountsAudit />
                  </Grid>
                  <Grid item md={12}>
                    <CustomerTestimonial />
                  </Grid>
                  <Grid item md={12}>
                    <br />
                    <br />
                    <Typography
                      className={classes.head}
                      variant={"h3"}
                      component={"h3"}
                      color="textSecondary"
                      align={"center"}
                      gutterBottom
                    >
                      <GreenText>6 Powerful Insights</GreenText> from our Free
                      Google Ads Grader.
                    </Typography>
                    <SixMetrics />
                  </Grid>
                  <Grid item></Grid>
                </Grid>
              </Grid>
            </Grid>
            <SafeAndSecure />
            <Paper style={{ boxShadow: "unset" }}>
              <Footer />
            </Paper>
          </CardContent>
        </Card>
      </div>
    );
  }
}

GraderHomePage.propTypes = {};

export default withStyles(GraderHomePageStyles)(GraderHomePage);
