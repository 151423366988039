import React, { Component, useEffect } from "react";
import PropTypes from "prop-types";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import googleAdsGraderStyles from "../../assets/jss/googleAdsGrader/googleAdsGraderStyle";
import {
  BlueTitleTypography,
  OrangeTextSize12,
  OrangeTypography,
  TaglineForHeadline,
} from "../../components/typography";
import GraderHomePageForm from "./grader-home-page-form";
import icons from "../../components/icons";
import SixMetrics from "./six-metrics";
import SafeAndSecure from "../../components/safe-and-secure";
import MetaTags from "react-meta-tags";
import * as types from "../../actions/actionTypes";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="xl" />;

let id = 1;

function createGoogleAdsData(img, head, title, para, para2, bg, leftAlign) {
  id += 1;
  return { id, img, head, title, para, para2, bg, leftAlign };
}

export const googleAdsData = [
  createGoogleAdsData(
    "mobileDesktopStatsNew",
    "Comprehensive analysis of your Google Ads campaigns to help you beat your targets at the right ad spend ",
    "Quality Score",
    "LXRGuide generates a quality score report based on the aggregate quality scores across your campaigns keeping the benchmark for individual average quality score between 7 and 10.  Quality Score is an important metric that determines your Cost Per Click [CPC].  A higher quality score gives you a clear bidding advantage, lowers your CPC and increases your chances of gaining a better position in the search results.  The grader report will direct you towards keywords, ads and landing pages which need to be optimized to increase your quality score.",
    null,
    true,
    true
  ),
  createGoogleAdsData(
    "groupStatsNew",
    null,
    "Impression Share",
    "The grader analyses your account to determine the impression share for your account – the total of impressions your ad received divided by the total of impressions it was eligible to receive." +
      " This vital metric helps you balance your paid search budget and calls your attention to budget problems that might result in loss of impressions." +
      "  The report gives you a fairly good idea of apportioning your budget to maximise impression share.",
    null,
    false,
    false
  ),
  createGoogleAdsData(
    "landingPageMetricNew",
    null,
    "Landing Page Performance",
    "The grader measures the landing page performance through specific user behavior exhibited on page that might influence or lead to conversions. " +
      "It will classify your landing page performance according to an account average for conversions derived from an average of conversions across high performance pages which exhibit excellent speed, strong call to action and keyword relevance. " +
      "The report will show three-page statuses: the average, below average and above average pages. " +
      "It will also highlight those pages which fall below the average and suggest improvements. Remember, higher the page status, greater the chances of conversions.",
    null,
    true,
    true
  ),
  createGoogleAdsData(
    "negativeKeywordsNew",
    null,
    "Negative Keywords",
    "Negative keywords prevent your ads from showing up when a user searches certain words or phrases. " +
      "It is important to determine whether there are conflicting negative keywords which block lucrative searches and to what extent they are present. " +
      "The grader checks your account for the presence of such negative keywords that conflict with other keywords added to your account. " +
      "The report will give you the performance score on this.  Be careful about not losing out on lucrative searches while avoiding wrong exposure for your ad. " +
      "The report can help you use your Search Terms report and Keyword planner more effectively.",
    null,
    false,
    false
  ),
  createGoogleAdsData(
    "wastedSpendMetricNew",
    null,
    "Wasted Spend Analysis",
    "Tracking and identifying wasted spend is the first step towards cutting down expenditure and diverting spend to areas where there will be a positive return on investment. " +
      "Google Ads grader will tell you the exact percentage of your budget that is not bringing revenue and also the amount wasted in the previous month. " +
      "Use the information in the report to maximize profits and minimize spend for greater returns. ",
    null,
    true,
    true
  ),
  createGoogleAdsData(
    "accountMetricScoreNew",
    null,
    "Account Structure Score",
    "The grader uses a 12-point checklist to estimate your Account Structure Score. " +
      "It includes Campaign Budget, Keywords/Ad group, Shopping Campaign Availability, " +
      "Active ad copies and structure, Use of Trademark terms, Keywords Match Type split, " +
      "Conflicting Keywords Match type bid, Landing page Status, number of ad copies and ad extensions per ad group. " +
      "It is a comprehensive measure that helps you assess the stability of your internal account structure and marketability among customers. " +
      "Scoring well on this index is important.  Interpreting this score correctly is crucial. If you are not sure, call us.",
    null,
    false,
    false
  ),
];

export function OverviewSection(props) {
  const { icon, pageCaption, title, para, children, classes, width } = props;
  //console.log("OverviewSection--->"+props.children)
  return (
    <Grid
      container
      className={classes.gridBg}
      justifyContent={"center"}
      style={{ height: "100%" }}
      align={"center"}
    >
      <Grid item md={12}>
        <Typography gutterBottom align={"center"}>
          {icon}
        </Typography>
        <br />
        <OrangeTextSize12
          variant={"caption"}
          align={"center"}
          color={"secondary"}
          gutterBottom
        >
          {pageCaption}
        </OrangeTextSize12>
        <Typography className={classes.heading}
          // color={"textPrimary"}
          // variant={width == "sm" || width == "xs" ? "h3" : "subtitle1"}
          style={{ lineHeight:"1.2" }}
          // align={"center"}
          gutterBottom
        >
          {title}
        </Typography>
        <Grid container justifyContent={"center"}>
          <Grid item sm={10} xs={10} md={8}>
            <TaglineForHeadline
              color={"textPrimary"}
              align={"center"}
              gutterBottom
            >
              {para}
            </TaglineForHeadline>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={4}>
        {children}
      </Grid>
    </Grid>
  );
}

OverviewSection.propTypes = { classes: PropTypes.any };

export function iconWithPara(icon, count, para, classes) {
  return (
    <div>
      <Typography align={"center"}>{icon}</Typography>
      <Typography align={"center"} component={"h4"} variant="h4">
        <b>{count}</b>
      </Typography>
      <Typography align={"center"} component={"h6"} variant="h6">
        {para}
      </Typography>
    </div>
  );
}

function GoogleAdHome(props) {
  const { classes, width } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
    props.googleAdsPageTrack();
  }, []);

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent} style={{ padding: 0 }}>
        <Helmet>
          <title>
            {" "}
            Google Ads Grader Tool | Google Adwords Tool | LXRGuide
          </title>
          <meta
            name="description"
            content="Use our Google Ad Graders Tool to optimize your web presence with scores on your target impression share and more. We show you how to increase conversions with enhanced cpc."
          />
        </Helmet>
        <Grid container >
          <Grid item md={12} className={classes.sectionBg}>
            <OverviewSection
              width={width}
              classes={classes}
              icon={icons.graderHomePageIcon}
              pageCaption={"GOOGLE ADS GRADER"}
              title={"IDENTIFY GAPS IN YOUR GOOGLE ADS CAMPAIGN WITHIN MINUTES"}
              para={
                "LXRGuide’s Google ads grader can give you a free technical paid search audit report in under 5 minutes.  Use the information to optimize your account performance and increase conversions."
              }
            >
              <GraderHomePageForm classes={classes} />
            </OverviewSection>
          </Grid>
          <Grid item md={12}>
            <Grid
              container
              justifyContent={"center"}
              alignItems={"center"}
              className={classes.section2}
            >
              <Grid item sm={12} xs={12} md={6}>
                {iconWithPara(
                  icons.gaugeSm2(),
                  "1,247",
                  "Google Ads Accounts Audited",
                  {}
                )}
              </Grid>
              <Grid item sm={12} xs={12} md={6}>
                {iconWithPara(
                  icons.groupHand2(),
                  "8%",
                  "Incremental new customer opportunities identified",
                  {}
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12}>
            <SixMetrics classes={classes} data={googleAdsData} googleAdsHome />
          </Grid>
          <Grid item md={12}>
            <SafeAndSecure />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

GoogleAdHome.propTypes = {};
const mapDispatchToProps = (dispatch) => ({
  googleAdsPageTrack: () =>
    dispatch({ type: types.GOOGLE_ADS_GRADER_HOME_TRACK }),
});
export default connect(
  null,
  mapDispatchToProps
)(withWidth()(withStyles(googleAdsGraderStyles)(GoogleAdHome)));
