import React from "react";
import { connect } from "react-redux";
import { Grid, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import { TextForErrorPage } from "../../components/typography";
import "./WebsiteHealthScoreCard.css";
import icons from "../../components/icons";
const styles = (theme) => ({
  mainDiv: {
    [theme.breakpoints.up("md")]: {
      padding: "15px 50px 50px 50px",
      backgroundColor: "#F9FAFB",
    },
    [theme.breakpoints.down("md")]: {
      padding: "15px",
      backgroundColor: "#F9FAFB",
    },
  },
});

function WhsErrorPage(props) {
  const { classes } = props;

  window.scrollTo(0, 0);
  return (
    <div className={classes.mainDiv}>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TextForErrorPage>YOUR WEBSITE'S HEALTH SCORECARD</TextForErrorPage>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography
            style={{ fontSize: "1.75rem", color: "red", marginTop: "20px" }}
          >
            Unexpected Error
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className="cardErrorPage">
            <div
              style={{
                fontSize: "25px",
                fontFamily: "lato",
                fontWeight: "700",
                margin: "15px 0px 0px 15px",
              }}
            >
              Error Report
            </div>
            <div style={{ margin: "50px 0px 0px 25px" }}>
              <Typography>{icons.errorWarning}</Typography>
              <Typography>
                The website encountered an unexpected error. Unable to generate Website
                Health Scorecard.
              </Typography>
              <Typography>Please try again later.</Typography>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(styles, { withTheme: true })(WhsErrorPage);
