import React from "react";
import { Grid, Paper, Typography } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import icons from "../icons";
import { GreenText, OrangeText } from "../typography";

function IconText({ icon, text, classes }) {
  return (
    <Grid
      container
      spacing={2}
      alignItems={"flex-end"}
      className={classes.marginBtm}
    >
      <Grid item>{icon}</Grid>
      <Grid item>
        <Typography component={"p"} variant="subtitle1">
          <GreenText>
            <b style={{ fontFamily: "Lato" }}>{text}</b>
          </GreenText>
        </Typography>
      </Grid>
    </Grid>
  );
}

const InsightsStyles = (theme) => ({
  title: {
    fontWeight: "bold",
    lineHeight:"1.2"
  },
  subtitle: {
    fontWeight: "Lato",
    
  },
  subPara: {
    fontWeight: "Lato",
  },
  subTitle: {
    fontWeight: "Lato",
  },
  marginBtm: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  insightsPaper: {
    padding: 10,
    boxShadow: "unset",
  },
});

function Insights(props) {
  const {
    icon,
    title,
    subtitle,
    para,
    subPara,
    subtitle2,
    subPara2,
    leftIcon,
    classes,
  } = props;

  const getInsightsContent = () => {
    return (
      <Grid item md={6}>
        <Typography

          variant="h4"
          component={"h4"}
          color="textSecondary"
          className={classes.title}
          gutterBottom
        >
          {title}
        </Typography>
        <Typography
          component={"p"}
          variant="body1"
          color="textSecondary"
          gutterBottom
        >
          {para}
        </Typography>
        {/*<IconText icon={icons.leafOrange} text={subtitle} classes={classes}/>*/}
        <Typography
          component={"p"}
          variant="body1"
          color="textSecondary"
          className={classes.subPara}
          gutterBottom
        >
          {subPara}
        </Typography>
        <Typography
          component={"p"}
          variant="subtitle1"
          className={classes.subTitle}
          gutterBottom
        >
          <OrangeText>
            <b style={{ fontFamily: "Lato" }}>{subtitle2}</b>
          </OrangeText>
        </Typography>
        <Typography
          component={"p"}
          variant="body1"
          className={classes.subPara}
          color="textSecondary"
          gutterBottom
        >
          {subPara2}
        </Typography>
      </Grid>
    );
  };

  return (
    <Paper className={classes.insightsPaper}>
      <Grid container justifyContent={"center"} alignItems={"center"}>
        {leftIcon ? (
          <Grid item md={6}>
            <Typography align={"center"}>{icon}</Typography>
          </Grid>
        ) : (
          getInsightsContent()
        )}

        {leftIcon ? (
          getInsightsContent()
        ) : (
          <Grid item md={6}>
            <Typography align={"center"}>{icon}</Typography>
          </Grid>
        )}
      </Grid>
      <br />
      <br />
    </Paper>
  );
}

export default withStyles(InsightsStyles)(Insights);
