import React from 'react';
import PropTypes from 'prop-types';

const HelpCenter = props => {
    return (
        <div>
            Help Center
        </div>
    );
};

HelpCenter.propTypes = {};

export default HelpCenter;
