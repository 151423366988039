import React, { useEffect } from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import { Grid } from "@mui/material";
import {
  CompactCardContent,
  MainSectionCardHeader,
  CustomCard,
} from "../components/cards";
import CartContainer from "../components/onDemand/cart-container";
import { MINI_SERVICE_PAGE_TRACK } from "../actions/actionTypes";
import { connect } from "react-redux";
import Paragraph from "../NewInterfacePages/paragraph/paragraph.js";

function OnDemandService(props) {
  const { classes, theme } = props;

  // console.log("The values is in onDemandSevice", props);
  useEffect(() => {
    props.miniServicePageTrack();
  }, []);

  return (
    <CustomCard>
      <Paragraph
        para={
          "LXRGuide now offers a variety of On-Demand services. " +
          "We recognize that many of you may not have the In-house marketing " +
          "team to implement the powerful recommendations by our LXRGuide tool. " +
          "We are proud to introduce packages tailored to your marketing needs."
        }
      />
      <Grid container>
        {/* <Grid item md={8}>
                        <MainSectionCardHeader
                            style={{paddingBottom: 0}}
                            // title={"ONDEMAND SERVICES"}
                            subheader={"LXRGuide now offers a variety of On-Demand services. " +
                            "We recognize that many of you may not have the In-house marketing " +
                            "team to implement the powerful recommendations by our LXRGuide tool. " +
                            "We are proud to introduce packages tailored to your marketing needs."}
                            style={{textAlign: "justifyContent"}}
                        />

                    </Grid>*/}
        <Grid item md={12}>
          <CompactCardContent>
            <CartContainer initialPage={props.match.params.initialPage || 1} />
          </CompactCardContent>
        </Grid>
      </Grid>
    </CustomCard>
  );
}

const styles = {};

OnDemandService.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  miniServicePageTrack: () =>
    dispatch({
      type: MINI_SERVICE_PAGE_TRACK,
    }),
});

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(OnDemandService));
