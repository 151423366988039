import React from "react";
import bargraphbg from "../../assets/img/bargraphbg.png";
import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid";
import {
  OrangeTextSize12,
  GrayText,
  HeadLineTypography,
  BlueTitleTypography,
  CatalinaBlue,
} from "../../components/typography";
import { Typography } from "@mui/material/index";

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="xl" />;

const styles = (theme) => ({
  root: {
    [theme.breakpoints.down("xl")]: {
      backgroundImage: "unset",
    },
    backgroundImage: `url(${bargraphbg})`,
    textAlign: "center",
    backgroundRepeat: "no-repeat",
    height: 721,
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(4),
    backgroundPositionX: "right",
    backgroundPositionY: "bottom",
    [theme.breakpoints.down("xl")]: {
      height: 721,
    },
  },
  HeadLineTypographyStyle: {
    paddingTop: 80,
    paddingBottom: 29,
  },
  CatalinaBlueStyle: {
    [theme.breakpoints.up("xs")]: {
      paddingLeft: 15,
      paddingRight: 15,
      paddingBottom: 15,
      textAlign: "initial",
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: 180,
      paddingRight: 198,
      paddingBottom: 99,
      textAlign: "initial",
    },
  },
});

function DeightAndRetain(props) {
  const classes = props.classes;
  const width = props.width;
  return (
    <div>
      <Grid container className={classes.root}>
        <Grid item md={12} sm={12} xs={12}>
          <Typography
            align={"center"}
            variant={width == "sm" || width == "xs" ? "h3" : "subtitle1"}
            color={"textPrimary"}
            style={{ fontWeight: "700" }}
            gutterBottom
          >
            Delight & Retain
          </Typography>
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <CatalinaBlue className={classes.CatalinaBlueStyle}>
            For a ECommerce business to grow they need a loyal group of
            customers upon which to build their growth. On average, SaaS
            businesses suffer from monthly churn rates of between 5-7% per
            month. Delighting your clients and retaining them go hand-in-hand
            and this is why the third pillar of the Growth Model is to delight
            and retain.
            <br /> <br />
            In order to delight your clients, you need to continue to provide a
            supportive customer experience that is informative and unintrusive.
            Using a data-driven approach to identify customers at risk of
            leaving you can mitigate SaaS churn and recognise opportunities to
            grow your wallet share with existing clients.
            <br /> <br />
            We help our clients to delight their clients with:
            <br /> <br />
            Chatbots
            <br />
            Ticketing systems
            <br />
            Smart content
          </CatalinaBlue>
        </Grid>
      </Grid>
    </div>
  );
}

export default withWidth()(withStyles(styles)(DeightAndRetain));
