import * as types from "../actions/actionTypes";
export function autoPostingDataReducers(
  state = {
    isLoading: false,
    message: ""
  },
  action
) {
  switch (action.type) {
    case types.FETCH_AUTO_TASK_DATA_REQUESTED:
      // return console.log("inside case FETCH_AUTO_TASK_DATA_REQUESTED");
      console.log("======FETCH_AUTO_TASK_DATA_REQUESTED======>");
      return Object.assign({}, state, {
        isLoading: true,
        message: ""
      });
    case types.FETCH_AUTO_TASK_DATA_RECEIVED:
      let { ppcId,data } = action;
      console.log("FETCH_AUTO_TASK_DATA_RECEIVED======>", data);
      return Object.assign({}, state, {
        isLoading: false,
        ...action.data,
        ...action.ppcId,
        message: ""
      });
    case types.FETCH_AUTO_TASK_DATA_FAILED:
        console.log("======FETCH_AUTO_TASK_DATA_FAILED======>");
        return Object.assign({}, state, {
        isLoading: false,
        message: "FETCH_AUTO_TASK_DATA_FAILED",
      });
    default:
      return state;
  }
}
export function saveAutoPostingDataReducers(
  state = {
    isLoading: false,
    message: ""
  },
  action
) {
  switch (action.type) {
    case types.SAVE_AUTO_TASK_DATA_REQUESTED:
      // return console.log("inside case FETCH_AUTO_TASK_DATA_REQUESTED");
      console.log("======SAVE_AUTO_TASK_DATA_REQUESTED======>");
      return Object.assign({}, state, {
        isLoading: true,
        message: ""
      });
    case types.SAVE_AUTO_TASK_DATA_RECEIVED:
      let { ppcId,data } = action;
      console.log("SAVE_AUTO_TASK_DATA_RECEIVED======>", data);
      return Object.assign({}, state, {
        isLoading: false,
        ...action.data,
        ...action.ppcId,
        message: ""
      });
    case types.SAVE_AUTO_TASK_DATA_FAILED:
        console.log("======SAVE_AUTO_TASK_DATA_FAILED======>");
        return Object.assign({}, state, {
        isLoading: false,
        message: "SAVE_AUTO_TASK_DATA_FAILED",
      });
    default:
      return state;
  }
}


//below is the reference sanjay shared.
// export function signUpReducer(
//     state = {
//         isLoading: false,
//         message: "",
//     },
//     action
// ) {
//     switch (action.type) {
//         case types.SIGN_UP_REQUEST:
//             return Object.assign({}, state, {
//                 isLoading: true,
//             });
//         case types.SIGN_UP_SUCCESS:
//             let {resultData} = action;
//             localStorage.setItem("token", resultData.token);
//             return Object.assign({}, state, {
//                 isLoading: false,
//                 ...action.resultData,
//             });
//         case types.SIGN_UP_FAILURE:
//             return Object.assign({}, state, {
//               isLoading: false,
//               ...action.resultData,
//               message: "Sign up Failed",
//             });
//         default:
//             return state;
//     }
// }
