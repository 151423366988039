import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import {
  TextField,
  InputAdornment,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  Tooltip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Close } from "@mui/icons-material";

function KeywordFilterSearchBar(props) {
  const { handleSearchByKeyword, searchTerm, handleSearchTerm } = props;
  const [searched, setSearched] = useState(false);
  const previousSearchTermRef = useRef(searchTerm.searchText);

  const handleClearButton = () => {
    if (searched == true) {
      handleSearchByKeyword({ ...searchTerm, searchText: "" });
    }
    handleSearchTerm({ ...searchTerm, searchText: "" });
    setSearched(false);
  };

  const handleSearchKeywordChange = (e) => {
    handleSearchTerm({ ...searchTerm, searchText: e.target.value });
  };

  const handleBlur = () => {
    if (searchTerm.searchText === "" && searched) {
      handleSearchTerm({
        ...searchTerm,
        searchText: previousSearchTermRef.current,
      });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && searchTerm.searchText !== "") {
      setSearched(true);
      handleSearchByKeyword(searchTerm);
      previousSearchTermRef.current = searchTerm.searchText;
    }
  };

  const handleSelectChange = (e) => {
    handleSearchTerm({ ...searchTerm, contains: e.target.value });
    if (searchTerm.searchText != "") {
      handleSearchByKeyword({ ...searchTerm, contains: e.target.value });
    }
  };

  const handleClick = () =>{
    if(searchTerm.searchText != ""){
      setSearched(true);
      handleSearchByKeyword(searchTerm);
      previousSearchTermRef.current = searchTerm.searchText;
    }
  }

  return (
    <>
      <TextField
        className="keywordSearchBar"
        size="small"
        placeholder="Type here to search"
        autoComplete="off"
        hiddenLabel
        value={searchTerm.searchText}
        onChange={handleSearchKeywordChange}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={handleClearButton}
                disabled={searchTerm.searchText == ""}
              >
                <Close
                  fontSize="small"
                  sx={{
                    color: searchTerm.searchText == "" ? "#fff" : "inherit",
                  }}
                />
              </IconButton>
              <Tooltip title="Click or press enter to search" placement="top">
              <IconButton onClick={handleClick} className="keywordSearchBtn">
                <SearchIcon
                  fontSize="medium"
                  className="keywordSearchIcon"
                />
              </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        }}
        sx={{
          ".MuiOutlinedInput-root": {
            fontSize: "0.875rem",
            paddingRight: "0"
          },
        }}
      />
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <Select
          size="small"
          value={searchTerm.contains}
          onChange={handleSelectChange}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          className="fontSizeMenuItem"
        >
          <MenuItem value={true} className="fontSizeMenuItem">
            Contains
          </MenuItem>
          <MenuItem value={false} className="fontSizeMenuItem">
            Does not contain
          </MenuItem>
        </Select>
      </FormControl>
    </>
  );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KeywordFilterSearchBar);
