import { withStyles } from "@mui/styles";

import { TextField, Radio, Select, NativeSelect, Switch } from "@mui/material";

export const InputField = withStyles((theme) => ({
  root: {
    "& > div": {
      border: "1px solid",
      borderColor: theme.palette.border,
      borderRadius: 3,
    },
    "& > label": {
      paddingLeft: 5,
      color: theme.palette.text.primary,
    },
  },
}))(TextField);
export const DemoInputField = withStyles((theme) => ({
  root: {
    marginTop: 0,
    "& > div": {
      border: "1px solid",
      borderColor: theme.palette.border,
      borderRadius: 3,
    },
    "& > label": {
      paddingLeft: 5,
      color: theme.palette.text.primary,
    },
  },
}))(TextField);
export const JumpstartInputField = withStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    marginTop: 0,
    "& > div": {
      border: "1px solid",
      borderColor: theme.palette.border,
      borderRadius: 3,
    },
    "& > label": {
      paddingLeft: 5,
      color: theme.palette.text.primary,
    },
  },
}))(TextField);
export const ExpertHelpInputField = withStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    marginTop: 0,
    "& > div": {
      border: "1px solid",
      borderColor: theme.palette.border,
      borderRadius: 3,
    },
    "& > label": {
      paddingLeft: 5,
      color: theme.palette.text.primary,
    },
  },
}))(TextField);
export const InputFields = withStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    marginTop: 0,
    "& > div": {
      // position:"unset",
      "& > input": {
        padding: "8.5px 18px",
      },
      border: "1px solid",
      borderColor: theme.palette.text.primary,
      borderRadius: 3,
    },
    "& > label": {
      paddingLeft: 5,
      color: theme.palette.text.primary,
    },
  },
}))(TextField);
export const NativeSelectFields = withStyles((theme) => ({
  select: {
    border: "1px solid",
    borderColor: theme.palette.text.primary,
    borderRadius: 3,
    padding: "8.5px 16px",
  },
  icon: {
    color: theme.palette.text.primary,
  },
}))(NativeSelect);
export const SwitchFields = withStyles((theme) => ({
  switchBase: {
    height: 20,
  },
}))(Switch);
export const TDInputFields = withStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    marginTop: 0,
    focused: {
      outline: "unset !important",
    },
    "& > div": {
      outline: "unset !important",
      "& > fieldset": {
        outline: "unset !important",
      },
      "& > input": {
        padding: "8.5px 18px",
        "&:focus": {
          border: "1px solid",
          borderColor: theme.palette.secondary.main,
        },
      },
      border: "1px solid",
      borderColor: theme.palette.common.border,
      borderRadius: 3,
      // 1px solid #E3E6F4
    },
    "& > label": {
      paddingLeft: 5,
      color: theme.palette.text.primary,
    },
  },
}))(TextField);

/*
export const CustomRadio= withStyles(theme => ({
    root:{
        color:theme.palette.border,
        "&:checked":{
            color:theme.palette.orangeColor,
        }
    },
    checked:{
    },

}))(Radio);*/
