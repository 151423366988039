import React from "react";
import { connect } from "react-redux";
import { Button, Typography, TextField, CircularProgress, Grid, DialogContent } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import { storeFeedback } from "../actions";
import icons from "./icons";
import {
  CustomTypographyText,
  CustomDialogActions,
  BlueBtn,
  GreyBtn,
  CustomDialogBox,
  GreenCheck,
  IconTypography,
  CustomDialogTitle,
  SubHeading,
} from "../components/dialog.jsx";
import CheckIcon from "@mui/icons-material/Check.js";
import WaitingIcon from "@mui/icons-material/HourglassEmpty.js";
import Rating from "react-rating";
import FloatingCard from "./dashboard/floating-card";
import { FEEDBACK_TRACK } from "../actions/actionTypes";

class Feedback extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      toggleFeedback: false,
      returnToPreVersion: false,
      feedback: "",
      rating: 5,
      saving: false,
      response: [],
    };
  }

  handleRatingInput = (rating) => {
    this.setState({ rating: rating });
  };
  handleInput = (event) => {
    event.preventDefault();
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handleSubmit = async (event) => {
    event.preventDefault();
    const data = this.state;
    this.setState({ saving: true });
    this.state.response = await this.props.storeFeedback(
      this.state.feedback,
      this.state.rating
    );
    this.setState({ saving: false, feedback: "", rating: 5 });
  };
  handleClickOpen = () => {
    this.setState({ toggleFeedback: true, response: [] });
    this.props.feedbackTrack();
  };
  handleReturnToPreVersion = () => {
    if (this.props.triggerFeedback) {
      this.setState({ toggleFeedback: true, returnToPreVersion: true });
    } else {
      window.location.href = "/switch-ui.html";
    }
  };

  handleClose = () => {
    this.setState({ toggleFeedback: false });
    if (this.state.returnToPreVersion) {
      this.setState({ returnToPreVersion: false });
      window.location.href = "/switch-ui.html?style=Classic";
    }
  };

  render() {
    const { response, rating, feedback, saving, toggleFeedback } = this.state;
    const { handleRatingInput, handleInput, handleClose } = this;

    const renderFeedbackButtons = () => (
      <Grid container justifyContent={"flex-end"} alignItems={"center"}>
        <Grid item>
          <FloatingCard />
        </Grid>
        <Grid item>
          <CustomButtonLink
            variant="contained"
            onClick={this.handleClickOpen}
            style={{ cursor: "pointer" }}
          >
            Feedback <FeedbackIcon />
          </CustomButtonLink>
        </Grid>
        {/* <Grid item>
                    <Typography>
                        <CustomButtonLink onClick={this.handleReturnToPreVersion} variant="contained">
                            Return to previous Version
                        </CustomButtonLink>
                    </Typography>
                </Grid>*/}
      </Grid>
    );

    const renderResponse = () =>
      response[0] != null ? (
        <Grid container justifyContent={"center"}>
          <Grid item md={12}>
            <IconTypography variant="body1">
              <GreenCheck />
              {response}
            </IconTypography>
          </Grid>
        </Grid>
      ) : null;
    const renderForm = () =>
      response[0] == null ? (
        <DialogContent style={{ margin: 8 }}>
          <Grid container spacing={2} justifyContent={"flex-start"}>
            <Grid item md={12}>
              <CustomTypographyText>
                What do you think of new LXRGUIDE Experience?
              </CustomTypographyText>
            </Grid>
            <Grid item md={12}>
              <SubHeading variant={"subheading"}>
                Rating:&nbsp;&nbsp;&nbsp;&nbsp;
                <Rating
                  initialRating={rating}
                  emptySymbol={icons.EmptyStar}
                  fullSymbol={icons.FilledStar}
                  onChange={(rate) => handleRatingInput(rate)}
                />
              </SubHeading>
            </Grid>
            <Grid item md={12}>
              <CustomTypographyText>
                Tell us about your experience?
              </CustomTypographyText>
              <TextField
                type="text"
                value={feedback}
                name="feedback"
                multiline={true}
                rows={5}
                fullWidth={true}
                onChange={handleInput}
              />
            </Grid>
          </Grid>
        </DialogContent>
      ) : null;
    const renderLoading = () =>
      saving == true ? (
        <div style={{ textAlign: "center" }}>
          <CircularProgress />
        </div>
      ) : null;
    const renderformActionButtons = () =>
      saving == false && response[0] != null ? (
        <CustomDialogActions>
          <BlueBtn size="large" onClick={handleClose}>
            Done
          </BlueBtn>
        </CustomDialogActions>
      ) : null;
    const renderSuccessActionbutton = () =>
      saving == false && response[0] == null ? (
        <CustomDialogActions>
          <GreyBtn size="large" onClick={handleClose}>
            <WaitingIcon />
            Later
          </GreyBtn>
          <BlueBtn size="large" type="submit" form="feedbacFrom" autoFocus>
            <CheckIcon style={{ color: "#F58120" }} />
            Submit
          </BlueBtn>
        </CustomDialogActions>
      ) : null;

    return (
      <div>
        {renderFeedbackButtons()}
        <form onSubmit={this.handleSubmit} id="feedbacFrom">
          <CustomDialogBox
            open={toggleFeedback}
            aria-labelledby="Feedback"
            aria-describedby="Description"
            maxWidth={"sm"}
          >
            <CustomDialogTitle>
              {icons.BrandLogo}&nbsp;Feedback
            </CustomDialogTitle>
            {renderResponse()}
            {renderForm()}
            {renderLoading()}
            {renderformActionButtons()}
            {renderSuccessActionbutton()}
          </CustomDialogBox>
        </form>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  storeFeedback: (description, starRating) => {
    return dispatch(storeFeedback(description, starRating));
  },
  feedbackTrack: () => dispatch({ type: FEEDBACK_TRACK }),
});
const mapStateToProps = (state) => ({
  triggerFeedback: state.perf.triggerFeedback,
});

Feedback = connect(mapStateToProps, mapDispatchToProps)(Feedback);

export default Feedback;

const CustomButtonLink = withStyles((theme) => ({
  root: {
    backgroundColor: "rgba(30, 51, 78, 0.3)",
    margin: theme.spacing(1),
  },
  label: {
    fontWeight: "bold",
    color: theme.palette.common.white,
    "& :hover": {
      color: theme.palette.white,
    },
    textTransform: "capitalize",
  },
}))(Button);
const FeedbackIcon = withStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(1),
    fontSize: 20,
  },
}))(icons.Feedback);
