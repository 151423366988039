import {
  selectDateRange,
  customDateRange,
  fetchPerfData,
  onChangeKPIGraphType,
  selectGraphDateRange,
  customGraphDateRange,
} from "../actions";
import * as _ from "lodash";
import { TOGGLE_WELCOME_BANNER, OPEN_MARTCH } from "../actions/actionTypes";

let moment = require("moment");

// noinspection JSUnusedLocalSymbols
export const kpiValue = {
  RC: (cost, revenue, orders) => {
    if (cost == 0 || cost < 0 || revenue == 0 || revenue < 0) {
      return 0;
    } else {
      return revenue / cost;
    }
  },
  CR: (cost, revenue, orders) => {
    if (revenue == 0 || revenue < 0 || cost < 0 || cost == 0) {
      return 0;
    } else {
      return cost / revenue;
    }
  },
  CPO: (cost, revenue, orders) => {
    if (orders == 0) {
      return 0;
    } else {
      return cost / orders;
    }
  },
  CPL: (cost, revenue, orders) => {
    if (orders == 0) {
      return 0;
    } else {
      return cost / orders;
    }
  },
};
export const KpiString = {
  RC: "R/C",
  CR: "C/R",
  CPO: "CPO",
  CPL: "CPL",
};
export const KpiTypeNumbers = {
  RC: 0,
  CR: 1,
  CPO: 2,
  CPL: 3,
};

export const kpiLabel = {
  RC: "Orders",
  CR: "Orders",
  CPO: "Orders",
  CPL: "Leads",
};

export const daysCount = (dateRange) => {
  var a = moment(dateRange.fromDate);
  // console.log("A value", a);
  var b = moment(dateRange.toDate);
  // console.log("B value", b);
  var daysCount = b.diff(a, "days");
  // console.log("days:", daysCount);
  return daysCount + 1;
};

function kpiHistoryFromPerfHistory(history, kpiType, dateRange, kpiTarget) {
  history = history
    .filter((x) => x.ADVERTISING_CHANNEL_TYPE === "ALL")
    .filter((v) =>
      moment(v.day).isBetween(dateRange.fromDate, dateRange.toDate, null, "[]")
    )
    .map((x) => ({
      day: moment(x.day).unix(),
      KPI: kpiValue[kpiType](x.COST, x.CONVERSIONVALUE, x.CONVERSIONS),
      KPITarget: kpiTarget,
    }));
  var days = [...Array(daysCount(dateRange)).keys()].map((i) =>
    moment(dateRange.toDate).subtract(i, "days").unix()
  );
  history = _.keyBy(history, "day");
  history = days.map(
    (day) => history[day] || { day: day, KPI: 0, KPITarget: kpiTarget }
  );

  return history;
}

function campaignsSnapshot(history, dateRange, kpiType) {
  const today = moment().startOf("day");
  // console.log("dateRange=", dateRange);
  return (
    _.chain(history)
      // .filter(x => x.ADVERTISING_CHANNEL_TYPE != "ALL")
      .groupBy("ADVERTISING_CHANNEL_TYPE")
      .mapValues((a) =>
        a.filter((v) =>
          moment(v.day).isBetween(
            dateRange.fromDate,
            dateRange.toDate,
            null,
            "[]"
          )
        )
      )
      .map((v, k) => ({
        type: k,
        impressions: _.sumBy(v, "IMPRESSIONS"),
        clicks: _.sumBy(v, "CLICKS"),
        orders: _.sumBy(v, "CONVERSIONS"),
        spend: _.sumBy(v, "COST"),
        revenue: _.sumBy(v, "CONVERSIONVALUE"),
      }))
      .map((v) => ({
        ctr: v.clicks / v.impressions,
        kpiValue: kpiValue[kpiType](v.spend, v.revenue, v.orders),
        ...v,
      }))
      .reverse()
      .value()
  );
}

function revenueTarget({ kpiType, kpiTarget, budget }) {
  switch (kpiType) {
    case "RC":
      return Math.round(budget * kpiTarget);
    case "CR":
      return Math.round(budget / kpiTarget);
    default:
      return null;
  }
}

function orderTarget({ kpiType, kpiTarget, budget }) {
  switch (kpiType) {
    case "CPO":
      return Math.round(budget / kpiTarget);
    case "CPL":
      return Math.round(budget / kpiTarget);
    default:
      return null;
  }
}

export function campaignsSnapshotPrevious(history, dateRange, kpiType) {
  const today = moment().startOf("day");
  let toDate = moment(dateRange.toDate);
  let fromDate = moment(dateRange.fromDate);
  // console.log("history", history);
  const selectedDateDifferenceCount = toDate.diff(fromDate, "days");
  // console.log("selectedDateDifferenceCount=", selectedDateDifferenceCount);
  fromDate = moment(dateRange.fromDate)
    .subtract(selectedDateDifferenceCount + 1, "days")
    .format("YYYY-MM-DD");
  toDate = moment(dateRange.toDate)
    .subtract(selectedDateDifferenceCount + 1, "days")
    .format("YYYY-MM-DD");
  // console.log("dateRangePrevioustoDate=", fromDate);
  // console.log("dateRangePrevioustoDate=", toDate);
  return (
    _.chain(history)
      // .filter(x => x.ADVERTISING_CHANNEL_TYPE != "ALL")
      .groupBy("ADVERTISING_CHANNEL_TYPE")
      .mapValues((a) =>
        a.filter((v) => moment(v.day).isBetween(fromDate, toDate, null, "[]"))
      )
      .map((v, k) => ({
        typeP: k,
        impressionsP: _.sumBy(v, "IMPRESSIONS"),
        clicksP: _.sumBy(v, "CLICKS"),
        ordersP: _.sumBy(v, "CONVERSIONS"),
        spendP: _.sumBy(v, "COST"),
        revenueP: _.sumBy(v, "CONVERSIONVALUE"),
      }))
      .map((v) => ({
        ctrP: v.clicksP / v.impressionsP,
        kpiValueP: kpiValue[kpiType](v.spendP, v.revenueP, v.ordersP),
        ...v,
      }))
      .reverse()
      .value()
  );
}

function currentMonthRunRate(history, kpiType) {
  const monthStart = moment().startOf("month");
  const today = moment().startOf("day");
  history = _.chain(history)
    .filter((x) => x.ADVERTISING_CHANNEL_TYPE === "ALL")
    .filter((x) => monthStart.isSameOrBefore(x.day))
    .value();
  return {
    spend: Math.round(_.sumBy(history, "COST")),
    revenue: Math.round(_.sumBy(history, "CONVERSIONVALUE")),
    orders: Math.round(_.sumBy(history, "CONVERSIONS")),
    kpiValue: kpiValue[kpiType](
      Math.round(_.sumBy(history, "COST")),
      Math.round(_.sumBy(history, "CONVERSIONVALUE")),
      Math.round(_.sumBy(history, "CONVERSIONS"))
    ),
    days: today.diff(monthStart, "day"),
    currentMonth: moment().format("MMMM"),
    daysInMonth: moment().daysInMonth(),
  };
}

function selectedDateRangeKPIvalue(history, kpiType, dateRange) {
  history = _.chain(history)
    .filter((x) => x.ADVERTISING_CHANNEL_TYPE === "ALL")
    .filter((v) =>
      moment(v.day).isBetween(dateRange.fromDate, dateRange.toDate, null, "[]")
    )
    .value();
  return {
    selectedDateRangeKPIValue: kpiValue[kpiType](
      Math.round(_.sumBy(history, "COST")),
      Math.round(_.sumBy(history, "CONVERSIONVALUE")),
      Math.round(_.sumBy(history, "CONVERSIONS"))
    ),
  };
}

export function displayDateRange(daterange) {
  return {
    dateFrom: moment(daterange.fromDate).format("MMM DD, YYYY"),
    dateTo: moment(daterange.toDate).format("MMM DD, YYYY"),
  };
}

export const mapStateToProps = (state) => {
  console.log(state);
  const props = {
    isLoading: state.isLoading,
    isKpiGraphLoading: state.perf.isKpiGraphLoading,
    selectedDateRange: state.selectedDateRange,
    selectedGraphDateRange: state.selectedGraphDateRange,
    // kpiHistory: kpiHistoryFromPerfHistory(state.perf.history, state.perf.kpiType, state.selectedDateRange, state.perf.kpiTarget, state.perf.currency),
    kpiHistory: kpiHistoryFromPerfHistory(
      state.perf.history,
      state.perf.kpiTypeUi,
      state.selectedGraphDateRange,
      state.perf.kpiTarget,
      state.perf.currency
    ),
    campaignsSnapshot: campaignsSnapshot(
      state.perf.history,
      state.selectedDateRange,
      state.perf.kpiType
    ),
    campaignsSnapshotPrevious: campaignsSnapshotPrevious(
      state.perf.history,
      state.selectedDateRange,
      state.perf.kpiType
    ),
    currentMonthRunRate: currentMonthRunRate(
      state.perf.history,
      state.perf.kpiType
    ),
    revenueTarget: revenueTarget(state.perf),
    orderTarget: orderTarget(state.perf),
    selectedDateRangeKPIValue: selectedDateRangeKPIvalue(
      state.perf.history,
      state.perf.kpiTypeUi,
      state.selectedGraphDateRange
    ),
    currencySymbol: state.perf.currency,
    displayDateRange: displayDateRange(state.selectedDateRange),
    displayGraphDateRange: displayDateRange(state.selectedGraphDateRange),
    viewValue: state.dashboard.viewValue,
    selectedAccountInfo: state.userInformation.selectedAccountInfo,
    userInformation: state.userInformation,
    jumpstart: state.jumpstart,
    isConversionAlert: state.perf.isConversionAlert,
    isHeaderDrawer: state.header.open,
    isAdminUserDashboard: state.userInformation.isAdminUserDashboard,
    userInfo: state.userInformation.userInfo,
    productTour: state.productTour,
    martechServices: state.martechServices,
    ...state.perf,
  };
  return props;
};

export const mapDispatchToProps = (dispatch) => ({
  onSelectDateRange: (event) => dispatch(selectDateRange(event.target.value)),
  onSelectGraphDateRange: (event) =>
    dispatch(selectGraphDateRange(event.target.value)),
  customDateRanges: (dateRange) => dispatch(customDateRange(dateRange)),
  customGraphDateRanges: (dateRange) =>
    dispatch(customGraphDateRange(dateRange)),
  fetchPerfData: () => dispatch(fetchPerfData()),
  onChaneKPIGraphValue: (KPiType) => dispatch(onChangeKPIGraphType(KPiType)),
  welcomeToggle: () => dispatch({ type: TOGGLE_WELCOME_BANNER }),
  //test martech banner
  openMartech: () => dispatch({ type: OPEN_MARTCH }),
});

const taskStatus = {
  NEW: 0,
  SKIPPED: 1,
  COMPLETED: 2,
};

function sumTasks(tasks) {
  let hasTaskData = tasks[0].taskDataList;
  return Object.assign({}, tasks[0], {
    taskIds: _.map(tasks, "taskId"),
    costForecast: _.sumBy(tasks, "costForecast"),
    revenueForecast: _.sumBy(tasks, "revenueForecast"),
    orderForecast: _.sumBy(tasks, "orderForecast"),
    timeEstimate: _.sumBy(tasks, "timeEstimate"),
    taskDataList: hasTaskData ? _.flatMap(tasks, "taskDataList") : null,
  });
}

export function getCurrentTasks(tasks) {
  return _.chain(tasks)
    .filter((t) =>
      [taskStatus.NEW, taskStatus.SKIPPED, taskStatus.COMPLETED].includes(
        t.actionStatus
      )
    )
    .groupBy("taskTypeId")
    .map(sumTasks)
    .value();
}
