import React, { Fragment } from "react";
import {
  adminUserTable,
  deleteUser,
  loadUserInfo,
  endJumpStart,
  getUserNote,
  getUserAccounts,
  updateUserNote,
  handleDeleteUserAlert,
  disableAccount,
} from "../actions";
import { connect } from "react-redux";
import UserNoteTable from "../components/admin/user-note-table";
import NumberFormat from "react-number-format";
import * as _ from "lodash";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Paper,
  Snackbar,
  TextField,
  Typography,
  Table,
  TableBody,
  Fab,
  TableRow,
  TableCell,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Dialog,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import SnackbarContent from "@mui/material/SnackbarContent";
import CircularProgress from "@mui/material/CircularProgress";
import AdminAccountDetails from "../../src/components/admin/admin-account-details.jsx";
// import CustomDialog from '../components/admin/dialog-dynamic';
import CustomDialog from "../components/dialog-generic";
import icons from "../components/icons";
import * as types from "../actions/actionTypes";
import {
  getUserInfo,
  getChannel,
  getSearchEngineIcon,
  getSourceOfFunds,
  setTerminationDate,
  jumpStartField,
  setAccountImportIds,
  nullCheck,
  getSearchEngageFormat,
  IconText,
  IN_ACTIVE_PAYMENT,
  IN_ACTIVE,
  UNSUBSCRIBED,
  productFeedOrReportsCols,
  backButton,
} from "../components/admin/templates";
import { createHashHistory } from "history";
import { JUMP_START, STANDARD } from "../actions/actionTypes";

let moment = require("moment");

const CustomCard = withStyles((theme) => ({
  root: {
    overflow: "unset",
    width: "100%",
    margin: "25px 0",
    display: "inline-block",
    position: "relative",
    boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
    background: "#fff",
    borderRadius: 3,
  },
}))(Card);
const CustomTableCell = withStyles((theme) => ({
  root: {
    padding: "4px 56px 4px 0px",
    maxWidth: 200,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}))(TableCell);
const CustomTableCell2 = withStyles((theme) => ({
  root: {
    padding: "4px 56px 4px 0px",
    maxWidth: 200,
    fontSize: ".875 rem", // This is addition
  },
}))(TableCell);
const CustomHeadTableCell = withStyles((theme) => ({
  root: {
    padding: "4px 10px 4px 0px",
    fontSize: "1 rem", // This is addition
  },
}))(TableCell);
const CustomSnackbarContent = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.success,
    fontWeight: "bold",
  },
}))(SnackbarContent);
const CustomIconTypography = withStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
}))(Typography);

const CustomCardHeader = withStyles((theme) => ({
  root: {
    flex: "none",
    margin: "-20px 15px 0",
    padding: 15,
    background: "linear-gradient(60deg, #ffa726, #fb8c00)",
    boxShadow:
      "0 12px 20px -10px rgba(255, 152, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 152, 0, 0.2)",
    lineHeight: "1.5em",
    borderRadius: 3,
  },
}))(CardHeader);
const CustomCardContent = withStyles((theme) => ({
  root: {
    minHeight: "30vh",
    maxHeight: "30vh",
    overflowX: "hidden",
    overflowY: "auto",
  },
}))(CardContent);
const CustomCardContent1 = withStyles((theme) => ({
  root: {
    minHeight: "50vh",
    maxHeight: "50vh",
    overflowX: "hidden",
    overflowY: "auto",
  },
}))(CardContent);

const AdminUserStyles = (theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  hyperlinks: {
    textDecoration: "unset",
  },
  icons: {
    margin: theme.spacing(1),
    cursor: "pointer",
  },
  fabButton: {
    color: theme.palette.text.primary,
  },
  userBtn: {
    marginTop: 4,
  },
});

class UserDetailsPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      edit: false,
      successAlert: false,
      vertical: "top",
      horizontal: "right",
      response: {},
      adminName: "",
      adminNameError: "",
      note: "",
      noteError: "",
      deleteAlert: false,
      pauseAlert: false,
    };
    this.userId = this.props.match.params.id;
  }

  async componentDidMount() {
    await this.loadUser();
    window.scrollTo(0, 0);
  }

  async loadUser() {
    let { userInfoList, userNoteMap } = this.props.adminUserTable;
    const { userInfo } = this.props;
    console.log("userInfo=", this.props.userInfo);
    if (userInfo == null || userInfo == undefined) {
      await this.props.loadUserInfo(this.userId);
    }

    console.log("userInfoList===", userInfoList);
    if (!_.has(userNoteMap, this.userId)) {
      await this.props.getUserNote(this.userId);
    }
    if (userInfoList.length == 0) {
      const {
        currentPage,
        sorting,
        statusFilter,
        searchTerm,
        channelFilter,
        userTypeToggle,
      } = this.props.adminUserTable;
      await this.props.getAdminUserTableData(
        currentPage,
        sorting,
        true,
        statusFilter,
        searchTerm,
        channelFilter,
        userTypeToggle
      );
    }
  }

  handleToggle(note) {
    this.setState({ note: note, edit: !this.state.edit });
  }

  validate = () => {
    let isError = false;
    const errors = {};
    if (this.state.adminName.length == 0) {
      isError = true;
      errors.adminNameError = "This Field is required";
    } else {
      errors.adminNameError = "";
    }
    if (this.state.note.length == 0) {
      isError = true;
      errors.noteError = "This Field is required";
    } else {
      errors.noteError = "";
    }
    if (isError) {
      this.setState({
        ...this.state,
        ...errors,
      });
    }
    return isError;
  };
  handleChange = async (event) => {
    event.preventDefault();
    await this.setState({
      [event.target.name]: event.target.value,
    });
    console.log("Note State Updated=", this.state);
  };

  handleClose = () => {
    this.setState({ successAlert: false });
  };
  handleDelete = async () => {
    if (!window.confirm("Are you sure you wish to delete this User?")) {
      return null;
    }
    const { userInfo } = this.props;
    let response = await this.props.deleteUser(userInfo.userId);
    console.log("res=", response);
    if (response.isDeleted && response.isDeleted == true) {
      this.setState({
        deleteAlert: !this.state.deleteAlert,
        deleteUserSuccess: "User Deleted Successfully",
      });
    } else {
      this.setState({
        deleteAlert: !this.state.deleteAlert,
        deleteUserError: "Error in deleting user",
      });
    }
  };
  clearNotification = async () => {
    // this.props.handleDeleteUserAlert();
    await this.setState({ deleteAlert: !this.state.deleteAlert });
    const history = createHashHistory();
    history.goBack();
  };
  handleEndBtn = async () => {
    if (
      !window.confirm(
        "The client will be charged in the next day after you click OK. Are you sure, you want to proceed?"
      )
    ) {
      return null;
    }
    const { userInfo } = this.props;
    await this.props.endJumpStart(userInfo.userId);
    // await this.loadUser();
  };
  handlePauseToggle = () => {
    this.setState({ pauseAlert: !this.state.pauseAlert });
  };
  handleActionPauseDialog = () => {
    this.handlePauseToggle();
    const { userInfo } = this.props;
    this.props.disableAccount(userInfo.userId);
  };
  handleSubmit = async () => {
    const { userInfo } = this.props;
    let error = this.validate();
    if (!error) {
      this.setState({ isSaving: true, noteError: "", adminNameError: "" });
      let response = await this.props.updateNote(
        this.state.note,
        userInfo.userId,
        this.state.adminName
      );
      this.setState({ isSaving: false });
      if (response && response[1] !== null) {
        this.setState({ successAlert: true, note: "", adminName: "" });
      }
    }
  };

  render() {
    const { columns, vertical, horizontal, deleteAlert } = this.state;

    const productFeedDetailsOrReport = productFeedOrReportsCols.call(this);
    let { userInfo, isLoading, classes } = this.props;
    let { userNoteMap } = this.props.adminUserTable;
    const accounts = userInfo
      ? setAccountImportIds(userInfo.clientInfo, userInfo.accountImportSucess)
      : [];
    const userNoteArray =
      userInfo && userNoteMap != null ? userNoteMap[userInfo.userId] : [];
    return (
      <Fragment>
        <Paper style={{ paddingLeft: 15, paddingRight: 15 }}>
          {isLoading == true ? (
            <CircularProgress />
          ) : (
            <div>
              {backButton("/admin")}
              <Grid container spacing={2} justifyContent={"flex-start"}>
                <Grid item md={4}>
                  <CustomCard>
                    <CustomCardHeader title="Personal Details" />
                    {userInfo ? (
                      <CustomCardContent>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <CustomHeadTableCell component="th" scope="row">
                                <b>User Name :</b>
                              </CustomHeadTableCell>
                              <CustomTableCell2 align={"left"}>
                                &nbsp;&nbsp;{nullCheck(userInfo.username)}
                              </CustomTableCell2>
                            </TableRow>
                            <TableRow>
                              <CustomHeadTableCell component="th" scope="row">
                                <b>Email Address :</b>
                              </CustomHeadTableCell>
                              <CustomTableCell2 align={"left"}>
                                <IconText icon={icons.envelopeResizable(12)}>
                                  <a href={`mailto:${userInfo.emailId}`}>
                                    {nullCheck(userInfo.emailId)}
                                  </a>
                                </IconText>
                              </CustomTableCell2>
                            </TableRow>
                            <TableRow>
                              <CustomHeadTableCell component="th" scope="row">
                                <b> Phone Number :</b>
                              </CustomHeadTableCell>

                              <CustomTableCell2 align={"left"}>
                                <IconText icon={icons.phoneResizable(12)}>
                                  {userInfo.phoneNumber &&
                                  nullCheck(userInfo.phoneNumber) != "" &&
                                  userInfo.phoneNumber.length != 0 ? (
                                    <a href={`tel:${userInfo.phoneNumber}`}>
                                      <NumberFormat
                                        value={userInfo.phoneNumber}
                                        displayType={"text"}
                                        format="###-###-####"
                                      />
                                    </a>
                                  ) : (
                                    "-"
                                  )}
                                </IconText>
                              </CustomTableCell2>
                            </TableRow>
                            <TableRow>
                              <CustomHeadTableCell component="th" scope="row">
                                <b>Company Name :</b>
                              </CustomHeadTableCell>
                              <CustomTableCell2 align={"left"}>
                                <IconText icon={icons.domainResizable("16px")}>
                                  {userInfo.companyName &&
                                  nullCheck(userInfo.companyName) != "" &&
                                  userInfo.companyName.length != 0 ? (
                                    <div
                                      className={"flex"}
                                      style={{ alignItems: "end" }}
                                    >
                                      {nullCheck(userInfo.companyName)}
                                    </div>
                                  ) : (
                                    "-"
                                  )}
                                </IconText>
                              </CustomTableCell2>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </CustomCardContent>
                    ) : null}
                  </CustomCard>
                </Grid>
                <Grid item md={4}>
                  <CustomCard>
                    <CustomCardHeader title="Creation Details" />
                    {userInfo ? (
                      <CustomCardContent>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <CustomHeadTableCell component="th" scope="row">
                                <b>Registration Date :</b>
                              </CustomHeadTableCell>
                              <CustomTableCell align={"left"}>
                                {userInfo.registrationDate}
                              </CustomTableCell>
                            </TableRow>
                            <TableRow>
                              <CustomHeadTableCell component="th" scope="row">
                                <b>Channel :</b>
                              </CustomHeadTableCell>
                              <CustomTableCell align={"left"}>
                                {getChannel(userInfo.type)}
                              </CustomTableCell>
                            </TableRow>
                            <TableRow>
                              <CustomHeadTableCell component="th" scope="row">
                                <b>Netelixir Representative Email :</b>
                              </CustomHeadTableCell>
                              <CustomTableCell2 align={"left"}>
                                {userInfo.neRepresentativeMailId &&
                                nullCheck(userInfo.neRepresentativeMailId) !=
                                  "" &&
                                userInfo.neRepresentativeMailId.length != 0
                                  ? nullCheck(userInfo.neRepresentativeMailId)
                                  : "-"}
                              </CustomTableCell2>
                            </TableRow>

                            {userInfo.type == types.UPS_CLIENT ? (
                              <TableRow>
                                <CustomHeadTableCell component="th" scope="row">
                                  <b>UPS Sales Professional Name :</b>
                                </CustomHeadTableCell>
                                <CustomTableCell align={"left"}>
                                  {nullCheck(userInfo.upsSalesProfessionalName)}
                                </CustomTableCell>
                              </TableRow>
                            ) : null}
                            {userInfo.type == types.UPS_CLIENT ||
                            userInfo.type == types.UPS_CLIENT_WITH_CTP ? (
                              <TableRow>
                                <CustomHeadTableCell component="th" scope="row">
                                  <b>UPS Region:</b>
                                </CustomHeadTableCell>
                                <CustomTableCell2 align={"left"}>
                                  {nullCheck(userInfo.upsRegion)}
                                </CustomTableCell2>
                              </TableRow>
                            ) : null}
                          </TableBody>
                        </Table>
                      </CustomCardContent>
                    ) : null}
                  </CustomCard>
                </Grid>
                <Grid item md={4}>
                  <CustomCard>
                    <CustomCardHeader title="Payment Details" />
                    {userInfo ? (
                      <CustomCardContent>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <CustomHeadTableCell component="th" scope="row">
                                <b> Pricing Plan:</b>
                              </CustomHeadTableCell>
                              <CustomTableCell align={"left"}>
                                {userInfo.pricingPlan
                                  ? userInfo.pricingPlan.packageName
                                  : "-"}
                              </CustomTableCell>
                            </TableRow>
                            <TableRow>
                              <CustomHeadTableCell component="th" scope="row">
                                <b> Source of Funds:</b>
                              </CustomHeadTableCell>
                              <CustomTableCell align={"left"}>
                                {getSourceOfFunds(userInfo.type)}
                              </CustomTableCell>
                            </TableRow>
                            <TableRow>
                              <CustomHeadTableCell component="th" scope="row">
                                <b>Remaining Prepaid Balance:</b>
                              </CustomHeadTableCell>
                              <CustomTableCell align={"left"}>
                                ${userInfo.advanceRemaining}
                              </CustomTableCell>
                            </TableRow>
                            {(userInfo.status == IN_ACTIVE ||
                              userInfo.status == IN_ACTIVE_PAYMENT) &&
                            userInfo.endDate != "-" ? (
                              <TableRow>
                                <CustomHeadTableCell component="th" scope="row">
                                  <b>End Date:</b>
                                </CustomHeadTableCell>
                                <CustomTableCell align={"left"}>
                                  {userInfo.endDate}
                                </CustomTableCell>
                              </TableRow>
                            ) : null}
                            {userInfo.status != IN_ACTIVE &&
                            userInfo.status != IN_ACTIVE_PAYMENT ? (
                              <TableRow>
                                <CustomHeadTableCell component="th" scope="row">
                                  <b>Next Payment Date:</b>
                                </CustomHeadTableCell>
                                <CustomTableCell align={"left"}>
                                  {userInfo.endDate != "-"
                                    ? moment(userInfo.endDate)
                                        .add(1, "days")
                                        .format("MM/DD/YYYY")
                                    : "-"}
                                </CustomTableCell>
                              </TableRow>
                            ) : null}
                          </TableBody>
                        </Table>
                      </CustomCardContent>
                    ) : null}
                  </CustomCard>
                </Grid>
                <Grid item md={4}>
                  <CustomCard>
                    <CustomCardHeader title="Status" />
                    {userInfo ? (
                      <CustomCardContent1>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <CustomHeadTableCell component="th" scope="row">
                                <b> Account Status:</b>
                              </CustomHeadTableCell>
                              <CustomTableCell align={"left"}>
                                {nullCheck(userInfo.status)}
                              </CustomTableCell>
                            </TableRow>
                            <Fragment>
                              <TableRow>
                                <CustomHeadTableCell component="th" scope="row">
                                  <b> OBF Status:</b>
                                </CustomHeadTableCell>
                                <CustomTableCell2 align={"left"}>
                                  {nullCheck(userInfo.obfURL)}
                                </CustomTableCell2>
                              </TableRow>
                            </Fragment>
                            {nullCheck(userInfo.signupURL) != "-" ? (
                              <TableRow>
                                <CustomHeadTableCell component="th" scope="row">
                                  <b>Registration Link:</b>
                                </CustomHeadTableCell>
                                <CustomTableCell2 align={"left"}>
                                  {nullCheck(userInfo.signupURL)}
                                </CustomTableCell2>
                              </TableRow>
                            ) : null}
                            <TableRow>
                              <CustomHeadTableCell component="th" scope="row">
                                <b>Account Termination Date:</b>
                              </CustomHeadTableCell>
                              <CustomTableCell align={"left"}>
                                {setTerminationDate(
                                  userInfo.permission,
                                  userInfo.endDate,
                                  userInfo.status
                                )}
                              </CustomTableCell>
                            </TableRow>
                            {userInfo.pricingPlan &&
                            userInfo.pricingPlan.pricingPlanId == JUMP_START &&
                            userInfo.status !== types.NOT_YET_REGISTERED ? (
                              <TableRow>
                                <Fragment>
                                  {jumpStartField(
                                    userInfo.pricingPlan,
                                    userInfo.obf,
                                    classes,
                                    this.handleEndBtn,
                                    userInfo.status
                                  )}
                                </Fragment>
                              </TableRow>
                            ) : null}
                            {userInfo.pricingPlan &&
                            userInfo.pricingPlan.pricingPlanId == STANDARD &&
                            userInfo.obf == 4 ? (
                              <TableRow>
                                <CustomHeadTableCell component="th" scope="row">
                                  <b>End JumpStart:</b>
                                </CustomHeadTableCell>
                                <CustomTableCell>Completed</CustomTableCell>
                              </TableRow>
                            ) : null}
                            {userInfo.password == null ||
                            userInfo.password == "" ? (
                              <TableRow>
                                <CustomHeadTableCell component="th" scope="row">
                                  <b>Delete:</b>
                                </CustomHeadTableCell>
                                <CustomTableCell align={"left"}>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={this.handleDelete}
                                  >
                                    Delete
                                    <icons.Delete />
                                  </Button>
                                </CustomTableCell>
                              </TableRow>
                            ) : null}
                            <TableRow>
                              <CustomHeadTableCell component="th" scope="row">
                                <b>Contract End Date:</b>
                              </CustomHeadTableCell>
                              <CustomTableCell align={"left"}>
                                {userInfo.contractEnd}
                              </CustomTableCell>
                            </TableRow>
                            {userInfo.status === UNSUBSCRIBED ? (
                              <TableRow>
                                <CustomHeadTableCell component="th" scope="row">
                                  <b>Unsubscribed Date:</b>
                                </CustomHeadTableCell>
                                <CustomTableCell align={"left"}>
                                  {nullCheck(userInfo.unsubscribedDate) != "-"
                                    ? moment(
                                        new Date(userInfo.unsubscribedDate)
                                      ).format("MM/DD/YYYY")
                                    : "-"}
                                </CustomTableCell>
                              </TableRow>
                            ) : null}

                            {productFeedDetailsOrReport(
                              userInfo.obf,
                              userInfo.userId
                            )}

                            {userInfo.permission != Boolean.FALSE &&
                            userInfo.status == "Active" ? (
                              <TableRow>
                                <CustomHeadTableCell component="th" scope="row">
                                  <b>Change Status:</b>
                                </CustomHeadTableCell>
                                <CustomTableCell align={"left"}>
                                  {userInfo.unsubscribed == 3 ? (
                                    <Typography component={"p"}>
                                      Paused
                                    </Typography>
                                  ) : (
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      className={classes.button}
                                      onClick={this.handlePauseToggle}
                                    >
                                      Pause
                                    </Button>
                                  )}

                                  <CustomDialog
                                    title={"Account will be disabled"}
                                    content={
                                      <Typography>
                                        The account will be disabled and the
                                        billing cycle is also paused after you
                                        click OK.
                                        <br /> Are you sure, you want to
                                        proceed?
                                      </Typography>
                                    }
                                    open={this.state.pauseAlert}
                                    handleAction={this.handleActionPauseDialog}
                                    handleClose={this.handlePauseToggle}
                                    titleColor={"unSuccess"}
                                  />
                                </CustomTableCell>
                              </TableRow>
                            ) : null}
                          </TableBody>
                        </Table>
                      </CustomCardContent1>
                    ) : null}
                  </CustomCard>
                </Grid>
                {/*<Grid item md={4}>
                                    <CustomCard>
                                        <CustomCardHeader
                                            title="Accounts"
                                            action={
                                                <Button variant="outlined"
                                                        href={`/selected-user-view.html?userId=${this.userId}`}
                                                        className={classes.userBtn}>
                                                    User Dashboard&nbsp;&nbsp;
                                                    {icons.Dashboard}
                                                </Button>
                                            }
                                        />
                                        <CustomCardContent>
                                            <Table>
                                                <TableBody>
                                                    {(userInfo && accounts.length > 0) ? accounts.map((account, key) =>
                                                        <Fragment key={key}>
                                                            <TableRow>
                                                                <CustomHeadTableCell component="th" scope="row">
                                                                    <b>{getSearchEngineIcon(account.searchEngineId)}:</b>
                                                                </CustomHeadTableCell>
                                                                <CustomTableCell>
                                                                    {getSearchEngageFormat(account.searchEngineId, account.seAccountId, account.ppcId, userInfo.userId)}
                                                                </CustomTableCell>
                                                            </TableRow>
                                                        </Fragment>
                                                    ) : "No Account Integrated Yet"}
                                                </TableBody>
                                            </Table>
                                        </CustomCardContent>
                                    </CustomCard>
                                </Grid>*/}

                {userInfo && (
                  <Grid item md={8}>
                    <CustomCard>
                      <CustomCardHeader title="User Note" />
                      <CustomCardContent1>
                        <div>
                          <div>
                            <UserNoteTable
                              rows={userNoteArray}
                              isLoading={isLoading}
                              tableColumnExtensions={
                                this.state.tableColumnExtensions
                              }
                              userInfoNote={userInfo.note}
                              userInfoRegDate={userInfo.registrationDate}
                            />
                          </div>

                          {this.state.isSaving ? (
                            <Typography className={"fullWidth"}>
                              <CircularProgress />
                            </Typography>
                          ) : null}
                          <Grid container spacing={2} alignItems={"center"}>
                            <Grid item>
                              <TextField
                                label="Admin Name"
                                value={this.state.adminName}
                                onChange={this.handleChange}
                                margin="normal"
                                name={"adminName"}
                                variant="outlined"
                                error={this.state.adminNameError != ""}
                                helperText={this.state.adminNameError}
                              />
                            </Grid>
                            <Grid item style={{ flexGrow: "1" }}>
                              <TextField
                                inputProps={{ maxLength: 200 }}
                                placeholder="200"
                                label="New Note"
                                value={this.state.note}
                                onChange={this.handleChange}
                                margin="normal"
                                name={"note"}
                                variant="filled"
                                multiline
                                rows={3}
                                error={this.state.noteError != ""}
                                helperText={this.state.noteError}
                                fullWidth
                              />
                            </Grid>
                          </Grid>
                          <Typography align={"right"}>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => this.handleSubmit()}
                            >
                              Submit <icons.Check />
                            </Button>
                          </Typography>
                        </div>
                      </CustomCardContent1>
                    </CustomCard>
                  </Grid>
                )}
                <Grid item md={12}>
                  <AdminAccountDetails userId={this.userId} />
                </Grid>
              </Grid>
            </div>
          )}
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={this.state.successAlert}
            onClose={this.handleClose}
            ContentProps={{
              "aria-describedby": "message-id",
            }}
            autoHideDuration={6000}
          >
            <CustomSnackbarContent
              onClose={this.handleClose}
              variant="success"
              message={
                <span className={"flex alignItemsCenter"}>
                  <icons.CheckCircle />
                  &nbsp;{"Successfully saved"}
                </span>
              }
            />
          </Snackbar>
        </Paper>
        <Dialog
          open={deleteAlert}
          onClose={this.clearNotification}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle>{"Notification"}</DialogTitle>
          <DialogContent>
            <DialogContentText>{"User Deleted Successfully"}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Link className={classes.hyperlinks} to="/admin">
              Ok
            </Link>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

UserDetailsPage.propTypes = {};
const mapStateToProps = (state, ownProps) => ({
  userInfo: getUserInfo(
    state.adminUserTable.userInfoList,
    ownProps.match.params.id
  ),
  isLoading: state.adminUserTable.isLoading,
  adminUserTable: state.adminUserTable,
});
const mapDispatchToProps = (dispatch) => ({
  getAdminUserTableData: (
    currentPage,
    sorting,
    sortingPresent,
    statusFilter,
    searchTerm,
    channelFilter,
    userTypeToggle
  ) => {
    return dispatch(
      adminUserTable(
        currentPage,
        sorting,
        sortingPresent,
        statusFilter,
        searchTerm,
        channelFilter,
        userTypeToggle
      )
    );
  },
  updateNote: (Note, userId, adminName) => {
    return dispatch(updateUserNote(Note, userId, adminName));
  },
  getUserNote: (userId) => {
    return dispatch(getUserNote(userId));
  },
  endJumpStart: (userId) => dispatch(endJumpStart(userId)),
  disableAccount: (userId) => dispatch(disableAccount(userId)),
  deleteUser: (userId) => dispatch(deleteUser(userId)),
  loadUserInfo: (userId) => dispatch(loadUserInfo(userId)),
  // handleDeleteUserAlert: (dispatch) => dispatch(handleDeleteUserAlert())
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(AdminUserStyles)(UserDetailsPage));
