import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Grid,Typography, Button } from "@mui/material";
import "./jumpstart.css";
import icons from "../../components/icons";

function ResetPassword(props) {
  const { handleForgotPassword } = props;

  return (
    <>
      <Grid
        item
        container
        xs={12}
        direction="row"
        rowSpacing={2}
        style={{textAlign:"center"}}
      >
        <Grid item xs={12}>
          <Typography fontFamily="500">Reset Password</Typography>
        </Grid>
        <Grid item xs={12}>
          {icons.PasswordResetEmail}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2" color="#747474">
            If your Email Id is registered with us, you will receive a link to
            click & setup a new password.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            style={{ textTransform: "none", color: "#F58120" }}
            onClick={handleForgotPassword}
          >
            Go Back to Sign In
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = (state) => ({
});
const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
