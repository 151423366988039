import React, { Component } from "react";
import {
  MenuItem,
  Grid,
  Typography,
  TextField,
  IconButton,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import * as _ from "lodash";
import {
  statusFilterList,
  channelFilterList,
  calculateOrderBy,
} from "./templates";
import { adminUserTable } from "../../actions";
import { connect } from "react-redux";
import Search from "./search";
import icons from "../icons";
import UserTypeToggle from "./user-type-toggle";

export const CustomInputField = withStyles((theme) => ({
  root: {
    textAlign: "left",
    fontStyle: "normal",
  },
}))(TextField);
export const CustomTypography = withStyles((theme) => ({
  root: {
    fontWeight: "bold",
    color: "black",
    fontSize: 20,
    paddingTop: 15,
  },
}))(Typography);

class Filter extends React.PureComponent {
  handleInputs = (event) => {
    const {
      currentPage,
      sorting,
      searchTerm,
      channelFilter,
      statusFilter,
      userTypeToggle,
    } = this.props.adminUserTable;
    if (event.target.name == "channelFilter") {
      this.props.getAdminUserTableData(
        currentPage,
        sorting,
        true,
        statusFilter,
        searchTerm,
        event.target.value,
        userTypeToggle
      );
    } else {
      this.props.getAdminUserTableData(
        currentPage,
        sorting,
        true,
        event.target.value,
        searchTerm,
        channelFilter,
        userTypeToggle
      );
    }
  };

  render() {
    const { statusFilter, channelFilter, sorting, searchTerm, userTypeToggle } =
      this.props.adminUserTable;
    const { sortDirectionString, columnName } = calculateOrderBy(sorting);
    console.log("channelFilter=", channelFilter);
    if (channelFilter != 10) {
      return (
        <Grid
          container
          justifyContent={"flex-end"}
          alignItems={"center"}
          spacing={2}
        >
          <Grid item md={2}>
            <UserTypeToggle />
          </Grid>
          <Grid item md={2}>
            <Typography align={"right"} className={"alignItemsCenter"}>
              <CustomInputField
                label="Channel"
                select
                fullWidth
                margin="normal"
                value={channelFilter}
                onChange={this.handleInputs}
                name="channelFilter"
                InputLabelProps={{
                  shrink: true,
                }}
              >
                <MenuItem key={1} value={-1}>
                  <em>All</em>
                </MenuItem>
                {_.map(channelFilterList, (key, value) => {
                  return (
                    <MenuItem key={key} value={key}>
                      {value}
                    </MenuItem>
                  );
                })}
              </CustomInputField>
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography align={"right"} className={"alignItemsCenter"}>
              <CustomInputField
                label="Status"
                select
                fullWidth
                margin="normal"
                value={statusFilter}
                onChange={this.handleInputs}
                name="statusFilter"
                InputLabelProps={{
                  shrink: true,
                }}
              >
                <MenuItem key={1} value="all">
                  <em>All</em>
                </MenuItem>
                {_.map(statusFilterList, (value, key) => {
                  return (
                    <MenuItem key={key} value={value}>
                      {value}
                    </MenuItem>
                  );
                })}
              </CustomInputField>
            </Typography>
          </Grid>
          <Grid item md={4}>
            <Search />
          </Grid>
          <Grid item md={1}>
            <a
              traget="_blank"
              href={`/download-excel.html?orderby=${columnName}&direction=${sortDirectionString}&statusFilter=${statusFilter}&searchTerm=${searchTerm}&channelFilter=${channelFilter}&userTypeToggle=${userTypeToggle}`}
            >
              <IconButton aria-label="Download" size="large">
                <CustomTypography>{icons.download}</CustomTypography>
              </IconButton>
            </a>
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid
          container
          justifyContent={"center"}
          alignItems={"center"}
          spacing={2}
        >
          <Grid item md={2}>
            <Typography align={"right"} className={"alignItemsCenter"}>
              <CustomInputField
                label="Channel"
                select
                fullWidth
                margin="normal"
                value={channelFilter}
                onChange={this.handleInputs}
                name="channelFilter"
                InputLabelProps={{
                  shrink: true,
                }}
              >
                <MenuItem key={1} value={-1}>
                  <em>All</em>
                </MenuItem>
                {_.map(channelFilterList, (key, value) => {
                  return (
                    <MenuItem key={key} value={key}>
                      {value}
                    </MenuItem>
                  );
                })}
              </CustomInputField>
            </Typography>
          </Grid>
        </Grid>
      );
    }
  }
}

Filter.propTypes = {};

const mapStateToProps = (state, ownProps) => ({
  adminUserTable: state.adminUserTable,
});
const mapDispatchToProps = (dispatch) => ({
  getAdminUserTableData: (
    currentPage,
    sorting,
    sortingPresent,
    statusFilter,
    searchTerm,
    channelFilter,
    userTypeToggle
  ) => {
    return dispatch(
      adminUserTable(
        currentPage,
        sorting,
        sortingPresent,
        statusFilter,
        searchTerm,
        channelFilter,
        userTypeToggle
      )
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
