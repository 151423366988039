import React, { Fragment } from "react";
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import classNames from "classnames";
import NumberFormat from "react-number-format";
import * as PropTypes from "prop-types";
import { Link } from "react-router-dom";
import withStyles from '@mui/styles/withStyles';
import {
  NewOutLinedRectBlackBtn,
  NewOutLinedRectBlackTextBtn,
} from "../../components/buttons";

function WebsiteTraffic(props) {
  const { siteGrader, classes, type } = props;
  const seoTrafficEstimate =
    siteGrader != null && siteGrader.seoTrafficEstimate
      ? siteGrader.seoTrafficEstimate
      : 0;
  const monthlyAdwordsBudget =
    siteGrader != null && siteGrader.monthlyAdwordsBudget
      ? siteGrader.monthlyAdwordsBudget
      : 0;
  const robotsText =
    siteGrader != null && siteGrader.technical
      ? siteGrader.technical.robotsTxt
      : false;
  const validSSLCertificate =
    siteGrader != null && siteGrader.technical
      ? siteGrader.technical.validSSLCertificate
      : false;
  const brokenURLs =
    siteGrader != null && siteGrader.technical
      ? siteGrader.technical.brokenURLs
      : null;
  const wwwResolved =
    siteGrader != null && siteGrader.technical
      ? siteGrader.technical.wwwResolved
      : false;
  const structuredDataScore =
    siteGrader != null && siteGrader.technical
      ? siteGrader.technical.structuredDataScore
      : 0;
  const domainUrl =
    siteGrader != null && siteGrader.url ? siteGrader.url : null;
  return (
    <Fragment>
      <Typography className={classes.text} color={"textPrimary"}>
        <b>Website Traffic</b>
      </Typography>
      <Card style={{ height: "100%", boxShadow: "unset" }}>
        {/*<SubSectionCardHeader title={"Website Traffic"}
                                      className={classNames(classes.cardTitle, classes.paper)}/>*/}

        <CardContent style={{ height: "100%" }}>
          <Grid container style={{ height: "100%" }} alignItems={"center"}>
            <Grid item xs={12} sm={12} md={12}>
              {seoTrafficEstimate > 0 &&
              !(
                robotsText == true &&
                validSSLCertificate == true &&
                brokenURLs == null &&
                wwwResolved != false &&
                structuredDataScore > 0
              ) ? (
                <Typography
                  variant={"body1"}
                  gutterBottom
                  className={classes.websiteErrorContent}
                >
                  You could have{" "}
                  <span className={classes.lightGreen}>
                    <b>
                      <NumberFormat
                        value={seoTrafficEstimate}
                        thousandSeparator={true}
                        readOnly={true}
                        displayType={"text"}
                      />
                    </b>
                  </span>{" "}
                  more visitors. Fix those on-page seo issues now to capture
                  their attention!
                </Typography>
              ) : (
                <Typography
                  variant={"body1"}
                  align={"left"}
                  color={"textSecondary"}
                  gutterBottom
                >
                  <Typography
                    align={"left"}
                    variant={"body1"}
                    className={classNames(classes.websiteErrorContent)}
                    color={"textSecondary"}
                  >
                    <b>
                      Find out how you can improve traffic to your website.{" "}
                      <span>
                        {type && type == "0" ? "Visit LXRGuide.com." : null}
                      </span>
                    </b>
                  </Typography>
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Typography align={"center"} gutterBottom>
                {/* <Link to={`/seo/expert-help`} style={{textDecoration: "none"}} target={"_blank"}>
                                    <NewOutLinedRectBlackTextBtn variant={"outline"}>
                                        <b>Request Expert Help</b>
                                    </NewOutLinedRectBlackTextBtn>
                                </Link>*/}
                <NewOutLinedRectBlackTextBtn
                  href={`/expert-help.html?channel=seo`}
                  style={{ textDecoration: "none" }}
                  target={"_blank"}
                  variant={"outline"}
                >
                  <b>Request Expert Help</b>
                </NewOutLinedRectBlackTextBtn>
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className={"justifyCenter"}></CardActions>
      </Card>
    </Fragment>
  );
}

WebsiteTraffic.propTypes = {
  classes: PropTypes.any,
  robotsText: PropTypes.bool,
  validSSLCertificate: PropTypes.bool,
  brokenURLs: PropTypes.any,
  wwwResolved: PropTypes.bool,
  structuredDataScore: PropTypes.number,
  seoTrafficEstimate: PropTypes.number,
  monthlyAdwordsBudget: PropTypes.number,
};
const websiteErrorStyles = (theme) => ({
  text: { fontSize: "14px" },
  montserrat: {
    fontFamily: "Montserrat",
    opacity: "0.5",
    color: theme.palette.text.primary,
    fontSize: "16px",
  },
});
export default withStyles(websiteErrorStyles)(WebsiteTraffic);
