// import { Grid } from '@mui/material';
import { Grid, Hidden } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import React, { Fragment } from "react";
import expertservicesupport from "../../assets/img/expertservicesupport.png";
import googleadsgrader from "../../assets/img/googleadsgrader.png";
import guidedwalkthrough from "../../assets/img/guidedwalkthrough.png";
import jumpstart from "../../assets/img/jumpstart.png";
import toolonboarding from "../../assets/img/toolonboarding.png";
import s from "../../assets/img/S.png";
import reverses from "../../assets/img/reverseS.png";
import {
  OrangeTxt,
  OrangeTextSize12,
  HeadLineTypography,
  BlueTitleTypography,
  CatalinaBlue,
  SubHeadingTypo,
  GrayText,
} from "../../components/typography.jsx";
import { Typography } from "@mui/material/index";

import useMediaQuery from "@mui/material/useMediaQuery";
import icons from "../../components/icons";
import * as PropTypes from "prop-types";

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="xl" />;

const styles = (theme) => ({
  GridPara: {
    [theme.breakpoints.up("xs")]: {
      lineHeight: "1.54",
      fontSize: 21,
      paddingLeft: 10,
      color: "#888888",
      textAlign: "center",
    },
    [theme.breakpoints.up("md")]: {
      lineHeight: "1.54",
      fontSize: 21,
      color: "#888888",
      textAlign: "initial",
    },

    // paddingLeft:'40px',
    // paddingRight:'40px',
  },
  GridHeader: {
    fontSize: "1.471rem",
    color: "#1E334E",

    [window.screen.width >= 1280]: {
      display: "none",
    },
  },
  GridImage: {
    textAlign: "center",
  },
  learnmorelinkstyle: {
    color: theme.palette.orangeColor,
  },
  root: {
    // backgroundColor: "#ECEFF3",
    paddingTop: 40,
    paddingBottom: 40,
    textAlign: "center",
  },
  centerstyle: {
    textAlign: "center",
    paddingLeft: 86,
    paddingRight: 86,
    [theme.breakpoints.down("xl")]: {
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
  paddingLeftAndRight: {
    [theme.breakpoints.down("xl")]: {
      paddingTop: 10,
      paddingLeft: 10,
      paddingRight: 10,
      alignItems: "center !important",
    },
    [theme.breakpoints.up("sm")]: {
      paddingTop: 40,
      paddingLeft: 60,
      paddingRight: 60,
      alignItems: "center !important",
    },
  },
  dottedImage: {
    [theme.breakpoints.up("sm")]: {
      textAlign: "center",
      width: "65%",
    },
  },
  imagedottedlinestyle: {
    [theme.breakpoints.up("xs")]: {
      display: "none",
    },
    [theme.breakpoints.up("sm")]: {
      textAlign: "center",
      display: "block",
      width: "65%",
    },
    [theme.breakpoints.between("sm", "xl")]: {
      display: "none",
    },
  },
  SubHeadingTypoStyle: {
    fontSize: 30,
  },
  OrangeTxtStyle: {
    fontSize: 38,
  },
});

let id = 1;

function createShopifyData(img, title, para, leftAlign) {
  id += 1;
  return { id, img, title, para, leftAlign };
}

function Content(props) {
  return (
    <Fragment>
      {props.width == "sm" || props.width == "xs" ? <br /> : null}
      <SubHeadingTypo
        gutterBottom={props.width == "sm" || props.width == "xs" ? true : false}
      >
        {props.data.title}
      </SubHeadingTypo>
      {props.width != "sm" && props.width != "xs" ? <br /> : null}
      <CatalinaBlue classame={props.classes.FontStyle}>
        {props.data.para}
      </CatalinaBlue>
      {/* <p ><a className={classes.learnmorelinkstyle} href="">LEARN MORE</a></p> */}
    </Fragment>
  );
}

Content.propTypes = {
  classes: PropTypes.any,
  width: PropTypes.any,
  data: PropTypes.shape({
    img: PropTypes.func,
    para: PropTypes.func,
    leftAlign: PropTypes.func,
    id: PropTypes.any,
    title: PropTypes.func,
  }),
};

function LeadGenContent(props) {
  const type = props.type;
  const data = [
    createShopifyData(
      "leadGenSec1",
      `Create product feed from ${type} and upload in Google merchant center`,
      "You don’t have to worry about compatibility issues and other details required to create regular\n" +
        `product feeds on the Google Merchant centre (GMC). Just connect your ${type} account with\n` +
        "LXRGuide and based on product title, description, sales price and other details, our tool will get\n" +
        "the feed ready for GMC. LXRGuide will automatically schedule an auto fetch for the feed and\n" +
        "upload it to GMC every day. Save time and boost your search rankings through fresh feed\n" +
        "submission, every day!",
      true
    ),
    createShopifyData(
      "leadGenSec2",
      "Create automated shopping campaign",
      "Once the product feed is approved, LXRGuide will start creating shopping campaigns. The\n" +
        "campaigns will be automated and you will be recommended simple tasks to perform. You can\n" +
        "activate the campaigns in just a couple of clicks. Once the campaigns are activated, LXRGuide\n" +
        "continuously monitors and analyzes product level data that power its performance optimization\n" +
        "algorithms. You get optimization recommendations every week that you can review and execute\n" +
        "within seconds.",
      false
    ),
    createShopifyData(
      "leadGenSec3",
      "Optimize search & shopping campaigns",
      "Product feeds and automated shopping campaigns are not the only things that we can help you\n" +
        "with. Continuous, on-the-go account optimization every week is possible with LXRGuide’s 30+\n" +
        "proprietary algorithms. The optimization suggestions will be broken into tasks which can be\n" +
        "completed in just a few clicks. From search query analysis, new keyword additions, keyword bid\n" +
        "changes to complex day parting manoeuvres and, device bid adjustments, LXRGuide can\n" +
        "optimize for hundreds of complex tasks with its machine learning algorithms. Boost your online\n" +
        "sales through smarter search and shopping campaigns on Google and Microsoft Ad Platforms.",
      true
    ),
    createShopifyData(
      "leadGenSec4",
      `Monthly paid search and SEO reporting for ${type}`,
      `Gain valuable insights into your Paid Search as well as your SEO Campaigns through
LXRGuide. For no extra cost, LXRGuide provides a comprehensive, monthly SEO performance
report along with a detailed Paid Search reports that are delivered to your emailbox.
The Paid Search Report captures your paid search performance on Google Ads and Microsoft
ads in great detail.
The comprehensive SEO performance report includes an in-depth analysis of your website on
over 62 key SEO metrics and offers pin-pointed recommendations to help your ${type} website
rank higher on Google and Bing.
LXRGuide’s comprehensive reports empower ${type} Businesses to grow their online sales
profitably. `,
      false
    ),
  ];
  const classes = props.classes;
  const { width, theme, eCommercePlatform } = props;
  const isTabletResponsive = useMediaQuery(
    theme.breakpoints.between("sm", "xl")
  );
  const pageData = data;
  console.log("data===");
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item md={12} xs={12}>
          <div className={classes.centerstyle}>
            {/*<OrangeTextSize12 gutterBottom>GROWTH JOURNEY</OrangeTextSize12>*/}
            <br />
            <Typography
              color={"textPrimary"}
              variant={width == "sm" || width == "xs" ? "h3" : "subtitle1"}
              style={{ fontWeight: "700" }}
            >
              LXRGuide Makes Paid Search Management Easy For {type}
            </Typography>
            {/*<br/>*/}
            {/*<CatalinaBlue className={classes.FontStyle}>When you sign up for LXRGuide, you don’t just
                            get a DIY tool… you subscribe into a proven system for growing your ecommerce sales – the
                            LXRGuide Growth Model. The model is tailored for small business marketers who want to take
                            charge of paid search campaigns but would appreciate expert guidance and continued growth
                            insights as they scale their campaigns.
                        </CatalinaBlue>
                        <OrangeTxt> Here’s how your Growth Journey would look –</OrangeTxt>*/}
            <br />
            <br />
          </div>
        </Grid>
      </Grid>
      {pageData.map((data, i) => (
        <Fragment key={i}>
          <Grid container className={classes.paddingLeftAndRight}>
            {data.leftAlign ? (
              <Grid item md={4} xs={12} className={classes.GridImage}>
                {width == "sm" || width == "xs" ? (
                  <Fragment>
                    <br />
                    <br />
                  </Fragment>
                ) : null}
                {icons[data.img]}
              </Grid>
            ) : (
              <Fragment>
                <Hidden only={["md", "lg", "xl"]}>
                  <Grid item md={4} xs={12} className={classes.GridImage}>
                    {width == "sm" || width == "xs" ? (
                      <Fragment>
                        <br />
                        <br />
                      </Fragment>
                    ) : null}
                    {icons[data.img]}
                  </Grid>
                </Hidden>
                <Grid item md={8} xs={12} className={props.classes.GridPara}>
                  <Content classes={classes} width={width} data={data} />
                </Grid>
              </Fragment>
            )}
            {data.leftAlign ? (
              <Grid item md={8} xs={12} className={props.classes.GridPara}>
                <Content classes={classes} width={width} data={data} />
              </Grid>
            ) : (
              <Hidden only={["sm", "xs"]}>
                <Grid item md={4} xs={12} className={classes.GridImage}>
                  {width == "sm" || width == "xs" ? (
                    <Fragment>
                      <br />
                      <br />
                    </Fragment>
                  ) : null}
                  {icons[data.img]}
                </Grid>
              </Hidden>
            )}
            {!(pageData.length - 1 == i) && (
              <Fragment>
                {data.leftAlign ? (
                  <Grid item className={classes.imagedottedlinestyle} md={12}>
                    <img src={s} className={classes.dottedImage} />
                  </Grid>
                ) : (
                  <Grid item className={classes.imagedottedlinestyle} md={12}>
                    <img src={reverses} className={classes.dottedImage} />
                  </Grid>
                )}
              </Fragment>
            )}
          </Grid>
        </Fragment>
      ))}
    </div>
  );
}

export default withWidth()(
  withStyles(styles, { withTheme: true })(LeadGenContent)
);
