import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Grid, Typography } from "@mui/material";
import "../jumpstart.css";
import PageHeader from "../commonComponents/pageHeaderDetails";
import JumpstartMain from "../jumpstartMain";
import { JsSubSectionTypography } from "../../../components/typography";
import icons from "../../../components/icons";
import { Link } from "react-router-dom";
import { fetchUserInfoReducer } from "../../../reducers/jumpstartRevampedReducer";
import { AccountStatus, Authority } from "../commonComponents/jumpstartEnum";
import { useParams } from "react-router-dom";
import {
  campaignFrameworkDownload,
  fetchJsAccountById,
  fetchJsAccounts,
} from "../../../actions/jumpstartRevamped";
import { JsTextAsButton } from "../../../components/buttons";
import { sanitizeCompanyName } from "../commonComponents/companyNameCheck";

function ReviewerApproval(props) {
  const {
    fetchUserInfoReducer,
    fetchJsAccountByIdReducer,
    fetchJsAccountById,
    fetchJsAccounts,
    campaignFrameworkDownload,
  } = props;
  const token = sessionStorage.getItem("jwtToken");
  // const token = Cookies.get("jwtToken");

  const [authority, setAuthority] = useState();
  const [accountStatus, setAccountStatus] = useState();
  const { id } = useParams();
  useEffect(() => {
    if (fetchUserInfoReducer.message == "JS_USER_INFO_RECEIVED") {
      setAuthority(fetchUserInfoReducer.authority);
    }
  }, [fetchUserInfoReducer]);

  useEffect(() => {
    if (fetchJsAccountByIdReducer.message == "JS_ACCOUNT_BY_ID_RECEIVED") {
      setAccountStatus(fetchJsAccountByIdReducer.status);
    }
  }, [fetchJsAccountByIdReducer]);

  useEffect(() => {
    fetchJsAccountById(token, id);
    fetchJsAccounts(token);
  }, []);


  const handleDownload = () => {
    if (
      fetchJsAccountByIdReducer.status != "" ||
      (fetchJsAccountByIdReducer.status != "JS_ACCOUNT_BY_ID_FAILED" &&
        fetchJsAccountByIdReducer.id == id)
    ) {
      const data = campaignFrameworkDownload(
        token,
        id,
        fetchJsAccountByIdReducer.companyName,
        );
    }
  };
  return (
    <JumpstartMain>
      <div className="pageContent">
        <PageHeader
          sectionHeading={"Campaign Structure:"}
          hidden="true"
          token={token}
          completedText = {"Structure Setup Completed Successfully"}
        />
      </div>
      <div style={{ padding: "220px 4% 4%" }}>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
          rowSpacing={2}
          className="frameworkCompleted"
          style={{ paddingBottom: "2%", textAlign: "center" }}
        >
          <Grid item xs={12}>
            <Typography variant="h3">Congratulations!</Typography>
          </Grid>
          <Grid item xs={12}>
            <JsSubSectionTypography>
              Campaign Structure has been successfully submitted.
            </JsSubSectionTypography>
          </Grid>
          <Grid item xs={12}>
            {icons.CampaignStructureCompleted}
          </Grid>
          <Grid item xs={12}>
            <JsSubSectionTypography>
              {(authority == Authority.ANALYST || authority == Authority.RWF) &&
              accountStatus !== AccountStatus.c
                ? "Please wait till the reviewer/manager reviews & approves it."
                : (authority == Authority.ANALYST ||
                    authority == Authority.RWF) &&
                  accountStatus ==
                    AccountStatus.CAMPAIGN_STRUCTURE_REVIEWER_APPROVED
                ? "The campaign structure has been approved."
                : "Please wait till the client reviews & approves it."}
            </JsSubSectionTypography>
          </Grid>
          <Grid item xs={12}>
            <Link to="/jumpstart-dashboard" style={{ textDecoration: "none" }}>
              <Typography color="#F58120" fontWeight="500" lineHeight="30px">
                Go to Account Management
              </Typography>
            </Link>
          </Grid>
          <Grid item xs={12} style={{display:"flex", justifyContent:"center"}}>
            <JsTextAsButton onClick={() => handleDownload()}>
              <span>{icons.ExcelDownload}</span>&nbsp;
              <u>Download Campaign Structure</u>
            </JsTextAsButton>
          </Grid>
        </Grid>
      </div>
    </JumpstartMain>
  );
}

const mapStateToProps = (state) => ({
  fetchUserInfoReducer: state.fetchUserInfoReducer,
  fetchJsAccountByIdReducer: state.fetchJsAccountByIdReducer,
});
const mapDispatchToProps = (dispatch) => ({
  campaignFrameworkDownload: (jwtToken, id, companyName) =>
    dispatch(campaignFrameworkDownload(jwtToken, id, companyName)),
  fetchJsAccountById: (jwtToken, id) =>
    dispatch(fetchJsAccountById(jwtToken, id)),
  fetchJsAccounts: (jwtToken) => dispatch(fetchJsAccounts(jwtToken)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewerApproval);
