import React, { Component, Fragment, useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Switch,
  FormControlLabel,
  FormGroup,
  Button,
} from "@mui/material";
import {} from "react-router";
import {
  CircularProgress,
  FormControl,
  InputBase,
  MenuItem,
  Select,
} from "@mui/material/index";
import { withStyles } from "@mui/styles";

import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  DemoInputField,
  InputFields,
  NativeSelectFields,
  SelectFields,
  SwitchFields,
} from "../../components/input-forms";
import {
  NewOrangeContainedBtn,
  NewOutLinedRectBlackBtn,
  NewTextRectOrangeTextBtn,
} from "../../components/buttons";

export const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    color: theme.palette.text.primary,
    border: "1px solid #182C45",
    fontSize: 16,
    width: "91%",
    minWidth: 120,
    padding: "8.5px 18px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

function GetLayout({ title, children, classes }) {
  return (
    <Grid
      container
      alignItems={"center"}
      spacing={2}
      style={{ marginBottom: "0.35em" }}
    >
      <Grid item md={6}>
        <Typography
          style={{ fontWeight: "500" }}
          variant={"body2"}
          color={"textPrimary"}
        >
          {title}
        </Typography>
      </Grid>
      <Grid item md={6}>
        {children}
      </Grid>
    </Grid>
  );
}

function AccountSettingsForm(props) {
  const {
    classes,
    selectedAccountInfo,
    accountSettingsData,
    isAccountDetailsLoading,
  } = props;

  const [state, setState] = useState({
    accountName:
      props.selectedAccountInfo && props.selectedAccountInfo.clientName
        ? props.selectedAccountInfo.clientName
        : "",
    selAccountId:
      props.selectedAccountInfo && props.selectedAccountInfo.seAccountId
        ? props.selectedAccountInfo.seAccountId
        : 0,
    kPIMetric:
      props.selectedAccountInfo && props.selectedAccountInfo.targetKpi
        ? props.selectedAccountInfo.targetKpi
        : 0,
    targetValue:
      props.selectedAccountInfo && props.selectedAccountInfo.targetValue
        ? props.selectedAccountInfo.targetValue
        : 0,
    budget:
      props.selectedAccountInfo && props.selectedAccountInfo.budget
        ? props.selectedAccountInfo.budget
        : 0,
    emailIds:
      props.selectedAccountInfo && props.selectedAccountInfo.emailIds
        ? props.selectedAccountInfo.emailIds
        : "",
    targetValueError: "",
    budgetValueError: "",
    emailIdsError: "",
  });

  const {
    accountName,
    selAccountId,
    kPIMetric,
    targetValue,
    budget,
    emailIds,
  } = state;

  console.log(
    "The state values are",
    accountName,
    selAccountId,
    kPIMetric,
    targetValue,
    budget,
    emailIds
  );

  const googleAuthUrls = accountSettingsData.googleAuthUrl;
  const bingAuthUrl = accountSettingsData.bingAuthUrl;
  const isEqual =
    (selectedAccountInfo.clientName === state.accountName &&
      selectedAccountInfo.targetKpi == state.kPIMetric &&
      selectedAccountInfo.targetValue == state.targetValue &&
      selectedAccountInfo.budget == state.budget &&
      selectedAccountInfo.emailIds === state.emailIds) ||
    selectedAccountInfo.enabled != 1;

  useEffect(() => {
    console.log("props.selectedAccountInfo", props.selectedAccountInfo);
    if (props.selectedAccountInfo != props.selectedAccountInfo) {
      const accountName =
        props.selectedAccountInfo && props.selectedAccountInfo.clientName
          ? props.selectedAccountInfo.clientName
          : "";
      const selAccountId =
        props.selectedAccountInfo && props.selectedAccountInfo.seAccountId
          ? props.selectedAccountInfo.seAccountId
          : 0;
      const kPIMetric =
        props.selectedAccountInfo && props.selectedAccountInfo.targetKpi
          ? props.selectedAccountInfo.targetKpi
          : 0;
      const targetValue =
        props.selectedAccountInfo && props.selectedAccountInfo.targetValue
          ? props.selectedAccountInfo.targetValue
          : 0;
      const budget =
        props.selectedAccountInfo && props.selectedAccountInfo.budget
          ? props.selectedAccountInfo.budget
          : 0;
      const emailIds =
        props.selectedAccountInfo && props.selectedAccountInfo.emailIds
          ? props.selectedAccountInfo.emailIds
          : "";
      setState({
        accountName,
        selAccountId,
        kPIMetric,
        targetValue,
        budget,
        emailIds,
      });
      // console.log(
      //   "inside if values are",
      //   accountName,
      //   selAccountId,
      //   kPIMetric,
      //   targetValue,
      //   budget,
      //   emailIds
      // );
    }
  }, [props.selectedAccountInfo]);

  const handleEnableAndDisable = (type, value) => {
    console.log("value==", value);
    if (value != 0 || value != false) {
      if (type == 0) {
        var r = window.confirm(
          "Deactivating your account will stop data fetching from Search Engine and any data related to this account will not be accessible."
        );
      } else if (type == 1) {
        var r = window.confirm(
          "Deactivating it, will stop the reminder emails sent to you when tasks are refreshed."
        );
      } else {
        var r = window.confirm(
          "Deactivating Report will stop the generation of Weekly/Monthly reports that is sent through the mail."
        );
      }
    } else {
      var r = true;
    }
    if (r == true) {
      const { handleAccountToggles } = props;
      handleAccountToggles(type);
    }
  };

  const handleChange = (name) => (event) => {
    console.log("we are inside Handle change");
    setState((prevState) => ({
      ...prevState,
      [name]: event.target.value,
    }));
  };

  const handleKpiChange = () => {
    console.log("handle Kpi");
    // setState(/)
  };

  const validateAccountDetails = () => {
    console.log("came to validateAccountDetails section");
    let isError = false;
    const errors = {};
    if (state.emailIds && state.emailIds.length === 0) {
      isError = true;
      errors.emailIdsError = "This field is required";
    } else if (state.emailIds && state.emailIds.length > 0) {
      let emailError = false;
      var separator = ",";
      var emailIdList = state.emailIds.split(separator);
      var i;
      for (i = 0; i < emailIdList.length; i++) {
        var emails = "";
        emails = emailIdList[i];
        console.log("emailIdList is= ", emails);
        let result = emails
          .trim()
          .match(
            /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
          );
        if (!result) {
          isError = true;
          errors.emailIdsError = "Please enter valid email";
          emailError = true;
        }
      }
      if (!emailError) {
        errors.emailIdsError = "";
      }
    } else {
      errors.emailIdsError = "";
    }
    if (state.budget === "") {
      isError = true;
      errors.budgetValueError = "Please enter the budgetValue";
    } else if (isNaN(state.budget)) {
      isError = true;
      errors.budgetValueError = "Please enter the valid budgetValue";
    } else if (state.budget <= 0) {
      isError = true;
      errors.budgetValueError =
        "Please enter the budget Value greater than zero";
    } else {
      errors.budgetValueError = "";
    }
    if (state.targetValue === "") {
      isError = true;
      errors.targetValueError = "Please enter the valid targetValue";
    } else if (state.targetValue <= 0) {
      isError = true;
      errors.targetValueError =
        "Please enter the target Value greater than zero";
    } else if (
      state.targetValue != "" &&
      state.targetValue < 1 &&
      state.kPIMetric == 1
    ) {
      isError = true;
      errors.targetValueError = "Please enter the target value greater than 1";
    } else if (
      state.targetValue != "" &&
      state.targetValue > 1 &&
      state.kPIMetric == 2
    ) {
      isError = true;
      errors.targetValueError = "Please enter the target value less than 1";
    } else if (isNaN(state.targetValue)) {
      isError = true;
      errors.targetValueError = "Please enter the valid targetValue";
    } else {
      errors.targetValueError = "";
    }
    setState({
      ...state,
      ...errors,
    });
    return isError;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let error = validateAccountDetails();
    if (!error) {
      const { budget, targetValue, kPIMetric, emailIds } = state;
      const { ppcId } = props.selectedAccountInfo;
      props.UpdateAccountDetails({
        ppcId: ppcId,
        budget: budget,
        targetValue: targetValue,
        kPIMetric: kPIMetric,
        emailIds: emailIds,
      });
    }
  };
  return (
    <Fragment>
      <Grid item md={12}>
        <form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item sm={12} xs={12} md={6} id={"account-edit"}>
              <Typography
                color={"textPrimary"}
                variant={"h5"}
                component={"h5"}
                gutterBottom
              >
                <b>Edit Account</b>
              </Typography>
              <Typography variant={"body1"} gutterBottom>
                <b>Account Name:</b>
              </Typography>
              <GetLayout title={accountName} classes={classes}>
                <FormGroup row>
                  <FormControlLabel
                    labelPlacement={"start"}
                    control={
                      <SwitchFields
                        style={{ boxSizing: "unset" }}
                        className={classes.switch}
                        checked={selectedAccountInfo.enabled == 1}
                        defaultChecked
                        color="secondary"
                        onChange={(event) =>
                          handleEnableAndDisable(0, selectedAccountInfo.enabled)
                        }
                      />
                    }
                  />
                </FormGroup>
              </GetLayout>
              <div className={classes.mb} classes={classes}>
                <Typography variant={"body1"} gutterBottom>
                  <b>Account Id:</b>
                </Typography>
                <GetLayout title={selAccountId}>
                  {selectedAccountInfo.searchEngineId == 1 ? (
                    <NewTextRectOrangeTextBtn
                      style={{ margin: 0 }}
                      onClick={() =>
                        window.open(
                          googleAuthUrls,
                          "authorizationUrl",
                          "menubar=no,status=no,location=no,toolbar=no,scrollbars=yes,resizable=no"
                        )
                      }
                    >
                      Refresh Token
                    </NewTextRectOrangeTextBtn>
                  ) : (
                    <NewTextRectOrangeTextBtn
                      style={{ margin: 0 }}
                      onClick={() =>
                        window.open(
                          bingAuthUrl,
                          "authorizationUrl",
                          "menubar=no,status=no,location=no,toolbar=no,scrollbars=yes,resizable=no"
                        )
                      }
                    >
                      Refresh Token
                    </NewTextRectOrangeTextBtn>
                  )}
                </GetLayout>
              </div>
              <div className={classes.mb}>
                <Typography variant={"body1"} gutterBottom>
                  <b>KPI Metric</b>
                </Typography>
                <FormControl className={classes.formControl}>
                  <NativeSelectFields
                    disableUnderline
                    value={kPIMetric}
                    onChange={handleChange("kPIMetric")}
                    displayEmpty
                    input={
                      <BootstrapInput
                        name="age"
                        id="age-customized-native-simple"
                      />
                    }
                    inputProps={{ "aria-label": "Without label" }}
                    IconComponent={ExpandMore}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "bottom",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "top",
                      },
                    }}
                  >
                    <option value={1}>Revenue / Cost</option>
                    <option value={2}>Cost / Revenue</option>
                    <option value={3}>Cost Per Order</option>
                    <option value={4}>Cost Per Lead</option>
                  </NativeSelectFields>
                </FormControl>
              </div>
              <Typography variant={"body1"} gutterBottom>
                <b>Target Value</b>
              </Typography>
              <FormControl className={classes.formControl}>
                <InputFields
                  type={"number"}
                  value={targetValue}
                  onChange={handleChange("targetValue")}
                  margin="normal"
                  error={state.targetValueError !== ""}
                  helperText={state.targetValueError}
                  style={{ marginTop: 0 }}
                />
              </FormControl>
              <Typography variant={"body1"} gutterBottom>
                <b>Monthly Budget</b>
              </Typography>
              <FormControl className={classes.formControl}>
                <InputFields
                  type={"number"}
                  value={budget}
                  onChange={handleChange("budget")}
                  margin="normal"
                  error={state.budgetValueError !== ""}
                  helperText={state.budgetValueError}
                  style={{ marginTop: 0 }}
                />
              </FormControl>
            </Grid>
            <Grid item sm={12} xs={12} md={6}>
              <Typography
                color={"textPrimary"}
                variant={"h5"}
                component={"h5"}
                gutterBottom
              >
                <b>Email Settings</b>
              </Typography>

              <Typography variant={"body1"} gutterBottom>
                <b>Task Reminder</b>
              </Typography>
              <GetLayout title={"Active"} classes={classes}>
                <FormGroup row>
                  <FormControlLabel
                    labelPlacement={"start"}
                    control={
                      <SwitchFields
                        style={{ boxSizing: "unset" }}
                        checked={selectedAccountInfo.taskNotification == true}
                        defaultChecked
                        color="secondary"
                        onChange={() =>
                          handleEnableAndDisable(
                            1,
                            selectedAccountInfo.taskNotification
                          )
                        }
                      />
                    }
                  />
                </FormGroup>
              </GetLayout>
              <Typography variant={"body1"} gutterBottom>
                <b>Weekly Report</b>
              </Typography>
              <GetLayout title={"Active"} classes={classes}>
                <FormGroup row>
                  <FormControlLabel
                    labelPlacement={"start"}
                    control={
                      <SwitchFields
                        style={{ boxSizing: "unset" }}
                        checked={selectedAccountInfo.weeklyReportStatus == 1}
                        defaultChecked
                        color="secondary"
                        onChange={() =>
                          handleEnableAndDisable(
                            2,
                            selectedAccountInfo.weeklyReportStatus
                          )
                        }
                      />
                    }
                  />
                </FormGroup>
              </GetLayout>
              <Typography variant={"body1"} gutterBottom>
                <b>Email Recipients</b>
              </Typography>
              <FormControl className={classes.formControl}>
                <InputFields
                  value={emailIds}
                  onChange={handleChange("emailIds")}
                  error={state.emailIdsError !== ""}
                  helperText={state.emailIdsError}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ marginTop: 0 }}
                  required
                />
              </FormControl>
              <div className={classes.mb}>
                <Typography variant={"body1"} gutterBottom>
                  <b>Fiscal Week</b>
                </Typography>
                <Typography variant={"body2"} color={"textPrimary"}>
                  Mon - Sun
                </Typography>
              </div>
              <div className={classes.mb}>
                <Typography variant={"body1"} gutterBottom>
                  <b>Day of Report Delivery</b>
                </Typography>
                <Typography variant={"body2"} color={"textPrimary"}>
                  Monday
                </Typography>
              </div>
            </Grid>
            <Grid sm={12} xs={12} md={12}>
              <div className={"flex alignItemsCenter justifyCenter"}>
                {selectedAccountInfo.ppcId == 2 ||
                selectedAccountInfo.ppcId == 30 ||
                selectedAccountInfo.ppcId == 67 ||
                selectedAccountInfo.ppcId == 83 ||
                selectedAccountInfo.ppcId == 681 ? (
                  <div className={classes.wrapper}>
                    <NewOutLinedRectBlackBtn
                      variant={"outlined"}
                      onClick={() => props.handleResetData()}
                    >
                      Reset
                    </NewOutLinedRectBlackBtn>
                    {isAccountDetailsLoading && (
                      <CircularProgress
                        className={classes.buttonProgress}
                        color="primary"
                        size={24}
                      />
                    )}
                  </div>
                ) : null}
                <div className={classes.wrapper}>
                  <NewOrangeContainedBtn
                    type="submit"
                    variant={"outlined"}
                    disabled={isEqual || isAccountDetailsLoading}
                  >
                    Update
                  </NewOrangeContainedBtn>
                  {isAccountDetailsLoading && (
                    <CircularProgress
                      className={classes.buttonProgress}
                      color="primary"
                      size={24}
                    />
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Fragment>
  );
}

export default AccountSettingsForm;
