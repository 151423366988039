import React from 'react';
import PropTypes from 'prop-types';
import icons from "../icons";
import {Button, Grid, Typography,Dialog,ListItemText,ListItem,List,Divider,AppBar,Toolbar,IconButton,Slide} from "@mui/material";
import {Transition} from './templates'

class SalesAdminForm extends React.PureComponent {
    render() {
        return (
            <Dialog
                fullScreen
                open={this.props.userDialog}
                onClose={this.props.toggle}
                TransitionComponent={Transition}
            >
                <AppBar >
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            onClick={this.props.toggle}
                            aria-label="Close"
                            size="large">
                            <icons.close/>
                        </IconButton>
                        <Typography variant="h6" color="inherit" >
                            Sound
                        </Typography>
                        <Button color="inherit" onClick={this.props.toggle}>
                            save
                        </Button>
                    </Toolbar>
                </AppBar>
                <List>
                    <ListItem button>
                        <ListItemText primary="Phone ringtone" secondary="Titania" />
                    </ListItem>
                    <Divider />
                    <ListItem button>
                        <ListItemText primary="Default notification ringtone" secondary="Tethys" />
                    </ListItem>
                </List>
            </Dialog>
        );
    }
}

SalesAdminForm.propTypes = {};

export default SalesAdminForm;
