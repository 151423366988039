import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  TextField,
  Grid,
  Typography,
  Badge,
  LinearProgress,
  CircularProgress,
} from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import { executeAddAdcopyTask } from "../../../actions";
import { PostButton } from "../../buttons";
import { connect } from "react-redux";
import * as _ from "lodash";
import InputAdornment from "@mui/material/InputAdornment";
import { FormControl } from "@mui/material/index";
import { TDInputFields } from "../../input-forms";
import icons from "../../icons";
import MySnackbarWrapper from "../../../NewInterfacePages/snackbars";
import CustomToolTip from "../../../NewInterfacePages/customToolTip";

function ToolTipContent(props) {
  return (
    <Grid container alignItems={"center"}>
      <Grid item>
        <Typography variant={"body1"} gutterBottom>
          <div>
            <b>{props.title} &nbsp;</b>
          </div>
        </Typography>
      </Grid>
      <Grid item>
        <CustomToolTip>
          <Typography variant={"body2"} color={"textPrimary"}>
            {props.toolTipText}
          </Typography>
        </CustomToolTip>
      </Grid>
    </Grid>
  );
}

const adCopyHierarchyStyles = (theme) => ({
  textField: {
    marginLeft: 0,
    marginRight: theme.spacing(1),
    marginBottom: 0,
    marginTop: 0,
  },
  field: {
    marginBottom: 10,
  },
  lengthTags: {
    width: "100%",
    marginLeft: 0,
  },
  lengthTags1: {
    marginLeft: 8,
  },
  newBadge: {
    padding: "0px 2px",
    backgroundColor: theme.palette.newBadge,
    color: theme.palette.common.white,
    borderRadius: 2,
  },
  post: {
    display: "flex",
    justifyContent: "flex-end",
  },
  flex: {
    display: "flex !important",
  },
  infoIcon: {
    paddingTop: 2.5,
    paddingLeft: 3,
  },
});
const CustomBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: theme.palette.newBadge,
    color: theme.palette.common.white,
    borderRadius: 2,
  },
}))(Badge);
const CustomInputAdornment = withStyles((theme) => ({
  positionEnd: {
    marginLeft: "-17px",
  },
}))(InputAdornment);

const setFinalUrl = (refAdCopy) => {
  console.log("refAdCopy=", refAdCopy);
  if (refAdCopy.displayUrl && refAdCopy.displayUrl != "") {
    refAdCopy.creativeFinalUrls = refAdCopy.displayUrl;
  }
  return refAdCopy;
};

class AdCopyHierarchyFrom extends React.PureComponent {
  constructor(props) {
    super(props);

    const { refAdCopy } = this.props.taskData;
    let creativeFinalUrls = "--";
    if (refAdCopy != null) {
      setFinalUrl(refAdCopy);
      let creativeFinalUrls = refAdCopy.creativeFinalUrls;
    }
    this.state = {
      headLine1: "",
      headLine2: "",
      headLine3: "",
      description1: "",
      description2: "",
      finalUrl:
        creativeFinalUrls != null && creativeFinalUrls != "--"
          ? creativeFinalUrls
          : "https://",
      path1: "",
      path2: "",
      domainUrl: "",
      finalUrlError: "",
      headLine1Error: "",
      headLine2Error: "",
      headLine3Error: "",
      path1Error: "",
      path2Error: "",
      description1Error: "",
      description2Error: "",
      isPosting: false,
      statusAlert: false,
      variantType: "info",
      message: "",
    };
  }

  calculateWords = (text) => {
    const words = text.split(" ");
    const improperCapitalizationWords = words.filter((w) => w.match(/.[A-Z]/));
    return improperCapitalizationWords;
  };
  validate = () => {
    let isError = false;
    let ErrorType1 = "You can't leave this blank";
    let ErrorType2 = "Max length exceeds";
    let ErrorType3 = "Improper capitalization: ";
    let ErrorType4 = "Space is not allowed";
    let ErrorType5 = "URL Format Wrong";
    // var UrlRegex = /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
    var UrlRegex =
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#()?&//=]*)/;
    const errors = {};
    if (this.state.finalUrl.length == 0 || this.state.finalUrl.trim() == "") {
      isError = true;
      errors.finalUrlError = ErrorType1;
    } else if (
      this.state.finalUrl.length != 0 &&
      !UrlRegex.test(this.state.finalUrl)
    ) {
      isError = true;
      errors.finalUrlError = ErrorType5;
    } else {
      errors.finalUrlError = "";
    }
    if (this.state.headLine1.length == 0 || this.state.headLine1.trim() == "") {
      isError = true;
      errors.headLine1Error = ErrorType1;
    } else if (
      this.state.headLine1.length > 0 &&
      _.some(this.calculateWords(this.state.headLine1))
    ) {
      isError = true;
      errors.headLine1Error =
        ErrorType3 + this.calculateWords(this.state.headLine1);
    } else {
      errors.headLine1Error = "";
    }
    if (this.state.headLine2.length == 0 || this.state.headLine2.trim() == "") {
      isError = true;
      errors.headLine2Error = ErrorType1;
    } else if (
      this.state.headLine2.length > 0 &&
      _.some(this.calculateWords(this.state.headLine2))
    ) {
      isError = true;
      errors.headLine2Error =
        ErrorType3 + this.calculateWords(this.state.headLine2);
    } else {
      errors.headLine2Error = "";
    }
    if (this.state.headLine3.length != 0 && this.state.headLine3.length > 30) {
      isError = true;
      errors.headLine3Error = ErrorType2;
    } else if (
      this.state.headLine3.length > 0 &&
      _.some(this.calculateWords(this.state.headLine3))
    ) {
      isError = true;
      errors.headLine3Error =
        ErrorType3 + this.calculateWords(this.state.headLine3);
    } else {
      errors.headLine3Error = "";
    }
    if (this.state.path1.length != 0 && this.state.path1.length > 15) {
      isError = true;
      errors.path1Error = ErrorType2;
    } else if (
      this.state.path1.length > 0 &&
      _.some(this.calculateWords(this.state.path1))
    ) {
      isError = true;
      errors.path1Error = ErrorType3 + this.calculateWords(this.state.path1);
    } else if (this.state.path1.length > 0 && this.state.path1.match(/\s/)) {
      isError = true;
      errors.path1Error = ErrorType4;
    } else {
      errors.path1Error = "";
    }
    if (this.state.path2.length != 0 && this.state.path2.length > 15) {
      isError = true;
      errors.path2Error = ErrorType2;
    } else if (
      this.state.path2.length > 0 &&
      _.some(this.calculateWords(this.state.path2))
    ) {
      isError = true;
      errors.path2Error = ErrorType3 + this.calculateWords(this.state.path2);
    } else if (this.state.path2.length > 0 && this.state.path2.match(/\s/)) {
      isError = true;
      errors.path2Error = ErrorType4;
    } else {
      errors.path2Error = "";
    }

    if (
      this.state.description1.length == 0 ||
      this.state.description1.trim() == ""
    ) {
      isError = true;
      errors.description1Error = ErrorType1;
    } else if (
      this.state.description1.length > 0 &&
      _.some(this.calculateWords(this.state.description1))
    ) {
      isError = true;
      errors.description1Error =
        ErrorType3 + this.calculateWords(this.state.description1);
    } else {
      errors.description1Error = "";
    }
    if (
      this.state.description2.length > 0 &&
      _.some(this.calculateWords(this.state.description2))
    ) {
      isError = true;
      errors.description2Error =
        ErrorType3 + this.calculateWords(this.state.description2);
    } else {
      errors.description2Error = "";
    }

    if (isError) {
      this.setState({
        ...this.state,
        ...errors,
      });
    }
    return isError;
  };

  handleChange = (name) => async (event) => {
    await this.setState({
      [name]: event.target.value,
    });
    this.props.formObj(this.state);
  };
  handleCloseAlert = () => {
    this.setState({ statusAlert: false });
  };
  handlePost = async () => {
    const { taskDataId, taskId, refAdCopy } = this.props.taskData;
    console.log("taskData on submit=", this.props.taskData);
    console.log("local state=", this.state);
    let error = this.validate();
    console.log("error=", error);
    if (!error) {
      const addAdCopyObj = {
        taskId: taskId,
        taskDataId: taskDataId,
        completedAs: "DIY",
        details: {
          headLine1: this.state.headLine1,
          headLine2: this.state.headLine2,
          headLine3: this.state.headLine3,
          description1: this.state.description1,
          description2: this.state.description2,
          finalUrl: this.state.finalUrl,
          path1: this.state.path1,
          path2: this.state.path2,
          domainUrl: this.state.domainUrl,
        },
      };
      this.setState({
        isPosting: true,
      });
      if (refAdCopy && refAdCopy.creativeFinalUrls != null) {
        await this.setState({ domainUrl: refAdCopy.creativeFinalUrls });
      }
      let response = await this.props.executeAddAdCopyTask(addAdCopyObj);
      console.log("The adcopy reponse is: ", response);
      if (response) {
        const executedLength = _.values(response).filter(
          (status) => status == 1
        ).length;
        console.log("executedLength is = ", executedLength);
        if (executedLength > 0) {
          this.setState({
            // executedLength: executedLength,
            statusAlert: true,
            variantType: "success",
            message: executedLength + " Ad copy successfully published",
            isPosting: false,
          });
        } else {
          this.setState({
            statusAlert: true,
            variantType: "error",
            message: "Error in publishing the Ad copy",
            // executedLength: 0,
            isPosting: false,
          });
        }
      } else {
        this.setState({
          statusAlert: true,
          variantType: "error",
          message: "Error in publishing the Ad copy",
          // executedLength: 0,
          isPosting: false,
        });
      }
    }
    /*this.setState({
                isPosting: false,
            });*/
  };

  render() {
    console.log("taskData in form=", this.props.taskData);
    console.log("AdCopyHierarchy State:=", this.state);
    const { classes } = this.props;
    const { statusAlert, variantType, message } = this.state;

    return (
      <Fragment>
        <div className={classes.field}>
          <ToolTipContent
            title={"Final URL"}
            toolTipText={
              "The final URL is the URL that people reach after clicking your ad. It should match what your ad promotes."
            }
          />
          <TDInputFields
            // label="Final URL"
            value={this.state.finalUrl}
            onChange={this.handleChange("finalUrl")}
            margin="normal"
            variant="outlined"
            className={classes.textField}
            error={this.state.finalUrlError != ""}
            helperText={this.state.finalUrlError}
            fullWidth={true}
            placeholder="finalUrl"
          />
        </div>
        <Typography style={{ paddingBottom: 15 }}></Typography>
        <div className={classes.field}>
          <ToolTipContent
            title={"Headline 1"}
            toolTipText={
              "Headline 1 appears at the top of your ad and can be up to 30 characters."
            }
          />
          <TDInputFields
            // label="Headline 1"
            value={this.state.headLine1}
            onChange={this.handleChange("headLine1")}
            margin="normal"
            variant="outlined"
            inputProps={{
              maxLength: 30,
            }}
            error={this.state.headLine1Error != ""}
            helperText={this.state.headLine1Error}
            className={classes.textField}
            fullWidth={true}
            placeholder="Headline 1"
          />
          <Typography align={"right"} className={classes.lengthTags}>
            {this.state.headLine1.length}/30
          </Typography>
        </div>
        <div className={classes.field}>
          <ToolTipContent
            title={"Headline 2"}
            toolTipText={
              "Headline 2 appears after Headline 1 at the top of your ad and can be up to 30 characters. It may wrap to the second line of your ad on mobile devices."
            }
          />
          <TDInputFields
            // label="Headline 2"
            value={this.state.headLine2}
            onChange={this.handleChange("headLine2")}
            margin="normal"
            variant="outlined"
            inputProps={{
              maxLength: 30,
            }}
            error={this.state.headLine2Error != ""}
            helperText={this.state.headLine2Error}
            className={classes.textField}
            fullWidth={true}
            placeholder="Headline 2"
          />
          <Typography align={"right"} className={classes.lengthTags}>
            {this.state.headLine2.length}/30
          </Typography>
        </div>
        <div className={classes.field}>
          <ToolTipContent
            title={"Headline 3"}
            toolTipText={
              "Headline 3 appears after Headline 2 at the top of your ad and can be up to 30 characters. On mobile devices it may wrap to the second line of your ad. Headline 3 may not always show."
            }
          />
          <TDInputFields
            // label="Headline 3"
            value={this.state.headLine3}
            onChange={this.handleChange("headLine3")}
            margin="normal"
            inputProps={{
              maxLength: 30,
            }}
            error={this.state.headLine3Error != ""}
            helperText={this.state.headLine3Error}
            /* InputProps={{
                             endAdornment: (
                                 <CustomInputAdornment position="end">
                                     <CustomBadge badgeContent={"New"} max={999} color="primary"/>
                                 </CustomInputAdornment>
                             ),
                         }}*/
            variant="outlined"
            className={classes.textField}
            fullWidth={true}
            placeholder="Headline 3"
          />
          <Typography align={"right"} className={classes.lengthTags}>
            {this.state.headLine3.length}/30
          </Typography>
        </div>
        <div className={classes.field}>
          <ToolTipContent
            title={"Path"}
            toolTipText={
              "Path 1 field is part of your display URL (the green text under your headline text) and can be up to 15 characters. This field is optional. Path 2 appears after Path 1, separated by '/' (forward slash). Paths are not guaranteed to show. This field is optional."
            }
          />
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Typography variant={"body1"} color={"textPrimary"}>
                {this.props.domainUrl}
              </Typography>
            </Grid>
            <Grid item md={6}>
              <TDInputFields
                // label="Path 1"
                value={this.state.path1}
                onChange={this.handleChange("path1")}
                margin="normal"
                variant="outlined"
                inputProps={{
                  maxLength: 15,
                }}
                error={this.state.path1Error != ""}
                helperText={this.state.path1Error}
                className={classes.textField}
                fullWidth={true}
                placeholder="/Path 1"
              />
              <Typography align={"right"} className={classes.lengthTags}>
                {this.state.path1.length}/15
              </Typography>
              {/*<Typography className={classes.flex} variant={"body1"} gutterBottom>
                                <div><b>Path 2</b></div>
                                <span className={classes.infoIcon}>{icons.infoIcon}</span></Typography>*/}
              <TDInputFields
                // label="Path 2"
                value={this.state.path2}
                onChange={this.handleChange("path2")}
                margin="normal"
                variant="outlined"
                inputProps={{
                  maxLength: 15,
                }}
                error={this.state.path2Error != ""}
                helperText={this.state.path2Error}
                className={classes.textField}
                fullWidth={true}
                placeholder="/Path 2"
              />
              <Typography align={"right"} className={classes.lengthTags}>
                {this.state.path2.length}/15
              </Typography>
            </Grid>
          </Grid>
        </div>
        <div className={classes.field}>
          <ToolTipContent
            title={"Description 1"}
            toolTipText={
              "Your ad's Description Line 1 appears below the display URL and can be up to 90 characters."
            }
          />
          <TDInputFields
            // label="Description 1"
            multiline={true}
            rows={4}
            fullWidth={true}
            value={this.state.description1}
            onChange={this.handleChange("description1")}
            margin="normal"
            variant="outlined"
            inputProps={{
              maxLength: 90,
            }}
            error={this.state.description1Error != ""}
            helperText={this.state.description1Error}
            className={classes.textField}
            placeholder="Description 1"
          />
          <Typography align={"right"} className={classes.lengthTags}>
            {this.state.description1.length}/90
          </Typography>
        </div>

        <div className={classes.field}>
          <ToolTipContent
            title={"Description 2"}
            toolTipText={
              "Description Line 2 appears after Description Line 1 and can be up to 90 characters. Description 2 may not always show."
            }
          />
          <TDInputFields
            // label="Description 2"
            multiline={true}
            rows={4}
            fullWidth={true}
            value={this.state.description2}
            onChange={this.handleChange("description2")}
            margin="normal"
            variant="outlined"
            inputProps={{
              maxLength: 90,
            }}
            error={this.state.description2Error != ""}
            helperText={this.state.description2Error}
            placeholder="Description 2"
            /* InputProps={{
                             endAdornment: (
                                 <CustomInputAdornment position="end">
                                     <CustomBadge badgeContent={"New"} max={999} color="primary"/>
                                 </CustomInputAdornment>
                             ),
                         }}*/
            className={classes.textField}
          />
          <Typography align={"right"} className={classes.lengthTags}>
            {this.state.description2.length}/90
          </Typography>
        </div>
        <div className={classes.post}>
          {this.state.isPosting ? (
            <CircularProgress style={{ width: 63, position: "absolute" }} />
          ) : null}
          <PostButton
            disabled={this.state.isPosting == true}
            onClick={this.handlePost}
            variant="raised"
            size="small"
          >
            POST
          </PostButton>
        </div>
        <MySnackbarWrapper
          open={statusAlert}
          onClose={this.handleCloseAlert}
          message={
            <Grid container>
              <Grid item md={12} sm={12} xs={12}>
                <Typography variant={"h3"} style={{ color: "#ffffff" }}>
                  <b>{variantType == "success" ? "Success" : "Failed"}</b>
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant={"h3"}
                  style={{ color: "#ffffff" }}
                  variant={"body1"}
                >
                  {message}
                </Typography>
              </Grid>
            </Grid>
          }
          verticalAlign={"bottom"}
          horizontalAlign={"right"}
          duration={6000}
          variant={this.state.variantType}
        />
      </Fragment>
    );
  }
}

AdCopyHierarchyFrom.propTypes = {};
const mapDispatchToProps = (dispatch) => ({
  executeAddAdCopyTask: (adcopyContentObj) =>
    dispatch(executeAddAdcopyTask(adcopyContentObj)),
});
export default connect(
  null,
  mapDispatchToProps
)(withStyles(adCopyHierarchyStyles, { withTheme: true })(AdCopyHierarchyFrom));
