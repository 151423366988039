import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Card, Grid, Typography } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import {CompactCardContent} from "../cards";
import {SubSectionCardHeader, calculateCustomerOpportunity} from "./templates";
import icons from "../icons";

const customerOpportunityStyles = (theme) => ({
    count: {
        color: theme.palette.hyperLink,
        fontFamily:'Montserrat',
    },
    para: {
        fontSize: 14,
        fontWeight: "300",
        color: theme.palette.graderGraphColors.text
    },
    smPara: {
        fontSize: 12,
        fontWeight: "300",
        color: theme.palette.graderGraphColors.text
    },
    grnHead: {
        fontSize: 18,
        color: theme.palette.graderGraphColors.green,
        fontFamily:'Montserrat',
    }
});


class CustomerOpportunity extends React.PureComponent {

    render() {
        const {data, classes} = this.props;

        return (
            <Card className={"fullHeight"}>
                <SubSectionCardHeader title={"New Customer Opportunity"}/>
                <CompactCardContent className={"compactVertical"} style={{minHeight:"43vh"}}>
                    {data ? calculateCustomerOpportunity(data,classes) : "no data"}
                </CompactCardContent>
            </Card>
        );
    }
}

CustomerOpportunity.propTypes = {};
// withStyles(campaignSnapshotStyles)(CampaignSnapshot)
export default withStyles(customerOpportunityStyles)(CustomerOpportunity);
