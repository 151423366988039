import * as React from "react";

function SvgDashboard(props) {
  return (
    <svg width={18} height={18} fill="none" {...props}>
      <path
        d="M6.717 3.72a.826.826 0 01.897.446.84.84 0 01.095.193c.102 1.573.21 3.119.312 4.665-.002.16.023.32.075.473.122.3.425.49.754.473l4.992-.32.034.014.09.006c.181.023.35.106.477.235.15.152.231.355.228.565-.197 2.866-2.299 5.262-5.159 5.879s-5.792-.692-7.196-3.214a6.161 6.161 0 01-.768-2.352 4.464 4.464 0 01-.047-.74c.01-3.048 2.18-5.679 5.216-6.323zM9.685 1.5c3.428.103 6.293 2.592 6.812 5.918.004.02.004.04 0 .06v.094a.587.587 0 01-.147.337.609.609 0 01-.416.202l-5.76.38-.095.002a.686.686 0 01-.425-.175.66.66 0 01-.22-.494l-.387-5.657v-.093a.595.595 0 01.198-.417.618.618 0 01.44-.156z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgDashboard;
