import React from "react";
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import classNames from "classnames";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import NumberFormat from "react-number-format";
import {
  OutLinedOrangeBtnBlueBorder,
  OutLinedOrangeBtnBlueBorderNoUnderLine,
} from "../buttons";
import * as PropTypes from "prop-types";
import {
  CustomListItem,
  CustomListItemText,
  SubSectionCardHeader,
} from "../siteGrader/templates";
import moment from "moment";
import { Link } from "react-router-dom";
import icons from "../icons";
import KeywordScoreCard from "../internalSiteGrader/keywordScoreCard";

function ErrorsTrafficPaidSearch(props) {
  const { siteGrader, classes, type } = props;
  const seoTrafficEstimate =
    siteGrader != null && siteGrader.seoTrafficEstimate
      ? siteGrader.seoTrafficEstimate
      : 0;
  const monthlyAdwordsBudget =
    siteGrader != null && siteGrader.monthlyAdwordsBudget
      ? siteGrader.monthlyAdwordsBudget
      : 0;
  const robotsText =
    siteGrader != null && siteGrader.technical
      ? siteGrader.technical.robotsTxt
      : false;
  const validSSLCertificate =
    siteGrader != null && siteGrader.technical
      ? siteGrader.technical.validSSLCertificate
      : false;
  const brokenURLs =
    siteGrader != null && siteGrader.technical
      ? siteGrader.technical.brokenURLs
      : null;
  const wwwResolved =
    siteGrader != null && siteGrader.technical
      ? siteGrader.technical.wwwResolved
      : false;
  const structuredDataScore =
    siteGrader != null && siteGrader.technical
      ? siteGrader.technical.structuredDataScore
      : 0;
  const domainUrl =
    siteGrader != null && siteGrader.url ? siteGrader.url : null;
  return (
    <Grid container spacing={2}>
      <Grid item md={4} sm={12}>
        <Card className={classes.midCard}>
          <SubSectionCardHeader
            title={"On-Page SEO"}
            className={classNames(classes.cardTitle, classes.paper)}
          />
          <CardContent>
            <List component="nav">
              <CustomListItem>
                <CustomListItemText
                  className={classes.listItemText}
                  primary="Robots.txt"
                />
                <ListItemIcon>
                  <div>
                    {robotsText != true ? (
                      <Typography
                        align={"left"}
                        className={classes.crossStyle}
                        style={{ paddingTop: 5 }}
                      >
                        {icons.closeCircle}
                      </Typography>
                    ) : (
                      <Typography
                        className={classes.checkedStyle}
                        style={{ paddingTop: 5 }}
                      >
                        {icons.checkedCircle}
                      </Typography>
                    )}
                  </div>
                </ListItemIcon>
              </CustomListItem>
              <CustomListItem>
                <CustomListItemText primary="Valid SSL Certificate" />
                <ListItemIcon>
                  <div>
                    {validSSLCertificate != true ? (
                      <Typography
                        align={"left"}
                        className={classes.crossStyle}
                        style={{ paddingTop: 5 }}
                      >
                        {icons.closeCircle}
                      </Typography>
                    ) : (
                      <Typography
                        className={classes.checkedStyle}
                        style={{ paddingTop: 5 }}
                      >
                        {icons.checkedCircle}
                      </Typography>
                    )}
                  </div>
                </ListItemIcon>
              </CustomListItem>
              <CustomListItem>
                <CustomListItemText primary="Broken URLs" />
                <ListItemIcon>
                  <div>
                    {brokenURLs != null ? (
                      <Typography
                        className={classes.crossStyle}
                        style={{ paddingTop: 5 }}
                      >
                        {icons.closeCircle}
                      </Typography>
                    ) : (
                      <Typography
                        className={classes.checkedStyle}
                        style={{ paddingTop: 5 }}
                      >
                        {icons.checkedCircle}
                      </Typography>
                    )}
                  </div>
                </ListItemIcon>
              </CustomListItem>
              <CustomListItem>
                <CustomListItemText primary="www Resolved" />
                <ListItemIcon>
                  <div>
                    {wwwResolved == false ? (
                      <Typography
                        className={classes.crossStyle}
                        style={{ paddingTop: 5 }}
                      >
                        {icons.closeCircle}
                      </Typography>
                    ) : (
                      <Typography
                        className={classes.checkedStyle}
                        style={{ paddingTop: 5 }}
                      >
                        {icons.checkedCircle}
                      </Typography>
                    )}
                  </div>
                </ListItemIcon>
              </CustomListItem>
              <CustomListItem>
                <CustomListItemText primary="Structured Data Score" />
                <ListItemIcon>
                  <div
                    className={
                      structuredDataScore == 0
                        ? classes.buttonFalse
                        : classes.buttonTrue
                    }
                  >
                    {`${structuredDataScore} / 100`}
                  </div>
                </ListItemIcon>
              </CustomListItem>
            </List>
          </CardContent>
        </Card>
      </Grid>
      <Grid item md={4} sm={12}>
        <Card className={classes.midCard}>
          <SubSectionCardHeader
            title={"Website Traffic"}
            className={classNames(classes.cardTitle, classes.paper)}
          />
          <CardContent style={{ minHeight: "24vh" }}>
            <br />
            {seoTrafficEstimate > 0 &&
            !(
              robotsText == true &&
              validSSLCertificate == true &&
              brokenURLs == null &&
              wwwResolved != false &&
              structuredDataScore > 0
            ) ? (
              <Typography
                variant={"h6"}
                className={classes.padding2X}
                align={"left"}
                color={"textSecondary"}
                gutterBottom
              >
                You could have{" "}
                <span className={classes.lightGreen}>
                  <b>
                    <NumberFormat
                      value={seoTrafficEstimate}
                      thousandSeparator={true}
                      readOnly={true}
                      displayType={"text"}
                    />
                  </b>
                </span>{" "}
                more visitors. Fix those on-page seo issues now to capture their
                attention!
              </Typography>
            ) : (
              <Typography
                variant={"h6"}
                className={classes.padding2X}
                align={"left"}
                color={"textSecondary"}
                gutterBottom
              >
                {type && type == "1"
                  ? "Find out how you can improve traffic to your website."
                  : "Find out how you can improve traffic to your website. Visit LXRGuide.com."}
              </Typography>
            )}
          </CardContent>
          <CardActions className={"justifyCenter"}>
            <Typography
              className={classes.padding2X}
              align={"center"}
              gutterBottom
            >
              {/*<Link to={`/seo-services/${domainUrl}`} style={{textDecoration: "none"}}>
                            <OutLinedOrangeBtnBlueBorderNoUnderLine variant={"outlined"}>
                                <b>Request Expert Help</b>
                            </OutLinedOrangeBtnBlueBorderNoUnderLine>
                        </Link>*/}
              <Link
                to={`/seo/expert-help`}
                style={{ textDecoration: "none" }}
                target={"_blank"}
              >
                <OutLinedOrangeBtnBlueBorderNoUnderLine variant={"outlined"}>
                  <b>Request Expert Help</b>
                </OutLinedOrangeBtnBlueBorderNoUnderLine>
              </Link>
              {/*{type && type != "1" ?
                                <Link to={`/seo-services-packages/${domainUrl}`}
                                      style={{textDecoration: "none"}}>
                                    <OutLinedOrangeBtnBlueBorderNoUnderLine variant={"outlined"}>
                                        <b>Fix Now</b>
                                    </OutLinedOrangeBtnBlueBorderNoUnderLine>
                                </Link>
                                :
                                <Link to={`/seo-packages-internal`}
                                      style={{textDecoration: "none"}}>
                                    <OutLinedOrangeBtnBlueBorderNoUnderLine variant={"outlined"}>
                                        <b>Fix Now</b>
                                    </OutLinedOrangeBtnBlueBorderNoUnderLine>
                                </Link>
                            }*/}
            </Typography>
          </CardActions>
        </Card>
      </Grid>
      <Grid item md={4} sm={12}>
        <Card className={classes.midCard}>
          <SubSectionCardHeader
            title={type && type == "1" ? "Top Keywords" : "Paid Search"}
            className={classNames(classes.cardTitle, classes.paper)}
          />
          <CardContent style={{ minHeight: "24vh" }}>
            {type && type == "1" ? (
              <div>
                <KeywordScoreCard siteGrader={siteGrader} />
              </div>
            ) : (
              <div>
                <br />
                {monthlyAdwordsBudget > 0 ? (
                  <Typography
                    variant={"h6"}
                    // className={classes.padding2X}
                    align={"left"}
                    color={"textSecondary"}
                    gutterBottom
                  >
                    In {moment().subtract(1, "month").format("MMMM")} an
                    estimated{" "}
                    <span className={classes.darkRed}>
                      <b>
                        <NumberFormat
                          value={monthlyAdwordsBudget}
                          thousandSeparator={true}
                          prefix={"$"}
                          readOnly={true}
                          displayType={"text"}
                        />
                      </b>
                    </span>{" "}
                    was spent on Google Ads.
                  </Typography>
                ) : (
                  <Typography
                    variant={"h6"}
                    // className={classes.padding2X}
                    align={"left"}
                    color={"textSecondary"}
                    gutterBottom
                  >
                    Increase search traffic with Google Ads, a leader in paid
                    search advertising.
                  </Typography>
                )}
                {monthlyAdwordsBudget > 0 ? (
                  <p className={classes.lightRedPinkBg}>
                    Find out if you are spending your budget efficiently.
                  </p>
                ) : (
                  <p className={classes.lightRedPinkBg}>
                    Find out how LXRGuide can help with your Google Ads
                    performance.
                  </p>
                )}
              </div>
            )}
          </CardContent>
          {type && type != "1" ? (
            <CardActions className={"justifyCenter"}>
              <Typography
                className={classes.padding2X}
                align={"center"}
                gutterBottom
              >
                <a
                  href={"/adwords-grader.html"}
                  className={classes.textDec}
                  target={"_blank"}
                >
                  <OutLinedOrangeBtnBlueBorder variant={"outlined"}>
                    <b>Get a Free Google Ads Scorecard</b>
                  </OutLinedOrangeBtnBlueBorder>
                </a>
              </Typography>
            </CardActions>
          ) : null}
        </Card>
      </Grid>
    </Grid>
  );
}

ErrorsTrafficPaidSearch.propTypes = {
  classes: PropTypes.any,
  robotsText: PropTypes.bool,
  validSSLCertificate: PropTypes.bool,
  brokenURLs: PropTypes.any,
  wwwResolved: PropTypes.bool,
  structuredDataScore: PropTypes.number,
  seoTrafficEstimate: PropTypes.number,
  monthlyAdwordsBudget: PropTypes.number,
};
export default ErrorsTrafficPaidSearch;
