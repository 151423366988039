import * as types from "../actions/actionTypes";
export default function websiteHealthEmailDataReducers(
  state = {
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.FETCH_HEALTH_SCORECARD_EMAIL_DATA_REQUESTED:
      return Object.assign({}, state, {
        message: "FETCH_HEALTH_SCORECARD_EMAIL_DATA_REQUESTED",
      });
    case types.FETCH_HEALTH_SCORECARD_EMAIL_DATA_RECEIVED:
      let { data } = action;
      console.log("FETCH_HEALTH_SCORECARD_EMAIL_DATA_RECEIVED", data);
      return Object.assign({}, state, {
        ...action.data,
        message: "FETCH_HEALTH_SCORECARD_EMAIL_DATA_RECEIVED",
      });
    case types.FETCH_HEALTH_SCORECARD_EMAIL_DATA_FAILED:
      return Object.assign({}, state, {
        message: "FETCH_HEALTH_SCORECARD_EMAIL_DATA_FAILED",
      });
    default:
      return state;
  }
  
}
