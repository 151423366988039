import React, { Component, useEffect } from "react";
import PropTypes from "prop-types";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import googleAdsGraderStyles from "../../assets/jss/googleAdsGrader/googleAdsGraderStyle";
import {
  BlueTitleTypography,
  OrangeTypography,
} from "../../components/typography";
import icons from "../../components/icons";
import SixMetrics from "../googleAdsGrader/six-metrics";
import SafeAndSecure from "../../components/safe-and-secure";
import SiteGraderHomeForm from "./SiteGraderHomeForm";
import { OverviewSection } from "../googleAdsGrader/googleAdHome";
import MetaTags from "react-meta-tags";
import * as types from "../../actions/actionTypes";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="xl" />;

let id = 1;

function createSiteGraderData(img, head, title, para, para2, bg, leftAlign) {
  id += 1;
  return { id, img, head, title, para, para2, bg, leftAlign };
}

export const seoSiteGraderData = [
  createSiteGraderData(
    "onPageScoreNew",
    "Comprehensive analysis and recommendations on six SEO factors that can help you get found on Search Engines",
    "On Page Score",
    "Starting right from the Meta title in the HTML documents, the descriptions, Images, header tags, URL structure, consistency and density of keywords, everything about your website is measured and analysed in detail to determine the level of your website optimization.  The report generated by the grader based on this analysis will give you an idea of the average performance of the individual pages and the aggregate score. It will point out areas which need improvement. Importantly, the grader will help you determine and eliminate those factors which are preventing your site from getting a higher ranking or grade.",
    null,
    true,
    false
  ),
  createSiteGraderData(
    "mobileCompatibilityNew",
    null,
    "Mobile Compatibility Score",
    "Mobile compatibility scores are based on responsive web page design on mobile devices and various customer platforms, screens and displays.  The SEO grader checks for AMP (indicative of faster loading time across devices and platforms), mobile website configurations, presence of meta view port tag and mobile speed performance.  All results are analysed and graded to determine the level of mobile optimization for the site. The resulting score will tell you more about your mobile conversion rates and your chances of gaining a higher search engine ranking.  Remember, the quality of user experience on mobile is a signal to search engines.",
    null,
    false,
    true
  ),
  createSiteGraderData(
    "linkValidityScoreNew",
    null,
    "Link Validity Score",
    "Link validity score evaluates the quality, pattern, and distribution of the backlinks to your website, outbound links, domain score, the quality of referring domains, presence of SEO-friendly and relevant anchor text and the internal links across your site.  The report will help you identify the problem areas in link building and optimize link building strategies for better rankings.",
    null,
    true,
    false
  ),
  createSiteGraderData(
    "websiteTechinicalScore",
    null,
    "Website Technical Score",
    "The grader checks for technical factors like presence of robots.txt files, structured date in specific formats like schema.org, custom error pages, broken links, SSL certificates for secure connections, WWW resolve and the presence of site maps. All these factors together impact the bots’ ability to find, crawl and index your site, visitors’ ability to access your site, and defines the trustworthiness of your site and influences rankings. ",
    null,
    false,
    true
  ),
  createSiteGraderData(
    "socialMediaScore",
    null,
    "Social Media Score",
    "The grader checks for your social media presence – Facebook, Twitter, Instagram, blogs - and works it into its SEO calculations. " +
      "It checks for the presence of optimized Open Graph tags. Social media presence signals authority and most often social profiles are listed in searches. Open Graph tags when optimized drives more social media traffic to websites.  The grader report will highlight ways in which social signals may influence rankings. ",
    null,
    true,
    false
  ),
  createSiteGraderData(
    "websiteTrafficAnalysis",
    null,
    "Website Traffic Analysis",
    "LXRGuide’s SEO grader presents a series of graphs and statistics including number of google indexed pages and important metrics like Visits, Page Views and Daily reach. These metrics give a snapshot of the vibrancy and health of your website pages and a grasp of which areas/pages to concentrate for securing better rankings.",
    null,
    false,
    true
  ),
];

function SiteGraderHomePage(props) {
  const { classes, width } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
    props.seoSiteGraderPageTrack();
  }, []);

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent} style={{ padding: 0 }}>
        <Helmet>
          <title>SEO Grader Tool | Free SEO Grader Tools | LXRGuide</title>
          <meta
            name="description"
            content="Need help on how to do an SEO audit? Upgrade your SEO performance with our simple yet powerful free SEO Grader tool. Key features include SEO optimization tips, an SEO audit, and more."
          />
        </Helmet>
        <Grid container columnSpacing={8}>
          <Grid item md={12} className={classes.sectionBg}>
            <OverviewSection
              classes={classes}
              icon={icons.seoGraderHomePageIcon}
              pageCaption={"SEO SITE GRADER"}
              title={"HELP YOUR WEBSITE GET FOUND ON GOOGLE"}
              para={
                "LXRGuide’s SEO grader gives you a free technical SEO audit report of your website in under 5 minutes. " +
                " Use the information to optimize your website performance and ranking, and get found on Google."
              }
              width={width}
            >
              <SiteGraderHomeForm />
            </OverviewSection>
          </Grid>
          <Grid item md={12}>
            <SixMetrics classes={classes} data={seoSiteGraderData} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
const mapDispatchToProps = (dispatch) => ({
  seoSiteGraderPageTrack: () =>
    dispatch({ type: types.GOOGLE_ADS_GRADER_HOME_TRACK }),
});
export default connect(
  null,
  mapDispatchToProps
)(withWidth()(withStyles(googleAdsGraderStyles)(SiteGraderHomePage)));
