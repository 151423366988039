import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import {
  Grid,
  Typography,
  Button,
  Popover,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import {
  fetchHealthScorecardData,
  refreshWHSData,
  websiteHealthRefreshStatus,
   recordWhsUserLog,
} from "../../actions";
import OverallScore_WHC from "./overallScore_WHC";
import WhsErrorPage from "./whsErrorPage";
import icons from "../../components/icons";
import ParameterSection_WHC from "./parameterSection_WHC";
import "./WebsiteHealthScoreCard.css";
import { MetaTags } from "react-meta-tags";
import { WHSDescription } from "../../components/typography";
import LinearProgressBar from "./linearProgressBar";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { NewGreyOutLinedRectBtn } from "../../components/buttons";

const styles = (theme) => ({
  mainDiv: {
    [theme.breakpoints.up("md")]: {
      padding: "35px 50px 30px 50px",
      backgroundColor: "#F9FAFB",
    },
    [theme.breakpoints.down("md")]: {
      padding: "30px 15px 15px 15px",
      backgroundColor: "#F9FAFB",
    },
  },
  popover: {
    borderRadius: "10px",
  },
  customDialogTitle: {
    fontSize: "1.125rem",
  },
});

function HealthScorecard(props) {
  let externalUserId = "";
  useEffect(() => {
    fetchHealthScorecardData(externalUserId);
    recordWhsUserLog();
  }, []);

  const {
    websiteHealthScoreDataReducers,
    fetchHealthScorecardData,
    refreshWHSDataReducer,
    refreshWHSData,
    websiteHealthRefreshStatusReducer,
    websiteHealthRefreshStatus,
    classes,
    recordWhsUserLog,
    recordWhsUserLogReducer,
  } = props;

  console.log("External user id in health scorecard component",externalUserId);

  // const recordWhsUserLogStore = recordWhsUserLogReducer.success;
  // console.log("recordWhsUserLogStore====>"+recordWhsUserLogStore)

  const newDiscoverability = websiteHealthScoreDataReducers.discoverability;

  const newPerformance = websiteHealthScoreDataReducers.performance;

  const newAccessabilityData = websiteHealthScoreDataReducers.accessibility;

  const newSecurityData = websiteHealthScoreDataReducers.security;

  const lastUpdatedDate = websiteHealthScoreDataReducers.lastUpdatedDate;

  const clientWebsiteUrl = websiteHealthScoreDataReducers.clientUrl;

  const clientWebsiteUrlWithHttps = clientWebsiteUrl && clientWebsiteUrl.startsWith("https://");

  const [sectionWithLowestScore, setSectionWithLowestScore] = useState();

  useEffect(() => {
    if (
      websiteHealthScoreDataReducers &&
      websiteHealthScoreDataReducers["message"] ===
        "FETCH_HEALTH_SCORECARD_DATA_RECEIVED"
    ) {
      const scores = {
        discoverability: newDiscoverability
          ? newDiscoverability.discoverabilityScore
          : 0,
        performance: newPerformance ? newPerformance.performanceScore : 0,
        accessibility: newAccessabilityData
          ? newAccessabilityData.accessibilityScore
          : 0,
        security: newSecurityData ? newSecurityData.securityScore : 0,
      };

      const sectionWithLowestScore = Object.entries(scores).reduce((a, b) =>
        a[1] < b[1] ? a : b
      )[0];

      setSectionWithLowestScore(sectionWithLowestScore);
    }
  }, [websiteHealthScoreDataReducers]);

  const [emptyDataCheck, setEmptyDataCheck] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [completedValue, setCompletedValue] = useState(
    websiteHealthRefreshStatusReducer
      ? websiteHealthRefreshStatusReducer.complete_value
      : 5
  );
  const [completedStatus, setCompletedStatus] = useState(
    websiteHealthRefreshStatusReducer
      ? websiteHealthRefreshStatusReducer.status
      : null
  );

  const targetDate = new Date(lastUpdatedDate);
  const oneDayInMilliseconds = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
  const currentDate = new Date();
  const isOneDayAhead = currentDate - targetDate >= oneDayInMilliseconds;

  const formattedDateTime = targetDate.toLocaleString("en-US", {
    weekday: "long",
    month: "long",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  console.log("last updated", targetDate, currentDate, isOneDayAhead);
  const [open, setOpen] = useState(false);

  const handleClickOpen = (event) => {
    if (isOneDayAhead) {
      setOpen(true);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenPopover = (event) => {
    if (!isOneDayAhead) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);

  let syncCheckId;

  //Refresh button
  const handleRefreshWHS = async () => {
    setOpen(false);
    const data = await refreshWHSData();
    if (data != null) {
      syncCheckId = data.syncCheckId;
    }
  };

  if (refreshWHSDataReducer.syncCheckId) {
    syncCheckId = refreshWHSDataReducer.syncCheckId;
  }
  console.log("syncCheckId ==", syncCheckId, refreshWHSDataReducer.syncCheckId);

  //for calling 2nd API for refresh status
  const countRef = useRef(null);
  countRef.current = completedValue;
  useEffect(() => {
    if (syncCheckId) {
      const interval = setInterval(() => {
        if (countRef.current >= 100 || completedStatus === "Failed") {
          clearInterval(interval);
          window.location.reload();
        } else {
          websiteHealthRefreshStatus(syncCheckId);
        }
      }, 10000);
    }
  }, [refreshWHSDataReducer.syncCheckId]);

  //for storing refresh status
  useEffect(() => {
    if (websiteHealthRefreshStatusReducer) {
      setCompletedValue(websiteHealthRefreshStatusReducer.complete_value);
      setCompletedStatus(websiteHealthRefreshStatusReducer.status);
    }
  }, [
    websiteHealthRefreshStatusReducer &&
      websiteHealthRefreshStatusReducer.status,
  ]);

  useEffect(() => {
    if (
      newDiscoverability ||
      newPerformance ||
      newAccessabilityData ||
      newSecurityData
    ) {
      setEmptyDataCheck(false);
    } else {
      setEmptyDataCheck(true);
    }
  }, [
    newDiscoverability,
    newPerformance,
    newAccessabilityData,
    newSecurityData,
  ]);

  window.scrollTo(0, 0);



  const progressBarColor = `linear-gradient(90deg, #FFBD15 0%, rgba(132, 205, 1, 0.46) 100%)`;
  return (
    <div>
      {websiteHealthScoreDataReducers["message"] ===
        "FETCH_HEALTH_SCORECARD_DATA_FAILED" || emptyDataCheck === true ? (
        ""
      ) : (
        <Grid container justify="center" alignItems="center">
          <Grid
            item
            style={{
              padding: "5px 20px 20px 20px",
              textAlign: "center",
            }}
          >
            <WHSDescription>
              {icons.WHSDescription} Unlock your site's potential with our 4D
              Scorecard: Discoverability, Performance, Accessibility, and
              Security at a glance!
            </WHSDescription>
          </Grid>
        </Grid>
      )}
      <div className={classes.mainDiv}>
        <MetaTags>
          <title> LXRGuide - Website Health Scorecard </title>
        </MetaTags>
        {websiteHealthScoreDataReducers["message"] ===
          "FETCH_HEALTH_SCORECARD_DATA_FAILED" || emptyDataCheck === true ? (
          <Grid container>
            <Grid item>
              <div>
                <WhsErrorPage />
              </div>
            </Grid>
          </Grid>
        ) : (
          <Grid container columnSpacing={8} direction="column">
            <Grid item>
              <Grid container direction="row">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  style={{
                    textAlign: "left",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h3" style={{ color: "#6D6D6D" }}>
                    <b>Website URL:</b> {clientWebsiteUrl} &nbsp;
                    {clientWebsiteUrlWithHttps == true ? (
                      <a
                        href={clientWebsiteUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {icons.WHSExternalLink}
                      </a>
                    ) : (
                      <a
                        href={"https://" + clientWebsiteUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {icons.WHSExternalLink}
                      </a>
                    )}
                    <br />
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="lastUpdatedDateCss"
                >
                  <Typography
                    style={{
                      color: "#6D6D6D",
                      fontSize: "0.875rem",
                      fontWeight: "400",
                    }}
                  >
                    {syncCheckId == undefined ? (
                      <div>
                        <b>Last updated:</b> {formattedDateTime}&nbsp;
                      </div>
                    ) : (
                      <span>
                        <div className="lastUpdatedDateCss">
                          <b>Updating: &nbsp;</b>
                          <span>
                            <LinearProgressBar
                              progress={
                                completedValue != undefined ? completedValue : 0
                              }
                              color={
                                completedStatus === "Failed"
                                  ? "#F13B0C"
                                  : progressBarColor
                              }
                            />
                          </span>
                        </div>
                        <span className="lastUpdatedDateCss">
                          {completedStatus != null ||
                          completedStatus != undefined ? (
                            completedStatus !== "Failed" ? (
                              completedStatus
                            ) : (
                              <span
                                style={{ color: "rgb(241, 59, 12)" }}
                                className="lastUpdatedDateCss"
                              >
                                {icons.WHSUnchecked}&nbsp; Failed. Please retry
                                later.
                              </span>
                            )
                          ) : (
                            "0% Completed"
                          )}
                        </span>{" "}
                      </span>
                    )}
                  </Typography>
                  {syncCheckId == undefined ? (
                    <span
                      onClick={handleOpenPopover}
                      aria-owns={openPopover ? "simple-popper" : undefined}
                      aria-haspopup="true"
                    >
                      <NewGreyOutLinedRectBtn
                        onClick={handleClickOpen}
                        disabled={!isOneDayAhead}
                      >
                        Refresh
                      </NewGreyOutLinedRectBtn>
                    </span>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </Grid>
           <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <OverallScore_WHC
                websiteHealthScoreDataReducers={websiteHealthScoreDataReducers}
                sectionWithLowestScore={sectionWithLowestScore}
                externalUserId = {externalUserId}
              />
            </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <ParameterSection_WHC
                discoverabilityData={newDiscoverability}
                performanceData={newPerformance}
                accessibilityData={newAccessabilityData}
                securityData={newSecurityData}
              />
            </Grid>
          </Grid>
        )}
        <Popover
          classes={{ paper: classes.popover }}
          id="simple-popper"
          open={openPopover}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Typography
            style={{
              margin: "10px",
              color: "rgb(109, 109, 109)",
              fontSize: "14px",
            }}
            align="center"
          >
            Your Website Health <br /> Scorecard is up to date.
          </Typography>
        </Popover>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            className={classes.customDialogTitle}
          >
            Are you sure you want to refresh Website Health Scorecard data?
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              No
            </Button>
            <Button onClick={handleRefreshWHS} color="primary" autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  websiteHealthScoreDataReducers: state.websiteHealthScoreDataReducers,
  refreshWHSDataReducer: state.refreshWHSDataReducer,
  websiteHealthRefreshStatusReducer: state.websiteHealthRefreshStatusReducer,
  recordWhsUserLogReducer: state.recordWhsUserLogReducer,
});

const mapDispatchToProps = (dispatch) => ({
  fetchHealthScorecardData: (externalUserId) => dispatch(fetchHealthScorecardData(externalUserId)),
  recordWhsUserLog: () => dispatch(recordWhsUserLog()),
  refreshWHSData: () => dispatch(refreshWHSData()),
  websiteHealthRefreshStatus: (syncCheckId) =>
    dispatch(websiteHealthRefreshStatus(syncCheckId)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(HealthScorecard));
