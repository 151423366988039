import React, { Fragment } from "react";
import { Grid, Typography, CardHeader } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import classNames from "classnames";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import icons from "../icons";

export function GetMeters({ classes, speedScore, secounds, textColor, type }) {
  const heightStyles = type && type == 1 ? classes.meter_h2 : classes.meter_h1;
  return (
    <div>
      <Grid container justifyContent={"center"}>
        <Grid item md={9} sm={8} xs={12}>
          <Grid container>
            <Grid item md={12} sm={12} xs={12}>
              <div
                className={classes.headPointer}
                style={{
                  width: speedScore ? speedScore + "%" : "15%",
                  transition: speedScore ? "all 3s" : "unset",
                }}
              >
                <div
                  className={
                    secounds != 0 ? classes.pointer : classes.pointerInvalid
                  }
                >
                  {/*<Typography className={secounds != 0 ? classNames(classes.pointerText, textColor): classNames(classes.pointerTextInvalid)}
                                            component={"div"}>{secounds}s</Typography>*/}
                  <Typography
                    className={classNames(classes.pointerText, textColor)}
                    component={"div"}
                  >
                    {secounds}s
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <div className={"flex"} style={{ width: "100%" }}>
                <div
                  style={{ width: "15%" }}
                  lg={2}
                  md={2}
                  sm={2}
                  className={classNames(classes.meter1, heightStyles)}
                >
                  <Typography className={classes.scaleText} align={"right"}>
                    3s
                  </Typography>
                </div>
                <div
                  style={{ width: "20%" }}
                  lg={2}
                  md={2}
                  sm={2}
                  className={classNames(classes.meter2, heightStyles)}
                >
                  <Typography className={classes.scaleText} align={"right"}>
                    5s
                  </Typography>
                </div>
                <div
                  style={{ width: "25%" }}
                  lg={2}
                  md={2}
                  sm={2}
                  className={classNames(classes.meter3, heightStyles)}
                >
                  <Typography className={classes.scaleText} align={"right"}>
                    8s
                  </Typography>
                </div>
                <div
                  style={{ width: "35%" }}
                  lg={2}
                  md={2}
                  sm={2}
                  className={classNames(classes.meter4, heightStyles)}
                >
                  <Typography className={classes.scaleText} align={"right"}>
                    30s
                  </Typography>
                </div>
                <div
                  style={{ width: "5%" }}
                  md={4}
                  sm={4}
                  className={classNames(classes.meter5, heightStyles)}
                ></div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export function GetMetersWithOutSeconds({
  classes,
  speedScore,
  secounds,
  textColor,
  type,
}) {
  const heightStyles = classes.meter_h2;
  return (
    <div>
      <Grid container justifyContent={"center"}>
        <Grid item md={9} sm={8} xs={12}>
          <Grid container>
            <Grid item md={12} sm={12} xs={12}>
              <div
                className={classes.headPointer}
                style={{
                  // width: speedScore ? speedScore + "%" : "0%",
                  width: speedScore ? speedScore + "%" : "0%",
                  transition: speedScore ? "all 3s" : "unset",
                }}
              >
                <div
                  className={
                    secounds != 0
                      ? classNames(
                          classes.pointerBlack,
                          classes.pointerInternal
                        )
                      : classes.pointerInvalid
                  }
                >
                  {/*<Typography className={secounds != 0 ? classNames(classes.pointerText, textColor): classNames(classes.pointerTextInvalid)}
                                            component={"div"}>{secounds}s</Typography>*/}
                  <Typography
                    className={classNames(classes.pointerText, textColor)}
                    component={"div"}
                  ></Typography>
                </div>
              </div>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <div className={"flex"} style={{ width: "100%" }}>
                <div
                  style={{ width: "15%" }}
                  lg={2}
                  md={2}
                  sm={2}
                  className={classNames(classes.meter1, heightStyles)}
                >
                  <Typography className={classes.scaleText} align={"right"}>
                    3s
                  </Typography>
                </div>
                <div
                  style={{ width: "20%" }}
                  lg={2}
                  md={2}
                  sm={2}
                  className={classNames(classes.meter2, heightStyles)}
                >
                  <Typography className={classes.scaleText} align={"right"}>
                    5s
                  </Typography>
                </div>
                <div
                  style={{ width: "25%" }}
                  lg={2}
                  md={2}
                  sm={2}
                  className={classNames(classes.meter3, heightStyles)}
                >
                  <Typography className={classes.scaleText} align={"right"}>
                    8s
                  </Typography>
                </div>
                <div
                  style={{ width: "35%" }}
                  lg={2}
                  md={2}
                  sm={2}
                  className={classNames(classes.meter4, heightStyles)}
                >
                  <Typography className={classes.scaleText} align={"right"}>
                    30s
                  </Typography>
                </div>
                <div
                  style={{ width: "5%" }}
                  md={4}
                  sm={4}
                  className={classNames(classes.meter5, heightStyles)}
                ></div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

/*export function calculateError(technical) {
    let count = 0;
    if (technical != null) {
        if (technical.robotsTxt != true) {
            count = count + 1;
        }
        if (technical.validSSLCertificate != true) {
            count = count + 1;
        }
        if (technical.brokenURLs != null) {
            count = count + 1;
        }
        if (technical.wwwResolved == false) {
            count = count + 1;
        }
        if (technical.structuredDataScore == 0) {
            count = count + 1;
        }
    }
    return count;
}*/

export function calculateIntegrationStatus(siteGrader) {
  console.log("calculateIntegrationStatus=", siteGrader);
  let statusScore = 0;
  if (siteGrader != null) {
    if (siteGrader.onPage != null) {
      statusScore = statusScore + 10;
    }
    if (siteGrader.keyword != null) {
      statusScore = statusScore + 10;
    }
    if (siteGrader.mobile != null) {
      statusScore = statusScore + 10;
    }
    if (siteGrader.speed != null) {
      statusScore = statusScore + 10;
    }
    if (siteGrader.links != null) {
      statusScore = statusScore + 10;
    }
    if (siteGrader.technical != null) {
      statusScore = statusScore + 10;
    }
    if (siteGrader.social != null) {
      statusScore = statusScore + 10;
    }
    if (siteGrader.traffic != null) {
      statusScore = statusScore + 10;
    }
    if (siteGrader.urlAuditInfoList != null) {
      statusScore = statusScore + 10;
    }
    if (siteGrader.siteGraderScore > 0) {
      statusScore = statusScore + 10;
    }
  }
  return statusScore;
}

export function IconAndNameError({
  icon,
  name,
  caption,
  classes,
  siteGraderScore,
  titleColor,
}) {
  console.log("titleColor=", titleColor);
  return (
    <Grid container style={{ padding: 10 }} alignItems={"center"}>
      <Grid item md={9} className={"growable"}>
        <Grid container spacing={2}>
          <Grid item className={classes.icon}>
            {icon}
          </Grid>
          <Grid item>
            <Typography
              className={classNames(
                classes.headTitle,
                classes.titleAlignLeft,
                titleColor
              )}
              style={{ maxWidth: "auto" }}
            >
              {name}
            </Typography>
            <Typography
              color="inherit"
              variant={"caption"}
              className={classNames(classes.caption, classes.titleAlignLeft)}
            >
              {caption}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export function IconAndName({
  icon,
  name,
  caption,
  classes,
  siteGraderScore,
  titleColor,
}) {
  console.log("titleColor=", titleColor);
  return (
    <Grid container style={{ padding: 10 }} alignItems={"center"}>
      <Grid item md={9} className={"growable"}>
        <Grid container spacing={2}>
          <Grid item className={classes.icon}>
            {icon}
          </Grid>
          <Grid item>
            <Typography
              className={classNames(
                classes.headTitle,
                classes.titleAlignLeft,
                titleColor
              )}
              align={"center"}
              style={{ maxWidth: "auto" }}
            >
              {name}
            </Typography>
            <Typography
              color="inherit"
              variant={"caption"}
              className={classNames(classes.caption, classes.titleAlignLeft)}
            >
              {caption}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={3} sm={12} xs={12}>
        <Typography
          component={"h3"}
          variant={"h3"}
          align={"center"}
          className={classNames(classes.titleScore, classes.titleAlign)}
        >
          <b>{siteGraderScore}/100</b>
        </Typography>
        <Typography
          variant="overline"
          component={"p"}
          align={"right"}
          className={classNames(classes.montserrat, classes.titleAlign)}
        >
          SITE SCORE
        </Typography>
        <Typography
          component={"p"}
          align={"right"}
          className={classNames(classes.montserrat, classes.titleAlign)}
        >
          <b>Benchmark score : 60</b>
        </Typography>
      </Grid>
    </Grid>
  );
}

export function InternalTitleIconAndName({
  icon,
  name,
  caption,
  classes,
  siteGraderScore,
  titleColor,
}) {
  console.log("titleColor=", titleColor);
  return (
    <Grid container style={{ padding: 10 }} alignItems={"center"}>
      <Grid item md={9} className={"growable"} justifyContent={"center"}>
        <Grid container spacing={2} justifyContent={"center"}>
          <Grid item className={classes.icon} justifyContent={"center"}>
            {icon}
          </Grid>
          <Grid item>
            <Typography
              component={"h3"}
              variant={"h3"}
              className={classNames(classes.titleScore, classes.titleAlign)}
            >
              <b>{siteGraderScore}/100</b>
            </Typography>
            <Typography
              variant="overline"
              component={"p"}
              align={"center"}
              className={classNames(classes.montserrat, classes.titleAlign)}
            >
              SITE SCORE
            </Typography>
            <Typography
              component={"p"}
              align={"center"}
              className={classNames(classes.montserrat, classes.titleAlign)}
            >
              <b>Benchmark score : 60</b>
            </Typography>
            <Grid item>
              <Typography
                className={classNames(
                  classes.siteScoreheadTitle,
                  classes.titleAlignLeft,
                  titleColor
                )}
                style={{ maxWidth: "auto" }}
              >
                {name}
              </Typography>
              {/*<Typography color="inherit" variant={"caption"}
                                        className={classNames(classes.caption, classes.titleAlignLeft)}>{caption}</Typography>*/}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/*<Grid item md={3} sm={12} xs={12}>
                <Typography component={"h3"} variant={"h3"}
                            className={classNames(classes.titleScore, classes.titleAlign)}
                ><b>{siteGraderScore}/100</b>
                </Typography>
                <Typography variant="overline" component={"p"} align={"right"}
                            className={classNames(classes.montserrat, classes.titleAlign)}>SITE
                    SCORE
                </Typography>
                <Typography component={"p"} align={"right"}
                            className={classNames(classes.montserrat, classes.titleAlign)}><b>Benchmark score :
                    60</b>
                </Typography>
            </Grid>*/}
    </Grid>
  );
}

export const SubSectionCardHeader = withStyles((theme) => ({
  title: {
    fontFamily: "Montserrat",
    color: theme.palette.text.secondary,
    fontSize: 16,
    fontWeight: "bold",
  },
  subheader: {
    fontSize: "12px",
    fontWeight: "300",
    color: theme.palette.text.primary,
  },
}))(CardHeader);
export const CustomListItem = withStyles((theme) => ({
  root: {
    paddingBottom: 0,
    paddingTop: 10,
  },
}))(ListItem);
export const CustomListItemText = withStyles((theme) => ({
  primary: {
    fontFamily: "Lato",
    fontWeight: "bold",
    color: theme.palette.text.secondary,
  },
}))(ListItemText);

export function ReportTitle(props) {
  return (
    <Fragment>
      {props.siteGraderScore <= 49 ? (
        <IconAndName
          icon={icons.cupRed("", "")}
          name={"Your site performance is low and needs improvement!"}
          caption={
            props.redirectedURL != null ? props.redirectedURL : "www.domain.com"
          }
          classes={props.classes}
          titleColor={props.classes.red}
          siteGraderScore={props.siteGraderScore}
        />
      ) : props.siteGraderScore >= 50 && props.siteGraderScore <= 69 ? (
        <IconAndName
          icon={icons.cupYellow("", "")}
          name={"Your site performance looks good!"}
          caption={
            props.redirectedURL != null ? props.redirectedURL : "www.domain.com"
          }
          classes={props.classes}
          titleColor={props.classes.titleYellow}
          siteGraderScore={props.siteGraderScore}
        />
      ) : (
        <IconAndName
          icon={icons.cupGreen("", "")}
          name={"Your site performance looks great!"}
          caption={
            props.redirectedURL != null ? props.redirectedURL : "www.domain.com"
          }
          classes={props.classes}
          titleColor={props.classes.green}
          siteGraderScore={props.siteGraderScore}
        />
      )}
    </Fragment>
  );
}

export function InternalReportTitle(props) {
  return (
    <Fragment>
      {props.siteGraderScore <= 49 ? (
        <InternalTitleIconAndName
          icon={icons.cupRed("", 35)}
          name={"Your site performance is low and needs improvement!"}
          caption={
            props.redirectedURL != null ? props.redirectedURL : "www.domain.com"
          }
          classes={props.classes}
          titleColor={props.classes.red}
          siteGraderScore={props.siteGraderScore}
        />
      ) : props.siteGraderScore >= 50 && props.siteGraderScore <= 69 ? (
        <InternalTitleIconAndName
          icon={icons.cupYellow("", 35)}
          name={"Your site performance looks good!"}
          caption={
            props.redirectedURL != null ? props.redirectedURL : "www.domain.com"
          }
          classes={props.classes}
          titleColor={props.classes.titleYellow}
          siteGraderScore={props.siteGraderScore}
        />
      ) : (
        <InternalTitleIconAndName
          icon={icons.cupGreen("", 35)}
          name={"Your site performance looks great!"}
          caption={
            props.redirectedURL != null ? props.redirectedURL : "www.domain.com"
          }
          classes={props.classes}
          titleColor={props.classes.green}
          siteGraderScore={props.siteGraderScore}
        />
      )}
    </Fragment>
  );
}

export const initialSiteGraderState = {
  url: null,
  redirectedURL: null,
  keyword: null,
  links: null,
  mobile: null,
  desktopScreenShot: false,
  siteGraderScore: 0,
  onPage: null,
  social: null,
  speed: null,
  technical: null,
  traffic: null,
  urlAuditInfoList: null,
  tabScreenshot: false,
  urlInfo: null,
  threadStarted: false,
  serverError: null,
  urlInfoLoading: false,
  robotsTxt: true,
  validSSLCertificate: true,
  brokenURLs: null,
  wwwResolved: false,
  structuredDataScore: 0,
  lxrSeoDomainURL: "",
  seoReportStatus: false,
  clientUpdateCheckError: "",
  clientId: null,
  userId: null,
};
