import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Box,
  Tabs,
  Tab,
  Typography,
  Grid,
  Button,
  Drawer,
} from "@mui/material";
import { styled } from "@mui/system";
import {
  JsAddUserLabelTypography,
  JsSubSectionTypography,
} from "../../components/typography.jsx";
import JsAddCampaign from "./jsAddCampaign.jsx";
import SaveOutlined from "@mui/icons-material/SaveOutlined";
import { JsOrangeContainedBtn, JsTextAsButton } from "../../components/buttons.jsx";
import JsAddAdgroups from "./jsAddAdgroups.jsx";
import JsCampaignData from "./jsCampaignData.jsx";
import { storeUpdatedCampaignData, fetchCampaignDetailsByJsAccountId, fetchAdGroupDetailsByJsAccountId } from "../../actions/jumpstartRevamped.js"
import { useParams } from "react-router-dom/cjs/react-router-dom.min.js";
import { fetchCampaignDetailsByJsAccountIdReducer, storeAdgroupDataReducer, fetchAdGroupDetailsByJsAccountIdReducer, deleteAdgroupDataReducer } from "../../reducers/jumpstartRevampedReducer.js";
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { AccountStatus, Authority } from "./commonComponents/jumpstartEnum.js";

//we are sending nothing as the props from <JumpstartMain> component
// const MemoizedChildComponent = React.memo(

function JsCampaignDetails(props) {
  const {
    campaignName,
    campaignType, dailyBudget,
    // setCampaignName,
    // setCampaignType,
    currencySymbol,
    currencyCode,
    handleCloseIcon,
    handleCampaignNameChange,
    handleCampaignTypeChange,
    handleDailyBudgetChange,
    handleDoneButton,
    handleAddNewCampaignData,
    updatedCampaignData,
    toggleDrawer, value1, handleChange, isOpen, editCampaign, newCampaign,
    storeUpdatedCampaignData,
    storeUpdatedCampaignDataReducer,
    fetchCampaignDetailsByJsAccountId,
    fetchCampaignDetailsByJsAccountIdReducer,
    jsAccountId,
    jwtToken,
    setCampaignName,
    handleEditCampaign,
    campaignNameinputRef,
    campaignTypeinputRef,
    campaignBudgetinputRef,
    // edCampaignName,
    // edCampaignType,
    // edDailyBudget,
    isEditMode,
    handleClickAway,
    storeAdgroupDataReducer,
    // adGroups,
    heading,
    fetchAdGroupDetailsByJsAccountId,
    fetchAdGroupDetailsByJsAccountIdReducer,
    deleteAdgroupDataReducer,
    authority,
    campaignId,
    fetchJsAccountByIdReducer,
  } = props


  const [accountStatus, setAccountStatus] = useState("");
  const [disableFramework, setDisableFramework] = useState(false);
  const [selCampaignId, setSelCampaignId] = useState("");
  const [selCampaignJsId, setSelCampaignJsId] = useState("");
  const [selCampaignName, setSelcampaignName] = useState("");


  useEffect(()=>{
    if(fetchJsAccountByIdReducer.message == "JS_ACCOUNT_BY_ID_RECEIVED"){
      setAccountStatus(fetchJsAccountByIdReducer.status);
      if(fetchJsAccountByIdReducer.status == AccountStatus.CAMPAIGN_FRAMEWORK_IN_REVIEW && (authority == Authority.ANALYST ||authority == Authority.RWF)){
        setDisableFramework(true);
      }
    }
  },[fetchJsAccountByIdReducer])

  useEffect(() => {
    
    if (storeUpdatedCampaignDataReducer.status === 200) {
       fetchCampaignDetailsByJsAccountId(jwtToken, jsAccountId);
    }
    else {
       fetchCampaignDetailsByJsAccountId(jwtToken, jsAccountId);
    }
  }, [storeUpdatedCampaignDataReducer])


  delete fetchCampaignDetailsByJsAccountIdReducer.status;
  delete fetchCampaignDetailsByJsAccountIdReducer.message;


  const { id } = useParams();

  const dummyAdgroupData = [
    {
      adgroupName: "Demo adgroup 1",
      adgroupTheme: "Demo adgroup theme",
      adgroupUrl: "Demo adgroup URL",
    },
    {
      adgroupName: "Demo adgroup 2",
      adgroupTheme: "Demo adgroup 2 theme",
      adgroupUrl: "Demo adgroup 2 URL",
    },
    {
      adgroupName: "Demo adgroup 3",
      adgroupTheme: "Demo adgroup 3 theme",
      adgroupUrl: "Demo adgroup 3 URL",
    },
  ];


  const handleSubmitButton = (e) => {
    history.push(`/campaign-framework-setup-completed`);
  };
  
  const handleCampaignRowClick = async (selcampaignId, selCampaignJsId, selcampaignName) => {
    setSelCampaignId(selcampaignId);
    setSelCampaignJsId(selCampaignJsId);
    setSelcampaignName(selcampaignName);
    await fetchAdGroupDetailsByJsAccountId(jwtToken, selCampaignJsId)
  }
  return (
    <>
      <Grid container direction="row" style={{ height: "60vh", overflowY: "auto" }}>
        <Grid item xs={6} style={{ borderRight: "1px solid #E6E6E6" }}>
          <div
            style={{
              display: "flex",
              padding: "5% 3% 0 3%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <JsAddUserLabelTypography>
              <span className="numberContainer">1</span>Campaign Name & Budget
            </JsAddUserLabelTypography>
            <JsTextAsButton onClick={toggleDrawer(true)} disabled={disableFramework}>
              <Typography sx={{ color: "#F58120", fontWeight: "500" }}>
                + Add Campaign
              </Typography>
            </JsTextAsButton>
          </div>

          <StyledTabs
            orientation="vertical"
            value={value1}
            onChange={handleChange}
            aria-label="Vertical tabs example"
          >

            {fetchCampaignDetailsByJsAccountIdReducer/*  */ ?
              Object.keys(fetchCampaignDetailsByJsAccountIdReducer).map((key, index) => (
                <AntTab
                  onClick={(e) => {
                    handleCampaignRowClick(fetchCampaignDetailsByJsAccountIdReducer[key].id, fetchCampaignDetailsByJsAccountIdReducer[key].jsAccountId, fetchCampaignDetailsByJsAccountIdReducer[key].name,);
                  }}
                  key={index}
                  label={
                    <JsCampaignData
                      campaignId={fetchCampaignDetailsByJsAccountIdReducer[key].id}
                      campaignName={fetchCampaignDetailsByJsAccountIdReducer[key].name}
                      campaignType={fetchCampaignDetailsByJsAccountIdReducer[key].type}
                      currencySymbol={currencySymbol}
                      currencyCode={currencyCode}
                      budget={fetchCampaignDetailsByJsAccountIdReducer[key].budget}
                      value={value1}
                      index={index}
                      toggleDrawer={toggleDrawer}
                      handleEditCampaign={handleEditCampaign}
                      campaignNameinputRef={campaignNameinputRef}
                      campaignTypeinputRef={campaignTypeinputRef}
                      campaignBudgetinputRef={campaignBudgetinputRef}
                      authority={authority}
                      disableFramework={disableFramework}
                    />
                  }
                  {...a11yProps(index)}
                />
              )) :

              updatedCampaignData.map((tab, index) => (
                <AntTab
                  key={index}
                  label={
                    <JsCampaignData
                      campaignName={tab.name}
                      campaignType={tab.type}
                      currencySymbol={currencySymbol}
                      currencyCode={currencyCode}                      
                      budget={tab.budget}
                      value={value1}
                      index={index}
                      toggleDrawer={toggleDrawer}
                      handleEditCampaign={handleEditCampaign}
                      campaignNameinputRef={campaignNameinputRef}
                      campaignTypeinputRef={campaignTypeinputRef}
                      campaignBudgetinputRef={campaignBudgetinputRef}
                      authority={authority}
                      disableFramework={disableFramework}
                    />
                  }
                  {...a11yProps(index)}
                />
              ))}
          </StyledTabs>
        </Grid>
        <Grid item xs={6}>
          {selCampaignJsId ? Object.keys(fetchCampaignDetailsByJsAccountIdReducer).map((tab, index) => (
            <TabPanel
              key={index} value={value1} index={index}>
              <JsAddAdgroups dummyAdgroupData={dummyAdgroupData}
                authority={authority}
                disableFramework={disableFramework}
                selCampaignId={selCampaignId} selCampaignJsId={selCampaignJsId} selCampaignName={selCampaignName} />
            </TabPanel>
          )) : ""}
        </Grid>
      </Grid>

      <Drawer
        anchor="right"
        open={isOpen}
        onClose={toggleDrawer(false)}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}
      >
          <Box sx={{ width: 550 }}>
            {isEditMode ? <JsAddCampaign
              campaignName={campaignName}
              handleCloseIcon={handleCloseIcon}
              handleCampaignNameChange={handleCampaignNameChange}
              handleCampaignTypeChange={handleCampaignTypeChange}
              handleDailyBudgetChange={handleDailyBudgetChange}
              handleDoneButton={handleDoneButton}
              handleAddNewCampaignData={handleAddNewCampaignData}
              currencySymbol={currencySymbol}
              currencyCode={currencyCode}
              isEditMode={isEditMode}
              campaignType={campaignType}
              dailyBudget={dailyBudget}
              heading={"Edit Campaign"}
              campaignId={campaignId}

            /> : <JsAddCampaign
              campaignName={""}
              handleCloseIcon={handleCloseIcon}
              handleCampaignNameChange={handleCampaignNameChange}
              handleCampaignTypeChange={handleCampaignTypeChange}
              handleDailyBudgetChange={handleDailyBudgetChange}
              handleDoneButton={handleDoneButton}
              handleAddNewCampaignData={handleAddNewCampaignData}
              currencySymbol={currencySymbol}
              currencyCode={currencyCode}
              isEditMode={isEditMode}
              campaignType={campaignType}
              dailyBudget={""}
              heading={"+ Add Campaign"}
            />}

          </Box>
      </Drawer>


    </>
  );
}

// JsCampaignDetails.propTypes = {
//   tabs: PropTypes.arrayOf(
//     PropTypes.shape({
//       label: PropTypes.node.isRequired,
//       content: PropTypes.node.isRequired,
//     })
//   ).isRequired,
// };

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme, tabcolor }) => ({
    textTransform: "none",
    alignItems: "flex-start",
    display: "contents",
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    color: tabcolor || "#838383",
    textAlign: "left",
    fontFamily: "lato",
  })
);

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{
      children: <span className="MuiTabs-indicatorSpan" />,
    }}
  />
))({
  borderBottom: "1px solid #DAE4ED",
});

const mapStateToProps = (state) => ({
  storeUpdatedCampaignDataReducer: state.storeUpdatedCampaignDataReducer,
  fetchCampaignDetailsByJsAccountIdReducer: state.fetchCampaignDetailsByJsAccountIdReducer,
  storeAdgroupDataReducer: state.storeAdgroupData,
  deleteAdgroupDataReducer: state.deleteAdgroupDataReducer,
  fetchJsAccountByIdReducer: state.fetchJsAccountByIdReducer,
  fetchAdGroupDetailsByJsAccountIdReducer: state.fetchAdGroupDetailsByJsAccountIdReducer,
});
const mapDispatchToProps = (dispatch) => ({
  storeUpdatedCampaignData: (jwtToken, updatedCampaignData) => dispatch(storeUpdatedCampaignData(jwtToken, updatedCampaignData)),
  fetchCampaignDetailsByJsAccountId: (jwtToken, jsAccountId) => dispatch(fetchCampaignDetailsByJsAccountId(jwtToken, jsAccountId)),
  fetchAdGroupDetailsByJsAccountId: (jwtToken, jsAccountId) => dispatch(fetchAdGroupDetailsByJsAccountId(jwtToken, jsAccountId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(JsCampaignDetails);

