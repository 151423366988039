import React from "react";
import Growthmodel from "./growthmodel.jsx";
import Header from "../homepage/headerNewInterface.jsx";
import Modellxrgrowth from "../homepage/lxrguidegrowthmodel.jsx";
import Footer from "../homepage/footerNewInterface.jsx";
import AttractLeads from "./attractleads.jsx";
import EngageProspect from "./engageprospects.jsx";
import DelignAndRetain from "./deightandretain.jsx";
import ThreeAED from "./threeAED.jsx";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import useMediaQuery from "@mui/material/useMediaQuery";
import MetaTags from "react-meta-tags";
import { Helmet } from "react-helmet";

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="xl" />;

function lxrgrowthmodelpage(props) {
  const isTabletResponsive = useMediaQuery(
    props.theme.breakpoints.between("sm", "xl")
  );
  const classes = props.classes;
  const width = props.width;
  console.log("widthh==", width);

  return (
    <Card>
      <Helmet>
        <title>
          Growth Model | Google Small Business Advertising | LXRGuide
        </title>
        <meta
          name="description"
          content="Our growth model features online marketing strategies for ecommerce websites including attracting leads, engaging prospects, and retaining customers."
        />
      </Helmet>
      <CardContent
        style={{
          padding: 0,
          paddingBottom: 0,
          paddingTop:
            width == "sm" || width == "xs" || isTabletResponsive ? 5 : 15,
        }}
      >
        <Growthmodel />
        <Modellxrgrowth growthModel />
        <Grid
          container
          justifyContent={"center"}
          alignItems={"center"}
          className={classes.testimonialIframeSection}
        >
          <Grid item sm={12} xs={12} md={5}>
            <iframe
              className={classes.youtubeSection}
              src="https://www.youtube.com/embed/DNqTdH_60Fg"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            <Typography variant={"body1"} align={"left"} gutterBottom>
              To find out how much paid search growth LXRGuide can drive for
              your campaigns, contact us at&nbsp;
              <a
                href={
                  "https://mail.google.com/mail/?view=cm&amp;fs=1&amp;to=support@lxrguide.com&amp;su=Reg: LXRGuide Product Help"
                }
                style={{ color: props.theme.palette.hyperLink }}
                target={"_blank"}
              >
                growth@lxrguide.com
              </a>
              .
            </Typography>
          </Grid>
        </Grid>
        {/*<ThreeAED/>*/}
        {/*<AttractLeads/>*/}
        {/*<EngageProspect/>*/}
        {/*<DelignAndRetain/>*/}
        {/* <Footer /> */}
      </CardContent>
    </Card>
  );
}

export default withWidth()(
  withStyles(
    (theme) => ({
      testimonialIframeSection: {
        backgroundColor: theme.palette.common.darkGreybg,
        [theme.breakpoints.down("lg")]: {
          paddingTop: theme.spacing(6),
          paddingBottom: theme.spacing(6),
          paddingLeft: theme.spacing(4),
          paddingRight: theme.spacing(4),
        },
        [theme.breakpoints.up("sm")]: {
          paddingTop: theme.spacing(6),
          paddingBottom: theme.spacing(6),
        },
      },
      youtubeSection: {
        marginBottom: "10px",
        borderRadius: "5px",
        width: "560px",
        height: "315px",
        [theme.breakpoints.down("md")]: {
          width: "100%",
          height: "215px",
        },
      },
    }),
    { withTheme: true }
  )(lxrgrowthmodelpage)
);
