import { Slide, Typography, Button, Tooltip, TableRow, TableCell, Grid, Fab } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import React, {Fragment} from "react";
import {Link} from "react-router-dom";
import icons from "../icons";
import * as types from "../../actions/actionTypes"
import * as _ from 'lodash';
import {JUMP_START} from '../../actions/actionTypes';
import NumberFormat from 'react-number-format';


let moment = require('moment');


export function Transition(props) {
    return <Slide direction="up" {...props} />;
}

export function PlainLink({classes, ...rest}) {
    return (
        <Link {...rest} className={classes.a}/>
    );
}


PlainLink = withStyles({
    a: {width: "100%", color: "unset", textDecoration: "none", cursor: "pointer"}
})(PlainLink);
export const PopUpCommonBtn = withStyles(theme => ({
    root: {
        fontWeight: "400",
        color: theme.palette.text.primary,
    }

}))(Button);

const CustomHeadTableCell = withStyles(theme => ({
    root: {
        padding: "4px 10px 4px 0px",
    }
}))(TableCell);
const CustomTableCell = withStyles(theme => ({
    root: {
        padding: "4px 56px 4px 0px",
    }
}))(TableCell);

const CustomTableCell2 = withStyles(theme => ({
    root: {
        padding: "4px 56px 4px 0px",
        maxWidth: 200,
    }
}))(TableCell);


export function backButton(to) {
    return <Typography align={"right"}>
        <Link style={{textDecoration: "unset"}} to={to}>
            <Button style={{margin: 4}}
                    variant={"outlined"}>{icons.ChevronArrowLeft}Back</Button>
        </Link>
    </Typography>;
}


export const dateRangeInitialState = {
    fromDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
    toDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
    itemValue: 6,
}

export function getManipulatedUserInfoList(users, view) {
    console.log("users=", users);
    users = _.filter(users, (user) => user.deleted != true);
    console.log("after filter users=", users);
    return users.map(user => ({
        ...user,
        username:
            <Fragment>
                {(view && view == types.ADMIN_USER_TABLE) ?
                    <div>
                        <div style={{padding: 5}}>
                            <PlainLink
                                to={`/user-details/${user.userId}`}
                                style={{textDecoration: "none"}}
                            >
                                {user.userType == true ? icons.users : icons.user}&nbsp;
                                <Tooltip title={`${user.emailId}`}>
                                    <span style={{padding: 5}}>{icons.envelopeResizable(12)}</span>
                                </Tooltip>
                                &nbsp;
                                <Tooltip title={<NumberFormat value={`${user.phoneNumber}`}
                                                              displayType={'text'}
                                                              format="###-###-####"/>}>
                                    <span style={{padding: 5}}>{icons.phoneResizable(12)}</span>
                                </Tooltip>
                                &nbsp;
                                {user.username}
                            </PlainLink>
                        </div>
                    </div>
                    :
                    <IconText icon={user.userType == true ? icons.users : icons.user}>{user.username}</IconText>}
            </Fragment>,
        type: (view && view == types.ADMIN_USER_TABLE) ? getChannel(user.type) : user.type,
        nextPaymentDate: (user.endDate == null || (user.status == "Inactive" && view && view == types.ADMIN_USER_TABLE)) ? "-" : moment(new Date(user.endDate)).add(1, "days").format("MM/DD/YYYY"),
        endDate: (user.endDate == null || (user.status == "Inactive" && view && view == types.ADMIN_USER_TABLE)) ? "-" : moment(new Date(user.endDate)).format("MM/DD/YYYY"),
        contractEnd: user.contractEnd == null ? "-" : moment(new Date(user.contractEnd)).format("MM/DD/YYYY"),
        registrationDate: moment(new Date(user.registrationDate)).format("MM/DD/YYYY"),
    }));
}

export function getProvUserInfoList(users, view, handleCreateUserBtn) {
    return users.map(user => ({
        ...user,
        pvslUsername:
            <Fragment>
                {(view && view == types.ADMIN_USER_TABLE) ?
                    <div>
                        <div style={{padding: 5}}>
                            {icons.user}&nbsp;
                            <Tooltip title={`${user.pvslEmailAddress}`}>
                                <span style={{padding: 5}}>{icons.envelopeResizable(12)}</span>
                            </Tooltip>
                            &nbsp;
                            <Tooltip title={<NumberFormat value={`${user.pvslPhoneNumber}`}
                                                          displayType={'text'}
                                                          format="###-###-####"/>}>
                                <span style={{padding: 5}}>{icons.phoneResizable(12)}</span>
                            </Tooltip>
                            &nbsp;
                            {user.pvslUserName}

                        </div>
                    </div>
                    :
                    <IconText icon={icons.user} >{user.pvslUserName}</IconText>}
            </Fragment>,
        registrationDate: moment(new Date(user.registrationDate)).format("MM/DD/YYYY"),
        contractSigned:user.contractSigned?"Yes":"No",
        action:

            <div
                label="Add as User"  
                // onClick={() => handleCreateUserBtn(user.pvslUserId)
                onClick={ () => handleCreateUserBtn(user.pvslUserId) }>
                <p 
                style={{color:"blue",textDecorationLine: 'underline',cursor: "pointer"}}>Add as User</p>
            </div>,
    }));
}

export function suggestionTagLine(plainId, respone) {
    let amount = (respone && respone.amount) ? respone.amount : "-";
    let amountStandard = (respone && respone.amountStandard) ? respone.amountStandard : "-";
    switch (plainId) {
        case "8":
            return 'User will be charged $' + amount + ' per month' + ' or 2% of actual spend whichever is higher';
        case "11":
            return 'User will be charged $' + amount + ' per month' + ' or 1.5% of actual spend whichever is higher';
        case "12":
            return 'User will be charged $' + amount + ' per month' + ' or 1% of actual aggregated spend whichever is higher';
        case "13":
            return 'User will be charged $' + amount + ' per month' + ' or 0.5% of actual aggregated spend whichever is higher';
        default:
            return 'User will be charged $' + amount + ' First Month ' + ' + $' + amountStandard + ' or 2% of actual spend whichever is higher from Second month onwards ';
    }
}


export function setClipBoardData(account) {
    let kpiType = "Orders";
    if(account.targetKpiName == "CPL"){
        kpiType = "Leads";
    }
    kpiType = kpiType + "\t";
    let clipboardData = "Account Id" + "\tAccount Name" + "\t Impressions\t" + "Clicks\t" + "PPC Spend\t" + kpiType + "Revenue\t" + "CTR\t"
        + "CVR\t" + "KPI\t" + "Target KPI\t" + "Budget\t" + "Task Provided\t" + "Task Completed\n" + account.accId + "\t" + account.accName
        + "\t" + account.impressions + "\t" + account.clicks + "\t" + account.currSymbol + account.cost.toLocaleString(undefined, {maximumFractionDigits: 2}) + "\t" + account.conversions.toLocaleString(undefined, {maximumFractionDigits: 2}) + "\t"
        + account.currSymbol + account.conversionValue.toLocaleString(undefined, {maximumFractionDigits: 0}) + "\t" + account.ctr + "%" + "\t" + account.cvr + "%" + "\t" + account.kpiVal + "\t"
        + account.targetKpiName + " = " + account.targetValue + "\t" + account.currSymbol + account.budget.toLocaleString(undefined, {maximumFractionDigits: 0}) + "\t" + account.taskProvided + "\t"
        + account.taskCompleted;
    return clipboardData;
}

export function getUserInfo(userInfoList, userId) {
    userInfoList = userInfoList.filter(user => user.userId == userId);
    userInfoList = getManipulatedUserInfoList(userInfoList);
    /*  userInfoList = userInfoList.map(user => ({
          ...user, pricingPlan: user.pricingPlan ? user.pricingPlan.packageName : "-"
      }));*/
    return userInfoList[0];
}

export function getChannel(userType) {
    switch (userType) {
        case types.LOCAL_CLIENT:
            return "Local Client";
        case types.NON_UPS_CLIENT:
            return "Non UPS Client";
        case types.UPS_CLIENT:
            return "UPS Client";
        case types.UPS_CLIENT_WITH_CTP:
            return "UPS Client With CTP";
        case types.NON_UPS_DIRECT_CLIENT:
            return "Non UPS Direct Client";
        case types.EX_CTP:
            return "Ex CTP";
        case types.GRADER_USERS:
            return "Grader User";
        default:
            return "-";
    }
}

/*export function getSearchEngineIcon(searchEngineId) {
    switch (searchEngineId) {
        case types.BING:
            return (<span>{icons.bingSmall}&nbsp;Bing&nbsp;</span>);
        default:
            return (<span>{icons.googleSmall}&nbsp;Google&nbsp;</span>);
    }
}*/

// todo We will delete this method after merging
export function getAccountTypeIcon(searchEngineId) {
    switch (searchEngineId) {
        case types.BING:
            return icons.bingSmall;
        default:
            return icons.googleSmall;
    }
}

export  function getTitleBasedOnKpi(targetKpiName){
    console.log("The present kpi name is",targetKpiName);
    switch (targetKpiName) {
        case "CPL":
            return "Leads";
        default:
            return "Orders";
    }
}


export const getSourceOfFunds = (userType) => {
    let state = "NON CTP";
    if (userType == types.UPS_CLIENT_WITH_CTP) {
        state = "CTP"
    }
    return state;
};

export const setTerminationDate = (permission, endDate, userStatus) => {
    let terminationDate = "-";
    if ((userStatus == "Inactive" || userStatus == "Inactive (payment)") && permission == false) {
        terminationDate = endDate;
    }
    return terminationDate
};
export const jumpStartField = (userPricingPlan, obf, classes, handleEndBtn, status) => {
    let field = "-";
    if (userPricingPlan && userPricingPlan.pricingPlanId == JUMP_START && status !== types.NOT_YET_REGISTERED) {
        field = <Fragment>
            <CustomHeadTableCell component="th" scope="row">
                <b>End JumpStart:</b>
            </CustomHeadTableCell>
            <CustomTableCell align={'left'}>
                <Button variant={"contained"} onClick={handleEndBtn}
                        className={classes.button}>
                    End JumpStart
                </Button>
            </CustomTableCell>
        </Fragment>;
    }
    console.log("field=", field);
    return field;
};
export const setAccountImportIds = (clientList, accountImportSucess) => {
    let field = [];
    if (accountImportSucess == "Yes") {
        field = _.flatten(_.map(clientList, (u) => _.map(u.accounts, (a) => _.pick(a, "seAccountId", "searchEngineId", "ppcId"))))
    }
    console.log("final field=", field);
    return field
};
export const nullCheck = (value) => {
    let fieldValue = "-";
    if (value != null) fieldValue = value;
    return fieldValue
};

export function getSearchEngageFormat(searchEngineId, seAccountId, ppcId, userId) {
    switch (searchEngineId) {
        case types.BING:
            return (<NumberFormat value={seAccountId}
                                  displayType={'text'}
                                  format="###-###-####"/>);
        default:
            return (
                <Fragment>
                    <NumberFormat value={seAccountId}
                                  displayType={'text'}
                                  format="###-###-####"/>
                    &nbsp;&nbsp;
                    <PlainLink
                        to={`/task-analysis/${seAccountId}/${ppcId}`}
                        style={{textDecoration: "none"}}
                    >
                        <Button variant={"outlined"}>{icons.tasks}&nbsp;&nbsp;Tasks</Button>
                    </PlainLink>
                </Fragment>
            );
    }
}

export const statusFilterList = ["Unsubscribed", "Inactive (payment)", "Not yet Registered", "Registered", "On Boarding in Progress", "Active", "Inactive", "JumpStart in Progress"];
export const channelFilterList = {
    "Local Client": types.LOCAL_CLIENT,
    "Non UPS Client": types.NON_UPS_CLIENT,
    "UPS Client": types.UPS_CLIENT,
    "UPS Client With CTP": types.UPS_CLIENT_WITH_CTP,
    "Non UPS Direct Client": types.NON_UPS_DIRECT_CLIENT,
    "Ex CTP": types.EX_CTP,
    "Grader User": types.GRADER_USERS,
    "Provisional User": types.PROVISIONAL_USER,
};
export const IN_ACTIVE_PAYMENT = "Inactive (payment)";
export const IN_ACTIVE = "Inactive";
export const ACTIVE = "Active";
export const REGISTERED = "Registered";
export const UNSUBSCRIBED = "Unsubscribed";

export function calculateOrderBy(currentSorting) {
    const columnSorting = currentSorting[0];
    const sortDirectionString = columnSorting.direction === 'desc' ? 'desc' : '';
    let columnName = "endDate";
    if (columnSorting.columnName != "nextPaymentDate") columnName = columnSorting.columnName;
    return {sortDirectionString, columnName};
}

export const initialState = {
    type: '2',
    userType: 'false',
    response: {},
    pricingPlan: '8',
    userName: "",
    upsRegion:"",
    emailId: "",
    website: "",
    phoneNumber: "",
    supportMailId: "",
    upsSalesProfessionalName: "",
    pvslUserId: 0,
    pvslUserName: "",
    pvslUpsRegion: "",
    pvslEmailAddress: "",
    pvslCompany: "",
    pvslPhoneNumber: "",
    neEmailId: "",
    contractSigned:"",
    action: "",
    ctpCode: "",
    ctpAmount: "",
    ctpAddress: "",
    companyName: "",
    note: "",
    userNameError: "",
    upsRegionError: "",
    emailIdError: "",
    websiteError: "",
    phoneNumberError: "",
    supportMailIdError: "",
    upsSalesProfessionalNameError: "",
    ctpCodeError: "",
    ctpAmountError: "",
    ctpAddressError: "",
    companyNameError: "",
    noteError: "",
    serverError: "",
    isEmail: "",
    tagLine: "",
}
/*export function PlainLink({classes, ...rest}) {
    return (
        <Link {...rest} className={classes.a}/>
    );
}*/
PlainLink = withStyles({
    a: {width: "100%", color: "unset", textDecoration: "none", cursor: "pointer"}
})(PlainLink)

export function IconText({icon, children}) {
    return (
        <Grid container alignItems={"center"}>
            <Grid item md={1}>
                {icon}
            </Grid>
            <Grid item md={11}>
                <div className={"flex"}>&nbsp;&nbsp;{children}</div>
            </Grid>
        </Grid>
    )
}

function downloadReportOrFeedsFile(title, value) {
    const {classes} = this.props;
    return (
        title ? <TableRow>
            <CustomHeadTableCell component="th" scope="row">
                <b>{title} :</b>
            </CustomHeadTableCell>
            <CustomTableCell2 align={'left'}>
                {value ?
                    <a target={"_blank"} href={"/downloadFile.html?client=" + this.userId + "&file=" + nullCheck(value)}
                       className={classes.hyperlinks}><Fab className={classes.fabButton} variant="extended"
                                                           size="small">Download&nbsp;&nbsp;{icons.download}</Fab></a> : "-"}
            </CustomTableCell2>
        </TableRow> : null
    );
}

export function productFeedOrReportsCols() {
    const productFeedDetailsOrReport = (obf, userId) => {
        switch (obf) {
            case 0:
            case 4:
                return (
                    <Fragment>
                        {downloadReportOrFeedsFile.call(this)}
                        {downloadReportOrFeedsFile.call(this)}
                    </Fragment>
                );
            case 1:
                return (
                    <Fragment>
                        {downloadReportOrFeedsFile.call(this, "Product Sales Report", "salesRep")}
                        {downloadReportOrFeedsFile.call(this, "Product Feed",)}
                    </Fragment>
                );
            case 2:
                return (
                    <Fragment>
                        {downloadReportOrFeedsFile.call(this, "Product Sales 'Report",)}
                        {downloadReportOrFeedsFile.call(this, "Product Feed", "feedRep")}
                    </Fragment>
                );
            case 3:
                return (
                    <Fragment>
                        {downloadReportOrFeedsFile.call(this, "Product Sales 'Report", "salesRep")}
                        {downloadReportOrFeedsFile.call(this, "Product Feed", "feedRep")}
                    </Fragment>
                );
            default:
                return null;
        }

    };
    return productFeedDetailsOrReport;
}
