import React from "react";
import { Tab, Tabs } from "@mui/material";
import PropTypes from "prop-types";
import icons from "../../components/icons";
import { Link } from "react-router-dom";
import withStyles from "@mui/styles/withStyles";
import * as _ from "lodash";

export function PlainLink({ classes, ...rest }) {
  return <Link {...rest} className={classes.a} />;
}

PlainLink = withStyles({
  a: {
    width: "100%",
    color: "unset",
    textDecoration: "none",
    cursor: "pointer",
  },
})(PlainLink);

class NotificationsHeaderTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedNotificationTab: 0,
    };
  }

  componentDidMount() {
    this.setTabOnchange();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.selectedClientInfo != this.props.selectedClientInfo) {
      this.setTabOnchange();
    }
  }

  setTabOnchange() {
    this.setState({
      selectedNotificationTab:
        this.props.pathName != "/" && this.props.selectedClientInfo != null
          ? _.findIndex(
              this.props.selectedClientInfo.accounts,
              (account) => {
                return (
                  account.ppcId ==
                  Number(this.props.pathName.replace("/notifications/", ""))
                );
              },
              0
            ) + 1
          : 0,
    });
  }

  handleSelectedNotificationTabChange = (event, value) => {
    this.setState({
      selectedNotificationTab: value,
    });
  };

  render() {
    const { classes, pathName } = this.props;
    const { selectedNotificationTab } = this.state;
    console.log("selectedNotificationTab", selectedNotificationTab);
    return (
      <Tabs
        value={selectedNotificationTab}
        onChange={this.handleSelectedNotificationTabChange}
        TabIndicatorProps={{ style: { background: "#F58120" } }}
        textColor="primary"
        variant="scrollable"
        scrollButtons={true}
        style={{ marginLeft: "24px" }}
        className={classes.tabsIndicator}
        classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
      >
        <Tab
          label={<div>&nbsp;General</div>}
          component={Link}
          to={`/notifications`}
          className={this.props.classes.tab}
          classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
        />

        {this.props.selectedClientInfo != null &&
          this.props.selectedClientInfo.accounts.map((account, i) => (
            <Tab
              key={i}
              label={<div> &nbsp;{account.clientName}</div>}
              component={Link}
              className={this.props.classes.tab}
              to={`/notifications/${account.ppcId}`}
              classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
            />
          ))}
      </Tabs>
    );
  }
}

export default NotificationsHeaderTabs;
