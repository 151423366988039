import React, { useEffect, useState } from "react";
import { alpha, styled } from "@mui/material/styles";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem, treeItemClasses } from "@mui/x-tree-view/TreeItem";
import { Button, Grid, Typography } from "@mui/material";
import { JsAddUserLabelTypography } from "../../../components/typography";
import icons from "../../../components/icons";
import { JsTextAsButton } from "../../../components/buttons";
import JsSearchBar from "../jsSearchBar";
import CircleIcon from "@mui/icons-material/Circle";

const StyledTreeItem = styled(TreeItem)(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    "& .close": {
      opacity: 0.3,
    },
    "&.Mui-selected": {
      background: "none",
    },
    "&.MuiTreeItem-iconContainer": {
      margin: 0,
      width: 0,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 19,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
  "&.MuiTreeItem-root": {
    marginTop: "6%",
  },
  [`& .${treeItemClasses.content}`]: {
    "&.Mui-selected": {
      background: "rgb(255, 244, 235)",
      color: "#F58120",
      padding: "3% 0",
      paddingLeft: "7px",
    },
  },
}));

const StyledSubTreeItem = styled(TreeItem)(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    "& .close": {
      opacity: 0.3,
    },
    "&.Mui-selected": {
      background: "none",
    },
    "&.MuiTreeItem-iconContainer": {
      margin: 0,
      width: 0,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 36,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
  "&.MuiTreeItem-root": {
    marginTop: "6%",
  },
  [`& .${treeItemClasses.content}`]: {
    "&.Mui-selected": {
      background: "rgb(255, 244, 235)",
      color: "#F58120",
      padding: "3% 0",
      paddingLeft: "8px",
      paddingRight: "8px",
    },
  },
}));

const createLabel = (labelText, image, campaignStatus, type) => (
  // <Grid
  //   container
  //   direction="row"
  //   alignItems="center"
  //   style={{ position: "relative", left: "-11px" }}
  // >
  //   <Grid item style={{ display: "flex", alignItems: "center" }}>
  //     <Typography variant="subtitle2" color="#E6E6E6">
  //       ---
  //     </Typography>
  //     {image}
  //   </Grid>
  //   <Grid item style={{ display:"flex" , alignItems:"center", position:"relative"}}>
  //     <>
  //     <Typography
  //       variant="subtitle2"
  //       style={{ color: "inherit", paddingLeft: "2px" }}
  //     >
  //      {labelText}
  //      </Typography>
  //      {campaignStatus != undefined ? (
  //         campaignStatus == true ? (
  //           <CircleIcon
  //             sx={{ color: "#00D83A", fontSize: "0.875rem", float: "right" }}
  //           />
  //         ) : (
  //           <CircleIcon
  //             sx={{ color: "#F8C79E", fontSize: "0.875rem", float: "right" }}
  //           />
  //         )
  //       ) : (
  //         ""
  //       )}
  //     </>
  //   </Grid>
  // </Grid>

  <div className="treeViewCss">
    {icons.HorizontalDashedLine}
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item xs={11} container direction="row">
        <Grid item>
          <Typography
            variant="subtitle2"
            color="inherit"
            paddingLeft="2px"
          >
            <span
              className={
                type == "A" ? "adgroupIcon" : type == "C" ? "campaignIcon" : ""
              }
            >
              {type}
            </span>
            &nbsp; {labelText}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={1}>
        {campaignStatus != undefined ? (
          campaignStatus == true ? (
            <CircleIcon
              sx={{ color: "#00D83A", fontSize: "0.875rem", float: "right" }}
            />
          ) : (
            <CircleIcon
              sx={{ color: "#F8C79E", fontSize: "0.875rem", float: "right" }}
            />
          )
        ) : (
          ""
        )}
      </Grid>
    </Grid>
  </div>
);

//Pass campaign structure details as props
export default function CampaignStructureSideBar(props) {
  const {
    handleContentChange,
    campaignData,
    handleCampaignIdChange,
    handleAdgroupIdChange,
    selected,
    businessName,
  } = props;
  const campaignIdsAsStringArray = campaignData.map((campaign) =>
    String(campaign.campaignId)
  );

  const [selectedNodeId, setSelectedNodeId] = useState();
  useEffect(()=>{
    if(selected != undefined){
      setSelectedNodeId(selected);
    }else{
      setSelectedNodeId(businessName != undefined ? businessName : businessName)
    }
  },[selected, businessName]);
  
  return (
    <Grid container direction="column" style={{ color: "#A4A4A4" }}>
      <Grid item xs={12}>
        {/* Add Search Bar here */}
      </Grid>
      <Grid item xs={12}>
        <TreeView
          aria-label="campaign-structure"
          expanded={[businessName, ...campaignIdsAsStringArray]}
          selected={String(selectedNodeId)}
        >
          <StyledTreeItem
            nodeId={String(businessName)}
            onClick={() =>
              handleContentChange("Callouts", 0, campaignIdsAsStringArray[0])
            }
            label={
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={3} md={2} lg={1}>
                  <Typography variant="subtitle2" className="businessIcon">
                    B
                  </Typography>
                </Grid>
                <Grid item xs={9} md={10} lg={11}>
                  <Typography
                    variant="subtitle2"
                    color="inherit"
                    fontWeight="500"
                  >
                    &nbsp; {businessName}
                  </Typography>
                </Grid>
              </Grid>
            }
          >
            {campaignData.map((data, index) => {
              return (
                <StyledSubTreeItem
                  key={data.campaignId}
                  nodeId={String(data.campaignId)}
                  label={createLabel(
                    data.campaignName,
                    icons.JsCampaignIcon,
                    data.campaignStatus,
                    "C"
                  )}
                  onClick={() =>
                    handleContentChange("Sitelinks", data.campaignId)
                  }
                >
                  {data.adgroups.map((group) => (
                    <StyledSubTreeItem
                      key={group.adgroupId}
                      nodeId={String(group.adgroupId)}
                      label={createLabel(
                        group.adgroupName,
                        icons.JsAdgroupIcon,
                        undefined,
                        "A"
                      )}
                      onClick={() =>
                        handleContentChange("Keywords", group.adgroupId)
                      }
                    />
                  ))}
                </StyledSubTreeItem>
              );
            })}
          </StyledTreeItem>
        </TreeView>
      </Grid>
    </Grid>
  );
}
