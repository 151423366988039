import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { getUserServices } from "../../actions";
import { connect } from "react-redux";
import { Typography, Grid, Card, CardContent, CircularProgress } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import { ErrorTypography } from "../typography";
import classNames from "classnames";
import {
  ACCEPTED,
  Delivered,
  PROGRESS,
  PURCHASED,
} from "../../actions/actionTypes";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import icons from "../icons";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SeoDashboardStepper from "./seo-dashboard-stepper";

let moment = require("moment");

const styles = (theme) => ({
  card: {
    minWidth: 275,
    margin: theme.spacing(2),
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  mainSec: {
    boxShadow: "unset",
  },
  cardTitle: {
    fontFamily: "Montserrat",
    color: theme.palette.textsecondary,
    fontSize: "1.4rem",
    fontWeight: "bold",
    marginLeft: "3%",
  },
  packageTitle: {
    fontFamily: "Montserrat",
    color: "#000000",
    fontSize: 17,
    fontWeight: "bold",
    // marginLeft: "3%",
  },
  homeTitle: {
    fontFamily: "Montserrat",
    color: theme.palette.dasboardHomeText,
  },
  serviceTitile: {
    marginLeft: "3%",
    color: "#757575",
    fontSize: "1.2rem",
  },
  servicePackTitle: {
    marginLeft: "1%",
    color: "#333333",
    fontSize: "1rem",
  },
  statusTitle: {
    marginLeft: "1%",
    color: "#219653",
    fontSize: "0.8rem",
    paddingTop: "1%",
  },
  tabBar: {
    fontFamily: "Montserrat",
    flexGrow: 1,
    backgroundColor: theme.palette.errorBg,
    color: "#000000",
    fontWeight: "bold",
  },
  tabListItem: {
    fontFamily: "Montserrat",
    flexGrow: 1,
    backgroundColor: theme.palette.errorBg,
    color: "#000000",
    fontWeight: "bold",
    fontSize: "1rem",
  },
  headTitle: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: 18,
    color: "#333333",
  },
  titleAlignLeft: {
    [theme.breakpoints.down('xl')]: {
      textAlign: "center",
    },
    [theme.breakpoints.up("sm")]: {
      textAlign: "left",
    },
  },
  captionColor: {
    color: "#2F80ED",
  },
});

const purchased = (status) => {
  let styleClass = "active";
  if (status == Delivered || status == PROGRESS || status == ACCEPTED) {
    styleClass = "complete";
  }
  return styleClass;
};
const progress = (status) => {
  let styleClass = "disabled";
  if (status == ACCEPTED || status == Delivered) {
    styleClass = "complete";
  } else if (status == PROGRESS) {
    styleClass = "active";
  }
  return styleClass;
};
const delivered = (status) => {
  let styleClass = "disabled";
  if (status == PURCHASED) {
    styleClass = "disabled";
  } else if (status == Delivered) {
    styleClass = "active";
  } else if (status == ACCEPTED) {
    styleClass = "complete";
  }
  return styleClass;
};
const accepted = (status) => {
  let styleClass = "disabled";
  if (status == Delivered) {
    styleClass = "disabled";
  } else if (status == ACCEPTED) {
    styleClass = "active";
  }
  return styleClass;
};

const packContent = (packageId) => {
  let packageContent = "";
  if (packageId == 1) {
    packageContent = ["Site Health Overview Report", "1 Hour Consulting"];
  } else if (packageId == 2) {
    packageContent = [
      "Site Health Overview Report",
      "Indexability and Site Crawability Audit",
      "Mobile First Audit",
      "Meta Tags Audit",
      "1 Hour Consultation",
    ];
  } else {
    packageContent = [
      "Site Health Overview Report",
      "Indexability and Site Crawability Audit",
      "Mobile First Audit",
      "Meta Tags Audit",
      "Keywords Report",
      "Site Architecture Audit",
      "Backlink Report",
      "2 Hour Consultation",
    ];
  }
  return packageContent;
};

const CustomListItem = withStyles((theme) => ({
  root: {
    paddingTop: 1,
    paddingBottom: 1,
    alignItems: "baseline",
  },
}))(ListItem);
const CustomListItemText = withStyles((theme) => ({
  root: {
    color: "inherit",
  },
}))(ListItemText);

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

export function PackageNameWithSubdomain({
  name,
  caption,
  classes,
  titleColor,
}) {
  return (
    <Grid container style={{ padding: 10 }} alignItems={"center"}>
      <Grid item md={9} className={"growable"}>
        <Grid container spacing={2}>
          <Grid item>
            <Typography
              className={classNames(
                classes.headTitle,
                classes.titleAlignLeft,
                titleColor
              )}
              style={{ maxWidth: "auto" }}
            >
              {name}
            </Typography>
            <Typography
              color="inherit"
              variant={"caption"}
              className={classNames(
                classes.caption,
                classes.titleAlignLeft,
                classes.captionColor
              )}
            >
              {caption}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

class SeoDashboard extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      userServices: null,
      errorMessage: null,
      isLoading: false,
      value: 0,
    };
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    const response = await this.props.getUserservicesByUserId();
    this.setState({ isLoading: false });
    if (response && response.userServices) {
      this.setState({ userServices: response.userServices });
    } else if (response && response.errorMessage) {
      this.setState({ errorMessage: response.errorMessage });
    }
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { errorMessage, userServices, isLoading } = this.state;
    console.log("userServices=", userServices);
    const { classes } = this.props;
    const { value } = this.state;
    return (
      <div>
        {errorMessage != null ? (
          <ErrorTypography>{errorMessage}</ErrorTypography>
        ) : null}
        <Card className={classes.mainSec}>
          <Typography align={"left"} className={classes.cardTitle}>
            <b>SERVICES</b>
          </Typography>
          <Typography align={"left"} className={classes.serviceTitile}>
            <b>Progress</b>
          </Typography>
          <br />
          <div className={classes.tabBar}>
            <AppBar position="static" className={classes.tabBar}>
              <Tabs
                value={value}
                onChange={this.handleChange}
                className={classes.tabBar}
              >
                <Tab
                  label="ON GOING SERVICES"
                  className={classes.tabListItem}
                />
                {/*<Tab label="HISTORY"/>
                                <Tab label="AVAILABLE PACKAGES"/>*/}
              </Tabs>
            </AppBar>
            {value === 0 && (
              <TabContainer>
                <CardContent>
                  <Grid container justifyContent={"center"} spacing={2}>
                    <Grid item md={12}>
                      {isLoading ? (
                        <CircularProgress />
                      ) : (
                        <Fragment>
                          {userServices != null &&
                            userServices.map((data) => (
                              <Card className={classes.card}>
                                <CardContent>
                                  <Typography
                                    className={classes.packageTitle}
                                    gutterBottom
                                  >
                                    01. SEO Service Pack:
                                  </Typography>
                                  <br />
                                  <PackageNameWithSubdomain
                                    name={data.packageName + " Service Pack"}
                                    caption={data.domain}
                                    classes={classes}
                                    titleColor={"red"}
                                  />
                                  <Typography>
                                    {packContent(data.packageId).map(
                                      (value, index) => {
                                        return (
                                          <CustomListItem>
                                            <ListItemIcon style={{ margin: 0 }}>
                                              {icons.dotGrey}
                                            </ListItemIcon>
                                            <CustomListItemText>
                                              {value}
                                            </CustomListItemText>
                                          </CustomListItem>
                                        );
                                      }
                                    )}
                                  </Typography>

                                  <br />
                                  <Typography
                                    className={classes.servicePackTitle}
                                  >
                                    STATUS
                                  </Typography>
                                  <Typography className={classes.statusTitle}>
                                    SEO TEAM IS WORKING ON THE CHANGES.
                                  </Typography>
                                  <br />
                                  <Grid item md={12}>
                                    <SeoDashboardStepper />
                                  </Grid>

                                  {/*<Grid container className="bs-wizard"
                                                                  style={{padding: 2, borderBottom: 0}}
                                                                  justifyContent={"center"}>
                                                                <Grid md={3}
                                                                      className={classNames("bs-wizard-step", purchased(data.status))}>
                                                                    <div className="text-center bs-wizard-stepnum">Step 1</div>
                                                                    <div className="progress">
                                                                        <div className="progress-bar"></div>
                                                                    </div>
                                                                    <a href="#" className="bs-wizard-dot"></a>
                                                                    <div className="bs-wizard-info text-center">Purchase</div>
                                                                </Grid>
                                                                <Grid md={3}
                                                                      className={classNames("bs-wizard-step", progress(data.status))}>
                                                                    <div className="text-center bs-wizard-stepnum">Step 2</div>
                                                                    <div className="progress">
                                                                        <div className="progress-bar"></div>
                                                                    </div>
                                                                    <a href="#" className="bs-wizard-dot"></a>
                                                                    <div className="bs-wizard-info text-center">Progress</div>
                                                                </Grid>

                                                                <Grid md={3}
                                                                      className={classNames("bs-wizard-step", delivered(data.status))}>
                                                                    <div className="text-center bs-wizard-stepnum">Step 3</div>
                                                                    <div className="progress">
                                                                        <div className="progress-bar"></div>
                                                                    </div>
                                                                    <a href="#" className="bs-wizard-dot"></a>
                                                                    <div className="bs-wizard-info text-center">Delivery</div>
                                                                </Grid>

                                                                <Grid md={3}
                                                                      className={classNames("bs-wizard-step", accepted(data.status))}>
                                                                    <div className="text-center bs-wizard-stepnum">Step 4</div>
                                                                    <div className="progress">
                                                                        <div className="progress-bar"></div>
                                                                    </div>
                                                                    <a href="#" className="bs-wizard-dot"></a>
                                                                    <div className="bs-wizard-info text-center">Accepted</div>
                                                                </Grid>
                                                            </Grid>*/}
                                  {/*<Grid item md={8}>
                                                            <Typography className={classes.title} style={{marginLeft:"10px"}} color="textSecondary"
                                                                        gutterBottom>
                                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.
                                                            </Typography>
                                                            </Grid>*/}
                                  {/*<Typography className={classes.title} color="textSecondary"
                                                                        gutterBottom>
                                                                Project Id: {data.projectId}
                                                            </Typography>
                                                            <Typography variant="h5" component="h2">
                                                                Purchase Date:{data.purchaseDate != null ?
                                                                <Fragment>{moment(data.purchaseDate).format("d/MM/YYYY")}</Fragment>
                                                                : "-"}
                                                            </Typography>
                                                            <Typography className={classes.pos} color="textSecondary">
                                                                Delivery Date: {data.deliveryDate != null ?
                                                                <Fragment>{moment(data.deliveryDate).format("d/MM/YYYY")}</Fragment>
                                                                : "-"}
                                                            </Typography>
                                                            <Typography className={classes.pos} color="textSecondary">
                                                                Accepted Date: {data.acceptedDate != null ?
                                                                <Fragment>{moment(data.acceptedDate).format("d/MM/YYYY")}</Fragment>
                                                                : "-"}
                                                            </Typography>*/}
                                </CardContent>
                              </Card>
                            ))}
                        </Fragment>
                      )}
                    </Grid>
                    {/* use classes "active" for dot, "complete" for progress-bar, "disabled" for disabling the dot */}
                    <Grid item md={8}></Grid>
                  </Grid>
                </CardContent>
              </TabContainer>
            )}
            {value === 1 && <TabContainer>HISTORY</TabContainer>}
            {value === 2 && <TabContainer>AVAILABLE PACKAGES</TabContainer>}
          </div>
        </Card>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getUserservicesByUserId: () => {
    return dispatch(getUserServices());
  },
});
export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(SeoDashboard));
