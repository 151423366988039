import * as types from "../actions/actionTypes";

export function accountSettings(state = {
    googleAuthUrl: {},
    bingAuthUrl: {},
    isUrlLoading: false,
    isMcc: false,
    errorMsg: "",
    accessSuccess: false,
    error: ""
}, action) {
    switch (action.type) {
        case types.AUTH_URLS_REQUESTED:
            return Object.assign({}, state, {
                isUrlLoading: true,
            });

        case types.AUTH_URLS_RECEIVED:
            return Object.assign({}, state, {
                isUrlLoading: false,
                ...action.data
            });
        case types.AUTH_URLS_FAILED:
            return Object.assign({}, state, {
                isUrlLoading: false,
                error: "Error in loading Auth Urls",
            });
        case types.UPDATE_REFRESH_ACCESS_SUCCESS:
            return Object.assign({}, state, {
                accessSuccess: false,
                errorMsg:"",
            });
        case types.Account_Import_STATUS_RECEIVED:
            return Object.assign({}, state, {
                error: action.data.errorMsg,
                ...action.data
            });

        default:
            return state;
    }
}