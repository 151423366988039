import * as types from "./actionTypes";

let moment = require('moment');

export function fetchReportsGraphs(graphType, dateType) {
    return async function (dispatch, getState) {
        const {reportMetric, selectedReportsDateRange} = getState();
        var dateFormat = "YYYY-MM-DD";
        var toDate = selectedReportsDateRange.toDate;
        var fromDate = selectedReportsDateRange.fromDate;
        // setTimeout(async () => {
        try {
            const url = `/reportData.json?fromDate1=${fromDate}&toDate1=${toDate}&chartType=${graphType}&channelType=${reportMetric.metric}&code=${dateType}`;
            let response = await fetch(url, {
                headers: {
                    'Accept': 'application/json',
                },
                'credentials': 'include',
            });
            let data = await response.json();
            console.log("Fetching User Profile Details successfully", data);
            return data;

        } catch (e) {
            console.error("exception", e);
            console.error("Error in account Details updating", e);
            await dispatch({
                type: types.FETCH_PROFILE_DETAILS_FAILED,
            });
        }
        // }, 0);
    }
}

export function sendEmailReport(reportType, reportName, subject, emailId) {
    return async function (dispatch, getState) {
        const {reportMetric, selectedReportsDateRange} = getState();
        var dateFormat = "YYYY-MM-DD";
        var toDate = selectedReportsDateRange.toDate;
        var fromDate = selectedReportsDateRange.fromDate;
        try {
            const url = `/emailReport.json?emailId=${emailId}&repType=${reportType}&repName=${reportName}&subject=${subject}&fromDate1=${fromDate}&toDate1=${toDate}&channelType=${reportMetric.metric}`;
            console.log("URL=", url);
            let response = await fetch(url, {
                headers: {
                    'Accept': 'application/json',
                },
                'credentials': 'include',
            });
            let data = await response.json();
            return data;

        } catch (e) {
            console.error("exception", e);
            console.error("Error in sending email", e);
        }

    }
}

export function selectReportsDateRange(dateRange) {
    const dateRanges = {
        fromDate: moment().subtract(dateRange + 1, 'd').format('YYYY-MM-DD'),
        toDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
        itemValue: dateRange,
    };
    return async (dispatch) => {
        await dispatch({
                type: types.SELECT_REPORT_PAGE_DATE_RANGE,
                dateRanges
            }
        );
        // dispatch(fetchReportsGraphs());
    }
}

export function customReportsDateRange(dateRanges) {
    return async (dispatch) => {
        await dispatch({
                type: types.SELECT_REPORT_PAGE_DATE_RANGE,
                dateRanges
            }
        );
        // dispatch(fetchReportsGraphs());
    };
}