import React from 'react';
import {Card} from "@mui/material";
import {CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import CircularProgress from '@mui/material/CircularProgress';
import {CompactCardContent, SubSectionCardHeader} from '../cards';
import {KpiString} from "../../selectors/dashboard-selectors";

var moment = require('moment');

function formatTooltipValue(value, name, props) {
    return value.toFixed(2);
}

const formatLabel = (label) => moment.unix(label).format("MMM-D");

function KpiGraph(props) {
    const {isLoading, kpiType, kpiHistory,selectedDateRange, ...rest} = props;
    console.log("kpiGraph props:", props);

    return (
        <Card {...rest} className={"fullHeight"} id={"KpiGraph"}>
            {/*<SubSectionCardHeader title={"KPI Graph"} subheader={`${KpiString[kpiType]} for last ${selectedDateRange} days`}/>*/}
            <SubSectionCardHeader title={"KPI Graph"}  action={<i className="fa fa-info-circle" aria-hidden="true" title={"This graph shows how your actual key performance indicator (blue line) such a R/C or C/R or CPO or CPL is trending against your goal (red line)"}></i>}/>
            <CompactCardContent className={"compactVertical"}>
                {isLoading ?
                    <CircularProgress/> :
                    <ResponsiveContainer width="100%" height={150}>
                        <LineChart data={kpiHistory}>
                            <XAxis dataKey="day" interval={"preserveStartEnd"}
                                   type={'number'} domain={['dataMin', 'dataMax']}
                                   tick={{style:{fontSize:12}}}
                                   // tick={{angle: -45}} textAnchor={"end"}
                                   tickFormatter={formatLabel}
                            />
                            <YAxis axisLine={false} width={20}/>
                            <CartesianGrid strokeDasharray="3" vertical={false}/>
                            <Tooltip formatter={formatTooltipValue} labelFormatter={formatLabel}/>
                            {/*<Legend />*/}
                            <Line type="monotone" dataKey="KPI" stroke="#8884d8" strokeWidth={2} dot={{r: 2}} isAnimationActive={true}
                                  animationDuration={500}/>
                            <Line type="monotone" dataKey="KPITarget"  stroke="red" strokeWidth={2} dot={false} isAnimationActive={false} animationDuration={500}/>
                            {/*<Line type="monotone" dataKey="uv" stroke="#82ca9d" />*/}
                        </LineChart>
                    </ResponsiveContainer>}
            </CompactCardContent>
        </Card>
    );
}



export default KpiGraph;