import React from "react";
import { Grid, Paper, Typography } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import { adCopyHierarchyStyles } from "./templates";
import { GoogleRecommendationBanner } from "../task-data";
import icons from "../../icons";

function adCopyPreview(props) {
  const { classes } = props;
  console.log("from preview=", props);
  return (
    <div className={classes.displayContainer}>
      <Typography variant={"body1"} color={"textPrimary"} gutterBottom>
        <b>{props.headLine}</b>
      </Typography>
      <Paper elevation={1} className={classes.paper}>
        <Grid container style={{ padding: 10, flexWrap: "nowrap" }} spacing={2}>
          <Grid item>{icons.ad}</Grid>
          <Grid item>
            <Typography component={"p"} className={classes.urlPath}>
              {props.domainUrl}/
              {props.path1 != "" && props.path1 != " --" ? (
                <span>
                  {props.path1}/{props.path2}
                </span>
              ) : null}
            </Typography>
            <Typography component="p" className={classes.headerLink}>
              {props.headLine1} |&nbsp;
              {props.headLine2}
              {props.headLine3 &&
              props.headLine3 != null &&
              props.headLine3 != "" ? (
                <span> |&nbsp;{props.headLine3}</span>
              ) : null}
            </Typography>
            <Typography
              component={"p"}
              className={classes.description}
              style={{ flexWrap: "wrap" }}
            >
              {props.description1}&nbsp;&nbsp;
              {props.description2 &&
              props.description2 != null &&
              props.description2 != ""
                ? props.description2
                : null}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
      {props.gleRecommendation == true ? <GoogleRecommendationBanner /> : null}
    </div>
  );
}

export default withStyles(adCopyHierarchyStyles, { withTheme: true })(
  adCopyPreview
);
