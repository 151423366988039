import { Checkbox } from "@mui/material";

const totalRowsInPage = 10;

export const columns = (
  handleRowCheckbox,
  handleSelectAllClick,
  isRowSelected,
  selectedRowCount,
  disableStructure
) => [
  {
    field: "selected",
    headerName: "Selected",
    width: 100,
    sortable: false,
    filterable: false,
    renderHeader: (params) => {
      return (
        <Checkbox
          size="small"
          color="secondary"
          checked={selectedRowCount() == totalRowsInPage}
          onChange={handleSelectAllClick}
          disabled={disableStructure}
        />
      );
    },
    renderCell: (params) => {
      return (
        <Checkbox
          size="small"
          color="secondary"
          checked={isRowSelected(params.row)}
          onClick={(event) => handleRowCheckbox(event, params)}
          disabled={disableStructure}
        />
      );
    },
  },
  {
    field: "text",
    headerName: "Keyword",
    flex: 1.5,
    headerAlign: "center",
  },
  {
    field: "minSearchVolume",
    headerName: "Min. Monthly Search Volume",
    type: "number",
    flex: 1.2,
    valueFormatter: (params) => {
      return formatNumber(params.value);
    },
    headerAlign: "center",
    align: "center",
  },
  {
    field: "maxSearchVolume",
    headerName: "Max. Monthly Search Volume",
    type: "number",
    flex: 1.2,
    valueFormatter: (params) => {
      return formatNumber(params.value);
    },
    headerAlign: "center",
    align: "center",
  },
  {
    field: "ninetyDaysSearchVolumeTrend",
    headerName: "90 Days Trend",
    type: "number",
    flex: 1,
    renderCell: (params) => {
      return (
        <span
          style={{
            color:
              params.value < 0
                ? "#ED4A4A"
                : params.value > 0
                ? "#00A850"
                : "inherit",
          }}
        >
          {params.value ? Number(params.value.toFixed(2)) + "%" : "NA"}
        </span>
      );
    },
    headerAlign: "center",
    align: "center",
  },
  {
    field: "yearOverYearSearchVolumeTrend",
    headerName: "Y-O-Y Trend",
    type: "number",
    flex: 1,
    renderCell: (params) => {
      return (
        <span
          style={{
            color:
              params.value < 0
                ? "#ED4A4A"
                : params.value > 0
                ? "#00A850"
                : "inherit",
          }}
        >
          {params.value ? Number(params.value.toFixed(2)) + "%" : "NA"}
        </span>
      );
    },
    headerAlign: "center",
    align: "center",
  },
  {
    field: "lowTopOfPageBid",
    headerName: "Bid Low",
    type: "number",
    flex: 1,
    valueFormatter: (params) => {
      return `$${params.value}`;
    },
    headerAlign: "center",
    align: "center",
  },
  {
    field: "highTopOfPageBid",
    headerName: "Bid High",
    type: "number",
    flex: 1,
    valueFormatter: (params) => {
      return `$${params.value}`;
    },
    align: "center",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "competition",
    headerName: "Competition",
    flex: 1,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <span
          style={{
            color:
              params.value == "HIGH"
                ? "#ED4A4A"
                : params.value == "LOW"
                ? "#00A850"
                : params.value == "MEDIUM"
                ? "#00A3FF"
                : "inherit",
          }}
        >
          {params.value == "HIGH"
            ? "High"
            : params.value == "LOW"
            ? "Low"
            : params.value == "MEDIUM"
            ? "Medium"
            : "NA"}
        </span>
      );
    },
  },
];
const formatNumber = (num) => {
  const formattedNum = num >= 1000 ? (num / 1000).toFixed(1) + "K" : num;
  return formattedNum;
};
