import React from "react";
import withStyles from '@mui/styles/withStyles';
import Grid from "@mui/material/Grid";
import {
  OrangeTextSize12,
  GrayText,
  HeadLineTypography,
  BlueTitleTypography,
  CatalinaBlue,
  OrangeText,
} from "../../components/typography";
import speakerimg from "../../assets/img/speakerimg.png";
import messageimg from "../../assets/img/messageimg.png";
import bargraphimg from "../../assets/img/barcolorimg.png";
import { Card, CardContent, Typography } from "@mui/material";
import icons from "../../components/icons";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    paddingRight: theme.spacing(8),
    paddingLeft: theme.spacing(8),
    [theme.breakpoints.down('xl')]: {
      padding: theme.spacing(4),
    },
    backgroundColor: "#ECEFF3",
    /* [theme.breakpoints.down('md')]: {
             paddingLeft: 10,
             paddingRight: 10,
             paddingTop: 10,
             paddingBottom: 10,
         },
         [theme.breakpoints.up('sm')]: {
             paddingLeft: theme.spacing(4),
             paddingRight: theme.spacing(4),
             paddingTop: 39,
             paddingBottom: 40,
         },*/

    // justifyContent: 'space-between',
  },
  OrangeTextStyle: {
    // fontSize:'22px',
    textAlign: "center",
    fontSize: 22,
    color: "#F58120",
    // textAlign: 'initial',
  },
  BorderGrid: {
    border: "1px solid " + theme.palette.common.darkGrey,
    boxSizing: "border-box",
    borderRadius: 10,
    height: 500,
    boxShadow: "unset",
    background: "unset",
    [theme.breakpoints.down('xl')]: {
      height: 330,
    },
  },
  imgstyles: {
    textAlign: "center! important",
  },
});

function ThreeAED(props) {
  const classes = props.classes;
  const width = props.width;
  return (
    <div>
      {/* className={classes.root}   */}
      <Grid
        container
        spacing={2}
        className={classes.root}
        justifyContent="space-around"
      >
        <Grid item md={4} sm={12} xs={12}>
          <Card className={classes.BorderGrid}>
            <CardContent>
              <div className={classes.imgstyles}>
                <img src={speakerimg} />
              </div>
              <br />
              <br />
              <Typography className={classes.OrangeTextStyle}>
                ATTRACT LEADS
              </Typography>{" "}
              <br />
              <CatalinaBlue>
                {" "}
                Ensuring that you have a regular flow of people visiting your
                website that convert into hand-raiser – people that indicate
                that they are interested in your product.
              </CatalinaBlue>
            </CardContent>
          </Card>
        </Grid>

        <Grid item md={4} sm={12} xs={12}>
          <Card className={classes.BorderGrid}>
            <CardContent>
              <div className={classes.imgstyles}>
                <img src={messageimg} />
              </div>
              <br />
              <br />
              <Typography className={classes.OrangeTextStyle}>
                ENGAGE PROSPECTS{" "}
              </Typography>
              <br />
              <CatalinaBlue>
                {" "}
                Having the right systems, processes, and content in place so
                that your sales teams can close more deals at a greater rate.
              </CatalinaBlue>
            </CardContent>
          </Card>
        </Grid>

        <Grid item md={4} sm={12} xs={12}>
          <Card className={classes.BorderGrid}>
            <CardContent>
              <div className={classes.imgstyles}>
                <img src={bargraphimg} />
              </div>
              <br />
              <br />
              <Typography className={classes.OrangeTextStyle}>
                DELIGHT & RETAIN{" "}
              </Typography>
              <br />
              <CatalinaBlue>
                {" "}
                Providing world-class onboarding and customer service that means
                they stay with you for longer and increase your customer
                lifetime value.
              </CatalinaBlue>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(ThreeAED);
