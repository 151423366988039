import React, { Fragment, useEffect, useState } from "react";
import ViewDetails from "./view-details";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  FormGroup,
  Checkbox,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import * as _ from "lodash";
import {
  BlueBtn,
  BorderBtn,
  GraderOutLineButton,
  LoginOutLineButton,
  NewOutLinedRectBlackBtn,
  PostButton,
  PublishOrangeContainedBtn,
  WhiteBtn,
  PopUpCommonBtn,
} from "../buttons";
import CurrencySymbol from "../../components/currencySymbol.jsx";
import Counter from "./counter";
import {
  formatOptions,
  CustomTableCell,
  CustomTableRow,
  CustomTable,
} from "./cart-container";
import {
  data,
  premierServicePack,
  // professionalServicePack,
  // starterServicePack,
  customProductFeedAuditFix,
  customProductFeedAuditFix10,
  webDevelopment,
  seoService,
  premierServicePackText,
  selectaplantoSubscribe,
  premierServicePackAmount,
  seoServicePackText,
  serviceDescription,
  serviceDescriptionSEO,
  seoServicePackAmount,
  selectaplantoSubscribeSEO,
  domainPara,
} from "./view-details-data";
import classNames from "classnames";
import { withStyles } from "@mui/styles";
import * as PropTypes from "prop-types";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import PersonIcon from "@mui/icons-material/Person";
import AddIcon from "@mui/icons-material/Add";
import blue from "@mui/material/colors/blue";
import icons from "../icons";
import {
  getAccountStructure,
  resetItemCount,
  saveOnDemandFroms,
  updateItemCount,
} from "../../actions";
import {
  ONE_TIME,
  SUBSCRIPTION,
  UPDATE_Premier_PACK_MONTHLY_TOGGLE,
  UPDATE_SEO_PACK_TRIMONTHLY_TOGGLE,
} from "../../actions/actionTypes";
import { connect } from "react-redux";
import { Link } from "react-router-dom";


export const HyperLinks = withStyles((theme) => ({
  root: {
    color: theme.palette.secondary.main,
    fontSize: 14,
    fontWeight: "700",
    textDecoration: "underline",
    textTransform: "uppercase",
  },
}))(Button);

export function ActionButtons({ activeStep, onSubmit, steps }) {
  return (
    <Grid
      item
      className={"fullWidth"}
      style={{ textAlign: "right", borderTop: "1px solid #8F99A7" }}
    >
      <WhiteBtn
        variant="contained"
        style={{ margin: 5 }}
        disabled={activeStep === 0}
        onClick={() => onSubmit(false)}
      >
        PREVIOUS
      </WhiteBtn>
      <BlueBtn
        onClick={() => onSubmit(true)}
        variant="contained"
        style={{ margin: 5 }}
      >
        {activeStep === steps.length - 1 ? "PROCEED TO PAY" : "NEXT"}
      </BlueBtn>
    </Grid>
  );
}

function PlanLabel(props) {
  const { packName, price, monthly, description } = props;
  return (
    <div>
      <Typography color={"secondary"} variant={"body1"} gutterBottom>
        <b className={props.classes.textBorder}>{packName}</b>
      </Typography>
      <Typography color={"secondary"} variant={"body1"} gutterBottom>
        <b>
          ${price} {monthly && <span>/ MONTH</span>}
        </b>
      </Typography>
      <Typography variant={"body1"} color={"textPrimary"} gutterBottom>
        {description}
      </Typography>
    </div>
  );
}

PlanLabel.propTypes = { classes: PropTypes.any };

function ProPacks(props) {
  const {
    name,
    currentCount,
    onClick,
    onClick1,
    removedIcon,
    className,
    successIcon,
    classes,
    isPremierServicePackMonthly,
    isSEOPackTrimonthly,
    subscriptions,
    companyName,
    companyURL,
  } = props;
  const [packName, setPackName] = useState(SUBSCRIPTION);
  const [isPlanCheck, setIsPlanCheck] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(false);

  const [open, setOpen] = useState(false);

  const handleScroll = (e) => {
    const divElement = document.querySelector("#last");
    if (!divElement) {
      return;
    } // Make sure the element exists

    const divBottom = divElement.offsetTop + divElement.offsetHeight;

    // we are adding 190 because we are not able to get the exact location remained
    if (e.currentTarget.scrollTop >= divBottom - window.innerHeight + 100) {
      //
      setScrollPosition(true);
    } else {
      setScrollPosition(false);
    }
  };



  const handleClickOpen = (value) => {
    setOpen(value);
  };

  const handleClose = () => {
    setOpen((prevState) => ({
      ...prevState,
      open: false,
    }));
    setScrollPosition(false);
    setIsChecked(false);
  };

  const handleCancelClose = () => {
    const { currentCount } = props;
    props.UpdatePremierMonthly(false);
    props.UpdateSEOTrimonthly(false);
    if (currentCount > 0) props.onClick1();

    setOpen((prevState) => ({
      ...prevState,
      open: false,
    }));
    setScrollPosition(false);
    setOpen(false);
  };

  const handleAgreeClose = (PackageName) => {

    try {
      const { currentCount } = props;
      // const { packName } = state;
      if (packName == SUBSCRIPTION && PackageName == "Premier") {
        props.UpdatePremierMonthly(true);
      } else if (packName == SUBSCRIPTION && PackageName == "SEO") {
        props.UpdateSEOTrimonthly(true);
      } else if (packName !== SUBSCRIPTION && PackageName == "SEO") {
        props.UpdateSEOTrimonthly(false);
      } else {
        props.UpdatePremierMonthly(false);
      }
      if (currentCount == 0) {
        props.onClick();
      }

      setIsChecked(false);
      setScrollPosition(false);
      setOpen(false);
    } catch (err) {
      console.log("error---at handleAgreeClose---->" + err);
    }
  };

  const handleChange = (planToggle) => (event) => {
    setOpen((prevState) => ({
      ...prevState,
      [planToggle]: event.target.value,
    }));
    // setState({ planToggle: event.target.value });
  };

  const handleDialogClose = () => {
    setOpen(false);
    setIsChecked(false);
    setScrollPosition(false);
  };
  const checkBoxHandleChange = (name) => (event) => {
    setIsChecked(event.target.checked);
  };

  const handlePlanChange = (event) => {
    setIsPlanCheck(event.target.checked);
  };
  useEffect(() => {
    if (isPlanCheck) {
      setPackName(SUBSCRIPTION);
    } else {
      setPackName(ONE_TIME);
    }
  }, [isPlanCheck]);

  //const {packName} = state;
  const hidePremier =
    subscriptions != null &&
    subscriptions.length > 0 &&
    subscriptions.filter((sub) => sub.onDemandPricingPlan.pricingId == 1)
      .length > 0;

  const hideSEO =
    subscriptions != null &&
    subscriptions.length > 0 &&
    subscriptions.filter((sub) => sub.onDemandPricingPlan.pricingId == 3)
      .length > 0;

  return (
    <Fragment>
      {name == customProductFeedAuditFix ||
        name == customProductFeedAuditFix10 ||
        name == webDevelopment ? (
        <Fragment>
          {currentCount == 0 ? (
            <HyperLinks onClick={onClick} variant={"text"}>
              Add
            </HyperLinks>
          ) : (
            <HyperLinks onClick={onClick1} variant={"text"}>
              Remove
            </HyperLinks>
          )}
        </Fragment>
      ) : null}

      {name == premierServicePack && (
        <Fragment>
          {hidePremier ? (
            <HyperLinks variant={"text"} component={Link} to={"/subscriptions"}>
              Go to Subscription
            </HyperLinks>
          ) : currentCount == 0 ? (
            <HyperLinks
              variant={"text"}
              onClick={() => {
                handleClickOpen("ADD");
              }}
            >
              Add
              {/* from above we are setting the ADD button in the ondemandpage ui */}
            </HyperLinks>
          ) : (
            <HyperLinks
              variant={"text"}
              onClick={() => {
                handleClickOpen("REMOVE");
              }}
            >
              Remove
            </HyperLinks>
          )}
        </Fragment>
      )}
      {name == seoService ? (
        <Fragment>
          {hideSEO ? (
            <HyperLinks variant={"text"} component={Link} to={"/subscriptions"}>
              Go to Subscription
            </HyperLinks>
          ) : currentCount == 0 ? (
            <HyperLinks
              variant={"text"}
              onClick={() => {
                handleClickOpen("ADD SEO");
              }}
            >
              Add
              {/* from above we are setting the ADD button in the ondemandpage ui */}
            </HyperLinks>
          ) : (
            <HyperLinks
              variant={"text"}
              onClick={() => {
                handleClickOpen("REMOVE");
              }}
            >
              Remove
            </HyperLinks>
          )}
        </Fragment>
      ) : (
        ""
      )}
      {open == "ADD" || open == "ADD SEO" ? (
        <Dialog
          open={true}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="md"
          className={classes.dialog}
        >
          <DialogTitle className={classes.headerTypo}>
            <Grid container>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}></Grid>
              <Grid item xs={10} sm={2} md={4} lg={4} xl={4}>
                <Typography
                  style={{
                    color: "white",
                    fontSize: "1.35rem",
                    textAlign: "center",
                  }}
                >
                  {/* {selectaplantoSubscribe[0]}
                  selectaplantoSubscribeSEO */}
                  {open == "ADD"
                    ? selectaplantoSubscribe[0]
                    : selectaplantoSubscribeSEO[0]}
                </Typography>
              </Grid>
              <Grid item xs={2} sm={12} md={4} lg={4} xl={4}>
                <Typography
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    // paddingTop: "11px",
                  }}
                >
                  <Button
                    style={{ textAlign: "end", cursor: "pointer" }}
                    onClick={handleDialogClose}
                  >
                    {icons.closeMenuMobile}
                  </Button>
                </Typography>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent
            className={classes.scrollbar}
            id="scrollbar"
            // style={{ overflowY: "hidden" }}
            onScroll={handleScroll}
          >
            <Grid container>
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <div
                  style={{
                    marginTop: "2.5rem",
                    position: "sticky",
                    top: 40,
                  }}
                >
                  <div>
                    <Typography color="secondary" variant="h2" gutterBottom>
                      <b>
                        {/* {premierServicePackAmount[0]} */}
                        {open == "ADD"
                          ? premierServicePackAmount[0]
                          : seoServicePackAmount[0]}
                      </b>
                    </Typography>
                    <span style={{ color: "#A4A4A4" }}>
                      {open == "ADD"
                        ? selectaplantoSubscribe[1]
                        : selectaplantoSubscribeSEO[1]}
                    </span>
                  </div>
                  <div>
                    <FormControl>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="secondary"
                              checked={isPlanCheck}
                              onChange={(event) => handlePlanChange(event)}
                              value="isPlanCheck"
                            />
                          }
                          label={
                            // <Typography style={{ color: "#747474" }}>
                            <Typography>Auto-renew*</Typography>
                          }
                        />
                      </FormGroup>
                    </FormControl>
                  </div>
                </div>
              </Grid>
              <Grid
                style={{
                  borderLeft: "2px solid #E7E7E7",
                }}
                item
                xs={12}
                sm={12}
                md={9}
                lg={9}
                xl={9}
              >
                <div
                  id="last"
                  style={{
                    wordBreak: "break-word",
                    margin: "2.5rem 2.5rem 0rem 2.5rem",
                  }}
                >
                  <Typography>{icons.NetElixirLogo}</Typography>
                  <br />
                  <Typography
                    style={{
                      fontSize: "1.6rem",
                      fontWeight: "700",
                      color: "#000",
                      // opacity: "0.80",
                    }}
                  >
                    {open == "ADD"
                      ? premierServicePackText[0]
                      : seoServicePackText[0]}
                  </Typography>
                  <br />
                  <Typography className={classes.descriptionTypo}>
                    {open == "ADD"
                      ? premierServicePackText[1]
                      : seoServicePackText[1]}{" "}
                    &nbsp;{companyName},&nbsp;
                    {open == "ADD"
                      ? premierServicePackText[2]
                      : seoServicePackText[2]}
                  </Typography>
                  <br />
                  <Typography className={classes.descriptionTypo}>
                    {open == "ADD"
                      ? ("")
                      : (
                        <>
                          {domainPara[0]}
                          {" "}{companyURL}
                        </>
                      )}{" "}


                  </Typography>
                  <br />
                  <div>
                    <p className={classes.listHeadings}>
                      {open == "ADD" ? "" : serviceDescriptionSEO[0]}
                    </p>
                    {open == "ADD SEO" ? (
                      <>
                        <p className={classes.mainLists}>
                          {serviceDescriptionSEO[1]}
                        </p>
                        <br />
                      </>
                    ) : (
                      ""
                    )}

                    <ol>
                      <li className={classes.listHeadings}>
                        {open == "ADD"
                          ? serviceDescription[0]
                          : seoServicePackText[3]}

                        <span className={classes.mainLists}>
                          &nbsp;
                          <br />
                          {open == "ADD"
                            ? serviceDescription[1]
                            : seoServicePackText[4]}
                        </span>
                      </li>
                      <br />
                      <ol type="a">
                        <li className={classes.nestedLists}>
                          {open == "ADD"
                            ? premierServicePackText[4]
                            : seoServicePackText[5]}
                        </li>
                        <br />
                        <li className={classes.nestedLists}>
                          {open == "ADD"
                            ? premierServicePackText[5]
                            : seoServicePackText[6]}
                        </li>
                        <br />
                        <li className={classes.nestedLists}>
                          {open == "ADD"
                            ? premierServicePackText[6]
                            : seoServicePackText[7]}
                        </li>
                        <br />
                        <li className={classes.nestedLists}>
                          {open == "ADD"
                            ? premierServicePackText[7]
                            : seoServicePackText[8]}
                        </li>
                        <br />
                        {open == "ADD" ? (
                          <>
                            <li className={classes.nestedLists}>
                              {premierServicePackText[8]}
                            </li>
                            <br />
                          </>
                        ) : (
                          ""
                        )}

                        {open == "ADD" ? (
                          <>
                            <li className={classes.nestedLists}>
                              {premierServicePackText[9]}
                            </li>
                            <br />
                          </>
                        ) : (
                          ""
                        )}

                        {open == "ADD" ? (
                          <>
                            <li className={classes.nestedLists}>
                              {premierServicePackText[10]}
                            </li>
                            <br />
                          </>
                        ) : (
                          ""
                        )}

                        {open == "ADD" ? (
                          <>
                            <li className={classes.nestedLists}>
                              {premierServicePackText[11]}
                            </li>
                            <br />
                          </>
                        ) : (
                          ""
                        )}

                        {open == "ADD" ? (
                          <>
                            <li className={classes.nestedLists}>
                              {premierServicePackText[12]}
                            </li>
                            <br />
                          </>
                        ) : (
                          ""
                        )}
                      </ol>
                      {/* ======new ===== */}
                      <li className={classes.listHeadings}>
                        {open == "ADD"
                          ? premierServicePackText[13]
                          : seoServicePackText[9]}

                        <span className={classes.mainLists}>
                          &nbsp;
                          {open == "ADD" ? premierServicePackText[14] : ""}
                        </span>
                      </li>
                      <br />
                      {/* =====new=========== */}
                      <ol type="a">
                        {open == "ADD" ? (
                          <>
                            <li className={classes.nestedLists}>
                              {premierServicePackText[15]}
                            </li>
                            <br />
                          </>
                        ) : (
                          <>
                            <li className={classes.nestedLists}>
                              {seoServicePackText[10]}
                            </li>
                            <br />
                          </>
                        )}

                        {open == "ADD" ? (
                          <>
                            <li className={classes.nestedLists}>
                              {premierServicePackText[16]}
                            </li>
                            <br />
                          </>
                        ) : (
                          ""
                        )}

                        {open == "ADD" ? (
                          <>
                            <li className={classes.nestedLists}>
                              {premierServicePackText[17]}
                            </li>
                            <br />
                          </>
                        ) : (
                          ""
                        )}

                        {open == "ADD" ? (
                          <>
                            <li className={classes.nestedLists}>
                              {premierServicePackText[18]}
                            </li>
                            <br />
                          </>
                        ) : (
                          ""
                        )}

                        {open == "ADD" ? (
                          <>
                            <li className={classes.nestedLists}>
                              {premierServicePackText[19]}
                            </li>
                            <br />
                          </>
                        ) : (
                          ""
                        )}
                      </ol>
                      <li className={classes.listHeadings}>
                        {open == "ADD"
                          ? premierServicePackText[20]
                          : seoServicePackText[11]}
                        <span className={classes.mainLists}>
                          &nbsp;
                          {open == "ADD" ? premierServicePackText[21] : ""}
                        </span>
                      </li>
                      <br />
                      <ol type="a">
                        {open == "ADD" ? (
                          <>
                            <li className={classes.nestedLists}>
                              {premierServicePackText[22]}
                            </li>
                            <br />
                          </>
                        ) : (
                          <>
                            <li className={classes.nestedLists}>
                              {seoServicePackText[12]}
                            </li>
                            <br />
                          </>
                        )}

                        {open == "ADD" ? (
                          <>
                            <li className={classes.nestedLists}>
                              {premierServicePackText[23]}
                            </li>
                            <br />
                          </>
                        ) : (
                          <>
                            <li className={classes.nestedLists}>
                              {seoServicePackText[13]}
                            </li>
                            <br />
                          </>
                        )}

                        {open == "ADD" ? (
                          <>
                            <li className={classes.nestedLists}>
                              {premierServicePackText[24]}
                            </li>
                            <br />
                          </>
                        ) : (
                          ""
                        )}

                        {open == "ADD" ? (
                          <>
                            <li className={classes.nestedLists}>
                              {premierServicePackText[25]}
                            </li>
                            <br />
                          </>
                        ) : (
                          ""
                        )}

                        {open == "ADD" ? (
                          <>
                            <li className={classes.nestedLists}>
                              {premierServicePackText[26]}
                            </li>
                            <br />
                          </>
                        ) : (
                          ""
                        )}
                      </ol>
                      <li className={classes.listHeadings}>

                        {open == "ADD"
                          ? premierServicePackText[20]
                          : seoServicePackText[14]}
                        <span className={classes.mainLists}>
                          &nbsp;
                          {open == "ADD" ? premierServicePackText[28] : ""}

                        </span>
                      </li>
                      <br />
                      <ol type="a">
                        {open == "ADD" ? (
                          <>
                            <li className={classes.nestedLists}>

                              {premierServicePackText[29]}
                            </li>
                            <br />
                          </>
                        ) : (
                          <>
                            <li className={classes.nestedLists}>
                              {seoServicePackText[15]}
                            </li>
                            <br />
                          </>
                        )}

                        {open == "ADD" ? (
                          <>
                            <li className={classes.nestedLists}>
                              {premierServicePackText[16]}
                            </li>
                            <br />
                          </>
                        ) : (
                          ""
                        )}
                      </ol>
                     
                      {open == "ADD" ? (
                        ""
                      ) : (
                        <li className={classes.listHeadings}>
                          {seoServicePackText[16]}
                          <ol type="a">
                            {open == "ADD" ? (
                              ""
                            ) : (
                              <>
                              <br />
                                <li className={classes.nestedLists}>
                                  {seoServicePackText[17]}
                                </li>
                                <br />
                              </>
                            )}
                          </ol>
                        </li>
                      )}
                    </ol>
                  </div>
                  <Typography className={classes.descriptionTypo}>
                    {premierServicePackText[31]}
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            style={{
              height: "74px",
              margin: "0px",
              border: "1px solid #F6F6F6",
              borderRadius: "6px",
              backgroundColor: "#F6F6F6",
            }}
          >
            <Grid container>
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <Typography style={{ fontStyle: "italic", color: "#A4A4A4" }}>
                  {premierServicePackText[32]}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                style={{ color: "#363636 !important" }}
              >
                <div style={{ paddingLeft: "48px", color: "#363636" }}>
                  <FormControl style={{ color: "#363636 !important" }}>
                    <FormGroup style={{ color: "#363636 !important" }}>
                      <FormControlLabel
                        style={{ color: "#363636 !important" }}
                        control={
                          <Checkbox
                            color="secondary"
                            checked={isChecked}
                            onChange={checkBoxHandleChange("isChecked")}
                            value="isChecked"
                            disabled={!scrollPosition}
                          />
                        }
                        label={
                          "I agree to the Terms & Conditions mentioned above."
                        }
                      />
                    </FormGroup>
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <Typography style={{ textAlign: "center", paddingTop: "4px" }}>
                  <PublishOrangeContainedBtn
                    disabled={!isChecked}
                    variant={"contained"}
                    onClick={
                      open == "ADD"
                        ? () => handleAgreeClose("Premier")
                        : () => handleAgreeClose("SEO")
                    }
                    autoFocus
                  >
                    ACCEPT & CONTINUE
                  </PublishOrangeContainedBtn>
                </Typography>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      ) : open == "REMOVE" ? (
        <Dialog
          open={true}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth={"xs"}
          className={classes.dialog}
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure you want to cancel?"}
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary" autoFocus>
              No
            </Button>
            <Button onClick={handleCancelClose} color="primary" autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </Fragment>
  );
}

ProPacks.propTypes = {
  name: PropTypes.any,
  currentCount: PropTypes.any,
  onClick: PropTypes.func,
  className: PropTypes.shape({ width: PropTypes.string }),
  removedIcon: PropTypes.shape({
    marginRight: PropTypes.any,
    color: PropTypes.string,
  }),
  onClick1: PropTypes.func,
  successIcon: PropTypes.shape({
    marginRight: PropTypes.any,
    color: PropTypes.string,
  }),
};

function _RenderTableCells({
  dataObj,
  currentCounts,
  currentTotals,
  handleCount,
  counter,
  classes,
  UpdatePremierMonthly,
  UpdateSEOTrimonthly,
  isPremierServicePackMonthly,
  isSEOPackTrimonthly,
  subscriptions,
  multiPageToggle,
  companyName,
  companyURL,
  onDemandPackagesPlanPrice,
}) {
  
  let currentCount = currentCounts[dataObj.name];
  const addPackCount = {
    name: dataObj.name,
    currentCount: currentCounts[dataObj.name] + 1,
  };
  const removePackCount = {
    name: dataObj.name,
    currentCount: currentCounts[dataObj.name] - 1,
  };
  let Packageprices = "";
  if (
    onDemandPackagesPlanPrice != undefined &&
    onDemandPackagesPlanPrice.onDemandPackages != undefined
  ) {
    Packageprices = onDemandPackagesPlanPrice != undefined ? onDemandPackagesPlanPrice.onDemandPackages : 0;
  }
  
  const packagePlanIndex = {
    8: 2,
    7: 5,
    6: 4,
    5: 3,
    9: 0,
  };
 
  const price =
    Packageprices &&
      packagePlanIndex[dataObj.id] !== undefined &&
      Packageprices[packagePlanIndex[dataObj.id]]
      ? Packageprices[packagePlanIndex[dataObj.id]].amount : dataObj.price;
  return (
    <Fragment>
      {dataObj.type == "item" ? (
        <CustomTableRow key={dataObj.id}>
          <CustomTableCell>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {dataObj.name == premierServicePack ? (
                <>
                  <Typography style={{ width: "252px" }}>
                    <Typography variant={"body1"}>
                      <b>{dataObj.name}</b>
                    </Typography>
                    {!multiPageToggle ? (
                      <Typography
                        color={"secondary"}
                        style={{ fontWeight: "700" }}
                      >
                        (Monthly Subscription Available)
                      </Typography>
                    ) : null}
                  </Typography>
                  <ViewDetails name={dataObj.name} />
                </>
              ) : dataObj.name == seoService ? (
                <>
                  <Typography style={{ width: "252px" }}>
                    <Typography variant={"body1"}>
                      <b>{dataObj.name}</b>
                    </Typography>
                    {!multiPageToggle ? (
                      <Typography
                        color={"secondary"}
                        style={{ fontWeight: "700" }}
                      >
                        (Quarterly Subscription Available)
                      </Typography>
                    ) : null}
                  </Typography>
                  <ViewDetails name={dataObj.name} />
                </>

              ) : dataObj.name == customProductFeedAuditFix10 ? (
                <>
                  <Typography style={{ width: "252px" }}>
                    <b>Custom Product Feed Audit/Fix</b>
                  </Typography>
                  <ViewDetails name={dataObj.name} />
                </>
              ) : (
                <>
                  <Typography style={{ width: "252px" }}>
                    <b>{dataObj.name}</b>
                  </Typography>
                  <ViewDetails name={dataObj.name} />
                </>
              )}
            </div>
          </CustomTableCell>
          {dataObj.context ? (
            <CustomTableCell>
              <b>${price.toLocaleString(
                "en-US"
              )} &nbsp;</b>
            </CustomTableCell>
          ) : (
            <CustomTableCell>
              <b>${price.toLocaleString(
                "en-US"
              )} &nbsp;</b>
            </CustomTableCell>
          )}

          <CustomTableCell>
            <b>
              {dataObj.name == (premierServicePack && isPremierServicePackMonthly) ? (" for Subscription") : dataObj.name == (seoService && isSEOPackTrimonthly) ? (" for Subscription") : dataObj.context}
            </b>
          </CustomTableCell>

          {counter == true ? (
            <Fragment>
              <CustomTableCell numeric style={{ textAlign: "right" }}>
                <Counter
                  onChange={(data) => handleCount(data)}
                  data={{
                    name: dataObj.name,
                    currentCount: currentCounts[dataObj.name],
                  }}
                />
                <ProPacks
                  name={dataObj.name}
                  currentCount={currentCount}
                  onClick={() => handleCount(addPackCount)}
                  className={classes.customButton}
                  removedIcon={classes.removedIcon}
                  onClick1={() => handleCount(removePackCount)}
                  successIcon={classes.successIcon}
                  classes={classes}
                  UpdatePremierMonthly={UpdatePremierMonthly}
                  UpdateSEOTrimonthly={UpdateSEOTrimonthly}
                  isPremierServicePackMonthly={isPremierServicePackMonthly}
                  isSEOPackTrimonthly={isSEOPackTrimonthly}
                  subscriptions={subscriptions}
                  companyName={companyName}
                  companyURL={companyURL}
                />
              </CustomTableCell>
            </Fragment>
          ) : null}
          {/* removing below because we are not having option of selecting multiple count for the same package   */}
          {/* {counter == false ? (
            <CustomTableCell>{currentCounts[dataObj.name]}</CustomTableCell>
          ) : null} */}

          <CustomTableCell
            numeric
            align="right"
          >
            <b>${currentTotals[dataObj.name].toLocaleString(
              "en-US"
            )}</b>
          </CustomTableCell>
        </CustomTableRow>
      ) : null}
    </Fragment>
  );
}

const style = (theme) => ({
  removedIcon: {
    color: "#1b926c",
    marginRight: theme.spacing.unit,
  },
  successIcon: {
    color: "#1b926c",
    marginRight: theme.spacing.unit,
  },
  customButton: {
    width: "85%",
  },
  radioButton: {
    marginBottom: 10,
    alignItems: "flex-start",
    "&> span:nth-child(1)": {
      paddingTop: 5,
    },
  },
  textBorder: {
    borderBottom: "1px solid",
  },
  dialog: {
    borderRadius: 9,
  },
  dialogActions: {
    paddingBottom: 10,
  },
  headerTypo: {
    backgroundColor: "#1E334E",
    fontSize: "1.5rem",
    fontWeight: "500",
    textAlign: "center",
  },
  descriptionTypo: {
    // fontWeight: "400",
    // opacity: "0.80",
    fontSize: "1rem",
    color: "#363636",
  },
  listHeadings: {
    fontWeight: "700",
    // opacity: "0.80",
    fontSize: "1rem",
    color: "#F58120",
  },
  mainLists: {
    fontWeight: "400",
    // opacity: "0.80",
    fontSize: "1rem",
    color: "#363636",
  },
  nestedLists: {
    fontWeight: "400",
    // opacity: "0.70",
    fontSize: "1rem",
    color: "#363636",
  },
  scrollbar: {
    marginRight: "15px",
    "&::-webkit-scrollbar": {
      width: "0.4em",
      height: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      borderRadius: "6px",
    },
  },
});
const mapDispatchToProps = (dispatch) => ({
  UpdatePremierMonthly: (isMonthly) => {

    return dispatch({
      type: UPDATE_Premier_PACK_MONTHLY_TOGGLE,
      isPremierServicePackMonthly: isMonthly,
    });
  },
  UpdateSEOTrimonthly: (isTrimonthly) => {

    return dispatch({
      type: UPDATE_SEO_PACK_TRIMONTHLY_TOGGLE,
      isSEOPackTrimonthly: isTrimonthly,
    });
  },
});
const mapStateToProps = (state) => ({
  isPremierServicePackMonthly:
    state.onDemandPayment.isPremierServicePackMonthly,
  isSEOPackTrimonthly: state.onDemandPayment.isSEOPackTrimonthly,
  subscriptions: state.subscriptionsData.subscriptions,
});
export let RenderTableCells = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(_RenderTableCells));

export function RenderHeader({ dataObj }) {
  return (
    <Fragment>
      {dataObj.type == "header" ? (
        <Typography variant={"h5"} gutterBottom>
          {dataObj.name}
        </Typography>
      ) : null}
    </Fragment>
  );
}

export function calculateSteps(steps) {
  steps = steps && steps.filter((step) => step.type != "Premier Service Pack");
  steps =
    steps &&
    steps.filter((step) => step.type != "Custom Product Feed Audit/Fix");
  steps =
    steps &&
    steps.filter((step) => step.type != "Custom Product Feed Audit/Fix 10");
  steps = steps && steps.filter((step) => step.type != "Web Development");
  steps = steps && steps.filter((step) => step.type != "SEO Service");
  return steps;
}

export function calculateGrandTotal(itemCounts) {
  const unitPrices = _.mapValues(_.keyBy(data, "name"), (v) => v.price);
  const lineTotals = _.mapValues(itemCounts, (v, k) => v * unitPrices[k]);
  const grandTotal = _.sum(Object.values(lineTotals));
  return grandTotal;
}
