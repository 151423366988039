import { Grid, Paper, Typography } from "@mui/material";
import * as PropTypes from "prop-types";
import React, { PureComponent } from "react";
import classNames from "classnames";
import { GetMeters, GetMetersWithOutSeconds } from "./templates";

function animationCounter(
  startNum,
  seconds,
  delay,
  duration,
  step,
  divId,
  handleValue,
  classes,
  type
) {
  let resultWidth = 0;
  let { endNum, color } = getSpeedValueBySeconds(seconds, classes, type);
  let myinterval = setInterval(function () {
    // console.log("my intervel=", resultWidth);
    startNum = startNum + step;
    if (startNum <= endNum) {
      resultWidth = startNum;
    } else {
      clearInterval(myinterval);
      handleValue(resultWidth);
    }
  }, delay);
  return color;
}

function getSpeedValueBySeconds(seconds, classes, type) {
  let x = 0;
  let y = 0;
  let a = 0;
  let b = 0;
  let defaultValue = 0;
  let endNum = 0;
  let color = classes.meterText1;
  if (seconds <= 3) {
    x = 0;
    y = 15;
    a = 0;
    b = 3;
    defaultValue = 8;
    color = classes.meterText1;
  } else if (seconds > 3 && seconds <= 5) {
    x = 16;
    y = 35;
    a = 3;
    b = 5;
    defaultValue = 24;
    color = classes.meterText2;
  } else if (seconds > 5 && seconds <= 8) {
    x = 36;
    y = 60;
    a = 5;
    b = 8;
    defaultValue = 44;
    color = classes.meterText3;
  } else if (seconds > 8 && seconds <= 30) {
    x = 61;
    y = 95;
    a = 8;
    b = 30;
    defaultValue = 69;
    color = classes.meterText4;
  } else {
    endNum = 98;
    color = classes.meterText5;
  }
  if (endNum == 0) {
    endNum =
      type == "1"
        ? defaultValue + ((y - x) / (b - a)) * (seconds - a) + 3.9
        : defaultValue + ((y - x) / (b - a)) * (seconds - a);
  }
  // image should have min 10% width
  if (endNum < 10) {
    endNum = 10;
  }
  return { endNum, color };
}

class SpeedMeters extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      desktopSpeedWidth: 0,
      mobileSpeedWidth: 0,
    };
  }

  componentDidMount() {
    const { type } = this.props;
    // const mobileTextColor = animationCounter(1, this.props.mobilePageLoadTime, 50, 5000, 1, "pageSpeedDesktopIndicator", this.handleMobileValue);
    const { classes } = this.props;
    const mobileTextColor = animationCounter(
      1,
      this.props.mobilePageLoadTime,
      50,
      5000,
      1,
      "pageSpeedDesktopIndicator",
      this.handleMobileValue,
      classes,
      type
    );
    const desktopTextColor = animationCounter(
      1,
      this.props.desktopPageLoadTime,
      50,
      5000,
      1,
      "pageSpeedDesktopIndicator",
      this.handleDesktopValue,
      classes,
      type
    );
    this.setState({
      mobileTextColor: mobileTextColor,
      desktopTextColor: desktopTextColor,
    });
  }

  handleMobileValue = (mobileSpeedWidth) => {
    this.setState({ mobileSpeedWidth: mobileSpeedWidth });
  };
  handleDesktopValue = (desktopSpeedWidth) => {
    this.setState({ desktopSpeedWidth: desktopSpeedWidth });
  };

  render() {
    const { classes, type } = this.props;
    const {
      desktopSpeedWidth,
      mobileSpeedWidth,
      mobileTextColor,
      desktopTextColor,
    } = this.state;
    return (
      <Grid>
        {type != "1" ? (
          <Grid container spacing={2}>
            <Grid item md={type == 1 ? 12 : 6} sm={12} xs={12}>
              <Paper className={classes.paper}>
                <div className={classes.padding2X}>
                  <Typography
                    variant={"subheading"}
                    className={classes.montserrat}
                    color={"textSecondary"}
                  >
                    <b>How fast your page is loading on Mobile:</b>
                  </Typography>
                  <GetMeters
                    classes={classes}
                    speedScore={mobileSpeedWidth}
                    secounds={this.props.mobilePageLoadTime}
                    textColor={mobileTextColor}
                    type={type}
                  />
                  <br />
                  <br />
                </div>
              </Paper>
            </Grid>
            <Grid item md={type == 1 ? 12 : 6} sm={12} xs={12}>
              <Paper className={classes.paper}>
                <div className={classes.padding2X}>
                  <Typography
                    variant={"subheading"}
                    className={classes.montserrat}
                    color={"textSecondary"}
                  >
                    <b>How fast your page is loading on Desktop:</b>
                  </Typography>
                  <GetMeters
                    classes={classes}
                    speedScore={desktopSpeedWidth}
                    secounds={this.props.desktopPageLoadTime}
                    textColor={desktopTextColor}
                    type={type}
                  />
                  <br />
                  <br />
                </div>
              </Paper>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={2}>
            <Grid item md={12} sm={12} xs={12}>
              <Paper className={classes.paper}>
                <div className={classes.padding2X}>
                  <Grid container justifyContent={"space-between"}>
                    <Grid item>
                      <Typography
                        variant={"subheading"}
                        className={classes.montserrat}
                        color={"textSecondary"}
                        style={{ fontSize: "0.9rem" }}
                      >
                        <b>How fast your page is loading on Mobile:</b>
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        className={classes.meterSection}
                        color={"textSecondary"}
                      >
                        {this.props.mobilePageLoadTime + "s"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <GetMetersWithOutSeconds
                    classes={classes}
                    speedScore={mobileSpeedWidth}
                    secounds={this.props.mobilePageLoadTime}
                    textColor={mobileTextColor}
                    type={type}
                  />
                  <br />
                  <br />
                </div>
              </Paper>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Paper className={classes.paper}>
                <div className={classes.padding2X}>
                  <Grid container justifyContent={"space-between"}>
                    <Grid item>
                      <Typography
                        variant={"subheading"}
                        className={classes.montserrat}
                        color={"textSecondary"}
                        style={{ fontSize: "0.9rem" }}
                      >
                        <b>How fast your page is loading on Desktop:</b>
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        className={classes.meterSection}
                        color={"textSecondary"}
                      >
                        {this.props.desktopPageLoadTime + "s"}
                      </Typography>
                    </Grid>
                  </Grid>

                  <GetMetersWithOutSeconds
                    classes={classes}
                    speedScore={desktopSpeedWidth}
                    secounds={this.props.desktopPageLoadTime}
                    textColor={desktopTextColor}
                    type={type}
                  />
                  <br />
                  <br />
                </div>
              </Paper>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  }
}

SpeedMeters.propTypes = {
  classes: PropTypes.any,
  speedScore: PropTypes.number,
  speedScore1: PropTypes.number,
};
export default SpeedMeters;
