import React, { PureComponent, Fragment } from "react";
import {
  Card,
  Grid,
  Paper,
  Typography,
  CircularProgress,
  CardContent,
  Button,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import icons from "../components/icons";
import ScoreCard from "../components/siteGrader/scoreCard";
import DetailedReportForm from "../components/siteGrader/detailedReportForm";
import pointer from "../assets/img/speedDropIcon.png";
import pointerBlack from "../assets/img/speedDropBlackIcon.png";
import {
  getsSiteGraderResult,
  getsSiteGraderInternalResult,
  siteGraderReset,
  seoReportStatusUpdation,
} from "../actions/index";
import { connect } from "react-redux";
import SpeedMeters from "../components/siteGrader/speedingMeters";
import classNames from "classnames";
import green from "@mui/material/colors/green";
import Footer from "../components/footer";
import ErrorsTrafficPaidSearch from "../components/siteGrader/errorsTrafficPaidSearch";
import { IconAndName, ReportTitle } from "../components/siteGrader/templates";
import Mobile from "../components/internalSiteGrader/Mobile";
import PackagesSection from "../components/internalSiteGrader/PackagesSection";
import { ErrorTypography } from "../components/typography";
import SeoServicePageInternal from "./seo-service-page-internal";
import DetailedReport from "../components/internalSiteGrader/DetailedReport";
import { OutLinedBlackBtn } from "../components/buttons";
import { createHashHistory } from "history";
import Checkbox from "@mui/material/Checkbox";
import { getCardType } from "../components/SeoServices/template";
import { onDemandPaymentForActiveUser } from "../actions";
import Snackbar from "../components/snackbars";
import ErrorPage from "../components/internalSiteGrader/ErrorPage";
import { DASHBOARD_PAGE_TRACK, SITE_GRADER_PAGE } from "../actions/actionTypes";

let moment = require("moment");

class SiteGrader extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      domainUrl: null,
      seoReportStatus:
        this.props.siteGrader.seoReportStatus == true ? "1" : "0",
      alert: false,
      seoReportStatusLoading: false,
    };
  }

  handleClose = () => {
    // this.setState({alert: false, errorMessage: ""});
    this.setState({ alert: false });
  };

  componentDidMount() {
    const { siteGrader } = this.props;
    this.props.siteGraderPageTrack(this.props.match.params.type);
    if (
      siteGrader &&
      siteGrader.siteGraderScore == 0 &&
      this.props.match.params.type != 1
    ) {
      this.props.getSiteGraderResult(
        this.props.match.params.id,
        this.props.match.params.url
      );
    } else if (this.props.match.params.type == 1) {
      this.props.getsSiteGraderInternalResult();
    }
  }

  handleRefresh = async () => {
    console.log("handleRefresh");
    const { siteGrader } = this.props;
    await this.setState({ domainUrl: siteGrader.url });
    this.setState({ isLoading: true });
    await this.props.siteGraderReset();
    let path = `/site-grader-refresh/${this.state.domainUrl}`;
    let history = createHashHistory();
    history.push(path);
  };

  handleChange = async (event) => {
    const { siteGrader } = this.props;
    // await this.setState({seoReportStatus: !this.state.seoReportStatus});
    this.setState({ seoReportStatusLoading: true });
    const response = await this.props.seoReportStatusUpdation(
      !this.props.siteGrader.seoReportStatus,
      siteGrader.clientId
    );
    this.setState({ seoReportStatusLoading: false });
    await this.setState({ alert: true });
  };

  render() {
    const { classes, siteGrader } = this.props;
    const { type } = this.props.match.params;
    const {
      isLoading,
      redirectedURL,
      lxrSeoDomainURL,
      serverError,
      clientUpdateCheckError,
    } = this.props.siteGrader;
    const mobilePageLoadTime =
      this.props.siteGrader.speed != null
        ? this.props.siteGrader.speed.mobilePageLoadTime
        : 0;
    const desktopPageLoadTime =
      this.props.siteGrader.speed != null
        ? this.props.siteGrader.speed.desktopPageLoadTime
        : 0;
    const siteGraderScore =
      this.props.siteGrader != null ? this.props.siteGrader.siteGraderScore : 0;
    const domainId =
      this.props.siteGrader != null ? this.props.siteGrader.domainId : 0;
    console.log("siteGrader=", siteGrader);
    const fetchDate =
      this.props.siteGrader != null && this.props.siteGrader.fetchDate
        ? this.props.siteGrader.fetchDate
        : 0;
    console.log("type=", type);
    console.log("props=", this.props);
    console.log("params=", this.props.match.params);
    let today = moment().format("YYYY-MM-DD");
    console.log(
      "condition=",
      moment(today).diff(moment(fetchDate).format("YYYY-MM-DD"))
    );
    return (
      <Fragment>
        {/*<ErrorPage handleRefresh={this.handleRefresh}/>*/}
        {serverError != null && serverError != "" ? (
          <Grid
            container
            justifyContent={"center"}
            alignItems={"center"}
            style={{ height: "40em" }}
          >
            {serverError == "Invalid Client Website URL or No Report Found" ? (
              <Grid item>
                <ErrorPage
                  siteGrader={siteGrader}
                  handleRefresh={this.handleRefresh}
                />
              </Grid>
            ) : (
              <Grid item>
                <ErrorTypography>{serverError}</ErrorTypography>
              </Grid>
            )}
          </Grid>
        ) : (
          <Card className={classes.customCard}>
            {isLoading ? (
              <Grid
                container
                justifyContent={"center"}
                alignItems={"center"}
                style={{ height: "40em" }}
              >
                <Grid item>
                  <CircularProgress />
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={2} alignItems={"center"}>
                <Grid
                  item
                  md={type && type == "1" ? 6 : 12}
                  sm={12}
                  className={type && type == "1" ? "flex" : ""}
                >
                  <Typography
                    component={"h5"}
                    variant={"h5"}
                    color={"textSecondary"}
                    className={classes.mainTitle}
                  >
                    <b>YOUR WEBSITE’S SEO SCORECARD</b>
                  </Typography>
                  <br />
                </Grid>

                {type && type == "1" ? (
                  <Grid item md={6} sm={12}>
                    <Grid
                      container
                      justifyContent={"flex-end"}
                      alignItems={"center"}
                    >
                      <Grid item>
                        <Typography
                          variant={"subhead"}
                          color={"textPrimary"}
                          className={classes.timeText}
                        >
                          <b>
                            Website analyzed on :{" "}
                            {moment(fetchDate).format("LLLL")}
                          </b>
                        </Typography>
                      </Grid>
                      <Grid item>
                        <OutLinedBlackBtn
                          disabled={
                            (siteGrader.userId != null &&
                              siteGrader.userId == 2) ||
                            moment(today).diff(
                              moment(fetchDate).format("YYYY-MM-DD")
                            ) == 0
                          }
                          onClick={this.handleRefresh}
                        >
                          Refresh
                        </OutLinedBlackBtn>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}

                <Grid item md={12} className={"growable"}>
                  <ReportTitle
                    siteGraderScore={siteGraderScore}
                    redirectedURL={redirectedURL}
                    classes={classes}
                  />
                  <br />
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <Paper
                    className={classNames(
                      classes.padding2X,
                      classes.montserrat,
                      classes.paper
                    )}
                    style={{ minHeight: "55vh" }}
                  >
                    <Grid
                      container
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Grid item md={12} sm={12} xs={12}>
                        <Typography
                          align={"left"}
                          className={classes.montserrat}
                          color={"textSecondary"}
                        >
                          <b>PREVIEW</b>
                        </Typography>
                      </Grid>
                      <Grid item>
                        <img
                          className={classes.img}
                          src={`${lxrSeoDomainURL}/screenshot/${domainId}_desk.png`}
                          height={300}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <ScoreCard data={siteGrader} />
                </Grid>
                <Grid item className={"fullWidth"}>
                  <ErrorsTrafficPaidSearch
                    classes={classes}
                    siteGrader={siteGrader}
                    type={type}
                    // domainUrl={this.props.match.params.url}
                  />
                </Grid>

                <Grid
                  item
                  md={type && type != "1" ? "auto" : 4}
                  className={"fullWidth"}
                >
                  <SpeedMeters
                    classes={classes}
                    mobilePageLoadTime={mobilePageLoadTime}
                    desktopPageLoadTime={desktopPageLoadTime}
                    type={type}
                  />
                </Grid>

                {type && type == "1" ? (
                  <Grid item md={8}>
                    <Mobile siteGrader={siteGrader} />
                  </Grid>
                ) : null}

                {type && type != "1" ? (
                  <Grid item md={12}>
                    <DetailedReportForm
                      classes={classes}
                      domainId={this.props.match.params.id}
                    />
                    <br />
                  </Grid>
                ) : null}
                {type && type == "1" ? (
                  <Grid item md={12}>
                    <DetailedReport siteGrader={siteGrader} />
                  </Grid>
                ) : null}
                {type && type == "1" ? (
                  <Grid item md={12}>
                    <Grid item md={12}>
                      <Grid
                        container
                        justifyContent={"space-around"}
                        alignItems={"center"}
                        style={{ minHeight: "20vh" }}
                      >
                        <Grid item md={12}>
                          {clientUpdateCheckError != null &&
                          clientUpdateCheckError != "" ? (
                            <ErrorTypography>
                              {clientUpdateCheckError}
                            </ErrorTypography>
                          ) : null}
                          {this.state.seoReportStatusLoading == true ? (
                            <Typography align={"center"}>
                              <CircularProgress />
                            </Typography>
                          ) : (
                            <Typography
                              variant={"subhead"}
                              className={classes.reportContentStyle}
                            >
                              Do you want this insightful SEO audit report
                              delivered to you monthly? &nbsp;&nbsp;
                              <Checkbox
                                checked={this.props.siteGrader.seoReportStatus}
                                // onChange={event => this.setState({ dense: event.target.checked })}
                                onChange={(event) => this.handleChange(event)}
                                value={"SendMeMonthlyReport"}
                                label="SEND ME MONTHLY REPORT"
                                className={classes.checkBoxStyles}
                                color={"#4AA459"}
                              />
                              <span className={classes.checkBoxContentStyle}>
                                SEND ME MONTHLY REPORT
                              </span>
                            </Typography>
                          )}
                          <Snackbar
                            verticalAlign={"bottom"}
                            horizontalAlign={"left"}
                            open={this.state.alert}
                            // duration={3000}
                            handleClose={this.handleClose}
                            onClose={this.handleClose}
                            variant={
                              clientUpdateCheckError != "" ? "error" : "success"
                            }
                            message={
                              clientUpdateCheckError != ""
                                ? "Something Went Wrong, Please Try again."
                                : "Monthly report status updated Successfully."
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}
                {/* {type && type == "1" ? <Grid item md={12}>
                                    <SeoServicePageInternal/>
                                    </Grid> : null}*/}
              </Grid>
            )}
            <br />
          </Card>
        )}
        {/*{showHeader &&
                                    <Card>
                                    <CardContent style={{paddingBottom: 0, padding: 0}}>
                                    <Paper style={{boxShadow: "unset"}}>
                                    <Footer/>
                                    </Paper>
                                    </CardContent>
                                    </Card>
                                    }*/}
      </Fragment>
    );
  }
}

const SiteGraderStyles = (theme) => ({
  customCard: {
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
    boxShadow: "unset",
    backgroundColor: theme.palette.graderBg,
  },
  paper: {
    boxShadow: "unset",
  },
  mainTitle: {
    fontWeight: "800",
    fontFamily: "Montserrat",
  },
  midCard: {
    minHeight: "58.5vh",
    boxShadow: "unset",
  },
  cardTitle: {
    fontFamily: "Montserrat",
    color: theme.palette.text.secondary,
    fontSize: "1rem",
    fontWeight: "bold",
  },
  padding2X: { padding: theme.spacing(1) },
  padding3X: { padding: theme.spacing(3) },
  montserrat: { fontFamily: "Montserrat" },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    // width: 200,
  },
  button: {
    margin: theme.spacing(1),
  },
  headTitle: {
    // color: theme.palette.orangeColor,
    fontSize: 30,
    fontFamily: "Montserrat",
  },
  caption: {
    fontFamily: "Lato",
    fontSize: 16,
  },
  middlePapers: {
    minHeight: "40.5vh",
    maxHeight: "40.5vh",
    boxShadow: "unset",
  },
  titleScore: {
    color: theme.palette.graderGraphColors.text,
    fontFamily: "Montserrat",
  },
  pointer: {
    backgroundImage: "url(" + pointer + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    width: "68px",
    height: "70px",
    marginBottom: "-28px",
    zIndex: 999,
    // backgroundPositionX: 24,
  },
  pointerInternal: {
    backgroundPosition: "center",
    backgroundSize: "contain",
  },
  pointerBlack: {
    backgroundImage: "url(" + pointerBlack + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    width: "68px",
    height: "70px",
    marginBottom: "-28px",
    zIndex: 999,
    // backgroundPositionX: 24,
  },
  pointerInvalid: {
    // backgroundImage: "url(" + pointer + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    width: "68px",
    height: "70px",
    marginBottom: "-28px",
    zIndex: 999,
    // backgroundPositionX: 24,
  },
  pointerText: {
    marginTop: 18,
    marginLeft: "-7px",
    textAlign: "center",
    fontFamily: "Montserrat",
    fontWeight: "bold",
    backgroundPositionX: "center",
  },
  pointerTextInvalid: {
    marginTop: 18,
    marginLeft: "-7px",
    textAlign: "center",
    fontFamily: "Montserrat",
    fontWeight: "bold",
    backgroundPositionX: "center",
    color: theme.palette.common.white,
  },
  headPointer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  meter_h1: {
    height: 53,
  },
  meter_h2: {
    height: 20,
  },
  meter1: {
    background: theme.palette.speedMeter.green,
  },
  meterText1: {
    color: theme.palette.speedMeter.green,
  },
  meter2: {
    background: theme.palette.speedMeter.lightGreen,
  },
  meterText2: {
    color: theme.palette.speedMeter.lightGreen,
  },
  meter3: {
    background: theme.palette.speedMeter.lightCream,
  },
  meterText3: {
    color: theme.palette.speedMeter.lightCream,
  },
  meter4: {
    background: theme.palette.speedMeter.red,
  },
  meterText4: {
    color: theme.palette.speedMeter.red,
  },
  meter5: {
    background: theme.palette.speedMeter.lightRed,
  },
  meterText5: {
    color: theme.palette.speedMeter.lightRed,
  },
  scaleText: {
    color: theme.palette.common.white,
    fontWeight: "500",
    fontFamily: "Montserrat",
    // paddingRight: 5,
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    position: "relative",
  },
  red: {
    color: theme.palette.graderGraphColors.red,
  },
  yellow: {
    color: theme.palette.graderGraphColors.yellow,
  },
  titleYellow: {
    color: theme.palette.darKYellow,
  },
  green: {
    color: theme.palette.graderGraphColors.green,
  },
  textDec: {
    textDecoration: "unset",
  },
  lightGreen: {
    color: theme.palette.speedMeter.lightGreen,
  },
  darkRed: {
    color: theme.palette.speedMeter.red,
  },
  lightRedPinkBg: {
    backgroundColor: theme.palette.lightRedPink,
    fontSize: 16,
    margin: 0,
    width: 280,
  },
  titleAlign: {
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
    [theme.breakpoints.up("sm")]: {
      textAlign: "right",
    },
  },
  titleAlignLeft: {
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
    [theme.breakpoints.up("sm")]: {
      textAlign: "left",
    },
  },
  icon: {
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      textAlign: "left",
    },
  },
  img: {
    maxWidth: "100%",
    maxHeight: "100%",
    width: "100%",
  },
  formSection: {
    minHeight: 110,
  },
  inputField: {
    fontWeight: "bold",
    fontFamily: "Montserrat",
    color: theme.palette.text.secondary,
  },
  buttonTrue: {
    color: theme.palette.common.white,
    background: theme.palette.graderGraphColors.green,
    padding: "0px 0px",
    fontSize: "0.875rem",
    minWidth: 78,
    boxSizing: "border-box",
    lineHeight: 1.75,
    fontWeight: "500",
    borderRadius: 4,
    fontFamily: "Lato",
    textAlign: "center",
  },
  buttonFalse: {
    color: theme.palette.common.white,
    background: theme.palette.graderGraphColors.red,
    padding: "0px 0px",
    fontSize: "0.875rem",
    minWidth: 78,
    boxSizing: "border-box",
    lineHeight: 1.75,
    fontWeight: "500",
    borderRadius: 4,
    fontFamily: "Lato",
    textAlign: "center",
  },
  checkedStyle: {
    color: "#4CAF50",
    fontSize: "1.5rem",
    padding: "0 2rem",
  },
  crossStyle: {
    color: "#EB3223",
    fontSize: "1.5rem",
    padding: "0 2rem",
  },
  timeText: {
    fontSize: 14,
    fontFamily: "Lato",
  },
  reportContentStyle: {
    color: "#9BA0A6",
    fontWeight: "normal",
    fontSize: 16,
    fontFamily: "Lato",
    marginLeft: "2%",
  },
  checkBoxContentStyle: {
    color: "#9BA0A6",
    fontWeight: "normal",
    fontSize: 15,
    fontFamily: "Lato",
    verticalAlign: -2,
    marginLeft: -8,
  },
  checkBoxStyles: {
    color: "#4AA459",
    border: "#4AA459",
  },
  meterSection: {
    fontFamily: "Montserrat",
    fontSize: "1.2rem",
    fontWeight: "500",
    important: "true",
  },
});

const mapStateToProps = (state) => ({
  siteGrader: state.siteGrader,
});
const mapDispatchToProps = (dispatch) => ({
  getSiteGraderResult: (domainName, url) =>
    dispatch(getsSiteGraderResult(domainName, url)),
  getsSiteGraderInternalResult: () => dispatch(getsSiteGraderInternalResult()),
  siteGraderReset: () => dispatch(siteGraderReset()),
  seoReportStatusUpdation: (seoReportStatus, clientId) =>
    dispatch(seoReportStatusUpdation(seoReportStatus, clientId)),
  siteGraderPageTrack: (reportType) =>
    dispatch({
      type: SITE_GRADER_PAGE,
      reportType,
    }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(SiteGraderStyles)(SiteGrader));
