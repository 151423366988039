import React from "react";
import { Grid, Typography, Paper } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import { iconWithPara } from "./templates";
import icons from "../icons";

const CustomPaper = withStyles((theme) => ({
  root: {
    boxShadow: "unset",
    background: "none",
  },
}))(Paper);

const AccountAuditStyles = (theme) => ({
  paddingLeftRight: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  paper: {
    boxShadow: "unset",
  },
});

function AccountsAudit(props) {
  const { classes } = props;
  return (
    <CustomPaper>
      {/*<CustomPaper className={"homeBg"}>*/}
      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        style={{ minHeight: "50vh" }}
        className={classes.paddingLeftRight}
      >
        <Grid item md={6}>
          {iconWithPara(
            icons.gaugeSm(),
            "1,247",
            "Google Ads Accounts Audited",
            {}
          )}
        </Grid>
        <Grid item md={6}>
          {iconWithPara(
            icons.groupHand(),
            "8%",
            "Incremental new customer opportunities identified",
            {}
          )}
        </Grid>
      </Grid>
    </CustomPaper>
  );
}

export default withStyles(AccountAuditStyles)(AccountsAudit);
