import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  TextField,
  Typography,
  Card,
  Button,
  CardContent,
  Divider,
  RadioGroup,
  Radio,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import MenuItem from "@mui/material/MenuItem";
import { DemoInputField, InputField } from "../input-forms";
import { PostButton } from "../buttons";
import { connect } from "react-redux";
import icons from "../icons";
import {
  emailValidation,
  saveSEOUserDetails,
  contactSupportForOnDemandServices,
  updateActiveStepSEODetails,
  updateSelectedSEOPackage,
  getUserPaymentInfo,
  onDemandPaymentForActiveUser,
} from "../../actions";
import InputMask from "react-input-mask";
import CircularProgress from "@mui/material/CircularProgress";
import green from "@mui/material/colors/green";
import { ErrorTypography } from "../typography";
import CustomDialog from "../dialog-generic";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Paper from "@mui/material/Paper";
import { getSteps, getCardType } from "./template";
import SeoStepper from "./seo-stepper";
import { Link, Redirect } from "react-router-dom";

import OrderSummary from "./OrderSummary";
import NumberFormat from "react-number-format";
import Footer from "../footer";
import * as _ from "lodash";
import FormControlLabel from "@mui/material/FormControlLabel";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { packagesList } from "../internalSiteGrader/templates";

const styles = (theme) => ({
  container: {
    /* display: 'flex',
         flexWrap: 'wrap',*/
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
    marginTop: 5,
  },
  numberField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
    border: 0,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  paper: {
    boxShadow: "unset",
  },
  mainContainer: {
    boxShadow: "unset",
    background: theme.palette.errorBg,
  },
  mainPaper: {
    boxShadow: "unset",
    borderRadius: 0,
  },
  stepLabel: {
    fontFamily: "Lato",
    fontSize: 18,
    fontWeight: "bold,",
  },
  cardContent: {
    padding: 0,
  },
  backBtn: {
    padding: 0,
    fontFamily: "Montserrat",
    fontWeight: "bold",
  },
  listContent: {
    fontFamily: "Montserrat",
    color: "#6F7075",
    fontSize: 16,
  },
  reviewContent: {
    fontFamily: "Montserrat",
    fontSize: 16,
  },
  paymentTitle: {
    color: theme.palette.summaryText,
    fontSize: 15,
    fontFamily: "Lato",
  },
  cardType: {
    color: theme.palette.summaryText,
    fontSize: 15,
    fontFamily: "Lato",
  },
  toolTip: {
    color: theme.palette.hyperLink,
    fontSize: 12,
    fontFamily: "Lato",
  },
  backLink: {
    textDecoration: "unset",
    color: theme.palette.text.secondary,
  },
});
const packContent = (packageId) => {
  let packageContent = "";
  if (packageId == 1) {
    packageContent = ["Site Health Overview Report", "1 Hour Consulting"];
  } else if (packageId == 2) {
    packageContent = [
      "Site Health Overview Report",
      "Indexability and Site Crawability Audit",
      "Mobile First Audit",
      "Meta Tags Audit",
      "1 Hour Consultation",
    ];
  } else {
    packageContent = [
      "Site Health Overview Report",
      "Indexability and Site Crawability Audit",
      "Mobile First Audit",
      "Meta Tags Audit",
      "Keywords Report",
      "Site Architecture Audit",
      "Backlink Report",
      "2 Hour Consultation",
    ];
  }
  return packageContent;
};

const CustomListItem = withStyles((theme) => ({
  root: {
    paddingTop: 1,
    paddingBottom: 1,
    alignItems: "baseline",
    fontFamily: "Montserrat",
    fontSize: 16,
  },
}))(ListItem);
const CustomListItemText = withStyles((theme) => ({
  root: {
    color: "inherit",
    fontSize: 16,
    fontFamily: "Montserrat",
  },
}))(ListItemText);

export const CustomStepLabel = withStyles((theme) => ({
  label: {
    fontFamily: "Lato",
    fontSize: 16,
    fontWeight: "bold",
    color: theme.palette.summaryText,
  },
  active: {
    fontFamily: "Lato",
    fontSize: 16,
    fontWeight: "bold !important",
    color: theme.palette.summaryText + "!important",
  },
  completed: {
    fontFamily: "Lato",
    fontSize: 16,
    fontWeight: "bold",
    color: theme.palette.summaryText,
  },
}))(StepLabel);

function getStepUI(step, classes) {
  const { isEmailValidLoading, showForm } = this.state;
  const { activeUser, cardDetails, cardValue } = this.props;
  const ListYears = [
    "2020",
    "2021",
    "2022",
    "2023",
    "2024",
    "2025",
    "2026",
    "2027",
    "2028",
    "2029",
    "2030",
    "2031",
    "2032",
    "2033",
    "2034",
    "2035",
    "2036",
  ];
  console.log("cardValue", cardValue);
  switch (step) {
    case 0:
      return (
        <Fragment>
          <Grid item md={12}>
            <TextField
              fullWidth
              id="standard-name"
              label="Name"
              className={classes.textField}
              value={this.state.name}
              onChange={this.handleChange("name")}
              margin="normal"
              error={this.state.nameError != ""}
              helperText={this.state.nameError}
              disabled={showForm}
            />
          </Grid>
          <Grid item md={12}>
            <TextField
              fullWidth
              id="standard-name"
              label="Email Address"
              className={classes.textField}
              value={this.state.emailId}
              onChange={this.handleChange("emailId")}
              margin="normal"
              error={
                this.state.emailIdError != "" ||
                this.state.emailExistsError != null
              }
              helperText={
                this.state.emailIdError || this.state.emailExistsError
              }
              disabled={showForm}
            />
          </Grid>
          <Grid item md={12}>
            {/*<TextField
                            fullWidth
                            id="standard-name"
                            label="Phone Number"
                            className={classes.textField}
                            value={this.state.phoneNumber}
                            onChange={this.handleChange('phoneNumber')}
                            margin="normal"
                            error={this.state.phoneNumberError != ""}
                            helperText={this.state.phoneNumberError}
                        />*/}
            <NumberFormat
              fullWidth
              margin="normal"
              name="phoneNumber"
              label="Phone Number"
              className={classes.numberField}
              value={this.state.phoneNumber}
              onValueChange={async (values) => {
                const { formattedValue, value } = values;
                await this.setState({ phoneNumber: value });
              }}
              error={this.state.phoneNumberError != ""}
              helperText={this.state.phoneNumberError}
              customInput={TextField}
              format="###-###-####"
              mask="_"
            />
          </Grid>
          {/* {showForm == false ? <Grid item>
                        <div className={classes.wrapper}>
                            <Button type={"button"}
                                    color={"primary"}
                                    variant="contained"
                                // className={buttonClassname}
                                    disabled={isEmailValidLoading}
                                    onClick={this.handleEmailValidationSubmit}>Next</Button>
                            {isEmailValidLoading &&
                            <CircularProgress size={24} className={classes.buttonProgress}/>}
                        </div>
                    </Grid> : null}*/}
          <br />
        </Fragment>
      );
    case 1:
      return (
        <Fragment>
          {activeUser ? (
            <Grid item>
              <Grid container direction={"column"} alignItems={"flex-start"}>
                {_.map(cardDetails, (cardName, paymentDetailId) => {
                  return (
                    <Grid item key={paymentDetailId}>
                      <RadioGroup
                        aria-label="gender"
                        name="cardValue"
                        value={this.state.cardValue}
                        onChange={this.handleChange("cardValue")}
                      >
                        <FormControlLabel
                          value={paymentDetailId}
                          control={<Radio color="primary" />}
                          label={`Use Credit Card  ${cardName}`}
                        />
                      </RadioGroup>
                    </Grid>
                  );
                })}
              </Grid>
              {this.state.cardValueError != "" ? (
                <ErrorTypography>{this.state.cardValueError}</ErrorTypography>
              ) : null}
            </Grid>
          ) : (
            <Fragment>
              <Grid item md={12}>
                <Typography className={classes.paymentTitle}>
                  Add new payment method
                </Typography>
                {/*&nbsp;&nbsp;&nbsp;<span className={classes.toolTip}>Why do we need this for a free trial?</span>*/}
              </Grid>
              <Grid md={12}>
                <Divider />
              </Grid>
              <Grid item md={12}>
                <TextField
                  fullWidth
                  id="standard-name"
                  label="Name of the holder"
                  className={classes.textField}
                  value={this.state.fullName}
                  onChange={this.handleChange("fullName")}
                  margin="normal"
                  error={this.state.fullNameError != ""}
                  helperText={this.state.fullNameError}
                />
              </Grid>
              <Grid item md={12}>
                <NumberFormat
                  margin="normal"
                  name="cardNo"
                  label="Card Number"
                  value={this.state.cardNo}
                  onValueChange={async (values) => {
                    const { formattedValue, value } = values;
                    await this.setState({ cardNo: value });
                  }}
                  className={classes.textField}
                  error={this.state.cardNoError != ""}
                  helperText={this.state.cardNoError}
                  customInput={TextField}
                  format="####-####-####-####"
                  mask="_"
                />
              </Grid>
              <Grid item md={12}>
                <Grid container spacing={2}>
                  <Grid item md={3}>
                    <TextField
                      id="standard-name"
                      label="Month"
                      select
                      fullWidth
                      margin="normal"
                      value={this.state.expireMonth}
                      onChange={this.handleChange("expireMonth")}
                      name="expireMonth"
                      error={this.state.expireMonthError != ""}
                      helperText={this.state.expireMonthError}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                    >
                      <MenuItem key={1} value="">
                        <em>Month</em>
                      </MenuItem>
                      <MenuItem value={"1"}>{1}</MenuItem>
                      <MenuItem value={"2"}>{2}</MenuItem>
                      <MenuItem value={"3"}>{3}</MenuItem>
                      <MenuItem value={"4"}>{4}</MenuItem>
                      <MenuItem value={"5"}>{5}</MenuItem>
                      <MenuItem value={"6"}>{6}</MenuItem>
                      <MenuItem value={"7"}>{7}</MenuItem>
                      <MenuItem value={"8"}>{8}</MenuItem>
                      <MenuItem value={"9"}>{9}</MenuItem>
                      <MenuItem value={"10"}>{10}</MenuItem>
                      <MenuItem value={"11"}>{11}</MenuItem>
                      <MenuItem value={"12"}>{12}</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item md={3}>
                    <TextField
                      id="standard-name"
                      label="Year"
                      select
                      fullWidth
                      margin="normal"
                      value={this.state.expireYear}
                      onChange={this.handleChange("expireYear")}
                      name="expireYear"
                      error={this.state.expireYearError != ""}
                      helperText={this.state.expireYearError}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                    >
                      <MenuItem key={1} value="">
                        <em>Year</em>
                      </MenuItem>
                      {ListYears.map((value, i) => {
                        return (
                          <MenuItem key={i} value={value}>
                            {value}
                          </MenuItem>
                        );
                      })}
                      ;
                    </TextField>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={12} style={{ paddingBottom: 10 }}>
                <TextField
                  fullWidth
                  id="standard-name"
                  label="CVV"
                  className={classes.textField}
                  value={this.state.cvv}
                  onChange={this.handleChange("cvv")}
                  name="cvv"
                  error={this.state.cvvError != ""}
                  helperText={this.state.cvvError}
                  margin="normal"
                />
              </Grid>
            </Fragment>
          )}
        </Fragment>
      );
    case 2:
      return (
        <Grid item>
          {/*<Typography className={classes.reviewContent}>Try out different ad text to see what brings in the most customers,
        and learn how to enhance your ads using features like ad extensions.
        If you run into any problems with your ads, find out how to tell if
        they're running and how to resolve approval issues.
                </Typography>*/}
          <Typography className={classes.listContent}>
            {packContent(this.state.selectedPlanId).map((value, index) => {
              return (
                <CustomListItem className={classes.listContent}>
                  <ListItemIcon style={{ margin: 0 }}>
                    {icons.dotGrey}
                  </ListItemIcon>
                  <CustomListItemText>{value}</CustomListItemText>
                </CustomListItem>
              );
            })}
          </Typography>
        </Grid>
      );
    default:
      return "Unknown step";
  }
}

function getButtons(activeStep, classes, steps) {
  console.log("loading", this.isLoading);
  const { activeUser, cardDetails } = this.props;
  switch (activeStep) {
    case 0:
      return (
        <div className={classes.wrapper}>
          <PostButton
            variant="contained"
            size={"large"}
            className={classes.button}
            onClick={this.handleEmailValidationSubmit}
          >
            {activeStep === steps.length - 1 ? "Finish" : "Proceed"}
          </PostButton>
          {(this.state.isLoading == true ||
            this.state.isEmailValidLoading == true) && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      );
    case 1:
      return (
        <Fragment>
          {activeUser != true ? (
            <PostButton
              variant="contained"
              size={"large"}
              onClick={this.handlePaymentValidationNext}
              className={classes.button}
            >
              ADD CREDIT CARD
            </PostButton>
          ) : (
            <PostButton
              variant="contained"
              size={"large"}
              onClick={this.handlePaymentActiveUserValidationNext}
              className={classes.button}
            >
              Proceed
            </PostButton>
          )}
        </Fragment>
      );
    case 2:
      return (
        <form className={classes.container} onSubmit={this.handleSubmit}>
          <Grid item>
            <div className={classes.wrapper}>
              <PostButton
                variant="contained"
                type={"submit"}
                disabled={this.isLoading || this.isEmailValidLoading}
              >
                Submit
              </PostButton>
              {(this.state.isLoading == true ||
                this.state.isEmailValidLoading == true) && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </Grid>
        </form>
      );
    default:
      return (
        <Button
          variant="contained"
          color="primary"
          onClick={this.handleNext}
          className={classes.button}
        >
          {activeStep === steps.length - 1 ? "Finish" : "Next"}
        </Button>
      );
  }
}

class SeoServiceExternal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      activeStep: this.props.activeUser == true ? 1 : 0,
      emailId: this.props.match.params.emailId
        ? this.props.match.params.emailId
        : "",
      phoneNumber: "",
      cardNo: "",
      fullName: "",
      expireMonth: "",
      expireYear: "",
      cvv: "",
      nameError: "",
      emailIdError: "",
      phoneNumberError: "",
      cardNoError: "",
      fullNameError: "",
      expireMonthError: "",
      expireYearError: "",
      cvvError: "",
      cardType: "unknown",
      isEmailValidLoading: false,
      showForm: false,
      emailExistsError: null,
      isMailSupportSent: false,
      dialog: false,
      isFormSubmit: false,
      errorMessage: null,
      submitDialog: false,
      isLoading: false,
      stepsCompleted: new Set(),
      cardValue: null,
      cardValueError: null,
      selectedPlanId: this.props.match.params.packageId,
      isCardLoading: false,
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    const { activeUser } = this.props;
    if (activeUser == false) {
      this.setState({ isCardLoading: true });
      const response = await this.props.getUserPaymentInfo();
      if (response && response.cardDetails != null) {
        this.setState({ activeStep: 1 });
      }
      this.setState({ isCardLoading: false });
    }
    console.log("this.props.activeUser ? 1 : 0", this.props.activeUser ? 1 : 0);
    /*making default card selected*/
    console.log(
      "default card selected=",
      _.head(_.keys(this.props.cardDetails))
    );
    this.setState({ isCardLoading: true });
    await this.setState({ cardValue: _.head(_.keys(this.props.cardDetails)) });
    this.setState({ isCardLoading: false });
    console.log("state=", this.state.cardValue);
  }

  /* state = {
         activeStep: 0,
     };
 */
  handleNext = () => {
    this.props.updateActiveStepSEODetails(this.state.activeStep + 1);
    this.updateCompletedSteps();
    this.setState((state) => ({
      activeStep: state.activeStep + 1,
    }));
  };

  updateCompletedSteps = () => {
    this.setState((state) => {
      const stepsCompleted = new Set(state.stepsCompleted.values());
      stepsCompleted.add(state.activeStep);
      return {
        stepsCompleted,
      };
    });
  };

  updateRemoveCompletedSteps = () => {
    this.setState((state) => {
      const stepsCompleted = new Set(state.stepsCompleted.values());
      stepsCompleted.delete(state.activeStep);
      return {
        stepsCompleted,
      };
    });
  };

  handleBack = () => {
    this.props.updateActiveStepSEODetails(this.state.activeStep - 1);
    this.updateRemoveCompletedSteps();
    this.setState((state) => ({
      activeStep: state.activeStep - 1,
    }));
  };
  handleBackPage = async () => {
    await this.props.updateSelectedPackage(null);
  };

  handleReset = () => {
    this.props.updateActiveStepSEODetails(0);
    this.setState({
      activeStep: 0,
      stepsCompletedL: new Set(),
    });
  };

  handleChange = (name) => async (event) => {
    this.setState({ [name]: event.target.value });
    if (name === "cardNo") {
      this.setState({ cardType: getCardType(event.target.value) });
    }
  };
  handlePlanChange = (event) => {
    this.setState({ selectedPlanId: event.target.value });
  };
  handleSubmit = async (event) => {
    const { activeUser } = this.props;
    event.preventDefault();
    if (activeUser == true) {
      const { selectedPlanId, cardValue } = this.state;
      this.setState({ isLoading: true });
      const response = await this.props.onDemandPaymentForActiveUser(
        cardValue,
        selectedPlanId
      );
      if (
        response &&
        response.errorMessage != null &&
        response.errorMessage !== "success"
      ) {
        this.setState({
          isLoading: false,
          errorMessage: response.errorMessage,
          isFormSubmit: false,
        });
      } else {
        this.setState({
          isLoading: false,
          errorMessage: null,
          isFormSubmit: true,
          submitDialog: true,
        });
      }
    } else {
      const {
        emailId,
        phoneNumber,
        fullName,
        expireMonth,
        expireYear,
        cvv,
        selectedPlanId,
        cardType,
        cardNo,
      } = this.state;
      // let cardNo = this.state.cardNo.replace(/-|\s/g, "");
      this.setState({ isLoading: true });
      const domainUrl = this.props.match.params.domainUrl;
      const response = await this.props.saveSEOUserDetails({
        emailId,
        phoneNumber,
        cardNo,
        fullName,
        expireMonth,
        expireYear,
        cvv,
        selectedPlanId,
        cardType,
        domainUrl,
      });
      if (
        response &&
        response.errorMessage != null &&
        response.errorMessage !== "success"
      ) {
        this.setState({
          isLoading: false,
          errorMessage: response.errorMessage,
          isFormSubmit: false,
        });
      } else {
        this.setState({
          isLoading: false,
          errorMessage: null,
          isFormSubmit: true,
          submitDialog: true,
        });
      }
    }
  };
  handleEmailValidationSubmit = async (event) => {
    let error;
    error = this.accountDetailsValidation();
    console.log("The error value is: ", error);
    if (!error) {
      event.preventDefault();
      const { emailId } = this.state;
      await this.setState({
        isEmailValidLoading: true,
        emailExistsError: null,
      });
      const response = await this.props.emailValidation(emailId.trim());
      console.log("response=", response);
      if (response && response.isEmailExists == false) {
        this.props.updateActiveStepSEODetails(this.state.activeStep + 1);
        this.setState((state) => ({
          showForm: true,
          isEmailValidLoading: false,
          emailExistsError: null,
          activeStep: state.activeStep + 1,
        }));
        // this.updateCompletedSteps();
      } else {
        this.setState({
          isEmailValidLoading: false,
          emailExistsError: "Email Id already exists, Please Login to Proceed",
          isMailSupportSent: false,
        });
      }
    }
  };
  accountDetailsValidation = () => {
    let isError = false;
    const errors = {};
    this.setState({ emailExistsError: null });
    console.log("Validation started for account details functionality");
    if (this.state.name.length == 0) {
      isError = true;
      errors.nameError = "This field is required";
    } else {
      errors.nameError = "";
    }
    if (this.state.emailId.length == 0) {
      isError = true;
      errors.emailIdError = "This field is required";
    } else if (this.state.emailId.length > 0) {
      let result = this.state.emailId.match(
        /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
      );
      if (!result) {
        isError = true;
        errors.emailIdError = "Please enter valid email";
      } else {
        errors.emailIdError = "";
      }
    } else {
      errors.emailIdError = "";
    }
    if (this.state.phoneNumber.length == 0) {
      isError = true;
      errors.phoneNumberError = "This field is required";
    } else if (this.state.phoneNumber.length < 10) {
      isError = true;
      errors.phoneNumberError = "Phone Number should be minimum 10 digits";
    } else if (this.state.phoneNumber.length > 10) {
      isError = true;
      errors.phoneNumberError = "Phone Number should not exceed 10 digits";
    } else {
      errors.phoneNumberError = "";
    }
    this.setState({
      ...this.state,
      ...errors,
    });
    return isError;
  };
  validate = () => {
    let isError = false;
    const errors = {};
    console.log("Validation started for payment functionality");
    // TODO validations for payment
    if (this.state.cardNo.length == 0) {
      isError = true;
      errors.cardNoError = "This field is required";
    } else {
      errors.cardNoError = "";
    }
    if (this.state.fullName.length == 0) {
      isError = true;
      errors.fullNameError = "This field is required";
    } else {
      errors.fullNameError = "";
    }
    if (this.state.expireMonth.length == 0) {
      isError = true;
      errors.expireMonthError = "This field is required";
    } else {
      errors.expireMonthError = "";
    }
    if (this.state.expireYear.length == 0) {
      isError = true;
      errors.expireYearError = "This field is required";
    } else {
      errors.expireYearError = "";
    }
    if (this.state.cvv.length == 0) {
      isError = true;
      errors.cvvError = "This field is required";
    } else {
      errors.cvvError = "";
    }
    if (isError) {
      this.setState({
        ...this.state,
        ...errors,
      });
    }
    return isError;
  };

  handlePaymentValidationNext = () => {
    let error;
    error = this.validate();
    if (!error) {
      this.props.updateActiveStepSEODetails(this.state.activeStep + 1);
      this.updateCompletedSteps();
      this.setState((state) => ({
        activeStep: state.activeStep + 1,
      }));
    }
  };

  validateActiveUserCardRadio = () => {
    let isError = false;
    const errors = {};
    console.log("Validation started for payment active user functionality");
    // TODO validations for payment
    if (this.state.cardValue.length == 0) {
      isError = true;
      errors.cardValueError = "This field is required";
    } else {
      errors.cardValueError = "";
    }
  };
  handlePaymentActiveUserValidationNext = () => {
    let error;
    error = this.validateActiveUserCardRadio();
    if (!error) {
      this.props.updateActiveStepSEODetails(this.state.activeStep + 1);
      this.updateCompletedSteps();
      this.setState((state) => ({
        activeStep: state.activeStep + 1,
      }));
    }
  };
  isStepCompleted = (step) => {
    return this.state.stepsCompleted.has(step);
  };

  handleClose = async (e) => {
    const packageId = this.props.match.params.packageId;
    const packageDetails = packagesList.filter(
      (packageDetail) => packageDetail.packagesDataId == packageId
    )[0];
    // e.preventDefault();
    const { activeStep, stepsCompleted } = this.state;
    // <Redirect push to={`/thank-you/${activeStep}/${packageDetails.packageName}`}/>
    if (activeStep == true) {
      window.location = `/site-grader.html?header=true#/thank-you/${activeStep}/${packageDetails.packageName}`;
    } else {
      window.location = `/site-grader.html?header=false#/thank-you/${activeStep}/${packageDetails.packageName}`;
    }
    /* const {isFormSubmit, isMailSupportSent} = this.state;
        await this.setState({
            submitDialog: false,
            // isFormSubmit: false,
            dialog: false,
        });
        if (isMailSupportSent == true) window.location = "/index.html";
        if (isFormSubmit == true) window.location = "/login.html";*/
  };

  render() {
    //const ListYears = ["2018", "2019", "2020", "2021", "2022", "2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030"];
    const ListYears = [
      "2023",
      "2024",
      "2025",
      "2026",
      "2027",
      "2028",
      "2029",
      "2030",
      "2031",
      "2032",
      "2033",
      "2034",
      "2035",
      "2036",
      "2037",
      "2038",
      "2039",
      "2040",
    ];

    const { classes, activeUser, cardDetails } = this.props;
    const {
      showForm,
      isEmailValidLoading,
      emailExistsError,
      errorMessage,
      isLoading,
      isCardLoading,
    } = this.state;
    const steps = getSteps();
    const { activeStep, stepsCompleted } = this.state;
    const showHeader = window.lxrOptions && window.lxrOptions.showHeader;
    console.log("state=", this.state);
    console.log("activeUser=", activeUser);
    console.log("cardDetails=", cardDetails);
    console.log("activeStep=", activeStep);
    console.log("this.props.match.params", this.props.match.params);
    return (
      <div>
        {isCardLoading ? (
          <CircularProgress />
        ) : (
          <Card className={"fullWidth"}>
            <CardContent className={classes.cardContent}>
              <Grid
                container
                className={classes.mainContainer}
                justifyContent={"center"}
                spacing={2}
              >
                <Grid item md={12}>
                  {/*this plus one added do show the extra stepper in payment page*/}
                  <SeoStepper
                    activeStep={activeStep + 1}
                    stepsCompleted={stepsCompleted}
                  />
                </Grid>
                <Grid item md={12}>
                  {errorMessage ? (
                    <ErrorTypography align={"center"}>
                      {errorMessage}
                    </ErrorTypography>
                  ) : null}
                </Grid>
                <Grid item md={10} className={classes.backBtn}>
                  <Typography
                    color={"textSecondary"}
                    align={"left"}
                    gutterBottom
                  >
                    <Link
                      className={classes.backLink}
                      onClick={this.handleBackPage}
                      to={
                        activeUser == true
                          ? `/seo-site-grader-report/1`
                          : `/seo-services-packages/${this.props.match.params.domainUrl}/${this.props.match.params.emailId}`
                      }
                    >
                      <b>{"< Back to Packages"}</b>
                    </Link>
                  </Typography>
                </Grid>
                <Grid item md={5}>
                  <Paper className={classes.mainPaper}>
                    <div className={classes.root}>
                      <Stepper activeStep={activeStep} orientation="vertical">
                        {steps.map((label, index) => (
                          <Step key={label}>
                            <CustomStepLabel>{label}</CustomStepLabel>
                            <StepContent>
                              <Typography>
                                {getStepUI.call(this, index, classes)}
                              </Typography>

                              <div className={classes.actionsContainer}>
                                <div style={{ display: "flex" }}>
                                  <Button
                                    disabled={activeStep === 0 || activeUser}
                                    onClick={this.handleBack}
                                    className={classes.button}
                                  >
                                    Back
                                  </Button>
                                  {getButtons.call(
                                    this,
                                    activeStep,
                                    classes,
                                    steps
                                  )}
                                </div>
                              </div>
                            </StepContent>
                          </Step>
                        ))}
                      </Stepper>
                      {activeStep === steps.length && (
                        <Paper
                          square
                          elevation={0}
                          className={classes.resetContainer}
                        >
                          <Typography>
                            All steps completed - you&apos;re finished
                          </Typography>
                          <Button
                            onClick={this.handleReset}
                            className={classes.button}
                          >
                            Reset
                          </Button>
                        </Paper>
                      )}
                    </div>
                  </Paper>
                  <br />
                  <br />
                </Grid>
                <Grid item md={5}>
                  <OrderSummary packageId={this.props.match.params.packageId} />
                </Grid>

                <Grid item>
                  <CustomDialog
                    open={this.state.dialog}
                    content={
                      this.state.isMailSupportSent == true ? (
                        <Typography component={"p"}>
                          Our Team will contact you shortly.
                        </Typography>
                      ) : (
                        <Typography component={"p"}>
                          Something Went Wrong, Please Try again.
                        </Typography>
                      )
                    }
                    okBtn
                    handleAction={this.handleClose}
                    titleColor={"Notification"}
                  />

                  <CustomDialog
                    open={this.state.submitDialog}
                    content={
                      this.state.isFormSubmit == true ? (
                        <Typography component={"p"}>
                          Your payment was successful.
                        </Typography>
                      ) : (
                        <Typography component={"p"}>
                          Something doesn’t seem right.
                          <br />
                          We were unable to process your payment on the LXR SEO
                          on-demand service pack due to a technical hitch.
                        </Typography>
                      )
                    }
                    okBtn
                    handleAction={this.handleClose}
                    titleColor={"Notification"}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        )}
        {showHeader && (
          <Card>
            <CardContent style={{ paddingBottom: 0, padding: 0 }}>
              <Paper style={{ boxShadow: "unset" }}>
                <Footer />
              </Paper>
            </CardContent>
          </Card>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  saveSEOUserDetails: (seoUserDetails) => {
    return dispatch(saveSEOUserDetails(seoUserDetails));
  },
  emailValidation: (emailId) => {
    return dispatch(emailValidation(emailId));
  },
  contactSupportForOnDemandServices: (emailId, phoneNumber) => {
    return dispatch(contactSupportForOnDemandServices(emailId, phoneNumber));
  },
  updateActiveStepSEODetails: (activeStep) => {
    return dispatch(updateActiveStepSEODetails(activeStep));
  },
  updateSelectedPackage: (packageId) => {
    return dispatch(updateSelectedSEOPackage(packageId));
  },
  getUserPaymentInfo: () => {
    return dispatch(getUserPaymentInfo());
  },
  onDemandPaymentForActiveUser: (paymentDetailId, planId) => {
    return dispatch(onDemandPaymentForActiveUser(paymentDetailId, planId));
  },
});
const mapStateToProps = (state) => ({
  siteGraderServiceDetails: state.siteGraderServiceDetails,
  activeUser: state.userInfoSEOInternal.cardDetails != null ? true : false,
  cardDetails: state.userInfoSEOInternal.cardDetails,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SeoServiceExternal));
