import React, { Fragment } from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import classNames from "classnames";
import { Link } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import {
  CHANGE_ORGANIZED_BY,
  TASK_PAGE_TRACK,
  TASKS_TABS_TRACK,
} from "../actions/actionTypes";
import { fetchPerfData, getTasksByAccount } from "../actions";
import { connect } from "react-redux";
import { sortTasks, store } from "../reducers/common";
import * as _ from "lodash";
import { CompactCardContent } from "../components/cards";
import Savings from "../components/task/savings";
import {
  Avatar,
  Button,
  Chip,
  FormControl,
  Grid,
  Hidden,
  Input,
  InputLabel,
  MenuItem,
  RadioGroup,
  Select,
} from "@mui/material";
import icons from "../components/icons";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { CircularProgress, NativeSelect } from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { IconAndText } from "./pendingtasks";
import ReactDOM from "react-dom";
import { NewTasks } from "./commonFunctions/commonFunctions";

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="xl" />;

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  p: {
    margin: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
  },
  tabsP: {
    padding: "0 16px",
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    boxShadow: "4px 4px 18px rgba(0, 0, 0, 0.06)",
    minHeight: 107,
    alignItems: "center",
    border: "1px solid " + theme.palette.common.border,
  },
  linearProgress: {
    width: "100%",
    paddingRight: theme.spacing(4),
  },
  tab: {
    color: theme.palette.text.primary,
    fontWeight: "bold",
    fontSize: theme.typography.pxToRem(14),
    fontFamily: "Lato",
    textTransform: "capitalize",
    minWidth: 125,
  },
  selectedTab: {
    background: theme.palette.common.mediumGrey,
    padding: "5px 7px 5px 7px",
    borderRadius: 21,
    // color: theme.palette.common.darkHash
  },
  indicator: {
    backgroundColor: theme.palette.common.disableGrey,
  },
  chip: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.success1.main,
    color: theme.palette.common.white,
    fontSize: theme.typography.pxToRem(14),
    fontFamily: "Lato",
    fontWeight: "bold",
  },
  labelContainer: {
    paddingBottom: 0,
  },
  mobileSelect: {
    border: " 3px solid",
    borderRadius: 20,
    background: theme.palette.text.primary,
    color: theme.palette.common.white,
    paddingLeft: 10,
  },
  mobileTabSelect: {
    borderRadius: 20,
    background: theme.palette.common.mediumGrey,
    color: theme.palette.text.primary,
    paddingLeft: 10,
    marginLeft: 10,
  },
  title: {
    paddingTop: 5,
    [theme.breakpoints.down("xl")]: {
      paddingLeft: 5,
    },
  },
});
const OrangeBtn = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.text.primary,
    fontWeight: "500",
    color: theme.palette.common.white,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: theme.palette.text.primary,
    },
  },
  outlined: {
    border: "0.2px solid #E0E0E0",
  },
}))(Button);
const WhiteBtn = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.mediumGrey,
    fontWeight: "normal",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: theme.palette.common.mediumGrey,
    },
  },
  outlined: {
    border: "1px solid #E0E0E0",
  },
}))(Button);
const CustomLinearProgress = withStyles((theme) => ({
  root: {
    height: 8,
    borderRadius: 5,
    backgroundColor: "#EAF3FD",
  },
  bar: {
    backgroundColor: theme.palette.progressBars,
    borderRadius: 5,
  },
}))(LinearProgress);

function MyRadioButton({ checked, onChange, value, children, style }) {
  // const variant = checked ? 'outlined' : 'contained';
  const Button = checked ? OrangeBtn : WhiteBtn;
  return (
    <Button
      disableRipple
      variant={"outlined"}
      size={"small"}
      style={style}
      onClick={() => onChange({ target: { value } }, true)}
    >
      {children}
    </Button>
  );
}

function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir}>
      {children}
    </Typography>
  );
}

function OrganizeBy({ value, setValue, theme }) {
  return (
    <Grid
      container
      justifyContent={"center"}
      spacing={2}
      alignItems={"flex-end"}
      style={{ margin: "4px 0px" }}
      id="organizeBy"
    >
      <Grid item>
        <Typography>Sort By </Typography>
      </Grid>
      <Grid item>
        <RadioGroup
          row={true}
          value={value}
          onChange={(evt) => setValue(evt.target.value)}
        >
          <MyRadioButton
            value={"TaskType"}
            style={{ borderTopLeftRadius: 25, borderBottomLeftRadius: 25 }}
          >
            Tasks
          </MyRadioButton>
          <MyRadioButton
            value={"Campaign"}
            className={"firstLetter"}
            style={{
              borderTopRightRadius: 25,
              borderBottomRightRadius: 25,
            }}
          >
            Campaigns
          </MyRadioButton>
        </RadioGroup>
      </Grid>
    </Grid>
  );
}

export function PlainLink({ classes, ...rest }) {
  return <Link {...rest} className={classes.a} />;
}

PlainLink = withStyles({
  a: {
    width: "100%",
    color: "unset",
    textDecoration: "none",
    cursor: "pointer",
  },
})(PlainLink);

function Task(props) {
  const { dataIdList, taskDataById, classes, width, index } = props;
  console.log("dataIdList=", dataIdList);
  console.log("props.summary=", props.summary);
  console.log("taskDataById==>", taskDataById);
  // console.log("");
  const totalTaskDataCount = dataIdList.length;
  const completedTDataCount = dataIdList.filter(
    (id) => taskDataById[id].status === 1
  ).length;
  const progressWidth = (completedTDataCount / totalTaskDataCount) * 100;
  const mobileWidth = width == "sm" || width == "xs" ? 11 : null;

    return (<div>
        <PlainLink
            to={`/task-new/${props.summary.groupId}`}>
            <Paper className={classNames("flex", props.classes.paper)}>
                <Grid container>
                    <Grid item sm={mobileWidth} xs={mobileWidth}>  
                        {/* <Typography variant={(width == "sm" || width == "xs") ? "body1" : "h4"} color="textPrimary"
                                    noWrap={(width == "sm" || width == "xs") ? false : true}>
                            <b>{props.summary.title}</b>
                        </Typography>                    */}

                        {/* use this for troas as well */}
                        {NewTasks.includes(props.summary.groupId) && <span style={{ display: "flex", alignItems: "center" }}>
                            <Typography variant={(width == "sm" || width == "xs") ? "body1" : "h4"} color="textPrimary"
                                    noWrap={(width == "sm" || width == "xs") ? false : true}>
                            <b>{props.summary.title} &nbsp; </b> 
                        </Typography>
                        <span> {icons.New_Sticker}</span>
                        </span> }

                        {!NewTasks.includes(props.summary.groupId) && <Typography variant={(width == "sm" || width == "xs") ? "body1" : "h4"} color="textPrimary"
                                    noWrap={(width == "sm" || width == "xs") ? false : true}>
                            <b>{props.summary.title}</b>
                        </Typography>}
                        {props.summary.groupId != 51 && <Typography variant={"caption"} style={{display:"flex"}}>
                            <Savings
                                orderForecast={props.summary.impact.orderForecast}
                                costForecast={props.summary.impact.costForecast}
                                revenueForecast={props.summary.impact.revenueForecast}
                                costSavingForecast={props.summary.impact.costSavingForecast}
                                timeSavedEstimate={props.summary.timeEstimate}
                            />
                        </Typography>}
                    </Grid>
                    <Hidden mdDown>
                        <Grid item className={props.classes.grow}/>
                    </Hidden>
                    <Grid item>
                        <div className={props.classes.linearProgress}>
                            {progressWidth == 100 ? <Chip
                                    avatar={icons.checkCircle}
                                    label="Completed"
                                    className={classes.chip}
                                    style={{minWidth: 130}}
                                />
                                :
                                <div style={{minWidth: 130}} id={"task-" + `${index}`}>
                                    <CustomLinearProgress variant="determinate" value={progressWidth}/>
                                    <Typography variant={"body2"} color={"textPrimary"}>
                                        <b>{completedTDataCount} / {totalTaskDataCount} Completed</b></Typography>
                                </div>
                            }
                        </div>
                    </Grid>
                    <Hidden mdDown>
                        <Grid item className={"flex alignItemsCenter"}>
                            <Typography>
                                <i className="fa fa-chevron-right" aria-hidden="true"></i>
                            </Typography>
                        </Grid>
                    </Hidden>
                </Grid>
                <Hidden smUp>
                    <Typography>
                        <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </Typography>
                </Hidden>
            </Paper>
        </PlainLink>
    </div>);
}

Task.propTypes = {
  summary: PropTypes.any,
  classes: PropTypes.any,
};

function getAllTypeTasks(groups, taskDataById) {
  //inside taskDataById we have action status
  //inside taskDataById we have taskDataList
  let pendingTasks = [];
  let completedTasks = [];
  let autoCompletedTasks = [];
  console.log("groups===>", groups);
  console.log("taskDataById===>", taskDataById);
  groups.forEach((group) => {
    if (
      _.filter(group.dataIdList, (id) => taskDataById[id].status == 0).length >
      0
    ) {
      pendingTasks.push(group);
      console.log("pendingTasks taskDataById =>id=>", pendingTasks);
    }
    if (
      _.filter(group.dataIdList, (id) => taskDataById[id].status == 1).length ==
      group.dataIdList.length
    ) {
      completedTasks.push(group);
      console.log("completedTasks taskDataById =>id=>", completedTasks);
    }
    if (
      _.filter(group.dataIdList, (id) => taskDataById[id].actionStatus == 5)
        .length > 0
    ) {
      // completedTasks.push(group);
      autoCompletedTasks.push(group);
      console.log("");
    }
  });
  return { pendingTasks, completedTasks };
}

class TaskPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      organizeBy: "TaskType",
      tabValue: 0,
    };
  }

  componentDidMount() {
    const { history } = this.props;
    window.scrollTo(0, 0);
    // ReactDOM.findDOMNode(this).scrollIntoView();
    if (history.length == 0) {
      this.props.fetchPerfData();
    }
  }

  handleChange = async (event, value) => {
    console.log("value=", value);
    await this.setState({ tabValue: value });
    this.props.trackTaskTabs(value);
  };
  handleMobileChange = async (event) => {
    await this.setState({ tabValue: event.target.value });
    console.log("tabValue=", this.state.tabValue);
  };

  render() {
    const { groupedTaskData, isAdmin, taskDataById, width, isLoading } =
      this.props;
    const { tabValue } = this.state;
    let groups = sortTasks(groupedTaskData[this.state.organizeBy]);
    const { pendingTasks, completedTasks } = getAllTypeTasks(
      groups,
      taskDataById
    );
    const { classes } = this.props;
    return (
      <div>
        {isLoading ? (
          <div
            style={{
              height: "100%",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress color={"secondary"} />
          </div>
        ) : (
          <div>
            {groups.length == 0 ? (
              <div
                id={"no-tasks"}
                style={{
                  height: "100%",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <IconAndText
                  icon={icons.noTasksIcon}
                  text={"No tasks this week."}
                />
              </div>
            ) : (
              <div>
                <Grid container alignItems={"center"} className={classes.tabsP}>
                  <Grid item>
                    <Typography
                      className={classes.title}
                      align={"left"}
                      variant={"h3"}
                      component={"h3"}
                      color={"textPrimary"}
                    >
                      <b>Tasks</b>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Hidden lgDown>
                      <Tabs
                        value={tabValue}
                        onChange={this.handleChange}
                        // indicatorColor="primary"
                        // indicatorColor="#F58020"
                        TabIndicatorProps={{ style: { background: "#F58120" } }}
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons={false}
                        classes={{ indicator: classes.indicator }}
                        id={"tasks-filter"}
                      >
                        <Tab
                          className={classes.tab}
                          classes={{
                            label: tabValue == 0 ? classes.selectedTab : null,
                            labelContainer: classes.labelContainer,
                          }}
                          label={`All (${groups.length})`}
                          disableRipple
                        />
                        <Tab
                          className={classes.tab}
                          classes={{
                            label: tabValue == 1 ? classes.selectedTab : null,
                            labelContainer: classes.labelContainer,
                          }}
                          label={`Pending (${pendingTasks.length})`}
                          disableRipple
                        />
                        <Tab
                          className={classes.tab}
                          classes={{
                            label: tabValue == 2 ? classes.selectedTab : null,
                            labelContainer: classes.labelContainer,
                          }}
                          label={`Completed (${completedTasks.length})`}
                          disableRipple
                        />
                      </Tabs>
                    </Hidden>
                    <Hidden smUp>
                      <FormControl className={classes.formControl}>
                        <CustomSelect1
                          className={classes.mobileTabSelect}
                          IconComponent={ExpandMore}
                          disableUnderline
                          value={tabValue}
                          onChange={this.handleMobileChange}
                        >
                          <MenuItem value={0}>
                            {`All(${groups.length})`}
                          </MenuItem>
                          <MenuItem value={1}>
                            {`Pending(${groups.length})`}
                          </MenuItem>
                          <MenuItem value={2}>
                            {`Completed(${groups.length})`}
                          </MenuItem>
                        </CustomSelect1>
                      </FormControl>
                    </Hidden>
                  </Grid>
                  <Grid item className={classes.grow}></Grid>
                  {/*ToDo uncomment the below code after completing the campaign wise selection */}
                  {/*<Grid item>
                                <Hidden lgDown>
                                    <OrganizeBy value={this.state.organizeBy}
                                                setValue={(event) => {
                                                    this.props.changeOrganizedBy(event.target.value);
                                                    this.setState({organizeBy: event.target.value})
                                                }}/>
                                </Hidden>
                                <Hidden smUp>
                                    <FormControl className={classes.formControl}>
                                        <CustomSelect
                                            className={classes.mobileSelect}
                                            IconComponent={ExpandMore}
                                            disableUnderline
                                            value={this.state.organizeBy}
                                            onChange={(organizeBy) => {
                                                this.props.changeOrganizedBy(organizeBy);
                                                this.setState({organizeBy})
                                            }}
                                        >
                                            <MenuItem value={"TaskType"}>
                                                Tasks
                                            </MenuItem>
                                            <MenuItem value={"Campaign"}>
                                                Campaign
                                            </MenuItem>
                                        </CustomSelect>
                                    </FormControl>
                                </Hidden>
                            </Grid>*/}
                </Grid>
                {tabValue === 0 && (
                  <TabContainer className={classes.tabContainer}>
                    {_.map(groups, ({ summary, dataIdList }, i) => (
                      <Task
                        width={width}
                        key={i}
                        index={i}
                        summary={summary}
                        classes={classes}
                        dataIdList={dataIdList}
                        taskDataById={taskDataById}
                      />
                    ))}
                  </TabContainer>
                )}
                {tabValue === 1 && (
                  <TabContainer className={classes.tabContainer}>
                    {_.map(pendingTasks, ({ summary, dataIdList }, i) => (
                      <Task
                        width={width}
                        key={i}
                        index={i}
                        summary={summary}
                        classes={classes}
                        dataIdList={dataIdList}
                        taskDataById={taskDataById}
                      />
                    ))}
                  </TabContainer>
                )}
                {tabValue === 2 && (
                  <TabContainer className={classes.tabContainer}>
                    {_.map(completedTasks, ({ summary, dataIdList }, i) => (
                      <Task
                        width={width}
                        key={i}
                        index={i}
                        summary={summary}
                        classes={classes}
                        dataIdList={dataIdList}
                        taskDataById={taskDataById}
                      />
                    ))}
                  </TabContainer>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

const CustomSelect = withStyles((theme) => ({
  icon: {
    color: theme.palette.common.white,
    paddingTop: 2,
  },
}))(Select);
const CustomSelect1 = withStyles((theme) => ({
  icon: {
    color: theme.palette.text.primary,
    paddingTop: 2,
  },
}))(Select);

TaskPage.propTypes = {};
const mapDispatchToProps = (dispatch) => ({
  changeOrganizedBy: (organizeBy) =>
    dispatch({ type: CHANGE_ORGANIZED_BY, organizeBy }),
  taskPageTrack: () => dispatch({ type: TASK_PAGE_TRACK }),
  getTasksByAccount: (ppcId, accountId) =>
    dispatch(getTasksByAccount(ppcId, accountId)),
  fetchPerfData: () => dispatch(fetchPerfData()),
  trackTaskTabs: (value) => dispatch({ type: TASKS_TABS_TRACK, value }),
});
const mapStateToProps = (state) => ({
  groupedTaskData: state.groupedTaskData,
  isAdmin: state.taskAnalysis.isAdmin,
  taskDataById: state.taskDataById,
  history: state.perf.history,
  isLoading: state.perf.isLoading,

  // getAllTypeTasks: getAllTypeTasks(sortTasks(state.groupedTaskData.TaskType, state.taskDataById), state.taskDataById)
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withWidth()(withStyles(styles)(TaskPage)));
