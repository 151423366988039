import {
  AppBar,
  CssBaseline,
  Divider,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  NativeSelect,
  SwipeableDrawer,
  Toolbar,
  Typography,
  Button,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import Badge from "@mui/material/Badge";
import useMediaQuery from "@mui/material/useMediaQuery";
import ExpandMore from "@mui/icons-material/ExpandMore";
import classNames from "classnames";
import * as _ from "lodash";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { fetchPerfData, toggleDrawer, updateDrawer } from "../actions";
import * as types from "../actions/actionTypes";
import {
  RESET_MENU,
  TOGGLE_WELCOME_BANNER,
  UPDATE_MENU,
} from "../actions/actionTypes";
import {
  fetchUserData,
  updateSelectedAccount,
  updateSelectedClient,
} from "../actions/fetchingUserData";
import styles from "../assets/jss/headerBar/headerStyles";
import icons from "../components/icons";
import { sortTasks } from "../reducers/common";
import { campaignsSnapshotPrevious } from "../selectors/dashboard-selectors";
import HeaderTabs from "./headerTabs/headerTabs";
import Notification from "./headerTabs/notification";
import NotificationsHeaderTabs from "./headerTabs/notificationsHeaderTabs";
import CustomMenuLists from "./menuList/menuList";
import UserSessionAlert from "./userSessionSnackBar";
import WhsScoreIcon from "./WebsiteHealthScorecard/whsScoreIcon";
import { ChevronLeftOutlined } from "@mui/icons-material";

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="xl" />;

const IconComponent = withStyles((theme) => ({
  selected: {
    color: theme.palette.text.primary,
  },
  IconComponent: {
    color: theme.palette.text.primary,
  },
  MuiNativeSelectIcon: {
    color: theme.palette.text.primary,
  },
}))(NativeSelect);
const CustomDrawer = withStyles((theme) => ({
  paper: {
    background: theme.palette.text.primary,
  },
}))(Drawer);
const CustomSwipeableDrawer = withStyles((theme) => ({
  paper: {
    background: theme.palette.text.primary,
  },
}))(SwipeableDrawer);

const CustomMenuItemSmallMenu = withStyles((theme) => ({
  root: {
    paddingTop: 8,
    paddingBottom: 8,
    "& > div": {
      "&:focus": {
        outline: "none !important",
      },
    },
    "&:hover": {
      backgroundColor: theme.palette.common.whiterGrey,
    },
  },
  selected: {
    backgroundColor: theme.palette.common.whiterGrey + " !important",
  },
}))(MenuItem);
const CustomMenu = withStyles((theme) => ({
  root: {
    "&:focus": {
      outline: "unset !important",
    },
  },
}))(Menu);

export function PlainLink({ classes, ...rest }) {
  return <Link {...rest} className={classes.a} />;
}

PlainLink = withStyles({
  a: {
    width: "100%",
    color: "unset",
    textDecoration: "none",
    cursor: "pointer",
  },
})(PlainLink);

function MobileMenu(props) {
  const isTabletResponsive = useMediaQuery(
    props.theme.breakpoints.between("xs", "lg")
  );

  return (
    <Fragment>
      {(props.width == "sm" || props.width == "xs" || isTabletResponsive) && (
        <IconButton
          className={props.classes.menuBtn}
          aria-label="Open drawer"
          onClick={props.onClick}
          /* className={classNames(classes.menuButton, {
                     [classes.hide]: open,
                 }, {
                     "root": classes.menuBurger
                 })}*/
          size="large"
        >
          {icons.menuMobile}
        </IconButton>
      )}
      {(props.width == "sm" || props.width == "xs" || isTabletResponsive) && (
        <PlainLink to={`/`}>{icons.brandMobile}</PlainLink>
      )}
    </Fragment>
  );
}

MobileMenu.propTypes = {
  width: PropTypes.any,
  tabletResponsive: PropTypes.any,
  classes: PropTypes.any,
  onClick: PropTypes.func,
};

class MiniDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // open: false,
      anchorEl: null,
      mobileMoreAnchorEl: null,
      value: 0,
      age: "Client1",
      left: false,
      selectedEngineId: !_.isEmpty(this.props.userDetails.selectedAccountInfo)
        ? this.props.userDetails.selectedAccountInfo.searchEngineId
        : 1,
      notificationAnchor: null,
    };
    window.handleNested = (selectedEngineId) => {
      this.setState({ selectedEngineId: selectedEngineId });
    };
    // console.log("Inital EId=", this.props.userDetails.selectedAccountInfo ? this.props.userDetails.selectedAccountInfo.searchEngineId : 1)
  }

  async componentDidMount() {
    let data = await this.props.fetchUserInfo();
    const {
      location: { pathname },
      history,
      userDetails,
    } = this.props;
    // const {userInfo} = userDetails
    const { viewValue } = this.props;
    // console.log("header history==", history);
    if (pathname.includes("dashboard") && viewValue != 0) {
      await this.props.updateViewValue(0);
    } else if (pathname.includes("tasks") && viewValue != 1) {
      await this.props.updateViewValue(1);
    } else if (pathname.includes("reports") && viewValue != 2) {
      await this.props.updateViewValue(2);
    } else if (pathname.includes("activity-report") && viewValue != 3) {
      await this.props.updateViewValue(3);
    }
    /* if (!(!_.isEmpty(use    rInfo) && userInfo.permission) || !(!_.isEmpty(userInfo) && userInfo.type == 9)) {
             return history.push("/profile-settings");
         }*/
  }


  async componentDidUpdate(prevProps, prevState, snapshot) {
    const { userDetails, history } = this.props;
    const { userInfo, selectedClientInfo } = userDetails;
    const isPermission = userInfo.permission;
    const pricingPlanId =
      userInfo && userInfo.pricingPlan
        ? userInfo.pricingPlan.pricingPlanId
        : null;
    const changeInAccountSettings =
      prevProps.userDetails.selectedAccountInfo &&
      prevProps.userDetails.userInfo &&
      (prevProps.userDetails.selectedAccountInfo.ppcId !=
        userDetails.selectedAccountInfo.ppcId ||
        prevProps.userDetails.selectedAccountInfo.targetKpi !=
          userDetails.selectedAccountInfo.targetKpi ||
        prevProps.userDetails.selectedAccountInfo.targetValue !=
          userDetails.selectedAccountInfo.targetValue ||
        prevProps.userDetails.selectedAccountInfo.budget !=
          userDetails.selectedAccountInfo.budget);
    const changeInProfileSettings =
      prevProps.userDetails.userInfo &&
      prevProps.userDetails.userInfo.permission !=
        userDetails.userInfo.permission;

    /* Handling the Redirection to account setting if no account is enabled under this client */
    if (
      prevProps.userDetails.selectedClientInfo.clientId !=
      userDetails.selectedClientInfo.clientId
    ) {
      let { accounts } = selectedClientInfo;
      if (!_.isEmpty(userInfo) && accounts != null) {
        accounts = accounts.filter((account) => account.enabled == 1);
      }
      // Redirecting to account setting when no accounts enable
      if (accounts && accounts.length == 0 && userInfo.permission)
        history.push("/account-settings");
      // Redirecting to profile setting when no permission for user
      if (!userInfo.permission) history.push("/profile-settings");
    }
    if (
      (changeInAccountSettings || changeInProfileSettings) &&
      pricingPlanId != null &&
      pricingPlanId != 7
    ) {
      await this.props.fetchPerfData();
    }
    const isDevEnv = process.env.NODE_ENV === "development";
    if (!isDevEnv && (userInfo == null || _.isArray(userInfo)))
      window.location = "/logout.html";

    if (prevProps.anchorEl != this.props.anchorEl) {
      if (window.hopscotch && window.hopscotch != null) {
        const currentStep = window.hopscotch.getCurrStepNum();
        console.log("currentStep123=", currentStep);
        if (currentStep) {
          const currentTour = window.hopscotch.getCurrTour();
          console.log("currentTour=", currentTour);
          console.log("currentStep=", currentStep);
          window.hopscotch.startTour(currentTour, currentStep);
          console.log("123");
        }
      }
    }
  }

  handleClientsSelectChange = (event) => {
    // this.setState({[event.target.name]: event.target.value});
    this.updateSelectedClient(event.target.value);
  };

  handleDrawerOpen = () => {
    this.props.updateDrawer(true);
    // this.setState({open: true});
  };

  handleDrawerClose = () => {
    this.props.updateDrawer(false);
    // this.setState({open: false});
  };
  handleDrawer = () => {
    this.props.toggleDrawer();
    // this.setState({open: !open});
  };

  handleProfileMenuOpen = async (event) => {
    console.log("event.currentTarget=", event.currentTarget);
    // await this.setState({anchorEl: event.currentTarget});
    this.props.updateMenu(event.currentTarget);
    console.log("anchorEl", this.state.anchorEl);
  };

  handleMenuClose = () => {
    // this.setState({anchorEl: null});
    this.props.resetMenu();
    this.handleMobileMenuClose();
  };

  handleMobileMenuOpen = (event) => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  handleChange = (event, value) => {
    this.props.updateViewValue(value);
    this.props.trackDashboardPages(value);
  };

  handleChangeIndex = (index) => {
    this.setState({ value: index });
  };

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open,
    });
  };
  handleBackButton = () => {
    this.props.history.push("/connections-page");
  }
  updateSelectedAccount = async (ppcId) => {
    const { userDetails, history } = this.props;
    const selectedPpcId = userDetails.selectedAccountInfo.ppcId
      ? userDetails.selectedAccountInfo.ppcId
      : 0;
    if (ppcId == selectedPpcId) {
      history.push("/dashboard");
      return;
    }
    await this.props.updateSelectedAccount(ppcId);
    history.push("/dashboard");
  };
  updateSelectedClient = async (clientId) => {
    console.log("updateSelectedCLient clientId= ", clientId);
    const { userDetails, history } = this.props;
    const selectedClientId = userDetails.selectedClientInfo.clientId
      ? userDetails.selectedClientInfo.clientId
      : 0;
    if (clientId == selectedClientId) {
      // history.push("/dashboard/" + selectedClientId);
      history.push("/dashboard");
      return;
    }
    await this.props.updateSelectedClient(clientId);
    // history.push("/dashboard/" + selectedClientId);
    history.push("/dashboard");
  };
  handleNestedMenuClick = async (engineId) => {
    console.log("cliecked id ", engineId);
    this.setState({ selectedEngineId: engineId }),
      this.props.updateDrawer(true);
  };

  handleNotificationChange = (event) => {
    this.setState({ notificationAnchor: event.currentTarget });
  };
  handleNotificationClose = () => {
    this.setState({ notificationAnchor: null });
  };


  render() {
    const {
      classes,
      theme,
      location: { pathname },
      userDetails,
      width,
      viewValue,
      selectedDateRange,
      taskDataById,
      tasks,
      open,
      isAdminUserDashboard,
      isPerfLoading,
      history,
      welcomeToggle,
      productTourInProgress,
      anchorEl,
      statsHistory,
      productThankYouDialog,
      productTourCloseAlert,
      isSubscriptions,
      isMarTech,
      openMartech,
      deleteConnectionsDataReducer,
      // isPremierServicePackMonthly
    } = this.props;
    const isAnalyticsAccountDeleted =
      deleteConnectionsDataReducer.message ===
      "DELETE_ANALY_CONNECTION_RECEIVED"
        ? true
        : false;
    console.log("isAnalyticsAccountDeleted", isAnalyticsAccountDeleted);
    const { mobileMoreAnchorEl, selectedEngineId, left, notificationAnchor } =
      this.state;
    const { userInfo } = userDetails;
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const isMenuOpen = Boolean(anchorEl);
    var dashboardColor = theme.palette.text.primary;
    var tasksColor = theme.palette.text.primary;
    var reportsColor = theme.palette.text.primary;
    var activityReportsColor = theme.palette.text.primary;
    var selectedColor = theme.palette.secondary.main;
    const selectedPpcId = userDetails.selectedAccountInfo.ppcId
      ? userDetails.selectedAccountInfo.ppcId
      : 0;
    const dashboardColour = viewValue == 0 ? selectedColor : dashboardColor;
    const tasksColour = viewValue == 1 ? selectedColor : tasksColor;
    const reportsColour = viewValue == 2 ? selectedColor : reportsColor;
    const activityReportColour =
      viewValue == 3 ? selectedColor : activityReportsColor;
    const userName = userDetails.userInfo
      ? userDetails.userInfo.username
      : "user Name";
    const emailId = userDetails.userInfo
      ? userDetails.userInfo.emailId
      : "EmailId@gmail.com";
    const pricingPlanId =
      userInfo && userInfo.pricingPlan
        ? userInfo.pricingPlan.pricingPlanId
        : null;
    const userId =
      userDetails.userInfo && userDetails.userInfo.userId
        ? userDetails.userInfo.userId
        : null;
    const isPermission = userDetails.userInfo
      ? userDetails.userInfo.permission
      : null;
    const unsubscribedManually = userDetails.userInfo
      ? userDetails.userInfo.unsubscribed
      : null;
    const selectedAccountNotificationCount = userDetails.notificationList
      ? userDetails.notificationList.filter(
          (notification) => notification.ppcId == selectedPpcId
        ).length
      : 0;
    const selectedClientInfo = !_.isEmpty(userDetails.selectedClientInfo)
      ? userDetails.selectedClientInfo
      : null;
    const notificationList = userDetails.notificationList
      ? userDetails.notificationList
      : null;
    const isHeaderRenderIn =
      (window.lxrOptions.defaultRoute == "dashboard" ||
        window.lxrOptions.defaultRoute == "ACCOUNT_SETTINGS" ||
        window.lxrOptions.defaultRoute == "PROFILE_SETTINGS" ||
        window.lxrOptions.defaultRoute == "PAYMENT_HISTORY" ||
        window.lxrOptions.defaultRoute == "CLIENT_SETTINGS" ||
        window.lxrOptions.defaultRoute == "PROPERTIES_AND_VIEWS") &&
      (pathname == "/" ||
        pathname == "/dashboard" ||
        pathname == "/tasks/" ||
        pathname == "/reports" ||
        pathname == "/activity-report");
    const isUserDataLoading = userDetails.isLoading;
    // console.log("pathname==", pathname);
    // console.log("this.props.ppcId", this.props);
    // console.log("this.props.match.params.ppcId", this.props.match.params.ppcId);
    // console.log("isHeaderRenderIn=====", isHeaderRenderIn);
    // console.log("this.state.left=", this.state.left);

    let taskscount = 0;
    if (!_.isEmpty(tasks)) {
      taskscount = tasks.length;
    }

    // console.log("campaignsSnapshotPrevious=", campaignsSnapshotPrevious);
    const renderMenu = (
      <CustomMenu
        anchorEl={anchorEl}
        /* anchorOrigin={{vertical: 'bottom', horizontal: 'bottom'}}
                 transformOrigin={{vertical: 'bottom', horizontal: 'bottom'}}*/
        open={isMenuOpen}
        onClose={this.handleMenuClose}
        PaperProps={{
          style: {
            width: 248,
          },
        }}
        style={{
          boxShadow: " 14px 14px 60px rgba(30, 51, 78, 0.14)",
          borderRadius: 3,
        }}
      >
        <div>
          <Typography
            variant={"body1"}
            className={classes.rightSmallMenu1}
            color={"textPrimary"}
          >
            <b>{userName}</b>
          </Typography>
          <Typography
            variant={"body2"}
            gutterBottom
            className={classNames(
              classes.emailSmallMenuList,
              classes.rightSmallMenu1,
              classes.textOverflow1
            )}
          >
            {emailId}
          </Typography>
          <Divider className={classes.menuDivider1} variant={"middle"} />
          <CustomMenuItemSmallMenu
            component={Link}
            id={"view-Pro"}
            to={"/profile-settings"}
            onClick={this.handleMenuClose}
          >
            <Typography
              className={classes.rightSmallMenuList}
              color={"textPrimary"}
              variant={"body2"}
            >
              View Profile
            </Typography>
          </CustomMenuItemSmallMenu>
          <CustomMenuItemSmallMenu
            component={Link}
            id={"client-link"}
            to={"/client-settings"}
            onClick={this.handleMenuClose}
          >
            <Typography
              className={classes.rightSmallMenuList}
              color={"textPrimary"}
              variant={"body2"}
            >
              Client Settings
            </Typography>
          </CustomMenuItemSmallMenu>
          <CustomMenuItemSmallMenu
            component={Link}
            id={"payment-link"}
            to={"/payment-history"}
            onClick={this.handleMenuClose}
          >
            <Typography
              className={classes.rightSmallMenuList}
              color={"textPrimary"}
              variant={"body2"}
            >
              Payment History
            </Typography>
          </CustomMenuItemSmallMenu>
          <Divider className={classes.menuDivider1} variant={"middle"} />
          <a href="/logout.html" className={classes.link}>
            <CustomMenuItemSmallMenu onClick={this.handleMenuClose}>
              <Typography
                className={classes.rightSmallMenuList}
                color={"textPrimary"}
                variant={"body2"}
              >
                Logout
              </Typography>
            </CustomMenuItemSmallMenu>
          </a>
        </div>
      </CustomMenu>
    );
    // const renderNotificationMenu = (
    //
    // );

    return (
      <Fragment>
        <CssBaseline />
        {/*{isAdminUserDashboard ?*/}
        {/*   <AppBar   position="fixed"
                              className={classNames(classes.appBar, {
                                  [classes.appBarShift]: open,
                              })}>


                        <Toolbar></Toolbar>
                    </AppBar>*/}
        {/*: null}*/}

        {/*{(userDetails.userInfo && (accounts == 0 || accounts == undefined)) ? <Redirect push to={`/account-settings`}/> : null}*/}
        {!pathname.includes("task-new") &&
          !pathname.includes("task-data") &&
          !isUserDataLoading && (
            <AppBar
              position="fixed"
              className={classNames(classes.appBar, {
                [classes.appBarShift]: open,
              })}
            >
              {isAdminUserDashboard ? (
                <UserSessionAlert
                  icon={icons.WarningAlert}
                  userId={userId}
                  message={"Please don't modify anything in user session"}
                />
              ) : null}
              <Toolbar disableGutters={!open}>
                <MobileMenu
                  width={width}
                  theme={theme}
                  classes={classes}
                  onClick={this.toggleDrawer("left", true)}
                />
                {/*Brand Icon for Mobile view*/}

                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  // onClick={this.handleDrawerOpen}
                  className={classNames(
                    classes.menuButton,
                    open && classes.hide
                  )}
                  size="large"
                >
                  {/*<MenuIcon />*/}
                  &nbsp;
                </IconButton>
                {(width === "md" || width === "lg" || width === "xl") &&
                  (isHeaderRenderIn ||
                    (!pathname.includes("/notifications") &&
                      pathname != "/on-demand-service" &&
                      pathname != "/properties-views" &&
                      window.lxrOptions.defaultRoute !=
                        "PROPERTIES_AND_VIEWS" &&
                      pathname != "/auto-posting" &&
                      pathname != "/connections-page" &&
                      pathname != "/google-analytics-dashboard" &&
                      pathname != "/google-analytics-dashboard-menu" &&
                      pathname != "/profile-settings" &&
                      window.lxrOptions.defaultRoute != "PROFILE_SETTINGS" &&
                      pathname != "/holiday-package" &&
                      pathname != "/account-settings" &&
                      window.lxrOptions.defaultRoute != "ACCOUNT_SETTINGS" &&
                      pathname != "/client-settings" &&
                      window.lxrOptions.defaultRoute != "CLIENT_SETTINGS" &&
                      pathname != "/payment-history" &&
                      pathname != "/website-health-scorecard" &&
                      pathname != "/whs-payment" &&
                      window.lxrOptions.defaultRoute != "PAYMENT_HISTORY" &&
                      pathname != "/site-grader-report/1")) && (
                    <Typography
                      variant="h2"
                      color="textPrimary"
                      noWrap
                      className={open ? classes.openText1 : classes.openTextP}
                    >
                      <b>
                        {userDetails.selectedAccountInfo
                          ? userDetails.selectedAccountInfo.clientName
                          : "Account Name"}
                      </b>
                    </Typography>
                  )}
                {(width === "md" || width === "lg" || width === "xl") &&
                  (pathname == "/client-settings" ||
                    (window.lxrOptions.defaultRoute == "CLIENT_SETTINGS" &&
                      pathname == "/")) && (
                    <Typography
                      variant="h2"
                      color="textPrimary"
                      noWrap
                      className={open ? classes.openText1 : classes.openTextP}
                    >
                      <b>{"Client Settings"}</b>
                    </Typography>
                  )}
                {(width === "md" || width === "lg" || width === "xl") &&
                  pathname == "/holiday-package" && (
                    <Typography
                      variant="h2"
                      color="textPrimary"
                      noWrap
                      className={open ? classes.openText1 : classes.openTextP}
                    >
                      <b>{"Holiday Package"}</b>
                    </Typography>
                  )}
                {(width === "md" || width === "lg" || width === "xl") &&
                  (pathname == "/payment-history" ||
                    (window.lxrOptions.defaultRoute == "PAYMENT_HISTORY" &&
                      pathname == "/")) && (
                    <Typography
                      variant="h2"
                      color="textPrimary"
                      noWrap
                      className={open ? classes.openText1 : classes.openTextP}
                    >
                      <b>{"Payment History"}</b>
                    </Typography>
                  )}
                {(width === "md" || width === "lg" || width === "xl") &&
                  (pathname == "/profile-settings" ||
                    (window.lxrOptions.defaultRoute == "PROFILE_SETTINGS" &&
                      pathname == "/")) && (
                    <Typography
                      variant="h2"
                      color="textPrimary"
                      noWrap
                      className={open ? classes.openText1 : classes.openTextP}
                    >
                      <b>{"Profile Settings"}</b>
                    </Typography>
                  )}
                {(width === "md" || width === "lg" || width === "xl") &&
                  pathname == "/on-demand-service" && (
                    <Typography
                      variant="h2"
                      color="textPrimary"
                      noWrap
                      className={open ? classes.openText1 : classes.openTextP}
                    >
                      <b>{"On-Demand Service"}</b>
                    </Typography>
                  )}
                {(width === "md" || width === "lg" || width === "xl") &&
                  pathname == "/auto-posting" && (
                    <Typography
                      variant="h2"
                      color="textPrimary"
                      noWrap
                      className={open ? classes.openText1 : classes.openTextP}
                    >
                      <b>{"Auto Apply"}</b>
                    </Typography>
                  )}
                {(width === "md" || width === "lg" || width === "xl") &&
                  pathname == "/connections-page" && (
                    <Typography
                      variant="h2"
                      color="textPrimary"
                      noWrap
                      className={open ? classes.openText1 : classes.openTextP}
                    >
                      <b>{"Connections"}</b>
                    </Typography>
                  )}
                {(width === "md" || width === "lg" || width === "xl") &&
                  pathname == "/google-analytics-dashboard-menu" && (
                    <Typography
                      variant="h2"
                      color="textPrimary"
                      noWrap
                      className={open ? classes.openText1 : classes.openTextP}
                    >
                      {/* for /google-analytics-dashboard */}
                      <b>{"Analytics Dashboard"}</b>
                    </Typography>
                  )}
                {(width === "md" || width === "lg" || width === "xl") &&
                  (pathname == "/properties-views" ||
                    (window.lxrOptions.defaultRoute == "PROPERTIES_AND_VIEWS" &&
                      pathname == "/")) && (
                    <Typography
                      variant="h2"
                      color="textPrimary"
                      noWrap
                      className={open ? classes.openText1 : classes.openTextP}
                    >
                      <b>{"Properties and Profiles"}</b>
                    </Typography>
                  )}
                {(width === "md" || width === "lg" || width === "xl") &&
                  pathname == "/whs-payment" && (
                    <Typography
                      variant="h2"
                      color="textPrimary"
                      noWrap
                      className={open ? classes.openText1 : classes.openTextP}
                    >
                      <b>{"Website Health Consultation"}</b>
                    </Typography>
                  )}
                {(width === "md" || width === "lg" || width === "xl") &&
                  pathname.includes("/notifications") && (
                    <Typography
                      variant="h2"
                      color="textPrimary"
                      noWrap
                      className={open ? classes.openText1 : classes.openTextP}
                    >
                      <b>{"Notifications"}</b>
                    </Typography>
                  )}
                {(width === "md" || width === "lg" || width === "xl") &&
                  (pathname == "/account-settings" ||
                    (window.lxrOptions.defaultRoute == "ACCOUNT_SETTINGS" &&
                      pathname == "/")) && (
                    <Typography
                      variant="h2"
                      color="textPrimary"
                      noWrap
                      className={open ? classes.openText1 : classes.openTextP}
                    >
                      <b>{"Account Settings"}</b>
                    </Typography>
                  )}
                {(width === "md" || width === "lg" || width === "xl") &&
                  pathname == "/site-grader-report/1" && (
                    <Typography
                      variant="h2"
                      color="textPrimary"
                      noWrap
                      className={open ? classes.openText1 : classes.openTextP}
                    >
                      <b>{"SEO Scorecard"}</b>
                    </Typography>
                  )}
                {(width === "md" || width === "lg" || width === "xl") &&
                  pathname == "/website-health-scorecard" && (
                    <Typography
                      variant="h2"
                      color="textPrimary"
                      noWrap
                      className={open ? classes.openText1 : classes.openTextP}
                    >
                      <b>{"Website Health Scorecard"}</b>
                    </Typography>
                  )}
                <div className={classes.grow} />
                <div className={classes.sectionDesktop}>
                  <WhsScoreIcon
                    clientId={userDetails.selectedAccountInfo.clientId}
                  />
                  {pricingPlanId != null && pricingPlanId != 7 && (
                    <IconButton
                      onClick={(event) => this.handleNotificationChange(event)}
                      className={classes.bell}
                      size="large"
                    >
                      <Badge
                        id={"notification-icon"}
                        badgeContent={selectedAccountNotificationCount}
                        classes={{ badge: classes.notificationBadge }}
                        color="secondary"
                      >
                        {icons.notificationBell}
                      </Badge>
                    </IconButton>
                  )}
                  <IconButton
                    id={"menu-sel"}
                    aria-owns={isMenuOpen ? "material-appbar" : undefined}
                    aria-haspopup="true"
                    onClick={this.handleProfileMenuOpen}
                    className={classes.accountIcon}
                    size="large"
                  >
                    {icons.userDialog}&nbsp;
                    <ExpandMore style={{ fontSize: 18 }} />
                  </IconButton>
                </div>
                <Notification
                  notificationAnchor={this.state.notificationAnchor}
                  handleClose={this.handleNotificationClose}
                  handleChange={this.handleNotificationChange}
                  notificationList={userDetails.notificationList}
                  selectedPpcId={selectedPpcId}
                />
                {/* <div className={classes.sectionMobile}>
                            <IconButton aria-haspopup="true" onClick={this.handleMobileMenuOpen} color="inherit">
                                <MoreIcon/>
                            </IconButton>
                        </div>*/}
              </Toolbar>
              {!pathname.includes("/notifications") && (
                <HeaderTabs
                  width={width}
                  pathname={pathname}
                  pricingPlanId={pricingPlanId}
                  value={viewValue}
                  onChange={this.handleChange}
                  open={open}
                  classes={classes}
                  color={dashboardColour}
                  color1={tasksColour}
                  taskscount={taskscount}
                  color2={reportsColour}
                  color3={activityReportColour}
                />
              )}

              {pathname.includes("/notifications") && (
                <NotificationsHeaderTabs
                  notificationList={notificationList}
                  selectedClientInfo={selectedClientInfo}
                  classes={classes}
                  pathName={pathname}
                />
              )}
            </AppBar>
          )}
        {renderMenu}
        {/*{renderNotificationMenu}*/}

        <Hidden lgDown>
          <CustomDrawer
            variant="permanent"
            className={classNames(
              classes.drawer,
              {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              },
              { paper: classes.drawerPaper }
            )}
            classes={{
              paper: classNames({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),
            }}
            open={open}
            onClose={(event) => event.preventDefault()}
          >
            <div className={classes.toolbar}>
              {open == true ? (
                <div className={"flex alignItemsCenter"}>
                  <PlainLink to={`/`}>{icons.logo}</PlainLink>
                  <IconButton size="large">
                    {/*{theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}*/}
                    &nbsp;
                  </IconButton>
                </div>
              ) : (
                <IconButton
                  aria-label="Open drawer"
                  onClick={this.handleDrawerOpen}
                  className={classNames(
                    {
                      [classes.hide]: open == true,
                    },
                    {
                      root: classes.menuBurger,
                    }
                  )}
                  size="large"
                >
                  {icons.xIcon}
                </IconButton>
              )}
            </div>
            <CustomMenuLists
              classes={classes}
              open={open}
              onChange={this.handleClientsSelectChange}
              pathname={pathname}
              userDetails={userDetails}
              updateSelectedAccount={this.updateSelectedAccount}
              updateSelectedClient={this.updateSelectedClient}
              handleNestedMenuClick={this.handleNestedMenuClick}
              selectedEngineId={selectedEngineId}
              isDrawer={open}
              width={width}
              isPermission={isPermission}
              pricingPlanId={pricingPlanId}
              isPerfLoading={isPerfLoading}
              history={history}
              statsHistory={statsHistory}
              welcomeToggle={welcomeToggle}
              productTourInProgress={productTourInProgress}
              productThankYouDialog={productThankYouDialog}
              productTourCloseAlert={productTourCloseAlert}
              isSubscriptions={isSubscriptions}
              openMartech={openMartech}
              isMarTech={isMarTech}
              isAnalyticsAccountDeleted={isAnalyticsAccountDeleted}
              // isPremierServicePackMonthly={isPremierServicePackMonthly}
              // unsubscribed={isPermission}
            />
          </CustomDrawer>
        </Hidden>
        {/* for mobile menu drawer*/}
        <CustomSwipeableDrawer
          open={this.state.left}
          onClose={this.toggleDrawer("left", false)}
          onOpen={this.toggleDrawer("left", true)}
        >
          <div
            tabIndex={0}
            role="button"
            onClick={this.toggleDrawer("left", false)}
            onKeyDown={this.toggleDrawer("left", false)}
          >
            {/*{sideList}*/}
            <Grid
              container
              spacing={2}
              alignItems={"center"}
              className={classes.mobileMenu}
            >
              <Grid item>
                <IconButton size="large">{icons.closeMenuMobile}</IconButton>
              </Grid>
              <Grid item>{icons.brandMobile1}</Grid>
            </Grid>
            <CustomMenuLists
              classes={classes}
              open={this.state.left}
              onChange={this.handleClientsSelectChange}
              pathname={pathname}
              userDetails={userDetails}
              updateSelectedAccount={this.updateSelectedAccount}
              updateSelectedClient={this.updateSelectedClient}
              handleNestedMenuClick={this.handleNestedMenuClick}
              selectedEngineId={selectedEngineId}
              isDrawer={this.state.left}
              width={width}
              isPermission={isPermission}
              pricingPlanId={pricingPlanId}
              isPerfLoading={isPerfLoading}
              history={history}
              statsHistory={statsHistory}
              welcomeToggle={welcomeToggle}
              productTourInProgress={productTourInProgress}
              productThankYouDialog={productThankYouDialog}
              productTourCloseAlert={productTourCloseAlert}
              openMartech={openMartech}
              isMarTech={isMarTech}
              isSubscriptions={isSubscriptions}
              // isPremierServicePackMonthly={isPremierServicePackMonthly}
            />
          </div>
        </CustomSwipeableDrawer>
        
        {/*<Notification notificationAnchor={this.state.notificationAnchor}
                              handleClose={this.handleNotificationClose}
                              handleChange={this.handleNotificationChange}
                              notificationList={userInfo.notificationList}
                />*/}
        <Hidden lgDown>
          <div
            style={{ width: 0 }}
            id={open ? "mainDrawerOpen" : "mainDrawerClose"}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={this.handleDrawer}
              style={{
                zIndex: "99999",
                marginLeft: "-24px",
                marginTop: 4,
              }}
              size="large"
            >
              {/*<MenuIcon/>*/}
              {open == false ? icons.rightArrowC : icons.leftArrowC}
            </IconButton>
          </div>
        </Hidden>
      </Fragment>
    );
  }
}

MiniDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  viewValue: state.dashboard.viewValue,
  userDetails: state.userInformation,
  taskDataById: state.taskDataById,
  selectedDateRange: state.selectedDateRange,
  campaignsSnapshotPrevious: campaignsSnapshotPrevious(
    state.perf.history,
    state.selectedDateRange,
    state.perf.kpiType
  ),
  tasks: sortTasks(state.groupedTaskData.TaskType, state.taskDataById),
  open: state.header.open,
  isAdminUserDashboard: state.userInformation.isAdminUserDashboard,
  isMarTech: state.martechServices.isMarTech,
  anchorEl: state.header.anchorEl,
  isPerfLoading: state.perf.isLoading,
  productTourInProgress: state.productTour.isProgress,
  productThankYouDialog: state.productTour.thankYouDialog,
  statsHistory: state.perf.history,
  productTourCloseAlert: state.productTour.productTourClose,
  isSubscriptions: state.perf.isSubscriptions,
  isPremierServicePackMonthly:
    state.onDemandPayment.isPremierServicePackMonthly,
  deleteConnectionsDataReducer: state.deleteConnectionsDataReducer,
  isSEOPackTrimonthly:state.onDemandPayment.isSEOPackTrimonthly,
});
const mapDispatchToProps = (dispatch) => ({
  updateViewValue: (viewValue) => {
    return dispatch({
      type: types.DASHBOARD_VIEW_VALUE_UPDATED,
      viewValue: viewValue,
    });
  },
  trackDashboardPages: (viewValue) => {
    return dispatch({
      type: types.DASHBOARD_VIEW_VALUE_TRACK,
      viewValue: viewValue,
    });
  },
  fetchUserInfo: () => dispatch(fetchUserData()),
  fetchPerfData: () => dispatch(fetchPerfData()),
  updateSelectedAccount: (ppcId) => dispatch(updateSelectedAccount(ppcId)),
  updateSelectedClient: (clientId) => dispatch(updateSelectedClient(clientId)),
  toggleDrawer: () => dispatch(toggleDrawer()),
  updateDrawer: (value) => dispatch(updateDrawer(value)),
  openMartech: () =>
    dispatch({
      type: types.OPEN_MARTCH,
    }),

  updateMenu: (anchorEl) => dispatch({ type: UPDATE_MENU, anchorEl }),
  resetMenu: () => dispatch({ type: RESET_MENU }),
  welcomeToggle: () => dispatch({ type: TOGGLE_WELCOME_BANNER }),
});

const _MiniDrawer = connect(mapStateToProps, mapDispatchToProps)(MiniDrawer);
export default withRouter(
  withWidth()(withStyles(styles, { withTheme: true })(_MiniDrawer))
);
