import React, { Component, Fragment, useState } from "react";
import {
  Grid,
  Typography,
  Switch,
  FormControlLabel,
  FormGroup,
  Button,
} from "@mui/material";
import { FormControl, MenuItem, Select } from "@mui/material/index";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { InputFields } from "../../components/input-forms";
import {
  NewOrangeContainedBtn,
  NewOutLinedRectBlackBtn,
} from "../../components/buttons";
import CircularProgress from "@mui/material/CircularProgress";

function ClientSettingsForm(props) {
  const { classes, selectedClientInfo, isClientDetailsLoading } = props;

  const [state, setState] = useState({
    clientName:
      props.selectedClientInfo && props.selectedClientInfo.clientName
        ? props.selectedClientInfo.clientName
        : "",
    clientEmail:
      props.selectedClientInfo && props.selectedClientInfo.clientEmail
        ? props.selectedClientInfo.clientEmail
        : 0,
    clientWebsite:
      props.selectedClientInfo && props.selectedClientInfo.clientWebsite
        ? props.selectedClientInfo.clientWebsite
        : 0,
    clientNameError: "",
    clientEmailError: "",
    clientWebsiteError: "",
  });

  const isEqual =
    selectedClientInfo.clientName === state.clientName &&
    selectedClientInfo.clientEmail == state.clientEmail &&
    selectedClientInfo.clientWebsite == state.clientWebsite;

  const validateClientDetails = () => {
    let isError = false;
    const errors = {};

    if (state.clientName && state.clientName.length === 0) {
      isError = true;
      errors.clientNameError = "This field is required";
    } else {
      errors.clientNameError = "";
    }
    if (state.clientWebsite && state.clientWebsite.length === 0) {
      isError = true;
      errors.clientWebsiteError = "This field is required";
    } else {
      errors.clientWebsiteError = "";
    }
    if (state.clientEmail && state.clientEmail.length === 0) {
      isError = true;
      errors.clientEmailError = "This field is required";
    } else if (state.clientEmail && state.clientEmail.length > 0) {
      let emailError = false;
      var email = state.clientEmail;
      console.log("emailIdList is= ", email);
      let result = email
        .trim()
        .match(
          /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
        );
      if (!result) {
        isError = true;
        errors.clientEmailError = "Please enter valid email";
        emailError = true;
      }
      if (!emailError) {
        errors.clientEmailError = "";
      }
    } else {
      errors.clientEmailError = "";
    }
    setState({
      ...state,
      ...errors,
    });
    return isError;
  };

  const handleChange = (name) => (event) => {
    setState((prevState) => ({
      ...prevState,
      [name]: event.target.value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault({});
    let error = validateClientDetails();

    if (!error) {
      const { clientName, clientEmail, clientWebsite } = state;
      const { clientId } = props.selectedClientInfo;
      console.log("the clientId from the props = ", clientId);
      props.UpdateClientDetails({
        clientName: clientName,
        clientWebsite: clientWebsite,
        clientEmail: clientEmail,
      });
    }
  };

  return (
    <Fragment>
      <form onSubmit={handleSubmit}>
        <Grid
          item
          md={6}
          lg={6}
          xl={6}
          style={{ marginBottom: 10 }}
        >
          <Typography color={"textPrimary"} variant={"h4"} gutterBottom>
            <b>Edit Client</b>
          </Typography>
          <Typography variant={"body1"} className={classes.clientFields}>
            <b>Client Name</b>
          </Typography>
          <FormControl className={classes.clientFormControl}>
            <InputFields
              value={state.clientName}
              onChange={handleChange("clientName")}
              margin="normal"
              variant="outlined"
              error={state.clientNameError != ""}
              helperText={state.clientNameError}
              style={{ marginTop: 0 }}
            />
          </FormControl>
          <Typography variant={"body1"} className={classes.clientFields}>
            <b>Client Email</b>
          </Typography>
          <FormControl className={classes.clientFormControl}>
            <InputFields
              // label="Name"
              value={state.clientEmail}
              onChange={handleChange("clientEmail")}
              margin="normal"
              variant="outlined"
              error={state.clientEmailError != ""}
              helperText={state.clientEmailError}
              style={{ marginTop: 0 }}
            />
          </FormControl>
          <Typography variant={"body1"} className={classes.clientFields}>
            <b>Client Website</b>
          </Typography>
          <FormControl className={classes.clientFormControl}>
            <InputFields
              // label="Name"
              value={state.clientWebsite}
              onChange={handleChange("clientWebsite")}
              margin="normal"
              variant="outlined"
              error={state.clientWebsiteError != ""}
              helperText={state.clientWebsiteError}
              style={{ marginTop: 0 }}
            />
          </FormControl>
        </Grid>
        <Grid item md={3} lg={3} xl={3} style={{ marginLeft: "20px" }}>
          <div className={classes.wrapper} style={{ textAlign: "left" }}>
            <NewOrangeContainedBtn
              type="submit"
              variant={"outlined"}
              disabled={isEqual || isClientDetailsLoading}
            >
              Update
            </NewOrangeContainedBtn>
            {isClientDetailsLoading && (
              <CircularProgress
                className={classes.buttonProgress}
                color="primary"
                size={24}
              />
            )}
          </div>
        </Grid>
      </form>
    </Fragment>
  );
}
export default ClientSettingsForm;
