import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "../jumpstart.css";
import { JsSectionTypography } from "../../../components/typography";
import {Grid, Typography,} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { JsAddUserLabelTypography } from "../../../components/typography";
import { JsBlackContainedBtn, JsOrangeContainedBtn } from "../../../components/buttons";
import InfoOutlined from "@mui/icons-material/InfoOutlined";

function GenerateSitelinks(props) {
  const { handleCloseDrawer, handleGenerateSitelinks, brandName } = props;
  const [themeName, setThemeName] = useState();
  const [url, setUrl] = useState();

  const handleThemeNameChange = (e) => {
    setThemeName(e.target.value);
  }
  const handleUrlChange = (e) => {
    setUrl(e.target.value);
  }

  return (
    <div>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        style={{ padding: "2%", borderBottom: "1px solid #E6E6E6" }}
      >
        <Grid item xs={11}>
          <JsSectionTypography style={{ fontSize: "1rem" }}>
            Provide Inputs to Generate Sitelinks
          </JsSectionTypography>
        </Grid>
        <Grid item xs={1}>
          <IconButton aria-label="close" onClick={handleCloseDrawer(false)}>
            <CloseIcon color="secondary" />
          </IconButton>
        </Grid>
      </Grid>
      <div>
        <Grid
          container
          direction="row"
          columnSpacing={2}
          rowSpacing={2}
          style={{ padding: "2%" }}
        >
          <Grid item xs={12}>
            <Typography variant="subtitle2" style={{display:"flex", padding:"1%", background:"rgba(255, 253, 234, 0.35)", color:"#C8B511"}}>
              <InfoOutlined fontSize="small"sx={{paddingTop:"4px"}}/> &nbsp; Brand name for the account is "{brandName}".
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <label htmlFor="themeName">
              <JsAddUserLabelTypography>
                <span className="numberContainer">1</span>Type Product Focus or Theme
              </JsAddUserLabelTypography>
            </label>
          </Grid>
          <div style={{ width: "100%", paddingLeft: "7%" }}>
            <input
              type="text"
              placeholder="Type your text here"
              id="themeName"
              className="addUser-input-css"
              value={themeName}
              onChange={handleThemeNameChange}
            />
          </div>
          <Grid item xs={12}>
            <label htmlFor="url">
              <JsAddUserLabelTypography>
                <span className="numberContainer">2</span>Type Final URL 
              </JsAddUserLabelTypography>
            </label>
          </Grid>
          <div style={{ width: "100%", paddingLeft: "7%" }}>
            <input
              type="url"
              placeholder="Type your text here"
              id="url"
              className="addUser-input-css"
              value={url}
              onChange={handleUrlChange}
            />
          </div>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={6} className="bottomPositionBtn">
            <JsOrangeContainedBtn style={{fontSize:"0.875rem"}}
            disabled={url == undefined || url == "" || themeName == "" || themeName == undefined}
            onClick={() => handleGenerateSitelinks(themeName, url)}
            >
              Generate
            </JsOrangeContainedBtn>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(GenerateSitelinks);
