import React, { Component } from "react";
import PropTypes, { func } from "prop-types";
import { Card, CardContent, Paper } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import { connect } from "react-redux";

const styles = (theme) => ({
  root: {
    width: "90%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: "inline-block",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  card: {
    boxShadow: "unset",
    background: "unset",
  },
  paper: {
    padding: theme.spacing(2),
    minHeight: "50vh",
    border: "1px solid" + theme.palette.common.border,
    borderRadius: 6,
    boxShadow: "4px 4px 18px " + theme.palette.boxShadowC,
  },
});

function getSteps() {
  return ["JUMPSTART", "APPROVAL", "GO LIVE"];
}

function calculateActiveStep(jumpstart) {
  let activeStep = 0;
  const { userStatus } = jumpstart;
  if (userStatus == "REVIEWER_APPROVED") {
    activeStep = 1;
  } else if (userStatus == "CLIENT_APPROVED") {
    activeStep = 2;
  }
  return activeStep;
}

function getStepContent(step, jumpstartInfo, userId) {
  switch (step) {
    case 0:
      return <Step1 jumpstartInfo={jumpstartInfo} />;
    case 1:
      return <Step2 jumpstartInfo={jumpstartInfo} />;
    case 2:
      return <Step3 jumpstartInfo={jumpstartInfo} />;
    default:
      return "Unknown step";
  }
}

class JumpstartDashboard extends React.PureComponent {
  state = {
    activeStep: 0,
    completed: {},
  };

  totalSteps = () => getSteps().length;

  async componentDidMount() {
    /* let activeStep = 0;
         const {userStatus} = this.props.jumpstart;
         if (userStatus == 'REVIEWER_APPROVED') {
             activeStep = 1;
         } else if (userStatus == 'CLIENT_APPROVED') {
             activeStep = 2;
         }
         await this.setState({activeStep})*/
  }

  handleNext = () => {
    let activeStep;

    if (this.isLastStep() && !this.allStepsCompleted()) {
      // It's the last step, but not all steps have been completed,
      // find the first step that has been completed
      const steps = getSteps();
      activeStep = steps.findIndex((step, i) => !(i in this.state.completed));
    } else {
      activeStep = this.state.activeStep + 1;
    }
    this.setState({
      activeStep,
    });
  };

  handleBack = () => {
    this.setState((state) => ({
      activeStep: state.activeStep - 1,
    }));
  };

  handleStep = (step) => () => {
    this.setState({
      activeStep: step,
    });
  };

  handleComplete = () => {
    const { completed } = this.state;
    completed[this.state.activeStep] = true;
    this.setState({
      completed,
    });
    this.handleNext();
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
      completed: {},
    });
  };

  completedSteps() {
    return Object.keys(this.state.completed).length;
  }

  isLastStep() {
    return this.state.activeStep === this.totalSteps() - 1;
  }

  allStepsCompleted() {
    return this.completedSteps() === this.totalSteps();
  }

  render() {
    const { jumpstart } = this.props;
    console.log("jumpstart=", jumpstart);
    const { classes } = this.props;
    const steps = getSteps();
    const { activeStep } = this.props;
    return (
      <Card className={classes.card}>
        <CardContent>
          <Paper className={classes.paper}>
            <div className={classes.root}>
              <Stepper alternativeLabel activeStep={activeStep}>
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepButton completed={this.state.completed[index]}>
                      {activeStep == index ? (
                        <b>{label}</b>
                      ) : (
                        <span>{label}</span>
                      )}
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
              <Typography component={"div"} className={classes.instructions}>
                {getStepContent(activeStep, jumpstart)}
              </Typography>
            </div>
          </Paper>
        </CardContent>
      </Card>
    );
  }
}

JumpstartDashboard.propTypes = {};
const mapStateToProps = (state) => ({
  userDetails: state.userInformation,
  activeStep: calculateActiveStep(state.jumpstart),
});
export default connect(mapStateToProps)(withStyles(styles)(JumpstartDashboard));
