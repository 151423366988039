import React from "react";
import { Grid, Paper, Typography } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import icons from "./icons";
import classNames from "classnames";

const safeAndSecureStyles = (theme) => ({
  paddingLeftRight: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('xl')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  },
  padding: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    paddingBottom: theme.spacing(1),
  },
  paper: {
    boxShadow: "unset",
    background: theme.palette.graderBg,
  },
  dialogTitle: {
    color: theme.palette.orangeColor,
    fontSize: 20,
  },
  dialogContent: {
    color: theme.palette.text.secondary,
  },
  dialogSubContent: {
    color: theme.palette.safeAndSecure,
    fontSize: 12,
  },
});

function safeAndSecure(props) {
  const { classes, dialog } = props;
  return (
    <Paper
      className={classNames(
        dialog ? classes.padding : classes.paddingLeftRight,
        classes.paper
      )}
      style={{ background: dialog ? "unset" : null }}
    >
      {dialog ? null : (
        <Typography variant={"h4"} component={"h4"} align={"left"} gutterBottom>
          <b>Safe & Secure</b>
        </Typography>
      )}
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item>{icons.safeAndSecure}</Grid>
        <Grid item md={10}>
          {dialog ? (
            <Typography className={classes.dialogTitle}>
              <b>Safe & Secure</b>
            </Typography>
          ) : null}
          {dialog ? null : (
            <Typography>
              LXRGuide’s Google Ads Grader will only log in to your Google Ads
              account with your permission and will use your credentials to
              analyze your Google Ads account performance. LXRGuide will never
              share your Google Ads data for any reason.
            </Typography>
          )}
          {dialog ? (
            <div>
              <Typography className={classes.dialogContent}>
                <b>
                  LXRGuide will use your Google Ads credentials only to analyze
                  your account.
                </b>
              </Typography>
              <Typography className={classes.dialogSubContent}>
                We will never share your Google Ads data for any reason.
              </Typography>
              <Typography className={classes.dialogSubContent}>
                *You can always remove 3rd party access to your account
                here:&nbsp;
                <a
                  target={"_blank"}
                  href={"https://myaccount.google.com/permissions"}
                >
                  https://myaccount.google.com/permissions
                </a>
              </Typography>
            </div>
          ) : null}
        </Grid>
      </Grid>
    </Paper>
  );
}

export default withStyles(safeAndSecureStyles)(safeAndSecure);
