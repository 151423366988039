import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CardActions,
} from "@mui/material";
import { Typography } from "@mui/material/index";
import { withStyles } from "@mui/styles";
import martechBg from "../../assets/img/martech_bg_2.png";
import holidayBannerBg from "../../assets/img/holiday_Banner_Bg.png";
import { connect } from "react-redux";
import * as types from "../../actions/actionTypes";
import { fetchUserData } from "../../actions/fetchingUserData";
import { martechService } from "../../actions";
import {
  PublishOrangeContainedBtn,
  Blueborderedwithbluetext,
} from "../../components/buttons";

export const CustomDialog = withStyles((theme) => ({
  paper: {
    // backgroundImage: "url(" + martechBg + ")",
    backgroundImage: "url(" + holidayBannerBg + ")",

    backgroundSize: "cover",
    // /static/media/martech_bg_2.e3d6a88a.png);
    backgroundPosition: "right",
  },
}))(Dialog);
export const CustomDialogContent = withStyles((theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      minHeight: "20vh",
    },
    [theme.breakpoints.up("md")]: {
      minHeight: "15vh",
    },
    [theme.breakpoints.up("lg")]: {
      minHeight: "20vh",
    },
    [theme.breakpoints.up("xl")]: {
      minHeight: "5vh",
    },
  },
}))(DialogContent);
export const CustomCardActions = withStyles((theme) => ({
  root: {
    padding: "25px 18px",
    justifyContent: "flex-start",
  },
}))(DialogActions);

class MartechServicesDialog extends Component {
  state = {
    open: false,
    planDialog: false,
  };

  handleClickOpen = () => {
    // this.setState({open: true});
    this.props.openMartech();
  };

  handleClose = () => {
    // this.setState({open: true});
    this.props.closeMartech();
  };

  submitted = async () => {
    this.setState({ open: false });
    await this.props.requestMartech();
    this.props.closeMartech();
    this.setState({ planDialog: true });
  };
  closePlain = async () => {
    this.setState({ planDialog: false });
  };

  render() {
    const { classes, isMarTech } = this.props;
    const { planDialog } = this.state;
    return (
      <div>
        <div>
          {/*   <Button variant="outlined" color="primary" onClick={this.handleClickOpen}>
                        Open alert dialog
                    </Button>*/}
          <CustomDialog
            open={isMarTech}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth={"sm"}
            // className={classes.card}
          >
            <DialogTitle id="alert-dialog-title">{""}</DialogTitle>
            {/* <CustomDialogContent style={{ width: "89%" }}>
              <Typography variant={"h3"} className={classes.text}>
                <b>
                  NetElixir's team of certified experts can help you improve
                  website Conversion Rate.
                </b>
              </Typography>
            </CustomDialogContent> */}
            {/* please uncomment above and comment below for martech services */}
            <CustomDialogContent style={{ textAlign: "center",paddingTop:"34px" }}>
              <Typography variant={"h3"} className={classes.text}>
                <b> Boost Holiday Sales with Expert Ad<br/> Management Services</b>
                
              </Typography>
            </CustomDialogContent>
            {/* for Martech service banner*/}
            {/* <CustomCardActions >
              
              <PublishOrangeContainedBtn
                variant={"contained"}
                size={"large"}
                onClick={this.submitted}
                color="primary"
                autoFocus
              >
                Schedule Call Back
              </PublishOrangeContainedBtn>
                          
            </CustomCardActions> */}

{/* below is for holiday season banner*/}
            <CustomCardActions style={{justifyContent:"center"}}>
             
              
              <Blueborderedwithbluetext
              
                variant={"contained"}
                size={"large"}
                onClick={this.submitted}
                color="primary"
                autoFocus

              >
                Find Out How
              </Blueborderedwithbluetext>
            </CustomCardActions>
    

            
          </CustomDialog>
          <Dialog
            open={planDialog}
            onClose={this.closePlain}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth={"sm"}
            // className={classes.card}
          >
            <DialogTitle id="alert-dialog-title">
              {"Thank You for your interest in LXRGuide services"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText color={"textPrimary"}>
                Thank You for your interest in LXRGuide services someone from
                LXRGuide team will contact you shortly.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                variant={"text"}
                size={"large"}
                onClick={this.closePlain}
                color="primary"
                autoFocus
              >
                ok
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    );
  }
}

MartechServicesDialog.propTypes = {};
const styles = (theme) => ({
  dialogPaper: {
    // background:
  },
  card: {
    container: {
      backgroundImage: "url(" + martechBg + ")",
    },
  },
  text: {
    //below is for martech services
    // color: theme.palette.common.white,
    //below is for holidaybanner services
    color: "#46558F",
  },
});
const mapStateToProps = (state) => ({
  isMarTech: state.martechServices.isMarTech,
});
const mapDispatchToProps = (dispatch) => ({
  requestMartech: () => dispatch(martechService()),
  openMartech: () =>
    dispatch({
      type: types.OPEN_MARTCH,
    }),
  closeMartech: () =>
    dispatch({
      type: types.CLOSE_MARTCH,
    }),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(MartechServicesDialog));
