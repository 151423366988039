import { Grid, Typography, Button, Paper } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import React, { useEffect, useState } from "react";
import { OrangeTextSize12 } from "../../components/typography";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  fetchIndustryInsightIndusryData,
  fetchIndustryInsightData,
} from "../../actions";

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="xl" />;

const styles = (theme) => ({
  mainDiv: {
    [theme.breakpoints.up("md")]: {
      padding: "70px",
    },
    backgroundColor: "#ECEFF3",
  },
  orange: {
    [theme.breakpoints.up("md")]: {
      textAlign: "initial",
    },
    [theme.breakpoints.down("xl")]: {
      textAlign: "center",
    },
  },
  heading: {
    [theme.breakpoints.down("xl")]: {
      width: "100%",
      fontSize: "2.20rem",
      textAlign: "center",
      color: "#1E334E",
      fontWeight: "bold",
    },
    [theme.breakpoints.down("xl")]: {
      width: "100%",
      fontSize: "1.25rem",
      textAlign: "center",
      color: "#1E334E",
      fontWeight: "bold",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "0px",
      fontSize: "2.75rem",
      textAlign: "left",
      color: "#1E334E",
      fontWeight: "bold",
    },
  },
  gutBott2: {
    [theme.breakpoints.down("xl")]: {
      color: "#F58120",
      fontSize: "2.00rem",
      textDecoration: "underline",
    },
    [theme.breakpoints.down("xl")]: {
      color: "#F58120",
      fontSize: "1.05rem",
      textDecoration: "underline",
    },
    [theme.breakpoints.up("md")]: {
      color: "#F58120",
      fontSize: "2.55rem",
      textDecoration: "underline",
    },
  },
  BtnDiv: {
    [theme.breakpoints.down("xl")]: {
      display: "flex",
      justifyContent: "center",
      paddingBottom: "20px",
    },
  },
});
var industry = "";
function IndustryTrendSection(props) {
  const classes = props.classes;
  const {
    width,
    fetchIndustryInsightIndusryData,
    fetchIndustryInsightData,
    industryInsightsIndustryDataReducers,
  } = props;

  useEffect(() => {
    fetchIndustryInsightIndusryData();
  }, []);

  delete industryInsightsIndustryDataReducers["message"];

  const [index, setIndex] = useState(0);
  const [industryValue, setIndustryValue] = useState(
    industryInsightsIndustryDataReducers[index]
      ? industryInsightsIndustryDataReducers[index].industry
      : null
  );
  const [avgValue, setAvgValue] = useState(
    industryInsightsIndustryDataReducers[index]
      ? industryInsightsIndustryDataReducers[index].conv_rate
      : null
  );
  const [yearValue, setYearValue] = useState(
    industryInsightsIndustryDataReducers[index]
      ? industryInsightsIndustryDataReducers[index].year
      : null
  );
  const [quarterValue, setQuarterValue] = useState(
    industryInsightsIndustryDataReducers[index]
      ? industryInsightsIndustryDataReducers[index].quarter
      : null
  );

  useEffect(() => {
    if (industryInsightsIndustryDataReducers[index]) {
      setIndustryValue(industryInsightsIndustryDataReducers[index].industry);
      setAvgValue(
        industryInsightsIndustryDataReducers[index].conv_rate.toFixed(2)
      );
      setYearValue(industryInsightsIndustryDataReducers[index].year);
      setQuarterValue(industryInsightsIndustryDataReducers[index].quarter);
    }
  }, [index, industryInsightsIndustryDataReducers]);

  const [change, setChange] = useState(true);
  useEffect(() => {
    if (change) {
      const interval = setInterval(() => {
        setIndex((index) =>
          index === Object.keys(industryInsightsIndustryDataReducers).length - 1
            ? 0
            : index + 1
        );
      }, 3000);
      return () => clearInterval(interval);
    }
  }, [index, change]);

  const HandleGetIndustry = () => {
    industry = industryInsightsIndustryDataReducers[index].industry;
    console.log("clicked industry is", industry);
    fetchIndustryInsightData(industry);
  };

  return (
    <div className={classes.mainDiv}>
      <div>
        <Typography className={classes.orange}>
          <OrangeTextSize12 gutterBottom>INDUSTRY TREND</OrangeTextSize12>
        </Typography>
      </div>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <h1
            onMouseEnter={() => {
              setChange(false);
            }}
            onMouseLeave={() => {
              setChange(true);
            }}
            onTouchStart={() => {
              if (change) {
                setChange(false);
              } else {
                setChange(true);
              }
            }}
            className={classes.heading}
          >
            Conversion rate of&nbsp;
            <span className={classes.gutBott2}>{industryValue}</span>
            &nbsp;in&nbsp;
            <span className={classes.gutBott2}>Q{quarterValue},</span>&nbsp;
            <span className={classes.gutBott2}>{yearValue}</span>&nbsp;is&nbsp;
            <span className={classes.gutBott2}>{avgValue}%</span>
          </h1>
        </Grid>
      </Grid>
      <div className={classes.BtnDiv}>
        <Button
          style={{ fontWeight: "bold" }}
          variant="outlined"
          component={Link}
          to={`/industry-insights?industry=${industryValue}`}
          onClick={HandleGetIndustry}
        >
          Learn More
        </Button>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  industryInsightsDataReducers: state.industryInsightsDataReducers,
  industryInsightsIndustryDataReducers:
    state.industryInsightsIndustryDataReducers,
});
const mapDispatchToProps = (dispatch) => ({
  fetchIndustryInsightData: (industry) =>
    dispatch(fetchIndustryInsightData(industry)),
  fetchIndustryInsightIndusryData: () =>
    dispatch(fetchIndustryInsightIndusryData()),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withWidth()(withStyles(styles, { withTheme: true })(IndustryTrendSection)));
