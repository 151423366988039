const styles = theme => ({
    mainDiv: {
        [theme.breakpoints.up("sm")]: {
          margin: "0 4rem 0 4rem",
          backgroundColor: "#FFFFFF",
        },
        [theme.breakpoints.down("sm")]: {
          margin: "0 1rem 0 1rem",
          backgroundColor: "#FFFFFF",
        },
      },
    
      expansionPanelStyle: {
        border: 0,
        boxShadow: "none",
      },
    
      generateButton: {
        [theme.breakpoints.up("md")]: {
          width: "463px",
          height: "48px",
          margin: "20px 0 10px 0",
        },
        [theme.breakpoints.down("md")]: {
          width: "100%",
          height: "48px",
        },
      },
    
      pendingStatusMessage: {
        color: "#F58120",
        padding: "2px 0px 7px 3px",
      },
    
      readyStatusMessage: {
        color: "#1098F7",
        padding: "2px 0px 7px 3px",
      },
    
      publishedStatusMessage: {
        color: "#1EBC61",
        padding: "2px 0px 7px 3px",
      },
    
      errorStatusMessage: {
        color: "#EB3223",
        padding: "4px 0px 8px 0px",
      },
      labelWithTooltip: {
        display: "flex",
        flexDirection: "row",
      },
      textarea1: {
        resize: "none",
        width: "100%",
        height: "130px",
        border: "1px solid #E3E6F4",
        borderRadius: "6px",
        padding: "10px 20px",
        fontFamily: "lato",
        fontSize: "14px",
        lineHeight: "17px",
      },
      textarea2: {
        resize: "none",
        width: "100%",
        height: "100px",
        border: "1px solid #E3E6F4",
        borderRadius: "6px",
        padding: "10px 20px",
        fontFamily: "lato",
        fontSize: "14px",
        lineHeight: "17px",
      },
      headlineBox: {
        resize: "none",
        height: "35px",
        width: "100%",
        border: "1px solid #E3E6F4",
        borderRadius: "6px",
        padding: "7px 20px",
      },
      descriptionBox: {
        resize: "none",
        height: "48px",
        width: "100%",
        border: "1px solid #E3E6F4",
        borderRadius: "6px",
        padding: "7px 20px",
        wordWrap: "break-word",
      },
      fieldBoxInfo: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      },
      characterCount: {
        display: "flex",
        marginLeft:"auto",
      },
      inputError: {
        borderColor: "#CF0505",
      },
      urlInfo: {
        display: "flex",
        alignItems: "center",
        marginBottom: "10px",
      },
});

export default styles;