import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import icons from "../../components/icons";
import { Avatar, Divider } from "@mui/material";
import { connect } from "react-redux";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useHistory } from "react-router-dom";

function JsHeader(props) {
  const {
    window,
    isSmallScreen,
    open,
    handleDrawerToggle,
    userName,
    email,
  } = props;
  const history = useHistory();


  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleLogout = () => {
    sessionStorage.removeItem("jwtToken");
    sessionStorage.removeItem("loggedOut");
    history.push("/jumpstart-login");
  };
  const handleLogoClick = () => {
    history.push("/jumpstart-dashboard");
  }
  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: "#fff",
      }}
      component="nav"
    >
      <Toolbar>
        {isSmallScreen && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon style={{ color: "#1E334E" }} />
          </IconButton>
        )}
        <Box
          sx={{ flexGrow: 1, display: { xs: "none", sm: "none", md: "block" } }}

        >
          <span style={{ cursor: "pointer" }} onClick={() => handleLogoClick()}>
            {icons.LXRGudieLogo}
          </span>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            display: { xs: "block", sm: "block", md: "none" },
          }}
        >
          <span style={{ cursor: "pointer" }} onClick={() => handleLogoClick()}
          >
            {icons.brandMobile}
          </span>

        </Box>
        <Box sx={{ display: { xs: "none", sm: "none", md: "block" }, pr: 1 }}>
          <Typography style={{ color: "black" }} align="center">
            Hi, {userName}
          </Typography>
        </Box>
        <Box
          sx={{
            alignContent: "center",
          }}
        >
          <IconButton sx={{ p: 0 }} onClick={handleOpenUserMenu}>
            <Avatar sx={{ bgcolor: "#F58120", width: 34, height: 33, fontSize: 16 }} alt={userName}>
              {userName.charAt(0).toUpperCase()}
            </Avatar>
          </IconButton>
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <Box sx={{ display: { xs: "block", sm: "none" }, p: 1 }}>
              <Typography style={{ color: "black" }} align="center">
                Hi, {userName}
              </Typography>
              <Divider />
            </Box>
            {/* <MenuItem key="Profile" onClick={handleCloseUserMenu}>
              <Typography textAlign="center">Profile</Typography>
            </MenuItem>
            <MenuItem key="Notification" onClick={handleCloseUserMenu}>
              <Typography textAlign="center">Notification</Typography>
            </MenuItem> */}
            <Typography
              textAlign="left"
              variant="subtitle2"
              padding="3px 10px"
              sx={{ wordBreak: "break-word" }}
            >
              {email}
            </Typography>
            <Divider />
            <MenuItem key="Logout" onClick={handleLogout}>
              <Typography textAlign="center">Logout</Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
const mapStateToProps = (state) => ({
  // fetchUserInfoReducer: state.fetchUserInfoReducer,
});
const mapDispatchToProps = (dispatch) => ({
  // fetchUserInfo: (jwtToken) => dispatch(fetchUserInfo(jwtToken)),
});
export default connect(mapStateToProps, mapDispatchToProps)(JsHeader);
