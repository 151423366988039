import * as types from "./actionTypes";
import { dispatchDashboardData, fetchPerfData } from "./index";

export function fetchUserData() {
  return async function (dispatch, getState) {
    console.log("fetching User info data");
    setTimeout(async () => {
      try {
        const useDummyData =
          window.lxrOptions && window.lxrOptions.useDummyData;
        dispatch({
          type: types.USER_INFORMATION_REQUESTED,
        });
        let url = useDummyData ? "/user-info-local.JSON" : "/user-info.json";
        let response = await fetch(url, {
       
          headers: {
            Accept: "application/json",
          },
          credentials: "include",
        });
        let data = await response.json();
        console.log("UserInfo Received", data);
        await dispatch({
          type: types.USER_INFORMATION_RECEIVED,
          data,
        });
        /* if (data.error && data.error == "") {
                     await this.props.fetchPerfData();
                 }*/
      } catch (e) {
        dispatch({ type: types.USER_INFORMATION_REQUEST_FAILED });
      }
    }, 0);
  };
}

export function updateSelectedAccount(ppcId) {
  return async function (dispatch, getState) {
    console.log("Updating Selected account ppcId ", ppcId);
    setTimeout(async () => {
      try {
        const useDummyData =
          window.lxrOptions && window.lxrOptions.useDummyData;
        await dispatch({
          type: types.UPDATE_SELECTED_ACCOUNT_REQUESTED,
        });
        let url = "/update-account.json";
        let response = await fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          credentials: "include",
          body: new URLSearchParams({ ppcId: ppcId ? ppcId : 0 }),
        });
        let data = await response.json();
        // console.log("updated Selected Received", data);
        // console.log("data.error && data.error == \"\"", (data.error && data.error == ""));
        /*  if (data.error && data.error == "") {
                    console.log("came here 2")
                    await fetchPerfData();
                }*/
        await dispatch({
          type: types.UPDATE_SELECTED_ACCOUNT_RECEIVED,
          data,
        });
        return data;
      } catch (e) {
        dispatch({ type: types.UPDATE_SELECTED_ACCOUNT_FAILED });
      }
    }, 0);
  };
}

export function updateSelectedClient(clientId) {
  return async function (dispatch, getState) {
    console.log("Updating Selected Client clientId ", clientId);
    setTimeout(async () => {
      try {
        const useDummyData =
          window.lxrOptions && window.lxrOptions.useDummyData;
        await dispatch({
          type: types.UPDATE_SELECTED_CLIENT_REQUESTED,
        });
        let url = "/update-client.json";
        let response = await fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          credentials: "include",
          body: new URLSearchParams({ clientId: clientId }),
        });
        let data = await response.json();
        console.log("updated Selected Received", data);
        dispatch({
          type: types.UPDATE_SELECTED_CLIENT_RECEIVED,
          data,
        });
        /*  if (data.error && data.error == "") {
                      await fetchPerfData();
                  }*/
      } catch (e) {
        dispatch({ type: types.UPDATE_SELECTED_CLIENT_FAILED });
      }
    }, 0);
  };
}

export function updateAccountDetails(accountDetails) {
  return async function (dispatch) {
    console.log("Saving account details...", accountDetails);
    const { ppcId, budget, targetValue, kPIMetric, emailIds } = accountDetails;
    console.log("ppcId=", ppcId);
    console.log("budget=", budget);
    console.log("targetValue=", targetValue);
    console.log("kPIMetric=", kPIMetric);
    console.log("emailIds=", emailIds);
    try {
      await dispatch({
        type: types.UPDATE_ACCOUNT_DETAILS_REQUESTED,
      });
      const url = "/update-account-details.json";
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        credentials: "include",
        body: new URLSearchParams({
          ppcId: ppcId,
          budget: budget,
          targetValue: targetValue,
          kPIMetric: kPIMetric,
          emailIds: emailIds,
        }),
      });
      let data = await response.json();
      await dispatch({
        type: types.UPDATE_ACCOUNT_DETAILS_RECEIVED,
        data,
      });
      console.log("Account Details Saved", data);
      return data;
    } catch (e) {
      console.error("exception", e);
      console.error("Error in account Details updating", e);
      await dispatch({
        type: types.UPDATE_ACCOUNT_DETAILS_FAILED,
      });
    }
  };
}
export function UpdateUserProfileDetails(userDetails) {
  return async function (dispatch) {
    
    console.log("updating user details...", userDetails);
    const {
      userId,
      emailIds,
      phoneNumber,
      address,
      billingName,
      billingEmail,
    } = userDetails;
    console.log("userId=", userId);
    console.log("phoneNumber=", phoneNumber);
    console.log("address=", address);
    console.log("billingName=", billingName);
    console.log("billingEmail=", billingEmail);
    console.log("emailIds=", emailIds);
    try {
      await dispatch({
        type: types.UPDATE_PROFILE_DETAILS_REQUESTED,
      });
      const url = "/update-profile-Settings.json";
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        credentials: "include",
        body: new URLSearchParams({
          userId: userId,
          emailIds: emailIds,
          phoneNumber: phoneNumber,
          address: address,
          billingName: billingName,
          billingEmail: billingEmail,
        }),
      });
      let data = await response.json();
      await dispatch({
        type: types.UPDATE_PROFILE_DETAILS_RECEIVED,
        data,
      });
      console.log("Profile Details Saved", data);
      return data;
    } catch (e) {
      console.error("exception", e);
      console.error("Error in user Profile Details updating", e);
      await dispatch({
        type: types.UPDATE_PROFILE_DETAILS_FAILED,
      });
    }
  };
}

export function UpdateUserPromoCodeDetails(promoCodeDetails) {
  return async function (dispatch) {
    console.log("updating promo code details...", promoCodeDetails);
    const { promoCode, target } = promoCodeDetails;
    try {
      await dispatch({
        type: types.APPLY_PROMO_CODE_DETAILS_REQUESTED,
      });
      const url = "/getPromoCode.json";
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        credentials: "include",
        body: new URLSearchParams({
          promoCode: promoCode,
          target: target,
        }),
      });
      let data = await response.json();
      await dispatch({
        type: types.APPLY_PROMO_CODE_DETAILS_RECEIVED,
        data,
      });
      console.log("Promo code successfully updated", data);
      return data;
    } catch (e) {
      console.error("exception", e);
      console.error("Error in applying promo code", e);
      await dispatch({
        type: types.APPLY_PROMO_CODE_DETAILS_FAILED,
      });
    }
  };
}

export function updateClientDetails(clientDetails) {
  return async function (dispatch) {
    console.log("Saving client details...", clientDetails);
    const { clientName, clientEmail, clientWebsite, clientId } = clientDetails;
    console.log("clientName=", clientName);
    console.log("clientEmail=", clientEmail);
    console.log("clientWebsite=", clientWebsite);
    try {
      await dispatch({
        type: types.UPDATE_CLIENT_DETAILS_REQUESTED,
      });
      const url = "/update-client-details.json";
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        credentials: "include",
        body: new URLSearchParams({
          clientId: clientId,
          clientName: clientName,
          clientEmail: clientEmail,
          clientWebsite: clientWebsite,
        }),
      });
      let data = await response.json();
      await dispatch({
        type: types.UPDATE_CLIENT_DETAILS_RECEIVED,
        data,
      });
      console.log("Client Details Saved", data);
      return data;
    } catch (e) {
      console.error("exception", e);
      console.error("Error in client Details updating", e);
      await dispatch({
        type: types.UPDATE_CLIENT_DETAILS_FAILED,
      });
    }
  };
}

export function handleAccountToggles(type) {
  return async function (dispatch, getState) {
    console.log("Updating Selected account type ", type);
    setTimeout(async () => {
      try {
        const useDummyData =
          window.lxrOptions && window.lxrOptions.useDummyData;
        await dispatch({
          type: types.UPDATE_SELECTED_ACCOUNT_REQUESTED,
        });
        let url = "/update-account-toggles.json";
        let response = await fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          credentials: "include",
          body: new URLSearchParams({ type: type }),
        });
        let data = await response.json();
        console.log("updated Selected Received", data);
        await dispatch({
          type: types.UPDATE_SELECTED_ACCOUNT_RECEIVED,
          data,
        });
        console.log(
          "data.error && data.error ==",
          data.error && data.error == ""
        );

        return data;
      } catch (e) {
        dispatch({ type: types.UPDATE_SELECTED_ACCOUNT_FAILED });
      }
    }, 0);
  };
}
