import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Typography, Grid, Card, CardContent, Button } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { GreenButton, DownloadReportButton } from "../buttons";
import Paper from "@mui/material/Paper";
import { SubSectionCardHeader } from "../siteGrader/templates";
import classNames from "classnames";
import { ErrorTypography } from "../typography";
import { CircularProgress } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Snackbar from "../snackbars";
import icons from "../icons";

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="xl" />;

const styles = (theme) => ({
  card: {
    width: "100%",
    boxShadow: "4px 4px 18px rgba(0, 0, 0, 0.06)",
    border: "1px solid " + theme.palette.common.border,
    borderRadius: "6px",
    height: 221,
    [theme.breakpoints.down("xl")]: {
      overflowY: "scroll",
      // height: 235,
    },
  },
  Grid: {
    padding: theme.spacing(2),
    boxShadow: "unset",
    // width: "100%"
  },
  cardTitle: {
    fontFamily: "Montserrat",
    color: theme.palette.text.secondary,
    fontSize: "1rem",
    fontWeight: "bold",
    textAlign: "left",
    padding: "0px",
  },
  paper: {
    boxShadow: "unset",
  },
  text: {
    opacity: "0.5",
    fontSize: 16,
  },
});

class DetailedReport extends React.PureComponent {
  render() {
    const {
      classes,
      siteGrader,
      clientUpdateCheckError,
      handleClose,
      seoReportStatusLoading,
      alert,
      handleChange,
      width,
    } = this.props;
    const domainId =
      siteGrader != null && siteGrader.domainId ? siteGrader.domainId : null;

    return (
      <Card className={classes.card}>
        <CardContent>
          <Typography align={"left"} color={"textPrimary"} variant={"body2"}>
            <b>Report</b>
          </Typography>
          {/*<br/>*/}
          {/*<br/>*/}
          <Grid container justifyContent={"center"} alignItems={"center"}>
            {/*9DA6B2*/}
            <Grid item xs={12} sm={12} md={12}>
              <Typography
                color={"textPrimary"}
                variant={"body1"}
                className={classes.text}
              >
                <b>Want a detailed report?</b>
              </Typography>
              <Typography
                color={"textPrimary"}
                variant={"body1"}
                className={classes.text}
              >
                <b>
                  Download our detailed SEO audit report with 62+ SEO
                  parameters.
                </b>
              </Typography>
            </Grid>
            <Grid item md={12}>
              {width == "sm" || width == "xs" ? (
                <br />
              ) : (
                <Fragment>
                  <br />
                  <br />
                  <br />
                </Fragment>
              )}

              <Grid
                container
                justifyContent={"space-around"}
                alignItems={"center"}
              >
                <Grid item xs={12} sm={12} md={7}>
                  {clientUpdateCheckError != null &&
                  clientUpdateCheckError != "" ? (
                    <ErrorTypography>{clientUpdateCheckError}</ErrorTypography>
                  ) : null}
                  {seoReportStatusLoading == true ? (
                    <Typography align={"center"}>
                      <CircularProgress />
                    </Typography>
                  ) : (
                    <Typography
                      variant={"subhead"}
                      className={classes.reportContentStyle}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Checkbox
                        checked={this.props.siteGrader.seoReportStatus}
                        // onChange={event => this.setState({ dense: event.target.checked })}
                        onChange={(event) => handleChange(event)}
                        value={"SendMeMonthlyReport"}
                        label="SEND ME MONTHLY REPORT"
                        // className={classes.checkBoxStyles}
                        // color={"#4AA459"}
                      />
                      <Typography
                        color={"textPrimary"}
                        style={{ opacity: 0.5, fontWeight: "900" }}
                        className={classes.checkBoxContentStyle}
                      >
                        Send me every month on my email.
                      </Typography>
                    </Typography>
                  )}
                  <Snackbar
                    verticalAlign={"bottom"}
                    horizontalAlign={"right"}
                    open={alert}
                    // duration={3000}
                    handleClose={() => handleClose()}
                    onClose={() => handleClose()}
                    variant={clientUpdateCheckError != "" ? "error" : "success"}
                    message={
                      clientUpdateCheckError != ""
                        ? "Something Went Wrong, Please Try again."
                        : "Monthly report status updated Successfully."
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={5}>
                  <Typography align={"center"}>
                    <a
                      href={
                        "/download-site-grader-report.html?domainId=" + domainId
                      }
                      style={{ textDecoration: "none" }}
                    >
                      <DownloadReportButton
                        variant={"contained"}
                        size={"large"}
                      >
                        Download Report
                      </DownloadReportButton>
                    </a>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

DetailedReport.propTypes = {};

export default withWidth()(withStyles(styles)(DetailedReport));
