import React, { Component } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import { Typography } from "@mui/material/index";
import icons from "../../components/icons";
import withStyles from '@mui/styles/withStyles';

const styles = (theme) => ({});

class Step3 extends React.PureComponent {
  render() {
    const { jumpstartInfo, theme, userId } = this.props;
    const { statusMessage, authorizationUrl } = jumpstartInfo;
    return (
      <Grid container justifyContent={"center"}>
        <Grid item md={12}>
          <Typography align={"center"}> {icons.accountLive}</Typography>
        </Grid>
        <Grid item md={12}>
          <Typography
            variant={"body1"}
            align={"center"}
            color={"textPrimary"}
            gutterBottom
          >
            We’ve notified the team to upload your content to Google Ads in a
            paused state. Your Customer Success Associate will be reaching out
            in the next few days to set up a Go-Live call!
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

Step3.propTypes = {};

export default withStyles(styles, { withTheme: true })(Step3);
