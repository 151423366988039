import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "../jumpstart.css";
import { JsSectionTypography } from "../../../components/typography";
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Select,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { JsAddUserLabelTypography } from "../../../components/typography";
import { JsBlackContainedBtn } from "../../../components/buttons";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";

function OptionalSettings(props) {
  const { handleCloseIcon } = props;
  const [geography, setGeography] = useState("");
  const [gender, setGender] = useState("");
  const [ageGroup, setAgeGroup] = useState("");

  const handleGeographyChange = (e) => {
    setGeography(e.target.value);
  };
  const handleGenderChange = (event) => {
    const value = event.target.value;

    // Toggle the state if the clicked checkbox is already checked
    setGender((prevGender) => (prevGender === value ? "" : value));
  };

  const handleAgeGroupChange = (event) => {
    const value = event.target.value;

    // Toggle the state if the clicked checkbox is already checked
    setAgeGroup((prevGender) => (prevGender === value ? "" : value));
  };

  const handleButtonClick = () => {
    handleCloseIcon();
  };
  return (
    <div>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        style={{ padding: "2%", borderBottom: "1px solid #E6E6E6" }}
      >
        <Grid item xs={11}>
          <JsSectionTypography style={{ fontSize: "1rem" }}>
            Optional Settings ( for Ad Copy Generation )
          </JsSectionTypography>
        </Grid>
        <Grid item xs={1}>
          <IconButton aria-label="close" onClick={handleCloseIcon}>
            <CloseIcon color="secondary" />
          </IconButton>
        </Grid>
      </Grid>
      <div>
        <Grid
          container
          direction="row"
          columnSpacing={2}
          rowSpacing={2}
          style={{ padding: "2%" }}
        >
          <Grid
            item
            xs={12}
            style={{
              backgroundColor: "#FFFDEA59",
              color: "#C8B511",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <InfoOutlinedIcon />
            <Typography variant="subtitle2" style={{ paddingLeft: "2%" }}>
              Please share the following details related to the account. The
              given information will be used with Generative AI to create ad
              copy assets for the account in the next step.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <label htmlFor="themeName">
              <JsAddUserLabelTypography>
                <span className="numberContainer">1</span>Target Geography
              </JsAddUserLabelTypography>
            </label>
          </Grid>
          <div style={{ width: "100%", paddingLeft: "7%", paddingRight: "2%" }}>
            <FormControl sx={{ m: 1, minWidth: 120 }} fullWidth>
              <Select
                value={geography}
                onChange={handleGeographyChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={{
                  height: 40 /* Set height to 40px */,
                  fontSize: "0.875rem" /* Set font size to 0.875 rem */,
                  color: "#747474",
                  borderRadius: 0,
                }}
              >
                <MenuItem value="" sx={{ fontSize: "0.875rem" }}>
                  Select target geography
                </MenuItem>
                <MenuItem value={10} sx={{ fontSize: "0.875rem" }}>
                  United States
                </MenuItem>
                <MenuItem value={20} sx={{ fontSize: "0.875rem" }}>
                  Australia
                </MenuItem>
                <MenuItem value={30} sx={{ fontSize: "0.875rem" }}>
                  United Kingdom
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          <Grid item xs={12}>
            <Grid container direction="row" alignItems="center">
              <Grid item xs={3}>
                <JsAddUserLabelTypography>
                  <span className="numberContainer">2</span>Gender
                </JsAddUserLabelTypography>
              </Grid>
              <Grid item xs={9} style={{ paddingBottom: "2px" }}>
                <FormControl component="fieldset">
                  <FormGroup aria-label="position" row>
                    <FormControlLabel
                      label={
                        <Typography
                          variant="subtitle2"
                          style={{ color: "#747474" }}
                        >
                          Male
                        </Typography>
                      }
                      control={
                        <Checkbox
                          checked={gender === "Male"}
                          onChange={handleGenderChange}
                          sx={{
                            p: 0,
                            color: "#9A9A9A",
                          }}
                        />
                      }
                      labelPlacement="end"
                      value="Male"
                    />
                    <FormControlLabel
                      label={
                        <Typography
                          variant="subtitle2"
                          style={{ color: "#747474" }}
                        >
                          Female
                        </Typography>
                      }
                      control={
                        <Checkbox
                          checked={gender === "Female"}
                          onChange={handleGenderChange}
                          sx={{
                            p: 0,
                            color: "#9A9A9A",
                          }}
                        />
                      }
                      labelPlacement="end"
                      value="Female"
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row" alignItems="center">
              <Grid item xs={3}>
                <JsAddUserLabelTypography>
                  <span className="numberContainer">3</span>Age Group
                </JsAddUserLabelTypography>
              </Grid>
              <Grid item xs={9} style={{ paddingBottom: "2px" }}>
                <FormControl component="fieldset">
                  <FormGroup aria-label="position" row>
                    <FormControlLabel
                      label={
                        <Typography
                          variant="subtitle2"
                          style={{ color: "#747474" }}
                        >
                          18-24
                        </Typography>
                      }
                      control={
                        <Checkbox
                          checked={ageGroup === "18-24"}
                          onChange={handleGenderChange}
                          sx={{
                            p: 0,
                            color: "#9A9A9A",
                          }}
                        />
                      }
                      labelPlacement="end"
                      value="18-24"
                    />
                    <FormControlLabel
                      label={
                        <Typography
                          variant="subtitle2"
                          style={{ color: "#747474" }}
                        >
                          25-34
                        </Typography>
                      }
                      control={
                        <Checkbox
                          checked={ageGroup === "25-34"}
                          onChange={handleGenderChange}
                          sx={{
                            p: 0,
                            color: "#9A9A9A",
                          }}
                        />
                      }
                      labelPlacement="end"
                      value="25-34"
                    />
                    <FormControlLabel
                      label={
                        <Typography
                          variant="subtitle2"
                          style={{ color: "#747474" }}
                        >
                          35-44
                        </Typography>
                      }
                      control={
                        <Checkbox
                          checked={ageGroup === "35-44"}
                          onChange={handleGenderChange}
                          sx={{
                            p: 0,
                            color: "#9A9A9A",
                          }}
                        />
                      }
                      labelPlacement="end"
                      value="35-44"
                    />
                    <FormControlLabel
                      label={
                        <Typography
                          variant="subtitle2"
                          style={{ color: "#747474" }}
                        >
                          45-54
                        </Typography>
                      }
                      control={
                        <Checkbox
                          checked={ageGroup === "45-54"}
                          onChange={handleGenderChange}
                          sx={{
                            p: 0,
                            color: "#9A9A9A",
                          }}
                        />
                      }
                      labelPlacement="end"
                      value="45-54"
                    />
                    <FormControlLabel
                      label={
                        <Typography
                          variant="subtitle2"
                          style={{ color: "#747474" }}
                        >
                          55-64
                        </Typography>
                      }
                      control={
                        <Checkbox
                          checked={ageGroup === "55-64"}
                          onChange={handleGenderChange}
                          sx={{
                            p: 0,
                            color: "#9A9A9A",
                          }}
                        />
                      }
                      labelPlacement="end"
                      value="55-64"
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={6} className="bottomPositionBtn">
            <JsBlackContainedBtn
            // onClick={handleButtonClick}
            >
              Done
            </JsBlackContainedBtn>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(OptionalSettings);
