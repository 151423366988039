import * as types from "../actions/actionTypes";
import * as _ from "lodash";
import {store, sumUpImpact} from "./common";
import {hasAdgroupLevel} from "./index";
// function calculateHistory(selectedDateRange, oldHistory, newHistory) {
//     let history = {...oldHistory};
//     history[selectedDateRange] = newHistory;
//     console.log("calculateHistory=", calculateHistory);
//     return history;
// }
const configAuditTasks = [13, 12, 14, 22, 11, 10];
const costSavingTasks = [19, 9, 7, 2, 5, 18, 21, 15, 6,];
const optimizationTasks = [8, 3, 16, 20, 1, 17, 4, 49];

function taskTypePriority(taskType) {
    if (configAuditTasks.includes(taskType)) return configAuditTasks.indexOf(taskType);
    if (costSavingTasks.includes(taskType)) return configAuditTasks.length + 1;
    return configAuditTasks.length + 2;
}

function groupActivityTaskData(taskDataList) {
    console.log("taskDataList======>==",taskDataList)
    let groupedTaskData = {
        "TaskType": _.chain(taskDataList)
            .groupBy("taskTypeId")
            .mapValues((list, taskTypeId) => ({
                summary: {
                    groupId: taskTypeId,
                    taskId: list[0].taskId,
                    displayStatus: list[0].displayStatus,
                    // title: `${list[0].taskName} (${taskTypeId})`,
                    title: `${list[0].taskName}`,
                    description: list[0].taskDescription,
                    impact: sumUpImpact(list),
                    totalCount: list.length,
                    taskTypePriority: taskTypePriority(taskTypeId / 1),
                    //===========================================
                    // GATD = groupActivityTaskData
                    actionStatusGATD: list[0].actionStatus,
                    actionDateGATD: list[0].actionDate,
                    //============================================
                   
                   
                },
                items: _.chain(list)
                    .groupBy("campaignId")
                    .mapValues((list) =>
                        hasAdgroupLevel(list[0].taskTypeId) ?
                            _.chain(list).groupBy("adGroupName").mapValues(list => _.map(list, "taskDataId")).value() :
                            _.map(list, "taskDataId"))
                    .value(),
                dataIdList: _.map(list, "taskDataId"),
            })).value(),
    };
    // console.log("groupedTaskData = ", groupedTaskData);
    /* This is Commented for campaign level requirement*/
    /*if (groupedTaskData.TaskType[33])
        groupedTaskData.TaskType[33].items = _.flatten(_.values(groupedTaskData.TaskType[33].items));
    if (groupedTaskData.TaskType[34])
        groupedTaskData.TaskType[34].items = _.flatten(_.values(groupedTaskData.TaskType[34].items));*/
    if (groupedTaskData.TaskType[44])
        groupedTaskData.TaskType[44].items = _.flatten(_.values(groupedTaskData.TaskType[44].items));
    if (groupedTaskData.TaskType[43])
        groupedTaskData.TaskType[43].items = _.flatten(_.values(groupedTaskData.TaskType[43].items));
    if (groupedTaskData.TaskType[57])
        groupedTaskData.TaskType[57].items = _.flatten(_.values(groupedTaskData.TaskType[57].items));
    console.log("groupedTaskData==activity", groupedTaskData);
    return groupedTaskData;
}

const defaultState = {
    errorMessage: "",
    weekTasks: {},
    ppcId: 0,
    history: [],
    kpiHistory: [],
    reportKPITypeUi: "RC",
    selectedDateRange: 1,
    tasksStatusCount: {},
    selectedTd: {},
}

export function activityReport(state = {
    isLoading: false,
    ...defaultState
}, action) {
    switch (action.type) {
        case types.ACTIVITY_REPORT_RECEIVED:
            let history = {...state.history};
            history[action.data.selectedDateRange] = action.data.history;
            console.log("calculateHistory=", history);
            let tasksStatusCount = {...state.tasksStatusCount}
            tasksStatusCount[action.data.selectedDateRange] = action.data.tasksStatusCount;
            let weekTasks = {...state.weekTasks}
            weekTasks[action.data.selectedDateRange] = action.data.weekTasks;
            let kPIHistory = {...state.kpiHistory}
            // console.log("kPIHistory=", kPIHistory)
            // let kPITypeUiMetricHistory = {...state.kpiHistory[action.data.selectedDateRange]}
            // console.log("kPITypeUiMetricHistory", kPITypeUiMetricHistory)
            // kPITypeUiMetricHistory[state.reportKPITypeUi] = action.data.kPIData[0];
            // console.log("kPITypeUiMetricHistory==2", kPITypeUiMetricHistory)
            kPIHistory[action.data.selectedDateRange] = action.data.kPIData[0];
            /*    let kPIHistory = {...state.kpiHistory}
                console.log("kPIHistory=", kPIHistory)
                let kPITypeUiMetricHistory = {...state.kpiHistory[action.data.selectedDateRange]}
                console.log("kPITypeUiMetricHistory", kPITypeUiMetricHistory)
                kPITypeUiMetricHistory[state.reportKPITypeUi] = action.data.kPIData[0];
                console.log("kPITypeUiMetricHistory==2", kPITypeUiMetricHistory)
                kPIHistory[action.data.selectedDateRange] = kPITypeUiMetricHistory;*/
            console.log("kPIHistory=final", kPIHistory);
            console.log("action.data.kPIData=", action.data.kPIData)
            return Object.assign({}, state, {
                isLoading: false,
                weekTasks: weekTasks,
                tasksStatusCount: tasksStatusCount,
                ppcId: action.data.ppcId,
                selectedDateRange: action.data.selectedDateRange,
                history: history,
                errorMessage: action.data.errorMessage,
                kpiHistory: kPIHistory,
            });
        case types.ACTIVITY_REPORT_REQUESTED:
            if (action.isPpIdChanged) {
                return Object.assign({}, state, {
                    isLoading: true,
                    ...defaultState
                });
            }
            return Object.assign({}, state, {
                isLoading: true,
            });
        case types.ACTIVITY_REPORT_FAILED:
        case types.ACTIVITY_REPORT_TASK_DATA_FAILED:
            return Object.assign({}, state, {
                isLoading: false,
                errorMessage: "Something went wrong"
            });
        case types.TOGGLE_WEEKLY_DATE_RANGE:
            return Object.assign({}, state, {
                isLoading: false,
                selectedDateRange: action.historyId
            });
        case types.ACTIVITY_REPORT_TASK_DATA_REQUESTED:
            return Object.assign({}, state, {
                isLoading: true,
                selectedTd: {},
            });
        case types.UPDATE_REPORT_KPI_TYPE_UI:
            return Object.assign({}, state, {
                reportKPITypeUi: action.KPIType,
            });
        case types.ACTIVITY_REPORT_TASK_DATA_RECEIVED:
            let selectedId = state.selectedTd;
            const selectedTask = action.data.task
            let selectedTaskData = action.data.taskData;
            let taskData = action.data.taskData;
            if (selectedTask.taskTypeId == 35) {
                const grouped = _.groupBy(selectedTaskData, "campaignId");
                taskData = _.map(grouped, (taskDataList) => ({
                    ...taskDataList[0],
                    taskDataList: taskDataList,
                    taskDataIdList: _.map(taskDataList, "taskDataId")
                }));
                console.log("TaskData cal", taskData);
            }
            taskData = (selectedTaskData && selectedTaskData.length != 0) ? selectedTaskData.map(td => ({
                ...td, ...selectedTask,
            })) : [];
            console.log("TaskData cal", taskData);
            selectedId[action.taskId] = groupActivityTaskData(taskData);

            console.log("selectedId", selectedId[action.taskId])
            return Object.assign({}, state, {
                isLoading: false,
                selectedTd: selectedId
            });
        default:
            return state;
    }
}

export function taskDataActivityById(state = {}, action) {
    switch (action.type) {
        case types.ACTIVITY_REPORT_TASK_DATA_RECEIVED: {
            const selectedTask = action.data.task
            let selectedTaskData = action.data.taskData;
            let taskData = action.data.taskData;
            taskData = (selectedTaskData && selectedTaskData.length != 0) ? selectedTaskData.map(td => ({
                ...td, ...selectedTask,
            })) : [];
            return _.keyBy(taskData, "taskDataId");
        }
        case types.ACTIVITY_REPORT_TASK_DATA_REQUESTED: {
            return Object.assign({}, state, {});
        }
        default:
            return state;
    }
}

export function kpiGraphNavigation(state = {
    selectedNodeKey: null
}, action) {
    switch (action.type) {
        case types.UPDATE_SELECTED_NODE_KEY: {
            return Object.assign({}, state, {
                selectedNodeKey: action.selectedNodeKey
            });
        }
        default:
            return state;
    }
}

export const idToTaskDataList = (idList, taskDataById = store.getState().taskDataActivityById) => idList.map(id => taskDataById[id]);

export function sumUpImpactOfIds(idList) {
    return sumUpImpact(idToTaskDataList(idList, store.getState().taskDataActivityById));
}

export function campaignIdActivityReportMap(state = {}, action) {
    switch (action.type) {
        case types.ACTIVITY_REPORT_TASK_DATA_RECEIVED:
            return _.keyBy(_.map(action.data.taskData, td => _.pick(td, ["campaignId", "campaignName", "campaignType"])), "campaignId");
        default:
            return state;
    }
}