import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import icons from "../../components/icons";
import { JsLoginTypography } from "../../components/typography";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
  CircularProgress,
} from "@mui/material";
import {
  NewOrangeContainedBtn,
  WHSOrangeButton,
} from "../../components/buttons";
import "./jumpstart.css";
import ForgotPassword from "./forgotPassword";

function JsUserLoginDetails(props) {
  const {
    handleEmailChange,
    handlePasswordChange,
    handleSignIn,
    error,
    emailFormatError,
    handleRememberChange,
    loading,
  } = props;

  const loggedOutStorage = sessionStorage.getItem("loggedOut");
  const [loggedOut, setLoggedOut] = useState(null);

  useEffect(() => {
    if (loggedOutStorage) {
      setLoggedOut(loggedOutStorage);
      sessionStorage.removeItem("loggedOut");
    }
  }, [loggedOut]);

  const [isCapsLockOn, setIsCapsLockOn] = useState(false);

  const [forgotPassword, setForgotPassword] = useState(false);
  const handleForgotPassword = () => {
    setForgotPassword(!forgotPassword);
  };

  const handleKeyUp = (event) => {
    const isCapsLockOn = event.getModifierState("CapsLock");
    setIsCapsLockOn(isCapsLockOn);
    if (event.key === "Enter") {
      event.preventDefault();
      handleSignIn();
    }
  };

  return (
    <Grid container direction="row" rowSpacing={2} className="loginContent">
      <Grid item xs={12}>
        <Typography align="center"> {icons.JumpstartLogo}</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        columnSpacing={1}
      >
        <Grid item>
          <Typography fontWeight="500" letterSpacing={"9%"} color="#707A86">
            Powered By
          </Typography>
        </Grid>
        <Grid item>{icons.LxrguideLogo}</Grid>
      </Grid>
      <Grid item xs={12}>
        <JsLoginTypography>
          Paid Search Management Solution for SMBs
        </JsLoginTypography>
      </Grid>
      <Grid item xs={12}></Grid>
      {forgotPassword === true ? (
        <ForgotPassword handleForgotPassword={handleForgotPassword} />
      ) : (
        <>
          <Grid item xs={12}>
            <Typography variant="body1" align="center" fontWeight="500" color="#1E334E">
              Sign in using valid credentials to continue
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <label>
              <Typography variant="subtitle2" color="#747474">
                Email
              </Typography>
            </label>
            <input
              type="email"
              placeholder="Type your email here"
              id="email"
              onChange={handleEmailChange}
              className="custom-input"
            />
          </Grid>
          <Grid item xs={12}>
            <label>
              <Typography variant="subtitle2" color="#747474">
                Password
              </Typography>
            </label>
            <input
              type="password"
              placeholder="Type your password here"
              id="password"
              onChange={handlePasswordChange}
              className="custom-input"
              onKeyUp={handleKeyUp}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              // justifyContent="space-between"
              // justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="secondary"
                        onChange={handleRememberChange}
                      />
                    }
                    label={
                      <Typography variant="subtitle2" color="#A4A4A4">
                        Remember Me
                      </Typography>
                    }
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Button
                  variant="text"
                  style={{
                    textTransform: "none",
                    color: "#A4A4A4",
                    float: "right",
                  }}
                  onClick={handleForgotPassword}
                >
                  Forgot Password?
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button
              disabled={loading}
              style={{
                backgroundColor: "#F58120",
                color: "#FFF",
                height: "41px",
                width: "100%",
                textTransform: "none",
              }}
              onClick={handleSignIn}
            >
              {loading ? (
                <CircularProgress color="inherit" size="1.5rem" />
              ) : (
                <Typography variant="body1" fontWeight="600">
                  {icons.SignIn}&nbsp;Sign In
                </Typography>
              )}
            </Button>
          </Grid>
          {isCapsLockOn ? (
            <Grid item xs={12}>
              <Typography color="#1E344E" align="center">
                Caps lock is on
              </Typography>
            </Grid>
          ) : (
            ""
          )}
          {loggedOut ? (
            <Grid item xs={12}>
              <Typography color="error" align="center">
                Session Expired
              </Typography>
            </Grid>
          ) : (
            ""
          )}

          <Grid item xs={12}>
            {emailFormatError != "" && emailFormatError != undefined ? (
              <Typography color="error" align="center">
                {emailFormatError}
              </Typography>
            ) : error ? (
              <Typography color="error" align="center">
                The email or password is incorrect.
              </Typography>
            ) : (
              ""
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
}
export default JsUserLoginDetails;
