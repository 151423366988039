import React, { Component, Fragment } from "react";
import PropTypes, { number } from "prop-types";
import { connect } from "react-redux";
import {
  fetchSubscriptions,
  unSubscribePack,
} from "../../actions/fetchSubscriptions";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import icons from "../../components/icons";
import withStyles from "@mui/styles/withStyles";
import styles from "../../assets/jss/subscription/subscriptionPageStyles";
import {
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  Table,
  TableBody,
  TableHead,
  TableRow,
  DialogTitle,
  Button,
} from "@mui/material/index";
import {
  CustomTableCell,
  CustomTableCellHead,
  KpiType,
  SelectedTableCell,
} from "../accountSettings/account-settings";
import { CircularProgress, DialogContentText } from "@mui/material";
import MySnackbarWrapper from "../snackbars";
import { withRouter } from "react-router";
import { HyperLinks } from "../../components/onDemand/view-details";
import Paragraph from "../../NewInterfacePages/paragraph/paragraph";

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="xl" />;

let moment = require("moment");

export const PopUpCommonBtn = withStyles((theme) => ({
  root: {
    fontWeight: "400",
    color: theme.palette.text.primary,
  },
}))(Button);
const PopUpOrangeBtn = withStyles((theme) => ({
  root: {
    fontWeight: "bold",
    color: theme.palette.orangeColor,
  },
}))(Button);
const DialogText = withStyles((theme) => ({
  root: {
    fontWeight: "lighter",
    fontSize: 16,
    color: theme.palette.text.primary,
    fontFamily: "Lato",
  },
}))(DialogContentText);

class SubscriptionPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: false,
      message: "",
      variantType: "info",
      errorMsg: "",
      dialog: false,
      selectedId: null,
    };
  }

  componentDidMount() {
    // this.props.fetchUserSubscriptions();
  }

  handleCloseAlert = () => {
    this.setState({ alert: false, message: "", errorMsg: "" });
  };

  handleCancelAlert = (id) => {
    this.setState({ dialog: true, selectedId: id });
  };
  handleCancelAgree = () => {
    const { selectedId } = this.state;
    this.setState({ dialog: false });
    if (selectedId != null) this.handleSubscriptionDelete(selectedId);
  };

  handleClose = () => {
    this.setState({ dialog: false, selectedId: null });
  };

  handleSubscriptionDelete = async (id) => {
    let response = await this.props.unSubscribePack(Number(id));
    if (response != null && response && response.status) {
      this.setState({
        alert: true,
        message: "Unsubscribed Successfully",
        variantType: "success",
        selectedId: null,
      });
      const { subscriptions, history } = this.props;
      if (subscriptions != null && subscriptions.length == 0)
        history.push("/dashboard");
    } else if (response != null && response && response.status == false) {
      this.setState({
        alert: true,
        message: response.errorMsg,
        variantType: "error",
        selectedId: null,
      });
    }
  };

  render() {
    const { subscriptions, classes, width, isLoading, isUnsubscribing } =
      this.props;
    
    const { errorMsg, dialog } = this.state;
    console.log("subscriptionsData=", subscriptions);
    return (
      <div>
        <Paragraph
          para={
            "LXRGuide now offers a variety of On-Demand services. " +
            "We recognize that many of you may not have the In-house marketing " +
            "team to implement the powerful recommendations by our LXRGuide tool. " +
            "We are proud to introduce packages tailored to your marketing needs."
          }
        />

        <Card className={classes.card}>
          <CardContent>
            <Paper className={classes.paper}>
              <Typography
                color={"textPrimary"}
                variant={"h5"}
                component={"h5"}
                gutterBottom
              >
                <b>Subscriptions</b>
              </Typography>

              <Table>
                <TableHead>
                  <TableRow>
                    <CustomTableCellHead>Service</CustomTableCellHead>
                    <CustomTableCellHead>Status</CustomTableCellHead>
                    <CustomTableCellHead>Purchased Date</CustomTableCellHead>
                    <CustomTableCellHead>Next Payment Date</CustomTableCellHead>
                    <CustomTableCellHead>Unsubscribe</CustomTableCellHead>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading || isUnsubscribing ? (
                    <CircularProgress color={"secondary"} />
                  ) : (
                    <Fragment>
                      {subscriptions != null &&
                        subscriptions.map((sub) => (
                          <TableRow>
                            <CustomTableCell>
                              {sub.onDemandPricingPlan.packageName}
                            </CustomTableCell>
                            <CustomTableCell>{sub.status}</CustomTableCell>
                            <CustomTableCell>
                              {moment(sub.purchaseDate).format("ll")}
                            </CustomTableCell>
                            <CustomTableCell>
                              {moment(sub.endDate).format("ll")}
                            </CustomTableCell>
                            {/*<CustomTableCell align="center" style={{cursor: "pointer"}}
                                                                     onClick={() => this.handleSubscriptionDelete(sub.subscriptionId)}>{icons.deleteIcon}</CustomTableCell>*/}
                            <CustomTableCell align="center">
                              <HyperLinks
                                // onClick={() => this.handleSubscriptionDelete(sub.subscriptionId)}
                                onClick={() =>
                                  this.handleCancelAlert(sub.subscriptionId)
                                }
                                style={{ cursor: "pointer" }}
                                variant={"text"}
                              >
                                Cancel
                              </HyperLinks>
                            </CustomTableCell>
                          </TableRow>
                        ))}
                    </Fragment>
                  )}
                </TableBody>
              </Table>
              <br />
              <br />
            </Paper>
          </CardContent>
        </Card>
        <MySnackbarWrapper
          open={this.state.alert || (errorMsg != null && errorMsg != "")}
          onClose={this.handleCloseAlert}
          message={
            errorMsg != null && errorMsg != "" ? errorMsg : this.state.message
          }
          verticalAlign={"bottom"}
          horizontalAlign={"right"}
          duration={6000}
          variant={
            errorMsg != null && errorMsg != ""
              ? "error"
              : this.state.variantType
          }
        />
        <Dialog
          open={dialog}
          // onClick={stopPropagation}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure you want to cancel the Subscription?"}
          </DialogTitle>
          {/* <DialogContent>
                        <DialogText id="alert-dialog-description">
                            <b>Are you sure you want to cancel the Subscription?</b>
                        </DialogText>
                    </DialogContent>*/}
          <DialogActions>
            <PopUpCommonBtn onClick={this.handleClose}>NO</PopUpCommonBtn>
            <PopUpOrangeBtn onClick={this.handleCancelAgree} autoFocus>
              YES
            </PopUpOrangeBtn>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

SubscriptionPage.propTypes = {};
const mapDispatchToProps = (dispatch) => ({
  fetchUserSubscriptions: () => dispatch(fetchSubscriptions()),
  unSubscribePack: (id) => dispatch(unSubscribePack(id)),
});
const mapStateToProps = (state, ownProps) => ({
  subscriptions: state.subscriptionsData.subscriptions,
  // isLoading: state.subscriptionsData.isLoading,
  isLoading: state.perf.isLoading,
  isUnsubscribing: state.subscriptionsData.isUnsubscribing,
});

const _SubscriptionPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionPage);

export default withRouter(withWidth()(withStyles(styles)(_SubscriptionPage)));
