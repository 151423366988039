import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import icons from "../../components/icons";
import PropTypes from "prop-types";
import React from "react";
import withStyles from "@mui/styles/withStyles";
import ListItemIcon from "@mui/material/ListItemIcon";

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="xl" />;

const styles = (theme) => ({
  desSection: {
   
    [theme.breakpoints.down("xl")]: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(8),
      marginRight: theme.spacing(8),
    },
  },
  des: {
    paddingLeft: theme.spacing(2),
  },

  TDes: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    background: theme.palette.taskfb,
    boxShadow: "unset",
    border: "1px solid" + theme.palette.taskfd2,
    boxSizing: "border-box",
    borderRadius: 6,
  },

  textDes: {
    opacity: 0.6,
  },
  paper: {
    // background: theme.palette.common.white,
    boxShadow: "4px 4px 18px rgba(0, 0, 0, 0.06)",
    borderRadius: 6,
    padding: theme.spacing(2),
    [theme.breakpoints.down("xl")]: {
      width: 400,
      overflowX: "scroll",
    },
  },
});

function holidayParagraph(props) {
  const { para, classes, width } = props;
  return (
    <div className={classes.desSection} >
      <Paper className={classes.TDes}>
        <Grid
          container
          alignItems={width == "sm" || width == "xs" ? "flex-start" : "center"}
        >
          <Grid item sm={1} xs={1} md={1}>
            <Typography align={"center"}>
              {width == "sm" || width == "xs"
                ? icons.lightTaskResizable(40)
                : icons.lightTaskResizable(60)}
              &nbsp;
            </Typography>
          </Grid>
          <Grid item sm={11} xs={11} md={11} className={classes.des}>
            <Typography variant="h6" color="inherit" noWrap gutterBottom>
              <b>
                Enjoy a successful holiday season with expert campaign
                management help
              </b>
            </Typography>
            <Typography variant={"h4"} className={classes.textDes}>
              {/* {para} */}
              {/* <ul>
                <li>Boost Sales</li>
                <li>Compete to Win</li>
                <li>Eliminate Ad Management Stress</li>


              </ul> */}
              {/* <ul>
                <li style={{listStyleType:"none" }}>
                 
                  <ListItemIcon style={{ margin: 0 }}>{icons.dot}</ListItemIcon>
                  Boost Sales
                </li>
                <li style={{listStyleType:"none" }}>
                  
                  <ListItemIcon style={{ margin: 0 }}>{icons.dot}</ListItemIcon>
                  Boost Sales
                </li>
                <li style={{listStyleType:"none" }}>
                
                  <ListItemIcon style={{ margin: 0 }}>{icons.dot}</ListItemIcon>
                  Boost Sales
                </li>
               
              </ul> */}
              <ListItemIcon style={{ margin: 0,minWidth:"2%" }}>{icons.dot}</ListItemIcon>
              Boost Sales <br />
              <ListItemIcon style={{ margin: 0,minWidth:"2%" }}>{icons.dot}</ListItemIcon>
              Compete to Win <br />
              <ListItemIcon style={{ margin: 0,minWidth:"2%" }}>{icons.dot}</ListItemIcon>
              Eliminate Ad Management Stress <br />
            </Typography>
          </Grid>
        </Grid>
      </Paper>
      <br />
    </div>
  );
}

holidayParagraph.propTypes = {
  classes: PropTypes.any,
  width: PropTypes.any,
};

export default withWidth()(withStyles(styles)(holidayParagraph));
