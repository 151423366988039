import React, { Fragment } from "react";
import {
  CircularProgress,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import CommonStyles from "../../assets/jss/reports/commonStyles";
import icons from "../../components/icons";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Card } from "@mui/material/index";
import PerformanceGraphStyles from "../../assets/jss/reports/reportsStyles";
import * as _ from "lodash";
import {
  graphSpacing,
  IsLoading,
  yAxisFontSize,
  yAxisFormatter,
} from "./performanceGraph";

function QualityGraph(props) {
  const {
    classes,
    data,
    kpiType,
    isLoading,
    theme,
    tabValue,
    handleChange,
    handleReportSent,
    handleRefs,
    handleDownloadReport,
  } = props;
  let metric = "revenue";
  console.log("KPI type is metric: ", kpiType);
  console.log("Quality Performance data is: ", data);
  if (kpiType != null && kpiType != undefined) {
    if (kpiType == "RC" || kpiType == "CR") {
      metric = "revenue";
    } else if (kpiType == "CPL") {
      metric = "leads";
    } else {
      metric = "orders";
    }
  }
  let finalData = !_.isEmpty(data)
    ? _.map(data.data, (value, index) => ({
        index: index,
        value: value,
      }))
    : {};
  console.log("finalData value is: ", finalData);

  const CommaFormattedNumber = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <Paper className={classes.paper}>
      <Grid
        container
        justifyContent={"space-between"}
        style={{ background: "#ffffff" }}
      >
        <Grid item sm={6} xs={6} md={6}>
          <Typography color={"textPrimary"} variant={"h3"}>
            <b>Quality Score</b>
          </Typography>
        </Grid>
        {isLoading ? (
          <IsLoading classes={classes} />
        ) : (
          <Fragment>
            {_.isEmpty(data) || (data && data.data.length == 0) ? (
              <Grid item md={12}>
                <Typography
                  align={"center"}
                  color={"textPrimary"}
                  variant={"body1"}
                >
                  No data to display
                </Typography>
              </Grid>
            ) : (
              <Fragment>
                <Grid item md={6}>
                  <Typography align={"right"}>
                    <span
                      className={classes.icons}
                      onClick={() => handleReportSent("quality")}
                    >
                      {icons.plane}
                    </span>
                    &nbsp; &nbsp; &nbsp;
                    <span
                      className={classes.icons}
                      onClick={(event) =>
                        handleDownloadReport("quality", event)
                      }
                    >
                      {icons.download}
                    </span>
                  </Typography>
                </Grid>
                <Grid item sm={12} xs={12} md={12}>
                  <Grid container>
                    <Grid item md={6}>
                      <Grid
                        container
                        alignItems={"center"}
                        justifyContent={"flex-start"}
                        spacing={2}
                        className={classes.legends}
                      >
                        <Grid item className={classes.legM}>
                          {icons.square(theme.palette.common.lightBlue)}
                        </Grid>
                        <Grid item>
                          <Typography
                            variant={"body2"}
                            color={"textPrimary"}
                            style={{
                              fontWeight: "500",
                              textTransform: "capitalize",
                            }}
                          >
                            Keyword Count
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Card className={classes.card}>
                    <ResponsiveContainer width="100%" height={360}>
                      <ComposedChart
                        data={finalData}
                        ref={(ref) => ref}
                        margin={graphSpacing}
                      >
                        <XAxis
                          dataKey="index"
                          tick={{ fontSize: yAxisFontSize }}
                        />
                        <CartesianGrid strokeDasharray="0 0" vertical={false} />
                        <YAxis
                          axisLine={false}
                          tickFormatter={yAxisFormatter}
                          // width={70}
                          tick={{ fontSize: yAxisFontSize }}
                        />
                        <Tooltip
                          labelFormatter={(value) =>
                            CommaFormattedNumber(value)
                          }
                        />
                        <Bar
                          dataKey={"value"}
                          name="Keyword Count"
                          fill="#1098F7"
                        />
                      </ComposedChart>
                    </ResponsiveContainer>
                  </Card>
                </Grid>
              </Fragment>
            )}
          </Fragment>
        )}
      </Grid>
    </Paper>
  );
}

export default withStyles(CommonStyles, { withTheme: true })(QualityGraph);
