import * as types from "../actions/actionTypes";
import * as _ from "lodash";

export function userInformation(state = {
    userInfo: {},
    selectedClientInfo: {},
    selectedAccountInfo: {},
    isLoading: false,
    error: null,
    notificationList: [],
    // userUnReadCount: 0,
    // unReadCount: 0,
    // clientNotifications: 0,
    isAccountLoading: false,
    isClientLoading: false,
    isAccountDetailsLoading: false,
    isClientDetailsLoading: false,
    isAdminUserDashboard: false,
    isUserProfileLoading: false,
}, action) {
    switch (action.type) {
        case types.USER_INFORMATION_REQUESTED:
            return Object.assign({}, state, {
                isLoading: true,
            });
        case types.UPDATE_ACCOUNT_DETAILS_REQUESTED:
            return Object.assign({}, state, {
                isAccountDetailsLoading: true,
            });
        case types.UPDATE_SELECTED_ACCOUNT_REQUESTED:
            return Object.assign({}, state, {
                isAccountLoading: true,
            });
        case types.UPDATE_CLIENT_DETAILS_REQUESTED:
            return Object.assign({}, state, {
                isClientDetailsLoading: true,
            });
        case types.UPDATE_SELECTED_CLIENT_REQUESTED:
            return Object.assign({}, state, {
                isClientLoading: true,
            });
        case types.UPDATE_PROFILE_DETAILS_REQUESTED:
            return Object.assign({}, state, {
                isUserProfileLoading: true,
            });
        case types.APPLY_PROMO_CODE_DETAILS_REQUESTED:
            return Object.assign({}, state, {
                isUserProfileLoading: true,
            });
        case types.USER_INFORMATION_RECEIVED:
        case types.UPDATE_SELECTED_ACCOUNT_RECEIVED:
        case types.UPDATE_SELECTED_CLIENT_RECEIVED:
            return Object.assign({}, state, {
                isLoading: false,
                isAccountLoading: false,
                isClientLoading: false,
                ...action.data
            });
        case types.UPDATE_ACCOUNT_DETAILS_RECEIVED:
            action.data.selectedAccountInfo
            return Object.assign({}, state, {
                isLoading: false,
                isAccountDetailsLoading: false,
                ...action.data

            });
        case types.UPDATE_CLIENT_DETAILS_RECEIVED:
            action.data.selectedClientInfo
            return Object.assign({}, state, {
                isLoading: false,
                isClientDetailsLoading: false,
                ...action.data

            });
        case types.UPDATE_PROFILE_DETAILS_RECEIVED:
            action.data.selectedClientInfo
            return Object.assign({}, state, {
                isLoading: false,
                isUserProfileLoading: false,
                ...action.data

            });
        case types.APPLY_PROMO_CODE_DETAILS_RECEIVED:
            return Object.assign({}, state, {
                userInfo: action.data.userInfo,
                isLoading: false,
                isUserProfileLoading: false,
                // ...action.data

            });
        case types.USER_INFORMATION_REQUEST_FAILED:
            return Object.assign({}, state, {
                isLoading: false,
                isAccountLoading: false,
                isClientLoading: false,
                error: "error in fetching user details"
            });
        case types.UPDATE_SELECTED_ACCOUNT_FAILED:
            return Object.assign({}, state, {
                isLoading: false,
                isAccountLoading: false,
                error: "error in updating Selected account details"
            });
        case types.UPDATE_ACCOUNT_DETAILS_FAILED:
            return Object.assign({}, state, {
                isLoading: false,
                isAccountDetailsLoading: false,
                error: "error in updating Account details"
            });
        case types.UPDATE_CLIENT_DETAILS_FAILED:
            return Object.assign({}, state, {
                isLoading: false,
                isClientDetailsLoading: false,
                error: "error in updating client details"
            });
        case types.UPDATE_SELECTED_CLIENT_FAILED:
            return Object.assign({}, state, {
                isLoading: false,
                isClientLoading: false,
                error: "error in updating Selected Client details"
            });
        case types.UPDATE_PROFILE_DETAILS_FAILED:
            return Object.assign({}, state, {
                isLoading: false,
                isUserProfileLoading: false,
                error: "error in updating User Profile details"
            });
        case types.APPLY_PROMO_CODE_DETAILS_FAILED:
            return Object.assign({}, state, {
                isLoading: false,
                isUserProfileLoading: false,
                error: "error in applying promo code from User Profile settings"
            });
        case types.DELETE_CARD_DETAILS_RECEIVED:
            return Object.assign({}, state, {
                userInfo: action.data.userInfo
            });
        case types.UNSUBSCRIBE_ACCOUNT_RECEIVED:
        case types.RESUME_ACCOUNT_RECEIVED:
        case types.SUBSCRIBE_DETAILS_RECEIVED:
        case types.ADD_CARD_DETAILS_RECEIVED:
            return Object.assign({}, state, {
                userInfo: action.data.userInfo,
            });
        /* case types.UPDATE_JS_ACCOUNT_RECEIVED:
         return Object.assign({}, state, {
             isLoading: false,
             userInfo: action.data.userInfo
         });*/
        default:
            return state;
    }
}