import React, { Component } from "react";
import PropTypes from "prop-types";
import { CompactCardContent } from "../cards";
import { Card, Grid, Typography } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import ReactSpeedometer from "react-d3-speedometer";
import {
  SubSectionCardHeader,
  calculateAdsPerformanceContent,
} from "./templates";
import { connect } from "react-redux";

const performanceScoreStyles = (theme) => ({
  count: {
    color: theme.palette.hyperLink,
  },
  para: {
    fontSize: 14,
    fontWeight: "300",
    color: theme.palette.graderGraphColors.text,
  },
  smPara: {
    fontSize: 12,
    fontWeight: "300",
    color: theme.palette.graderGraphColors.text,
  },
  grnHead: {
    fontSize: 18,
    color: theme.palette.graderGraphColors.green,
  },
  text: {
    fontSize: 14,
    color: theme.palette.graderGraphColors.text,
  },
  redHead: {
    fontSize: 15,
    color: theme.palette.graderGraphColors.red,
    fontFamily: "Montserrat",
  },
  benMarkTitle: {
    fontSize: 14,
    color: theme.palette.graderGraphColors.text,
    fontFamily: "Montserrat",
  },
});

class PerformanceScore extends React.PureComponent {
  render() {
    const { data, classes } = this.props;
    const overAllScore = data.googleAdsReportData
      ? data.googleAdsReportData.overallScore
      : 0;
    return (
      <Card className={"fullHeight"}>
        {/*<SubSectionCardHeader title={"KPI Graph"} subheader={`${KpiString[kpiType]} for last ${selectedDateRange} days`}/>*/}
        <SubSectionCardHeader title={"Google Ads Performance Score"} />
        <CompactCardContent
          className={"compactVertical"}
          style={{ minHeight: "43vh" }}
        >
          <Grid container justifyContent={"center"}>
            <Grid item md={12}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <ReactSpeedometer
                  width={200}
                  height={130}
                  value={overAllScore}
                  needleColor="#00000"
                  maxValue={100}
                  startColor={"#FF0000"}
                  endColor={"#00FF00"}
                  segments={4}
                  needleTransitionDuration={4000}
                  segmentColors={[
                    "#FF0000",
                    "#FF7F00",
                    "#FFFF00",
                    "#7FFF00",
                    // "#00FF00",
                  ]}
                  // ringWidth={10}
                  // maxSegmentLabels={5}
                  // forceRender={true}
                  // fluidWidth={true}
                  textColor={"#333333"}
                  needleHeightRatio={1}
                  currentValueText={"${value}/100"}
                  // currentValuePlaceholderStyle="#{value}"
                />
              </div>
            </Grid>
            <Grid item md={12}>
              <Typography
                component={"p"}
                className={classes.benMarkTitle}
                align={"center"}
              >
                <b>Benchmark Score: 60</b>
              </Typography>
              {calculateAdsPerformanceContent(overAllScore, classes)}
            </Grid>
          </Grid>
        </CompactCardContent>
      </Card>
    );
  }
}

PerformanceScore.propTypes = {};

export default withStyles(performanceScoreStyles)(PerformanceScore);
