import React from 'react';
import {
    Button,
    DialogTitle,
    DialogContentText,
    DialogContent,
    Typography,
    DialogActions,
    Dialog,
} from '@mui/material';


import withStyles from '@mui/styles/withStyles';


export default function CustomDialog(props) {
    console.log("console.log=", props);
    const {open, handleClose, title, handleAction, content, titleColor, hideSecondaryBtn, hidePrimaryBtn, primaryBtnContent, secondaryBtnContent} = props;
    const CustomTypography = withStyles((theme) => ({
        root: {
            color: theme.palette[titleColor],
            textAlign: 'left',
        },
    }))(Typography);
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle><CustomTypography component={"h5"} variant={"h5"}>{title}</CustomTypography></DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {hideSecondaryBtn ? null : <Button onClick={handleClose} color="primary">
                    {secondaryBtnContent}
                </Button>}
                {hidePrimaryBtn ? null : <Button onClick={handleAction} color="primary" autoFocus>
                    {primaryBtnContent}
                </Button>}
            </DialogActions>
        </Dialog>
    );
}
