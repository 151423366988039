import React from "react";
import { Divider, Grid, Hidden, Typography } from "@mui/material/index";
import { withStyles } from "@mui/styles";
import Avatar from "@mui/material/Avatar";
import icons from "../../components/icons";
import historyStyles from "../../assets/jss/activityReport/historyStyles";
import classNames from "classnames";

function History(props) {
  const { classes, task, index, totalTasks, noNavigate } = props;
  return (
    <div>
      <Grid container spacing={2} alignItems={"flex-start"}>
        <Grid item xs={2} sm={2} md={1}>
          <Avatar
            className={classNames(
              classes.avatar,
              task.actionStatus == 2 || task.actionStatus == 5
                ? classes.completedAvatar
                : classes.unCompletedAvatar
            )}
            variant="square"
          >
            <Typography
              variant={"body2"}
              className={
                task.actionStatus == 2 || task.actionStatus == 5
                  ? classes.completedAvatar
                  : classes.unCompletedAvatar
              }
            >
              <b>{index + 1}</b>
            </Typography>
          </Avatar>
        </Grid>
        <Grid item xs={10} sm={10} md={11}>
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"flex-start"}
          >
            <Grid item md={11}>
              <Typography
                color={"textPrimary"}
                variant={"body1"}
                gutterBottom
                style={{ fontSize: "1rem" }}
              >
                <b>{task.taskName}</b>
              </Typography>
              <Typography
                color={"textPrimary"}
                variant={"body1"}
                gutterBottom
                style={{ fontSize: "1rem" }}
              >
                <b>
                  {task.actionStatus == 2 || task.actionStatus == 5
                    ? "Completed"
                    : "Missed"}
                </b>

                {/* new piece of code add on 28 july 2022 here we can add the condition to check the actionStatus 5 as well */}
              </Typography>
            </Grid>
            {!noNavigate ? (
              <Hidden lgDown>
                <Grid item md={1}>
                  {icons.rightArrowT}
                </Grid>
              </Hidden>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
      <div style={{ padding: "0.8rem", paddingBottom: "1rem" }}>
        {props.index == totalTasks ? null : (
          <Divider variant={"middle"} className={classes.dashedLine} />
        )}
      </div>
    </div>
  );
}

export default withStyles(historyStyles)(History);
