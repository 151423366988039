import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import * as _ from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Promocode from "../../NewInterfacePages/onDemandPromocode/promocode";
import {
  deleteDatatInServer,
  onDemandPayment,
  onDemandPaymentStatus,
  resetInputForms,
  resetItemCount,
  fetchOnDemandPackages,
} from "../../actions";
import { BlueBtn, WhiteBtn } from "../buttons";
import icons from "../icons";
import { CustomTableCell } from "./cart-container";
import { BackButton, CustomCardContent, CustomHeader } from "./service-details";
import { RenderTableCells } from "./templates";
import { data } from "./view-details-data";
import { withStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import { event } from "d3";

function PaymentDetails(props) {
  const {
    classes,
    itemCounts,
    fromInputs,
    accountStructureDetails,
    onDemandPayment,
    updatePaymentStatus,
    updatePayment,
    resetInputForms,
    resetCartCount,
    isPremierServicePackMonthly,
    isSEOPackTrimonthly,
    OnDemandPromocodeReducer,
    graderUser,
    handlePreviuos,

    grandTotal,
    currentTotals,

    taskMode,

    multiPageToggle,
    fetchOnDemandPackagesReducer,
    fetchOnDemandPackages,
  } = props;


  const [isLoading, setIsLoading] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(true);
  const [userPromocode, setUserPromocode] = useState("");
  const [promocodeStatus, setPromocodeStatus] = useState(null);
  const [finalGrandTotal, setFinalGrandTotal] = useState(null);
  const [cardValue, setCardValue] = useState(null);
  const [payDialog, setPayDialog] = useState(false);
  const [cancelDialog, setCancelDialog] = useState(false);
  const [successDialog, setSuccessDialog] = useState(false);

  useEffect(() => {
    const defaultCard = _.head(_.keys(accountStructureDetails.cardDetails));
    setCardValue(defaultCard);
  }, [accountStructureDetails.cardDetails]);
  useEffect(() => {
    if (
      OnDemandPromocodeReducer &&
      Object.keys(OnDemandPromocodeReducer).length > 1 &&
      userPromocode != ""
    ) {
      setPromocodeStatus(OnDemandPromocodeReducer.codeStatus);
      setFinalGrandTotal(OnDemandPromocodeReducer.finalAmount);
    } else {
      setPromocodeStatus(null);
    }
  }, [OnDemandPromocodeReducer]);

  const handlePromocodeChange = (e) => {
    setUserPromocode(e.target.value);
    if (promocodeStatus == false) {
      setPromocodeStatus(null);
    }
  };

  let miniService = "";
  const handlePayment = () => {

    var isCTPFund = 0;
    handleClose();
    console.log("handlePayment fromInputs=", props.fromInputs);
    miniService = {
      ...miniService,
      serviceDetails: _.groupBy(props.fromInputs, "type"),
    };
    miniService = { ...miniService, grandTotal: props.grandTotal };
    miniService = { ...miniService, itemCounts: props.itemCounts };

    miniService = {
      ...miniService,
      isPremierServicePackMonthly: props.isPremierServicePackMonthly,

    };
    miniService = {
      ...miniService,
      isSEOPackTrimonthly: props.isSEOPackTrimonthly,

    };



    console.log("miniService ---->" + miniService.grandTotal);
    console.log("cardValue---->" + cardValue);

    console.log(
      "advanceRemaining props---->",
      props.accountStructureDetails.advanceRemaining
    );
    console.log(
      "fetchOnDemandPackagesReducer in whsPayment",
      fetchOnDemandPackagesReducer
    );

    if (
      cardValue == "FundFromCTP" &&
      props.accountStructureDetails.advanceRemaining >= miniService.grandTotal
    ) {
      //payment from CTP Fund only
      isCTPFund = 1;
      console.log(
        "we have enough CTP amount ",
        props.accountStructureDetails.advanceRemaining,
        " and Current package plan is ",
        miniService.grandTotal
      );

      props.updatePayment(miniService, "ONLY_CTP", isCTPFund, userPromocode);
    } else if (
      cardValue == "FundFromCTP" &&
      props.accountStructureDetails.advanceRemaining < miniService.grandTotal
    ) {
      //payment from the card and CTP Fund
      isCTPFund = 2; // from CTP and Card
      // var newGrandTotal =
      //   this.props.grandTotal -
      //   this.props.accountStructureDetails.advanceRemaining;
      // console.log(
      //   "we do not have enough CTP amount ",
      //   this.props.accountStructureDetails.advanceRemaining,
      //   " and Current package plan is ",
      //   miniService.grandTotal,
      //   "So amount from the card will be",
      //   newGrandTotal
      // );
      miniService = { ...miniService, grandTotal: props.grandTotal };
      props.updatePayment(miniService, "CTP+CARD", isCTPFund, userPromocode);
    } else {
      //payment only from the card
      console.log("userPromocode in payment", userPromocode);
      props.updatePayment(miniService, cardValue, isCTPFund, userPromocode);
    }
    setUserPromocode(userPromocode);
  };
  const clearPromocodeSection = (value) => {
    setPromocodeStatus(value);
    setUserPromocode("");
  };

  const handleChange = (name) => (event) => {
    setCardValue(event.target.checked);
  };

  const renderCartDetails = (currentCounts, currentTotals, multiPageToggle) => {
    const currentCountsObj = _.pickBy(currentCounts, (v, k) => v > 0);
    let dataMap = _.keyBy(data, "name");
    return _.map(currentCountsObj, (v, k) => (
      <RenderTableCells
        key={k}
        dataObj={dataMap[k]}
        counter={false}
        currentTotals={currentTotals}
        multiPageToggle={multiPageToggle}
        currentCounts={props.itemCounts}
        onDemandPackagesPlanPrice={props.accountStructureDetails}
      />
    ));
  };

  const handle = () => {
    updatePayment(props.itemCounts, props.fromInputs, props.grandTotal);
  };

  const handleClickOpen = (toggle) => {
    if (toggle) {
      setCancelDialog(true);
    } else {
      setPayDialog(true);
    }
  };

  const handleClose = () => {
    setPayDialog(false);
    setCancelDialog(false);
  };

  const handleCloseForFailedDialog = () => {
    props.updatePaymentStatus();
  };

  const UpdatePaymentStatus = () => {
    props.updatePaymentStatus();
    props.resetInputForms();
    props.resetCartCount();
  };

  const handleCancel = () => {

    handleClose();
    props.resetInputForms();
    props.resetCartCount();
    props.deleteInServer();
  };

  const handleInputs = (event) => {
    setCardValue(event.target.value);
  };
  const PaymentContext = () =>
    payDialog == true ? (
      <div>
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to pay?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Disagree
          </Button>
          <Button onClick={handlePayment} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </div>
    ) : null;
  const CancelContext = () =>
    cancelDialog == true ? (
      <div>
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to cancel?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {taskMode ? (
              <span>
                All the information related to the service request will be
                deleted.
              </span>
            ) : (
              <span>
                Please note that all the data you have entered will be lost.
              </span>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          <Link to="/" style={{ textDecoration: "none" }}>
            <Button onClick={handleCancel} color="primary" autoFocus>
              Yes
            </Button>
          </Link>
        </DialogActions>
      </div>
    ) : null;
  const SuccessContext = () =>
    props.onDemandPayment.status == true ? (
      <div>
        <DialogTitle id="alert-dialog-title">
          {"Payment successfully done"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Thank you! Your payment of $
            {finalGrandTotal != null && userPromocode != ""
              ? finalGrandTotal
              : grandTotal}{" "}
            has been successful. Someone from our team will reach out to you
            shortly for the next steps.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Link
            to={isPremierServicePackMonthly ? ("/subscriptions") : (isSEOPackTrimonthly) ? "/subscriptions" : "/"}
            style={{ textDecoration: "none" }}
          >
            <Button onClick={UpdatePaymentStatus} color="primary">
              Ok
            </Button>
          </Link>
        </DialogActions>
      </div>
    ) : null;
  const FailedContext = () =>
    props.onDemandPayment.status == false ? (
      <div>
        {props.onDemandPayment.isLoading == true ? (
          <Grid container>
            <Grid
              item
              style={{
                minHeight: "20vh",
                minWidth: "400px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Grid>
          </Grid>
        ) : (
          <div>
            <DialogTitle id="alert-dialog-title">
              {"Payment not successful"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Payment not done, please try again.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              {/*<Button onClick={this.handlePayment} color="primary">
                                  Try again
                              </Button>*/}
              <Button
                onClick={handleCloseForFailedDialog}
                color="primary"
                autoFocus
              >
                Ok
              </Button>
            </DialogActions>
          </div>
        )}
      </div>
    ) : null;

  return (
    <Grid item sm={12} xs={12} md={10}>
      {onDemandPayment.isLoading !== true ? (
        <Card className={classes.paper}>
          <CustomHeader
            avatar={
              <BackButton
                variant="fab"
                mini
                disabled={graderUser}
                aria-label="Back"
                onClick={handlePreviuos}
              >
                {icons.ChevronArrowLeft}
              </BackButton>
            }
            title={"Payment"}
          />
          <CustomCardContent>
            <Grid container style={{ padding: 15 }}>
              <Grid
                item
                sm={12}
                xs={12}
                className={"fullWidth"}
                style={{ minHeight: "65vh" }}
              >
                <Typography variant={"body1"}>
                  <p>
                    *If CTP amount is less than the package amount but greater
                    than $0, the remaining amount will be charged to the Card.
                  </p>
                </Typography>
                {accountStructureDetails.isLoading === true ? (
                  <CircularProgress />
                ) : (
                  <Grid
                    container
                    direction={"column"}
                    alignItems={"flex-start"}
                  >
                    {_.map(
                      accountStructureDetails.cardDetails,
                      (cardName, paymentDetailId) => (
                        <Grid
                          item
                          xl={6}
                          md={6}
                          lg={6}
                          xs={12}
                          sm={12}
                          key={paymentDetailId}
                        >
                          <RadioGroup
                            aria-label="gender"
                            name="cardValue"
                            value={cardValue}
                            onChange={handleInputs}
                          >
                            <FormControlLabel
                              value={paymentDetailId}
                              control={<Radio color="primary" />}
                              label={`Use Credit Card  ${cardName}`}
                            />
                          </RadioGroup>
                        </Grid>
                      )
                    )}
                    <RadioGroup
                      disabled={accountStructureDetails.advanceRemaining === 0}
                      aria-label="gender"
                      name="cardValue"
                      value={cardValue}
                      onChange={handleInputs}
                    >
                      <FormControlLabel
                        value="FundFromCTP"
                        control={<Radio color="primary" />}
                        label={`CTP Remaining Funds: $${accountStructureDetails.advanceRemaining.toLocaleString(
                          "en-US"
                        )}`}
                        disabled={
                          accountStructureDetails.advanceRemaining === 0
                        }
                      />
                    </RadioGroup>
                  </Grid>
                )}
                <br />
                <Typography>
                  <b>Price-Breakup</b>
                </Typography>
                <Table>
                  <TableHead>
                    <TableRow>
                      <CustomTableCell>Service</CustomTableCell>
                      <CustomTableCell>Unit Price</CustomTableCell>
                      <CustomTableCell>Hours / Days</CustomTableCell>
                      {/* <CustomTableCell>Units</CustomTableCell> */}
                      <CustomTableCell numeric align="right" style={{ width: "12%" }}>
                        Total
                      </CustomTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {renderCartDetails(
                      itemCounts,
                      currentTotals,
                      multiPageToggle
                    )}
                  </TableBody>
                </Table>
                {Object.keys(OnDemandPromocodeReducer).length > 1 &&
                  promocodeStatus === true &&
                  userPromocode !== "" &&
                  isLoading === false &&
                  paymentLoading ? (
                  <div>
                    <Table
                      style={{
                        borderTop: "1px solid #F1F3F9",
                        borderBottom: "1px solid #F1F3F9",
                      }}
                    >
                      <Grid
                        container
                        style={{ padding: "15px", paddingRight: "23px" }}
                      >
                        <Grid item xs={6}>
                          <Typography style={{ wordWrap: "break-word" }}>
                            <b>
                              Promo Code Discount{" "}
                              <span style={{ color: "#808080" }}>
                                ({userPromocode})
                              </span>
                            </b>
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="subtitle2" align="right">
                            <span
                              style={{ color: "#F58120", paddingRight: "5px" }}
                            >
                              ( - ){" "}
                            </span>
                            <b>${OnDemandPromocodeReducer.discountAmount}</b>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Table>
                    <Table>
                      <Grid
                        container
                        justify="flex-end"
                        style={{ padding: "15px", paddingRight: "23px" }}
                      >
                        <Grid item>
                          <Typography align="right">
                            <b>
                              Grand Total:{" "}
                              <span
                                style={{
                                  color: "#F58120",
                                  fontSize: "1.125rem",
                                }}
                              >
                                $
                                {OnDemandPromocodeReducer.discountAmount >
                                  grandTotal.toLocaleString(
                                    "en-US"
                                  )
                                  ? 0
                                  : (grandTotal -
                                    OnDemandPromocodeReducer.discountAmount).toLocaleString(
                                      "en-US"
                                    )}
                              </span>
                            </b>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Table>
                  </div>
                ) : (
                  <div>
                    <Table
                      style={{
                        borderTop: "1px solid #F1F3F9",
                      }}
                    >
                      <Grid
                        container
                        // justify="flex-end"
                        style={{ padding: "15px", paddingRight: "23px" }}
                      >
                        <Grid item xs={12}>
                          <Typography align="right">
                            <b>
                              Grand Total:{" "}
                              <span
                                style={{
                                  color: "#F58120",
                                  fontSize: "1.125rem",
                                }}
                              >
                                ${grandTotal.toLocaleString(
                                  "en-US"
                                )}
                              </span>
                            </b>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Table>
                  </div>
                )}
              </Grid>
            </Grid>
          </CustomCardContent>
          <Grid item className={"fullWidth"}>
            <div
              style={{
                textAlign: "right",
                borderTop: "1px solid #8F99A7",
                margin: 15,
              }}
            >
              <Grid container direction="row" alignItems="center">
                <Grid item xs={12} sm={12} md={12} lg={9} xl={8}>
                  <Promocode
                    onPromocodeChange={handlePromocodeChange}
                    clearPromocodeSection={clearPromocodeSection}
                    setIsLoading={setIsLoading}
                    grandTotal={grandTotal}
                    setPaymentLoading={setPaymentLoading}
                    userPromocode={userPromocode}
                    promocodeSuccess={promocodeStatus}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={3} xl={4}>
                  <WhiteBtn
                    variant="contained"
                    style={{ margin: 5 }}
                    onClick={() => handleClickOpen(true)}
                  >
                    Cancel
                  </WhiteBtn>
                  <BlueBtn
                    disabled={graderUser === true}
                    variant="contained"
                    style={{ margin: 5 }}
                    onClick={() => handleClickOpen(false)}
                  >
                    Pay
                  </BlueBtn>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Card>
      ) : (
        <CircularProgress color={"secondary"} />
      )}
      <Dialog
        open={
          payDialog ||
          cancelDialog ||
          props.onDemandPayment.status ||
          props.onDemandPayment.status === false
        }
      >
        {PaymentContext()}
        {CancelContext()}
        {SuccessContext()}
        {FailedContext()}
      </Dialog>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  itemCounts: state.miniServiceItemCount,
  fromInputs: state.inputFroms,
  fromInputs: state.inputFroms,
  accountStructureDetails: state.accountStructureDetails,
  onDemandPayment: state.onDemandPayment,
  graderUser: state.perf.graderUser,
  isPremierServicePackMonthly:
    state.onDemandPayment.isPremierServicePackMonthly,
  isSEOPackTrimonthly:
    state.onDemandPayment.isSEOPackTrimonthly,
  OnDemandPromocodeReducer: state.OnDemandPromocodeReducer,
  fetchOnDemandPackagesReducer: state.fetchOnDemandPackagesReducer,
});

const mapDispatchToProps = (dispatch) => ({
  updatePaymentStatus: () => {
    return dispatch(onDemandPaymentStatus());
  },
  updatePayment: (miniService, paymentdetailId, isCTPFund, promoCode) => {
    return dispatch(
      onDemandPayment(miniService, paymentdetailId, isCTPFund, promoCode)
    );
  },
  resetInputForms: () => {
    return dispatch(resetInputForms());
  },
  resetCartCount: () => {
    return dispatch(resetItemCount());
  },
  deleteInServer: () => {
    return dispatch(deleteDatatInServer());
  },
  fetchOnDemandPackages: () => {
    return dispatch(fetchOnDemandPackages());
  },
});
const style = (theme) => ({
  paper: {
    border: "1px solid #8F99A7",
    borderRadius: 3,
    [theme.breakpoints.down("md")]: {
      overflowX: "scroll",
      width: "80%",
    },
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style, { withTheme: true })(PaymentDetails));
