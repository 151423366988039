import React, { Fragment } from "react";
import Insights from "./insights";
import icons from "../icons";
import { Paper } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";
import { GreenText } from "../typography";
import CardContent from "@mui/material/CardContent";

const CustomPaper = withStyles((theme) => ({
  root: {
    [theme.breakpoints.down("lg")]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      boxShadow: "unset",
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
      boxShadow: "unset",
    },
  },
}))(Paper);

function SixMetrics(props) {
  return (
    <CustomPaper>
      <Insights
        icon={icons.mobDesktopStats}
        title={"Quality Score"}
        para={
          "Quality Score is an important metric that determines " +
          "your Cost Per Click [CPC].  Google rates it based on the " +
          "relevance of your keywords, ads and landing pages."
        }
        /*subtitle={"Holiday Season Tip:"}
                      subPara={
                          <span>
                              With fewer holidays, many marketing experts
                          feel that there will be more opportunities to generate sales
                              in 2019. A higher Quality Score can help your ads achieve
                              a higher position in the search results keeping you visible
                              during the holiday rush. <b>Make sure your quality score falls between
                              7 and 10 this season.</b> Concentrate on clickthrough rate, ad relevance
                              and landing page performance to keep the Quality Score high.
                          </span>
                      }*/
        subtitle2={"How we grade your account:"}
        subPara2={
          "We’ll give your accounts Quality Score a grade from " +
          "0 to 100 based on the proportion of keywords in your account that " +
          "have a Quality Score of 7 or higher."
        }
      />
      <Insights
        leftIcon
        icon={icons.groupUsers}
        title={"Impression Share"}
        para={
          "Impression Share is the total of impressions your ad receives divided by the total " +
          "amount of impressions it was eligible to receive."
        }
        /* subtitle={"Holiday Season Tip:"}
                      subPara={
                          <span>
                              It is predicted that decline in sales will be very gradual this time and
                              marketers will do well to ride the sales rush till it lasts. There will be
                              a jump in search queries which will persist throughout the holiday season.
                              This calls for a slightly bigger PPC budget than usual to <b>make sure you don’t
                              lose impressions due to budget problems during this season.</b> Make the most of
                              the impressions you garner, while also ensuring that your ad rank is consistently
                              on the higher side in search results.
                          </span>
                      }*/
        subtitle2={"How we grade your account:"}
        subPara2={
          "The grader will analyze your account to see what percentage of time your ads " +
          "are being shown out of all the times they were eligible to show. We’ll also give you some " +
          "helpful tips on how increasing your budget can impact your impression share."
        }
      />
      <Insights
        icon={icons.landingPageMetric}
        title={"Landing Page Performance"}
        para={
          "Landing page performance is measured through specific user behaviour exhibited " +
          "on-page that might influence or lead to conversions.\n"
        }
        /*subtitle={"Holiday Season Tip:"}
                      subPara={
                          <span>
                             Starting thanksgiving and throughout December there is a good chance
                              that your landing page will have a stream of visitors. Search is
                              expected to peak around this time and what is more stay that way
                              throughout. So, if you <b>improve your page speed, strengthen your call
                              to action on the page</b>, and tweak your keywords to match your pages,
                              you can’t go wrong. Your landing page status and performance will improve.
                              Page Status maybe average, below average or above average depending on user
                              behavior on site.  Higher the status, greater the chances of conversions.
                          </span>
                      }*/
        subtitle2={"How we grade your account:"}
        subPara2={
          "In order to grade your landing page performance, the grader will find " +
          "the proportion of landing pages with conversions less than your account average. " +
          "Don’t worry if your score is on the lower side, we’ll offer some tips on how you can " +
          "improve those landing pages!"
        }
      />
      <Insights
        leftIcon
        icon={icons.negativeKeywords}
        title={"Negative Keywords"}
        para={
          "Negative keywords prevent your ad from showing when a user searches certain " +
          "words or phrases. It is important to determine whether there are conflicting negative" +
          " keywords which block lucrative searches and to what extent they are present."
        }
        /*subtitle={"Holiday Season Tip:"}
                      subPara={<span>Thanksgiving marks the start of a wonderful buying spree.
                          Is your account ready to avoid wasteful traffic? While it is important
                          to avoid wrong exposure for your ad, it doesn’t make sense Use negative
                          keyword analysis report to find and <b>eliminate searches that are using up
                              your budget without giving you the necessary conversions.</b></span>}*/
        subtitle2={"How we grade your account:"}
        subPara2={
          "Our Google Ads Grader will check if any of your negative" +
          " keywords conflict with other keywords added to the account.  " +
          "In your report, you’ll see the number of conflicting negative keywords " +
          "it finds in your account."
        }
      />
      <Insights
        icon={icons.wastedSpend}
        title={"Wasted Spend"}
        para={
          "While this season is for harvesting the benefits of a good year’s " +
          "work, it is also important to exactly get to know the amount of your budget " +
          "that does not result in a positive return on investment."
        }
        /*subtitle={"Holiday Season Tip:"}
                      subPara={<span>If you suspect wasted spend, use our Obligation
                          free Google Ad health check ASAP to determine whether this
                          is true. <b>It is important to correct this quickly and divert
                              the spend where it will yield results.</b> If you have trouble,
                          book a free demo to find out how LXRGuide can help you out this season.
                          Alternately, you may also choose to explore the tool yourself.</span>}*/
        subtitle2={"How we grade your account:"}
        subPara2={
          "To calculate your score in wasted spend the grader will see what " +
          "percentage of your account’s spend is bringing in revenue. Our report will " +
          "show you the percentage of your budget not bringing in revenue and the amount " +
          "wasted in the last week."
        }
      />

      <Insights
        leftIcon
        icon={icons.accountStructure}
        title={"Account Structure Score"}
        para={
          "Your Account Score is a collection of 12 measures " +
          "that help you assess how desirable your business is to your PPC Customers."
        }
        /* subtitle={"Holiday Season Tip:"}
                      subPara={
                          <span>
                      Account Score gives the contours of your account structure and indicates soundness. It is
                              probably the best indicator of how well you will do this season. Doing well when the
                              market booms depends on your preparedness. <b>Make sure you check how well you score on this
                              index and rope in LXRGuide to help you along,</b> if you are not sure.
                      </span>
                      }*/
        subtitle2={"How we grade your account:"}
        subPara2={
          "To calculate your Account Structure Score we use a 12-point metric based on Campaign " +
          "Budget, Keywords/Ad group, Shopping Campaign Availability, Active ad copies and structure, " +
          "Use of Trademark terms, Keyword Match Type split, Conflicting Keyword match type bid, " +
          "Landing page status, number of ad copies and ad extensions per ad group."
        }
      />
    </CustomPaper>
  );
}

export default SixMetrics;
