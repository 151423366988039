import * as types from "../actions/actionTypes";
export default function industryInsightsDataReducers(
  state = {
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.FETCH_INDUSTRY_INSIGHT_DATA_REQUESTED:
      console.log("I am in data REQUESTED case");
      return Object.assign({}, state, {
        message: "FETCH_INDUSTRY_INSIGHT_DATA_REQUESTED",
      });

    case types.FETCH_INDUSTRY_INSIGHT_DATA_RECEIVED:
      console.log("I am in data Received case");
      let { data } = action;
      console.log("FETCH_INDUSTRY_INSIGHT_DATA_RECEIVED", data);
      return Object.assign({}, state, {
        ...action.data,
        message: "FETCH_INDUSTRY_INSIGHT_DATA_RECEIVED",
      });

    case types.FETCH_INDUSTRY_INSIGHT_DATA_FAILED:
      return Object.assign({}, state, {
        message: "FETCH_INDUSTRY_INSIGHTS_DATA_FAILED",
      });
      
    default:
      console.log("The state is ", state);
      return state;
  }
}
