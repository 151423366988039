import React from "react";
import * as _ from "lodash";
import { Checkbox, Grid, Paper, Typography, Button } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import icons from "../icons";
import { getRenderer } from "./task-data";
import PropTypes from "prop-types";
import Warning from "@mui/icons-material/Warning";
import { connect } from "react-redux";

import { DivTypography } from "../typography";
import { shoppingFeedBack } from "../../actions/index";
import {
  deleteDatatInServer,
  executeTasks,
  onDemandPayment,
  onDemandPaymentStatus,
  resetInputForms,
  resetItemCount,
  toggleSelection,
} from "../../actions";
import ReplayIcon from "@mui/icons-material/Replay.js";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { NewOrangeContainedBtn } from "../buttons";

export class ShoppingHierarchy extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      userValue: false,
      userFeedback: "",
      userFeedbackError: "",
      successMessage: "",
    };
  }

  handleChange = () => {
    this.setState({ userValue: !this.state.userValue });
  };

  handleInputs = (event) => {
    event.preventDefault();
    if (event.target.value == "" || event.target.value == null) {
      console.log(event.target.value);
    }
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  validate = () => {
    let isError = false;
    const errors = {};
    if (this.state.userFeedback.length == 0) {
      isError = true;
      errors.userFeedbackError = "This Field is required";
    } else {
      errors.userFeedbackError = "";
    }
    if (isError) {
      this.setState({
        ...this.state,
        ...errors,
      });
    }
    return isError;
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  handleSubmit = async () => {
    let result;

    if (this.state.userValue == true) {
      console.log("The value is true");
      let error = this.validate();
      if (!error) {
        this.setState({
          isLoading: true,
        });
        await this.props.shoppingFeedback(
          this.state.userValue,
          this.state.userFeedback,
          this.props.taskId
        );
        this.setState({
          isLoading: false,
          successMessage: "Your request saved successfully",
        });
      }
    } else {
      console.log("This value is false");
      await this.props.shoppingFeedback(
        this.state.userValue,
        this.state.userFeedback,
        this.props.taskId
      );
      window.location = "/shopping-settings.html";
    }
  };

  render() {
    const { isActivityReport } = this.props;
    return (
      <Grid container>
        <Grid item md={12}>
          <Typography color={"textPrimary"} variant={"h4"}>
            <b>We have not seen your presence in Google shopping platform.</b>
          </Typography>
          <RadioGroup
            aria-label="position"
            name="position"
            value={this.state.userValue}
            onChange={this.handleChange}
            disabled={isActivityReport}
          >
            <FormControlLabel
              value={false}
              control={
                <Radio
                  checkedIcon={icons.orangeChecked}
                  icon={icons.unCheckedNew}
                  color="primary"
                />
              }
              label="Go ahead and enable Shopping for your account"
              labelPlacement="end"
              // checked
            />
            <FormControlLabel
              value={true}
              control={
                <Radio
                  checkedIcon={icons.orangeChecked}
                  icon={icons.unCheckedNew}
                  color="primary"
                />
              }
              label="Doesn't Apply for me"
              labelPlacement="end"
            />
          </RadioGroup>
        </Grid>
        {this.state.userValue == true ? (
          <Grid item md={5}>
            <Typography variant={"h5"}>Comment Why?</Typography>
            <TextField
              defaultValue="comment"
              margin="normal"
              value={this.state.userFeedback}
              onChange={this.handleInputs}
              name="userFeedback"
              error={this.state.userFeedbackError != ""}
              helperText={this.state.userFeedbackError}
              InputLabelProps={{
                shrink: true,
              }}
              multiline
              maxRows="4"
              fullWidth
              required
            />
          </Grid>
        ) : null}
        <Grid item md={12}>
          <NewOrangeContainedBtn
            variant="contained"
            disabled={isActivityReport}
            onClick={this.handleSubmit}
          >
            {this.state.userValue ? "Proceed" : "SETUP"}
          </NewOrangeContainedBtn>
        </Grid>
        <Dialog
          open={this.state.successMessage != ""}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{""}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.state.successMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.setState({ successMessage: "" });
              }}
              color="primary"
            >
              ok
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );
  }
}

/*const mapStateToProps = state => ({
    itemCounts: state.miniServiceItemCount,
    fromInputs: state.inputFroms,
    accountStructureDetails: state.accountStructureDetails,
    onDemandPayment: state.onDemandPayment
});*/
ShoppingHierarchy = withStyles({}, { withTheme: true })(ShoppingHierarchy);

ShoppingHierarchy = connect(null, (dispatch) => ({
  shoppingFeedback: (shoppingRequest, userFeedback, taskId) => {
    return dispatch(shoppingFeedBack(shoppingRequest, userFeedback, taskId));
  },
}))(ShoppingHierarchy);
