import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {CustomCard,MainSectionCardHeader} from "./templates";
import {CompactCardContent} from "../cards";
import OpportunityScoreCard from './opportunityScoreCard'
import {connect} from "react-redux";

class OpportunityScore extends React.PureComponent {
    render() {
        return (
            <CustomCard>
                <MainSectionCardHeader
                    style={{paddingBottom: 0}}
                    title={"PPC Metrics Box w/ Fanatical Analytics"}
                    subheader={"Insights and predictions to help you coach and discipline your ad campaigns."}/>
                <CompactCardContent>
                    {this.props.data.googleAdsReportData ? <OpportunityScoreCard data={this.props.data.googleAdsReportData ? this.props.data.googleAdsReportData : null}/>:null}
                </CompactCardContent>
            </CustomCard>
        );
    }
}

OpportunityScore.propTypes = {};
const mapStateToProps = (state, ownProps) => ({
    data: state.googleAdsPerformance
});

export default connect(mapStateToProps, null)(OpportunityScore);
