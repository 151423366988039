import React from "react";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import "./whsScoreIcon.css";
import { Link } from "react-router-dom";
import { fetchHealthScorecardData, fetchWHSExpertData } from "../../actions";

function WHSScoreIcon(props) {
  const {clientId} = props
  let externalUserId=""
  let userType = 0;

  useEffect(() => {
    if(clientId !== undefined){
      fetchHealthScorecardData(externalUserId);
      fetchWHSExpertData(clientId,userType);
    }
  }, [clientId]);

  const { websiteHealthScoreDataReducers, fetchHealthScorecardData, fetchWHSExpertData, fetchWHSExpertDataReducer } = props;
  const [overallScore, setOverallScore] = useState(
    websiteHealthScoreDataReducers.overallScore
      ? websiteHealthScoreDataReducers.overallScore
      : null
  );
  useEffect(() => {
    if (websiteHealthScoreDataReducers) {
      setOverallScore(
        websiteHealthScoreDataReducers.overallScore
          ? websiteHealthScoreDataReducers.overallScore
          : null
      );
    }
  }, [websiteHealthScoreDataReducers]);

  console.log("overall score", overallScore);
  return (
    <div>
      {overallScore ? (
        <Link to="/website-health-scorecard">
          <div className="center">
            <div
              className={
                overallScore > 70
                  ? "pulse pulse-good"
                  : overallScore > 35 && overallScore <= 70
                  ? "pulse pulse-avg"
                  : "pulse pulse-poor"
              }
            >
              {overallScore}
            </div>
          </div>
        </Link>
      ) : (
        ""
      )}
    </div>
  );
}
const mapStateToProps = (state) => ({
  websiteHealthScoreDataReducers: state.websiteHealthScoreDataReducers,
  fetchWHSExpertDataReducer: state.fetchWHSExpertDataReducer,
});

const mapDispatchToProps = (dispatch) => ({
  fetchHealthScorecardData: (userId) => dispatch(fetchHealthScorecardData(userId)),
  fetchWHSExpertData: (userId, userType) => dispatch(fetchWHSExpertData(userId, userType)),
});
export default connect(mapStateToProps, mapDispatchToProps)(WHSScoreIcon);
