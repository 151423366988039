import React from "react";
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
  Divider,
} from "@mui/material";
import classNames from "classnames";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import NumberFormat from "react-number-format";
import {
  OutLinedOrangeBtnBlueBorder,
  OutLinedOrangeBtnBlueBorderNoUnderLine,
} from "../../components/buttons";
import * as PropTypes from "prop-types";
import {
  CustomListItem,
  CustomListItemText,
  SubSectionCardHeader,
} from "../SEOSiteGraderInternal/templates";
import moment from "moment";
import { Link } from "react-router-dom";
import icons from "../../components/icons";
// import KeywordScoreCard from "../components/internalSiteGrader/keywordScoreCard";
import KeywordScoreCard from "../../components/internalSiteGrader/keywordScoreCard";
import withStyles from "@mui/styles/withStyles";

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="xl" />;

function TopKeywords(props) {
  const { siteGrader, classes, type } = props;
  const seoTrafficEstimate =
    siteGrader != null && siteGrader.seoTrafficEstimate
      ? siteGrader.seoTrafficEstimate
      : 0;
  const monthlyAdwordsBudget =
    siteGrader != null && siteGrader.monthlyAdwordsBudget
      ? siteGrader.monthlyAdwordsBudget
      : 0;
  const robotsText =
    siteGrader != null && siteGrader.technical
      ? siteGrader.technical.robotsTxt
      : false;
  const validSSLCertificate =
    siteGrader != null && siteGrader.technical
      ? siteGrader.technical.validSSLCertificate
      : false;
  const brokenURLs =
    siteGrader != null && siteGrader.technical
      ? siteGrader.technical.brokenURLs
      : null;
  const wwwResolved =
    siteGrader != null && siteGrader.technical
      ? siteGrader.technical.wwwResolved
      : false;
  const structuredDataScore =
    siteGrader != null && siteGrader.technical
      ? siteGrader.technical.structuredDataScore
      : 0;
  const domainUrl =
    siteGrader != null && siteGrader.url ? siteGrader.url : null;
  return (
    <Grid item xs={12} md={12} sm={12}>
      <Card className={classes.smallCard}>
        <CardContent
          className={classes.cardContent}
          style={{ paddingTop: 5, height: "100%" }}
        >
          <Typography
            align={"left"}
            color={"textPrimary"}
            className={classes.text}
          >
            <b>Top Keywords</b>
          </Typography>
          <KeywordScoreCard siteGrader={siteGrader} />
        </CardContent>
      </Card>
    </Grid>
  );
}

TopKeywords.propTypes = {
  classes: PropTypes.any,
  robotsText: PropTypes.bool,
  validSSLCertificate: PropTypes.bool,
  brokenURLs: PropTypes.any,
  wwwResolved: PropTypes.bool,
  structuredDataScore: PropTypes.number,
  seoTrafficEstimate: PropTypes.number,
  monthlyAdwordsBudget: PropTypes.number,
};

const topKeywordsStyles = (theme) => ({
  dashedLine: {
    border: "1px dashed" + theme.palette.common.darkHash,
    backgroundColor: "unset",
    boxSizing: "border-box",
    borderRadius: 10,
    opacity: 0.4,
    height: 0,
  },
  text: { fontSize: "14px" },
  smallCard: {
    height: 221,
    boxShadow: "4px 4px 18px rgba(0, 0, 0, 0.06)",
    border: "1px solid #E3E6F4",
    borderRadius: "6px",
  },
  cardContent: {
    padding: 2,
    height: "75%",
  },
  keywords: {
    padding: 6,
    paddingTop: 10,
  },
});
export default withStyles(topKeywordsStyles)(TopKeywords);
