import React, { Fragment } from "react";
import { DemoInputField } from "../input-forms";
import {
  Grid,
  Typography,
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  RadioGroup,
  Radio,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CssBaseline,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { saveRequestDemo } from "../../actions";
import { connect } from "react-redux";
import { CurvedBlueBtn, PostButton } from "../buttons";
import { ErrorTypography } from "../typography";
import { countryList } from "./templates";
import icons from "../icons";
import * as _ from "lodash";
import NumberFormat from "react-number-format";
import * as types from "../../actions/actionTypes";

const demoFormStyles = (theme) => ({
  hyperLinks: {
    textDecoration: "unset",
    color: theme.palette.hyperLink,
    fontWeight: "bold",
    fontSize: "0.8rem",
    lineHeight: 0,
    padding: 0,
  },
  title: {
    fontWeight: "700",
  },
  customGrid: {
    paddingLeft: theme.spacing(15),
    [theme.breakpoints.down("lg")]: {
      padding: "1rem 1rem 1rem 1rem",
    },
  },
  orangeTxt: {
    color: theme.palette.orangeColor,
  },
  progress: {
    margin: theme.spacing(2),
  },
});

export const FormTitleTypography = withStyles((theme) => ({
  root: {
    fontFamily: "Lato",
    color: theme.palette.text.secondary,
    fontSize: 14,
  },
}))(Typography);
export const CustomCheckbox = withStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
  },
}))(Checkbox);
const PopUpCommonBtn = withStyles((theme) => ({
  root: {
    fontWeight: "400",
    color: theme.palette.text.primary,
  },
}))(Button);

class requestDemoForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      emailId: "",
      companyName: "",
      phoneNumber: "",
      website: "",
      country: "",
      advertiserType: "false",
      firstNameError: "",
      emailIdError: "",
      companyNameError: "",
      phoneNumberError: "",
      countryError: "",
      termsAndConditionsError: "",
      saving: false,
      termsAndConditions: false,
      SuccessNotification: "",
    };
  }

  handleCheckBox = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  validate = () => {
    let isError = false;
    const errors = {};
    if (this.state.firstName.length == 0) {
      isError = true;
      errors.firstNameError = "This field is required";
    } else {
      errors.firstNameError = "";
    }
    if (this.state.emailId.length == 0) {
      isError = true;
      errors.emailIdError = "This field is required";
    } else if (this.state.emailId.length > 0) {
      let result = this.state.emailId.match(
        /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
      );
      if (!result) {
        isError = true;
        errors.emailIdError = "Please enter valid email";
      } else {
        errors.emailIdError = "";
      }
    } else {
      errors.emailIdError = "";
    }
    if (this.state.companyName.trim().length == 0) {
      isError = true;
      errors.companyNameError = "This field is required";
    } else {
      errors.companyNameError = "";
    }
    if (this.state.country.length == 0) {
      isError = true;
      errors.countryError = "This field is required";
    } else {
      errors.countryError = "";
    }
    if (this.state.phoneNumber.length == 0) {
      isError = true;
      errors.phoneNumberError = "This field is required";
    } else if (this.state.phoneNumber.length < 10) {
      isError = true;
      errors.phoneNumberError = "Phone Number should be minimum 10 digits";
    } else if (this.state.phoneNumber.length > 10) {
      isError = true;
      errors.phoneNumberError = "Phone Number should not exceed 10 digits";
    } else {
      errors.phoneNumberError = "";
    }
    if (this.state.termsAndConditions == false) {
      isError = true;
      errors.termsAndConditionsError =
        "You must accept our Terms of Service and Privacy Policy";
    } else {
      errors.termsAndConditionsError = "";
    }

    if (isError) {
      this.setState({
        ...this.state,
        ...errors,
      });
    }
    return isError;
  };

  handleInputs = async (event) => {
    event.preventDefault();
    await this.setState({
      [event.target.name]: event.target.value,
    });
    console.log("Request Demo State Updated=", this.state);
  };

  handleSubmit = async () => {
    this.props.requestDemoFormSubmitPage();
    let error;
    error = this.validate();
    if (!error) {
      this.setState({ saving: true });
      this.state.response = await this.props.saveInputsToServer({
        firstName: this.state.firstName,
        emailId: this.state.emailId,
        companyName: this.state.companyName,
        phoneNumber: this.state.phoneNumber,
        website: this.state.website,
        country: this.state.country,
        advertiserType: this.state.advertiserType,
      });
      this.setState({
        saving: false,
        SuccessNotification:
          "Someone from LXRGuide team will contact you shortly",
        firstName: "",
        emailId: "",
        companyName: "",
        phoneNumber: "",
        website: "",
        country: "",
        emailIdError: "",
        companyNameError: "",
        phoneNumberError: "",
        countryError: "",
        termsAndConditionsError: "",
        advertiserType: "false",
      });
    }

    /*setTimeout(() => {
            this.clearNotification();
        }, 3000);*/
  };
  clearNotification = () => {
    this.setState({ SuccessNotification: "" });
  };

  render() {
    console.log("countryList", countryList);
    const countryNamesList = _.mapValues(countryList, "Name");
    console.log("countryNamesList", countryNamesList);
    const { classes } = this.props;
    const { fullScreen } = this.props;
    return (
      <Fragment>
        <Grid container alignItems={"center"} className={classes.customGrid}>
          <Grid item md={12} xs={12} sm={4}>
            <Typography
              color={"textPrimary"}
              variant={"body1"}
              className={classes.title}
              align={"left"}
            >
              Name
            </Typography>
          </Grid>
          <Grid item md={8} xs={12} sm={8}>
            <DemoInputField
              size="small"
              variant="standard"
              fullWidth
              margin="normal"
              name="firstName"
              value={this.state.firstName}
              onChange={this.handleInputs}
              error={this.state.firstNameError !== ""}
              helperText={this.state.firstNameError}
              InputLabelProps={{
                shrink: true,
              }}
              required
            />
          </Grid>
          <Grid item md={12} xs={12} sm={4}>
            <Typography
              color={"textPrimary"}
              variant={"body1"}
              className={classes.title}
              align={"left"}
            >
              Email Address
            </Typography>
          </Grid>
          <Grid item md={8} xs={12} sm={8}>
            <DemoInputField
              size="small"
              variant="standard"
              fullWidth
              margin="normal"
              name="emailId"
              value={this.state.emailId}
              onChange={this.handleInputs}
              error={this.state.emailIdError !== ""}
              helperText={this.state.emailIdError}
              InputLabelProps={{
                shrink: true,
              }}
              required
            />
          </Grid>
          <Grid item md={12} xs={12} sm={4}>
            <Typography
              color={"textPrimary"}
              variant={"body1"}
              className={classes.title}
              align={"left"}
            >
              Company Name
            </Typography>
          </Grid>
          <Grid item md={8} xs={12} sm={8}>
            <DemoInputField
              size="small"
              variant="standard"
              fullWidth
              margin="normal"
              name="companyName"
              value={this.state.companyName}
              onChange={this.handleInputs}
              error={this.state.companyNameError !== ""}
              helperText={this.state.companyNameError}
              InputLabelProps={{
                shrink: true,
              }}
              required
            />
          </Grid>
          <Grid item md={12} xs={12} sm={4}>
            <Typography
              color={"textPrimary"}
              variant={"body1"}
              className={classes.title}
              align={"left"}
            >
              Phone Number
            </Typography>
          </Grid>
          <Grid item md={8} xs={12} sm={8}>
            {/*<DemoInputField
                            type={"number"}
                            fullWidth
                            margin="normal"
                            name="phoneNumber"
                            value={this.state.phoneNumber}
                            onChange={this.handleInputs}
                            error={this.state.phoneNumberError != ""}
                            helperText={this.state.phoneNumberError}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            required
                        />*/}
            <NumberFormat
              size="small"
              variant="standard"
              fullWidth
              margin="normal"
              name="phoneNumber"
              value={this.state.phoneNumber}
              onValueChange={async (values) => {
                const { formattedValue, value } = values;
                await this.setState({ phoneNumber: value });
              }}
              error={this.state.phoneNumberError !== ""}
              helperText={this.state.phoneNumberError}
              InputLabelProps={{
                shrink: true,
              }}
              customInput={DemoInputField}
              format="###-###-####"
              mask="_"
            />
          </Grid>
          <Grid item md={12} xs={12} sm={4}>
            <Typography
              color={"textPrimary"}
              variant={"body1"}
              className={classes.title}
              align={"left"}
            >
              Website (Optional)
            </Typography>
          </Grid>
          <Grid item md={8} xs={12} sm={8}>
            <DemoInputField
              size="small"
              variant="standard"
              fullWidth
              margin="normal"
              name="website"
              value={this.state.website}
              onChange={this.handleInputs}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item md={12} xs={12} sm={4}>
            <Typography
              color={"textPrimary"}
              variant={"body1"}
              className={classes.title}
              align={"left"}
            >
              Country
            </Typography>
          </Grid>
          <Grid item md={8} xs={12} sm={8}>
            <DemoInputField
              size="small"
              variant="standard"
              id="standard-name"
              select
              fullWidth
              margin="normal"
              value={this.state.country}
              onChange={this.handleInputs}
              name="country"
              error={this.state.countryError !== ""}
              helperText={this.state.countryError}
              InputLabelProps={{
                shrink: true,
              }}
              required
            >
              <MenuItem key={1} value="">
                <em>None</em>
              </MenuItem>
              {_.map(countryNamesList, (value, key) => {
                return (
                  <MenuItem key={key} value={value}>
                    {value}
                  </MenuItem>
                );
              })}
            </DemoInputField>
          </Grid>
          <Grid item md={12} xs={12} sm={4}>
            <Typography
              color={"textPrimary"}
              variant={"body1"}
              className={classes.title}
              align={"left"}
            >
              I am an
            </Typography>
          </Grid>
          <Grid item md={8} xs={12} sm={8}>
            <CssBaseline />
            <RadioGroup
              aria-label="Account Type"
              name="advertiserType"
              value={this.state.advertiserType}
              onChange={this.handleInputs}
              row
            >
              <FormControlLabel
                value="false"
                control={
                  <Radio
                    color={"primary"}
                    icon={icons.unCheckedNew}
                    checkedIcon={icons.orangeChecked}
                  />
                }
                label="Advertiser"
              />
              <FormControlLabel
                value="true"
                control={
                  <Radio
                    color={"primary"}
                    icon={icons.unCheckedNew}
                    checkedIcon={icons.orangeChecked}
                  />
                }
                label="Agency"
              />
            </RadioGroup>
          </Grid>
          <Grid item md={12} xs={12} sm={12}>
            {this.state.saving ? (
              <CircularProgress className={classes.progress} />
            ) : (
              <Fragment>
                <FormControlLabel
                  control={
                    <CustomCheckbox
                      checked={this.state.termsAndConditions}
                      onChange={this.handleCheckBox("termsAndConditions")}
                      value="termsAndConditions"
                      icon={icons.orangeUncheck}
                      checkedIcon={icons.orangeCheckReact}
                    />
                  }
                  label={
                    <Typography>
                      I agree to&nbsp;
                      <a
                        className={classes.hyperLinks}
                        href={"/terms-of-service.html"}
                        target="_blank"
                      >
                        Terms of Service
                      </a>
                      &nbsp;and&nbsp;
                      <a
                        className={classes.hyperLinks}
                        href={"/privacy-policy.html"}
                        target="_blank"
                      >
                        Privacy Policy
                      </a>
                    </Typography>
                  }
                />
                {this.state.termsAndConditions == false ? (
                  <ErrorTypography>
                    {this.state.termsAndConditionsError}
                  </ErrorTypography>
                ) : null}
              </Fragment>
            )}
          </Grid>
          <Grid md={6}>
            <PostButton
              size={"medium"}
              variant="contained"
              onClick={this.handleSubmit}
              style={{ textTransform: "none" }}
            >
              Request Demo
            </PostButton>
          </Grid>
          <Grid>
            <Dialog
              fullScreen={fullScreen}
              open={this.state.SuccessNotification !== ""}
              onClose={this.clearNotification}
              aria-labelledby="responsive-dialog-title"
            >
              <DialogTitle id="responsive-dialog-title">
                {"Thank You for Requesting a Demo"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {this.state.SuccessNotification}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <PopUpCommonBtn
                  onClick={this.clearNotification}
                  color="primary"
                  autoFocus
                >
                  Ok
                </PopUpCommonBtn>
              </DialogActions>
            </Dialog>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

requestDemoForm.propTypes = {};

const mapDispatchToProps = (dispatch) => ({
  saveInputsToServer: (demoObj) => {
    return dispatch(saveRequestDemo(demoObj));
  },
  requestDemoFormSubmitPage: () =>
    dispatch({ type: types.REQUEST_DEMO_FORM_SUBMIT_TRACK }),
});

export default connect(
  null,
  mapDispatchToProps
)(withStyles(demoFormStyles)(requestDemoForm));
