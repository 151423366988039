import React, { Fragment, useState, useEffect } from "react";
import { Grid, Paper, Typography } from "@mui/material";
import { store } from "../../reducers/common";
import {
  Card,
  CardContent,
  TableBody,
  DialogTitle,
  TableCell,
  DialogContentText,
  Button,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { connect } from "react-redux";
import accountSettingsStyles from "../../assets/jss/accountSettings/accountSettingStyle";
import profileSettingsStyles from "../../assets/jss/profileSettings/profileSettingStyle";
import * as _ from "lodash";
import ProfileSettingsForm from "./profileSettingsForm";
import {
  UpdateUserProfileDetails,
  UpdateUserPromoCodeDetails,
} from "../../actions/fetchingUserData";
import {
  deleteCardDetails,
  fetchUserProfileDetails,
  updateCardDetailsForm,
} from "../../actions/fetchUserProfile";
import MySnackbarWrapper from "../snackbars";
import CardDetailsForm from "./cardDetailsForm";
import PromoCodeForm from "./promoCodeForm";
import PasswordChange from "./PasswordChange";
import Subscribe from "./subcribe";
import { CircularProgress } from "@mui/material/index";

export const CustomTableCellHead = withStyles((theme) => ({
  head: {
    background: "rgba(242, 243, 249, 0.6)",
    border: "1px solid #E3E6F4",
    boxSizing: "border-box",
    borderRadius: "10px 10px 0px 0px",
    color: theme.palette.text.primary,
    fontWeight: "500",
    fontSize: theme.typography.pxToRem(14),
    fontFamily: "Lato",
  },
  // label
}))(TableCell);
const DialogHead = withStyles((theme) => ({
  root: {
    fontWeight: "900",
    color: theme.palette.text.primary,
    fontFamily: "Lato",
  },
}))(DialogTitle);
const DialogText = withStyles((theme) => ({
  root: {
    fontWeight: "lighter",
    fontSize: 16,
    color: theme.palette.text.primary,
    fontFamily: "Lato",
  },
}))(DialogContentText);
const PopUpOrangeBtn = withStyles((theme) => ({
  root: {
    fontWeight: "bold",
    color: theme.palette.orangeColor,
  },
}))(Button);
export const PopUpCommonBtn = withStyles((theme) => ({
  root: {
    fontWeight: "400",
    color: theme.palette.text.primary,
  },
}))(Button);

function ProfileSettings(props) {
  const { classes, userDetails, userProfileInformation, isUserLoading } = props;
  const { selectedClientInfo, selectedAccountInfo, userInfo } = userDetails;
  const { cardDetails, endDate, isCardLoading, isCardUpdating } =
    userProfileInformation;
  const { paymentDetails } = userInfo;
  console.log("the user details = ", userDetails);
  console.log("the user payment details = ", paymentDetails);

  // const [state, setState] = useState({
  //   name: "",
  //   alert: false,
  //   message: "",
  //   variantType: "info",
  // });

  const [name,setName]=useState("");
  const [alert,setAlert]=useState(false);
  const [message,setMessage]=useState("");
  const [variantType,setVariantType]=useState("info")
  

  const loadPayAndSubscribeMessage = () => {
    if (userInfo.type != 7) {
      if (
        userInfo.unsubscribed == 2 ||
        (userInfo.unsubscribed == 3 && userInfo.permission == Boolean.FALSE)
      ) {
        return (
          <Fragment>
            <Typography
              variant={"body1"}
              gutterBottom
              style={{ color: "#ff3030", padding: "0% 9% 0% 9%" }}
              align={"center"}
            >
              <b>
                We apologize but we were unable to charge your credit card as we
                have received an error from our merchant processor. As an
                alternative please add a backup credit card and click on 'Pay &
                Subscribe' button.
              </b>
            </Typography>
          </Fragment>
        );
      }
    }
  };

  useEffect(() => {
    document.title = "LXRGuide - Profile";
    props.fetchUserProfileDetails();
  }, []);

  const handleUpdate = async ({
    userId,
    emailIds,
    phoneNumber,
    address,
    billingName,
    billingEmail,
  }) => {
    let response = await props.UpdateUserProfileDetails({
      userId,
      emailIds,
      phoneNumber,
      address,
      billingName,
      billingEmail,
    });
    if (response !== null && response.error && response.error != "") {
      // setState({
      //   alert: true,
      //   message: response.error,
      //   variantType: "error",
      // });

      setAlert(true)
      setMessage(response.error)
      setVariantType("error")


    } else {
      // setState({
      //   alert: true,
      //   message: "User Profile Details Updated Successfully",
      //   variantType: "success",
      // });

      setAlert(true)
      setMessage("User Profile Details Updated Successfully")
      setVariantType("success")



    }
  };

  const UpdateUserPromoCodeDetails = async (formObject) => {
    let response = await props.UpdateUserPromoCodeDetails(formObject);
    console.log("the response after the promo code submitted is:", response);
    return response;
  };

  const handleCloseAlert = async () => {
    // setState((prevState) => ({
    //   ...prevState,
    //   alert: false,
    // }));

    setAlert(false)
  };

  const handleDeleteCard = async (isPriority, paymentDetailId) => {
    var r = window.confirm(
      "Existing credit card will be removed.Do you still want to continue?"
    );
    if (r == true) {
      let response = await props.deleteCardDetails(isPriority, paymentDetailId);
      if (
        response !== null &&
        response &&
        response.error &&
        response.error != ""
      ) {
        // setState({
        //   alert: true,
        //   message: response.error,
        //   variantType: "error",
        // });
        setAlert(true)
        setMessage(response.error)
        setVariantType("error")
      } else {
        // setState({
        //   alert: true,
        //   message: "Card Details Deleted successfully",
        //   variantType: "success",
        // });
        setAlert(true)
        setMessage("Card Details Deleted successfully")
        setVariantType("success")
      }
    }
  };

  const addCard = async (formObject) => {
    let response = await props.addCard(formObject);
    console.log(
      "response != null && response && response.errorMsg && response.errorMsg !=",
      response != null &&
        response &&
        response.errorMsg &&
        response.errorMsg != ""
    );
    console.log("response=", response);
    if (
      response != null &&
      response &&
      response.errorMsg &&
      response.errorMsg != ""
    ) {
      // setState({
      //   alert: true,
      //   message: response.errorMsg,
      //   variantType: "error",
      // });

      setAlert(true)
      setMessage(response.errorMsg)
      setVariantType("error")

    } else {
      // setState({
      //   alert: true,
      //   message: "Card Details Added successfully",
      //   variantType: "success",
      // });
      setAlert(true)
      setMessage("Card Details Added successfully")
      setVariantType("success")
    }
    return response;
  };

  return (
    <Fragment>
      {isUserLoading ? (
        <div
          className={"flex alignItemsCenter justifyCenter"}
          style={{ height: "70%" }}
        >
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <Card className={classes.card} style={{zoom:"90%"}}>
          <CardContent>
            {loadPayAndSubscribeMessage()}
            {/*<br/>*/}
            {/*<br/>*/}
            {/*<br/>*/}
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12} md={12}>
                {userDetails && userDetails.userInfo ? (
                  <Grid container spacing={2} style={{ padding: 10 }}>
                    <Grid item sm={12} xs={12} md={6} style={{ paddingLeft: 10 }}>
                      <ProfileSettingsForm
                        classes={classes}
                        userDetails={userDetails}
                        paymentDetails={paymentDetails}
                        UpdateUserProfileDetails={(
                          userId,
                          emailIds,
                          phoneNumber,
                          address,
                          billingName,
                          billingEmail
                        ) =>
                          handleUpdate(
                            userId,
                            emailIds,
                            phoneNumber,
                            address,
                            billingName,
                            billingEmail
                          )
                        }
                      />
                      <br />
                      {userInfo.type != 9 ? <PasswordChange /> : null}
                      <Subscribe
                        userInfo={userInfo}
                        userProfileInformation={userProfileInformation}
                      />
                      <PromoCodeForm
                        classes={classes}
                        DialogHead={DialogHead}
                        DialogText={DialogText}
                        PopUpOrangeBtn={PopUpOrangeBtn}
                        PopUpCommonBtn={PopUpCommonBtn}
                        UpdateUserPromoCodeDetails={UpdateUserPromoCodeDetails}
                        userDetails={userDetails}
                      />
                    </Grid>
                    <Grid item sm={12} xs={12} md={6} style={{ paddingRight: 10 }}>
                      <CardDetailsForm
                        classes={classes}
                        cardDetails={cardDetails}
                        handleDeleteCard={handleDeleteCard}
                        endDate={endDate}
                        isCardLoading={isCardLoading}
                        addCard={addCard}
                        isCardUpdating={isCardUpdating}
                        userType={userInfo.type}
                      />
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
            <MySnackbarWrapper
              open={alert}
              onClose={handleCloseAlert}
              message={message}
              verticalAlign={"bottom"}
              horizontalAlign={"right"}
              duration={6000}
              variant={variantType}
            />
          </CardContent>
        </Card>
      )}
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  userDetails: state.userInformation,
  userProfileInformation: state.userProfileInformation,
  isUserLoading: state.userInformation.isLoading,
});
const mapDispatchToProps = (dispatch) => ({
  UpdateUserProfileDetails: (AccountDetailsObject) =>
    dispatch(UpdateUserProfileDetails(AccountDetailsObject)),
  fetchUserProfileDetails: (UserProfileDetailsObject) =>
    dispatch(fetchUserProfileDetails(UserProfileDetailsObject)),
  UpdateUserPromoCodeDetails: (UserPromoCodeDetailsObject) =>
    dispatch(UpdateUserPromoCodeDetails(UserPromoCodeDetailsObject)),
  deleteCardDetails: (isPriority, paymentDetailId) =>
    dispatch(deleteCardDetails(isPriority, paymentDetailId)),
  addCard: (formObj) => dispatch(updateCardDetailsForm(formObj)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(profileSettingsStyles, { withTheme: true })(ProfileSettings));
