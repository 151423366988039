import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({});

function Progress(props) {
  console.log("props=", props);
  return (
    <div>
      <Grid
        container
        justifyContent={"center"}
        spacing={8}
        alignItems={"center"}
        // style={{ height: "40vh" }}
      >
        {props.obfFillStatus == true ? (
          <Grid item md={12}>
            <div style={{margin:"130px"}}>
              <Typography variant={"h3"} color={"textPrimary"} align={"center"}>
                Please complete your <a href="/obf.html">On-boarding</a> form to
                get started
              </Typography>
            </div>
          </Grid>
        ) : (
          <Fragment>
            <Grid item md={12}>
              <div style={{ width: "20%", margin: "0 auto" }}>
                <CircularProgressbar
                  value={!isNaN(props.percentage) ? props.percentage : 0}
                  text={`${
                    !isNaN(props.remainingDays) ? props.remainingDays : 0
                  } DAY(S)`}
                  styles={buildStyles({
                    textSize: "1rem",
                    // How long animation takes to go from one percentage to another, in seconds
                    pathTransitionDuration: 0.5,
                    // Can specify path transition in more detail, or remove it entirely
                    // pathTransition: 'none',
                    // Colors
                    pathColor: `${props.theme.palette.common.liteOrange}`,
                    textColor: props.theme.palette.text.primary,
                    trailColor: props.theme.palette.common.liteGrey,
                  })}
                />
              </div>
            </Grid>
            <Grid item>
              <Typography variant={"body1"} color={"textPrimary"}>
                {props.statusMessage} {props.remainingDays} day(s) Your obflink
                is <a href="/obf.html">OBFLink</a>.
              </Typography>
            </Grid>
            {/* here we need to add link for OBF form 
            <Typography variant={"body1"} color={"textPrimary"}>
              Your obflink is <a href="/obf.html">OBFLink</a>.
            </Typography> */}
          </Fragment>
        )}
      </Grid>
    </div>
  );
}

Progress.propTypes = {
  obfFillStatus: PropTypes.bool,
  number: PropTypes.number,
  theme: PropTypes.any,
  statusMessage: PropTypes.any,
  remainingDays: PropTypes.any,
};

class Step1 extends React.PureComponent {
  render() {
    // console.log("this.props",this.props)
    const { jumpstartInfo, theme } = this.props;
    const { statusMessage, remainingDays, isObfFillStatus } = jumpstartInfo;
    console.log("jumpstartInfo=", jumpstartInfo);
    // const isObfFillStatus = false;
    const percentage = Math.round(Math.min((remainingDays / 30) * 100, 100));
    // const percentage = 10;
    return (
      <div>
        {/*  <c:when test="${remainingDays != null && remainingDays <= 0}">
                    <div id="" className="mx-auto my-5"><p className="m-0">${statusMessage}</p></div>
                </c:when>*/}
        {remainingDays != null && remainingDays < 0 ? (
          <Typography color={"textPrimary"} variant={"body1"} align={"center"}>
            {statusMessage}
          </Typography>
        ) : (
          <Progress
            obfFillStatus={isObfFillStatus}
            percentage={percentage}
            theme={theme}
            statusMessage={statusMessage}
            remainingDays={remainingDays}
          />
        )}

        {/*Step1*/}
      </div>
    );
  }
}

Step1.propTypes = {};

export default withStyles(styles, { withTheme: true })(Step1);
