import React, { Fragment } from "react";
import {
  CircularProgress,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import CommonStyles from "../../assets/jss/reports/commonStyles";
import icons from "../../components/icons";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Card } from "@mui/material/index";
import PerformanceGraphStyles from "../../assets/jss/reports/reportsStyles";
import * as _ from "lodash";
import {
  graphSpacing,
  IsLoading,
  renderTooltipContent,
  yAxisFontSize,
  yAxisFormatter,
} from "./performanceGraph";
// import {getComposedChart} from "./performanceGraph";

function DeviceGraph(props) {
  const {
    classes,
    data,
    kpiType,
    isLoading,
    theme,
    tabValue,
    handleChange,
    handleReportSent,
    handleRefs,
    handleDownloadReport,
    currency,
  } = props;
  let metric = "revenue";
  console.log("KPI type is metric: ", kpiType);
  console.log("Device distribution data is: ", data);
  if (kpiType != null && kpiType != undefined) {
    if (kpiType == "RC" || kpiType == "CR") {
      metric = "revenue";
    } else if (kpiType == "CPL") {
      metric = "leads";
    } else {
      metric = "orders";
    }
  }
  return (
    <Paper className={classes.paper}>
      <Grid
        container
        justifyContent={"space-between"}
        style={{ background: "#ffffff" }}
      >
        <Grid item sm={6} xs={6}>
          <Typography color={"textPrimary"} variant={"h3"}>
            <b>Device Distribution</b>
          </Typography>
        </Grid>
        {isLoading ? (
          <IsLoading classes={classes} />
        ) : (
          <Fragment>
            {_.isEmpty(data) || (data && data.data.length == 0) ? (
              <Grid item md={12}>
                <Typography
                  align={"center"}
                  color={"textPrimary"}
                  variant={"body1"}
                >
                  No data to display
                </Typography>
              </Grid>
            ) : (
              <Fragment>
                <Grid item>
                  <span
                    className={classes.icons}
                    onClick={() => handleReportSent("device")}
                  >
                    {icons.plane}
                  </span>
                  &nbsp; &nbsp; &nbsp;
                  <span
                    className={classes.icons}
                    onClick={(event) => handleDownloadReport("device", event)}
                  >
                    {icons.download}
                  </span>
                </Grid>
                <Grid item md={12}>
                  <Grid container>
                    <Grid item md={6}>
                      <Grid
                        container
                        alignItems={"center"}
                        spacing={2}
                        className={classes.legends}
                      >
                        <Grid item className={classes.legM}>
                          {icons.square(theme.palette.common.lightBlue)}
                        </Grid>
                        <Grid item>
                          <Typography
                            variant={"body2"}
                            color={"textPrimary"}
                            style={{ fontWeight: "500" }}
                          >
                            PPC Spend &nbsp;&nbsp;&nbsp;&nbsp;
                          </Typography>
                        </Grid>
                        <Grid item className={classes.legM}>
                          {icons.square(theme.palette.secondary.main)}
                        </Grid>
                        <Grid item>
                          <Typography
                            variant={"body2"}
                            color={"textPrimary"}
                            style={{
                              fontWeight: "500",
                              textTransform: "capitalize",
                            }}
                          >
                            {metric}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Card className={classes.card}>
                    <ResponsiveContainer width="100%" height={360}>
                      <ComposedChart
                        data={data.data}
                        ref={(ref) => ref}
                        margin={graphSpacing}
                      >
                        <CartesianGrid strokeDasharray="0 0" vertical={false} />
                        <XAxis
                          dataKey="device"
                          tick={{ fontSize: yAxisFontSize }}
                        />
                        {/*<YAxis/>*/}
                        {/*<XAxis dataKey="date" */}
                        {/*/>*/}
                        <YAxis
                          yAxisId="left"
                          axisLine={false}
                          tickFormatter={yAxisFormatter}
                          // width={70}
                          label={{
                            value: "PPC Spend",
                            angle: -90,
                            position: "insideLeft",
                          }}
                          tick={{ fontSize: yAxisFontSize }}
                        />
                        <YAxis
                          yAxisId="right"
                          orientation="right"
                          axisLine={false}
                          dataKey={metric == "revenue" ? "revenue" : metric}
                          name={metric == "revenue" ? "revenue" : metric}
                          tickFormatter={yAxisFormatter}
                          label={{
                            value: metric == "revenue" ? "Revenue" : metric,
                            angle: -90,
                            position: "right",
                          }}
                          // width={70}
                          tick={{ fontSize: yAxisFontSize }}
                        />
                      
                        <Tooltip
                          content={(props) =>
                            renderTooltipContent(props, currency)
                          }
                        />
                        <Bar
                          barSize={50}
                          yAxisId="left"
                          name="PPC Spend"
                          dataKey="cost"
                          fill="#1098F7"
                        />
                        <Line
                          yAxisId="right"
                          type="monotone"
                          strokeWidth={2}
                          name={metric == "revenue" ? "Revenue" : metric}
                          dataKey={metric == "revenue" ? "revenue" : metric}
                          stroke="#F58120"
                        />
                      </ComposedChart>
                    </ResponsiveContainer>
                  </Card>
                </Grid>
              </Fragment>
            )}
          </Fragment>
        )}
      </Grid>
    </Paper>
  );
}

export default withStyles(CommonStyles, { withTheme: true })(DeviceGraph);
