import * as React from "react";

function SvgTasks(props) {
  return (
    <svg width={18} height={18} fill="none" {...props}>
      <path
        d="M12.143 1.5c2.317 0 3.607 1.335 3.607 3.622v7.748c0 2.325-1.29 3.63-3.607 3.63H5.857c-2.28 0-3.607-1.305-3.607-3.63V5.123C2.25 2.834 3.578 1.5 5.857 1.5h6.286z"
        fill={props.fill}
      />
      <path
        d="M7.796 5.525a.542.542 0 00-.533.551c0 .305.239.551.533.551h2.487a.542.542 0 00.533-.55.542.542 0 00-.533-.552H7.796zM7.796 8.464a.542.542 0 00-.533.551c0 .305.239.551.533.551h2.487a.542.542 0 00.533-.55.542.542 0 00-.533-.552H7.796zM7.263 11.954c0-.305.239-.551.533-.551h4.263c.295 0 .533.246.533.55a.542.542 0 01-.533.552H7.796a.542.542 0 01-.533-.551zM5.664 6.627a.542.542 0 00.533-.55.542.542 0 00-.533-.552.542.542 0 00-.532.551c0 .305.238.551.532.551zM6.197 9.015a.542.542 0 01-.533.551.542.542 0 01-.532-.55c0-.305.238-.552.532-.552.295 0 .533.247.533.551zM5.664 12.505a.542.542 0 00.533-.551.542.542 0 00-.533-.551.542.542 0 00-.532.55c0 .305.238.552.532.552z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgTasks;
