import React, { Component } from "react";
import { Grid } from "@mui/material/index";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import { SnackbarContent, Button } from "@mui/material";
import { Link } from "react-router-dom";

const CustomSnackbarContent = withStyles((theme) => ({
  root: {
    maxWidth: "100%",
    margin: theme.spacing(1),
    justifyContent: "center",
    background: theme.palette.conversionAlertBg,
    color: theme.palette.conversionAlertText,
    fontWeight: "bold",
  },
}))(SnackbarContent);
const notificationStyles = (theme) => ({
  icon: {
    marginRight: theme.spacing(1),
    display: "flex",
  },
  message: {
    display: "flex",
    alignItems: "center",
    paddingRight: "1rem",
  },
});

export function PlainLink({ classes, ...rest }) {
  return <Link {...rest} className={classes.a} />;
}

PlainLink = withStyles({
  a: {
    width: "100%",
    color: "unset",
    textDecoration: "none",
    cursor: "pointer",
  },
})(PlainLink);

function UserSessionAlert(props) {
  const { classes, icon, message, userId } = props;
  return (
    <Grid container>
      <Grid item md={12}>
        <CustomSnackbarContent
          message={
            <div className={"flex"}>
              <div className={classes.message}>
                <span className={classes.icon}>{icon}</span>
                {message}
              </div>
              <div>
                <a
                  href={`/admin-user-table.html#/user-details/${userId}`}
                  style={{ textDecoration: "none" }}
                >
                  <Button type="button" variant={"contained"}>
                    <i className="fa fa-long-arrow-left" aria-hidden="true"></i>
                    &nbsp; GO Back
                  </Button>
                </a>
              </div>
            </div>
          }
        />
      </Grid>
    </Grid>
  );
}

export default withStyles(notificationStyles)(UserSessionAlert);
