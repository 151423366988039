import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import { withStyles } from "@mui/styles";
import * as _ from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  deleteDatatInServer,
  fetchOnDemandPackages,
  getAccountStructure,
  onDemandPayment,
  onDemandPaymentStatus,
  resetInputForms,
  resetItemCount,
} from "../../actions";
import { BlueBtn, WhiteBtn } from "../../components/buttons";
import icons from "../../components/icons";
import { CustomTableCell } from "../../components/onDemand/cart-container";
import {
  BackButton,
  CustomCardContent,
  CustomHeader,
} from "../../components/onDemand/service-details";
import Promocode from "../onDemandPromocode/promocode";
const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
});

const CustomTable = withStyles((theme) => ({
  root: {
    borderRadius: 3,
  },
}))(Table);

function WHSPaymentDetails(props) {
  const {
    classes,
    updatePayment,
    accountStructureDetails,
    taskMode,
    isPremierServicePackMonthly,
    getAccountStructure,
    fetchOnDemandPackagesReducer,
    fetchOnDemandPackages,
    OnDemandPromocodeReducer,
  } = props;

  console.log(
    "onDemandPromocodeReducer in WHS Payment",
    OnDemandPromocodeReducer
  );
 
  //User input promocode
  const [userPromocode, setUserPromocode] = useState("");
  const [promocodeStatus, setPromocodeStatus] = useState(null);
  const [finalGrandTotal, setFinalGrandTotal] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
      if(OnDemandPromocodeReducer && Object.keys(OnDemandPromocodeReducer).length > 1 && userPromocode != ""){
        setPromocodeStatus( OnDemandPromocodeReducer.codeStatus);
        setFinalGrandTotal(OnDemandPromocodeReducer.finalAmount)
      } else{
        setPromocodeStatus(null)
      }
    }, [OnDemandPromocodeReducer]);

    console.log("Promocode status in whs payment", promocodeStatus);

    const handlePromocodeChange = (e) => {
    setUserPromocode(e.target.value);
    if(promocodeStatus == false){
      setPromocodeStatus(null);
    }
  };

  const clearPromocodeSection = (value)=>{
    setPromocodeStatus(value);
    setUserPromocode("");
  }

  console.log(
    "promocode conditions in WHS payment",userPromocode
  );
  console.log("props.fromInputs==>", props.fromInputs);
  console.log("props.accountStructureDetails", accountStructureDetails);
  console.log(
    "fetchOnDemandPackagesReducer in whsPayment",
    fetchOnDemandPackagesReducer
  );
  const [packageName, setPackageName] = useState("");
  const [packageAmount, setPackageAmount] = useState("");

  useEffect(() => {
    if (
      fetchOnDemandPackagesReducer["message"] === "ON_DEMAND_PACKAGE_RECEIVED"
    ) {
      for (const key in fetchOnDemandPackagesReducer) {
        if (fetchOnDemandPackagesReducer[key].pricingId === 2) {
          const packageInfo = fetchOnDemandPackagesReducer[key];
          setPackageName(packageInfo.packageName);
          setPackageAmount(packageInfo.amount);
          break;
        }
      }
    }
  }, [fetchOnDemandPackagesReducer]);

  const [itemCounts, setItemCounts] = useState({});
  useEffect(() => {
    if (packageName) {
      setItemCounts({
        [packageName]: 1,
      });
    }
  }, [packageName]);

  var grandTotal = packageAmount;

  const [state, setState] = useState({
    cardValue: null,
    payDialog: false,
    cancelDialog: false,
    successDialog: false,
  });
  useEffect(() => {
    window.scrollTo(0, 0);
    getAccountStructure();
    fetchOnDemandPackages();
  }, []);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      cardValue: _.head(_.keys(accountStructureDetails.cardDetails)),
    }));
  }, [accountStructureDetails.cardDetails]);
  console.log("card value ====>", state.cardValue);

  const handleClickOpen = (toggle) => {
    if (toggle == true) {
      setState((prevState) => ({
        ...prevState,
        cancelDialog: true,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        payDialog: true,
      }));
    }
  };

  const handleClose = () => {
    setState((prevState) => ({
      ...prevState,
      payDialog: false,
      cancelDialog: false,
    }));
  };

  const handleCloseForFailedDialog = () => {
    props.updatePaymentStatus();
  };

  const UpdatePaymentStatus = () => {
    props.updatePaymentStatus();
    props.resetInputForms();
    props.resetCartCount();
  };

  const handleCancel = () => {
    handleClose();
    props.resetInputForms();
    props.resetCartCount();
    props.deleteInServer();
  };

  let miniService;
  let isCTPFund;

  const handlePayment = () => {
    handleClose();
    miniService = {
      ...miniService,
      serviceDetails: _.groupBy(props.fromInputs, "type"),
    };
    miniService = { ...miniService, grandTotal: grandTotal };
    miniService = { ...miniService, itemCounts: itemCounts };
    miniService = {
      ...miniService,
      isPremierServicePackMonthly: isPremierServicePackMonthly,
    };
    const promoCodeValue = userPromocode;
    if (
      state.cardValue == "FundFromCTP" &&
      props.accountStructureDetails.advanceRemaining >= miniService.grandTotal
    ) {
      //payment from CTP Fund only
      isCTPFund = 1;
      console.log(
        "we have enough CTP amount ",
        props.accountStructureDetails.advanceRemaining,
        " and Current package plan is ",
        miniService.grandTotal
      );

      props.updatePayment(miniService, "ONLY_CTP", isCTPFund,userPromocode);
    } else if (
      state.cardValue == "FundFromCTP" &&
      props.accountStructureDetails.advanceRemaining < miniService.grandTotal
    ) {
      //payment from the card and CTP Fund
      isCTPFund = 2; // from CTP and Card
      miniService = { ...miniService, grandTotal: grandTotal };
      props.updatePayment(miniService, "CTP+CARD", isCTPFund,userPromocode);
    } else {
      //payment only from the card
      props.updatePayment(miniService, state.cardValue, isCTPFund,userPromocode);
    }
    setUserPromocode(promoCodeValue);
  };

  const handleInputs = (event) => {
    console.log("On handle Inputs ==>", event.target.value, event.target.name);
    event.preventDefault();
    setState({
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    if (props.onDemandPayment.status == true) {
      setState((prevState) => ({
        ...prevState,
        successDialog: true,
      }));
    }
  }, [props.onDemandPayment.status == true]);

  const [paymentLoading, setPaymentLoading] = useState(true);
  useEffect(() => {
    if (props.onDemandPayment.message == "ON_DEMAND_PAYMENT_STATUS") {
     setPaymentLoading(false);
    }
  }, [props.onDemandPayment]);

  console.log("redux payment status=",props.onDemandPayment.message !== "ON_DEMAND_PAYMENT_STATUS",props.onDemandPayment);

  const PaymentContext = () =>
    state.payDialog == true ? (
      <Fragment>
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to pay?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Disagree
          </Button>
          <Button onClick={handlePayment} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Fragment>
    ) : null;

  const CancelContext = () =>
    state.cancelDialog == true ? (
      <Fragment>
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to cancel?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          <Link
            to="/website-health-scorecard"
            style={{ textDecoration: "none" }}
          >
            <Button onClick={handleCancel} color="primary" autoFocus>
              Yes
            </Button>
          </Link>
        </DialogActions>
      </Fragment>
    ) : null;

  console.log("props.onDemandPayment.status", props.onDemandPayment.status);
  const SuccessContext = () =>
    props.onDemandPayment.status == true ? (
      <Fragment>
        <DialogTitle id="alert-dialog-title">
          {"Payment successfully done"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Thank you! Your payment of ${finalGrandTotal != null && userPromocode != "" ? finalGrandTotal : grandTotal} has been successful.
            Someone from our team will reach out to you shortly for the next
            steps.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Link
            to={
              isPremierServicePackMonthly
                ? "/subscriptions"
                : "/website-health-scorecard"
            }
            style={{ textDecoration: "none" }}
          >
            <Button onClick={UpdatePaymentStatus} color="primary">
              Ok
            </Button>
          </Link>
        </DialogActions>
      </Fragment>
    ) : null;

  const FailedContext = () =>
    props.onDemandPayment.status == false ? (
      <Fragment>
        {props.onDemandPayment.isLoading == true ? (
          <Grid container>
            <Grid
              item
              style={{
                minHeight: "20vh",
                minWidth: "400px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Grid>
          </Grid>
        ) : (
          <Fragment>
            <DialogTitle id="alert-dialog-title">
              {"Payment not successful"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Payment not done, please try again.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseForFailedDialog}
                color="primary"
                autoFocus
              >
                Ok
              </Button>
            </DialogActions>
          </Fragment>
        )}
      </Fragment>
    ) : null;
  window.scrollTo(0, 0);
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ paddingTop: "1.125rem" }}
    >
      <Grid item sm={12} xs={12} md={10}>
        {props.onDemandPayment.isLoading != true ? (
          <Card className={classes.paper}>
            <CustomHeader
              avatar={
                <Link to="/website-health-scorecard">
                  <BackButton
                    variant="fab"
                    mini
                    disabled={taskMode}
                    aria-label="Back"
                  >
                    {icons.ChevronArrowLeft}
                  </BackButton>
                </Link>
              }
              title={"Payment"}
            />
            <CustomCardContent>
              <Grid container style={{ padding: 15 }}>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  className={"fullWidth"}
                  style={{ minHeight: "65vh" }}
                >
                  <Typography variant={"body1"}>
                    <p>
                      *If CTP amount is less than the package amount but greater
                      than $0, the remaining amount will be charged to the Card.
                    </p>
                    {/* <b>Grand Total: ${grandTotal}</b> */}
                  </Typography>
                  {accountStructureDetails.isLoading == true ? (
                    <CircularProgress />
                  ) : (
                    <Grid
                      container
                      direction={"column"}
                      alignItems={"flex-start"}
                    >
                      {_.map(
                        accountStructureDetails.cardDetails,
                        (cardName, paymentDetailId) => {
                          return (
                            <Grid item key={paymentDetailId}>
                              <RadioGroup
                                aria-label="gender"
                                name="cardValue"
                                value={
                                  state.cardValue != null ? state.cardValue : ""
                                }
                                onChange={handleInputs}
                              >
                                <FormControlLabel
                                  value={paymentDetailId}
                                  control={<Radio color="primary" />}
                                  label={`Use Credit Card  ${cardName}`}
                                />
                              </RadioGroup>
                            </Grid>
                          );
                        }
                      )}
                      <RadioGroup
                        disabled={accountStructureDetails.advanceRemaining == 0}
                        aria-label="gender"
                        name="cardValue"
                        value={state.cardValue != null ? state.cardValue : ""}
                        onChange={handleInputs}
                      >
                        <FormControlLabel
                          value="FundFromCTP"
                          control={<Radio color="primary" />}
                          label={`CTP Remaining Funds: ${
                            accountStructureDetails &&
                            typeof accountStructureDetails.advanceRemaining ===
                              "number"
                              ? `$${accountStructureDetails.advanceRemaining.toLocaleString(
                                  "en-US"
                                )}`
                              : ""
                          }`}
                          disabled={
                            accountStructureDetails.advanceRemaining == 0
                          }
                        />
                      </RadioGroup>
                    </Grid>
                  )}
                  <br />
                  <Typography>
                    <b>Price-Breakup</b>
                  </Typography>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <CustomTableCell>Package</CustomTableCell>
                        <CustomTableCell>Unit Price</CustomTableCell>
                        <CustomTableCell>Units</CustomTableCell>
                        <CustomTableCell numeric style={{ width: "12%" }}>
                          Total
                        </CustomTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <CustomTableCell>
                        <span style={{ fontSize: "16px" }}>
                          <b>{packageName}</b>
                        </span>
                      </CustomTableCell>
                      <CustomTableCell>
                        <b>${packageAmount}/</b>
                      </CustomTableCell>
                      <CustomTableCell>1</CustomTableCell>
                      <CustomTableCell align="right">
                        <b>${packageAmount}</b>
                      </CustomTableCell>
                    </TableBody>
                  </Table>

                  {/* Promo code Section */}
                  {Object.keys(OnDemandPromocodeReducer).length > 1 &&
                  promocodeStatus == true && userPromocode != "" && isLoading == false
                  ? (
                    <div>
                      <Table
                        style={{
                          borderTop: "1px solid #F1F3F9",
                          borderBottom: "1px solid #F1F3F9",
                        }}
                      >
                        <Grid container style={{ padding: "15px",paddingRight:"23px" }}>
                          <Grid item xs={6}>
                            <Typography style={{ wordWrap: "break-word" }}>
                              <b>
                                Promo Code Discount{" "}
                                <span style={{ color: "#808080" }}>
                                  ({userPromocode})
                                </span>
                              </b>
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                          <Typography variant="subtitle2" align="right">
                              <span
                                style={{
                                  color: "#F58120",
                                  paddingRight: "5px",
                                }}
                              >
                                ( - ){" "}
                              </span>
                              <b>${OnDemandPromocodeReducer.discountAmount}</b>
                            </Typography>              
                          </Grid>
                        </Grid>
                      </Table>
                      <Table>
                        <Grid
                          container
                          justify="flex-end"
                          style={{ padding: "15px",paddingRight:"23px"}}
                        >
                          <Grid item>
                            <Typography align="right">
                              <b>
                                Grand Total:{" "}
                                <span style={{ color: "#F58120", fontSize: "1.125rem" }}
                                >
                                  ${OnDemandPromocodeReducer.discountAmount > grandTotal ? 0 : (grandTotal - OnDemandPromocodeReducer.discountAmount)}
                                </span>
                              </b>
                            </Typography>
                          </Grid>
                        </Grid>
                      </Table>
                    </div>
                  ) : (
                    <div>
                    <Table
                      style={{
                        borderTop: "1px solid #F1F3F9",
                      }}
                    >
                      <Grid
                        container
                        justify="flex-end"
                        style={{ padding: "15px",paddingRight:"23px"  }}
                      >
                        <Grid item>
                          <Typography align="right">
                            <b>
                              Grand Total:{" "}<span style={{ color: "#F58120", fontSize: "1.125rem" }}>
                                ${grandTotal}
                              </span>
                            </b>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Table>
                  </div>
                  )}
                </Grid>
              </Grid>
            </CustomCardContent>
            <Grid item className={"fullWidth"}>
              <div
                style={{
                  textAlign: "right",
                  borderTop: "1px solid #8F99A7",
                  margin: 15,
                }}
              >
                <Grid container direction="row" alignItems="center">
                  <Grid item xs={12} sm={12} md={12} lg={9} xl={8}>
                    <Promocode 
                    onPromocodeChange={handlePromocodeChange} 
                    clearPromocodeSection={clearPromocodeSection} 
                    grandTotal={grandTotal} 
                    setIsLoading = {setIsLoading} 
                    setPaymentLoading ={setPaymentLoading}
                    userPromocode = {userPromocode}
                    promocodeSuccess = {promocodeStatus}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={3} xl={4}>
                    <WhiteBtn
                      variant="contained"
                      style={{ margin: 5 }}
                      onClick={() => handleClickOpen(true)}
                    >
                      Cancel
                    </WhiteBtn>
                    <BlueBtn
                      disabled={props.graderUser == true}
                      variant="contained"
                      style={{ margin: 5 }}
                      onClick={() => handleClickOpen(false)}
                    >
                      Pay
                    </BlueBtn>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Card>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "50vh",
            }}
          >
            <CircularProgress color="secondary" />
          </div>
        )}
        <Dialog
          open={
            state.payDialog ||
            state.cancelDialog ||
            props.onDemandPayment.status ||
            props.onDemandPayment.status == false
          }
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {PaymentContext()}
          {CancelContext()}
          {SuccessContext()}
          {FailedContext()}
        </Dialog>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  accountStructureDetails: state.accountStructureDetails,
  fromInputs: state.inputFroms,
  onDemandPayment: state.onDemandPayment,
  graderUser: state.perf.graderUser,
  isPremierServicePackMonthly:
    state.onDemandPayment.isPremierServicePackMonthly,
  fetchOnDemandPackagesReducer: state.fetchOnDemandPackagesReducer,
  OnDemandPromocodeReducer: state.OnDemandPromocodeReducer,
});
const mapDispatchToProps = (dispatch) => ({
  updatePaymentStatus: () => {
    return dispatch(onDemandPaymentStatus());
  },
  updatePayment: (miniService, paymentdetailId, isCTPFund, promoCode) => {
    return dispatch(onDemandPayment(miniService, paymentdetailId, isCTPFund,promoCode));
  },
  resetInputForms: () => {
    return dispatch(resetInputForms());
  },
  resetCartCount: () => {
    return dispatch(resetItemCount());
  },
  deleteInServer: () => {
    return dispatch(deleteDatatInServer());
  },
  getAccountStructure: () => {
    return dispatch(getAccountStructure());
  },
  fetchOnDemandPackages: () => {
    return dispatch(fetchOnDemandPackages());
  },
});

const style = (theme) => ({
  paper: {
    border: "1px solid #8F99A7",
    borderRadius: 3,
    [theme.breakpoints.down("md")]: {
      overflowX: "scroll",
      width: "80%",
      margin: "auto",
    },
  },
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(WHSPaymentDetails));
