import { withStyles } from "@mui/styles";
import { Button, IconButton, MenuItem } from "@mui/material";

export const BlueBtn = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.text.primary,
    color: theme.palette.common.white,
    textTransform: "uppercase",
    fontWeight: "bold",
    boxShadow: "0px 6px 12px -3px rgba(189,189,189,1)",
    margin: 10,
    "&:hover": {
      backgroundColor: theme.palette.text.primary,
    },
  },
}))(Button);
export const NewBlueBtn = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.text.primary,
    color: theme.palette.common.white,
    textTransform: "uppercase",
    fontWeight: "bold",
    boxShadow: "0px 6px 12px -3px rgba(189,189,189,1)",
    margin: 16,
    padding: "8px 35px",
    "&:hover": {
      backgroundColor: theme.palette.text.primary,
    },
  },
}))(Button);
export const GreyBtn = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[400],
    color: theme.palette.common.white,
    textTransform: "uppercase",
    fontWeight: "bold",
    boxShadow: "0px 6px 12px -3px rgba(224,224,224,1)",
    margin: 10,
    "&:hover": {
      backgroundColor: theme.palette.grey[400],
    },
  },
}))(Button);
export const WhiteBtn = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    textTransform: "uppercase",
    fontWeight: "bold",
    boxShadow: "0px 6px 12px -3px rgba(224,224,224,1)",
    margin: 10,
    "&:hover": {
      backgroundColor: theme.palette.common.white,
    },
  },
}))(Button);

export const CurvedBlueBtn = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.text.primary,
    color: theme.palette.common.white,
    textTransform: "capitalize",
    boxShadow: "0px 6px 12px -3px rgba(189,189,189,1)",
    margin: 10,
    borderRadius: 30,
    height: 45,
    [theme.breakpoints.down("xl")]: {
      fontSize: 12,
    },
    "&:hover": {
      fontWeight: "900",
      borderBottom: "5px solid #b24901",
      backgroundColor: theme.palette.orangeColor,
      color: theme.palette.common.white,
    },
  },
}))(Button);
export const CurvedOutLinedBlueBtn = withStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    fontWeight: "bold",
    boxShadow: "0px 6px 12px -3px rgba(189,189,189,1)",
    margin: 10,
    borderRadius: 30,
    border: "2px solid",
    [theme.breakpoints.down("xl")]: {
      fontSize: 12,
    },
    "&:hover": {
      backgroundColor: theme.palette.text.primary,
      color: theme.palette.common.white,
      borderColor: theme.palette.text.primary,
    },
    "&:first-letter": {
      color: theme.palette.orangeColor,
    },
  },
}))(Button);
export const LinkBtns = withStyles((theme) => ({
  root: {
    fontFamily: "Montserrat",
    color: theme.palette.liteBlack,
    textTransform: "capitalize",
    fontWeight: "bold",
    boxShadow: "0px 0px 0px 0px rgba(189,189,189,1)",
    margin: " 0px 10px 10px 10px",
    padding: "8px 0px",
    fontSize: 16,
  },
}))(Button);
export const LinkBtnsNew = withStyles((theme) => ({
  root: {
    fontFamily: "Montserrat",
    color: theme.palette.liteBlack,
    textTransform: "capitalize",
    fontWeight: "bold",
    boxShadow: "0px 0px 0px 0px rgba(189,189,189,1)",
    margin: " 0px 10px 10px 10px",
    padding: "8px 0px",
    fontSize: 16,
  },
  /*"&:after": {
        display: "inline-block",
        width: 0,
        height: 0,
        marginLeft: ".255em",
        verticalAlign: ".255em",
        content: "",
        borderTop: ".3em solid",
        borderRight: ".3em solid transparent",
        borderBottom: 0,
        borderLeft: ".3em solid transparent",
        boxSizing: "border-box",
    }*/
}))(Button);
export const BorderBtn = withStyles((theme) => ({
  root: {
    fontSize: 16,
    borderBottom: "5px solid #b24901",
    borderRadius: 0,
    color: theme.palette.common.white,
    fontWeight: "900",
    backgroundColor: theme.palette.orange2Color,
    padding: "0.4rem 1rem",
    "&:hover": {
      backgroundColor: theme.palette.orangeColor,
      color: theme.palette.common.white,
      opacity: 0.6,
    },
  },
}))(Button);

export const BurgerButton = withStyles((theme) => ({
  root: {
    color: theme.palette.orangeColor,
    borderColor: theme.palette.text.secondary,
    borderRadius: 5,
    border: "1px solid",
  },
}))(IconButton);

export const PostButton = withStyles((theme) => ({
  root: {
    backgroundColor: "#F58120",
    color: theme.palette.common.white,
    borderRadius: 4,
    // padding: "7px 55px",
    fontWeight: "bold",
    marginTop: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#F58120",
    },
    "&:first-letter": {
      backgroundColor: theme.palette.success,
    },
  },
}))(Button);
export const SignUpButton = withStyles((theme) => ({
  root: {
    backgroundColor: "#F58120",
    color: theme.palette.common.white,
    borderRadius: 4,
    boxShadow: "unset",
    // padding: "7px 55px",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "#E16904",
    },
    "&:first-letter": {
      backgroundColor: theme.palette.success,
    },
  },
}))(Button);
export const LoginOutLineButton = withStyles((theme) => ({
  root: {
    backgroundColor: "unset",
    color: theme.palette.common.white,
    borderRadius: 4,
    boxShadow: "unset",
    // padding: "7px 55px",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "#E16904",
    },
    "&:first-letter": {
      backgroundColor: theme.palette.success,
    },
  },
}))(Button);
export const GraderOutLineButton = withStyles((theme) => ({
  root: {
    backgroundColor: "unset",
    color: theme.palette.text.primary,
    borderRadius: 4,
    boxShadow: "unset",
    border: "1px solid " + theme.palette.common.darkHash,
    textTransform: "capitalize",
    // padding: "7px 55px",
    fontWeight: "700",
    [theme.breakpoints.down("xl")]: {
      paddingTop: 14,
      paddingBottom: 14,
      fontSize: 14,
    },
    "&:hover": {
      color: theme.palette.common.white,
      backgroundColor: "#E16904",
      border: "1px solid " + theme.palette.common.darkOrange,
    },
  },
}))(Button);
/*export const DemoSkyBlueButton = withStyles(theme => ({
    root: {
        backgroundColor: "unset",
        color: theme.palette.text.primary,
        borderRadius: 4,
        boxShadow: 'unset',
        border: "1px solid " + theme.palette.common.line,
        textTransform:"capitalize",
        // padding: "7px 55px",
        fontWeight: "bold",
        "&:hover": {
            color: theme.palette.common.white,
            backgroundColor: "#E16904",
        },
    }
}))(Button);*/
export const GreenButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.graderGraphColors.green,
    color: theme.palette.common.white,
    borderRadius: 4,
    // padding: "7px 55px",
    fontWeight: "bold",
    textTransform: "capitalize",
    // margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#F58120",
    },
    "&:first-letter": {
      backgroundColor: theme.palette.success,
    },
  },
}))(Button);

export const DownloadReportButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.orangeColor,
    color: theme.palette.common.white,
    borderRadius: 4,
    // padding: "7px 55px",
    fontWeight: "bold",
    textTransform: "capitalize",
    fontSize: "14px",
    // margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#E16904",
    },
    "&:first-letter": {
      backgroundColor: theme.palette.success,
    },
  },
}))(Button);
export const PricingButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.orangeColor,
    color: theme.palette.common.white,
    borderRadius: 3,
    // padding: "7px 55px",
    fontWeight: "bold",
    textTransform: "capitalize",
    fontSize: "14px",
    // margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#E16904",
    },
    "&:first-letter": {
      backgroundColor: theme.palette.success,
    },
  },
}))(Button);
export const OutLinedOrangeBtn = withStyles((theme) => ({
  root: {
    color: theme.palette.orangeColor,
    fontWeight: "bold",
    boxShadow: "0px 6px 12px -3px rgba(189,189,189,1)",
    margin: 10,
    border: "2px solid",
    [theme.breakpoints.down("xl")]: {
      fontSize: 12,
    },
    "&:hover": {
      backgroundColor: theme.palette.orangeColor,
      color: theme.palette.common.white,
      border: "2px solid",
      borderColor: theme.palette.orangeColor,
    },
  },
}))(Button);
export const OutLinedOrangeBtnBlueBorder = withStyles((theme) => ({
  root: {
    color: theme.palette.orangeColor,
    fontWeight: "bold",
    margin: 10,
    padding: "10px 30px 10px 30px",
    border: "1px solid" + theme.palette.text.secondary,
    [theme.breakpoints.down("xl")]: {
      fontSize: 12,
    },
    "&:hover": {
      backgroundColor: theme.palette.orangeColor,
      color: theme.palette.common.white,
      border: "1px solid",
      borderColor: theme.palette.orangeColor,
    },
  },
}))(Button);
export const OutLinedOrangeBtnBlueBorderNoUnderLine = withStyles((theme) => ({
  root: {
    color: theme.palette.orangeColor,
    fontWeight: "bold",
    margin: 10,
    padding: "10px 30px 10px 30px",
    border: "1px solid" + theme.palette.text.secondary,
    textDecoration: "none",
    [theme.breakpoints.down("xl")]: {
      fontSize: 12,
    },
    "&:hover": {
      backgroundColor: theme.palette.orangeColor,
      color: theme.palette.common.white,
      border: "1px solid",
      borderColor: theme.palette.orangeColor,
      textDecoration: "none",
    },
  },
}))(Button);

export const BlackBtnNew = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.text.secondary,
    color: theme.palette.common.white,
    fontWeight: "bold",
    textTransform: "capitalize",
    borderRadius: 0,
    padding: "8px 16px",
    // margin: 10,
    "&:hover": {
      backgroundColor: theme.palette.text.secondary,
      borderBottomWidth: 2,
      borderColor: theme.palette.common.white,
      borderStyle: "solid",
      textTransform: "capitalize",
      boxShadow: "0px 6px 12px -3px rgba(224,224,224,1)",
      borderRadius: 0,
    },
  },
}))(Button);
export const OrangeBtnNew = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.orangeColor,
    color: theme.palette.common.white,
    fontWeight: "bold",
    textTransform: "capitalize",
    borderRadius: 0,
    padding: "8px 16px",
    // margin: 10,
    "&:hover": {
      backgroundColor: theme.palette.text.secondary,
      borderBottomWidth: 2,
      borderColor: theme.palette.common.white,
      borderStyle: "solid",
      textTransform: "capitalize",
      boxShadow: "0px 6px 12px -3px rgba(224,224,224,1)",
      borderRadius: 0,
    },
  },
}))(Button);
export const ExternalOrangeBtnNew = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.orangeColor,
    color: theme.palette.common.white,
    fontWeight: "400",
    fontSize: 20,
    textTransform: "capitalize",
    borderRadius: 2,
    padding: "10px 16px",
    // margin: 10,
    "&:hover": {
      backgroundColor: theme.palette.text.secondary,
      borderBottomWidth: 2,
      borderColor: theme.palette.common.white,
      borderStyle: "solid",
      textTransform: "capitalize",
      boxShadow: "0px 6px 12px -3px rgba(224,224,224,1)",
      borderRadius: 2,
    },
  },
}))(Button);
export const WhiteAndOrangeText = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.orangeColor,
    fontWeight: "bold",
    textTransform: "capitalize",
    borderRadius: 0,
    padding: "8px 16px",
    // margin: 10,
    "&:hover": {
      backgroundColor: theme.palette.orangeColor,
      // borderBottomWidth: 2,
      color: theme.palette.common.white,
      // borderColor: theme.palette.orangeColor,
      // borderStyle: "solid",
      textTransform: "capitalize",
      boxShadow: "0px 6px 12px -3px rgba(224,224,224,1)",
      borderRadius: 0,
    },
  },
}))(Button);

export const OutLinedGreenBtn = withStyles((theme) => ({
  root: {
    color: theme.palette.common.white,
    fontWeight: "bold",
    boxShadow: "0px 6px 12px -3px rgba(189,189,189,1)",
    margin: 10,
    // border: "2px solid" + theme.palette.border,
    borderRadius: 0,
    backgroundColor: theme.palette.green,
    [theme.breakpoints.down("xl")]: {
      fontSize: 12,
    },
    "&:hover": {
      backgroundColor: theme.palette.green,
      color: theme.palette.common.white,
      border: "2.5px solid",
      borderColor: theme.palette.border,
      borderRadius: 0,
    },
  },
}))(Button);

export const OutLinedBlackBtn = withStyles((theme) => ({
  root: {
    color: theme.palette.orangeColor,
    fontWeight: "bold",
    boxShadow: "0px 6px 12px -3px rgba(189,189,189,1)",
    margin: 10,
    border: "1px solid" + theme.palette.text.secondary,
    textTransform: "capitalize",
    padding: "12px 8px",
    borderRadius: 2,
    [theme.breakpoints.down("xl")]: {
      fontSize: 12,
    },
    "&:hover": {
      backgroundColor: theme.palette.orangeColor,
      color: theme.palette.common.white,
      border: "1px solid",
      borderColor: theme.palette.orangeColor,
      padding: "12px 8px",
    },
  },
}))(Button);
export const OutLinedRectBlackBtn = withStyles((theme) => ({
  root: {
    color: theme.palette.orangeColor,
    fontWeight: "bold",
    boxShadow: "0px 6px 12px -3px rgba(189,189,189,1)",
    margin: 10,
    border: "1px solid" + theme.palette.text.secondary,
    textTransform: "capitalize",
    padding: "12px 8px",
    borderRadius: 2,
    [theme.breakpoints.down("xl")]: {
      fontSize: 12,
    },
    "&:hover": {
      backgroundColor: theme.palette.orangeColor,
      color: theme.palette.common.white,
      border: "1px solid",
      borderColor: theme.palette.orangeColor,
      padding: "12px 8px",
    },
  },
}))(Button);

export const PublishOrangeContainedBtn = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    boxShadow: "unset",
    // margin: 10,
    textTransform: "capitalize",
    padding: "6px 20px",
    borderRadius: 5,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: theme.palette.common.darkOrange,
      color: theme.palette.common.white,
      boxShadow: "unset",
      // margin: 10,
      textTransform: "capitalize",
      padding: "6px 20px",
      borderRadius: 5,
      fontWeight: "bold",
    },
  },
}))(Button);
export const NewOrangeContainedBtn = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    boxShadow: "unset",
    // margin: 10,
    textTransform: "capitalize",
    padding: "6px 20px",
    borderRadius: 5,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: theme.palette.common.darkOrange,
      color: theme.palette.common.white,
      boxShadow: "unset",
      // margin: 10,
      textTransform: "capitalize",
      padding: "6px 20px",
      borderRadius: 5,
      fontWeight: "bold",
    },
  },
}))(Button);
export const NewOrangeSaveContainedBtn = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    boxShadow: "unset",
    // margin: 10,
    textTransform: "capitalize",
    padding: "4px 20px",
    borderRadius: 5,
    // fontSize: theme.typography.pxToRem(16),
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: theme.palette.common.darkOrange,
      color: theme.palette.common.white,
      boxShadow: "unset",
      // margin: 10,
      textTransform: "capitalize",
      padding: "4px 20px",
      borderRadius: 5,
      fontWeight: "bold",
    },
  },
}))(Button);
export const NewOrangeSaveContainedCardBtn = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    boxShadow: "unset",
    // margin: 10,
    textTransform: "capitalize",
    padding: "6px 24px",
    borderRadius: 5,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: "bold",
    [theme.breakpoints.down("xl")]: {
      fontSize: 14,
    },
    "&:hover": {
      backgroundColor: theme.palette.common.darkOrange,
      color: theme.palette.common.white,
      boxShadow: "unset",
      // margin: 10,
      textTransform: "capitalize",
      padding: "6px 24px",
      borderRadius: 5,
      fontWeight: "bold",
    },

  },
}))(Button);
export const NewOrangeLargeBtn = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    boxShadow: "unset",
    // margin: 10,
    // textTransform: "capitalize",
    padding: "6px 40px",
    borderRadius: 5,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: theme.palette.common.darkOrange,
      color: theme.palette.common.white,
      boxShadow: "unset",
      // margin: 10,
      // textTransform: "capitalize",
      padding: "6px 40px",
      borderRadius: 5,
      fontWeight: "bold",
    },
  },
}))(Button);
export const NewOutLinedRectBlackBtn = withStyles((theme) => ({
  root: {
    color: theme.palette.orangeColor,
    fontWeight: "bold",
    // boxShadow: "0px 6px 12px -3px rgba(189,189,189,1)",
    margin: 10,
    border: "1px solid #A2B6C4",
    textTransform: "capitalize",
    padding: "6px 20px",
    borderRadius: 3,
    [theme.breakpoints.down("xl")]: {
      fontSize: 12,
    },
    "&:hover": {
      backgroundColor: theme.palette.orangeColor,
      color: theme.palette.common.white,
      border: "1px solid",
      borderColor: theme.palette.orangeColor,
      padding: "6px 20px",
    },
  },
}))(Button);
export const NewOutLinedRectBlueBtn = withStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    fontWeight: "bold",
    // boxShadow: "0px 6px 12px -3px rgba(189,189,189,1)",
    // margin: 10,
    border: "1px solid #A2B6C4",
    textTransform: "capitalize",
    padding: "6px 20px",
    borderRadius: 3,
    [theme.breakpoints.down("xl")]: {
      fontSize: 14,
    },
    "&:hover": {
      backgroundColor: theme.palette.orangeColor,
      color: theme.palette.common.white,
      border: "1px solid",
      borderColor: theme.palette.orangeColor,
      padding: "6px 20px",
    },
  },
}))(Button);
export const NewOutLinedRectCancelBlueBtn = withStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    fontWeight: "bold",
    // boxShadow: "0px 6px 12px -3px rgba(189,189,189,1)",
    // margin: 10,
    border: "1px solid #A2B6C4",
    textTransform: "capitalize",
    padding: "6px 20px",
    borderRadius: 3,
    marginRight: 5,
    
    [theme.breakpoints.down("xl")]: {
      fontSize: 12,
    },
    "&:hover": {
      backgroundColor: theme.palette.orangeColor,
      color: theme.palette.common.white,
      border: "1px solid",
      borderColor: theme.palette.orangeColor,
      padding: "6px 20px",
    },
  },
}))(Button);
export const NewOutLinedRectBlackTextBtn = withStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    fontWeight: "bold",
    // boxShadow: "0px 6px 12px -3px rgba(189,189,189,1)",
    margin: 10,
    border: "1px solid #A2B6C4",
    textTransform: "capitalize",
    padding: "6px 20px",
    boxSizing: "border-box",
    borderRadius: 3,
    [theme.breakpoints.down("xl")]: {
      fontSize: 12,
    },
    "&:hover": {
      backgroundColor: theme.palette.orangeColor,
      color: theme.palette.common.white,
      border: "1px solid",
      borderColor: theme.palette.orangeColor,
      padding: "6px 20px",
    },
  },
}))(Button);
export const NewTextRectOrangeTextBtn = withStyles((theme) => ({
  root: {
    color: theme.palette.orangeColor,
    fontWeight: "bold",
    // boxShadow: "0px 6px 12px -3px rgba(189,189,189,1)",
    margin: 10,
    // border: "1px solid #A2B6C4",
    textTransform: "capitalize",
    padding: "6px 20px",
    boxSizing: "border-box",
    // borderRadius: 3,
    [theme.breakpoints.down("xl")]: {
      fontSize: 14,
    },
    "&:hover": {
      // backgroundColor: theme.palette.orangeColor,
      // color: theme.palette.common.white,
      textDecoration: "underline",
      // border: "1px solid",
      // borderColor: theme.palette.orangeColor,
      padding: "6px 20px",
    },
  },
}))(Button);

export const NewTextRectOrangeTextBtn2 = withStyles((theme) => ({
  root: {
    color: theme.palette.orangeColor,
    fontWeight: "bold",
    textTransform: "capitalize",
    boxSizing: "border-box",
    [theme.breakpoints.down("lg")]: {
      fontSize: 12,
    },
    "&:hover": {
      textDecoration: "underline",
    },
  },
}))(Button);

export const SkyBlueBtn = withStyles((theme) => ({
  root: {
    fontFamily: "Lato",
    backgroundColor: theme.palette.common.lightBlue,
    color: "white",
    fontSize: 16,
    textTransform: "none",
    fontWeight: "700",
    [theme.breakpoints.down("xl")]: {
      // paddingTop: 14,
      // paddingBottom: 14,
      // fontSize: 14,
    },
    "&:hover": {
      color: theme.palette.common.white,
      backgroundColor: "#E16904",
    },
  },
}))(Button);
export const PopUpCommonBtn = withStyles((theme) => ({
  root: {
    fontWeight: "bold",
    color: theme.palette.text.primary,
    padding: "7px 16px",
    // fontWeight:"bold"
  },
}))(Button);

export const RoyalBlueUnderlinedButton = withStyles((theme) => ({
  root: {
    // font-family: 'Lato';
    // font-style: normal;
    fontWeight: 700,
    fontSize: "16px",
    // line-height: 19px;
    // textAlign: center,
    // textDecoration: "underline",

    boxShadow: "none",
    backgroundColor: "#A2B6C3",
    color: "white",
    cursor: "text",

    // fontWeight:"bold"
  },
}))(Button);

export const Blueborderedwithbluetext = withStyles((theme) => ({
  root: {
    backgroundColor: "#F3F8FF",
    color: "#46558F",
    boxShadow: "unset",
    // margin: 10,
    textTransform: "capitalize",
    padding: "6px 20px",
    borderRadius: 5,
    border: "2px solid #46558F",

    fontSize: theme.typography.pxToRem(16),
    fontWeight: "bold",
    // "&:hover": {
    //     backgroundColor: theme.palette.common.darkOrange,
    //     color: theme.palette.common.white,
    //     boxShadow: "unset",
    //     // margin: 10,
    //     textTransform: "capitalize",
    //     padding: "6px 20px",
    //     // borderRadius: 5,
    //     border: 'none',
    //     fontWeight: "bold",
    // }
  },
}))(Button);

export const WHSOrangeButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    boxShadow: "unset",
    textTransform: "capitalize",
    padding: "7px 9px",
    fontSize: theme.typography.pxToRem(16),
    [theme.breakpoints.down("md")]: {
      fontSize: theme.typography.pxToRem(14),
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "2px",
      width: "100%",
    },
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: theme.palette.common.darkOrange,
      color: theme.palette.common.white,
      boxShadow: "unset",
      textTransform: "capitalize",
      padding: "7px 9px",
      fontWeight: "bold",
    },
  },
}))(Button);

export const NewGreyOutLinedRectBtn = withStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    fontWeight: "bold",
    // boxShadow: "0px 6px 12px -3px rgba(189,189,189,1)",
    margin: 10,
    border: "1px solid #6d6d6d",
    textTransform: "capitalize",
    padding: "5px 18px",
    boxSizing: "border-box",
    borderRadius: 3,
    [theme.breakpoints.down("md")]: {
      fontSize: 12,
    },
    "&:hover": {
      backgroundColor: theme.palette.orangeColor,
      color: theme.palette.common.white,
      border: "1px solid",
      borderColor: theme.palette.orangeColor,
      padding: "5px 18px",
    },
  },
}))(Button);

export const WHSBannerButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    boxShadow: "unset",
    textTransform: "capitalize",
    padding: "5px 20px",
    fontSize: theme.typography.pxToRem(16),
    fontWeight: "bold",
    position: "absolute",
    top: "83%",
    left: "63%",
    transform: "translate(-50%, -50%)",
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
      left: "67%",
      padding: "2px 16px",
    },
    "&:hover": {
      backgroundColor: theme.palette.common.darkOrange,
      color: theme.palette.common.white,
      boxShadow: "unset",
      // margin: 10,
      textTransform: "capitalize",
      padding: "5px 20px",
      fontWeight: "bold",
    },
  },
}))(Button);

export const PromocodeApplyButton = withStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    border: "1px solid #1E334E",
    textTransform: "capitalize",
    padding: "3px 10px",
    boxSizing: "border-box",
    borderRadius: "0",
    fontSize: "1rem",
    width: "100%",
    "&:hover": {
      background: "none",
    },
    "&:disabled": {
      borderColor: "#D3D9D9",
    },
  },
}))(Button);

export const JsOrangeContainedBtn = withStyles((theme) => ({
  root: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main,
    textTransform: "none",
    borderRadius: "0",
    fontSize: "1rem",
    width: "100%",
    textAlign: "center",
    fontWeight: "600",
    "&:hover": {
      backgroundColor: theme.palette.common.darkOrange,
    },
    "&:disabled":{
      backgroundColor: "#DEDEDE",
      color: "#838383"
    }
  },
}))(Button);

export const JsBlackContainedBtn = withStyles((theme) => ({
  root: {
    color: theme.palette.common.white,
    backgroundColor: "#747474",
    textTransform: "none",
    borderRadius: "0",
    width:"100px",
    padding: "5% 4%",
    fontSize: "0.875rem",
    textAlign: "center",
    fontWeight: "600",
    "&:hover": {
      backgroundColor:"#454545",
    },
  },
}))(Button);

export const JsTextAsButton = withStyles((theme) => ({
  root: {
    color: theme.palette.secondary.main,
    fontFamily: "Lato",
    fontSize: "0.875rem",
    fontWeight: "500",
    lineHeight:"normal",
    "&:hover": {
      background:"none"
    },
    float:"right",
    textTransform:"none",
  },
}))(Button);

