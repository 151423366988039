import React from 'react';
import {Card, Typography} from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import {CompactCardContent, SubSectionCardHeader} from '../cards';
import CampaignSnapshotRow from '../../components/dashboard/campaign-snapshot-table-row.jsx'
import {typeIcon} from '../../components/dashboard/campaign-snapshot-table-row.jsx'
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import * as _ from 'lodash';
import icons from "../icons";
import {TableCellNoPadding, TableRowAutoHeight} from '../table';
import {kpiLabel, KpiString} from "../../selectors/dashboard-selectors";

function CampaignSnapshot({campaignsSnapshot: stats, isLoading,theme,kpiType}) {
    // stats.push(Object.assign({}, stats[0], {type:"Other"}) );

    // stats = stats.filter(s => s.type !== "Shopping");
    stats=_.keyBy(stats,"type");

    const TableCell = TableCellNoPadding;

    return (
        <Card className={"fullHeight"} id={"campaignSnapshot"}>
            <SubSectionCardHeader title={"Campaign Snapshot"} action={<i className="fa fa-info-circle" aria-hidden="true" title={"Here you can see all of the important statistics for your campaigns broken down by search and shopping campaigns"}></i>}/>
            <CompactCardContent>
            {isLoading ?
                <CircularProgress/> :
                <Table style={{tableLayout:"fixed"}}>
                    <TableHead>
                        <TableRow>
                            <TableCell numeric/>
                            <TableCell numeric>
                                {icons.Views}
                                <Typography variant={"caption"}>Views</Typography>
                            </TableCell>
                            <TableCell numeric>
                                {icons.Click}
                                <Typography variant={"caption"}>Clicks</Typography>
                            </TableCell>
                            <TableCell numeric>
                                {icons.Ctr}
                                <Typography variant={"caption"}>CTR</Typography>
                            </TableCell>
                            <TableCell numeric>
                                {icons.Orders}
                                <Typography variant={"caption"}>{kpiLabel[kpiType]}</Typography>
                            </TableCell>
                            <TableCell numeric>
                                {icons.Spend }
                                <Typography variant={"caption"}>Spend</Typography>
                            </TableCell>
                            <TableCell numeric>
                                {icons.Revenue}
                                <Typography variant={"caption"}>Revenue</Typography>
                            </TableCell>
                            <TableCell numeric>
                                {icons.KPI}
                                <Typography variant={"caption"}>{KpiString[kpiType]}</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {stats.Search ? <CampaignSnapshotRow stat={stats.Search} />
                        :

                            <TableRowAutoHeight>
                                <TableCell>
                                    <Typography variant={"caption"} style={{fontSize: 8}}>
                                        {typeIcon("Search")}
                                        {"Search"}
                                    </Typography>
                                </TableCell>
                                <TableCell colSpan={"7"} style={{textAlign:"center"}} >
                                    <Typography variant={"caption"}>No Search Campaigns!</Typography>
                                    <Typography variant={"caption"}>Please create Search campaigns for better account performance.</Typography>
                                </TableCell>
                            </TableRowAutoHeight>
                        }
                        {stats.Shopping ? <CampaignSnapshotRow stat={stats.Shopping} />
                            :
                            <TableRowAutoHeight>
                                <TableCell>
                                    <Typography variant={"caption"} style={{fontSize: 8}}>
                                        {typeIcon("Shopping")}
                                        {"Shopping"}
                                    </Typography>
                                </TableCell>
                                <TableCell colSpan={"7"} style={{textAlign:"center"}} >
                                    <Typography variant={"caption"}>No Shopping Campaigns!</Typography>
                                    <Typography variant={"caption"}>Please create shopping campaigns for better account performance.</Typography>
                                </TableCell>
                            </TableRowAutoHeight>
                        }
                        {stats.ALL && <CampaignSnapshotRow stat={stats.ALL} />}
                    </TableBody>
                </Table>
            }
            </CompactCardContent>
        </Card>

    );
}

CampaignSnapshot.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default CampaignSnapshot;