import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import WhsHomepage from "./whsHomepage";
import WhsIntegration from "./whsIntegration";
import CircularProgress from "@mui/material/CircularProgress";
import { Typography } from "@mui/material";

const WhsComponents = ({
  WHSExternalUserDetailsReducer,
  websiteHealthRefreshStatusReducer,
}) => {
  const [syncCheckId, setSyncCheckId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [completedValue, setCompletedValue] = useState(5);
  const [completedStatus, setCompletedStatus] = useState(
    websiteHealthRefreshStatusReducer
      ? websiteHealthRefreshStatusReducer.status
      : null
  );
  useEffect(() => {
    if (Object.keys(WHSExternalUserDetailsReducer).length > 1) {
      setSyncCheckId(WHSExternalUserDetailsReducer.syncCheckId);
      setUserId(WHSExternalUserDetailsReducer.userId);
      sessionStorage.setItem(
        "WHSExternalId",
        WHSExternalUserDetailsReducer.userId
      );
    }
  }, [WHSExternalUserDetailsReducer]);

  useEffect(() => {
    if (websiteHealthRefreshStatusReducer) {
      setCompletedValue(
        websiteHealthRefreshStatusReducer.complete_value
          ? websiteHealthRefreshStatusReducer.complete_value
          : 5
      );
      setCompletedStatus(websiteHealthRefreshStatusReducer.status);
    }
  }, [
    websiteHealthRefreshStatusReducer &&
      websiteHealthRefreshStatusReducer.complete_value,
  ]);

  useEffect(() => {
    if (completedValue === 100 || syncCheckId === "") {
      window.location.href = "/website-health-scorecard-data";
    }
  }, [completedValue, syncCheckId]);
  console.log("whs conditions", completedValue, syncCheckId);
  return (
    <div style={{zoom:"90%"}}>
      {syncCheckId != null && syncCheckId != "" && completedValue !== 100 ? (
        <WhsIntegration
          syncCheckId={syncCheckId}
          completedValue={completedValue}
          completedStatus={completedStatus}
        />
      ) : syncCheckId == null ||
        (syncCheckId == "" && completedValue !== 100) ? (
        <WhsHomepage />
      ) : (
        <Typography align="center" style={{ marginTop: "3.125rem" }}>
          <CircularProgress color={"secondary"} />
        </Typography>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  WHSExternalUserDetailsReducer: state.WHSExternalUserDetailsReducer,
  websiteHealthRefreshStatusReducer: state.websiteHealthRefreshStatusReducer,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(WhsComponents);
