const newFooterStyles = (theme) => ({
  footerBlock: {
    padding: "1rem",
    backgroundColor: "none",
    color: "#BDBDBD",
    // "&:before":{
    //     height:60,
    // }
  },
  subfooter1: {
    textAlign: "center",
    opacity: "0.5",
  },
  subfooter2: {
    textAlign: "right",
  },
  icon: {
    padding: theme.spacing(1),
    color: "#BDBDBD",
  },
});
export default newFooterStyles;
