import { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import {
  CircularProgress,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import JumpstartMain from "../jumpstartMain";
import { useHistory, useParams } from "react-router-dom";
import PageHeaderDetails from "../commonComponents/pageHeaderDetails.jsx";
import {
  fetchSyncCheckId,
  createAccountStructure,
} from "../../../actions/jumpstartRevamped";

function GenerateAccountStructure(props) {
  const {
    createAccountStructure,
    createAccountStructureReducer,
    fetchSyncCheckId,
    fetchSyncCheckIdReducer,
  } = props;
  const { id } = useParams();
  const [jwtToken, setJwtToken] = useState(sessionStorage.getItem("jwtToken"));

  // Progress bar
  const [message, setMessage] = useState(
    "Jumpstart Account Setup Sync process started"
  );
  const [progress, setProgress] = useState(5);
  const [syncCheckId, setSyncCheckId] = useState(null);
  const [error, setError] = useState(false);
  const [status, setStatus] = useState();
  const history = useHistory();
  const [tokensExpire, setTokensExpire] = useState(false);

  useEffect(() => {
    async function generateAccountStructure() {
      const data = await fetchSyncCheckId(jwtToken, id);
      if (data && data.status == 200) {
        setSyncCheckId(data.syncCheckId);
      } else if (data && data.status == 400) {
        setError(true);
        setTokensExpire(true);
      } else {
        setError(true);
      }
    }
    generateAccountStructure();
  }, []);

  const countRef = useRef(null);
  countRef.current = progress;

  useEffect(() => {
    if (!error && syncCheckId != null && syncCheckId != "") {
      const interval = setInterval(() => {
        if (status == 1) {
          clearInterval(interval);
          history.push(`/campaign-structure/${id}`);
        } else if (status == 2) {
          clearInterval(interval);
          setError(true);
        } else {
          createAccountStructure(jwtToken, syncCheckId);
        }
      }, 7000);
      return () => clearInterval(interval);
    }
  }, [syncCheckId, status]);

  useEffect(() => {
    if (
      createAccountStructureReducer &&
      createAccountStructureReducer.reducer_message ===
        "CREATE_ACCOUNT_STRUCTURE_RECEIVED"
    ) {
      setProgress(createAccountStructureReducer.complete_value);
      setMessage(createAccountStructureReducer.message);
      setStatus(createAccountStructureReducer.js_status);
    }
  }, [createAccountStructureReducer]);
 
  return (
    <JumpstartMain>
      <div className="pageContent">
        <PageHeaderDetails
          sectionHeading={"Campaign Framework:"}
          token={jwtToken}
        />
        <div style={{ padding: "16% 2% 3%" }}>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            rowSpacing={2}
            style={{ background: "#fff", padding: "5%", height: "370px" }}
          >
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <Box>
                <LinearProgress
                  variant="determinate"
                  color={error || status == 2 ? "error" : "secondary"}
                  sx={{
                    height: "20px",
                  }}
                  value={progress}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h3" textAlign="center" fontWeight="500">
                {progress}%
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h3" textAlign="center" fontWeight="500">
                {tokensExpire ? "Jumpstart Account Integration failed. Refresh Tokens has expired." : error || status == 2
                  ? "Jumpstart Account Integration failed"
                  : message}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
    </JumpstartMain>
  );
}

const mapStateToProps = (state) => ({
  createAccountStructureReducer: state.createAccountStructureReducer,
  fetchSyncCheckIdReducer: state.fetchSyncCheckIdReducer,
});

const mapDispatchToProps = (dispatch) => ({
  createAccountStructure: (token, id) =>
    dispatch(createAccountStructure(token, id)),
  fetchSyncCheckId: (token, id) => dispatch(fetchSyncCheckId(token, id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GenerateAccountStructure);
