import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
  
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { store } from "../../reducers/common";
import { deleteShoppingFeedConnection } from "../../actions/connectionPageActions";
import MySnackbarWrapper from "../snackbars";
import { CircularProgress } from "@mui/material/CircularProgress";
import {
  NewOrangeContainedBtn,
  NewOutLinedRectCancelBlueBtn,
} from "../../components/buttons";
import icons from "../../components/icons";
import  {ConnectionTypeTypography}  from "../../components/typography";

const styles = (theme) => ({
  GaGrid: {
    backgroundColor: "#FFFFFF",
    [theme.breakpoints.up("md")]: {
      width: "100%",
      height: "88px",
      border: "solid 2px #BBBBBB",
      borderRadius: "6px",
      padding: "0px 10px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "15px",
      width: "100%",
      height: "300px",
      border: "solid 1px #BBBBBB",
      borderRadius: "3px",
      textAlign: "center",
    },
  },
  TextCenter: {
    textAlign: "Center",
    fontSize: "16px",
  },

  gridItems: {
    margin: "Auto 0",
  },
  buttons: {
    backgroundColor: "#F58120",
    border: "solid 1px #1E334E",
  },
  flexCenter:{
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  dataStyle:{
    fontWeight:"500",
    color:"#1E334E",
    wordBreak:"break-word",
  },
  verticalLine:{
    paddingRight: "7px",
    [theme.breakpoints.down("sm")]: {
    display: "none",
    },
  },
});

let engineId = 0;
function ShoppingFeedConnection(props) {
  window.scrollTo(0, 0);
  const classes = props.classes;

  const {
    deleteShoppingFeedConnection,
    shoppingFeedData,
    deleteShoppingFeedReducer,
  } = props;

  //Shopping feed details
  const [platformType, setPlatformType] = useState("");
  const [feedName, setFeedName] = useState("");
  const [shoppingFeedId, setShoppingFeedId] = useState("");
  const [alert, setAlert] = useState(false);
  const [shoppingFeedError, setShoppingFeedError] = useState(false);

  useEffect(() => {
    if (shoppingFeedData) {
        setPlatformType(
          shoppingFeedData.platformType ? shoppingFeedData.platformType : "-"
        );
        setFeedName(
          shoppingFeedData.feedName ? shoppingFeedData.feedName : "-"
        );
        setShoppingFeedId(shoppingFeedData.shoppingFeedId);
    }
  }, [shoppingFeedData]);

  const [open, setOpen] = React.useState(false);

  const handleDialogToClose = () => {
    setOpen(false)
  }
  const handleDialogToOpen = () => {
    setOpen(true);
  };

  const handleCloseAlert = async () => {
    await setAlert(false);
  };

  const [deleteObjectAccountName, setDeleteObjectAccountName] = useState("");

  const handleShoppingFeedDeleteConnection = async (shoppingFeedId,platformType) => {
    setDeleteObjectAccountName(platformType);
    setOpen(false);
    const data = await deleteShoppingFeedConnection(shoppingFeedId); // Wait for deleteGMCConnection to complete
    if(data === true){
      setShoppingFeedError(false);
    }else{
      setShoppingFeedError(true);
    }
    setAlert(true);
  };

  useEffect(() => {
    if (deleteShoppingFeedReducer.message === "DELETE_SHOPPING_FEED_RECEIVED") {
      console.log("Shopping feed reducer",deleteShoppingFeedReducer);
      if (deleteObjectAccountName === platformType) {
        setPlatformType("");
        setFeedName("");
      }
    }
  }, [deleteShoppingFeedReducer]);
  const handleAddAccount = () => {
    window.location.href="/shopping-settings.html#shopping-menu-items";
  }
  return (
    <div style={{ marginTop: "20px" }}>
      <Grid container className={classes.GaGrid}>
        <Grid item xs={12} sm={12} md={3} lg={3} className={classes.gridItems}>
            <div className={classes.flexCenter}>
              {icons.ShoppingFeed}
              <ConnectionTypeTypography>Shopping Feed</ConnectionTypeTypography>
            </div>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3} className={classes.gridItems}>
          {feedName === "" || feedName === "-" ? (
            <Typography></Typography>
          ) : (
            <div className={classes.flexCenter}>
            <div className={classes.verticalLine}>{icons.SegregationLine}</div> 
            <Typography style={{color:"#747474"}}>Feed Name:<br/> <span className={classes.dataStyle}>{feedName}</span></Typography>
           </div>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2} className={classes.gridItems}>
          {platformType === "" || platformType === "-" ? (
            <Typography></Typography>
          ) : (
            <div className={classes.flexCenter}>
            <div className={classes.verticalLine}>{icons.SegregationLine}</div> 
            <Typography style={{color:"#747474"}}>Platform Name:<br/>
              {platformType === "WooCommerce" ? 
                              // icons.BigCommerce :
                              // icons.Shopify :
                 icons.WooCommerce:
                platformType === "BigCommerce" ?
                icons.BigCommerce :
                platformType === "Shopify" ?
                icons.Shopify :
                <span className={classes.dataStyle}>{platformType}</span>
              }
            </Typography>
           </div>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2} className={classes.gridItems}>
          {(shoppingFeedData &&
            deleteShoppingFeedReducer["message"] === "") ||
            deleteShoppingFeedReducer["message"] === "DELETE_SHOPPING_FEED_FAILED" ? (
               <Typography>
                <NewOutLinedRectCancelBlueBtn
                  onClick={() =>
                    (window.location.href =
                      "/shopping-settings.html#shopping-menu-items")
                  }
                  type={"button"}
                >
                  Refresh Token
                </NewOutLinedRectCancelBlueBtn>
            </Typography>
          ) : (
            <Typography></Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2} className={classes.gridItems}>
          {(shoppingFeedData &&
            deleteShoppingFeedReducer["message"] === "") ||
            deleteShoppingFeedReducer["message"] === "DELETE_SHOPPING_FEED_FAILED" ? (
            <Typography>
              <NewOutLinedRectCancelBlueBtn onClick={handleDialogToOpen}>
                Delete Account
              </NewOutLinedRectCancelBlueBtn>
            </Typography>
          ) : (
            <Typography>
              {/* <a
                href="/shopping-settings.html#shopping-menu-items"
                name="addGMC"
                id="addGMC"
              > */}
                <NewOrangeContainedBtn
                  onClick={handleAddAccount}
                  type={"button"}
                >
                  +Add Account
                </NewOrangeContainedBtn>
              {/* </a> */}
            </Typography>
          )}
        </Grid>
      </Grid>

      <Dialog open={open} onClose={handleDialogToClose}>
        <DialogTitle>{"Delete Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you really want to delete Shopping Feed integration in LXRGuide?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() =>
              handleShoppingFeedDeleteConnection(shoppingFeedId,platformType)
            }
            color="primary"
            autoFocus
          >
            Delete
          </Button>
          <Button onClick={handleDialogToClose} color="primary" autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <MySnackbarWrapper
        open={alert}
        onClose={handleCloseAlert}
        message={shoppingFeedError ? "Unable to delete shopping feed account" : "Shopping Feed account successfully deleted"}
        verticalAlign={"bottom"}
        horizontalAlign={"right"}
        duration={4000}
        variant={shoppingFeedError ? "error" : "success"}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  deleteShoppingFeedReducer: state.deleteShoppingFeedReducer,
});
const mapDispatchToProps = (dispatch) => ({
  deleteShoppingFeedConnection: (shoppingFeedId) =>
    dispatch(deleteShoppingFeedConnection(shoppingFeedId)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(ShoppingFeedConnection));
