import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Menu } from "@mui/icons-material";
import {
  Box,
  ClickAwayListener,
  Grid,
  Grow,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popover,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { Divider, Typography } from "@mui/material/index";
import classNames from "classnames";
import icons from "../../components/icons";
import { Link } from "react-router-dom";
import notificationIcon1 from "../../assets/img/celebrate-icon.svg";
import { PlainLink } from "../../components/dashboard/task";

const CustomMenu = withStyles((theme) => ({
  root: {
    "&:focus": {
      outline: "unset !important",
    },
  },
}))(Menu);
const CustomMenuItemSmallMenu = withStyles((theme) => ({
  root: {
    paddingTop: 8,
    paddingBottom: 8,
    "& > div": {
      "&:focus": {
        outline: "none !important",
      },
    },
    "&:hover": {
      backgroundColor: theme.palette.common.whiterGrey,
    },
  },
  selected: {
    backgroundColor: theme.palette.common.whiterGrey + " !important",
  },
}))(MenuItem);
const styles = (theme) => ({
  paper: {
    width: 452,
    background: "#FFFFFF",
    boxShadow: "14px 14px 60px rgba(30, 51, 78, 0.14)",
    borderRadius: 3,
    zIndex: "99999999",
  },
  p: {
    padding: theme.spacing(1),
  },
  iconp: {
    paddingTop: 2,
  },
  readUnread: {
    fontSize: "0.5rem",
  },
  readBg: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
  },
  unreadBg: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.common.skyBlue,
  },
  timeText: {
    opacity: 0.5,
    paddingLeft: "15px",
  },
});
export const iconsNames = {
  information: "R/C",
  warning: "C/R",
  CPO: "CPO",
  CPL: "CPL",
};

export function notificationIcon(notificationType, message) {
  if (
    message != null &&
    message != "" &&
    (message.search("Awesome") != -1 || message.search("great") != -1)
  ) {
    return icons.notificationIcon1;
  }
  if (message != null && message != "" && message.search("lowering") != -1) {
    return icons.infoIcon;
  } else {
    switch (notificationType) {
      case "information":
        // for information suggetion symbol
        return icons.notificationIcon2;
      case "warning":
        return icons.notificationIcon3;
      default:
        // return icons.notificationIcon1;
        return icons.infoIcon;
    }
  }
}
export function displayTextTrim(message) {
  let modifiedMessage = "";
  if (
    message != null &&
    message != "" &&
    (message.search("<b>") != -1 || message.search("</b>"))
  ) {
    modifiedMessage = message.replace("<b>", "");
    modifiedMessage = modifiedMessage.replace("</b>", "");
    return modifiedMessage;
  } else {
    return message;
  }
}

function Notification(props) {
  const {
    notificationAnchor,
    handleClose,
    handleChange,
    notificationList,
    classes,
    selectedPpcId,
  } = props;
  const open = Boolean(notificationAnchor);
  return (
    <div>
      <Popover
        open={open}
        anchorEl={notificationAnchor}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}

        //anchorPosition
        // transition
        // disablePortal
        // placement={"bottom-end"}
        // style={{ zIndex: "99999999" }}
      >
        {/* {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps} id="menu-list-grow"> */}
        <Paper className={classes.paper}>
          <ClickAwayListener onClickAway={() => handleClose()}>
            <Box>
              <Grid
                container
                justifyContent={"space-between"}
                className={classNames(classes.p)}
              >
                <Grid item>
                  <Typography color={"textPrimary"} variant={"body1"}>
                    <b>Notifications</b>
                  </Typography>
                </Grid>
                {/* <Grid item>
                      <Typography color={"secondary"} variant={"body2"}>
                        Mark all as read
                      </Typography>
                    </Grid> */}
              </Grid>
              <Divider />
              {notificationList != null && notificationList.length > 0 ? (
                notificationList
                  .filter((notification) => notification.ppcId == selectedPpcId)
                  .slice(0, 5)
                  .map((notification) => (
                    <Grid
                      container
                      alignItems={"flex-start"}
                      justifyContent={"space-around"}
                      //className={(notification.read == false) ? classes.unreadBg : classes.readBg}>
                      className={classes.readBg}
                    >
                      <Grid item>
                        <Typography className={classes.iconp}>
                          {notificationIcon(
                            notification.priority,
                            notification.displayText
                          )}
                        </Typography>
                      </Grid>
                      <Grid item md={10}>
                        {notification.displayText != null &&
                        notification.displayText != "" ? (
                          <Typography
                            variant={"body1"}
                            style={{ fontWeight: 500 }}
                            color={"textPrimary"}
                          >
                            {displayTextTrim(notification.displayText)}
                          </Typography>
                        ) : (
                          <Typography>No message</Typography>
                        )}
                      </Grid>
                      <Grid item md={12}>
                        <Typography
                          className={classes.timeText}
                          color={"textPrimary"}
                          variant={"caption"}
                          align={"right"}
                        >
                          {notification.displayTime}
                        </Typography>
                      </Grid>
                    </Grid>
                  ))
              ) : (
                <Typography
                  variant={"body1"}
                  color={"textPrimary"}
                  align={"center"}
                >
                  No Notifications
                </Typography>
              )}
              {/*{(notificationList != null && notificationList.length > 0) ?*/}

              {/*}*/}
              <Grid
                container
                justifyContent={"center"}
                className={classNames(classes.p)}
              >
                <Grid item md={12}>
                  <PlainLink
                    to={`/notifications/${selectedPpcId}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Typography
                      color={"textPrimary"}
                      variant={"body1"}
                      align={"center"}
                    >
                      <b>See All</b>
                    </Typography>
                  </PlainLink>
                </Grid>
              </Grid>
            </Box>
          </ClickAwayListener>
        </Paper>
        {/* </Grow>
        )} */}
      </Popover>
    </div>
  );
}

Notification.propTypes = {};

export default withStyles(styles)(Notification);
