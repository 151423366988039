import React, {Fragment} from 'react';
import ReactDOM from 'react-dom';

import {connect} from 'react-redux';

import { Typography, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import * as _ from 'lodash';

import {sumUpImpactOfIds, idToTaskDataList} from '../../reducers/common';
import {executeTasks, toggleSelection, addAdCopyMiniService} from '../../actions/index';

import icons from '../icons';

import {Hierarchy, IconAndName} from './hierarchy';
import {ShoppingHierarchy} from './shoppingHierarchy';
import KeywordHierarchy from './keywordHierarchy';
import {TypeTitle} from './task-data';
import {Summary} from './summary';
import {saveOnDemandFroms, taskSeenDate, updateTaskDisplayStatus} from "../../actions";
import {POST_TRIGGER, EXPANSION_STATUS} from "../../actions/actionTypes";
import TaskStatus from './task-status'

const CustomExpansionPanelDetails = withStyles(theme => ({
    root: {
        backgroundColor: "#F5F6FA",
    }
}))(AccordionDetails);
const CustomExpansionPanelSummary = withStyles(theme => ({
    root: {
        boxShadow: "0px 0px 3px 1px rgba(0,0,0,0.14)",
        backgroundColor: theme.palette.common.white,
        paddingLeft: 0,
    }
}))(AccordionSummary);


function markTaskSeen(expanded, taskIds, taskSeenDate) {
    if (expanded) {
        let markTaskId = _.uniq(taskIds);
        if (markTaskId.length == 1) taskSeenDate(markTaskId)
    }
}

function trackExpansion(summary, status) {
    if (this.props.organizeBy != 'Campaign') {
        this.props.taskExpansionTrack(summary.groupId, status);
    } else {
        this.props.taskExpansionTrack('Campaign', status);
    }
}

function taskSeenByAdminAndUser() {
    if (this.props.isAdmin) {
        const {data} = this.props;
        const {summary} = data;
        if (summary.displayStatus == 0) {
            this.props.updateTaskDisplayStatus(data.summary.taskId, 1)
        }
    } else {
        markTaskSeen(this.state.expanded, this.props.TaskIds, this.props.taskSeenDate);
    }
}

class GroupExpansionPanel extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            expanded: props.defaultExpanded,
            doRender: props.defaultExpanded,
            postAlert: false,
            pushAlert: false,
        };

        taskSeenByAdminAndUser.call(this);
        this.myrefs = {}
    }

    renderCampaignHeader = (campaignId) => {
        const {campaignIdMap} = this.props;
        let campaign = campaignIdMap[campaignId];
        // let CampaignTypeIcon = icons[campaign.campaignType] || Typography;
        return (
            <IconAndName icon={icons.Campaign} name={
                <Fragment>
                    <span><b>{campaign.campaignName}</b></span>
                    {/*<CampaignTypeIcon style={{height:20, width:20, verticalAlign:"middle"}}/>*/}
                    <TypeTitle type="Campaign" value={campaign.campaignType}/>
                    {/*<Typography variant={"caption"} className={"inline"}>({campaign.campaignType})</Typography>*/}
                </Fragment>
            }/>
        )
    };
    renderTaskTypeHeader = (taskTypeId) => {
        const {taskTypeIdMap} = this.props;
        const taskType = taskTypeIdMap[taskTypeId];
        return (
            <IconAndName icon={icons.Task} name={taskType.taskName} bold caption={taskType.taskDescription}/>
        );
    };
    renderAdgroupHeader = (name) => <IconAndName icon={icons.AdGroup} name={name}/>;

    headerRenderers = {
        TaskType: [this.renderCampaignHeader, this.renderAdgroupHeader],
        Campaign: [this.renderTaskTypeHeader, this.renderAdgroupHeader],
    };

    onExpandCollapse = async (e, expanded) => {
        await this.setState({expanded:expanded});
        const {data} = this.props;
        const {summary} = data;
        if (expanded) {
            this.setState({doRender: true});
            trackExpansion.call(this, summary, "Opened");
        } else {
            trackExpansion.call(this, summary, "Closed");
        }
        taskSeenByAdminAndUser.call(this);
    };

    componentDidMount() {
        if (this.state.expanded) {
            this.fixSticky();
        }
    }

    // noinspection JSUnusedLocalSymbols
    componentDidUpdate(prevProps) {
        this.fixSticky();
    }

    fixSticky() {
        const nodes = _.mapValues(this.myrefs, (ref) => ReactDOM.findDOMNode(ref));
        const heights = _.mapValues(nodes, (node) => node.clientHeight);
        const height = heights.content + heights.summary;
        const needsSticky = height > window.innerHeight;
        nodes.summary.style.position = needsSticky ? "sticky" : "relative";
    }

    onSelectClearAll = (event) => {
        const value = event.target.getAttribute('value');

        console.log("event =", event);
        console.log("value=", event.target.getAttribute('value'));
        if (!value) return;

        const selected = (value == "SelectAll");
        this.props.toggleSelection(this.props.selectableIds, selected)
    };
    postPopUp = () => {
        console.log("Post PopUp Triggered");
        this.setState({postAlert: true});
        this.props.postTrigger();
    };

    handleClose = () => {
        this.setState({postAlert: false, pushAlert: false});
    };
    postAgree = () => {
        console.log("selected = ", this.props.selectedIds);
        this.setState({postAlert: false});
        this.props.executeTasks(this.props.selectedIds);

    };
    handleTaskPush = () => {
        // this.props.updateTaskDisplayStatus(taskId, 2);
        this.setState({pushAlert: true});
    };

    handlePushAgree = (taskId) => {
        // alert();
        this.setState({pushAlert: false});
        this.props.updateTaskDisplayStatus(taskId, 2);
    };

    handleAddAdCopy = async () => {
        await this.props.addAdCopyMiniServices();
        /*below code to save the MiniService object to backend*/
        /* console.log("calculateGrandTotal=", calculateGrandTotal(this.props.miniServiceItemCounts));
         console.log("serviceDetails=", _.groupBy(this.props.miniServiceFromInputs, "type"));
         let miniService = {...miniService, serviceDetails: _.groupBy(this.props.miniServiceFromInputs, "type")};
         miniService = {...miniService, grandTotal: calculateGrandTotal(this.props.miniServiceItemCounts)};
         miniService = {...miniService, itemCounts: this.props.miniServiceItemCounts};
         console.log("miniService=", miniService);
         this.props.saveMiniServiceToServer(miniService);*/
    };


    render() {
        const {data, selectableIds, organizedBy, defaultExpanded, selectedIds, currency, isAdmin, selectedServicePacks} = this.props;
        const {summary, items} = data;
        const {doRender, expanded, postAlert,pushAlert} = this.state;
        return (
            <Accordion defaultExpanded={defaultExpanded} onChange={this.onExpandCollapse}
                            style={{marginBottom: 10}} CollapseProps={{timeout: 0}}>
                <CustomExpansionPanelSummary expandIcon={<ExpandMoreIcon/>} style={{top: 0, zIndex: 1}}
                                             ref={(e) => this.myrefs['summary'] = e}>
                    <TaskStatus dataIdList={data.dataIdList} displayStatus={summary.displayStatus}/>
                    <Summary icon={icons[organizedBy]} title={summary.title} description={summary.description}
                             {...summary.impact}
                             selectedImpact={sumUpImpactOfIds(selectedIds)}
                             selectedCount={selectedIds.length}
                             totalCount={selectableIds.length}
                             expanded={expanded}
                             onSelectClearAll={this.onSelectClearAll}
                             campaignType={summary.campaignType}
                             postPopUp={this.postPopUp}
                             pushPopUp={this.handleTaskPush}
                             postAlert={postAlert}
                             pushAlert={pushAlert}
                             handleClose={this.handleClose}
                             postAgree={this.postAgree}
                             pushAgree={(taskId) => this.handlePushAgree(taskId)}
                             currency={currency}
                             groupId={summary.groupId}
                             displayStatus={summary.displayStatus}
                             taskId={summary.taskId}
                             // pushTask={(taskId) => this.handleTaskPush(taskId)}
                             isAdmin={isAdmin}
                             selectedServicePacks={selectedServicePacks}
                             addAdCopyMiniServices={this.handleAddAdCopy}
                    />
                </CustomExpansionPanelSummary>
                <CustomExpansionPanelDetails ref={(e) => this.myrefs['content'] = e}>
                    <div className={"fullWidth"}>
                        {doRender && summary.groupId != 49 && summary.groupId != 54 &&
                        <Hierarchy
                            data={items}
                            headerRenderers={this.headerRenderers[organizedBy]}
                            title={summary.title}
                            groupId = {summary.groupId}
                        />
                        }
                        {doRender && summary.groupId == 49 &&
                        <ShoppingHierarchy taskId={summary.taskId}/>
                        }
                        {doRender && summary.groupId == 54 &&
                        <KeywordHierarchy summary={summary} data={data} dataIdList={data.dataIdList}/>
                        }
                    </div>
                </CustomExpansionPanelDetails>
            </Accordion>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    selectedServicePacks: state.selectedServicePackBytTaskDataIds,
    campaignIdMap: state.campaignIdMap,
    taskTypeIdMap: state.taskTypeIdMap,
    selectableIds: ownProps.data.dataIdList.filter(id => state.taskDataById[id].status == 0),
    selectedIds: ownProps.data.dataIdList.filter(id => state.taskDataById[id].selected),
    TaskIds: ownProps.data.dataIdList.map(id => state.taskDataById[id]).filter((taskData) => taskData.taskSeenDate == null).map(taskdata => taskdata.taskId),
    isAdmin: state.taskAnalysis.isAdmin,
    miniServiceItemCounts: state.miniServiceItemCount,
    miniServiceFromInputs: state.inputFroms,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    executeTasks: (taskDataIdList) => dispatch(executeTasks(idToTaskDataList(taskDataIdList))),
    toggleSelection: (taskIds, selected) => dispatch(toggleSelection(taskIds, selected)),
    taskSeenDate: (taskIds) => dispatch(taskSeenDate(taskIds)),
    addAdCopyMiniServices: () => dispatch(addAdCopyMiniService()),
    saveMiniServiceToServer: (miniService) => {
        return dispatch(saveOnDemandFroms(miniService))
    },
    postTrigger: (organizeBy) => dispatch({type: POST_TRIGGER}),
    taskExpansionTrack: (taskTypeId, expansionStatus) => dispatch({
        type: EXPANSION_STATUS,
        data: {taskTypeId, expansionStatus}
    }),
    updateTaskDisplayStatus: (taskId, displayType) => dispatch(updateTaskDisplayStatus(taskId, displayType))
});

function areStatePropsEqual(next, prev) {
    const isEqual =
        (next.campaignIdMap == prev.campaignIdMap) &&
        (next.taskTypeIdMap == prev.taskTypeIdMap) &&
        _.isEqual(next.selectableIds, prev.selectableIds) &&
        _.isEqual(next.selectedIds, prev.selectedIds) &&
        _.isEqual(next.TaskIds, prev.TaskIds) &&
        _.isEqual(next.selectedServicePacks, prev.selectedServicePacks) &&
        _.isEqual(next.miniServiceItemCounts, prev.miniServiceItemCounts) &&
        _.isEqual(next.miniServiceFromInputs, prev.miniServiceFromInputs)
    ;
    return isEqual;

}

GroupExpansionPanel = connect(mapStateToProps, mapDispatchToProps, null, {areStatePropsEqual})(GroupExpansionPanel);

export default GroupExpansionPanel;


