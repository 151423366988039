import React, { Fragment } from "react";
import icons from "../../components/icons";
import classNames from "classnames";
import { Grid, Typography, CardHeader } from "@mui/material";

import withStyles from '@mui/styles/withStyles';

export function InternalTitleIconAndName({
  icon,
  name,
  caption,
  classes,
  titleColor,
  siteGraderScore,
}) {
  console.log("titleColor", titleColor);
  console.log("siteGraderScore=", siteGraderScore);
  return (
    <Grid
      container
      style={{ padding: 10, height: "100%" }}
      alignItems={"center"}
    >
      <Grid item md={12} className={"growable"}>
        <Grid container spacing={2} justifyContent={"center"}>
          <Grid item md={12} align={"center"} className={classes.icon}>
            {icon}
          </Grid>
          <Grid item md={12}>
            <Typography
              component={"h1"}
              variant={"h1"}
              align={"center"}
              color={"textPrimary"}
              className={classNames(
                classNames(classes.titleScore, classes.siteScoreMainTitle)
              )}
            >
              {siteGraderScore}/100
            </Typography>
            <Typography
              variant="caption"
              align={"center"}
              color={"textPrimary"}
              className={classNames(classes.siteScoreTitle)}
            >
              SITE SCORE
            </Typography>
            <Typography
              component={"p"}
              align={"center"}
              color={"textPrimary"}
              variant={"body2"}
              style={{ opacity: 0.5 }}
            >
              <b>Benchmark score :</b>
              &nbsp;60
            </Typography>
            <Grid item>
              <Typography
                className={titleColor}
                align={"center"}
                variant={"body2"}
                style={{ maxWidth: "auto" }}
              >
                {name}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                align={"center"}
                variant={"body2"}
                color={"textPrimary"}
                style={{ maxWidth: "auto", opacity: 0.5 }}
              >
                <b>{caption}</b>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export function InternalReportTitle(props) {
  console.log("props.classes.red", props.classes.red);
  console.log("siteGraderScore <= 49", props.siteGraderScore <= 49);
  console.log(
    "props.siteGraderScore >= 50 && props.siteGraderScore <= 69",
    props.siteGraderScore >= 50 && props.siteGraderScore <= 69
  );
  return (
    <Fragment>
      {props.siteGraderScore <= 49 ? (
        <InternalTitleIconAndName
          icon={icons.cupRed("", 35)}
          name={"Your site performance is low and needs improvement!"}
          caption={
            props.redirectedURL != null ? props.redirectedURL : "www.domain.com"
          }
          classes={props.classes}
          titleColor={props.classes.red}
          siteGraderScore={props.siteGraderScore}
        />
      ) : props.siteGraderScore >= 50 && props.siteGraderScore <= 69 ? (
        <InternalTitleIconAndName
          icon={icons.cupYellow("", 35)}
          name={"Your site performance looks good!"}
          caption={
            props.redirectedURL != null ? props.redirectedURL : "www.domain.com"
          }
          classes={props.classes}
          titleColor={props.classes.titleYellow}
          siteGraderScore={props.siteGraderScore}
        />
      ) : (
        <InternalTitleIconAndName
          icon={icons.cupGreen("", 35)}
          name={"Your site performance looks great!"}
          caption={
            props.redirectedURL != null ? props.redirectedURL : "www.domain.com"
          }
          classes={props.classes}
          titleColor={props.classes.green}
          siteGraderScore={props.siteGraderScore}
        />
      )}
    </Fragment>
  );
}

export function IconText({ text, padding, classes, mobile }) {
  return (
    <Grid
      container
      alignItems={"center"}
      spacing={2}
      style={padding ? { paddingLeft: "4%" } : null}
    >
      <Grid item className={mobile == true ? classes.iconG : classes.iconR}>
        {mobile == true ? icons.checkedSquare : icons.closeSquare}
      </Grid>
      <Grid item>
        <Typography className={classes.title}>{text}</Typography>
      </Grid>
    </Grid>
  );
}

export function IconPara({ icon, title, text, padding, classes, des, mobile }) {
  return (
    <Grid
      container
      alignItems={"center"}
      spacing={2}
      style={padding ? { padding: "8px 0px 0px 30px" } : null}
    >
      <Grid item md={1} className={classes.icon}>
        {icon}
      </Grid>
      <Grid item md={11} style={{ paddingLeft: "5%" }}>
        <Typography
          className={
            mobile == true
              ? classNames(classes.titlePara, classes.titleGreen)
              : classNames(classes.titlePara, classes.titleRed)
          }
        >
          {title}
        </Typography>
        <Typography className={classes.para}>{text}</Typography>
      </Grid>
      <Grid item md={12}>
        <Typography className={classes.des}>{des}</Typography>
      </Grid>
    </Grid>
  );
}

export function IconMainText({ text, classes }) {
  return (
    <Grid container alignItems={"center"} spacing={2}>
      <Grid item>{icons.mobile}</Grid>
      <Grid item>
        <Typography style={{ color: "#1E334E", fontWeight: "bold" }}>
          {text}
        </Typography>
      </Grid>
    </Grid>
  );
}

export function GetMeters({ classes, speedScore, secounds, textColor, type }) {
  const heightStyles = type && type == 1 ? classes.meter_h2 : classes.meter_h1;
  return (
    <div>
      <Grid container justifyContent={"center"}>
        <Grid item md={9} sm={8} xs={12}>
          <Grid container>
            <Grid item md={12} sm={12} xs={12}>
              <div
                className={classes.headPointer}
                style={{
                  width: speedScore ? speedScore + "%" : "15%",
                  transition: speedScore ? "all 3s" : "unset",
                }}
              >
                <div
                  className={
                    secounds != 0 ? classes.pointer : classes.pointerInvalid
                  }
                >
                  {/*<Typography className={secounds != 0 ? classNames(classes.pointerText, textColor): classNames(classes.pointerTextInvalid)}
                                            component={"div"}>{secounds}s</Typography>*/}
                  <Typography
                    className={classNames(classes.pointerText, textColor)}
                    component={"div"}
                  >
                    {secounds}s
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <div className={"flex"} style={{ width: "100%" }}>
                <div
                  style={{ width: "15%" }}
                  lg={2}
                  md={2}
                  sm={2}
                  className={classNames(classes.meter1, heightStyles)}
                >
                  <Typography className={classes.scaleText} align={"right"}>
                    3s
                  </Typography>
                </div>
                <div
                  style={{ width: "20%" }}
                  lg={2}
                  md={2}
                  sm={2}
                  className={classNames(classes.meter2, heightStyles)}
                >
                  <Typography className={classes.scaleText} align={"right"}>
                    5s
                  </Typography>
                </div>
                <div
                  style={{ width: "25%" }}
                  lg={2}
                  md={2}
                  sm={2}
                  className={classNames(classes.meter3, heightStyles)}
                >
                  <Typography className={classes.scaleText} align={"right"}>
                    8s
                  </Typography>
                </div>
                <div
                  style={{ width: "35%" }}
                  lg={2}
                  md={2}
                  sm={2}
                  className={classNames(classes.meter4, heightStyles)}
                >
                  <Typography className={classes.scaleText} align={"right"}>
                    30s
                  </Typography>
                </div>
                <div
                  style={{ width: "5%" }}
                  md={4}
                  sm={4}
                  className={classNames(classes.meter5, heightStyles)}
                ></div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export const SubSectionCardHeader = withStyles((theme) => ({
  title: {
    fontFamily: "Lato",
    color: "#1E334E",
    fontSize: 14,
    fontWeight: "bold",
  },
  subheader: {
    fontSize: "12px",
    fontWeight: "300",
    color: "#1E334E",
  },
}))(CardHeader);
