import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormControl, MenuItem, Select } from "@mui/material";
import { withStyles } from "@mui/styles";
import ExpandMore from "@mui/icons-material/ExpandMore";

function WeeklyDateRange(props) {
  const { toggleDateRange, selectedDateRange, classes } = props;
  return (
    <div className={classes.marb}>
      <CustomSelect
        disableUnderline
        value={selectedDateRange}
        onChange={toggleDateRange}
        IconComponent={ExpandMore}
        className={classes.select}
        // inputProps={{
        //     name: 'age',
        //     id: 'age-simple',
        // }}
      >
        <CustomMenuItem value={1}>Current Week</CustomMenuItem>
        <CustomMenuItem value={2}>Last Week</CustomMenuItem>
        <CustomMenuItem value={3}>Last 4 Weeks</CustomMenuItem>
        <CustomMenuItem value={4}>Last 8 Weeks</CustomMenuItem>
        <CustomMenuItem value={5}>Last 16 Weeks</CustomMenuItem>
      </CustomSelect>
    </div>
  );
}

const CustomSelect = withStyles((theme) => ({
  icon: {
    color: theme.palette.text.primary,
  },
}))(Select);
const CustomMenuItem = withStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    fontWeight: "500",
    fontSize: "1rem",
  },
}))(MenuItem);
const style = (theme) => ({
  select: {
    // backgroundColor: "rgba(30, 51, 78, 0.1)",
    "& div": {
      paddingTop: 1,
      paddingBottom: 1,
      paddingLeft: 2,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  marb: {
    marginBottom: 4,
  },
});
export default withStyles(style)(WeeklyDateRange);
