import React, { Component, Fragment } from "react";
import { Drawer, Grid } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import clsx from "classnames";
import * as _ from "lodash";
import { Paper, Divider, CircularProgress } from "@mui/material";
import Typography from "@mui/material/Typography";
import icons from "../components/icons";
import { KpiString, kpiValue } from "../selectors/dashboard-selectors";
import NumericLabel from "react-pretty-numbers";
import { connect } from "react-redux";
import useScreenSizes from './useScreenSizes';


// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="xl" />;



const metrics = [
  "clicks",
  "impressions",
  "spend",
  "orders",
  "revenue",
  "kpiValue",
];
export const metricTitles = {
  "clicks": "Clicks",
  "impressions": "Impressions",
  "orders": "Orders",
  "revenue": "Revenue",
  "spend": "Spend",
};
export const kpiLabel = {
  "RC": "Orders",
  "CR": "Orders",
  "CPO": "Orders",
  "CPL": "Leads"
};
export const numberFormat = (value) =>
  new Intl.NumberFormat("en-US", {}).format(value);

function calculateDiffrence(searchMetricDifference) {
  let icon = icons.upBigArrow;
  let color = "#CF0505";
  if (searchMetricDifference > 0.0) {
    icon = icons.upBigArrow;
    color = "#1EBC61";
    
  } else if (searchMetricDifference < 0.0) {
    icon = icons.downindicationArrow;
    color = "#CF0505";
  } else {
    icon = icons.equalsArrow;
    color = "#1E334E";
  }
  return { icon, color };
}

function extracted(channelGroup) {
  const allSpend = (channelGroup.ALL && channelGroup.ALL["spend"]) ? channelGroup.ALL["spend"] : 0;
  const allRevenue = (channelGroup.ALL && channelGroup.ALL["revenue"]) ? channelGroup.ALL["revenue"] : 0;
  const allOrders = (channelGroup.ALL && channelGroup.ALL["orders"]) ? channelGroup.ALL["orders"] : 0;
  const searchSpend = (channelGroup.Search && channelGroup.Search["spend"]) ? channelGroup.Search["spend"] : 0;
  const searchRevenue = (channelGroup.Search && channelGroup.Search["revenue"]) ? channelGroup.Search["revenue"] : 0;
  const searchOrders = (channelGroup.Search && channelGroup.Search["orders"]) ? channelGroup.Search["orders"] : 0;
  const ShoppingSpend = (channelGroup.Shopping && channelGroup.Shopping["spend"]) ? channelGroup.Shopping["spend"] : 0;
  const ShoppingRevenue = (channelGroup.Shopping && channelGroup.Shopping["revenue"]) ? channelGroup.Shopping["revenue"] : 0;
  const ShoppingOrders = (channelGroup.Shopping && channelGroup.Shopping["orders"]) ? channelGroup.Shopping["orders"] : 0;
  const allSpendP = (channelGroup.ALL && channelGroup.ALL["spendP"]) ? channelGroup.ALL["spendP"] : 0;
  const allRevenueP = (channelGroup.ALL && channelGroup.ALL["revenueP"]) ? channelGroup.ALL["revenueP"] : 0;
  const allOrdersP = (channelGroup.ALL && channelGroup.ALL["ordersP"]) ? channelGroup.ALL["ordersP"] : 0;
  const searchSpendP = (channelGroup.Search && channelGroup.Search["spendP"]) ? channelGroup.Search["spendP"] : 0;
  const searchRevenueP = (channelGroup.Search && channelGroup.Search["revenueP"]) ? channelGroup.Search["revenueP"] : 0;
  const searchOrdersP = (channelGroup.Search && channelGroup.Search["ordersP"]) ? channelGroup.Search["ordersP"] : 0;
  const ShoppingSpendP = (channelGroup.Shopping && channelGroup.Shopping["spendP"]) ? channelGroup.Shopping["spendP"] : 0;
  const ShoppingRevenueP = (channelGroup.Shopping && channelGroup.Shopping["revenueP"]) ? channelGroup.Shopping["revenueP"] : 0;
  const ShoppingOrdersP = (channelGroup.Shopping && channelGroup.Shopping["ordersP"]) ? channelGroup.Shopping["ordersP"] : 0;
  return {
    allSpend,
    allRevenue,
    allOrders,
    searchSpend,
    searchRevenue,
    searchOrders,
    ShoppingSpend,
    ShoppingRevenue,
    ShoppingOrders,
    allSpendP,
    allRevenueP,
    allOrdersP,
    searchSpendP,
    searchRevenueP,
    searchOrdersP,
    ShoppingSpendP,
    ShoppingRevenueP,
    ShoppingOrdersP
  };
}


function metricCalCulation(classes, stats, previousStats, metric, KpiType) {
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const channelGroup = _.keyBy(stats, "type");
  // console.log("Stats = ", stats);
  // console.log("previousStats = ", previousStats);
  const PreviousChannelGroup = _.keyBy(previousStats, "typeP");

  const metricTotal = _.sumBy(stats.filter(stat => stat.type == "ALL"), metric);
  const previousMetricTotal = _.sumBy(
    previousStats.filter((stat) => stat.typeP == "ALL"),
    metric + "P"
  );
  // console.log("metric", metric)
  // console.log("metricTotal", metricTotal)
  // console.log("previousMetricTotal", previousMetricTotal)

  const metricDifference = previousMetricTotal > 0 ? Math.round(((metricTotal / previousMetricTotal) - 1) * 100) : 0;

  // console.log("metricDifference=", metricDifference);
  const __metric = calculateDiffrence(metricDifference);
  const search = channelGroup.Search ? channelGroup.Search[metric] : 0;
  const PreviousSearch = PreviousChannelGroup.Search
    ? PreviousChannelGroup.Search[metric + "P"]
    : 0;

  const searchMetricDifference =
    PreviousSearch > 0 ? Math.round((search / PreviousSearch - 1) * 100) : 0;
  // console.log("search=", search);
  // console.log("PreviousSearch=", PreviousSearch);
  // console.log("searchMetricDifference=", searchMetricDifference);
  const __search = calculateDiffrence(searchMetricDifference);
  const shopping = channelGroup.Shopping ? channelGroup.Shopping[metric] : 0;
  const PreviousShopping = PreviousChannelGroup.Shopping
    ? PreviousChannelGroup.Shopping[metric + "P"]
    : 0;

  const shoppingMetricDifference = PreviousShopping > 0 ? Math.round(((shopping / PreviousShopping) - 1) * 100) : 0;


  // console.log("shopping=", shopping);
  // console.log("PreviousShopping=", PreviousShopping);
  // console.log("shoppingMetricDifference=", shoppingMetricDifference);
  const __shopping = calculateDiffrence(shoppingMetricDifference);
  let other = 0;
  let PreviousOther = 0;
  if (metric == "kpiValue") {
    // if (channelGroup.ALL) {
    const {
      allSpend,
      allRevenue,
      allOrders,
      searchSpend,
      searchRevenue,
      searchOrders,
      ShoppingSpend,
      ShoppingRevenue,
      ShoppingOrders,
      allSpendP,
      allRevenueP,
      allOrdersP,
      searchSpendP,
      searchRevenueP,
      searchOrdersP,
      ShoppingSpendP,
      ShoppingRevenueP,
      ShoppingOrdersP,
    } = extracted(channelGroup);
    const searchAndShopping = searchSpend + ShoppingSpend;
    let otherSpend = allSpend - searchAndShopping;
    let otherRevenue = allRevenue - (searchRevenue + ShoppingRevenue);
    let otherOrders = allOrders - (searchOrders + ShoppingOrders);
    other = (kpiValue[KpiType](
      otherSpend.toFixed(2),
      otherRevenue.toFixed(2),
      otherOrders
    ));
    let otherSpendP = (allSpendP - (searchSpendP + ShoppingSpendP));
    let otherRevenueP = (allRevenueP - (searchRevenueP + ShoppingRevenueP));
    let otherOrdersP = (allOrdersP - (searchOrdersP + ShoppingOrdersP));
    PreviousOther = (kpiValue[KpiType](otherSpendP, otherRevenueP, otherOrdersP));

    /* let otherSpend = (channelGroup.ALL["spend"] - (channelGroup.Search["spend"] + channelGroup.Shopping["spend"]))
         let otherRevenue = (channelGroup.ALL["revenue"] - (channelGroup.Search["revenue"] + channelGroup.Shopping["revenue"]));
         let otherOrders = (channelGroup.ALL["orders"] - (channelGroup.Search["orders"] + channelGroup.Shopping["orders"]));
         other = (kpiValue[KpiType](otherSpend, otherRevenue, otherOrders));
         let otherSpendP = (channelGroup.ALL["spendP"] - (channelGroup.Search["spendP"] + channelGroup.Shopping["spendP"]))
         let otherRevenueP = (channelGroup.ALL["revenueP"] - (channelGroup.Search["revenueP"] + channelGroup.Shopping["revenueP"]));
         let otherOrdersP = (channelGroup.ALL["ordersP"] - (channelGroup.Search["ordersP"] + channelGroup.Shopping["ordersP"]));
         PreviousOther = (kpiValue[KpiType](otherSpendP, otherRevenueP, otherOrdersP));*/
    // }
  } else {
    other = channelGroup.ALL
      ? channelGroup.ALL[metric] - (search + shopping)
      : 0;
    const previousSearchAndShopping = PreviousSearch + PreviousShopping;
    PreviousOther = PreviousChannelGroup.ALL
      ? PreviousChannelGroup.ALL[metric + "P"].toFixed(2) -
      previousSearchAndShopping.toFixed(2)
      : 0;
    // console.error("metric=",metric);
    // console.error("PreviousOther=",PreviousOther);
  }

  // const PreviousOther = PreviousChannelGroup.ALL ? PreviousChannelGroup.ALL[metric + "P"] - (PreviousSearch + PreviousShopping) : 0;
  // console.log("other", other);
  // console.log("PreviousOther", PreviousOther);
  //     console.log("other")
  const otherDifference =
    PreviousOther > 0 ? Math.round((other / PreviousOther - 1) * 100) : 0;
  // console.log("otherDifference", otherDifference);
  const __Other = calculateDiffrence(otherDifference);
  // console.log("__Other", __Other);

  return {
    fixedHeightPaper,
    metricTotal,
    metricDifference,
    __metric,
    search,
    searchMetricDifference,
    __search,
    other,
    otherDifference,
    __Other,
    shopping,
    shoppingMetricDifference,
    __shopping,
  };
}

function MetricPaper({
  stats,
  metric,
  KpiType,
  isLoading,
  classes,
  previousStats,
  theme,
  handleDrawer,
  width,
  currencySymbol,
  isHeaderDrawer,
}) {
  /* console.log("stats", stats);
     console.log("previousStats", previousStats);
     console.log("width=", width);*/

  const { isXs, isSm, isMd, isLg } = useScreenSizes();

  const numOptions = {
    commafy: true,
    shortFormatMinValue: 999,
    shortFormatPrecision: 1,
    precision: (metric !== "clicks" && metric !== "impressions") ? 2 : 0,
    shortFormat: true,
    justification: 'L',
    locales: "en-US",
    currency: (metric == "spend" || metric == "revenue") ? true : false,
    currencyIndicator: currencySymbol,
  };

  const percentNumOptions = {
    commafy: true,
    shortFormatMinValue: 9999,
    shortFormatPrecision: 0,
    precision: 0,
    shortFormat: true,
    justification: "L",
    locales: "en-US",
    currency: false,
    currencyIndicator: currencySymbol,
  };

  const {
    fixedHeightPaper,
    metricTotal,
    metricDifference,
    __metric,
    search,
    searchMetricDifference,
    __search,
    other,
    otherDifference,
    __Other,
    shopping,
    shoppingMetricDifference,
    __shopping,
  } = metricCalCulation(classes, stats, previousStats, metric, KpiType);

  const K_Formatter = (num, metric) => {
    if (metric == ("revenue") || metric == ("spend")) {
      if ((num / 100) % 10 == 0) return Math.floor(num / 1000) + "K";
      else return (Math.floor(num / 100) / 10.0).toLocaleString("en-US", { style: "currency", currency: currencySymbol }) + "K";

    }
    else {
      if ((num / 100) % 10 == 0) return Math.floor(num / 1000) + "K";
      else return (Math.floor(num / 100) / 10.0) + "K";

    }
    //   if ((num / 100) % 10 == 0) return Math.floor(num / 1000) + "K";
    //   else return (Math.floor(num / 100) / 10.0).toLocaleString("en-US", { style: "currency", currency: currencySymbol }) + "K";
    // 
  };

  return (
    <Fragment>
      <Paper className={fixedHeightPaper}>
        <Typography variant={"body2"} align={"left"}>
          <b>
            {metric == "kpiValue"
              ? KpiString[KpiType]
              : metric !== "orders"
                ? metricTitles[metric]
                : kpiLabel[KpiType]}
          </b>
        </Typography>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "100%",
              justifyContent: "center",
            }}
          >
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <Fragment>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingTop: "1rem",
              }}
            >
              <Typography
                component={"p"}
                variant={width == "sm" || width == "xs" ? "h2" : "h1"}
                align={"left"}
                gutterBottom
                style={{ fontWeight: "bold" }}
              >
                <b>
                  <span
                    title={
                      !isNaN(metricTotal)
                        ? metric == "spend" ||
                          metric == "revenue" ||
                          metric == "kpiValue" ||
                          metric == "orders"
                          ? numberFormat(metricTotal.toFixed(2))
                          : numberFormat(metricTotal)
                        : 0
                    }
                  >
                    {1000 <= metricTotal.toFixed(2) &&
                      metricTotal.toFixed(2) < 999999 ? (
                      <span>{K_Formatter(metricTotal.toFixed(2), metric)}</span>
                    ) : (
                      <NumericLabel params={numOptions}>
                        {!isNaN(metricTotal) ? metricTotal.toFixed(2) : 0}
                      </NumericLabel>
                    )}
                  </span>
                </b>
              </Typography>
              <Typography
                variant={"body1"}
                component={"div"}
                style={{ display: "flex" }}
              >
                {" "}
                &nbsp;&nbsp;&nbsp;
                <div style={{ paddingRight: "0.5rem", paddingTop: "3px" }}>
                  {__metric.icon}
                </div>
                <div>
                  <Typography
                    variant={"body2"}
                    style={{ color: __metric.color }}
                  >
                    {!isNaN(Math.abs(metricDifference))
                      ? Math.abs(metricDifference)
                      : 0}
                    %
                  </Typography>
                </div>
              </Typography>
            </div>
            <div className={"growable"}></div>
            {isMd || isLg ? (
              <Fragment>
                <Grid
                  container
                  justifyContent={"space-between"}
                  style={{ padding: "5px" }}
                >
                  <Grid item md={4}>
                    <Typography
                      variant={"body2"}
                      style={{ fontWeight: "normal", opacity: 0.5 }}
                    >
                      Search
                    </Typography>
                  </Grid>
                  {/*<Grid item md={3}></Grid>*/}
                  {/*<Typography component={"div"} className={"growable"}></Typography>*/}
                  <Grid item md={isHeaderDrawer ? 8 : 7}>
                    <Typography
                      variant={"body1"}
                      component={"div"}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <span
                        title={
                          !isNaN(search)
                            ? (metric == "spend" ||
                              metric == "revenue" ||
                              metric == "kpiValue" ||
                              metric == "orders")
                              ? numberFormat(search.toFixed(2))
                              : numberFormat(search)
                            : 0
                        }
                      >
                        <b>
                          {1000 <= search.toFixed(2) &&
                            search.toFixed(2) < 999999 ? (
                            <span>{K_Formatter(search.toFixed(2), metric)}</span>
                          ) : (
                            <NumericLabel params={numOptions}>
                              {search}
                            </NumericLabel>
                          )}
                        </b>
                      </span>
                      <Typography
                        variant={"body1"}
                        component={"div"}
                        style={{
                          display: "flex",
                          width: isHeaderDrawer ? "65%" : "50%",
                        }}
                      >
                        {" "}
                        &nbsp;&nbsp;&nbsp;
                        <span style={{ paddingRight: "0.5rem" }}>
                          {__search.icon}
                        </span>
                        <span
                          style={{
                            color: __search.color,
                            width: "100%",
                            textAlign: "center",
                          }}
                        >
                          <NumericLabel params={percentNumOptions}>
                            {!isNaN(Math.abs(searchMetricDifference))
                              ? Math.abs(searchMetricDifference)
                              : 0}
                          </NumericLabel>
                          %
                        </span>
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
                <Divider className={classes.dashedLine} />
                <Grid
                  container
                  justifyContent={"space-between"}
                  style={{ padding: "5px" }}
                >
                  <Grid item md={4}>
                    <Typography
                      variant={"body2"}
                      style={{ fontWeight: "normal", opacity: 0.5 }}
                    >
                      Shopping
                    </Typography>
                  </Grid>
                  {/*<Grid item md={4}>*/}
                  {/*</Grid>*/}
                  {/*<Typography component={"div"} className={"growable"}></Typography>*/}
                  <Grid item md={isHeaderDrawer ? 8 : 7}>
                    <Typography
                      variant={"body1"}
                      component={"div"}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <span
                        title={
                          !isNaN(shopping)
                            ? metric == "spend" ||
                              metric == "revenue" ||
                              metric == "kpiValue" ||
                              metric == "orders"
                              ? numberFormat(shopping.toFixed(2))
                              : numberFormat(shopping)
                            : 0
                        }
                      >
                        <b>
                          {1000 <= shopping.toFixed(2) &&
                            shopping.toFixed(2) < 999999 ? (
                            <span>{K_Formatter(shopping.toFixed(2), metric)}</span>
                          ) : (
                            <NumericLabel params={numOptions}>
                              {shopping}
                            </NumericLabel>
                          )}
                        </b>
                      </span>
                      <Typography
                        variant={"body1"}
                        component={"div"}
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          minWidth: isHeaderDrawer ? "65%" : "50%",
                        }}
                      >
                        {" "}
                        &nbsp;&nbsp;&nbsp;
                        <span style={{ paddingRight: "0.5rem" }}>
                          {__shopping.icon}
                        </span>
                        <span
                          style={{
                            color: __shopping.color,
                            width: "100%",
                            textAlign: "center",
                          }}
                        >
                          <NumericLabel params={percentNumOptions}>
                            {!isNaN(Math.abs(shoppingMetricDifference))
                              ? Math.abs(shoppingMetricDifference)
                              : 0}
                          </NumericLabel>
                          %
                        </span>
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
                <Divider className={classes.dashedLine} />
                <Grid
                  container
                  justifyContent={"space-between"}
                  style={{ padding: "5px" }}
                >
                  <Grid item md={4}>
                    <Typography
                      variant={"body2"}
                      style={{ fontWeight: "normal", opacity: 0.5 }}
                    >
                      Others
                    </Typography>
                  </Grid>
                  {/*<Typography component={"div"} className={"growable"}></Typography>*/}
                  <Grid item md={isHeaderDrawer ? 8 : 7}>
                    <Typography
                      variant={"body1"}
                      component={"div"}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <span
                        title={
                          !isNaN(Math.abs(other))
                            ? (metric == "spend" ||
                              metric == "revenue" ||
                              metric == "kpiValue" ||
                              metric == "orders")
                              ? numberFormat(Math.abs(other).toFixed(2))
                              : numberFormat(Math.abs(other))
                            : 0
                        }
                      >
                        <b>
                          {1000 <= other.toFixed(2) &&
                            other.toFixed(2) < 999999 ? (
                            <span>{K_Formatter(other.toFixed(2), metric)}</span>
                          ) : (
                            <NumericLabel params={numOptions}>
                              {!isNaN(Math.abs(other))
                                ? Math.abs(other).toFixed(2)
                                : 0}
                            </NumericLabel>
                          )}

                        </b>
                      </span>
                      <Typography
                        variant={"body1"}
                        component={"div"}
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          minWidth: isHeaderDrawer ? "65%" : "50%",
                        }}
                      >
                        {" "}
                        &nbsp;&nbsp;&nbsp;
                        <span style={{ paddingRight: "0.5rem" }}>
                          {__Other.icon}
                        </span>
                        <span
                          style={{
                            color: __Other.color,
                            width: "100%",
                            textAlign: "center",
                          }}
                        >
                          <NumericLabel params={percentNumOptions}>
                            {!isNaN(Math.abs(otherDifference))
                              ? Math.abs(otherDifference)
                              : 0}
                          </NumericLabel>
                          %
                        </span>
                        {/*<span>{otherDifference}</span>*/}
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
              </Fragment>
            ) : (
              isXs || isSm ? (<Typography
                variant={"caption"}
                color={"Secondary"}
                onClick={() => handleDrawer(metric)}
              >
                Details
              </Typography>) : ("test")

            )}
          </Fragment>
        )}
      </Paper>
    </Fragment>
  );
}

class CampaignSnapshot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDrawer: false,
      selectedMetric: "clicks",
    };
  }

  closeDrawer = () => {
    this.setState({
      isDrawer: false,
    });
    //         2147483003
    // document.getElementsByClassName(
    //   "intercom-lightweight-app"
    // )[0].style.zIndex = "2147483003";
  };
  handleDrawer = (metric) => {
    console.log("slectedM=", metric);
    this.setState({
      selectedMetric: metric,
      isDrawer: true,
    });
    // document.getElementsByClassName(
    //   "intercom-lightweight-app"
    // )[0].style.zIndex = "0";
  };

  render() {
    // const { isXs, isSm } = useScreenSizes(); // Using the hook

    const {
      campaignsSnapshot: stats,
      isLoading,
      theme,
      kpiType,
      classes,
      campaignsSnapshotPrevious: previousStats,
      width,
      currencySymbol,
      isHeaderDrawer,
    } = this.props;
    const { isDrawer, selectedMetric } = this.state;
    const {
      fixedHeightPaper,
      metricTotal,
      metricDifference,
      __metric,
      search,
      searchMetricDifference,
      __search,
      other,
      otherDifference,
      __Other,
      shopping,
      shoppingMetricDifference,
      __shopping,
    } = metricCalCulation(
      classes,
      stats,
      previousStats,
      selectedMetric,
      kpiType
    );
    const numOptions = {
      commafy: true,
      shortFormatMinValue: 999,
      shortFormatPrecision: 1,
      precision: 0,
      shortFormat: true,
      justification: "L",
      locales: "en-US",
      currency: selectedMetric == "revenue" ? true : false,
      currencyIndicator: currencySymbol,
    };


    return (
      <div>
        <Grid container spacing={2} justifyContent={"center"}>
          {metrics.map((metric, i) => (
            <Grid item xs={6} sm={6} md={4} key={i}>
              <MetricPaper
                stats={stats}
                metric={metric}
                KpiType={kpiType}
                isLoading={isLoading}
                classes={classes}
                theme={theme}
                previousStats={previousStats}
                handleDrawer={this.handleDrawer}
                width={width}
                currencySymbol={currencySymbol}
                isHeaderDrawer={isHeaderDrawer}
              />
            </Grid>
          ))}
        </Grid>
        {/* {isSm || isXs ? (
          <Drawer
            anchor="bottom"
            open={isDrawer}
            onClose={() => this.closeDrawer}
          >
            <div style={{ padding: theme.spacing(2) }}>
              <Typography align={"right"} onClick={() => this.closeDrawer()}>
                {icons.closeIcon}
              </Typography>
              <Typography
                variant={"caption"}
                gutterBottom
                align={"left"}
                color={"textPrimary"}
              >
                <b>
                  {selectedMetric == "kpiValue"
                    ? KpiString[kpiType]
                    : metricTitles[selectedMetric]}
                </b>
              </Typography>
              {isLoading ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress color="secondary" />
                </div>
              ) : (
                <div>
                  <div className={"flex alignItemsCenter"}>
                    <Typography
                      variant={"h1"}
                      color={"textPrimary"}
                      style={{ fontWeight: "800" }}
                    >
                      <NumericLabel params={numOptions}>
                        {!isNaN(metricTotal) ? metricTotal.toFixed(2) : 0}
                      </NumericLabel>
                    </Typography>
                    <Typography
                      variant={"body1"}
                      component={"div"}
                      style={{ display: "flex" }}
                    >
                      {" "}
                      &nbsp;&nbsp;&nbsp;
                      <div style={{ paddingRight: "0.5rem" }}>
                        {__metric.icon}
                      </div>
                      <div>
                        <Typography
                          variant={"body2"}
                          style={{ color: __metric.color }}
                        >
                          {!isNaN(Math.abs(metricDifference))
                            ? Math.abs(metricDifference)
                            : 0}
                          %
                        </Typography>
                      </div>
                    </Typography>
                  </div>
                  <br />
                  <div
                    style={{
                      display: "flex",
                      paddingBottom: "0.5rem",
                      color: "#1E334E",
                    }}
                  >
                    <Typography
                      variant={"body2"}
                      style={{ fontWeight: "normal", opacity: 0.5 }}
                    >
                      Search
                    </Typography>
                    <Typography
                      component={"div"}
                      className={"growable"}
                    ></Typography>
                    <Typography
                      variant={"body1"}
                      component={"div"}
                      style={{ display: "flex" }}
                    >
                      <span title={search}>
                        <b>
                          <NumericLabel params={numOptions}>
                            {search}
                          </NumericLabel>
                        </b>
                      </span>
                      <Typography
                        variant={"body1"}
                        component={"div"}
                        style={{ display: "flex" }}
                      >
                        {" "}
                        &nbsp;&nbsp;&nbsp;
                        <span style={{ paddingRight: "0.5rem" }}>
                          {__search.icon}
                        </span>
                        <span style={{ color: __search.color }}>
                          {!isNaN(Math.abs(searchMetricDifference))
                            ? Math.abs(searchMetricDifference)
                            : 0}
                          %
                        </span>
                      </Typography>
                    </Typography>
                  </div>
                  <div
                    className={"flex"}
                    style={{
                      paddingBottom: "0.5rem",
                      paddingTop: "0.3rem",
                      color: "#1E334E",
                    }}
                  >
                    <Typography
                      variant={"body2"}
                      style={{ fontWeight: "normal", opacity: 0.5 }}
                    >
                      Shopping
                    </Typography>
                    <Typography
                      component={"div"}
                      className={"growable"}
                    ></Typography>
                    <Typography
                      variant={"body1"}
                      component={"div"}
                      style={{ display: "flex" }}
                    >
                      <span title={shopping}>
                        <NumericLabel params={numOptions}>
                          {shopping}
                        </NumericLabel>
                      </span>
                      <Typography
                        variant={"body1"}
                        component={"div"}
                        style={{ display: "flex" }}
                      >
                        {" "}
                        &nbsp;&nbsp;&nbsp;
                        <span style={{ paddingRight: "0.5rem" }}>
                          {__shopping.icon}
                        </span>
                        <span style={{ color: __shopping.color }}>
                          {!isNaN(Math.abs(shoppingMetricDifference))
                            ? Math.abs(shoppingMetricDifference)
                            : 0}
                          %
                        </span>
                      </Typography>
                    </Typography>
                  </div>
                  <div
                    className={"flex"}
                    style={{ paddingTop: "0.3rem", color: "#1E334E" }}
                  >
                    <Typography
                      variant={"body2"}
                      style={{ fontWeight: "normal", opacity: 0.5 }}
                    >
                      Others
                    </Typography>
                    <Typography
                      component={"div"}
                      className={"growable"}
                    ></Typography>
                    <Typography
                      variant={"body1"}
                      component={"div"}
                      style={{ display: "flex" }}
                    >
                      <span title={other}>
                        <NumericLabel params={numOptions}>{other}</NumericLabel>
                      </span>
                      <Typography
                        variant={"body1"}
                        component={"div"}
                        style={{ display: "flex" }}
                      >
                        {" "}
                        &nbsp;&nbsp;&nbsp;
                        <span style={{ paddingRight: "0.5rem" }}>
                          {__Other.icon}
                        </span>
                        <span style={{ color: __Other.color }}>
                          {!isNaN(Math.abs(otherDifference))
                            ? Math.abs(otherDifference)
                            : 0}
                          %
                        </span>
                      </Typography>
                    </Typography>
                  </div>
                </div>
              )}
            </div>
          </Drawer>
        ) : null} */}
        <CampaignSnapshotFunctional

          classes={classes}
          stats={stats}
          isLoading={isLoading}
          theme={theme}
          kpiType={kpiType}
          width={width}
          currencySymbol={currencySymbol}
          isHeaderDrawer={isHeaderDrawer}
          isDrawer={isDrawer}
          selectedMetric={selectedMetric}
          closeDrawer={this.closeDrawer}
          handleDrawer={this.handleDrawer}
          // metricTotal={metricTotal}
          fixedHeightPaper={fixedHeightPaper}
          metricTotal={metricTotal}
          metricDifference={metricDifference}
          __metric={__metric}
          search={search}
          searchMetricDifference={searchMetricDifference}
          __search={__search}
          other={other}
          otherDifference={otherDifference}
          __Other={__Other}
          shopping={shopping}
          shoppingMetricDifference={shoppingMetricDifference}
          __shopping={__shopping}
        />
      </div>
    );
  }
}

const style = (theme) => ({
  paper: {
    padding: theme.spacing(1),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    // width: 276,
    height: 223,
    border: "1px solid" + theme.palette.common.border,
    borderRadius: 6,
    boxShadow: "4px 4px 18px " + theme.palette.boxShadowC,
    marginBottom: theme.spacing(1),
    // marginRight: theme.spacing(0),
  },
  fixedHeight: {
    height: 223,
  },
  dashedLine: {
    border: "1px dashed" + theme.palette.common.darkHash,
    backgroundColor: "unset",
    boxSizing: "border-box",
    borderRadius: 10,
    opacity: 0.4,
    height: 0,
  },
});
const CampaignSnapshotFunctional = ({
  classes,
  stats,
  isLoading,
  theme,
  kpiType,
  width,
  currencySymbol,
  isHeaderDrawer,
  isDrawer,
  selectedMetric,
  closeDrawer,
  handleDrawer,

  fixedHeightPaper,
  metricTotal,
  metricDifference,
  __metric,
  search,
  searchMetricDifference,
  __search,
  other,
  otherDifference,
  __Other,
  shopping,
  shoppingMetricDifference,
  __shopping,
}) => {
  // Using the hook inside the functional component
  const { isXs, isSm } = useScreenSizes();

  // const { isDrawer, selectedMetric } = this.state;

  const numOptions = {
    commafy: true,
    shortFormatMinValue: 999,
    shortFormatPrecision: 1,
    precision: 0,
    shortFormat: true,
    justification: "L",
    locales: "en-US",
    currency: selectedMetric == "revenue" ? true : false,
    currencyIndicator: currencySymbol,
  };

  // Define your numOptions and other logic as before

  return (
    <div>
      {/* Your JSX content */}
      {isSm || isXs ? (
        <Drawer
          anchor="bottom"
          open={isDrawer}
          onClose={() => closeDrawer}
        >
          <div style={{ padding: theme.spacing(2) }}>
            <Typography align={"right"} onClick={() => closeDrawer()}>
              {icons.closeIcon}
            </Typography>
            <Typography
              variant={"caption"}
              gutterBottom
              align={"left"}
              color={"textPrimary"}
            >
              <b>
                {selectedMetric == "kpiValue"
                  ? KpiString[kpiType]
                  : metricTitles[selectedMetric]}
              </b>
            </Typography>
            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                  justifyContent: "center",
                }}
              >
                <CircularProgress color="secondary" />
              </div>
            ) : (
              <div>
                <div className={"flex alignItemsCenter"}>
                  <Typography
                    variant={"h1"}
                    color={"textPrimary"}
                    style={{ fontWeight: "800" }}
                  >
                    <NumericLabel params={numOptions}>
                      {!isNaN(metricTotal) ? metricTotal.toFixed(2) : 0}
                    </NumericLabel>
                  </Typography>
                  <Typography
                    variant={"body1"}
                    component={"div"}
                    style={{ display: "flex" }}
                  >
                    {" "}
                    &nbsp;&nbsp;&nbsp;
                    <div style={{ paddingRight: "0.5rem" }}>
                      {__metric.icon}
                    </div>
                    <div>
                      <Typography
                        variant={"body2"}
                        style={{ color: __metric.color }}
                      >
                        {!isNaN(Math.abs(metricDifference))
                          ? Math.abs(metricDifference)
                          : 0}
                        %
                      </Typography>
                    </div>
                  </Typography>
                </div>
                <br />
                <div
                  style={{
                    display: "flex",
                    paddingBottom: "0.5rem",
                    color: "#1E334E",
                  }}
                >
                  <Typography
                    variant={"body2"}
                    style={{ fontWeight: "normal", opacity: 0.5 }}
                  >
                    Search
                  </Typography>
                  <Typography
                    component={"div"}
                    className={"growable"}
                  ></Typography>
                  <Typography
                    variant={"body1"}
                    component={"div"}
                    style={{ display: "flex" }}
                  >
                    <span title={search}>
                      <b>
                        <NumericLabel params={numOptions}>
                          {search}
                        </NumericLabel>
                      </b>
                    </span>
                    <Typography
                      variant={"body1"}
                      component={"div"}
                      style={{ display: "flex" }}
                    >
                      {" "}
                      &nbsp;&nbsp;&nbsp;
                      <span style={{ paddingRight: "0.5rem" }}>
                        {__search.icon}
                      </span>
                      <span style={{ color: __search.color }}>
                        {!isNaN(Math.abs(searchMetricDifference))
                          ? Math.abs(searchMetricDifference)
                          : 0}
                        %
                      </span>
                    </Typography>
                  </Typography>
                </div>
                <div
                  className={"flex"}
                  style={{
                    paddingBottom: "0.5rem",
                    paddingTop: "0.3rem",
                    color: "#1E334E",
                  }}
                >
                  <Typography
                    variant={"body2"}
                    style={{ fontWeight: "normal", opacity: 0.5 }}
                  >
                    Shopping
                  </Typography>
                  <Typography
                    component={"div"}
                    className={"growable"}
                  ></Typography>
                  <Typography
                    variant={"body1"}
                    component={"div"}
                    style={{ display: "flex" }}
                  >
                    <span title={shopping}>
                      <NumericLabel params={numOptions}>
                        {shopping}
                      </NumericLabel>
                    </span>
                    <Typography
                      variant={"body1"}
                      component={"div"}
                      style={{ display: "flex" }}
                    >
                      {" "}
                      &nbsp;&nbsp;&nbsp;
                      <span style={{ paddingRight: "0.5rem" }}>
                        {__shopping.icon}
                      </span>
                      <span style={{ color: __shopping.color }}>
                        {!isNaN(Math.abs(shoppingMetricDifference))
                          ? Math.abs(shoppingMetricDifference)
                          : 0}
                        %
                      </span>
                    </Typography>
                  </Typography>
                </div>
                <div
                  className={"flex"}
                  style={{ paddingTop: "0.3rem", color: "#1E334E" }}
                >
                  <Typography
                    variant={"body2"}
                    style={{ fontWeight: "normal", opacity: 0.5 }}
                  >
                    Others
                  </Typography>
                  <Typography
                    component={"div"}
                    className={"growable"}
                  ></Typography>
                  <Typography
                    variant={"body1"}
                    component={"div"}
                    style={{ display: "flex" }}
                  >
                    <span title={other}>
                      <NumericLabel params={numOptions}>{other}</NumericLabel>
                    </span>
                    <Typography
                      variant={"body1"}
                      component={"div"}
                      style={{ display: "flex" }}
                    >
                      {" "}
                      &nbsp;&nbsp;&nbsp;
                      <span style={{ paddingRight: "0.5rem" }}>
                        {__Other.icon}
                      </span>
                      <span style={{ color: __Other.color }}>
                        {!isNaN(Math.abs(otherDifference))
                          ? Math.abs(otherDifference)
                          : 0}
                        %
                      </span>
                    </Typography>
                  </Typography>
                </div>
              </div>
            )}
          </div>
        </Drawer>
      ) : null}
    </div>
  );
};

export default withWidth()(
  withStyles(style, { withTheme: true })(CampaignSnapshot)
);