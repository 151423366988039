import React from 'react';
import SiteGraderHome from "../../pages/site-grader-home";
import {connect} from "react-redux";
import Integration from "../siteGrader/integration";
import ErrorRedirect from "../siteGrader/errorRedirect";
import { Card, CardContent, CircularProgress, Paper } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import {Redirect} from "react-router-dom";
import Footer from "../footer";
import {getDomainInfo, siteGraderReset, updateDomainInfoFromSession} from "../../actions";
import {INTERNAL_SEO} from "../../actions/actionTypes";

function defaultRouteSwitch(siteGrader, props) {
    const {urlInfo} = siteGrader;
    if (urlInfo != null && urlInfo.urlStatus == 200 && siteGrader.siteGraderScore == 0) {
        return <Integration type={INTERNAL_SEO}/>
    } else if (siteGrader && siteGrader.siteGraderScore > 0) {
        const {domainId} = siteGrader.urlInfo;
        const {url} = siteGrader.urlInfo;
        return <Redirect push to={`/site-grader-report/1`}/>
        // return <SiteGrader/>
    }
}


class SiteGraderInternalComponents extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isCompLoading: false,
        }
    }

    async componentDidMount() {
        this.setState({isCompLoading: true});
        // await this.props.siteGraderReset();
        const response = await this.props.saveSiteGraderDetails(this.props.match.params.domainUrl, INTERNAL_SEO);
        this.setState({isCompLoading: false});

    }

    render() {
        const {serverError} = this.props.siteGrader;
        const {siteGrader,classes} = this.props;
        const {isCompLoading} = this.state;
        return (
            <div className={"fullWidth"}>
                {(serverError != null && serverError != "") ?
                    <ErrorRedirect serverError={serverError}/> :

                    isCompLoading == true ? <div
                            className={classes.mainDiv}>
                            <CircularProgress/>
                        </div> :
                        defaultRouteSwitch(siteGrader, this.props)}
            </div>
        );
    }
}
const styles = (theme) => ({
    mainDiv: {
        display: "flex", justifyContent: "center", alignItems: "center", minHeight: "40em",
        width: "100%",
    }
});
const mapStateToProps = state => ({
    siteGrader: state.siteGrader,
});
const mapDispatchToProps = (dispatch) => ({
    checkSessionSiteGraderDetails: (domainName) => {
        return dispatch(getDomainInfo(domainName))
    },
    updateDomainInfoFromSession: () => {
        return dispatch(updateDomainInfoFromSession())
    },
    saveSiteGraderDetails: (domainName, type) => {
        return dispatch(getDomainInfo(domainName, type))
    },
    siteGraderReset: () => dispatch(siteGraderReset()),

});
export default connect(mapStateToProps, mapDispatchToProps)((withStyles(styles))(SiteGraderInternalComponents));
