import {dateRangeInitialState} from "../components/admin/templates";
import * as types from "../actions/actionTypes";

export function selectedReportsDateRange(state = {
    ...dateRangeInitialState
}, action) {
    switch (action.type) {
        // case types.SELECT_DATE_RANGE:
        //     console.log("Dfromdate",moment().subtract(7 + 1, 'd').format('YYYY-MM-DD'));
        //     console.log("dtoDate",moment().subtract(1, 'days').format('YYYY-MM-DD'));
        //     return action.dateRanges;
        case types.SELECT_REPORT_PAGE_DATE_RANGE:
            let dateRanges = action.dateRanges;
            console.log("selected dreducer=", action.dateRanges);
            return Object.assign({}, state, {
                fromDate: dateRanges.fromDate,
                toDate: dateRanges.toDate,
                itemValue: dateRanges.itemValue
            });
        case types.RESET_DATE_RANGE:
            return Object.assign({}, state, {
                ...dateRangeInitialState
            });
        default:
            return state
    }
}

export function reportMetric(state = {
    metric: "All Campaigns",
}, action) {
    switch (action.type) {
        case types.TOGGLE_REPORTS_METRIC:
            console.log("event.target.value=",action.metric)
            return Object.assign({}, state, {
                metric: action.metric
            });
        default:
            return state
    }
}