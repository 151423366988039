import React from "react";
import {
  OrangeTextSize12,
  TaglineForHeadline,
  BlueTitleTypography,
  HeadLineTypography,
  CatalinaBlue,
  OrangeTxt,
  OrangeText,
} from "../../components/typography";
import withStyles from "@mui/styles/withStyles";
import overtenkaccountgraded from "../../assets/img/overtenkaccountgraded.png";
import icons from "../../components/icons";

import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material/index";
import imageBg from "../../assets/img/home_bg.svg";
import useMediaQuery from "@mui/material/useMediaQuery";

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="xl" />;

const styles = (theme) => ({
  root: {
    backgroundColor: "#ECEFF3",
    textAlign: "center",
  },
  gridBg: {
    background: `url(${imageBg})`,
    [theme.breakpoints.up("md")]: {
      backgroundSize: "contain",
      backgroundPosition: "top center",
      backgroundColor: theme.palette.common.darkGreybg,
    },
    [theme.breakpoints.up("xl")]: {
      backgroundRepeat: "no-repeat",
    },
    backgroundColor: theme.palette.common.darkGreybg,
    [theme.breakpoints.down("xl")]: {
      // background: `unset`,
      // backgroundColor: theme.palette.common.darkGreybg,
    },
  },
  TaglineStyle: {
    [theme.breakpoints.up("xs")]: {
      paddingTop: 15,
      paddingBottom: 15,
      paddingLeft: 15,
      paddingRight: 15,
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: 25,
      paddingBottom: 113,
      paddingLeft: 252,
      paddingRight: 259,
    },
  },
  OverTenkimgstyle: {
    [theme.breakpoints.up("xs")]: {
      paddingTop: 25,
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: 35,
      width: 403,
      paddingBottom: 20,
    },
  },

  mainTitle: {
 
    color: "#1E334E",
    textAlign: "center",
    fontWeight: "700",
    textTransform: "uppercase",
    fontSize: "2.75rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.25rem",
    },
    lineHeight: "1.2",
  },
});

function OverTenkAccountGraded(prop) {
  const classes = prop.classes;
  const width = prop.width;
  const theme = prop.theme;
  const isTabletResponsive = useMediaQuery(
    theme.breakpoints.between("sm", "xl")
  );

  return (
    <div >
      <Grid
        container
        className={[classes.root, classes.gridBg]}
        justifyContent={"center"}
      >
        <Grid item md={12} sm={12} xs={12} className={classes.OverTenkimgstyle}>
          <Grid sm={12} xs={12} md={12} className={classes.sectionBg}>
            <Grid container justifyContent={"center"}>
              <Grid item sm={11} xs={11} md={12}>
                <Typography align={"center"} gutterBottom>
                  {width == "sm" || width == "xs"
                    ? isTabletResponsive
                      ? icons.overtenkaccountgraded("auto")
                      : icons.overtenkaccountgraded("100%")
                    : icons.overtenkaccountgraded("auto")}
                </Typography>
              </Grid>
            </Grid>
            {/* <div  className={classes.OverTenkimgstyle}> */}
            {/*{icons.overtenkaccountgraded} */}
            {/* </div> */}
          </Grid>
        </Grid>

        <Grid item sm={12} xs={12} md={8}>
          <OrangeTextSize12>FREE GRADER TOOLS</OrangeTextSize12>
          <Typography className={classes.mainTitle} gutterBottom>
            Over 1,000 businesses have already found our graders useful
          </Typography>
        </Grid>
        <Grid item sm={12} xs={12} md={12} xl={10}>
          <TaglineForHeadline className={classes.TaglineStyle}>
            If you are a small business owner, a marketing head or an agency
            head, our free graders can give you valuable insights on improving
            paid search and SEO performance and through an independent
            evaluation of your account tell you how you measure up against
            industry standards.
          </TaglineForHeadline>
        </Grid>
      </Grid>
    </div>
  );
}

export default withWidth()(
  withStyles(styles, { withTheme: true })(OverTenkAccountGraded)
);
