import * as types from "./actionTypes";

export function deleteBingAdsConnection(ppcId) {
    return async function (dispatch) {
      const url = "/delete-bing-google-account.json";
      dispatch({
        type: types.DELETE_BING_ADS_REQUESTED,
        ppcId,
      });
      try {
        let response = await fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          credentials: "include",
          body: new URLSearchParams({
            ppcId: ppcId,
          }),
        });
        let data = await response.json();
  
        console.log("Delete google connection o/p data is ", data);
        dispatch({
          type: types.DELETE_BING_ADS_RECEIVED,
          ppcId,
          data,
        });
        console.log("Delete google connection o/p data is 2: ", data);
        return data;
      } catch (e) {
        dispatch({
          type: types.DELETE_BING_ADS_FAILED,
          ppcId
        });
      }
    };
  }

  export function deleteGoogleAdsConnection(ppcId) {
    return async function (dispatch) {
      const url = "/delete-bing-google-account.json";
      dispatch({
        type: types.DELETE_GLE_ADS_REQUESTED,
        ppcId,
      });
      try {
        let response = await fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          credentials: "include",
          body: new URLSearchParams({
            ppcId: ppcId,
          }),
        });
        let data = await response.json();
  
        console.log("Delete google connection o/p data is ", data);
        dispatch({
          type: types.DELETE_GLE_ADS_RECEIVED,
          ppcId,
          data,
        });
        console.log("Delete google connection o/p data is 2: ", data);
        return data;
      } catch (e) {
        dispatch({
          type: types.DELETE_GLE_ADS_FAILED,
          ppcId
        });
      }
    };
  }
  
  export function deleteGMCConnection(gmcId) {
    return async function (dispatch) {
      const url = "/delete-gmc-account.json";
      dispatch({
        type: types.DELETE_GMC_REQUESTED,
        gmcId,
      });
      try {
        let response = await fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          credentials: "include",
          body: new URLSearchParams({
            gmcId: gmcId,
          }),
        });
        let data = await response.json();
  
        console.log("Delete gmc connection data is ", data);
        dispatch({
          type: types.DELETE_GMC_RECEIVED,
          gmcId,
          data,
        });
        console.log("Delete gmc connection data is 2: ", data);
        return data;
      } catch (e) {
        dispatch({
          type: types.DELETE_GMC_FAILED,
          gmcId
        });
      }
    };
  }
  
  
  export function deleteShoppingFeedConnection(shoppingFeedId) {
    return async function (dispatch) {
      const url = "/delete-shopping-feed.json";
      dispatch({
        type: types.DELETE_SHOPPING_FEED_REQUESTED,
        shoppingFeedId,
      });
      try {
        let response = await fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          credentials: "include",
          body: new URLSearchParams({
            shoppingFeedId: shoppingFeedId,
          }),
        });
        let data = await response.json();
  
        console.log("Delete gmc connection data is ", data);
        dispatch({
          type: types.DELETE_SHOPPING_FEED_RECEIVED,
          shoppingFeedId,
          data,
        });
        console.log("Delete gmc connection data is 2: ", data);
        return data;
      } catch (e) {
        dispatch({
          type: types.DELETE_SHOPPING_FEED_FAILED,
          shoppingFeedId
        });
      }
    };
  }

  export function fetchGMCAuthURL() {
    return async function (dispatch) {
      const url = "/get-gmc-authurl.json";
      dispatch({
        type: types.GMC_AUTH_URL_REQUESTED,
      });
      try {
        let response = await fetch(url, {
          method:"GET",
          headers:{
            Accept: "application/json",
          },
        });
        let data = await response.json();
        console.log("gmc auth url data",data);
        dispatch({
          type: types.GMC_AUTH_URL_RECEIVED,
          data,
        });
        return data;
      }
      catch(e){
        dispatch({
          type: types.GMC_AUTH_URL_FAILED,
        });
      }
    }
  }


  export function fetchGMCData(gmcId) {
    return async function (dispatch) {
      const url = "/get-gmc-account.json";
      dispatch({
        type: types.GMC_DETAILS_REQUESTED,
        gmcId,
      });
      try {
        let response = await fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          credentials: "include",
          body: new URLSearchParams({
            gmcId: gmcId,
          }),
        });
        let data = await response.json();
  
        dispatch({
          type: types.GMC_DETAILS_RECEIVED,
          gmcId,
          data,
        });
        return data;
      } catch (e) {
        dispatch({
          type: types.GMC_DETAILS_FAILED,
          gmcId
        });
      }
    };
  }



  export function deleteSearchConsoleAccount(searchConsoleId) {
    return async function (dispatch) {
      const url = "/delete-search-console-account.json";
      dispatch({
        type: types.DELETE_SEARCH_CONSOLE_REQUESTED,
        searchConsoleId,
      });
      try {
        let response = await fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          credentials: "include",
          body: new URLSearchParams({
            searchConsoleId: searchConsoleId,
          }),
        });
        let data = await response.json();
  
        console.log("Delete search console connection data is ", data);
        dispatch({
          type: types.DELETE_SEARCH_CONSOLE_RECEIVED,
          searchConsoleId,
          data,
        });
        console.log("Delete search console connection data is 2: ", data);
        return data;
      } catch (e) {
        dispatch({
          type: types.DELETE_SEARCH_CONSOLE_FAILED,
          searchConsoleId
        });
      }
    };
  }

  export function fetchSearchConsoleAcc(searchConsoleId) {
    return async function (dispatch) {
      const url = "/get-search-console-account-import-status.json";
      dispatch({
        type: types.FETCH_SEARCH_CONSOLE_ACCOUNT_REQUESTED,
        searchConsoleId,
      });
      try {
        let response = await fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          credentials: "include",
          body: new URLSearchParams({
            searchConsoleId: searchConsoleId,
          }),
        });
        let data = await response.json();
  
        dispatch({
          type: types.FETCH_SEARCH_CONSOLE_ACCOUNT_RECEIVED,
          searchConsoleId,
          data,
        });
        return data;
      } catch (e) {
        dispatch({
          type: types.FETCH_SEARCH_CONSOLE_ACCOUNT_FAILED,
          searchConsoleId
        });
      }
    };
  }