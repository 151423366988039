import React, { useEffect } from "react";
import GrowthmodelGP from "../../assets/img/growthmodelGP.png";
import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid";
import {
  TaglineForHeadline,
  OrangeTextSize12,
  GrayText,
  BlueTitleTypography,
  HeadLineTypography,
  CatalinaBlue,
} from "../../components/typography";
import { Typography } from "@mui/material";
import { SkyBlueBtn } from "../../components/buttons.jsx";
import { Link } from "react-router-dom";
import speakerimg from "../../assets/img/speakerimg.png";
import { CardContent } from "@mui/material/index";
import useMediaQuery from "@mui/material/useMediaQuery";
import imageBg from "../../assets/img/home_bg.svg";
import * as types from "../../actions/actionTypes";
import * as PropTypes from "prop-types";
import { connect } from "react-redux";

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="xl" />;

const styles = (theme) => ({
  root: {
    zoom:"90%",
    background: `url(${imageBg})`,
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      backgroundSize: "contain",
      backgroundPosition: "top center",
      backgroundColor: theme.palette.common.darkGreybg,
    },
    [theme.breakpoints.up("xl")]: {
      backgroundRepeat: "no-repeat",
    },
    [theme.breakpoints.down("xl")]: {
      textAlign: "center",
      // height: 524,
      backgroundColor: "#ECEFF3",
      padding: 10,
      height: 390,
      paddingTop: theme.spacing(6),
      // background: "unset",
    },
    [theme.breakpoints.up("sm")]: {
      textAlign: "center",
      height: 524,
      // backgroundColor: "#ECEFF3",
    },
  },
  Typographystyle: {
    paddingTop: 18,
    paddingBottom: 16,
  },
  imgstyle: {
    paddingTop: 49,
  },
  GrowthmodelGPimgStyle: {
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.between("sm", "xl")]: {
      width: "auto",
    },
    [theme.breakpoints.up("md")]: {
      width: 464,
    },
  },
  heading: {
    color: "textPrimary",
    textAlign: "center",
    fontWeight: "700",
    textTransform: "uppercase",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.25rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2.75rem",
    },
  },
});

function MainImage(props) {
  const isTabletResponsive = useMediaQuery(
    props.theme.breakpoints.between("sm", "xl")
  );
  return (
    <img
      src={GrowthmodelGP}
      width={
        props.width == "sm" || props.width == "xs"
          ? isTabletResponsive
            ? "auto"
            : "100%"
          : "auto"
      }
      className={props.classes.GrowthmodelGPimgStyle}
    />
  );
}

MainImage.propTypes = {
  width: PropTypes.any,
  tabletResponsive: PropTypes.bool,
  classes: PropTypes.any,
};

function GrowthModel(props) {
  const { classes, width, theme } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
    props.growthModelPageTrack();
  }, []);

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item md={12} xs={12} sm={12}>
          <Grid container justifyContent={"center"}>
            <Grid item sm={11} xs={11} md={12}>
              <Typography align={"center"} gutterBottom>
                <MainImage width={width} classes={classes} theme={theme} />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12} xs={12} md={12}>
          <OrangeTextSize12>GROWTH MODEL</OrangeTextSize12>
          <Typography
            className={classes.heading}
            align={"center"}
            variant={width == "sm" || width == "xs" ? "h3" : "subtitle1"}
            color={"textPrimary"}
            style={{ fontWeight: "700", textTransform: "uppercase" }}
            gutterBottom
          >
            THE LXRGUIDE GROWTH MODEL
          </Typography>
          {/*<BlueTitleTypography></BlueTitleTypography>*/}
          <TaglineForHeadline className={classes.Typographystyle}>
            A Proven Model to Grow Your Business
          </TaglineForHeadline>
          <SkyBlueBtn component={Link} to={"/request-demo"}>
            Book your free growth call
          </SkyBlueBtn>
        </Grid>
      </Grid>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  growthModelPageTrack: () => dispatch({ type: types.GROWTH_MODEL_PAGE_TRACK }),
});
export default connect(
  null,
  mapDispatchToProps
)(withWidth()(withStyles(styles, { withTheme: true })(GrowthModel)));
