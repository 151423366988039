import * as types from "../actions/actionTypes";
import * as _ from "lodash";

export function userProfileInformation(state = {
    userInfo: {},
    isUserProfileLoading: false,
    error: null,
    cardDetails: [],
    isCardLoading: false,
    isCardUpdating: false,
    isSubscribeLoading: false,
    endDate: null,
    amount: null,
}, action) {
    switch (action.type) {
        case types.FETCH_PROFILE_DETAILS_REQUESTED:
            return Object.assign({}, state, {
                isLoading: true,
                isUserProfileLoading: true,
            });
        case types.DELETE_CARD_DETAILS_REQUESTED:
            return Object.assign({}, state, {
                isCardLoading: true,
            });
        case types.SUBSCRIBE_DETAILS_REQUESTED:
            return Object.assign({}, state, {
                isSubscribeLoading: true,
            });
        case types.FETCH_PROFILE_DETAILS_RECEIVED:
            action.data.selectedClientInfo
            return Object.assign({}, state, {
                isLoading: false,
                isUserProfileLoading: false,
                ...action.data

            });
        case types.DELETE_CARD_DETAILS_RECEIVED:
            action.data.selectedClientInfo
            return Object.assign({}, state, {
                isLoading: false,
                isUserProfileLoading: false,
                isCardLoading: false,
                cardDetails: action.data.cardDetails
            });
        case types.FETCH_PROFILE_DETAILS_FAILED:
            return Object.assign({}, state, {
                isLoading: false,
                isUserProfileLoading: false,
                error: "error in fetching User Profile details"
            });
        case types.DELETE_CARD_DETAILS_FAILED:
            return Object.assign({}, state, {
                isLoading: false,
                isUserProfileLoading: false,
                isCardLoading: false,
                error: "Error in Deleting Card Details"
            });
        case types.SUBSCRIBE_DETAILS_FAILED:
            return Object.assign({}, state, {
                isLoading: false,
                isUserProfileLoading: false,
                isCardLoading: false,
                isSubscribeLoading: false,
                error: "Error in updating Subscribe Details"
            });
        case types.ADD_CARD_DETAILS_REQUESTED:
            return Object.assign({}, state, {
                isCardUpdating: true
            });
        case types.RESUME_ACCOUNT_FAILED:
            return Object.assign({}, state, {
                isLoading: false,
                isUserProfileLoading: false,
                isCardLoading: false,
                isSubscribeLoading: false,
                error: "Error in updating Resuming the Account"
            });
        case types.ADD_CARD_DETAILS_FAILED:
            return Object.assign({}, state, {
                isLoading: false,
                isUserProfileLoading: false,
                isCardLoading: false,
                error: "Error in Adding Card Details"
            });
        case types.ADD_CARD_DETAILS_RECEIVED:
            return Object.assign({}, state, {
                isLoading: false,
                isUserProfileLoading: false,
                isCardUpdating: false,
                cardDetails: action.data.cardDetails,
                error: action.errorMsg
            });

        case types.SUBSCRIBE_DETAILS_RECEIVED:
            return Object.assign({}, state, {
                isLoading: false,
                isUserProfileLoading: false,
                isCardUpdating: false,
                isSubscribeLoading: false,
                endDate: action.data.endDate,
                error: action.errorMsg
            });
        case types.UNSUBSCRIBE_ACCOUNT_RECEIVED:
            return Object.assign({}, state, {
                isLoading: false,
                isUserProfileLoading: false,
                isCardUpdating: false,
                isSubscribeLoading: false,
                // endDate: action.data.endDate,
                error: action.errorMsg
            });

        default:
            return state;
    }

}