import {connect} from "react-redux";
import React from 'react';
import NumericLabel from "react-pretty-numbers";

function CurrencySymbol(props) {
    const {formatOptions, children, currencyCode} = props;
    formatOptions.currencyIndicator = currencyCode;
    return (<NumericLabel params={formatOptions}>{children}</NumericLabel>)
}

export default connect((state) => ({
    currencyCode: state.perf.currency
}))(CurrencySymbol);
