import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Grid,
  Typography,
  Button,
  TextField,
  CircularProgress,
} from "@mui/material";
import "./jumpstart.css";
import icons from "../../components/icons";
import { JsLoginTypography } from "../../components/typography";
import { JsOrangeContainedBtn } from "../../components/buttons";
import LockReset from "@mui/icons-material/LockReset";
import { useParams } from "react-router-dom";
import { finishPasswordReset } from "../../actions/jumpstartRevamped";
import PasswordChanged from "./passwordChanged";

function CreateNewPassword(props) {
  const { finishPasswordReset, finishPasswordResetReducer } = props;
  const { key } = useParams();
  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [error, setError] = useState("");
  const [passwordChangeSuccess, setPasswordChangeSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleNewPassword = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handlePasswordChange = async () => {
    setLoading(true);
    if (newPassword === confirmPassword) {
      if (
        newPassword != "" &&
        newPassword != undefined &&
        newPassword.length > 3
      ) {
        const data = await finishPasswordReset(newPassword, key);
        switch (data.status) {
          case 200:
            setPasswordChangeSuccess(true);
            break;
          case 400:
            setError("No user with the reset key found");
            break;
          case 500:
            setError("The link has expired. Please try again.");
            break;
          default:
            setError("Unexpected error occurred. Please try again.");
        }
      } else {
        setError("Password should be between 4 and 100 characters.");
      }
    } else {
      setError("Password doesn't match. Try again.");
    }
    setLoading(false);
  };

  return (
    <div className="mainDiv">
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item container xs={6} className="loginContent" rowSpacing={2}>
          <Grid item xs={12}>
            <Typography align="center"> {icons.JumpstartLogo}</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            columnSpacing={1}
          >
            <Grid item>
              <Typography fontWeight="500" letterSpacing={"9%"} color="#707A86">
                Powered By
              </Typography>
            </Grid>
            <Grid item>{icons.LxrguideLogo}</Grid>
          </Grid>
          <Grid item xs={12}>
            <JsLoginTypography>
              Paid Search Management Solution for SMBs
            </JsLoginTypography>
          </Grid>
          <Grid item></Grid>
          <Grid item xs={12}></Grid>

          {passwordChangeSuccess ? (
            <PasswordChanged />
          ) : (
            <>
              <Grid item xs={12}>
                <Typography fontWeight="500" align="center" color="#1E334E">
                  Setup New Password
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" color="#747474">
                  New Password
                </Typography>
                <TextField
                  fullWidth
                  id="fullWidth"
                  placeholder="Type a new password"
                  size="small"
                  sx={{ backgroundColor: "#fff", fontSize: "0.875rem" }}
                  onChange={handleNewPassword}
                  type="password"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" color="#747474">
                  Confirm New Password
                </Typography>
                <TextField
                  fullWidth
                  id="fullWidth"
                  placeholder="Re-type to confirm new password"
                  size="small"
                  sx={{ backgroundColor: "#fff", fontSize: "0.875rem" }}
                  onChange={handleConfirmPassword}
                  type="password"
                />
              </Grid>
              <Grid item xs={12}>
                <JsOrangeContainedBtn
                  onClick={handlePasswordChange}
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress sx={{ color: "#fff" }} size="1.5rem" />
                  ) : (
                    <>
                      <LockReset />
                      Reset Password
                    </>
                  )}
                </JsOrangeContainedBtn>
              </Grid>
              {error != "" && error != undefined ? (
                <Grid item xs={12}>
                  <Typography variant="subtitle2" color="red" align="center">
                    {error}
                  </Typography>
                </Grid>
              ) : (
                ""
              )}
            </>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => ({
  finishPasswordResetReducer: state.finishPasswordReset,
});
const mapDispatchToProps = (dispatch) => ({
  finishPasswordReset: (newPassword, key) =>
    dispatch(finishPasswordReset(newPassword, key)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewPassword);
