import { Grid, Typography, Divider, CircularProgress } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import * as PropTypes from "prop-types";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { sortTasks } from "../reducers/common";
import PendingTask from "./pendingTask";
import icons from "../components/icons";
import * as _ from "lodash";

// import {mapDispatchToProps, mapStateToProps} from "../../selectors/dashboard-selectors";

function getPendingTasks(tasks, taskDataById) {
  return tasks.map((task) => {
    let selectedIds = task.dataIdList.filter((id) => taskDataById[id].selected);
    if (selectedIds.length > 0) return task;
  });
}

function isAllTasksCompleted(taskDataById) {
  let iscompleted = false;
  if (!_.isEmpty(taskDataById)) {
    let completedTasksCount = _.values(taskDataById).filter(
      (td) => td.status == 1
    ).length;
    if (completedTasksCount == _.values(taskDataById).length) {
      iscompleted = true;
    }
  }
  return iscompleted;
}

export function IconAndText({ icon, text, caption }) {
  return (
    <Grid
      container
      style={{ height: "100%" }}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Grid item md={12}>
        <Typography align={"center"}>{icon}</Typography>
      </Grid>
      <Grid item md={12}>
        <Typography
          variant={"h3"}
          style={{ color: "#1E334E", fontWeight: "bold" }}
          align={"center"}
        >
          {text}
        </Typography>
        <Typography variant={"h6"} align={"center"}>
          Come back next week to see new tasks.
        </Typography>
      </Grid>
    </Grid>
  );
}

export function PlainLink({ classes, ...rest }) {
  return <Link {...rest} className={classes.a} />;
}

PlainLink = withStyles({
  a: {
    width: "100%",
    color: "unset",
    textDecoration: "none",
    cursor: "pointer",
  },
})(PlainLink);

function PendingTasks(props) {
  const { pendingTasks, isLoading, isAllTasksCompleted } = props;
  console.log("pendingTasks=", pendingTasks);
  const slicedTasks =
    pendingTasks.length > 4 ? pendingTasks.slice(0, 4) : pendingTasks;
  console.log("slicedTasks=", slicedTasks);
  console.log("isAllTasksCompleted=", isAllTasksCompleted);
  return (
    <div style={{ paddingTop: "1rem", height: "100%" }}>
      {isLoading ? (
        <div
          className={"flex justifyCenter alignItemsCenter"}
          style={{ height: 340 }}
        >
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <Fragment>
          {slicedTasks.length > 0 && isAllTasksCompleted == false ? (
            <Fragment>
              {slicedTasks.map((task, index) => (
                <Fragment key={index}>
                  {task != undefined ? (
                    <PlainLink
                      key={index}
                      to={`/task-new/${task.summary.groupId}`}
                    >
                      <PendingTask
                        key={index}
                        classes={props.classes}
                        index={index}
                        task={task}
                      />
                    </PlainLink>
                  ) : null}
                </Fragment>
              ))}
            </Fragment>
          ) : isAllTasksCompleted == true ? (
            <IconAndText
              icon={icons.taskCompletedIcon}
              text={"All tasks completed!"}
            />
          ) : (
            <IconAndText
              icon={icons.noTasksIcon}
              text={"No tasks this week."}
            />
          )}
        </Fragment>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  // tasks: sortTasks(state.groupedTaskData.TaskType, state.taskDataById),
  pendingTasks: getPendingTasks(
    sortTasks(state.groupedTaskData.TaskType, state.taskDataById),
    state.taskDataById
  ),
  KPIType: state.perf.kpiType,
  currency: state.perf.currency,
  isLoading: state.perf.isLoading,
  taskDataById: state.taskDataById,
  isAllTasksCompleted: isAllTasksCompleted(state.taskDataById),
});

PendingTasks.propTypes = { classes: PropTypes.any };
export default connect(mapStateToProps, null)(PendingTasks);
