import React, { Fragment, useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Switch,
  FormControlLabel,
  FormGroup,
  Button,
} from "@mui/material";
import {} from "react-router";
import {
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material/index";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { InputFields, TDInputFields } from "../../components/input-forms";
import {
  NewOrangeContainedBtn,
  NewOrangeSaveContainedBtn,
  NewOutLinedRectBlackBtn,
  NewOutLinedRectBlueBtn,
  NewOutLinedRectCancelBlueBtn,
  NewTextRectOrangeTextBtn,
} from "../../components/buttons";
import { Card, CardContent, TableBody } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import * as _ from "lodash";
import { ErrorTypography } from "../../components/typography";
import MySnackbarWrapper from "../snackbars";

function profileSettingsForm(props) {
  const { classes, userDetails } = props;
  console.log("props inside form =", props);
  console.log("props user details =", props.userDetails);
  console.log("props user payment details =", props.paymentDetails);

  const [state, setState] = useState({
    emailIds:
      props.userDetails && props.userDetails.userInfo.emailId
        ? props.userDetails.userInfo.emailId
        : "",
    phoneNumber:
      props.userDetails && props.userDetails.userInfo.phoneNumber
        ? props.userDetails.userInfo.phoneNumber
        : "",
    address:
      props.userDetails && props.userDetails.userInfo.address
        ? props.userDetails.userInfo.address
        : "",
    billingName:
      props.userDetails && props.userDetails.userInfo.billingName
        ? props.userDetails.userInfo.billingName
        : "",
    billingEmail:
      props.userDetails && props.userDetails.userInfo.billingEmail
        ? props.userDetails.userInfo.billingEmail
        : "",
    emailIdsError: "",
    phoneNumberError: "",
    addressError: "",
    billingNameError: "",
    billingEmailError: "",
    // isEdit: false,
    // isProfileUpdating: false,
    // commonError: "",
    alert: false,
    message: "",
    variantType: "info",
  });
  const [isEdit, setIsEdit] = useState(false);
  const [isProfileUpdating, setIsProfileUpdating] = useState(false);
  const [commonError, setCommonError] = useState("");

  // const { isEdit, isProfileUpdating, commonError } = useState();

  const isEqual =
    !_.isEmpty(userDetails.userInfo) &&
    userDetails.userInfo.phoneNumber != null &&
    state.phoneNumber != null &&
    userDetails.userInfo.phoneNumber.trim() === state.phoneNumber.trim() &&
    userDetails.userInfo.address != null &&
    state.address != null &&
    userDetails.userInfo.address.trim() === state.address.trim() &&
    userDetails.userInfo.billingName != null &&
    state.billingName != null &&
    userDetails.userInfo.billingName.trim() === state.billingName.trim() &&
    userDetails.userInfo.billingEmail != null &&
    state.billingEmail != null &&
    userDetails.userInfo.billingEmail.trim() === state.billingEmail.trim();

  useEffect(() => {
    const { userDetails } = props;
    if (props.userDetails?.userInfo !== userDetails.userInfo) {
      setState({
        emailIds:
          props.userDetails && props.userDetails.userInfo.emailId
            ? props.userDetails.userInfo.emailId
            : "",
        phoneNumber:
          props.userDetails && props.userDetails.userInfo.phoneNumber
            ? props.userDetails.userInfo.phoneNumber
            : "",
        address:
          props.userDetails && props.userDetails.userInfo.address
            ? props.userDetails.userInfo.address
            : "",
        billingName:
          props.userDetails && props.userDetails.userInfo.billingName
            ? props.userDetails.userInfo.billingName
            : "",
        billingEmail:
          props.userDetails && props.userDetails.userInfo.billingEmail
            ? props.userDetails.userInfo.billingEmail
            : "",
      });
    }
  }, [props.userDetails]);

  const handleChange = (name) => (event) => {
    console.log("we are inside Handle change");
    setState((prevState) => ({
      ...prevState,
      [name]: event.target.value,
    }));
  };
  const validProfileSettings = () => {
    console.log("came to valid Profile settings section");
    let isError = false;
    const errors = {};
    if (state.billingName.length == 0) {
      isError = true;
      errors.billingNameError = "This field is required";
    } else {
      errors.billingNameError = "";
    }
    if (state.billingEmail.length == 0) {
      isError = true;
      errors.billingEmailError = "This field is required";
    } else if (state.billingEmail.length > 0) {
      let result = state.billingEmail.match(
        /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
      );
      if (!result) {
        isError = true;
        errors.billingEmailError = "Please enter valid email";
      } else {
        errors.billingEmailError = "";
      }
    } else {
      errors.billingEmailError = "";
    }

    if (state.phoneNumber === "") {
      isError = true;
      errors.phoneNumberError = "Please enter the phone number";
    } else if (isNaN(state.phoneNumber)) {
      isError = true;
      errors.phoneNumberError = "Please enter the valid phone number";
    } else if (state.phoneNumber <= 0) {
      isError = true;
      errors.phoneNumberError = "Please enter the valid phone number";
    } else if (state.phoneNumber.length < 8) {
      isError = true;
      errors.phoneNumberError = "Phone Number should be minimum 8 digits";
    } else if (state.phoneNumber.length > 10) {
      isError = true;
      errors.phoneNumberError = "Phone Number should be maximum 10 digits";
    } else {
      errors.phoneNumberError = "";
    }

    setState({
      ...state,
      ...errors,
    });
    return isError;
  };

  const handleSubmit = async (event) => {
   
    event.preventDefault({});
    let error = true;
    error = validProfileSettings();
    console.log("came to the submit section with error value = ", error);
    if (!error) {
      const { emailIds, phoneNumber, address, billingName, billingEmail } = state;
      const { userInfo } = props;
      console.log("the userInfo from the props = ", userInfo);
      setIsProfileUpdating(true);
      //setState({ isProfileUpdating: true });
      let response = await props.UpdateUserProfileDetails({
        userId: "",
        emailIds: emailIds,
        phoneNumber: phoneNumber,
        address: address,
        billingName: billingName,
        billingEmail: billingEmail,
      });
      setIsEdit(false);
      setIsProfileUpdating(false)
      // setState({ isProfileUpdating: false, isEdit: false });
    }
  };

  const handleClose = () => {
    setState({
      emailIds:
        props.userDetails && props.userDetails.userInfo.emailId
          ? props.userDetails.userInfo.emailId
          : "",
      phoneNumber:
        props.userDetails && props.userDetails.userInfo.phoneNumber
          ? props.userDetails.userInfo.phoneNumber
          : "",
      address:
        props.userDetails && props.userDetails.userInfo.address
          ? props.userDetails.userInfo.address
          : "",
      billingName:
        props.userDetails && props.userDetails.userInfo.billingName
          ? props.userDetails.userInfo.billingName
          : "",
      billingEmail:
        props.userDetails && props.userDetails.userInfo.billingEmail
          ? props.userDetails.userInfo.billingEmail
          : "",
      // isEdit: false,
      emailIdsError: "",
      phoneNumberError: "",
      addressError: "",
      billingNameError: "",
      billingEmailError: "",
    });
    setIsEdit(false);
  };
  console.log("The isEdit is", isEdit);
  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12} md={8}>
          <form onSubmit={handleSubmit}>
            {commonError != "" ? <ErrorTypography></ErrorTypography> : null}
            <Grid
              container
              justifyContent={"space-between"}
              alignItems={"center"}
              className={classes.sectionHead}
            >
              <Grid item>
                <Typography
                  color={"textPrimary"}
                  variant={"h4"}
                  style={{ marginTop: "10px" }}
                  gutterBottom
                >
                  <b>My Profile</b>
                </Typography>
              </Grid>
              <Grid item>
                {isEdit ? (
                  <div
                    className={"flex alignItemsCenter"}
                    style={{ textAlign: "right" }}
                  >
                    <NewOutLinedRectCancelBlueBtn
                      variant={"outlined"}
                      onClick={handleClose}
                    >
                      Cancel
                    </NewOutLinedRectCancelBlueBtn>
                    <div className={classes.wrapper}>
                      <NewOrangeSaveContainedBtn
                        type="submit"
                        variant={"outlined"}
                        disabled={isEqual || isProfileUpdating}
                      >
                        Save
                      </NewOrangeSaveContainedBtn>
                      {isProfileUpdating ? (
                        <CircularProgress
                          className={classes.buttonProgress}
                          color={"primary"}
                          size={24}
                        />
                      ) : null}
                    </div>
                  </div>
                ) : (
                  <NewTextRectOrangeTextBtn
                    variant={"text"}
                    // onClick={() => setState({ isEdit: true })}
                    onClick={() => setIsEdit(true)}
                  >
                    <b>Edit</b>{" "}
                  </NewTextRectOrangeTextBtn>
                )}
              </Grid>
            </Grid>
            <Typography variant={"body1"} gutterBottom>
              <b>Email</b>
            </Typography>
            <FormControl className={classes.formControl}>
              <InputFields
                // label="Enter Email Id"
                value={state.emailIds}
                onChange={handleChange("emailIds")}
                margin="normal"
                variant="outlined"
                error={state.emailIdsError !== ""}
                helperText={state.emailIdsError}
                style={{ marginTop: 0 }}
                className={classes.inputFieldStyle}
                disabled={true}
              />
            </FormControl>
            <Typography variant={"body1"} gutterBottom>
              <b>Phone Number</b>
            </Typography>
            <FormControl className={classes.formControl}>
              <InputFields
                value={state.phoneNumber}
                type={"number"}
                onChange={handleChange("phoneNumber")}
                margin="normal"
                variant="outlined"
                error={state.phoneNumberError !== ""}
                helperText={state.phoneNumberError}
                style={{ marginTop: 0 }}
                className={classes.inputFieldStyle}
                disabled={!isEdit}
              />
            </FormControl>
            <Typography variant={"body1"} gutterBottom>
              <b>Address (Optional)</b>
            </Typography>
            <FormControl className={classes.formControl}>
              <InputFields
                value={state.address}
                onChange={handleChange("address")}
                margin="normal"
                variant="outlined"
                multiline
                row={3}
                rowsMax="3"
                error={state.addressError !== ""}
                helperText={state.addressError}
                style={{ marginTop: 0 }}
                className={classes.inputFieldStyle}
                disabled={!isEdit}
              />
            </FormControl>
            <br />
            <br />
            <br />
            <br />
            <Typography
              color={"textPrimary"}
              variant={"h4"}
              component={"h4"}
              gutterBottom
            >
              <b>Billing Details</b>
            </Typography>
            <Typography variant={"body1"} gutterBottom>
              <b>Name</b>
            </Typography>
            <FormControl className={classes.formControl}>
              <InputFields
                value={state.billingName}
                onChange={handleChange("billingName")}
                margin="normal"
                variant="outlined"
                error={state.billingNameError !== ""}
                helperText={state.billingNameError}
                style={{ marginTop: 0 }}
                className={classes.inputFieldStyle}
                disabled={!isEdit}
              />
            </FormControl>
            <Typography variant={"body1"} gutterBottom>
              <b>Email</b>
            </Typography>
            <FormControl className={classes.formControl}>
              <InputFields
                value={state.billingEmail}
                onChange={handleChange("billingEmail")}
                margin="normal"
                variant="outlined"
                error={state.billingEmailError !== ""}
                helperText={state.billingEmailError}
                style={{ marginTop: 0 }}
                className={classes.inputFieldStyle}
                disabled={!isEdit}
              />
            </FormControl>
          </form>
        </Grid>
      </Grid>
    </Fragment>
  );
}
export default profileSettingsForm;
