import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card, Grid, Typography } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import { CompactCardContent, MainSectionCardHeader } from "../cards";
import icons from "../icons";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { getGoogleAccountPerformance, sendGraderMail } from "../../actions";
import { connect } from "react-redux";
import CustomDialog from "../dialog-generic";
import Tooltip from "@mui/material/Tooltip";

import { getLastDates } from "./templates";
import Snackbar from "../snackbars";

let moment = require("moment");

export const CustomCard = withStyles((theme) => ({
  root: {
    background: theme.palette.graderAppBarBg,
  },
}))(Card);
const campaignSnapshotStyles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  Button: {
    minWidth: 35,
  },
  appBar: {
    background: theme.palette.graderAppBarBg,
    boxShadow: "unset",
    paddingTop: "",
  },
  margin: {
    margin: theme.spacing(1),
  },
});

class GraderAppbar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      dialog: false,
      isMailSent: false,
      error: null,
    };
  }

  /* const response = await this.props.bookGraderMeeting(selectedDate, accountLevelGraderUserId)
     this.setState({
                       dialog: true,
                   });
     if (response && response.isAccountLevelDetailsUpdated == true) {
     this.setState({
                       isAccountLevelDetailsUpdated: true,
                   })
 } else {
     this.setState({
         isAccountLevelDetailsUpdated: false,
     })
 }*/
  handleMailsending = async () => {
    const response = await this.props.sendGraderMail();
    this.setState({
      snackbarOpen: true,
    });
    if (response && response.isMailSent && response.isMailSent == true) {
      this.setState({ isMailSent: true });
    } else {
      this.setState({ isMailSent: false });
    }
  };

  handleClose = () => {
    this.setState({
      snackbarOpen: false,
    });
  };

  render() {
    const { data, classes } = this.props;
    return (
      <div>
        <AppBar position="static" className={classes.appBar}>
          <Toolbar>
            <Typography color="inherit" className={classes.grow}>
              ACCOUNT: {data.accountName}&nbsp; DATE:{" "}
              {data.registrationDate
                ? moment(data.registrationDate).format("MMM DD, YYYY")
                : moment().format("MMM DD, YYYY")}
            </Typography>

            <Tooltip title="Send this report by Email">
              <Button
                color="inherit"
                className={classes.Button}
                onClick={this.handleMailsending}
              >
                {icons.paperPlane}
              </Button>
            </Tooltip>
            {/*<Button color="inherit" className={classes.Button}
                                onClick={() => alert("Download Work In Progress")}>{icons.download}</Button>*/}
          </Toolbar>
        </AppBar>
        {/*<CustomDialog open={this.state.dialog}
                              content={
                                  this.state.isMailSent == true ?
                                      <Typography component={"p"}>
                                          Mail sent Successfully
                                      </Typography> :
                                      <Typography component={"p"}>
                                          Something Went Wrong, Please Try again.
                                      </Typography>
                              }
                              okBtn
                              handleAction={this.handleClose}
                              titleColor={"Notification"}
                />*/}

        <Snackbar
          verticalAlign={"bottom"}
          horizontalAlign={"left"}
          open={this.state.snackbarOpen}
          duration={3000}
          handleClose={this.handleClose}
          onClose={this.handleClose}
          variant={this.state.isMailSent == true ? "success" : "error"}
          className={classes.margin}
          message={
            this.state.isMailSent == true
              ? "Mail sent Successfully."
              : "Something Went Wrong, Please Try again."
          }
        />
      </div>
    );
  }
}

GraderAppbar.propTypes = {};
const mapDispatchToProps = (dispatch) => ({
  sendGraderMail: () => dispatch(sendGraderMail()),
});

export default connect(
  null,
  mapDispatchToProps
)(withStyles(campaignSnapshotStyles)(GraderAppbar));
