import React, { Component } from "react";
import PropTypes from "prop-types";
import { CardContent, Grid, LinearProgress, Typography } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import { CustomCard } from "./templates";
import { CompactCardContent } from "../cards";
import { connect } from "react-redux";
import icons from "../icons";
import { getGraderIntegrationStatus } from "../../actions/index";
import Footer from "../footer";

class Integration extends React.PureComponent {
  componentDidMount() {
    this.timer = setInterval(this.progress, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  progress = () => {
    const { status } = this.props;
    console.log("status=", status);
    if (status == 80) {
      clearInterval(this.timer);
    } else {
      this.props.getIntegrationStatus();
    }
  };

  render() {
    const CompactCardContent = withStyles({
      root: {
        paddingTop: 0,
        marginTop: 20,
        boxShadow: "unset",
      },
    })(CardContent);
    const CustomLinearProgress = withStyles((theme) => ({
      root: {
        height: 15,
      },
      barColorPrimary: {
        backgroundColor: theme.palette.graderGraphColors.green,
      },
      determinate: {
        backgroundColor: "#E6E6E6",
      },
    }))(LinearProgress);
    const CustomTypography = withStyles((theme) => ({
      root: {
        color: theme.palette.graderGraphColors.text,
      },
    }))(Typography);
    const IntegrationStatusTypography = withStyles((theme) => ({
      root: {
        color: theme.palette.graderGraphColors.green,
      },
    }))(Typography);
    return (
      <CustomCard>
        <CompactCardContent>
          <Grid
            container
            style={{ minHeight: "85vh" }}
            alignItems={"center"}
            justifyContent={"center"}
          >
            {/*  <Grid item md={10}>
                            {icons.loading_1_Resizable(100, 100)}
                            {icons.loading_3_Resizable(100, 100)}
                            {icons.loading_7_Resizable(100, 100)}

                        </Grid>*/}
            <Grid item md={10}>
              <CustomTypography align={"center"}>
                {icons.loading_6_Resizable(100, 100)}
              </CustomTypography>
              <CustomTypography
                align={"center"}
                variant={"h5"}
                component={"h5"}
              >
                <b>{this.props.integrationMessage}</b>
              </CustomTypography>
              <br />
              <CustomLinearProgress
                variant="determinate"
                value={this.props.status ? this.props.status : 0}
              />
              <IntegrationStatusTypography
                align={"center"}
                variant={"h6"}
                component={"h6"}
              >
                <b>{this.props.status ? this.props.status : 0}%</b>
              </IntegrationStatusTypography>
            </Grid>
          </Grid>
        </CompactCardContent>
      </CustomCard>
    );
  }
}

Integration.propTypes = {};
const mapStateToProps = (state) => ({
  status: state.googleAdsPerformance.integrationProgressBar,
  integrationMessage: state.googleAdsPerformance.integrationMessage,
});
const mapDispatchToProps = (dispatch) => ({
  getIntegrationStatus: () => dispatch(getGraderIntegrationStatus()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Integration);
