import React from "react";
import { CompactCardContent } from "../cards";
import { Card, Grid, Typography } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import PerformanceScore from "./performanceScore";
import CampaignSnapshot from "./campaignSnapshot";
import CustomerOpportunity from "./customerOpportunity";
import {
  CustomCard,
  MainSectionCardHeader,
  calculateCustomerOpportunity,
  calculateCustomerOpportunity1,
} from "../grader/templates";
import { connect } from "react-redux";
import GraderAppbar from "./grader-appbar";
import * as _ from "lodash";
import { getGoogleAccountPerformance } from "../../actions";

const overViewStyles = (theme) => ({
  count: {
    color: theme.palette.hyperLink,
  },
  para: {
    fontSize: 14,
    fontWeight: "300",
    color: theme.palette.graderGraphColors.text,
  },
  smPara: {
    fontSize: 12,
    fontWeight: "300",
    color: theme.palette.graderGraphColors.text,
  },
  grnHead: {
    fontSize: 18,
    color: theme.palette.graderGraphColors.green,
  },
});

class Overview extends React.PureComponent {
  /* componentDidMount() {
         const {integrationProgressBar, impressionsGraphData, graderData} = this.props;
         console.log("window.lxrOptions.graderReportId=", window.lxrOptions.graderReportId);
         this.props.getGoogleAccountPerformance(window.lxrOptions.graderReportId);

     }*/

  render() {
    const { data, accountStats, classes, currency } = this.props;
    return (
      <div>
        <GraderAppbar data={data} />
        <CustomCard>
          <MainSectionCardHeader
            style={{ paddingBottom: 0 }}
            title={
              "Google Ads grades at a glance " + data.accountName + " Scorecard"
            }
            subheader={
              <span>
                A snapshot of your business. The last 30 days ({" "}
                <b>
                  {data.fromDate} - {data.toDate}
                </b>{" "}
                ) with all key account performance metrics at your fingertips.
              </span>
            }
          />
          <CompactCardContent>
            <Grid container spacing={2}>
              <Grid
                item
                md={
                  data.googleAdsReportData
                    ? calculateCustomerOpportunity(
                        data.googleAdsReportData,
                        classes
                      ) == null
                      ? 4
                      : 3
                    : 3
                }
                sm={12}
                xs={12}
              >
                <PerformanceScore data={data} />
              </Grid>
              <Grid
                item
                md={
                  data.googleAdsReportData
                    ? calculateCustomerOpportunity(
                        data.googleAdsReportData,
                        classes
                      ) == null
                      ? 8
                      : 6
                    : 3
                }
                sm={12}
                xs={12}
              >
                <CampaignSnapshot
                  accountStats={accountStats}
                  currency={currency}
                />
              </Grid>
              {data.googleAdsReportData ? (
                calculateCustomerOpportunity(
                  data.googleAdsReportData,
                  classes
                ) == null ? null : (
                  <Grid item md={3} sm={12} xs={12}>
                    <CustomerOpportunity
                      data={
                        data.googleAdsReportData
                          ? data.googleAdsReportData
                          : null
                      }
                    />
                  </Grid>
                )
              ) : null}
            </Grid>
          </CompactCardContent>
        </CustomCard>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getGoogleAccountPerformance: (graderReportId) =>
    dispatch(getGoogleAccountPerformance(graderReportId)),
});
Overview.propTypes = {};
const mapStateToProps = (state, ownProps) => ({
  data: state.googleAdsPerformance,
  accountStats: state.googleAdsPerformance.accountStats,
  currency: state.googleAdsPerformance.googleAdsReportData
    ? state.googleAdsPerformance.googleAdsReportData.currencySymbol
    : "",
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(overViewStyles)(Overview));
