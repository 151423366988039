const ReportStyles = theme => ({
    popUpContainer: {
        minHeight: 50,
        // height: 60,
        padding: 10,
        overflowY: "hidden",
    },
    wBg: {
        background: "#ffffff"
    }
});

export default ReportStyles;