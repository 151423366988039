import React, { useState, useEffect, useRef } from "react";
import {
  CardContent,
  Grid,
  LinearProgress,
  Typography,
  
} from "@mui/material";
import {withStyles} from "@mui/styles";
import { CustomCard } from "../../components/grader/templates";
import { CompactCardContent } from "../../components/cards";
import { connect } from "react-redux";
import icons from "../../components/icons";
import { websiteHealthRefreshStatus } from "../../actions/index";

function WhsIntegration(props) {
  const {
    syncCheckId,
    websiteHealthRefreshStatusReducer,
    websiteHealthRefreshStatus,
    completedValue,
    completedStatus,
  } = props;

  const [statusMessage, setStatusMessage] = useState("Initializing Technical Analysis");

  const countRef = useRef(null);
  countRef.current = completedValue;
  useEffect(() => {
    if (syncCheckId) {
      const interval = setInterval(() => {
        if (countRef.current >= 100 || completedStatus === "Failed") {
          clearInterval(interval);
        } else {
          websiteHealthRefreshStatus(syncCheckId);
        }
      }, 10000);
    }
  }, [syncCheckId]);

  useEffect(() => {
    if (completedValue >= 35 && completedValue <= 74) {
      setStatusMessage("Mapping Your Website's Health Indicator");
    } else if(completedValue >= 75){
      setStatusMessage("Almost Ready – Weaving Data into Insights");
    }
  }, [completedValue]);

  console.log("status message",completedValue, statusMessage);
  const CompactCardContent = withStyles({
    root: {
      paddingTop: 10,
      marginTop:  0,
      boxShadow: "unset",
    },
  })(CardContent);
  const CustomLinearProgress = withStyles((theme) => ({
    root: {
      height: 15,
    },
    barColorPrimary: {
      backgroundColor: theme.palette.graderGraphColors.green,
    },
    determinate: {
      backgroundColor: "#E6E6E6",
    },
  }))(LinearProgress);

  const CustomTypography = withStyles((theme) => ({
    root: {
      color: theme.palette.graderGraphColors.text,
    },
  }))(Typography);

  const IntegrationStatusTypography = withStyles((theme) => ({
    root: {
      color: theme.palette.graderGraphColors.green,
    },
  }))(Typography);

  window.scrollTo(0, 0);
  return (
    <CustomCard>
      <CompactCardContent>
        <Grid
          container
          style={{ minHeight: "85vh" }}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Grid item md={10}>
            <CustomTypography align={"center"}>
              {icons.loading_6_Resizable(100, 100)}
            </CustomTypography>
            <CustomTypography align={"center"} variant={"h5"} component={"h5"}>
               <b>{statusMessage}</b>
            </CustomTypography>
            <br />
            <CustomLinearProgress
              variant="determinate"
              value={completedValue === 0 || completedValue == undefined ? 5 : completedValue}
            />
            <IntegrationStatusTypography
              align={"center"}
              variant={"h6"}
              component={"h6"}
            >
              <b>{completedValue === 0 || completedValue == undefined ? 5 : completedValue}% </b>
            </IntegrationStatusTypography>
          </Grid>
        </Grid>
      </CompactCardContent>
    </CustomCard>
  );
}

WhsIntegration.propTypes = {};

const mapStateToProps = (state) => ({
  websiteHealthRefreshStatusReducer: state.websiteHealthRefreshStatusReducer,
});

const mapDispatchToProps = (dispatch) => ({
  websiteHealthRefreshStatus: (syncCheckId) =>
    dispatch(websiteHealthRefreshStatus(syncCheckId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WhsIntegration);
