import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Grid,
  TextField,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import "./jumpstart.css";
import { JsOrangeContainedBtn } from "../../components/buttons";
import LockResetIcon from "@mui/icons-material/LockReset";
import { passwordReset } from "../../actions/jumpstartRevamped";
import { emailValidationMtd } from "../commonFunctions/commonFunctions";
import ResetPassword from "./resetPassword";
function ForgotPassword(props) {
  const { handleForgotPassword, passwordReset, passwordResetReducer } = props;
  const [email, setEmail] = useState();
  const [passwordResetMailSent, setPasswordResetMailSent] = useState(false);
  const [error, setError] = useState(false);
  const [emailFormatError, setEmailFormatError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlePasswordReset = async () => {
    if (emailValidationMtd(email)) {
      setLoading(true);
      const data = await passwordReset(email);
      setLoading(false);
      if (data.status === 200) {
        setPasswordResetMailSent(true);
      } else {
        setError(true);
      }
    } else {
      setEmailFormatError("Please enter valid email format");
    }
  };

  return (
    <>
      {passwordResetMailSent ? (
        <ResetPassword handleForgotPassword={handleForgotPassword} />
      ) : (
        <>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography color="#1E334E" fontWeight="500">
              Forgot Password?
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="subtitle2" color="#747474">
              Type your registered E-mail ID below
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              fullWidth
              id="fullWidth"
              placeholder="Type your email here"
              onChange={handleEmailChange}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <JsOrangeContainedBtn
              onClick={handlePasswordReset}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress sx={{color:"#fff"}} size="1.5rem"/>
              ) : (
                <>
                  <LockResetIcon />
                  Reset Password
                </>
              )}
            </JsOrangeContainedBtn>
          </Grid>
          <Grid
            item
            container
            justifyContent="center"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
          >
            <Button
              style={{ textTransform: "none", color: "#F58120" }}
              onClick={handleForgotPassword}
            >
              Go Back to Sign In
            </Button>
          </Grid>
          {error ? (
            <Grid item xs={12}>
              <Typography color="red" align="center" variant="subtitle2">
                Email Id Not Found
              </Typography>
            </Grid>
          ) : emailFormatError !== "" && emailFormatError !== undefined ? (
            <Grid item xs={12}>
              <Typography color="red" align="center" variant="subtitle2">
                {emailFormatError}
              </Typography>
            </Grid>
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  passwordResetReducer: state.passwordResetReducer,
});
const mapDispatchToProps = (dispatch) => ({
  passwordReset: (email) => dispatch(passwordReset(email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
