import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateCardDetailsForm } from "../../actions/fetchUserProfile";
import { updatePassword } from "../../actions/fetchUserProfile";
import { Button, DialogTitle, IconButton, InputAdornment } from "@mui/material";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  Grid,
} from "@mui/material/index";
import Typography from "@mui/material/Typography";
import { InputFieldPassword, InputFields } from "../../components/input-forms";
import {
  NewOrangeSaveContainedBtn,
  NewTextRectOrangeTextBtn,
} from "../../components/buttons";
import withStyles from "@mui/styles/withStyles";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import MySnackbarWrapper from "../snackbars";
import { ErrorTypography } from "../../components/typography";

const CustomDialogContentText = withStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    fontSize: 16,
    // minWidth:200,
    // minHeight:100
  },
}))(DialogContentText);

function PasswordChange(props) {
  const [oldPassword, setOldPassword] = useState("");
  const [oldPasswordError, setOldPasswordError] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [reEnterPassword, setReEnterPassword] = useState("");
  const [reEnterPasswordError, setReEnterPasswordError] = useState("");
  const [isPasswordUpdating, setIsPasswordUpdating] = useState(false);
  const [commonError, setCommonError] = useState("");
  const [dialog, setDialog] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showReEnterPassword, setShowReEnterPassword] = useState(false);

  // const [state, setState] = useState({
  //   alert: false,
  //   message: "",
  //   variantType: "info",
  // });
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [variantType, setVariantType] = useState("info");


  useEffect(() => {
    setOldPassword("");
    setOldPasswordError("");
    setNewPassword("");
    setNewPasswordError("");
    setReEnterPassword("");
    setReEnterPasswordError("");
    setIsPasswordUpdating(false);
    setCommonError("");
    setDialog(false);
  }, []);

  const validate = () => {
    
    let isError = false;
    const errors = {};
    if ((oldPassword && oldPassword.length == 0) || oldPassword.trim() == "") {
      isError = true;
      errors.oldPasswordError = "Password cannot be left empty";
    } else {
      errors.oldPasswordError = "";
    }
    if ((newPassword && newPassword.length == 0) || newPassword.trim() == "") {
      isError = true;
      errors.newPasswordError = "Password cannot be left empty";
    } else if (newPassword && newPassword.length < 7) {
      isError = true;
      errors.newPasswordError =
        "New password must contain minimum 7 characters";
    } else {
      errors.newPasswordError = "";
    }
    if (
      (reEnterPassword && reEnterPassword.length == 0) ||
      reEnterPassword.trim() == ""
    ) {
      isError = true;
      errors.reEnterPasswordError = "Password cannot be left empty";
    } else {
      errors.reEnterPasswordError = "";
    }

    setOldPasswordError(errors.oldPasswordError);
    setNewPasswordError(errors.newPasswordError);
    setReEnterPasswordError(errors.reEnterPasswordError);

    return isError;
  };

  const handlePasswordSubmit = async (event) => {
    event.preventDefault();
    let error = validate();
    if (!error) {
      setIsPasswordUpdating(true);
      let response = await props.updatePassword(
        oldPassword,
        newPassword,
        reEnterPassword
      );
      console.log("The response is", response);
      if (
        response != null &&
        response &&
        response.error &&
        response.error != ""
      ) {
        setCommonError(response.error);
        // setState({ commonError: response.error });
      } else {
        // setState({
        //   alert: true,
        //   message: "Password successfully changed",
        //   variantType: "success",
        // });
        setAlert(true);
        setMessage("Password successfully changed");
        setVariantType("success");

        setCommonError("");
      }
      setIsPasswordUpdating(false);
    }
  };

  const handleClose = () => {
    setDialog(false);
    setCommonError("");
    setOldPassword("");
    setOldPasswordError("");
    setNewPassword("");
    setNewPasswordError("");
    setReEnterPassword("");
    setReEnterPasswordError("");
  };

  const handleOpenDialog = () => {
    setDialog(true);
  };

  const handleCloseAlert = async () => {
    // setState((prevState) => ({
    //   ...prevState,
    //   alert: false,
    // }));
    setAlert(false);
  };

  const handleShowOldPassword = () =>
    setShowOldPassword((prevState) => !prevState);
  const handleShowNewPassword = () =>
    setShowNewPassword((prevState) => !prevState);
  const handleShowReEnterPassword = () =>
    setShowReEnterPassword((prevState) => !prevState);

  return (
    <Grid container>
      <Grid item sm={8} xs={8} md={5}>
        <Typography gutterBottom>
          <NewOrangeSaveContainedBtn
            className={"fullWidth"}
            variant={"contained"}
            onClick={handleOpenDialog}
          // onClick={setDialog(true)}
          >
            Change Password
          </NewOrangeSaveContainedBtn>
        </Typography>
      </Grid>
      <Grid item>
        <Dialog
          open={dialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {isPasswordUpdating ? (
            <DialogContent>
              <CustomDialogContentText>
                <CircularProgress />
              </CustomDialogContentText>
            </DialogContent>
          ) : (
            <Fragment>
              <DialogTitle>{"Change Password"}</DialogTitle>
              <form onSubmit={handlePasswordSubmit}>
                <DialogContent>
                  <Grid container>
                    <Grid md={12}>
                      {commonError != "" ? (
                        <ErrorTypography>{commonError}</ErrorTypography>
                      ) : null}
                    </Grid>
                    <Grid item md={12}>
                      <FormControl>
                        <Typography
                          color={"textPrimary"}
                          variant={"body1"}
                          gutterBottom
                        >
                          <b>Old Password</b>
                        </Typography>
                        <InputFields
                          type={showOldPassword ? "text" : "password"}
                          value={oldPassword}
                          onChange={(event) =>
                            setOldPassword(event.target.value)
                          }
                          margin="normal"
                          variant="outlined"
                          error={oldPasswordError !== ""}
                          helperText={oldPasswordError}
                          style={{ marginTop: 0 }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="Toggle password visibility"
                                  onClick={handleShowOldPassword}
                                  // onClick={setShowOldPassword(true)
                                  size="large"
                                >
                                  {showOldPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={12}>
                      <FormControl>
                        <Typography
                          color={"textPrimary"}
                          variant={"body1"}
                          gutterBottom
                        >
                          <b>New Password</b>
                        </Typography>
                        <InputFields
                          type={showNewPassword ? "text" : "password"}
                          value={newPassword}
                          onChange={(event) =>
                            setNewPassword(event.target.value)
                          }
                          margin="normal"
                          variant="outlined"
                          error={newPasswordError !== ""}
                          helperText={newPasswordError}
                          style={{ marginTop: 0 }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="Toggle password visibility"
                                  onClick={handleShowNewPassword}
                                  // onClick={setShowNewPassword(true)}
                                  size="large"
                                >
                                  {showNewPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={12}>
                      <FormControl>
                        <Typography
                          color={"textPrimary"}
                          variant={"body1"}
                          gutterBottom
                        >
                          <b>Re-Enter New Password</b>
                        </Typography>
                        <InputFields
                          type={showReEnterPassword ? "text" : "password"}
                          value={reEnterPassword}
                          onChange={(event) =>
                            setReEnterPassword(event.target.value)
                          }
                          margin="normal"
                          variant="outlined"
                          error={reEnterPasswordError !== ""}
                          helperText={reEnterPasswordError}
                          style={{ marginTop: 0 }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="Toggle password visibility"
                                  onClick={handleShowReEnterPassword}
                                  // onClick={setShowReEnterPassword(true)}
                                  size="large"
                                >
                                  {showReEnterPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <NewTextRectOrangeTextBtn
                    onClick={handleClose}
                    color="primary"
                  >
                    Cancel
                  </NewTextRectOrangeTextBtn>
                  <NewTextRectOrangeTextBtn type={"submit"} autoFocus>
                    Save
                  </NewTextRectOrangeTextBtn>
                </DialogActions>
              </form>
            </Fragment>
          )}
        </Dialog>
        <MySnackbarWrapper
          open={alert}
          onClose={handleCloseAlert}
          message={message}
          verticalAlign={"top"}
          horizontalAlign={"right"}
          duration={6000}
          variant={variantType}
        />
      </Grid>
    </Grid>
  );
}
PasswordChange.propTypes = {};

const mapDispatchToProps = (dispatch) => ({
  updatePassword: (oldPassword, newPassword, reEnterPassword) => dispatch(updatePassword(oldPassword,newPassword,reEnterPassword)),
});

export default connect(null, mapDispatchToProps)(PasswordChange);
