import React, { useEffect, useState } from "react";
import { Grid, IconButton, Skeleton, Typography } from "@mui/material";
import {
  JsSectionTypography,
  JsSubSectionTypography,
} from "../../../components/typography";
import LaunchIcon from "@mui/icons-material/Launch";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchJsAccountById } from "../../../actions/jumpstartRevamped";
import { formatDate } from "../../commonFunctions/commonFunctions";
import { FormatAccountStatus } from "./formatStatus";
import "../jumpstart.css";
import { fetchJsAccountByIdReducer } from "../../../reducers/jumpstartRevampedReducer";

function PageHeader(props) {
  const history = useHistory();
  const {
    hidden,
    sectionHeading,
    fetchJsAccountById,
    fetchJsAccountByIdReducer,
    token,
    completedText,
  } = props;
  const { id } = useParams();
  const [businessName, setBusinessName] = useState();
  const [businessUrl, setBusinessUrl] = useState();
  const [lastUpdated, setLastUpdated] = useState();
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [businessUrlWithHttps, setBusinessUrlWithHtps] = useState("");

  // useEffect(async () => {
  //      setLoading(true);
  //     const data = await fetchJsAccountById(token, id);
  //     if (data && data.responseStatus == 200) {
  //       setBusinessName(data.companyName);
  //       setBusinessUrl(data.websiteUrl);
  //       setStatus(data.status);
  //       setLastUpdated(formatDate(data.lastModifiedDate));
  //     }
  //     setLoading(false);
  // }, []);

  useEffect(async () => {
    if (
      fetchJsAccountByIdReducer.message == "JS_ACCOUNT_BY_ID_RECEIVED" &&
      fetchJsAccountByIdReducer.id == id
    ) {
      setBusinessName(fetchJsAccountByIdReducer.companyName);
      setBusinessUrl(fetchJsAccountByIdReducer.websiteUrl);
      setStatus(fetchJsAccountByIdReducer.status);
      setLastUpdated(formatDate(fetchJsAccountByIdReducer.lastModifiedDate));
    }
  }, [fetchJsAccountByIdReducer]);

  useEffect(() => {
    if (businessUrl != "" && businessUrl != null && businessUrl != undefined) {
      setBusinessUrlWithHtps(
        businessUrl.startsWith("http") ? businessUrl : "https://" + businessUrl
      );
    }
  }, [businessUrl]);

  const setStatusColor = (status) => {
    if (status.includes("CREATED")) {
      return "yellowStatusColor";
    } else if (status.includes("IN_PROGRESS")) {
      return "orangeStatusColor";
    } else if (status.includes("IN_REVIEW")) {
      return "blueStatusColor";
    } else if (status.includes("REVIEWER_APPROVED")) {
      return "purpleStatusColor";
    } else if (status == "COMPLETED") {
    }
  };

  const handleBackIconBtn = () => {
    history.push(`/jumpstart-dashboard`);
  };

  return (
    <div className="stickyHeader">
      <Grid
        container
        direction="row"
        rowSpacing={1}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={9}>
          <JsSectionTypography>
            {loading ? (
              <Skeleton />
            ) : (
              <>
                <IconButton onClick={handleBackIconBtn}>
                  <ArrowBackIcon sx={{ color: "#F58120" }} />
                </IconButton>
                {sectionHeading}
                <span style={{ color: "#F58120" }}>&nbsp;{businessName}</span>
              </>
            )}
          </JsSectionTypography>
        </Grid>

        <Grid
          item
          container
          direction="row"
          justifyContent="end"
          alignItems="center"
          xs={3}
        >
          {loading ? (
            <Skeleton />
          ) : (
            <>
              <Grid item>
                <JsSubSectionTypography style={{ paddingRight: "5px" }}>
                  Status:{" "}
                </JsSubSectionTypography>
              </Grid>
              <Grid
                item
                className={setStatusColor(status)}
                style={{ padding: "3px 10px" }}
              >
                <Typography variant="subtitle2">
                  {FormatAccountStatus(status)}
                </Typography>
              </Grid>
            </>
          )}
        </Grid>

        {hidden && hidden === "true" ? (
          <JsSubSectionTypography style={{paddingLeft:"40px"}}>
            {loading ? <Skeleton /> : completedText}
          </JsSubSectionTypography>
        ) : (
          <>
            <Grid item container alignItems="center" xs={8}>
              <Grid item>
                <JsSubSectionTypography style={{ paddingLeft: "4px" }}>
                  {loading ? (
                    <Skeleton />
                  ) : (
                    <>
                      {businessUrl == null ||
                      businessUrl == undefined ||
                      businessUrl == "" ? (
                        ""
                      ) : (
                        <a
                          href={businessUrlWithHttps}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <IconButton>
                            <LaunchIcon fontSize="small" color="action" />
                          </IconButton>
                        </a>
                      )}

                      {businessUrl}
                    </>
                  )}
                </JsSubSectionTypography>
              </Grid>
              {/* <Grid item>
                <JsSubSectionTypography>
                  <IconButton>
                    <RemoveRedEyeOutlinedIcon fontSize="small" color="action" />
                  </IconButton>
                  More Details
                </JsSubSectionTypography>
              </Grid>
              <Grid item>
                <JsSubSectionTypography>
                  <IconButton>
                    <CommentOutlinedIcon fontSize="small" color="action" />
                  </IconButton>
                  Comments
                </JsSubSectionTypography>
              </Grid> */}
            </Grid>

            <Grid item xs={4}>
              {/* <JsSubSectionTypography style={{ float: "right" }}>
                {loading ? (
                  <Skeleton />
                ) : (
                  <>Last Updated:&nbsp;{lastUpdated} </>
                )} 
              </JsSubSectionTypography>*/}
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
}
const mapStateToProps = (state) => ({
  fetchJsAccountByIdReducer: state.fetchJsAccountByIdReducer,
});
const mapDispatchToProps = (dispatch) => ({
  fetchJsAccountById: (token, id) => dispatch(fetchJsAccountById(token, id)),
  // fetchJsAccountS
});

export default connect(mapStateToProps, mapDispatchToProps)(PageHeader);
