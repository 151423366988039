const styles = (theme) => ({
  root: {
    //   height:"60px",
    width: "100%",
  },
  grow: {
    flexGrow: 1,
    [theme.breakpoints.down("lg")]: {
      flexGrow: 1,
    },
    [theme.breakpoints.down("md")]: {
      flexGrow: 1,
    },
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  toolbarMargin: {
    ...theme.mixins.toolbar,

    [theme.breakpoints.up("sm")]: {
      marginBottom: "1.2em",
    },
    [theme.breakpoints.down("xl")]: {
      marginBottom: "1.5em",
    },
  },

  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(10),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
  },
  sectionDesktop: {
    [theme.breakpoints.up("xs")]: {
      display: "none",
      fontSize: "1rem",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
      fontWeight: "bold",
      // fontSize:'2.0rem',
      // justify-content: 'space-evenly',
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  topline: {
    margin: "0px",
    width: "auto",
    height: "40px",
    textAlign: "center",
    background: "#f58120",
    color: "white",

    fontSize: "0.9rem",
    fontWeight: "bold",
    position: "static",
  },
  ButtonStyle: {
    color: "white",
    fontWeight: "bold",
    fontSize: "14px",
    borderColor: "white !important",
    textTransform: "none",
  },
  ButtonsDiv: {
    paddingLeft: 60,
  },
  LoginAndSignup: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 299,
    },
  },
  LxrGuideImagestyle: {
    width: "100%",
    height: "100%",
    [theme.breakpoints.down("lg")]: {
      width: 150,
    },
  },
  mobileMenu: {
    // padding: theme.spacing(1) * 1,
    padding: "10px 10px 10px 10px",
    display: "none",
    [theme.breakpoints.down("lg")]: {
      display: "flex",
    },
  },
  ToolBarStyle: {
    minHeight: 90,
  },

  ToolBarStyle1: {
    minHeight: 15,
    padding: 0,
    backgroundColor: theme.palette.orangeColor,
  },
  AppBarstyle: {
    zoom:"90%",
    backgroundColor: "#1e334e",
    height: "90px",
    //         height: 120,
    // position: "static",
    paddingRight: 0,
    // "&:hover": {
    //     paddingRight: 0
    // }
    
  },
  covidBanner: {
    color: theme.palette.common.white,
    fontWeight: "700",
    fontSize: "16px",
    [theme.breakpoints.down("xl")]: {
      fontSize: "12px",
    },
  },
  NetelixirImgStyle: {
    paddingLeft: 10,
    width: "100%",
    // height:31.15,
  },
  SignUpLogo: {
    backgroundColor: theme.palette.secondary.main,

    color: "white",

    fontSize: 14,
    borderColor: "white !important",
    textTransform: "none",
  },
  tab: {
    fontSize: 16,
    fontFamily: "Lato",
    fontWeight: "500",
    minWidth: 10,
    marginLeft: 0,
    opacity: 1,
  },
  tabContainer: {
    marginLeft: 25,
  },
  loginButtons: {
    marginLeft: "auto",
    fontSize: 14,
  },
  menu: {
    backgroundColor: theme.palette.text.primary,
    color: "white",
    borderRadius: 0,
  },
  menuItem: {
    fontSize: 16,
    boxShadow: "unset",
    // opacity: 0.7,
    "&:hover": {
      // opacity: 1,
    },
  },
  mobileBurger: {
    width: 50,
    height: 50,
    color: theme.palette.common.white,
  },
  mobilePaper: {
    backgroundColor: theme.palette.text.primary,
    minWidth: 250,
  },
  menuItemText: {
    color: theme.palette.common.white,
  },
  iconColor: {
    color: theme.palette.common.white,
  },
  loginsbtnsMob: {
    padding: "0px 15px",
  },
  showBanner : {
    background: "#000",
    color: theme.palette.common.white,
    padding: "0 1% 0 2%",
  },
  hideBanner : {
    display: "none",
    background: "#000",
    color: theme.palette.common.white,
    padding: "0 1% 0 2%",
  },
  floatRight: {
    float:"right",
  }
});

export default styles;
