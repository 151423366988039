import React, { Fragment, useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Chip,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import icons from "../icons";
import { IconAndName } from "./hierarchy";
import * as _ from "lodash";
import ArrowForward from "@mui/icons-material/ArrowForward";
import { TableRowAutoHeight } from "../table";
import { connect } from "react-redux";
import CurrencySymbol from "../../components/currencySymbol.jsx";
import AdCopyHierarchy from "./adcopyHierarchyTask/adCopy-hierarchy";
import AdCopyPreview from "./adcopyHierarchyTask/adCopy-hierarchy-preview";
import { getDomainUrl } from "../task/adcopyHierarchyTask/templates";
import { DivTypography } from "../typography";
import { TaskTypeEnum, addCommas } from "../../NewInterfacePages/commonFunctions/commonFunctions.jsx";
import "../../NewInterfacePages/task.css"
import EastIcon from "@mui/icons-material/East";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

export function getRenderer(taskType) {
  switch (taskType) {
    case TaskTypeEnum.INCREASE_BID_GEO_LOCATIONS.taskTypeId:
    case TaskTypeEnum.DECREASE_BID_GEO_LOCATIONS.taskTypeId:
    case TaskTypeEnum.INCREASE_BID_GEO_LOCATIONS_PLA.taskTypeId:
    case TaskTypeEnum.DECREASE_BID_GEO_LOCATIONS_PLA.taskTypeId:
      return regionAndBid;

    case TaskTypeEnum.SQR_EXACT_NEGATIVE_KEYWORDS.taskTypeId:
    case TaskTypeEnum.ADD_NEGATIVE_KEYWORDS.taskTypeId:
      return keywordWithMatchType;

    case TaskTypeEnum.KEY_BID_INCREASE_LOW_IMPRESSION.taskTypeId:
    case TaskTypeEnum.DECREASE_BID_NON_PERFORM_KEY.taskTypeId:
    case TaskTypeEnum.INCREASE_BID_AOV.taskTypeId:
    case TaskTypeEnum.DECREASE_BID_AOV.taskTypeId:
    case TaskTypeEnum.CHANGE_KEYWORD_BIDS_CUSTOM.taskTypeId:
      return kwMatchTypeOldNewCPC;

    // case 3:
    case TaskTypeEnum.REMOVE_CONFLICT_NEG_KEY.taskTypeId:
      return keywordWithMatchType;

    case TaskTypeEnum.SQR_EXACT_KEYWORDS.taskTypeId:
    case TaskTypeEnum.CONVERTING_KEY_MATCH_TYPE.taskTypeId:
    case TaskTypeEnum.ADD_KEYWORD_CUSTOM.taskTypeId:

    case TaskTypeEnum.SQR_CONVERTING_SEARCH_QUERIES.taskTypeId:
    case TaskTypeEnum.ADD_NEW_KEYWORD_OPPORTUNITY_ANALYSIS_FRESH_KEYWORD_TASK
      .taskTypeId:
      return keywordWithMatchTypeURLAndCPC;

    case TaskTypeEnum.COST_TAKING_SQ_AS_NEGATIVE.taskTypeId:
    case TaskTypeEnum.PAUSE_NON_PERFORM_KEY.taskTypeId:
    case TaskTypeEnum.PAUSE_BROAD_KEYWORD.taskTypeId:
      return keywordWithMatchType;

    case TaskTypeEnum.PAUSE_ADGRPS_NO_ACTIVE_KEY.taskTypeId:
    case TaskTypeEnum.PAUSE_ADGRPS_NO_ACTIVE_ADCOPY.taskTypeId:
    case TaskTypeEnum.PAUSE_ADGRPS_CONFLICT_PRODUCT_GRPS.taskTypeId:
      return adgroup;

    case TaskTypeEnum.PAUSE_KEY_BROKEN_URLS.taskTypeId:
      // case 3:
      return keywordWithMatchTypeAndURL;

    case TaskTypeEnum.PAUSE_ADCOPY_BROKEN_URLS.taskTypeId:
      return urlAndText;

    case TaskTypeEnum.OPTIMIZE_SPEND_MOBILE_DEVICES.taskTypeId:
    case TaskTypeEnum.INCREASE_BID_MOBILE_DEVICES.taskTypeId:
    case TaskTypeEnum.INCREASE_BID_MOBILE_DEVICES_PLA.taskTypeId:
    case TaskTypeEnum.OPTIMIZE_SPEND_MOBILE_DEVICES_PLA.taskTypeId:
      return adgroupBidModifier;

    case TaskTypeEnum.REMOVE_CONFLICT_CAMPAIGN_NEG_KEY.taskTypeId:
    case TaskTypeEnum.ADD_CAMPAIGN_NEGATIVE.taskTypeId:
      return keywordWithMatchType;

    case TaskTypeEnum.RESTRUCTURE_PRODUCT_GROUP.taskTypeId:
      return productGroupDimCPC;

    case TaskTypeEnum.INCREASE_BID_PRODUCT_GRPS.taskTypeId:
    case TaskTypeEnum.DECREASE_BID_PRODUCT_GRPS.taskTypeId:
      return productGroupDimCPC;

    case TaskTypeEnum.RESTRUCTURE_PRODUCT_GRP_CROSS_SERVING.taskTypeId:
      return addRemoveProductGroups;

    case TaskTypeEnum.NEW_PRODUCT_GRP_OPPORTUNITIES.taskTypeId:
      return productGroupDimCPC;

    case TaskTypeEnum.INCREASE_BUDGET_CUSTOM.taskTypeId:
    case TaskTypeEnum.DECREASE_BUDGET_CUSTOM.taskTypeId:
    case TaskTypeEnum.NEW_SHOPPING_CAMPAIGN.taskTypeId:
    case TaskTypeEnum.NEW_SMART_SHOPPING_CAMPAIGN.taskTypeId:
      return campaignNameTypeBudget;
    // return campaignNameAdgroupTypeBudget;

    case TaskTypeEnum.HOURLY_BID_MODIFIER.taskTypeId:
      return Schedule2;

    case TaskTypeEnum.CAMPAIGN_SITE_LINKS.taskTypeId:
    case TaskTypeEnum.DISABLE_SEARCH_PARTNER.taskTypeId:
      return Campaign;

    case TaskTypeEnum.REMOVE_ADGROUP_NEG_KEYWORDS.taskTypeId:
      return keywordWithMatchTypeAndStatus;

    case TaskTypeEnum.REMOVE_CAMPAIGN_NEG_KEYWORDS.taskTypeId:
      return keywordWithMatchTypeAndStatus;

    case 45:
      return urlAndTextAds;

    case TaskTypeEnum.SETUP_SHOPPING_FEED.taskTypeId:
      return shoppingTask;
    case 47:
      return pauseAdCopyHierarchy;
    case TaskTypeEnum.ADD_ADCOPY.taskTypeId:
      return adCopyHierarchy;
    case TaskTypeEnum.ADD_ADCOPY_RECOMMENDATION.taskTypeId:
      return addAdCopy;
    case 54:
      return shoppingTask;
    case TaskTypeEnum.MAXIMUM_CLICKS_POSSIBLE_.taskTypeId:
      return campaignBidStrategyToMaxClicks;

    case TaskTypeEnum.INCREASE_TARGET_ROAS.taskTypeId:
      return IncreaseOrDecreaseTROAS;

    case TaskTypeEnum.DECREASE_TARGET_ROAS.taskTypeId:
      return IncreaseOrDecreaseTROAS;

    case TaskTypeEnum.INCREASE_TARGET_CPA.taskTypeId:
      return IncreaseOrDecreaseTROAS;

    case TaskTypeEnum.DECREASE_TARGET_CPA.taskTypeId:
      return IncreaseOrDecreaseTROAS;

    case TaskTypeEnum.UPGRADE_MANUAL_BID_TO_TARGET_ROAS.taskTypeId:
      return UpgradeBiddingStrategy;

    case TaskTypeEnum.UPGRADE_MANUAL_BID_TO_TARGET_CPA.taskTypeId:
      return UpgradeBiddingStrategy;

    default:
      return null;
  }
}

export function multiGroupBy(list, levels) {
  let grouped = _.groupBy(list, levels[0]);
  if (levels.length > 1) {
    grouped = _.mapValues(grouped, (list) =>
      multiGroupBy(list, levels.slice(1))
    );
  }
  return grouped;
}

/*const MatchTypeChip = withStyles(theme => ({
    root: {
        height: 20,
        borderRadius: 5,
        // color:theme.palette.common.white,
        backgroundColor: theme.palette.grey.A100,
    }
}))(Chip);*/
export const RecommendationsBanner = withStyles((theme) => ({
  root: {
    borderTop: "2px solid #FFEAC5",
    backgroundColor: theme.palette.recommendationsBg,
    boxShadow: "unset",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    marginBottom: 20,
  },
}))(Paper);

function GenericBox({ name, caption, icon, children }) {
  return (
    <Fragment>
      <Paper className={"fullWidth"}>
        {/*<Typography style={{fontSize:10, backgroundColor:blueGrey[100], textAlign:"center"}}>New Keyword</Typography>*/}
        <Grid container style={{ padding: 10 }} alignItems={"center"}>
          <Grid item className={"growable"}>
            <IconAndName icon={icon} name={name} caption={caption} />
          </Grid>
          <Grid item>{children}</Grid>
        </Grid>
      </Paper>
    </Fragment>
  );
}

export function GoogleRecommendationBanner() {
  return (
    <RecommendationsBanner className={"fullWidth"}>
      <Grid
        container
        justifyContent={"flex-end"}
        alignItems={"center"}
        spacing={2}
      >
        <Grid item>
          <Typography style={{ paddingTop: 5 }}>
            {icons.googleRecommendation}
          </Typography>
        </Grid>
        <Grid item>
          <DivTypography>Google Recommended&nbsp;&nbsp;</DivTypography>
        </Grid>
      </Grid>
    </RecommendationsBanner>
  );
}

function conciseURL(urlString) {
  try {
    const url = new URL(urlString);
    return url.pathname;
  } catch (e) {
    console.log("count not parse urlString = ", urlString, e);
    return urlString;
  }
}

/*const bidModifierFormatOptions = {
    // commafy: true,
    // shortFormatMinValue: 9999,
    // shortFormatPrecision: 0,
    // precision: 1,
    // shortFormat: true,
    // locales: "en-US",
    percentage: true,
    // wholenumber: 'ceil',
    // currencyIndicator: 'USD',
    // cssClass: ["inline"]
};*/

const oldMaxCpcChip = withStyles((theme) => ({
  root: {
    height: 24,
    borderRadius: 3,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.Bid,
    opacity: 0.5,
    minWidth: 52,
  },
}))(Chip);

const MaxCpcChip = withStyles((theme) => ({
  root: {
    height: 28,
    borderRadius: 3,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.Bid,
    opacity: 1,
    minWidth: 52,
  },
}))(oldMaxCpcChip);

const BidModifiersChip = withStyles((theme) => ({
  root: {
    height: 28,
    borderRadius: 3,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.bidModifier,
    opacity: 1,
    minWidth: 52,
  },
}))(Chip);
// const BidModifiersChipOld = withStyles(theme => ({
//     root: {
//         height: 24,
//         opacity: 0.5
//     }
//
// }))(BidModifiersChip);
const BudgetChip = withStyles((theme) => ({
  root: {
    height: 28,
    borderRadius: 3,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.budget,
    opacity: 1,
    minWidth: 52,
  },
}))(Chip);
const oldBudget = withStyles((theme) => ({
  root: {
    height: 24,
    opacity: 0.5,
  },
}))(BudgetChip);

/**
 * @return {null}
 */
const CustomTypographyChip = withStyles((theme) => ({
  root: {
    fontSize: 8,
  },
}))(Typography);

const CustomTypographyCPCBidChip = withStyles((theme) => ({
  root: {
    fontSize: 10,
  },
}))(Typography);

const TypeTypography = withStyles((theme) => ({
  root: {
    fontSize: 10,
    color: theme.palette.text.primary,
  },
}))(Typography);

function MaxCPC({ value, old }) {
  const formatOptions = {
    precision: 2,
    locales: "en-US",
    currency: true,
    currencyIndicator: "USD",
  };
  if (value == null) return null;
  const Chip = old ? oldMaxCpcChip : MaxCpcChip;
  return (
    <Fragment>
      <Chip
        label={
          <b>
            <CurrencySymbol formatOptions={formatOptions}>
              {value}
            </CurrencySymbol>
          </b>
        }
        title={"Bid Amount"}
      />
    </Fragment>
  );
}

MaxCPC = connect((state) => ({
  currencySymbol: state.perf.currency,
}))(MaxCPC);



export function NewTaskTypeTitle({ type, value }) {
  return (
    <Typography fontSize="0.875rem" color={"inherit"}>
      {type} type:{" "}
      <span
        className={
          value === "Search" ?
             "searchCampaign"
            : value === "Shopping"
            ? "shoppingCampaign"
            : ""
        }
      >
        {value}
      </span>
    </Typography>
  );
}
// export default connect(state => ({currencySymbol:state.perf.currency}))(MaxCPC);

// function BidModifier({value,old}) {
//     if(value== null) return null;
//     const Chip = old ?  BidModifiersChipOld: BidModifiersChip;
//     return (
//         <Fragment>
//             <Chip label={<b>{value}%</b>} title={"Bid Modifier Amount"}/>
//         </Fragment>
//     );
//
// }
function Budget({ value, old }) {
  const formatOptions = {
    precision: 2,
    locales: "en-US",
    currency: true,
    currencyIndicator: "usd",
  };

  if (value == null) return null;
  const Chip = old ? oldBudget : BudgetChip;
  return (
    <Fragment>
      <Chip
        label={
          <b>
            <CurrencySymbol formatOptions={formatOptions}>
              {value}
            </CurrencySymbol>
          </b>
        }
        title={old ? "Old Budget" : "New Budget"}
      />
    </Fragment>
  );
}

// Budget = connect((state) => ({
//     currencySymbol:state.perf.currency
// }))(Budget);

// export default connect(state => ({currencySymbol:state.perf.currency}))(Budget);

export function TypeTitle({ type, value }) {
  return (
    <TypeTypography variant={"caption"} color={"inherit"}>
      {type} Type:{" "}
      <span
        style={{
          fontSize: 12,
          textTransform: "capitalize",
          fontWeight: "normal",
        }}
      >
        {value}
      </span>
    </TypeTypography>
  );
}

export function keywordWithMatchType(d) {
  return (
    <GenericBox
      icon={icons.Keyword}
      name={d.keywordText}
      caption={<TypeTitle type="Match" value={d.keywordMatchType} />}
    />
  );
}

export function keywordWithMatchTypeURLAndCPC(d) {
  return (
    <Fragment>
      <GenericBox
        icon={icons.Keyword}
        name={d.keywordText}
        caption={
          <div>
            <div>
              <TypeTitle type="Match" value={d.keywordMatchType} />
            </div>
            <div>
              {d.keywordUrl ? (
                <span className={"flex"}>
                  {conciseURL(d.keywordUrl)}
                  <a
                    target="_blank"
                    href={d.keywordUrl}
                    style={{ color: "inherit", verticalAlign: "middle" }}
                  >
                    &nbsp;
                    <i className="fa fa-external-link" />
                  </a>
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
        }
      >
        <div align="right">
          <MaxCPC value={d.maxCpc} status={d.status} />
          <CustomTypographyChip variant={"caption"} align={"right"}>
            Bid
          </CustomTypographyChip>
        </div>
      </GenericBox>
      {d.analysisTypeId == 23 ? <GoogleRecommendationBanner /> : null}
    </Fragment>
  );
}

export function keywordWithMatchTypeAndURL(d) {
  return (
    <GenericBox
      icon={icons.Keyword}
      name={d.keywordText}
      caption={
        <div>
          <div>
            <TypeTitle type="Match" value={d.keywordMatchType} />
          </div>
          <div className={"flex"}>
            {d.keywordUrl ? (
              <span className={"flex"}>
                {conciseURL(d.keywordUrl)}
                <a
                  target="_blank"
                  href={d.keywordUrl}
                  className={"flex alignItemsCenter"}
                  style={{ color: "inherit" }}
                >
                  &nbsp;
                  <i className="fa fa-external-link" />
                </a>
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
      }
    ></GenericBox>
  );
}

export function keywordWithMatchTypeAndStatus(d) {
  const addRemoveCaption = d.keywordStatus == "enabled" ? "Remove" : "";
  const captionColor = d.keywordStatus == "enabled" ? "secondary" : "";
  return (
    <GenericBox
      icon={icons.Keyword}
      name={d.keywordText}
      caption={<TypeTitle type="Match" value={d.keywordMatchType} />}
    >
      <Typography color={captionColor}>{addRemoveCaption}</Typography>
    </GenericBox>
  );
}

export function regionAndBid(d) {
  return (
    <GenericBox icon={icons.LocationOn} name={d.region}>
      <div align="right">
        <BidModifiersChip
          label={d.bidModifier + "%"}
          title={"Bid Modifier"}
        ></BidModifiersChip>
        <CustomTypographyChip variant={"caption"} align={"right"}>
          Bid Modifier
        </CustomTypographyChip>
      </div>
    </GenericBox>
  );
}

export function kwMatchTypeOldNewCPC(d) {
  return (
    <GenericBox
      icon={icons.Keyword}
      name={d.keywordText}
      caption={<TypeTitle type="Match" value={d.keywordMatchType} />}
    >
      <div align="right">
        <MaxCPC value={d.currentMaxCpc} old status={d.status} />
        <ArrowForward style={{ verticalAlign: "middle" }} />
        <MaxCPC value={d.maxCpc} status={d.status} />
        <CustomTypographyChip variant={"caption"} align={"right"}>
          Bid
        </CustomTypographyChip>
      </div>
    </GenericBox>
  );
}

export function productGroupDimCPC(d) {
  return (
    <GenericBox
      icon={icons.ProductGroup}
      name={d.productGroup}
      caption={d.productDimension}
      status={d.status}
    >
      <div align="right">
        <MaxCPC value={d.currentMaxCpc} old status={d.status} />
        <ArrowForward style={{ verticalAlign: "middle" }} />
        <MaxCPC value={d.maxCpc} status={d.status} />
        <CustomTypographyChip variant={"caption"} align={"right"}>
          Bid
        </CustomTypographyChip>
      </div>
    </GenericBox>
  );
}

export function addRemoveProductGroups(d) {
  const addRemoveCaption = d.negative ? "Remove" : "";
  const captionColor = d.negative ? "secondary" : "default";
  return (
    <GenericBox
      icon={icons.ProductGroup}
      name={d.productGroup}
      caption={d.productDimension}
    >
      <MaxCPC value={d.maxCpc} status={d.status} />
      <Typography color={captionColor}>{addRemoveCaption}</Typography>
    </GenericBox>
  );
}

export function urlAndText(d) {
  return (
    <Paper className={"fullWidth"}>
      <GenericBox
        icon={icons.Link}
        name={
          <div className={"flex alignItemsCenter"}>
            {d.keywordUrl ? (
              <span className={"flex"}>
                {d.keywordUrl}&nbsp;
                <a
                  target="_blank"
                  href={d.keywordUrl}
                  className={"flex alignItemsCenter"}
                  style={{ color: "inherit" }}
                >
                  <i className="fa fa-external-link" />
                </a>
              </span>
            ) : (
              ""
            )}
          </div>
        }
        caption={d.keywordText}
      />
    </Paper>
  );
}

export function adgroupBidModifier(d) {
  return (
    <GenericBox icon={icons.AdGroup} name={d.adGroupName}>
      {/*<BidModifier value={d.oldBidModifier} old ></BidModifier>*/}
      {/*<ArrowForward style={{verticalAlign:"middle"}}/>*/}
      {/*<BidModifier value={d.bidModifier}></BidModifier>*/}
      <BidModifiersChip
        label={<b>{d.bidModifier}%</b>}
        title={"Bid Modifier"}
      />
      <CustomTypographyChip variant={"caption"} align={"right"}>
        Bid Modifier
      </CustomTypographyChip>
    </GenericBox>
  );
}

export function adgroup(d) {
  return <GenericBox icon={icons.AdGroup} name={d.adGroupName} />;
}

export function Campaign(d) {
  return (
    <GenericBox
      icon={icons.Campaign}
      name={d.campaignName}
      caption={<TypeTitle type="Campaign" value={d.campaignType} />}
    />
  );
}

export function campaignNameTypeBudget(d) {
  return (
    <GenericBox
      icon={icons.Campaign}
      name={d.campaignName}
      caption={
        d.campaignType && d.campaignType != "" && d.campaignType != null ? (
          <TypeTitle type="Campaign" value={d.campaignType} />
        ) : null
      }
    >
      {/*<BudgetChip label={d.budget}/>*/}
      {d.oldBudget ? (
        <Fragment>
          <Budget value={d.oldBudget} old></Budget>
          <ArrowForward style={{ verticalAlign: "middle" }} />
        </Fragment>
      ) : null}
      <Budget value={d.budget}></Budget>
      <CustomTypographyChip variant={"caption"} align={"right"}>
        Budget
      </CustomTypographyChip>
    </GenericBox>
  );
}

export function campaignBidStrategyToMaxClicks(d) {
  const formatOptions = {
    precision: 2,
    locales: "en-US",
    currency: true,
    currencyIndicator: "USD",
  };
  return (
    <GenericBox icon={icons.Campaign} name={d.campaignName}>
      <div align="right">
        <BidModifiersChip
          label={
            <b>
              <CurrencySymbol formatOptions={formatOptions}>
                {d.maxCpc}
              </CurrencySymbol>
            </b>
          }
          title={"Max CPC Bid Limit"}
        ></BidModifiersChip>
        <CustomTypographyCPCBidChip variant={"caption"} align={"right"}>
          Bid Limit
        </CustomTypographyCPCBidChip>
      </div>
    </GenericBox>
  );
}

export const TableCellSchedule = withStyles((theme) => ({
  root: {
    padding: "4px 24px",
  },
}))(TableCell);

export function Schedule2({ taskDataList }) {
  let grouped = multiGroupBy(taskDataList, ["dayOfWeek", "startHour"]);
  let days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  let hours = _.chain(taskDataList)
    .map("startHour")
    .uniq()
    .value();
  let hourLabels = _.chain(taskDataList)
    .map(({ startHour, endHour }) => `${startHour} - ${endHour}`)
    .uniq()
    .value();
  const TableRow = TableRowAutoHeight;
  const TableCell = TableCellSchedule;
  return (
    <Paper style={{ width: "fit-content" }}>
      {
        <Table style={{ width: "auto" }}>
          <TableHead>
            <TableRow>
              <TableCell>Day</TableCell>
              {hourLabels.map((hourLabel) => (
                <TableCell numeric key={hourLabel}>
                  <Typography noWrap>{hourLabel}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {days.map((day) => (
              <TableRow key={day}>
                <TableCell>{day}</TableCell>
                {hours.map((hour) => (
                  <TableCell numeric key={hour}>
                    {day in grouped &&
                      hour in grouped[day] &&
                      grouped[day][hour][0].bidModifier}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      }
    </Paper>
  );
}

export function shoppingTask(d) {
  return (
    <GenericBox>
      <Typography component="p">
        We have not seen your presence in Google shopping platform.
      </Typography>
    </GenericBox>
  );
}

export function urlAndTextAds(d) {
  // const {adcopyData} = d;
  return (
    <Paper className={"fullWidth"}>
      <GenericBox
        icon={icons.Adcopy}
        name={
          <div>
            <Typography component={"div"}>{d.headline1}</Typography>
            <Typography component={"div"}>{d.headline2}</Typography>
            <Typography component={"div"}>{d.displayUrl}</Typography>
            <Typography component={"div"}>{d.description1}</Typography>
            <Typography component={"div"}>{d.description2}</Typography>
            {/*{d.finalUrl ?
                        <span className={"flex"}>{conciseURL(d.finalUrl)}&nbsp;
                            <a target="_blank" href={d.finalUrl} className={"flex alignItemsCenter"}
                               style={{color: "inherit"}}>
                                <i className="fa fa-external-link"/>
                            </a>
                        </span> : ""}*/}
          </div>
        }
        caption={d.keywordText}
      />
    </Paper>
  );
}

export function adCopyHierarchy(d) {
  const { adCopy, refAdCopy } = d;
  const domainUrl = getDomainUrl(refAdCopy);
  return (
    <Grid container>
      {d.status == 1 ? (
        <Grid item className={"fullWidth"} md={6}>
          {adCopy && adCopy.completedAs == "DIY" && (
            <AdCopyPreview
              headLine1={adCopy.details.headLine1}
              headLine2={adCopy.details.headLine2}
              headLine3={adCopy.details.headLine3}
              domainUrl={domainUrl}
              path1={adCopy.details.path1}
              path2={adCopy.details.path2}
              description1={adCopy.details.description1}
              description2={adCopy.details.description2}
            />
          )}
          {adCopy && adCopy.completedAs == "ServicePack" && (
            <Typography>Service Requested</Typography>
          )}
        </Grid>
      ) : (
        <Grid item className={"fullWidth"}>
          <AdCopyHierarchy taskData={d} />
        </Grid>
      )}
    </Grid>
  );
}

export function pauseAdCopyHierarchy(d) {
  const { refAdCopy } = d;
  const domainUrl = getDomainUrl(refAdCopy);

  return (
    <Grid container>
      <Grid>
        {refAdCopy != null ? (
          <AdCopyPreview
            headLine1={refAdCopy.headlinePart1}
            headLine2={refAdCopy.headlinePart2}
            path1={refAdCopy.path1}
            path2={refAdCopy.path2}
            domainUrl={domainUrl}
            description1={refAdCopy.description}
          />
        ) : null}
      </Grid>
    </Grid>
  );
}

export function addAdCopy(d) {
  const { adCopyContent } = d;
  const domainUrl = getDomainUrl(adCopyContent);

  return (
    <Grid container>
      <Grid>
        {adCopyContent != null ? (
          <AdCopyPreview
            headLine1={adCopyContent.headLine1}
            headLine2={adCopyContent.headLine2}
            headLine3={adCopyContent.headLine3}
            path1={adCopyContent.path1}
            path2={adCopyContent.path2}
            domainUrl={domainUrl}
            description1={adCopyContent.description1}
            description2={adCopyContent.description2}
            finalUrl={adCopyContent.finalUrl}
            gleRecommendation={d.analysisTypeId == 23}
          />
        ) : null}
      </Grid>
    </Grid>
  );
}


function UpgradeBiddingStrategyBox({
  name,
  caption,
  currStrategy,
  recommendedStrategy,
  targetValue,
  targetType,
  classes,
}) {
  const [newRecommendedTarget, setNewRecommendedTarget] = useState(targetValue);

  useEffect(() => {
    if (targetType === "TROAS") {
      setNewRecommendedTarget((prevTarget) => Math.floor(prevTarget * 100));
    }
  }, [targetValue, targetType]);

  return (
    <Fragment>
      <Grid container style={{ padding: 5 }} direction="column" rowSpacing={2}>
        <Grid
          item
          container
          xs={12}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ paddingBottom: "2%" }}
        >
          <Grid item className="campaignNameAndType">
            <Typography
              variant={"h4"}
              color="textPrimary"
              style={{ maxWidth: "auto", lineHeight: "2rem" }}
            >
              <b>{name}</b>
            </Typography>
          </Grid>
          <Grid item>{caption}</Grid>
        </Grid>

        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className="greyBorder greyBackground">
                  <Typography
                    color="#1E344E"
                    fontSize={"0.875rem"}
                    fontWeight={500}
                  >
                    Current Bid Strategy Type
                  </Typography>
                </TableCell>
                <TableCell className="greyBorder">
                  <Typography color="#1E344E" fontWeight={"bold"}>
                    {currStrategy}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  size="small"
                  sx={{ borderBottom: "none" }}
                ></TableCell>
              </TableRow>
            </TableBody>
            <TableBody>
              <TableRow>
                <TableCell className="greenBorder greenBackground">
                  <Typography
                    color="#00A850"
                    fontSize={"0.875rem"}
                    fontWeight={500}
                  >
                    Recommended Bid Strategy Type
                  </Typography>
                </TableCell>
                <TableCell align="left" className="greenBorder">
                  <Typography color="#1E344E" fontWeight={"bold"}>
                    {recommendedStrategy}
                  </Typography>
                </TableCell>
                <TableCell
                  align="right"
                  sx={{ borderBottom: "none" }}
                ></TableCell>
                <TableCell align="left" className="greenBorder greenBackground">
                  <Typography
                    color="#00A850"
                    fontSize={"0.875rem"}
                    fontWeight={500}
                  >
                    {targetType}
                  </Typography>
                </TableCell>
                <TableCell align="right" className="greenBorder">
                  <Typography color="#1E344E" fontWeight={"bold"} align="right">
                    {targetType === "TCPA" ? "$" : ""}
                    {addCommas(newRecommendedTarget)}
                    {targetType === "TROAS" ? "%" : ""}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Fragment>
  );
}



function IncreaseOrDecreaseTarget({
  taskTypeId,
  name,
  caption,
  currTarget,
  recommendedTarget,
}) {
  const [newRecommendedTarget, setNewRecommendedTarget] = useState(
    recommendedTarget
  );
  const [newCurrTarget, setNewCurrTarget] = useState(currTarget);
  const [currTargetText, setCurrTargetText] = useState();
  const [recommendedTargetText, setRecommendedTargetText] = useState();
  const [targetChange, setTargetChange] = useState();

  useEffect(() => {
    if (
      taskTypeId === TaskTypeEnum.INCREASE_TARGET_ROAS.taskTypeId ||
      taskTypeId === TaskTypeEnum.DECREASE_TARGET_ROAS.taskTypeId
    ) {
      setCurrTargetText("Current Target ROAS");
      setRecommendedTargetText("Recommended Target ROAS");
      setNewRecommendedTarget(
        (prevTarget) => addCommas(Math.floor(prevTarget * 100)) + "%"
      );
      setNewCurrTarget(
        (prevTarget) => addCommas(Math.floor(prevTarget * 100)) + "%"
      );
    } else {
      setCurrTargetText("Current Target CPA");
      setRecommendedTargetText("Recommended Target CPA");
      setNewRecommendedTarget((prevTarget) => "$" + addCommas(prevTarget));
      setNewCurrTarget((prevTarget) => "$" + addCommas(prevTarget) );
    }

    if (
      taskTypeId === TaskTypeEnum.INCREASE_TARGET_ROAS.taskTypeId ||
      taskTypeId === TaskTypeEnum.INCREASE_TARGET_CPA.taskTypeId
    ) {
      setTargetChange("increases");
    } else {
      setTargetChange("decreases");
    }
  }, [taskTypeId]);

  return (
    <Fragment>
      <Grid container style={{ padding: 5 }} direction="column" rowSpacing={2}>
        <Grid
          item
          container
          xs={12}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item className="campaignNameAndType">
            <Typography variant={"h4"} color="textPrimary" lineHeight={"2rem"}>
              <b>{name}</b>
            </Typography>
          </Grid>
          <Grid item>{caption}</Grid>
        </Grid>
        <Grid item>
          <TableContainer>
            <Table aria-label="simple table">
              <TableBody>
                <TableRow>
                  <TableCell className="greyBorder greyBackground">
                    <Typography
                      color="#1E344E"
                      fontSize={"0.875rem"}
                      fontWeight={500}
                    >
                      {currTargetText}
                    </Typography>
                  </TableCell>
                  <TableCell align="right" className="greyBorder">
                    <Typography color="#1E344E" fontWeight={"bold"}>
                      {newCurrTarget}
                    </Typography>
                  </TableCell>
                  <TableCell align="center" className="noBorder">
                    <EastIcon
                      className={
                         "orangeColor"
                      }
                    />
                  </TableCell>
                  <TableCell
                    align="left"
                    className="greenBorder greenBackground"
                  >
                    <Typography
                      color="#00A850"
                      fontSize={"0.875rem"}
                      fontWeight={500}
                    >
                      {recommendedTargetText}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="left"
                    className="greenBorder whiteRightBorder"
                  >
                    {targetChange === "increases" ? (
                      <ArrowUpwardIcon
                        fontSize="small"
                        sx={{ color: "#00A850" }}
                      />
                    ) : targetChange === "decreases" ? (
                      <ArrowDownwardIcon
                        fontSize="small"
                        sx={{ color: "#CF0505" }}
                      />
                    ) : (
                      ""
                    )}
                  </TableCell>
                  <TableCell align="right" className="greenBorder">
                    <Typography
                      color="#F58120"
                      fontWeight={"bold"}
                      align="right"
                    >
                      {newRecommendedTarget}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Fragment>
  );
}


export function UpgradeBiddingStrategy(data,classes) {
  function getRecommendationStrategy(currentTaskData) {
    console.log("currentTaskData=",data.taskTypeId);
    switch (data.taskTypeId) {
      case TaskTypeEnum.UPGRADE_MANUAL_BID_TO_TARGET_ROAS.taskTypeId:
        if (currentTaskData.campaignType === "Search") {
          return "Maximize Conversion Value";
        } else if (currentTaskData.campaignType === "Shopping") {
          return "Target ROAS";
        } else {
          return "Maximize Conversion Value";
        }
      case TaskTypeEnum.UPGRADE_MANUAL_BID_TO_TARGET_CPA.taskTypeId:
        return "Maximize Conversions";
      default:
        return "Maximize Conversions";
    }
  }
  return (
    <div>
      <Card className={classes.card}>
        <CardContent>
          <Grid container direction="row" rowSpacing={3}>
              <Grid item sm={12} xs={12} md={12} lg={12}>
                  <UpgradeBiddingStrategyBox
                    name={data.campaignName}
                    caption={
                      <NewTaskTypeTitle
                        type="Campaign"
                        value={data.campaignType}
                      />
                    }
                    currStrategy={data.customData}
                    recommendedStrategy={getRecommendationStrategy(data)}
                    targetValue={data.updatedTargetValue}
                    targetType={
                      data.taskTypeId ==
                      TaskTypeEnum.UPGRADE_MANUAL_BID_TO_TARGET_ROAS.taskTypeId
                        ? "TROAS"
                        : "TCPA"
                    }
                    classes={classes}
                  />
              </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}



export function IncreaseOrDecreaseTROAS(data, classes) {
  return (
      <Card className={classes.card}>
        <CardContent>
          <Grid container>
              <Grid item xs={12}>
                  <IncreaseOrDecreaseTarget
                    taskTypeId={data.taskTypeId}
                    name={data.campaignName}
                    caption={
                      <NewTaskTypeTitle
                        type="Campaign"
                        value={data.campaignType}
                      />
                    }
                    currTarget={data.currentTargetValue}
                    recommendedTarget={data.updatedTargetValue}
                    classes={classes}
                  />
              </Grid>
          </Grid>
        </CardContent>
      </Card>
  );
}