import { Grid, Hidden } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import React, { Fragment } from "react";
import expertservicesupport from "../../assets/img/expertservicesupport.png";
import googleadsgrader from "../../assets/img/googleadsgrader.png";
import guidedwalkthrough from "../../assets/img/guidedwalkthrough.png";
import jumpstart from "../../assets/img/jumpstart.png";
import toolonboarding from "../../assets/img/toolonboarding.png";
import s from "../../assets/img/S.png";
import reverses from "../../assets/img/reverseS.png";
import {
  OrangeTxt,
  OrangeTextSize12,
  HeadLineTypography,
  BlueTitleTypography,
  CatalinaBlue,
  SubHeadingTypo,
  GrayText,
} from "../../components/typography.jsx";
import { Typography } from "@mui/material/index";

import useMediaQuery from "@mui/material/useMediaQuery";

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="xl" />;

const styles = (theme) => ({
  GridPara: {
    [theme.breakpoints.up("xs")]: {
      lineHeight: "1.54",
      fontSize: 21,
      paddingLeft: 10,
      color: "#888888",
      textAlign: "center",
    },
    [theme.breakpoints.up("md")]: {
      lineHeight: "1.54",
      fontSize: 21,
      color: "#888888",
      textAlign: "initial",
    },

    // paddingLeft:'40px',
    // paddingRight:'40px',
  },
  GridHeader: {
    fontSize: "1.471rem",
    color: "#1E334E",

    [window.screen.width >= 1280]: {
      display: "none",
    },
  },
  GridImage: {
    textAlign: "center",
  },
  learnmorelinkstyle: {
    color: "#F58120",
  },
  root: {
    backgroundColor: "#ECEFF3",
    paddingTop: 40,
    paddingBottom: 40,
    textAlign: "center",
  },
  centerstyle: {
    textAlign: "center",
    paddingLeft: 86,
    paddingRight: 86,
    [theme.breakpoints.down("xl")]: {
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
  paddingLeftAndRight: {
    [theme.breakpoints.down("xl")]: {
      paddingTop: 10,
      paddingLeft: 10,
      paddingRight: 10,
      alignItems: "center !important",
    },
    [theme.breakpoints.up("sm")]: {
      paddingTop: 40,
      paddingLeft: 60,
      paddingRight: 60,
      alignItems: "center !important",
    },
  },
  dottedImage: {
    [theme.breakpoints.up("sm")]: {
      textAlign: "center",
      width: "65%",
    },
  },
  imagedottedlinestyle: {
    [theme.breakpoints.up("xs")]: {
      display: "none",
    },
    [theme.breakpoints.up("sm")]: {
      textAlign: "center",
      display: "block",
      width: "65%",
    },
    [theme.breakpoints.between("sm", "lg")]: {
      display: "none",
    },
  },
  SubHeadingTypoStyle: {
    fontSize: 30,
  },
  OrangeTxtStyle: {
    fontSize: 38,
  },
  heading: {
    color: "textPrimary",
    fontSize: "1.25rem",
    fontWeight: "700",
    [theme.breakpoints.up("md")]: {
      fontSize: "2.75rem",
    },
  },
});

function GrowthModel(props) {
  const classes = props.classes;
  const { width, theme } = props;
  const isTabletResponsive = useMediaQuery(
    theme.breakpoints.between("sm", "xl")
  );
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item md={12} xs={12}>
          <div className={classes.centerstyle}>
            <OrangeTextSize12 gutterBottom>GROWTH JOURNEY</OrangeTextSize12>
            <br />
            <Typography
              className={classes.heading}
              // color={"textPrimary"}
              // variant={width == "sm" || width == "xs" ? "h3" : "subtitle1"}
              // style={{ fontWeight: "700" }}
            >
              HOW DOES LXRGUIDE'S GROWTH MODEL WORK?
            </Typography>
            <br />
            <CatalinaBlue className={classes.FontStyle}>
              When you sign up for LXRGuide, you don’t just get a DIY tool… you
              subscribe to a proven system for growing your e-commerce sales.
              The LXRGuide Growth Model is tailored to small business marketers
              who want to take charge of their paid search campaigns, but would
              appreciate expert guidance and insights as they scale their
              campaigns.
            </CatalinaBlue>

            <OrangeTxt>
              {" "}
              Here’s What Your Growth Journey Would Look Like:
            </OrangeTxt>
            <br />
            <br />
          </div>
        </Grid>
      </Grid>

      <Grid container className={classes.paddingLeftAndRight}>
        <Grid className={classes.GridImage} item md={4} xs={12}>
          {width == "sm" || width == "xs" ? (
            <Fragment>
              <br />
              <br />
            </Fragment>
          ) : null}
          <img src={googleadsgrader} />
        </Grid>
        <Grid item md={8} xs={12} className={classes.GridPara}>
          {width == "sm" || width == "xs" ? <br /> : null}
          <SubHeadingTypo
            gutterBottom={width == "sm" || width == "xs" ? true : false}
          >
            Initial Campaign Diagnostics
          </SubHeadingTypo>
          {width != "sm" && width != "xs" ? <br /> : null}
          <CatalinaBlue className={classes.FontStyle}>
            Through a combination of our powerful Google Ads Grader and expert
            evaluation of your account structure, we identify gaps and growth
            opportunities. Your results are compiled in a scorecard of your
            current campaign. This stage will help you understand where your
            Google Campaign currently stands and also provide you a game plan
            for revenue growth.
          </CatalinaBlue>
          {/* <p ><a className={classes.learnmorelinkstyle} href="">LEARN MORE</a></p> */}
        </Grid>
        <Grid className={classes.imagedottedlinestyle} md={12}>
          <img src={s} className={classes.dottedImage} />
        </Grid>
        <Hidden only={["md", "lg", "xl"]}>
          <Grid item md={4} xs={12} className={classes.GridImage}>
            <br />
            <br />
            <img src={jumpstart} />
          </Grid>
        </Hidden>
        <Grid item md={8} xs={12} className={classes.GridPara}>
          {width == "sm" || width == "xs" ? <br /> : null}
          <SubHeadingTypo
            gutterBottom={width == "sm" || width == "xs" ? true : false}
          >
            Account Onboarding and Product Training by an Expert Customer
            Success Manager
          </SubHeadingTypo>
          {width != "sm" && width != "xs" ? <br /> : null}
          <CatalinaBlue className={classes.FontStyle}>
            {" "}
            We understand that business managers who use LXRGuide have limited
            time to manage the complexities of paid search. Unlike any other DIY
            Paid Search Tool, the LXRGuide solution includes dedicated
            onboarding support and product training by an expert customer
            success manager. Having a dedicated customer success manager ensures
            you're not bouncing around from one service rep to another. Your
            questions will always be addressed promptly and effectively. We are
            your growth partner.
          </CatalinaBlue>
          {/* <p ><a className={classes.learnmorelinkstyle} href="">LEARN MORE</a></p> */}
        </Grid>
        {/*  <Hidden only={['sm', 'lg']}>
                    <Paper className={classes.paper}>Hidden on sm and lg</Paper>
                </Hidden>*/}
        <Hidden only={["sm", "xs"]}>
          <Grid item md={4} xs={12} className={classes.GridImage}>
            <img src={jumpstart} />
          </Grid>
        </Hidden>
        <Grid className={classes.imagedottedlinestyle} md={12}>
          <img src={reverses} className={classes.dottedImage} />
        </Grid>

        <Grid item md={4} xs={12} className={classes.GridImage}>
          {width == "sm" || width == "xs" ? (
            <Fragment>
              <br />
              <br />
            </Fragment>
          ) : null}
          <img src={toolonboarding} />
        </Grid>
        <Grid item md={8} xs={12} className={classes.GridPara}>
          {width == "sm" || width == "xs" ? <br /> : null}
          <SubHeadingTypo
            gutterBottom={width == "sm" || width == "xs" ? true : false}
          >
            Jumpstart Your Paid Search Growth
          </SubHeadingTypo>
          {width != "sm" && width != "xs" ? <br /> : null}
          <CatalinaBlue className={classes.FontStyle}>
            {" "}
            We have analyzed over 10,000 paid search accounts to build an
            account framework for growth campaigns. During the one-month long
            jumpstart phase, our experts apply this framework to streamline your
            paid search account and set you up for success.
          </CatalinaBlue>
          {/* <p ><a className={classes.learnmorelinkstyle} href="">LEARN MORE</a></p> */}
        </Grid>
        <Grid className={classes.imagedottedlinestyle} md={12}>
          <img src={s} className={classes.dottedImage} />
        </Grid>
        <Hidden only={["md", "lg", "xl"]}>
          <Grid item md={4} xs={12} className={classes.GridImage}>
            <br />
            <br />
            <img src={guidedwalkthrough} />
          </Grid>
        </Hidden>
        <Grid item md={8} xs={12} className={classes.GridPara}>
          {width == "sm" || width == "xs" ? <br /> : null}
          <SubHeadingTypo
            gutterBottom={width == "sm" || width == "xs" ? true : false}
          >
            Ongoing Campaign Management & Reporting{" "}
          </SubHeadingTypo>
          {width != "sm" && width != "xs" ? <br /> : null}
          <CatalinaBlue className={classes.FontStyle}>
            {" "}
            Execute on personalized, real-time optimization strategies
            recommended by LXRGuide’s high-powered algorithms to profitably grow
            your paid search orders and revenue. Sustainable growth can be
            driven only through holistic management of your SEO and Paid Search
            Programs. That’s why LXRGuide also generates a monthly SEO scorecard
            to help you identify opportunities for ranking highly on search
            results.
          </CatalinaBlue>
          {/* <p ><a className={classes.learnmorelinkstyle} href="">LEARN MORE</a></p> */}
        </Grid>
        <Hidden only={["sm", "xs"]}>
          <Grid item md={4} xs={12} className={classes.GridImage}>
            <img src={guidedwalkthrough} />
          </Grid>
        </Hidden>
        <Grid className={classes.imagedottedlinestyle} md={12}>
          <img src={reverses} className={classes.dottedImage} />
        </Grid>

        <Grid item md={4} xs={12} className={classes.GridImage}>
          {width == "sm" || width == "xs" ? (
            <Fragment>
              <br />
              <br />
            </Fragment>
          ) : null}
          <img src={expertservicesupport} />
        </Grid>
        <Grid item md={8} xs={12} className={classes.GridPara}>
          {width == "sm" || width == "xs" ? <br /> : null}
          <SubHeadingTypo
            gutterBottom={width == "sm" || width == "xs" ? true : false}
          >
            Comprehensive Reporting and Growth Consultation Sessions{" "}
          </SubHeadingTypo>
          {width != "sm" && width != "xs" ? <br /> : null}
          <CatalinaBlue className={classes.FontStyle}>
            {" "}
            Gain actionable insights into your account performance through a
            comprehensive suite of reports and charts. During monthly Growth
            Consultation Sessions, your customer success manager will share
            unique insights and powerful strategies to profitably grow your paid
            search program.
          </CatalinaBlue>
          {/* <p ><a className={classes.learnmorelinkstyle} href="">LEARN MORE</a></p> */}
        </Grid>
      </Grid>
    </div>
  );
}

export default withWidth()(
  withStyles(styles, { withTheme: true })(GrowthModel)
);
