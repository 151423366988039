import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Card, CardContent, Grid, Paper, Typography } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import icons from "../icons";
import { OutLinedBlackBtn, OutLinedRectBlackBtn } from "../buttons";
import { IconAndNameError, ReportTitle } from "../siteGrader/templates";
import classNames from "classnames";

let moment = require("moment");

const styles = (theme) => ({
  title: {
    fontFamily: "Montserrat",
  },
  paper: {
    padding: theme.spacing(2),
    boxShadow: "unset",
  },
  customCard: {
    [theme.breakpoints.down('xl')]: {
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
    boxShadow: "unset",
    backgroundColor: theme.palette.graderBg,
    width: "100%",
  },
  mainTitle: {
    fontWeight: "800",
    fontFamily: "Montserrat",
  },
  mainDiv: {
    padding: theme.spacing(3),
  },
  headTitle: {
    fontSize: 30,
    fontFamily: "Montserrat",
    color: theme.palette.unSuccess,
    fontWeight: "500",
  },
  caption: {
    fontFamily: "Lato",
    fontSize: 16,
  },
  para: {
    fontFamily: "Lato",
  },
});

class ErrorPage extends React.PureComponent {
  render() {
    const { classes, siteGrader, handleRefresh } = this.props;
    const url =
      this.props.siteGrader != null ? this.props.siteGrader.url : "DomainUrl";
    const fetchDate =
      this.props.siteGrader != null && this.props.siteGrader.fetchDate
        ? this.props.siteGrader.fetchDate
        : 0;
    let today = moment().format("YYYY-MM-DD");

    return (
      <Card className={classes.customCard}>
        <CardContent>
          <Grid container>
            <Grid item md={6} sm={12}>
              <Typography
                component={"h5"}
                variant={"h5"}
                color={"textSecondary"}
                className={classes.mainTitle}
              >
                <b>YOUR WEBSITE’S SEO SCORECARD</b>
              </Typography>
              <br />
            </Grid>
            <Grid item md={6} sm={12}>
              <OutLinedBlackBtn
                disabled={
                  (siteGrader &&
                    siteGrader.userId != null &&
                    siteGrader.userId == 2) ||
                  moment(today).diff(moment(fetchDate).format("YYYY-MM-DD")) ==
                    0
                }
                onClick={handleRefresh}
              >
                Refresh
              </OutLinedBlackBtn>
            </Grid>
            <Grid item md={12}>
              <Grid container spacing={2}>
                <Grid item>
                  <Typography
                    className={classes.headTitle}
                    style={{ maxWidth: "auto" }}
                  >
                    Unexpected Error
                  </Typography>
                  <Typography
                    color="inherit"
                    variant={"caption"}
                    className={classes.caption}
                  >
                    {url}
                  </Typography>
                </Grid>
              </Grid>
              <br />
              <br />
              <br />
            </Grid>
            <Grid item md={6}>
              <Paper className={classes.paper}>
                <Typography
                  align={"left"}
                  className={classes.title}
                  color={"textSecondary"}
                >
                  <b>Error Report</b>
                </Typography>
                <br />
                <br />
                <div className={classes.mainDiv}>
                  <Typography>{icons.errorWarning}</Typography>
                  <Typography
                    variant={"subhead"}
                    color={"textSecondary"}
                    className={classes.para}
                  >
                    The website encountered an unexpected error. Unable to
                    generate SEO Scorecard. Please try again later.
                  </Typography>
                  <br />
                  <br />
                  <br />
                  <a
                    href={"/dashboard.html"}
                    style={{ textDecoration: "none" }}
                  >
                    <OutLinedRectBlackBtn size={"large"}>
                      {" "}
                      Back to Dashboard
                    </OutLinedRectBlackBtn>
                  </a>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

ErrorPage.propTypes = {};

export default withStyles(styles)(ErrorPage);
