import React, { Component } from "react";
import PropTypes from "prop-types";
import { MediaObject } from "./templates";
import { Grid } from "@mui/material";
import { googleAdMetrics, getMetricsValues } from "./templates";

class OpportunityScoreCard extends React.PureComponent {
  render() {
    console.log("this.props.data=", this.props.data);
    return (
      <Grid container spacing={2}>
        {googleAdMetrics.map((title) => (
          <Grid item md={6}>
            <MediaObject
              title={title}
              value={
                this.props.data != null
                  ? getMetricsValues(this.props.data, title, "value")
                  : 0
              }
              color={
                this.props.data != null
                  ? getMetricsValues(this.props.data, title, "color")
                  : "red"
              }
              content={
                this.props.data != null
                  ? getMetricsValues(this.props.data, title, "content")
                  : null
              }
              data={this.props.data != null ? this.props.data : null}
            />
          </Grid>
        ))}
      </Grid>
    );
  }
}

OpportunityScoreCard.propTypes = {};

export default OpportunityScoreCard;
