import {
  CustomTableCellHead,
  SelectedTableCell,
} from "../accountSettings/account-settings";
import { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import accountSettingsStyles from "../../assets/jss/accountSettings/accountSettingStyle";
import { Grid, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getPaymentDetails } from "../../actions/fetchingPaymentHistory";
import moment from "moment";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { CircularProgress, TableCell } from "@mui/material/index";

export const CustomTableCell = withStyles((theme) => ({
  body: {
    background: "rgba(255, 255, 255, 0.6)",
    border: "1px solid #E3E6F4",
    boxSizing: "border-box",
    color: theme.palette.text.primary,
    fontWeight: "bold",
    fontSize: theme.typography.pxToRem(16),
    fontFamily: "Lato",
    padding: 10,
  },
}))(TableCell);

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onPageChange, classes } = props;

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  return (
    <div>
      <IconButton
        style={{
          color: page !== 0 ? "#f58120" : "#808080",
          fontWeight: "bold",
          fontSize: "1rem",
          fontFamily: "Lato",
        }}
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
        size="large"
      >
        <KeyboardArrowLeft />
        <Typography
          component={"span"}
          color={"textPrimary"}
          style={{
            color: page !== 0 ? "#f58120" : "#808080",
            fontWeight: "bold",
          }}
        >
          Previous
        </Typography>
      </IconButton>
      <span
        style={{
          color: "#f58120",
          fontWeight: "bold",
          fontSize: "1rem",
          fontFamily: "Lato",
        }}
      >
        |
      </span>
      <IconButton
        style={{
          color:
            page >= Math.ceil(count / rowsPerPage) - 1 ? "#808080" : "#f58120",
          fontWeight: "bold",
          fontSize: "1rem",
          fontFamily: "Lato",
        }}
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        size="large"
      >
        <Typography
          component={"span"}
          style={{
            color:
              page >= Math.ceil(count / rowsPerPage) - 1
                ? "#808080"
                : "#f58120",
            fontWeight: "bold",
          }}
        >
          Next
        </Typography>
        <KeyboardArrowRight />
      </IconButton>
    </div>
  );
}

const CustomTablePagination = withStyles((theme) => ({
  toolbar: {
    justifyContent: "space-between",
  },
 displayedRows:{
  fontWeight: "bold",
  },
  spacer: {
    display: "none",
    flex: 0,
  },
  caption: {
    color: theme.palette.text.primary,
    fontWeight: "bold",
    fontSize: theme.typography.pxToRem(16),
    fontFamily: "Lato",
  },
  
}))(TablePagination);

function PaymentHistory(props) {
  const { classes, paymentHistory } = props;
  const { isLoading } = paymentHistory;
  console.log("paymentHistory", paymentHistory);

  const count = paymentHistory.transactionHistoryList
    ? paymentHistory.transactionHistoryList.length
    : 0;

  const [state, setState] = useState({
    page: 0,
    rowsPerPage: 10,
  });

  useEffect(() => {
    document.title = "LXRGuide - Payment History";
    props.getPaymentDetails();
  }, []);

  const handlePageChange = (e, newPage) => {
    console.log("newPage", newPage);
    setState((prevState) => ({
      ...prevState,
      page: newPage,
    }));
  };

  const handlePackages = (historyIntent, amount) => {
    let intent = "";
    if (
      historyIntent == "CTP" &&
      (amount == "1999.00" || amount == "2500.00")
    ) {
      intent = "JumpStart";
    } else if (historyIntent == "CTP" && amount == "500.00") {
      intent = "Standard";
    } else {
      intent = historyIntent;
    }
    return intent;
  };

  const handleModeOfPayments = (transactionId, historyIntent, message) => {
    let intent = "";
    if (
      transactionId == null &&
      (historyIntent == "CTP" ||
        historyIntent == "JumpStart" ||
        message == "Charging amount from CTP Fund")
    ) {
      intent = "CTP";
    } else if (transactionId == null) {
      intent = "-";
    } else if (
      transactionId != null &&
      message == "Charging amount from CTP Fund and Card"
    ) {
      intent = "CTP + Credit card";
    } else {
      intent = "Credit card";
    }
    return intent;
  };

  return (
    <Fragment>
      {isLoading ? (
        <div
          className={"flex alignItemsCenter justifyCenter"}
          style={{ height: "70%" }}
        >
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <Fragment>
          {count > 0 ? (
            <Card className={classes.card}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item md={12} lg={12} xl={12}>
                    <br />
                    <br />
                  </Grid>
                  <Grid item md={12} lg={12} xl={12}>
                    <br />
                    <Paper
                      className={classes.paper}
                      style={{ padding: 32, width: "100%" }}
                    >
                      <Table className={classes.table}>
                        <TableHead>
                          <TableRow>
                            <CustomTableCellHead>Date</CustomTableCellHead>
                            <CustomTableCellHead>
                              Transaction Id
                            </CustomTableCellHead>
                            <CustomTableCellHead>Package</CustomTableCellHead>
                            <CustomTableCellHead>
                              Total Amount
                            </CustomTableCellHead>
                            <CustomTableCellHead>Status</CustomTableCellHead>
                            <CustomTableCellHead>
                              Mode Of Payment
                            </CustomTableCellHead>
                            <CustomTableCellHead>Download</CustomTableCellHead>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {(paymentHistory.transactionHistoryList
                            ? paymentHistory.transactionHistoryList.slice(
                                state.page * state.rowsPerPage,
                                state.page * state.rowsPerPage +
                                  state.rowsPerPage
                              )
                            : paymentHistory.transactionHistoryList
                          ).map((transaction, i) => (
                            // var date = moment(new Date(transaction.endTime.year, transaction.endTime.month, transaction.endTime.dayOfMonth, transaction.endTime.hourOfDay, transaction.endTime.minute, transaction.endTime.second)).format("DD-MM-YYYY");
                            // console.log("transaction date", date);
                            <TableRow>
                              <CustomTableCell>
                                {moment(transaction.endTime).format(
                                  "MM-DD-YYYY"
                                )}
                              </CustomTableCell>
                              <CustomTableCell>
                                {transaction.transactionId != null &&
                                transaction.transactionId != ""
                                  ? transaction.transactionId
                                  : "--"}
                              </CustomTableCell>
                              <CustomTableCell>
                                {handlePackages(
                                  transaction.intent,
                                  transaction.amount
                                )}
                              </CustomTableCell>
                              <CustomTableCell>
                                {transaction.amount.toFixed(2)}
                              </CustomTableCell>
                              <CustomTableCell>
                                {transaction.transactionStatus}
                              </CustomTableCell>
                              <CustomTableCell>
                                {handleModeOfPayments(
                                  transaction.transactionId,
                                  transaction.intent,
                                  transaction.message
                                )}
                              </CustomTableCell>
                              <SelectedTableCell>
                                <a
                                  href={
                                    "/download-payment-history.html?transactionId=" +
                                    `${transaction.transactionHistoryId}`
                                  }
                                  style={{
                                    color: "inherit",
                                    textDecoration: "none",
                                  }}
                                >
                                  PDF
                                </a>
                              </SelectedTableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <CustomTablePagination
                              rowsPerPageOptions={[]}
                              labelDisplayedRows={({ from, to, count }) =>
                                `Showing ${from} to ${to} entries`
                              }
                              count={count}
                              rowsPerPage={state.rowsPerPage}
                              page={state.page}
                              onPageChange={(e, page) =>
                                handlePageChange(e, page)
                              }
                              ActionsComponent={(
                                count,
                                page,
                                rowsPerPage,
                                onPageChange
                              ) =>
                                TablePaginationActions(
                                  count,
                                  page,
                                  rowsPerPage,
                                  onPageChange,
                                  classes
                                )
                              }
                            />
                          </TableRow>
                        </TableFooter>
                      </Table>
                      <br />
                      <br />
                    </Paper>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          ) : (
            <Typography align={"center"} variant={"body1"}>
              {" "}
              No Payment History Available{" "}
            </Typography>
          )}
        </Fragment>
      )}
    </Fragment>
  );
}
const mapStateToProps = (state) => ({
  paymentHistory: state.paymentHistory,
});
const mapDispatchToProps = (dispatch) => ({
  getPaymentDetails: () => dispatch(getPaymentDetails()),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(accountSettingsStyles, { withTheme: true })(PaymentHistory));
