import React, { useEffect } from "react";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  
  
} from "@mui/material";

import { withStyles } from "@mui/styles";
import { Helmet } from "react-helmet";
import icons from "../../components/icons";
import SixMetrics from "../googleAdsGrader/six-metrics";
import { OverviewSection } from "../googleAdsGrader/googleAdHome";
import * as types from "../../actions/actionTypes";
import { connect } from "react-redux";
import googleAdsGraderStyles from "../../assets/jss/googleAdsGrader/googleAdsGraderStyle";
import WhsUserDetailsForm from "./whsUserDetailsForm";
const withWidth = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="xl" />;

let id = 1;

function createWHSData(img, head, title, para, para2, bg, leftAlign) {
  id += 1;
  return { id, img, head, title, para, para2, bg, leftAlign };
}

export const whsExternalData = [
  createWHSData(
    "ExternalDiscoverability",
    "Comprehensive analysis and recommendations on four factors that can help you get found on Search Engines",
    "Discoverability",
    "Discoverability of your website health scorecard focuses on how effortlessly users can locate the information they need on your site. It evaluates key elements like robots.txt, sitemap.xml, structured data (JSON-LD), social media links, and top keywords to ensure a smooth and efficient user experience.",
    null,
    true,
    false
  ),
  createWHSData(
    "ExternalPerformance",
    null,
    "Performance",
    "Step into the Performance of your website health scorecard, your speed gauge for a fast and efficient website. We measure loading speed to deliver a seamless browsing experience, with a 2-3 second target to keep users engaged. We assess critical factors like URL Redirects, Render Blocking Resources, Server Response, JS and CSS Minification, Website Compression, First Contentful Paint (FCP), and Largest Contentful Paint (LCP).",
    null,
    false,
    true
  ),
  createWHSData(
    "ExternalAccessibility",
    null,
    "Accessibility",
    "Accessibility is about creating a digital space that welcomes everyone, regardless of their abilities. It is a commitment to features like text alternatives, keyboard navigation, and color contrast to ensure universal access to content and functionality. We evaluate key elements like Cumulative Layout Shift (CLS), Font Size, Layout Shift Elements, Broken Links, Use of iFrame, and Breadcrumbs to guarantee a website that is open and accommodating to all.",
    null,
    true,
    false
  ),
  createWHSData(
    "ExternalSecurity",
    null,
    "Security",
    "Security is the digital fortress safeguarding your website and its users from unauthorized access, data breaches, and cyber threats. It evaluates key parameters like SSL/TSL Certificates, Privacy Policy Page, HTTP links on HTTPS Pages, Security Header Availability, Strict Security Policy, and Toxic Backlinks, safeguarding your website and users against potential cyber-attacks and malicious activities, making sure your website is a safe and secure platform for its users.",
    null,
    false,
    true
  ),
];

function WhsHomepage(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
    props.seoSiteGraderPageTrack();
  }, []);

  const { classes, width } = props;

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent} style={{ padding: 0 }}>
        <Helmet>
          <title>
            Website Health Scorecard | Free Website Health Scorecard Tool |
            LXRGuide
          </title>
          <meta
            name="description"
            content="Free Website Health Checker. Measure and improve your website's health with our comprehensive scorecard tool. Evaluate discoverability, security, accessibility, and performance to optimize your online presence. Get actionable insights for a better website experience."
          />
        </Helmet>
        <Grid container>
          <Grid item md={12} className={classes.sectionBg}>
            <OverviewSection
              classes={classes}
              icon={icons.ExternalWHSIcon}
              pageCaption={"WEBSITE HEALTH SCORECARD"}
              title={"MAXIMIZE YOUR WEBSITE'S PERFORMANCE"}
              para={
                "LXRGuide’s Website Health Scorecard tool gives you a free technical audit report of your website in under 5 minutes. " +
                "Unleash the power of digital optimization to elevate your Google rankings and make a significant impact online."
              }
              width={width}
            >
              <WhsUserDetailsForm />
            </OverviewSection>
          </Grid>
          <Grid item md={12}>
            <SixMetrics
              classes={classes}
              data={whsExternalData}
              isWHSExternal={true}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

const mapDispatchToProps = (dispatch) => ({
  seoSiteGraderPageTrack: () =>
    dispatch({ type: types.GOOGLE_ADS_GRADER_HOME_TRACK }),
});

export default connect(
  null,
  mapDispatchToProps
)(withWidth()(withStyles(googleAdsGraderStyles)(WhsHomepage)));
