import React, { Fragment, useEffect, useState } from "react";
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  DialogTitle,
} from "@mui/material";
import {
  NewTextRectOrangeTextBtn,
  NewOutLinedRectBlueBtn,
} from "../../components/buttons";
import { FormControl } from "@mui/material/index";
import { InputFields } from "../../components/input-forms";
import {
  ErrorTypography,
  SuccessTypography,
} from "../../components/typography";

function PromoCodeForm(props) {
  const { classes, userDetails } = props;

  // const [state, setState] = useState({
  //   target: "settings",
  //   successMessage: "",
  //   promocodePopupType: "",
  // });


  const [target,setTarget]=useState("settings")
  const [successMessage,setSuccessMessage]=useState("")
  const [promocodePopupType,setPromocodePopupType]=useState("")
  const [promoCodeDialog, setPromoCodeDialog] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [promoCodeError, setPromoCodeError] = useState("");
  const [commonError, setCommonError] = useState("");

  // const { promocodePopupType } = state;
  const { userInfo } = userDetails;

  useEffect(() => {
    setPromoCode("");
    setPromoCodeDialog(false);
    setPromoCodeError("");
    setCommonError("");
  }, []);

  const handlePromoCodeAdding = (promocodePopupType) => {
    
    setPromoCodeDialog(true);
    // setState((prevState) => ({
    //   ...prevState,
    //   promocodePopupType: promocodePopupType,
    // }));
    setPromocodePopupType(promocodePopupType);
  };

  const handleClosePromoCodePop = (event) => {
    setPromoCodeDialog(false);
    setPromoCode("");
    setPromoCodeError("");
    setCommonError("");
  };

  const validPromoCodeDetails = () => {
   
    let isError = false;
    const errors = {};
    console.log("The promcode inside the validPromoCodeDetails", promoCode);
    if (promoCode == "" && promoCode.length == 0) {
      isError = true;
      errors.promoCodeError = "This field is required";
    } else {
      errors.promoCodeError = "";
    }
    setPromoCodeError(errors.promoCodeError);
    // setState({
    //   ...state,
    //   // ...errors,
    // });
    console.log("The errors and error are", errors, isError);
    return isError;
  };
  const handleSubmit = async (event) => {
   
    event.preventDefault({});
    console.log("The promcode inside the handle submit", promoCode);
    let error = validPromoCodeDetails();
    console.log("The promoCode and error After validation", promoCode, error);
   
    if (!error) {
      // const { promoCode, target } = state;
      // console.log("The promoCode and target are", state);
      let response = await props.UpdateUserPromoCodeDetails({
        promoCode: promoCode,
        target: target,
      });
      console.log("The response is", response);
      if (
        response != null &&
        response.error &&
       
        response.error != "" &&
        response.error != "done"
      ) {
        setCommonError(response.error);
        // setState((prevState) => ({ ...prevState, successMessage: "" }));
        setSuccessMessage("");
      } else if (
        response != null &&
        response.error &&
        response.error == "done"
      ) {
        setCommonError("");
        // setState((prevState) => ({
        //   ...prevState,
        //   successMessage: "Promo code applied successfully",
        // }));
        setSuccessMessage("Promo code applied successfully")
      }
    }
  };

  const promoCodeApplied = (classes, promoCodeObject) => {
    return (
      <Fragment>
        <DialogTitle style={{ textAlign: "center" }}>
          {"Promo Code Applied"}
        </DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <Typography color={"textPrimary"} variant={"body1"} gutterBottom>
            <b>Your Promo code "{promoCodeObject.promoCode}" is applied.</b>
          </Typography>
          <br />
          <Typography color={"textPrimary"} variant={"body1"} gutterBottom>
            <b>{promoCodeObject.displayText}</b>
          </Typography>
        </DialogContent>
        <DialogActions>
          <NewTextRectOrangeTextBtn onClick={handleClosePromoCodePop}>
            Cancel
          </NewTextRectOrangeTextBtn>
        </DialogActions>
      </Fragment>
    );
  };
  const applyPromoCode = (classes, promoCode) => {
    return (
      <form onSubmit={handleSubmit}>
        <DialogTitle>{"Promo Code"}</DialogTitle>
        <DialogContent>
          <Typography color={"textPrimary"} variant={"body1"} gutterBottom>
            <b>Enter Promo code here</b>
          </Typography>
          <FormControl className={classes.formControl}>
            <InputFields
              value={promoCode}
              onChange={(event) => setPromoCode(event.target.value)}
              margin="normal"
              variant="outlined"
              error={promoCodeError !== ""}
              helperText={promoCodeError}
              style={{ marginTop: 0 }}
              className={classes.inputFieldStyle}
            />
          </FormControl>
          {commonError != "" ? (
            <ErrorTypography>{commonError}</ErrorTypography>
          ) : null}
          {successMessage != "" ? (
            <SuccessTypography style={{ fontSize: "0.95rem" }}>
              {successMessage}
            </SuccessTypography>
          ) : null}
        </DialogContent>
        <DialogActions>
          <NewTextRectOrangeTextBtn onClick={handleClosePromoCodePop}>
            Cancel
          </NewTextRectOrangeTextBtn>
          <NewTextRectOrangeTextBtn
            type="submit"
            autoFocus
            disabled={promoCode == null}
          >
            Save
          </NewTextRectOrangeTextBtn>
        </DialogActions>
      </form>
    );
  };
  return (
    <Fragment>
      <Grid container>
        <Grid item sm={8} xs={8} md={5}>
          <Typography gutterBottom>
            {(userInfo.promoCode == null || userInfo.promoCode == "") &&
            userInfo.userType != true ? (
              <NewOutLinedRectBlueBtn
                className={"fullWidth"}
                variant={"outlined"}
                onClick={() => handlePromoCodeAdding("applyPromocode")}
              >
                Apply Promo Code
              </NewOutLinedRectBlueBtn>
            ) : (
              <NewOutLinedRectBlueBtn
                className={"fullWidth"}
                variant={"outlined"}
                onClick={() => handlePromoCodeAdding("promocodeApplied")}
              >
                Promo Code Applied
              </NewOutLinedRectBlueBtn>
            )}
          </Typography>
        </Grid>
      </Grid>
      <Dialog
        open={promoCodeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={handleClosePromoCodePop}
      >
        {promocodePopupType == "applyPromocode" &&
          applyPromoCode(classes, promoCode)}
        {promocodePopupType == "promocodeApplied" &&
          promoCodeApplied(classes, userInfo.promoCode)}
      </Dialog>
    </Fragment>
  );
}
export default PromoCodeForm;
