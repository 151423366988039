const accountSettingsStyles = (theme) => ({
  paper: {
    // background: theme.palette.common.white,
    boxShadow: "4px 4px 18px rgba(0, 0, 0, 0.06)",
    borderRadius: 6,
    padding: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      width: 730,
      // overflowX: "scroll",
    },
    [theme.breakpoints.up("lg")]: {
      width: 916,
      // overflowX: "scroll",
    },
    [theme.breakpoints.up("xl")]: {
      width: 1248,
      // overflowX: "scroll",
    },
  },
  card: {
    background: "none",
    boxShadow: "unset",
  },
  grow: {
    flexGrow: 1,
  },
  mb: {
    marginBottom: theme.spacing(2),
  },
  hyperLink: {
    cursor: "pointer",
  },
  clientFormControl: {
    width: 330,
    height: 33,
    boxSizing: "border-box",
    borderRadius: 6,
    paddingBottom: "8%",
    marginBottom: 20,
    marginTop: 5,
  },
  clientFields: {
    paddingTop: "1%",
    paddingBottom: "1%",
  },
  formControl: {
    width: "65%",
  },
  mb2: {
    marginBottom: "0.35em",
  },
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    // color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  // AddButton: {
  //   display: "flex",
  //   justifyContent: "end",
  // },
  table: {
    [theme.breakpoints.down("md")]: {
      width: 400,
      // overflow:"scroll",
      // overflowX:"scroll"
    },
    // [theme.breakpoints.up('sm')]: {
    //     margin: theme.spacing(2),
    //     // margin: theme.spacing(1),
    //
    // },
    // [theme.breakpoints.down('md')]: {
    //    width:400,
    //     overflow:"scroll",
    //     // overflowX:"scroll"
    // },
  },
});

export default accountSettingsStyles;
