import { Hidden } from "@mui/material";

const drawerWidth = 240;
const styles = (theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    background: theme.palette.common.white,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: "0px 2px 14px rgba(0, 0, 0, 0.05)",
  },
  toolBar: {
    minHeight: "27vh",
  },
  appBarShift: {
    [theme.breakpoints.down('lg')]: {
      width: `100%`,
      marginLeft: 0,
    },
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    zIndex: "1300",
  },
  drawerPaper: {
    background: theme.palette.text.primary,
  },
  drawerOpen: {
    // overflow:Hidden,
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX:"hidden",
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    // width: theme.spacing(7),
    [theme.breakpoints.up("lg")]: {
      width: theme.spacing(8),
    },
  },

  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
  },
  grow: {
    flexGrow: 1,
  },
  sectionDesktop: {
    display: "flex",
    /*    display: 'none',
            [theme.breakpoints.up('md')]: {
                display: 'flex',
            },*/
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  //Selection controls css
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  margin: {
    margin: theme.spacing(1),
  },
  menuSelectSpace: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
    minHeight: "8vh",
    textAlign: "left",
  },
  accountIcon: {
    color: theme.palette.text.primary,
  },
  bell: {
    color: theme.palette.text.primary,
  },
  customListItem: {
    marginLeft: theme.spacing(1),
  },
  menuPaper: {
    background: theme.palette.text.primary,
    // opacity: 0.5
  },
  menuBurger: {
    color: theme.palette.text.primary,
    opacity: 0.5,
  },
  menuBtn: {
    [theme.breakpoints.down('xl')]: {
      display: "block",
    },
    [theme.breakpoints.up("sm")]: {
      display: "flex",
    },
  },
  mobileMenu: {
    padding: theme.spacing(1),
  },
  linkText: {
    fontWeight: "bold",
    fontSize: "12px",
    textTransform: "uppercase",
    backgroundColor: "#1E334E",
    color: "rgba(255, 255, 255, 0.3)",
    margin: "4%",
  },
  iconComponentStyle: {
    color: "#ffffff !important",
  },
  link: {
    textDecoration: "unset",
  },
  imageAlignment: {
    verticalAlign: "-3px",
  },
  tab: {
    textTransform: "capitalize",
    fontSize: "1rem !important",
    color: theme.palette.text.primary,
    flexDirection: "row",
    // borderBottomColor: '1px solid white'
  },
  wrapper: {
    flexDirection: "row",
  },
  tabsRoot: {
    borderBottom: "1px solid #e8e8e8",
  },
  tabsIndicator: {
    // borderBottom: '1px solid #F58120',
    // borderBottomColor:"1px solid #F58120",
  },
  tabRoot: {
    "&$tabSelected": {
      textTransform: "initial",
      color: "#F58120",
    
      fontWeight: "bold",
      // borderBottom: '1px solid #F58120',
      // borderBottomColor: '1px solid #ffffff',
    },
  },
  tabSelected: {},
  openTextP: {
    paddingLeft: theme.spacing(2.5),
    paddingTop: 10,
    fontSize: "2rem",
    paddingBottom: "5px",
  },
  openText1: {
    paddingLeft: 5,
    paddingTop: 10,
    paddingBottom: 5,
    fontSize: "2rem",
  },
  menuSubPaper: {
    backgroundColor: theme.palette.common.greyWhite,
    opacity: 1,
    
    margin: "0px 10px 0px 10px",
    boxShadow: "unset",
    // marginBottom: theme.spacing(1) * 4,
    marginBottom: theme.spacing(1),
  },
  menuDivider: {
    backgroundColor: "rgba(255, 255, 255, 0.05)",
  },
  rightSmallMenu: {
    padding: theme.spacing(2),
  },
  rightSmallMenu1: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  textOverflow1: {
    overflowWrap: "break-word",
  },
  rightSmallMenuList: {
    fontWeight: "500",
  },
  emailSmallMenuList: {
    opacity: 0.5,
  },
  menuDivider1: {
    height: 1,
    margin: 0,
    border: "none",
    fleShrink: 0,
    backgroundColor: theme.palette.common.lighterGrey,
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginTop: 10,
    marginBottom: 10,
  },
  badge: {
    display: "inline",
    margin: "4%",
    background: theme.palette.selected,
    color: theme.palette.common.white,
    padding: "3px 7px 3px 7px",
    borderRadius: "21px",
    fontWeight: "bold",
  },
  marginMain: {
    background: theme.palette.selected,
    color: theme.palette.common.white,
    marginTop: "-3px",
    marginRight: "0px",
  },
  notificationBadge: {
    background: theme.palette.selected,
    color: theme.palette.common.white,
  },
  toolBarLeft: {
    paddingLeft: theme.spacing(2),
  },
  mobileMenuSpace: {
    // padding: theme.spacing(1) * 8,
    padding: theme.spacing(8),
  },
  list: {
    paddingLeft: 15,
    paddingRight: 15,
  },
});

export default styles;
