import React from "react";
import * as _ from "lodash";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import CustomDialog from "../admin/dialog-dynamic.jsx";

import {
  Typography,
  Grid,
  Button,
  Chip,
  Menu,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import withStyles from '@mui/styles/withStyles';

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { DivTypography } from "../typography";
import { TypeTitle } from "./task-data";
import Savings from "./savings";

const SelectInfoChip = withStyles((theme) => ({
  root: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: "2px solid #8A95A4",
    fontWeight: "900",
    fontSize: 16,
    color: theme.palette.text.primary,
  },
}))(Chip);

const PostButton = withStyles((theme) => ({
  root: {
    backgroundColor: "#F58120",
    color: theme.palette.common.white,
    borderRadius: 4,
    // padding: "7px 55px",
    fontWeight: "bold",
    marginTop: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#F58120",
    },
    "&:first-letter": {
      backgroundColor: theme.palette.success,
    },
  },
}))(Button);

const HeadLine = withStyles({
  root: {
    fontWeight: "900",
  },
})(Typography);
const DialogHead = withStyles((theme) => ({
  root: {
    fontWeight: "900",
    color: theme.palette.text.primary,
  },
}))(DialogTitle);
const HyperLink = withStyles({
  textDecoration: "none",
  "&:focus": {
    outlineStyle: "none",
  },
})(Link);
const PopUpOrangeBtn = withStyles((theme) => ({
  root: {
    fontWeight: "bold",
    color: theme.palette.orangeColor,
  },
}))(Button);
const PopUpCommonBtn = withStyles((theme) => ({
  root: {
    fontWeight: "400",
    color: theme.palette.text.primary,
  },
}))(Button);
const DialogText = withStyles((theme) => ({
  root: {
    fontWeight: "lighter",
    fontSize: 16,
    color: theme.palette.text.primary,
  },
}))(DialogContentText);

class SelectionInfo extends React.Component {
  state = {
    anchorEl: null,
  };
  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
    event.stopPropagation();
  };

  handleClose = (event) => {
    this.setState({ anchorEl: null });
    event.stopPropagation();
    this.props.onChange(event);
  };

  render() {
    const { selectedCount, totalCount } = this.props;
    const { anchorEl } = this.state;

    if (totalCount == 0) return null;

    return (
      <DivTypography noWrap>
        {/*<Typography variant={"button"} className={"inline"}><a onClick={this.handleClick}>{selectedCount}/{totalCount}</a></Typography>*/}
        <SelectInfoChip
          style={{ height: "unset" }}
          onClick={this.handleClick}
          onDelete={this.handleClick}
          deleteIcon={<ArrowDropDownIcon />}
          label={`${selectedCount}/${totalCount}`}
        ></SelectInfoChip>
        &nbsp;
        <Typography variant={"caption"} className={"inline"}>
          Items Selected
        </Typography>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          keepMounted={true}
        >
          <MenuItem value={"SelectAll"} onClick={this.handleClose}>
            Select All
          </MenuItem>
          <MenuItem value={"ClearAll"} onClick={this.handleClose}>
            Clear All
          </MenuItem>
        </Menu>
      </DivTypography>
    );
  }
}

function _Summary({
  icon,
  title,
  description,
  selectedImpact,
  selectedCount,
  totalCount,
  expanded,
  onSelectClearAll,
  campaignType,
  postPopUp,
  postAlert,
  handleClose,
  postAgree,
  taskTypeId,
  addAdCopyMiniServices,
  history,
  ...rest
}) {
  const selectAll = (e) => {
    console.log("e = ", e);
    console.log("e.target = ", e.target);
    e.stopPropagation();
  };
  // rest.selectedServicePacks
  const selectedServicePacksLen = _.filter(
    _.mapValues(rest.selectedServicePacks, (v, k) => v.selected == true),
    (v) => v == true
  ).length;
  console.log("rest.selectedServicePacks=", selectedServicePacksLen);
  console.log("taskTypeId- in summary=", taskTypeId);
  // const OnSkipClick =(e) =>{
  //     onSkip();
  //    e.stopPropagation();
  // };
  const OnPostClick = (e) => {
    postPopUp();
    e.stopPropagation();
  };
  const onAddMiniServiceClick = async (e) => {
    await addAdCopyMiniServices();
    history.push("/add-adcopy-service/3");
    e.stopPropagation();
  };
  const OnClickHandleClose = (e) => {
    handleClose();
    e.stopPropagation();
  };
  const onClickAgreePost = (e) => {
    postAgree();
    e.stopPropagation();
  };
  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const hasImpact = _.sum(_.values(selectedImpact)) > 0;
  const selectable = totalCount > 0;

  return (
    <Grid container spacing={2} wrap={"nowrap"}>
      <Grid item></Grid>
      <Grid item>{icon}</Grid>
      <Grid item className={"growable"}>
        <HeadLine>{title}</HeadLine>
        {campaignType ? (
          <TypeTitle type={"Campaign"} value={campaignType}></TypeTitle>
        ) : (
          <Typography variant={"caption"}>{description}</Typography>
        )}
        <Savings {...rest} />
      </Grid>
      {expanded &&
        selectable &&
        rest.groupId != 49 &&
        rest.groupId != 51 &&
        rest.groupId != 54 && (
          <Grid item style={{ paddingRight: 10 }}>
            <Grid container spacing={2} wrap={"nowrap"} alignItems={"flex-end"}>
              <Grid item>
                {rest.isAdmin !== true ? (
                  <SelectionInfo
                    {...{ selectedCount, totalCount }}
                    onChange={onSelectClearAll}
                  />
                ) : null}
              </Grid>
              <Grid item id="postBtn">
                {!rest.isAdmin ? (
                  <PostButton
                    variant="raised"
                    size="small"
                    onClick={OnPostClick}
                    disabled={selectedCount == 0}
                  >
                    POST
                  </PostButton>
                ) : null}
                {(rest.displayStatus == 1 || rest.displayStatus == 0) &&
                rest.isAdmin ? (
                  <PostButton
                    variant="raised"
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      rest.pushPopUp();
                      // rest.pushTask(rest.taskId);
                    }}
                  >
                    PUSH
                  </PostButton>
                ) : null}
                {rest.displayStatus == 2 && rest.isAdmin ? (
                  <PostButton
                    disabled={rest.displayStatus == 2 && rest.isAdmin}
                    variant="raised"
                    size="small"
                  >
                    Pushed
                  </PostButton>
                ) : null}
              </Grid>
            </Grid>
            {hasImpact && <Savings {...selectedImpact} />}
          </Grid>
        )}
      {expanded && selectable && rest.groupId == 51 && (
        <Grid item style={{ paddingRight: 10 }}>
          <Grid container spacing={2} wrap={"nowrap"} alignItems={"flex-end"}>
            <Grid item>
              <PostButton
                variant="raised"
                size="small"
                onClick={onAddMiniServiceClick}
                disabled={selectedServicePacksLen == 0}
              >
                Request Service&nbsp;({selectedServicePacksLen})
              </PostButton>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Dialog
        open={postAlert}
        onClick={stopPropagation}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogHead id="alert-dialog-title">{"Post"}</DialogHead>
        <DialogContent>
          <DialogText id="alert-dialog-description">
            Are you sure you want to post these changes into the Search Engine?
          </DialogText>
        </DialogContent>
        <DialogActions>
          <PopUpCommonBtn onClick={OnClickHandleClose}>Disagree</PopUpCommonBtn>
          <PopUpOrangeBtn onClick={onClickAgreePost} autoFocus>
            Agree
          </PopUpOrangeBtn>
        </DialogActions>
      </Dialog>
      <CustomDialog
        open={rest.pushAlert}
        title={"Notification"}
        content={"Are you sure you want to push the task?"}
        handleAction={(e) => {
          e.stopPropagation();
          rest.pushAgree(rest.taskId);
        }}
        handleClose={OnClickHandleClose}
        secondaryBtnContent={"Cancel"}
        primaryBtnContent={"Ok"}
      />
    </Grid>
  );
}

export const Summary = withRouter(_Summary);
