import React, { Component } from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import { Grid, Typography } from "@mui/material";
import newFooterStyles from "../../assets/jss/footer/newFooterStyles";

class Footer extends Component {
  render() {
    const { classes } = this.props;
    return (
      <footer className={classes.footerBlock}>
        <Grid container spacing={2}>
          <Grid item md={12} xs={12} className={classes.subfooter1}>
            <Typography varient={"caption"} color={"black"}>
              <b>
                &copy; {new Date().getFullYear()}
                &nbsp;NetElixir Inc, All Rights Reserved.
              </b>
            </Typography>
          </Grid>
        </Grid>
      </footer>
    );
  }
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(newFooterStyles)(Footer);
