import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Grid,
  Snackbar,
  Alert,
  Slide,
  FormControlLabel,
  Checkbox,
  Typography,
  Button,
} from "@mui/material";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import EditOutlined from "@mui/icons-material/EditOutlined";
import {
  JsOrangeContainedBtn,
  JsTextAsButton,
} from "../../../components/buttons";
import SendOutlined from "@mui/icons-material/SendOutlined";
import SaveOutlined from "@mui/icons-material/SaveOutlined";
import { Link } from "react-router-dom";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import {
  fetchRsaDetails,
  saveRsaDetails,
  generateNewDescriptions,
  generateNewHeadlines,
  generateNewPaths,
  submitCampaignStructure,
  fetchCampaignDetails,
} from "../../../actions/jumpstartRevamped";
import { AdCopySection } from "./adCopyData";
import { useParams, useHistory } from "react-router-dom";
import { Authority } from "../commonComponents/jumpstartEnum";
import { InfoOutlined } from "@mui/icons-material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

function GenerateAdCopy(props) {
  const {
    token,
    fetchRsaDetails,
    saveRsaDetails,
    generateNewDescriptions,
    generateNewHeadlines,
    generateNewPaths,
    fetchRsaDetailsReducer,
    submitCampaignStructure,
    submitCampaignStructureReducer,
    fetchUserInfoReducer,
    adgroupId,
    authority,
    disableStructure,
    fetchCampaignDetails,
    handleButton,
    backId,
    fetchCampaignDetailsAPI,
  } = props;
  const [adCopy, setAdCopy] = useState({});
  const [headlines, setHeadlines] = useState([]);
  const [descriptions, setDescriptions] = useState([]);
  const [paths, setPaths] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const { id } = useParams();
  const history = useHistory();
  const [totalAdgroups, setTotalAdgroups] = useState(5);
  const [savedAdgroups, setSavedAdgroups] = useState(1);

  // const [authority, setAuthority] = useState();

  // useEffect(() => {
  //   if (fetchUserInfoReducer.message == "JS_USER_INFO_RECEIVED") {
  //     setAuthority(fetchUserInfoReducer.authority);
  //   }
  // }, [fetchUserInfoReducer]);

  //Snackbar
  const [snackbarMsg, setSnackbarMsg] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const handleSnackbarClose = (event, reason) => {
    setError(false);
    setSnackbarOpen(false);
    if (reason === "clickaway") {
      return;
    }
  };
  const fetchRsaData = async () => {
    setLoading(true);
    const data = await fetchRsaDetails(token, adgroupId);
    if (data && data.status == 200) {
      const { status, ...rsaData } = data;
      setAdCopy(rsaData);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchRsaData();
  }, []);

  useEffect(() => {
    if (
      fetchRsaDetailsReducer &&
      fetchRsaDetailsReducer.message === "FETCH_RESPONSIVE_ADS_RECEIVED"
    ) {
      setHeadlines(
        fetchRsaDetailsReducer.headlines != null
          ? fetchRsaDetailsReducer.headlines
          : []
      );
      setDescriptions(
        fetchRsaDetailsReducer.descriptions != null
          ? fetchRsaDetailsReducer.descriptions
          : []
      );
      setPaths(
        fetchRsaDetailsReducer.paths != null ? fetchRsaDetailsReducer.paths : []
      );
    }
  }, [fetchRsaDetailsReducer]);

  const handleCheckboxChange = (event, type, index) => {
    const isChecked = event.target.checked;
    type = type.toLowerCase() + "s";

    setAdCopy((prevAdCopies) => {
      const updatedCopies = [...prevAdCopies[type]];
      updatedCopies[index].selected = isChecked;
      return {
        ...prevAdCopies,
        [type]: updatedCopies,
      };
    });
  };

  const callSaveAPI = async () => {
    return await saveRsaDetails(token, adCopy);
  };

  const handleSaveRsa = async () => {
    const data = await callSaveAPI();
    if (data && data.status == 200) {
      fetchCampaignDetails(token, id);
      setError(false);
      setSnackbarMsg("Responsive Search Ads saved");
    } else {
      setError(true);
      setSnackbarMsg("Error in saving Responsive Search Ads");
    }
    setSnackbarOpen(true);
  };

  const handleGenerateRSA = async (type) => {
    const newType = type.toLowerCase();
    setLoading(true);

    let newData;
    switch (newType) {
      case "path":
        newData = await generateNewPaths(token, adgroupId);
        break;
      case "headline":
        newData = await generateNewHeadlines(token, adgroupId);
        break;
      case "description":
        newData = await generateNewDescriptions(token, adgroupId);
        break;
      default:
        newData = undefined;
        break;
    }

    if (newData && newData.status == 200) {
      const { status, ...newRSA } = newData;
      setAdCopy(newRSA);
      setSnackbarMsg("New " + newType + "s generated");
      setError(false);
      await fetchRsaData();
    } else {
      setSnackbarMsg("Error in generating " + newType + "s");
      setError(true);
    }

    setLoading(false);
    setSnackbarOpen(true);
  };

  const handleTextAreaChange = (event, type, index) => {
    const newText = event.target.value;
    type = type.toLowerCase() + "s";
    setAdCopy((prevAdCopies) => {
      const updatedCopies = [...prevAdCopies[type]];
      updatedCopies[index].text = newText;
      return {
        ...prevAdCopies,
        [type]: updatedCopies,
      };
    });
  };

  // const handleNavigateToAccountStructure = () => {
  //   // history.push(`/campaign-structure/${id}`);
  //   window.location.reload();
  // };

  const handleNavigateToAccountStructure = () => {
    fetchCampaignDetailsAPI();
    handleButton("Callouts", 0, id);
  };
  return (
    <>
      <Grid
        container
        direction="row"
        style={{ padding: "0% 2%" }}
        justifyContent="stretch"
      >
        <AdCopySection
          adCopy={headlines}
          type="Headline"
          heading="Generated Headlines"
          onSelect={handleCheckboxChange}
          handleTextAreaChange={handleTextAreaChange}
          count={15}
          handleGenerateRSA={handleGenerateRSA}
          charLimit={30}
          loading={loading}
          disableStructure={disableStructure}
        />
        <AdCopySection
          adCopy={descriptions}
          type="Description"
          heading="Generated Descriptions"
          onSelect={handleCheckboxChange}
          handleTextAreaChange={handleTextAreaChange}
          count={4}
          handleGenerateRSA={handleGenerateRSA}
          charLimit={90}
          loading={loading}
          disableStructure={disableStructure}
        />
        <AdCopySection
          adCopy={paths}
          type="Path"
          heading="Generated Paths"
          onSelect={handleCheckboxChange}
          handleTextAreaChange={handleTextAreaChange}
          count={2}
          handleGenerateRSA={handleGenerateRSA}
          charLimit={15}
          loading={loading}
          disableStructure={disableStructure}
        />
        <Grid
          item
          xs={12}
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          columnSpacing={2}
          style={{ paddingTop: "1%" }}
        >
          <Grid item xs={4}>
            <Button
              variant="text"
              style={{ textTransform: "none" }}
              onClick={() => handleButton("MatchType", backId)}
              disabled={loading}
            >
              <ChevronLeftIcon/>Back
            </Button>
            {/* {authority == Authority.ANALYST || authority == Authority.RWF ? (
              ""
            ) : (
              <>
                <FormControlLabel
                  control={
                    <Checkbox size="small" id="final" color="secondary" />
                  }
                  label="Mark this as final"
                  sx={{
                    ".MuiFormControlLabel-label": {
                      fontSize: "0.875rem",
                      color: "#838383",
                    },
                  }}
                />
              </>
            )} */}
          </Grid>
          <Grid
            item
            xs={8}
            container
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            columnSpacing={2}
          >
            {/* <Grid item>
              <Typography
                variant="subtitle2"
                color={savedAdgroups == totalAdgroups ? "#00A850" : "#747474"}
              >
                {savedAdgroups}/{totalAdgroups} Ad Copies Completed
              </Typography>
            </Grid> */}
            <Grid item>
              <JsTextAsButton
                onClick={() => handleSaveRsa()}
                disabled={
                  disableStructure ||
                  (headlines.filter((headline) => headline.selected).length ==
                    0 &&
                    descriptions.filter((description) => description.selected)
                      .length == 0)
                }
              >
                <SaveOutlined fontSize="small" color="inherit" /> Save
              </JsTextAsButton>
            </Grid>
            <Grid item>
              <JsOrangeContainedBtn
                style={{ fontSize: "0.8125rem" }}
                onClick={() => handleNavigateToAccountStructure()}
                // onClick={() => handleButton("Callouts",0, id)}
              >
                <SendOutlined
                  fontSize="small"
                  sx={{ transform: "rotate(320deg)" }}
                />
                Go to Account Structure
              </JsOrangeContainedBtn>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={error ? "error" : "success"}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarMsg}
        </Alert>
      </Snackbar>
    </>
  );
}

const mapStateToProps = (state) => ({
  saveRsaDetailsReducer: state.saveRsaDetailsReducer,
  fetchRsaDetailsReducer: state.fetchRsaDetailsReducer,
  generateNewPathsReducer: state.generateNewPathsReducer,
  generateNewHeadlinesReducer: state.generateNewHeadlinesReducer,
  generateNewDescriptionsReducer: state.fetchRsaDetailsReducer,
  fetchUserInfoReducer: state.fetchUserInfoReducer,
});

const mapDispatchToProps = (dispatch) => ({
  saveRsaDetails: (token, rsa) => dispatch(saveRsaDetails(token, rsa)),
  fetchRsaDetails: (token, adgroupId) =>
    dispatch(fetchRsaDetails(token, adgroupId)),
  generateNewPaths: (token, adgroupId) =>
    dispatch(generateNewPaths(token, adgroupId)),
  generateNewHeadlines: (token, adgroupId) =>
    dispatch(generateNewHeadlines(token, adgroupId)),
  generateNewDescriptions: (token, adgroupId) =>
    dispatch(generateNewDescriptions(token, adgroupId)),
  submitCampaignStructure: (token, id) =>
    dispatch(submitCampaignStructure(token, id)),
  fetchCampaignDetails: (token, id) =>
    dispatch(fetchCampaignDetails(token, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GenerateAdCopy);
