import withStyles from "@mui/styles/withStyles";
import React from "react";
import Grid from "@mui/material/Grid";
import { Row } from "reactstrap";
import { Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import icons from "../../../components/icons.js";

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="xl" />;

const styles = (theme) => ({
  paddingTB: {
    paddingBottom: "inherit",
  },

  SocialStyle: {
    paddingRight: 12,
    color: "#F26A30",
    // textAlign: "end",
    [theme.breakpoints.down("md")]: {
      textAlign: "left",
    },
    [theme.breakpoints.up("md")]: {
      textAlign: "end",
    },
    [theme.breakpoints.down("lg")]: {
      paddingRight: 12,
    },
  },
  bottomYear: {
    textAlign: "center",
    backgroundColor: "#060C14",
    color: "white",
    padding: "10px",
    position: "bottom",
    left: 0,
    bottom: 0,
    right: 0,
  },
  lrpadding: {
    backgroundColor: "#1E334E",
    color: "white",
    paddingLeft: 30,
    paddingRight: 50,
    paddingTop: 40,
    paddingBottom: 30,
    [theme.breakpoints.down("lg")]: {
      paddingLeft: 20,
      paddingRight: 40,
      paddingTop: 30,
      paddingBottom: 20,
    },
  },
  iconstyles: {
    color: "white",
    fontSize: "1.3rem",
  },
  lxrguidelogo: {
    paddingLeft: 24,
    paddingBottom: 15,
    [theme.breakpoints.down("lg")]: {
      paddingLeft: 0,
    },
    // height: 27.12,
  },
  marginBottomImages: {
    marginBottom: 2,
  },
  marginBottomspacing: {
    marginBottom: 4,
  },
  gapbtwgrid: {
    textAlign: "initial",
    padding: "1rem",
  },
  imagespacing: {
    textAlign: "end",
    padding: "1rem",
  },
  upsimagestyling: {
    textAlign: "initial",
    paddingLeft: 5,
  },
  upsimagewidth: {
    width: 156,
  },
  anchortagStyle: {
    textDecoration: "none",
    color: "white",
    textTransform: "none",
    fontSize: 16,
  },
  blogStyle: {
    paddingTop: 9,
    paddingLeft: 7,
    paddingBottom: 12,
  },
  lastImages: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "flex-start",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "flex-end",
    },
  },
});

function JumpstartExternalFooter(props) {
  const classes = props.classes;
  const width = props.width;
  return (
    <div>
      <Grid container className={classes.lrpadding}>
        {/* <div className={classes.marginBottomspacing}> */}
        <Grid className={classes.lxrguidelogo} item md={3} xs={12} sm={12}>
          {icons.Lxrguidewhite}
        </Grid>
        <Grid item xs={12} sm={12} md={9} className={classes.SocialStyle}>
          <Grid>
            <Grid item>
              <Typography color={"secondary"}>
                Stay Connected. Find us on. &nbsp;
                <a
                  className={classes.iconstyles}
                  href="https://www.facebook.com/LXRGuide-1736697119883388/"
                  target="_blank"
                >
                  <i className="fa fa-facebook-official" aria-hidden="true" />
                </a>
                &nbsp;&nbsp; &nbsp;
                <a
                  className={classes.iconstyles}
                  href="https://twitter.com/LXRGuide/"
                  target="_blank"
                >
                  <i className="fa fa-twitter-square" aria-hidden="true" />
                </a>
                &nbsp;&nbsp; &nbsp;
                <a
                  className={classes.iconstyles}
                  href="https://www.linkedin.com/company/11116448/"
                  target="_blank"
                >
                  <i className="fa fa-linkedin-square" aria-hidden="true" />
                </a>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4} className={classes.gapbtwgrid}>
          <Row className={classes.blogStyle}>
            <i
              style={{ color: "#EF8127" }}
              className="fa fa-envelope"
              aria-hidden="true"
            />
            &nbsp;
            <a
              className={classes.anchortagStyle}
              href="https://mail.google.com/mail/?view=cm&amp;fs=1&amp;to=support@lxrguide.com&amp;su=Reg: LXRGuide Product Help"
              target="_blank"
            >
              support@lxrguide.com
            </a>
          </Row>
          <Row className={classes.blogStyle}>
            <i
              style={{ color: "#EF8127" }}
              className="fa fa-phone"
              aria-hidden="true"
            />
            &nbsp;609 356 5112
          </Row>
          <Row className={classes.blogStyle}>
            <i
              style={{ color: "#EF8127" }}
              className="fa fa-map-marker"
              aria-hidden="true"
            />
            &nbsp;3 Independence Way, Suite #203 <br />
            &ensp; Princeton, NJ 08540 USA
          </Row>
        </Grid>
        <Grid item xs={12} md={2} className={classes.gapbtwgrid}>
          <Row>
            <Button
              component={Link}
              className={classes.anchortagStyle}
              to={"/features"}
              target="_blank"
            >
              Features & Benefits
            </Button>
          </Row>
          <Row>
            {" "}
            <Button
              component={Link}
              className={classes.anchortagStyle}
              to={"/pricing"}
              target="_blank"
            >
              Pricing
            </Button>
          </Row>
          <Row className={classes.blogStyle}>
            <a className={classes.anchortagStyle} href="/blog/" target="_blank">
              {" "}
              Blog
            </a>
          </Row>
        </Grid>
        <Grid item xs={12} md={2} className={classes.gapbtwgrid}>
          <Row className={classes.blogStyle}>
            <a
              className={classes.anchortagStyle}
              href="/terms-of-service.html"
              target="_blank"
            >
              {" "}
              Terms of Service
            </a>
          </Row>
          <Row className={classes.blogStyle}>
            <a
              className={classes.anchortagStyle}
              href="/press-release.html"
              target="_blank"
            >
              {" "}
              News
            </a>
          </Row>
          <Row className={classes.blogStyle}>
            <a
              className={classes.anchortagStyle}
              href="/privacy-policy.html"
              target="_blank"
            >
              {" "}
              Privacy Policy
            </a>
          </Row>
          <Row>
            <Button
              component={Link}
              to={"/about-us"}
              className={classes.anchortagStyle}
              target="_blank"
            >
              About Us
            </Button>
          </Row>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          className={classes.imagespacing}
          style={{ paddingRight: 0 }}
        >
          <Grid container className={classes.lastImages}>
            <Grid item>
              <Row className={classes.marginBottomImages}>
                <a
                  href="https://www.google.com/partners/agency?id=1430663498"
                  target="_blank"
                >
                  {icons.googleSig("auto")}
                </a>
              </Row>
              <Row className={classes.marginBottomImages}>
                <a
                  href="https://learninglab.about.ads.microsoft.com/certification/membership-directory/"
                  target="_blank"
                >
                  {icons.microsoftNewlogoft("147px")}
                </a>
              </Row>
              {/* <Row><img src={FB_market} /></Row> */}
            </Grid>
            <Grid item className={classes.upsimagestyling}>
              <Row className={classes.marginBottomImages}>
                <a href="https://www.netelixir.com/ups/" target="_blank">
                  {/* <img src={ups_sig} className={classes.upsimagewidth}/> */}
                  {icons.upsNewLogoFooter("auto")}
                </a>
              </Row>
              <Row className={classes.marginBottomImages}>{icons.isoSig}</Row>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div className={classes.bottomYear}>
        <Grid container>
          <Grid item xs={12}>
            <Row>
              <p>
                {" "}
                © {new Date().getFullYear()} NetElixir Inc, All Rights Reserved.
              </p>
            </Row>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
export default withWidth()(withStyles(styles)(JumpstartExternalFooter));
