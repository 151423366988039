import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Grid, LinearProgress, Typography, Divider } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
// import Tooltip from "@mui/material/Tooltip";
import * as _ from "lodash";
import { Tooltip } from "@mui/material/index";
// import {topKeywordList} from "./templates";

const styles = (theme) => ({
  determinate: {
    backgroundColor: "#E6E6E6",
  },
  orange: {
    backgroundColor: theme.palette.orangeColor,
  },
  montserrat: {
    fontFamily: "Lato",
    color: "#1E4C4E",
    fontWeight: "normal",
    fontSize: 14,
  },
  padding2X: { padding: theme.spacing(1) },
  keywordText: {
    fontFamily: "Lato",
    fontWeight: "400",
    fontSize: 14,
  },
  progressBarRoot: {
    height: 8,
    borderRadius: 5,
    // color
  },
  dashedLine: {
    border: "1px dashed" + theme.palette.common.darkHash,
    backgroundColor: "unset",
    boxSizing: "border-box",
    borderRadius: 10,
    opacity: 0.4,
    height: 0,
  },
  container: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  pd: {
    paddingTop: 12,
  },
  textOverFlow: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
});

class KeywordScoreCard extends React.PureComponent {
  render() {
    const { classes, siteGrader } = this.props;
    const topKeywordList =
      siteGrader != null && siteGrader.keyword && siteGrader.keyword != null
        ? siteGrader.keyword.topKeyWords
        : null;
    let count = 0;
    return (
      <Grid container style={{ height: "100%" }} alignItems={"center"}>
        <Grid item xs={12} sm={12} md={12}>
          <div
            className={topKeywordList == null ? classes.container : classes.pd}
          >
            {/*<br/>*/}
            {topKeywordList != null ? (
              <Fragment>
                {_.map(topKeywordList, function (keywordValue, keywordName) {
                  return (
                    <Grid
                      container
                      justifyContent={"center"}
                      key={keywordValue}
                      alignItems={"center"}
                      spacing={2}
                    >
                      <Grid
                        item
                        md={8}
                        sm={7}
                        xs={7}
                        className={classes.textOverFlow}
                      >
                        <Tooltip title={keywordName}>
                          <Typography
                            component={"p"}
                            variant={"body2"}
                            align={"left"}
                            color={"textPrimary"}
                            style={{ opacity: 0.5 }}
                          >
                            {keywordName}
                          </Typography>
                        </Tooltip>
                      </Grid>
                      <Grid item md={4} sm={5} xs={5}>
                        <Typography
                          className={classes.montserrat}
                          align={"right"}
                        >
                          <b>{keywordValue}</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Divider className={classes.dashedLine} />
                      </Grid>
                    </Grid>
                  );
                })}
              </Fragment>
            ) : (
              <div>
                <Typography align={"center"} color={"textPrimary"}>
                  No Keywords{" "}
                </Typography>
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    );
  }
}

KeywordScoreCard.propTypes = {};

export default withStyles(styles)(KeywordScoreCard);
