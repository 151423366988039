import Home_bg from "../../img/home_1.png";

const siteGraderHomeFormStyles = (theme) => ({
  mainDiv: {
    border: "1px solid #F9D565",
    height: "25em",
    display: " inline-block",
    overflow: "unset",
    // position: "relative",
    boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
    backgroundImage: "url(" + Home_bg + ")",
    backgroundPosition: "bottom center",
    backgroundSize: "cover",
  },
  subDiv: {
    [theme.breakpoints.down('xl')]: {
      width: "93%",
      position: "relative",
      margin: "-125px 0px 0px",
    },
    width: "35%",
    flex: "none",
    padding: 15,
    background: "#F9D565",
    boxShadow:
      "0 12px 20px -10px rgba(255, 152, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 152, 0, 0.2)",
    lineHeight: "1.5em",
    borderRadius: 3,
    margin: "-125px 40px 0px",
    height: "14em",
    position: "absolute",
  },
  steps: {
    height: "15em",
    [theme.breakpoints.down('xl')]: {
      height: "13em",
    },
  },
  button: {
    marginTop: theme.spacing(2),
    padding: "8px 50px",
    [theme.breakpoints.down('xl')]: {
      padding: "18px 45px",
      fontSize: 12,
    },
  },
  formTitle: {
    fontSize: 20,
  },
  dialogTitle: {
    color: "#000000",
    padding: 10,
    fontSize: 16,
  },
  sectionCard: {
    boxShadow: "unset",
    padding: "0px 0px 0px 0px",
  },
  CustomPaper: {
    width: "100%",
    height: "11em",
  },
  listAlign: {
    textAlign: "right",
  },
  dialogP: {
    padding: "30px 10px 10px 10px",
  },
  formHeight: {
    height: "3em",
    [theme.breakpoints.down('xl')]: {
      width: "100%",
    },
  },
  fontFamilyM: {
    fontFamily: "Montserrat",
  },
  stepsTitle: {
    fontFamily: "Montserrat",
    fontsize: 18,
    [theme.breakpoints.down('xl')]: {
      paddingTop: 30,
    },
  },
  NewIcon: {
    position: "absolute",
    zIndex: "99",
    margin: "-144px 0px 0px 15px",
  },
  mainTitle: {
    fontFamily: "Montserrat",
    fontSize: 45,
    fontWeight: "bold",
    color: theme.palette.common.white,
    [theme.breakpoints.down('xl')]: {
      fontSize: 30,
    },
  },
  titleSection: {
    marginLeft: 40,
    width: "60%",
    marginTop: 45,
    [theme.breakpoints.down('xl')]: {
      marginLeft: 0,
      width: "95%",
      marginTop: 20,
      padding: 14,
    },
  },
  titleSubPara: {
    fontFamily: "Lato",
    color: theme.palette.common.white,
    fontSize: 20,
    fontWeight: "bold",
    [theme.breakpoints.down('xl')]: {
      fontSize: 16,
    },
  },
});

export default siteGraderHomeFormStyles;
