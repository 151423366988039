// import Datamap from 'datamaps/dist/datamaps.usa.min';
import Datamap from "datamaps/dist/datamaps.world.min";
import React, { useEffect } from "react";
import d3 from "d3";
import ReactDOM from "react-dom";
import { DefaultRegionData } from "../reports/geoGraph";
import objectAssign from "object-assign";
import PropTypes from "prop-types";
import * as _ from "lodash";
import { numbersFormat } from "./geoGraph";
import getSymbolFromCurrency from "currency-symbol-map";

export default class DataMap extends React.Component {
  constructor(props) {
    super(props);
    this.datamap = null;
  }

  linearPalleteScale(value) {
    const dataValues = _.values(
      _.mapValues(this.props.regionData, (data) => {
        return data.kpiMetric;
      })
    );
    const minVal = Math.min(...dataValues);
    const maxVal = Math.max(...dataValues);
    // /!* main dark code is used here*!/
    // return d3.scale.linear().domain([minVal, maxVal]).range(["#EFEFFF", "#02386F"])(value);
    return d3.scale
      .linear()
      .domain([minVal, maxVal])
      .range(["#63ab48", "#3C971B"])(value);
  }

  reducedData() {
    const newData = _.keyBy(
      _.map(this.props.regionData, (object, code) => ({
        ...object,
        fillColor: this.linearPalleteScale(object.kpiMetric),
      })),
      "countryCode"
    );
    console.log("newData===", newData);
    return objectAssign({}, DefaultRegionData, newData);
  }

  renderMap() {
    const handleCallBack = this.props.handleCallBack;
    const kpiType = this.props.kpiType;
    let regionData = this.props.regionData;
    const currency = this.props.currency;
    const width = this.props.width;

    return new Datamap({
      element: document.getElementById("datamap-container"),
      position: "relative",
      // scope: 'usa',
      width: width == "sm" || width == "xs" ? 360 : 500,
      projection: "mercator", // Style of projection to be used. try "equirectangular"
      // data: this.redducedData(),
      data: this.reducedData(),
      fills: {
        // 'USA': '#2ca02c',
        defaultFill: "#E3E6F4",
      },
      done: function (datamap) {
        datamap.svg
          .selectAll(".datamaps-subunit")
          .on("click", function (geography) {
            // alert(geography.properties.name);
            // handleCallBack("GeoGraph", geography.properties.name)
            console.log("geography.properties=", geography);
            if (geography.id == "USA") {
              handleCallBack(geography.id);
            }
          });
      },
      geographyConfig: {
        borderWidth: 0.5,
        highlightFillColor: "#FFCC80",
        popupTemplate: function (geography, data) {
          // if (kpiType === "RC" || kpiType === "CR") {
          return (
            '<div class="hoverinfo"><strong>' +
            geography.properties.name +
            "</strong><br><strong>Revenue: " +
            getSymbolFromCurrency(currency) +
            data.kpiMetric.toLocaleString() +
            "</strong><br><strong>PPC Spend: " +
            getSymbolFromCurrency(currency) +
            data.cost.toLocaleString() +
            "</strong></div>"
          );
          // }
        },
        popupOnHover: true,
      },
    });
  }

  currentScreenWidth() {
    return (
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    );
  }

  componentDidMount() {
    d3.select(".datamap").remove();
    // document.getElementById("#datamap-container").remove()
    const mapContainer = d3.select("#datamap-container");
    const initialScreenWidth = this.currentScreenWidth();
    const containerWidth =
      initialScreenWidth < 500
        ? {
            width: initialScreenWidth + "px",
            height: initialScreenWidth * 0.5625 + "px",
          }
        : { width: "500px", height: "400px", margin: "0 auto" };

    mapContainer.style(containerWidth);
    let regionData1 = this.props;
    console.log("DataMap---", regionData1);
    this.datamap = this.renderMap();
  }

  render() {
    return <div id="datamap-container" />;
  }
}

// export default DataMap

/*export default class DataMap extends React.Component {
    constructor(props) {
        super(props);
        this.datamap = null;
    }

    linearPalleteScale(value) {
        const dataValues = this.props.regionData.map(function (data) {
            return data.value
        });
        const minVal = Math.min(...dataValues);
        const maxVal = Math.max(...dataValues);
        /!* main dark code is used here*!/
        // return d3.scale.linear().domain([minVal, maxVal]).range(["#EFEFFF", "#02386F"])(value);
        return d3.scale.linear().domain([minVal, maxVal]).range(["#DE5F6D", "#781F29"])(value);
    }
    total = _.sumBy(this.props.regionData, "value");
    redducedData() {
        const newData = this.props.regionData.reduce((object, data) => {
            object[data.code] = {value: data.value, fillColor: this.linearPalleteScale(data.value), share: (data.value*100/this.total).toFixed(2)};
            return object;
        }, {});
        return objectAssign({}, DefaultRegionData, newData);
    }
    renderMap() {
        const filteringDataGeo1 = this.props.filteringDataGeo1
        const chartMetricGeo1 = this.props.chartMetric
        return new Datamap({
            element: ReactDOM.findDOMNode(this),
            position: "relative",
            scope: 'usa',
            width: 500,
            data: this.redducedData(),
            fills: {
                defaultFill: "#aaaaaa",
            },
            done: function(datamap) {
                datamap.svg.selectAll('.datamaps-subunit').on('click', function(geography) {
                    // alert(geography.properties.name);
                    filteringDataGeo1("GeoGraph", geography.properties.name)
                });
            },
            geographyConfig: {
                borderWidth: 0.5,
                highlightFillColor: '#FFCC80',
                popupTemplate: function (geography, data) {
                    if (data && data.value) {
                        if(chartMetricGeo1 === "orders") {
                            return '<div class="hoverinfo"><strong>' + geography.properties.name + '</strong><br><strong>Orders: ' + data.value + '</strong><br><strong>Share: ' + data.share + '%</strong></div>';
                        }else{
                            return '<div class="hoverinfo"><strong>' + geography.properties.name + '</strong><br><strong>Revenue: $' + numbersFormat(data.value,0) + '</strong><br><strong>Share: ' + data.share + '%</strong></div>';
                        }
                    } else {
                        return '<div class="hoverinfo"><strong>' + geography.properties.name + '</strong></div>';
                    }
                },
                popupOnHover: true
            }
        });
    }

    currentScreenWidth() {
        return window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth;
    }

    componentDidMount() {
        const mapContainer = d3.select('#datamap-container');
        const initialScreenWidth = this.currentScreenWidth();
        const containerWidth = (initialScreenWidth < 500) ?
            {width: initialScreenWidth + 'px', height: (initialScreenWidth * 0.5625) + 'px'} :
            {width: '500px', height: '350px'};

        mapContainer.style(containerWidth);
        this.datamap = this.renderMap();
        /!*window.addEventListener('resize', () => {
            const currentScreenWidth = this.currentScreenWidth();
            const mapContainerWidth = mapContainer.style('width');
            if (this.currentScreenWidth() > 500 && mapContainerWidth !== '500px') {
                d3.select('svg').remove();
                mapContainer.style({
                    width: '500px',
                    height: '350px'
                });
                this.datamap = this.renderMap();
            } else if (this.currentScreenWidth() <= 500) {
                d3.select('svg').remove();
                mapContainer.style({
                    width: currentScreenWidth + 'px',
                    height: (currentScreenWidth * 0.5625) + 'px'
                });
                this.datamap = this.renderMap();
            }
        });*!/
    }

    componentDidUpdate() {
        // this.datamap.updateChoropleth(this.redducedData());
        //this.datamap = this.renderMap();
    }

    /!*componentWillUnmount() {
        d3.select('svg').remove();
    }*!/

    render() {
        return (
            <div id="datamap-container"/>
        );
    }
}

DataMap.propTypes = {
    regionData: PropTypes.array.isRequired
};*/
