import React, { useState, useEffect } from "react";
import { Grid, MenuItem } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { ActionButtons } from "./templates";
import { connect } from "react-redux";
import { updateFromInputs, saveOnDemandFroms } from "../../actions/index";
import { InputField } from "../input-forms";
import * as _ from "lodash";

export const CustomGrid = withStyles((theme) => ({
  item: {
    padding: 25,
  },
}))(Grid);

// class AdGroupFrom extends React.Component {
//     constructor(props) {
//         super(props);
//         console.log("AGF, props", props);
//         this.state = {
//             ...this.props.initialValue,
//             adgroupThemeError: '',
//             businessDetailsError: '',
//             urlError: '',
//             campaignError: '',
//         }
//     }

function AdGroupFrom(props) {
  const [state, setState] = useState({
    ...props.initialValue,
    // adgroupThemeError: "",
    // businessDetailsError: "",
    // urlError: "",
    // campaignError: "",
  });

  const [adgroupThemeError, setAdgroupThemeError] = useState("");
  const [businessDetailsError, setBusinessDetailsError] = useState("");
  const [campaignError, setCampaignError] = useState("");
  const [urlError, setUrlError] = useState("");

  useEffect(() => {
    setAdgroupThemeError("");
    setBusinessDetailsError("");
    setCampaignError("");
    setUrlError("");
  }, []);

  console.log("AFG render state", state);
  console.log("initialValue", props.initialValue);

  const handleChange = (name) => (event) => {
    setState((prevState) => ({
      ...prevState,
      [name]: event.target.value,
    }));
  };

  const handleInputs = (event) => {
    event.preventDefault();
    handleChange();
    if (event.target.value == "" || event.target.value == null) {
      console.log(event.target.value);
    }
    // setState({
    //   [event.target.name]: event.target.value,
    // });
    console.log("AdGroup form state", state);
  };

  const validate = () => {
    let isError = false;
    const errors = {};
    if (state.campaign == "" && state.campaign.length == 0) {
      isError = true;
      errors.campaignError = "This Field is required";
    } else {
      errors.campaignError = "";
    }
    if (state.adgroupTheme == "" && state.adgroupTheme.length == 0) {
      isError = true;
      errors.adgroupThemeError = "This Field is required";
    } else {
      errors.adgroupThemeError = "";
    }
    if (state.url == "" && state.url.length == 0) {
      isError = true;
      errors.urlError = "This Field is required";
    } else if (state.url && state.url.length > 0) {
      var usrInput = state.url;
      var result = usrInput.match(
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
      );
      if (result) {
        errors.urlError = "";
      } else {
        isError = true;
        errors.urlError = "Please enter correct url";
      }
    } else {
      errors.urlError = "";
    }

    if (state.businessDetails == "" && state.businessDetails.length == 0) {
      isError = true;
      errors.businessDetailsError = "This Field is required";
    } else {
      errors.businessDetailsError = "";
    }
    setCampaignError(errors.campaignError);
    setBusinessDetailsError(errors.businessDetailsError);
    setAdgroupThemeError(errors.adgroupThemeError);
    setUrlError(errors.urlError);
    if (isError) {
      setState({
        ...state,
        // ...errors,
      });
    }
    return isError;
  };

  let miniService;
  const onSubmit = async (preNext) => {
    console.log("aggroupform state", state);
    let { handleNext } = props;
    let error;
    const { campaign, adgroupTheme, businessDetails, url } = state;
    const { label, type } = props;
    if (preNext == true) {
      error = validate();
    }
    if (!error) {
      await props.updateInputs(
        label,
        type,
        campaign,
        adgroupTheme,
        businessDetails,
        url
      );
      miniService = {
        ...miniService,
        serviceDetails: _.groupBy(props.fromInputs, "type"),
      };
      miniService = { ...miniService, grandTotal: props.grandTotal };
      miniService = { ...miniService, itemCounts: props.itemCounts };
      props.saveInputsToServer(miniService);

      if (props.activeStep === props.steps.length - 1 && preNext != false) {
        props.handleMultiPageAndSteppers(null, (handleNext = true));
        return;
      } else {
        props.handleMultiPageAndSteppers(preNext);
      }
      setAdgroupThemeError("");
      setBusinessDetailsError("");
      setUrlError("");
      // setState({
      //   adgroupThemeError: "",
      //   businessDetailsError: "",
      //   urlError: "",
      // });
    }
  };

  return (
    <Grid container className={"fullHeight"}>
      <CustomGrid item style={{ height: "90%" }} className={"fullWidth"}>
        <InputField
          variant="standard"
          id="standard-name"
          label="Which Campaign would you want to add the ad group to"
          select
          fullWidth
          margin="normal"
          value={state.campaign}
          onChange={handleChange("campaign")}
          // onChange={handleInputs}
          name="campaign"
          error={campaignError != ""}
          helperText={campaignError}
          InputLabelProps={{
            shrink: true,
          }}
          required
        >
          <MenuItem key={1} value="">
            <em>None</em>
          </MenuItem>
          {_.map(props.accountStructureDetails.campaignsMap, (value, key) => {
            return (
              <MenuItem key={key} value={value}>
                {value}
              </MenuItem>
            );
          })}
        </InputField>
        <br />
        <InputField
          variant="standard"
          id="standard-name"
          label="Name of the Ad group"
          fullWidth
          margin="normal"
          value={state.adgroupTheme}
          onChange={handleChange("adgroupTheme")}
          // onChange={handleInputs}
          name="adgroupTheme"
          error={adgroupThemeError != ""}
          helperText={adgroupThemeError}
          InputLabelProps={{
            shrink: true,
          }}
          onClick={(e) => {
            e.preventDefault();
          }}
          required
        />
        <br />
        <InputField
          variant="standard"
          id="standard-name"
          label="Tell us some more business details."
          multiline={true}
          rows={4}
          fullWidth
          margin="normal"
          value={state.businessDetails}
          onChange={handleChange("businessDetails")}
          // onChange={handleInputs}
          error={businessDetailsError != ""}
          helperText={businessDetailsError}
          name="businessDetails"
          InputLabelProps={{
            shrink: true,
          }}
          required
        />
        <br />
        <InputField
          variant="standard"
          id="standard-name"
          label="URL"
          fullWidth
          margin="normal"
          value={state.url}
          // onChange={handleInputs}
          onChange={handleChange("url")}
          error={urlError != ""}
          helperText={urlError}
          name="url"
          InputLabelProps={{
            shrink: true,
          }}
          required
        />
      </CustomGrid>
      <ActionButtons
        activeStep={props.activeStep}
        onSubmit={(value) => onSubmit(value)}
        steps={props.steps}
      />
    </Grid>
  );
}
const mapStateToProps = (state, ownProps) => ({
  initialValue: state.inputFroms[ownProps.label],
  accountStructureDetails: state.accountStructureDetails,
  itemCounts: state.miniServiceItemCount,
  fromInputs: state.inputFroms,
});
const mapDispatchToProps = (dispatch) => ({
  updateInputs: (label, type, campaign, adgroupTheme, businessDetails, url) => {
    return dispatch(
      updateFromInputs(
        label,
        type,
        campaign,
        adgroupTheme,
        businessDetails,
        url
      )
    );
  },
  saveInputsToServer: (miniService) => {
    return dispatch(saveOnDemandFroms(miniService));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(AdGroupFrom);
