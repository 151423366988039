import React from "react";
import messagebg from "../../assets/img/messagebg.png";
import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid";
import {
  OrangeTextSize12,
  GrayText,
  HeadLineTypography,
  BlueTitleTypography,
  CatalinaBlue,
} from "../../components/typography";
import { Typography } from "@mui/material/index";

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="xl" />;

const styles = (theme) => ({
  root: {
    [theme.breakpoints.down("xl")]: {
      backgroundImage: "unset",
    },
    backgroundImage: `url(${messagebg})`,
    textAlign: "center",
    backgroundRepeat: "no-repeat",
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(4),
    backgroundPositionX: "left",
    backgroundPositionY: "bottom",
  },
  HeadLineTypographyStyle: {
    paddingTop: 191,
    paddingBottom: 39,
  },
  CatalinaBlueStyle: {
    [theme.breakpoints.up("xs")]: {
      paddingLeft: 15,
      paddingRight: 15,
      paddingBottom: 15,
      textAlign: "initial",
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: 180,
      paddingRight: 198,
      paddingBottom: 99,
      textAlign: "initial",
    },
  },
});

function EngageProspect(props) {
  const classes = props.classes;
  const width = props.width;
  return (
    <div>
      <Grid container className={classes.root}>
        <Grid item md={12} sm={12} xs={12}>
          <Typography
            align={"center"}
            variant={width == "sm" || width == "xs" ? "h3" : "subtitle1"}
            color={"textPrimary"}
            style={{ fontWeight: "700" }}
            gutterBottom
          >
            Engage Prospects
          </Typography>
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <CatalinaBlue className={classes.CatalinaBlueStyle}>
            Those leads that are not sales-ready today, will be ready to buy one
            day. Your growing pool of prospects will need to stay engaged with
            your brand if you’re going to ensure they choose your SaaS solution
            over your competitors. That is why the second pillar of The Klood
            Growth Model is to engage your prospects.
            <br />
            <br />
            Engaging your prospects effectively requires having the systems and
            processes in place that help you organise, educate and qualify your
            contacts database. Through analysis of marketing engagement data,
            you can deliver a sales enablement strategy and guide your prospects
            through an optimised sales process.
          </CatalinaBlue>
        </Grid>
      </Grid>
    </div>
  );
}

export default withWidth()(withStyles(styles)(EngageProspect));
