import React, { Component } from "react";
import PropTypes from "prop-types";
import { SnackbarContent, Grid, Button } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import icons from "../icons";

const CustomSnackbarContent = withStyles((theme) => ({
  root: {
    maxWidth: "100%",
    margin: theme.spacing(1),
    justifyContent: "center",
    background: theme.palette.conversionAlertBg,
    color: theme.palette.conversionAlertText,
    fontWeight: "bold",
  },
}))(SnackbarContent);
const notificationStyles = (theme) => ({
  icon: {
    marginRight: theme.spacing(1),
    display: "flex",
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
});

class NotificationAlert extends React.PureComponent {
  render() {
    const { classes, icon, message } = this.props;
    return (
      <Grid container>
        <Grid item md={12}>
          <CustomSnackbarContent
            message={
              <span className={classes.message}>
                <span className={classes.icon}>{icon}</span>
                {message}
              </span>
            }
          />
        </Grid>
      </Grid>
    );
  }
}

NotificationAlert.propTypes = {};

export default withStyles(notificationStyles)(NotificationAlert);
