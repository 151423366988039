import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "../jumpstart.css";
import { JsSectionTypography } from "../../../components/typography";
import { Grid, InputAdornment, TextField, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { JsAddUserLabelTypography } from "../../../components/typography";
import {
  JsBlackContainedBtn,
  JsOrangeContainedBtn,
} from "../../../components/buttons";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { websiteValidation } from "../../commonFunctions/commonFunctions";
function UpdateSitelink(props) {
  const {
    handleCloseIcon,
    sitelink,
    handleUpdateSitelink,
    sitelinkIndex,
  } = props;

  const [updatedSitelink, setUpdatedSitelink] = useState(sitelink);

  const handleSitelinkUpdate = (e, property) => {
    const value = e.target.value;
    setUpdatedSitelink((prevSitelink) => ({
      ...prevSitelink,
      [property]: e.target.value,
    }));
  };

  const handleButtonClick = () => {
    handleUpdateSitelink(updatedSitelink);
    handleCloseIcon();
  };
  const [websiteValidationCheck, setWebsiteValidationCheck] = useState(true);

  const handleFinalUrlChange = (e, text) => {
    if (websiteValidation(e.target.value)) {
      setWebsiteValidationCheck(true);
    } else {
      setWebsiteValidationCheck(false);
    }
    handleSitelinkUpdate(e, text);
  };

  const charCountSection = (value, limit) => {
    return (
      <JsAddUserLabelTypography>
        Char : &nbsp;
        <span
          style={{
            color: value > limit ? "red" : "inherit",
          }}
        >
          {value}
        </span>
        /{limit}
      </JsAddUserLabelTypography>
    );
  };
  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        style={{ padding: "2%", borderBottom: "1px solid #E6E6E6" }}
      >
        <Grid item xs={11}>
          <JsSectionTypography style={{ fontSize: "1rem" }}>
            + Edit Sitelink {sitelinkIndex + 1}
          </JsSectionTypography>
        </Grid>
        <Grid item xs={1}>
          <IconButton aria-label="close" onClick={handleCloseIcon}>
            <CloseIcon color="secondary" />
          </IconButton>
        </Grid>
      </Grid>

      <Grid container direction="row" rowSpacing={2} style={{ padding: "2%" }}>
        {/* Link text :label and input box*/}
        <Grid
          item
          xs={12}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <label htmlFor="link">
              <JsAddUserLabelTypography>
                <span className="numberContainer">1</span>Sitelink text
              </JsAddUserLabelTypography>
            </label>
          </Grid>
          <Grid item>{charCountSection(updatedSitelink.text.length, 25)}</Grid>
          <TextField
            error={
              updatedSitelink.text.length > 25 || updatedSitelink.text == ""
            }
            helperText={
              updatedSitelink.text.length > 25
                ? "Sitelink text is more than 25 characters"
                : ""
            }
            fullWidth
            placeholder="Type your text here"
            size="small"
            id="link"
            value={updatedSitelink.text}
            onChange={(e) => handleSitelinkUpdate(e, "text")}
            sx={{
              ".MuiOutlinedInput-root": {
                fontSize: "0.8125rem",
              },
            }}
          />
        </Grid>

        {/* Description 1 : label and input box*/}
        <Grid
          item
          xs={12}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <label htmlFor="description1">
              <JsAddUserLabelTypography>
                <span className="numberContainer">2</span>Description line 1
              </JsAddUserLabelTypography>
            </label>
          </Grid>
          <Grid item>
            {charCountSection(updatedSitelink.descriptionLine1.length, 35)}
          </Grid>

          <TextField
            error={
              updatedSitelink.descriptionLine1.length > 35 ||
              updatedSitelink.descriptionLine1 == ""
            }
            helperText={
              updatedSitelink.descriptionLine1.length > 35
                ? "Description line 1 is more than 35 characters"
                : ""
            }
            fullWidth
            placeholder="Type description line 1 here"
            size="small"
            id="description1"
            value={updatedSitelink.descriptionLine1}
            onChange={(e) => handleSitelinkUpdate(e, "descriptionLine1")}
            sx={{
              ".MuiOutlinedInput-root": {
                fontSize: "0.8125rem",
              },
            }}
          />
        </Grid>

        {/* Description 2 : label and input box*/}
        <Grid
          item
          xs={12}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <label htmlFor="description2">
              <JsAddUserLabelTypography>
                <span className="numberContainer">3</span>Description line 2
              </JsAddUserLabelTypography>
            </label>
          </Grid>
          <Grid item>
            {charCountSection(updatedSitelink.descriptionLine2.length, 35)}
          </Grid>
          <TextField
            error={
              updatedSitelink.descriptionLine2.length > 35 ||
              updatedSitelink.descriptionLine2 == ""
            }
            helperText={
              updatedSitelink.descriptionLine2.length > 35
                ? "Description line 2 is more than 35 characters"
                : ""
            }
            fullWidth
            placeholder="Type description line 2 here"
            size="small"
            id="description2"
            value={updatedSitelink.descriptionLine2}
            onChange={(e) => handleSitelinkUpdate(e, "descriptionLine2")}
            sx={{
              ".MuiOutlinedInput-root": {
                fontSize: "0.8125rem",
              },
            }}
          />
        </Grid>
        {/* URL : label and input box*/}
        <Grid item xs={12}>
          <label htmlFor="finalUrl">
            <JsAddUserLabelTypography>
              <span className="numberContainer">4</span> Final URL
            </JsAddUserLabelTypography>
          </label>
          <TextField
            error={websiteValidationCheck ? false : true}
            helperText={
              websiteValidationCheck ? "" : "Please enter valid website URL"
            }
            fullWidth
            placeholder="Type URL here"
            size="small"
            id="finalUrl"
            value={updatedSitelink.finalUrl}
            onChange={(e) => handleFinalUrlChange(e, "finalUrl")}
            sx={{
              ".MuiOutlinedInput-root": {
                fontSize: "0.8125rem",
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="To ensure that your customer's information is protected, use HTTPS whenever possible.">
                    <InfoOutlined fontSize="small" />
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>

      <Grid container direction="row">
        <Grid item xs={6} className="bottomPositionBtn">
          <JsOrangeContainedBtn
            style={{ fontSize: "0.875rem" }}
            onClick={handleButtonClick}
            disabled={
              updatedSitelink.text == "" ||
              updatedSitelink.text.length > 25 ||
              updatedSitelink.descriptionLine1.length > 35 ||
              updatedSitelink.descriptionLine2.length > 35 ||
              updatedSitelink.descriptionLine1 == "" ||
              updatedSitelink.descriptionLine2 == "" ||
              updatedSitelink.finalUrl == "" ||
              updatedSitelink.finalUrl == undefined ||
              websiteValidationCheck == false
            }
          >
            Update
          </JsOrangeContainedBtn>
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(UpdateSitelink);
