import * as _ from 'lodash';
import rootReducer from './index';
import thunkMiddleware from 'redux-thunk'
import {applyMiddleware, createStore} from 'redux'
import {createMiddleware, EventsMap} from 'redux-beacon';
import logger from '@redux-beacon/logger';
import GoogleAnalytics, {trackPageView, trackEvent} from '@redux-beacon/google-analytics';
import * as types from "../actions/actionTypes"
import {HOLIDAY_PACKAGE_TRACK, REQUEST_DEMO_TRACK} from "../actions/actionTypes";


// export const history = createBrowserHistory()

const eventsMap = {
    [types.SELECT_DATE_RANGE]: trackEvent((action, prevState, nextState) => ({
        category: "DateRange",
        action: "DateRange-Change",
        value: action.dateRanges.itemValue
    })),
    [types.CHANGE_ORGANIZED_BY]: trackEvent((action) => ({
        category: 'OrganizedBy',
        action: `OrganizedBy-${action.organizeBy}`
    })),
    [types.POST_TRIGGER]: trackEvent((action) => ({
        category: 'PostTriggered',
        action: "PostTriggered-Clicked",
    })),
    [types.EXPANSION_STATUS]: trackEvent((action) => ({
        category: `TaskType ${action.data.taskTypeId} Expansion`,
        action: `TaskType ${action.data.taskTypeId} Expansion-${action.data.expansionStatus}`,
    })),
    [types.TASK_PAGE_TRACK]: trackPageView((action) => ({
        page: `${window.location}`,
        title: "Task",
        location: window.location
    })),
    [types.DASHBOARD_PAGE_TRACK]: trackPageView((action) => ({
        page: `${window.location}`,
        title: "Dashboard",
        location: window.location
    })),
    [types.MINI_SERVICE_PAGE_TRACK]: trackPageView((action) => ({
        page: `${window.location}`,
        title: `Mini Service`,
        location: window.location
    })),
    [types.REQUEST_DEMO_TRACK]: trackPageView((action) => ({
        page: `${window.location}`,
        title: `Request Demo`,
        location: window.location
    })),
    [types.REQUEST_JUMPSTART_TRACK]: trackPageView((action) => ({
        page: `${window.location}`,
        title: `Request Jumpstart`,
        location: window.location
    })),
    [types.FLOATING_CARD_TRACK]: trackEvent((action) => ({
        category: 'FloatingCard',
        label: "Floating Card Button",
        action: "FloatingCard-Clicked",
    })),
    [types.FEEDBACK_TRACK]: trackEvent((action) => ({
        category: 'Feedback',
        label: "Feeback Button",
        action: "Feedback-Clicked",
    })),
    [types.SITE_GRADER_PAGE]: trackPageView((action) => ({
        page: `${window.location}`,
        title: action.reportType == "1" ? `Site Grader Internal Report` : `Site Grader External Report`,
        location: window.location
    })),
    [types.REQUEST_EXPERT_HELP_TRACK]: trackPageView((action) => ({
        page: `${window.location}`,
        title: `Request Expert Help`,
        location: window.location
    })),
    [types.DASHBOARD_VIEW_VALUE_TRACK]: trackPageView((action) => ({
        page: `${window.location}`,
        title: `${pageNames[action.viewValue]}`,
        location: window.location
    })),
    [types.SELECT_GRAPH_DATE_RANGE]: trackEvent((action, prevState, nextState) => ({
        category: "KPI Date Range",
        action: "DateRange-Change",
        value: action.dateRanges.itemValue
    })),
    [types.UPDATE_KPI_TYPE_UI]: trackEvent((action, prevState, nextState) => ({
        category: "KPI Type Change",
        action: `KPI Graph Metric changed to ${KpiString[action.KPIType]}`,
        value: action.KPIType
    })),
    /*[types.TASKS_TABS_TRACK]: trackEvent((action, prevState, nextState) => ({
        category: "Task Page Tabs",
        action: `Task Tab changed to ${taskPageTabs[action.value]}`,
        value: action.KPIType
    })),*/
    [types.TASK_DATA_TRACK]: trackEvent((action) => ({
        category: `TaskType ${action.taskTypeId}`,
        action: `TaskType ${action.taskTypeId}`,
    })),
    [types.HOLIDAY_PACKAGE_TRACK]: trackPageView((action) => ({
        page: `${window.location}`,
        title: `Holiday Package`,
        location: window.location
    })),
    [types.HOME_PAGE]: trackPageView((action) => ({
            page: `${window.location}`,
            title: `Home Page`,
            location: window.location
        })),
    [types.GROWTH_MODEL_PAGE_TRACK]: trackPageView((action) => ({
        page: `${window.location}`,
        title: `Growth Model Page`,
        location: window.location
    })),
    [types.FEATURES_PAGE_TRACK]: trackPageView((action) => ({
        page: `${window.location}`,
        title: `Features & Benefits Page`,
        location: window.location
    })),
    [types.FREE_TOOLS_PAGE_TRACK]: trackPageView((action) => ({
        page: `${window.location}`,
        title: `Free Tools Page`,
        location: window.location
    })),
    [types.GOOGLE_ADS_GRADER_HOME_TRACK]: trackPageView((action) => ({
        page: `${window.location}`,
        title: `Google Ads Grader Home Page`,
        location: window.location
    })),
    [types.SEO_SITE_GRADER_HOME_TRACK]: trackPageView((action) => ({
        page: `${window.location}`,
        title: `SEO Site Grader Home Page`,
        location: window.location
    })),
    [types.PRICING_PAGE_TRACK]: trackPageView((action) => ({
        page: `${window.location}`,
        title: `Pricing Page`,
        location: window.location
    })),
     [types.REQUEST_DEMO_FORM_SUBMIT_TRACK]: trackEvent((action) => ({
            category: `Request demo submit`,
            action: `Request demo submit button`,
        })),
     [types.GOOGLE_ADS_FORM_SUBMIT_TRACK]: trackEvent((action) => ({
        category: `Google Ads submit`,
        action: `Google Ads submit button`,
    })),
    [types.SEO_SITE_GRADER_FORM_SUBMIT_TRACK]: trackEvent((action) => ({
        category: `SEO Site Grader submit`,
        action: `SEO Site Grader submit button`,
    })),
    [types.LEAD_GEN_TRACK]: trackPageView((action) => ({
        page: `${window.location}`,
        title: `Lead Generation Page for ${action.eCommercePlatform}`,
        location: window.location
    })),
};
const pageNames = {
    0: "Dashboard",
    1: "Tasks",
    2: "Reports",
    3: "Activity Reports",
}
const taskPageTabs = {
    0: "All",
    1: "Pending",
    2: "Completed",
}
const KpiString = {
    "RC": "R/C",
    "CR": "C/R",
    "CPO": "CPO",
    "CPL": "CPL"
};

export function sumUpImpact(list) {
    return ({
        costSavingForecast: _.sumBy(list, "costSavingForecast"),
        costForecast: _.sumBy(list, "costForecast"),
        revenueForecast: _.sumBy(list, "revenueForecast"),
        orderForecast: _.sumBy(list, "orderForecast"),
    });
}

let ga = GoogleAnalytics();
const gaMiddleware = createMiddleware(eventsMap, ga);
export const store = createStore(rootReducer, applyMiddleware(thunkMiddleware, gaMiddleware));

export const idToTaskDataList = (idList, taskDataById = store.getState().taskDataById) => idList.map(id => taskDataById[id]);

export function sumUpImpactOfIds(idList) {
    return sumUpImpact(idToTaskDataList(idList, store.getState().taskDataById));
}

export function sortTasks(tasks, taskDataById = store.getState().taskDataById) {
    const newRatio = _.chain(tasks)
        .mapValues(task => idToTaskDataList(task.dataIdList, taskDataById))
        .mapValues(list => list.filter(td => td.status == 0).length / list.length)
        .value();
    const sorted = _.sortBy(tasks, [
        t => -newRatio[t.summary.groupId],
        // t => -t.summary.totalCount,
        t => t.summary.taskTypePriority,
        t => -t.summary.impact.costSavingForecast || 0,
        t => -t.summary.impact.revenueForecast
    ]);

    /*
        const sortedSummaries = _.map(sorted, t => _.pick(t.summary, ['newRatio', 'totalCount', 'groupId', 'taskTypePriority', 'impact.costSavingForecast', 'impact.revenueForecast']));
        console.log("sortedSummaries = ", sortedSummaries);

    */
    return sorted;
}

const TaskTypes = [{
    "taskTypeId": 1,
    "taskName": "Increase campaign Bid Modifiers for performing Geos",
    "taskDescription": "Increase location bid adjustments based on Search Campaign performance to show your ad more frequently to the customers in performing countries, regions, or other geographic areas.",
    "hasAdgroupLevel": false
}, {
    "taskTypeId": 2,
    "taskName": "Add Exact match type Negative Keywords",
    "taskDescription": "Prevent competition or cross serving between campaigns within your account by adding Exact negative keywords to trigger an ad from the most appropriate ad group.",
    "hasAdgroupLevel": true
}, {
    "taskTypeId": 3,
    "taskName": "Add converting Search Queries as Keywords",
    "taskDescription": "Save cost by adding relevant search queries as Exact keywords to trigger ads from the most appropriate ad group at a lower Cost Per Click.",
    "hasAdgroupLevel": true
}, {
    "taskTypeId": 4,
    "taskName": "Add Exact match type Keywords",
    "taskDescription": "Save cost by adding relevant keywords as Exact keywords to trigger ads from the most appropriate ad group at a lower Cost Per Click.",
    "hasAdgroupLevel": true
}, {
    "taskTypeId": 5,
    "taskName": "Add only cost taking Search Queries as Negative keywords",
    "taskDescription": "Save cost by adding cost taking search queries as negative keywords to exclude irrelevant traffic and prevent spend on non-converting search queries.",
    "hasAdgroupLevel": true
}, {
    "taskTypeId": 6,
    "taskName": "Add match type variations for converting Keywords",
    "taskDescription": "Save cost by adding performing keywords with the most appropriate Match Type to trigger ads at a lower Cost Per Click.",
    "hasAdgroupLevel": true
}, {
    "taskTypeId": 7,
    "taskName": "Pause non-performing Keywords",
    "taskDescription": "Save cost by pausing keywords that are not converting into a sale and thus increasing your overall AdWords spend.",
    "hasAdgroupLevel": true
}, {
    "taskTypeId": 8,
    "taskName": "Increase Bids for low impression Keywords",
    "taskDescription": "Ensure higher visibility for your ads by taking your potential keywords into the competition by increasing their bids.",
    "hasAdgroupLevel": true
}, {
    "taskTypeId": 9,
    "taskName": "Decrease Bids for non-performing Keywords",
    "taskDescription": "Save cost by decreasing bids for keywords that aren't converting into a sale and thus, increasing your overall AdWords spend.",
    "hasAdgroupLevel": true
}, {
    "taskTypeId": 10,
    "taskName": "Pause active Adgroups with no active Keywords",
    "taskDescription": "Follow the best practice of pausing active Ad Groups without any active keywords thus identifying the potential Ad Group candidates for removal later and easing your account maintenance.",
    "hasAdgroupLevel": false
}, {
    "taskTypeId": 11,
    "taskName": "Pause active Adgroups with no active Adcopies",
    "taskDescription": "Follow the best practice of pausing active Ad Groups without any active Ad Copies, thus identifying the potential Ad Group candidates for removal later and easing your account maintenance.",
    "hasAdgroupLevel": false
}, {
    "taskTypeId": 12,
    "taskName": "Pause Keywords with Broken URLs",
    "taskDescription": "Save cost by pausing keywords having destination URLs that are broken to avoid wasting money on AdWords clicks that have no chance of converting.",
    "hasAdgroupLevel": true
}, {
    "taskTypeId": 13,
    "taskName": "Pause Adcopies with Broken URLs",
    "taskDescription": "Save cost by pausing Ad Copies having destination URLs that are broken to avoid wasting money on AdWords clicks that have no chance of converting.",
    "hasAdgroupLevel": true
}, {
    "taskTypeId": 14,
    "taskName": "Remove conflicting Negative Keywords",
    "taskDescription": "Eliminate Ad Group level negative keywords that are blocking targeted keywords and preventing your ads from appearing on related searches.",
    "hasAdgroupLevel": true
}, {
    "taskTypeId": 15,
    "taskName": "Optimize spend by decreasing Bids for Mobile devices",
    "taskDescription": "Decrease device bid adjustments based on Search Campaigns performance to show your ad less frequently for searches that occur on mobile devices.",
    "hasAdgroupLevel": false
}, {
    "taskTypeId": 16,
    "taskName": "Increase Bids based on AOV",
    "taskDescription": "Drive more traffic to the higher value keywords by increasing their bids and thus, helping you generate more revenue.",
    "hasAdgroupLevel": true
}, {
    "taskTypeId": 17,
    "taskName": "Increase Bid Modifiers for Mobile devices",
    "taskDescription": "Increase device bid adjustments based on Search Campaigns performance to show your ad more frequently for searches that occur on mobile devices.",
    "hasAdgroupLevel": false
}, {
    "taskTypeId": 19,
    "taskName": "Decrease Bids based on AOV",
    "taskDescription": "",
    "hasAdgroupLevel": true
}, {
    "taskTypeId": 21,
    "taskName": "Decrease Bid Modifiers for non-performing Geo locations",
    "taskDescription": "Decrease location bid adjustments based on Search Campaigns' performance to show your ad less frequently to the customers in non-performing countries, regions, or other geographic areas.",
    "hasAdgroupLevel": false
}, {
    "taskTypeId": 22,
    "taskName": "Remove conflicting campaign Negative Keywords",
    "taskDescription": "Eliminate campaign level negative keywords that are blocking targeted keywords and preventing your ads from appearing on related searches.",
    "hasAdgroupLevel": false
}, {
    "taskTypeId": 23,
    "taskName": "Increase Bid Modifiers for performing Geo locations",
    "taskDescription": "Increase location bid adjustments based on Shopping Campaigns performance to show your ad more frequently to the customers in performing countries, regions, or other geographic areas.",
    "hasAdgroupLevel": false
}, {
    "taskTypeId": 24,
    "taskName": "Decrease Bid Modifiers for non-performing Geo locations",
    "taskDescription": "Decrease location bid adjustments based on Shopping Campaigns' performance to show your ad less frequently to customers in non-performing countries, regions, or other geographic areas.",
    "hasAdgroupLevel": false
}, {
    "taskTypeId": 25,
    "taskName": "Increase Bid Modifiers for Mobile devices",
    "taskDescription": "Increase device bid adjustments based on Shopping Campaigns' performance to show your ad more frequently for searches that occur on mobile devices.",
    "hasAdgroupLevel": false
}, {
    "taskTypeId": 26,
    "taskName": "Optimize spend by decreasing Bids for Mobile devices",
    "taskDescription": "Decrease device bid adjustments based on the Shopping Campaign's performance to show your ad less frequently for searches that occur on mobile devices.",
    "hasAdgroupLevel": false
}, {
    "taskTypeId": 27,
    "taskName": "Pause active Adgroups with conflicting Product Groups",
    "taskDescription": "Reduce internal competition between conflicting Product Groups in Shopping Campaigns to ensure the ad is triggered from the most appropriate Ad Group.",
    "hasAdgroupLevel": false
}, {
    "taskTypeId": 28,
    "taskName": "Restructure Product Group model",
    "taskDescription": "Subdivide your Product Groups based on the attribute's performance to ensure better control on the bidding of your products.",
    "hasAdgroupLevel": true
}, {
    "taskTypeId": 29,
    "taskName": "Increase Bids for Product Groups",
    "taskDescription": "Improve the visibility of your Product Ads by increasing the bids for a Product Group based on its performance.",
    "hasAdgroupLevel": true
}, {
    "taskTypeId": 30,
    "taskName": "Decrease Bids for Product Groups",
    "taskDescription": "Optimize spend of your Shopping Campaigns by making the ideal bid modification on your Product Groups based on its performance.",
    "hasAdgroupLevel": true
}, {
    "taskTypeId": 31,
    "taskName": "Restructure Product Groups to remove cross serving",
    "taskDescription": "Remove cross serving of products between Ad Groups in the same shopping campaigns to ensure the ad is triggered from the most appropriate Ad Group.",
    "hasAdgroupLevel": true
}, {
    "taskTypeId": 32,
    "taskName": "New Product Group Opportunities",
    "taskDescription": "Add any new product from your Shopping Feed to the appropriate Product Group to ensure proper categorization in your Shopping Campaigns.",
    "hasAdgroupLevel": true
}, {
    "taskTypeId": 33,
    "taskName": "Increase budget for performing campaigns",
    "taskDescription": "Increase your Search Campaigns' budget based on their performance so the best performing campaigns are allocated the required budget and the ad spend is apportioned accordingly.",
    "hasAdgroupLevel": false
}, {
    "taskTypeId": 34,
    "taskName": "Decrease budget for non-performing campaigns",
    "taskDescription": "Decrease your Search Campaigns' budget based on their performance so that the non-performing campaigns' spend is reduced and the ad spend is apportioned accordingly.",
    "hasAdgroupLevel": false
}, {
    "taskTypeId": 35,
    "taskName": "Change Hourly Bid Modifiers for Performing Hours",
    "taskDescription": "Increase your ad exposure during best performing times of the week to make sure you're maximizing account performance by spending at the right time. ",
    "hasAdgroupLevel": false
}, {
    "taskTypeId": 36,
    "taskName": "Start Shopping Campaign",
    "taskDescription": "Decrease your ad exposure during non-performing times of the week to make sure that you are optimizing cost by not spending at the wrong time.",
    "hasAdgroupLevel": false
}, {
    "taskTypeId": 41,
    "taskName": "Remove Plussed Adgroup Negative Keywords",
    "taskDescription": "Follow the best practice of negative keyword configuration by removing negative keywords with modifiers",
    "hasAdgroupLevel": true
}, {
    "taskTypeId": 42,
    "taskName": "Remove Plussed Campaign Negative Keywords",
    "taskDescription": "Follow the best practice of negative keyword configuration by removing negative keywords with modifiers",
    "hasAdgroupLevel": false
}];

