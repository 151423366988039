import React, { Fragment, useState, useEffect } from "react";
import Insights from "./insights";
import icons from "../../components/icons";
import { Paper } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="xl" />;

function SixMetrics(props) {
  const { classes, width, data, googleAdsHome ,isWHSExternal} = props;

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <Fragment>
      {data.map((data, i) => (
        <Insights
          key={i}
          leftIcon={screenWidth <= 899 ? true : data.leftAlign}
          head={data.head}
          icon={
            googleAdsHome ? (
              icons[data.img](screenWidth <= 899 ? "100%" : "auto")
            ): isWHSExternal ? (
              <img
                src={icons[data.img]}
                alt={data.title}
                className={classes[data.img]}
                screenWidth={screenWidth <= 899 ? "100%" : "auto"}
              />
            ) : (
              <img
                src={icons[data.img]}
                alt={data.title}
                className={classes[data.img]}
                screenWidth={screenWidth <= 899 ? "100%" : "auto"}
              />
            )
          }
          title={data.title}
          para={data.para}
          bg={data.bg}
          subPara2={data.para2}
        />
      ))}
    </Fragment>
  );
}

export default withWidth()(SixMetrics);
       