import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import icons from "../../components/icons";
import { NewOrangeLargeBtn } from "../../components/buttons";
import { updateJsAccountStatus } from "../../actions/jumpstart";
import { connect } from "react-redux";
import withStyles from '@mui/styles/withStyles';
import MySnackbarWrapper from "../snackbars";
import * as _ from "lodash";

const styles = (theme) => ({});

class Step2 extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      goLive: true,
      alert: false,
      message: "Something went wrong",
      variantType: "error",
    };
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    let response = await this.props.updateJsAccountStatus();
    console.log("response=", response);
    if (response && response.data && response.data.isError) {
      this.setState({
        alert: true,
        message: "Something went wrong",
        variantType: "error",
      });
    } else {
      this.setState({ alert: false });
    }
  };
  handleCloseAlert = async () => {
    await this.setState({ alert: false, message: "", variantType: "info" });
  };

  render() {
    const { jumpstartInfo, theme, userId } = this.props;
    const { statusMessage, authorizationUrl } = jumpstartInfo;
    const { goLive } = this.state;
    console.log("jumpstartInfo step2", jumpstartInfo);
    return (
      <Grid container justifyContent={"center"}>
        <Grid item md={12}>
          <Typography align={"center"}> {icons.accountDone}</Typography>
        </Grid>
        <Grid item md={12}>
          <Typography
            variant={"body1"}
            align={"center"}
            color={"textPrimary"}
            gutterBottom
          >
            {statusMessage}
          </Typography>
          <Typography
            variant={"body1"}
            align={"center"}
            color={"textPrimary"}
            gutterBottom
          >
            Download your structure &nbsp;
            <a href={`${authorizationUrl}/${userId}/pdf`} target="_blank">
              here&nbsp;
            </a>
            <i className="fa fa-file-pdf-o" aria-hidden="true"></i>&nbsp; and
            review it.
          </Typography>
          <Typography
            variant={"body1"}
            align={"center"}
            color={"textPrimary"}
            gutterBottom
          >
            Shoot us an email at{" "}
            <a href={"mailto:support@lxrguide.com"}>support@lxrguide.com</a> if
            you have any questions or comments.
          </Typography>
          <Typography
            variant={"body1"}
            align={"center"}
            color={"textPrimary"}
            gutterBottom
          >
            If everything looks good, check off the button below!
          </Typography>
          <form onSubmit={this.handleSubmit}>
            <Typography color={"textPrimary"} align={"center"}>
              <FormGroup row className={"justifyCenter"}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={goLive}
                      onChange={(event, selected) =>
                        this.setState({ goLive: selected })
                      }
                      // value="checkedA"
                    />
                  }
                  label="Ready to GO-LIVE?"
                />
              </FormGroup>
            </Typography>
            <Typography component={"div"} align={"center"}>
              <NewOrangeLargeBtn disabled={goLive == false} type={"submit"}>
                Proceed
              </NewOrangeLargeBtn>
            </Typography>
          </form>
          <MySnackbarWrapper
            open={this.state.alert}
            onClose={this.handleCloseAlert}
            message={this.state.message}
            verticalAlign={"bottom"}
            horizontalAlign={"right"}
            duration={6000}
            variant={this.state.variantType}
          />
        </Grid>
      </Grid>
    );
  }
}

Step2.propTypes = {};
const mapDispatchToProps = (dispatch) => ({
  updateJsAccountStatus: () => {
    return dispatch(updateJsAccountStatus());
  },
});
const mapStateToProps = (state, ownProps) => ({
  userId: !_.isEmpty(state.userInformation.userInfo)
    ? state.userInformation.userInfo.userId
    : 0,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Step2));
