import { Grid, LinearProgress, Paper, Typography } from "@mui/material";
import React, { Fragment } from "react";
import * as PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';
import * as types from "../../actions/actionTypes";
import classNames from "classnames";
import Tooltip from "@mui/material/Tooltip";

function scoresColor(score, classes) {
  let color = classes.red;
  if (score == 0 || (score > 0 && score <= 49)) {
    color = classes.red;
  } else if (score == 50 || (score > 50 && score <= 69)) {
    color = classes.yellow;
  } else if (score >= 70) {
    color = classes.green;
  }
  return color;
}

function ScoreCard(props) {
  const { classes } = props;
  const onPageScore =
    props.data.onPage != null ? props.data.onPage.onPageScore : 0;
  const mobileScore =
    props.data.mobile != null ? props.data.mobile.mobileScore : 0;
  const speedScore = props.data.speed != null ? props.data.speed.speedScore : 0;
  const linksScore = props.data.links != null ? props.data.links.linksScore : 0;
  const technicalScore =
    props.data.technical != null ? props.data.technical.technicalScore : 0;
  const socialScore =
    props.data.social != null ? props.data.social.socialScore : 0;

  return (
    <Paper
      className={classNames(classes.padding2X, classes.paper)}
      style={{ minHeight: "55vh" }}
    >
      <Typography
        variant={"subheading"}
        className={classes.montserrat}
        color={"textSecondary"}
        // color={"textSecondary"}
      >
        <b>Score</b>
      </Typography>
      <div>
        <br />
        <Grid
          container
          justifyContent={"center"}
          className={classes.padding2X}
          alignItems={"center"}
          spacing={2}
        >
          <Grid item md={3} sm={3} xs={12}>
            <Tooltip
              title={
                <Fragment>
                  <Typography color="inherit">
                    On page scores show how well the site in its entirety and
                    each page is optimized.
                  </Typography>
                </Fragment>
              }
            >
              <Typography
                align={"left"}
                className={classes.montserrat}
                color={"textSecondary"}
              >
                <b>ON PAGE</b>
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item md={4} sm={4} xs={12}>
            <LinearProgress
              classes={{
                root: classes.progressBarRoot,
                barColorPrimary: scoresColor(onPageScore, classes),
                determinate: classes.determine,
              }}
              variant="determinate"
              value={onPageScore}
            />
          </Grid>
          <Grid item md={3} sm={3} xs={12}>
            <Typography
              align={"center"}
              className={classes.montserrat}
              color={"textSecondary"}
            >
              <b>{onPageScore}/100</b>
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent={"center"}
          className={classes.padding2X}
          alignItems={"center"}
          spacing={2}
        >
          <Grid item md={3} sm={3} xs={12}>
            <Tooltip
              title={
                <Fragment>
                  <Typography color="inherit">
                    Mobile scores tell you how well your site displays on
                    different screen sizes and devices.
                  </Typography>
                </Fragment>
              }
            >
              <Typography
                align={"left"}
                className={classes.montserrat}
                color={"textSecondary"}
              >
                <b>MOBILE</b>
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item md={4} sm={4} xs={12}>
            <LinearProgress
              classes={{
                root: classes.progressBarRoot,
                barColorPrimary: scoresColor(mobileScore, classes),
                determinate: classes.determine,
              }}
              variant="determinate"
              value={mobileScore}
            />
          </Grid>
          <Grid item md={3} sm={3} xs={12}>
            <Typography
              align={"center"}
              className={classes.montserrat}
              color={"textSecondary"}
            >
              <b>{mobileScore}/100</b>
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent={"center"}
          className={classes.padding2X}
          alignItems={"center"}
          spacing={2}
        >
          <Grid item md={3} sm={3} xs={12}>
            <Tooltip
              title={
                <Fragment>
                  <Typography color="inherit">
                    A high score means your site and pages load quickly across
                    all devices. The faster your page loads, the more visitors
                    you get.
                  </Typography>
                </Fragment>
              }
            >
              <Typography
                align={"left"}
                className={classes.montserrat}
                color={"textSecondary"}
              >
                <b>SPEED</b>
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item md={4} sm={4} xs={12}>
            <LinearProgress
              classes={{
                root: classes.progressBarRoot,
                barColorPrimary: scoresColor(speedScore, classes),
                determinate: classes.determine,
              }}
              variant="determinate"
              value={speedScore}
            />
          </Grid>
          <Grid item md={3} sm={3} xs={12}>
            <Typography
              align={"center"}
              className={classes.montserrat}
              color={"textSecondary"}
            >
              <b>{speedScore}/100</b>
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent={"center"}
          className={classes.padding2X}
          alignItems={"center"}
          spacing={2}
        >
          <Grid item md={3} sm={3} xs={12}>
            <Tooltip
              title={
                <Fragment>
                  <Typography color="inherit">
                    The quality of links, both inbound and outbound, determine
                    page rank. Link score is critical.
                  </Typography>
                </Fragment>
              }
            >
              <Typography
                align={"left"}
                className={classes.montserrat}
                color={"textSecondary"}
              >
                <b>LINKS</b>
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item md={4} sm={4} xs={12}>
            <LinearProgress
              classes={{
                root: classes.progressBarRoot,
                barColorPrimary: scoresColor(linksScore, classes),
                determinate: classes.determine,
              }}
              variant="determinate"
              value={linksScore}
            />
          </Grid>
          <Grid item md={3} sm={3} xs={12}>
            <Typography
              align={"center"}
              className={classes.montserrat}
              color={"textSecondary"}
            >
              <b>{linksScore}/100</b>
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent={"center"}
          className={classes.padding2X}
          alignItems={"center"}
          spacing={2}
        >
          <Grid item md={3} sm={3} xs={12}>
            <Tooltip
              title={
                <Fragment>
                  <Typography color="inherit">
                    Errors make your pages invisible to bots and visitors. The
                    more errors you have, the less visible your page is, which
                    turns into less traffic.
                  </Typography>
                </Fragment>
              }
            >
              <Typography
                align={"left"}
                className={classes.montserrat}
                color={"textSecondary"}
              >
                <b>TECHNICAL</b>
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item md={4} sm={4} xs={12}>
            <LinearProgress
              classes={{
                root: classes.progressBarRoot,
                barColorPrimary: scoresColor(technicalScore, classes),
                determinate: classes.determine,
              }}
              variant="determinate"
              value={technicalScore}
            />
          </Grid>
          <Grid item md={3} sm={3} xs={12}>
            <Typography
              align={"center"}
              className={classes.montserrat}
              color={"textSecondary"}
            >
              <b>{technicalScore}/100</b>
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent={"center"}
          className={classes.padding2X}
          alignItems={"center"}
          spacing={2}
        >
          <Grid item md={3} sm={3} xs={12}>
            <Tooltip
              title={
                <Fragment>
                  <Typography color="inherit">
                    Social score help you understand how optimize is your social
                    media marketing
                  </Typography>
                </Fragment>
              }
            >
              <Typography
                align={"left"}
                className={classes.montserrat}
                color={"textSecondary"}
              >
                <b>SOCIAL</b>
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item md={4} sm={4} xs={12}>
            <LinearProgress
              classes={{
                root: classes.progressBarRoot,
                barColorPrimary: scoresColor(socialScore, classes),
                determinate: classes.determine,
              }}
              variant="determinate"
              value={socialScore}
            />
          </Grid>
          <Grid item md={3} sm={3} xs={12}>
            <Typography
              align={"center"}
              className={classes.montserrat}
              color={"textSecondary"}
            >
              <b>{socialScore}/100</b>
            </Typography>
          </Grid>
        </Grid>
        <br />
      </div>
    </Paper>
  );
}

ScoreCard.propTypes = { classes: PropTypes.any };
const scoreCardStyles = (theme) => ({
  progressBarRoot: {
    height: 8,
    borderRadius: 5,
    // color
  },
  red: {
    backgroundColor: theme.palette.graderGraphColors.red,
  },
  yellow: {
    backgroundColor: theme.palette.graderGraphColors.yellow,
  },
  green: {
    backgroundColor: theme.palette.graderGraphColors.green,
  },
  determinate: {
    backgroundColor: "#E6E6E6",
  },
  customCard: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    boxShadow: "unset",
    backgroundColor: theme.palette.graderGraphColors.graphBg,
  },
  padding2X: { padding: theme.spacing(1) },
  paper: { boxShadow: "unset" },

  montserrat: { fontFamily: "Montserrat" },
});

export default withStyles(scoreCardStyles)(ScoreCard);
