import * as React from "react";

function SvgReports(props) {
  return (
    <svg width={18} height={18} fill="none" {...props}>
      <path
        d="M12.143 1.5c2.317 0 3.607 1.335 3.607 3.622v7.748c0 2.325-1.29 3.63-3.607 3.63H5.857c-2.28 0-3.607-1.305-3.607-3.63V5.123C2.25 2.834 3.578 1.5 5.857 1.5h6.286zM6.06 11.805a.596.596 0 00-.563.908c.12.187.338.3.563.27h5.88c.3-.03.525-.286.525-.585a.588.588 0 00-.525-.593H6.06zm5.88-3.42H6.06a.586.586 0 000 1.17h5.88a.586.586 0 000-1.17zM8.302 4.987H6.06v.007a.585.585 0 000 1.17h2.242a.588.588 0 00.585-.593.586.586 0 00-.585-.585z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgReports;
