import imageBg from "../../img/home_bg.svg";

const requestDemoStyles = (theme) => ({
  card: {
    boxShadow: "unset",
  },
  gridBg: {
    background: `url(${imageBg})`,
    [theme.breakpoints.up("md")]: {
      backgroundSize: "contain",
      backgroundPosition: "top center",
      backgroundColor: theme.palette.common.darkGreybg,
    },
    [theme.breakpoints.up("xl")]: {
      backgroundRepeat: "no-repeat",
    },
    [theme.breakpoints.down("xl")]: {
      background: "unset",
    },
  },
  cardContent: {
    padding: 0,
    // paddingTop: theme.spacing(3),
    paddingBottom: 0,
  },
  sectionBg: {
    backgroundColor: theme.palette.common.darkGreybg,
    height: 540,
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(2),
  },
  section2: {
    backgroundColor: theme.palette.common.white,
    // height: 400,
    paddingTop: theme.spacing(3.5),
    paddingBottom: theme.spacing(2.5),
    // paddingRight:theme.spacing(4),
    // paddingLeft:theme.spacing(4),
  },
  section3: {
    backgroundColor: theme.palette.common.darkGreybg,
    minHeight: 400,
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    paddingRight: theme.spacing(8),
    paddingLeft: theme.spacing(8),
  },
  secCard: {
    border: "1px solid " + theme.palette.common.darkGrey,
    boxSizing: "border-box",
    borderRadius: 10,
    height: 400,
    boxShadow: "unset",
    background: "unset",
  },
  section4: {
    backgroundColor: theme.palette.common.white,
    minHeight: 400,
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    paddingRight: theme.spacing(8),
    paddingLeft: theme.spacing(8),
  },
  menuItemText: {},
  mainCaption: {
    margin: "0 auto",
    width: "45%",
    [theme.breakpoints.down("lg")]: {
      width: "100%",
    },
  },
  mainTitle: {
    margin: "0 auto",
    width: "80%",
  },
  heading: {
    color: "textPrimary",
    textAlign: "center",
    fontWeight: "700",
    textTransform: "uppercase",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.25rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2.75rem",
    },
  },
});

export default requestDemoStyles;
