import React, { Fragment, useEffect, useState } from "react";
import { Grid, Typography, LinearProgress } from "@mui/material";
import { withStyles } from "@mui/styles";
import icons from "../../components/icons";
import "./WebsiteHealthScoreCard.css";
import {
  SectionInfoWHS,
  SubSectionHeading,
  GoodSubSectionComment,
  ImproveSubSectionComment,
  ErrorSubSectionComment,
  NoDataSubSectionComment,
} from "../../components/typography";

const styles = (theme) => ({});

function Performance_WHC({ performanceData }) {
  console.log("inside Performance_WHC-->", performanceData);
  const GOOD = "Looks good";
  const IMPROVED = "Can be improved";
  const ERROR = "Critical error found!";
  const NO_DATA = "Not available";
  const [firstContentfulPaint, setFirstContentfulPaint] = useState(
    performanceData ? performanceData.firstContentfulPaint : null
  );
  const [largestContentfulPaint, setLargestContentfulPaint] = useState(
    performanceData ? performanceData.largestContentfulPaint : null
  );
  const [renderBlockingResources, setRenderBlockingResources] = useState();
  // performanceData ? performanceData.renderBlockingResources : null
  const [unminified_CSS, setUnminified_CSS] = useState(
    performanceData ? performanceData.unminified_CSS : null
  );
  const [unminified_JS, setunminified_JS] = useState(
    performanceData ? performanceData.unminified_JS : null
  );
  const [numberOfRedirectUrls, setNumberOfRedirectUrls] = useState(
    performanceData ? performanceData.numberOfRedirectUrls : null
  );
  const [websiteCompression, setWebsiteCompression] = useState(
    performanceData ? performanceData.websiteCompression : null
  );
  const [performanceScore, setPerformanceScore] = useState(
    performanceData ? performanceData.performanceScore : null
  );
  const [serverResponseTime, setServerResponseTime] = useState(
    performanceData ? performanceData.serverResponseTime : null
  );

  //Descriptions
  const [serverResponseTimeDescription, setServerResponseTimeDescription] =
    useState(
      performanceData ? performanceData.serverResponseTimeDescription : null
    );
  const [numberOfRedirectUrlsDescription, setnumberOfRedirectUrlsDescription] =
    useState(
      performanceData ? performanceData.numberOfRedirectUrlsDescription : null
    );
  const [websiteCompressionDescription, setWebsiteCompressionDescription] =
    useState(
      performanceData ? performanceData.websiteCompressionDescription : null
    );
  const [
    renderBlockingResourcesDescription,
    setRenderBlockingResourcesDescription,
  ] = useState(
    performanceData ? performanceData.renderBlockingResourcesDescription : null
  );
  const [firstContentfulPaintDescription, setFirstContentfulPaintDescription] =
    useState(
      performanceData ? performanceData.firstContentfulPaintDescription : null
    );
  const [
    largestContentfulPaintDescription,
    setLargestContentfulPaintDescription,
  ] = useState(
    performanceData ? performanceData.largestContentfulPaintDescription : null
  );
  const [unminified_CSSDescription, setUnminified_CSSDescription] = useState(
    performanceData ? performanceData.unminified_CSSDescription : null
  );
  const [unminified_JSDescription, setUnminified_JSDescription] = useState(
    performanceData ? performanceData.unminified_JSDescription : null
  );
  useEffect(() => {
    if (performanceData) {
      setFirstContentfulPaint(
        performanceData.firstContentfulPaint
          ? performanceData.firstContentfulPaint
          : null
      );
      setLargestContentfulPaint(
        performanceData.largestContentfulPaint
          ? performanceData.largestContentfulPaint
          : null
      );
      setRenderBlockingResources(
        performanceData.renderBlockingResources !== undefined
          ? performanceData.renderBlockingResources
          : null
      );
      setUnminified_CSS(
        performanceData.unminified_CSS ? performanceData.unminified_CSS : null
      );
      setunminified_JS(
        performanceData.unminified_JS ? performanceData.unminified_JS : null
      );
      setNumberOfRedirectUrls(
        performanceData.numberOfRedirectUrls !== undefined
          ? performanceData.numberOfRedirectUrls
          : null
      );
      setWebsiteCompression(
        performanceData.websiteCompression !== undefined
          ? performanceData.websiteCompression
          : null
      );
      setPerformanceScore(
        performanceData.performanceScore
          ? performanceData.performanceScore
          : null
      );
      setServerResponseTime(
        performanceData.serverResponseTime
          ? performanceData.serverResponseTime
          : null
      );
      setServerResponseTimeDescription(
        performanceData.serverResponseTimeDescription
          ? performanceData.serverResponseTimeDescription
          : null
      );
      setnumberOfRedirectUrlsDescription(
        performanceData.numberOfRedirectUrlsDescription
          ? performanceData.numberOfRedirectUrlsDescription
          : null
      );
      setWebsiteCompressionDescription(
        performanceData.websiteCompressionDescription
          ? performanceData.websiteCompressionDescription
          : null
      );
      setRenderBlockingResourcesDescription(
        performanceData.renderBlockingResourcesDescription
          ? performanceData.renderBlockingResourcesDescription
          : null
      );
      setFirstContentfulPaintDescription(
        performanceData.firstContentfulPaintDescription
          ? performanceData.firstContentfulPaintDescription
          : null
      );
      setLargestContentfulPaintDescription(
        performanceData.largestContentfulPaintDescription
          ? performanceData.largestContentfulPaintDescription
          : null
      );
      setUnminified_JSDescription(
        performanceData.unminified_JSDescription
          ? performanceData.unminified_JSDescription
          : null
      );
      setUnminified_CSSDescription(
        performanceData.unminified_CSSDescription
          ? performanceData.unminified_CSSDescription
          : null
      );
    }
  }, [performanceData]);

  console.log(
    "firstContentfulPaint,largestContentfulPaint,numberOfRedirectUrls,renderBlockingResources,unminified_CSS,unminified_JS,websiteCompression",
    firstContentfulPaint,
    largestContentfulPaint,
    numberOfRedirectUrls,
    renderBlockingResources,
    unminified_CSS,
    unminified_JS,
    websiteCompression
  );

  const performanceInfo =
    "Performance indicates how fast and efficiently a website loads and responds to user interactions. It ensures a smooth and speedy browsing experience, allowing users to access content and perform tasks without delays or frustration. Most users will tolerate wait times of 2-3 seconds before abandonment rate increases. This should be the target for most website owners.";

  return (
    <Fragment>
      <Grid
        container
        direction="row"
        className="sectionInfo"
        alignItems="center"
      >
        <Grid item xs={3} sm={2} md={1} lg={1} xl={1} className="grid-item-css">
          {icons.WHSPerformanceInfo}
        </Grid>
        <Grid item xs={9} sm={10} md={11} lg={11} xl={11}>
          <SectionInfoWHS>{performanceInfo}</SectionInfoWHS>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-start"
        columnSpacing={3}
        style={{ marginTop: "2%" }}
      >
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          {/* URL Redirects */}
          <div className="subsection-css">
            <SubSectionHeading>URL Redirects</SubSectionHeading>
            <div
              className={
                numberOfRedirectUrls == 0
                  ? "looksGoodBgColor"
                  : numberOfRedirectUrls > 0 && numberOfRedirectUrls <= 40
                  ? "improvedBgColor"
                  : numberOfRedirectUrls > 40
                  ? "errorBgColor"
                  : "noDataBgColor"
              }
            >
              {numberOfRedirectUrls == 0 ? (
                <GoodSubSectionComment>{GOOD}</GoodSubSectionComment>
              ) : numberOfRedirectUrls > 0 && numberOfRedirectUrls <= 40 ? (
                <ImproveSubSectionComment>{IMPROVED}</ImproveSubSectionComment>
              ) : numberOfRedirectUrls > 40 ? (
                <ErrorSubSectionComment>{ERROR}</ErrorSubSectionComment>
              ) : (
                <NoDataSubSectionComment>{NO_DATA}</NoDataSubSectionComment>
              )}
            </div>
          </div>
          <div
            className={
              numberOfRedirectUrls == 0
                ? "subsection-info-good"
                : numberOfRedirectUrls > 0 && numberOfRedirectUrls <= 40
                ? "subsection-info-improve"
                : numberOfRedirectUrls > 40
                ? "subsection-info-error"
                : "subsection-info-noData"
            }
          >
            <Typography variant="subtitle2" className="wordBreakTypography">
              {numberOfRedirectUrlsDescription}
            </Typography>
          </div>
          {/* Render Blocking Resources */}
          <div className="subsection-css">
            <SubSectionHeading>Render-Blocking Resources</SubSectionHeading>
            <div
              className={
                renderBlockingResources == 0
                  ? "looksGoodBgColor"
                  : renderBlockingResources != 0
                  ? "errorBgColor"
                  : "noDataBgColor"
              }
            >
              {renderBlockingResources == 0 ? (
                <GoodSubSectionComment>{GOOD}</GoodSubSectionComment>
              ) : renderBlockingResources != 0 ? (
                <ErrorSubSectionComment>{ERROR}</ErrorSubSectionComment>
              ) : (
                <NoDataSubSectionComment>{NO_DATA}</NoDataSubSectionComment>
              )}
            </div>
          </div>
          <div
            className={
              renderBlockingResources == 0
                ? "subsection-info-good"
                : renderBlockingResources != 0
                ? "subsection-info-error"
                : "subsection-info-noData"
            }
          >
            <Typography variant="subtitle2" className="wordBreakTypography">
              {renderBlockingResourcesDescription}
            </Typography>
          </div>
          {/* Server response */}
          <div className="subsection-css">
            <SubSectionHeading>Server Response</SubSectionHeading>
            <div
              className={
                serverResponseTime <= 100
                  ? "looksGoodBgColor"
                  : serverResponseTime > 100 && serverResponseTime <= 500
                  ? "improvedBgColor"
                  : serverResponseTime > 500
                  ? "errorBgColor"
                  : "noDataBgColor"
              }
            >
              {serverResponseTime <= 100 ? (
                <GoodSubSectionComment>{GOOD}</GoodSubSectionComment>
              ) : serverResponseTime > 100 && serverResponseTime <= 500 ? (
                <ImproveSubSectionComment>{IMPROVED}</ImproveSubSectionComment>
              ) : serverResponseTime > 500 ? (
                <ErrorSubSectionComment>{ERROR}</ErrorSubSectionComment>
              ) : (
                <NoDataSubSectionComment>{NO_DATA}</NoDataSubSectionComment>
              )}
            </div>
          </div>
          <div
            className={
              serverResponseTime <= 100
                ? "subsection-info-good"
                : serverResponseTime > 100 && serverResponseTime <= 500
                ? "subsection-info-improve"
                : serverResponseTime > 500
                ? "subsection-info-error"
                : "subsection-info-noData"
            }
          >
            <Typography variant="subtitle2" className="wordBreakTypography">
              {serverResponseTimeDescription}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          {/* Minification of JS*/}
          <div className="subsection-css">
            <SubSectionHeading>Minification of JS</SubSectionHeading>
            <div
              className={
                unminified_JS == null
                  ? "noDataBgColor"
                  : unminified_JS == "0"
                  ? "looksGoodBgColor"
                  : unminified_JS != "0"
                  ? "errorBgColor"
                  : "noDataBgColor"
              }
            >
              {unminified_JS == null ? (
                <NoDataSubSectionComment>{NO_DATA}</NoDataSubSectionComment>
              ) : unminified_JS == "0" ? (
                <GoodSubSectionComment>{GOOD}</GoodSubSectionComment>
              ) : unminified_JS != "0" ? (
                <ErrorSubSectionComment>{ERROR}</ErrorSubSectionComment>
              ) : (
                <NoDataSubSectionComment>{NO_DATA}</NoDataSubSectionComment>
              )}
            </div>
          </div>
          <div
            className={
              unminified_JS == null
                ? " subsection-info-noData"
                : unminified_JS == "0"
                ? "subsection-info-good"
                : unminified_JS != "0"
                ? "subsection-info-error"
                : "subsection-info-noData"
            }
          >
            <Typography variant="subtitle2" className="wordBreakTypography">
              {unminified_JSDescription}
            </Typography>
          </div>

          {/* Minification of CSS*/}
          <div className="subsection-css">
            <SubSectionHeading>Minification of CSS</SubSectionHeading>
            <div
              className={
                unminified_CSS == null
                  ? "noDataBgColor"
                  : unminified_CSS == "0"
                  ? "looksGoodBgColor"
                  : unminified_CSS != "0"
                  ? "errorBgColor"
                  : "noDataBgColor"
              }
            >
              {unminified_CSS == null ? (
                <NoDataSubSectionComment>{NO_DATA}</NoDataSubSectionComment>
              ) : unminified_CSS == "0" ? (
                <GoodSubSectionComment>{GOOD}</GoodSubSectionComment>
              ) : unminified_CSS != "0" ? (
                <ErrorSubSectionComment>{ERROR}</ErrorSubSectionComment>
              ) : (
                <NoDataSubSectionComment>{NO_DATA}</NoDataSubSectionComment>
              )}
            </div>
          </div>
          <div
            className={
              unminified_CSS == null
                ? "subsection-info-noData"
                : unminified_CSS == "0"
                ? "subsection-info-good"
                : unminified_CSS != "0"
                ? "subsection-info-error"
                : "subsection-info-noData"
            }
          >
            <Typography variant="subtitle2" className="wordBreakTypography">
              {unminified_CSSDescription}
            </Typography>
          </div>
          {/* Website Compression */}
          <div className="subsection-css">
            <SubSectionHeading>Website Compression</SubSectionHeading>
            <div
              className={
                websiteCompression
                  ? "looksGoodBgColor"
                  : !websiteCompression
                  ? "errorBgColor"
                  : "noDataBgColor"
              }
            >
              {websiteCompression ? (
                <GoodSubSectionComment>{GOOD}</GoodSubSectionComment>
              ) : !websiteCompression ? (
                <ErrorSubSectionComment>{ERROR}</ErrorSubSectionComment>
              ) : (
                <NoDataSubSectionComment>{NO_DATA}</NoDataSubSectionComment>
              )}
            </div>
          </div>
          <div
            className={
              websiteCompression
                ? "subsection-info-good"
                : !websiteCompression
                ? "subsection-info-error"
                : "subsection-info-noData"
            }
          >
            <Typography variant="subtitle2" className="wordBreakTypography">
              {websiteCompressionDescription}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          {/* FCP */}
          <div className="subsection-css">
            <SubSectionHeading>First Contentful Paint (FCP)</SubSectionHeading>
            <div
              className={
                firstContentfulPaint == null
                  ? "noDataBgColor"
                  : firstContentfulPaint <= 1.8
                  ? "looksGoodBgColor"
                  : firstContentfulPaint > 1.8 && firstContentfulPaint <= 3
                  ? "improvedBgColor"
                  : firstContentfulPaint > 3
                  ? "errorBgColor"
                  : "noDataBgColor"
              }
            >
              {firstContentfulPaint == null ? (
                <NoDataSubSectionComment>{NO_DATA}</NoDataSubSectionComment>
              ) : firstContentfulPaint <= 1.8 ? (
                <GoodSubSectionComment>{GOOD}</GoodSubSectionComment>
              ) : firstContentfulPaint > 1.8 && firstContentfulPaint <= 3 ? (
                <ImproveSubSectionComment>{IMPROVED}</ImproveSubSectionComment>
              ) : firstContentfulPaint > 3 ? (
                <ErrorSubSectionComment>{ERROR}</ErrorSubSectionComment>
              ) : (
                <NoDataSubSectionComment>{NO_DATA}</NoDataSubSectionComment>
              )}
            </div>
          </div>
          <div
            className={
              firstContentfulPaint == null
                ? "subsection-info-noData"
                : firstContentfulPaint <= 1.8
                ? "subsection-info-good"
                : firstContentfulPaint > 1.8 && firstContentfulPaint <= 3
                ? "subsection-info-improve"
                : firstContentfulPaint > 3
                ? "subsection-info-error"
                : "subsection-info-noData"
            }
          >
            <Typography variant="subtitle2" className="wordBreakTypography">
              {firstContentfulPaintDescription}
            </Typography>
          </div>
          {/* LCP */}
          <div className="subsection-css">
            <SubSectionHeading>
              Largest Contentful Paint (LCP)
            </SubSectionHeading>
            <div
              className={
                largestContentfulPaint == null
                  ? "noDataBgColor"
                  : largestContentfulPaint <= 2.5
                  ? "looksGoodBgColor"
                  : largestContentfulPaint > 2.5 && largestContentfulPaint <= 4
                  ? "improvedBgColor"
                  : largestContentfulPaint > 4
                  ? "errorBgColor"
                  : "noDataBgColor"
              }
            >
              {largestContentfulPaint == null ? (
                <NoDataSubSectionComment>{NO_DATA}</NoDataSubSectionComment>
              ) : largestContentfulPaint <= 2.5 ? (
                <GoodSubSectionComment>{GOOD}</GoodSubSectionComment>
              ) : largestContentfulPaint > 2.5 &&
                largestContentfulPaint <= 4 ? (
                <ImproveSubSectionComment>{IMPROVED}</ImproveSubSectionComment>
              ) : largestContentfulPaint > 4 ? (
                <ErrorSubSectionComment>{ERROR}</ErrorSubSectionComment>
              ) : (
                <NoDataSubSectionComment>{NO_DATA}</NoDataSubSectionComment>
              )}
            </div>
          </div>
          <div
            className={
              largestContentfulPaint == null
                ? "subsection-info-noData"
                : largestContentfulPaint <= 2.5
                ? "subsection-info-good"
                : largestContentfulPaint > 2.5 && largestContentfulPaint <= 4
                ? "subsection-info-improve"
                : largestContentfulPaint > 4
                ? "subsection-info-error"
                : "subsection-info-noData"
            }
          >
            <Typography variant="subtitle2" className="wordBreakTypography">
              {largestContentfulPaintDescription}
            </Typography>
          </div>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default withStyles(styles, { withTheme: true })(Performance_WHC);
