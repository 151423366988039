const styles = (theme) => ({
  grow: {
    flexGrow: 1,
  },
  /* paper: {
         display: "flex",
         padding: theme.spacing(2),
         boxShadow: "unset"
     },*/
  card: {
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4),
    },
    background: "unset",
    boxShadow: "unset",
    /* marginLeft: theme.spacing(8),
         marginRight: theme.spacing(8),
         border: "1px solid gray",
         background: theme.palette.common.white,
         boxShadow: "4px 4px 18px rgba(0, 0, 0, 0.06)",
         borderRadius: 6,*/
  },
  otherCards: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    background: "unset",
    boxShadow: "unset",
    /* marginLeft: theme.spacing(8),
         marginRight: theme.spacing(8),
         border: "1px solid gray",
         background: theme.palette.common.white,
         boxShadow: "4px 4px 18px rgba(0, 0, 0, 0.06)",
         borderRadius: 6,*/
  },
  desSection: {
    [theme.breakpoints.down("lg")]: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(8),
      marginRight: theme.spacing(8),
    },
    // marginLeft: theme.spacing(8),
    // marginRight: theme.spacing(8),
  },
  des: {
    paddingLeft: theme.spacing(2),
  },
  taskView: {
    paddingLeft: theme.spacing(2),
  },
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  table: {
    marginBottom: 15,
    [theme.breakpoints.up("sm")]: {
      // minWidth: 300,
    },
    [theme.breakpoints.down("lg")]: {
      // maxWidth: 355,
      // width:355,
      tableLayout: "fixed",
    },
    /* border: "1px solid #CCCCCC",
         borderRadius: "6px 6px 6px 6px",
         boxShadow: "0 1px 1px #CCCCCC",*/
  },
  row: {
    // '&:nth-of-type(odd)': {
    //     backgroundColor: theme.palette.background.default,
    // },
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  TDes: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    background: theme.palette.taskfb,
    boxShadow: "unset",
    border: "1px solid" + theme.palette.taskfd2,
    boxSizing: "border-box",
    borderRadius: 6,
  },
  TDes2: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    background: theme.palette.taskfb,
    boxShadow: "unset",
    border: "1px solid" + theme.palette.taskfd2,
    boxSizing: "border-box",
    borderRadius: 6,
  },
  tdPaper: {
    background: theme.palette.common.white,
    boxShadow: "4px 4px 18px rgba(0, 0, 0, 0.06)",
    borderRadius: 6,
    padding: theme.spacing(2),
    // margin: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(2),
    },
    [theme.breakpoints.down("lg")]: {
      margin: theme.spacing(1),
    },
  },
  houlryPaper: {
    background: theme.palette.common.white,
    boxShadow: "4px 4px 18px rgba(0, 0, 0, 0.06)",
    borderRadius: 6,
    padding: theme.spacing(4),
    // margin: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(2),
      // margin: theme.spacing(1),
    },
    [theme.breakpoints.down("lg")]: {
      margin: theme.spacing(1),
      width: "49%",
      // overflowX:"scroll"
    },
  },
  menuButton: {
    marginLeft: theme.spacing(8),
    // marginRight: 20,
  },
  space: {
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },

  textDes: {
    opacity: 0.6,
  },
  btn: {
    padding: 0,
    textTransform: "none",
  },
  appBarGrid: {
    marginLeft: -6,
  },
  success: {
    color: theme.palette.success1.main,
    // color: "#F58120",
  },
  campaignTitle: {
    paddingLeft: theme.spacing(2),
  },
  warningIconStyle: {
    color: theme.palette.error.light,
  },
  hourlyPaper1: {
    width: "100%",
    [theme.breakpoints.down("lg")]: {
      // margin: theme.spacing(1),
      overflowX: "scroll",
      width: "88%",
    },
  },
  hourlyTable1: {
    width: "100%",
    [theme.breakpoints.down("lg")]: {
      width: 900,
    },
  },
  chip: {
    fontSize: "1rem",
    paddingLeft: 5,
    color: theme.palette.text.primary,
  },
  chipLabel: {
    label: {
      color: theme.palette.text.primary,
    },
  },
  successBanner: {
    position: "Fixed",
    top: 303,
    right: 30,
    zIndex: "999",
  },
  bannerContainer: {
    position: "absolute",
    top: 80,
    // right: 63,
    width: "100%",
  },
  bannerContainer2: {
    position: "absolute",
    top: 67,
    // right: 63,
    width: "100%",
  },
  counterText: {
    fontSize: "1.123rem",
  },
  counterWeek: {
    fontSize: 10,
  },
});

export default styles;
