import React from "react";
import { CompactCardContent } from "../cards";
import { Card, CardContent, CardHeader, Grid } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import icons from "../icons";
import {
  campaignDummyData,
  CampaignSnapshotPaper,
  SubSectionCardHeader,
} from "./templates";
import { displayDateRange } from "../../selectors/dashboard-selectors";
import { connect } from "react-redux";

export const CustomCardContent = withStyles((theme) => ({
  root: {
    background: theme.palette.graderBg,
    marginTop: 4,
    padding: 0,
  },
}))(CompactCardContent);
const campaignSnapshotStyles = (theme) => ({
  text: {
    fontSize: 24,
    fontWeight: "bold",
    color: theme.palette.graderGraphColors.text,
    fontFamily: "Montserrat",
    textTransform: "uppercase",
  },
  title: {
    fontSize: 16,
    fontWeight: "500",
    color: theme.palette.graderGraphColors.text,
    fontFamily: "Montserrat",
  },
  containerSpace: {
    padding: "2px 2px 0px 2px",
  },
  Card: {
    boxShadow: "unset",
    height: "100%",
  },
});

class CampaignSnapshot extends React.PureComponent {
  render() {
    const { accountStats, classes, currency } = this.props;
    // let accountStats = accountStats ? accountStats : null;
    console.log("accountStats=", accountStats);
    return (
      <Card className={classes.Card}>
        {/*<SubSectionCardHeader title={"KPI Graph"} subheader={`${KpiString[kpiType]} for last ${selectedDateRange} days`}/>*/}
        <SubSectionCardHeader title={"Campaign Snapshot"} />
        <CustomCardContent className={"compactVertical"}>
          <Grid container spacing={2} className={classes.containerSpace}>
            {campaignDummyData.map((data, i) => (
              <Grid item md={4} sm={6} xs={12} key={i}>
                <CampaignSnapshotPaper
                  title={data.title}
                  icon={icons[data.icon]}
                  value={
                    accountStats != null ? accountStats[data.valueName] : 0
                  }
                  classes={classes}
                  currency={currency}
                />
              </Grid>
            ))}
          </Grid>
        </CustomCardContent>
      </Card>
    );
  }
}

CampaignSnapshot.propTypes = {};
/*const mapStateToProps = state => ({
    data: state.googleAdsPerformance,
});*/

export default withStyles(campaignSnapshotStyles)(CampaignSnapshot);
