import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import ReportStyles from "../../assets/jss/reports/reportsStyles";
import styles from "../../assets/jss/task/taskStyles";

import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Menu,
} from "@mui/material";
import PerformanceGraph from "./performanceGraph";
import GeoGraph from "./geoGraph";
import DeviceGraph from "./deviceGraph";
import HourlyGraph from "./hourlyGraph";
import QualityGraph from "./qualityGraph";
import ImpressionGraph from "./impressionGraph";
import SkuPerformanceGraph from "./skuPerformance";
import Typography from "@mui/material/Typography";
import DateRangeSelector from "../../components/dashboard/date-range-selector";
import { connect } from "react-redux";
import * as types from "../../actions/actionTypes";
import {
  campaignsSnapshotPrevious,
  displayDateRange,
} from "../../selectors/dashboard-selectors";
import { sortTasks } from "../../reducers/common";
import { customDateRange, selectDateRange } from "../../actions";
import FileSaver from "file-saver";
import { getPngData } from "recharts-to-png";

import {
  customReportsDateRange,
  fetchReportsGraphs,
  selectReportsDateRange,
  sendEmailReport,
} from "../../actions/fetchReports";
import { FormControl, MenuItem, Select } from "@mui/material/index";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import { InputFields } from "../../components/input-forms";
import { NewTextRectOrangeTextBtn } from "../../components/buttons";
import MySnackbarWrapper from "../snackbars";
import CustomToolTip from "../customToolTip";
import { countryCode } from "../reports/geoGraph";
import * as _ from "lodash";

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="xl" />;

let moment = require("moment");

function ReportsType(props) {
  return (
    <FormControl>
      <CustomSelect 
   
        sx={{
          boxShadow: "none",
          ".MuiOutlinedInput-notchedOutline": { border: 0 },
          "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            border: 0,
          },
          "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              border: 0,
            },
        }}
        disableUnderline
        value={props.value}
        onChange={props.onChange}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        IconComponent={ExpandMore}
        MenuProps={{
          anchorOrigin: { vertical: "bottom", horizontal: "bottom" },
          transformOrigin: { vertical: "top", horizontal: "top" },
      
        }}
      >
        {/*<MenuItem value="">
                                                            <em>None</em>
                                                        </MenuItem>*/}
        <MenuItem value={"All Campaigns"}>
          <b>All Campaigns</b>
        </MenuItem>
        <MenuItem  value={"Search Campaigns"}>
          <b>Search Campaigns</b>
        </MenuItem>
        <MenuItem  value={"Shopping Campaigns"}>
          <b>Shopping Campaigns</b>
        </MenuItem>
      </CustomSelect>
      {/*<FormHelperText>&nbsp;</FormHelperText>*/}
    </FormControl>
  );
}

const CustomSelect = withStyles((theme) => ({
  icon: {
    color: theme.palette.text.primary,
  },

}))(Select);
const CustomMenu = withStyles((theme) => ({
  root: {
    minHeight: 100,
  },
}))(Menu);

ReportsType.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.any,
};
const CustomDialogContentText = withStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    fontSize: 16,
    // minWidth:200,
    // minHeight:100
  },
}))(DialogContentText);

async function fetchPerfromanceData(tabValue) {
  this.setState({ isPerformanceLoading: true });
  let performanceResponse = await this.props.fetchReports("account", tabValue);
  this.setState({ isPerformanceLoading: false, performanceResponse });
}

export const reportTypes = {
  account: "Performance",
  geo: "Geographical Distribution",
  device: "Device Distribution",
  hourly: "Hourly Performance",
  quality: "Quality Score",
  impShare: "Impression Share",
  skuPerf: "Sku Performance",
};

function Subject(props) {
  return (
    <Fragment>
      <Typography variant={"body1"} gutterBottom>
        Subject: {props.accountName} {reportTypes[props.reportType]} Graph for{" "}
        {props.displayReportsDateRange.dateFrom} -{" "}
        {props.displayReportsDateRange.dateTo}
      </Typography>
    </Fragment>
  );
}

Subject.propTypes = {
  reportType: PropTypes.any,
  displayReportsDateRange: PropTypes.any,
};

function updateGraphRefs(downloadReportType, ref) {
  let refs = { ...this.state.refs };
  refs[downloadReportType] = ref;
  console.log("refs123", refs);
  this.setState({
    downloadReportType: downloadReportType,
    refs: refs,
  });
}

class Reports extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isPerformanceLoading: false,
      isGeoLoading: false,
      isDeviceLoading: false,
      isQualityLoading: false,
      isHourlyLoading: false,
      isImpShareLoading: false,
      isSkuPerformanceLoading: false,
      performanceResponse: [],
      geoResponse: [],
      deviceResponse: [],
      qualityResponse: [],
      hourlyResponse: [],
      impShareResponse: [],
      tabValue: 0,
      performanceDialog: false,
      emailId: "",
      emailIdError: "",
      isEmailSending: false,
      reportType: "",
      downloadReportType: "",
      alert: false,
      message: "",
      variantType: "info",
      dialog: false,
      anchorEl: null,
      selectedRegion: "world",
      refs: {},
      activeIndex: 0,
    };
    this.account = React.createRef();
    this.device = React.createRef();
    this.geo = React.createRef();
    this.houlry1 = React.createRef();
    this.quality = React.createRef();
    this.imprShare = React.createRef();
    this.keyword = React.createRef();
    this.sku = React.createRef();
  }

  async componentDidMount() {
    await this.fetchAllTheGraphs();
    console.log("Ref value:", this.account.current);

    /*this.setState({isSkuPerformanceLoading: true});
        let skuPerformanceResponse = await this.props.fetchReports("skuPerf");
        this.setState({isSkuPerformanceLoading: false, skuPerformanceResponse});*/
    if (window.hopscotch && window.hopscotch != null) {
      const currentStep = window.hopscotch.getCurrStepNum();
      console.log("currentStep=", currentStep);
      if (currentStep) {
        const currentTour = window.hopscotch.getCurrTour();
        console.log("currentTour=", currentTour);
        window.hopscotch.startTour(currentTour, currentStep);
      }
    }
  }

  async fetchAllTheGraphs() {
    const { tabValue } = this.state;
    const { selectedMetric } = this.props;
    console.log("In fetchAllTheGraphs the selectedMetric = ", selectedMetric);
    await fetchPerfromanceData.call(this, tabValue);
    this.setState({ isGeoLoading: true });
    let geoResponse = await this.props.fetchReports("geo", "country");
    this.setState({ isGeoLoading: false, geoResponse });
    this.setState({ isDeviceLoading: true });
    let deviceResponse = await this.props.fetchReports("device");
    this.setState({ isDeviceLoading: false, deviceResponse });
    if (
      selectedMetric == "Search Campaigns" ||
      selectedMetric == "All Campaigns"
    ) {
      this.setState({ isQualityLoading: true });
      let qualityResponse = await this.props.fetchReports("quality");
      this.setState({ isQualityLoading: false, qualityResponse });
    }
    this.setState({ isHourlyLoading: true });
    let hourlyResponse = await this.props.fetchReports("hourly");
    this.setState({ isHourlyLoading: false, hourlyResponse });
    this.setState({ isImpShareLoading: true });
    let impShareResponse = await this.props.fetchReports("impShare");
    this.setState({ isImpShareLoading: false, impShareResponse });
    if (selectedMetric == "Shopping Campaigns") {
      this.setState({ isSkuPerformanceLoading: true });
      let skuPerformanceResponse = await this.props.fetchReports("skuData");
      this.setState({ isSkuPerformanceLoading: false, skuPerformanceResponse });
      console.log(
        "In fetchAllTheGraphs the skuPerformanceResponse = ",
        skuPerformanceResponse
      );
    }
  }

  handleChange = async (event, value) => {
    this.setState({ tabValue: value });
    await fetchPerfromanceData.call(this, value);
    await this.setState({ refs: {} });
    console.log("refs cleared=", this.state.refs);
  };
  handleClose = () => {
    this.setState({ dialog: false });
  };

  handlePopsClose = async (downloadType) => {
    console.log("this.account.current before", this.account);
    const { selectedReportsDateRange } = this.props;
    var toDate = selectedReportsDateRange.toDate;
    var fromDate = selectedReportsDateRange.fromDate;
    if (downloadType == "excel")
      window.location = "/downloadReport.html?repType";
    if (downloadType == "pdf") window.location = "/downloadPDFReport.html";
   
    if (downloadType == "jpg") {
      let node = null;
      // Send the chart to getPngData
      // const pngData = await getPngData(this.state.refs[this.state.downloadReportType]);
      if (this.state.downloadReportType == "account") {
        node = this.account.current;
      } else if (this.state.downloadReportType == "hourly") {
        node = this.houlry1.current;
      } else if (this.state.downloadReportType == "quality") {
        node = this.quality.current;
        console.log("this.account.current after", this.account.current);
      } else if (this.state.downloadReportType == "impressionShare") {
        node = this.imprShare.current;
      } else if (this.state.downloadReportType == "skuPerf") {
        node = this.sku.current;
      } else if (this.state.downloadReportType == "device") {
        node = this.device.current;
      } else if (this.state.downloadReportType == "geo") {
        node = this.geo.current;
      }
      if (node != null) {
        const pngData = await getPngData(node);
        // Use FileSaver to download the PNG
        FileSaver.saveAs(
          pngData,
          reportTypes[
            this.state.downloadReportType == "impressionShare"
              ? "impShare"
              : this.state.downloadReportType
          ] +
            moment() +
            ".png"
        );
      } else {
        console.log("Error in selecting PNG node");
      }
    }
    this.setState({ anchorEl: null });
  };

  handleRefs = (downloadReportType, ref) => {
    if (!_.has(this.state.refs, downloadReportType)) {
      console.log("refs has");
      updateGraphRefs.call(this, downloadReportType, ref);
    }
  };
  handleDialogs = (reportType) => {
    this.setState({ reportType: reportType, dialog: true });
  };
  handlePops = (reportType, event) => {
    this.setState({
      downloadReportType: reportType,
      anchorEl: event.currentTarget,
    });
  };
  handleCloseAlert = async () => {
    await this.setState({ alert: false });
  };
  validateAccountDetails = () => {
    let isError = false;
    const errors = {};
    if (this.state.emailId.trim().length == 0) {
      isError = true;
      errors.emailIdError = "This field is required";
    } else if (this.state.emailId.length > 0) {
      /* else if (this.state.emailId.length > 0) {
             let result = this.state.emailId.match(/^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/);
             if (!result) {
                 isError = true;
                 errors.emailIdError = 'Please enter valid email';
             } else {
                 errors.emailIdError = '';
             }
         }*/
      let emailError = false;
      var separator = ",";
      var emailIdList = this.state.emailId.trim().split(separator);
      // var emailIdList = this.state.emailIds.split(',');
      var i;
      for (i = 0; i < emailIdList.length; i++) {
        var emails = "";
        emails = emailIdList[i];
        console.log("emailIdList is= ", emails);
        let result = emails
          .trim()
          .match(
            /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
          );
        if (!result) {
          isError = true;
          errors.emailIdError = "Please enter valid email";
          emailError = true;
        }
      }
      if (!emailError) {
        errors.emailIdError = "";
      }
    } else {
      errors.emailIdError = "";
    }
    this.setState({
      ...this.state,
      ...errors,
    });
    // isError = true;
    return isError;
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const { reportType, emailId } = this.state;
    let error;
    error = this.validateAccountDetails();
    if (!error) {
      this.setState({ isEmailSending: true }); //skuPerf
      let subject = `${this.props.accountName} ${
        reportTypes[this.state.reportType]
      } Graph for ${this.props.displayReportsDateRange.dateFrom}  -   ${
        this.props.displayReportsDateRange.dateTo
      }`;
      const selectedReportType =
        this.state.reportType == "impShare"
          ? "impressionShare"
          : this.state.reportType == "skuData"
          ? "skuPerf"
          : this.state.reportType;
      const response = await this.props.sendEmailReport(
        selectedReportType,
        reportTypes[reportType],
        subject,
        emailId.trim()
      );
      this.setState({ isEmailSending: false });
      if (response !== null && response.isMailSent == false) {
        this.setState({
          alert: true,
          message: "Mail sent failed",
          variantType: "error",
          dialog: false,
        });
      } else {
        this.setState({
          alert: true,
          message: "Mail sent Successfully",
          variantType: "success",
          dialog: false,
        });
      }
    }
  };
  handleDateRange = async (event) => {
    await this.props.onSelectReportsDateRange(event);
    await this.setState({ refs: {} });
    await this.fetchAllTheGraphs();
    console.log("refs cleared= handleDateRange", this.state.refs);
  };
  handleCustomDateRange = async (dateRange) => {
    await this.props.customReportsDateRanges(dateRange);
    await this.setState({ refs: {} });
    console.log("refs cleared= handleCustomDateRange", this.state.refs);
    await this.fetchAllTheGraphs();
  };
  handleCallBack = async (selectedRegion) => {
    this.setState({ selectedRegion, isGeoLoading: true });
    let selectedType = "world";
    if (selectedRegion != "world") {
      selectedType = _.invert(countryCode)[selectedRegion];
    } else {
      selectedType = "country";
    }
    let geoResponse = await this.props.fetchReports("geo", selectedType);
    this.setState({ isGeoLoading: false, geoResponse });
  };
  hanldeOnmetricChange = async (metric) => {
    if (metric == this.props.selectedMetric) return;
    await this.props.onMetricChange(metric);
    await this.setState({ refs: {} });
    console.log("refs cleared=", this.state.refs);
    await this.fetchAllTheGraphs();
  };

  onPieEnter = (data, index) => {
    this.setState({
      activeIndex: index,
    });
  };

  render() {
    console.log("Render - this.account.current:", this.account.current);

    const {
      width,
      kpiType,
      onSelectReportsDateRange,
      customReportsDateRanges,
      displayReportsDateRange,
      selectedReportsDateRange,
      onMetricChange,
      selectedMetric,
      currency,
      classes,
    } = this.props;
    const {
      performanceResponse,
      geoResponse,
      deviceResponse,
      qualityResponse,
      hourlyResponse,
      impShareResponse,
      skuPerformanceResponse,
      isPerformanceLoading,
      tabValue,
      isDeviceLoading,
      isHourlyLoading,
      isQualityLoading,
      isImpShareLoading,
      isSkuPerformanceLoading,
      isGeoLoading,
      dialog,
      emailId,
      emailIdError,
      reportType,
      isEmailSending,
      selectedRegion,
      anchorEl,
      downloadReportType,
      activeIndex,
    } = this.state;
    let toDate = selectedReportsDateRange.toDate;
    let fromDate = selectedReportsDateRange.fromDate;

    return (
      <div ref={this.props.forwardedRef}>
      <Grid container spacing={2} >
        <Grid item sm={12} xs={12} md={12} style={{ paddingBottom: 0 }}>
          <Grid
            container
            justifyContent={
              width == "sm" || width == "xs" ? "space-between" : "none"
            }
            alignItems={"center"}
            // justifyContent={
            //   width == "sm" || width == "xs" ? "space-between" : "flex-around"
            // }
          >
            <Grid item xs={6} sm={6} md={3}>
              <ReportsType
                value={selectedMetric}
                onChange={(event) =>
                  this.hanldeOnmetricChange(event.target.value)
                }
              />
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={4}
              style={
                width == "sm" || width == "xs"
                  ? { textAlign: "right" }
                  : { textAlign: "unset" }
                 
              }
             
            >
              <DateRangeSelector
                value={selectedReportsDateRange}
                onChange={this.handleDateRange}
                range={displayReportsDateRange}
                customDateRanges={this.handleCustomDateRange}
              />
            </Grid>
          </Grid>
        </Grid>
          <Grid item xs={12} sm={12} md={6} style={{paddingTop:"0px"}} >
          <PerformanceGraph
            isLoading={isPerformanceLoading}
            data={performanceResponse}
            tabValue={tabValue}
            handleChange={this.handleChange}
            kpiType={kpiType}
            handleReportSent={this.handleDialogs}
            handleDownloadReport={this.handlePops}
            // // handleRefs={this.handleRefs}
            currency={currency}
            ref={this.account}
            width={width}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} style={{paddingTop:"0px"}}>
          <GeoGraph
            isLoading={isGeoLoading}
            data={geoResponse}
            kpiType={kpiType}
            handleCallBack={this.handleCallBack}
            selectedRegion={selectedRegion}
            currency={currency}
            handleReportSent={this.handleDialogs}
            handleDownloadReport={this.handlePops}
            ref={this.geo}
            width={width}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <DeviceGraph
            isLoading={isDeviceLoading}
            data={deviceResponse}
            kpiType={kpiType}
            handleReportSent={this.handleDialogs}
            // handleRefs={this.handleRefs}
            handleDownloadReport={this.handlePops}
            currency={currency}
            ref={this.device}
            width={width}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <HourlyGraph
            isLoading={isHourlyLoading}
            data={hourlyResponse}
            kpiType={kpiType}
            handleReportSent={this.handleDialogs}
            // handleRefs={this.handleRefs}
            handleDownloadReport={this.handlePops}
            currency={currency}
            ref={this.houlry1}
            width={width}
          />
        </Grid>
        {selectedMetric == "Shopping Campaigns" ? (
          <Grid item xs={12} sm={12} md={6}>
            <SkuPerformanceGraph
              isLoading={isSkuPerformanceLoading}
              data={skuPerformanceResponse}
              kpiType={kpiType}
              handleReportSent={this.handleDialogs}
              // handleRefs={this.handleRefs}
              handleDownloadReport={this.handlePops}
              activeIndex={activeIndex}
              onPieEnter={this.onPieEnter}
              ref={this.sku}
              width={width}
            />
          </Grid>
        ) : (
          <Grid item xs={12} sm={12} md={6}>
            <QualityGraph
              isLoading={isQualityLoading}
              data={qualityResponse}
              kpiType={kpiType}
              handleReportSent={this.handleDialogs}
              //           handleRefs={this.handleRefs}
              // // handleRefs={() => null}
              handleDownloadReport={this.handlePops}
              ref={this.quality}
              width={width}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={6}>
          <ImpressionGraph
            isLoading={isImpShareLoading}
            data={impShareResponse}
            kpiType={kpiType}
            handleReportSent={this.handleDialogs}
            // handleRefs={this.handleRefs}
            // handleRefs={() => null}
            handleDownloadReport={this.handlePops}
            ref={this.imprShare}
            width={width}
          />
        </Grid>
        <Grid item>
          <Dialog
            open={dialog}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            {isEmailSending ? (
              <DialogContent>
                <CustomDialogContentText>
                  <CircularProgress />
                </CustomDialogContentText>
              </DialogContent>
            ) : (
              <form onSubmit={this.handleSubmit}>
                <DialogContent>
                  <CustomDialogContentText>
                    <FormControl className={"fullWidth"}>
                      {/*You can send report to multiple email ids by inserting comma(,) after each email address*/}
                      <Typography
                        color={"textPrimary"}
                        variant={"body1"}
                        gutterBottom
                      >
                        To{" "}
                        <CustomToolTip verticalAlign>
                          <Typography variant={"body1"} color={"textPrimary"}>
                            You can send report to multiple email ids by
                            inserting comma(,) after each email address
                          </Typography>
                        </CustomToolTip>
                      </Typography>
                      <InputFields
                        value={emailId}
                        onChange={(event) =>
                          this.setState({ emailId: event.target.value })
                        }
                        margin="normal"
                        variant="outlined"
                        error={emailIdError !== ""}
                        helperText={emailIdError}
                        style={{ marginTop: 0, width: "75%" }}
                      />
                    </FormControl>
                  </CustomDialogContentText>
                  <CustomDialogContentText>
                    <Subject
                      reportType={reportType}
                      displayReportsDateRange={displayReportsDateRange}
                      accountName={this.props.accountName}
                    />
                  </CustomDialogContentText>
                  <CustomDialogContentText>
                    Hello,
                    <br />
                    The {reportTypes[reportType]} Graph for{" "}
                    {this.props.accountName} is attached with this email for you
                    to look over. If you have any questions or comments about
                    your report feel free to shoot us an email at
                    support@lxrguide.com!
                    <br />
                    Thank You!
                  </CustomDialogContentText>
                </DialogContent>
                <DialogActions>
                  <NewTextRectOrangeTextBtn
                    onClick={this.handleClose}
                    color="primary"
                  >
                    Cancel
                  </NewTextRectOrangeTextBtn>
                  <NewTextRectOrangeTextBtn type={"submit"} autoFocus>
                    Send
                  </NewTextRectOrangeTextBtn>
                </DialogActions>
              </form>
            )}
          </Dialog>
          <MySnackbarWrapper
            open={this.state.alert}
            onClose={this.handleCloseAlert}
            message={this.state.message}
            verticalAlign={"bottom"}
            horizontalAlign={"right"}
            duration={6000}
            variant={this.state.variantType}
          />
          <div>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={this.handlePopsClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <Grid container spacing={2} className={classes.popUpContainer}>
                <Grid md={12} item>
                  <Typography color={"textPrimary"} variant={"body1"}>
                    Download report as
                  </Typography>
                </Grid>
                <Grid item>
                  <a
                    title={"Download as Excel"}
                    href={`/downloadReport.html?repType=${downloadReportType}&fromDate1=${fromDate}&toDate1=${toDate}&channelType=${selectedMetric}`}
                    target={"_blank"}
                  >
                    <Typography variant={"h3"} color={"textPrimary"}>
                      <i className="fa fa-file-excel-o" aria-hidden="true"></i>
                    </Typography>
                  </a>
                </Grid>
                {downloadReportType != "skuPerf" ? (
                  <Grid item>
                    <a
                      title={"Download as PDF"}
                      href={`/downloadPDFReport.html?repType=${downloadReportType}&fromDate1=${fromDate}&toDate1=${toDate}&channelType=${selectedMetric}`}
                      target={"_blank"}
                    >
                      <Typography variant={"h3"} color={"textPrimary"}>
                        <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
                      </Typography>
                    </a>
                  </Grid>
                ) : null}
                {/*{downloadReportType != "geo" ?*/}
                {/* <Grid
                  item
                  title={"Download as PNG"}
                  style={{ cursor: "pointer" }}
                  onClick={() => this.handlePopsClose("jpg")}
                >
                  <Typography variant={"h3"} color={"textPrimary"}>
                    <i className="fa fa-file-image-o" aria-hidden="true"></i>
                  </Typography>
                </Grid> */}
              </Grid>
            </Menu>
          </div>
        </Grid>
      </Grid>
      </div>
    );
  }
}

Reports.propTypes = {};
const mapDispatchToProps = (dispatch) => ({
  onSelectReportsDateRange: (event) =>
    dispatch(selectReportsDateRange(event.target.value)),
  customReportsDateRanges: (dateRange) =>
    dispatch(customReportsDateRange(dateRange)),
  onMetricChange: (metric) =>
    dispatch({
      type: types.TOGGLE_REPORTS_METRIC,
      metric,
    }),
  fetchReports: (graphType, dateType) =>
    dispatch(fetchReportsGraphs(graphType, dateType)),
  sendEmailReport: (reportType, reportName, subject, emailId) =>
    dispatch(sendEmailReport(reportType, reportName, subject, emailId)),
});
const mapStateToProps = (state) => ({
  displayReportsDateRange: displayDateRange(state.selectedReportsDateRange),
  selectedReportsDateRange: state.selectedReportsDateRange,
  selectedMetric: state.reportMetric.metric,
  kpiType: state.perf.kpiType,
  currency: state.perf.currency,
  accountName: state.userInformation.selectedAccountInfo.clientName,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withWidth()(withStyles(ReportStyles)(Reports)));
