import * as React from "react";

function SvgActivityReports(props) {
  return (
    <svg width={18} height={18} fill="none" {...props}>
      <path
        d="M12.502 1.5c2.55 0 3.99 1.447 3.998 3.998v7.005c0 2.55-1.447 3.997-3.998 3.997H5.498c-2.551 0-3.998-1.447-3.998-3.997V5.498C1.5 2.947 2.947 1.5 5.498 1.5h7.004zM9.375 4.598a.63.63 0 00-.96.592v7.643a.632.632 0 00.622.562.62.62 0 00.622-.562V5.19a.612.612 0 00-.284-.592zm-3.502 2.46a.617.617 0 00-.66 0 .63.63 0 00-.293.592v5.183c.03.322.3.562.622.562a.62.62 0 00.622-.562V7.65a.632.632 0 00-.291-.592zm6.944 2.722a.629.629 0 00-.667 0 .604.604 0 00-.285.593v2.46c.03.322.3.562.623.562.315 0 .584-.24.622-.562v-2.46a.626.626 0 00-.293-.593z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgActivityReports;
