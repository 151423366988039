import withStyles from "@mui/styles/withStyles";
import { Typography } from "@mui/material";
import React from "react";

export const LightCaption = withStyles((theme) => ({
  caption: {
    fontSize: "0.7rem",
    color: theme.palette.text.primary,
  },
}))(Typography);
LightCaption.defaultProps = {
  variant: "caption",
};

export const DivTypography = Typography;

DivTypography.defaultProps = {
  headlinemapping: { body1: "div" },
};
/*export const SpanTypography = Typography;

SpanTypography.defaultProps = {
    headlineMapping: {body1: 'span'}
};*/

export const ErrorTypography = withStyles((theme) => ({
  root: {
    color: theme.palette.unSuccess,
    fontSize: " 0.95rem",
  },
}))(Typography);
export const ErrorSmallTypography = withStyles((theme) => ({
  root: {
    fontFamily: "Montserrat",
    color: theme.palette.unSuccess,
    fontSize: " 0.75rem",
  },
}))(Typography);
export const SessionErrorTypography = withStyles((theme) => ({
  root: {
    color: theme.palette.unSuccess,
    fontSize: "1.5rem",
  },
}))(Typography);
export const WhiteTypography = withStyles((theme) => ({
  root: {
    color: theme.palette.common.white,
    fontSize: " 0.75rem",
  },
}))(Typography);
export const SuccessTypography = withStyles((theme) => ({
  root: {
    color: theme.palette.success,
    fontSize: " 0.75rem",
  },
}))(Typography);
export const HeadLineTypography = withStyles((theme) => ({
  root: {
    fontWeight: "600",
    color: theme.palette.text.primary,
    fontSize: 40,
    [theme.breakpoints.between("lg", "xl")]: {
      fontSize: 38,
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: 24,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 24,
    },
  },
}))(Typography);
export const OrangeTxt = withStyles((theme) => ({
  root: {
    fontWeight: "700",
    color: theme.palette.orangeColor,
    fontSize: 24,
    [theme.breakpoints.down("xl")]: {
      fontSize: 16,
    },
  },
}))(Typography);
export const ParagraphTypography = withStyles((theme) => ({
  root: {
    color: theme.palette.dialogText,
    fontSize: 16,
    fontWeight: "600",
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down("xl")]: {
      fontSize: 12,
      padding: theme.spacing(2),
    },
  },
}))(Typography);

export const SubheadTypography = withStyles((theme) => ({
  root: {
    color: theme.palette.dialogText,
    fontSize: 16,
    fontWeight: "400",
  },
}))(Typography);
export const OrangeTypography = withStyles((theme) => ({
  root: {
    fontWeight: "700",
  },
}))(Typography);
export const BlueTitleTypography = withStyles((theme) => ({
  root: {
    fontWeight: "700",
    fontSize: 48,
  },
}))(Typography);

export function GreenText(props) {
  const { children } = props;
  return <span style={{ color: "#7BC658" }}>{children}</span>;
}

export function OrangeText(props) {
  const { children } = props;
  return <span style={{ color: "#F58120" }}>{children}</span>;
}

export const OrangeTextSize12 = withStyles((theme) => ({
  root: {
    color: theme.palette.orangeColor,
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 18,
    padding: 5,
    [theme.breakpoints.down("lg")]: {
      textAlign: "center",
    },
    // textAlign:'center',
    // padding:'10px',
  },
}))(Typography);
export const GrayText = withStyles((theme) => ({
  root: {
    color: "#888888",
  },
}))(Typography);
export const SubHeadingTypo = withStyles((theme) => ({
  root: {
    fontSize: 30,
    color: theme.palette.text.primary,
    fontWeight: "bold",
    [theme.breakpoints.down("md")]: {
      fontSize: 20,
    },
  },
}))(Typography);
export const SubHeadingInsights = withStyles((theme) => ({
  root: {
    fontSize: 28,
    fontWeight: "bold",
    [theme.breakpoints.down("md")]: {
      fontSize: 20,
    },
  },
}))(Typography);
export const CatalinaBlue = withStyles((theme) => ({
  root: {
    fontSize: 20,
    color: theme.palette.text.primary,
    fontFamily: "Lato",
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
    },
  },
}))(Typography);
export const TaglineForHeadline = withStyles((theme) => ({
  root: {
    fontSize: 16,
    color: "#1E334E",
    fontFamily: "Lato",
    lineHeight:"1.6",
    [theme.breakpoints.down("md")]: {
      fontSize: 12,
    },
  },
}))(Typography);
export const OrangeTextForGrid = withStyles((theme) => ({
  root: {
    fontSize: 22,
    color: "#F58120",
    textAlign: "initial",
    fontWeight: "bold",
  },
}))(Typography);
export const NewTaglineForHeadline = withStyles((theme) => ({
  root: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "1.5rem",
    color: "#1E334E",
    fontFamily: "Lato",
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
    },
  },
}))(Typography);
export const SubSectionScore = withStyles((theme) => ({
  root: {
    fontSize: "2rem",
    color: "#1E334E",
    fontFamily: "Lato",
    fontWeight: "700",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.25rem",
    },
  },
}))(Typography);
export const SubSectionScoreTagLine = withStyles((theme) => ({
  root: {
    fontSize: "1.2rem",
    color: "#8F99A7",
    fontFamily: "Lato",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.75rem",
    },
  },
}))(Typography);

export const SubSectionHeading = withStyles((theme) => ({
  root: {
    color: "#1E334E",
    fontSize: "0.875rem",
    fontFamily: "Lato",
    fontWeight: "400",
    textAlign: "left",
    wordBreak: " break-word",
  },
}))(Typography);

export const BlueConsultationTextWHS = withStyles((theme) => ({
  root: {
    fontSize: "1.125rem",
    fontFamily: "Lato",
    color: "#1E334E",
    fontWeight: 500,
    // [theme.breakpoints.down("sm")]: {
    //   fontSize: "16px",
    // },
  },
}))(Typography);

export const GreyHeadingTextWHS = withStyles((theme) => ({
  root: {
    fontFamily: "Lato",
    fontSize: "1rem",
    fontWeight: "500",
    lineHeight: "19px",
    textAlign: "left",
    color: "#989898",
    padding: "0 5%",
    [theme.breakpoints.down("xs")]: {
      // fontSize: "0.875rem",
      textAlign: "center",
      padding: 0,
    },
  },
}))(Typography);

export const ScoreTextWHS = withStyles((theme) => ({
  root: {
    fontFamily: "Lato",
    fontWeight: "700",
    fontSize: "2.125rem",
    lineHeight: "19px",
    textAlign: "center",
  },
}))(Typography);

export const GoodSubSectionComment = withStyles((theme) => ({
  root: {
    fontFamily: "Lato",
    fontWeight: "400",
    fontSize: "0.75rem",
    textAlign: "left",
    color: "#00A850",
    wordBreak: "break-word",
  },
}))(Typography);

export const ImproveSubSectionComment = withStyles((theme) => ({
  root: {
    fontFamily: "Lato",
    fontWeight: "400",
    fontSize: "0.75rem",
    textAlign: "left",
    color: "#F58120",
    wordBreak: "break-word",
  },
}))(Typography);

export const ErrorSubSectionComment = withStyles((theme) => ({
  root: {
    fontFamily: "Lato",
    fontWeight: "400",
    fontSize: "0.75rem",
    textAlign: "left",
    color: "#F13B0C",
    wordBreak: "break-word",
  },
}))(Typography);

export const NoDataSubSectionComment = withStyles((theme) => ({
  root: {
    fontFamily: "Lato",
    fontWeight: "400",
    fontSize: "0.75rem",
    textAlign: "left",
    color: "#414141",
    wordBreak: "break-word",
  },
}))(Typography);

export const ScoreTypography = withStyles((theme) => ({
  root: {
    fontFamily: "Lato",
    fontWeight: "700",
    fontSize: "2.125rem",
    textAlign: "left",
    color: "#6D6D6D",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
}))(Typography);

export const SectionInfoWHS = withStyles((theme) => ({
  root: {
    fontFamily: "Lato",
    fontSize: "0.875rem",
    fontWeight: "600",
    lineHeight: "22px",
    color: "#CA9B00",
    wordWrap: "break-word",
    wordBreak: "break-word",
  },
}))(Typography);

export const TextForErrorPage = withStyles((theme) => ({
  root: {
    fontSize: "1.5rem",
    fontFamily: "Lato",
    color: "#1E334E",
    fontWeight: 700,
  },
}))(Typography);

export const WHSDescription = withStyles((theme) => ({
  root: {
    fontSize: "1.25rem",
    fontFamily: "Lato",
    color: "#747474",
    fontWeight: 500,
    lineHeight: "1.5rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
}))(Typography);

export const ConnectionTypeTypography = withStyles((theme) => ({
  root: {
    fontFamily: "Lato",
    color: "#6D6D6D",
    fontWeight: 500,
    paddingLeft: "6px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
}))(Typography);
export const OverallSectionTypography = withStyles((theme) => ({
  root: {
    fontSize: "1rem",
    fontFamily: "Lato",
    fontWeight: 400,
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.875rem",
    },
  },
}))(Typography);
export const JsLoginTypography = withStyles((theme) => ({
  root: {
    fontSize: "1rem",
    color: "#707A86",
    letterSpacing: "1.44px",
    textAlign:"center",
  },
}))(Typography);
export const JsSectionTypography = withStyles((theme) => ({
  root: {
      fontSize: "1.25rem",
      color: "#4E4E4E",
      fontFamily: "Lato",
      fontWeight: 600,
  },
}))(Typography);
export const JsSubSectionTypography = withStyles((theme) => ({
  root: {
    fontSize: "0.875rem",
      color: "#838383",
      fontFamily: "Lato",
      fontWeight: 400,
      wordBreak: "break-word"
  },
}))(Typography);
export const JsAddUserLabelTypography = withStyles((theme) => ({
  root: {
    fontSize: "0.875rem",
      color: "#747474",
      fontFamily: "Lato",
      fontWeight: 500,
      paddingBottom: "5px",
  },
}))(Typography);


