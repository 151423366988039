import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchConnectionRelatedInfo } from "../../actions";
import { deleteGMCConnection , fetchGMCAuthURL, fetchGMCData} from "../../actions/connectionPageActions";
import MySnackbarWrapper from "../snackbars";
import {
  NewOrangeContainedBtn,
  NewOutLinedRectCancelBlueBtn,
} from "../../components/buttons";
import icons from "../../components/icons";
import { ConnectionTypeTypography } from "../../components/typography";

const styles = (theme) => ({
  GaGrid: {
    backgroundColor: "#FFFFFF",
    [theme.breakpoints.up("md")]: {
      width: "100%",
      height: "88px",
      border: "solid 2px #BBBBBB",
      borderRadius: "6px",
      padding: "0px 10px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "15px",
      width: "100%",
      height: "300px",
      border: "solid 1px #BBBBBB",
      borderRadius: "3px",
      textAlign: "center",
    },
  },
  TextCenter: {
    textAlign: "Center",
    fontSize: "16px",
  },

  gridItems: {
    margin: "Auto 0",
  },
  flexCenter:{
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  buttons: {
    backgroundColor: "#F58120",
    border: "solid 1px #1E334E",
  },
  dataStyle:{
    fontWeight:"500",
    color:"#1E334E",
    wordBreak:"break-word",
  },
  verticalLine:{
    paddingRight: "7px",
    [theme.breakpoints.down("sm")]: {
    display: "none",
    },
  },
});

function GmcConnection(props) {
  window.scrollTo(0, 0);
  const classes = props.classes;

  const { gmcData,fetchGMCAccountReducer, fetchGMCData,deleteGMCAccountReducer, deleteGMCConnection, fetchGMCAuthURL,fetchGMCAuthURLReducer,fetchConnectionRelatedInfo} = props;

  //Google Merchant Center
  const [gmcMerchantId, setGmcMerchantId] = useState("");
  const [gmcId, setGmcId] = useState("");
  const [gmcRefreshToken, setGmcRefreshToken] = useState("");
  const [gmcAuthUrl, setGmcAuthUrl] = useState(fetchGMCAuthURLReducer ? fetchGMCAuthURLReducer.authUrl : "");
  const [refreshAlert, setRefreshAlert] = useState(false);
  const [gmcError, setGmcError] = useState(false)

  useEffect(()=>{
    fetchGMCAuthURL();
  },[])

  let gmcAuthWindow;

  const openGMCAuthentication = () => {
    gmcAuthWindow = window.open(
      gmcAuthUrl,
      'GMC Authentication',
      'menubar=no,status=no,location=no,toolbar=no,scrollbars=yes,resizable=no'
    );

    const checkWindowStatus = setInterval(() => {
      if (gmcAuthWindow && gmcAuthWindow.closed) {
        clearInterval(checkWindowStatus); 
        fetchingGmcDataForRefresh();
      }
    }, 2000);
  };
  
  const handleAddAccount = () => {
    window.location.href = "/shopping-settings.html";
  };

  const fetchingGmcDataForRefresh = async () => {
    if(gmcData.gmc_id){
      const data = await fetchGMCData(gmcData.gmc_id);
      setRefreshAlert(true);
      if(data && data.gmcRefreshToken === gmcRefreshToken){
        setGmcError(true);
      }else{
        setGmcError(false);
      }
    }
  };

  console.log("checking gmc refresh tokens", refreshAlert,gmcRefreshToken,gmcError);
  useEffect(()=>{
    if(fetchGMCAuthURLReducer.authUrl){
      setGmcAuthUrl(fetchGMCAuthURLReducer.authUrl);
    }
  },[fetchGMCAuthURLReducer])

  useEffect(() => {
    //Google Merchant Center
    if (gmcData) {
      setGmcMerchantId(gmcData.gmcMerchantId ? gmcData.gmcMerchantId : "-");
      setGmcId(gmcData.gmc_id ? gmcData.gmc_id : "-" );
      setGmcRefreshToken(gmcData.gmcRefreshToken ? gmcData.gmcRefreshToken : "-");
    }
  }, [gmcData]);


  console.log("gmcData",gmcData);

  const [openGMC, setOpenGMC] = React.useState(false);
  const [alert, setAlert] = useState(false);

  const handleDialogToOpenGMC = () => {
    setOpenGMC(true);
  };
  const handleDialogToCloseGMC = () => {
    setOpenGMC(false);
  };

  const handleCloseGMCAlert = async () => {
    await setAlert(false);
  };
  const handleCloseGMCRefreshAlert = async () => {
    await setRefreshAlert(false);
  };
  
  const [deleteGMCAccountId, setDeleteGMCAccountId] = useState("");

  const handleGMCDeleteConnection = async (gmcMerchantId, gmcId) => {
    setDeleteGMCAccountId(gmcMerchantId);
    setOpenGMC(false);
    const data = await deleteGMCConnection(gmcId);
    if (data === true) {
      setGmcError(false)
    }else{
      setGmcError(true)
    }
    setAlert(true);
  };

  useEffect(() => {
    if (deleteGMCAccountReducer.message === "DELETE_GMC_RECEIVED") {
      if (deleteGMCAccountId === gmcMerchantId) {
        setGmcMerchantId("");
      }
    }
  }, [deleteGMCAccountReducer]);

  return (
    <div style={{ marginTop: "20px" }}>
      <Grid container className={classes.GaGrid}>
        <Grid item xs={12} sm={12} md={3} lg={3} className={classes.gridItems}>
            <div className={classes.flexCenter}>
              {icons.GMC}
              <ConnectionTypeTypography>Google Merchant Center</ConnectionTypeTypography>
            </div>
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5} className={classes.gridItems}>
          {gmcMerchantId === "" || gmcMerchantId === "-" ? (
            <Typography></Typography>
          ) : (
            <div className={classes.flexCenter}>
            <div className={classes.verticalLine}>{icons.SegregationLine}</div> 
            <Typography style={{color:"#747474"}}>Merchant Id:<br/><span className={classes.dataStyle}>{gmcMerchantId}</span></Typography>
        </div>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2} className={classes.gridItems}>
          {gmcData && (deleteGMCAccountReducer["message"] === "" ||
          deleteGMCAccountReducer["message"] === "DELETE_GMC_FAILED") ? (
            <Typography>
               <NewOutLinedRectCancelBlueBtn
               onClick={openGMCAuthentication}
              >
                Refresh Token
              </NewOutLinedRectCancelBlueBtn>
            </Typography>
          ) : (
            <Typography></Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2} className={classes.gridItems}>
          {gmcData && (deleteGMCAccountReducer["message"] === "" ||
          deleteGMCAccountReducer["message"] === "DELETE_GMC_FAILED") ? (
            <Typography>
              <NewOutLinedRectCancelBlueBtn onClick={handleDialogToOpenGMC}>
                Delete Account
              </NewOutLinedRectCancelBlueBtn>
            </Typography>
          ) : (
            <Typography>
                <NewOrangeContainedBtn
                  onClick={
                    handleAddAccount
                  }
                  type={"button"}
                >
                  +Add Account
                </NewOrangeContainedBtn>
            </Typography>
          )}
        </Grid>
      </Grid>

      {/* GMC Delete Dialog Box */}
      <Dialog open={openGMC} onClose={handleDialogToCloseGMC}>
        <DialogTitle>{"Delete Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you really want to delete Google Merchant Center integration in
            LXRGuide?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleGMCDeleteConnection(gmcMerchantId, gmcId)}
            color="primary"
            autoFocus
          >
            Delete
          </Button>
          <Button onClick={handleDialogToCloseGMC} color="primary" autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/*Delete Snackbar*/}
      <MySnackbarWrapper
        open={alert}
        onClose={handleCloseGMCAlert}
        message={"GMC Account deleted successfully"}
        verticalAlign={"bottom"}
        horizontalAlign={"right"}
        duration={6000}
        variant={"success"}
      />

      <MySnackbarWrapper
        open={refreshAlert}
        onClose={handleCloseGMCRefreshAlert}
        message={gmcError ? "Error in updating refresh tokens" : "Refresh tokens updated"}
        verticalAlign={"bottom"}
        horizontalAlign={"right"}
        duration={6000}
        variant={gmcError ? "error" : "success"}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  deleteGMCAccountReducer: state.deleteGMCAccountReducer,
  fetchGMCAuthURLReducer: state.fetchGMCAuthURLReducer,
  fetchGMCAccountReducer: state.fetchGMCAccountReducer,
});
const mapDispatchToProps = (dispatch) => ({
  deleteGMCConnection: (gmcId) => dispatch(deleteGMCConnection(gmcId)),
  fetchGMCAuthURL: () => dispatch(fetchGMCAuthURL()),
  fetchConnectionRelatedInfo: () => dispatch(fetchConnectionRelatedInfo()),
  fetchGMCData: (gmcId) => dispatch(fetchGMCData(gmcId))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(GmcConnection));
