const CommonStyles = (theme) => ({
  paper: {
    padding: theme.spacing(1),
    border: "1px solid" + theme.palette.common.border,
    borderRadius: 6,
    boxShadow: "4px 4px 18px " + theme.palette.boxShadowC,
    minHeight: 471,
    // maxHeight: 470,
  },
  geoPaper: {
    padding: theme.spacing(1),
    border: "1px solid" + theme.palette.common.border,
    borderRadius: 6,
    boxShadow: "4px 4px 18px " + theme.palette.boxShadowC,
    minHeight: 468,
  },
  skuPaper: {
    padding: theme.spacing(1),
    border: "1px solid" + theme.palette.common.border,
    borderRadius: 6,
    boxShadow: "4px 4px 18px " + theme.palette.boxShadowC,
    minHeight: 454,
  },
  card: {
    boxShadow: "unset",
    height: "100%",
  },
  tab: {
    color: theme.palette.text.primary,
    fontWeight: "bold",
    fontSize: theme.typography.pxToRem(14),
    fontFamily: "Lato",
    textTransform: "capitalize",
    minWidth: 60,
  },
  selectedTab: {
    // background: theme.palette.common.mediumGrey,
    // padding: "5px 7px 5px 7px",
    // borderRadius: 21,
    color: theme.palette.secondary.main,
  },
  indicator: {
    backgroundColor: theme.palette.secondary.main,
  },
  labelContainer: {
    paddingBottom: 0,
  },
  legends: {
    padding: "20px 0px",
  },
  legM: {
    marginBottom: 2.5,
  },
  icons: {
    cursor: "pointer",
  },
  countryLevel: {
    display: "flex",
    justifyContent: "flex-end",
  },
  countryLevelBtn: {
    marginTop: 0,
    // marginLeft: theme.spacing(5)
  },
  loadingDiv: {
    height: "100%",
    minHeight: 430,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});
export default CommonStyles;
