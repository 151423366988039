import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getSearchConsoleAuthUrl } from "../../actions";
import {
  deleteSearchConsoleAccount,
  fetchSearchConsoleAcc,
} from "../../actions/connectionPageActions";
import { fetchConnectionRelatedInfo } from "../../actions";
import MySnackbarWrapper from "../snackbars";
import {
  NewOrangeContainedBtn,
  NewOutLinedRectCancelBlueBtn,
} from "../../components/buttons";
import icons from "../../components/icons";
import { ConnectionTypeTypography } from "../../components/typography";
import { useHistory } from "react-router-dom";

const styles = (theme) => ({
  GaGrid: {
    backgroundColor: "#FFFFFF",
    [theme.breakpoints.up("md")]: {
      width: "100%",
      height: "88px",
      border: "solid 2px #BBBBBB",
      borderRadius: "6px",
      padding: "0px 10px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "15px",
      width: "100%",
      height: "300px",
      border: "solid 1px #BBBBBB",
      borderRadius: "3px",
      textAlign: "center",
    },
  },
  TextCenter: {
    textAlign: "Center",
    fontSize: "16px",
  },

  gridItems: {
    margin: "Auto 0",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  buttons: {
    backgroundColor: "#F58120",
    border: "solid 1px #1E334E",
  },
  dataStyle: {
    fontWeight: "500",
    color: "#1E334E",
    wordBreak: "break-word",
  },
  verticalLine: {
    paddingRight: "7px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
});

function SearchConsole(props) {
  window.scrollTo(0, 0);
  const classes = props.classes;

  const {
    searchConsoleData,
    deleteSearchConsoleAccount,
    deleteSearchConsoleAccountReducer,
    fetchSearchConsoleAccReducer,
    fetchSearchConsoleAcc,
    getSearchConsoleAuthUrl,
    fetchConnectionRelatedInfo,
    setSearchConsoleData,
  } = props;

  const [searchConsoleURI, setSearchConsoleURI] = useState("");
  const [searchConsoleId, setSearchConsoleId] = useState();
  const [searchConsoleAuth, setSearchConsoleAuth] = useState();
  const [loading, setLoading] =useState(false);


  useEffect(async () => {
    const data = await getSearchConsoleAuthUrl(null, null);
    if (data && data.authUrl) {
      setSearchConsoleAuth(data.authUrl);
    }
  }, []);

  useEffect(() => {
    if (searchConsoleData) {
      setSearchConsoleId(
        searchConsoleData.searchConsoleId
          ? searchConsoleData.searchConsoleId
          : "-"
      );
      setSearchConsoleURI(
        searchConsoleData.searchConsoleURI
          ? searchConsoleData.searchConsoleURI
          : "-"
      );
    }
  }, [searchConsoleData]);

  //delete confirmation dialog
  const [open, setOpen] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState();

  const [success, setSuccess] = useState();

  const handleDialogToClose = () => {
    setOpen(false);
  };
  const handleDialogToOpen = () => {
    setOpen(true);
  };

  //snackbar
  const [alert, setAlert] = useState(false);

  const handleOpenAlert = () => {
    setAlert(true);
  };
  const handleCloseAlert = () => {
    setAlert(false);
  };

  const [error, setError] = useState(false);

  const [deleteObjectAccountName, setDeleteObjectAccountName] = useState("");
  const history = useHistory();

  const handleSearchConsoleDeleteConnection = async (
    searchConsoleId,
    searchConsoleURI
  ) => {
    setDeleteObjectAccountName(searchConsoleURI);
    setOpen(false);
    const data = await deleteSearchConsoleAccount(searchConsoleId);
    if (data === true) {
      setError(false);
      setSnackbarMsg("Search Console Account deleted successfully");
    } else {
      setError(true);
      setSnackbarMsg("Error in deleting Search Console Account");
    }
    setAlert(true);

  };

  useEffect(() => {
    if (
      deleteSearchConsoleAccountReducer.message ===
      "DELETE_SEARCH_CONSOLE_RECEIVED"
    ) {
      if (deleteObjectAccountName === searchConsoleURI) {
        setSearchConsoleURI("");
      }
    }
  }, [deleteSearchConsoleAccountReducer]);

  const setErrorMessage = (data) => {
    if (
      data.error == "Refresh tokens updated successfully" ||
      data.error == "Search Console account added successfully"
    ) {
      setError(false);
      setSearchConsoleData(data.searchConsoleDetails);
    } else {
      setError(true);
    }
    setSnackbarMsg(
      data.error != null && data.error != ""
        ? data.error
        : "Something went wrong"
    );
    setAlert(true);
  };

  const handleAddAccount = () => {
    if (searchConsoleAuth != undefined && searchConsoleAuth != "") {
      let newWindow = window.open(
        searchConsoleAuth,
        "Search Console Authentication",
        "menubar=no,status=no,location=no,toolbar=no,scrollbars=yes,resizable=no, width=700, height=600, top=30"
      );

      const checkWindowStatus = setInterval(async () => {
        if (newWindow && newWindow.closed) {
          clearInterval(checkWindowStatus);
          setLoading(true);
          const data = await fetchSearchConsoleAcc();
          setErrorMessage(data);
          setLoading(false);
        }
      }, 3000);
    }
  };

  return (
    <div style={{ marginTop: "20px" }}>
      <Grid container className={classes.GaGrid}>
        <Grid item xs={12} sm={12} md={3} lg={3} className={classes.gridItems}>
          <div className={classes.flexCenter}>
            {icons.GoogleSearchConsole}
            <ConnectionTypeTypography>
              Google Search Console
            </ConnectionTypeTypography>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5} className={classes.gridItems}>
          {searchConsoleURI === "" || searchConsoleURI === "-" ? (
            <Typography></Typography>
          ) : (
            <div className={classes.flexCenter}>
              <div className={classes.verticalLine}>
                {icons.SegregationLine}
              </div>
              <Typography style={{ color: "#747474" }}>
                Search Console URL:
                <br />
                <span className={classes.dataStyle}>{searchConsoleURI}</span>
              </Typography>
            </div>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2} className={classes.gridItems}>
          {searchConsoleData &&
          (deleteSearchConsoleAccountReducer["message"] === "" ||
            deleteSearchConsoleAccountReducer["message"] ===
              "DELETE_SEARCH_CONSOLE_FAILED") ? (
            <Typography>
              <NewOutLinedRectCancelBlueBtn onClick={handleAddAccount} disabled={loading}>
                Refresh Token
              </NewOutLinedRectCancelBlueBtn>
            </Typography>
          ) : (
            <Typography></Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2} className={classes.gridItems}>
          {searchConsoleData &&
          (deleteSearchConsoleAccountReducer["message"] === "" ||
            deleteSearchConsoleAccountReducer["message"] ===
              "DELETE_SEARCH_CONSOLE_FAILED") ? (
            <Typography>
              <NewOutLinedRectCancelBlueBtn onClick={handleDialogToOpen}>
                Delete Account
              </NewOutLinedRectCancelBlueBtn>
            </Typography>
          ) : (
            <Typography>
              <NewOrangeContainedBtn onClick={handleAddAccount} type={"button"} disabled={loading}>
                +Add Account
              </NewOrangeContainedBtn>
            </Typography>
          )}
        </Grid>
      </Grid>

      {/* Search Console Delete Dialog Box */}
      <Dialog open={open} onClose={handleDialogToOpen}>
        <DialogTitle>{"Delete Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you really want to delete Google Search Console integration in
            LXRGuide?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() =>
              handleSearchConsoleDeleteConnection(
                searchConsoleId,
                searchConsoleURI
              )
            }
            color="primary"
            autoFocus
          >
            Delete
          </Button>
          <Button onClick={handleDialogToClose} color="primary" autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/*Delete Snackbar*/}
      <MySnackbarWrapper
        open={alert}
        onClose={handleCloseAlert}
        message={snackbarMsg}
        verticalAlign={"bottom"}
        horizontalAlign={"right"}
        duration={6000}
        variant={error ? "error" : "success"}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  deleteSearchConsoleAccountReducer: state.deleteSearchConsoleAccountReducer,
  fetchSearchConsoleAccReducer: state.fetchSearchConsoleAccReducer,
});
const mapDispatchToProps = (dispatch) => ({
  deleteSearchConsoleAccount: (searchConsoleId) =>
    dispatch(deleteSearchConsoleAccount(searchConsoleId)),
  getSearchConsoleAuthUrl: (taskId, taskDataId) =>
    dispatch(getSearchConsoleAuthUrl(taskId, taskDataId)),
  fetchSearchConsoleAcc: () => dispatch(fetchSearchConsoleAcc()),
  fetchConnectionRelatedInfo: () => dispatch(fetchConnectionRelatedInfo()),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(SearchConsole));
