import { Grid, Paper } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import Typography from "@mui/material/Typography";
import classNames from "classnames";
import React from "react";
import { connect } from "react-redux";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import {
  getSteps,
  getStepsForPackagesView,
  getStepsForSEODashboardView,
} from "./template";

import icons from "../icons";

const styles = (theme) => ({
  mainTitle: {
    fontWeight: "800",
    fontFamily: "Montserrat",
  },
  gridP: {
    padding: theme.spacing(2),
    paddingTop: 0,
  },
  gridM: {
    padding: theme.spacing(2),
    paddingTop: 0,
    marginBottom: theme.spacing(6),
  },
  caption: {
    fontFamily: "Lato",
    fontSize: 16,
  },
  stepTitle: {
    fontFamily: "Montserrat",
  },
  stepper: {
    boxShadow: "unset",
  },
  Paper: {
    boxShadow: "unset",
  },
});
function stepCalculation(status) {
  let activeStatus = 0;
  if (status == "PURCHASED") {
    activeStatus == 0;
  } else if (status == "PROGRESS") {
    activeStatus == 1;
  } else if (status == "DELIVERED") {
    activeStatus == 2;
  } else if (status == "ACCEPTED") {
    activeStatus == 3;
  }
  return activeStatus;
}

function seoDashboardStepper(props) {
  // const steps = getStepsForSEODashboardView();
  // const {activestep} = 0;
  const { classes } = props;
  const { activeStep } = stepCalculation("PURCHASED");
  return (
    <Grid container justifyContent={"left"}>
      <Grid item md={8} sm={12} className={classes.gridP}>
        <Stepper
          className={classes.stepper}
          activeStep={3}
          orientation="horizontal"
          alternativeLabel
        >
          <Step>
            <StepLabel
              className={classes.stepTitle}
              icon={activeStep == 0 ? icons.grayCircle : icons.blueCircle}
            >
              <b>{"PURCHASED"} </b>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel className={classes.stepTitle} icon={icons.yellowCircle}>
              <span style={{ color: "#F3AE1B" }}>
                <b>{"PROGRESS"}</b>
              </span>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel className={classes.stepTitle} icon={icons.grayCircle}>
              <span style={{ color: "#C4C4C4" }}>
                <b>{"DELIVERED"}</b>
              </span>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel className={classes.stepTitle} icon={icons.grayCircle}>
              <span style={{ color: "#C4C4C4" }}>
                <b>{"ACCEPTED"}</b>
              </span>
            </StepLabel>
          </Step>
        </Stepper>
      </Grid>
    </Grid>
  );
}

export default connect(null, null)(withStyles(styles)(seoDashboardStepper));
