import * as types from "../actions/actionTypes";
export default function OnDemandPromocodeReducer(
  state = {
    reducer_message: "",
  },
  action
) {
  switch (action.type) {
    case types.PROMOCODE_CONFIRMATION_REQUESTED:
      return Object.assign({}, state, {
        reducer_message: "PROMOCODE_CONFIRMATION_REQUESTED",
      });
    case types.PROMOCODE_CONFIRMATION_RECEIVED:
      let { data } = action;
      console.log("PROMOCODE_CONFIRMATION_RECEIVED", data);
      return Object.assign({}, state, {
        ...action.data,
        reducer_message: "PROMOCODE_CONFIRMATION_RECEIVED",
      });
    case types.PROMOCODE_CONFIRMATION_FAILED:
      return Object.assign({}, state, {
        reducer_message: "PROMOCODE_CONFIRMATION_FAILED",
      });      
    default:
      return state;
  }
}