import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Grid, Typography } from "@mui/material";

import withStyles from "@mui/styles/withStyles";

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="xl" />;

const styles = (theme) => ({
  mainDiv: {
    [theme.breakpoints.up("md")]: {
      margin: "20px 20px 150px 20px",
      textAlign: "center",
    },
    [theme.breakpoints.down("xl")]: {
      margin: "20px 20px 160px 20px",
      textAlign: "center",
    },
  },
  progressBar: {
    [theme.breakpoints.up("md")]: {
      width: "300px",
      height: "200px",
      textAlign: "center",
    },
    [theme.breakpoints.down("xl")]: {
      width: "250px",
      height: "150px",
      textAlign: "center",
    },
  },
});

function LinearProgressBar(props) {
  const classes = props.classes;
  const { status, percentage } = props;

  return (
    <div className={classes.mainDiv}>
      <div className={classes.progressBar}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CircularProgressbar
              value={percentage}
              text={`${percentage}%`}
              styles={buildStyles({
                textColor: "#1E334E",
                pathColor:
                  percentage === 0
                    ? "none"
                    : percentage > 0 && percentage <= 25
                    ? "#1098F7"
                    : percentage > 25 && percentage <= 50
                    ? "#A2B6C3"
                    : percentage > 50 && percentage <= 75
                    ? "#F58120"
                    : "#1EBC61",
                trailColor: "grey",
              })}
            />
            <Typography>{status} </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
export default withWidth()(
  withStyles(styles, { withTheme: true })(LinearProgressBar)
);
