import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Grid,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  Checkbox,
  TextField,
  Tooltip,
  Skeleton,
} from "@mui/material";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import EditOutlined from "@mui/icons-material/EditOutlined";
import { SaveOutlined } from "@mui/icons-material";

export const AdCopySection = ({
  adCopy,
  type,
  heading,
  onSelect,
  handleTextAreaChange,
  count,
  handleGenerateRSA,
  charLimit,
  loading,
  disableStructure,
}) => {
  const [editModes, setEditModes] = useState([]);

  const handleEditToggle = (index) => {
    setEditModes((prevEditModes) => {
      const indexInArray = prevEditModes.indexOf(index);
      if (indexInArray === -1) {
        return [...prevEditModes, index];
      } else {
        const newEditModes = [...prevEditModes];
        newEditModes.splice(indexInArray, 1);
        return newEditModes;
      }
    });
  };
  return (
    <Grid
      item
      xs={4}
      style={{
        border: "1px solid #F2F2F4",
        height: "380px",
        overflowY: "scroll",
      }}
    >
      <AppBar position="sticky" color="primary" sx={{ padding: "0 1%", zIndex:100 }}>
        <Toolbar
          variant="dense"
          disableGutters
          sx={{ justifyContent: "space-between", minHeight: "40px" }}
        >
          <Typography variant="subtitle2">
            {loading ? (
              <Skeleton />
            ) : (
              <>
                {heading}
                <Tooltip title= {`Click to generate new ${type}s`}>
                  <IconButton
                    size="small"
                    edge="start"
                    color="inherit"
                    aria-label={type}
                    onClick={() => handleGenerateRSA(type)}
                    disabled={disableStructure}
                  >
                    <RefreshOutlinedIcon fontSize="1.3rem" />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </Typography>

          {loading ? (
            <Skeleton />
          ) : (
            <Typography variant="subtitle2">
              {adCopy.filter((row) => row.selected).length} / {count}
            </Typography>
          )}
        </Toolbar>
      </AppBar>
      {adCopy != null && adCopy.length > 0 ? (
        adCopy.map((row, index) => (
          <Grid
            container
            direction="column"
            key={index}
            style={{ padding: "2%" }}
          >
            <Grid
              item
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              xs={12}
              className=" adCopyHeader"
            >
              <Grid item>
                {loading ? (
                  <Skeleton />
                ) : (
                  <Typography variant="subtitle2">
                    {`${type} ${index + 1}`}
                  </Typography>
                )}
              </Grid>
              <Grid item>
                {loading ? (
                  <Skeleton />
                ) : (
                  <Checkbox
                    sx={{ padding: "0" }}
                    checked={adCopy.some(
                      (selectedRow, selectedIndex) =>
                      selectedIndex == index && selectedRow.selected
                    )}
                    disabled={
                      disableStructure ||
                      row.text.length > charLimit || (
                      adCopy.filter(
                        (selectedRow) => selectedRow.selected
                      ).length >= count 
                      &&
                      !adCopy.some(
                        (selectedRow, selectedIndex) =>
                        selectedIndex === index &&
                          selectedRow.selected
                      ) )
                    }
                    onChange={(e) => onSelect(e, type, index)}
                    size="small"
                    color="secondary"
                  />
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} className="adCopyContent">
              {loading ? (
                <Skeleton />
              ) : (
                <textarea
                  id={`${type}-${index}`}
                  value={row.text}
                  className="textAreaCss"
                  onChange={(event) => handleTextAreaChange(event, type, index)}
                  disabled={editModes.includes(index) ? false : true}
                />
              )}
            </Grid>
            <Grid
              item
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              xs={12}
              className="adCopyFooter"
            >
              <Grid item>
                {loading ? (
                  <Skeleton />
                ) : (
                  <Typography variant="subtitle2" style={{color:"#9A9A9A"}}>
                    Char: &nbsp;
                    <Tooltip
                      title={
                        row.text.length > charLimit
                          ? `${type} is more than ${charLimit} characters`
                          : ""
                      }
                    >
                      <span
                        style={{
                          color:
                            row.text.trim().length > charLimit ? "red" : "#747474",
                        }}
                      >
                        {row.text ? row.text.trim().length : 0}
                      </span>
                    </Tooltip>
                    /{charLimit}
                  </Typography>
                )}
              </Grid>
              <Grid item>
                {loading ? (
                  <Skeleton />
                ) : (
                  <IconButton
                    aria-label={`edit-${type}-${index}`}
                    onClick={() => handleEditToggle(index)}
                    sx={{ padding: 0 }}
                    disabled={disableStructure}
                  >
                    {editModes.includes(index) ? (
                      <SaveOutlined fontSize="1.3rem" />
                    ) : (
                      <EditOutlined fontSize="1.3rem" />
                    )}
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </Grid>
        ))
      ) : (
        <Typography variant="subtitle2" align="center">
          No Data to Show
        </Typography>
      )}
    </Grid>
  );
};
