import * as types from "../actions/actionTypes";

export function deleteGleAdsAccountReducer(
    state = {
      message: "",
    },
    action
  ) {
    switch (action.type) {
      case types.DELETE_GLE_ADS_REQUESTED:
        return Object.assign({}, state, {
          message: "DELETE_GLE_ADS_REQUESTED",
        });
      case types.DELETE_GLE_ADS_RECEIVED:
        let { data } = action;
        return Object.assign({}, state, {
          ...action.data,
          message: "DELETE_GLE_ADS_RECEIVED",
        });
      case types.DELETE_GLE_ADS_FAILED:
        return Object.assign({}, state, {
          message: "DELETE_GLE_ADS_FAILED",
        });
      default:
        // console.log("The state of Delete Connections Data for gle:", state);
        return state;
    }
  }
  export function deleteBingAdsAccountReducer(
    state = {
      message: "",
    },
    action
  ) {
    switch (action.type) {
      case types.DELETE_BING_ADS_REQUESTED:
        return Object.assign({}, state, {
          message: "DELETE_BING_ADS_REQUESTED",
        });
      case types.DELETE_BING_ADS_RECEIVED:
        let { data } = action;
        return Object.assign({}, state, {
          ...action.data,
          message: "DELETE_BING_ADS_RECEIVED",
        });
      case types.DELETE_BING_ADS_FAILED:
        return Object.assign({}, state, {
          message: "DELETE_BING_ADS_FAILED",
        });
      default:
        // console.log("The state of Delete Connections Data for bing:", state);
        return state;
    }
  }
  export function deleteGMCAccountReducer(
    state = {
      message: "",
    },
    action
  ) {
    switch (action.type) {
      case types.DELETE_GMC_REQUESTED:
        return Object.assign({}, state, {
          message: "DELETE_GMC_REQUESTED",
        });
      case types.DELETE_GMC_RECEIVED:
        let { data } = action;
        return Object.assign({}, state, {
          ...action.data,
          message: "DELETE_GMC_RECEIVED",
        });
      case types.DELETE_GMC_FAILED:
        return Object.assign({}, state, {
          message: "DELETE_GMC_FAILED",
        });
      default:
        // console.log("The state of Delete Connections Data for gmc:", state);
        return state;
    }
  }

  export function deleteShoppingFeedReducer(
    state = {
      message: "",
    },
    action
  ) {
    switch (action.type) {
      case types.DELETE_SHOPPING_FEED_REQUESTED:
        return Object.assign({}, state, {
          message: "DELETE_SHOPPING_FEED_REQUESTED",
        });
      case types.DELETE_SHOPPING_FEED_RECEIVED:
        let { data } = action;
        return Object.assign({}, state, {
          ...action.data,
          message: "DELETE_SHOPPING_FEED_RECEIVED",
        });
      case types.DELETE_SHOPPING_FEED_FAILED:
        return Object.assign({}, state, {
          message: "DELETE_SHOPPING_FEED_FAILED",
        });
      default:
        // console.log("The state of Delete Connections Data for gmc:", state);
        return state;
    }
  }

  export function deleteSearchConsoleAccountReducer(
    state = {
      message: "",
    },
    action
  ) {
    switch (action.type) {
      case types.DELETE_SEARCH_CONSOLE_REQUESTED:
        return Object.assign({}, state, {
          message: "DELETE_SEARCH_CONSOLE_REQUESTED",
        });
      case types.DELETE_SEARCH_CONSOLE_RECEIVED:
        let { data } = action;
        return Object.assign({}, state, {
          ...action.data,
          message: "DELETE_SEARCH_CONSOLE_RECEIVED",
        });
      case types.DELETE_SEARCH_CONSOLE_FAILED:
        return Object.assign({}, state, {
          message: "DELETE_SEARCH_CONSOLE_FAILED",
        });
      default:
        console.log("The state of Delete Connections Data for search console:", state);
        return state;
    }
  }
  export function fetchGMCAuthURLReducer(
    state = {
      message: "",
    },
    action
  ) {
    switch (action.type) {
      case types.GMC_AUTH_URL_REQUESTED:
        return Object.assign({}, state, {
          message: "GMC_AUTH_URL_REQUESTED",
        });
      case types.GMC_AUTH_URL_RECEIVED:
        let { data } = action;
        return Object.assign({}, state, {
          ...action.data,
          message: "GMC_AUTH_URL_RECEIVED",
        });
      case types.GMC_AUTH_URL_FAILED:
        return Object.assign({}, state, {
          message: "GMC_AUTH_URL_FAILED",
        });
      default:
        // console.log("The state of Delete Connections Data for gmc:", state);
        return state;
    }
  }

  export function fetchGMCAccountReducer(
    state = {
      message: "",
    },
    action
  ) {
    switch (action.type) {
      case types.GMC_DETAILS_REQUESTED:
        return Object.assign({}, state, {
          message: "GMC_DETAILS_REQUESTED",
        });
      case types.GMC_DETAILS_RECEIVED:
        let { data } = action;
        return Object.assign({}, state, {
          ...action.data,
          message: "GMC_DETAILS_RECEIVED",
        });
      case types.GMC_DETAILS_FAILED:
        return Object.assign({}, state, {
          message: "GMC_DETAILS_FAILED",
        });
      default:
        // console.log("The state of GMC ACCOUNT FETCH:", state);
        return state;
    }
  }

  export function fetchSearchConsoleAccReducer(
    state = {
      message: "",
    },
    action
  ) {
    switch (action.type) {
      case types.FETCH_SEARCH_CONSOLE_ACCOUNT_REQUESTED:
        return Object.assign({}, state, {
          message: "FETCH_SEARCH_CONSOLE_ACCOUNT_REQUESTED",
        });
      case types.FETCH_SEARCH_CONSOLE_ACCOUNT_RECEIVED:
        let { data } = action;
        return Object.assign({}, state, {
          ...action.data,
          message: "FETCH_SEARCH_CONSOLE_ACCOUNT_RECEIVED",
        });
      case types.FETCH_SEARCH_CONSOLE_ACCOUNT_FAILED:
        return Object.assign({}, state, {
          message: "FETCH_SEARCH_CONSOLE_ACCOUNT_FAILED",
        });
      default:
        console.log("The state of GMC ACCOUNT FETCH:", state);
        return state;
    }
  }