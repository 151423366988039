import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Tasks from '../../components/dashboard/tasks'
import {CompactCardContent, MainSectionCardHeader} from "../cards";
import { Button, Typography, Card } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import {connect} from "react-redux";
import * as _ from "lodash";
import {sortTasks} from "../../reducers/common";
import {getTasksByAccount} from "../../actions/index";
import {createHashHistory} from 'history';
import icons from "../icons";

const CustomCard = withStyles(theme => ({
    root: {
        boxShadow: "none",
    }
}))(Card);


class AdminTaskAnalysis extends React.PureComponent {
    constructor(props) {
        super(props);
        this.ppcId = this.props.match.params.ppcId;
        this.accountId = this.props.match.params.accountId;
    }

    componentDidMount() {
        const {getTasksByAccount} = this.props;
        getTasksByAccount(this.ppcId, this.accountId);
    }

    render() {
        const {taskAnalysis} = this.props;
        console.log("render taskAnalysis=", taskAnalysis);
        const history = createHashHistory();
        return (
            <div>
                <CustomCard>
                    {/*<MainSectionCardHeader/>*/}
                    <CompactCardContent>
                        <Typography align={"right"}>
                            <Button style={{margin: 4}}
                                    variant={"outlined"}
                                    onClick={() => history.goBack()}>{icons.ChevronArrowLeft}Back</Button>
                        </Typography>
                        <Tasks {...this.props} />
                    </CompactCardContent>
                </CustomCard>
            </div>
        );
    }
}

AdminTaskAnalysis.propTypes = {};

//export default connect(mapStateToProps,mapDispatchToProps)(Dashboard);

const mapStateToProps = state => ({
    firstTaskObj: _.head(sortTasks(state.groupedTaskData.TaskType)),
    taskAnalysis: state.taskAnalysis,
});
const mapDispatchToProps = (dispatch) => ({
    getTasksByAccount: (ppcId, accountId) => dispatch(getTasksByAccount(ppcId, accountId))
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminTaskAnalysis);
