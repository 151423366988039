import React, { useState, useEffect } from "react";
import {
  JsSectionTypography,
  JsSubSectionTypography,
} from "../../../components/typography";
import { Typography, Grid, Box, Snackbar, Alert } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import { JsOrangeContainedBtn } from "../../../components/buttons";
import {
  fetchAllUsersData,
  createUser,
  fetchJsAccounts,
} from "../../../actions/jumpstartRevamped";
import JsAddUser from "./jsAddUser";
import "../jumpstart.css";
import JumpstartMain from "../jumpstartMain";
import { connect } from "react-redux";
import JsUsers from "./jsUsers";

function JsUserManagement(props) {
  const {
    fetchAllUsersData,
    fetchAllUserDataReducer,
    fetchJsAccountsReducer,
    createUser,
    createUserReducer,
    fetchJsAccounts,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [userList, setUserList] = useState();
  const [jwtToken, setJwtToken] = useState(sessionStorage.getItem("jwtToken"));
  const [jsAccounts, setJsAccounts] = useState();
  const [userData, setUserData] = useState(null);

  //Snackbar
  const [error, setError] = useState();
  const [snackbarMsg, setSnackbarMsg] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  const handleUserDataUpdate = (updatedUser) => {
    const userExists = userList.some((user) => user.id === updatedUser.id);

    if (userExists) {
      const updatedUsersArray = userList.map((user) =>
        user.id === updatedUser.id ? updatedUser : user
      );
      setUserList(updatedUsersArray);
    } else {
      setUserList((prevUserData) => [...prevUserData, updatedUser]);
    }
  };

  const handleJsUser = async (user) => {
    setUserData(user);
    if (user !== undefined) {
      const data = await createUser(jwtToken, user);
      if (data.status == 201) {
        const { status, ...newUser } = data;
        handleUserDataUpdate(newUser);
        setError(false);
        setSnackbarMsg("User has been successfully created");
        setIsOpen(false);
        setSnackbarOpen(true);
      } else if (data.status == 400) {
        setError(true);
        setSnackbarMsg("Email Id is already in use");
        setSnackbarOpen(true);
      } else {
        setError(true);
        setSnackbarMsg("Error in creating a new user");
        setSnackbarOpen(true);
      }
    }
  };

  useEffect(() => {
    if (jsAccounts == undefined) {
      fetchJsAccounts(jwtToken);
    }
  }, [jsAccounts]);

  useEffect(() => {
    if (fetchJsAccountsReducer.message === "JS_ACCOUNTS_RECEIVED") {
      const { message, status, ...accounts } = fetchJsAccountsReducer;
      setJsAccounts(Object.values(accounts));
    }
  }, [fetchJsAccountsReducer]);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setIsOpen(open);
  };

  const handleCloseIcon = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    async function fetchUserData() {
      const data = await fetchAllUsersData(jwtToken);
    }
    fetchUserData();
  }, []);

  useEffect(() => {
    if (fetchAllUserDataReducer.message === "JS_ALL_USER_DETAILS_RECEIVED") {
      const { message, ...userData } = fetchAllUserDataReducer;
      setUserList(Object.values(userData));
    }
  }, [fetchAllUserDataReducer]);

  return (
    <JumpstartMain>
      <div className="stickyHeader">
        <Grid container direction="row" alignItems="center">
          <Grid item xs={12} sm={10}>
            <Grid container direction="column" rowSpacing={2}>
              <Grid item>
                <JsSectionTypography>User Management</JsSectionTypography>
              </Grid>
              <Grid item>
                <JsSubSectionTypography>
                  Add, Remove, or Assign Role to the users
                </JsSubSectionTypography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={2}>
            <JsOrangeContainedBtn
              style={{ fontSize: "0.875rem" }}
              onClick={toggleDrawer(true)}
            >
              + Add User
            </JsOrangeContainedBtn>
          </Grid>
        </Grid>
        <Drawer
          anchor="right"
          open={isOpen}
          onClose={toggleDrawer(false)}
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}
        >
          <Box sx={{ width: 550 }}>
            <JsAddUser
              handleCloseIcon={handleCloseIcon}
              jsAccounts={jsAccounts}
              handleJsUser={handleJsUser}
            />
          </Box>
        </Drawer>
      </div>
      <JsUsers
        userList={userList}
        jsAccounts={jsAccounts}
        jwtToken={jwtToken}
        handleMainUserList={setUserList}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={error ? "error" : "success"}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarMsg}
        </Alert>
      </Snackbar>
    </JumpstartMain>
  );
}
const mapStateToProps = (state) => ({
  fetchAllUserDataReducer: state.fetchAllUserDataReducer,
  fetchJsAccountsReducer: state.fetchJsAccountsReducer,
  createUserReducer: state.createUserReducer,
});
const mapDispatchToProps = (dispatch) => ({
  fetchAllUsersData: (jwtToken) => dispatch(fetchAllUsersData(jwtToken)),
  createUser: (jwtToken, userData) => dispatch(createUser(jwtToken, userData)),
  fetchJsAccounts: (jwtToken) => dispatch(fetchJsAccounts(jwtToken)),
});
export default connect(mapStateToProps, mapDispatchToProps)(JsUserManagement);
