import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import {
  BlueBtn,
  CustomDialogActions,
  CustomDialogBox,
  CustomDialogTitle,
  GreyBtn,
} from "../dialog";
import icons from "../icons";
import { CardContent, Typography, Grid } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check.js";
import WaitingIcon from "@mui/icons-material/HourglassEmpty.js";
import green from "@mui/material/colors/green";
import amber from "@mui/material/colors/amber";
import withStyles from "@mui/styles/withStyles";
// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="xl" />;

const CustomTypographyText = withStyles((theme) => ({
  root: {
    fontWeight: "normal",
    color: theme.palette.trailError,
  },
}))(Typography);

const CustomStyles = (theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
  titleDialog: {
    action: {
      display: "flex",
      "&> h2": {
        display: "flex",
      },
    },
  },
  dialogCounter: {
    color: theme.palette.safeAndSecure,
    fontSize: 12,
  },
  actionsBtns: {
    margin: 0,
  },
});

const CustomDialogTitle1 = withStyles((theme) => ({
  root: {
    display: "flex",
  },
}))(CustomDialogTitle);

function BrandDialog(props) {
  const {
    handleClose,
    open,
    title,
    message,
    messageType,
    handleAction,
    subMessage,
    graderUser,
    classes,
    fullWidth,
    children,
    safeAndSecure,
    counter,
    width,
  } = props;
  return (
    <div>
      <CustomDialogBox
        open={open}
        aria-labelledby=""
        aria-describedby="Description"
        maxWidth={"md"}
        fullWidth={fullWidth}
        onClose={handleClose}
      >
        <CustomDialogTitle1 className={classes.titleDialog}>
          {width == "sm" || width == "xs" ? (
            <Grid container>
              <Grid item>{icons.BrandLogo}</Grid>
              <Grid item>{title}</Grid>
            </Grid>
          ) : null}
          {!(width == "sm" || width == "xs") && (
            <div className={"flex alignItemsCenter"}>
              {icons.BrandLogo}&nbsp;{title}
            </div>
          )}
        </CustomDialogTitle1>
        <CardContent>
          <CustomTypographyText variant="h5" align={"center"}>
            {message ? message : null}
          </CustomTypographyText>
          <Typography variant={"subtitle1"} align={"center"}>
            {subMessage ? subMessage : null}
          </Typography>
          {children ? children : null}
        </CardContent>
        <CustomDialogActions
          classes={{
            root: classes.actionsBtns,
          }}
          style={{ justifyContent: safeAndSecure ? "flex-end" : "center" }}
        >
          {graderUser ? (
            <Fragment>
              <GreyBtn size="large" onClick={handleClose}>
                <a
                  href={"/logout.html"}
                  className={"flex alignItemsCenter"}
                  style={{ color: "inherit", textDecoration: "unset" }}
                >
                  <WaitingIcon />
                  Later
                </a>
              </GreyBtn>
              <BlueBtn size="large" onClick={handleAction}>
                <a
                  href={"/payment-details.html"}
                  className={"flex alignItemsCenter"}
                  style={{ color: "inherit", textDecoration: "unset" }}
                >
                  <CheckIcon style={{ color: "#F58120" }} /> Proceed To Pay
                </a>
              </BlueBtn>
            </Fragment>
          ) : null}
          {safeAndSecure ? (
            <Grid
              container
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Grid item></Grid>
              {counter ? (
                <Grid item>
                  <Typography className={classes.dialogCounter}>
                    Redirecting in {counter} sec...
                  </Typography>
                </Grid>
              ) : null}
              <Grid item>
                <BlueBtn size="large" onClick={handleAction}>
                  Continue
                </BlueBtn>
              </Grid>
            </Grid>
          ) : null}
        </CustomDialogActions>
      </CustomDialogBox>
    </div>
  );
}

export default withWidth()(withStyles(CustomStyles)(BrandDialog));
