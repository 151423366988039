import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Grid, IconButton } from "@mui/material";
import icons from "../../components/icons";
import { authenticateUser } from "../../actions/jumpstartRevamped";
import "./jumpstart.css";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { JsSubSectionTypography } from "../../components/typography";
import "./jumpstart.css";
import { useParams } from "react-router-dom";

function JsCampaignData(props) {
  const {
    campaignId,
    campaignName,
    budget,
    campaignType,
    value,
    index,
    campaignTypeinputRef,
    campaignBudgetinputRef,
    campaignNameinputRef,
    handleEditCampaign,
    authority,
    disableFramework,
    currencySymbol,
    currencyCode,
  } = props;

  const handleEdit = () => {
    // Invoke the parent callback with the data
    handleEditCampaign(campaignName, campaignType, budget, campaignId);
  };

  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        className={`custom-tab-wrapper ${
          value === index ? " selectedCampaignTab" : ""
        }`}
      >
        <Grid item xs={2} sm={2} md={1}>
          {/* <EditOutlinedIcon onClick={toggleDrawer(true)}>
          </EditOutlinedIcon> */}
          <IconButton onClick={handleEdit} disabled={disableFramework}>
          <EditOutlinedIcon fontSize="0.875rem"/>

          </IconButton>
        </Grid>
        <Grid item xs={7} sm={7} md={8}>
          <Grid container direction="column">
            <Grid item paddingBottom={1}>
              <JsSubSectionTypography>
                <span ref={campaignNameinputRef}>
                  {campaignName ? campaignName : "Please enter campaign name"}
                </span>
              </JsSubSectionTypography>
            </Grid>
            <Grid
              item
              className={
                campaignType === "SEARCH"
                  ? "search-type-css"
                  : campaignType === "DISPLAY"
                  ? "display-type-css"
                  : campaignType === "PERFORMANCE_MAX"
                  ? "pmax-type-css"
                  : campaignType === "DISCOVERY"
                  ? "dsa-type-css"
                  : ""
              }
            >
              <span ref={campaignTypeinputRef}>
                {campaignType ? campaignType : "Please select campaign type"}
              </span>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Grid container direction="column" style={{ textAlign: "right" }}>
            <Grid item>
              <JsSubSectionTypography>Daily Budget ({currencySymbol})</JsSubSectionTypography>
            </Grid>
            <Grid item>
              <JsSubSectionTypography ref={campaignBudgetinputRef}>
                {budget ? budget : "Please enter budget"}
              </JsSubSectionTypography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = (state) => ({
  authenticateUserReducer: state.authenticateUserReducer,
});
const mapDispatchToProps = (dispatch) => ({
  authenticateUser: (username, password, rememberMe) =>
    dispatch(authenticateUser(username, password, rememberMe)),
});

export default connect(mapStateToProps, mapDispatchToProps)(JsCampaignData);
