import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { Grid, Tabs, Tab, Typography, Box } from "@mui/material";
import "./jumpstart.css";
import JumpstartMain from "./jumpstartMain.jsx";
import {
  JsSectionTypography,
  JsSubSectionTypography,
} from "../../components/typography.jsx";

import JsBusinessDetails from "./jsBusinessDetails.jsx";
import PageHeader from "./commonComponents/pageHeaderDetails.jsx";
import CustomTabs from "./customTabs.jsx";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import icons from "../../components/icons.js";
import JsCampaignDetails from "./jsCampaignDetails.jsx";
import JsCampaignData from "./jsCampaignData.jsx";
import {
  fetchCampaignDetailsByJsAccountId,
  storeUpdatedCampaignData,
  campaignFrameworkDownload,
  fetchJsAccountById
} from "../../actions/jumpstartRevamped.js";
import {
  updateBusinessDetailsReducer,
  storeUpdatedCampaignDataReducer,
  fetchCampaignDetailsByJsAccountIdReducer,
} from "../../reducers/jumpstartRevampedReducer";
import { idID } from "@mui/material/locale";
import { set } from "lodash";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { JsTextAsButton } from "../../components/buttons.jsx";
import { sanitizeCompanyName } from "./commonComponents/companyNameCheck.js";

function JsCampaignFramework(props) {
  const {
    storeUpdatedCampaignData,
    storeUpdatedCampaignDataReducer,
    fetchUserInfoReducer,
    campaignFrameworkDownload,
    fetchJsAccountByIdReducer,
    fetchJsAccountById,
  } = props;
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [value, setValue] = useState("1");
  const [campaignName, setCampaignName] = useState("");
  const [campaignType, setCampaignType] = useState("SEARCH");
  const [dailyBudget, setDailyBudget] = useState("");
  const [campaignId, setCampaignId] = useState();
  // const [editCampaignName, setEditCampaignName] = useState();
  // const [editCampaignType, setEditCampaignType] = useState();
  // const [editDailyBudget, setEditDailyBudget] = useState();
  //currency code or symbol need to get from API
  const [currencySymbol, setCurrencySymbol] = useState();
  const [currencyCode, setCurrencyCode] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const tabs = [{ label: "Business Details" }, { label: "Campaign Details" }];
  const [updatedCampaignData, setUpdatedCampaignData] = useState([]);
  const [
    latestValueofupdatedCampaignData,
    setLatestValueofupdatedCampaignData,
  ] = useState([]);
  const [snackbarMsg, setSnackbarMsg] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [error, setError] = useState();

  const [authority, setAuthority] = useState();
  const [disableCampaignTab, setDisableCampaignTab] = useState(null);

  useEffect(() => {
    if (fetchUserInfoReducer.message == "JS_USER_INFO_RECEIVED") {
      setAuthority(fetchUserInfoReducer.authority);
    }
  }, [fetchUserInfoReducer]);



  const handleTabChange = (event, tabIndex) => {
    setCurrentTabIndex(tabIndex);
  };

  const handleCampaignNameChange = (name) => {
    setCampaignName(name);
  };

  const handleCampaignTypeChange = (type) => {
    setCampaignType(type);
  };

  const handleDailyBudgetChange = (budget) => {
    setDailyBudget(budget);
  };

  const token = sessionStorage.getItem("jwtToken");
  // const token = Cookies.get("jwtToken");

  const { id } = useParams();

  useEffect(async () => {
    await fetchJsAccountById(token, id);
  },[])

  let newTab = {};

  const handleUpdateCampaignData = async (campaign) => {
    return await storeUpdatedCampaignData(token, campaign);
  };

  const handleDoneButton = async (
    campaignId,
    campaignNewName,
    campaignNewType,
    campaignNewBudget,
    deleted
  ) => {
    if (campaignId != undefined || campaignId != null) {
      newTab = {
        id: campaignId,
        jsAccountId: id,
        name: campaignNewName,
        type: campaignNewType,
        budget: campaignNewBudget,
        delete: deleted,
      };
      await setUpdatedCampaignData((prevArray) => [...prevArray, newTab]);
      const data = await handleUpdateCampaignData(newTab);
      if (data && data.status == 200) {
        setError(false);
        setSnackbarMsg("Campaign details updated successfully");
      } else {
        setError(true);
        setSnackbarMsg("Error in updating campaign details");
      }
    } else {
      newTab = {
        jsAccountId: id,
        name: campaignNewName,
        type: campaignNewType,
        budget: campaignNewBudget,
      };
      await setUpdatedCampaignData((prevArray) => [...prevArray, newTab]);
      const data = await handleUpdateCampaignData(newTab);
      if (data && data.status == 200) {
        setError(false);
        setSnackbarMsg("Campaign details created successfully");
      } else if(data && data.status == 400) {
        setError(true);
        setSnackbarMsg("Campaign with same name is already present");
      } else {
        setError(true);
        setSnackbarMsg("Error in creating campaign details");
      }
    }
    handleCloseIcon();
    setSnackbarOpen(true);
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       let lastElement = updatedCampaignData.slice(-1);
  //       let lastElementArray = {};
  //       lastElementArray = lastElement[0];
  //       let data = "";
  //       if (lastElement.length > 0) {
  //         data = await storeUpdatedCampaignData(token, lastElementArray);
  //       }
  //       if (updatedCampaignData.length > 0) {
  //         if (data.status === 200) {
  //           setSnackbarMsg("Successfully updated campaign details");
  //           setError(false);
  //         } else {
  //           setError(true);
  //           setSnackbarMsg("Error in updating campaign details");
  //         }
  //         setIsOpen(false);
  //         setSnackbarOpen(true);
  //       }
  //     } catch (error) {
  //       console.error("Error updating campaign details:", error);
  //     }
  //   };
  //   fetchData(); // Call the async function
  // }, [updatedCampaignData]);


  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const [value1, setValue1] = useState(0);
  const history = useHistory();
  //(new campaign added)
  //Need to call API to store the new added campaign or the whole list of campaign?
  const handleAddNewCampaignData = (newTab) => {
    setUpdatedCampaignData((prevData) => {
      const newData = [...prevData, newTab];
      return newData;
    });
  };

  const handleChange = (event, newValue) => {
    setValue1(newValue);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsOpen(open);
  };

  const handleCloseIcon = () => {
    setIsOpen(false);
    setIsEditMode(false);
  };
  const campaignNameinputRef = useRef(null);
  const campaignTypeinputRef = useRef(null);
  const campaignBudgetinputRef = useRef(null);

  const [isEditMode, setIsEditMode] = useState(false);

  const handleEditCampaign = (
    editCampaignName,
    editCampaignType,
    editDailyBudget,
    campaignId
  ) => {
    // Capture the current values
    setCampaignName(editCampaignName);
    setCampaignType(editCampaignType);
    setDailyBudget(editDailyBudget);
    setCampaignId(campaignId);
    setIsEditMode(true);
    setIsOpen(true);
  };

  const handleClickAway = () => {
    setIsEditMode(false);
    setIsOpen(false);
  };

useEffect(()=>{
  if(fetchJsAccountByIdReducer.message == "JS_ACCOUNT_BY_ID_RECEIVED" && fetchJsAccountByIdReducer.id == id ){
    setCurrencyCode(fetchJsAccountByIdReducer.currencyCode);
    setCurrencySymbol(fetchJsAccountByIdReducer.currencySymbol);
  }
},[fetchJsAccountByIdReducer])
  const handleDownload = () => {
    if(fetchJsAccountByIdReducer.message == "JS_ACCOUNT_BY_ID_RECEIVED" && fetchJsAccountByIdReducer.id == id ){
      const data = campaignFrameworkDownload(token,id,fetchJsAccountByIdReducer.companyName);
    }
  }
  return (
    <JumpstartMain>
      <div className="pageContent">
        <PageHeader sectionHeading={"Campaign Framework:"} token={token} />
      </div>
      <div className="pageContainer">
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          style={{ borderBottom: "1px solid #E6E6E6" }}
        >
          <Grid item xs={8}>
            <CustomTabs
              variant="standard"
              tabs={tabs}
              currentTabIndex={currentTabIndex}
              handleTabChange={handleTabChange}
              tabcolor="#4E4E4E"
              disabled={disableCampaignTab}
            />
          </Grid>
          <Grid item xs={4} container justifyContent="flex-end">
            <Grid item>
              <JsTextAsButton color="inherit" onClick={() => handleDownload()}>
                <span>{icons.ExcelDownload}</span>&nbsp;Download
              </JsTextAsButton>
            </Grid>
            {/* <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                <div>
                  <JsSubSectionTypography className="centered-container">
                    <span>
                      <RemoveRedEyeOutlinedIcon sx={{ color: "#F58120" }} />
                    </span>
                    &nbsp;Preview
                  </JsSubSectionTypography>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                <div>
                  <JsSubSectionTypography className="centered-container">
                    <span>{icons.ExcelDownload}</span>&nbsp;Download
                  </JsSubSectionTypography>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                <div>
                  <JsSubSectionTypography className="centered-container">
                    <span>{icons.ExcelUpload}</span>&nbsp;Upload
                  </JsSubSectionTypography>
                </div>
              </Grid> */}
          </Grid>
        </Grid>

        {currentTabIndex === 0 && (
          <Box sx={{ p: 3 }}>
            <JsBusinessDetails
              handleTabChange={handleTabChange}
              authority={authority}
              handleCampaignDetailsDisable={setDisableCampaignTab}
            />
          </Box>
        )}
        {currentTabIndex === 1 && (
          <JsCampaignDetails
            campaignName={campaignName}
            dailyBudget={dailyBudget}
            campaignType={campaignType}
            currencySymbol={currencySymbol}
            currencyCode={currencyCode}
            handleCloseIcon={handleCloseIcon}
            handleCampaignNameChange={handleCampaignNameChange}
            handleCampaignTypeChange={handleCampaignTypeChange}
            handleDailyBudgetChange={handleDailyBudgetChange}
            handleDoneButton={handleDoneButton}
            handleAddNewCampaignData={handleAddNewCampaignData}
            toggleDrawer={toggleDrawer}
            value1={value1}
            handleChange={handleChange}
            updatedCampaignData={updatedCampaignData}
            isOpen={isOpen}
            jwtToken={token}
            jsAccountId={id}
            setCampaignName={setCampaignName}
            campaignNameinputRef={campaignNameinputRef}
            campaignTypeinputRef={campaignTypeinputRef}
            campaignBudgetinputRef={campaignBudgetinputRef}
            // edCampaignName={edCampaignName}
            // edCampaignType={edCampaignType}
            // edDailyBudget={edDailyBudget}
            handleEditCampaign={handleEditCampaign}
            isEditMode={isEditMode}
            handleClickAway={handleClickAway}
            authority={authority}
            campaignId={campaignId}
          />
        )}
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={error ? "error" : "success"}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarMsg}
        </Alert>
      </Snackbar>
    </JumpstartMain>
  );
}

// const mapStateToProps = (state) => ({});
// const mapDispatchToProps = (dispatch) => ({});

const mapStateToProps = (state) => ({
  storeUpdatedCampaignDataReducer: state.storeUpdatedCampaignDataReducer,
  storeAdgroupDataReducer: state.storeAdgroupDataReducer,
  fetchUserInfoReducer: state.fetchUserInfoReducer,
  campaignFrameworkDownloadReducer: state.campaignFrameworkDownloadReducer,
  fetchJsAccountByIdReducer: state.fetchJsAccountByIdReducer,
});
const mapDispatchToProps = (dispatch) => ({
  storeUpdatedCampaignData: (jwtToken, updatedCampaignData) =>
    dispatch(storeUpdatedCampaignData(jwtToken, updatedCampaignData)),
  campaignFrameworkDownload: (jwtToken, id, companyName) =>
    dispatch(campaignFrameworkDownload(jwtToken, id,companyName)),
    fetchJsAccountById: (jwtToken, id) =>
    dispatch(fetchJsAccountById(jwtToken, id)),  
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JsCampaignFramework);
