import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { CardContent, Card, Grid } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import PackagesSection from "../components/internalSiteGrader/PackagesSection";
import {Redirect} from "react-router-dom";
import {connect} from "react-redux";
import {updateSelectedSEOPackage} from "../actions";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";


function defaultRouteSwitch(siteGraderServiceDetails, domainUrl, emailId) {
    if (siteGraderServiceDetails && siteGraderServiceDetails.selectedPackage != null && siteGraderServiceDetails.selectedPackage > 0) {
        return <Redirect push to={`/seo-payment/${siteGraderServiceDetails.selectedPackage}`}/>;
    } else {
        return <PackagesSection domainUrl={domainUrl}/>;
    }
}

const styles = theme => ({
    cardContent: {
        paddingTop: 45,
        width: "100%"
    },
    card: {
        boxShadow: "unset",
        width: "100%"
    },
    mainTitle: {
        fontWeight: "800",
        fontFamily: "Montserrat",
    },
});

class SeoServicePageInternal extends React.PureComponent {
    constructor() {
        super();
        this.state = {
            isPackLoading: false
        }
    }

    /* async componentDidUpdate(prevProps, prevState, snapshot) {
         this.setState({isPackLoading: true});
         await this.props.updateSelectedPackage(null);
         this.setState({isPackLoading: false});
     }*/
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const {emailId, domainUrl} = this.props.userInfoSEOInternal;
        const {siteGraderServiceDetails, classes} = this.props;
        const {isPackLoading} = this.state;
        console.log("siteGraderServiceDetails", siteGraderServiceDetails);
        return (

            <Grid container>
                <Grid item md={12}>
                    <br/>
                    <br/>
                    <Typography component={"h5"} variant={"h5"} color={"textSecondary"}
                                className={classes.mainTitle} align={"left"}>
                        <b>Optimization service packs</b>
                    </Typography>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                </Grid>
                <Grid item md={12}>
                    {isPackLoading ? <CircularProgress/>
                        : defaultRouteSwitch(siteGraderServiceDetails, domainUrl, emailId)
                    }
                </Grid>
            </Grid>
        );
    }
}

SeoServicePageInternal.propTypes = {};

const mapStateToProps = state => ({
    userInfoSEOInternal: state.userInfoSEOInternal,
    siteGraderServiceDetails: state.siteGraderServiceDetails
});

const mapDispatchToProps = (dispatch) => ({
    updateSelectedPackage: (packageId) => {
        return dispatch(updateSelectedSEOPackage(packageId))
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SeoServicePageInternal));
