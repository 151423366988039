import React from "react";
import ReactDOM from "react-dom";
import Dashboard from "./pages/dashboard";
import FeedBack from "./components/feedBack";
import AdminTaskAnalysis from "./components/admin/admin-task-analysis";
import TaskPage from "./pages/task-page";
import OnDemandService from "./pages/onDemandService-page";
import AutoPosting from "./NewInterfacePages/autoPosting/auto-posting";
// import RequestDemo from "pages/request-demo-page";
import requestJumpstart from "./pages/request-jumpstart-page";
import AdminPage from "./pages/admin-page";
import UserDetails from "./pages/user-details-page.jsx";
import * as types from "./actions/actionTypes";
import GraderPage from "./pages/grader-page";
import GraderHomePage from "./pages/grader-home-page";
import Integration from "./components/grader/integration";
import SEOSignUp from "./components/SeoServices/seo-service-external";
import SEOServicePage from "./pages/seo-service-page";
import SeoDashboard from "./components/SeoServices/seo-dashboard";
import SeoSiteGraderHomePage from "./pages/seo-site-grader-home-page";
import SeoServicePageInternal from "./pages/seo-service-page-internal";
import whsPaymentDetails from "./NewInterfacePages/WebsiteHealthScorecard/whsPaymentDetails";
import theme from "./theme/theme";
import ExpertHelp from "./pages/expert-help-page";
import { Route, Router, Switch } from "react-router-dom";
import "./index.css";
import "font-awesome/css/font-awesome.min.css";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material";
import { connect, Provider } from "react-redux";
import { store } from "./reducers/common";
import { createHashHistory, createBrowserHistory } from "history";
import HeaderBar from "../src/components/header/header-bar";

import { fetchPerfData } from "./actions";
import { dispatchDashboardData } from "./actions";
import FreeTrialDashboard from "./pages/free-trial-dashboard";
import SiteGrader from "./pages/site-grader";
import SiteGraderInternal from "./NewInterfacePages/SEOSiteGraderInternal/siteGrader";
import SiteGraderHome from "./pages/site-grader-home";
import SiteGraderComponents from "./components/siteGrader/site-grader-components";
import ErrorRedirect from "./components/siteGrader/errorRedirect";
import SiteGraderInternalComponents from "./components/internalSiteGrader/site-grader-internal-components";
import ThankYou from "./pages/thank-you-page";
import Header from "./NewInterfacePages/header";
import ErrorPage from "./components/internalSiteGrader/ErrorPage";
import Task from "./NewInterfacePages/task";
import TagManager from "react-gtm-module";
import DashboardNew from "./NewInterfacePages/dashboard";
import AccountSettings from "./NewInterfacePages/accountSettings/account-settings";
import ClientSettings from "./NewInterfacePages/clientSettings/client-settings";
import PaymentHistory from "./NewInterfacePages/paymentHistory/payment-history";
import ProfileSettings from "./NewInterfacePages/profileSettings/profile-settings";
import GoogleAdHome from "./NewInterfacePages/googleAdsGrader/googleAdHome.jsx";
import SiteGraderNewHomePage from "./NewInterfacePages/SEOSiteGraderHomePage/site-grader-home-page";
import HelpCenter from "./NewInterfacePages/help-center";
import ErrorBoundaryRoute from "./components/error/error-boundary-route";
import MobileFooter from "./NewInterfacePages/mobile-footer";
import { CircularProgress, Hidden } from "@mui/material";
import Footer from "./NewInterfacePages/footer/footer";
import TaskData from "./NewInterfacePages/activityReport/activityTaskData";
import AboutUs from "./NewInterfacePages/aboutUs/aboutUs.jsx";
import WhoWeHelp from "./NewInterfacePages/help/helpPage.jsx";
import HolidayPackage from "./NewInterfacePages/holidayPackage/holidayPackage";
import NotificationsPage from "./NewInterfacePages/headerTabs/notifications-page";
import SubscriptionsPage from "./NewInterfacePages/subscription/subscriptionPage";

import Homepage from "./NewInterfacePages/homepage/homepage.jsx";
import Lxrgrowthmodelpage from "./NewInterfacePages/growthmodel/lxrgrowthmodelpage.jsx";
import Priceplaning from "./NewInterfacePages/pricingplanpage/pricingplan.jsx";
import FreeGraderTools from "./NewInterfacePages/freeGraderTools/freeGraderToolsPage.jsx";
import NotFound from "./NewInterfacePages/notFound.jsx";
// import Homepage from './NewInterfacePages/homepage/homepage.jsx';
import HeaderNewInterface from "./NewInterfacePages/homepage/headerNewInterface.jsx";
import Features from "./NewInterfacePages/features/featuresPage.jsx";
import RequestDemo from "./NewInterfacePages/requestDemo/requestDemo.jsx";
import LeadGenerationPage from "./NewInterfacePages/leadGeneration/leadGenerationPage.jsx";
// import Lxrgrowthmodelpage from './NewInterfacePages/growthmodel/lxrgrowthmodelpage.jsx';
// impZrt HeaderBar from "./components/"
import ExternalFooter from "./NewInterfacePages/homepage/footerNewInterface.jsx";
import { Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import route from "./NewInterfacePages/routes.js";
import ChatBot from "./NewInterfacePages/chatbot/chatbot";
import MartechServicesDialog from "./NewInterfacePages/MartechServices/martechServicesDialog";
import ProductTourDialogs from "./NewInterfacePages/productTour/productTourDialogs";
import IndustryTrendNewSection from "./NewInterfacePages/homepage/IndustryTrendNewSection";
import googleAnalyticsIntegration from "./NewInterfacePages/googleAnalyticsDashboard/google-analytics-integration";
import propertiesAndViews from "./NewInterfacePages/googleAnalyticsDashboard/propertiesAndProfiles";
import connectionsPage from "./NewInterfacePages/connections/connectionsPage";
import GoogleAnalyticsDashboardPage from "./NewInterfacePages/googleAnalyticsDashboard/google-analytics-dashboard-page";
import GoogleAnalyticsDashboardPageMenu from "./NewInterfacePages/googleAnalyticsDashboard/google-analy-dashboard-from-menulist";
import LinearProgressBar from "./NewInterfacePages/graphComponents/LinearProgressBar";

import HealthScorecard from "./NewInterfacePages/WebsiteHealthScorecard/healthScorecard";
import WhsComponents from "./NewInterfacePages/websiteHealthScorecardExternal/whsComponents";
import WhsExternalData from "./NewInterfacePages/websiteHealthScorecardExternal/whsData";
import JsLogin from "./NewInterfacePages/revampedJumpstart/jsLogin.jsx";
import JsAccountManagement from "./NewInterfacePages/revampedJumpstart/jumpstartAccounts/jsAccountManagement.jsx";
import JsUserManagement from "./NewInterfacePages/revampedJumpstart/jumpstartUsers/jsUserManagement.jsx";
import JsCampaignFramework from "./NewInterfacePages/revampedJumpstart/jsCampaignFramework.jsx";
import JsWaitPage from "./NewInterfacePages/revampedJumpstart/jsWaitPage.jsx";
import CampaignStructureMain from "./NewInterfacePages/revampedJumpstart/campaignStructure/campaignStructureMain.jsx";
import ReviewerApproval from "./NewInterfacePages/revampedJumpstart/campaignStructure/reviewPage.jsx";
import CreateNewPassword from "./NewInterfacePages/revampedJumpstart/createNewPassword.jsx";
import GenerateAccountStructure from "./NewInterfacePages/revampedJumpstart/campaignFramework/generateAccountStructure.jsx";

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="xl" />;

export const defaultRouteValue = window.lxrOptions.defaultRoute;
export const showHeader = window.lxrOptions && window.lxrOptions.showHeader;
export const isHeaderRenderIn = HeaderCondition(defaultRouteValue);
const history =
  isHeaderRenderIn && !showHeader
    ? createHashHistory()
    : createBrowserHistory();
const unlisten = history.listen((location, action) => {
  // location is an object like window.location
  console.log("***********", action, location.pathname, window.location);
});

export function HeaderCondition(defaultRouteValue) {
  const isHeaderRenderIn =
    "dashboard" ||
    defaultRouteValue == "ACCOUNT_SETTINGS" ||
    defaultRouteValue == "PROFILE_SETTINGS" ||
    defaultRouteValue == "PAYMENT_HISTORY" ||
    defaultRouteValue == "CLIENT_SETTINGS" ||
    defaultRouteValue == "PROPERTIES_AND_VIEWS";
  return isHeaderRenderIn;
}

const styles = (theme) => ({});
function defaultRouteSwitch(defaultRoute) {
  console.log("defaultRouteSwitch method");
  switch (defaultRoute) {
    case types.REQUEST_DEMO_ROUTE:
      return RequestDemo;
    case types.ACCOUNT_SETTINGS:
      return AccountSettings;
    case types.PROFILE_SETTINGS:
      return ProfileSettings;
    case types.CLIENT_SETTINGS:
      return ClientSettings;
    case types.PROPERTIES_AND_VIEWS:
      return propertiesAndViews;
    case types.PAYMENT_HISTORY:
      return PaymentHistory;
    case types.ADMIN_USER_TABLE_ROUTE:
      return AdminPage;
    case types.GOOGLE_ADS_GRADER:
      return GraderPage;
    case types.GOOGLE_ADS_GRADER_HOME_PAGE:
      return GoogleAdHome;
    case types.REQUEST_JUMPSTART_ROUTE:
      return requestJumpstart;
    case types.FREE_TRIAL_DASHBOARD:
      return FreeTrialDashboard;
    case types.SITE_GRADER_HOME:
      return SiteGraderComponents;
    case types.SEO_DASHBOARD:
      return SeoDashboard;
    case types.HOME_PAGE:
      console.log("came here home page");
      return Homepage;
    case types.FEATURES_PAGE:
      return Features;
    case types.PRICING_PAGE:
      return Priceplaning;
    case types.GROWTH_MODEL_PAGE:
      return Lxrgrowthmodelpage;
    case types.FREE_TOOLS_PAGE:
      return FreeGraderTools;
    case types.EXTERNAL_SEO_RESULT_PAGE:
      return SiteGraderInternal;
    case types.REQUEST_DEMO_ROUTE:
      return RequestDemo;
    case types.JUMPSTART_REVAMPED:
      return JsLogin;
    default:
      return DashboardNew;
  }
}

function noop() {}

if (process.env.NODE_ENV !== "development") {
  console.log = noop;
}

class App extends React.Component {
  componentDidMount() {
    const defaultRouteValue = window.lxrOptions.defaultRoute;
    if (defaultRouteValue == types.FEATURES_PAGE) {
      history.push("/features");
    } else if (defaultRouteValue == types.ABOUT_US_PAGE) {
      history.push("/about-us");
    } else if (defaultRouteValue == types.PRICING_PAGE) {
      history.push("/pricing");
    } else if (
      window.location.pathname == "/site-grader.html" &&
      window.location.hash &&
      window.location.hash == "#/PPC/expert-help"
    ) {
      window.location.href = "/expert-help.html?channel=PPC";
    } else if (
      defaultRouteValue == types.GOOGLE_ADS_GRADER_HOME_PAGE &&
      window.location.hash &&
      window.location.hash == ""
    ) {
      history.push("/google-ads-grader");
    } else if (defaultRouteValue == types.SITE_GRADER_HOME) {
      //             history.push("/site-grader-home");
      history.push("/seo-site-grader");
    } else if (defaultRouteValue == types.REQUEST_DEMO_ROUTE) {
      history.push("/request-demo");
    } else if (defaultRouteValue == types.PROPERTIES_AND_VIEWS) {
      history.push("/properties-views");
    }
  }

  render() {
    const { width, isProductTour, isScrollHandleProgress } = this.props;
    const defaultRoute = defaultRouteSwitch(window.lxrOptions.defaultRoute);
    console.log("defaultRoute=", defaultRoute);
    const { pathname } = history.location;
    console.log("history1", pathname);
    const { isLoading, isAdminUserDashboard } =
      store.getState().userInformation;
    let classStyles =
      defaultRoute != "requestDemo" &&
      defaultRoute != types.ADMIN_USER_TABLE_ROUTE
        ? "appIndex1"
        : "appIndex2";
    let headerSpace = isAdminUserDashboard ? "headerSpaceAdmin" : "headerSpace";
    const isDevEnv = process.env.NODE_ENV === "development";
    const defaultRouteValue = window.lxrOptions.defaultRoute;
    const showHeader = window.lxrOptions && window.lxrOptions.showHeader;
    const isHeaderRenderIn =
      "dashboard" ||
      defaultRouteValue == "ACCOUNT_SETTINGS" ||
      defaultRouteValue == "PROFILE_SETTINGS" ||
      defaultRouteValue == "PAYMENT_HISTORY" ||
      defaultRouteValue == "CLIENT_SETTINGS";

    console.log("google history=", history);
    const mainStyle =
      isHeaderRenderIn &&
      !showHeader &&
      defaultRouteValue != types.ADMIN_USER_TABLE_ROUTE
        ? {
            flexGrow: 1,
            height: isScrollHandleProgress == true ? "auto" : "100vh",
            overflow: "auto",
            width: "unset",
            background: "#fff",
          }
        : {
            flexGrow: 1,
            height: "auto",
            overflow: width == "sm" || width == "xs" ? "hidden" : "auto",
          };
    return (
      <div>
        <Router history={history}>
          <div>
            {showHeader &&
              defaultRouteValue != types.ADMIN_USER_TABLE_ROUTE &&
              defaultRouteValue != types.JUMPSTART_REVAMPED && (
                <HeaderNewInterface />
              )}
            <div style={{ display: "flex", width: "100%" }}>
              {isHeaderRenderIn &&
              !showHeader &&
              defaultRouteValue != types.ADMIN_USER_TABLE_ROUTE &&
              defaultRouteValue != types.JUMPSTART_REVAMPED ? (
                <Header />
              ) : null}
              {/*<main style={{flexGrow: isHeaderRenderIn ? 1 : "unset", height: isHeaderRenderIn ?  "100vh" : "unset", overflow: "auto",width:isHeaderRenderIn ?"unset":"100%" }}>*/}
              <main style={mainStyle}>
                {isHeaderRenderIn && !showHeader ? (
                  <div className={headerSpace}></div>
                ) : null}
                <div className={classStyles}>
                  <ErrorBoundaryRoute exact path="/" component={defaultRoute} />
                  <ErrorBoundaryRoute
                    exact
                    path="/adwords-grader-home.html"
                    component={defaultRoute}
                  />
                  {/*<Route exact path="/dashboard" component={Dashboard}/>*/}
                  <Route path="/task/:id?" component={TaskPage} />
                  <Route path="/on-demand-service" component={OnDemandService} />
                  <Route path="/auto-posting" component={AutoPosting} />
                  <Route path="/request-demo" component={RequestDemo} />
                  <Route
                    path="/request-jumpstart"
                    component={requestJumpstart}
                  />
                  <Route
                    path="/add-adcopy-service/:initialPage"
                    component={OnDemandService}
                  />
                  <Route path="/admin" component={AdminPage} />
                  <Route path="/user-details/:id?" component={UserDetails} />
                  <Route
                    path="/task-analysis/:accountId/:ppcId?"
                    component={AdminTaskAnalysis}
                  />
                  <Route
                    path="/admin-task/:id/:accountId/:ppcId?"
                    component={TaskPage}
                  />
                  <Route path="/grader-result/" component={GraderPage} />
                  <Route path="/grader-result.html/" component={GraderPage} />
                  <Route
                    path="/grader-landing-page/"
                    component={GraderHomePage}
                  />
                  <Route path="/google-ads-grader" component={GoogleAdHome} />
                  <Route path="/adwords-grader.html" component={GoogleAdHome} />
                  <Route path="/integration/" component={Integration} />
                  <Route path="/adwords-grader.html" component={GoogleAdHome} />
                  <Route
                    path="/free-trail-dashboard/"
                    component={FreeTrialDashboard}
                  />
                  <Route
                    path={"/site-grader/:id/:url/:type"}
                    component={SiteGrader}
                  />
                  <Route
                    path={"/site-grader-home1"}
                    component={SiteGraderHome}
                  />
                  <Route
                    path={"/site-grader-home"}
                    component={SiteGraderComponents}
                  />
                  <Route
                    path={"/seo-site-grader"}
                    component={SiteGraderComponents}
                  />
                  <Route
                    path={"/website-health-score-card"}
                    component={WhsComponents}
                  />
                  <Route
                    path={"/website-health-scorecard-data"}
                    component={WhsExternalData}
                  />
                  <Route
                    path={"/jumpstart-dashboard"}
                    component={JsAccountManagement}
                  />
                  <Route
                    path={"/jumpstart-dashboard/:id"}
                    component={JsAccountManagement}
                  />
                  <Route
                    path={"/jumpstart-user-management"}
                    component={JsUserManagement}
                  />
                  <Route
                    path={"/campaign-framework/:id"}
                    component={JsCampaignFramework}
                  />
                  {/* <Route
                    path={"/campaign-framework/:id/setup-completed"}
                    component={JsWaitPage}
                  /> */}
                    <Route
                    path={"/campaign-framework-completed/:id"}
                    component={JsWaitPage}
                  />
                  <Route
                    path={"/campaign-structure/:id"}
                    component={CampaignStructureMain}
                  />
                  <Route
                    path={"/campaign-structure-completed/:id"}
                    component={ReviewerApproval}
                  />
                   <Route
                    path={"/generate-campaign-structure/:id"}
                    component={GenerateAccountStructure}
                  />
                  <Route
                    path={"/jumpstart-reset-password/finish/:key"}
                    component={CreateNewPassword}
                  />
                  <Route path={"/error-redirect"} component={ErrorRedirect} />
                  <Route path={"/seo-user"} component={SEOSignUp} />
                  <Route
                    path={"/seo-services-packages/:domainUrl/:emailId"}
                    exact
                    component={SEOServicePage}
                  />
                  <Route
                    path={"/industry-insights/:industry?"}
                    exact
                    component={IndustryTrendNewSection}
                  />
                  <Route
                    path={"/google-analytics-dashboard"}
                    exact
                    component={GoogleAnalyticsDashboardPage}
                  />
                  <Route
                    path={"/google-analytics-dashboard-menu"}
                    exact
                    component={GoogleAnalyticsDashboardPageMenu}
                  />
                  <Route
                    path={"/seo-services-packages/:domainUrl/"}
                    exact
                    component={SEOServicePage}
                  />
                  <Route
                    path={"/seo-payment/:packageId"}
                    component={SEOSignUp}
                  />
                  <Route path={"/whs-payment"} component={whsPaymentDetails} />
                  <Route
                    path={"/seo-sign-up/:domainUrl/:packageId"}
                    component={SEOSignUp}
                  />
                  <Route path={"/seo-services-test/"} component={SEOSignUp} />
                  <Route path={"/seo-dashboard"} component={SeoDashboard} />
                  <Route
                    path={"/:channel/expert-help"}
                    component={ExpertHelp}
                  />
                  <Route path={"/expert-help.html"} component={ExpertHelp} />
                  {/*<Route path={"/seo-site-grader-home-page"} component={SeoSiteGraderHomePage}/>*/}
                  <Route
                    path={"/seo-site-grader-home-page"}
                    component={SiteGraderNewHomePage}
                  />
                  {/*<Route path={"/seo-site-grader-report1"} component={SEOSiteGraderInternalPagee}/>*/}
                  {/* //                                      <Route path={"/seo-site-grader-report/:type"} component={SiteGrader}/> */}
                  {/*This is for new external url*/}
                  <Route
                    path={"/seo-site-grader-report/:id/:url/:type"}
                    component={SiteGraderInternal}
                  />
                  <Route
                    path={"/seo-site-grader-report.html"}
                    component={SiteGraderInternal}
                  />
                  <Route
                    path={"/seo-packages-internal"}
                    exact
                    component={SeoServicePageInternal}
                  />
                  <Route
                    path={"/thank-you/:activeUser/:packageName"}
                    exact
                    component={ThankYou}
                  />
                  <Route
                    path={"/site-grader-refresh/:domainUrl"}
                    exact
                    component={SiteGraderInternalComponents}
                  />
                  <Route path={"/error-page"} exact component={ErrorPage} />
                  {/*<Route path={"/ppc/expert-help"} component={ExpertHelp}/>*/}
                  {/*<Route path={"/dashboard-new"} exact component={Header}/>*/}
                  {/*<Route path={"/dashboard-new"} exact component={DashboardNew}/>*/}
                  <Route path={"/task-new/:id"} exact component={Task} />
                  {/*<Route path={"/dashboard/:engineType"} exact component={DashboardNew}/>*/}
                  {/*<Route path={"/dashboard-google"} exact component={DashboardNew}/>*/}
                  {/*<Route path={"/dashboard-bing"} exact component={DashboardNew}/>*/}
                  {/*   <ErrorBoundaryRoute path={"/account-settings"} exact
                                                            component={AccountSettings}/>*/}
                  <Route path={"/help-center"} exact component={HelpCenter} />
                  <Route
                    path={"/on-demand-center"}
                    exact
                    component={DashboardNew}
                  />
                  <Route path={"/home-page"} exact component={Homepage} />
                  {/*<Route path={"/home"} exact component={Homepage}/>*/}
                  <Route path={"/logout.html"} exact component={Homepage} />
                  <Route
                    path={"/growth-model"}
                    exact
                    component={Lxrgrowthmodelpage}
                  />
                  <Route path={"/tools"} exact component={FreeGraderTools} />

                  <Route path={"/pricing"} exact component={Priceplaning} />
                  <Route path={"/about-us"} exact component={AboutUs} />
                  <Route path={"/who-we-help"} exact component={WhoWeHelp} />
                  <Route path={"/features"} exact component={Features} />
                  <Route path={"/jumpstart-login"} component={JsLogin} />
                  <Route
                    path={"/integrations"}
                    component={LeadGenerationPage}
                  />
                  <Route
                    path={"/google-analytics-integration"}
                    component={googleAnalyticsIntegration}
                  />
                  <Route
                    path={"/properties-views"}
                    component={propertiesAndViews}
                  />
                  <Route
                    path={"/connections-page"}
                    component={connectionsPage}
                  />
                  <ErrorBoundaryRoute
                    path="/website-health-scorecard"
                    exact
                    component={HealthScorecard}
                  />
                  <ErrorBoundaryRoute
                    path="/dashboard/"
                    exact
                    component={DashboardNew}
                  />
                  <ErrorBoundaryRoute
                    path="/dashboard/:type"
                    exact
                    component={DashboardNew}
                  />
                  <ErrorBoundaryRoute
                    path="/reports/"
                    exact
                    component={DashboardNew}
                  />
                  <ErrorBoundaryRoute
                    path="/tasks/"
                    exact
                    component={DashboardNew}
                  />
                  <ErrorBoundaryRoute
                    path="/activity-report/"
                    component={DashboardNew}
                  />

                  {/*<ErrorBoundaryRoute path="/task/:id" component={Tasks}/>*/}
                  <ErrorBoundaryRoute
                    path={"/account-settings"}
                    exact
                    component={AccountSettings}
                  />
                  <ErrorBoundaryRoute
                    path={"/client-settings"}
                    exact
                    component={ClientSettings}
                  />
                  {/* <ErrorBoundaryRoute
                    path={"/properties-views"}
                    exact
                    component={propertiesAndViews}
                  /> */}
                  <ErrorBoundaryRoute
                    path={"/payment-history"}
                    exact
                    component={PaymentHistory}
                  />
                  <ErrorBoundaryRoute
                    path={"/profile-settings"}
                    exact
                    component={ProfileSettings}
                  />
                  <ErrorBoundaryRoute
                    path={"/help-center"}
                    exact
                    component={HelpCenter}
                  />
                  <ErrorBoundaryRoute
                    path={"/on-demand-center"}
                    exact
                    component={OnDemandService}
                  />
                  <ErrorBoundaryRoute
                    path={"/site-grader-report/:type"}
                    exact
                    component={SiteGraderInternal}
                  />
                  <ErrorBoundaryRoute
                    path={"/task1/:id"}
                    exact
                    component={Task}
                  />
                  <ErrorBoundaryRoute
                    path={
                      "/task-data/:taskId/:taskTypeId/:analysisTypeId/:selectedDateRange"
                    }
                    exact
                    component={TaskData}
                  />
                  <ErrorBoundaryRoute
                    path={"/holiday-package"}
                    exact
                    component={HolidayPackage}
                  />
                  <ErrorBoundaryRoute
                    path={"/notifications/:ppcId"}
                    exact
                    component={NotificationsPage}
                  />
                  <ErrorBoundaryRoute
                    path={"/notifications"}
                    exact
                    component={NotificationsPage}
                  />
                  <ErrorBoundaryRoute
                    path="/subscriptions"
                    exact
                    component={SubscriptionsPage}
                  />
                  <ProductTourDialogs />
                  {isHeaderRenderIn && !showHeader ? <ChatBot /> : null}
                  <MartechServicesDialog />
                </div>
                {isHeaderRenderIn && !showHeader && <MobileFooter />}
                {isHeaderRenderIn && !showHeader ? (
                  <Hidden lgDown>
                    <Footer />
                  </Hidden>
                ) : (
                  <ExternalFooter />
                )}
              </main>
            </div>
          </div>
        </Router>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  isScrollHandleProgress: state.scrollHandler.isProgress,
});
const _App = connect(mapStateToProps, null)(withWidth()(withStyles({})(App)));
//  const _App= withStyles(withWidth()({},App))

ReactDOM.render(
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <_App />
      </ThemeProvider>
    </StyledEngineProvider>
  </Provider>,
  document.getElementById("root")
); /*let fontType = [
  'Lato',
  'Montserrat',
  'Roboto',
  'sans-serif',
];*/
/*if (window.lxrOptions && window.lxrOptions.defaultRoute && window.lxrOptions.defaultRoute == 'requestDemo' && window.lxrOptions.defaultRoute == types.ADMIN_USER_TABLE_ROUTE ||
  window.lxrOptions && window.lxrOptions.defaultRoute && window.lxrOptions.defaultRoute == 'GOOGLE_ADS_GRADER_HOME_PAGE'
) {
  fontType = [
      'Montserrat',
      'Roboto',
      'sans-serif',
  ]
}*/

if (window.dashboardData) {
  dispatchDashboardData(window.dashboardData, store.dispatch);
} else if (
  (window.defaultRoute && window.defaultRoute != "requestDemo") ||
  (window.lxrOptions && window.lxrOptions.useDummyData == true)
) {
  // store.dispatch(fetchPerfData());
}
