import { Grid, Typography } from "@mui/material";
import React from "react";
import classNames from "classnames";
import icons from "../icons";
import { Card, CardActions, CardContent } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import { SubSectionCardHeader } from "../siteGrader/templates";
import Divider from "@mui/material/Divider";
import { connect } from "react-redux";
import { packagesList } from "../internalSiteGrader/templates";

const styles = (theme) => ({
  midCard: {
    boxShadow: "unset",
    padding: theme.spacing(3),
    borderRadius: 0,
  },
  paper: {
    boxShadow: "unset",
  },
  cardTitle: {
    fontFamily: "Montserrat",
    color: theme.palette.summaryText,
    fontSize: 17,
    fontWeight: "bold",
  },
  padding2X: { padding: theme.spacing(1) },
  amountValue: {
    fontFamily: "Lato",
    fontSize: "1.2rem",
    color: theme.palette.summaryText,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  text: {
    fontFamily: "Lato",
    color: theme.palette.summaryText,
    fontSize: 16,
  },
  text1: {
    fontFamily: "Lato",
    color: theme.palette.summaryText,
    fontSize: 12,
  },
  span: {
    paddingRight: theme.spacing(2),
  },
  dividerP: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  subPara: {
    fontFamily: "Montserrat",
    fontSize: 17,
    fontWeight: "500",
  },
});

function OrderSummary(props) {
  const { classes, packageId } = props;
  console.log("packageId=", packageId);
  const packageDetails = packagesList.filter(
    (packageDetail) => packageDetail.packagesDataId == packageId
  )[0];

  return (
    <div>
      <Card className={classes.midCard}>
        <Typography
          align={"left"}
          className={classes.cardTitle}
          color={"textSecondary"}
        >
          <b>Order Summary</b>
        </Typography>
        <CardContent>
          <Grid container spacing={2} justifyContent={"space-between"}>
            <Grid item md={4}>
              <Typography align={"left"} className={classes.text1} gutterBottom>
                Plan
              </Typography>
              <Typography align={"left"} className={classes.text} gutterBottom>
                {packageDetails.packageName}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography
                align={"right"}
                className={classes.text1}
                gutterBottom
              >
                Price
              </Typography>
              <Typography align={"right"} className={classes.text} gutterBottom>
                ${packageDetails.price}
              </Typography>
            </Grid>
          </Grid>
          <Grid item md={12}>
            <Divider className={classes.dividerP} />
          </Grid>
          <Grid container spacing={2} justifyContent={"space-between"}>
            <Grid item md={12}>
              <Typography align={"right"} className={classes.text} gutterBottom>
                <span
                  className={classNames(classes.paddingRight, classes.text)}
                >
                  Subtotal
                </span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span className={classes.text}>${packageDetails.price}</span>
              </Typography>
            </Grid>
          </Grid>
          <Grid item md={12}>
            <br />
            <br />
            <br />
            <br />
            <Divider className={classes.dividerP} />
          </Grid>

          <Grid container spacing={2} justifyContent={"space-between"}>
            <Grid item md={4}>
              <Typography align={"left"} className={classes.text} gutterBottom>
                Grand Total
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Typography
                align={"right"}
                className={classes.amountValue}
                gutterBottom
              >
                <b>${packageDetails.price}</b>
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <br />
      {/* <Typography className={classes.subPara} color={"textSecondary"}>
                Lorem ipsum dolor sit amet, consectetur
                adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </Typography>*/}
    </div>
  );
}

export default withStyles(styles)(OrderSummary);
