import React, {Fragment} from 'react';
import withStyles from '@mui/styles/withStyles';
import {Typography} from "@mui/material";
import NumericLabel from "react-pretty-numbers";
import icons from "../icons";
import {TableCellNoPadding, TableRowAutoHeight} from '../table';
import CurrencySymbol from "../../components/currencySymbol.jsx";

export function typeIcon(type) {
    switch (type) {
        case "Search":
            return icons.Search;
        case "Shopping":
            return icons.Shopping;
        case "ALL":
            return icons.All;
        case "Other":
            return icons.All;
        default:
            return null;
    }
}


function CampaignSnapshotTableRow({stat, classes}) {
    const numOptions = {
        commafy: true,
        shortFormatMinValue: 9999,
        shortFormatPrecision: 0,
        precision: 0,
        shortFormat: true,
        justification: 'R',
        locales: "en-US",
        // title: true
    };
    const currencyOptions = {currency: true, wholenumber: 'ceil', currencyIndicator: 'USD'};

    const TableRow = TableRowAutoHeight;
    const TableCell = TableCellNoPadding;
    return (
            <TableRow key={stat.type} className={stat.type === 'ALL' ? classes.highlight : ""}>
            <TableCell>
                <Typography variant={"caption"} style={{fontSize: 8}}>
                    {typeIcon(stat.type)}
                    {stat.type}</Typography>
            </TableCell>
            <TableCell numeric>
                <span title={stat.impressions}><NumericLabel params={numOptions}>{stat.impressions}</NumericLabel></span>
            </TableCell>
            <TableCell numeric>
                <span title={stat.clicks}><NumericLabel params={numOptions}>{stat.clicks}</NumericLabel></span>
            </TableCell>
            <TableCell numeric>
                {!isNaN(stat.ctr) ?
                    <NumericLabel params={{...numOptions, percentage: true}}>{stat.ctr}</NumericLabel> : "-"}
            </TableCell>
            <TableCell numeric>
                <span title={stat.orders}><NumericLabel params={numOptions}>{stat.orders}</NumericLabel></span>
            </TableCell>
            <TableCell numeric>
                <span title={stat.spend}><CurrencySymbol formatOptions={{...numOptions, ...currencyOptions}}>{stat.spend}</CurrencySymbol></span>
            </TableCell>
            <TableCell numeric>
                <span title={stat.revenue}><CurrencySymbol formatOptions={{...numOptions, ...currencyOptions}}>{stat.revenue}</CurrencySymbol></span>
            </TableCell>
            <TableCell numeric>
                {!isNaN(stat.kpiValue) ? stat.kpiValue.toFixed(2) : "-"}
            </TableCell>
        </TableRow>
    );

}

const campaignSnapshotRowStyles = (theme) => ({
    highlight: {
        background: "rgba(30, 51, 78, 0.1)",
        "& > td": {
            fontWeight: "bold",
        }
    }
});

export default withStyles(campaignSnapshotRowStyles)(CampaignSnapshotTableRow);