import imageBg from "../../img/home_bg.svg";

const googleAdsGraderStyles = (theme) => ({
  card: {
    boxShadow: "unset",
  },
  gridBg: {
    background: `url(${imageBg})`,
    [theme.breakpoints.up("md")]: {
      backgroundSize: "contain",
      backgroundPosition: "top center",
      backgroundColor: theme.palette.common.darkGreybg,
    },
    [theme.breakpoints.up("lg")]: {
      backgroundRepeat: "no-repeat",
    },
    [theme.breakpoints.down("xl")]: {
      // background: `unset`,
      // backgroundColor: theme.palette.common.darkGreybg,
    },
  },
  cardContent: {
    padding: 0,
    paddingBottom: 0,
  },
  sectionBg: {
    backgroundColor: theme.palette.common.darkGreybg,
    height: 595,
    paddingTop: theme.spacing(4),

    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down("lg")]: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
  },
  section2: {
    height: 208,
    [theme.breakpoints.down("lg")]: {
      height: 308,
    },
    backgroundColor: theme.palette.common.white,
  },
  insightsPaperBg: {
    // padding: 10,
    backgroundColor: theme.palette.common.darkGreybg,
  },
  onPageScore_Resizable1: {
    width: "50%",
    // height: 220,
    [theme.breakpoints.down("lg")]: {
      width: "65%",
      paddingTop: theme.spacing(3),
    },
  },
  keywordScore_Resizable1: {
    width: "45%",
    // height: 220,
    [theme.breakpoints.down("lg")]: {
      width: "65%",
    },
  },
  mobileCompatibility_Resizable1: {
    width: "50%",
    // height: 200,
    [theme.breakpoints.down("lg")]: {
      width: "65%",
      paddingTop: theme.spacing(3),
    },
  },
  pageLoadSpeedScore_Resizable1: {
    width: "50%",
    // height: 230,
    [theme.breakpoints.down("lg")]: {
      width: "70%",
    },
  },
  linkValidityScore_Resizable1: {
    width: "50%",
    // height: 200,
    [theme.breakpoints.down("lg")]: {
      width: "60%",
      paddingTop: theme.spacing(3),
    },
  },
  technicalVisibility_Resizable1: {
    width: "65%",
    // height: 200,
    [theme.breakpoints.down("lg")]: {
      width: "75%",
    },
  },
  subTitle: {
    width: "80%",
    [theme.breakpoints.down("lg")]: {
      width: "100%",
    },
  },
  heading: {
    color: "textPrimary",
    textAlign: "center",
    fontWeight: "700",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.25rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2.75rem",
    },
  },
});

export default googleAdsGraderStyles;
