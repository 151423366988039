import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import withStyles from "@mui/styles/withStyles";
import { CircularProgress, Grow, Switch } from "@mui/material";
import Grid from "@mui/material/Grid";
import { createHashHistory } from "history";
import { connect } from "react-redux";
import {
  executeTasks,
  fetchPerfData,
  taskSeenDate,
  toggleSelection,
  updateTaskDisplayStatus,
} from "../actions";
import icons from "../components/icons";
import { TypeTitle } from "../components/task/task-data";
import * as _ from "lodash";
import { getRenderer } from "./task-data";
import styles from "../assets/jss/task/taskStyles";
import TaskDataAppBar from "./TaskDataAppBar";
import { idToTaskDataList, sumUpImpactOfIds } from "../reducers/common";
import CustomDialog from "../components/admin/dialog-dynamic";
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Checkbox, FormControlLabel } from "@mui/material";
import { NewOrangeContainedBtn } from "../components/buttons";
import { PopUpCommonBtn } from "../components/admin/templates";
import { ShoppingHierarchy } from "../components/task/shoppingHierarchy";
import KeywordHierarchy from "../components/task/keywordHierarchy";
import MySnackbarWrapper from "./snackbars";
import ReactDOM from "react-dom";
import * as types from "../actions/actionTypes";
import CountUp from "react-countup";
// import ellipse from "../assets/img/ellipse.png";
// import savingDec from "../assets/img/savings-icon.png";
import getSymbolFromCurrency from "currency-symbol-map";

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="md" />;

const history = createHashHistory();

let id = 0;

function createData(adGroup, keywords, bids) {
  id += 1;
  return { id, adGroup, keywords, bids };
}

const DialogHead = withStyles((theme) => ({
  root: {
    fontWeight: "900",
    color: theme.palette.text.primary,
    fontFamily: "Lato",
  },
}))(DialogTitle);
export const PopUpOrangeBtn = withStyles((theme) => ({
  root: {
    fontWeight: "bold",
    color: theme.palette.orangeColor,
  },
}))(Button);
const DialogText = withStyles((theme) => ({
  root: {
    fontWeight: "lighter",
    fontSize: 16,
    color: theme.palette.text.primary,
    fontFamily: "Lato",
  },
}))(DialogContentText);
const TextCenter = {
  justifyContent: "Center",
};
const rows = [
  createData("sale shoes", "Cheap Shoes", 6.0),
  createData("Cheap shoes", "Shoes", 9.0),
  createData("Men Shoes", "Sale", 16.0),
  createData("Shoes Cheap Shoes", "boots", 3.7),
];

export function IconAndName({ name, caption }) {
  return (
    <Grid container spacing={2}>
      <Grid item>
        <Typography
          variant={"h4"}
          color="textPrimary"
          style={{ maxWidth: "auto", lineHeight: "2rem" }}
        >
          <b>{name}</b>
        </Typography>
        <Typography color="textPrimary" variant={"body1"}>
          {caption}
        </Typography>
      </Grid>
    </Grid>
  );
}

const costForecast = "costForecast";
const revenueForecast = "revenueForecast";

function CustomPaper(props) {
  return (
    <Card className={props.classes.otherCards}>
      <CardContent>
        <Paper className={props.classes.tdPaper}>{props.children}</Paper>
      </CardContent>
    </Card>
  );
}

CustomPaper.propTypes = { classes: PropTypes.any };

async function calculateSaving() {
  // alert(1);
  if (
    this.props.selectedIds != undefined &&
    this.props.selectedIds.length > 0
  ) {
    const selectedImpact = sumUpImpactOfIds(this.props.selectedIds);
    if (
      selectedImpact.costSavingForecast &&
      selectedImpact.costSavingForecast > 0 &&
      this.state.savingAmount != Math.ceil(selectedImpact.costSavingForecast)
    ) {
      console.log("costSavingForecast");
      await this.setState({
        savingAmount: Math.ceil(selectedImpact.costSavingForecast),
        textToggle: costForecast,
        showForecast: true,
      });
    }
    if (
      selectedImpact.revenueForecast > 0 &&
      this.props.KPIType !== "CPO" &&
      this.state.savingAmount != Math.ceil(selectedImpact.revenueForecast)
    ) {
      console.log("revenueForecast");
      await this.setState((prev) => ({
        savingAmount: Math.ceil(selectedImpact.revenueForecast),
        textToggle: revenueForecast,
        showForecast: true,
      }));
    }
  } else if (
    this.props.selectedIds != undefined &&
    this.props.selectedIds.length == 0
  ) {
    await this.setState({ savingAmount: 0, showForecast: false });
  }
}
//this is the start of the class component
class Task extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectionAlFlag: true,
      postAlert: false,
      statusAlert: false,
      adcopyAlert: false,
      executedLength: 0,
      variantType: "info",
      message: "",
      taskTypeId: this.props.match.params.id
        ? parseInt(this.props.match.params.id)
        : null,
      isTaskExecutionLoading: false,
      isSaving: false,
      checked: false,
      savingAmount: 0,
      showForecast: false,
      autoPublish: false,
      selectedAdCopies: [], // Array to store selected ad copies
      selectAllCheck: false,
      saveStatus: "Auto-saved",
      isDisabled: false,
      allAdCopies: [],
    };
  }

  setSaveStatus = (newStatus) => {
    this.setState({ saveStatus: newStatus });
  };

  // setAllAdCopies = (adCopy) => {
  //   const { allAdCopies } = this.state;

  //   // Check if the adCopy already exists in the allAdCopies array
  //   const existingIndex = allAdCopies.findIndex((copy) => copy.adgroupId === adCopy.adgroupId);
  //   const hasNonEmptyAdgroupId = adCopy.adgroupId !== '';

  //   if (existingIndex !== -1 && hasNonEmptyAdgroupId) {
  //     // Replace the existing adCopy with the new one
  //     const updatedAdCopies = [...allAdCopies];
  //     updatedAdCopies[existingIndex] = adCopy;

  //     this.setState({
  //       allAdCopies: updatedAdCopies,
  //     });
  //   } else if (existingIndex == -1 && hasNonEmptyAdgroupId) {
  //     // Add the adCopy to allAdCopies
  //     this.setState((prevState) => ({
  //       allAdCopies: [...prevState.allAdCopies, adCopy],
  //     }));
  //   }
  // };

  // handleSelectAll = (event) => {
  //   const { checked } = event.target;

  //   if (checked) {
  //     // Add all ad copies with status "Ready to publish" to selectedAdCopies
  //     const { allAdCopies } = this.state;
  //     const updatedSelectedAdCopies = allAdCopies.filter(
  //       (adCopy) => adCopy.adCopyStatus === "Ready to publish"
  //     );

  //     this.setState({
  //       selectedAdCopies: updatedSelectedAdCopies,
  //       selectAllCheck: checked,
  //     });
  //   } else {
  //     this.setState({
  //       selectedAdCopies: [],
  //       selectAllCheck: checked,
  //     });
  //   }
  // };

  handleAdCopySelection = (isSelected, adCopy) => {
    console.log("isSelected here", isSelected);
    if (adCopy && Object.keys(adCopy).length > 0) {
      if (isSelected) {
        this.setState((prevState) => {
          if (
            !prevState.selectedAdCopies.some(
              (copy) => copy.adgroupId === adCopy.adgroupId
            )
          ) {
            return {
              selectedAdCopies: [...prevState.selectedAdCopies, adCopy],
            };
          }
        });
      } else {
        this.setState((prevState) => ({
          selectedAdCopies: prevState.selectedAdCopies.filter(
            (copy) => copy.adgroupId !== adCopy.adgroupId
          ),
        }));
        // if(this.state.selectedAdCopies.length <= 1)
        // {
        //   this.state.selectAllCheck = false;
        // }
      }
    }
  };

  //todo remove the method after counterup works fine
  /* handleChange = async () => {
         await this.setState(state => ({checked: !state.checked}));
         this.setState({forecastAmount: 0})
     };*/
     componentDidMount() {
      window.scrollTo(0, 0);
    }
  async componentDidMount() {
    window.scrollTo(0, 0);
    console.log("came here");
    const { history } = this.props;
    ReactDOM.findDOMNode(this).scrollIntoView();
    if (history.length == 0) {
      this.props.fetchPerfData();
    }
    this.taskSeenByAdminAndUser();
    this.props.updateViewValue(1);
    this.props.taskDataTack(this.props.match.params.id);
    await calculateSaving.call(this);
    if (window.hopscotch && window.hopscotch != null) {
      const currentStep = window.hopscotch.getCurrStepNum();
      console.log("currentStep=", currentStep);
      if (currentStep) {
        const currentTour = window.hopscotch.getCurrTour();
        console.log("currentTour=", currentTour);
        window.hopscotch.startTour(currentTour, currentStep);
      }
    }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    window.scrollTo(0, 0);
    console.log("prevProps.selectedIds", prevProps.selectedIds);
    console.log("this.props.selectedIds", this.props.selectedIds);
    if (
      prevProps.selectedIds != undefined &&
      JSON.stringify(prevProps.selectedIds) !=
        JSON.stringify(this.props.selectedIds)
    ) {
      await calculateSaving.call(this);
    }
  }

  closeForecastPopUP = async () => {
    await this.setState({ textToggle: null });
    console.log("Ended hello! 👏");
  };

  taskSeenByAdminAndUser = () => {
    const { data } = this.props;
    const summary = data && data.summary ? data.summary : null;
    const taskId = summary && summary.taskId ? summary.taskId : null;
    if (this.props.isAdmin) {
      if (summary.displayStatus == 0 && taskId != null) {
        this.props.updateTaskDisplayStatus(taskId, 1);
      }
    } else {
      // markTaskSeen(summary.taskId, this.props.taskSeenDate);
      if (taskId != null) {
        this.props.taskSeenDate(taskId);
      }
    }
  };

  onSelectClearAll = async (event) => {
    await this.setState((state) => ({
      selectionAlFlag: !state.selectionAlFlag,
    }));
    const value = this.state.selectionAlFlag;
    console.log("value=", value);
    // if (!value) return;
    // alert();
    // const selected = (value == "SelectAll");
    const selected = value == true;
    await this.props.toggleSelection(this.props.selectableIds, selected);
  };
  renderCampaignHeader = (campaignId) => {
    const { campaignIdMap } = this.props;
    let campaign = campaignIdMap[campaignId];
    console.log("campaign=", campaign);
    // let CampaignTypeIcon = icons[campaign.campaignType] || Typography;
    return (
      <IconAndName
        icon={icons.Campaign}
        name={
          <Fragment>
            <span>
              <b>{campaign.campaignName}</b>
            </span>
            <br />
            {/*<CampaignTypeIcon style={{height:20, width:20, verticalAlign:"middle"}}/>*/}
            <TypeTitle type="Campaign" value={campaign.campaignType} />
            {/*<Typography variant={"caption"} className={"inline"}>({campaign.campaignType})</Typography>*/}
          </Fragment>
        }
      />
    );
  };
  renderTaskTypeHeader = (taskTypeId) => {
    const { taskTypeIdMap } = this.props;
    const taskType = taskTypeIdMap[taskTypeId];
    return (
      <IconAndName
        icon={icons.Task}
        name={taskType.taskName}
        bold
        caption={taskType.taskDescription}
      />
    );
  };
  renderAdgroupHeader = (name) => (
    <IconAndName
      classes={this.props.classes}
      icon={icons.AdGroup}
      name={name}
    />
  );

  headerRenderers = {
    TaskType: [this.renderCampaignHeader, this.renderAdgroupHeader],
    Campaign: [this.renderTaskTypeHeader, this.renderAdgroupHeader],
  };

  handleRsaPublish = () => {
    this.setState({ adcopyAlert: true });
  };

  handlePublish = () => {
    this.setState({ postAlert: true });
  };
  handleAutoPublish = () => {
    // this.setState({ postAlert: true });
    this.setState({ autoPublish: true });
  };
  handleBack = async () => {
    await this.props.updateViewValue(1);
    history.push("/tasks");
  };
  handleClose = () => {
    this.setState({ postAlert: false });
  };
  handleRsaClose = () => {
    this.setState({ adcopyAlert: false });
  };
  handleAutoPublishClose = () => {
    this.setState({ autoPublish: false });
  };
  handleRsaPostAgree = async () => {
    console.log("selectedAdCopiessssss", this.state.selectedAdCopies);
    const { executeTasks } = this.props;
    const { selectedAdCopies } = this.state;
    const selectedIds = selectedAdCopies.map((adCopy) => adCopy.taskDataId);
    this.setState({ adcopyAlert: false, isTaskExecutionLoading: true });
    let response = await executeTasks(selectedIds);
    window.location.reload();
  };

  handlePostAgree = async () => {
    const { data, taskDataById, executeTasks } = this.props;
    const selectedIds =
      data &&
      data.items &&
      data.dataIdList.filter((id) => taskDataById[id].selected);
    this.setState({ postAlert: false, isTaskExecutionLoading: true });
    let response = await executeTasks(selectedIds);
    if (response) {
      const executedLength = _.values(response).filter(
        (status) => status == 1
      ).length;
      if (executedLength > 0) {
        this.setState({
          executedLength: executedLength,
          statusAlert: true,
          variantType: "success",
          message: executedLength + " item(s) successfully published",
          isTaskExecutionLoading: false,
        });
      } else {
        this.setState({
          statusAlert: true,
          variantType: "error",
          message: "Error in publishing the item(s)",
          executedLength: 0,
          isTaskExecutionLoading: false,
        });
      }
    } else {
      this.setState({
        statusAlert: true,
        variantType: "error",
        message: "Error in publishing the item(s)",
        executedLength: 0,
        isTaskExecutionLoading: false,
      });
    }
  };
  handleCloseAlert = () => {
    this.setState({ statusAlert: false, executedLength: 0, isSaving: false });
  };

  componentWillUnmount() {
    this.setState({ showForecast: false });
  }

  render() {
    const {
      classes,
      data,
      taskDataById,
      toggleSelection,
      isLoading,
      open,
      kpiType,
      currency,
      width,
      data1,
    } = this.props;
    const {
      selectionAlFlag,
      postAlert,
      adcopyAlert,
      statusAlert,
      message,
      variantType,
      isTaskExecutionLoading,
      showForecast,
      checked,
      savingAmount,
      textToggle,
      autoPublish,
      selectedAdCopies,
      saveStatus,
      selectAllCheck,
      statusMessage,
      isDisabled,
      allAdCopies,
    } = this.state;
    console.log("statusMessage =====>", isDisabled);
    const isActivityReport = false;
    const title = data && data.summary ? data.summary.title : null;
    const timeEstimate =
      data && data.summary ? data.summary.timeEstimate : null;
    const summary = data && data.summary ? data.summary : null;
    const dataIdList = data && data.dataIdList ? data.dataIdList : null;
    const description = data && data.summary ? data.summary.description : null;
    const taskId = data && data.summary ? data.summary.taskId : 0;
    const groupId = data && data.summary ? data.summary.groupId : 0;
    const actionStatus = data && data.summary ? data.summary.actionStatus : 0;
    const actionDate = data && data.summary ? data.summary.actionDate : 0;
    console.log("light bulb content..", data);
    console.log("summary==>", summary);
    Object.values(taskDataById).includes(title);

    var dateStringMili = new Date(actionDate);
    console.log("SelectedAdCopies in task.jsx ======> ", selectedAdCopies);
    const adcopies = selectedAdCopies;
    // var dateString=dateStringMili.toString("dd mm yyyy"); // "Dec 20"
    // var dateStringFormat = dateStringMili.toLocaleDateString(); backup

    // var dateStringFormat = dateStringMili.toLocaleDateString("es-CL");
    var dateStringFormat = dateStringMili.toLocaleDateString();
    var dateStringFormatTime = dateStringMili.toLocaleTimeString();
    const hasReadyToPublishAdCopy = allAdCopies.some(
      (copy) => copy.adCopyStatus === "Ready to publish"
    );

    console.log("Props in task.jsx====>", selectAllCheck);
    const items = data && data.items ? data.items : null;
    console.log("items35= Main task page=", items);
    const selectedIds =
      data && data.items
        ? data.dataIdList.filter((id) => taskDataById[id].selected)
        : [];
    const impact = data && data.summary ? data.summary.impact : null;
    let headerRenderer =
      _.first(this.headerRenderers["TaskType"]) ||
      ((name) => <IconAndName name={name} />);
    // console.log("data----=", data);
    // console.log("summary=", (data && data.summary) ? data.summary : null);
    // console.log("sumUpImpactOfIds(selectedIds)=", sumUpImpactOfIds(selectedIds));
    // console.log("own selectedIds==", selectedIds)
    // console.log(" form props selectedIds==", this.props.selectedIds)
    // console.log("savingAmount", savingAmount);
    // console.log("textToggle", textToggle);
    // console.log("showForecast", showForecast);
    // console.log("getSymbolFromCurrency(currency)==", getSymbolFromCurrency(currency));
    /*        const polygon = (
                    <Paper elevation={4} className={classes.paper}>
                        <svg className={classes.svg}>
                            <polygon points="0,100 50,00, 100,100" className={classes.polygon}/>
                        </svg>
                    </Paper>
                );*/
    console.log("alladcopies here", this.state.allAdCopies);
    return (
      <div style={{ backgroundColor: "#F9FAFF",zoom:"90%" }}>
        {isLoading ? (
          <div
            style={{
              height: "100%",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress color={"secondary"} />
          </div>
        ) : (
          <div>
            <TaskDataAppBar
              open={open}
              onClick={this.handleBack}
              title={title}
              selectedIds={selectedIds}
              // onClick1={(stringofAutotaskIds.includes(groupId.toString())&& actionStatus===5)?this.handleAutoPublish:this.handlePublish}
              // onClick1={(actionStatus === 5 && stringofAutotaskIds.includes(groupId.toString())) ? this.handleAutoPublish : this.handlePublish }
              onClick1={
                actionStatus === 5 ? this.handleAutoPublish : this.handlePublish
              }
              onClick2={this.handleRsaPublish}
              impact={impact}
              kpiType={kpiType}
              currency={currency}
              taskTypeId={groupId}
              timeEstimate={timeEstimate}
              selectedImpact={sumUpImpactOfIds(selectedIds)}
              width={width}
              npm
              isTaskExecutionLoading={isTaskExecutionLoading}
              actionStatus={actionStatus}
              actionStatusDate={actionDate}
              selectedAdCopies={adcopies}
              saveStatus={saveStatus}
            />
           
            <br />
            <br />
            <br />
            <br />
            <div className={classes.desSection}>
              <Paper className={classes.TDes}>
                <Grid
                  container
                  alignItems={
                    width == "sm" || width == "xs" ? "flex-start" : "center"
                  }
                >
                  <Grid item sm={1} xs={1} md={1}>
                    <Typography align={"center"}>
                      {width == "sm" || width == "xs"
                        ? icons.lightTaskResizable(40)
                        : icons.lightTaskResizable(60)}
                      &nbsp;
                    </Typography>
                  </Grid>
                  <Grid item sm={11} xs={11} md={11} className={classes.des}>
                    <Typography
                      variant="h6"
                      color="inherit"
                      noWrap
                      gutterBottom
                    >
                      <b>What does this task do?</b>
                    </Typography>
                    {this.state.taskTypeId == 51 ? (
                      <Typography variant={"h4"} className={classes.textDes}>
                        {
                          <div>
                            <p>
                              Using Generative AI technology, this tool can
                              analyze information about your brand, company, and
                              products to generate RSA content for Google Ads
                              that are highly likely to catch the attention of
                              potential customers and drive conversions.
                            </p>
                            <p>To use this tool, follow these steps:</p>
                            <p>
                              <ul>
                                <li>
                                  Provide information about your brand, company,
                                  and products in the input field.
                                </li>
                                <li>
                                  Click "Generate" to generate a set of ad
                                  assets.
                                </li>
                                <li>
                                  Manually review and refine the automatically
                                  generated ad assets to ensure they accurately
                                  reflect your brand and target messaging.
                                </li>
                                <li>
                                  Update the final URL or landing page to ensure
                                  it directs users to the most relevant page on
                                  your website.
                                </li>
                                <li>
                                  Select the ads you want to publish and click
                                  "Publish" to post them.
                                </li>
                              </ul>
                            </p>
                          </div>
                        }
                      </Typography>
                    ) : (
                      <Typography variant={"h4"} className={classes.textDes}>
                        {description}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Paper>
              <br />
            </div>
            {/* {this.state.taskTypeId == 51 && (
              <div className={classes.desSection} style={{paddingTop:"20px",paddingLeft:"10px"}}>
                  <FormControlLabel control={
                  <Checkbox
                  checked={selectAllCheck}
              {...console.log("select all check in checkbox", selectAllCheck)}
                  onChange={this.handleSelectAll}
                  value="selectAllCheck"
                  disabled = {!hasReadyToPublishAdCopy}
                  />
                }
                label="Select All"
                />
              </div>
            )} */}
            {this.state.taskTypeId == 49 && (
              <CustomPaper classes={classes}>
                <ShoppingHierarchy taskId={taskId} />
              </CustomPaper>
            )}
            {this.state.taskTypeId == 54 && (
              <CustomPaper classes={classes}>
                {!_.isEmpty(dataIdList) && (
                  <KeywordHierarchy
                    summary={summary}
                    data={data}
                    dataIdList={dataIdList}
                  />
                )}
              </CustomPaper>
            )}

            {this.state.taskTypeId != null &&
              this.state.taskTypeId != 49 &&
              this.state.taskTypeId != 54 &&
              getRenderer(this.state.taskTypeId)(
                classes,
                description,
                items,
                headerRenderer,
                selectionAlFlag,
                toggleSelection,
                taskDataById,
                this.state.taskTypeId,
                this.onSelectClearAll,
                dataIdList,
                isActivityReport,
                this.handleAdCopySelection,
                this.setSaveStatus
                // selectAllCheck,
                // this.setAllAdCopies,
              )}
          </div>
        )}
        {/* RSA TASK PUBLISH DIALOG BOX */}
        <Dialog
          open={adcopyAlert}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogHead id="alert-dialog-title">
            {"Publish Selected Ads"}
          </DialogHead>
          <DialogContent>
            <DialogText id="alert-dialog-description">
              <b>
                You have selected {selectedAdCopies.length} out of{" "}
                {dataIdList ? dataIdList.length : 0} available ads. Please click
                "Agree" to confirm, which will publish the selected ad(s) to
                Google Ads.
              </b>
            </DialogText>
          </DialogContent>
          <DialogActions>
            <PopUpCommonBtn onClick={this.handleRsaClose}>
              Disagree
            </PopUpCommonBtn>
            <PopUpOrangeBtn onClick={this.handleRsaPostAgree} autoFocus>
              Agree
            </PopUpOrangeBtn>
          </DialogActions>
        </Dialog>

        <Dialog
          open={postAlert}
          // onClick={stopPropagation}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogHead id="alert-dialog-title">{"Publish "}</DialogHead>
          <DialogContent>
            <DialogText id="alert-dialog-description">
              <b>
                Are you sure you want to publish these changes into the Search
                Engine?
              </b>
            </DialogText>
          </DialogContent>
          <DialogActions>
            <PopUpCommonBtn onClick={this.handleClose}>Disagree</PopUpCommonBtn>
            <PopUpOrangeBtn onClick={this.handlePostAgree} autoFocus>
              Agree
            </PopUpOrangeBtn>
          </DialogActions>
        </Dialog>

        {/*======================== dialog  box for the autoposting task======================== */}
        <Dialog
          open={autoPublish}
          // onClick={stopPropagation}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogHead id="alert-dialog-title">
            {"Published Automatically"}
          </DialogHead>
          <DialogContent>
            <DialogText id="alert-dialog-description">
              <b>
                This task was published automatically on Date:{" "}
                {dateStringFormat} Time:{dateStringFormatTime} on your Google
                Ads Account.
              </b>
            </DialogText>
          </DialogContent>
          <DialogActions style={TextCenter}>
            <NewOrangeContainedBtn onClick={this.handleAutoPublishClose}>
              OK
            </NewOrangeContainedBtn>
          </DialogActions>
        </Dialog>
        {/*==================== close dialog  box for the autoposting task =======================*/}

        <MySnackbarWrapper
          open={statusAlert}
          onClose={this.handleCloseAlert}
          message={
            <Grid container>
              <Grid item md={12} sm={12} xs={12}>
                <Typography variant={"h3"} style={{ color: "#ffffff" }}>
                  <b>{variantType == "success" ? "Success" : "Failed"}</b>
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant={"h3"}
                  style={{ color: "#ffffff" }}
                  // variant={"body1"}
                >
                  {message}
                </Typography>
              </Grid>
            </Grid>
          }
          verticalAlign={"bottom"}
          horizontalAlign={"right"}
          duration={6000}
          variant={this.state.variantType}
        />
        <Grow
          in={!isLoading && showForecast}
          style={{ transformOrigin: "0 0 0" }}
          {...(savingAmount > 0 ? { timeout: 1000 } : {})}
        >
          <div className={classes.successBanner}>
            {icons.saveCostForecast}
            {/*<img src={ellipse}/>*/}
            <div
              className={
                textToggle == costForecast
                  ? classes.bannerContainer
                  : classes.bannerContainer2
              }
            >
              <Grid container justifyContent={"center"}>
                <Grid item md={10} sm={10} xs={10}>
                  {textToggle == costForecast ? (
                    <Typography
                      variant={"h2"}
                      align={"center"}
                      className={classes.counterText}
                    >
                      <b>Savings</b>
                    </Typography>
                  ) : (
                    <Fragment>
                      <Typography
                        variant={"h2"}
                        align={"center"}
                        className={classes.counterText}
                      >
                        <b>Estimated</b>
                      </Typography>
                      <Typography
                        variant={"h2"}
                        align={"center"}
                        className={classes.counterText}
                      >
                        <b>Revenue</b>
                      </Typography>
                    </Fragment>
                  )}
                </Grid>
                <Grid item md={6} sm={6} xs={6}>
                  <Typography
                    variant={"h6"}
                    align={"center"}
                    style={{ wordBreak: "break-all" }}
                  >
                    <CountUp
                      start={0}
                      delay={0}
                      end={savingAmount}
                      duration={2.75}
                      decimal=","
                      prefix={getSymbolFromCurrency(currency)}
                      redraw={showForecast}
                      onEnd={() => {
                        console.log("popup ended");
                        this.setState({ showForecast: false });
                      }}
                      onStart={() => console.log("Started! 💨")}
                    >
                      {({ countUpRef, start }) => (
                        <div>
                          <span ref={countUpRef} />
                          {/*<button onClick={start}>Start</button>*/}
                        </div>
                      )}
                    </CountUp>
                  </Typography>
                  <Typography
                    className={classes.counterWeek}
                    align={"center"}
                    style={{ wordBreak: "break-all" }}
                  >
                    Per Week
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grow>
      </div>
    );
  }
}

function selectableIds(groupedTaskData, taskTypeId, taskDataById) {
  let selectableIds = null;
  if (isEmptyObj(groupedTaskData)) {
    selectableIds = groupedTaskData[taskTypeId].dataIdList.filter(
      (id) => taskDataById[id].status == 0
    );
  }
  return selectableIds;
}

function isEmptyObj(object) {
  for (var key in object) {
    if (object.hasOwnProperty(key)) {
      return true;
    }
  }
  return false;
}

Task.propTypes = {};
const mapStateToProps = (state, ownProps) => ({
  // Todo TaskTYpe need to be dynamic
  data: state.groupedTaskData["TaskType"][ownProps.match.params.id],
  data1: state.groupedTaskData["TaskType"],
  taskDataById: state.taskDataById,
  campaignIdMap: state.campaignIdMap,
  taskTypeIdMap: state.taskTypeIdMap,
  history: state.perf.history,
  isLoading: state.perf.isLoading,
  open: state.header.open,
  kpiType: state.perf.kpiType,
  currency: state.perf.currency,
  selectableIds: selectableIds(
    state.groupedTaskData["TaskType"],
    ownProps.match.params.id,
    state.taskDataById
  ),
  isAdmin: state.userInformation.isAdminUserDashboard,
  selectedIds:
    state.groupedTaskData["TaskType"][ownProps.match.params.id] &&
    state.groupedTaskData["TaskType"][ownProps.match.params.id].items &&
    state.groupedTaskData["TaskType"][
      ownProps.match.params.id
    ].dataIdList.filter((id) => state.taskDataById[id].selected),
});
const mapDispatchToProps = (dispatch) => ({
  toggleSelection: (taskIds, selected) =>
    dispatch(toggleSelection(taskIds, selected)),
  fetchPerfData: () => dispatch(fetchPerfData()),
  executeTasks: (taskDataIdList) =>
    dispatch(executeTasks(idToTaskDataList(taskDataIdList))),
  taskSeenDate: (taskIds) => dispatch(taskSeenDate(taskIds)),
  updateTaskDisplayStatus: (taskIds, displayType) =>
    dispatch(updateTaskDisplayStatus(taskIds, displayType)),
  updateViewValue: (viewValue) => {
    return dispatch({
      type: types.DASHBOARD_VIEW_VALUE_UPDATED,
      viewValue: viewValue,
    });
  },
  taskDataTack: (taskTypeId) => {
    return dispatch({
      type: types.TASK_DATA_TRACK,
      taskTypeId: taskTypeId,
    });
  },
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withWidth()(withStyles(styles)(Task)));
