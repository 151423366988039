import React from "react";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import icons from "./icons";
import { Grid } from "@mui/material";
import classNames from "classnames";
import { GreenText } from "./typography";

const styles = (theme) => ({
  card: {
    display: "flex",
    boxShadow: "none",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: 250,
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  para: {
    fontFamily: "Montserrat",
    fontWeight: "500",
  },
  client1: {
    fontFamily: "Montserrat",
    fontWeight: "lighter",
    fontSize: 20,
  },
  clientName1: {
    fontFamily: "Montserrat",
    fontSize: 24,
  },
});

function MediaControlCard(props) {
  const { classes, theme, imgUrl } = props;

  return (
    <Card className={classes.card}>
      {/*<CardMedia
                className={classes.cover}
                image={imgUrl}
            />*/}
      <Grid container justifyContent={"center"}>
        <Grid item md={2}>
          {icons.client_1_dummy}
        </Grid>
        <Grid item sm={12} xs={12} md={10}>
          <div className={classNames(classes.details, "fullWidth")}>
            <CardContent className={classes.content}>
              <Typography
                variant="subtitle1"
                color="textPrimary"
                className={classes.para}
              >
                <sup>{icons.quoteLeft}</sup>
                LXRGuide the thing that really has made the biggest difference
                is how simple it's been to go in and make decisions every day...
                I feel like we saw immediate results in that, to begin with.
              </Typography>
              <br />
              <br />
              <br />
              <Grid container spacing={2}>
                <Grid item>
                  <Typography
                    component={"p"}
                    variant={"h6"}
                    color="textPrimary"
                  >
                    <b>Thomas Ellis</b>
                    <Typography
                      component={"span"}
                      color="textPrimary"
                      className={classes.client1}
                    >
                      Owner
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item>{icons.client_1_icon}</Grid>
              </Grid>
            </CardContent>
          </div>
        </Grid>
      </Grid>
    </Card>
  );
}

MediaControlCard.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(MediaControlCard);
