import imageBg from "../../img/home_bg.svg";

const aboutUsStyles = (theme) => ({
  card: {
    boxShadow: "unset",
    zoom:"90%",
  },
  gridBg: {
    background: `url(${imageBg})`,
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      backgroundSize: "contain",
      backgroundPosition: "top center",
      backgroundColor: theme.palette.common.darkGreybg,
      // background: `url(${imageBg})`,
    },
    [theme.breakpoints.up("xl")]: {
      backgroundRepeat: "no-repeat",
      // background: `url(${imageBg})`,
    },
    [theme.breakpoints.down("xl")]: {
      // background: `unset`,
      // background: `url(${imageBg})`,
      backgroundColor: theme.palette.common.darkGreybg,
    },
  },
  cardContent: {
    padding: 0,
    // paddingTop: theme.spacing(3),
    paddingBottom: 0,
  },
  sectionBg: {
    // backgroundColor: theme.palette.common.darkGreybg,
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.between("md", "lg")]: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      height: 750,
    },
    [theme.breakpoints.between("xm", "md")]: {
      height: 600,
    },
  },
  highlightsSection: {
    paddingTop: 20,
    paddingBottom: 10,
  },
  section2: {
    backgroundColor: theme.palette.common.white,
    // height: 400,
    paddingTop: theme.spacing(3.5),
    paddingBottom: theme.spacing(2.5),
    // paddingRight:theme.spacing(4),
    // paddingLeft:theme.spacing(4),
  },
  section3: {
    backgroundColor: theme.palette.common.darkGreybg,
    minHeight: 400,
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    paddingRight: theme.spacing(8),
    paddingLeft: theme.spacing(8),
  },
  secCard: {
    border: "1px solid " + theme.palette.common.darkGrey,
    boxSizing: "border-box",
    borderRadius: 10,
    height: 400,
    boxShadow: "unset",
    background: "unset",
  },
  section4: {
    backgroundColor: theme.palette.common.white,
    minHeight: 400,
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    paddingRight: theme.spacing(8),
    paddingLeft: theme.spacing(8),
  },
  li: {
    paddingLeft: theme.spacing(2),
  },
  menuItemText: {},
  divider: {
    border: "1px solid #EAEAEA",
  },
  stepContent: {
    color: theme.palette.text.primary,
    fontSize: 24,
    [theme.breakpoints.down("xl")]: {
      fontSize: 12,
    },
  },
  orangeText: {
    color: theme.palette.orangeColor,
    fontWeight: "700",
  },
  heading: {
    color: "textPrimary",
    textAlign: "center",
    fontWeight: "700",
    lineHeight:"1.2",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.25rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2.75rem",
    },
  },
});

export default aboutUsStyles;
