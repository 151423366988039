import React from "react";
import {
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Typography,
  Paper,
} from "@mui/material";
import classNames from "classnames";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import NumberFormat from "react-number-format";
import {
  OutLinedOrangeBtnBlueBorder,
  OutLinedOrangeBtnBlueBorderNoUnderLine,
} from "../../components/buttons";
import * as PropTypes from "prop-types";
import {
  CustomListItem,
  CustomListItemText,
  SubSectionCardHeader,
} from "../../components/siteGrader/templates";
import moment from "moment";
import { Link } from "react-router-dom";
import icons from "../../components/icons";
import KeywordScoreCard from "../../components/internalSiteGrader/keywordScoreCard";
import withStyles from '@mui/styles/withStyles';

function OnPageSeo(props) {
  const { siteGrader, classes, type } = props;
  const robotsText =
    siteGrader != null && siteGrader.technical
      ? siteGrader.technical.robotsTxt
      : false;
  const validSSLCertificate =
    siteGrader != null && siteGrader.technical
      ? siteGrader.technical.validSSLCertificate
      : false;
  const brokenURLs =
    siteGrader != null && siteGrader.technical
      ? siteGrader.technical.brokenURLs
      : null;
  const wwwResolved =
    siteGrader != null && siteGrader.technical
      ? siteGrader.technical.wwwResolved
      : false;
  const structuredDataScore =
    siteGrader != null && siteGrader.technical
      ? siteGrader.technical.structuredDataScore
      : 0;
  return (
    <Paper
      className={classNames(classes.paper, classes.text)}
      style={{ height: "100%" }}
    >
      <Typography className={classes.text} color={"textPrimary"}>
        <b>On Page</b>
      </Typography>
      <Grid container style={{ height: "88%" }} alignItems={"center"}>
        <Grid item xs={12} sm={12} md={12}>
          <br />
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
            spacing={2}
            className={classes.padding2x}
          >
            <Grid item md={6} sm={6} xs={6}>
              <Typography
                align={"left"}
                className={classNames(classes.montserrat, classes.text)}
                color={"textPrimary"}
              >
                Robots.txt
              </Typography>
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
              {robotsText != true ? (
                <Typography
                  align={"right"}
                  className={classes.crossStyle}
                  style={{ paddingTop: 5 }}
                >
                  {icons.closeCircle}
                </Typography>
              ) : (
                <Typography
                  align={"right"}
                  className={classes.checkedStyle}
                  style={{ paddingTop: 5 }}
                >
                  {icons.checkedCircle}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Divider className={classes.dashedLine} />
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
            spacing={2}
            className={classes.padding2x}
          >
            <Grid item md={8} sm={6} xs={6}>
              <Typography
                align={"left"}
                className={classNames(classes.montserrat, classes.text)}
                color={"textPrimary"}
              >
                Valid SSL Certificate
              </Typography>
            </Grid>
            <Grid item md={4} sm={6} xs={6}>
              {validSSLCertificate != true ? (
                <Typography
                  align={"right"}
                  className={classes.crossStyle}
                  style={{ paddingTop: 5 }}
                >
                  {icons.closeCircle}
                </Typography>
              ) : (
                <Typography
                  align={"right"}
                  className={classes.checkedStyle}
                  style={{ paddingTop: 5 }}
                >
                  {icons.checkedCircle}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Divider className={classes.dashedLine} />
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
            spacing={2}
            className={classes.padding2x}
          >
            <Grid item md={6} sm={6} xs={6}>
              <Typography
                align={"left"}
                className={classNames(classes.montserrat, classes.text)}
                color={"textPrimary"}
              >
                Broken URLs
              </Typography>
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
              {brokenURLs != null ? (
                <Typography
                  align={"right"}
                  className={classes.crossStyle}
                  style={{ paddingTop: 5 }}
                >
                  {icons.closeCircle}
                </Typography>
              ) : (
                <Typography
                  align={"right"}
                  className={classes.checkedStyle}
                  style={{ paddingTop: 5 }}
                >
                  {icons.checkedCircle}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Divider className={classes.dashedLine} />
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
            spacing={2}
            className={classes.padding2x}
          >
            <Grid item md={6} sm={6} xs={6}>
              <Typography
                align={"left"}
                className={classNames(classes.montserrat, classes.text)}
                color={"textPrimary"}
              >
                WWW Resolved
              </Typography>
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
              {wwwResolved == false ? (
                <Typography
                  align={"right"}
                  className={classes.crossStyle}
                  style={{ paddingTop: 5 }}
                >
                  {icons.closeCircle}
                </Typography>
              ) : (
                <Typography
                  align={"right"}
                  className={classes.checkedStyle}
                  style={{ paddingTop: 5 }}
                >
                  {icons.checkedCircle}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Divider className={classes.dashedLine} />
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
            spacing={2}
            className={classes.padding2x}
          >
            <Grid item md={8} sm={6} xs={6}>
              <Typography
                align={"left"}
                className={classNames(classes.montserrat, classes.text)}
                color={"textPrimary"}
              >
                Structured Data Score
              </Typography>
            </Grid>
            <Grid item md={4} sm={6} xs={6}>
              {structuredDataScore == 0 ? (
                <Typography
                  align={"right"}
                  className={classes.red}
                  style={{ paddingTop: 5 }}
                >{`${structuredDataScore} / 100`}</Typography>
              ) : (
                <Typography
                  align={"right"}
                  className={classes.green}
                  style={{ paddingTop: 5 }}
                >{`${structuredDataScore} / 100`}</Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

OnPageSeo.propTypes = {
  classes: PropTypes.any,
  robotsText: PropTypes.bool,
  validSSLCertificate: PropTypes.bool,
  brokenURLs: PropTypes.any,
  wwwResolved: PropTypes.bool,
  structuredDataScore: PropTypes.number,
  seoTrafficEstimate: PropTypes.number,
  monthlyAdwordsBudget: PropTypes.number,
};
const pageSeoCardStyles = (theme) => ({
  dashedLine: {
    border: "1px dashed" + theme.palette.common.darkHash,
    backgroundColor: "unset",
    boxSizing: "border-box",
    borderRadius: 10,
    opacity: 0.4,
    height: 0,
  },
  scoreValue: { fontFamily: "Lato", color: theme.palette.text.primary },
  text: { fontSize: "14px" },
  paper: { boxShadow: "unset" },
  montserrat: {
    fontFamily: "Lato",
    opacity: "0.5",
    color: theme.palette.text.primary,
    fontSize: "12px",
  },
  padding2x: {
    padding: 2,
  },
});
export default withStyles(pageSeoCardStyles)(OnPageSeo);
