const styles = (theme) => ({
  desSection: {
    [theme.breakpoints.down("xl")]: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(8),
      marginRight: theme.spacing(8),
    },
  },
  des: {
    paddingLeft: theme.spacing(2),
  },

  TDes: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    background: theme.palette.taskfb,
    boxShadow: "unset",
    border: "1px solid" + theme.palette.taskfd2,
    boxSizing: "border-box",
    borderRadius: 6,
  },

  textDes: {
    opacity: 0.6,
  },
  paper: {
    // background: theme.palette.common.white,
    boxShadow: "4px 4px 18px rgba(0, 0, 0, 0.06)",
    borderRadius: 6,
    padding: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      width: 400,
      overflowX: "scroll",
    },
  },
  card: {
    background: "none",
    boxShadow: "unset",
  },
});

export default styles;
