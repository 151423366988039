import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Paper } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import * as types from "../../actions/actionTypes"
import {getManipulatedUserInfoList, getProvUserInfoList, filterList} from '../admin/templates';
import Filter from './filter';
import Search from './search';
import {
    SortingState,
    GroupingState,
    IntegratedSorting,
    PagingState,
    IntegratedPaging,
    FilteringState,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    TableColumnResizing,
    GroupingPanel,
    Toolbar,
    VirtualTable,
    TableFilterRow
} from '@devexpress/dx-react-grid-material-ui';
import {CustomPaging} from '@devexpress/dx-react-grid'
import {adminUserTable, pageElements} from "../../actions";
import {connect} from "react-redux";
import CircularProgress from '@mui/material/CircularProgress';
import * as _ from "lodash";
import {InputField} from "../input-forms";

class AdminUserTable extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            columns: [{name: 'username', title: "User Name"},
                {name: 'companyName', title: "Company Name"},
                {name: 'registrationDate', title: "Registration Date"},
                {name: 'status', title: "Status"},
                {name: 'type', title: "Channel"},
                {name: 'nextPaymentDate', title: "Next Payment"},
            ],
            pageSizes: [5, 10, 15, 0],
            tableColumnExtensions: [
                {columnName: 'advanceRemaining', align: 'right'},
            ],
            columnWidths: [
                {columnName: 'username', width: 280},
                {columnName: 'companyName', width: 160},
                {columnName: 'registrationDate', width: 180},
                {columnName: 'status', width: 180},
                {columnName: 'type', width: 150},
                {columnName: 'nextPaymentDate', width: 150},
            ],
            filters: [],
        };
        this.changeCurrentPage = this.changeCurrentPage.bind(this);
        this.changeFilters = this.changeFilters.bind(this);
        this.changeColumnWidths = (columnWidths) => {
            this.setState({columnWidths});
        };
    }

    componentDidMount() {
        const {currentPage, sorting, statusFilter, searchTerm, channelFilter, userTypeToggle} = this.props.adminUserTable;
        console.log("sorting=", sorting);
        this.props.getAdminUserTableData(currentPage, sorting, false, statusFilter, searchTerm, channelFilter, userTypeToggle);
    }
    changeCurrentPage(currentPage) {
        const {sorting, statusFilter, searchTerm, channelFilter, userTypeToggle} = this.props.adminUserTable;
        console.log("in side func=currentPage", currentPage);
        this.props.getAdminUserTableData(currentPage, sorting, false, statusFilter, searchTerm, channelFilter, userTypeToggle);
    }

    async changeFilters(filters) {
        console.log("on change filters", filters);
        await this.setState({filters});
        console.log("updated state=", this.state);
        const URL = this.filterConstructString();
        console.log("queryString=", URL);
        const {currentPage, sorting, searchTerm, channelFilter, userTypeToggle} = this.props.adminUserTable;
        this.props.getAdminUserTableData(currentPage, sorting, true, filters, searchTerm, channelFilter, userTypeToggle);
    }

    filterConstructString() {
        const {filters} = this.state;

        let filter = filters.reduce((acc, {columnName, value}) => {
            acc.push(`["${columnName}", "contains", "${encodeURIComponent(value)}"]`);
            return acc;
        }, []).join(',"and",');

        if (filters.length > 1) {
            filter = `[${filter}]`;
        }

        return filter;
    }

    changeSorting = sorting => {
        const {currentPage, statusFilter, searchTerm, channelFilter, userTypeToggle} = this.props.adminUserTable;   
        this.props.getAdminUserTableData(currentPage, sorting, true, statusFilter, searchTerm, channelFilter, userTypeToggle);
    };

    handleCreateUserBtn = async (id) => {
        if (!window.confirm("Are you sure you want to add this provisional user as LXRGuide User?")) {
            return null;
        }
        const {provisionalUserList} = this.props.adminUserTable;
        console.log("provisionalUserList--->",provisionalUserList)
        let user;
        const {currentPage, sorting, statusFilter, searchTerm, channelFilter, userTypeToggle} = this.props.adminUserTable;
        for (var n = 0; n < provisionalUserList.length; n++) {
            let tempUser=provisionalUserList[n];
            if(tempUser.pvslUserId==id){
                user= tempUser;
            }
            this.state.provisionUserId=id;
            this.props.handleProvUserDialog('userDialog',user);

        }
    };
   
    render() {

        const {rows, isLoading, currentPage, pageSize, totalCount, sorting, statusFilter,channelFilter, provisionalUserList} = this.props.adminUserTable;
        const {columnWidths, columns} = this.state;
        let {users} = pageElements(pageSize, currentPage, rows);
        if(channelFilter!=10){
            users = getManipulatedUserInfoList(users, types.ADMIN_USER_TABLE);
            this.state = {
                columns: [{name: 'username', title: "User Name"},
                    {name: 'companyName', title: "Company Name"},
                    {name: 'registrationDate', title: "Registration Date"},
                    {name: 'status', title: "Status"},
                    {name: 'type', title: "Channel"},
                    {name: 'nextPaymentDate', title: "Next Payment"},
                ],
                pageSizes: [5, 10, 15, 0],
                tableColumnExtensions: [
                    {columnName: 'advanceRemaining', align: 'right'},
                ],
                columnWidths: [
                    {columnName: 'username', width: 280},
                    {columnName: 'companyName', width: 160},
                    {columnName: 'registrationDate', width: 180},
                    {columnName: 'status', width: 180},
                    {columnName: 'type', width: 150},
                    {columnName: 'nextPaymentDate', width: 150},
                ],
                filters: [],
            };
            this.changeCurrentPage = this.changeCurrentPage.bind(this);
            this.changeFilters = this.changeFilters.bind(this);
            this.changeColumnWidths = (columnWidths) => {
                this.setState({columnWidths});
            };

        }else{
            // {console.log("provisional user -----:",provisionalUserList)}
            users = getProvUserInfoList(provisionalUserList, types.ADMIN_USER_TABLE,this.handleCreateUserBtn);
            this.state = {
                columns: [{name: 'pvslUsername', title: "User Name"},
                    {name: 'pvslCompany', title: "Company Name"},
                    {name: 'registrationDate', title: "Registration Date"},
                    {name: 'contractSigned', title: "Contract Signed"},
                    {name: 'agreementURL', title: "Agreement URL"},
                    {name: 'action', title: "Action"},
                ],
                pageSizes: [5, 10],

                columnWidths: [
                    {columnName: 'pvslUsername', width: 240},
                    {columnName: 'pvslCompany', width: 160},
                    {columnName: 'registrationDate', width: 180},
                    {columnName: 'contractSigned', width: 140},
                    {columnName: 'agreementURL', width: 500},
                    {columnName: 'action', width: 120},

                ],
                filters: [],
            };
            this.changeCurrentPage = this.changeCurrentPage.bind(this);
            this.changeFilters = this.changeFilters.bind(this);
            this.changeColumnWidths = (columnWidths) => {
                this.setState({columnWidths});
            };
        }


        console.log("rows= in render", rows);
        return (
            <Paper>
                {(isLoading == true) ? <CircularProgress/> :
                    <div>
                        <Filter/>
                        <Grid
                            rows={users}
                            columns={columns}
                        >
                            <PagingState
                                currentPage={currentPage}
                                onCurrentPageChange={this.changeCurrentPage}
                                pageSize={pageSize}
                            />
                            <CustomPaging
                                totalCount={totalCount}
                            />
                            {channelFilter!=10?
                                <SortingState
                                    sorting={sorting}
                                    onSortingChange={this.changeSorting}
                                    columnExtensions={[{columnName: 'status', sortingEnabled: false}]}
                                />: <SortingState
                                    columnExtensions={[{columnName: 'pvslUsername', sortingEnabled: false},
                                        {columnName: 'pvslCompany', sortingEnabled: false},
                                        {columnName: 'registrationDate', sortingEnabled: false},
                                        {columnName: 'contractSigned', sortingEnabled: false},
                                        {columnName: 'agreementURL', sortingEnabled: false},
                                        {columnName: 'action', sortingEnabled: false}]}
                                />}

                            <Table columnExtensions={this.state.tableColumnExtensions}/>
                            {channelFilter!=10? <TableColumnResizing
                                columnWidths={columnWidths}
                                onColumnWidthsChange={this.changeColumnWidths}
                            />:<TableColumnResizing
                                columnWidths={columnWidths}
                            />}
                            <TableHeaderRow showSortingControls/>
                            <PagingPanel/>
                        </Grid>
                    </div>
                }

            </Paper>
        );    }
}

AdminUserTable.propTypes = {
    handleProvUserDialog:PropTypes.func,
};
const mapStateToProps = (state, ownProps) => ({
    adminUserTable: state.adminUserTable
});
const mapDispatchToProps = (dispatch) => ({
    getAdminUserTableData: (currentPage, sorting, sortingPresent, statusFilter, searchTerm, channelFilter, userTypeToggle) => {
        return dispatch(adminUserTable(currentPage, sorting, sortingPresent, statusFilter, searchTerm, channelFilter, userTypeToggle))
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminUserTable);
