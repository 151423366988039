import React, { Fragment } from "react";
import withStyles from "@mui/styles/withStyles";
import NumericLabel from "react-pretty-numbers";
import { Typography } from "@mui/material";
import { connect } from "react-redux";
import CurrencySymbol from "../../components/currencySymbol.jsx";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const OrangeText = withStyles((theme) => ({
  root: {
    color: theme.palette.orangeColor,
  },
}))(Typography);
const whiteText = withStyles((theme) => ({
  root: {
    color: theme.palette.common.white,
    fontWeight: "lighter",
    fontSize: 12,
  },
}))(Typography);

function Savings(props) {
  const {
    orderForecast,
    costForecast,
    revenueForecast,
    costSavingForecast,
    timeSavedEstimate,
    KPIType,
  } = props;
  const formatOptions = {
    commafy: true,
    precision: 0,
    locales: "en-US",
    currency: true,
    wholenumber: "ceil",
    currencyIndicator: "USD",
    cssClass: ["inline"],
  };

  const renderSavings = () =>
    costSavingForecast ? (
      <OrangeText
        variant={"caption"}
        component={"span"}
        style={{ justifyContent: "end", display: "block", marginTop: "2px" }}
      >
        • Save{" "}
        <CurrencySymbol formatOptions={formatOptions}>
          {costSavingForecast}
        </CurrencySymbol>{" "}
        per week &nbsp;
      </OrangeText>
    ) : null;

  const timeSavedEstimates = () =>
    timeSavedEstimate &&
    (revenueForecast > 0 ||
      orderForecast > 0 ||
      costForecast > 0 ||
      costSavingForecast > 0) ? (
      <OrangeText variant={"caption"} component={"span"}>
        |
        <span style={{ verticalAlign: "sub" }}>
          {" "}
          <AccessTimeIcon style={{ fontSize: 12 }} />
        </span>
        <span style={{ verticalAlign: "bottom" }}>
          {" "}
          {timeSavedEstimate} mins average time saving per week &nbsp;
        </span>
      </OrangeText>
    ) : timeSavedEstimate ? (
      <OrangeText variant={"caption"} component={"span"}>
        <span style={{ verticalAlign: "sub" }}>
          <AccessTimeIcon style={{ fontSize: 12 }} />
        </span>
        <span style={{ verticalAlign: "bottom" }}>
          {" "}
          {timeSavedEstimate} mins average time saving per week &nbsp;
        </span>
      </OrangeText>
    ) : null;

  const renderRevenueImapct = () =>
    revenueForecast > 0 && KPIType !== "CPO" ? (
      <OrangeText
        variant={"caption"}
        component={"span"}
        style={{ justifyContent: "end", display: "block", marginTop: "2px" }}
      >
        • Increase Revenue by&nbsp;
        <CurrencySymbol formatOptions={formatOptions}>
          {revenueForecast}
        </CurrencySymbol>{" "}
        per week with additional spend
        <CurrencySymbol formatOptions={formatOptions}>
          {costForecast}
        </CurrencySymbol>{" "}
        &nbsp;
      </OrangeText>
    ) : null;

  const renderOrderImapct = () =>
    orderForecast > 0 && KPIType === "CPO" ? (
      <OrangeText
        variant={"caption"}
        component={"span"}
        style={{ marginTop: "2px" }}
      >
        • Increase Orders by&nbsp;
        <CurrencySymbol formatOptions={formatOptions}>
          {orderForecast}
        </CurrencySymbol>{" "}
        per week with additional spend
        <CurrencySymbol formatOptions={formatOptions}>
          {costForecast}
        </CurrencySymbol>{" "}
        &nbsp;
      </OrangeText>
    ) : null;

  return (
    <Fragment>
      {renderOrderImapct()}
      {renderRevenueImapct()}
      {renderSavings()}
      {timeSavedEstimates()}
    </Fragment>
  );
}

export default connect((state) => ({ KPIType: state.perf.KPIType }))(Savings);
