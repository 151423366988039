import { Divider, Grid, Hidden, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import icons from "../components/icons.js";
import * as PropTypes from "prop-types";
import React from "react";
// import {sortTasks} from "../reducers/common";
import { connect } from "react-redux";
import { NewTasks } from "./commonFunctions/commonFunctions.jsx";

function PendingTask(props) {
  const { task, classes, itemsPendingCount } = props;
  console.log("task=", task);
  console.log("props in pending tasks", props);
  const {
    groupId: taskTypeId,
    title: taskName,
    description: taskDescription,
    impact,
  } = task.summary;

  return (
    <div>
      <Grid container alignItems={"flex-start"}>
        <Grid item xs={2} sm={2} md={1} lg={1} xl={1}>
          <Avatar className={classes.avatar} variant="square">
            <Typography
              className={classes.avatarText}
              variant={"body2"}
              color={"textPrimary"}
              style={{ fontWeight: "bold" }}
            >
              {props.index + 1}
            </Typography>
          </Avatar>
        </Grid>
        <Grid item xs={10} sm={10} md={11} lg={11} xl={11}>
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"flex-start"}
          >
            <Grid item>
              {NewTasks.includes(taskTypeId) && (
                <span style={{ display: "flex", alignItems: "flex-start" }}>
                  <Typography
                    color={"textPrimary"}
                    variant={"body1"}
                    gutterBottom
                    style={{ fontSize: "1rem" }}
                  >
                    <b>{taskName} &nbsp;</b>
                  </Typography>
                  <span> {icons.New_Sticker}</span>
                </span>
              )}

              {!NewTasks.includes(taskTypeId) && ( 
                <Typography
                  color={"textPrimary"}
                  variant={"body1"}
                  gutterBottom
                  style={{ fontSize: "1rem" }}
                >
                  <b>{taskName}</b>
                </Typography>
              )}
              <div className={"flex"}>
                <Typography color={"textPrimary"} variant={"body2"}>
                  <b>{itemsPendingCount}</b>
                </Typography>
                &nbsp;
                <Typography
                  color={"textPrimary"}
                  variant={"body2"}
                  className={classes.taskCaption}
                >
                  item(s) pending
                </Typography>
              </div>
            </Grid>
            <Hidden mdDown>
              <Grid item>{icons.rightArrowT}</Grid>
            </Hidden>
          </Grid>
        </Grid>
      </Grid>
      <div style={{ padding: "0.8rem", paddingBottom: "1rem" }}>
        {props.index != 3 ? (
          <Divider variant={"middle"} className={classes.dashedLine} />
        ) : null}
      </div>
    </div>
  );
}

PendingTask.propTypes = {
  classes: PropTypes.any,
  index: PropTypes.any,
  task: PropTypes.any,
};
const mapStateToProps = (state, ownProps) => ({
  itemsPendingCount: ownProps.task.dataIdList.filter(
    (id) => state.taskDataById[id].selected
  ).length,
  KPIType: state.perf.kpiType,
  currency: state.perf.currency,
  isLoading: state.perf.isLoading,
  taskDataById: state.taskDataById,
});
//
export default connect(mapStateToProps, null)(PendingTask);
