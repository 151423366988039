import React, { Fragment, useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import icons from "../../components/icons";
import { connect } from "react-redux";
import { PromocodeApplyButton } from "../../components/buttons";
import { onDemandPromocodeApply } from "../../actions";
import "./promocode.css";

const styles = (theme) => ({});

function Promocode(props) {
  const {
    OnDemandPromocodeReducer,
    onDemandPromocodeApply,
    onPromocodeChange,
    clearPromocodeSection,
    grandTotal,
    setIsLoading,
    setPaymentLoading,
    userPromocode,
    promocodeSuccess,
  } = props;


  const actualAmount = grandTotal;
//Promocode message
  const [promocodeMessage, setPromocodeMessage] = useState(
    OnDemandPromocodeReducer && OnDemandPromocodeReducer.message
      ? OnDemandPromocodeReducer.message
      : null
  );

  const promocodeApply = async () => {
    if (userPromocode && userPromocode != "") {
      setIsLoading(true);
      setPaymentLoading(true);
      const data = await onDemandPromocodeApply(userPromocode, actualAmount);
      console.log("apply promocode data", data);
      if (data) {
        setPromocodeMessage(data.message);
      }
    }
    setIsLoading(false);
  };

  const clearPromocode = () => {
    clearPromocodeSection(null);
  };
  return (
    <Grid
      container
      columnSpacing={2}
      alignItems="center"
      style={{ margin: "4px 0px", textAlign: "left" }}
    >
      <Grid item xs={12} sm={5} md={4} lg={4} xl={3}>
        <Typography style={{ color: "#F58120" }}>Have a promo code?</Typography>
      </Grid>
      <Grid item xs={8} sm={4} md={3} lg={3} xl={3}>
        <div
          className={
            promocodeSuccess == null
              ? "flexCenter inputBoxAndCrossBtn"
              : promocodeSuccess
              ? "flexCenter greenBorder"
              : "flexCenter errorBorder"
          }
        >
          <input
            type="text"
            id="promocode"
            onChange={onPromocodeChange}
            placeholder={
              userPromocode == "" ? "Type or paste it here" : userPromocode
            }
            value={userPromocode}
            disabled={promocodeSuccess == true}
          />
          {promocodeSuccess !== null && userPromocode != "" ? (
            <button
              id="clear"
              onClick={clearPromocode}
              style={{
                background: "none",
                cursor: "pointer",
                paddingLeft: "2px",
              }}
            >
              {icons.PromocodeClear}
            </button>
          ) : (
            ""
          )}
        </div>
      </Grid>
      <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
        <PromocodeApplyButton
          onClick={promocodeApply}
          disabled={promocodeSuccess == true || userPromocode == ""}
        >
          Apply
        </PromocodeApplyButton>
      </Grid>
      {promocodeSuccess == null ? (
        ""
      ) : promocodeSuccess ? (
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Typography
            variant="subtitle2"
            style={{ color: "#00A850", fontStyle: "italic" }}
          >
            {promocodeMessage}
          </Typography>
        </Grid>
      ) : (
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Typography
            variant="subtitle2"
            style={{ color: "#ED4A4A", fontStyle: "italic" }}
          >
            {promocodeMessage}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}
const mapStateToProps = (state) => ({
  OnDemandPromocodeReducer: state.OnDemandPromocodeReducer,
});

const mapDispatchToProps = (dispatch) => ({
  onDemandPromocodeApply: (promocode, actualAmount) =>
    dispatch(onDemandPromocodeApply(promocode, actualAmount)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Promocode));
