import React, { Fragment } from "react";
import { connect } from "react-redux";
import { createHashHistory } from "history";
import {
  fetchActivityReport,
  fetchTaskDataByTaskId,
} from "../../actions/activityReport";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import icons from "../../components/icons";
import Typography from "@mui/material/Typography";
import { Card, CardContent, CircularProgress } from "@mui/material/index";
import withStyles from "@mui/styles/withStyles";
import styles from "../../assets/jss/activityReport/activityTaskDataStyles";
import * as _ from "lodash";
import { getRenderer } from "../task-data";
import { IconAndName } from "../task";
import TaskDataAppBar from "../TaskDataAppBar";
import { sumUpImpactOfIds } from "../../reducers/activityReportData";
import Snackbar from "../../components/snackbars";
import MySnackbarWrapper from "../snackbars";
import { TypeTitle } from "../../components/task/task-data";
import { ShoppingHierarchy } from "../../components/task/shoppingHierarchy";
import KeywordHierarchy from "../../components/task/keywordHierarchy";
import PropTypes from "prop-types";
import RadioGroup from "@mui/material/RadioGroup";
import ReactDOM from "react-dom";

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="xl" />;

function getData(state, taskId, taskTypeId) {
  const activityReport = state.activityReport;
  const selectedTd = activityReport.selectedTd;
  if (!_.isEmpty(activityReport.selectedTd) && _.has(selectedTd, taskId)) {
    return selectedTd[taskId].TaskType[taskTypeId];
  } else {
    return {};
  }
}

const history = createHashHistory();

async function fetchTaskData() {}
function CustomPaper(props) {
  return (
    <Card className={props.classes.otherCards}>
      <CardContent>
        <Paper className={props.classes.tdPaper}>{props.children}</Paper>
      </CardContent>
    </Card>
  );
}

CustomPaper.propTypes = { classes: PropTypes.any };

class ActivityTaskData extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      taskTypeId: this.props.match.params.taskTypeId
        ? parseInt(this.props.match.params.taskTypeId)
        : null,
      selectionAlFlag: true,
      isTaskExecutionLoading: false,
      isTdLoading: false,
      alert: false,
      message: "",
      variantType: "",
    };
  }
  setSaveStatus = (newStatus) => {};
  handleAdCopySelection = (isSelected, adCopy) => {};

  async componentDidMount() {
    const { data } = this.props;
    console.log("componentDidMount=");
    ReactDOM.findDOMNode(this).scrollIntoView();
    window.scrollTo(0, 0);
    //         if (_.isEmpty(data)) {
    this.setState({ isTdLoading: true });
    let response = await this.props.fetchTaskDataById(
      this.props.match.params.taskId,
      this.props.match.params.taskTypeId,
      this.props.match.params.analysisTypeId
    );
    console.log("response=", response);
    if (response && response.error && response.error != "") {
      await this.setState({
        isTdLoading: false,
        alert: true,
        message: response.error,
        variantType: "error",
      });
    } else {
      await this.setState({
        isTdLoading: false,
        alert: false,
        message: "",
        variantType: "info",
      });
    }
    //         }
  }

  renderCampaignHeader = (campaignId) => {
    const { campaignIdMap } = this.props;
    console.log("campaignIdMap", campaignIdMap);
    let campaign = campaignIdMap[campaignId];
    console.log("campaign===", campaign);
    // let CampaignTypeIcon = icons[campaign.campaignType] || Typography;
    return (
      <Fragment>
        {campaign != undefined && !_.isEmpty(campaignIdMap) && (
          <IconAndName
            icon={icons.Campaign}
            name={
              <Fragment>
                <span>
                  <b>{campaign.campaignName}</b>
                </span>
                <Typography>
                  <TypeTitle type="Campaign" value={campaign.campaignType} />
                </Typography>
              </Fragment>
            }
          />
        )}
      </Fragment>
    );
  };
  renderTaskTypeHeader = (taskTypeId) => {
    const { taskTypeIdMap } = this.props;
    const taskType = taskTypeIdMap[taskTypeId];
    return (
      <IconAndName
        icon={icons.Task}
        name={taskType.taskName}
        bold
        caption={taskType.taskDescription}
      />
    );
  };
  renderAdgroupHeader = (name) => (
    <IconAndName
      classes={this.props.classes}
      icon={icons.AdGroup}
      name={name}
    />
  );

  headerRenderers = {
    TaskType: [this.renderCampaignHeader, this.renderAdgroupHeader],
    Campaign: [this.renderTaskTypeHeader, this.renderAdgroupHeader],
  };
  handleCloseAlert = async () => {
    await this.setState({ alert: false, message: "", variantType: "info" });
  };

  render() {
    const {
      classes,
      data,
      taskDataById,
      isTaskExecutionLoading,
      isDrawerOpen,
      kpiType,
      width,
      currency,
      isUserDataLoading,
    } = this.props;
    const { selectionAlFlag, isTdLoading, selectAllCheck } = this.state;
    const toggleSelection = null;
    const isActivityReport = true;
    console.log("data===========", data);
    console.log("taskDataById=======", taskDataById);
    console.log("isTaskExecutionLoading======", isTaskExecutionLoading);
    console.log("isDrawerOpen======", isDrawerOpen);
    console.log("kpiType=========", kpiType);
    console.log("width=========", width);
    console.log("currency=======", currency);
    console.log("isUserDataLoading=======", isUserDataLoading);

    // console.log("activityReport")
    // console.log("component data", data);
    const title = data && data.summary ? data.summary.title : null;
    const summary = data && data.summary ? data.summary : null;
    const dataIdList = data && data.dataIdList ? data.dataIdList : null;
    const description = data && data.summary ? data.summary.description : null;
    const taskId = data && data.summary ? data.summary.taskId : 0;
    const groupId = data && data.summary ? data.summary.groupId : 0;
    const items = data && data.items ? data.items : null;
    const actionStatusGATD =
      data && data.summary ? data.summary.actionStatusGATD : null;
    const actionDateGATD =
      data && data.summary ? data.summary.actionDateGATD : null;
    // (Activity Task Data = ATD)
    const actionStatusATD = console.log("items35=", items);
    // const selectedIds = (data && data.items) ? data.dataIdList.filter(id => taskDataById[id].selected) : [];
    const selectedIds = [];
    const impact = data && data.summary ? data.summary.impact : null;
    console.log("isTdLoading=", isTdLoading);

    //===============================================
    var dateStringMili = new Date(actionDateGATD);

    // var dateStringFormat = dateStringMili.toLocaleDateString("es-CL");
    var dateStringFormat = dateStringMili.toLocaleDateString();
    var dateStringFormatTime = dateStringMili.toLocaleTimeString();

    //===============================================

    ///======================================
    // Put consoles here and check whether we are getting task related data or not in this page
    ///======================================
    let headerRenderer =
      _.first(this.headerRenderers["TaskType"]) ||
      ((name) => <IconAndName name={name} />);

    return (
      <Fragment>
        {isUserDataLoading ? (
          <Grid container style={{ height: "100%" }}>
            <Grid item>
              <CircularProgress color={"secondary"} />
            </Grid>
          </Grid>
        ) : (
          <div>
            {isTdLoading == true ? (
              <Grid
                container
                justifyContent={"center"}
                style={{ height: "100%" }}
              >
                <Grid item>
                  <CircularProgress color={"secondary"} />
                </Grid>
              </Grid>
            ) : (
              <div>
                <TaskDataAppBar
                  open={isDrawerOpen}
                  onClick={() => history.goBack()}
                  title={title}
                  selectedIds={selectedIds}
                  onClick1
                  impact={impact}
                  kpiType={kpiType}
                  currency={currency}
                  taskTypeId={groupId}
                  selectedImpact={sumUpImpactOfIds(selectedIds)}
                  width={width}
                  isTaskExecutionLoading={isTaskExecutionLoading}
                  actionStatus={actionStatusGATD}
                  actionStatusDate={actionDateGATD}
                />
           
                <br />
                <br />
                <br /> 
                <br />
                <br />
                <div className={classes.desSection}>
                  <Paper className={classes.TDes}>
                    <Grid
                      container
                      alignItems={
                        width == "sm" || width == "xs" ? "flex-start" : "center"
                      }
                    >
                      <Grid item sm={1} xs={1} md={1}>
                        <Typography align={"center"}>
                          {width == "sm" || width == "xs"
                            ? icons.lightTaskResizable(40)
                            : icons.lightTaskResizable(60)}
                          &nbsp;
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        sm={11}
                        xs={11}
                        md={11}
                        className={classes.des}
                      >
                        <Typography variant="h6" color="inherit" noWrap>
                          What does This Task Do?
                        </Typography>
                        <Typography
                          variant={"body1"}
                          className={classes.textDes}
                        >
                          {description}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                  <br />
                </div>
                {this.state.taskTypeId == 49 && (
                  <CustomPaper classes={classes}>
                    <ShoppingHierarchy taskId={taskId} isActivityReport />
                  </CustomPaper>
                )}
                {this.state.taskTypeId == 54 && (
                  <CustomPaper classes={classes}>
                    {!_.isEmpty(dataIdList) && (
                      <KeywordHierarchy
                        summary={summary}
                        data={data}
                        dataIdList={dataIdList}
                        isActivityReport
                      />
                    )}
                  </CustomPaper>
                )}
                {this.state.taskTypeId != null &&
                  this.state.taskTypeId != 49 &&
                  this.state.taskTypeId != 54 &&
                  !_.isEmpty(taskDataById) &&
                  getRenderer(this.state.taskTypeId)(
                    classes,
                    description,
                    items,
                    headerRenderer,
                    selectionAlFlag,
                    toggleSelection,
                    taskDataById,
                    this.state.taskTypeId,
                    this.onSelectClearAll,
                    dataIdList,
                    isActivityReport
                  )}
              </div>
            )}
          </div>
        )}
        <MySnackbarWrapper
          open={this.state.alert}
          onClose={this.handleCloseAlert}
          message={this.state.message}
          verticalAlign={"bottom"}
          horizontalAlign={"right"}
          duration={6000}
          variant={this.state.variantType}
        />
      </Fragment>
    );
  }
}

ActivityTaskData.propTypes = {};
const mapStateToProps = (state, ownprops) => ({
  data: getData(
    state,
    ownprops.match.params.taskId,
    ownprops.match.params.taskTypeId
  ),
  taskDataById: state.taskDataActivityById,
  isDrawerOpen: state.header.open,
  kpiType: state.perf.kpiType,
  currency: state.perf.currency,
  activityReport: state.activityReport,
  selectedAccountInfo: state.userInformation.selectedAccountInfo,
  isUserDataLoading: state.userInformation.isLoading,
  campaignIdMap: state.campaignIdActivityReportMap,
  // toggleSelection: (taskIds, selected) => dispatch(toggleSelection(taskIds, selected)),
});
const mapDispatchToProps = (dispatch) => ({
  fetchTaskDataById: (taskId, taskTypeId, analysisTypeId) =>
    dispatch(fetchTaskDataByTaskId(taskId, taskTypeId, analysisTypeId)),
  fetchActivityReport: (historyId, isPpIdChanged) =>
    dispatch(fetchActivityReport(historyId, isPpIdChanged)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withWidth()(withStyles(styles)(ActivityTaskData)));
