import { Grid, Paper } from "@mui/material/index";
import Typography from "@mui/material/Typography";
import DateRangeSelector from "../components/dashboard/date-range-selector";
import CampaignSnapshot from "./campaign-snapshot";
import icons from "../components/icons";
import CurrentMonthPerformance from "./currentMonthPerformance";
import KPIGraph from "./kpi-graph";
import PendingTasks from "./pendingtasks";
import React, { useEffect, useState } from "react";
import { IconAndText } from "./dashboard";
import { Link } from "react-router-dom";
import * as types from "../actions/actionTypes";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import holidayPackage from "./holidayPackage/holidayPackage";
import AnalyticsBanner from "./graphComponents/googleAnalyticsBanner";
import * as _ from "lodash";

function Overview(props) {
  const {
    martechServices,
    openMartech,
    width,
    selectedDateRange,
    onSelectDateRange,
    displayDateRange,
    customDateRanges,
    fixedPerfHeightPaper,
    fixedGraphHeightPaper,
    classes,
    selectedGraphDateRange,
    onSelectGraphDateRange,
    displayGraphDateRange,
    customGraphDateRanges,
    kpiHistory,
    kpiType,
    isLoading,
    kpiTypeUi,
    match,
    currencySymbol,
    history,
    selectedDateRangeKPIValue,
    onChaneKPIGraphValue,
    userInformation,
    closeGoogleAnalyticsBanner,
    googleAnalyticsBannerServiceReducer,
  } = props;

  console.log("props at overview js-->", props);
  // for holidayPackage
  const [count, setCount] = useState(0);

  // below is for holidayPackage banner
  // useEffect(() => {
  //   // if (count === 0) {
  //     // Update the document title using the browser API
  //     console.log("count=======>", count);
  //     if (!martechServices.isShowed) {
  //       openMartech();
  //     }
  //     // setCount((count) => count + 1);
  //   // }
  // }, []);

  const isAnalyticsAccountsCheck = _.isEmpty(
    userInformation.analyticsAccountInfo
  )
    ? true
    : false;
  const [isAnalyticsAccounts, setIsAnalyticsAccounts] = React.useState(
    googleAnalyticsBannerServiceReducer.isGoogleAnalyticsBanner
  );

  const handleChange = async (history) => {
    await props.updateViewValue(1);
    ("/tasks/");
  };
  return (
    <div >
      <Grid
        container
        spacing={2}
        alignItems={"center"}
        justifyContent={
          width == "sm" || width == "xs" ? "space-between" : "flex-start"
        }
      >
        <Grid item id={"snapshot"}>
          <Typography
            align={"left"}
            variant={"h3"}
            component={"h3"}
            color={"textPrimary"}
            gutterBottom
          >
            <b>Campaign Snapshot</b>
          </Typography>
        </Grid>
        <Grid item>
          <DateRangeSelector
            value={selectedDateRange}
            onChange={onSelectDateRange}
            range={displayDateRange}
            customDateRanges={customDateRanges}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8} lg={9}>
          <CampaignSnapshot {...props} />
        </Grid>
        <Grid item xs={12} md={4} lg={3} id={"performance"}>
          <Paper className={fixedPerfHeightPaper}>
            <IconAndText
              icon={icons.performance}
              text={"Performance"}
              caption
            />
            <CurrentMonthPerformance {...props} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={6} id={"user-KPI"}>
          <KPIGraph
            className={fixedGraphHeightPaper}
            classes={classes}
            value1={selectedGraphDateRange}
            onChange1={onSelectGraphDateRange}
            range={displayGraphDateRange}
            customGraphDateRanges={customGraphDateRanges}
            kpiHistory={kpiHistory}
            kpiType={kpiType}
            loading={isLoading}
            kpiTypeUi={kpiTypeUi}
            selectedDateRangeKPIValue={selectedDateRangeKPIValue}
            onChaneKPIGraphValue={onChaneKPIGraphValue}
            dateRanges
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6} id={"pending-tasks"}>
          <Paper className={fixedGraphHeightPaper}>
            <Grid container justifyContent={"space-between"}>
              <Grid item>
                <IconAndText icon={icons.pendingTask} text={"Pending Tasks"} />
              </Grid>
              <Grid item>
                <Link
                  to={`/tasks/`}
                  onClick={() => handleChange(history)}
                  className={classes.link}
                >
                  <Typography variant={"body1"} color={"secondary"}>
                    View All
                  </Typography>
                </Link>
              </Grid>
            </Grid>
            <PendingTasks classes={classes} />
          </Paper>
        </Grid>
      </Grid>
      {/* googleAnalytics banner code */}
      {/* {isAnalyticsAccountsCheck && isAnalyticsAccounts? (
        <AnalyticsBanner
          contenttext1="LXRGuide has a new Analytics feature! Connect your Google account now to get started."
          buttontext1="connect now"
        />
      ) : null} */}
        <AnalyticsBanner
          contenttext1="Boost your website's performance with LXRGuide's new Website Health Scorecard. Get your score and optimize today!"
          buttontext1="Click here"
        />
    </div>
  );
}

const mapStateToProps = (state) => ({
  googleAnalyticsBannerServiceReducer:
    state.googleAnalyticsBannerServiceReducer,
});
const mapDispatchToProps = (dispatch) => ({
  updateViewValue: (viewValue) => {
    return dispatch({
      type: types.DASHBOARD_VIEW_VALUE_UPDATED,
      viewValue: viewValue,
    });
  },
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Overview)
);
