import React, { useEffect } from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material/index";
import { withStyles } from "@mui/styles";
import icons from "../../components/icons";
import {
  OrangeTypography,
  TaglineForHeadline,
} from "../../components/typography";
import requestDemoStyles from "../../assets/jss/requestDemo/requestDemoStyles";
import RequestDemoForm from "../../components/requestDemo/request-demo-form";
import * as PropTypes from "prop-types";
import useMediaQuery from "@mui/material/useMediaQuery";
import { connect } from "react-redux";
import { REQUEST_DEMO_TRACK } from "../../actions/actionTypes";

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="xl" />;

function MainImage(props) {
  const isTabletResponsive = useMediaQuery(
    props.theme.breakpoints.between("sm", "xl")
  );
  return (
    <Typography align={"center"} gutterBottom>
      {props.width == "sm" || props.width == "xs"
        ? isTabletResponsive
          ? icons.requestDemo("auto")
          : icons.requestDemo("100%")
        : icons.requestDemo("auto")}
    </Typography>
  );
}

MainImage.propTypes = { width: PropTypes.any };

function RequestDemo(props) {
  const { classes, width, theme } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
    props.requestDemoPage();
  }, []);

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent} style={{ paddingBottom: 0 }}>
        <Grid container>
          <Grid item sm={12} xs={12} md={12}>
            <Grid container>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                className={[classes.sectionBg, classes.gridBg]}
              >
                <Grid container justifyContent={"center"}>
                  <Grid item sm={11} xs={11} md={12}>
                    <MainImage width={width} theme={theme} />
                  </Grid>
                </Grid>
                <br />
                <Typography
                  className={classes.heading}
                  // align={"center"}
                  // variant={width == "sm" || width == "xs" ? "h3" : "subtitle1"}
                  // color={"textPrimary"}
                  // style={{ fontWeight: "700", textTransform: "uppercase" }}
                  gutterBottom
                >
                  <b>REQUEST A FREE DEMO</b>
                </Typography>
                <br />
                <TaglineForHeadline
                  variant="caption"
                  color="textPrimary"
                  display="flex"
                  align="center"
                  className={classes.mainCaption}
                >
                  You might find the true brilliance of LXRGuide is its
                  simplicity. Designed with the help of our Paid Search
                  Managers, we knew there had to be a system that worked for
                  everyone, not just the experts.
                </TaglineForHeadline>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12} className={classes.section2}>
            <Grid container spacing={2} justifyContent={"space-around"}>
              <Grid item sm={12} xs={12} md={6}>
                <RequestDemoForm />
              </Grid>
              <Grid item sm={10} xs={10} md={6}>
                {icons.requestPreview}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

RequestDemo.propTypes = {};
const mapDispatchToProps = (dispatch) => ({
  requestDemoPage: () => dispatch({ type: REQUEST_DEMO_TRACK }),
});
export default connect(
  null,
  mapDispatchToProps
)(withWidth()(withStyles(requestDemoStyles, { withTheme: true })(RequestDemo)));
