import React, {Component} from 'react';
import PropTypes from 'prop-types';
import icons from "./icons";
import { Snackbar } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import SnackbarContent from "@mui/material/SnackbarContent";

const CustomSnackbarContentSuccess = withStyles(theme => ({
    root: {
        backgroundColor: theme.palette.success,
        fontWeight: "bold"
    },
}))(SnackbarContent);
const CustomSnackbarContentError = withStyles(theme => ({
    root: {
        backgroundColor: theme.palette.unSuccess,
        fontWeight: "bold"
    },
}))(SnackbarContent);
const CustomSnackbarContentWarning = withStyles(theme => ({
    root: {
        backgroundColor: theme.palette.warning,
        fontWeight: "bold"
    },
}))(SnackbarContent);

export function typeColor(type) {
    switch (type) {
        case "success":
            return CustomSnackbarContentSuccess;
        case "warning":
            return CustomSnackbarContentWarning;
        /* case "information":
             return icons.All;*/
        case "error":
            return CustomSnackbarContentError;
        default:
            return CustomSnackbarContentError;
    }
}

class SnackBar extends React.PureComponent {
    render() {
        const {alert, handleAlertClose, message, colorType} = this.props;
        const SnackbarContent = typeColor(colorType);

        return (
            <Snackbar
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                open={alert}
                onClose={handleAlertClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                autoHideDuration={6000}
            >
                <SnackbarContent
                    onClose={handleAlertClose}
                    variant="success"
                    message={<span className={"flex alignItemsCenter"}>
                            <icons.CheckCircle/>
                        &nbsp;{message}
                        </span>}
                />
            </Snackbar>
        );
    }
}

SnackBar.propTypes = {};

export default SnackBar;
