import React from "react";
import { Select, MenuItem } from "@mui/material";
import { Typography, Grid, TextField, Button } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { daysCount } from "../../selectors/dashboard-selectors";
import ReactDOM from "react-dom";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { NativeSelect } from "@mui/material/index";

let moment = require("moment");
const ErrorTypography = withStyles((theme) => ({
  root: {
    color: theme.palette.textError,
    paddingLeft: 16,
    paddingRight: 16,
  },
}))(Typography);



class DateRangeSelector extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fromDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
      toDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
      fromDateError: "",
      toDateError: "",
      customDate: false,
      open:false
    };
  }

  handleInput = (event) => {
    event.preventDefault();
    this.setState({
      [event.target.name]: event.target.value,
    });
    // if (event.target.value == "") {
    //     this.setState({dateError: "Improper date Selected"});
    // }
    if (event.target.name == "fromDate" && event.target.value == "") {
      this.setState({ fromDateError: "Improper date Selected" });
    } else if (
      event.target.name == "fromDate" &&
      moment(event.target.value) > moment(this.state.toDate)
    ) {
      this.setState({ fromDateError: "From Date is greater than To Date" });
    } else {
      this.setState({ fromDateError: "" });
    }
    if (event.target.name == "toDate" && event.target.value == "") {
      this.setState({ toDateError: "Improper date Selected" });
    } else if (
      event.target.name == "toDate" &&
      moment(event.target.value) < moment(this.state.fromDate)
    ) {
      this.setState({ toDateError: "To Date is less than From Date" });
      //    in case of equal string comparison is happening
    } else if (
      event.target.name == "toDate" &&
      moment(event.target.value).format("YYYY-MM-DD") >=
        moment().format("YYYY-MM-DD")
    ) {
      this.setState({ toDateError: "To Date should be less than today" });
    } else {
      this.setState({ toDateError: "" });
    }
  };
  handleSubmit = async (event) => {
    console.log("Inside the handleSubmit");
    event.preventDefault();

    if (this.state.fromDate != "" && this.state.toDate != "") {
      await this.setState({ customDate: true, itemValue: 100 });
      await this.props.customDateRanges(this.state);
    }
    this.setState({open:false})
  };

  render() {
    const { classes, ...rest } = this.props;
    const { fromDate, toDate } = this.state;
    let displayDateString = (value) => {
      return "Last " + daysCount(value) + " Days";
    };
    console.log("select open",open)

    const handlePickers = (event) => {
      const goNode = ReactDOM.findDOMNode(this.goRef);
      console.log("handlePickers", goNode.contains(event.target));
      if (!goNode.contains(event.target)) return event.stopPropagation();
    };
    const appendSelectedDatesToCustomInputs = () => {
      const { toDateError, fromDateError } = this.state;
      if (this.props.range.dateFrom != "" || this.props.range.dateTo != "") {
        this.setState({
          fromDate: moment(this.props.range.dateFrom).format("YYYY-MM-DD"),
          toDate: moment(this.props.range.dateTo).format("YYYY-MM-DD"),
          itemValue: 100,
        });
        if (toDateError != "" || fromDateError != "") {
          this.setState({ toDateError: "", fromDateError: "" });
        }
      }
    };
    const handleOnClick = async (event) => {
      if (this.state.customDate == true && this.props.onChange)
        this.setState({ customDate: false });
      if (event.target.value) {
        this.props.onChange(event);
      }
    };
    const handleOnChange=(e)=>{
      handleOnClick(e);
      this.setState({ open: false })
    }
    return (
      <CustomSelect
        disableUnderline
        value={this.props.value.itemValue}
        onChange={(event) => handleOnChange(event)}
        onOpen={(event)=>this.setState({ open: true })}
        onClose={(event)=>this.setState({ open: false })}
        open={this.state.open}
        IconComponent={ExpandMore}
        className={classes.select}
      
        renderValue={() => {
          return (
            <Grid
              style={{zoom:"90%"}}
              container
              spacing={2}
              alignItems={"center"}
              onClick={appendSelectedDatesToCustomInputs}
            >
              {this.state.customDate != true ? (
                <Grid item>
                  <Typography
                    variant={"body1"}
                    color={"textPrimary"}
                    style={{ fontWeight: "500", fontSize: "1rem" }}
                  >
                    {displayDateString(this.props.value)}
                  </Typography>
                </Grid>
              ) : (
                <Grid item>
                  <Typography variant={"body1"}>
                    &nbsp;&nbsp;{this.props.range.dateFrom} &nbsp;&nbsp; -
                    &nbsp;&nbsp; {this.props.range.dateTo}
                  </Typography>
                </Grid>
              )}
            </Grid>
          );
        }}
      >
        <MenuItem value={6}>Last 7 Days</MenuItem>
        <MenuItem value={14}>Last 15 Days</MenuItem>
        <MenuItem value={29}>Last 30 Days</MenuItem>
        <MenuItem value={100} disabled>
          Custom
        </MenuItem>
        <div style={{ outline: "unset" }}>
          <ErrorTypography component={"span"}>
            {this.state.dateError}
          </ErrorTypography>
          <form onSubmit={this.handleSubmit} id="dateRangeForm">
            <GridContainer
              container
              onClick={handlePickers}
              spacing={2}
              style={{ outline: "unset" }}
            >
              <Grid item md={6} style={{paddingTop:0}}>
                <TextField

                  label="From"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={fromDate}
                  onChange={this.handleInput}
                  name={"fromDate"}
                  error={this.state.fromDateError != ""}
                  helperText={this.state.fromDateError}
                  disabled={this.state.toDateError != ""}
                />
              </Grid>
              <Grid item md={6} style={{paddingTop:0}}>
                <TextField
                  label="To"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={toDate}
                  error={this.state.toDateError != ""}
                  helperText={this.state.toDateError}
                  onChange={this.handleInput}
                  name={"toDate"}
                  disabled={this.state.fromDateError != ""}
                />
              </Grid>
              <Grid item md={12} style={{ textAlign: "right" }}>
                <Button
                  type="submit"
                  form="dateRangeForm"
                  variant="contained"
                  color="primary"
                  disabled={
                    this.state.fromDateError != "" ||
                    this.state.toDateError != ""
                  }
                  ref={(goRef) => (this.goRef = goRef)}
                >
                  Go
                </Button>
              </Grid>
            </GridContainer>
          </form>
        </div>
      </CustomSelect>
    );
  }
}

const CustomSelect = withStyles((theme) => ({
  icon: {
    color: theme.palette.text.primary,
  },
}))(Select);
const style = (theme) => ({
  select: {
    // backgroundColor: "rgba(30, 51, 78, 0.1)",
    "& div": {
      paddingTop: 1,
      paddingBottom: 2,
      paddingLeft: 2,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
});

const LightText = withStyles((theme) => ({
  root: {
    fontWeight: "lighter",
    color: theme.palette.text.primary,
  },
}))(Typography);

const RegularText = withStyles((theme) => ({
  color: theme.palette.text.primary,
}))(Typography);

const GridContainer = withStyles((theme) => ({
  container: {
    paddingRight: 16,
    paddingLeft: 16,
    paddingTop: 12,
    paddingBottom: 12,
  },
}))(Grid);

export default withStyles(style)(DateRangeSelector);
