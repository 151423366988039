import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Grid, Typography } from "@mui/material";
import { fetchHealthScorecardData, fetchWHSExpertData } from "../../actions";
import WhsErrorPage from "../WebsiteHealthScorecard/whsErrorPage";
import icons from "../../components/icons";
import ParameterSection_WHC from "../WebsiteHealthScorecard/parameterSection_WHC";
import "../WebsiteHealthScorecard/WebsiteHealthScoreCard.css";
import { MetaTags } from "react-meta-tags";
import { WHSDescription } from "../../components/typography";
import OverallScore_WHC from "../WebsiteHealthScorecard/overallScore_WHC";
import CircularProgress from "@mui/material/CircularProgress";
import ScrollToTop from "react-scroll-to-top";
import { withStyles } from "@mui/styles";
const styles = (theme) => ({
  mainDiv: {
    [theme.breakpoints.up("md")]: {
      padding: "35px 50px 30px 50px",
      backgroundColor: "#F9FAFB",
    },
    [theme.breakpoints.down("md")]: {
      padding: "30px 15px 15px 15px",
      backgroundColor: "#F9FAFB",
    },
  },
  scrollButton: {
    backgroundColor:"#F58120"
  },
  popover: {
    borderRadius: "10px",
  },
  customDialogTitle: {
    fontSize: "18px",
  },
});

function WhsExternalData(props) {
  const {
    websiteHealthScoreDataReducers,
    fetchHealthScorecardData,
    // externalUserId,
    fetchWHSExpertData,
    classes,
    history,
    location,
  } = props;

  const userType = 1;
  const [externalUserId, setExternalUserId] = useState(
    sessionStorage.getItem("WHSExternalId")
  );
  console.log("external user id in whsdata", externalUserId);
  useEffect(() => {
    if (externalUserId) {
      fetchHealthScorecardData(externalUserId);
      fetchWHSExpertData(externalUserId, userType);

      // Remove it from sessionStorage after using it
      // sessionStorage.removeItem('WHSExternalId');
    }
  }, [externalUserId]);

  const newDiscoverability = websiteHealthScoreDataReducers.discoverability;

  const newPerformance = websiteHealthScoreDataReducers.performance;

  const newAccessabilityData = websiteHealthScoreDataReducers.accessibility;

  const newSecurityData = websiteHealthScoreDataReducers.security;

  const lastUpdatedDate = websiteHealthScoreDataReducers.lastUpdatedDate;

  const clientWebsiteUrl = websiteHealthScoreDataReducers.clientUrl;

  const clientWebsiteUrlWithHttps =
    clientWebsiteUrl && clientWebsiteUrl.startsWith("https://");

  const [sectionWithLowestScore, setSectionWithLowestScore] = useState();

  useEffect(() => {
    if (
      websiteHealthScoreDataReducers &&
      websiteHealthScoreDataReducers["message"] ===
        "FETCH_HEALTH_SCORECARD_DATA_RECEIVED"
    ) {
      const scores = {
        discoverability: newDiscoverability
          ? newDiscoverability.discoverabilityScore
          : 0,
        performance: newPerformance ? newPerformance.performanceScore : 0,
        accessibility: newAccessabilityData
          ? newAccessabilityData.accessibilityScore
          : 0,
        security: newSecurityData ? newSecurityData.securityScore : 0,
      };

      const sectionWithLowestScore = Object.entries(scores).reduce((a, b) =>
        a[1] < b[1] ? a : b
      )[0];

      setSectionWithLowestScore(sectionWithLowestScore);
    }
  }, [websiteHealthScoreDataReducers]);
  
  const [emptyDataCheck, setEmptyDataCheck] = useState(false);

  const targetDate = new Date(lastUpdatedDate);
  const oneDayInMilliseconds = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
  const currentDate = new Date();
  const isOneDayAhead = currentDate - targetDate >= oneDayInMilliseconds;

  const formattedDateTime = targetDate.toLocaleString("en-US", {
    weekday: "long",
    month: "long",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  useEffect(() => {
    if (Object.keys(websiteHealthScoreDataReducers).length > 1){
      if(newDiscoverability != "undefined" ||
      newPerformance != "undefined" ||
      newAccessabilityData != "undefined" ||
      newSecurityData != "undefined"
    )   {
      setEmptyDataCheck(false);
    } else {
      setEmptyDataCheck(true);
    }
    }else{
      setEmptyDataCheck(true);
    }
  }, [websiteHealthScoreDataReducers["message"] ===
  "FETCH_HEALTH_SCORECARD_DATA_RECEIVED"]);

  window.scrollTo(0, 0);

  console.log(
    "Empty data check ===>",
    newDiscoverability,
    newPerformance,
    newAccessabilityData,
    newSecurityData
  );

  console.log(
    "scorecard condition",
    emptyDataCheck,
    Object.keys(websiteHealthScoreDataReducers).length > 1
  );
  const progressBarColor = `linear-gradient(90deg, #FFBD15 0%, rgba(132, 205, 1, 0.46) 100%)`;
  return (
    <div>
      {websiteHealthScoreDataReducers["message"] ===
        "FETCH_HEALTH_SCORECARD_DATA_FAILED" || emptyDataCheck === true ? (
        ""
      ) : (
        <Grid container justify="center" alignItems="center">
          <Grid
            item
            style={{
              padding: "30px 20px 20px 20px",
              textAlign: "center",
            }}
          >
            <WHSDescription>
              {icons.WHSDescription} Unlock your site's potential with our 4D
              Scorecard: Discoverability, Performance, Accessibility, and
              Security at a glance!
            </WHSDescription>
          </Grid>
        </Grid>
      )}
      <div className={classes.mainDiv}>
        <MetaTags>
          <title> LXRGuide - Website Health Scorecard </title>
        </MetaTags>
        {
        websiteHealthScoreDataReducers["message"] ===
          "FETCH_HEALTH_SCORECARD_DATA_FAILED" || (websiteHealthScoreDataReducers["message"] ===
          "FETCH_HEALTH_SCORECARD_DATA_RECEIVED" && emptyDataCheck) ? (
          <Grid container>
            <Grid item>
              <div>
                <WhsErrorPage />
              </div>
            </Grid>
          </Grid>
        ) : websiteHealthScoreDataReducers["message"] ===
            "FETCH_HEALTH_SCORECARD_DATA_RECEIVED" && !emptyDataCheck ? 
            (
          <Grid container direction="column">
            <Grid item xs={12}>
              <Grid container direction="row">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  style={{
                    textAlign: "left",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h3" style={{ color: "#6D6D6D" }}>
                    <b>Website URL:</b> {clientWebsiteUrl} &nbsp;
                    {clientWebsiteUrlWithHttps == true ? (
                      <a
                        href={clientWebsiteUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {icons.WHSExternalLink}
                      </a>
                    ) : (
                      <a
                        href={"https://" + clientWebsiteUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {icons.WHSExternalLink}
                      </a>
                    )}
                    <br />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <OverallScore_WHC
                websiteHealthScoreDataReducers={websiteHealthScoreDataReducers}
                sectionWithLowestScore={sectionWithLowestScore}
                externalUserId={externalUserId}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <ParameterSection_WHC
                discoverabilityData={newDiscoverability}
                performanceData={newPerformance}
                accessibilityData={newAccessabilityData}
                securityData={newSecurityData}
              />
              <ScrollToTop color="white" smooth className={classes.scrollButton}/>
            </Grid>
          </Grid>
        ) : (
          <Typography align="center" style={{ marginTop: "2.125rem" }}>
            <CircularProgress color={"secondary"} />
          </Typography>
        )
        }
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  websiteHealthScoreDataReducers: state.websiteHealthScoreDataReducers,
});

const mapDispatchToProps = (dispatch) => ({
  fetchHealthScorecardData: (externalUserId) =>
    dispatch(fetchHealthScorecardData(externalUserId)),
  fetchWHSExpertData: (userId, userType) =>
    dispatch(fetchWHSExpertData(userId, userType)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(WhsExternalData));
