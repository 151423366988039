import * as types from "../actions/actionTypes";

export function jumpstart(state = {
    isLoading: false,
    statusMessage: "",
    isObfFillStatus: false,
    remainingDays: 0,
    authorizationUrl: null,
    userStatus: ""
}, action) {
    switch (action.type) {
        case types.USER_INFORMATION_RECEIVED:
            return Object.assign({}, state, {
                isLoading: false,
                isObfFillStatus: action.data.isObfFillStatus,
                remainingDays: action.data.remainingDays,
                userStatus: action.data.userStatus,
                authorizationUrl: action.data.authorizationUrl,
                statusMessage: action.data.statusMessage,
            });
        case types.UPDATE_JS_ACCOUNT_REQUESTED:
            return Object.assign({}, state, {
                isLoading: true,
            });
        case types.UPDATE_JS_ACCOUNT_RECEIVED:
            return Object.assign({}, state, {
                isLoading: false,
                userStatus: types.CLIENT_APPROVED,
            });
        case types.UPDATE_JS_ACCOUNT_RECEIVED:
            const isError = action.data.isError;
            return Object.assign({}, state, {
                isLoading: false,
                userStatus: !isError ? action.data.userStatus : state.userStatus,
            });
        default:
            return state;
    }
}