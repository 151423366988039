import React, { Component } from "react";
import PackagesSection from "../components/internalSiteGrader/PackagesSection";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import SeoStepper from "../components/SeoServices/seo-stepper";
import { updateSelectedSEOPackage } from "../actions";
import { Card, CardContent, CircularProgress } from "@mui/material";
import withStyles from '@mui/styles/withStyles';

function defaultRouteSwitch(siteGraderServiceDetails, domainUrl, emailId) {
  if (
    siteGraderServiceDetails &&
    siteGraderServiceDetails.selectedPackage != null &&
    siteGraderServiceDetails.selectedPackage > 0
  ) {
    return (
      <Redirect
        push
        to={`/seo-sign-up/${domainUrl}/${siteGraderServiceDetails.selectedPackage}`}
      />
    );
  } else {
    return <PackagesSection domainUrl={domainUrl} />;
  }
}

class SeoServicePage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    this.setState({ isLoading: true });
    await this.props.updateSelectedPackage(null);
    this.setState({ isLoading: false });
  }

  render() {
    const { siteGraderServiceDetails, isLoading, classes } = this.props;
    console.log("siteGraderServiceDetails= ", siteGraderServiceDetails);
    return (
      <Card className={classes.card}>
        <CardContent className={"fullWidth"}>
          {isLoading == true ? (
            <CircularProgress />
          ) : (
            <div>
              <SeoStepper margin />
              {defaultRouteSwitch(
                siteGraderServiceDetails,
                this.props.match.params.domainUrl,
                this.props.match.params.emailId
              )}
            </div>
          )}
        </CardContent>
      </Card>
    );
  }
}

const styles = (theme) => ({
  card: {
    boxShadow: "unset",
    background: theme.palette.errorBg,
    width: "100%",
    paddingTop: theme.spacing(2),
  },
});
SeoServicePage.propTypes = {};
const mapStateToProps = (state) => ({
  siteGraderServiceDetails: state.siteGraderServiceDetails,
});
const mapDispatchToProps = (dispatch) => ({
  updateSelectedPackage: (packageId) => {
    return dispatch(updateSelectedSEOPackage(packageId));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SeoServicePage));
