import React from 'react';
/*
interface IErrorBoundaryProps {
    readonly children: JSX.Element | JSX.Element[];
}

IErrorBoundaryState {
    readonly error: any;
    readonly errorInfo: any;
}*/

// class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
class ErrorBoundary extends React.Component {
    // readonly state: IErrorBoundaryState = { error: undefined, errorInfo: undefined };
    constructor(props) {
        super();
        this.state= { error: undefined, errorInfo: undefined }

    }
     componentDidMount() {
     const {errorInfo} = this.state;
     const isDevEnv = process.env.NODE_ENV === 'development';
             if (!isDevEnv && errorInfo) window.location = "/logout.html";
     }

    componentDidCatch(error, errorInfo) {
        this.setState({
            error,
            errorInfo,
        });
    }

    render() {
        const { error, errorInfo } = this.state;
        if (errorInfo) {
            const errorDetails =
                process.env.NODE_ENV === 'development' ? (
                    <details className="preserve-space">
                        {error && error.toString()}
                        <br />
                        {errorInfo.componentStack}
                    </details>
                ) : undefined;
            return (
                <div>
                    <h2 className="error">An unexpected error has occurred. Please refresh the page.</h2>
                    {errorDetails}
                </div>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
