import React, {Fragment} from 'react';
import Overview from '../components/grader/overview';
import OpportunityScore from "../components/grader/opportunityScore"
import {getGoogleAccountPerformance, sendGraderMail} from '../actions';
import {connect} from "react-redux";
import AccountTracking from "../components/grader/accountTracking";
import LxrGuideInAction from "../components/grader/lxrGuideInAction";
import Integration from "../components/grader/integration";
import CircularProgress from '@mui/material/CircularProgress';
import { Grid, Typography, Card, CardContent, CardActionArea, Button } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import GraderComponents from "../components/grader/garder-components";
import Footer from "../components/footer";


const CustomTypography = withStyles(theme => ({
    root: {
        color: theme.palette.graderGraphColors.text,
    }
}))(Typography);

class GraderPage extends React.PureComponent {

    render() {
        const {integrationProgressBar, impressionsGraphData, graderData} = this.props;
        return (
            <Fragment>
                {graderData.graderError != null ? <CustomTypography align={"center"} variant={"h5"} component={"h5"}>
                        <b>{graderData.graderError}</b>
                    </CustomTypography>
                    :
                    <div>
                            <Card>
                                <CardContent style={{padding: 0}}>
                                {(integrationProgressBar != 80 && integrationProgressBar < 80) ? <Integration/> :
                                    <Fragment>
                                        <div>
                                            <GraderComponents/>
                                        </div>
                                    </Fragment>
                                }
                                {/*<Footer/>*/}
                            </CardContent>
                        </Card>


                    </div>
                }
            </Fragment>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    getGoogleAccountPerformance: (graderReportId) => dispatch(getGoogleAccountPerformance(graderReportId)),
});
const mapStateToProps = state => ({
    graderData: state.googleAdsPerformance,
    integrationProgressBar: state.googleAdsPerformance.integrationProgressBar,
    impressionsGraphData: state.googleAdsPerformance.googleAdsReportData ? state.googleAdsPerformance.googleAdsReportData.accImpDataMap : null
});

export default connect(mapStateToProps, mapDispatchToProps)(GraderPage);
