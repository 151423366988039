import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { generateAdCopy, fetchAdCopy, saveAdCopy } from "../../actions";
import { NewOrangeContainedBtn } from "../../components/buttons";
import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { withStyles } from "@mui/styles";
import icons from "../../components/icons.js";
import styles from "./adcopyStyles";

const CustomTypography = withStyles((theme) => ({
  root: {
    fontWeight: "700",
    lineHeight: "2rem",
  },
}))(Typography);
const CustomTypographyHeading = withStyles((theme) => ({
  root: {
    fontSize: "1.25rem",
    fontWeight: "400",
    lineHeight: "2rem",
  },
}))(Typography);

function AdCopyGeneration(props) {
  console.log("Props in adcopy.jsx", props);
  const { onCheck, setSaveStatus, isActivityReport } = props;
  const classes = props.classes;
  const [statusMessage, setStatus] = useState("");
  const [brandName, setBrandName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [adCopy, setAdCopy] = useState({
    campaignId: "",
    adgroupId: "",
    headLine1: "",
    headLine2: "",
    headLine3: "",
    headLine4: "",
    headLine5: "",
    headLine6: "",
    headLine7: "",
    headLine8: "",
    headLine9: "",
    headLine10: "",
    headLine11: "",
    headLine12: "",
    headLine13: "",
    headLine14: "",
    headLine15: "",
    description1: "",
    description2: "",
    description3: "",
    description4: "",
    adCopyStatus: "",
    finalUrl: "",
    path1: "",
    path2: "",
    generateClicks: 0,
    taskDataId: 0,
  });
  console.log("adcopy in adcopy.jsx", adCopy);

  adCopy.taskDataId = props.taskDataId;

  const [headlines, setHeadlines] = useState(new Array(15));
  useEffect(() => {
    const updatedHeadlines = [];
    for (let i = 1; i <= 15; i++) {
      updatedHeadlines.push(adCopy[`headLine${i}`]);
    }

    setHeadlines(updatedHeadlines);
  }, [adCopy]);

  const [descriptions, setDescriptions] = useState(new Array(4).fill(""));

  useEffect(() => {
    const updatedDescriptions = [];
    for (let i = 1; i <= 4; i++) {
      updatedDescriptions.push(adCopy[`description${i}`]);
    }

    setDescriptions(updatedDescriptions);
  }, [adCopy]);

  const [paths, setPaths] = useState(new Array(2).fill(""));
  useEffect(() => {
    const updatedPaths = [];
    for (let i = 1; i <= 2; i++) {
      updatedPaths.push(adCopy[`path${i}`]);
    }

    setPaths(updatedPaths);
  }, [adCopy]);

  console.log("adCopy change:", adCopy);
  const campaign = props.campaignName;
  const adgroup = props.adgroupName;
  const campaignId = props.campaignId;
  const adgroupId = props.adgroupId;

  const [campaignName, setCampaignName] = useState(campaign);
  const [adgroupName, setAdgroupName] = useState(adgroup);
  const [loading, setLoading] = useState(false);

  const PENDING_STATUS = "Pending";
  const FAILED_STATUS = "Failed";
  const PUBLISHED_STATUS = "Published";
  const READY_TO_PUBLISH = "Ready to publish";
  const [readyToPublish, setReadyToPublish] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [generateBtnDisabled, setGenerateBtnDisabled] = useState(false);
  const [expanded, setExpanded] = useState(null);
  const handleChange = (panel) => (e, expanded) => {
    setExpanded(expanded ? panel : false);
    console.log("expanded panel", expanded);
  };

  const rsaBrandTooltip =
    " Describe your organization's unique selling proposition (USP), the problem it solves, and your brand's specialty. This will enable our generative AI to create highly relevant ads for your business.";
  const rsaDescTooltip =
    "Share details about your product by highlighting its unique features and how it sets itself apart from competitors. This will allow our generative AI to create compelling advertisements tailored to your business.";
  const rsaGenerateTooltip =
    "Our generative AI has created persuasive ad copy assets that will effectively engage potential customers. Please review and customize them according to your preferences before clicking the publish button. Clicking publish will submit your content live to Google Ads.";
  const rsaUrlTooltip =
    "Specify the landing page on your website that you would like to target as the final URL, which is the page people will be directed to when they click on your ad.";

  const handleBrandNameChange = (e) => {
    setBrandName(e.target.value);
    setIsSaving(true);
  };

  const handleProductDescriptionChange = (e) => {
    setProductDescription(e.target.value);
    setIsSaving(true);
  };

  const handleHeadlineChange = (value, index) => {
    validateUrl(adCopy.finalUrl);
    const newHeadlines = [...headlines];
    newHeadlines[index] = value;
    setAdCopy({
      ...adCopy,
      [`headLine${index + 1}`]: value,
    });
    setHeadlines(newHeadlines);
  };

  const handleDescriptionChange = (value, index) => {
    validateUrl(adCopy.finalUrl);
    const newDescriptions = [...descriptions];
    newDescriptions[index] = value;
    setAdCopy({
      ...adCopy,
      [`description${index + 1}`]: value,
    });
    setDescriptions(newDescriptions);
  };

  const handlePathChange = (value, index) => {
    validateUrl(adCopy.finalUrl);
    const newPaths = [...paths];
    newPaths[index] = value;
    setAdCopy({
      ...adCopy,
      [`path${index + 1}`]: value,
    });
    setPaths(newPaths);
  };

  const handleGenerateClick = async () => {
    setLoading(true);
    setIsSaving(true);
    const data = await props.generateAdCopy(brandName, productDescription);
    setLoading(false);
    if (data) {
      setAdCopy((prevAdCopy) => ({
        ...prevAdCopy,
        ...data,
        generateClicks: prevAdCopy.generateClicks + 1,
      }));
    }
    setIsSaving(false);
  };

  useEffect(() => {
    if (
      adCopy.generateClicks == 3 ||
      statusMessage == PUBLISHED_STATUS ||
      statusMessage == FAILED_STATUS ||
      isActivityReport == true
    ) {
      setGenerateBtnDisabled(true);
    } else if (adCopy.generateClicks > 0) {
      validateUrl(adCopy.finalUrl);
    }
  }, [statusMessage, adCopy]);

  useEffect(() => {
    async function fetchData() {
      const data = await props.fetchAdCopy(campaignId, adgroupId);
      console.log("fetchData function", data);
      if (data) {
        setAdCopy(data);
        setBrandName(data.brandName);
        setProductDescription(data.productDescription);
        setCampaignName(data.campaignName);
        setAdgroupName(data.adgroupName);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsSaving(true);
      props.saveAdCopy({
        ...adCopy,
        campaignName,
        adgroupName,
        brandName,
        productDescription,
        campaignId,
        adgroupId,
      });
    }, 3000);
    setIsSaving(false);
    return () => clearTimeout(timeoutId);
  }, [brandName, productDescription, adCopy]);

  useEffect(() => {
    let timeoutIdSaving = null;
    let timeoutIdSaved = null;

    if (isSaving) {
      if (!timeoutIdSaved) {
        if (setSaveStatus) {
          setSaveStatus("Saving...");
        }

        if (timeoutIdSaving) {
          clearTimeout(timeoutIdSaving);
        }

        timeoutIdSaving = setTimeout(() => {
          if (setSaveStatus) {
            setSaveStatus("Saved");
          }

          if (timeoutIdSaved) {
            clearTimeout(timeoutIdSaved);
          }

          timeoutIdSaved = setTimeout(() => {
            if (setSaveStatus) {
              setSaveStatus("Auto-saved");
            }
          }, 3000);
        }, 5000);
      }
    } else {
      if (timeoutIdSaving) {
        clearTimeout(timeoutIdSaving);
      }
      if (timeoutIdSaved) {
        clearTimeout(timeoutIdSaved);
      }
      if (setSaveStatus) {
        setSaveStatus("Auto-saved");
      }
    }

    return () => {
      clearTimeout(timeoutIdSaving);
      clearTimeout(timeoutIdSaved);
    };
  }, [isSaving, props.setSaveStatus]);

  const urlRegex =
    /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g;
  const isUrlValid = (url) => {
    return urlRegex.test(url);
  };

  useEffect(() => {
    const hasEnoughHeadlines =
      Object.keys(adCopy)
        .filter((key) => key.startsWith("headLine"))
        .filter((h) => adCopy[h] && adCopy[h].trim().length > 0).length >= 3;
    const areAllHeadlinesValid = Object.keys(adCopy)
      .filter((key) => key.startsWith("headLine"))
      .every((h) => (adCopy[h] || "").length <= 30);
    const hasEnoughDescriptions =
      Object.keys(adCopy)
        .filter((key) => key.startsWith("description"))
        .filter((h) => adCopy[h] && adCopy[h].trim().length > 0).length >= 2;
    const areAllDescriptionsValid = Object.keys(adCopy)
      .filter((key) => key.startsWith("description"))
      .every((h) => (adCopy[h] || "").length <= 90);
    const hasValidFinalUrl =
      adCopy.finalUrl &&
      adCopy.finalUrl.length > 0 &&
      isUrlValid(adCopy.finalUrl);
    const isHeadlineDuplicateExist =
      headlines
        .map((item) => item.trim())
        .filter(
          (item, index, arr) => item !== "" && arr.indexOf(item) !== index
        ).length > 0;
    const isDescriptionDuplicateExist =
      descriptions
        .map((item) => item.trim())
        .filter(
          (item, index, arr) => item !== "" && arr.indexOf(item) !== index
        ).length > 0;
    const isPathDuplicateExist =
      paths
        .map((item) => item.trim())
        .filter(
          (item, index, arr) => item !== "" && arr.indexOf(item) !== index
        ).length > 0;

    if (
      hasEnoughHeadlines &&
      areAllHeadlinesValid &&
      hasEnoughDescriptions &&
      areAllDescriptionsValid &&
      hasValidFinalUrl &&
      !isHeadlineDuplicateExist &&
      !isPathDuplicateExist &&
      !isDescriptionDuplicateExist
    ) {
      setReadyToPublish(READY_TO_PUBLISH);
    } else {
      setReadyToPublish(PENDING_STATUS);
    }
  }, [adCopy, readyToPublish]);

  useEffect(() => {
    if (props.taskStatus === 0 && readyToPublish === PENDING_STATUS) {
      setStatus(PENDING_STATUS);
      adCopy.adCopyStatus = PENDING_STATUS;
    } else if (props.taskStatus === 0 && readyToPublish === READY_TO_PUBLISH) {
      setStatus(READY_TO_PUBLISH);
      adCopy.adCopyStatus = READY_TO_PUBLISH;
    } else if (props.taskStatus === -1) {
      setStatus(FAILED_STATUS);
      adCopy.adCopyStatus = FAILED_STATUS;
    } else if (props.taskStatus === 1) {
      setStatus(PUBLISHED_STATUS);
      adCopy.adCopyStatus = PUBLISHED_STATUS;
    }
  }, [props.taskStatus, readyToPublish, statusMessage]);

  const [isChecked, setIsChecked] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const onCheckRef = useRef(onCheck);
  const handleCheckboxChange = (event) => {
    const { checked } = event.target;
    setIsChecked(checked);
    onCheckRef.current(checked, adCopy);
  };

  useEffect(() => {
    if (isActivityReport === false) {
      if (
        statusMessage === PUBLISHED_STATUS ||
        statusMessage === FAILED_STATUS
      ) {
        setIsDisabled(true);
        setIsChecked(true);
      } else if (statusMessage === READY_TO_PUBLISH) {
        setIsDisabled(false);
      } else if (statusMessage === PENDING_STATUS) {
        setIsDisabled(false);
        setIsChecked(false);
      }
    } else {
      setIsDisabled(true);
      setIsChecked(false);
    }
  }, [statusMessage]);

  // useEffect(() => {
  //   if (selectAllCheck && statusMessage === READY_TO_PUBLISH) {
  //     console.log("selected ad copies in adcopy")
  //     setIsChecked(true);
  //     onCheckRef.current(true, adCopy);
  //   } else {
  //     setIsChecked(false);
  //     onCheckRef.current(false, adCopy);
  //   }
  // }, [statusMessage, selectAllCheck]);

  const [urlError, setUrlError] = useState(false);
  const validateUrl = (url) => {
    try {
      const hostnameprotocol = new URL(url).protocol;
      const protocolWithDoubleSlash = `${hostnameprotocol}//`;

      const hostname = new URL(url).hostname;
      const parts = hostname.split(".");
      const partsCount = parts.length;
      const URLparts = url.split(".");
      console.log("URLparts->", URLparts);
      setUrlError(false);
      if (
        url.startsWith(protocolWithDoubleSlash) &&
        parts.includes("www") &&
        hostnameprotocol == "https:" &&
        partsCount >= 3 &&
        !parts.includes("") &&
        !URLparts.includes("")
      ) {
        console.log(
          "parts include www and count is above or equal to 3->",
          parts,
          " ",
          partsCount
        );
        setUrlError(false);
      } else if (
        url.startsWith(protocolWithDoubleSlash) &&
        !parts.includes("www") &&
        hostnameprotocol == "https:" &&
        partsCount >= 2 &&
        !parts.includes("") &&
        !URLparts.includes("")
      ) {
        console.log(
          "parts does not include www and count is above or equal to 2->",
          parts,
          " ",
          partsCount
        );

        setUrlError(false);
      } else {
        setUrlError(true);
      }
    } catch (error) {
      setUrlError(true);
      console.log("enter url is incorrect:", url);
    }
  };

  return (
    <div>
      <div className={classes.mainDiv}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          wrap="nowrap"
        >
          <Grid item style={{ paddingTop: "2rem" }}>
            <Checkbox
              color="secondary"
              checked={
                isChecked ||
                statusMessage === FAILED_STATUS ||
                statusMessage === PUBLISHED_STATUS
              }
              onChange={handleCheckboxChange}
              disabled={
                statusMessage !== "Ready to publish" || isActivityReport
              }
            />
          </Grid>
          <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
            <Accordion
              className={classes.expansionPanelStyle}
              expanded={expanded === adgroupName}
              onChange={handleChange(adgroupName)}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Grid
                  container
                  direction="column"
                  wrap="nowrap"
                  justify="flex-center"
                >
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="body1">
                      <b>Campaign:</b> &nbsp;{campaign}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="body1">
                      <b>Ad Group: </b>&nbsp;{adgroup}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <span style={{ display: "flex", alignItems: "center" }}>
                      <span>
                        {statusMessage === FAILED_STATUS ? (
                          <span>{icons.errorWarningNew}</span>
                        ) : statusMessage === PENDING_STATUS ? (
                          <span>{icons.rsaPendingStatusIcon}</span>
                        ) : statusMessage === PUBLISHED_STATUS ? (
                          <span>{icons.rsaPublishedStatusIcon}</span>
                        ) : statusMessage === READY_TO_PUBLISH ? (
                          <span>{icons.rsaReadyToPublishStatusIcon}</span>
                        ) : null}
                      </span>
                      <Typography
                        variant="subtitle2"
                        className={
                          statusMessage === FAILED_STATUS
                            ? classes.errorStatusMessage
                            : statusMessage === PUBLISHED_STATUS
                            ? classes.publishedStatusMessage
                            : statusMessage === READY_TO_PUBLISH
                            ? classes.readyStatusMessage
                            : classes.pendingStatusMessage
                        }
                      >
                        &nbsp;{statusMessage}
                      </Typography>
                    </span>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid item>
                      <div className={classes.labelWithTooltip}>
                        <label htmlFor="brand-name">
                          <CustomTypography>
                            Describe your brand/organization &nbsp;
                          </CustomTypography>
                        </label>
                        <Tooltip
                          title={
                            <React.Fragment>
                              <Typography variant="subtitle2" color="inherit">
                                {rsaBrandTooltip}
                              </Typography>
                            </React.Fragment>
                          }
                          placement="right-end"
                        >
                          {icons.rsaToolTipIcon}
                        </Tooltip>
                      </div>
                    </Grid>
                    <Grid item>
                      <textarea
                        className={classes.textarea1}
                        type="text"
                        id="brand-name"
                        value={brandName}
                        onChange={handleBrandNameChange}
                        placeholder="Describe your brand here."
                        disabled={isDisabled}
                      />
                    </Grid>

                    <Grid item>
                      <div className={classes.labelWithTooltip}>
                        <label htmlFor="product-description">
                          <CustomTypography>
                            Describe the product you wish to advertise &nbsp;
                          </CustomTypography>
                        </label>
                        <Tooltip
                          title={
                            <React.Fragment>
                              <Typography variant="subtitle2" color="inherit">
                                {rsaDescTooltip}
                              </Typography>
                            </React.Fragment>
                          }
                          placement="right-end"
                        >
                          {icons.rsaToolTipIcon}
                        </Tooltip>
                      </div>
                    </Grid>
                    <Grid item>
                      <textarea
                        className={classes.textarea2}
                        type="text"
                        id="product-description"
                        value={productDescription}
                        onChange={handleProductDescriptionChange}
                        placeholder="Explain AI about your product."
                        disabled={isDisabled}
                      />
                    </Grid>
                    <Grid item>
                      <NewOrangeContainedBtn
                        onClick={handleGenerateClick}
                        className={classes.generateButton}
                        disabled={
                          loading ||
                          generateBtnDisabled ||
                          brandName.length == 0 ||
                          productDescription.length == 0
                        }
                      >
                        {loading ? (
                          <span style={{ color: "white" }}>
                            Generating &nbsp;
                            <CircularProgress color="inherit" size={"19px"} />
                          </span>
                        ) : (
                          "GENERATE"
                        )}
                      </NewOrangeContainedBtn>
                      <br />
                      <Typography variant="subtitle2">
                        {Number(3) - Number(adCopy.generateClicks)} Out Of 3
                        Usages Left
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className={classes.labelWithTooltip}>
                      <CustomTypographyHeading>
                        <b>Generated Ad </b>(Please review and edit each
                        parameter)&nbsp;
                      </CustomTypographyHeading>
                      <Tooltip
                        title={
                          <React.Fragment>
                            <Typography variant="subtitle2" color="inherit">
                              {rsaGenerateTooltip}
                            </Typography>
                          </React.Fragment>
                        }
                        placement="right-end"
                      >
                        {icons.rsaToolTipIcon}
                      </Tooltip>
                    </div>
                    <br />
                    {headlines.map((headline, index) => {
                      const headlineValue = adCopy[`headLine${index + 1}`];
                      const isDuplicate =
                        headlineValue.trim() !== "" &&
                        headlines
                          .slice(0, index)
                          .filter(
                            (headline) =>
                              headline.trim() !== "" &&
                              headline.trim() === headlineValue.trim()
                          ).length > 0;
                      const hasError =
                        headlineValue && headlineValue.trim().length > 30;
                      const isRequired = index < 3;
                      const hasEnoughHeadlines = headlines.filter(
                        (headline) => headline.trim() !== ""
                      ).length;
                      //  const hasValidHeadlines = isRequired && (index != hasEnoughHeadlines-1) && headlineValue.length == 0 && counter != 0
                      //  console.log("color issues",adgroupName,index,hasValidHeadlines,index != hasEnoughHeadlines-1);
                      return (
                        <div key={index}>
                          <label>
                            <CustomTypography>
                              Headline {index + 1}
                            </CustomTypography>
                          </label>
                          <input
                            className={classes.headlineBox}
                            id={`${adgroupId}-headline${index}`}
                            placeholder={`Headline ${index + 1}`}
                            value={headlineValue}
                            onChange={(e) =>
                              handleHeadlineChange(e.target.value, index)
                            }
                            disabled={isDisabled}
                            style={{
                              border:
                                hasError || isDuplicate
                                  ? "1px solid #CF0505"
                                  : "1px solid #E3E6F4",
                            }}
                          />
                          <div className={classes.fieldBoxInfo}>
                            {/* {!isDuplicate && !hasError &&  (
                            
                          <Typography variant="subtitle2" style={{color : hasValidHeadlines && index < 3? "#CF0505" :"#1E334E"}}>
                            {hasEnoughHeadlines >= 3 && isRequired ? "Required" : isRequired && (index != hasEnoughHeadlines-1) && headlineValue.length == 0 && counter != 0 ? "At least 3 headlines are required." : isRequired ? "Required" : ""}
                            </Typography>
                          )} */}
                            {isDuplicate && (
                              <Typography variant="subtitle2" color="error">
                                Headlines are identical. Please enter a
                                different headline.
                              </Typography>
                            )}
                            {!isDuplicate && hasError && (
                              <Typography variant="subtitle2" color="error">
                                Maximum character limit exceeded.
                              </Typography>
                            )}
                            {!isDuplicate && !hasError && (
                              <Typography variant="subtitle2">
                                {isRequired ? "Required" : ""}
                              </Typography>
                            )}

                            <div className={classes.characterCount}>
                              <Typography
                                variant="caption"
                                color={hasError ? "error" : "inherit"}
                              >
                                {headlineValue
                                  ? headlineValue.trim().length
                                  : 0}
                              </Typography>
                              <Typography variant="caption">/30</Typography>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {descriptions.map((description, index) => {
                      const descriptionValue =
                        adCopy[`description${index + 1}`];
                      const isDuplicate =
                        descriptionValue.trim() !== "" &&
                        descriptions
                          .slice(0, index)
                          .filter(
                            (description) =>
                              description.trim() !== "" &&
                              description.trim() === descriptionValue.trim()
                          ).length > 0;
                      const hasError =
                        descriptionValue && descriptionValue.trim().length > 90;
                      const isRequired = index < 2;
                      return (
                        <span key={index}>
                          <label>
                            <CustomTypography>
                              Description Line {index + 1}
                            </CustomTypography>
                          </label>
                          <input
                            className={classes.descriptionBox}
                            id={`${adgroupId}-description${index}`}
                            placeholder={`Description Line ${index + 1}`}
                            value={descriptionValue}
                            onChange={(e) =>
                              handleDescriptionChange(e.target.value, index)
                            }
                            disabled={isDisabled}
                            style={{
                              border:
                                hasError || isDuplicate
                                  ? "1px solid #CF0505"
                                  : "1px solid #E3E6F4",
                            }}
                          />
                          <div className={classes.fieldBoxInfo}>
                            {isDuplicate && (
                              <Typography variant="subtitle2" color="error">
                                Descriptions are identical. Please enter a
                                different description.
                              </Typography>
                            )}
                            {!isDuplicate && hasError && (
                              <Typography variant="subtitle2" color="error">
                                Maximum character limit exceeded.
                              </Typography>
                            )}
                            {!isDuplicate && !hasError && (
                              <Typography variant="subtitle2">
                                {isRequired ? "Required" : ""}
                              </Typography>
                            )}
                            <div className={classes.characterCount}>
                              <Typography
                                variant="caption"
                                color={hasError ? "error" : "inherit"}
                              >
                                {descriptionValue
                                  ? descriptionValue.trim().length
                                  : 0}
                              </Typography>
                              <Typography variant="caption">/90</Typography>
                            </div>
                          </div>
                        </span>
                      );
                    })}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className={classes.labelWithTooltip}>
                      <CustomTypographyHeading>
                        <b>Set Path and Final URL</b> (Please set landing page
                        for your Ads) &nbsp;
                      </CustomTypographyHeading>
                      <Tooltip
                        title={
                          <React.Fragment>
                            <Typography variant="subtitle2" color="inherit">
                              {rsaUrlTooltip}
                            </Typography>
                          </React.Fragment>
                        }
                        placement="right-end"
                      >
                        {icons.rsaToolTipIcon}
                      </Tooltip>
                    </div>
                    <br />
                    <div style={{ marginBottom: "15px" }}>
                      <label>
                        <CustomTypography>Final URL</CustomTypography>
                      </label>
                      <input
                        className={classes.descriptionBox}
                        id={`${adCopy.adgroupId}-finalUrl`}
                        placeholder="https://www.example.com"
                        value={adCopy.finalUrl}
                        onChange={(event) => {
                          const newFinalUrl = event.target.value;
                          setAdCopy({
                            ...adCopy,
                            finalUrl: newFinalUrl,
                          });
                          validateUrl(newFinalUrl);
                        }}
                        disabled={isDisabled}
                        style={{
                          border:
                            urlError ||
                            (adCopy.finalUrl && adCopy.finalUrl.length === 0)
                              ? "1px solid #CF0505"
                              : "1px solid #E3E6F4",
                        }}
                      />
                      <span className={classes.fieldBoxInfo}>
                        {adCopy.finalUrl.length === 0 && (
                          <Typography variant="subtitle2" color="error">
                            Final URL cannot be empty.
                          </Typography>
                        )}

                        {adCopy.finalUrl.length != 0 && urlError && (
                          <Typography variant="subtitle2" color="error">
                            Please enter valid URL as indicated.
                          </Typography>
                        )}
                      </span>
                    </div>

                    {paths.map((path, index) => {
                      const pathValue = adCopy[`path${index + 1}`];
                      const isDuplicate =
                        pathValue.trim() !== "" &&
                        paths
                          .slice(0, index)
                          .filter(
                            (path) =>
                              path.trim() !== "" &&
                              path.trim() === pathValue.trim()
                          ).length > 0;
                      const hasError =
                        pathValue && pathValue.trim().length > 15;
                      return (
                        <div>
                          <label>
                            <CustomTypography>
                              Path {index + 1}
                            </CustomTypography>
                          </label>
                          <input
                            className={classes.descriptionBox}
                            id={`${adgroupId}-path${index}`}
                            placeholder={`/path ${index + 1}`}
                            value={adCopy[`path${index + 1}`]}
                            onChange={(e) =>
                              handlePathChange(e.target.value, index)
                            }
                            disabled={isDisabled}
                            style={{
                              border:
                                hasError || isDuplicate
                                  ? "1px solid #CF0505"
                                  : "1px solid #E3E6F4",
                            }}
                          />
                          <div className={classes.fieldBoxInfo}>
                            {isDuplicate && (
                              <Typography variant="subtitle2" color="error">
                                Paths are duplicate. Please enter a different
                                path.
                              </Typography>
                            )}
                            {!isDuplicate && hasError && (
                              <Typography variant="subtitle2" color="error">
                                Maximum character limit exceeded.
                              </Typography>
                            )}
                            <div className={classes.characterCount}>
                              <Typography
                                variant="caption"
                                color={hasError ? "error" : "inherit"}
                              >
                                {pathValue ? pathValue.trim().length : 0}
                              </Typography>
                              <Typography variant="caption">/15</Typography>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  descriptions: state.descriptions,
  headlines: state.headlines,
});
const mapDispatchToProps = {
  generateAdCopy,
  fetchAdCopy,
  saveAdCopy,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(AdCopyGeneration));
