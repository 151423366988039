import React from "react";
import { Grid, Typography } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import { GreenText } from "../typography";
import MediaControlCard from "../media-object";
import icons from "../icons";

const CustomGrid = withStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
}))(Grid);

function CustomerTestimonial() {
  return (
    <CustomGrid container>
      <Grid item sm={12} xs={12} md={12}>
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: 500,
            fontSize: "2.8rem",
          }}
          variant={"h3"}
          component={"h3"}
          color="textSecondary"
          align={"center"}
          gutterBottom
        >
          What <GreenText>our Customers </GreenText>are saying.
        </Typography>
        <br />
      </Grid>
      <Grid item md={12}>
        <MediaControlCard imgUrl={icons.client_1} />
      </Grid>
    </CustomGrid>
  );
}

export default CustomerTestimonial;
