const profileSettingsStyles = (theme) => ({
  paper: {
    // background: theme.palette.common.white,
    boxShadow: "4px 4px 18px rgba(0, 0, 0, 0.06)",
    borderRadius: 6,
    padding: theme.spacing(2),
  },
  card: {
    background: "none",
    boxShadow: "unset",
  },
  grow: {
    flexGrow: 1,
  },
  mb: {
    marginBottom: theme.spacing(2),
  },
  hyperLink: {
    cursor: "pointer",
  },
  cardStyle: {
    // background: theme.palette.common.white,
    boxShadow: "4px 4px 18px rgba(0, 0, 0, 0.06)",
    borderRadius: 6,
    padding: theme.spacing(1),
    border: "1px solid #E3E6F4",
    minHeight: 100,
    // background:"#e6ecff"
  },
  inputFieldStyle: {
    width: 300,
    height: 33,
    boxSizing: "border-box",
    borderRadius: 6,
    fontSize: "14px",
    fontFamily: "Lato",
  },
  solid: {
    padding: 10,
    backgroundColor: theme.palette.border,
    // width:"20%"
  },
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    // color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: { width: "100%", marginBottom: 15 },
  sectionHead: {
    minHeight: 60,
  },
});

export default profileSettingsStyles;
