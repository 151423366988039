import React from "react";
import { Paper, Grid, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import icons from "../../components/icons";

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="xl" />;

const styles = (theme) => ({
  paraItem: {
    paddingLeft: "8px",
  },
  title: {
    font: "lato",
    fontWeight: "700",
    fontSize: "20px",
  },
  paper: {
    // padding:"top rigth bottom left"
    padding: "32px 24px 32px 24px",
    background: "#F2F3F9",
    boxShadow: "unset",
    border: "1px solid #E3E6F4",
    boxSizing: "border-box",
    borderRadius: 6,
  },

  paras: {
    opacity: "60%",
    font: "lato",
    fontWeight: "500",
    fontSize: "18px",
    paddingTop : "5px",
  },
});

function Paragraph(props) {
  const { para1, para2, para3, title, classes, width } = props;
  return (
    <div className={classes.desSection}>
      <Paper className={classes.paper}>
        <Grid
          container
          alignItems={width == "sm" || width == "xs" ? "flex-start" : "center"}
        >
          <Grid item sm={1} xs={1} md={1}>
            <Typography align={"center"}>
              {width == "sm" || width == "xs"
                ? icons.lightTaskResizable(40)
                : icons.lightTaskResizable(60)}
              &nbsp;
            </Typography>
          </Grid>
          <Grid item sm={11} xs={11} md={11} className={classes.paraItem}>
            <Typography className={classes.title}>
              <b>{title}</b><br/>
            </Typography>
            <Typography className={classes.paras}>{para1}</Typography>
            {/* &nbsp; */}
            <Typography className={classes.paras}>{para2}</Typography>
            {/* &nbsp; */}
            <Typography className={classes.paras}>{para3}</Typography>
          </Grid>
        </Grid>
      </Paper>
      <br />
    </div>
  );
}
export default withWidth()(withStyles(styles, { withTheme: true })(Paragraph));
