import React, { useState, useEffect } from "react";
import { Grid, MenuItem } from "@mui/material";
import { CustomGrid } from "./adgroup-from";
import { ActionButtons } from "./templates";
import { connect } from "react-redux";
import { saveOnDemandFroms, updateFromInputs } from "../../actions";
import * as _ from "lodash";
import { InputField } from "../input-forms";

// class CommonForm extends React.PureComponent {
//     constructor(props) {
//         super(props);
//         this.state = {
//             ...this.props.initialValue,
//             businessDetailsError: '',
//             adgroupThemeError: '',
//             campaignError: '',
//             disableFields: false,
//         }
//     }

function CommonForm(props) {
  const [state, setState] = useState({
    ...props.initialValue,
    // businessDetailsError: "",
    // adgroupThemeError: "",
    // campaignError: "",
    disableFields: false,
  });
  const [adgroupThemeError, setAdgroupThemeError] = useState("");
  const [businessDetailsError, setBusinessDetailsError] = useState("");
  const [campaignError, setCampaignError] = useState("");

  useEffect(() => {
    setAdgroupThemeError("");
    setBusinessDetailsError("");
    setCampaignError("");
  }, []);

  console.log("props.initialValue = ", props.initialValue);
  console.log("state = ", state);

  // async componentDidMount() {
  //     console.log("bus-=", this.state.businessDetails == undefined);
  //     if (this.state.campaign && this.state.adgroupTheme && this.state.businessDetails == undefined) {
  //         await this.setState({disableFields: true});
  //         this.handleInputs({
  //             target: {
  //                 name: 'campaign',
  //                 value: this.state.campaign
  //             },
  //             preventDefault: () => {
  //             }
  //         });
  //     }
  // }

  useEffect(() => {
    const fetchData = async () => {
      console.log("bus-=", state.businessDetails === undefined);
      if (
        state.campaign &&
        state.adgroupTheme &&
        state.businessDetails === undefined
      ) {
        await setState((prevState) => ({
          ...prevState,
          disableFields: true,
        }));

        handleInputs({
          target: {
            name: "campaign",
            value: state.campaign,
          },
          preventDefault: () => {},
        });
      }
    };

    fetchData();
  }, []);

  const heandleChange = (name) => (event) => {
    event.preventDefault();
    const { disableFields } = state;
    setState((prevState) => ({
      ...prevState,
      [name]: event.target.value,
    }));
    if (name == "campaign") {
      let adgroupMap = _.filter(
        _.keyBy(props.accountStructureDetails.adGroupList, "adGroupId"),
        (item) => item.campaignName == event.target.value
      );
      console.log("onchange adgrp list", adgroupMap);
      if (disableFields == true) {
        setState((prevState) => ({
          ...prevState,
          adgroupMap: adgroupMap,
          adgroupTheme: state.adgroupTheme,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          adgroupMap: adgroupMap,
          adgroupTheme: "",
        }));
      }
    }
  };

  const handleInputs = (event) => {
    event.preventDefault();
    const { disableFields } = state;
    heandleChange();
    // if (event.target.name == "campaign") {
    //   let adgroupMap = _.filter(
    //     _.keyBy(props.accountStructureDetails.adGroupList, "adGroupId"),
    //     (item) => item.campaignName == event.target.value
    //   );
    //   console.log("onchange adgrp list", adgroupMap);
    //   if (disableFields == true) {
    //     setState({
    //       adgroupMap: adgroupMap,
    //       adgroupTheme: state.adgroupTheme,
    //     });
    //   } else {
    //     setState({ adgroupMap: adgroupMap, adgroupTheme: "" });
    //   }
    // }
  };

  // useEffect((name) => {
  //   if (name == "campaign") {
  //     let adgroupMap = _.filter(
  //       _.keyBy(props.accountStructureDetails.adGroupList, "adGroupId"),
  //       (item) => item.campaignName == event.target.value
  //     );
  //     console.log("onchange adgrp list", adgroupMap);
  //     if (disableFields == true) {
  //       setState({
  //         adgroupMap: adgroupMap,
  //         adgroupTheme: state.adgroupTheme,
  //       });
  //     } else {
  //       setState({ adgroupMap: adgroupMap, adgroupTheme: "" });
  //     }
  //   }
  // }, []);

  const validate = () => {
    let isError = false;
    const errors = {};
    if (state.campaign == "" && state.campaign.length == 0) {
      isError = true;
      errors.campaignError = "This Field is required";
    } else {
      errors.campaignError = "";
    }
    if (state.adgroupTheme == "" && state.adgroupTheme.length == 0) {
      isError = true;
      errors.adgroupThemeError = "This Field is required";
    } else {
      errors.adgroupThemeError = "";
    }
    if (state.businessDetails == "" && state.businessDetails.length == 0) {
      isError = true;
      errors.businessDetailsError = "This Field is required";
    } else {
      errors.businessDetailsError = "";
    }

    setCampaignError(errors.campaignError);
    setBusinessDetailsError(errors.businessDetailsError);
    setAdgroupThemeError(errors.adgroupThemeError);

    if (isError) {
      setState({
        ...state,
        // ...errors,
      });
    }
    return isError;
  };

  let miniService;
  const onSubmit = async (preNext) => {
    console.log("form state", state);
    const { handleNext } = props;
    let error;
    const {
      campaign,
      adgroupTheme,
      businessDetails,
      url,
      adgroupMap,
      disableFields,
    } = state;
    const { label, type } = props;
    if (preNext == true) {
      error = disableFields == false ? validate() : false;
    }

    if (!error) {
      const { taskDataId } = props.initialValue;
      await props.updateInputs(
        label,
        type,
        campaign,
        adgroupTheme,
        businessDetails,
        null,
        adgroupMap,
        taskDataId
      );
      miniService = {
        ...miniService,
        serviceDetails: _.groupBy(props.fromInputs, "type"),
      };
      miniService = { ...miniService, grandTotal: props.grandTotal };
      miniService = { ...miniService, itemCounts: props.itemCounts };
      props.saveInputsToServer(miniService);
      if (props.activeStep === props.steps.length - 1 && preNext != false) {
        props.handleMultiPageAndSteppers(null, handleNext);
      } else {
        props.handleMultiPageAndSteppers(preNext);
      }
      setBusinessDetailsError("");
      // setState({ businessDetailsError: "" });
    }
  };

  return (
    <Grid container className={"fullHeight"}>
      <CustomGrid item className={"fullWidth"} style={{ height: "90%" }}>
        <InputField
          variant="standard"
          id="standard-name"
          label="Which Campaign would you want to add the ad group to"
          select
          fullWidth
          margin="normal"
          value={state.campaign}
          onChange={heandleChange("campaign")}
          error={campaignError != ""}
          helperText={campaignError}
          name="campaign"
          disabled={state.disableFields == true}
          InputLabelProps={{
            shrink: true,
          }}
          required
        >
          <MenuItem key={1} value="">
            <em>None</em>
          </MenuItem>
          {_.map(props.accountStructureDetails.campaignsMap, (value, key) => {
            return (
              <MenuItem key={key} value={value}>
                {value}
              </MenuItem>
            );
          })}
        </InputField>
        <br />
        <InputField
          variant="standard"
          id="standard-name"
          label="Which Adgroup would you like you add"
          select
          fullWidth
          margin="normal"
          value={state.adgroupTheme}
          onChange={heandleChange("adgroupTheme")}
          name="adgroupTheme"
          InputLabelProps={{
            shrink: true,
          }}
          disabled={state.disableFields == true}
          error={adgroupThemeError != ""}
          helperText={adgroupThemeError}
          required
        >
          <MenuItem key={2} value="">
            <em>None</em>
          </MenuItem>
          {_.map(state.adgroupMap, (value, key) => {
            return (
              <MenuItem key={key} value={value.adGroupName}>
                {value.adGroupName}
              </MenuItem>
            );
          })}
        </InputField>
        <br />
        <InputField
          variant="standard"
          id="standard-name"
          label="Tell us some more business details."
          multiline={true}
          rows={4}
          fullWidth
          margin="normal"
          value={state.businessDetails}
          onChange={heandleChange("businessDetails")}
          error={businessDetailsError != ""}
          helperText={businessDetailsError}
          name="businessDetails"
          InputLabelProps={{
            shrink: true,
          }}
          disabled={state.disableFields == true}
          required
        />
      </CustomGrid>
      <ActionButtons
        activeStep={props.activeStep}
        onSubmit={(value) => onSubmit(value)}
        steps={props.steps}
      />
    </Grid>
  );
}

const mapStateToProps = (state, ownProps) => ({
  initialValue: state.inputFroms[ownProps.label],
  accountStructureDetails: state.accountStructureDetails,
  itemCounts: state.miniServiceItemCount,
  fromInputs: state.inputFroms,
});
const mapDispatchToProps = (dispatch) => ({
  updateInputs: (
    label,
    type,
    campaign,
    adgroupTheme,
    businessDetails,
    url,
    adgroupMap,
    taskDataId
  ) => {
    return dispatch(
      updateFromInputs(
        label,
        type,
        campaign,
        adgroupTheme,
        businessDetails,
        url,
        adgroupMap,
        taskDataId
      )
    );
  },
  saveInputsToServer: (miniService) => {
    return dispatch(saveOnDemandFroms(miniService));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CommonForm);
