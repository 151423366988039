import TableCell from '@mui/material/TableCell/index';
import TableRow from '@mui/material/TableRow/index';
import withStyles from '@mui/styles/withStyles';

export const TableCellNoPadding = withStyles(theme =>({
    root: {
        padding: 6,
        borderBottom:0,
        color:theme.palette.text.primary,
        '&:last-child': {
            // padding: 0,
        },
    },
}))(TableCell);

export const TableRowAutoHeight = withStyles(theme =>({
    root: {
        height: "auto",
    },
}))(TableRow);

