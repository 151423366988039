import { Card, Grid, CardContent, Typography, Divider, CardHeader } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import { outLinedOrangeBtn } from "../buttons";
import React, { Fragment } from "react";
import icons from "../icons";
import NumericLabel from "react-pretty-numbers";
import CurrencySymbol from "../../components/currencySymbol.jsx";
import { SUPPORT_MAIL_FOR_CONTENT } from "../../actions/actionTypes";

let moment = require("moment");

export const CustomCard = withStyles((theme) => ({
  root: {
    background: theme.palette.graderBg,
    width: "100%",
  },
}))(Card);
export const calculateCustomerOpportunity = (data, classes) => {
  if (
    data &&
    data.wastedSpendPercent <= 1 &&
    data.customerCount < 10 &&
    data.overallScore > 60
  ) {
    return (
      <Grid container justifyContent={"center"}>
        {/*<Grid item>
                            {icons.optimisedHand}
                            <Typography component={"h3"} variant={"h3"}
                                        align={"center"}><b>{`${data.graderReportData.customerCount}+`}</b></Typography>
                        </Grid>*/}
        <Grid item sm={12}>
          <Typography component={"p"} align={"center"}>
            Congratulations!
            <br /> Your Google Ads account is well optimized.
          </Typography>
        </Grid>
      </Grid>
    );
  } else if (
    (data &&
      data.wastedSpendPercent <= 1 &&
      data.customerCount < 10 &&
      data.overallScore < 60) ||
    (data && data.customerCount <= 0)
  ) {
    return null;
  } else if (
    data &&
    data.wastedSpendPercent > 1 &&
    data.customerCount < 10 &&
    data.overallScore < 60
  ) {
    return null;
  } else {
    return (
      <Grid container justifyContent={"center"}>
        {data ? (
          <Grid item sm={12}>
            <Typography align={"center"}>{icons.optimisedHand}</Typography>
            <Typography
              component={"h4"}
              variant={"h4"}
              className={classes.count}
              align={"center"}
            >
              <b>{`${data.customerCount}+`}</b>
            </Typography>
            <Typography
              component={"p"}
              align={"center"}
              className={classes.smPara}
            >
              More customers/month
            </Typography>
            <br />
            {Math.round(data.convPercInc) != 0 ? (
              <Typography
                component={"p"}
                className={classes.grnHead}
                align={"center"}
              >
                <b>
                  {`${Math.round(data.convPercInc)}%`} Increase in conversions*
                </b>
              </Typography>
            ) : null}
            <Typography
              component={"p"}
              align={"center"}
              className={classes.para}
            >
              You can win {`${data.customerCount}+`}
              &nbsp;more customers <br /> for the same Google Ads spend in a
              month
            </Typography>
            <br />
          </Grid>
        ) : null}
      </Grid>
    );
  }
};
export const calculateAdsPerformanceContent = (overAllScore, classes) => {
  if (overAllScore > 80) {
    return (
      <Fragment>
        <Typography
          component={"p"}
          className={classes.redHead}
          align={"center"}
        >
          <b>Your account does not require a Jumpstart.</b>
        </Typography>
        <Typography component={"p"} className={classes.para} align={"center"}>
          I love the stats. But if you would like some tips on smart campaign
          optimization, get in touch now{" "}
          <a href={`mailto:${SUPPORT_MAIL_FOR_CONTENT}`}>
            {SUPPORT_MAIL_FOR_CONTENT}
          </a>
        </Typography>
      </Fragment>
    );
  } else if (overAllScore >= 60 && overAllScore <= 80) {
    return (
      <Fragment>
        <Typography
          component={"p"}
          className={classes.redHead}
          align={"center"}
        >
          <b>Jumpstart recommended for your account</b>
        </Typography>
        <Typography component={"p"} className={classes.para} align={"center"}>
          A PPC tool like LXRGuide can help you save time and money and improve
          overall performance across the board.
          <br /> Message us{" "}
          <a href={`mailto:${SUPPORT_MAIL_FOR_CONTENT}`}>
            {SUPPORT_MAIL_FOR_CONTENT}
          </a>
        </Typography>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <Typography
          component={"p"}
          className={classes.redHead}
          align={"center"}
        >
          <b>Your account needs a Jumpstart</b>
        </Typography>
        <Typography component={"p"} className={classes.para} align={"center"}>
          You already have the answer. LXRGuide can create custom solutions
          tailored to your PPC requirements and business needs. Let’s catch up
          now{" "}
          <a href={`mailto:${SUPPORT_MAIL_FOR_CONTENT}`}>
            {SUPPORT_MAIL_FOR_CONTENT}
          </a>
        </Typography>
      </Fragment>
    );
  }
  return null;
};

export const MainSectionCardHeader = withStyles((theme) => ({
  title: {
    fontSize: "24px",
    fontWeight: "900",
    color: theme.palette.graderGraphColors.text,
    fontFamily: "Montserrat",
  },
  subheader: {
    fontSize: "16px",
    color: theme.palette.graderGraphColors.text,
    fontFamily: "Lato",
  },
  action: {
    marginRight: "unset",
  },
}))(CardHeader);
let id = 0;

function campaignSnapshotvalues(icon, title, valueName) {
  id += 1;
  return { id, icon, title, valueName };
}

export const campaignDummyData = [
  campaignSnapshotvalues("Views", "Views", "impressions"),
  campaignSnapshotvalues("Click", "Clicks", "clicks"),
  campaignSnapshotvalues("Ctr", "CTR", "ctr"),
  campaignSnapshotvalues("Orders", "Orders", "conversions"),
  campaignSnapshotvalues("Spend", "Spend", "cost"),
  campaignSnapshotvalues("Revenue", "Revenue", "revenue"),
];

export const CustomPaper = withStyles((theme) => ({
  root: {
    boxShadow: "unset",
  },
}))(Card);

export function CampaignSnapshotPaper({
  icon,
  value,
  title,
  classes,
  currency,
}) {
  console.log("currency=", currency);
  const numOptions = {
    commafy: true,
    shortFormatMinValue: 9999,
    shortFormatPrecision: 0,
    precision: 0,
    shortFormat: true,
    justification: "R",
    locales: "en-US",
    // title: true
  };
  const ctrOptions = {
    commafy: true,
    shortFormatMinValue: 9999,
    shortFormatPrecision: 0,
    precision: 2,
    shortFormat: true,
    justification: "R",
    locales: "en-US",
    // title: true
  };
  const currencyOptions = { currency: false, wholenumber: "ceil" };
  return (
    <CustomPaper>
      <div style={{ padding: 5 }}>
        <Grid container justifyContent={"space-between"}>
          <Grid item>
            <Typography>{icon}</Typography>
          </Grid>
          <Grid item>
            <Typography component={"p"} className={classes.text}>
              {title == "Revenue" || title == "Spend" ? (
                <span className={"flex"}>
                  {currency}
                  <CurrencySymbol
                    formatOptions={{ ...numOptions, ...currencyOptions }}
                  >
                    {value}
                  </CurrencySymbol>
                </span>
              ) : (
                <span>
                  {title == "CTR" ? (
                    <span className={"flex"}>
                      <NumericLabel params={ctrOptions}>{value}</NumericLabel>%
                    </span>
                  ) : (
                    <NumericLabel params={numOptions}>{value}</NumericLabel>
                  )}
                </span>
              )}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <br />
            <br />
            <br />
            <br />
          </Grid>
        </Grid>
        <Grid container justifyContent={"flex-start"}>
          <Grid item>
            <Typography component={"p"} className={classes.title}>
              {title}
            </Typography>
          </Grid>
        </Grid>
      </div>
    </CustomPaper>
  );
}

function getTextNegativeKeywords(value, classes) {
  if (value > 0) {
    return (
      <Typography
        component={"h4"}
        variant="h4"
        className={classes.negativeKeyIcon}
      >
        {icons.closeCircle}
      </Typography>
    );
  } else {
    return (
      <Typography
        component={"h4"}
        variant="h4"
        className={classes.negativeKeyIcon}
      >
        {icons.checkedCircle}
      </Typography>
    );
  }
}

function _MediaObject(props) {
  const { classes, title, color, value, content, data } = props;
  console.log("title=", title);
  console.log("title=value=", value);
  console.log("color=value=", color);
  console.log("content=value=", content);

  const ScoreFillTypography = withStyles((theme) => ({
    root: {
      width: "100%",
      height: title == "Conflicting Negative Keywords" ? "100%" : value + "%",
      backgroundColor: theme.palette.graderGraphColors[color],
    },
  }))(Typography);

  const numOptions = {
    commafy: true,
    precision: 0,
    justification: "R",
    locales: "en-US",
    // title: true
  };

  return (
    <Card className={classes.card}>
      <div className={classes.controls}>
        <div className={classes.graphFillParent}>
          <ScoreFillTypography></ScoreFillTypography>
        </div>
        <div className={classes.graphContentParent}>
          {title == "Conflicting Negative Keywords" ? (
            getTextNegativeKeywords(value, classes)
          ) : (
            <Typography
              component={"h4"}
              variant="h4"
              className={classes.graphContent}
            >
              {value}
              <span style={{ fontSize: "initial" }}>/100</span>
            </Typography>
          )}
        </div>
      </div>
      <CardContent className={classes.content}>
        {title == "Cut down Wasted Spend by" ? (
          <Typography
            component="h6"
            variant="h6"
            className={classes.title}
            style={{ display: "flex" }}
          >
            {title + " " + data.currencySymbol}
            <NumericLabel params={numOptions}>
              {Math.round(data.wastedSpend)}
            </NumericLabel>
          </Typography>
        ) : (
          <Typography component="h6" variant="h6" className={classes.title}>
            {title}
          </Typography>
        )}
        <Typography component={"div"} className={classes.contentp}>
          {content}
        </Typography>
      </CardContent>
    </Card>
  );
}

export const MediaObject = withStyles(
  (theme) => ({
    controls: {
      position: "relative",
      width: "20%",
    },
    graphFillParent: {
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "flex-end",
      backgroundColor: theme.palette.graderGraphColors.graphBg,
      position: "absolute",
      top: 0,
    },
    graphContentParent: {
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      position: "absolute",
      top: 0,
      justifyContent: "center",
    },
    graphContent: {
      fontWeight: "bold",
      color: theme.palette.graderGraphColors.text,
      fontFamily: "Montserrat",
    },
    negativeKeyIcon: {
      color: theme.palette.common.white,
    },
    whiteContent: {
      color: theme.palette.common.white,
    },
    redContent: {
      color: theme.palette.graderGraphColors.red,
    },
    whiteColor: {
      color: theme.palette.common.white,
    },
    card: {
      display: "flex",
    },
    content: {
      width: "80%",
      flex: "1 0 auto",
      padding: 5,
      minHeight: "20vh",
      fontSize: 14,
      color: theme.palette.graderGraphColors.text,
    },
    contentp: {
      "& > p": {
        fontSize: 14,
        fontWeight: "300",
        color: theme.palette.graderGraphColors.text,
      },
    },
    title: {
      fontSize: 16,
      color: theme.palette.graderGraphColors.text,
      fontFamily: "Montserrat",
    },
  }),
  { withTheme: true }
)(_MediaObject);

export const googleAdMetrics = [
  "Quality Score",
  "Impressions Share Score",
  "Landing Page Performance",
  "Account Structure Score",
  "Conflicting Negative Keywords",
  "Cut down Wasted Spend by",
];

export function IconText({ icon, text, padding, classes }) {
  return (
    <Grid container spacing={2} style={padding ? { padding: 15 } : null}>
      <Grid item>{icon}</Grid>
      <Grid item>
        <Typography className={classes.title}>{text}</Typography>
      </Grid>
    </Grid>
  );
}

export const trackingList = [
  "E Commerce Platform",
  "Tracking  Implementation",
  "Revenue Tracking",
  "Multiple Tracking",
];

export function getLastDates() {
  let daysAgo = [];
  let n = 5;
  for (var i = 1; i <= n; i++) {
    if (
      moment().add(i, "days").format("ddd") === "Sat" ||
      moment().add(i, "days").format("ddd") === "Sun"
    ) {
      n += 1;
    } else {
      daysAgo[i] = {
        month: moment().add(i, "days").format("MMM"),
        day: moment().add(i, "days").format("DD"),
        year: moment().add(i, "days").format("YYYY"),
        dayText: moment().add(i, "days").format("ddd"),
        date: moment().add(i, "days").format("MMMM DD, YYYY"),
      };
    }
  }
  return daysAgo;
}

export function getGraphColor(value) {
  if (value > 60) {
    return "green";
  } else if (value > 40 && value <= 60) {
    return "yellow";
  } else if (value <= 40) {
    return "red";
  }
}

function getLandingScoreGraphColor(value) {
  if (value > 60) {
    return "green";
  } else if (value > 40 && value < 60) {
    return "yellow";
  } else if (value <= 40) {
    return "red";
  }
}

function getConflictNegativeKeywordGraphColor(value) {
  console.log("getConflictNegativeKeywordGraphColor=", value);
  if (value > 0) {
    return "red";
  } else {
    return "green";
  }
}

export function getMetricsValues(data, title, type) {
  switch (title) {
    case "Quality Score":
      if (type == "color") return getGraphColor(data.finalQualityScore);
      else if (type == "content")
        return qualityScoreContent(data.accountLevelQualityScore);
      else return data.finalQualityScore;
    case "Impressions Share Score":
      if (type == "color") return getGraphColor(data.searchImprShare);
      else if (type == "content")
        return getImpressionShareContent(data.searchImprShare, data);
      else return data.searchImprShare;
    case "Landing Page Performance":
      if (type == "color")
        return getLandingScoreGraphColor(data.landingPageScore);
      if (type == "content")
        return getLandingPageContent(data.landingPageScore, data);
      else return data.landingPageScore;
    case "Account Structure Score":
      if (type == "color") return getGraphColor(data.accountStructureScore);
      if (type == "content") return getAccountActivityScoreContent(data);
      else return data.accountStructureScore;
    case "Conflicting Negative Keywords":
      if (type == "color")
        return getConflictNegativeKeywordGraphColor(
          data.conflictNegativeKeyword
        );
      if (type == "content") return getConflictNegativeKeywordContent(data);
      else return data.conflictNegativeKeyword;
    // todo below doller value make it dynamic
    case "Cut down Wasted Spend by":
      if (type == "color") return getGraphColor(data.wastedSpendPercent);
      if (type == "content") return getWastedSpendContent(data);
      else return data.wastedSpendPercent;
    default:
      return null;
  }
}

const InactiveTypography = withStyles((theme) => ({
  root: {
    color: theme.palette.graderGraphColors.red,
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: 18,
  },
}))(Typography);
const ActiveTypography = withStyles((theme) => ({
  root: {
    color: theme.palette.graderGraphColors.green,
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: 18,
  },
}))(Typography);
const ContentPTypography = withStyles((theme) => ({
  root: {
    fontSize: 14,
    fontWeight: "300",
    color: theme.palette.graderGraphColors.text,
  },
}))(Typography);

export function getAccountStatusHead(impressionGraphData) {
  if (impressionGraphData == 0) {
    return <InactiveTypography>Account is inactive</InactiveTypography>;
  } else if (impressionGraphData == 30) {
    return (
      <ActiveTypography>
        Impressions available for {impressionGraphData} days
      </ActiveTypography>
    );
  } else if (impressionGraphData > 0) {
    return (
      <ActiveTypography>
        Impressions available for {impressionGraphData} days
      </ActiveTypography>
    );
  }
}

export function getAccountStatusContent(impressionGraphData) {
  if (impressionGraphData == 0) {
    return null;
  } else if (impressionGraphData > 0 && impressionGraphData < 30) {
    return (
      <ContentPTypography component={"p"}>
        Your account is making its presence felt. But account fitness is not
        about activity spurts. Consistency is the key.
      </ContentPTypography>
    );
  } else if (impressionGraphData == 30) {
    return (
      <ContentPTypography component={"p"}>
        Making an impression is not easy and maintaining it across 30 days is
        definitely a feat. Well done. . Consistency is the key. Fitness is not
        about activity spurts. Well done! Your account shows a healthy degree of
        activity on all 30 days
      </ContentPTypography>
    );
  }
}

export function qualityScoreContent(accountLevelQualityScore) {
  if (accountLevelQualityScore <= 3.9) {
    return (
      <Typography component={"p"}>
        Search is tough. Managing a business full time and concentrating on
        aligning PPC campaigns, keywords and landing pages to fetch traffic and
        cut costs is a challenging proposition. If we were you, We would try the
        LXRGuide.
      </Typography>
    );
  } else if (accountLevelQualityScore >= 4 && accountLevelQualityScore <= 6.9) {
    return (
      <Typography component={"p"}>
        Welcome to the club! Optimizing your landing pages and sharpening the
        focus of your search keywords is a challenge not just for you, but for
        almost everyone in the business. You should concentrate on quality
        score; 7 and above is where PPC gold lies.
      </Typography>
    );
  } else if (accountLevelQualityScore >= 7 && accountLevelQualityScore <= 7.9) {
    return (
      <Typography component={"p"}>
        Your average quality score is between 7 and 8. You've accomplished what
        you need in terms of quality. Google likes what you are doing, and you
        are not being penalized in terms of traffic or costs.
      </Typography>
    );
  } else if (accountLevelQualityScore >= 8) {
    return (
      <Typography component={"p"}>
        Impressive! Your ads frequently appear in prominent positions. Your
        costs have dropped, and traffic is surging to your site. You have a
        win-win situation. Wow!
      </Typography>
    );
  }
}

function getImpressionShareContent(value, data) {
  let content = "";
  if (data.searchLostISBudget > 0) {
    content =
      "This is sometimes a good rule. Spend more to make an impression. Know\n" +
      "why? We noticed that out of all relevant searches, your ads are showing up only " +
      value +
      "% of the\n" +
      "times. If they are refusing to show up, you need to treat them well. Spend more on them. The\n" +
      "more they show up, better your chances of converting them.";
  } else {
    content =
      "Your ads are working hard and showing up where they should. As far as\n" +
      "budget goes, you have nothing to worry about.";
  }
  return <Typography component={"p"}>{content}</Typography>;
}

function getLandingPageContent(value, data) {
  let landingPagePercentage = `${Math.round(data.landingPageScore)}`;
  if (landingPagePercentage < 90) {
    return (
      <Typography component={"p"}>
        We noticed that {`${Math.round(100 - data.landingPageScore)}%`} of your
        landing pages are not getting as many conversions as they should.
      </Typography>
    );
  } else {
    return (
      <Typography component={"p"}>
        We are rather charmed by the chemistry between your landing pages and
        customers. A majority of your landing pages are getting the expected
        number of conversions – way to go!
      </Typography>
    );
  }
}

function getAccountActivityScoreContent(data) {
  let content = "";
  if (data.accountStructureScore > 80) {
    content =
      "Our 12-Point likeability scale tells us that your account is way ahead of the average score. Great work!\n" +
      "We use metrics like campaign budget, keyword/Ad group, landing page status and more to tell you how\n" +
      "desirable your business appears to your PPC customers.\n" +
      "If you would like to hear more about your account structure score, drop us an email " +
      SUPPORT_MAIL_FOR_CONTENT;
  } else if (
    data.accountStructureScore >= 60 ||
    data.accountStructureScore < 80
  ) {
    content =
      "Our 12-Point likeability scale tells us that your account is close to the average score. That is a good sign,\n" +
      "but some structural changes are required for the account..\n" +
      "We use metrics like campaign budget, keyword/Ad group, landing page status and more to tell you how\n" +
      "desirable your business appears to your PPC customers.\n" +
      "Get in touch to learn more about your account structure now! " +
      SUPPORT_MAIL_FOR_CONTENT;
  } else {
    content =
      "While there is more to your account than what meets the eye, your account structure score is below the\n" +
      "peer group average and this is cause for concern. It is time to act.\n" +
      "We use metrics like campaign budget, keywords/Ad group, landing page status and more, to tell you\n" +
      "how desirable your business appears to your PPC customers.\n" +
      SUPPORT_MAIL_FOR_CONTENT;
    ("Let’s talk about your account structure score now! ");
  }
  return <Typography component={"p"}>{content}</Typography>;
}

function getConflictNegativeKeywordContent(data) {
  let content =
    "Good job! No conflicting keywords are blocking your ads in your campaigns.\n" +
    "Your ads stand a fair chance of winning and we are sure they will.";
  if (data.conflictNegativeKeyword != 0) {
    content =
      "Hey, this is unfair. The conflicting negative keywords in your campaigns are\n" +
      "giving your ads a lousy deal. They don’t let your ads show up where they should, let alone garner\n" +
      "clicks. Campaign efficiency will pick up if you give your ads a level playing field – look into it!";
  }
  return <Typography component={"p"}>{content}</Typography>;
}

function getWastedSpendContent(data) {
  const numOptions = {
    commafy: true,
    precision: 0,
    justification: "R",
    locales: "en-US",
    // title: true
  };
  let content = null;
  if (data.wastedSpendPercent > 1) {
    return (
      <Typography component={"p"}>
        Not trying to snitch on those keywords. But, really…
        {`${Math.round(100 - data.wastedSpendPercent)}% `}
        of your spend has been wasted on non performing keywords. They have
        wasted {data.currencySymbol}
        {`${Math.round(data.wastedSpend)}`} of your money these last few days.
        You must show them the door.
      </Typography>
    );
  } else {
    return (
      <Typography component={"p"}>
        Had your keywords under surveillance for a week. Looks like they are
        doing their job well. They do care and are not wasting your money.
      </Typography>
    );
  }
  // return ({content})
}

{
  /*
<p className="score-desc">Some of your ads are not being served
    because there are ${grader.conflictNegativeKeyword}
    conflicting negative keywords. These negative keywords are
    inadvertently blocking normal keyword matching, thereby
    decreasing your campaign's effectiveness.</p>
*/
}

export const SubSectionCardHeader = withStyles((theme) => ({
  root: {
    border: "1px solid rgba(30, 51, 78, 0.5)",
    borderRadius: "3px",
  },
  title: {
    fontSize: "16px",
    fontWeight: "bold",
    color: theme.palette.graderGraphColors.text,
  },
  subheader: {
    fontSize: "12px",
    fontWeight: "300",
    color: theme.palette.graderGraphColors.text,
  },
  action: {
    marginTop: 0,
    alignSelf: "auto",
    color: theme.palette.info,
  },
}))(CardHeader);

export function trackingContent(data) {
  let content = "";
  if (
    data.conversionTrackingPresent == false &&
    data.revTrackingPresent == false
  ) {
    content =
      "Tracking is like\n" +
      "keeping your GPS on. Make sure you enable tracking for both conversions and revenue.\n" +
      "Additional tip: If you want to enable multiple tracking, make sure the data does not get added to\n" +
      "the conversion table. This causes inflated conversions and revenue numbers.";
  } else if (
    data.conversionTrackingPresent == false &&
    data.revTrackingPresent == true
  ) {
    content =
      "You are tracking revenue without tracking conversions.\n" +
      "Please go back and implement tracking properly. If you would like some help in implement tracking, get in touch now " +
      SUPPORT_MAIL_FOR_CONTENT +
      "\n" +
      "Additional tip: If you want to enable multiple tracking, make sure the\n" +
      "data does not get added to the conversion table. This causes inflated conversions and revenue\n" +
      "numbers.";
  } else if (
    data.conversionTrackingPresent == true &&
    data.revTrackingPresent == true
  ) {
    content =
      "Tracking is like keeping your GPS on. You can’t lose your way with this GPS tracker on.\n" +
      "Additional tip: If you have enabled multiple tracking, make sure the data\n" +
      "does not get added to the conversion table. This causes inflated conversions and revenue numbers.";
  } else {
    // true && false
    content =
      "We noticed that you are tracking for\n" +
      "conversions, but not for revenue. At what cost? That’s the question. Additional tip: If you have\n" +
      "enabled multiple tracking, make sure the data does not get added to the conversion table. This causes\n" +
      "inflated conversions and revenue numbers.";
  }
  return <Typography component={"p"}>{content}</Typography>;
}

export function trackingHead(data) {
  let content = "";
  if (
    data.conversionTrackingPresent == false &&
    data.revTrackingPresent == false
  ) {
    content = "Enable conversion and revenue tracking for your account";
  } else if (
    data.conversionTrackingPresent == false &&
    data.revTrackingPresent == true
  ) {
    content = "Improper Tracking";
  } else if (
    data.conversionTrackingPresent == true &&
    data.revTrackingPresent == true
  ) {
    content = "Tracking implemented";
  } else {
    content = "Enable Revenue tracking for your account";
  }
  return content;
}

export function iconWithPara(icon, count, para, classes) {
  return (
    <div>
      <Typography align={"center"}>{icon}</Typography>
      <Typography align={"center"} component={"h4"} variant="h4">
        <b>{count}</b>
      </Typography>
      <Typography align={"center"} component={"h6"} variant="h6">
        {para}
      </Typography>
    </div>
  );
}
