import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import { connect } from "react-redux";
import {
  CANCEL_WELCOME_BANNER,
  DASHBOARD_VIEW_VALUE_UPDATED,
  ON_PRODUCT_TOUR_CLOSE,
  ON_PRODUCT_TOUR_CLOSE_CROSS,
  TOGGLE_THANK_YOU_BANNER,
  TOGGLE_WELCOME_BANNER,
} from "../../actions/actionTypes";
import { getTaskTypeId, tour } from "../productTour";
import { store } from "../../reducers/common";
import { withRouter } from "react-router";
import { withStyles } from "@mui/styles";
import { PublishOrangeContainedBtn } from "../../components/buttons";

async function startProductTour(history) {
  console.log("history", history);
  window.productTaskTypeId = await getTaskTypeId();
  window.hopscotch.endTour(tour);
  await store.dispatch({
    type: DASHBOARD_VIEW_VALUE_UPDATED,
    viewValue: 0,
  });
  await history.push("/dashboard");
  window.hopscotch.startTour(tour(window.productTaskTypeId));
}

class ProductTourDialogs extends Component {
  handleClickToggle = () => {
    this.props.welcomeToggle();
  };

  handleClose = () => {
    const { welcomeAlert, thankYouAlert, productTourCloseAlert } = this.props;
    console.log("productTourCloseAlert", productTourCloseAlert);
    console.log("welcomeAlert", welcomeAlert);
    console.log("thankYouAlert", thankYouAlert);
    if (welcomeAlert) this.props.welcomeCancel();
    if (thankYouAlert) this.props.thankYouToggle();
    if (productTourCloseAlert) this.props.productTourClose();
  };
  handleCancel = () => {
    const { welcomeCancel } = this.props;
    welcomeCancel();
  };
  handleProductTourStart = async () => {
    this.handleClickToggle();
    const { history } = this.props;
    await startProductTour(history);
  };

  render() {
    const {
      welcomeAlert,
      thankYouAlert,
      classes,
      productTourClose,
      productTourCloseAlert,
    } = this.props;
    const title = thankYouAlert
      ? "Thank You!"
      : welcomeAlert
      ? "Welcome to LXRGuide!"
      : null;
    const thankYouContent =
      "You have completed LXRGuide's Interactive Tour. For further questions, please reach out to your LXRGuide customer success manager.";
    const welcomeContent =
      "This guided tour throughout our paid search optimization recommendation platform will help you to better understand how the tool can help you.";
    const closeContent =
      "You can resume this tour at any time. Just click on the Product Tour icon on the left menu panel to pick up where you left off.";
    const content = productTourCloseAlert
      ? closeContent
      : thankYouAlert
      ? thankYouContent
      : welcomeAlert
      ? welcomeContent
      : null;
    console.log("thankYouAlert=", thankYouAlert);
    console.log("productTourCloseAlert=", productTourCloseAlert);
    return (
      <div>
        {/* <Button variant="outlined" color="primary" onClick={this.handleClickToggle}>
                    Open alert dialog
                </Button>*/}
        <Dialog
          classes={{
            paper: classes.dialogPaper,
          }}
          open={welcomeAlert || thankYouAlert || productTourCloseAlert}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <Typography variant={"body1"}>{content}</Typography>
          </DialogContent>
          <DialogActions>
            {welcomeAlert ? (
              <Fragment>
                <PublishOrangeContainedBtn
                  className={classes.button}
                  onClick={this.handleCancel}
                  color="primary"
                >
                  Cancel
                </PublishOrangeContainedBtn>
                <PublishOrangeContainedBtn
                  className={classes.button}
                  onClick={this.handleProductTourStart}
                  color="primary"
                  autoFocus
                >
                  Next
                </PublishOrangeContainedBtn>
              </Fragment>
            ) : null}
            {thankYouAlert || productTourCloseAlert ? (
              <PublishOrangeContainedBtn
                className={classes.button}
                onClick={this.handleClose}
                color="primary"
                autoFocus
              >
                Done
              </PublishOrangeContainedBtn>
            ) : null}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

ProductTourDialogs.propTypes = {};
const mapStateToProps = (state, ownProps) => ({
  welcomeAlert: state.productTour.welcomeDialog,
  thankYouAlert: state.productTour.thankYouDialog,
  productTourCloseAlert: state.productTour.productTourClose,
});
const mapDispatchToProps = (dispatch) => ({
  welcomeToggle: () => dispatch({ type: TOGGLE_WELCOME_BANNER }),
  welcomeCancel: () => dispatch({ type: CANCEL_WELCOME_BANNER }),
  thankYouToggle: () => dispatch({ type: TOGGLE_THANK_YOU_BANNER }),
  productTourClose: () => dispatch({ type: ON_PRODUCT_TOUR_CLOSE_CROSS }),
});
const styles = (theme) => ({
  dialogPaper: {
    border: "5px solid rgba(0, 0, 0, 0.5)",
    backgroundColor: "#ffffff",
    borderRadius: 0,
    // border: "5px solid #000000"
    // color: #333;
    zIndex: 999999,
    boxSizing: "content-box",
    backgroundClip: "padding-box",
  },
  button: {
    margin: theme.spacing(1),
  },
});
const _ProductTourDialogs = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductTourDialogs);
export default withRouter(
  withStyles(styles, { withTheme: true })(_ProductTourDialogs)
);
