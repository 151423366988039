import {
  Grid,
  LinearProgress,
  Paper,
  Typography,
  Divider,
} from "@mui/material";
import React, { Fragment } from "react";
import * as PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';
import * as types from "../../actions/actionTypes";
import classNames from "classnames";
import Tooltip from "@mui/material/Tooltip";
import CustomToolTip from "../../NewInterfacePages/customToolTip";
import icons from "../icons";

function IconText(props) {
  const { classes } = props;
  return (
    <Grid container alignItems={"left"}>
      <Grid item>
        <Typography
          align={"left"}
          className={classNames(classes.montserrat, classes.text)}
          color={"textSecondary"}
        >
          {props.text}
        </Typography>
      </Grid>
      <Grid item style={{ margin: "2% 0% 0% 2%", fontSize: "1rem" }}>
        <CustomToolTip>
          <Typography variant={"body1"} color={"textPrimary"}>
            {props.toolTipText}
          </Typography>
        </CustomToolTip>
      </Grid>
    </Grid>
  );
}

function scoresColor(score, classes) {
  let color = classes.red;
  if (score == 0 || (score > 0 && score <= 49)) {
    color = classes.red;
  } else if (score == 50 || (score > 50 && score <= 69)) {
    color = classes.yellow;
  } else if (score >= 70) {
    color = classes.green;
  }
  return color;
}

function NewScoreCard(props) {
  const { classes } = props;
  const onPageScore =
    props.data.onPage != null ? props.data.onPage.onPageScore : 0;
  const mobileScore =
    props.data.mobile != null ? props.data.mobile.mobileScore : 0;
  const speedScore = props.data.speed != null ? props.data.speed.speedScore : 0;
  const linksScore = props.data.links != null ? props.data.links.linksScore : 0;
  const technicalScore =
    props.data.technical != null ? props.data.technical.technicalScore : 0;
  const socialScore =
    props.data.social != null ? props.data.social.socialScore : 0;

  return (
    <Paper
      className={classNames(classes.paper, classes.text)}
      style={{ height: "100%" }}
    >
      <Typography className={classes.text} color={"textPrimary"}>
        <b>SEO Score</b>
      </Typography>
      <br />
      <Grid container style={{ height: "88%" }} alignItems={"center"}>
        <Grid item xs={12} sm={12} md={12}>
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
            style={{ padding: "1%" }}
          >
            <Grid item md={6} sm={6} xs={6}>
              {/*<Tooltip placement={"right-end"} title={
                            <Fragment>
                                <Typography color="inherit">On page scores show how well the site in its entirety
                                    and each
                                    page is optimized.</Typography>
                            </Fragment>
                        }
                        >
                            <Typography align={"left"} className={classNames(classes.montserrat, classes.text)}
                                        color={"textSecondary"}>On Page</Typography></Tooltip>*/}
              <IconText
                text={"On Page"}
                toolTipText={
                  "On page scores show how well the site in its entirety and each page is optimized."
                }
                classes={classes}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
              <Typography
                align={"right"}
                className={classNames(
                  classes.scoreValue,
                  classes.text,
                  scoresColor(onPageScore, classes)
                )}
              >
                {onPageScore}/100
              </Typography>
            </Grid>
          </Grid>
          <Divider className={classes.dashedLine} />
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
            spacing={2}
            style={{ padding: "1%" }}
          >
            <Grid item md={6} sm={6} xs={6}>
              {/*<Typography align={"left"} className={classNames(classes.montserrat, classes.text)}
                                     color={"textSecondary"}>Mobile</Typography>*/}
              <IconText
                text={"Mobile"}
                toolTipText={
                  "Mobile scores tell you how well your site displays on different screen sizes and devices."
                }
                classes={classes}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
              <Typography
                align={"right"}
                className={classNames(
                  classes.scoreValue,
                  classes.text,
                  scoresColor(mobileScore, classes)
                )}
              >
                {mobileScore}/100
              </Typography>
            </Grid>
          </Grid>
          <Divider className={classes.dashedLine} />
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
            spacing={2}
            style={{ padding: "1%" }}
          >
            <Grid item md={6} sm={6} xs={6}>
              {/*<Tooltip placement={"right-end"}
                            title={
                                <Fragment>
                                    <Typography color="inherit">A high score means your site and pages load quickly
                                        across
                                        all devices. The faster your page loads, the more visitors you
                                        get.</Typography>
                                </Fragment>
                            }

                        ><Typography align={"left"} className={classNames(classes.montserrat, classes.text)}
                                     color={"textSecondary"}>Speed</Typography></Tooltip>*/}
              <IconText
                text={"Speed"}
                toolTipText={
                  "A high score means your site and pages load quickly across all devices. The faster your page loads, the more visitors you get."
                }
                classes={classes}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
              <Typography
                align={"right"}
                className={classNames(
                  classes.scoreValue,
                  classes.text,
                  scoresColor(speedScore, classes)
                )}
              >
                {speedScore}/100
              </Typography>
            </Grid>
          </Grid>
          <Divider className={classes.dashedLine} />
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
            spacing={2}
            style={{ padding: "1%" }}
          >
            <Grid item md={6} sm={6} xs={6}>
              {/*<Tooltip placement={"right-end"}
                            title={
                                <Fragment>
                                    <Typography color="inherit">The quality of links, both inbound and outbound, determine page rank. Link score is critical.</Typography>
                                </Fragment>
                            }

                        ><Typography align={"left"} className={classNames(classes.montserrat, classes.text)}
                                     color={"textSecondary"}>Links</Typography></Tooltip>*/}
              <IconText
                text={"Links"}
                toolTipText={
                  "The quality of links, both inbound and outbound, determine page rank. Link score is critical."
                }
                classes={classes}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
              <Typography
                align={"right"}
                className={classNames(
                  classes.scoreValue,
                  classes.text,
                  scoresColor(linksScore, classes)
                )}
              >
                {linksScore}/100
              </Typography>
            </Grid>
          </Grid>
          <Divider className={classes.dashedLine} />
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
            spacing={2}
            style={{ padding: "1%" }}
          >
            <Grid item md={6} sm={6} xs={6}>
              {/*<Tooltip placement={"right-end"} title={
                            <Fragment>
                                <Typography color="inherit">Errors make your pages invisible to bots and visitors.
                                    The more
                                    errors you have, the less visible your page is, which turns into less
                                    traffic.</Typography>
                            </Fragment>
                        }
                        >
                            <Typography align={"left"} className={classNames(classes.montserrat, classes.text)}
                                        color={"textSecondary"}>Technical</Typography></Tooltip>*/}
              <IconText
                text={"Technical"}
                toolTipText={
                  "Errors make your pages invisible to bots and visitors.\n" +
                  "                                    The more\n" +
                  "                                    errors you have, the less visible your page is, which turns into less\n" +
                  "                                    traffic."
                }
                classes={classes}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
              <Typography
                align={"right"}
                className={classNames(
                  classes.scoreValue,
                  classes.text,
                  scoresColor(technicalScore, classes)
                )}
              >
                {technicalScore}/100
              </Typography>
            </Grid>
          </Grid>
          <Divider className={classes.dashedLine} />
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
            spacing={2}
            style={{ padding: "1%" }}
          >
            <Grid item md={6} sm={6} xs={6}>
              {/*<Tooltip placement={"right-end"} title={
                            <Fragment>
                                <Typography color="inherit">Social score help you understand how optimize is your
                                    social
                                    media marketing.</Typography>
                            </Fragment>
                        }
                        >
                            <Typography align={"left"} className={classNames(classes.montserrat, classes.text)}
                                        color={"textSecondary"}>Social
                            </Typography>
                        </Tooltip>*/}
              <IconText
                text={"Social"}
                toolTipText={
                  "Social score help you understand how optimize is your\n" +
                  "                                    social\n" +
                  "                                    media marketing."
                }
                classes={classes}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
              <Typography
                align={"right"}
                className={classNames(
                  classes.scoreValue,
                  classes.text,
                  scoresColor(socialScore, classes)
                )}
              >
                {socialScore}/100
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <br />
    </Paper>
  );
}

NewScoreCard.propTypes = { classes: PropTypes.any };
const scoreCardStyles = (theme) => ({
  progressBarRoot: {
    height: 8,
    borderRadius: 5,
    // color
  },
  red: {
    color: theme.palette.graderGraphColors.red,
  },
  yellow: {
    color: theme.palette.graderGraphColors.yellow,
  },
  green: {
    color: theme.palette.graderGraphColors.green,
  },
  determinate: {
    backgroundColor: theme.palette.graderGraphColors.graphBg,
  },
  customCard: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    boxShadow: "unset",
    backgroundColor: theme.palette.graderGraphColors.graphBg,
  },
  padding2X: { padding: theme.spacing(1) },
  paper: { boxShadow: "unset" },

  montserrat: {
    fontFamily: "Lato",
    opacity: "0.5",
    color: theme.palette.text.primary,
    fontSize: "14px",
  },
  scoreValue: { fontFamily: "Lato" },
  text: { fontFamily: "Lato", fontSize: "14px" },
  subHeading: { fontFamily: "Lato", color: theme.palette.text.primary },
  dashedLine: {
    border: "1px dashed" + theme.palette.common.darkHash,
    backgroundColor: "unset",
    boxSizing: "border-box",
    borderRadius: 10,
    opacity: 0.4,
    height: 0,
  },
});

export default withStyles(scoreCardStyles)(NewScoreCard);
