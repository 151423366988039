import React, { Component } from "react";
import PropTypes from "prop-types";
import { Grid, Typography, Paper } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import { IconPara, IconText, IconMainText } from "./templates";
import icons from "../icons";

class Mobile extends React.PureComponent {
  render() {
    const { classes, siteGrader } = this.props;
    const mobileFriendly =
      siteGrader &&
      siteGrader.mobile != null &&
      siteGrader.mobile.mobileFriendly
        ? siteGrader.mobile.mobileFriendly
        : false;
    const mobileViewPort =
      siteGrader &&
      siteGrader.mobile != null &&
      siteGrader.mobile.mobileViewPort
        ? siteGrader.mobile.mobileViewPort
        : false;

    console.log("mobileFriendly=", mobileFriendly);
    console.log("mobileViewPort=", mobileViewPort);

    return (
      <Paper className={classes.paper}>
        <Typography
          variant={"subheading"}
          className={classes.montserrat}
          color={"textSecondary"}
        >
          {/*<b>Mobile</b>*/}
          <b>
            <IconMainText text={"Mobile"} classes={classes} />
          </b>
        </Typography>
        {/*<Grid container spacing={2} justifyContent={"space-around"}>*/}
        <Grid container spacing={2}>
          <Grid item md={12} alignItems={"left"}>
            <div className={classes.gridItem}>
              <br />
              {/* {mobileFriendly == true ?
                                <IconText icon={icons.checkedSquare} text={"Mobile Friendly"} classes={classes}/>
                                : <IconText icon={icons.closeSquare} text={"Mobile Friendly"} classes={classes}/>
                            }*/}
              <IconText
                text={"Mobile Friendly"}
                classes={classes}
                mobile={mobileFriendly}
              />
              <div className={classes.sec1}>
                {mobileFriendly == true ? (
                  <IconPara
                    icon={icons.mobileFriendly}
                    title={"Responsive Design"}
                    text={
                      "Google recognizes your site\n" +
                      "as having a mobile friendly\n" +
                      "responsive design."
                    }
                    classes={classes}
                    des={
                      "Google recommends a responsive website design pattern. A responsive website design automatically changes to fit the device you're reading it on and provides a seamless user experience."
                    }
                    mobile={mobileFriendly}
                  />
                ) : (
                  <IconPara
                    icon={icons.mobileFriendlyNot}
                    title={"Not optimized"}
                    text={
                      "Your site does not have a responsive design as per Google's API."
                    }
                    classes={classes}
                    des={
                      "Google recommends a responsive website design pattern. A responsive website design automatically changes to fit the device you're reading it on and provides a seamless user experience."
                    }
                    mobile={mobileFriendly}
                  />
                )}
              </div>
            </div>
            <br />
            <div className={classes.gridItem}>
              <br />
              {/*{mobileViewPort == true ?
                                <IconText className={classes.sec1} icon={icons.checkedSquare}
                                          text={"Mobile Viewport"}
                                          classes={classes} mobile={mobileViewPort}
                                /> :*/}
              <IconText
                className={classes.sec1}
                text={"Mobile Viewport"}
                classes={classes}
                mobile={mobileViewPort}
              />
              {/*}*/}

              <div className={classes.sec1}>
                {mobileViewPort == true ? (
                  <IconPara
                    icon={icons.mobileViewPort}
                    title={"Your site has a meta viewport tag set"}
                    text={
                      "Google recognizes your site\n" +
                      "as having a mobile friendly\n" +
                      "responsive design."
                    }
                    classes={classes}
                    des={
                      "The meta viewpoint tag lets you control your page width and scale on different devices types."
                    }
                    mobile={mobileViewPort}
                  />
                ) : (
                  <IconPara
                    icon={icons.mobileViewPortNot}
                    title={"Not optimized"}
                    text={"Your site does not have a meta viewport tag set"}
                    classes={classes}
                    des={
                      "The meta viewport tag lets you control your page width and scale on different devices types."
                    }
                    mobile={mobileViewPort}
                  />
                )}
              </div>
            </div>
            <br />
          </Grid>
          {/*<Grid item md={5}>*/}
          {/*<div className={classes.gridItem}>*/}
          {/*<br/>*/}
          {/*/!*{mobileViewPort == true ?*/}
          {/*<IconText className={classes.sec1} icon={icons.checkedSquare}*/}
          {/*text={"Mobile Viewport"}*/}
          {/*classes={classes} mobile={mobileViewPort}*/}
          {/*/> :*!/*/}
          {/*<IconText className={classes.sec1}*/}
          {/*text={"Mobile Viewport"}*/}
          {/*classes={classes} mobile={mobileViewPort}*/}
          {/*/>*/}
          {/*/!*}*!/*/}

          {/*<div className={classes.sec1}>*/}
          {/*{mobileViewPort == true ?*/}
          {/*<IconPara icon={icons.mobileViewPort}*/}
          {/*title={"Your site has a meta viewport tag set"} text={*/}
          {/*"Google recognizes your site\n" +*/}
          {/*"as having a mobile friendly\n" +*/}
          {/*"responsive design."}*/}
          {/*classes={classes}*/}
          {/*des={"The meta viewpoint tag lets you control your page width and scale on different devices types."}*/}
          {/*mobile={mobileViewPort}*/}
          {/*/> :*/}
          {/*<IconPara icon={icons.mobileViewPortNot} title={"Not optimized"} text={*/}
          {/*"Your site does not have a meta viewport tag set"}*/}
          {/*classes={classes}*/}
          {/*des={"The meta viewport tag lets you control your page width and scale on different devices types."}*/}
          {/*mobile={mobileViewPort}*/}
          {/*/>}*/}
          {/*</div>*/}
          {/*</div>*/}
          {/*<br/>*/}
          {/*</Grid>*/}
        </Grid>
      </Paper>
    );
  }
}

const styles = (theme) => ({
  montserrat: {
    fontFamily: "Montserrat",
  },
  title: {
    color: theme.palette.text.secondary,
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: 16,
  },
  iconG: {
    color: theme.palette.greenCheck,
    fontSize: 24,
  },
  iconR: {
    color: theme.palette.redText,
    fontSize: 24,
  },
  titleGreen: {
    color: theme.palette.textGreen,
  },
  titleRed: {
    color: theme.palette.redText,
  },
  titlePara: {
    // color: theme.palette.textGreen,
    fontFamily: "Montserrat",
    fontWeight: "bold",
  },
  para: {
    fontFamily: "Montserrat",
    fontWeight: "400",
    color: theme.palette.dialogText,
    fontSize: 14,
  },
  des: {
    fontFamily: "Montserrat",
    fontWeight: "400",
    color: theme.palette.dialogText,
  },
  paper: {
    boxShadow: "unset",
    padding: theme.spacing(1),
    height: "34em",
  },
  gridItem: {
    // paddingLeft: theme.spacing(2)
  },
  sec1: {
    paddingLeft: theme.spacing(2),
  },
});
Mobile.propTypes = {};

export default withStyles(styles)(Mobile);
