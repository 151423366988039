import Datamap from "datamaps/dist/datamaps.usa.min";
import React, { useEffect } from "react";
import d3 from "d3";
import { DefaultRegionData } from "./Map";
import objectAssign from "object-assign";
import * as _ from "lodash";
import getSymbolFromCurrency from "currency-symbol-map";

import { numbersFormat } from "./Map";

export default class DataMap extends React.Component {
  constructor(props) {
    super(props);
    this.datamap = null;
  }

  linearPalleteScale(value) {
    const dataValues = _.values(
      _.mapValues(this.props.regionData, (data) => {
        return data.kpiMetric;
      })
    );
    const minVal = Math.min(...dataValues);
    const maxVal = Math.max(...dataValues);
    // /!* main dark code is used here*!/
    // return d3.scale.linear().domain([minVal, maxVal]).range(["#EFEFFF", "#02386F"])(value);
    return d3.scale
      .linear()
      .domain([minVal, maxVal])
      .range(["#DE5F6D", "#781F29"])(value);
  }

  reducedData() {
    const dataValues = _.map(
      _.mapValues(this.props.regionData),
      (object, data) => {
        return data.kpiMetic;
      }
    );
    const newData = _.keyBy(
      _.map(this.props.regionData, (object, code) => ({
        ...object,
        fillColor: this.linearPalleteScale(object.kpiMetric),
      })),
      "code"
    );
    // console.log("newData==", newData)
    return objectAssign({}, DefaultRegionData, newData);
  }

  renderMap() {
    const handleCallBack = this.props.handleCallBack;
    const kpiType = this.props.kpiType;
    const regionData = this.props.regionData;
    const currency = this.props.currency;
    const width = this.props.width;
    const data = this.props.data;
    const selectedRegion = this.props.selectedRegion;
    return new Datamap({
      element: document.getElementById("datamap-container"),
      position: "relative",
      scope: "usa",
      width: width == "sm" || width == "xs" ? 360 : 500,
      data: this.reducedData(),
      fills: {
        defaultFill: "#aaaaaa",
      },
      geographyConfig: {
        borderWidth: 0.5,
        highlightFillColor: "#FFCC80",
        popupTemplate: function (geography, data) {
          return (
            '<div class="hoverinfo"><strong>' +
            geography.properties.name +
            "</strong><br><strong>Revenue:  " +
            getSymbolFromCurrency(currency) +
            data.kpiMetric +
            "</strong><br><strong>PPC Spend: " +
            getSymbolFromCurrency(currency) +
            data.cost +
            "</strong></div>"
          );
        },
        popupOnHover: true,
      },
    });
  }

  currentScreenWidth() {
    return (
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    );
  }

  componentDidMount() {
    const mapContainer = d3.select("#datamap-container");
    const initialScreenWidth = this.currentScreenWidth();
    const containerWidth =
      initialScreenWidth < 500
        ? {
            width: initialScreenWidth + "px",
            height: initialScreenWidth * 0.5625 + "px",
          }
        : { width: "500px", height: "350px", margin: "0 auto" };

    mapContainer.style(containerWidth);
    this.datamap = this.renderMap();
  }

  render() {
    return <div id="datamap-container" style={{ position: "relative" }} />;
  }
}
