import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import {
  Box,
  Grid,
  Typography,
  Drawer,
  Button,
  IconButton,
  TablePagination,
  Snackbar,
  Alert,
  Pagination,
  PaginationItem,
  Skeleton,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RefreshIcon from "@mui/icons-material/Refresh";
import RenameCallout from "./renameCallout.jsx";
import { JsOrangeContainedBtn } from "../../../components/buttons.jsx";
import StyledTableCell from "../commonComponents/styledTableCell.js";
import "../jumpstart.css";
import {
  fetchCallouts,
  generateNewCallouts,
  saveCallouts,
} from "../../../actions/jumpstartRevamped.js";

function Callouts(props) {
  const {
    handleSitelinkShow,
    id,
    token,
    fetchCallouts,
    fetchCalloutsReducer,
    generateNewCallouts,
    generateNewCalloutsReducer,
    saveCallouts,
    saveCalloutsReducer,
    handleSectionChange,
    nextId,
    disableStructure,
  } = props;

  const [callouts, setCallouts] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCalloutForEdit, setSelectedCalloutForEdit] = useState(null);
  const [selectedCallouts, setSelectedCallouts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  //Snackbar
  const [snackbarMsg, setSnackbarMsg] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [error, setError] = useState(false);

  //Generate Snackbar
  const [generateError, setGenerateError] = useState(false);
  const [generateSnackbarMsg, setGenerateSnackbarMsg] = useState();
  const [generateSnackbarOpen, setGenerateSnackbarOpen] = useState(false);

  const calloutCharLimit = 25;

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(false);
    setSnackbarOpen(false);
  };

  const handleGenerateSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setGenerateError(false);
    setGenerateSnackbarOpen(false);
  };

  useEffect(() => {
    if (callouts && callouts.length > 0) {
      const indexOfLastItem = page * rowsPerPage;
      const indexOfFirstItem = indexOfLastItem - rowsPerPage;
      const currentItems = callouts.slice(indexOfFirstItem, indexOfLastItem);
      setTotalPages(Math.ceil(callouts.length / rowsPerPage));
    }
  }, [callouts]);

  const fetchCalloutsData = async () => {
    setLoading(true);
    const data = await fetchCallouts(token, id);

    if (data && data.status == 200) {
      const { status, ...calloutsData } = data;
      const allCallouts = Object.values(calloutsData);
      setCallouts(allCallouts);
      const saveSelectedCallouts = allCallouts.filter(
        (callout) => callout.selected
      );
      setSelectedCallouts(saveSelectedCallouts);
    } else {
      setError("No data found");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchCalloutsData();
  }, []);

  const handleGenerateCallouts = async () => {
    handleSaveCallouts(token, callouts);
    setLoading(true);
    const data = await generateNewCallouts(token, id);
    if (data && data.status == 200) {
      setGenerateError(false);
      setGenerateSnackbarMsg("Successfully generated new callouts");
      await fetchCalloutsData();
    } else {
      setGenerateError(true);
      setGenerateSnackbarMsg("Error in generating new callouts");
    }
    setLoading(false);
    setGenerateSnackbarOpen(true);
  };

  const handleSaveAPICall = async (callouts) => {
    return await saveCallouts(token, callouts);
  };

  const handleSaveCallouts = async () => {
    const data = await handleSaveAPICall(selectedCallouts);
    if (data && data.status) {
      setError(false);
      setSnackbarMsg("Callouts saved");
    } else {
      setError(true);
      setSnackbarMsg("Error in saving callouts");
    }
    setSnackbarOpen(true);
  };

  const handleCheckboxChange = (event, row) => {
    const isChecked = event.target.checked;

    setSelectedCallouts((prevCallouts) => {
      const updatedCallouts = [...prevCallouts];
      const index = updatedCallouts.findIndex(
        (selectedRow) => selectedRow.id === row.id
      );
      if (index === -1) {
        updatedCallouts.push({ ...row, selected: true });
      } else {
        updatedCallouts[index] = { ...row, selected: isChecked };
      }

      return updatedCallouts;
    });
  };

  const toggleDrawer = (row, index) => () => {
    setSelectedCalloutForEdit(row);
    setIsOpen(true);
  };

  const handleCloseIcon = () => {
    setIsOpen(false);
  };

  const handleUpdateCallout = async (updatedCallout) => {
    const updatedCallouts = callouts.map((callout) =>
      callout.id === updatedCallout.id ? updatedCallout : callout
    );
    setCallouts(updatedCallouts);
    let newCalloutArray = [];
    newCalloutArray.push(updatedCallout);
    const data = await handleSaveAPICall(newCalloutArray);
    if(data && data.status == 200){
      setError(false);
      setSnackbarMsg("Callout updated");
    }
    else{
      setError(true);
      setSnackbarMsg("Error in updating callout");
    }
    setSnackbarOpen(true);
  };

  const handleChangePage = (event, newPage) => {
    newPage = newPage - 1;
    setPage(newPage);
  };

  // const visibleRows = useMemo(
  //   () => callouts.length > 0 && callouts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
  //   [page, rowsPerPage, callouts]
  // );

  const [visibleRows, setVisibleRows] = useState([]);

  useEffect(() => {
    if (callouts.length > 0) {
      const startIndex = page * rowsPerPage;
      const endIndex = Math.min(startIndex + rowsPerPage, callouts.length);
      setVisibleRows(callouts.slice(startIndex, endIndex));
    } else {
      setVisibleRows([]);
    }
  }, [page, callouts]);

  return (
    <>
      <Grid container direction="column" style={{ padding: "0 2%" }}>
        <Grid item xs={12}>
          <Typography variant="subtitle2">Select at least 4 callouts</Typography>
        </Grid>

        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          xs={12}
          style={{
            padding: "0",
            marginTop: "2%",
            backgroundColor: "#1E334E",
          }}
        >
          <Grid item xs={10}>
            <IconButton
              aria-label="generate-new-callouts"
              onClick={() => handleGenerateCallouts()}
              disabled={disableStructure}
            >
              <Typography variant="subtitle2" style={{ color: "#fff" }}>
                Generate Callouts
              </Typography>
              <Tooltip title="Click to generate new callouts">
                <RefreshIcon fontSize="small" style={{ color: "#fff" }} />
              </Tooltip>
            </IconButton>
          </Grid>
          <Grid item xs={2}>
            <Typography
              variant="subtitle2"
              align="right"
              style={{ color: "#fff", paddingRight: "10px" }}
            >
              Selected: {selectedCallouts.filter((row) => row.selected).length}
              /6
            </Typography>
          </Grid>
        </Grid>
        {loading ? (
          <>
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </>
        ) : (
          <Grid item xs={12}>
            {callouts != undefined && callouts.length > 0 ? (
              <TableContainer
                component={Paper}
                style={{ boxShadow: "none", border: 0 }}
              >
                <Table aria-label="customized table">
                  <TableBody>
                    {visibleRows.map((row, index) => (
                      <TableRow key={index} tabIndex={-1}>
                        <StyledTableCell>
                          <Typography fontSize="0.875rem" color="#0D61CE">
                            <Checkbox
                              size="small"
                              disabled={
                                selectedCallouts.filter(
                                  (selectedRow) => selectedRow.selected
                                ).length >= 6 &&
                                !selectedCallouts.some(
                                  (selectedRow) =>
                                    selectedRow.id === row.id &&
                                    selectedRow.selected
                                ) || (row.text.length > calloutCharLimit) || disableStructure
                              }
                              checked={selectedCallouts.some(
                                (selectedRow) =>
                                  selectedRow.id === row.id &&
                                  selectedRow.selected
                              )}
                              onChange={(e) => handleCheckboxChange(e, row)}
                              color="secondary"
                            />
                            {row.text}
                            <IconButton
                              onClick={toggleDrawer(row, index)}
                              size="small"
                              disabled={disableStructure}
                            >
                              <EditOutlinedIcon
                                fontSize="small"
                                sx={{ color: "#1E334E" }}
                              />
                            </IconButton>
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell
                          align="right"
                          style={{ color: "#A4A4A4" }}
                        >
                          Char Count:&nbsp;
                          <Tooltip title={row.text.length > calloutCharLimit ? "Callout is more than 25 characters" : ""}>
                            <span
                              style={{
                                color:
                                  row.text.length > calloutCharLimit
                                    ? "red"
                                    : "#747474",
                              }}
                            >
                              {row.text ? row.text.length : 0}
                            </span>
                          </Tooltip>
                          <span style={{ color: "#747474" }}>
                            /{calloutCharLimit}
                          </span>
                        </StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <div className="structureContent">
                <Typography align="center">No data to show</Typography>
              </div>
            )}
          </Grid>
        )}

        <Grid
          container
          direction="column"
          rowSpacing={1}
          justifyContent="center"
          alignItems="center"
          style={{ paddingTop: "2%" }}
        >
          {loading ? (
            ""
          ) : (
            <Grid item xs={12}>
              <Pagination
                count={totalPages}
                defaultPage={1}
                onChange={handleChangePage}
                showFirstButton
                showLastButton
              />
            </Grid>
          )}

          <Grid
            item
            container
            xs={12}
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            columnSpacing={2}
          >
            <Grid item>
              <Button
                variant="text"
                sx={{ textTransform: "none" }}
                disabled={selectedCallouts.length == 0 ? true : false || disableStructure}
                onClick={() => handleSaveCallouts()}
              >
                <SaveOutlinedIcon
                  sx={{ color: "#F58120", paddingRight: "2px" }}
                />
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}
      >
        <Box sx={{ width: 550 }}>
          <RenameCallout
            callout={selectedCalloutForEdit}
            handleCloseIcon={handleCloseIcon}
            handleUpdateCallout={handleUpdateCallout}
          />
        </Box>
      </Drawer>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={error ? "error" : "success"}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarMsg}
        </Alert>
      </Snackbar>

      <Snackbar
        open={generateSnackbarOpen}
        autoHideDuration={5000}
        onClose={handleGenerateSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleGenerateSnackbarClose}
          severity={generateError ? "error" : "success"}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {generateSnackbarMsg}
        </Alert>
      </Snackbar>
    </>
  );
}

const mapStateToProps = (state) => ({
  fetchCalloutsReducer: state.fetchCalloutsReducer,
  generateNewCalloutsReducer: state.generateNewCalloutsReducer,
  saveCalloutsReducer: state.saveCalloutsReducer,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCallouts: (token, id) => dispatch(fetchCallouts(token, id)),
  generateNewCallouts: (token, id) => dispatch(generateNewCallouts(token, id)),
  saveCallouts: (token, callouts) => dispatch(saveCallouts(token, callouts)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Callouts);
