import { createTheme } from "@mui/material/styles";
import * as types from "../actions/actionTypes";

let fontType = ["Lato", "Montserrat", "Roboto", "sans-serif"];
/* if (window.lxrOptions && window.lxrOptions.defaultRoute && window.lxrOptions.defaultRoute == 'requestDemo' && window.lxrOptions.defaultRoute == types.ADMIN_USER_TABLE_ROUTE ||
    window.lxrOptions && window.lxrOptions.defaultRoute && window.lxrOptions.defaultRoute == 'GOOGLE_ADS_GRADER_HOME_PAGE'
) {
    fontType = [
        'Montserrat',
        'Roboto',
        'sans-serif',
    ]
} */

const mainColor = "#1E334E";
const paperBg = "rgba(255,255,255,0.05)";
const border = "#E3E6F4";
const line = "#A2B6C4";
const menuHover = "#b4c4cf";
const menuLine = "#EDEFF5";
const pieChart1 = "#F58020";
const pieChart2 = "#8531C8";
const liteLine = "rgba(162, 182, 196, 0.5)";
const labelAvatar = "rgba(30, 51, 78, 0.1)";
const taskDescription = `#F2F3F9`;
const tab = `#CDD0D7`;
const disableBorder = `#fafafa`;
const buttonHover = `#E16904`;
const graphColor = `#A2B6C3`;
const barColor = `#1098F7`;
const graphLineColor = `#CF0505`;
const unRead = `#E2ECF3`;
const graderBg = `#ECEFF3`;
const conditionBg = `#E9E9E9`;
const theme = createTheme({
  // breakpoints: {
  //   values: {
  //     xs: 0,
  //     sm: 600,
  //     md: 960,
  //     lg: 1280,
  //     xl: 1920,
  //   },
  // },
  typography: {
    fontFamily: fontType.join(","),
    h1: {
      // 32px
      // 36px
      fontSize: "2.25rem",
    },
    h2: {
      // 24px
      fontSize: "1.5rem",
    },
    h3: {
      // 20px
      fontSize: "1.25rem",
    },
    h4: {
      // 18px
      fontSize: "1.125rem",
    },
    // body1
    // 16px
    body1: {
      fontSize: "1rem",
    },
    subtitle1: {
      fontSize: "2.75rem",
      lineHeight: "0 rem",
    },
    // display: {
    //   fontSize: "2.75rem",
    // },
    // body2
    //    14px
    //    caption below 14px
  },
  palette: {
    common: {
      greyWhite: `${paperBg}`,
      border: `${border}`,
      darkHash: `${line}`,
      liteOrange: `${pieChart1}`,
      litePurple: `${pieChart2}`,
      liteGrey: `${liteLine}`,
      whiteGrey: `${labelAvatar}`,
      shadyGrey: `${taskDescription}`,
      lighterGrey: `${menuLine}`,
      whiterGrey: `${labelAvatar}`,
      mediumGrey: `${tab}`,
      disableGrey: `${disableBorder}`,
      darkGrey: `${graphColor}`,
      darkOrange: `${buttonHover}`,
      lightBlue: `${barColor}`,
      lightRed: `${graphLineColor}`,
      skyBlue: `${unRead}`,
      darkGreybg: `${graderBg}`,
      whiteGrey: `${conditionBg}`,
    },
    boxShadowC: "rgba(0, 0, 0, 0.06)",
    suggestedCPC: "#4CD137",
    budget: "#35A8EA",
    disable: "#E0E0E0",
    counterDisplay: "#FFCE32",
    counterIcon: "#FFF8E2",
    counterIconHover: "#D5D5D5",
    mobileMenuBg: "#28b3b6",
    // info: "#E0E0E0",
    // success: "#6FCF97",
    unSuccess: "#E31A1C",
    trailError: "#EB5757",
    graderWarning: "#d32f2f",
    alertHeaderBg: "#FFCE32",
    alertContentBg: "#FFF3CC",
    Bid: "#24AB73",
    newBadge: "#0b8043",
    bidModifier: "#F58120",
    orangeColor: "#F58120",
    orange2Color: "#fc761e",
    darkOrange: "#b24901",
    postAppBar: "#A5B1C2",
    checkedStatus: "#8A95A4",
    taskBtn: "#1E334E",
    formFieldsBg: "#F1F1F1",
    dialogText: "#4F4F4F",
    select: "#E8EBED",
    border: "#C4C4C4",
    safeAndSecure: "#898989",
    blueLink: "#121EA4",
    footerBg2: "#121315",
    footerTxt: "#888",
    footerBg: "#1B1C25",
    urlPathLink: "#499033",
    hyperLink: "#2F80ED",
    alertInfo: "#1976d2",
    liteBlack: "#32363a",
    // warning: "#ffa000",
    pricingPlanTagLine: "#1b5e20",
    recommendationsBg: "#FFFAF1",
    /*warning:"#F3B412",*/
    graderGraphColors: {
      yellow: "#F1C40F",
      red: "#EB3223",
      green: "#4CAF50",
      graphBg: "#E6E6E6",
      text: "#333333",
    },
    darKYellow: "#f1b10f",
    speedMeter: {
      green: "#219653",
      lightGreen: "#5DD18D",
      lightCream: "#F5A77E",
      red: "#EB5757",
      lightRed: "#FFCFCF",
      yellow: "#FFCC06",
    },
    lightRedPink: "#FFEEDA",
    lightRedColor: "#FAE6E6",
    graderBg: "#F5F6FA",
    graderAppBarBg: "#343031",
    green: "#7BC658",
    conversionAlertBg: "rgba(52, 48, 49, 0.7)",
    conversionAlertText: "#F7931E",
    /*warning:"#F3B412",*/
    lightgrey: "#627083",
    trailText: "#909090",
    errorBg: "#E5E5E5",
    redBg: "#F67166",
    creamBg: "#EFAD77",
    greenCheck: "#27AE60",
    textGreen: "#219653",
    redText: "#EB3223",
    lightB: "#648EBC",
    summaryText: "#6F7075",
    dasboardHomeText: "#0055FF",
    progressBars: "#1098F7",
    selected: "#F58020",
    taskfb: "#F2F3F9",
    taskfd2: "#E3E6F4",
    dropdownBg: "#182C45",
    primary: {
      light: "#4b5b71",
      main: `${mainColor}`,
      dark: "#152336",
    },
    secondary: {
      light: "#ab5a16",
      main: "#F58120",
      dark: "#f79a4c",
    },
    text: {
      primary: "#1E334E",
      secondary: "#333333",
    },
    success1: {
      light: "#4bc980",
      main: "#1EBC61",
      dark: "#158343",
    },
    error: {
      light: "#d83737",
      main: "#CF0505",
      dark: "#900303",
    },
  },
});

export default theme;
