import React, { Fragment, useEffect, useState } from "react";
import { formatOptions } from "./cart-container";
import { data } from "./view-details-data";
import CurrencySymbol from "../../components/currencySymbol.jsx";
import { RenderTableCells, RenderHeader } from "./templates";
import { CustomTableCell, CustomTableRow, CustomTable } from "./cart-container";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import { WhiteBtn, BlueBtn } from "../buttons";
import withStyles from "@mui/styles/withStyles";
import { PublishOrangeContainedBtn, PopUpCommonBtn } from "../buttons";

function CartDetails(props) {
  const {
    grandTotal,
    currentCounts,
    currentTotals,
    handleReset,
    handleCount,
    saveCart,
    isDrawerOpen,
    classes,

  } = props;
  console.log("props in cart container are below ", props);
  let companyName = "";
  let companyURL ="";
  
  if (props.userInfo && props.userInfo.userInfo != undefined) {
    companyName = props.userInfo.userInfo.companyName;
    console.log("props in cart container are below companyName ", companyName);
  }
  else {
    companyName = "your"
    console.log("props in cart container are below companyName fal", companyName);
  }
  if (props.userInfo && props.userInfo.userInfo != undefined) {

    companyURL = props.userInfo.userInfo.clientInfo[0].clientWebsite;
  }
  else {
    companyURL = "your website"
  }
  return (
    <Grid item sm={12} xs={12} md={10} lg={10} xl={10}>
      <Fragment>
        <Paper style={{ padding: "25px" }}>
          {data.filter((data) => data.type == "header")
            .map((dataObj, index) => {
              return (
                <div style={{ paddingTop: 15 }}>
                  <RenderHeader dataObj={dataObj} />
                  <CustomTable>
                    <TableHead>
                      <TableRow>
                        <CustomTableCell>Service</CustomTableCell>
                        <CustomTableCell>Unit Price</CustomTableCell>
                        <CustomTableCell>{dataObj.timePeriod}</CustomTableCell>
                        <CustomTableCell
                          style={{ width: isDrawerOpen ? "33%" : "28%" }}
                        ></CustomTableCell>
                        <CustomTableCell numeric align="right" style={{ width: "12%" }} >
                          Total
                        </CustomTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.map((dataObj1, index) => {
                        return (
                          <Fragment key={index}>
                            {dataObj.category == dataObj1.category ? (
                              <Fragment>
                                <RenderTableCells
                                  dataObj={dataObj1}
                                  currentCounts={currentCounts}
                                  currentTotals={currentTotals}
                                  handleCount={(data) => handleCount(data)}
                                  counter={true}
                                  companyName={companyName}
                                  companyURL={companyURL}
                                  onDemandPackagesPlanPrice={props.userInfo}
                                />
                              </Fragment>
                            ) : null}
                          </Fragment>
                        );
                      })}
                    </TableBody>
                  </CustomTable>
                </div>
              );
            })}
          <Grid
            container
            component={"span"}
            direction="row"
            justifyContent={"space-between"}
            alignItems="center"
            style={{ borderTop: "1px solid #8F99A7", padding: 10 }}
          >
            <Grid item>
              <PopUpCommonBtn
                variant={"outlined"}
                style={{ margin: 5, padding: "7px 25px" }}
                onClick={handleReset()}
                disabled={grandTotal == 0}
              >
                RESET
              </PopUpCommonBtn>
              <PublishOrangeContainedBtn
                variant="contained"
                style={{ margin: 5 }}
                onClick={() => saveCart(grandTotal)}
                disabled={grandTotal == 0}
              >
                PROCEED
              </PublishOrangeContainedBtn>
            </Grid>
            <Grid item>
              <Typography variant="body1" gutterBottom align="left">
                <b>Grand Total:&nbsp; ${grandTotal.toLocaleString(
                  "en-US"
                )}</b>
              </Typography>
            </Grid>
          </Grid>
          {/*</CustomTableCell>*/}
          {/*</CustomTableRow>*/}
        </Paper>
      </Fragment>
    </Grid>
  );
}

const style = (theme) => ({
  paper: {
    boxShadow: "unset",
    border: "1px solid #8F99A7",
    [theme.breakpoints.down("xl")]: {
      overflowX: "scroll",
      width: "90%",
    },
  },
});

export default withStyles(style)(CartDetails);
