import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "../jumpstart.css";
import { JsSectionTypography } from "../../../components/typography";
import { Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { JsAddUserLabelTypography } from "../../../components/typography";
import { JsBlackContainedBtn } from "../../../components/buttons";

function AssignAccounts(props) {
  const { handleCloseDrawer, handleAssignUsers } = props;
  // const {handleSe}
  const [analyst, setAnalyst] = useState([]);
  const [reviewer, setReviewer] = useState([]);

  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        style={{ padding: "2%", borderBottom: "1px solid #E6E6E6" }}
      >
        <Grid item xs={11}>
          <JsSectionTypography style={{ fontSize: "1rem" }}>
            Assign User(s) to Account
          </JsSectionTypography>
        </Grid>
        <Grid item xs={1}>
          <IconButton aria-label="close" onClick={handleCloseDrawer}>
            <CloseIcon color="secondary" />
          </IconButton>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        columnSpacing={2}
        rowSpacing={2}
        style={{ padding: "2%" }}
      >
        <Grid item xs={12}>
          <label htmlFor="analysts">
            <JsAddUserLabelTypography>
              <span className="numberContainer">1</span>Select Analysts (upto
              2):
            </JsAddUserLabelTypography>
          </label>
        </Grid>
        <Grid item xs={12}></Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(AssignAccounts);
