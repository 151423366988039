import React, { useState } from "react";
import { Grid, MenuItem } from "@mui/material";
import { CustomGrid } from "./adgroup-from";
import { ActionButtons } from "./templates";
import { connect } from "react-redux";
import { saveOnDemandFroms, updateFromInputs } from "../../actions";
import * as _ from "lodash";
import { InputField } from "../input-forms";

function CommonFrom1(props) {
  const [state, setState] = useState({
    ...props.initialValue,
    adgroupThemeError: "",
    campaignError: "",
  });

  const validate = () => {
    let isError = false;
    const errors = {};
    if (state.campaign == "" && state.campaign.length == 0) {
      isError = true;
      errors.campaignError = "This Field is required";
    } else {
      errors.campaignError = "";
    }
    if (state.adgroupTheme == "" && state.adgroupTheme.length == 0) {
      isError = true;
      errors.adgroupThemeError = "This Field is required";
    } else {
      errors.adgroupThemeError = "";
    }
    if (isError) {
      setState({
        ...state,
        ...errors,
      });
    }
    return isError;
  };

  // const handleChange = (name) => (event) => {
  //   event.preventDefault();
  //   handleChange();
  //   setState((prevState) => ({
  //     ...prevState,
  //     [name]: event.target.value,
  //   }));
  // };

  const handleChange = (name) => (event) => {
    event.preventDefault();
    // handleChange();
    setState((prevState) => ({
      ...prevState,
      [name]: event.target.value,
    }));
    if (name == "campaign") {
      let adgroupMap = _.filter(
        _.keyBy(props.accountStructureDetails.adGroupList, "adGroupId"),
        (item) => item.campaignName == event.target.value
      );
      console.log("onchange adgrp list", adgroupMap);
      setState((prevState) => ({
        ...prevState,
        adgroupMap: adgroupMap,
        adgroupTheme: "",
      }));
    }
  };

  let miniService;
  const onSubmit = async (preNext) => {
    console.log("form state", state);
    const { handleNext } = props;
    let error;
    const { campaign, adgroupTheme, adgroupMap } = state;
    const { label, type } = props;
    if (preNext == true) {
      error = validate();
    }
    if (!error) {
      await props.updateInputs(
        label,
        type,
        campaign,
        adgroupTheme,
        null,
        null,
        adgroupMap
      );
      miniService = {
        ...miniService,
        serviceDetails: _.groupBy(props.fromInputs, "type"),
      };
      miniService = { ...miniService, grandTotal: props.grandTotal };
      miniService = { ...miniService, itemCounts: props.itemCounts };
      props.saveInputsToServer(miniService);

      if (props.activeStep === props.steps.length - 1 && preNext != false) {
        props.handleMultiPageAndSteppers(null, handleNext);
      } else {
        props.handleMultiPageAndSteppers(preNext);
      }
    }
  };
  return (
    <Grid container className={"fullHeight"}>
      <CustomGrid item className={"fullWidth"} style={{ height: "90%" }}>
        <InputField
          variant="standard"
          id="standard-name"
          label="Which Campaign would you want to add the ad group to"
          select
          fullWidth
          margin="normal"
          value={state.campaign}
          onChange={handleChange("campaign")}
          name="campaign"
          error={state.campaignError != ""}
          helperText={state.campaignError}
          InputLabelProps={{
            shrink: true,
          }}
          required
        >
          <MenuItem key={1} value="">
            <em>None</em>
          </MenuItem>
          {_.map(props.accountStructureDetails.campaignsMap, (value, key) => {
            return (
              <MenuItem key={key} value={value}>
                {value}
              </MenuItem>
            );
          })}
        </InputField>
        <br />
        <InputField
          variant="standard"
          id="standard-name"
          label="Which Adgroup would you like you add"
          select
          fullWidth
          margin="normal"
          value={state.adgroupTheme}
          onChange={handleChange("adgroupTheme")}
          name="adgroupTheme"
          InputLabelProps={{
            shrink: true,
          }}
          error={state.adgroupThemeError != ""}
          helperText={state.adgroupThemeError}
          required
        >
          <MenuItem key={2} value="">
            <em>None</em>
          </MenuItem>
          {_.map(state.adgroupMap, (value, key) => {
            return (
              <MenuItem key={key} value={value.adGroupName}>
                {value.adGroupName}
              </MenuItem>
            );
          })}
        </InputField>
      </CustomGrid>
      <ActionButtons
        activeStep={props.activeStep}
        onSubmit={(value) => onSubmit(value)}
        steps={props.steps}
      />
    </Grid>
  );
}

const mapStateToProps = (state, ownProps) => ({
  initialValue: state.inputFroms[ownProps.label],
  accountStructureDetails: state.accountStructureDetails,
  itemCounts: state.miniServiceItemCount,
  fromInputs: state.inputFroms,
});
const mapDispatchToProps = (dispatch) => ({
  updateInputs: (
    label,
    type,
    campaign,
    adgroupTheme,
    businessDetails,
    url,
    adgroupMap
  ) => {
    return dispatch(
      updateFromInputs(
        label,
        type,
        campaign,
        adgroupTheme,
        businessDetails,
        url,
        adgroupMap
      )
    );
  },
  saveInputsToServer: (miniService) => {
    return dispatch(saveOnDemandFroms(miniService));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CommonFrom1);
