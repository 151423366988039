import React, { PureComponent } from "react";
import {
  Typography,
  Grid,
  Card,
  CardContent,
  ListItem,
  ListItemText,
  ListItemIcon,
  List,
} from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import Home_bg from "../assets/img/home_1.png";
import Paper from "@mui/material/Paper";
import Input from "@mui/material/Input";
import {
  SessionErrorTypography,
  ErrorSmallTypography,
} from "../components/typography";
import { connect } from "react-redux";
import { getDomainInfo } from "../actions";
import CircularProgress from "@mui/material/CircularProgress";
import { OrangeBtnNew, WhiteAndOrangeText } from "../components/buttons";
import icons from "../components/icons";
import classNames from "classnames";
import { EXTERNAL_SEO } from "../actions/actionTypes";

const PlainInput = withStyles((theme) => ({
  root: {
    fontWeight: "bold",
  },
  underline: {
    "&::before": {
      borderBottom: "1px solid " + "#A0A0A0",
    },
    "&:hover": {
      borderBottom: "1px solid #fff",
    },
    "&:after": {
      borderBottom: "2px solid " + theme.palette.orangeColor,
    },
  },
}))(Input);
const CustomListItem = withStyles((theme) => ({
  root: {
    paddingTop: 1,
    paddingBottom: 1,
    alignItems: "baseline",
  },
}))(ListItem);
const CustomListItemText = withStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    paddingLeft: 2,
  },
}))(ListItemText);

class SeoSiteGraderHomePage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      domainName: "",
      domainNameError: "",
      errorMsg:
        window.lxrOptions && window.lxrOptions.errorMsg
          ? window.lxrOptions.errorMsg
          : null,
    };
  }

  validate = () => {
    let isError = false;
    const errors = {};

    // const UrlRegex = /^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}$/;
    const UrlRegex =
      /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
    if (
      this.state.domainName.length == 0 ||
      this.state.domainName.trim() == ""
    ) {
      isError = true;
      errors.domainNameError = "This field is required";
    } else if (
      this.state.domainName.length != 0 &&
      !UrlRegex.test(this.state.domainName)
    ) {
      isError = true;
      errors.domainNameError = "URL Format Wrong";
    } else {
      errors.domainNameError = "";
    }

    if (isError) {
      this.setState({
        ...this.state,
        ...errors,
      });
    }
    return isError;
  };
  handleSubmit = async (event) => {
    event.preventDefault();
    let error;
    error = this.validate();
    if (!error) {
      const { domainName } = this.state;
      this.props.saveSiteGraderDetails(domainName, EXTERNAL_SEO);
    }
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  render() {
    const { classes } = this.props;
    const { urlInfoLoading } = this.props.siteGrader;
    return (
      <Card className={classes.sectionCard}>
        <CardContent style={{ padding: 0 }}>
          <Grid container justifyContent={"center"}>
            <Grid item md={12} sm={12} className={classes.mainDiv}>
              <div className={classes.titleSection}>
                <Typography className={classes.mainTitle}>
                  How efficient is your SEO and website?
                </Typography>
                <Typography className={classes.titleSubPara}>
                  LXRGuide SEO grader will give you a free analysis of your
                  website, including valuable information on the structure and
                  performance.
                </Typography>
              </div>
            </Grid>
            <Grid item md={12} sm={12}>
              <div className={classes.NewIcon}>{icons.New_icon}</div>
              <form onSubmit={this.handleSubmit}>
                <div className={classes.subDiv}>
                  {this.state.errorMsg != "" ? (
                    <SessionErrorTypography align={"center"}>
                      <b>{this.state.errorMsg}</b>
                    </SessionErrorTypography>
                  ) : (
                    ""
                  )}
                  <Typography
                    gutterBottom
                    className={classNames(
                      classes.dialogTitle,
                      classes.fontFamilyM
                    )}
                  >
                    <b>Get your free SEO scorecard now</b>
                  </Typography>
                  <Paper elevation={1}>
                    <Grid
                      container
                      justifyContent={"center"}
                      alignItems={"center"}
                      className={classes.dialogP}
                    >
                      <Grid item md={12} sm={12} className={classes.formHeight}>
                        <PlainInput
                          placeholder="Please enter your Website..."
                          onChange={this.handleChange("domainName")}
                          inputProps={{
                            "aria-label": "Description",
                          }}
                          fullWidth={true}
                          error={this.state.domainNameError != ""}
                          helpertext={this.state.domainNameError}
                          style={{ color: "#333333", height: "2em" }}
                        />
                        {this.state.domainNameError != "" ? (
                          <ErrorSmallTypography
                            className={classes.errorTypo}
                            align={"center"}
                          >
                            <b>{this.state.domainNameError}</b>
                          </ErrorSmallTypography>
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Grid item md={12} sm={12}>
                        {urlInfoLoading ? (
                          <Typography align={"center"}>
                            <CircularProgress />
                          </Typography>
                        ) : (
                          <Typography align={"center"}>
                            <WhiteAndOrangeText
                              type="submit"
                              size="medium"
                              variant="contained"
                              className={classes.button}
                            >
                              Get Analysis
                            </WhiteAndOrangeText>
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Paper>
                </div>
              </form>
            </Grid>
          </Grid>
          <Grid container justifyContent={"center"} className={classes.steps}>
            <Grid item md={6} sm={12}></Grid>
            <Grid item md={6} sm={12}>
              <Typography
                className={classes.stepsTitle}
                color={"textSecondary"}
                align={"left"}
                component={"h6"}
                variant={"h6"}
              >
                Get your scorecard in two easy steps
              </Typography>
              <div className={classes.listAlign}>
                <List component="nav">
                  <CustomListItem>
                    <ListItemIcon>{icons.dot}</ListItemIcon>
                    <CustomListItemText primary="Enter your Website." />
                  </CustomListItem>
                  <CustomListItem>
                    <ListItemIcon>{icons.dot}</ListItemIcon>
                    <CustomListItemText primary="Get Scorecard." />
                  </CustomListItem>
                </List>
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

const styles = (theme) => ({
  mainDiv: {
    border: "1px solid #F9D565",
    height: "25em",
    display: " inline-block",
    overflow: "unset",
    // position: "relative",
    boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
    backgroundImage: "url(" + Home_bg + ")",
    backgroundPosition: "bottom center",
    backgroundSize: "cover",
  },
  subDiv: {
    [theme.breakpoints.down('xl')]: {
      width: "93%",
      position: "relative",
      margin: "-125px 0px 0px",
    },
    width: "35%",
    flex: "none",
    padding: 15,
    background: "#F9D565",
    boxShadow:
      "0 12px 20px -10px rgba(255, 152, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 152, 0, 0.2)",
    lineHeight: "1.5em",
    borderRadius: 3,
    margin: "-125px 40px 0px",
    height: "14em",
    position: "absolute",
  },
  steps: {
    height: "15em",
    [theme.breakpoints.down('xl')]: {
      height: "13em",
    },
  },
  button: {
    margin: theme.spacing(2),
    padding: "8px 50px",
  },
  formTitle: {
    fontSize: 20,
  },
  dialogTitle: {
    color: "#000000",
    padding: 10,
    fontSize: 16,
  },
  sectionCard: {
    boxShadow: "unset",
    padding: "0px 0px 0px 0px",
  },
  CustomPaper: {
    width: "100%",
    height: "11em",
  },
  listAlign: {
    textAlign: "right",
  },
  dialogP: {
    padding: "30px 10px 10px 10px",
  },
  formHeight: {
    height: "2.5em",
    [theme.breakpoints.down('lg')]: {
      width: "100%",
    },
  },
  fontFamilyM: {
    fontFamily: "Montserrat",
  },
  stepsTitle: {
    fontFamily: "Montserrat",
    fontsize: 18,
    [theme.breakpoints.down('xl')]: {
      paddingTop: 30,
    },
  },
  NewIcon: {
    position: "absolute",
    zIndex: "99",
    margin: "-144px 0px 0px 15px",
  },
  mainTitle: {
    fontFamily: "Montserrat",
    fontSize: 45,
    fontWeight: "bold",
    color: theme.palette.common.white,
    [theme.breakpoints.down('xl')]: {
      fontSize: 30,
    },
  },
  titleSection: {
    marginLeft: 40,
    width: "60%",
    marginTop: 45,
    [theme.breakpoints.down('xl')]: {
      marginLeft: 0,
      width: "95%",
      marginTop: 20,
      padding: 14,
    },
  },
  titleSubPara: {
    fontFamily: "Lato",
    color: theme.palette.common.white,
    fontSize: 20,
    fontWeight: "bold",
    [theme.breakpoints.down('xl')]: {
      fontSize: 16,
    },
  },
});
const mapStateToProps = (state) => ({
  siteGrader: state.siteGrader,
});

const mapDispatchToProps = (dispatch) => ({
  saveSiteGraderDetails: (domainName, type) => {
    return dispatch(getDomainInfo(domainName, type));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SeoSiteGraderHomePage));
