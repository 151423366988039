import React, { Fragment, useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import icons from "../../components/icons";
import "./WebsiteHealthScoreCard.css";
import {
  SectionInfoWHS,SubSectionHeading,GoodSubSectionComment,ImproveSubSectionComment, ErrorSubSectionComment, NoDataSubSectionComment
} from "../../components/typography";

function accessibility_WHC({ accessibilityData }) {
  console.log("inside accessibility_WHC-->", accessibilityData);

  const GOOD = "Looks good";
  const IMPROVED = "Can be improved";
  const ERROR = "Critical error found!";
  const NO_DATA = "Not available";

  const [cumulativeLayoutShift, setCumulativeLayoutShift] = useState(
    accessibilityData ? accessibilityData.cumulativeLayoutShift : null
  );
  const [fontSizePercentage, setFontSizePercentage] = useState(
    accessibilityData ? accessibilityData.fontSizePercentage : null
  );
  const [iFrame, setIFrame] = useState(
    accessibilityData ? accessibilityData.iFrame : null
  );
  const [brokenLinkCount, setBrokenLinkCount] = useState(
    accessibilityData ? accessibilityData.brokenLinkCount : null
  );
  const [breadCrumb, setBreadCrumb] = useState(
    accessibilityData ? accessibilityData.breadCrumb : null
  );
  const [accessibilityScore, setAccessibilityScore] = useState(
    accessibilityData ? accessibilityData.accessibilityScore : null
  );
  const [layoutShiftElements, setLayoutShiftElements] = useState(
    accessibilityData ? accessibilityData.layoutShiftElements : null
  );

  //Descriptions
  const [cumulativeLayoutShiftDescriptions,setCumulativeLayoutShiftDescriptions]  = useState(
    accessibilityData ? accessibilityData.cumulativeLayoutShiftDescriptions : null
  );
 
  const [fontSizePercentageDescriptions, setFontSizePercentageDescriptions] = useState(
    accessibilityData ? accessibilityData.fontSizePercentageDescriptions : null
  );

  const [iFrameDescriptions, setIFrameDescriptions] = useState(
    accessibilityData ? accessibilityData.iFrameDescriptions : null
  )

  const [brokenLinkCountDescriptions, setBrokenLinkCountDescriptions] = useState(
    accessibilityData ? accessibilityData.brokenLinkCountDescriptions : null
  )

  const [breadCrumbDescriptions, setBreadCrumbDescriptions] =  useState(
    accessibilityData ? accessibilityData.breadCrumbDescriptions : null
  )

  const [layoutShiftElementsDescriptions, setLayoutShiftElementsDescriptions] = useState(
    accessibilityData ? accessibilityData.layoutShiftElementsDescriptions : null
  )

  useEffect(() => {
    if (accessibilityData) {
      setCumulativeLayoutShift(
        accessibilityData.cumulativeLayoutShift
          ? accessibilityData.cumulativeLayoutShift
          : null
      );
      setFontSizePercentage(
        accessibilityData.fontSizePercentage !== undefined
          ? accessibilityData.fontSizePercentage
          : null
      );
      setIFrame(
        accessibilityData.iFrame !== undefined ? accessibilityData.iFrame : null
      );
      setBrokenLinkCount(
        accessibilityData.brokenLinkCount !== undefined
          ? accessibilityData.brokenLinkCount
          : null
      );
      setBreadCrumb(
        accessibilityData.breadCrumb !== undefined
          ? accessibilityData.breadCrumb
          : null
      );
      setAccessibilityScore(
        accessibilityData.accessibilityScore
          ? accessibilityData.accessibilityScore
          : null
      );
      setLayoutShiftElements(
        accessibilityData.layoutShiftElements
          ? accessibilityData.layoutShiftElements
          : null
      );
      setCumulativeLayoutShiftDescriptions(
        accessibilityData.cumulativeLayoutShiftDescriptions
        ? accessibilityData.cumulativeLayoutShiftDescriptions
        : null   
      )
      setFontSizePercentageDescriptions(
        accessibilityData.fontSizePercentageDescriptions
        ? accessibilityData.fontSizePercentageDescriptions
        : null     
      ) 
      setIFrameDescriptions(
        accessibilityData.iFrameDescriptions
        ? accessibilityData.iFrameDescriptions
        : null
      )
      setBrokenLinkCountDescriptions(
        accessibilityData.brokenLinkCountDescriptions
        ? accessibilityData.brokenLinkCountDescriptions
        : null     
      )
      setBreadCrumbDescriptions(
        accessibilityData.breadCrumbDescriptions
        ? accessibilityData.breadCrumbDescriptions
        : null        
      )
      setLayoutShiftElementsDescriptions(
        accessibilityData.layoutShiftElementsDescriptions
        ? accessibilityData.layoutShiftElementsDescriptions
        : null  
      )
    }
  }, [accessibilityData]);
  console.log(
    "The values are",
    cumulativeLayoutShift,
    fontSizePercentage,
    iFrame,
    brokenLinkCount,
    breadCrumb,
    accessibilityScore
  );
  const accessibilityInfo ="Accessibility ensures that everyone can access and navigate the website, regardless of their physical or cognitive abilities, through features like text alternatives, keyboard navigation, and color contrast. The goal is to allow all users equal access to content and functionality.";
  return (
    <Fragment>
      <Grid container direction="row" className="sectionInfo" alignItems="center">
        <Grid item xs={3} sm={2} md={1} lg={1} xl={1} className="grid-item-css">
          {icons.WHSAccessibilityInfo}
        </Grid>
        <Grid item xs={9} sm={10} md={11} lg={11} xl={11}>
          <SectionInfoWHS>{accessibilityInfo}</SectionInfoWHS>
        </Grid>
      </Grid>

      <Grid container direction="row" alignItems="flex-start" columnSpacing={3} style={{marginTop:"2%"}}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          {/* CLS*/}
              <div className="subsection-css">
               <SubSectionHeading>Cumulative Layout Shift (CLS)</SubSectionHeading>
               <div className={cumulativeLayoutShift == null ? "noDataBgColor" :cumulativeLayoutShift <= 0.10 ? "looksGoodBgColor" : cumulativeLayoutShift > 0.10 && cumulativeLayoutShift <= 0.25 ? "improvedBgColor" : cumulativeLayoutShift > 0.25 ? "errorBgColor" : "noDataBgColor"}>
                 {cumulativeLayoutShift == null ? (
                 <NoDataSubSectionComment>{NO_DATA}</NoDataSubSectionComment>   
                 ) : cumulativeLayoutShift <= 0.10 ? (
                  <GoodSubSectionComment>{GOOD}</GoodSubSectionComment>
                  ): cumulativeLayoutShift > 0.10 && cumulativeLayoutShift <= 0.25 ? (
                  <ImproveSubSectionComment>{IMPROVED}</ImproveSubSectionComment>
                  ):cumulativeLayoutShift > 0.25 ? (
                    <ErrorSubSectionComment>{ERROR}</ErrorSubSectionComment>
                  ):(
                    <NoDataSubSectionComment>{NO_DATA}</NoDataSubSectionComment>   
                  )
                }
               </div>
              </div>
              <div className={cumulativeLayoutShift == null ? "subsection-info-noData" : cumulativeLayoutShift <= 0.10 ? "subsection-info-good" : cumulativeLayoutShift > 0.10 && cumulativeLayoutShift <= 0.25 ? "subsection-info-improve" : cumulativeLayoutShift > 0.25 ? "subsection-info-error" : "subsection-info-noData"}>
                <Typography variant="subtitle2" className="wordBreakTypography">{cumulativeLayoutShiftDescriptions}</Typography>
              </div>            
            {/* Font size */}
            <div className="subsection-css">
               <SubSectionHeading>Font Size</SubSectionHeading>
               <div className={fontSizePercentage >= 60 ? "looksGoodBgColor" : fontSizePercentage >= 40 && fontSizePercentage < 60 ? "improvedBgColor" : fontSizePercentage < 40 ? "errorBgColor" : "noDataBgColor"}>
                 { fontSizePercentage >= 60  ? (
                  <GoodSubSectionComment>{GOOD}</GoodSubSectionComment>
                  ): fontSizePercentage >= 40 && fontSizePercentage < 60 ? (
                    <ImproveSubSectionComment>{IMPROVED}</ImproveSubSectionComment>
                  ): fontSizePercentage < 40 ? (
                    <ErrorSubSectionComment>{ERROR}</ErrorSubSectionComment>
                  ): (
                    <NoDataSubSectionComment>{NO_DATA}</NoDataSubSectionComment>   
                  )
                }
               </div>
              </div>
              <div className={fontSizePercentage >= 60 ? "subsection-info-good" : fontSizePercentage >= 40 && fontSizePercentage < 60  ? "subsection-info-improve" : fontSizePercentage < 40 ? "subsection-info-error" : "subsection-info-noData"}>
                <Typography variant="subtitle2" className="wordBreakTypography">{fontSizePercentageDescriptions}</Typography>
              </div>     
         </Grid>
         <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            {/* Layout Shift Elements*/}
            <div className="subsection-css">
               <SubSectionHeading>Layout Shift Elements</SubSectionHeading>
               <div className={layoutShiftElements <= 3 ? "looksGoodBgColor" : layoutShiftElements > 3 && layoutShiftElements <= 6 ? "improvedBgColor" : layoutShiftElements > 6 ? "errorBgColor" : "noDataBgColor"}>
                 { layoutShiftElements <= 3 ? (
                  <GoodSubSectionComment>{GOOD}</GoodSubSectionComment>
                  ): layoutShiftElements > 3 && layoutShiftElements <= 6 ?(
                  <ImproveSubSectionComment>{IMPROVED}</ImproveSubSectionComment>
                  ) : layoutShiftElements > 6 ? (
                    <ErrorSubSectionComment>{ERROR}</ErrorSubSectionComment>
                    ) :(
                      <NoDataSubSectionComment>{NO_DATA}</NoDataSubSectionComment>   
                    )
                }
               </div>
              </div>
              <div className={layoutShiftElements <= 3 ? "subsection-info-good" : layoutShiftElements > 3 && layoutShiftElements <= 6 ? "subsection-info-improve" : layoutShiftElements > 6 ? "subsection-info-error" : "subsection-info-noData"}>
                  <Typography variant="subtitle2" className="wordBreakTypography">{layoutShiftElementsDescriptions}</Typography>
              </div>

            {/* Broken links*/}
            <div className="subsection-css">
               <SubSectionHeading>Broken Links</SubSectionHeading>
               <div className={brokenLinkCount == 0 ? "looksGoodBgColor" : brokenLinkCount > 0 && brokenLinkCount <= 40 ? "improvedBgColor" : brokenLinkCount > 40 ? "errorBgColor" : "noDataBgColor"}>
                 {brokenLinkCount == 0 ? (
                  <GoodSubSectionComment>{GOOD}</GoodSubSectionComment>
                  ): brokenLinkCount > 0 && brokenLinkCount <= 40 ? (
                  <ImproveSubSectionComment>{IMPROVED}</ImproveSubSectionComment>
                  ) : brokenLinkCount > 40 ? (
                    <ErrorSubSectionComment>{ERROR}</ErrorSubSectionComment>
                    ): (
                      <NoDataSubSectionComment>{NO_DATA}</NoDataSubSectionComment>   
                    )
                }
               </div>
              </div>
              <div className={brokenLinkCount == 0 ? "subsection-info-good" : brokenLinkCount > 0 && brokenLinkCount <= 40 ? "subsection-info-improve" : brokenLinkCount > 40 ? "subsection-info-error" : "subsection-info-noData"}>
                  <Typography variant="subtitle2" className="wordBreakTypography">{brokenLinkCountDescriptions}</Typography>
              </div>
         </Grid>
         <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          {/* iFrame */}
          <div className="subsection-css">
            <SubSectionHeading>Use of iFrame</SubSectionHeading>
            <div className={iFrame ? "errorBgColor" : !iFrame ? "looksGoodBgColor" : "noDataBgColor" }>
              { iFrame ? (
                  <ErrorSubSectionComment>{ERROR}</ErrorSubSectionComment>
               ): !iFrame ? (
                <GoodSubSectionComment>{GOOD}</GoodSubSectionComment>
              ) :(
                <NoDataSubSectionComment>{NO_DATA}</NoDataSubSectionComment>     
              )}
            </div>
          </div>
          <div className={iFrame ? "subsection-info-error" : !iFrame ? "subsection-info-good" : "subsection-info-noData"}>
            <Typography variant="subtitle2" className="wordBreakTypography">{iFrameDescriptions}</Typography>
          </div> 
          {/* Breadcrumbs */} 
          <div className="subsection-css">
            <SubSectionHeading>Use of Breadcrumbs</SubSectionHeading>
            <div className={breadCrumb ? "looksGoodBgColor" : !breadCrumb ? "errorBgColor" : "noDataBgColor"}>
            { breadCrumb ? (
              <GoodSubSectionComment>{GOOD}</GoodSubSectionComment>
              ): !breadCrumb ? (
                <ErrorSubSectionComment>{ERROR}</ErrorSubSectionComment>
                ): (
                  <NoDataSubSectionComment>{NO_DATA}</NoDataSubSectionComment>     
                )
              }
            </div>
          </div>
          <div className={breadCrumb ? "subsection-info-good" : !breadCrumb ? "subsection-info-error" : "subsection-info-noData" }>
            <Typography variant="subtitle2" className="wordBreakTypography">{breadCrumbDescriptions}</Typography>
          </div>   
         </Grid>
        </Grid>
    </Fragment>
 );
}
export default accessibility_WHC;
