import React from "react";
import { Grid, Typography } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import { CustomCard } from "../components/cards";
import RequestJumpstartForm from "../components/requestJumpstart/request-jumpstart-form";
import {
  HeadLineTypography,
  ParagraphTypography,
} from "../components/typography";
import Footer from "../components/footer";
import { REQUEST_JUMPSTART_TRACK } from "../actions/actionTypes";
import { connect } from "react-redux";
const requestDemoStyles = (theme) => ({
  formsSectionBg: {
    backgroundColor: theme.palette.formFieldsBg,
    minHeight: "70vh",
  },
  customGridForms: {
    paddingLeft: "4.5rem",
    paddingRight: "4.5rem",
    paddingBottom: "2rem",
    [theme.breakpoints.down('xl')]: {
      padding: "1rem",
    },
  },
  padding: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  lowPadding: {
    padding: theme.spacing(1),
  },
  paddingTopBottom: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  paddingBottom: {
    paddingBottom: theme.spacing(4),
  },
  typoSubHead: {
    color: theme.palette.dialogText,
    fontSize: "0.975rem",
  },
  orangeText: {
    color: theme.palette.orangeColor,
  },
  inline: {
    display: "flex",
    justifyContent: "center",
  },
  containerPadding: {
    padding: theme.spacing(4),
  },
  ParagraphTypography: {
    color: theme.palette.dialogText,
    fontSize: 16,
    fontWeight: "600",
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('xl')]: {
      fontSize: 12,
      padding: theme.spacing(2),
    },
  },
});
const content = [
  "Setting up a Google Ads account can be an arduous task. Our Google Ads certified paid search experts can help you setup an account structure that will achieve the maximum results.",
  "We start building a strategy, discuss the business goals you want to achieve by advertising on Google, give recommendations on budget and identify keywords that maximize budget and results. Our paid search experts take care of all the intricate details, including target demographic, device preferences, geography,and language.",
  "We keep you informed and ask for your feedback throughout the process. Please fill out your contact information and we will be in touch as soon as possible.",
];

function MultiColorHeadLine(props) {
  const { blueTxt, orangeTxt, classes } = props;

  return (
    <Typography className={classes.inline}>
      <HeadLineTypography align={"center"}>
        {blueTxt}&nbsp;<span className={classes.orangeText}>{orangeTxt} </span>
      </HeadLineTypography>
    </Typography>
  );
}

class requestJumpstart extends React.PureComponent {
  componentDidMount() {
    this.props.requestJumpstartPage();
  }

  render() {
    const { classes } = this.props;
    return (
      <CustomCard>
        <Grid container justifyContent={"center"}>
          <Grid
            item
            md={12}
            component={"div"}
            className={classes.formsSectionBg}
          >
            <Grid
              container
              direction="row"
              justifyContent={"space-around"}
              alignItems={"flex-start"}
              className={classes.customGridForms}
            >
              <Grid item md={12} className={classes.padding}>
                <MultiColorHeadLine
                  blueTxt={"ADVERTISE ON"}
                  orangeTxt={"GOOGLE"}
                  classes={classes}
                />
              </Grid>
              <Grid item md={6} xs={11} sm={11}>
                <Typography
                  className={classes.ParagraphTypography}
                  align={"left"}
                >
                  <Typography
                    component={"p"}
                    align={"justifyContent"}
                    className={classes.typoSubHead}
                  >
                    Setting up a Google Ads account can be an arduous task. Our
                    Google Ads certified paid search experts can help you setup
                    an account structure that will achieve the maximum results.
                  </Typography>
                  <br />
                  <Typography
                    component={"p"}
                    align={"justifyContent"}
                    className={classes.typoSubHead}
                  >
                    We start with building a strategy, discuss the business
                    goals you want to achieve by advertising on Google, give
                    recommendations on budget and identify keywords that
                    maximize budget and results. Our paid search experts take
                    care of all the intricate details, including target
                    demographic, device preferences, geography, and language.
                  </Typography>
                  <br />
                  <Typography
                    component={"p"}
                    align={"justifyContent"}
                    className={classes.typoSubHead}
                  >
                    We keep you informed and ask for your feedback throughout
                    the process. Please fill out your contact information and we
                    will be in touch as soon as possible.
                  </Typography>
                </Typography>
              </Grid>
              <Grid item md={6} xs={11} sm={11}>
                <RequestJumpstartForm />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Footer />
      </CustomCard>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  requestJumpstartPage: () => dispatch({ type: REQUEST_JUMPSTART_TRACK }),
});
export default connect(
  null,
  mapDispatchToProps
)(withStyles(requestDemoStyles)(requestJumpstart));
