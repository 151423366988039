import React, {Fragment} from 'react';
import { Card, Button, CardActions, CardContent, CardHeader, Typography, Dialog } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import {Zoom} from "@mui/material";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {FLOATING_CARD_TRACK} from "../../actions/actionTypes";


const CustomDialog = withStyles(theme => ({
    paperWidthMd: {
        maxWidth: 500
    },

}))(Dialog);
const floatingCardStyles = theme => ({
    card: {
        position: "fixed", bottom: 0, right: 0, width: 360,
        backgroundColor: theme.palette.alertContentBg,
    },
    cardHeader: {
        backgroundColor: theme.palette.alertHeaderBg,
        paddingTop: 5,
        paddingBottom: 5,
        cursor: "pointer",
    },
    onDemandServiceBtn: {
        backgroundColor: theme.palette.alertHeaderBg,
        textTransform: "capitalize",
        color:theme.palette.text.primary,
        fontWeight: "bold"
    },
    active: {
        display: "block",
        transform: "translate(0px, 0px)",
        transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        flexDirection: "column",
        height: "auto",
        maxHeight: "100%",
        backgroundColor: theme.palette.alertContentBg,
    },
    unActive: {display: "none"},
    cardContent: {},
    subTitle: {
        fontWeight: "normal",
        color: theme.palette.text.primary
    },
    orangeBtn: {
        backgroundImage: "linear-gradient(to right, #F58120, #F15A2D)",
        fontWeight: "bold",
        color: theme.palette.common.white,
        width: "50%",
        textTransform:"capitalize"
    },
    toggleArrows: {
        cursor: "pointer",
        paddingLeft: 10,
        float: "right"
    },
    closeBtn: {
        cursor: "pointer",
        paddingLeft: 10,
        float: "right",
        fontSize: "1rem"
    },

});

const tagLines = ['Ad Group', 'Expanded Text Ads', 'Sitelinks Extensions', 'Price Extensions', 'Callout Extensions', 'Structure Snippet Extensions']

class FloatingCard extends React.Component {
    componentDidMount() {
        /*setTimeout(() => {
            this.show();
        }, 3000);*/
    }

    state = {
        open: false
    }
    show = () => {
        this.setState({open: true});
        this.props.FloatingCardTrack();
    };

    close = () => {
        this.setState({open: false});
    }

    render() {
        const {classes} = this.props;
        return (
            <Fragment>
                <Button className={classes.onDemandServiceBtn} variant="contained" onClick={this.show}>
                    On Demand Services
                </Button>

                <CustomDialog
                    open={this.state.open}
                    onClose={this.close}
                    TransitionComponent={Zoom}
                    maxWidth="md"
                >
                    <Card>
                        <CardHeader className={classes.cardHeader} title={
                            <div>
                                <span style={{fontSize: "1.3rem"}}><b>Introducing Mini Service Pack</b></span>
                                <sup className={"textGradient"} style={{fontSize: 16, paddingBottom: 5,}}>
                                    New
                                </sup>
                                <span className={classes.closeBtn} onClick={this.close}>
                                <i className="fa fa-times" aria-hidden="true"></i></span>
                            </div>

                        }
                        />
                        <div className={classes.active}>
                            <CardContent>
                                <Typography className={classes.subTitle} variant={"subtitle1"}>
                                    How would you like perfectly tailored Ad copy for products that need a serious push?
                                </Typography>
                               {/* <Typography>
                                    Or want just a better ad-copy for a product that needs a serious push?
                                </Typography>*/}
                                <br/>
                                <Typography>
                                    <b>
                                        Now, you can request additional account assets for all your needs, however big or small.
                                    </b>
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Link to="/on-demand-service"
                                      style={{textDecoration: "none", width: "100%", textAlign: "center"}}
                                      className={classes.hyperlink}>
                                    <Button className={classes.orangeBtn} variant="contained">
                                        View Now
                                    </Button>
                                </Link>
                            </CardActions>
                        </div>

                    </Card>
                </CustomDialog>
            </Fragment>
        );

    }
}
const mapDispatchToProps = (dispatch) => ({
    FloatingCardTrack: () => dispatch({
        type: FLOATING_CARD_TRACK,
    }),
});

export default connect(null, mapDispatchToProps)(withStyles(floatingCardStyles)(FloatingCard));
