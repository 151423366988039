import React, { Fragment } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import DataRange from "../../components/dashboard/date-range-selector";
import { displayDateRange } from "../../selectors/dashboard-selectors";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Snackbar,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import * as _ from "lodash";
import { connect } from "react-redux";
import {
  customDateRange,
  getUserAccounts,
  selectDateRange,
  resetDateRange,
} from "../../actions";
import { getAccountTypeIcon, IconText, setClipBoardData } from "./templates";
import NumberFormat from "react-number-format";
import icons from "../icons";
import SnackbarContent from "@mui/material/SnackbarContent/index";
import { Link } from "react-router-dom";
export function PlainLink({ classes, ...rest }) {
  return <Link {...rest} className={classes.a} />;
}

PlainLink = withStyles({
  a: {
    width: "100%",
    color: "unset",
    textDecoration: "none",
    cursor: "pointer",
  },
})(PlainLink);

const CustomCard = withStyles((theme) => ({
  root: {
    overflow: "unset",
    width: "100%",
    margin: "25px 0",
    display: "inline-block",
    position: "relative",
    boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
    background: "#fff",
    borderRadius: 3,
  },
}))(Card);
const CustomSnackbarContent = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.alertInfo,
    fontWeight: "bold",
    minWidth: 200,
  },
}))(SnackbarContent);
const CustomCardContent = withStyles((theme) => ({
  root: {
    minHeight: "30vh",
    overflowY: "hidden",
  },
}))(CardContent);
const CustomTable = withStyles((theme) => ({
  root: {
    width: "auto",
  },
}))(Table);
const adminAccountStyles = (theme) => ({
  numberFormat: {
    border: "0",
    textAlign: "right",
    width: 90,
    float: "right",
    backgroundColor: "unset",
  },
});
const CustomCardHeader = withStyles((theme) => ({
  root: {
    flex: "none",
    margin: "-20px 15px 0",
    padding: 15,
    background: "linear-gradient(60deg, #ffa726, #fb8c00)",
    boxShadow:
      "0 12px 20px -10px rgba(255, 152, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 152, 0, 0.2)",
    lineHeight: "1.5em",
    borderRadius: 3,
  },
}))(CardHeader);
const CustomHeadTableCell = withStyles((theme) => ({
  root: {
    padding: "4px 10px 4px 18px",
  },
}))(TableCell);

class AdminAccountDetails extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      clipBoardAlert: null,
    };

    console.log("The props userId is", this.props.userId);
  }

  async componentDidMount() {
    await this.props.resetDateRanges();
    this.props.getUserAccounts(this.props.userId);
  }

  onSelectedDateRange = async (event) => {
    await this.props.onSelectDateRange(event);
    this.props.getUserAccounts(this.props.userId);
  };
  onCustomDateRange = async (dateRange) => {
    await this.props.customDateRanges(dateRange);
    this.props.getUserAccounts(this.props.userId);
  };
  handleCopy = (account) => {
    const clipboardData = setClipBoardData(account);
    console.log("the returned set data is: ", clipboardData);
    navigator.clipboard
      .writeText(clipboardData)
      .then(() => {
        this.showStatus("Copied to clipboard");
        console.log("Text copied.");
      })
      .catch(() => {
        this.showStatus("Copy Failed Try Again");
        console.log("Failed to copy text.");
      });
  };
  showStatus = (statusMessage) => {
    this.setState({
      clipBoardAlert: statusMessage,
    });
  };
  hideAlerts = () => {
    this.setState({
      clipBoardAlert: null,
    });
  };

  render() {
    const { accountStatsMap, isLoading } = this.props.accountStatsData;
    const {
      userId,
      selectedDateRange,
      onSelectDateRange,
      displayDateRange,
      customDateRanges,
      classes,
    } = this.props;
    const { clipBoardAlert } = this.state;
    // const accountStats = getAccountStats(userId,accountStatsMap);
    let account = accountStatsMap[userId];
    return (
      <Fragment>
        <Grid container justifyContent={"flex-end"}>
          <Grid item md={3} xs={12} sm={12}>
            {/* <DataRange value={selectedDateRange}
                                   onChange={onSelectDateRange} range={displayDateRange}
                                   customDateRanges={customDateRanges}/>*/}
            <DataRange
              value={selectedDateRange}
              onChange={(e) => this.onSelectedDateRange(e)}
              range={displayDateRange}
              customDateRanges={(dateRange) =>
                this.onCustomDateRange(dateRange)
              }
            />
          </Grid>
          <Grid item md={12}>
            <CustomCard>
              <CustomCardHeader
                title="Account Stats"
                action={
                  <Button
                    variant="outlined"
                    href={`/selected-user-view.html?userId=${this.props.userId}`}
                    className={classes.userBtn}
                  >
                    User Dashboard&nbsp;&nbsp;
                    {icons.Dashboard}
                  </Button>
                }
              />
              <CustomCardContent>
                {isLoading ? (
                  <CircularProgress />
                ) : (
                  <div>
                    {account && _.size(account) > 0 ? (
                      <Fragment>
                        <CustomTable>
                          <TableHead>
                            <TableRow>
                              <CustomHeadTableCell component="th" scope="row">
                                <b>Metrics </b>
                              </CustomHeadTableCell>
                              {account &&
                                _.map(account, (value, key) => (
                                  <CustomHeadTableCell
                                    component="th"
                                    scope="row"
                                    key={key}
                                  >
                                    <IconText
                                      icon={getAccountTypeIcon(
                                        value.searchEngine
                                      )}
                                    >
                                      &nbsp;&nbsp;&nbsp;&nbsp;{value.accId}
                                      &nbsp;&nbsp;
                                      <Typography
                                        onClick={() =>
                                          this.handleCopy(
                                            account[value.ppcId],
                                            [value.ppcId]
                                          )
                                        }
                                      >
                                        <i
                                          className={"fa fa-clipboard"}
                                          style={{ cursor: "pointer" }}
                                          aria-hidden="true"
                                        ></i>
                                      </Typography>
                                    </IconText>
                                    <PlainLink
                                      to={`/task-analysis/${value.accId}/${value.ppcId}`}
                                      style={{ textDecoration: "none" }}
                                    >
                                      <Button>
                                        {icons.tasks}&nbsp;&nbsp;Tasks
                                      </Button>
                                    </PlainLink>
                                  </CustomHeadTableCell>
                                ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <CustomTableCell title={"Account"}>
                              {account &&
                                _.map(account, (value, key) => (
                                  <TableCell
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                    }}
                                    key={key}
                                  >
                                    {value.accName}
                                  </TableCell>
                                ))}
                            </CustomTableCell>
                            <CustomTableCell title={"Impressions"}>
                              {account &&
                                _.map(account, (value, key) => (
                                  <TableCell key={key}>
                                    <NumberFormat
                                      value={value.impressions}
                                      thousandSeparator={true}
                                      className={classes.numberFormat}
                                      readOnly={true}
                                      displayType={"text"}
                                    />
                                  </TableCell>
                                ))}
                            </CustomTableCell>
                            <CustomTableCell title={"Clicks"}>
                              {account &&
                                _.map(account, (value, key) => (
                                  <TableCell key={key}>
                                    <NumberFormat
                                      value={value.clicks}
                                      thousandSeparator={true}
                                      className={classes.numberFormat}
                                      readOnly={true}
                                      displayType={"text"}
                                    />
                                  </TableCell>
                                ))}
                            </CustomTableCell>
                            <CustomTableCell title={"PPC Spend"}>
                              {account &&
                                _.map(account, (value, key) => (
                                  <TableCell key={key}>
                                    <NumberFormat
                                      value={value.cost}
                                      thousandSeparator={true}
                                      prefix={value.currSymbol}
                                      className={classes.numberFormat}
                                      readOnly={true}
                                      displayType={"text"}
                                    />
                                  </TableCell>
                                ))}
                            </CustomTableCell>
                            <CustomTableCell title={"Orders/Leads"}>
                              {account &&
                                _.map(
                                  account,
                                  (value, key) => (
                                    /*<CustomTableCell title={getTitleBasedOnKpi(value.targetKpiName)}>*/
                                    <TableCell key={key}>
                                      <NumberFormat
                                        value={value.conversions}
                                        thousandSeparator={true}
                                        className={classes.numberFormat}
                                        readOnly={true}
                                        displayType={"text"}
                                      />
                                    </TableCell>
                                  )
                                  // </CustomTableCell>
                                )}
                            </CustomTableCell>
                            <CustomTableCell title={"Revenue"}>
                              {account &&
                                _.map(account, (value, key) => (
                                  <TableCell key={key}>
                                    <NumberFormat
                                      value={value.conversionValue}
                                      thousandSeparator={true}
                                      prefix={value.currSymbol}
                                      className={classes.numberFormat}
                                      readOnly={true}
                                      displayType={"text"}
                                    />
                                  </TableCell>
                                ))}
                            </CustomTableCell>
                            <CustomTableCell title={"CTR"}>
                              {account &&
                                _.map(account, (value, key) => (
                                  <TableCell
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                    }}
                                    numeric
                                    key={key}
                                  >
                                    {value.ctr.toFixed(2)}%
                                  </TableCell>
                                ))}
                            </CustomTableCell>
                            <CustomTableCell title={"CVR"}>
                              {account &&
                                _.map(account, (value, key) => (
                                  <TableCell
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                    }}
                                    numeric
                                    key={key}
                                  >
                                    {value.cvr.toFixed(2)}%
                                  </TableCell>
                                ))}
                            </CustomTableCell>
                            <CustomTableCell title={"KPI"}>
                              {account &&
                                _.map(account, (value, key) => (
                                  <TableCell
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                    }}
                                    numeric
                                    key={key}
                                  >
                                    {value.kpiVal}
                                  </TableCell>
                                ))}
                            </CustomTableCell>
                            <CustomTableCell title={"Target KPI"}>
                              {account &&
                                _.map(account, (value, key) => (
                                  <TableCell
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                    }}
                                    numeric
                                    key={key}
                                  >
                                    {value.targetKpiName} = {value.targetValue}
                                  </TableCell>
                                ))}
                            </CustomTableCell>
                            <CustomTableCell title={"Budget"}>
                              {account &&
                                _.map(account, (value, key) => (
                                  <TableCell key={key}>
                                    <NumberFormat
                                      value={value.budget}
                                      thousandSeparator={true}
                                      prefix={value.currSymbol}
                                      className={classes.numberFormat}
                                      readOnly={true}
                                      displayType={"text"}
                                    />
                                  </TableCell>
                                ))}
                            </CustomTableCell>
                            <CustomTableCell title={"Task Provided"}>
                              {account &&
                                _.map(account, (value, key) => (
                                  <TableCell
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                    }}
                                    numeric
                                    key={key}
                                  >
                                    {value.taskProvided}
                                  </TableCell>
                                ))}
                            </CustomTableCell>
                            <CustomTableCell title={"Task Completed"}>
                              {account &&
                                _.map(account, (value, key) => (
                                  <TableCell
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                    }}
                                    numeric
                                    key={key}
                                  >
                                    {value.taskCompleted}
                                  </TableCell>
                                ))}
                            </CustomTableCell>
                          </TableBody>
                        </CustomTable>
                      </Fragment>
                    ) : (
                      <Typography variant={"h5"} align={"center"}>
                        No Stats Available
                      </Typography>
                    )}
                  </div>
                )}
                <Snackbar
                  anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                  open={this.state.clipBoardAlert != null}
                  onClose={this.hideAlerts}
                  ContentProps={{
                    "aria-describedby": "message-id",
                  }}
                  autoHideDuration={2000}
                >
                  <CustomSnackbarContent
                    onClose={this.hideAlerts}
                    variant="info"
                    message={
                      <span className={"flex alignItemsCenter"}>
                        <icons.Info />
                        &nbsp;{clipBoardAlert}
                      </span>
                    }
                  />
                </Snackbar>
              </CustomCardContent>
            </CustomCard>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

// admin

function CustomTableCell({ title, children }) {
  return (
    <TableRow>
      <TableCell>{title}</TableCell>
      {children}
    </TableRow>
  );
}

const mapStateToProps = (state) => ({
  accountStatsData: state.adminAccountData,
  selectedDateRange: state.selectedDateRange,
  displayDateRange: displayDateRange(state.selectedDateRange),
});
const mapDispatchToProps = (dispatch) => ({
  getUserAccounts: (userId) => {
    return dispatch(getUserAccounts(userId));
  },
  onSelectDateRange: (event) => dispatch(selectDateRange(event.target.value)),
  customDateRanges: (dateRange) => dispatch(customDateRange(dateRange)),
  resetDateRanges: () => dispatch(resetDateRange()),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(adminAccountStyles)(AdminAccountDetails));
