import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import * as PropTypes from "prop-types";

const styles = (theme) => ({
  banner: {
    margin: "1.125rem",
    marginBottom: "5px",
    padding: "0.5rem",
    zIndex: 1000,
    position: "fixed",
    bottom: 0,
    // background: "#EFEFEF",
    background: "#D2D2D3",
    left: "12%",
    color: "#1E334E",
    borderRadius: 6,
    fontSize: 14,
    width: "72%",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      left: "0",
    },
  },
});

class CookiePopUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      popUp: false,
    };
  }

  componentDidMount() {
    this.checkCookie();
    // document.title = "LXRGuide - Home Page";
  }

  checkCookie = () => {
    let visited = this.getCookie("visited");
    if (visited == "true") {
      this.setState({ popUp: false });
      // $("#close-cookie-popup").fadeOut("slow");
    } else {
      this.setState({ popUp: true });
      // $("#close-cookie-popup").fadeIn(1000);
    }
  };

  setCookie = () => {
    document.cookie = "visited" + "=" + "true" + ";";
    this.setState({ popUp: false });
    // $("#close-cookie-popup").fadeOut("slow");
  };

  getCookie = (cname) => {
    let name = cname + "=";
    let decodedCookie = document.cookie;
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };
  closeCookiePopup = () => {
    this.setState({ popUp: false });
    // $("#close-cookie-popup").fadeOut("slow");
  };

  render() {
    const { classes } = this.props;
    return (
      // <section id="close-cookie-popup" style={{display: this.props.popUp ? "block" : "none"}}>
      // <section id="close-cookie-popup" style={{display: this.state.popUp ? "block" : "none"}}>
      <section
        id="close-cookie-popup"
        style={{ display: this.state.popUp ? "block" : "none" }}
      >
        <div className="container-fluid">
          <Grid container spacing={2} className={classes.banner}>
            <Grid item md={9}>
              <Typography variant={"body1"} color={"textPrimary"}>
                We use cookies to understand how you use our site and provide
                the best browsing experience possible. This includes analysing
                our traffic, personalizing content and advertising. By
                continuing to use our site, you accept our use of cookies,&nbsp;
                <a href="/privacy-policy.html" target="_blank">
                  Privacy Policy
                </a>{" "}
                and &nbsp;
                <a href="/terms-of-service.html" target="_blank">
                  Terms of Service
                </a>
                .
              </Typography>
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={3}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Grid
                container
                justifyContent={"space-between"}
                alignItems={"center"}
                style={{ paddingLeft: 15 }}
              >
                <Grid item xs={8} sm={8} md={8}>
                  <Button
                    className="btn lxrm-bold"
                    style={{
                      background: "#EFEFEF",
                      width: "100%",
                      fontWeight: "700",
                    }}
                    onClick={() => this.setCookie()}
                  >
                    Accept
                  </Button>
                </Grid>
                <Grid item xs={2} sm={2} md={2}>
                  <Typography align={"right"}>
                    <span
                      className=" fa fa-times fa-2x"
                      style={{ cursor: " pointer" }}
                      onClick={() => this.closeCookiePopup()}
                    ></span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </section>
    );
  }
}

CookiePopUp.propTypes = {
  popUp: PropTypes.any,
  onClick: PropTypes.func,
  onClick1: PropTypes.func,
};

export default withStyles(styles)(CookiePopUp);
