import React, { Fragment } from "react";
import {
  CircularProgress,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import CommonStyles from "../../assets/jss/reports/commonStyles";
import icons from "../../components/icons";
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  Bar,
  BarChart,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Card } from "@mui/material/index";
import PerformanceGraphStyles from "../../assets/jss/reports/reportsStyles";
import * as _ from "lodash";
import { graphSpacing, IsLoading } from "./performanceGraph";

function SkuPerformanceGraph(props) {
  const {
    classes,
    data,
    kpiType,
    isLoading,
    theme,
    tabValue,
    handleChange,
    handleReportSent,
    handleRefs,
    handleDownloadReport,
    activeIndex,
    onPieEnter,
  } = props;
  let metric = "revenue";
  const mainData = data && data != null ? data.data : {};
  if (kpiType != null && kpiType != undefined) {
    if (kpiType == "RC" || kpiType == "CR") {
      metric = "revenue";
    } else if (kpiType == "CPL") {
      metric = "leads";
    } else {
      metric = "orders";
    }
  }
  const COLORS = ["#3D69C5", "#CC4728", "#F29D39", "#FF8042"];
  const getSelectedColour = {
    "Zero Cost Taking SKU": "#3D69C5",
    "Only Cost Taking SKU": "#CC4728",
    "Converting SKU": "#F29D39",
  };

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };
  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
      name,
      cond,
    } = props;
    console.log("graph props=", props);
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";
    const customFill = getSelectedColour[cond];
    const textFill = theme.palette.text.primary;
    return (
      <g>
        <text
          x={cx}
          y={cy}
          dy={8}
          textAnchor="middle"
          fontSize={12}
          fill={theme.palette.text.primary}
        >
          {cond}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={customFill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={customFill}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={customFill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={customFill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          fontSize={11}
          textAnchor={textAnchor}
          fill={textFill}
        >{`${cond}: ${value}`}</text>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill={textFill}
        >
          {`(${(percent * 100).toFixed(2)}%)`}
        </text>
      </g>
    );
  };
  return (
    <Paper className={classes.paper}>
      <Grid
        container
        justifyContent={"space-between"}
        style={{ background: "#ffffff" }}
      >
        <Grid item sm={6} xs={6} md={6}>
          <Typography color={"textPrimary"} variant={"h3"}>
            <b>SKU Performance</b>
          </Typography>
        </Grid>
        {isLoading ? (
          <IsLoading classes={classes} />
        ) : (
          <Fragment>
            {_.isEmpty(data) || (data && data.data.length == 0) ? (
              <Grid item md={12}>
                <Typography
                  align={"center"}
                  color={"textPrimary"}
                  variant={"body1"}
                >
                  No data to display
                </Typography>
              </Grid>
            ) : (
              <Fragment>
                <Grid item md={6}>
                {data && !_.isEmpty(data) ? (
                   <Typography align={"right"}>
                   <span
                     className={classes.icons}
                     onClick={() => handleReportSent("skuData")}
                   >
                     {icons.plane}
                   </span>
                   &nbsp; &nbsp; &nbsp;
                   <span
                     className={classes.icons}
                     onClick={(event) =>
                       handleDownloadReport("skuPerf", event)
                     }
                   >
                     {icons.download}
                   </span>
                 </Typography>
                ):""}
                 
                </Grid>
                <br />
                <br />
                <Grid item xs={12} sm={12} md={12}>
                  <Card className={classes.card}>
                    <ResponsiveContainer width="100%" height={360}>
                      {data && !_.isEmpty(data) ? (
                        <PieChart
                          width={480}
                          height={290}
                          margin={graphSpacing}
                          ref={(ref) => ref}
                        >
                          <Pie
                            activeIndex={activeIndex}
                            activeShape={renderActiveShape}
                            data={mainData}
                            // cx={200}
                            // cy={200}
                            innerRadius={60}
                            isAnimationActive={true}
                            animationBegin={500}
                            animationDuration={6000}
                            outerRadius={80}
                            fill="#8884d8"
                            dataKey="count"
                            onMouseEnter={onPieEnter}
                          >
                            {mainData.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={COLORS[index % COLORS.length]}
                              />
                            ))}
                          </Pie>
                        </PieChart>
                      ) : null}
                    </ResponsiveContainer>
                  </Card>
                </Grid>
              </Fragment>
            )}
          </Fragment>
        )}
      </Grid>
    </Paper>
  );
}

export default withStyles(CommonStyles, { withTheme: true })(
  SkuPerformanceGraph
);
