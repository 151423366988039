import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Grid, Hidden, Typography } from "@mui/material/index";
import { withStyles } from "@mui/styles";
import {
  ErrorSmallTypography,
  SessionErrorTypography,
} from "../../components/typography";
import CircularProgress from "@mui/material/CircularProgress";
import {
  ExternalOrangeBtnNew,
  WhiteAndOrangeText,
} from "../../components/buttons";
import { EXTERNAL_SEO } from "../../actions/actionTypes";
import { getDomainInfo } from "../../actions";
import { connect } from "react-redux";
import Home_bg from "../../assets/img/home_1.png";
import siteGraderHomeFormStyles from "../../assets/jss/siteGraderExternal/siteGraderHomeFormStyles";
import Input from "@mui/material/Input";
import NumberFormat from "react-number-format";

const PlainInput = withStyles((theme) => ({
  root: {
    // fontWeight: "bold"
  },
  underline: {
    "&::before": {
      borderBottom: "1px solid " + "#A0A0A0",
    },
    "&:hover": {
      borderBottom: "1px solid #fff",
    },
    "&:after": {
      borderBottom: "2px solid " + theme.palette.orangeColor,
    },
  },
}))(Input);

function SiteGraderHomeForm(props) {
  const { classes } = props;
  const { urlInfoLoading } = props.siteGrader;

  const [domainName, setDomainName] = useState(
    props.defaultWebsite !== "" ? props.defaultWebsite : ""
  );
  const [domainNameError, setDomainNameError] = useState("");
  const [errorMsg, setErrorMsg] = useState(
    window.lxrOptions && window.lxrOptions.errorMsg
      ? window.lxrOptions.errorMsg
      : null
  );
  const [emailId, setEmailId] = useState("");
  const [emailIdError, setEmailIdError] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");

  useEffect(() => {
    setDomainName(props.defaultWebsite !== "" ? props.defaultWebsite : "");
    setDomainNameError("");
    setErrorMsg(
      window.lxrOptions && window.lxrOptions.errorMsg
        ? window.lxrOptions.errorMsg
        : null
    );
    setEmailId("");
    setEmailIdError("");
    setPhoneNumber("");
    setPhoneNumberError("");
  }, []);

  const validate = () => {
    let isError = false;
    const errors = {};
    const UrlRegex =
      /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
    if (
      (domainName && domainName.trim().length == 0) ||
      domainName.trim() == ""
    ) {
      isError = true;
      errors.domainNameError = "This field is required";
    } else if (
      domainName &&
      domainName.trim().length != 0 &&
      !UrlRegex.test(domainName.trim())
    ) {
      isError = true;
      errors.domainNameError = "URL Format Wrong";
    } else {
      errors.domainNameError = "";
    }
    if (emailId.trim().length == 0) {
      isError = true;
      errors.emailIdError = "This field is required";
    } else if (emailId.trim().length > 0) {
      // var reg =;
      let result = emailId
        .trim()
        .match(
          /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
        );
      if (!result) {
        isError = true;
        errors.emailIdError = "Please enter valid email";
      } else {
        errors.emailIdError = "";
      }
    } else {
      errors.emailIdError = "";
    }
    if ((phoneNumber && phoneNumber.length == 0) || phoneNumber.trim() == 0) {
      isError = true;
      errors.phoneNumberError = "This field is required";
    } else if (
      phoneNumber &&
      phoneNumber.length > 0 &&
      phoneNumber.length < 10
    ) {
      isError = true;
      errors.phoneNumberError = "Phone Number should be minimum 10 digits";
    } else if (
      phoneNumber &&
      phoneNumber.length != 0 &&
      phoneNumber.length > 10
    ) {
      isError = true;
      errors.phoneNumberError = "Phone Number should not exceed 10 digits";
    } else {
      errors.phoneNumberError = "";
    }
    setDomainNameError(errors.domainNameError);
    setEmailIdError(errors.emailIdError);
    setPhoneNumberError(errors.phoneNumberError);
    return isError;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let error = validate();
    console.log("The error is", error);
    if (!error) {
      props.saveSiteGraderDetails(
        domainName,
        EXTERNAL_SEO,
        emailId,
        phoneNumber
      );
    }
  };

  return (
    <Fragment>
      {errorMsg != "" ? (
        <SessionErrorTypography align={"center"}>
          <b>{errorMsg}</b>
        </SessionErrorTypography>
      ) : (
        ""
      )}
      <form onSubmit={handleSubmit}>
        <Grid
          container
          justifyContent={"center"}
          alignItems={"center"}
          className={classes.dialogP}
        >
          <Grid item md={12} sm={12} className={classes.formHeight}>
            <PlainInput
              placeholder="Your Website..."
              onChange={(event) => setDomainName(event.target.value)}
              inputProps={{
                "aria-label": "Description",
              }}
              fullWidth={true}
              error={domainNameError != ""}
              defaultValue={domainName}
              helperText={domainNameError}
              style={{ color: "#333333", height: "2em" }}
            />
            {domainNameError != "" ? (
              <ErrorSmallTypography
                className={classes.errorTypo}
                align={"left"}
              >
                <b>{domainNameError}</b>
              </ErrorSmallTypography>
            ) : (
              ""
            )}
          </Grid>
          <Grid item md={12} xs={12} sm={12} className={classes.formHeight}>
            <PlainInput
              placeholder="Your Email..."
              onChange={(event) => setEmailId(event.target.value)}
              inputProps={{
                "aria-label": "Description",
              }}
              fullWidth={true}
              error={emailIdError !== ""}
              defaultValue={emailId}
              helperText={emailIdError}
              style={{ color: "#333333", height: "2em" }}
            />
            <Hidden lgDown>
              {" "}
              {emailIdError !== "" ? (
                <ErrorSmallTypography align={"left"}>
                  <b>{emailIdError}</b>
                </ErrorSmallTypography>
              ) : (
                ""
              )}
            </Hidden>
          </Grid>
          <Grid item md={12} xs={12} sm={12} className={classes.formHeight}>
            <NumberFormat
              placeholder="Your Phone Number..."
              fullWidth
              margin="normal"
              name="phoneNumber"
              value={phoneNumber}
              onValueChange={async (values) => {
                const { formattedValue, value } = values;
                await setPhoneNumber(value);
              }}
              error={phoneNumberError !== ""}
              helperText={phoneNumberError}
              InputLabelProps={{
                shrink: true,
              }}
              customInput={PlainInput}
              format="###-###-####"
              mask="_"
              style={{ color: "#333333", height: "2em" }}
            />
            {phoneNumberError != "" ? (
              <ErrorSmallTypography
                className={classes.errorTypo}
                align={"left"}
              >
                <b>{phoneNumberError}</b>
              </ErrorSmallTypography>
            ) : (
              ""
            )}
          </Grid>
          <Grid item md={12} xs={12} sm={12}>
            {urlInfoLoading ? (
              <Typography align={"center"}>
                <CircularProgress color={"secondary"} />
              </Typography>
            ) : (
              <Typography align={"center"} style={{ minHeight: "14vh" }}>
                <ExternalOrangeBtnNew
                  type="submit"
                  size="large"
                  fullWidth
                  variant="contained"
                  className={classes.button}
                >
                  Get your Free SEO Scorecard
                </ExternalOrangeBtnNew>
              </Typography>
            )}
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
}
const mapStateToProps = (state) => ({
  siteGrader: state.siteGrader,
  defaultWebsite: state.leadGeneration.website,
});

const mapDispatchToProps = (dispatch) => ({
  saveSiteGraderDetails: (domainName, type, emailId, phoneNumber) => {
    return dispatch(getDomainInfo(domainName, type, emailId, phoneNumber));
  },
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(siteGraderHomeFormStyles)(SiteGraderHomeForm));
