import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card, CardContent, Grid, Typography } from "@mui/material/index";
import { withStyles } from "@mui/styles";
import icons from "../../components/icons";
import ListItemIcon from "@mui/material/ListItemIcon";
import {
  IconAndName,
  cardsDetails,
  CustomListItem,
  CustomListItemText,
  content,
  CatalinaHeadOrangeBlue,
} from "../help/helpPage";
import aboutUsStyles from "../../assets/jss/aboutUs/aboutUs";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Button, Divider } from "@mui/material";
import { CatalinaBlue, TaglineForHeadline } from "../../components/typography";
import useMediaQuery from "@mui/material/useMediaQuery";

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="xl" />;

function GetMainImage(props) {
  const isTabletResponsive = useMediaQuery(
    props.theme.breakpoints.between("sm", "xl")
  );
  return (
    <Typography align={"center"} gutterBottom>
      {props.width == "sm" || props.width == "xs"
        ? isTabletResponsive
          ? icons.aboutUs("auto")
          : icons.aboutUs("100%")
        : icons.aboutUs("auto")}
    </Typography>
  );
}

class AboutUs extends React.PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { classes, width, theme } = this.props;
    return (
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Grid container>
            <Grid item sm={12} xs={12} md={12}>
              <Grid container justifyContent={"center"}>
                <Grid
                  sm={12}
                  xs={12}
                  md={12}
                  className={[classes.sectionBg, classes.gridBg]}
                >
                  <Grid container>
                    <Grid item sm={11} xs={11} md={12}>
                      <GetMainImage width={width} theme={theme} />
                    </Grid>
                  </Grid>
                  <br />
                  <Grid container justifyContent={"center"}>
                    <Grid item md={10}>
                      <Typography className={classes.heading}>
                        <b>
                          Empowering small retail business with a world class
                          paid search tool. At a fraction of the cost charged by
                          a paid search agency.
                        </b>
                      </Typography>
                      <br/>
                      <TaglineForHeadline
                        variant={"body1"}
                        color={"textPrimary"}
                        align={"center"}
                      >
                        LXRGuide’s campaign management, insights, optimization
                        and reporting technology platform will help you generate
                        greater revenue and more business from Google Ads and
                        Microsoft Ads.
                      </TaglineForHeadline>
                      <br />
                      <Button
                        variant={"outlined"}
                        align={"center"}
                        href={"https://www.netelixir.com/"}
                        style={{
                          textDecoration: "none",
                          textTransform: "capitalize",
                        }}
                        target={"_blank"}
                      >
                        <Typography
                          color={"textPrimary"}
                          variant={"body1"}
                          align={"center"}
                        >
                          LEARN MORE ABOUT NETELIXIR
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12}>
              <Grid container justifyContent={"center"}>
                <Grid item sm={10} xs={10} md={10}>
                  <div className="highlights-section">
                    <div>
                      <Typography
                        variant={"h2"}
                        color={"textPrimary"}
                        className={classes.highlightsSection}
                      >
                        <b>ABOUT US</b>
                      </Typography>
                      <Divider
                        className={classes.divider}
                        //className="row-separator-field"
                      />
                      <br />
                      <CatalinaBlue>
                        The LXRGuide platform was built in 2016 by the leading
                        digital marketing agency, NetElixir. The platform is
                        powered by 25+ optimization algorithms built on the
                        experience of over 7 million hours of successful,
                        hands-on paid search campaign management since 2005. The
                        paid search platform along with expert campaign
                        management support is at the core of the LXRGuide Growth
                        Model.
                      </CatalinaBlue>
                      <br />
                      <CatalinaBlue>
                        Every eCommerce business wants to grow their online
                        revenue while lowering their risks. No marketer wants to
                        deal with the complexities of paid search campaign
                        management. The LXRGuide Growth Model helps them do just
                        that.
                      </CatalinaBlue>
                      <br />
                      <CatalinaBlue>
                        NetElixir is a premier Google and Microsoft Partner. Our
                        mission is to help eCommerce businesses worldwide
                        profitably and predictably grow their online sales.
                      </CatalinaBlue>
                      <br />
                      <CatalinaBlue>
                        Visit us at{" "}
                        <a
                          target={"_blank"}
                          href={"https://www.netelixir.com/"}
                        >
                          netelixir.com
                        </a>{" "}
                        for more information.
                      </CatalinaBlue>

                      {/* <ul className="verticalwiz">
                                                <li className={classes.li}>
                                                    <CatalinaHeadOrangeBlue style={{fontWeight: "700"}}>Empowering small
                                                        business growth</CatalinaHeadOrangeBlue>
                                                    <CatalinaBlue>
                                                        The LXRGuide platform was built in 2016 by the leading digital marketing agency, NetElixir. The platform is powered by 25+ optimization algorithms built on the experience of over 7 million hours of successful, hands-on paid search campaign management since 2005.  The paid search platform along with expert campaign management support is at the core of the LXRGuide Growth Model.

                                                        Every eCommerce business wants to grow their online revenue while lowering their risks. No marketer wants to deal with the complexities of paid search campaign management. The LXRGuide Growth Model helps them do just that.

                                                        NetElixir is a premier Google and Microsoft Partner. Our mission is to help eCommerce businesses worldwide profitably and predictably grow their online sales.

                                                        Visit us at netelixir.com for more information.
                                                    </CatalinaBlue>
                                                </li>*/}
                      {/* <li className={classes.li}>
                                                    <CatalinaHeadOrangeBlue style={{fontWeight: "700"}}>Launch of
                                                        LXRGuide</CatalinaHeadOrangeBlue>
                                                    <CatalinaBlue>To provide a solution for
                                                        To provide a bespoke paid search platform for small business,
                                                        NetElixir launched LXRGuide in 2016 as a
                                                        technology and service hub meant to offer custom solutions for
                                                        small and mid-sized business at reasonable cost.
                                                    </CatalinaBlue>
                                                </li>
                                                <li className={classes.li}>
                                                    <CatalinaHeadOrangeBlue style={{fontWeight: "700"}}>Mission of
                                                        LXRGuide</CatalinaHeadOrangeBlue>
                                                    <CatalinaBlue>
                                                        LXRGuide would like to be the paid search platform that small
                                                        businesses turn to for tools, technology,
                                                        support and knowledge to grow their business, profits and learn
                                                        more about paid search.
                                                    </CatalinaBlue>
                                                </li>
                                                <li className={classes.li}>
                                                    <CatalinaHeadOrangeBlue style={{fontWeight: "700"}}>
                                                        Technical Expertise
                                                    </CatalinaHeadOrangeBlue>
                                                    <CatalinaBlue>Our own analysts designed all our
                                                        The LXRGuide growth model is a proven system built from hands-on
                                                        experience gained from successfully launching
                                                        thousands of paid search accounts. The model is scalable and
                                                        based on industry best practices and works to bridge
                                                        gaps in campaign management. The dedicated technology team,
                                                        hands-on support from
                                                        the service team and the constant focus on holistic optimization
                                                        helps small business sustain their growth and revenues.
                                                    </CatalinaBlue>
                                                </li>
                                                <li className={classes.li}>
                                                    <CatalinaHeadOrangeBlue style={{fontWeight: "700"}}>
                                                        Service Expertise
                                                    </CatalinaHeadOrangeBlue>
                                                    <CatalinaBlue>Our tools are categorized into
                                                        LXRGuide is not just about matching growth and revenue needs, we
                                                        also
                                                        understand customer requirements. We help clients efficiently
                                                        manage the time and effort they spend on
                                                        the platform to stay in charge, while shifting the actual
                                                        workload to the LXRGuide support. Dedicated
                                                        support teams are built around each client and together
                                                        client-specific strategies and programs are engineered
                                                        to satisfy individual business requirements.
                                                    </CatalinaBlue>
                                                </li>*/}
                      {/*</ul>*/}
                      {/*<ul className="experiences">

                                        <li className=classes.li className="green">
                                            <div className="where">New York Times</div>
                                            <h3 className="what green">Senior Online Editor</h3>
                                            <div className="when">2012 - Present</div>
                                            <p className="description">Jelly-o pie chocolate cake...</p>
                                        </li>
                                        <li className=classes.li className="green">
                                            <div className="where">New York Times</div>
                                            <h3 className="what green">Senior Online Editor</h3>
                                            <div className="when">2012 - Present</div>

                                            <p className="description">Jelly-o pie chocolate cake...</p>
                                        </li>
                                        <li className="green">
                                            <div className="where">New York Times</div>
                                            <h3 className="what green">Senior Online Editor</h3>
                                            <div className="when">2012 - Present</div>

                                            <p className="description">Jelly-o pie chocolate cake...</p>
                                        </li>
                                    </ul>*/}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

AboutUs.propTypes = {};

export default withWidth()(
  withStyles(aboutUsStyles, { withTheme: true })(AboutUs)
);
