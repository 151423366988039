import React, { Fragment } from "react";
import { Typography, Grid, CircularProgress, Divider } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { kpiLabel } from "../selectors/dashboard-selectors";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import NumericLabel from "react-pretty-numbers";


// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="xl" />;

// import currentMonthPerformanceStyle from "../../assets/jss/components/dashboard/currentMonthPerformanceStyle"
// import {useTheme} from "@mui/material/styles";

function RunRateCharts({
  label,
  current,
  target,
  currency,
  currencySymbol,
  fillColor,
  line,
  theme,
  classes,
  width,
}) {
   // const classes = currentMonthPerformanceStyle();
  const percentage = Math.round(Math.min((current / target) * 100, 100));
  // const theme = useTheme();
  const formatOptions = {
    commafy: true,
    shortFormatMinValue: 9999,
    shortFormatPrecision: 0,
    precision: 0,
    shortFormat: true,
    locales: "en-US",
    wholenumber: "ceil",
    currency: true,
    currencyIndicator: currencySymbol,
    cssClass: ["inline"],
  };
  const K_Formatter = (num) => {
    if ((num / 100) % 10 == 0) return Math.floor(num / 1000) + "K";
    else return (Math.floor(num / 100) / 10.0).toLocaleString("en-US", { style: "currency", currency: currencySymbol }) + "K";
  };

  const numberFormat = (value) =>
    new Intl.NumberFormat("en-US", {}).format(value);
  const numericValue = (value, isCurrency) =>
    isCurrency ? (
      <span title={numberFormat(value)} style={{ display: "flex" }}>
        {1000 <= value.toFixed(2) && value.toFixed(2) < 999999 ? (
          <span>{K_Formatter(value.toFixed(2))} </span>
        ) : (
          <NumericLabel params={formatOptions}>{value}</NumericLabel>
        )}

        {/* <NumericLabel params={formatOptions}>{value}</NumericLabel> &nbsp; */}
      </span>
    ) : (
      value
    );
  return (
    <Fragment>
      <Grid item xs={6} sm={6} md={12}>
        <div className={classes.pieChart}>
          <div style={{ padding: "0.7rem" }}>
            <Typography variant={"body1"} align={"center"}>
              <b>{label}</b>
            </Typography>
          </div>
          <CircularProgressbar 
          
            value={!isNaN(percentage) ? percentage : 0}
            text={`${!isNaN(percentage) ? percentage : 0}%`}
            styles={buildStyles({
              textSize: "1rem",
              // How long animation takes to go from one percentage to another, in seconds
              pathTransitionDuration: 0.5,
              // Can specify path transition in more detail, or remove it entirely
              // pathTransition: 'none',
              // Colors
              pathColor: `${fillColor}`,
              textColor: theme.palette.text.primary,
              trailColor: theme.palette.common.liteGrey,
             
            })}
          />
          <div
            className={"flex alignItemsflexEnd justifyCenter"}
            style={{ textAlign: "center", paddingTop: 10 }}
          >
            <Typography
              className={classes.current}
              variant={width == "sm" || width == "xs" ? "h4" : "h2"}
              component={width == "sm" || width == "xs" ? "h4" : "h2"}
            >
              {numericValue(current, currency)}
            </Typography>
            &nbsp;/&nbsp;
            <Typography
              className={classes.target}
              variant={width == "sm" || width == "xs" ? "body2" : "body1"}
            >
              <b>{numericValue(!isNaN(target) ? target : 0, currency)}</b>
            </Typography>
          </div>
        </div>
        {line && width != "sm" && width != "xs" ? (
          <Grid container>
            <Grid item xs={0} sm={0} md={12} lg={12} xl={12}>
             <Divider className={classes.dashedLine} />
             </Grid>
          </Grid>
         
        ) : null}
      </Grid>
    </Fragment>
  );
}

function CurrentMonthPerformance({
  currentMonthRunRate: month,
  budget,
  revenueTarget,
  orderTarget,
  kpiType,
  kpiTarget,
  currencySymbol,
  isLoading,
  classes,
  theme,
  width,
}) {
  return (
    <Grid container alignItems={"center"} style={{ height: "100%" }}>
      {isLoading ? (
        <div
          className={"flex alignItemsCenter justifyCenter"}
          style={{ width: "100%", height: "100%" }}
        >
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <Fragment>
          {kpiType === "CPO" || kpiType === "CPL" ? (
            <RunRateCharts
              icon={"Orders"}
              label={kpiLabel[kpiType]}
              current={month.orders}
              target={orderTarget}
              currencySymbol={currencySymbol}
              currency={false}
              fillColor={theme.palette.common.liteOrange}
              classes={classes}
              theme={theme}
              width={width}
              line
            />
          ) : (
            <RunRateCharts
              icon={"Revenue"}
              label={"Revenue"}
              current={month.revenue}
              target={revenueTarget}
              currencySymbol={currencySymbol}
              currency={true}
              fillColor={theme.palette.common.liteOrange}
              classes={classes}
              theme={theme}
              width={width}
              line
            />
          )}
          <RunRateCharts
            icon={"Spend"}
            label={"Spend"}
            current={month.spend}
            target={budget}
            currencySymbol={currencySymbol}
            currency={true}
            fillColor={theme.palette.common.litePurple}
            classes={classes}
            theme={theme}
            width={width}
          />
        </Fragment>
      )}
    </Grid>
  );
}

const style = (theme) => ({
  current: {
    fontWeight: "800",
  },
  target: {
    opacity: "0.5",
  },
  dashedLine: {
    border: "1px dashed " + theme.palette.common.darkHash,
    backgroundColor: "unset",
    boxSizing: "border-box",
    borderRadius: 10,
    opacity: 0.4,
    height: 0,
  },
  pieChart: {
    width: "50%",
    height: "50%",
    margin: "0 auto",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "1.2rem",
      paddingRight: "1.2rem",
    },
    [theme.breakpoints.down("lg")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    paddingBottom: "1.2rem",
  },
});

export default withWidth()(
  withStyles(style, { withTheme: true })(CurrentMonthPerformance)
);
